import { IconButton, IconButtonProps } from "@material-ui/core";
import React from "react";
import ExpandIcon from "../icons/ExpandIcon";
interface Props extends IconButtonProps {
  expanded: boolean;
  style?: React.CSSProperties;
}

const ExpandButton: React.FC<Props> = ({ expanded, style, ...props }) => {
  return (
    <IconButton {...props} style={{ ...style }}>
      <ExpandIcon expanded={expanded} />
    </IconButton>
  );
};

export default ExpandButton;
