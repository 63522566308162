import { Grid } from "@material-ui/core";
import React from "react";
import { Control } from "react-hook-form";
import * as Yup from "yup";
import ControlledInput from "../../../app/components/inputs/ControlledInput";

export const sendResetPasswordSchema = Yup.object().shape({
  email: Yup.string().label("Email").required().email(),
});

interface Props {
  control: Control<any>;
  disabled: boolean;
}

const SendResetPasswordFormFields: React.FC<Props> = ({
  control,
  disabled,
}) => {
  return (
    <Grid item md={12} xs={12}>
      <ControlledInput
        control={control}
        name="email"
        disabled={disabled}
        fullWidth
        label="Email"
        size="small"
      />
    </Grid>
  );
};

export default SendResetPasswordFormFields;
