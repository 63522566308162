export enum ProgramTypeIdEnum {
  // These will eventually be replaced by integers, once those back end changes are made
  SnapYouthJustice = "e798ea4f-23f4-4784-929d-133e89db3caf",
  DvRespite = "2e9ec2f9-4f1e-4f6c-aebc-0c90c77a9076",
  Snap = "4355fb1f-8ce4-4eb3-bd68-ce68e2b8c7e1",
  Icm = "b139b204-dc3e-4744-99a4-19fffbc12f3d",
  Shelter = "184eb506-399a-49eb-9b29-32c93d5e9676",
  ProbationRespite = "a60159eb-a87a-43ad-a78d-0e265895d6f5",
  CommunityCounseling = "db0121ca-e7d9-4723-a860-eeaa35341e33",
  SnapInSchools = "76e9df92-4f23-46dd-860e-d8b4fd96e5f8",
}
