import { Box, Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import { providerToOptionDto } from "../../provider/dtos/ProviderDto";
import ErrorText from "../../../app/components/ErrorText";
import { NirvanaExportSearchParamsDto } from "../dtos/NirvanaExportSearchParamsDto";
import { useNirvanaExportReportFilter } from "../hooks/useNirvanaExportReportFilter";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";

interface Props {
  params: NirvanaExportSearchParamsDto;
  isLoading: boolean;
  onSearch(dto: NirvanaExportSearchParamsDto): Promise<void>;
}

const NirvanaExportReportFilter: React.FC<Props> = ({
  params,
  isLoading,
  onSearch,
}) => {
  //#region State
  const {
    state: { providers, getAllProvidersRequestState, isValid, isFnyfsUser },
    form: { control },
    handlers: { handleDownload },
  } = useNirvanaExportReportFilter(onSearch, params);
  //#endregion

  return (
    <Fragment>
      <ErrorText error={getAllProvidersRequestState.error?.message} />
      <Grid container spacing={2}>
        {/* Date Range */}

        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="startOn"
            label="Start Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="endOn"
            label="End Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>

        {/* Provider Name */}
        <FnAccessWrapper>
          <Grid item md={3} xs={6}>
            <ControlledSelect
              control={control}
              name="providerIds"
              label="Providers"
              fullWidth
              multiple
              options={providers.map(providerToOptionDto)}
              isLoading={getAllProvidersRequestState.isLoading}
              disabled={isLoading}
              size="small"
            />
          </Grid>
        </FnAccessWrapper>

        <Grid item md={isFnyfsUser ? 3 : 6} xs={6}>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            height="100%"
          >

              <SpinnerButton
                showSpinner={isLoading}
                disabled={!isValid || isLoading }
                content="Download"
                color="primary"
                variant="contained"
                type="button"
                onClick={handleDownload}
              />  
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default NirvanaExportReportFilter;
