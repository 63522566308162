import {AssessmentTypeEnum} from "../enums/AssessmentTypeEnum";
import {QueryStringHelpers} from "../../../app/helpers";

export interface NirvanaParentAssessmentIdRequestDto
{
  type: AssessmentTypeEnum;
  youthId: string;
  caseId: string;
  completedOn: string;
}

export const convertToQueryString = (dto: NirvanaParentAssessmentIdRequestDto) => {
  return QueryStringHelpers.toQueryString(dto);
}
