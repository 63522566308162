import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GreenAlertLabel from "../../../app/components/labels/GreenAlertLabel";
import { ReferralDto } from "../../referrals/dtos/ReferralDto";
import { ReferralSummaryDto } from "../../referrals/dtos/ReferralSummaryDto";
import { referralStatusTextMap } from "../../referrals/enums/ReferralStatusEnum";

interface Props {
  referral: ReferralDto | ReferralSummaryDto;
}

export const ReferralScreeningCreatedLabel: React.FC<Props> = ({
  referral,
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box
      style={{ cursor: "pointer" }}
      component="span"
      onClick={() => {
        history.push({
          pathname: `/screenings/${referral.screening?.id}`,
          state: { prevLocation: location.pathname, text: "Back to Referral" },
        });
      }}
    >
      <Tooltip
        title={
          <Fragment>
            <Typography component="span">
              {referral.screening?.referenceNumber}
            </Typography>
          </Fragment>
        }
      >
        <Box component="span">
          <GreenAlertLabel
            label={referralStatusTextMap[referral.status]}
            show={"true"}
            component="span"
            variant="outlined"
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
