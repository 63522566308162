import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { monthOptions } from "../../../app/enums/MonthEnum";
import {getIsFnyfsUser} from "../../authentication/state/authenticationActions";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import {
  MonthlyReportCardDateRangeType,
  monthlyReportCardDateRangeTypeOptions,
  MonthlyReportCardSearchParamsDto,
} from "../dtos/MonthlyReportCardSearchParamsDto";
import {useGetFiscalYears} from "../../provider-fiscal-year/hooks/providerFiscalYearHooks";

interface Props {
  providers: ProviderDto[];
  invoiceYears: number[];
  getInvoiceYearsRequestState: RequestStateDto;
  getProvidersRequestState: RequestStateDto;
  requestState: RequestStateDto;
  onSubmit(params: MonthlyReportCardSearchParamsDto): Promise<void>;
}

const MonthlyReportCardFilter: React.FC<Props> = ({
  providers,
  invoiceYears,
  getInvoiceYearsRequestState,
  getProvidersRequestState,
  requestState,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
  } = useForm({
    defaultValues: new MonthlyReportCardSearchParamsDto(),
    resolver: yupResolver(MonthlyReportCardSearchParamsDto.getSchema()),
    mode: "all",
  });

  const isFnyfsUser = getIsFnyfsUser();

  const [getFiscalYears, fiscalYears, getFiscalYearsRequestState] = useGetFiscalYears();

  const toFiscalYearRange = (year: number) => {
    return `${year}-${year + 1}`;
  }

  useEffect(() => {
      getFiscalYears(null);
  }, [getFiscalYears])

  const getCurrentFiscalYear = () => {
    const now = new Date();
  
    return now.getMonth() >= 6 ? now.getFullYear() : now.getFullYear() - 1;
  };

  const [availableMonthOptions, setAvailableMonthOptions] =
    useState<SelectOption[]>(monthOptions);

  const { dateRangeType, year } = watch();

  useEffect(() => {
    if (dateRangeType !== MonthlyReportCardDateRangeType.MonthYear) return;

    const now = DateTime.now();
    if (now.year !== +year) {
      setAvailableMonthOptions(monthOptions);
    } else {
      setAvailableMonthOptions(
        monthOptions.filter((x) => x.value === "" || +x.value < now.month - 1)
      );
    }
  }, [year, dateRangeType, setAvailableMonthOptions]);

  useEffect(() => {
    if (
      dateRangeType === MonthlyReportCardDateRangeType.FiscalYearToDate &&
      invoiceYears.length > 0
    ) {
      setValue("year", `${getCurrentFiscalYear()}`);
    }

    if(dateRangeType === MonthlyReportCardDateRangeType.PreviousFiscalYear && year === getCurrentFiscalYear().toString()) {
      setValue("year", "");
    }
  }, [dateRangeType, setValue, invoiceYears, year]);
  const getButtonGridWidth = () => {
    if (isFnyfsUser) {
      switch(dateRangeType) {
        case MonthlyReportCardDateRangeType.MonthYear:
          return 1;
        case MonthlyReportCardDateRangeType.FiscalYearToDate:
          return 5;
        case MonthlyReportCardDateRangeType.PreviousFiscalYear:
          return 3;
      }
    } else {
      switch(dateRangeType) {
        case MonthlyReportCardDateRangeType.MonthYear:
          return 4;
        case MonthlyReportCardDateRangeType.FiscalYearToDate:
          return 8;
        case MonthlyReportCardDateRangeType.PreviousFiscalYear:
          return 6;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item md={4} sm={6}>
          <ControlledSelect
            control={control}
            name="dateRangeType"
            label="Date Type"
            fullWidth
            options={monthlyReportCardDateRangeTypeOptions}
            disabled={requestState.isLoading}
            size="small"
          />
        </Grid>

        {dateRangeType === MonthlyReportCardDateRangeType.MonthYear && (
          <Fragment>
            <Grid item md={2} sm={3}>
              <ControlledSelect
                control={control}
                name="year"
                fullWidth
                label="Year"
                isLoading={getInvoiceYearsRequestState.isLoading}
                disabled={requestState.isLoading}
                options={invoiceYears.map((x) => ({ value: x, label: `${x}` }))}
                size="small"
              />
            </Grid>
            <Grid item md={2} sm={3}>
              <ControlledSelect
                control={control}
                name="month"
                fullWidth
                label="Month"
                options={availableMonthOptions}
                disabled={requestState.isLoading}
                size="small"
              />
            </Grid>
          </Fragment>
        )}

        {dateRangeType === MonthlyReportCardDateRangeType.PreviousFiscalYear && (
          <Fragment>
            <Grid item md={2} sm={3}>
              <ControlledSelect
                control={control}
                name="year"
                fullWidth
                label="Fiscal Year"
                isLoading={getFiscalYearsRequestState.isLoading}
                disabled={requestState.isLoading}
                options={fiscalYears?.filter(x => x !== getCurrentFiscalYear()).map((x) => ({ value: x, label: toFiscalYearRange(x) })) || []}
                size="small"
              />
            </Grid>
          </Fragment>
        )}

        <FnAccessWrapper>
          <Grid item md={3} sm={4}>
            <ControlledSelect
              control={control}
              name="providerId"
              fullWidth
              isLoading={getProvidersRequestState.isLoading}
              options={providerToOptionDtos(providers)}
              disabled={requestState.isLoading}
              size="small"
            />
          </Grid>
        </FnAccessWrapper>
        <Grid item md={getButtonGridWidth()} sm={8}>
          <Box display="flex" alignItems="center" justifyContent="end">
            <div>
              <SpinnerButton
                showSpinner={false}
                disabled={!isValid || requestState.isLoading}
                content="View"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default MonthlyReportCardFilter;
