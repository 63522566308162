import { CaseDetailDto } from "../../features/cases/dtos/CaseDetailDto";
import { useGetProviderStaffMembers } from "../../features/provider/hooks/providerHooks";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createServicePlanFormDto,
  ServicePlanFormDto,
} from "../dto/ServicePlanFormDto";
import { useRequest } from "../../app/hooks/useRequest";
import {
  createServicePlanRequest,
  updateServicePlanRequest,
} from "../ServicePlanRequests";
import { useEffect } from "react";
import {ServicePlanDto} from "../dto/ServicePlanDto";

const schema = Yup.object().shape({
  startedOn: Yup.string().required().label("Plan Start Date"),
  counselorStaffId: Yup.string().required().label("Counselor"),
  notes: Yup.string().optional().label("Service Plan Notes"),
});

export const useCreateServicePlanForm = (
  onSubmit: () => Promise<void>,
  caseDto: CaseDetailDto,
  servicePlan?: ServicePlanDto,
) => {
  const [
    getProviderStaffMembers,
    staffMembers,
    getProviderStaffMembersRequestState,
    clearGetProviderStaffMemberErrors,
  ] = useGetProviderStaffMembers();

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<ServicePlanFormDto>({
    defaultValues: createServicePlanFormDto(caseDto, servicePlan),
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = () => {
    reset(createServicePlanFormDto(caseDto, servicePlan));
  };

  const [createServicePlan, , createServicePlanRequestState] = useRequest<
    ServicePlanFormDto,
    void
  >((dto) => createServicePlanRequest(caseDto.id, dto));

  const [updateServicePlan, , updateServicePlanRequestState] = useRequest<
    ServicePlanFormDto,
    void
  >((dto) => updateServicePlanRequest(caseDto.id, dto));

  const handleOnCreateSubmit = handleSubmit(
    async (formData: ServicePlanFormDto) => {
      await createServicePlan(formData);
      clearForm();
      await onSubmit();
    }
  );

  const handleOnUpdateSubmit = handleSubmit(
    async (formData: ServicePlanFormDto) => {
      await updateServicePlan(formData);
      clearForm();
      await onSubmit();
    }
  );

  useEffect(() => {
    clearGetProviderStaffMemberErrors();
    getProviderStaffMembers(caseDto.provider.id);
  }, [
    caseDto.provider.id,
    clearGetProviderStaffMemberErrors,
    getProviderStaffMembers,
  ]);

  useEffect(() => {
    reset(createServicePlanFormDto(caseDto, servicePlan));
  }, [caseDto, servicePlan, reset])

  return {
    state: {
      staffMembers,
      getProviderStaffMembersRequestState,
      createServicePlanRequestState,
      updateServicePlanRequestState,
    },
    form: { isValid, control, clearForm },
    handlers: {
      handleCreateSubmit: handleOnCreateSubmit,
      handleUpdateSubmit: handleOnUpdateSubmit,
    },
  };
};
