import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ProviderFiscalYearDto } from "../dtos/ProviderFiscalYearDto";
import {
  createProviderFiscalYearLicensedBedFormDto,
  ProviderFiscalYearLicensedBedFormDto,
} from "../dtos/ProviderFiscalYearLicensedBedFormDto";
import * as Yup from "yup";

const schema = Yup.object().shape({
  licensedBeds: Yup.string()
    .label("Licensed Beds")
    .typeError("Licensed Beds must be a valid number.")
    .required(),
});

export const useProviderFiscalYearLicensedBedsForm = (
  onSubmit: (dto: ProviderFiscalYearLicensedBedFormDto) => Promise<any>,
  fiscalYear?: ProviderFiscalYearDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createProviderFiscalYearLicensedBedFormDto(fiscalYear),
    resolver: yupResolver(schema),
    mode: "all",
  });

  //#endregion

  const clearForm = () => {
    reset(createProviderFiscalYearLicensedBedFormDto(fiscalYear));
  };
  const handleOnSubmit = handleSubmit(
    async (dto: ProviderFiscalYearLicensedBedFormDto) => {
      await onSubmit(dto);
      clearForm();
    }
  );

  useEffect(() => {
    reset(createProviderFiscalYearLicensedBedFormDto(fiscalYear));
  }, [reset, fiscalYear]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
