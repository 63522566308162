import React from "react";
import { ColorsEnum } from "../../enums/ColorsEnum";
import AlertLabel, { AlertLabelProps } from "./AlertLabel";

interface Props extends AlertLabelProps {}

const GreyAlertLabel: React.FC<Props> = (props) => (
  <AlertLabel
    {...props}
    color={ColorsEnum.RowGrey}
    style={{ color: "black", ...props.style }}
  />
);

export default GreyAlertLabel;
