import { useEffect } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { confirmDeleteOutreachActivityDialogId } from "../components/ConfirmDeleteOutreachActivityDialog";
import { outreachActivityFormModalId } from "../components/OutreachActivityFormDialog";
import { OutreachActivityFormDto } from "../dtos/OutreachActivityFormDto";
import {
  useDeleteOutreachActivity,
  useGetOutreachActivity,
  useUpdateOutreachActivity,
} from "./outreachActivitityHooks";

export const useOutreachActivityDetailPage = () => {
  const id = useId();
  const [
    getOutreachActivity,
    outreachActivity,
    getOutreachActivityRequestState,
  ] = useGetOutreachActivity();
  const dispatch = useAppDispatch();
  const history = useHistory();

  // Update
  const [
    updateOutreachActivity,
    ,
    updateOutreachActvitiyRequestState,
    clearUpdateOutreachActivityErrors,
  ] = useUpdateOutreachActivity();
  const handleUpdateOutreachActivityClicked = () => {
    clearUpdateOutreachActivityErrors();
    dispatch(openModal({ modalId: outreachActivityFormModalId }));
  };

  const handleUpdateOutreachActivity = async (dto: OutreachActivityFormDto) => {
    await updateOutreachActivity({ id, dto });
    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({ message: "Outreach Activity Updated." })
      );
    });
    await getOutreachActivity(id);
  };

  // Delete
  const [
    deleteOutreachActivity,
    ,
    deleteOutreachActivityRequestState,
    clearDeleteOutreachActivityErrors,
  ] = useDeleteOutreachActivity();
  const handleDeleteOutreachActivityClicked = () => {
    clearDeleteOutreachActivityErrors();
    dispatch(openModal({ modalId: confirmDeleteOutreachActivityDialogId }));
  };

  const handleDeleteOutreachActivity = async () => {
    await deleteOutreachActivity(id);
    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({ message: "Outreach Activity Deleted." })
      );
    });
    history.push("/outreach-Activities");
  };

  useEffect(() => {
    getOutreachActivity(id);
  }, [getOutreachActivity, id]);

  return {
    state: {
      outreachActivity,
      getOutreachActivityRequestState,
      updateOutreachActvitiyRequestState,
      deleteOutreachActivityRequestState,
    },
    handlers: {
      handleUpdateOutreachActivityClicked,
      handleUpdateOutreachActivity,
      handleDeleteOutreachActivityClicked,
      handleDeleteOutreachActivity,
    },
  };
};
