export enum TargetAudienceEnum {
    YouthAndParents = "YouthAndParents",
    ExtendedFamily = "ExtendedFamily",
    SchoolPersonnel = "SchoolPersonnel",
    LawEnforcementPersonnel = "LawEnforcementPersonnel",
    LocalDjjOtherGovernment = "LocalDjjOtherGovernment",
    JudgesCourtPersonnel = "JudgesCourtPersonnel",
    Churches = "Churches",
    HumanServiceCivicOrganizations = "HumanServiceCivicOrganizations",
    ElectedOfficials = "ElectedOfficials",
    GeneralPublic = "GeneralPublic",
    NotSpecified = "NotSpecified"
}

export const targetAudienceEnumTextMap:  { [key: string]: string } = {
    [TargetAudienceEnum.YouthAndParents]: "Youth and Parents",
    [TargetAudienceEnum.ExtendedFamily]: "Extended Family Members",
    [TargetAudienceEnum.SchoolPersonnel]: "School Personnel",
    [TargetAudienceEnum.LawEnforcementPersonnel]: "Law Enforcement Personnel",
    [TargetAudienceEnum.LocalDjjOtherGovernment]: "Local DJJ or Other Government Offices",
    [TargetAudienceEnum.JudgesCourtPersonnel]: "Judges and Court Personnel",
    [TargetAudienceEnum.Churches]: "Churches",
    [TargetAudienceEnum.HumanServiceCivicOrganizations]: "Human Service and Civic Organizations",
    [TargetAudienceEnum.ElectedOfficials]: "Elected Officials",
    [TargetAudienceEnum.GeneralPublic]: "General Public",
    [TargetAudienceEnum.NotSpecified]: "Not Specified",
}