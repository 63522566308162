import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getExpirationDate } from "../dtos/JwtPayloadDto";
import { UserDto } from "../dtos/UserDto";
import { UserPermissionDto } from "../dtos/UserPermissionDto";
import {
  getToken,
  initializeAuthentication,
  login,
  logout,
  refresh,
} from "./authenticationActions";
export interface AuthenticationState {
  token: string | null;
  isLoading: boolean;
  user?: UserDto;
  expiration?: number;
  permissions: UserPermissionDto;
  selectedProviderId: string | null;
  initialized: boolean;
}
const createInitialState = (): AuthenticationState => {
  const token = getToken();
  const expiration = getExpirationDate(token);
  return {
    isLoading: false,
    token,
    permissions: {},
    selectedProviderId: null,
    initialized: false,
    expiration,
  };
};

const initialState: AuthenticationState = createInitialState();

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setProvider: (state, action: PayloadAction<string | null>) => {
      state.selectedProviderId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeAuthentication.fulfilled, (state, action) => {
      const {
        initialized,
        user,
        expiration,
        permissions,
        token,
        selectedProviderId,
      } = action.payload;
      state.initialized = initialized;
      state.user = user;
      state.expiration = expiration;
      state.permissions = permissions;
      state.token = token;
      state.selectedProviderId = selectedProviderId;
    });
    builder.addCase(initializeAuthentication.rejected, (state) => {
      state.initialized = true;
    });
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.permissions = action.payload.permissions;
      state.selectedProviderId = action.payload.selectedProviderId;
      state.isLoading = false;
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.token = null;
      state.user = undefined;
      state.permissions = {};
      state.selectedProviderId = null;
    });
    builder.addCase(logout.rejected, (state) => {
      state.token = null;
      state.user = undefined;
      state.permissions = {};
      state.selectedProviderId = null;
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.permissions = action.payload.permissions;
      state.selectedProviderId = action.payload.selectedProviderId;
    });
    builder.addCase(refresh.rejected, (state) => {
      state.token = null;
      state.user = undefined;
      state.permissions = {};
      state.selectedProviderId = null;
    });
  },
});

// Actions
export const { setProvider } = authenticationSlice.actions;

// Reducer
export default authenticationSlice.reducer;
