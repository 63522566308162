import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
import { LocationDto } from "../dtos/LocationDto";
import { ProgramLocationDto } from "../dtos/ProgramLocationDto";
import { ProgramLocationFormDto } from "../dtos/ProgramLocationFormDto";
import { useProgramLocationForm } from "../hooks/useProgramLocationForm";
import ProgramLocationFormFields from "./ProgramLocationFormFields";

export const programLocationModalId = "program-location-form-modal";

interface Props {
  programLocation?: ProgramLocationDto;
  onSubmit: (dto: ProgramLocationFormDto) => Promise<any>;
  locations: LocationDto[];
  program: ProgramDto;
  createExistingRequestState: RequestStateDto;
  createNewRequestState: RequestStateDto;
  updateProgramLocationRequestState: RequestStateDto;
}

const ProgramLocationFormDialog: React.FC<Props> = ({
  programLocation,
  onSubmit,
  locations,
  program,
  createExistingRequestState,
  createNewRequestState,
  updateProgramLocationRequestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { state, form, handlers } = useProgramLocationForm(
    onSubmit,
    program,
    programLocation
  );
  const { jjisPrograms, getJjisProgramsRequestState, useExisting } = state;
  const { clearForm, control, isValid } = form;
  const { handleSubmit } = handlers;
  const fundingSource = program?.fundingSource || ProgramFundingSourceEnum;
  const isLoading =
    createExistingRequestState.isLoading ||
    createNewRequestState.isLoading ||
    updateProgramLocationRequestState.isLoading;
  const { isLoading: getJjisProgramsAreLoading, error: getJjisProgramsError } =
    getJjisProgramsRequestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  //#endregion

  return (
    <Dialog open={modalId === programLocationModalId} fullWidth maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {programLocation ? "Edit" : "Create"} Program Location{" "}
          <ErrorText error={createExistingRequestState.error?.message} />
          <ErrorText error={createNewRequestState.error?.message} />
          <ErrorText error={updateProgramLocationRequestState.error?.message} />
          <ErrorText error={getJjisProgramsError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <ProgramLocationFormFields
              control={control}
              disabled={isLoading}
              useExisting={useExisting === "true"}
              locations={locations}
              fundingSource={fundingSource}
              jjisCodes={jjisPrograms.filter(
                (x) =>
                  program?.programType?.jjisProgramType === undefined ||
                  x.jjisProgramType === program?.programType?.jjisProgramType
              )}
              jjisCodesAreLoading={getJjisProgramsAreLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProgramLocationFormDialog;
