import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { ForwardReferralFormDto } from "./dtos/ForwardReferralFormDto";
import { ReferralDto } from "./dtos/ReferralDto";
import { ReferralFormDto } from "./dtos/ReferralFormDto";
import { ReferralSearchParamsDto } from "./dtos/ReferralSearchParamsDto";
import { ReferralSummaryDto } from "./dtos/ReferralSummaryDto";
import { RemoveReferralFormDto } from "./dtos/RemoveReferralFormDto";
import { ReferralStatusEnum } from "./enums/ReferralStatusEnum";

interface CreateReferralParams {
  dto: ReferralFormDto;
  recaptchaToken: string;
}

interface ForwardReferralProps {
  id: string;
  dto: ForwardReferralFormDto;
}

interface RemoveReferralParams {
  id: string;
  dto: RemoveReferralFormDto;
}

export const createReferralRequest = ({
  dto,
  recaptchaToken,
}: CreateReferralParams) =>
  coreAgent.postRequest<ReferralSummaryDto>("referrals", dto, {
    headers: {
      "x-recaptcha-token": recaptchaToken,
      withCredentials: false,
    },
  });

export const getReferralsRequest = (searchParams: ReferralSearchParamsDto) =>
  coreAgent.getRequest<PaginationResultDto<ReferralSummaryDto>>(
    `referrals?${ReferralSearchParamsDto.toQueryString(searchParams)}`
  );

export const forwardReferralRequest = ({ id, dto }: ForwardReferralProps) =>
  coreAgent.postRequest(`referrals/${id}/forward`, dto);

export const getReferralRequest = (id: string) =>
  coreAgent.getRequest<ReferralDto>(`referrals/${id}`);

export const removeReferralRequest = ({ id, dto }: RemoveReferralParams) =>
  coreAgent.putRequest<ReferralDto>(`referrals/${id}/remove`, dto);

export const getPendingReferralCountRequest = () => {
  const params = new ReferralSearchParamsDto({});
  params.status =  ReferralStatusEnum.Pending;
  return coreAgent.getRequest<number>(
    `referrals/count?${ReferralSearchParamsDto.toQueryString(params)}`
  )};