import { ButtonProps } from "@material-ui/core";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import WhiteButton from "./WhiteButton";

const FilterButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <WhiteButton title="Filter List" startIcon={<FilterListIcon />} {...props}>
      {children || "Filter"}
    </WhiteButton>
  );
};

export default FilterButton;
