import React, { Fragment } from "react";
import AlertDismissalFormDialog from "../../alerts/components/AlertDismissalFormDialog";
import { useSnapInSchoolsDetailPageContext } from "../pages/SnapInSchoolCyclesDetailPage";
import ConfirmDeleteSnapInSchoolsCycleDialog from "./ConfirmDeleteSnapInSchoolsCycle";
import ConfirmDeleteSnapInSchoolsSessionDialog from "./ConfirmDeleteSnapInSchoolsSessionDialog";
import ConfrimDeleteSnapInSchoolsFidelityAdherenceChecklistDialog from "./ConfrimDeleteSnapInSchoolsFidelityAdherenceChecklistDialog";
import SnapInSchoolsCycleFormDialog from "./SnapInSchoolsCycleFormDialog";
import SnapInSchoolsFidelityAdherenceFormDialog from "./SnapInSchoolsFidelityAdherenceChecklistFormDialog";
import SnapInSchoolsSessionFormDialog from "./SnapInSchoolsSessionFormDialog";

const SnapInSchoolsCycleDetailModals = () => {
  const context = useSnapInSchoolsDetailPageContext();
  const {
    snapCycle,
    updateSnapInSchoolsCycleRequestState,
    deleteSnapInSchoolsCycleRequestState,
    upsertFidelityAdherenceChecklistRequestState,
    deleteFidelityAdherenceChecklistRequestState,
    handleDeleteSnapInSchoolsCycle,
    handleEditSnapInSchoolsCycle,
    handleUpsertFidelityAdherenceChecklist,
    handleDeleteFidelityAdherenceChecklist,
  } = context.snapCycle;
  const {
    upsertSnapSessionRequestState,
    selectedSnapInSchoolsSession,
    deleteSnapInSchoolsSessionRequestState,
    handleUpsertSnapSession,
    handleDeleteSnapInSchoolsSession,
  } = context.sessions;

  const {
    selectedAlertType,
    createAlertDismissalRequestState,
    handleCreateAlertDismissal,
  } = context.alerts;

  return (
    <Fragment>
      <AlertDismissalFormDialog
        requestState={createAlertDismissalRequestState}
        type={selectedAlertType}
        onSubmit={handleCreateAlertDismissal}
        snapCycle={snapCycle}
      />
      <ConfrimDeleteSnapInSchoolsFidelityAdherenceChecklistDialog
        snapCycle={snapCycle}
        requestState={deleteFidelityAdherenceChecklistRequestState}
        onConfirm={handleDeleteFidelityAdherenceChecklist}
      />
      <SnapInSchoolsFidelityAdherenceFormDialog
        onSubmit={handleUpsertFidelityAdherenceChecklist}
        requestState={upsertFidelityAdherenceChecklistRequestState}
        snapCycle={snapCycle}
      />
      <SnapInSchoolsSessionFormDialog
        onSubmit={handleUpsertSnapSession}
        requestState={upsertSnapSessionRequestState}
        snapCycle={snapCycle}
        snapSession={selectedSnapInSchoolsSession}
      />
      <SnapInSchoolsCycleFormDialog
        onSubmit={handleEditSnapInSchoolsCycle}
        requestState={updateSnapInSchoolsCycleRequestState}
        snapCycle={snapCycle}
      />
      <ConfirmDeleteSnapInSchoolsCycleDialog
        cycle={snapCycle}
        requestState={deleteSnapInSchoolsCycleRequestState}
        onConfirm={handleDeleteSnapInSchoolsCycle}
      />
      <ConfirmDeleteSnapInSchoolsSessionDialog
        session={selectedSnapInSchoolsSession}
        requestState={deleteSnapInSchoolsSessionRequestState}
        onConfirm={handleDeleteSnapInSchoolsSession}
      />
    </Fragment>
  );
};

export default SnapInSchoolsCycleDetailModals;
