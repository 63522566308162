import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useFormState, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import {
  SnapInSchoolsCurriculumDto,
  snapInSchoolsCurriculumToOptions,
} from "../dtos/SnapInSchoolsCurriculumDto";
import * as Yup from "yup";
import FileUploadComponent from "../../../app/components/FileUploadComponent";
import { SnapInSchoolsSessionFormDto } from "../dtos/SnapInSchoolsSessionFormDto";
import { toOriginalFilename } from "../../../app/helpers";

const allowedFileTypes = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png"
];

export const snapInSchoolsSessionSchema = Yup.object().shape({
  sessionHeldOn: Yup.string().label("Held On").required(),
  curriculumId: Yup.string().label("Session Name").required(),
  numberOfAttendees: Yup.string().label("# of Attendees").required(),
  attendanceLogFilename: Yup.string()
    .label("Attendance Log")
    .required()
    .test(
      "",
      `Attendance Logs can only have the following file extensions: ${allowedFileTypes.join(
        ", "
      )}`,
      function (value) {
        if (value == null || value === "") return true;

        return allowedFileTypes.some((fileType) => value.toLowerCase().endsWith(fileType));
      }
    ),
});

interface Props {
  control: Control<SnapInSchoolsSessionFormDto>;
  curriculum: SnapInSchoolsCurriculumDto[];
  curriculumIsLoading: boolean;
  isDisabled: boolean;
  onFileUploaded(filename: string): void;
}

const SnapInSchoolsSessionFormFields: React.FC<Props> = ({
  control,
  curriculum,
  curriculumIsLoading,
  isDisabled,
  onFileUploaded,
}) => {
  const { attendanceLogFilename: attendanceLog } = useWatch({ control });
  const { errors } = useFormState({ control });

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="sessionHeldOn"
          label="Session Held On*"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          label="Session Name"
          name="curriculumId"
          options={snapInSchoolsCurriculumToOptions(curriculum)}
          isLoading={curriculumIsLoading}
          disabled={isDisabled}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledWholeNumberInput
          control={control}
          name="numberOfAttendees"
          label="# of Attendees"
          fullWidth
          disabled={isDisabled}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <FileUploadComponent
          uploadButtonText="Upload Attendance"
          onFileUploaded={onFileUploaded}
          defaultFileName={toOriginalFilename(attendanceLog)}
          allowedFiletypes={allowedFileTypes.join(",")}
          error={
            errors.attendanceLogFilename && errors.attendanceLogFilename.message
          }
        />
      </Grid>
    </Fragment>
  );
};

export default SnapInSchoolsSessionFormFields;
