import {TransferableRecordTypes} from "./TransferableRecordTypes";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectModalId} from "../../../modal/state/modalSelectors";
import {useTransferYouthRecordForm} from "./UseTransferYouthRecordForm";
import {closeModal} from "../../../modal/state/modalSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography
} from "@material-ui/core";
import ErrorText from "../../../../app/components/ErrorText";
import ControlledInput from "../../../../app/components/inputs/ControlledInput";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import {youthRecordTransferModalId} from "./YouthRecordFormDto";
import SearchIcon from "@material-ui/icons/Search";
import KeyValueDetails from "../../../../app/components/KeyValueDetails";
import DateLabel from "../../../../app/components/labels/DateLabel";
import {YouthSummaryDto} from "../../../youths/dto/YouthSummaryDto";
import {YouthDto} from "../../../youths/dto/YouthDto";

interface Props {
  recordId: string;
  recordType: TransferableRecordTypes;

  currentYouth: YouthDto | YouthSummaryDto;
  refresh: () => Promise<void>
}

export const TransferYouthRecordDialog: React.FC<Props> = ({recordId, recordType, currentYouth, refresh}) => {
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const {form, state, handlers} = useTransferYouthRecordForm(recordId, recordType, refresh);

  const {control, clearForm } = form;
  const {
    transferCaseRequestState: {error, isLoading},
    youthReferenceEntered,
    selectedYouths,
    youthRequestState
  } = state;
  const {transferCase, handleYouthLookupClicked} = handlers;

  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };

  const noYouthFound = !youthRequestState.isLoading && selectedYouths != null && selectedYouths.count === 0;
  const multipleYouthFound = !youthRequestState.isLoading && selectedYouths != null && selectedYouths.count > 1;
  const singleYouthFound = !youthRequestState.isLoading && selectedYouths != null && selectedYouths.count === 1;

  const renderYouthResult = (youth: YouthSummaryDto) => (<Grid item md={12} xs={12} style={{ padding: 8 }}>
    <p>
    <Typography>{recordType} will be transferred from [{currentYouth.firstName} {currentYouth.lastName} ({currentYouth.referenceNumber})] to this Youth:</Typography>
    </p>
    <KeyValueDetails
      rows={[
        { key: "firstName", label: "First Name", value: youth.firstName },
        { key: "lastName", label: "Last Name", value: youth.lastName },
        { key: "dob", label: "DOB", value: <DateLabel date={youth.dob} /> },
        { key: "youthId", label: "Youth ID", value: youth.referenceNumber }
      ]}
      isLoading={youthRequestState.isLoading}
    />

  </Grid>);

  return (
    <Dialog open={modalId === youthRecordTransferModalId} maxWidth="md" fullWidth>
      <form onSubmit={handleYouthLookupClicked}>
        <DialogTitle>
          Transfer {recordType}
          <ErrorText error={error?.message}/>
        </DialogTitle>
        <DialogContent>

          <Grid item md={12} xs={12} style={{marginBottom: 8}}>
            <Typography>
              Enter the Youth ID of the Youth to whom the {recordType} should be transferred.
            </Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ControlledInput
              name="youthReferenceNumber"
              label="Youth ID"
              control={control}
              disabled={false}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SpinnerButton
                      showSpinner={isLoading}
                      content={<SearchIcon />}
                      color="primary"
                      disabled={!youthReferenceEntered || youthRequestState.isLoading}
                      onClick={handleYouthLookupClicked}
                    />
                  </InputAdornment>
                )}}
            />
          </Grid>

          {noYouthFound && <ErrorText error={"No Youth found with matching Youth ID."}/>}
          {multipleYouthFound && <ErrorText
              error={"More than one matching youth found. Please ensure you entered a complete Youth ID."}/>}
          {singleYouthFound && renderYouthResult(selectedYouths!.items[0])}
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content={`Transfer ${recordType}`}
            color="primary"
            disabled={!singleYouthFound}
            onClick={() => transferCase(selectedYouths!.items[0].id)}
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}
