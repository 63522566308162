import {useCallback, useEffect, useMemo} from "react";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import {
  useGetProviderLocations,
  useGetProviderPrograms,
} from "../../provider/hooks/providerHooks";
import {programLocationActiveOn} from "../../locations/dtos/ProgramLocationDto";

export const useProgramLocationNestedFields = (
  waitlistOnly: boolean = false,
  intakeDate?: Date | string,
  programId?: string
) => {
  //#region State

  const [
    getProviderPrograms,
    providerPrograms,
    getProviderProgramsRequestState,
  ] = useGetProviderPrograms();
  const [
    getProviderLocations,
    providerLocations,
    getProviderLocationsRequestState,
  ] = useGetProviderLocations();
  //#endregion

  //#region Handlers

  const [validPrograms, validLocations] = useMemo(() => {
    const fixedIntakeDate = intakeDate ? new Date(intakeDate) : undefined;
    if(!(providerPrograms && providerLocations)) return [[], []];

    const programs = providerPrograms
      .filter(p => p.programType.canHaveCases)
      .filter(p => !fixedIntakeDate || p.locations.some(programLocationActiveOn(fixedIntakeDate)))
      .filter(x => !waitlistOnly || (x.programType.canWaitlist && x.locations.some((y) => !y.terminatedOn)))

    const program = programs.find((x) => x.id === programId);
    if(!program) return [programs, []];

    const locationIds = program.locations
        .filter(p => !fixedIntakeDate || programLocationActiveOn(fixedIntakeDate)(p))
        .filter((x) => x.terminatedOn === undefined)
        .map((x) => x.locationId);

    const locations = providerLocations.filter(location => locationIds.includes(location.id) );

    return [programs, locations]
  }, [intakeDate, programId, providerLocations, providerPrograms, waitlistOnly])

  const getProgramLocationId = useCallback(
    (programId: string, locationId: string) => {
      if (!(programId && locationId)) {
        return '';
      }

      const program = validPrograms?.find((x) => x.id === programId);
      if(!program) {
        return '';
      }

      const programLocation = program.locations.find(x => x.locationId === locationId);
      if(!programLocation) {
        return '';
      }

      return programLocation.id;
    },
    [validPrograms]
  );

  //#endregion
  //#region Effects
  useEffect(() => {
    const providerId = getProviderId();
    if (providerId === fnyfsProviderId) return;

    getProviderPrograms(providerId!);
    getProviderLocations(providerId!);
  }, [getProviderPrograms, getProviderLocations]);

  //#endregion

  return {
    state: {
      providerPrograms: validPrograms,
      locations: validLocations,
      getProviderProgramsRequestState,
      getProviderLocationsRequestState,
    },
    getters: { getProgramLocationId },
  };
};
