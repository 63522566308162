import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SnapInSchoolsCycleFilterFormDto } from "../dtos/SnapInSchoolsCycleFilterFormDto";
import { SnapInSchoolsSearchParamsDto } from "../dtos/SnapInSchoolsSearchParamsDto";

export const useSnapInSchoolsCycleFilter = (
  onSubmit: (dto: SnapInSchoolsSearchParamsDto) => void,
  onClose: () => void,
  onProviderChange: (id: string) => void,
  params: SnapInSchoolsSearchParamsDto
) => {
  //#region State
  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues: new SnapInSchoolsCycleFilterFormDto(params),
  });

  const { providerId } = watch();

  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit(
    (dto: SnapInSchoolsCycleFilterFormDto) => {
      const newParams: SnapInSchoolsSearchParamsDto = {
        ...dto,
        sortBy: "",
        page: 0,
        pageSize: params.pageSize,
      };

      onSubmit(newParams);
      onClose();
    }
  );
  const clearAllFilters = () => {
    reset(new SnapInSchoolsCycleFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new SnapInSchoolsCycleFilterFormDto(params));
  }, [params, reset]);

  useEffect(() => {
    onProviderChange(providerId);
  }, [providerId, onProviderChange]);

  //#endregion

  return {
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
