import { StaffMemberDto } from "../../features/staff-members/dtos/StaffMemberDto";
import { ServicePlanParticipantRolesEnum } from "../enums/ServicePlanParticipantRolesEnum";
import { ServicePlanGoalStatusEnum } from "../enums/ServicePlanGoalStatusEnum";

export interface ServicePlanDto {
  id: string;
  startedOn: Date;
  counselor: StaffMemberDto;
  notes: string;
  participants: ServicePlanParticipantDto[];
  goals: ServicePlanGoalDto[];
  reviews: ServicePlanReviewDto[];
  updatedAt: Date;
}

export interface ServicePlanParticipantDto {
  id?: string;
  name: string;
  role: ServicePlanParticipantRolesEnum;
}

export const createDefaultServicePlanParticipant = (dto?: ServicePlanParticipantDto | undefined) => {
  return {
    id: dto?.id,
    name: dto?.name || "",
    role: dto?.role || ServicePlanParticipantRolesEnum.None
  }
}

export interface ServicePlanGoalDto {
  id: string;
  name: string;
  notes: string;
  status: ServicePlanGoalStatusEnum;
  interventions: ServicePlanInterventionDto[];
  domains: string[];
}

export interface ServicePlanInterventionDto {
  id: string;
  description: string;
  notes: string;
}

export interface ServicePlanReviewDto {
  id: string;
  reviewedOn: Date;
  notes: string;
  youthParticipated: boolean;
  counselorAtReview: string;
  counselorParticipated: boolean;
  otherParticipants: ServicePlanReviewParticipantDto[];
}

export interface ServicePlanReviewParticipantDto {
  id: string;
  name: string;
  role: ServicePlanParticipantRolesEnum;
}
