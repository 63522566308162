import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import { ProgramDto } from "../../../programs/dtos/ProgramDto";
import ProgramFundingSourceLabel from "../../../programs/components/ProgramFundingSourceLabel";
import Box from "@material-ui/core/Box/Box";

import { Avatar, Paper } from "@material-ui/core";
import ProviderProgramLocations from "../provider-program-location/ProviderProgramLocations";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import AddButton from "../../../../app/components/buttons/AddButton";
import EditButton from "../../../../app/components/buttons/EditButton";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { LocationDto } from "../../../locations/dtos/LocationDto";
import { getProviderId } from "../../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../../authentication/constants";
import { ProgramFundingSourceEnum } from "../../../programs/enums/ProgramFundingSourceEnum";
import { ProgramLocationDto } from "../../../locations/dtos/ProgramLocationDto";
import { ColorsEnum } from "../../../../app/enums/ColorsEnum";
interface Props {
  locations: LocationDto[];
  program: ProgramDto;
  onChange: () => void;
  onEdit: () => void;
  onCreateLocationClick: () => void;
  onEditLocationClick: (
    program: ProgramDto,
    programLocation: ProgramLocationDto
  ) => void;
  onTerminateProgramLocationClick: (location: ProgramLocationDto) => void;
}

const ProviderProgramItem: React.FC<Props> = ({
  program,
  onChange,
  onEdit,
  onCreateLocationClick,
  onEditLocationClick,
  locations,
  onTerminateProgramLocationClick,
}) => {
  return (
    <Paper variant="outlined">
      <Box padding="16px 16px 0 16px">
        <Box display="flex">
          <Avatar>
            <EventAvailableIcon />
          </Avatar>
          <Box marginLeft="12px">
            <Typography variant="h5">{program.name}</Typography>
            <Typography color="textSecondary">
              Type: {program.programType.name}
            </Typography>
            <Typography color="textSecondary">
              Funding Source: <ProgramFundingSourceLabel program={program} />
            </Typography>
            { program.contractId && <Typography color="textSecondary">
              Contract: {program.contractName}
            </Typography> }
          </Box>
          <Box marginLeft="auto">
            {(getProviderId() === fnyfsProviderId ||
              program.fundingSource === ProgramFundingSourceEnum.Other) && (
              <WriteAccessLevelWrapper
                name={PermissionResourceNameEnum.Program}
              >
                <EditButton onClick={onEdit} />
              </WriteAccessLevelWrapper>
            )}
          </Box>
        </Box>
      </Box>
      <Box padding="0 16px 16px 16px" marginLeft="52px">
        <Box display="flex">
          <Typography color="textSecondary">Locations:</Typography>{" "}
          <Box marginLeft="auto">
            {(getProviderId() === fnyfsProviderId ||
              program.fundingSource === ProgramFundingSourceEnum.Other) && (
              <WriteAccessLevelWrapper
                name={PermissionResourceNameEnum.ProgramLocation}
              >
                <AddButton
                  text="ADD LOCATION"
                  title="Add Location"
                  size="small"
                  style={{ color: ColorsEnum.Blue }}
                  onClick={onCreateLocationClick}
                />
              </WriteAccessLevelWrapper>
            )}
          </Box>
        </Box>

        <ProviderProgramLocations
          program={program}
          locations={locations}
          onEditLocationClick={onEditLocationClick}
          onTerminateProgramLocationClick={onTerminateProgramLocationClick}
        />
      </Box>
    </Paper>
  );
};

export default ProviderProgramItem;
