import { useForm } from "react-hook-form";
import {
  createTerminateProgramLocationFormDto,
  TerminateProgramLocationFormDto,
} from "../dtos/TerminateProgramLocationFormDto";

export const useTerminateProgramLocationForm = (
  onSumbit: (dto: TerminateProgramLocationFormDto) => Promise<any>,
  terminatedOn?: Date
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createTerminateProgramLocationFormDto(terminatedOn),
    mode: "all",
  });
  //#endregion

  //#region handlers
  const clearForm = () => {
    reset(createTerminateProgramLocationFormDto(terminatedOn));
  };
  const handleOnSubmit = handleSubmit(
    async (dto: TerminateProgramLocationFormDto) => {
      await onSumbit(dto);
      clearForm();
    }
  );

  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
