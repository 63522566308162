import { datetimeToFormString } from "../../../app/helpers";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { DischargeDto } from "./DischargeDto";
import * as Yup from "yup";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
export class ChangeDischargeDateFormDto {
  dischargedAt: string;

  constructor(discharge?: DischargeDto) {
    this.dischargedAt = discharge
      ? datetimeToFormString(new Date(discharge.dischargedAt))
      : "";
  }

  public static getDischargedAtShema(caseDto?: CaseDetailDto) {
    return Yup.date()
      .typeError("Invalid Date/Time format.")
      .required("Discharge Date may not be a future date.")
      .test(
        "validate-not-in-future",
        "Discharge date can not be in the future.",
        function (value) {
          if (
            caseDto?.program?.fundingSource !==
            ProgramFundingSourceEnum.FloridaNetwork
          )
            return true;
          if (value) {
            if (value > new Date()) {
              return false;
            }
          }

          return true;
        }
      );
  }

  public static getSchema(caseDto?: CaseDetailDto) {
    return Yup.object().shape({
      dischargedAt: ChangeDischargeDateFormDto.getDischargedAtShema(caseDto),
    });
  }
}
