import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import {
  createSendResetPasswordFormDto,
  SendResetPasswordFormDto,
} from "../dtos/SendResetPasswordFormDto";
import SendResetPasswordFormFields, {
  sendResetPasswordSchema,
} from "./SendResetPasswordFormFields";

interface Props {
  onSubmit: (dto: SendResetPasswordFormDto, callback?: () => void) => void;
  isLoading: boolean;
}

const SendResetPasswordForm: React.FC<Props> = ({ onSubmit, isLoading }) => {
  //#region State
  const {
    control,
    formState: { isValid },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: createSendResetPasswordFormDto(),
    mode: "all",
    resolver: yupResolver(sendResetPasswordSchema),
  });
  //#endregion

  //#region Handlers
  const handleOnSubmit = (dto: SendResetPasswordFormDto) => {
    onSubmit(dto, reset);
  };
  //#endregion

  return (
    <Card elevation={4}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <CardContent>
          <Typography variant="h6" component="h6">
            Reset Password
          </Typography>
          <Grid container spacing={1}>
            <SendResetPasswordFormFields
              control={control}
              disabled={isLoading}
            />
          </Grid>
        </CardContent>
        <CardActions>
          <Box width="100%" padding="4px" paddingTop="0">
            <SpinnerButton
              showSpinner={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={isValid === false}
              content="Submit"
            />
          </Box>
        </CardActions>
      </form>
    </Card>
  );
};

export default SendResetPasswordForm;
