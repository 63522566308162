import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { fiscalYearMonthOptions } from "../../../app/enums/MonthEnum";
import { getIsFnyfsUser } from "../../authentication/state/authenticationActions";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import { CumulativeDeliverableReportParamsDto } from "../dtos/CumulativeDeliverableReportParametersDto";

const filterFiscalYears = (fiscalYear: number, year: number, month: number) => {
  if (year < fiscalYear) return fiscalYearMonthOptions;

  return fiscalYearMonthOptions.filter((x) => {
    if (x.value === "") return true;

    if (year === fiscalYear && +x.value > 5 && month > +x.value) {
      return true;
    }

    if (year - 1 === +fiscalYear && (+x.value >= 6 || month > +x.value))
      return true;

    return false;
  });
};

interface Props {
  providers: ProviderDto[];
  getProvidersRequestState: RequestStateDto;
  fiscalYears: number[];
  getFiscalYearsRequestState: RequestStateDto;
  requestState: RequestStateDto;
  onSubmit(params: CumulativeDeliverableReportParamsDto): Promise<void>;
}

const BenchmarksReportFilter: React.FC<Props> = ({
  providers,
  getProvidersRequestState,
  requestState,
  fiscalYears,
  getFiscalYearsRequestState,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm({
    defaultValues: new CumulativeDeliverableReportParamsDto(),
    resolver: yupResolver(CumulativeDeliverableReportParamsDto.getSchema()),
    mode: "all",
  });
  const isProviderUser = !getIsFnyfsUser();
  const [availableMonthOptions, setAvailableMonthOptions] = useState<
    SelectOption[]
  >(fiscalYearMonthOptions);

  const { providerId, fiscalYear } = watch();

  useEffect(() => {
    const now = new Date();

    if (fiscalYear === "") {
      setAvailableMonthOptions(fiscalYearMonthOptions);
      return;
    }

    setAvailableMonthOptions(
      filterFiscalYears(+fiscalYear, now.getFullYear(), now.getMonth())
    );
  }, [fiscalYear, setAvailableMonthOptions]);

  return (
    <form
      onSubmit={handleSubmit((params) => {
        params.throughMonth = `${+params.throughMonth + 1}`;

        onSubmit(params);
      })}
    >
      <Grid container spacing={1}>
        <Grid item md={3} sm={6}>
          <ControlledSelect
            control={control}
            name="fiscalYear"
            fullWidth
            label="Fiscal Year"
            isLoading={getFiscalYearsRequestState.isLoading}
            disabled={requestState.isLoading}
            options={fiscalYears.map((x) => ({
              value: x,
              label: `${x}-${x + 1}`,
            }))}
            size="small"
          />
        </Grid>
        <Grid item md={3} sm={6}>
          <ControlledSelect
            control={control}
            name="throughMonth"
            fullWidth
            label="Through Month"
            options={availableMonthOptions}
            disabled={requestState.isLoading}
            size="small"
          />
        </Grid>
        <ProviderAccessWrapper>
          <Grid item md={3} sm={6}>
            <ControlledSelect
              control={control}
              name="compareToProviderId"
              fullWidth
              isLoading={getProvidersRequestState.isLoading}
              options={providerToOptionDtos(providers)}
              disabled={requestState.isLoading || providerId === ""}
              size="small"
              label="Compare To"
            />
          </Grid>
        </ProviderAccessWrapper>
        <Grid item md={isProviderUser ? 3 : 6} sm={6}>
          <Box display="flex" justifyContent="end">
            <div>
              <SpinnerButton
                showSpinner={false}
                disabled={!isValid || requestState.isLoading}
                content="View"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default BenchmarksReportFilter;
