import React, { useCallback } from "react";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { ReferralSearchParamsDto } from "../dtos/ReferralSearchParamsDto";
import { fnyfsName, fnyfsProviderId } from "../../authentication/constants";
import { referralStatusTextMap } from "../enums/ReferralStatusEnum";
import { getProviderId } from "../../authentication/state/authenticationActions";
import FilterList from "../../../app/components/FilterList";
import { ReferralFilterFormDto } from "../dtos/ReferralFilterFormDto";

interface Props {
  params: ReferralSearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  onSearch(dto: ReferralSearchParamsDto): void;
}

const ReferralFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  onSearch,
}) => {
  const handleClearParams = () => {
    const form = new ReferralFilterFormDto();
    return new ReferralSearchParamsDto({
      previous: params,
      form,
    });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "providerId":
          if (getProviderId() !== fnyfsProviderId) return "";
          return `Assigned To: ${
            value === fnyfsProviderId
              ? fnyfsName
              : providers.find((x) => x.id === value)?.name
          }`;
        case "status":
          return `Status: ${referralStatusTextMap[value]}`;
      }
      return "";
    },
    [providers]
  );

  const ordering = ["providerId", "status"];
  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default ReferralFilterList;
