import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import EventLogPage from "./pages/EventLogPage";

export const createEventLogRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.InvoicingEventLog}
    path={formatUrl(baseUrl, "invoicing")}
    component={EventLogPage}
    key="invoicing-event-log"
    exact
  />,
];
