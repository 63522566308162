import { IcmActivitySearchParamsDto } from "./dtos/IcmActivitySearchParamsDto";
import {
  assessmentAgent,
  coreAgent,
  invoicingAgent,
} from "../../app/api/agent";
import { QueryStringHelpers } from "../../app/helpers";
import { NirvanaExportSearchParamsDto } from "./dtos/NirvanaExportSearchParamsDto";
import { MonthlyReportCardSearchParamsDto } from "./dtos/MonthlyReportCardSearchParamsDto";
import { MonthlyDeliverablesReportParamsDto } from "./dtos/MonthlyDeliverablesReportParametersDto";
import { MonthlyCorrectionsReportSearchParamsDto } from "./dtos/MonthlyCorrectionsReportParametersDto";
import { CumulativeDeliverableReportParamsDto } from "./dtos/CumulativeDeliverableReportParametersDto";
import { BedUtilizationReportParamsDto } from "./dtos/BedUtilizationReportParamsDto";
import { ReportFormatEnum } from "./enums/ReportFormatEnum";
import { MonthlyReportCardByProviderSearchParamsDto } from "./dtos/MonthlyReportCardByProviderSearchParamsDto";
import { ScreeningsReportParamsDto } from "./dtos/ScreeningsReportParamsDto";
import {NirvanaAveragesOrImpactReportParameters} from "./dtos/NirvanaAveragesOrImpactReportParameters";
import {SatisfactionSurveyExportParametersDto} from "./dtos/SatisfactionSurveyExportParametersDto";
import {SNAPExportParametersDto} from "./hooks/useSNAPExportReportFilter";

export const getIcmActivityReportRequest = (dto: IcmActivitySearchParamsDto) =>
  coreAgent.getRequest<string>(
    `reports/IcmActivity?${QueryStringHelpers.toQueryString(dto)}`,
    {},
    false
  );

export const downloadIcmActivityReportRequest = ({
  dto,
  filename,
}: {
  dto: IcmActivitySearchParamsDto;
  filename: string;
}) =>
  coreAgent.downloadFileRequest(
    `reports/IcmActivity?${QueryStringHelpers.toQueryString(dto)}`,
    filename,
    false
  );

export const downloadNirvanaExportRequest = ({
  dto,
  filename,
}: {
  dto: NirvanaExportSearchParamsDto;
  filename: string;
}) =>
  assessmentAgent.downloadFileRequest(
    `reports/assessments?${QueryStringHelpers.toQueryString(dto)}`,
    filename
  );

export const getMonthlyReportCardDocumentRequest = (
  params: MonthlyReportCardSearchParamsDto
) =>
  coreAgent.getRequest<Blob>(
    `/reports/reportCard?${QueryStringHelpers.toQueryString(params)}`,
    {
      responseType: "blob",
    },
    false
  );

export const getMonthlyReportCardByProviderDocumentRequest = ({
  filename,
  params,
}: {
  filename: string;
  params: MonthlyReportCardByProviderSearchParamsDto;
}) =>
  coreAgent.downloadFileRequest(
    `/reports/reportCard?${QueryStringHelpers.toQueryString(params)}`,
    filename,
    false
  );

export const getMonthlyDeliverablesReportDocumentRequest = (
  params: MonthlyDeliverablesReportParamsDto
) =>
  invoicingAgent.getRequest<Blob>(
    `/reports/MonthlyDeliverablesReport?${QueryStringHelpers.toQueryString(
      params
    )}`,
    {
      responseType: "blob",
    },
    false
  );

export const getMonthlyDeliverablesPivotReportDocumentRequest = (
  params: MonthlyDeliverablesReportParamsDto
) =>
  invoicingAgent.getRequest<Blob>(
    `/reports/MonthlyDeliverablesPivotReport?${QueryStringHelpers.toQueryString(
      params
    )}`,
    {
      responseType: "blob",
    },
    false
  );

export const getMonthlyCorrectionsReportDocumentRequest = (
  params: MonthlyCorrectionsReportSearchParamsDto
) =>
  invoicingAgent.getRequest<Blob>(
    `/reports/DeliverableCorrectionsReport?${QueryStringHelpers.toQueryString(
      params
    )}`,
    {
      responseType: "blob",
    },
    false
  );

export const getCumulativeDeliverablesReportDocumentRequest = (
  params: CumulativeDeliverableReportParamsDto
) =>
  invoicingAgent.getRequest<Blob>(
    `/reports/BenchmarksReport?${QueryStringHelpers.toQueryString(params)}`,
    {
      responseType: "blob",
    },
    false
  );

export const getCumulativeDeliverablesPivotReportDocumentRequest = (
  params: CumulativeDeliverableReportParamsDto
) =>
  invoicingAgent.getRequest<Blob>(
    `/reports/BenchmarksPivotReport?${QueryStringHelpers.toQueryString(
      params
    )}`,
    {
      responseType: "blob",
    },
    false
  );

interface CaseFollowUpReportParamsDto {
  id: string;
  format: ReportFormatEnum;
}

export const getCaseFollowUpReportDocumentRequest = ({
  id,
  format,
}: CaseFollowUpReportParamsDto) =>
  coreAgent.getRequest<Blob>(
    `/reports/cases/${id}/followup?format=${format}`,
    {
      responseType: "blob",
    },
    false
  );
export const getBlankFollowUpReportDocumentRequest = (
  format: ReportFormatEnum
) =>
  coreAgent.getRequest<Blob>(
    `/reports/followup?format=${format}`,
    {
      responseType: "blob",
    },
    false
  );
export const downloadBedUtilizationReport = ({
  dto,
  filename,
}: {
  dto: BedUtilizationReportParamsDto;
  filename: string;
}) =>
  invoicingAgent.downloadFileRequest(
    `reports/BedUtilizationReport?${QueryStringHelpers.toQueryString(dto)}`,
    filename,
    false
  );
export const downloadScreeningsReport = ({
  dto,
  filename,
}: {
  dto: ScreeningsReportParamsDto;
  filename: string;
}) =>
  coreAgent.downloadFileRequest(
    `reports/Screenings?${QueryStringHelpers.toQueryString(dto)}`,
    filename,
    false
  );

export const getNirvanaAveragesReportDocumentRequest = (
  params: NirvanaAveragesOrImpactReportParameters
) =>
  coreAgent.getRequest<Blob>(
    `/reports/NirvanaAveragesReport?${QueryStringHelpers.toQueryString(
      params
    )}`,
    {
      responseType: "blob",
    },
    false
  );

export const getNirvanaImpactReportDocumentRequest = (
  params: NirvanaAveragesOrImpactReportParameters
) =>
  coreAgent.getRequest<Blob>(
    `/reports/NirvanaImpactReport?${QueryStringHelpers.toQueryString(
      params
    )}`,
    {
      responseType: "blob",
    },
    false
  );

export const getDataExportRequest = (filename: string) =>
  coreAgent.downloadFileRequest(
    `/dataexport`,
    filename,
    true
  );

export const getSatisfactionSurveysExportRequest = ({dto, filename}: {dto: SatisfactionSurveyExportParametersDto, filename: string}) =>
  coreAgent.downloadFileRequest(
    `/satisfactionSurveys/export?${QueryStringHelpers.toQueryString(
      dto
    )}`,
    filename,
    true
  );

export const getSnapExportRequest = ({dto, filename}: {dto: SNAPExportParametersDto, filename: string}) =>
  coreAgent.downloadFileRequest(
    `/snap/export?${QueryStringHelpers.toQueryString(
      dto
    )}`,
    filename,
    true
  );

export const getSnapInSchoolsExportRequest = ({dto, filename}: {dto: SNAPExportParametersDto, filename: string}) =>
  coreAgent.downloadFileRequest(
    `/snapinschools/export?${QueryStringHelpers.toQueryString(
      dto
    )}`,
    filename,
    true
  );

export const primeFunction = () =>
  coreAgent.postRequest('/reports/prime', {}, {}, false);
