import { Box, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  getLocationOptions,
  LocationDto,
} from "../../locations/dtos/LocationDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import * as Yup from "yup";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";
import InfoTooltip from "../../../app/components/InfoTooltip";

export const waitlistSchema = Yup.object().shape({
  locationId: Yup.string().label("Location").required(),
  programId: Yup.string().label("Program").required(),
});

interface Props {
  control: Control<any>;
  isDisabled: boolean;
  providerPrograms: ProgramDto[];
  locations: LocationDto[];
  providerProgramsAreLoading: boolean;
  providerLocationsAreLoading: boolean;
}

const WaitlistFormFields: React.FC<Props> = ({
  control,
  isDisabled,
  providerPrograms,
  locations,
  providerLocationsAreLoading,
  providerProgramsAreLoading,
}) => {
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ProgramControlledSelect
          name="programId"
          label="Program"
          control={control}
          disabled={isDisabled}
          programs={providerPrograms}
          isLoading={providerProgramsAreLoading}
          defaultOptionLabel={"Select Program"}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          name="locationId"
          label="Location"
          control={control}
          disabled={isDisabled}
          options={getLocationOptions(
            locations,
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <div>Select Location</div>{" "}
              <InfoTooltip
                title={
                  <Typography>
                    Only currently active Program/Locations are displayed.
                  </Typography>
                }
              />
            </Box>
          )}
          isLoading={providerLocationsAreLoading}
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export default WaitlistFormFields;
