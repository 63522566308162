import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import ErrorAlert from "../../../app/components/ErrorAlert";
import {Box, Paper, Typography} from "@material-ui/core";
import {useNirvanaExportReportPage} from "../hooks/useSatisfactionSurveyExportPage";
import SatisfactionSurveysExportReportFilter from "../components/SatisfactionSurveysExportReportFilter";


const SatisfactionSurveysExportReportPage = () => {

  const { state, handlers } = useNirvanaExportReportPage();

  const { downloadExportRequestState, params } = state;
  const { handleSearch } = handlers;

  return (
    <div>
    <BackButton route="/reports" style={{ marginTop: "24px" }} />
    <Header title="Satisfaction Surveys Export" />
    <ErrorAlert error={downloadExportRequestState.error?.message} />
    <Box className="my-2">
      <Typography>
        Select any filter criteria and
        "Download" for an Excel report of Satisfaction Surveys.
      </Typography>
    </Box>
    <Paper
      style={{
        padding: "16px",
      }}
    >
      <Box>
        <SatisfactionSurveysExportReportFilter
          onSearch={handleSearch}
          params={params}
          isLoading={downloadExportRequestState.isLoading}
        />
      </Box>
    </Paper>
  </div>
  );
}
export default SatisfactionSurveysExportReportPage;
