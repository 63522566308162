import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditLinkButton from "../../../../app/components/buttons/EditLinkButton";
import TabContainer, {
  TabProps,
} from "../../../../app/components/TabContainer";
import TableLoadingPlaceholder from "../../../../app/components/TableLoadingPlaceholder";
import { fiscalYearOrder, monthTextMap } from "../../../../app/enums/MonthEnum";
import { useTabIndex } from "../../../../app/hooks/useTabIndex";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import ProviderFilledBedDayFormDialog from "../../../provider-filled-bed-days/components/ProviderFilledBedDayFormDialog";
import { useProviderDetailPageContext } from "../../pages/ProviderDetailPage";

const minDate = new Date(2022, 6, 1, 0, 0, 0);

const getCurrentFiscalYear = () => {
  const now = new Date();

  return now.getMonth() >= 6 ? now.getFullYear() : now.getFullYear() - 1;
};

export const providerDcfFilledBedDaysSectionName = "dcf-filled-bed-days";

const FilledBedDayFiscalYearTab = ({ fiscalYear }: { fiscalYear: number }) => {
  const context = useProviderDetailPageContext();
  const {
    filledBedDays,
    selectedMonth,
    selectedFilledBedDays,
    upsertFilledBedDaysRequestState,
    getFilledBedDaysRequestState: { isLoading },
    handleUpsertFilledBedDays,
    handleUpsertFilledBedDaysClicked,
  } = context.filledBedDays;
  const currentFiscalYear = getCurrentFiscalYear();

  const renderListItem = (month: number) => {
    const currentDays = filledBedDays.find(
      (x) => x.month === month && x.fiscalYear === fiscalYear
    );

    const date = new Date(fiscalYear + (month < 6 ? 1 : 0), month, 1, 0, 0, 0);
    const now = new Date();
    const canSet =
      date <= now &&
      date >= minDate &&
      (fiscalYear === currentFiscalYear ||
        fiscalYear + 1 === currentFiscalYear);

    let days: string;

    if (currentDays != null) {
      days = `${currentDays?.filledDays}`;
    } else if (canSet) {
      days = "-";
    } else {
      days = "N/A";
    }

    return (
      <Fragment key={month}>
        <TableRow>
          <TableCell>
            <Typography color={canSet ? "textPrimary" : "textSecondary"}>
              {monthTextMap[month]}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color={canSet ? "textPrimary" : "textSecondary"}>
              {days}
            </Typography>
          </TableCell>
          <TableCell>
            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.FilledBedDays}
            >
              {canSet && (
                <EditLinkButton
                  onClick={() =>
                    handleUpsertFilledBedDaysClicked(
                      month,
                      fiscalYear,
                      currentDays
                    )
                  }
                >
                  {currentDays ? "Edit" : "Add"}
                </EditLinkButton>
              )}
            </WriteAccessLevelWrapper>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <>
      <ProviderFilledBedDayFormDialog
        onSubmit={handleUpsertFilledBedDays}
        month={selectedMonth}
        fiscalYear={fiscalYear}
        dto={selectedFilledBedDays}
        requestState={upsertFilledBedDaysRequestState}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell width="130px">DCF Filled Bed Days</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && fiscalYearOrder.map(renderListItem)}
          {isLoading && (
            <TableLoadingPlaceholder
              columns={3}
              rows={fiscalYearOrder.length}
            />
          )}
        </TableBody>
      </Table>
    </>
  );
};

const ProviderDcfFilledBedDaysSection: React.FC = () => {
  const context = useProviderDetailPageContext();
  const {
    providerFiscalYears,
    getProviderFiscalYearsRequestState: { isLoading },
  } = context.fiscalYears;
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const [index, setIndex] = useTabIndex("");

  useEffect(() => {
    const tabs: TabProps[] = providerFiscalYears
      .sort((a, b) => (a.fiscalYear > b.fiscalYear ? -1 : 1))
      .map((x) => ({
        label: `${x.fiscalYear}-${x.fiscalYear + 1}`,
        value: `${x.fiscalYear}-bed-days`,
        content: <FilledBedDayFiscalYearTab fiscalYear={x.fiscalYear} />,
      }));
    if (tabs.length > 0) {
      setIndex(null!, tabs[0].value);
    }
    setTabs(tabs);
  }, [providerFiscalYears, setTabs, setIndex]);

  return (
    <>
      <TabContainer
        index={index}
        onIndexChange={setIndex}
        tabs={tabs}
        orientation="vertical"
        disabled={isLoading}
      />
    </>
  );
};

export default ProviderDcfFilledBedDaysSection;

export const createProviderDcfFilledBedDaysSection = (): TabProps => ({
  label: "DCF Filled Bed Days",
  value: providerDcfFilledBedDaysSectionName,
  content: <ProviderDcfFilledBedDaysSection />,
});
