import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum ReferralStatusEnum {
  Pending = "Pending",
  ScreeningCreated = "ScreeningCreated",
  OutOfState = "OutOfState",
  Removed = "Removed",
}

export const referralStatusTextMap: { [key: string]: string } = {
  [ReferralStatusEnum.Pending]: "Pending",
  [ReferralStatusEnum.ScreeningCreated]: "Screening Created",
  [ReferralStatusEnum.OutOfState]: "Out of State",
  [ReferralStatusEnum.Removed]: "Removed",
};

export const referralStatusOptions: SelectOption[] = [
  { value: "", label: "All" },
  ...Object.keys(ReferralStatusEnum).map((status) => ({
    value: status,
    label: referralStatusTextMap[status],
  })),
];
