import { Paper, TableContainer } from "@material-ui/core";
import React from "react";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import { YouthSearchParamsDto } from "../dto/YouthSearchParamsDto";
import { YouthSummaryDto } from "../dto/YouthSummaryDto";
import YouthListItem from "./YouthListItem";

const YouthList: React.FC<
  SortableTableProps<YouthSummaryDto, YouthSearchParamsDto>
> = (props) => {
  const { params } = props;

  const columns: ColumnProps[] = [
    { name: "referenceNumber", label: "Youth ID" },
    { name: "firstName", label: "First Name", infoText: params.firstName ? 'Results are sorted by how close they match the first name filter you entered.' : undefined },
    { name: "lastName", label: "Last Name", infoText: params.lastName ? 'Results are sorted by how close they match the last name filter you entered.' : undefined },
    { name: "dob", label: "DOB" },
    { name: "djjIdNumber", label: "DJJ ID" },
  ];

  const renderRow = (youth: YouthSummaryDto) => (
    <YouthListItem youth={youth} key={youth.id} />
  );

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default YouthList;
