import React from "react";
import { dischargeStatusTextMap } from "../../discharges/enums/DischargeStatusEnum";
import { TableValueMetricPanel } from "./TableValueMetricPanel";
import { PieChartValueMetric } from "../dtos/MainDashboardMetricsDto";

function decodeDischargeReasonData(data?: any) {
  if (!data) return;

  return {
    ...data,
    values: data.values.map((v: any) => ({
      ...v,
      title: dischargeStatusTextMap[v.title] || v.title,
    })),
  };
}

interface Props {
  metric?: PieChartValueMetric;
  isLoading: boolean;
}

export const DischargeReasonsMetricPanel: React.FC<Props> = ({
  metric,
  isLoading,
}) => {
  return (
    <TableValueMetricPanel
      metric={decodeDischargeReasonData(metric)}
      isLoading={isLoading}
    />
  );
};
