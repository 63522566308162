import { List, ListItem, ListItemText } from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ScreeningDetailDto } from "../../dtos/ScreeningDetailDto";
import { WaitlistScreeningDto } from "../../../waitlists/dtos/WaitlistScreeningDto";
import WaitlistScreeningListItem from "./WaitlistScreeningListItem";
import ListDivider from "../../../../app/components/ListDivider";

interface Props {
  screening?: ScreeningDetailDto;
  isLoading: boolean;
  onRemoveWaitlistClick(waitlist: WaitlistScreeningDto): void;
  showRemoved?: boolean;
}

const WaitlistScreeningList: React.FC<Props> = ({
  screening,
  isLoading,
  onRemoveWaitlistClick,
  showRemoved,
}) => {
  const waitlists = screening?.waitlists || [];
  const history = useHistory();
  const location = useLocation();

  const filteredWaitlists = () => {
    let filtered = screening?.waitlists || [];

    if (!showRemoved)
      filtered = filtered.filter((x) => x.removedAt === undefined);

    return filtered;
  };

  const handleStartCaseClick = (waitlist: WaitlistScreeningDto) => {
    history.push(
      `/youths/${screening?.youth.id}/cases/new?screeningId=${screening?.id}&programId=${waitlist.program.id}&locationId=${waitlist.location.id}`,
      {
        prevLocation: location.pathname,
        text: "Back to screening",
      }
    );
  };

  return (
    <List>
      {filteredWaitlists().map((waitlist, index) => (
        <Fragment key={waitlist.id}>
          <WaitlistScreeningListItem
            waitlist={waitlist}
            onRemoveWaitlistClick={() => onRemoveWaitlistClick(waitlist)}
            onStartCaseClick={() => handleStartCaseClick(waitlist)}
          />
          <ListDivider index={index} length={waitlists.length} />
        </Fragment>
      ))}
      {waitlists.length === 0 && (
        <ListItem>
          <ListItemText primary="No waitlists created" />
        </ListItem>
      )}
    </List>
  );
};

export default WaitlistScreeningList;
