import { Box, Divider, Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledYesNoRadioGroup from "../../../app/components/inputs/ControlledYesNoRadioGroup";
import { SuicideQuestion } from "../hooks/useSuicideScreeningForm";
import ControlledInput from "../../../app/components/inputs/ControlledInput";

interface QuestionProps {
  question: SuicideQuestion;
  values: { [key: string]: string };
  control: Control<any>;
}

const Question: React.FC<QuestionProps> = ({ question, values, control }) => {
  return (
    <Fragment key={question.name}>
      <Grid item md={12} xs={12}>
        <ControlledYesNoRadioGroup
          disabled={false}
          control={control}
          name={question.name}
          label={question.text}
        />
        {values[question.name] === "true" && question.subQuestion && (
          <Box marginLeft="36px">
            <ControlledYesNoRadioGroup
              disabled={false}
              control={control}
              name={`${question.name}-a`}
              label={question.subQuestion}
            />
          </Box>
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
    </Fragment>
  );
};

interface Props {
  control: Control<any>;
  questions: SuicideQuestion[];
}

export const SuicideScreeningPageFormFields: React.FC<Props> = ({
  control,
  questions,
}) => {
  const values = useWatch({ control });

  return (
    <Fragment>
      <Grid item xs={12} style={{marginTop: '12px'}}>
        <ControlledInput
          control={control}
          name="screeningAt"
          type="datetime-local"
          label="Screening Date/Time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }} />
      </Grid>
      {questions.map((question) => (
        <Question
          question={question}
          control={control}
          values={values}
          key={question.name}
        />
      ))}
    </Fragment>
  );
};
