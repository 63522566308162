import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import React, { Fragment } from "react";
import { FieldError } from "react-hook-form/dist/types/errors";

interface Props {
  errors?: FieldError;
}

const InputErrorText: React.FC<Props> = ({ errors }) => {
  if (errors === undefined) return <Fragment></Fragment>;
  return <FormHelperText error>{errors.message}</FormHelperText>;
};

export default InputErrorText;
