import React, {CSSProperties, Fragment, useState} from "react";
import {Autocomplete} from "@material-ui/lab";
import {StaffMemberDto} from "../dtos/StaffMemberDto";
import {Control, Controller} from "react-hook-form";
import {
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const activeStyle: CSSProperties = {
  fontStyle: "normal",
  fontWeight: "normal"
};

const inActiveStyle: CSSProperties = {
  fontStyle: "italic",
  fontWeight: "bold"
};

interface Props {
  staffMembers: StaffMemberDto[];
  control: Control<any>;
  isLoading: boolean;
  name: string;
  label: string;
  disabled?: boolean;
  value?: string;
  multiple?: boolean;
  size?: "small" | "medium";
}

const renderOption = (dto?: StaffMemberDto | null) => dto == null ? <Typography>Select</Typography> : (
  <Typography
    style={dto.isInactive ? inActiveStyle : activeStyle}
    component="span"
    color={dto.isInactive ? "textSecondary" : "initial"}
  >
    {dto.firstName} {dto.lastName}
  </Typography>
)

export const StaffMemberControlledAutoComplete: React.FC<Props> = ({
  control,
  name,
  label,
  value,
  staffMembers,
  isLoading,
  ...props
}) => {

  const { multiple } = props;

  const getStaff = (id: string) => {
    if(!staffMembers) return null;
    return staffMembers.find(s => s.id === id);
  }

  const getStaffName = (id: string) => {
    if(id === '') return '';

    const staff = getStaff(id)
    if(!staff) return '';
    return `${staff.firstName} ${staff.lastName}`;
  }

  const getStaffGroup = (id: string) => {
    if(id === '') return '';
    const staff = getStaff(id)
    return staff?.isInactive ? "Inactive Staff" : "Active Staff";
  }

  const [includeInactive, setIncludeInactive] = useState(false);

  if(isLoading) return <CircularProgress size={24} variant={"indeterminate"} />

  const options = staffMembers
    .filter(s => includeInactive ? true : !s.isInactive )
    .sort((a, b) => {
      const normalizedA = `${a.isInactive ? 'I' : 'A'}-${`${a.firstName} ${a.lastName}`}`
      const normalizedB = `${b.isInactive ? 'I' : 'A'}-${`${b.firstName} ${b.lastName}`}`

      if(normalizedA === normalizedB) return 0;
      return normalizedA > normalizedB ? 1 : -1;
    }).map(s => s.id)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const staff = getStaff(field.value);

        if(staff && staff.isInactive) {
          setIncludeInactive(true)
        }


        return (
          <Autocomplete
            {...field}
            {...props}
            style={{ flex: 1 }}
            //disableClearable
            options={options}
            onChange={(_, data) => {
              field.onChange(data == null ? '' : data);
              console.log(data)
            }}
            getOptionLabel={option => !option ? '' : getStaffName(option)}
            renderOption={(option, state) => {
              const { selected } = state;
              return (
                <Fragment>
                  {multiple && <Checkbox color="primary" checked={selected} />}
                  {renderOption(getStaff(option))}
                </Fragment>
              );
            }}
            groupBy={option => getStaffGroup(option)}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                disabled={props.disabled}
                name={name}
                label={label}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                InputProps={{
                  ...params.InputProps,
                  style: staff?.isInactive ? inActiveStyle : activeStyle,
                  endAdornment: <>
                    <InputAdornment position={"end"} >
                      <Tooltip title={includeInactive ? "Click to hide inactive staff." : "Click to show inactive staff."}>
                      <IconButton size={"small"} onClick={() => setIncludeInactive(!includeInactive)}>
                        {includeInactive ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.endAdornment}
                  </>,
                }}
              />
            )}
          />
        );
      }}
    />
  )
}
