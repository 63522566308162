import { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useGetFiscalYears } from "../../provider-fiscal-year/hooks/providerFiscalYearHooks";
import { BedUtilizationReportParamsDto } from "../dtos/BedUtilizationReportParamsDto";
import { downloadBedUtilizationReport } from "../ReportRequests";
import { useRequest } from "../../../app/hooks/useRequest";

export const useBedUtilizationReportPage = () => {
  const [getFiscalYears, , getFiscalYearsRequestState] = useGetFiscalYears();
  const [fiscalYears, setFiscalYears] = useState<number[]>([]);
  const [downloadReport, , downloadReportRequestState] = useRequest(
    downloadBedUtilizationReport
  );

  const viewReport = useCallback(
    (dto: BedUtilizationReportParamsDto) => {
      downloadReport({
        dto,
        filename: `BedUtilizationReport-FY${dto.fiscalYear}-${dto.throughMonth}.xlsx`,
      });
    },
    [downloadReport]
  );

  useEffect(() => {
    const load = async () => {
      let result = await getFiscalYears(undefined);
      if (result) {
        const now = DateTime.now();

        // to exclude upcoming fiscal year which gets pre-populated on 6/15.
        result = result.filter((x) => x <= now.year); 

        setFiscalYears(
          result.filter((x) => x !== 0).sort((a, b) => (a > b ? 1 : -1))
        );
      }
    };

    load();
  }, [getFiscalYears, setFiscalYears]);

  return {
    fiscalYears,
    getFiscalYearsRequestState,
    downloadReportRequestState,
    handleSearch: viewReport,
  };
};
