import { useEffect, useState } from "react";
import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { selectHasReadAccess } from "../../authentication/state/authenticationSelectors";
import { deliverableRateFormDialogId } from "../../deliverable-rates/components/DeliverableRateFormDialog";
import { DeliverableRateDto } from "../../deliverable-rates/dtos/DeliverableRateDto";
import { DeliverableRateFormDto } from "../../deliverable-rates/dtos/DeliverableRateFormDto";
import {
  useChangeDeliverableRate,
  useDeleteDeliverableRate,
  useGetDeliverableRates,
} from "../../deliverable-rates/hooks/deliverableRateHooks";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { confirmDeleteDeliverableRateDialogId } from "../components/admin-sections/ConfirmDeleteDeliverableRateDialog";
import { deliverableRatesSectionName } from "../components/admin-sections/deliverable-rates/DeliverableRatesSection";

export const useAdminPage = () => {
  //#region State
  const [
    getDeliverableRates,
    deliverableRates,
    getDeliverableRatesRequestState,
  ] = useGetDeliverableRates();

  const [tabIndex, setTabIndex] = useState(deliverableRatesSectionName);

  const [
    changeDeliverableRate,
    ,
    changeDeliverableRateRequestState,
    clearChangeDeliverableRateError,
  ] = useChangeDeliverableRate();

  const [
    deleteDeliverableRate,
    ,
    deleteDeliverableRateRequestState,
    clearDeleteDeliverableRateError,
  ] = useDeleteDeliverableRate();

  const [selectedDeliverableRate, setSelectedDeliverableRate] =
    useState<DeliverableRateDto>();

  const dispatch = useAppDispatch();
  const canViewDeliverableRate = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.DeliverableRate)
  );
  //#endregion

  //#region Handlers
  const handleChangeTabIndex = (e: React.ChangeEvent<any>, index: string) =>
    setTabIndex(index);

  // Deliverable Rate
  const handleChangeDeliverableRateClicked = (deliverableRateId: string) => {
    const rate = Object.entries(deliverableRates!).flatMap(x => x[1]).find(r => r.id === deliverableRateId);
    setSelectedDeliverableRate(rate);
    clearChangeDeliverableRateError();
    dispatch(openModal({ modalId: deliverableRateFormDialogId }));
  };

  const handleChangeDeliverableRate = async (dto: DeliverableRateFormDto) => {
    await changeDeliverableRate({
      programTypeDeliverableId: selectedDeliverableRate!.programTypeDeliverableId,
      contractId: selectedDeliverableRate!.contract.id,
      dto,
    });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Deliverable Rate Updated" }));
      dispatch(closeModal());
    });
    await getDeliverableRates("");
  };

  const handleDeleteDeliverableRateClicked = (
    deliverableTypeId: string,
    deliverableRateId: string
  ) => {
    const rate = Object.entries(deliverableRates!).flatMap(x => x[1]).find(r => r.id === deliverableRateId);
    setSelectedDeliverableRate(rate);
    clearDeleteDeliverableRateError();
    dispatch(openModal({ modalId: confirmDeleteDeliverableRateDialogId }));
  };

  const handleDeleteDeliverableRate = async () => {
    await deleteDeliverableRate(selectedDeliverableRate!.id);
    batch(() => {
      dispatch(addSuccessNotification({ message: "Deliverable Rate Deleted" }));
      dispatch(closeModal());
    });
    await getDeliverableRates("");
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    if (canViewDeliverableRate) {
      getDeliverableRates("");
    }
  }, [getDeliverableRates, canViewDeliverableRate]);
  //#endregion

  return {
    state: {
      tabIndex,
      // Deliverable Rates
      deliverableRates: deliverableRates || {},
      getDeliverableRatesRequestState,
      changeDeliverableRateRequestState,
      deleteDeliverableRateRequestState,
      selectedDeliverableRate,
      canViewDeliverableRate,
    },
    handlers: {
      handleChangeTabIndex,
      // Deliverable Rates
      handleChangeDeliverableRateClicked,
      handleChangeDeliverableRate,
      // Delete Deliverable Rate
      handleDeleteDeliverableRateClicked,
      handleDeleteDeliverableRate,
    },
  };
};
