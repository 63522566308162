import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { PieChartValueMetric } from "../dtos/MainDashboardMetricsDto";
import { Skeleton } from "@material-ui/lab";
import TableLoadingPlaceholder from "../../../app/components/TableLoadingPlaceholder";

export const TableValueMetricPanel: React.FC<{
  metric?: PieChartValueMetric;
  centerTitle?: boolean;
  isLoading?: boolean;
}> = ({ metric, centerTitle, isLoading }) => {
  const renderContent = () => {
    if (isLoading || !metric)
      return (
        <>
          <Typography variant={"body1"}>
            <Skeleton />
          </Typography>

          <Table>
            <TableBody>
              <TableLoadingPlaceholder columns={2} rows={2} />
            </TableBody>
          </Table>
        </>
      );

    return (
      <>
        <Typography variant={"body1"}>{metric.title}</Typography>

        {metric.values.length > 0 && (
          <Table>
            <TableBody>
              {metric.values.map((v) => (
                <TableRow key={v.title}>
                  <TableCell>{v.title}</TableCell>
                  <TableCell>{v.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {metric.values.length === 0 && (
          <Box
            display={"flex"}
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography>No data available</Typography>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box
      padding="16px"
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      alignItems={centerTitle ? "center" : "left"}
      width={300}
    >
      {renderContent()}
    </Box>
  );
};
