import {useState} from "react";
import {SNAPExportParametersDto} from "./useSNAPExportReportFilter";
import {useRequest} from "../../../app/hooks/useRequest";
import {getSnapExportRequest, getSnapInSchoolsExportRequest} from "../ReportRequests";
import {useCombinedRequests} from "../../../app/hooks/useCombinedRequests";
import {ProgramTypeEnum} from "../../programs/enums/ProgramTypeEnum";

export const useSNAPExportReportPage = () => {
  const [downloadSnapExport, , downloadSnapExportRequestState,] = useRequest(getSnapExportRequest);
  const [downloadSISExport, , downloadSISExportRequestState,] = useRequest(getSnapInSchoolsExportRequest);

  const downloadExportRequestState = useCombinedRequests([downloadSnapExportRequestState, downloadSISExportRequestState]);

  const [params, setParams] = useState<SNAPExportParametersDto>(
    new SNAPExportParametersDto()
  );

  const handleSearch = async ({dto, programType}: { dto: SNAPExportParametersDto, programType: ProgramTypeEnum }) => {
    setParams(dto);

    const dateString = new Date()
      .toLocaleDateString()
      .replaceAll("/", "_");

    const fileNamePrefix = programType === ProgramTypeEnum.Snap || programType === ProgramTypeEnum.SnapYouthJustice ? 'SNAP' : 'SNAP_In_Schools';

    const filename = `${fileNamePrefix}_Export_${dateString}.csv`

    if(programType === ProgramTypeEnum.SnapInSchools) {
      await downloadSISExport({dto, filename});
    } else {
      await downloadSnapExport({dto, filename});
    }
  };

  return {
    state: {
      downloadExportRequestState,
      params
    },
    handlers: {
      handleSearch
    }
  }
};
