import { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import { ProgramLocationDto } from "../../locations/dtos/ProgramLocationDto";
import { ProgramTypeDto } from "../../program-types/dtos/ProgramTypeDto";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { ProgramFundingSourceEnum } from "../enums/ProgramFundingSourceEnum";

export interface ProgramDto {
  id: string;
  name: string;
  providerId: string;
  provider: ProviderDto;
  programType: ProgramTypeDto;
  fundingSource: ProgramFundingSourceEnum;
  locations: ProgramLocationDto[];
  contractId?: string;
  contractName?: string;
}

export const programToOption = (dto: ProgramDto): SelectOption => ({
  value: dto.id,
  label: dto.name,
});
export const getProgramOptions = (dtos: ProgramDto[]): SelectOption[] => {
  return dtos.map(programToOption);
};
