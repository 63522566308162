export class PaginationResultDto<T> {
  items: T[];
  count: number;
  page: number;
  pageSize: number;

  constructor() {
    this.items = [];
    this.page = 0;
    this.pageSize = 10;
    this.count = 0;
  }
}
