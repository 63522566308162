import { Avatar, Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { createNumberRange } from "../../helpers";
import Section from "../Section";

interface Props {
  lines?: number;
  icon?: React.ReactNode;
}

const LoadingDetail: React.FC<Props> = ({ lines = 3, icon }) => {
  return (
    <Section>
      <Box display="flex" alignItems={"center"}>
        {icon && <Avatar style={{ marginRight: "8px" }}>{icon}</Avatar>}
        <div>
          <Typography variant="h6">
            <Skeleton variant="text" animation="pulse" width="300px" />
          </Typography>

          {createNumberRange(lines).map((i) => (
            <Skeleton
              variant="text"
              animation="pulse"
              width={`${180 - i * 20}px`}
              key={`loading-line-${i}`}
            />
          ))}
        </div>
      </Box>
    </Section>
  );
};

export default LoadingDetail;
