import { Button, ButtonProps } from "@material-ui/core";
import React, { useState } from "react";

interface Props extends ButtonProps {}

const WhiteButton = ({ children, ...props }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => setIsHovered(true);
  const handleUnhover = () => setIsHovered(false);

  return (
    <Button
      variant="outlined"
      style={{ backgroundColor: isHovered ? "#ddd" : "#fff" }}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      {...props}
    >
      {children}
    </Button>
  );
};

export default WhiteButton;
