import { Box, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import RedAlertLabel from "../../../../app/components/labels/RedAlertLabel";

interface Props {
  isDeleted: boolean;
  reason?: string;
}

const YouthDeletedLabel: React.FC<Props> = ({ isDeleted, reason }) => {
  const label = (
    <RedAlertLabel hide={!isDeleted} variant="outlined" label="Deleted" />
  );
  if (reason)
    return (
      <Tooltip title={<Typography>{reason}</Typography>}>
        <Box style={{ cursor: "pointer" }}>{label}</Box>
      </Tooltip>
    );

  return label;
};

export default YouthDeletedLabel;
