import { useRequest } from "../../../app/hooks/useRequest";
import {
  addCasesToSnapGroupRequest,
  createSnapGroupCycleRequest,
  createSnapGroupRequest,
  getSnapGroupCycleRequest,
  getSnapGroupCyclesRequest,
  getSnapProgramTypesRequest,
  getSnapGroupRequest,
  upsertSnapGroupFidelityRequest,
  getSnapCurriculumRequest,
  removeCaseFromSnapGroupRequest,
  removeSnapGroupCycleRequest,
  removeSnapGroupRequest,
  updateSnapGroupCycleRequest,
  updateSnapGroupRequest,
  getSnapGroupCycleAlertsRequest,
  setMadeCompliancePhoneCallRequest,
} from "../SnapRequests";

export const useGetSnapGroupCycles = () =>
  useRequest(getSnapGroupCyclesRequest);

export const useGetSnapGroupCycle = () => useRequest(getSnapGroupCycleRequest);

export const useCreateSnapGroupCycle = () =>
  useRequest(createSnapGroupCycleRequest);

export const useUpdateSnapGroupCycle = () =>
  useRequest(updateSnapGroupCycleRequest);

export const useCreateSnapGroup = () => useRequest(createSnapGroupRequest);

export const useUpdateSnapGroup = () => useRequest(updateSnapGroupRequest);

export const useGetSnapGroup = () => useRequest(getSnapGroupRequest);

export const useAddCasesToSnapGroup = () =>
  useRequest(addCasesToSnapGroupRequest);

export const useGetSnapProgramTypes = () =>
  useRequest(getSnapProgramTypesRequest);

export const useUpsertSnapGroupFidelity = () =>
  useRequest(upsertSnapGroupFidelityRequest);

export const useGetSnapCurriculum = () => useRequest(getSnapCurriculumRequest);

export const useDeleteSnapGroupCase = () =>
  useRequest(removeCaseFromSnapGroupRequest);

export const useDeleteSnapGroupCycle = () =>
  useRequest(removeSnapGroupCycleRequest);

export const useDeleteSnapGroup = () => useRequest(removeSnapGroupRequest);

export const useGetSnapGroupCycleAlerts = () =>
  useRequest(getSnapGroupCycleAlertsRequest);

export const useSeetMadeCompliancePhoneCall = () =>
  useRequest(setMadeCompliancePhoneCallRequest);
