import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { SnapInSchoolsSatisfactionSurveyDto } from "../SnapInSchoolsSatisfactionSurveyDto";
import DateLabel from "../../../app/components/labels/DateLabel";
interface Props {
  requestState: RequestStateDto;
  survey?: SnapInSchoolsSatisfactionSurveyDto;
  onConfirm(): Promise<void>;
}

export const confirmDeleteSnapInSchoolsSatisfactionSurveyDialogId =
  "delete-snap-in-schools-survey-dialog";
const ConfirmDeleteSnapInSchoolsSatisfactionSurveyDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  survey,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteSnapInSchoolsSatisfactionSurveyDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Satisfaction Survey"
      maxWidth="md"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to delete the Satisfaction Survey completed on{" "}
        <DateLabel date={survey?.completedOn} /> for{" "}
        {survey?.youthNameOrInitials}?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteSnapInSchoolsSatisfactionSurveyDialog;
