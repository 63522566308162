import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { trimPhoneNumber } from "../../../app/helpers";
import {
  HeardAboutEnum,
  heardAboutTextMap,
} from "../../screenings/enums/HeardAboutEnum";
import { referralSchema } from "../components/ReferralFormFields";
import {
  createReferralFormDto,
  getRequestedServicesOptions,
  ReferralFormDto,
} from "../dtos/ReferralFormDto";
import { ReferralServiceRequestedEnum } from "../enums/ReferralServiceRequestedEnum";
import { ReferralSourceEnum } from "../enums/ReferralSourceEnum";
import { ReferrerRelationshipEnum } from "../enums/ReferrerRelationshipEnum";
import { useCreateReferral } from "./referralHooks";

export const useReferralFormPage = () => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: createReferralFormDto(),
    resolver: yupResolver(referralSchema),
    mode: "all",
  });
  const [createReferral, referral, createReferralRequestState] =
    useCreateReferral();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>();
  const {
    referredBy,
    isReferralSelf,
    isReferralFamily,
    requestedServicesOptions,
    youthCity,
    youthZipCode,
  } = watch();

  const dontKnowSelected =
    requestedServicesOptions[ReferralServiceRequestedEnum.DontKnow];

  //#endregion
  //#region Handlers
  const getRequestedServices = () => {
    return Object.entries(requestedServicesOptions)
      .filter(([, value]) => value)
      .map(([key]) => key);
  };

  const handleOnSubmit = handleSubmit(async (dto: ReferralFormDto) => {
    if (dto.youthDob === "") dto.youthDob = undefined;
    if (dto.heardAboutUsOption !== HeardAboutEnum.Other)
      dto.heardAboutUs = heardAboutTextMap[dto.heardAboutUsOption];

    dto.referredBy.phoneNumber = trimPhoneNumber(dto.referredBy.phoneNumber)!;
    dto.requestedServices = getRequestedServices();
    await createReferral({ dto, recaptchaToken: recaptchaToken! });
    reset(createReferralFormDto());
  });

  const handleCaptchaChanged = (token: string | null) => {
    setRecaptchaToken(token);
  };
  //#endregion

  useEffect(() => {
    if (isReferralSelf === "true") {
      setValue("youthFirstName", referredBy.firstName);
      setValue("youthLastName", referredBy.lastName);
      setValue("referredBy.relationship", ReferrerRelationshipEnum.Self);
    } else if (isReferralSelf === "false") {
      setValue("youthFirstName", "");
      setValue("youthLastName", "");
      setValue("referredBy.relationship", "");
      setValue("isReferralFamily", isReferralFamily);
    }
  }, [isReferralSelf, referredBy.firstName, referredBy.lastName, isReferralFamily, referredBy.source, setValue]);

  useEffect(() => {
    if (isReferralSelf === "true") {
      setValue("referredBy.source", ReferralSourceEnum.Self);
    }
    else {
      setValue("referredBy.source", "");
    }
  }, [setValue, isReferralSelf])

  useEffect(() => {
    if (isReferralFamily === "true") {
      setValue("referredBy.source", ReferralSourceEnum.Family);
    } else if (isReferralFamily === "false") {
      setValue("referredBy.source", referredBy.source === ReferralSourceEnum.Family ? "" : referredBy.source);
    }
  }, [setValue, isReferralFamily, referredBy.source ]);

  useEffect(() => {
    switch (referredBy.source) {
      case ReferralSourceEnum.Dcf:
      case ReferralSourceEnum.Djj:
      case ReferralSourceEnum.LawEnforcement:
      case ReferralSourceEnum.Court:
        setValue("heardAboutUsOption", HeardAboutEnum.ReferralSource);
        break;
    }
  }, [referredBy.source, setValue]);

  useEffect(() => {
    if (
      dontKnowSelected &&
      Object.values(requestedServicesOptions).filter((x) => x).length > 1
    ) {
      const options = getRequestedServicesOptions();
      options[ReferralServiceRequestedEnum.DontKnow] = true;
      setValue("requestedServicesOptions", options);
    }
  }, [setValue, requestedServicesOptions, dontKnowSelected]);

  useEffect(() => {
    setValue("youthCity", youthCity, { shouldValidate: true });
    setValue("youthZipCode", youthZipCode, { shouldValidate: true });
  }, [youthCity, youthZipCode, setValue])

  return {
    state: {
      referral,
      createReferralRequestState,
      isReferralSelf,
    },
    form: {
      control,
      isValid: isValid && recaptchaToken != null,
    },
    handlers: { handleSubmit: handleOnSubmit, handleCaptchaChanged },
  };
};
