export interface DeliverableRateFormDto {
  month: string;
  year: string;
  startsOn?: Date;
  providerAmount: number;
  fnyfsAmount: number;
}

export const createDeliverableRateFormDto = (): DeliverableRateFormDto => {
  return {
    month: "",
    year: "",
    providerAmount: 0,
    fnyfsAmount: 0,
  };
};
