import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";

export class CaseContactSearchParamsDto implements PaginationSearchParamsDto {
  page?: number;
  pageSize?: number;
  sortBy: string;
  sortDirection: SortDirectionEnum;

  constructor({
    query,
    previous,
  }: {
    query?: ParsedQuery<string>;
    previous?: CaseContactSearchParamsDto;
  }) {
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
    this.sortBy = previous?.sortBy || (query?.sortBy as string) || "contactOn";
    this.sortDirection =
      previous?.sortDirection || SortDirectionEnum.Descending;
  }

  public static toQueryString(dto: CaseContactSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
