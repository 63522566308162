import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { useAppDispatch } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { useGetProgramTypes } from "../../program-types/hooks/programTypeHooks";
import {
  useGetProviderLocations,
  useGetProviderPrograms,
  useGetProviders,
} from "../../provider/hooks/providerHooks";
import { removeWaitlistModalId } from "../components/RemoveWaitlistFormDialog";
import { RemoveWaitlistFormDto } from "../dtos/RemoveWaitlistFormDto";
import { WaitlistDto } from "../dtos/WaitlistDto";
import { WaitlistSearchParamsDto } from "../dtos/WaitlistSearchParamsDto";
import { useGetWaitlists, useRemoveWaitlist } from "./waitlistHooks";

export const useWaitlistPage = () => {
  //#region
  const history = useHistory();
  const query = useQuery();
  const [getWaitlists, waitlistResults, getWaitlistRequestState] =
    useGetWaitlists();
  const [params, setParams] = useState<WaitlistSearchParamsDto>(
    new WaitlistSearchParamsDto({ query })
  );
  const [selectedWaitlist, setSelectedWaitlist] = useState<WaitlistDto>();
  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [getProviders, providers, getProvidersRequestState] = useGetProviders();
  const [getProgramTypes, programTypes, getProgramTypesRequestState] =
    useGetProgramTypes();

  const [getPrograms, programs, getProgramsRequestState] =
    useGetProviderPrograms();

  const [getLocations, locations, getLocationsRequestState] =
    useGetProviderLocations();

  const [
    removeWaitlist,
    ,
    removeWaitlistRequestState,
    clearRemoveWaitlistErrors,
  ] = useRemoveWaitlist();

  const dispatch = useAppDispatch();
  //#endregion

  //#region Handlers
  const handlePageChange = useCallback(
    (page: number) => {
      setParams((params) => ({ ...params, page }));
    },
    [setParams]
  );

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    history.push(`/waitlists?${QueryStringHelpers.toQueryString(newParams)}`);
    setParams(newParams);
  };

  const handleOpenFilterClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setFilterAnchorEl(event.currentTarget);
    },
    [setFilterAnchorEl]
  );

  const handleCloseFilter = useCallback(
    () => setFilterAnchorEl(null),
    [setFilterAnchorEl]
  );

  const handleSearch = useCallback(
    (dto: WaitlistSearchParamsDto) => {
      setParams(dto);
    },
    [setParams]
  );

  const handleRemoveWaitlistClicked = (waitlist: WaitlistDto) => {
    clearRemoveWaitlistErrors();
    setSelectedWaitlist(waitlist);
    dispatch(openModal({ modalId: removeWaitlistModalId }));
  };

  const handleRemoveWaitlist = async (dto: RemoveWaitlistFormDto) => {
    await removeWaitlist({ id: selectedWaitlist!.id, dto });
    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({ message: "Youth Removed from Waitlist" })
      );
    });
    getWaitlists(params);
  };

  //#endregion

  //#region Effects
  useEffect(() => {
    getWaitlists(params);
  }, [params, getWaitlists]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId === fnyfsProviderId) {
      getProviders({ pageSize: 1000 });
    }
  }, [getProviders]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId === fnyfsProviderId) {
      getProgramTypes("");
    }
  }, [getProgramTypes]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId !== fnyfsProviderId) {
      getPrograms(providerId);
    }
  }, [getPrograms]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId !== fnyfsProviderId) {
      getLocations(providerId);
    }
  }, [getLocations]);

  useEffect(() => {
    history.push(`/waitlists?${WaitlistSearchParamsDto.toQueryString(params)}`);
  }, [params, history]);
  //#endregion

  return {
    state: {
      params,
      waitlistResult: waitlistResults,
      providers: providers?.items || [],
      programs: programs || [],
      programTypes: programTypes || [],
      locations: locations || [],
      getWaitlistRequestState,
      getProvidersRequestState,
      getProgramsRequestState,
      getProgramTypesRequestState,
      getLocationsRequestState,
      filterAnchorEl,
      selectedWaitlist,
      removeWaitlistRequestState,
    },
    handlers: {
      handlePageChange,
      handleSort,
      handleOpenFilterClicked,
      handleCloseFilter,
      handleSearch,
      // Remove Waitlist
      handleRemoveWaitlist,
      handleRemoveWaitlistClicked,
    },
  };
};
