import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import ErrorText from "../../app/components/ErrorText";
import SpinnerButton from "../../app/components/buttons/SpinnerButton";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectModalId} from "../../features/modal/state/modalSelectors";
import {closeModal} from "../../features/modal/state/modalSlice";
import {useServicePlanParticipantForm} from "../hooks/useServicePlanParticipantForm";
import { ServicePlanParticipantDto } from "../dto/ServicePlanDto";
import { ServicePlanParticipantFormDialogFields } from "./ServicePlanParticipantFormDialogFields";

export const servicePlanParticipantFormDialogId = 'service-plan-participant-dialog';

interface Props {
  dto: ServicePlanParticipantDto | undefined
}

export const ServicePlanParticipantFormDialog: React.FC<Props> = ({
  dto
}) => {
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const {
    state: {
      addParticipantRequestState,
      roleOptions
    },
    form: {
      isValid,
      clearForm,
      control
    },
    handlers: {
      handleCreateSubmit,
      handleUpdateSubmit
    }
  } = useServicePlanParticipantForm(dto);

  const { isLoading, error } = addParticipantRequestState;

  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  return ( <Dialog open={modalId === servicePlanParticipantFormDialogId} maxWidth="sm" fullWidth>
    <form onSubmit={dto ? handleUpdateSubmit : handleCreateSubmit}>
      <DialogTitle>
        {dto ? 'Edit' : 'Add'} a Service Plan Participant
        <ErrorText error={error?.message} />
      </DialogTitle>
      <DialogContent>

        <ServicePlanParticipantFormDialogFields
          control={control}
          roleOptions={roleOptions}
        />

      </DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Cancel"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
          disabled={isLoading}
        />
        <SpinnerButton
          showSpinner={false}
          content="Save"
          color="primary"
          type="submit"
          disabled={!isValid || isLoading}
        />
      </DialogActions>
    </form>
  </Dialog>)

}
