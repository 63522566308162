import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { SnapInSchoolsSearchParamsDto } from "./SnapInSchoolsSearchParamsDto";

export class SnapInSchoolsCycleFilterFormDto {
  providerId: string;
  grade: string;  
  cycleStartDateRangeStart: string;
  cycleStartDateRangeEnd: string;  

  constructor(dto?: SnapInSchoolsSearchParamsDto) {
    const currentProviderId = getProviderId();
    this.providerId = dto?.providerId || "";
    if (
      currentProviderId != null &&
      currentProviderId !== "" &&
      currentProviderId !== fnyfsProviderId
    )
      this.providerId = currentProviderId;
      
    this.grade = dto?.grade || "";
    this.cycleStartDateRangeStart = dto?.cycleStartDateRangeStart || "";
    this.cycleStartDateRangeEnd = dto?.cycleStartDateRangeEnd|| "";
  }
}
