import { coreAgent } from "../../app/api/agent";
import { BakerActDto, BakerActFormDto } from "./dto/BakerActDto";

export const createBakerActRequest = ({
  dto,
  caseId,
}: {
  caseId: string;
  dto: BakerActFormDto;
}) => coreAgent.postRequest<BakerActDto>(`cases/${caseId}/bakerAct`, dto);

export const updateBakerActRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: BakerActFormDto;
}) => coreAgent.putRequest<BakerActDto>(`bakerActs/${id}`, dto);

export const deleteBakerActRequest = (id: string) =>
  coreAgent.deleteRequest(`bakerActs/${id}`);

export const getCaseBakerActsRequest = (id: string) =>
  coreAgent.getRequest<BakerActDto[]>(`cases/${id}/bakerAct`);
