import {EventLogSearchParamsDto} from "./EventLogSearchParamsDto";
import {dateToFormString} from "../../../app/helpers";

export class EventLogFilterFormDto {

  providerId: string;
  referenceType: string;
  affectedDeliverables: string;
  startDate: string;
  endDate: string;

  constructor(dto?: EventLogSearchParamsDto) {
    this.providerId = dto?.providerId || "";
    this.referenceType = dto?.referenceType || '';
    this.startDate = dto?.startDate ? dateToFormString(dto?.startDate) : "";
    this.endDate = dto?.endDate ? dateToFormString(dto?.endDate) : "";
    this.affectedDeliverables = dto?.affectedDeliverables || '';
  }
}
