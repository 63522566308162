import { Box, IconButton, Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { Fragment, useEffect, useState } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ColorsEnum } from "../../enums/ColorsEnum";
import { areDatesEqual, getMonthDays, toDate } from "../../helpers";

const styles = (
  <style>
    {`#calendar table td {
  text-align: center;
  width: 14.27%;
  height: 20%;
  border: 1px solid ${ColorsEnum.Border};
  padding: 16px;
}

#calendar table th {
  text-align: center;
  background-color: #134b7c;
  color: white;
  padding: 12px;
}

#calendar .disabled {
  cursor: not-allowed;
  background-color: #9d9d9d;
}

#calendar .selected {
  color: white;
  background-color: black;
}

#calendar {
  border: 1px solid ${ColorsEnum.Border};
  border-collapse: collapse;
}
#calendar table {
  border-collapse: collapse;
  width: 100%;
}

#calendar table #today {
  color: ${ColorsEnum.InfoBlue};
  font-weight: bolder;
}

#calendar table #today span {
  border: 2px solid ${ColorsEnum.InfoBlue};
  border-radius: 50%;
  padding: 6px;
}

.inactive-date {
  background-color: #c0c0c0;
}

.active-date:hover {
  cursor: pointer;
  background-color: #cdcdcd;
}
`}
  </style>
);

const normalizeDays = (days: DateTime[]) => {
  const startingWeekNumber = days[0].weekNumber % 52;
  const formatted = days.reduce<DateTime[][]>(
    (acc, x) => {
      const day = x.weekday % 7;
      let week = (x.weekNumber - startingWeekNumber) % 52;
      if (day === 0) week += 1;
      acc[week][day] = x;
      return acc;
    },
    [...Array(6).fill(null)].map((_) => Array(7).fill(null))
  );

  return formatted.filter((week) => week.some((x) => x != null));
};

const renderWeekday = (day: string) => (
  <th key={day}>
    <Typography component="span">{day}</Typography>
  </th>
);

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
  renderWeekday
);

interface Props {
  earliestDate?: DateTime;
  latestDate?: DateTime;
  selectedDates: DateTime[];
  onDateClicked(date: DateTime): void;
}

const MultiDateCalendarSelect: React.FC<Props> = ({
  earliestDate,
  latestDate,
  selectedDates,
  onDateClicked,
}) => {
  //#region State
  const [currentDate, setCurrentDate] = useState(toDate(new Date()));
  const today = toDate(new Date());
  const [days, setDays] = useState<DateTime[][]>(
    [...Array(0).fill(null)].map((_) => Array(7).fill(null))
  );
  const [isInitialized, setIsInitialized] = useState(false);
  //#endregion

  //#region UI Helpers

  const renderDay = (date?: DateTime, index: number = 0) => {
    const isToday = date != null && areDatesEqual(date, today);

    const isDisabled =
      date != null &&
      ((earliestDate != null && date < earliestDate) ||
        (latestDate != null && date > latestDate));

    const classNames: string[] = [];

    if (date == null) classNames.push("inactive-date");
    else classNames.push("active-date");

    if (isDisabled) classNames.push("disabled");

    if (date != null && selectedDates.find((x) => areDatesEqual(x, date)))
      classNames.push("selected");

    const action =
      isDisabled || date == null
        ? undefined
        : () => {
            onDateClicked(date);
          };

    return (
      <td
        id={isToday ? "today" : ""}
        className={classNames.join(" ")}
        key={date?.day || `placeholder-${index}`}
        onClick={action}
      >
        <Typography component="span" style={{ fontWeight: "bold" }}>
          {date?.day}
        </Typography>
      </td>
    );
  };

  const renderWeek = (dates: DateTime[], index: number) => (
    <tr key={`week-${index}`}>{dates.map(renderDay)}</tr>
  );

  //#endregion

  //#region Handlers
  const handleIncrementMonth = () =>
    setCurrentDate((date) => date.plus({ month: 1 }));

  const handleDecrementMonth = () =>
    setCurrentDate((date) => date.plus({ month: -1 }));
  //#endregion

  //#region Effects
  useEffect(() => {
    const rawDays = getMonthDays(currentDate);
    const normalizedDays = normalizeDays(rawDays);
    setDays(normalizedDays);
  }, [currentDate, setDays]);

  useEffect(() => {
    if (
      latestDate &&
      !isInitialized &&
      latestDate.month < today.month &&
      latestDate.year <= today.year
    ) {
      setCurrentDate(toDate(latestDate.toJSDate()));
      setIsInitialized(true);
      console.log("Running");
    }
  }, [setCurrentDate, latestDate, today, isInitialized, setIsInitialized]);
  //#endregion

  return (
    <Fragment>
      {styles}
      <Box id="calendar">
        <Box
          component="span"
          marginBottom="0px"
          display="flex"
          justifyContent="space-between"
          bgcolor={ColorsEnum.DarkBlue}
          color="white"
          padding="4px"
          alignItems="center"
        >
          <IconButton color="inherit" onClick={handleDecrementMonth}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography style={{ fontWeight: "bold" }}>
            {currentDate.monthLong} {currentDate.year}
          </Typography>
          <IconButton color="inherit" onClick={handleIncrementMonth}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <table>
          <thead>
            <tr>{weekdays}</tr>
          </thead>
          <tbody>{days.map(renderWeek)}</tbody>
        </table>
      </Box>
    </Fragment>
  );
};

export default MultiDateCalendarSelect;
