import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectModalId } from "../../features/modal/state/modalSelectors";
import { useAddServicePlanInterventionForm } from "../hooks/useAddServicePlanInterventionForm";
import { closeModal } from "../../features/modal/state/modalSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import ErrorText from "../../app/components/ErrorText";
import ControlledInput from "../../app/components/inputs/ControlledInput";
import SpinnerButton from "../../app/components/buttons/SpinnerButton";

interface Props {
  goalId: string;
}

export const addServicePlanInterventionFormDialogId =
  "add-service-plan-intervention-dialog";

export const AddServicePlanInterventionFormDialog: React.FC<Props> = ({
  goalId,
}) => {
  const selectedModalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const {
    state: { goal, addInterventionRequestState },
    form: { isValid, clearForm, control },
    handlers: { handleSubmit },
  } = useAddServicePlanInterventionForm(goalId);

  const { isLoading, error } = addInterventionRequestState;

  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  return (
    <Dialog
      open={addServicePlanInterventionFormDialogId === selectedModalId}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Add Intervention to Goal: {goal?.name}
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="description"
                label="Description*"
                type="text"
                control={control}
                disabled={isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={"See Note Below"}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant={"body2"}>
                The Description should include the type of Intervention (e.g.,
                "mentorship"), frequency (e.g., "# per week for # weeks") and
                location (e.g., "at school").
              </Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <ControlledInput
                name="notes"
                label="Notes"
                control={control}
                disabled={isLoading}
                fullWidth
                multiline
                minRows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
