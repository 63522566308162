import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { useAppDispatch } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { youthModalId } from "../components/YouthFormDialog";
import { YouthFormDto } from "../dto/YouthFormDto";
import { YouthSearchParamsDto } from "../dto/YouthSearchParamsDto";
import { useCreateYouth, useGetYouths } from "./youthHooks";

export const useYouthListPage = () => {
  //#region State
  const query = useQuery();
  const [params, setParams] = useState<YouthSearchParamsDto>(
    new YouthSearchParamsDto({query})
  );
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [getYouths, youthResults, getYouthsRequestState] = useGetYouths();
  const [createYouth, , createYouthRequestState, clearCreateYouthError] =
    useCreateYouth();
  //#endregion

  //#region Handlers
  const handleCreateYouthClicked = useCallback(() => {
    clearCreateYouthError();
    dispatch(openModal({ modalId: youthModalId }));
  }, [dispatch, clearCreateYouthError]);

  const handlePageChange = useCallback(
    (page: number) => {
      setParams((params) => ({ ...params, page }));
    },
    [setParams]
  );

  const handleSearch = useCallback(
    (dto: YouthSearchParamsDto) => {
      const sortBy = dto.firstName ? 'firstName' : dto.lastName ? 'lastName' : params.sortBy;
      const newParams = { ...params, ...dto, sortBy };
      history.push(`/youths?${YouthSearchParamsDto.toQueryString(newParams)}`);
      setParams(newParams);
    },
    [history, setParams, params]
  );

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    history.push(`/youths?${YouthSearchParamsDto.toQueryString(newParams)}`);
    setParams(newParams);
  };

  const handleCreateYouth = useCallback(
    async (dto: YouthFormDto) => {
      const result = await createYouth(dto);
      if (result) {
        batch(async () => {
          try {
            dispatch(addSuccessNotification({ message: "Youth Created" }));
            dispatch(closeModal());
            history.push(`/youths/${result.id}`);
          } catch (error: any) {
            throw error;
          }
        });
      }
    },
    [dispatch, createYouth, history]
  );
  const handleOpenFilterClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setFilterAnchorEl(event.currentTarget);
    },
    [setFilterAnchorEl]
  );

  const handleCloseFilter = useCallback(
    () => setFilterAnchorEl(null),
    [setFilterAnchorEl]
  );

  //#endregion

  //#region Effects
  useEffect(() => {
    getYouths(params);
  }, [params, getYouths]);

  useEffect(() => {
    history.push(`/youths?${YouthSearchParamsDto.toQueryString(params)}`);
  }, [params, history]);
  //#endregion

  return {
    state: {
      params,
      youthResults,
      createYouthRequestState,
      getYouthsRequestState,
      filterAnchorEl,
    },
    handlers: {
      handleCreateYouth,
      handleCreateYouthClicked,
      handleCloseFilter,
      handleOpenFilterClicked,
      handleSearch,
      handlePageChange,
      handleSort,
    },
  };
};
