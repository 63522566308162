import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";
import { TextFieldProps } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface Props {
  control: Control<any>;
  name: string;
}

const ControlledPasswordInput: React.FC<Props & TextFieldProps> = ({
  control,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () =>
    setShowPassword((showPassword) => !showPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { name } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          variant="outlined"
          fullWidth
          {...field}
          autoComplete="off"
          {...props}
          type={showPassword ? "text" : "password"}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default ControlledPasswordInput;
