import { CaseDetailDto } from "../../dtos/CaseDetailDto";
import React from "react";
import { TabProps } from "../../../../app/components/TabContainer";
import { Box, Typography } from "@material-ui/core";
import EditButton from "../../../../app/components/buttons/EditButton";
import { openModal } from "../../../modal/state/modalSlice";
import { useAppDispatch } from "../../../../app/hooks";
import {
  ServicePlanFormDialog,
  servicePlanFormDialogId,
} from "../../../../service-planning/components/ServicePlanFormDialog";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { ServicePlanPage } from "../../../../service-planning/components/ServicePlanPage";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import {ServicePlanDto} from "../../../../service-planning/dto/ServicePlanDto";

interface Props {
  caseDto: CaseDetailDto;
  servicePlan?: ServicePlanDto;
  requestState: RequestStateDto;
  onChangeServicePlanData(): Promise<void>;
}

export const servicePlanSectionName = "service-plan-section";

const ServicePlanSection: React.FC<Props> = ({
  caseDto,
  servicePlan,
  requestState,
  onChangeServicePlanData,
}) => {
  const dispatch = useAppDispatch();

  const createServicePlanClicked = () => {
    dispatch(openModal({ modalId: servicePlanFormDialogId }));
  };

  return (
    <>
      <ServicePlanFormDialog
        caseDto={caseDto}
        servicePlan={servicePlan}
        onSubmit={onChangeServicePlanData}
      />
      {!servicePlan && (
        <div>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography>No Service Plan Created</Typography>
            {!caseDto?.dischargeInfo?.dischargedAt && (
              <WriteAccessLevelWrapper
                name={PermissionResourceNameEnum.ServicePlan}
              >
                <EditButton
                  text={"Start Service Plan"}
                  onClick={createServicePlanClicked}
                />
              </WriteAccessLevelWrapper>
            )}
          </Box>
        </div>
      )}
      {servicePlan && (
        <ServicePlanPage
          onPlanUpdated={onChangeServicePlanData}
          caseDto={caseDto}
          servicePlan={servicePlan}
          requestState={requestState}
        />
      )}
    </>
  );
};

export default ServicePlanSection;

export const createServicePlanSection = (
  caseDto: CaseDetailDto,
  requestState: RequestStateDto,
  onChange: () => Promise<void>,
  servicePlan?: ServicePlanDto,
): TabProps => ({
  value: servicePlanSectionName,
  label: "Service Plan",
  content: (
    <ServicePlanSection
      caseDto={caseDto}
      servicePlan={servicePlan}
      onChangeServicePlanData={onChange}
      requestState={requestState}
    />
  ),
});
