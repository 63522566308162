import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {createSnapInSchoolsCycleFormDto, SnapInSchoolsCycleFormDto,} from "../dtos/SnapInSchoolsCycleFormDto";
import {SnapInSchoolsCycleDto} from "../dtos/SnapInSchoolsCycleDto";
import {useGetSnapInSchoolsLocations} from "./snapInSchoolsHooks";
import {snapInSchoolsCycleSchema} from "../components/SnapInSchoolsCycleFormFields";
import {useGetProviderPrograms} from "../../provider/hooks/providerHooks";
import {getProviderId} from "../../authentication/state/authenticationActions";
import {ProgramDto} from "../../programs/dtos/ProgramDto";
import {ProgramTypeEnum} from "../../programs/enums/ProgramTypeEnum";

export const useSnapInSchoolsCycleForm = (
  onSubmit: (dto: SnapInSchoolsCycleFormDto) => Promise<void>,
  snapCycle?: SnapInSchoolsCycleDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createSnapInSchoolsCycleFormDto(snapCycle),
    resolver: yupResolver(snapInSchoolsCycleSchema),
    mode: "all",
  });
  const [getSnapInSchoolsLocations, , getSnapInSchoolsLocationsRequestState] =
    useGetSnapInSchoolsLocations();

  const [getProviderPrograms, , getProgramsRequestState] = useGetProviderPrograms();

  const [locations, setLocations] = useState<string[]>([]);

  const [programs, setPrograms] = useState<ProgramDto[]>([])

  const providerId = getProviderId();

  //#endregion

  const clearForm = () => {
    reset(createSnapInSchoolsCycleFormDto(snapCycle));
  };

  const handleOnSubmit = handleSubmit(
    async (dto: SnapInSchoolsCycleFormDto) => {
      await onSubmit(dto);
    }
  );

  useEffect(() => {
    reset(createSnapInSchoolsCycleFormDto(snapCycle));
  }, [snapCycle, reset]);

  useEffect(() => {
    const load = async () => {
      const result = await getSnapInSchoolsLocations(undefined);
      if (result) setLocations(result);

      const providerPrograms = await getProviderPrograms(providerId!)
      if(providerPrograms) setPrograms(providerPrograms.filter(p => p.programType.programType === ProgramTypeEnum.SnapInSchools))
    };

    load();
  }, [getSnapInSchoolsLocations, setLocations, getProviderPrograms, providerId]);

  return {
    state: { locations, getSnapInSchoolsLocationsRequestState, programs, getProgramsRequestState },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
