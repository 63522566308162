import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { WaitlistFormDto } from "../dtos/WaitlistFormDto";
import { useWaitlistForm } from "../hooks/useWaitlistForm";
import WaitlistFormFields from "./WaitlistFormFields";

export const waitlistModalId = "waitlist-form-modal";

interface Props {
  onSubmit: (dto: WaitlistFormDto) => Promise<any>;
  requestState: RequestStateDto;
}

const WaitlistFormDialog: React.FC<Props> = ({ onSubmit, requestState }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers, state } = useWaitlistForm(onSubmit);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const {
    locations,
    providerPrograms,
    getProviderLocationsRequestState,
    getProviderProgramsRequestState,
  } = state;

  const {
    error: getProviderLocationsError,
    isLoading: providerLocationsAreLoading,
  } = getProviderLocationsRequestState;
  const {
    error: getProviderProgramsError,
    isLoading: providerProgramsAreLoading,
  } = getProviderProgramsRequestState;
  const { error } = requestState;
  const isLoading =
    providerLocationsAreLoading ||
    providerProgramsAreLoading ||
    requestState.isLoading;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === waitlistModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Add to Waitlist
          <ErrorText error={error?.message} />
          <ErrorText error={getProviderProgramsError?.message} />
          <ErrorText error={getProviderLocationsError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <WaitlistFormFields
              control={control}
              providerPrograms={providerPrograms}
              locations={locations}
              providerLocationsAreLoading={
                getProviderProgramsRequestState.isLoading
              }
              providerProgramsAreLoading={
                getProviderLocationsRequestState.isLoading
              }
              isDisabled={isLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default WaitlistFormDialog;
