import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@material-ui/core";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SuicideScreeningFormDto } from "../dtos/SuicideScreeningDto";
import { useSuicideReScreeningForm } from "../hooks/useSuicideReScreeningForm";
import SuicideReScreeningFormFields from "./SuicideReScreeningFormFields";
import * as Yup from "yup";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import ErrorText from "../../../app/components/ErrorText";


export const suicideReScreeningModalId = "suicide-rescreening-form-modal";

export const suicideReScreeningFormSchema = Yup.object().shape({
    screeningAt: Yup.string().label("Screening Date/Time").required(),
    result: Yup.string().label("Result").required()
});

interface Props {
    onSubmit: (dto: SuicideScreeningFormDto) => Promise<any>;
    requestState: RequestStateDto;
}

const SuicideReScreeningFormDialog: React.FC<Props> = ({
    onSubmit,
    requestState
}) => {
    const modalId = useAppSelector(selectModalId);
    const dispatch = useAppDispatch();
    const { form, handlers } = useSuicideReScreeningForm(onSubmit);
    const { handleSubmit } = handlers;
    
    const {
        control,
        isValid,
        clearForm
    } = form;

    const {
        isLoading,
        error
    } = requestState;

    const handleCloseModal = async () => {
        clearForm();
        dispatch(closeModal());
    };

    return (
        <Dialog
            open={modalId === suicideReScreeningModalId}
            maxWidth="sm"
            fullWidth
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    Add Suicide Re-Screening
                    <ErrorText error={error?.message} />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <SuicideReScreeningFormFields
                            control={control}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                <SpinnerButton
                    content="Cancel"
                    showSpinner={false}
                    type="button"
                    onClick={handleCloseModal}
                />
                <SpinnerButton
                    showSpinner={isLoading}
                    content="Save"
                    color="primary"
                    type="submit"
                    disabled={!isValid}
                />
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default SuicideReScreeningFormDialog;