import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum ModalityEnum {
  GroupPresentation = "GroupPresentation",
  IndividualMeeting = "IndividualMeeting",
  ShortTermIntervention = "ShortTermIntervention",
  MaterialDistribution = "MaterialDistribution",
  FacilityTour = "FacilityTour",
  MediaEvent = "MediaEvent",
  SafePlaceLocation = "SafePlaceLocation",
  CommunityFundraising = "CommunityFundraising",
  ProviderAgreement = "ProviderAgreement",
}

export const modalityEnumTextMap: { [key: string]: string } = {
  [ModalityEnum.GroupPresentation]: "Group Presentations/Discussions",
  [ModalityEnum.IndividualMeeting]: "Individual Meeting",
  [ModalityEnum.ShortTermIntervention]: "Short-Term Intervention Groups",
  [ModalityEnum.MaterialDistribution]:
    "Set Up/Display and Distribution of Materials",
  [ModalityEnum.FacilityTour]: "Conducting Facility Tours",
  [ModalityEnum.MediaEvent]: "Media Events/Interview",
  [ModalityEnum.SafePlaceLocation]:
    "Establishing/Training Safe Place Locations",
  [ModalityEnum.CommunityFundraising]: "Community Fundraising",
  [ModalityEnum.ProviderAgreement]:
    "Relationships and Inter-Local Provider Agreements with Local Entities",
};

export const getModalityEnumOptions = (
  defaultLabel: string = "Select Modality"
): SelectOption[] => [
  { value: "", label: defaultLabel },
  ...Object.keys(ModalityEnum).map((x) => ({
    value: x,
    label: modalityEnumTextMap[x],
  })),
];
