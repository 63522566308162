import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum SnapCycleGenderEnum {
  Girls = "Girls",
  Boys = "Boys",
  NotSpecified = "NotSpecified"
}

export const snapCycleGenderEnumTextMap: { [key: string]: string } = {
  [SnapCycleGenderEnum.Boys]: "Boys",
  [SnapCycleGenderEnum.Girls]: "Girls",
  [SnapCycleGenderEnum.NotSpecified]: "Not Specified"
};

export const getSnapCycleGenderOptions = (
  defaultValueLabel: string = "Select Gender"
): SelectOption[] => {
  return [
    { value: "", label: defaultValueLabel },
    ...Object.values(SnapCycleGenderEnum).map((value) => ({
      value,
      label: snapCycleGenderEnumTextMap[value],
    })),
  ];
};
