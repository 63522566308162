import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, {useEffect} from "react";
import ErrorText from "../../../app/components/ErrorText";
import InfoTooltip from "../../../app/components/InfoTooltip";
import DateLabel from "../../../app/components/labels/DateLabel";
import TableLoadingPlaceholder from "../../../app/components/TableLoadingPlaceholder";
import {ColorsEnum} from "../../../app/enums/ColorsEnum";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectModalId} from "../../modal/state/modalSelectors";
import {closeModal} from "../../modal/state/modalSlice";
import {CaseProgramLocationHistoryDto} from "../dtos/CaseProgramLocationHistoryDto";
import {useCaseProgramLocationHistoryDialog} from "../hooks/useCaseProgramLocationHistoryDialog";
import {Delete} from "@material-ui/icons";
import AddButton from "../../../app/components/buttons/AddButton";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import {useCombinedRequests} from "../../../app/hooks/useCombinedRequests";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import {PermissionResourceNameEnum} from "../../authentication/enums/PermissionResourceNameEnum";
import EditIconButton from "../../../app/components/buttons/EditIconButton";

export const caseProgramLocationHistoryModalId =
  "case-program-location-history-form-modal";

interface Props {
  id: string;
}

const CaseProgramLocationHistoryDialog: React.FC<Props> = ({ id }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const {
    locationHistory,
    getCaseProgramLocationHistoryRequestState,
    handleRefreshProgramLocationHistory,
    onEditClick,
    mode,
    selectedLocation,
    locations,
    onAddNewLocationClick,
    onSaveChanges,
    cancelEditMode,
    onDeleteLocationClick,
    onDeleteConfirm,
    control,
    addCaseLocationRequestState,
    updateCaseLocationRequestState,
    deleteCaseLocationRequestState,
    editFormIsValid,
  } = useCaseProgramLocationHistoryDialog(id);

  const { errors, isLoading } = useCombinedRequests([
    getCaseProgramLocationHistoryRequestState,
    addCaseLocationRequestState,
    updateCaseLocationRequestState,
    deleteCaseLocationRequestState
  ]);


  //#endregion

  const orderedLocationHistory = () => {
    return locationHistory.sort((a, b) =>
      new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
    );
  };

  //#region Handlers
  const handleCloseModal = async () => {
    dispatch(closeModal());
  };
  //#endregion

  const selectedLocationDto = locationHistory.find(l => l.id === selectedLocation);
  const earliestLocationSelected = locationHistory.findIndex(lh => lh.id === selectedLocationDto?.id) === locationHistory.length - 1;
  const renderHistoryRow = (dto: CaseProgramLocationHistoryDto, index: number, items: CaseProgramLocationHistoryDto[]) => {
    const isCurrentLocation = dto.endOn == null;
    const rowStyle = isCurrentLocation
      ? { backgroundColor: ColorsEnum.RowGrey }
      : {};

    const cellStyle: React.CSSProperties = isCurrentLocation
      ? { fontWeight: "bold", verticalAlign: "middle" }
      : {};
    return (
      <TableRow key={dto.id} style={rowStyle}>
        <TableCell style={cellStyle}>
          {isCurrentLocation && (
            <InfoTooltip style={{verticalAlign: 'middle'}} title={<Typography>Current Location</Typography>} />
          )}

        </TableCell>
        <TableCell style={cellStyle}>
          <Typography>
            {dto.locationName}
          </Typography>
        </TableCell>
        <TableCell style={cellStyle}>
          <DateLabel date={dto.startOn} />
        </TableCell>
        <TableCell style={cellStyle}>
          <DateLabel date={dto.endOn} />
        </TableCell>
        <TableCell style={cellStyle}>
          <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
            <EditIconButton title={"Edit Location"} size={"small"} onClick={() => onEditClick(dto.id)} />
            {index === 0 && items.length > 1 && <IconButton title={"Delete Location"} size={"small"} onClick={() => onDeleteLocationClick(dto.id)}>
                <Delete style={{ color: ColorsEnum.Red }}/>
            </IconButton>}

          </WriteAccessLevelWrapper>
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    if (modalId === caseProgramLocationHistoryModalId)
      handleRefreshProgramLocationHistory();
  }, [handleRefreshProgramLocationHistory, modalId]);

  return (
    <Dialog
      open={modalId === caseProgramLocationHistoryModalId}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {mode === "list" && "View/Update Case Location History"}
        {mode === "edit" && selectedLocation !== '' && "Edit Case Location"}
        {mode === "edit" && selectedLocation === '' && "Youth Moved to New Location"}
        {mode === "delete" && "Delete Case Location"}
        { errors && errors.map(e => <ErrorText error={e} />) }
      </DialogTitle>
      <DialogContent>
        {mode === "list" && <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Location Name</TableCell>
              <TableCell>Start On</TableCell>
              <TableCell>End On</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && orderedLocationHistory().map(renderHistoryRow)}
            {isLoading && <TableLoadingPlaceholder columns={5} rows={3} />}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} align={"right"}>
                <AddButton text={"Youth moved to new location"} onClick={() => onAddNewLocationClick()} />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>}
        {mode === 'edit' && <form onSubmit={onSaveChanges}>
          <Grid container spacing={2}>
            {selectedLocation === '' && <Grid item xs={12}>
                  <Typography>
                      Use this form to enter a new location where the Youth moved during this Case.
                  </Typography>
              </Grid>}
            <Grid item xs={12}>
              <ControlledSelect
                  fullWidth
                  control={control}
                  name={"locationId"}
                  options={[ {label: 'Please Select a Location', value: ''},  ...locations.map(l => ({ label: l.name, value: l.id }))] }
                  label={"Location*"}
              />
            </Grid>
              <Grid item md={12} xs={12}>
                  <ControlledInput
                      control={control}
                      name="startOn"
                      label="Start On*"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={earliestLocationSelected}
                  />
                {earliestLocationSelected && <Typography>Note: the start date for the first location cannot be modified.</Typography>}
              </Grid>
          </Grid>
        </form>}
        {mode === 'delete' && (
          <Grid container>
            <Grid item xs={12}>
                <Typography>
                    Are you sure you would like to delete this location?
                </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Location Name</TableCell>
                    <TableCell>Start On</TableCell>
                    <TableCell>End On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                  <TableCell>
                    <Typography>
                      {selectedLocationDto?.locationName}
                    </Typography>

                  </TableCell>
                  <TableCell>
                    <DateLabel date={selectedLocationDto?.startOn} />
                  </TableCell>
                  <TableCell>
                    <DateLabel date={selectedLocationDto?.endOn} />
                  </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/*<p>*/}
              {/*  {selectedLocationDto?.locationName}*/}
              {/*  <br />*/}
              {/*  {selectedLocationDto?.startOn}*/}
              {/*  {selectedLocationDto?.endOn != null &&  (*/}
              {/*    <>*/}
              {/*      <br />*/}
              {/*      {selectedLocationDto?.endOn}*/}
              {/*    </>*/}
              {/*    )}*/}
              {/*</p>*/}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {mode === 'edit' && (<>
          <Button disabled={!editFormIsValid} onClick={() => onSaveChanges()}>Save</Button>
          <Button onClick={() => cancelEditMode()}>Cancel</Button>
        </>)}
        {mode === 'delete' && (<>
          <Button onClick={() => onDeleteConfirm()}>Delete</Button>
          <Button onClick={() => cancelEditMode()}>Cancel</Button>
        </>)}
        {mode === 'list' && (<Button onClick={handleCloseModal}>Close</Button>) }
      </DialogActions>
    </Dialog>
  );
};

export default CaseProgramLocationHistoryDialog;
