import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { FollowUpAttemptFailureDto, FollowUpDto } from "../dtos/FollowUpDto";
import { useRequest } from "../../../app/hooks/useRequest";
import { updateFailedFollowUpDataRequest } from "../FollowUpRequests";
import {
  unableToContactReasonOptions,
  UnableToContactReasonsEnum,
} from "../enums/UnableToContactReasonsEnum";
import { useCallback, useEffect } from "react";
import { datetimeToFormString } from "../../../app/helpers";

export interface UpdateFollowUpAttemptDataFormData {
  attemptedAt: string;
  reason: string;
}

const schema = Yup.object().shape({
  attemptedAt: Yup.date().required().label("Attempted At"),
  reason: Yup.string().when("outcome", {
    is: "failed",
    then: Yup.string().required().label("Reason for Failed Attempt"),
  }),
});

export const useUpdateFollowUpAttemptDataForm = (
  onSubmit: () => Promise<void>,
  caseDto: CaseDetailDto,
  followUp?: FollowUpDto,
  attempt?: FollowUpAttemptFailureDto
) => {
  //#region State

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<UpdateFollowUpAttemptDataFormData>({
    defaultValues: {
      attemptedAt: attempt?.attemptedOn
        ? datetimeToFormString(new Date(attempt?.attemptedOn))
        : undefined,
      reason: attempt?.reason,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = useCallback(() => {
    reset({
      attemptedAt: attempt?.attemptedOn
        ? datetimeToFormString(new Date(attempt?.attemptedOn))
        : undefined,
      reason: attempt?.reason,
    });
  }, [attempt, reset]);

  const [updateFollowUpAttempt, , updateFollowUpAttemptRequestState] =
    useRequest(updateFailedFollowUpDataRequest);

  const handleOnSubmit = handleSubmit(
    async (formData: UpdateFollowUpAttemptDataFormData) => {
      if (followUp == null) return;

      const dto = {
        reason: formData.reason as UnableToContactReasonsEnum,
        attemptedOn: new Date(formData.attemptedAt),
      };

      await updateFollowUpAttempt({
        id: followUp.id,
        failureId: attempt?.id!,
        dto,
      });

      clearForm();
      await onSubmit();
    }
  );

  useEffect(() => {
    if (!attempt) return;

    clearForm();
  }, [attempt, clearForm]);

  return {
    state: {
      unableToContactReasonOptions,
      updateFollowUpAttemptRequestState,
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
