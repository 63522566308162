import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React from "react";
import TableLoadingPlaceholder from "../../../app/components/TableLoadingPlaceholder";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";

import {EventLogListDto} from "../dtos/EventLogListDto";
import EventLogListItem from "./EventLogListItem";

interface Props {
  eventLogListResult: PaginationResultDto<EventLogListDto>;
  onPageChange: (page: number) => void;
  isLoading: boolean;
}

const CaseList: React.FC<Props> = ({
                                     eventLogListResult,
                                     onPageChange,
                                     isLoading,
                                   }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Processed At</TableCell>
            <TableCell>Resource Affected</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>User</TableCell>
            <TableCell align="center">Deliverables</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading === false &&
            eventLogListResult.items?.map((eventDto) => (
              <EventLogListItem key={eventDto.id} eventDto={eventDto} />
            ))}
          {isLoading && <TableLoadingPlaceholder rows={10} columns={6} />}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[eventLogListResult.pageSize || 10]}
              rowsPerPage={eventLogListResult.pageSize || 0}
              count={eventLogListResult?.count || 0}
              onPageChange={(_: any, page: number) => onPageChange(page)}
              page={eventLogListResult?.page || 0}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CaseList;
