import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum IntakeGradeLevelEnum {
  Kindergarten = "Kindergarten",
  FirstGrade = "FirstGrade",
  SecondGrade = "SecondGrade",
  ThirdGrade = "ThirdGrade",
  FourthGrade = "FourthGrade",
  FifthGrade = "FifthGrade",
  SixthGrade = "SixthGrade",
  SeventhGrade = "SeventhGrade",
  EighthGrade = "EighthGrade",
  NinthGrade = "NinthGrade",
  TenthGrade = "TenthGrade",
  EleventhGrade = "EleventhGrade",
  TwelfthGrade = "TwelfthGrade",
  NotSpecified = "NotSpecified"
}

export const intakeGradeLevelTextMap: { [key: string]: string } = {
  [IntakeGradeLevelEnum.Kindergarten]: "Kindergarten",
  [IntakeGradeLevelEnum.FirstGrade]: "1st Grade",
  [IntakeGradeLevelEnum.SecondGrade]: "2nd Grade",
  [IntakeGradeLevelEnum.ThirdGrade]: "3rd Grade",
  [IntakeGradeLevelEnum.FourthGrade]: "4th Grade",
  [IntakeGradeLevelEnum.FifthGrade]: "5th Grade",
  [IntakeGradeLevelEnum.SixthGrade]: "6th Grade",
  [IntakeGradeLevelEnum.SeventhGrade]: "7th Grade",
  [IntakeGradeLevelEnum.EighthGrade]: "8th Grade",
  [IntakeGradeLevelEnum.NinthGrade]: "9th Grade",
  [IntakeGradeLevelEnum.TenthGrade]: "10th Grade",
  [IntakeGradeLevelEnum.EleventhGrade]: "11th Grade",
  [IntakeGradeLevelEnum.TwelfthGrade]: "12th Grade",
  [IntakeGradeLevelEnum.NotSpecified]: "Not Specified",
};

export const intakeGradeLevelOptions: SelectOption[] = [
  { value: "", label: "Select Grade" },
  ...Object.keys(IntakeGradeLevelEnum).map((status) => ({
    value: status,
    label: intakeGradeLevelTextMap[status],
  })),
];
