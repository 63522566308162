import React, { Fragment } from "react";
import { fnyfsProviderId } from "../../../features/authentication/constants";
import { getProviderId } from "../../../features/authentication/state/authenticationActions";

interface Props {}

const ProviderAccessWrapper: React.FC<Props> = ({ children }) => {
  const providerId = getProviderId();

  if (providerId === null || providerId === fnyfsProviderId)
    return <Fragment></Fragment>;

  return <Fragment>{children}</Fragment>;
};

export default ProviderAccessWrapper;
