import { Box, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { Fragment } from "react";
interface Props {
  error?: string;
}

const ErrorAlert: React.FC<Props> = ({ error }) => {
  const errors = error?.split("\n").filter((x) => x !== "") || [];
  const renderErrors = () => {
    if (errors.length === 1) return <Typography>{errors[0]}</Typography>;

    return (
      <ul>
        {errors.map((e, i) => (
          <li key={`error-${i}`}>
            <Typography>{e}</Typography>
          </li>
        ))}
      </ul>
    );
  };

  if (errors.length > 0)
    return (
      <Box marginBottom="12px">
        <Alert severity="error" variant="filled">
          {renderErrors()}
        </Alert>
      </Box>
    );

  return <Fragment></Fragment>;
};

export default ErrorAlert;
