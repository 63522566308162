import { Box, Paper } from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { createCaseSummaryTab } from "../../cases/components/CaseSummaryTab";
import RemoveWaitlistFormDialog from "../../waitlists/components/RemoveWaitlistFormDialog";
import WaitlistFormDialog from "../../waitlists/components/WaitlistFormDialog";
import YouthScreeningFormDialog from "../../youths/components/modals/YouthScreeningFormDialog";
import ScreeningDetailAdditionalActions from "../components/ScreeningDetailAdditionalActions";
import { createScreeningDetailsTab } from "../components/ScreeningDetailsTab";
import { createScreeningWaitlistTab } from "../components/ScreeningWaitlistTab";
import { useScreeningDetailPage } from "../hooks/useScreeningDetailPage";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import ConfirmDeleteScreeningDialog from "../components/ConfirmDeleteScreeningDialog";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";

const ScreeningDetailPage = () => {
  //#region State
  const {
    handlers: {
      handleStartCaseClicked,
      handleCreateScreeningWaitlistClicked,
      handleCreateScreeningWaitlist,
      handleRemoveWaitlist,
      handleRemoveWaitlistClicked,
      handleChangeTabIndex,
      handleUpdateScreening,
      handleDeleteScreening,
      handleUpdateScreeningClicked,
      handleTransferScreeningClicked,
      handleDeleteScreeningClicked
    },
    state: {
      tabIndex,
      canReadCases,
      screening,
      getScreeningRequestState,
      createScreeningWaitlistRequestState,
      removeWaitlistRequestState,
      updateScreeningRequestState,
      deleteScreeningRequestState,
      canCreateCase,
    },
    refreshScreening,
  } = useScreeningDetailPage();

  const tabs: TabProps[] = [
    createScreeningDetailsTab({
      screening,
      isLoading: getScreeningRequestState.isLoading,
      refresh: refreshScreening,
    }),
  ];

  if (canReadCases)
    tabs.push(
      createCaseSummaryTab({
        cases: screening?.cases || [],
        isLoading: getScreeningRequestState.isLoading,
        onCreateClick: handleStartCaseClicked,
      })
    );

  tabs.push(
    createScreeningWaitlistTab({
      screening,
      isLoading: getScreeningRequestState.isLoading,
      allowWaitlistCreation: canCreateCase,
      onRemoveWaitlistClick: handleRemoveWaitlistClicked,
      onCreateWaitlistClick: handleCreateScreeningWaitlistClicked,
    })
  );

  //#endregion

  return (
    <Fragment>
      <Box height="100%" display="flex" flexDirection="column">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Waitlist}>
          <WaitlistFormDialog onSubmit={handleCreateScreeningWaitlist} requestState={createScreeningWaitlistRequestState} />
          <RemoveWaitlistFormDialog onSubmit={handleRemoveWaitlist} requestState={removeWaitlistRequestState} />
        </WriteAccessLevelWrapper>
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Screening}>
          <YouthScreeningFormDialog onSubmit={handleUpdateScreening} requestState={updateScreeningRequestState} screening={screening} />
        </WriteAccessLevelWrapper>
        {screening && 
        <DeleteAccessLevelWrapper name={PermissionResourceNameEnum.Screening}>
          <ConfirmDeleteScreeningDialog 
            onConfirm={handleDeleteScreening}
            requestState={deleteScreeningRequestState} 
            screening={screening} />
        </DeleteAccessLevelWrapper>
        }
        <Box marginBottom="12px">
          <BackButton route={`/youths/${screening?.youth.id}`} text="Back to youth" />
        </Box>

          <Header
            title={
              <>
                {`Screening ${screening?.referenceNumber || ""}`}
                <RedAlertLabel hide={!screening?.deletedAt} variant="outlined" label="Deleted"/>
              </>
            }
            isLoading={getScreeningRequestState.isLoading}
            actions={
              <ScreeningDetailAdditionalActions
                onUpdateScreeningClicked={handleUpdateScreeningClicked}
                onTransferScreeningClicked={handleTransferScreeningClicked}
                onDeleteScreeningClicked={handleDeleteScreeningClicked}
              />
            }
          />        
          
        <Paper variant="outlined" style={{ flex: 1 }}>
          <TabContainer index={tabIndex} onIndexChange={handleChangeTabIndex} tabs={tabs} disabled={getScreeningRequestState.isLoading} />
        </Paper>
      </Box>
    </Fragment>
  );
};

export default ScreeningDetailPage;
