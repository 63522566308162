import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import { WaitlistDto } from "../dtos/WaitlistDto";
import { WaitlistScreeningDto } from "../dtos/WaitlistScreeningDto";
import RemovalReasonLabel from "./RemovalReasonLabel";

interface Props {
  waitlist: WaitlistDto | WaitlistScreeningDto;
  hideTooltip?: boolean
}

export const WaitlistRemovedLabel: React.FC<Props> = ({ waitlist, hideTooltip }) => {
  return (
    <Box style={{ cursor: "help" }} component="span">
      <Tooltip
        disableHoverListener={hideTooltip}
        title={
          <Fragment>
            <Typography component="span">
              Reason:{" "}
              <RemovalReasonLabel
                style={{ fontWeight: "bold" }}
                component="span"
                reason={waitlist.removalReason!}
              />
            </Typography>
            <br />
            <Typography component="span">
              Removed At:{" "}
              <strong>
                <DateTimeLabel date={waitlist.removedAt} />
              </strong>
            </Typography>
          </Fragment>
        }
      >
        <Box component="span">
          <RedAlertLabel label={"Removed"} component="span" />
        </Box>
      </Tooltip>
    </Box>
  );
};
