import React from "react";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum GradeLevelEnum {
  Kindergarten = "Kindergarten",
  FirstGrade = "FirstGrade",
  SecondGrade = "SecondGrade",
  ThirdGrade = "ThirdGrade",
  FourthGrade = "FourthGrade",
  FifthGrade = "FifthGrade",
  Other = "Other",
}

export const gradeLevelTextMap: { [key: string]: string } = {
  [GradeLevelEnum.Kindergarten]: "Kindergarten",
  [GradeLevelEnum.FirstGrade]: "1st Grade",
  [GradeLevelEnum.SecondGrade]: "2nd Grade",
  [GradeLevelEnum.ThirdGrade]: "3rd Grade",
  [GradeLevelEnum.FourthGrade]: "4th Grade",
  [GradeLevelEnum.FifthGrade]: "5th Grade",
  [GradeLevelEnum.Other]: "Other",
};

export const getGradeLevelOptions = (
  defaultLabel: React.ReactNode = "Select Grade"
): SelectOption[] => [
  { value: "", label: defaultLabel },
  ...Object.keys(GradeLevelEnum).map((status) => ({
    value: status,
    label: gradeLevelTextMap[status],
  })),
];

export const gradeLevelOptions: SelectOption[] = getGradeLevelOptions();
