import React, { useCallback, useState } from "react";

export const useAnchor = (): [
  HTMLElement | null,
  (event: React.MouseEvent<HTMLElement>) => void,
  () => void
] => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  return [anchorEl, handleOpenClicked, handleClose];
};
