import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectModalId } from "../../features/modal/state/modalSelectors";
import { useServicePlanPageContext } from "../ServicePlanPageContext";
import { closeModal } from "../../features/modal/state/modalSlice";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ErrorText from "../../app/components/ErrorText";
import ControlledInput from "../../app/components/inputs/ControlledInput";
import NameLabel from "../../app/components/labels/NameLabel";
import { getRoleDisplayText } from "../enums/ServicePlanParticipantRolesEnum";
import SpinnerButton from "../../app/components/buttons/SpinnerButton";
import { useUpdateServicePlanReviewForm } from "../hooks/useUpdateServicePlanReviewForm";

interface Props {
  reviewId: string;
}

export const updateServicePlanReviewModalId = "update-service-plan-review";
export const UpdateServicePlanReviewFormDialog: React.FC<Props> = ({
  reviewId,
}) => {
  const selectedModalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { servicePlan, youth } = useServicePlanPageContext();

  const {
    state: {
      participants,
      counselorParticipated,
      youthParticipated,
      updateReviewRequestState: { isLoading, error },
    },
    form: {
      isValid,
      // clearForm,
      control,
    },
    handlers: {
      handleSubmit,
      onToggleParticipant,
      onToggleCounselorParticipated,
      onToggleYouthParticipated,
    },
  } = useUpdateServicePlanReviewForm(reviewId);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog
      open={updateServicePlanReviewModalId === selectedModalId}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Update 30 Day Review
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="reviewedOn"
                label="Plan Reviewed On"
                type="date"
                control={control}
                disabled={isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color={"primary"}>Review Participants</Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"youth"}>
                      <TableCell>
                        <NameLabel nameDto={youth} />
                      </TableCell>
                      <TableCell>
                        <Typography component="span">Youth</Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          disabled={isLoading}
                          color={"primary"}
                          checked={youthParticipated}
                          onChange={() => onToggleYouthParticipated()}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={"counselor"}>
                      <TableCell>
                        <NameLabel nameDto={servicePlan.counselor} />
                      </TableCell>
                      <TableCell>
                        <Typography component="span">Counselor</Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          disabled={isLoading}
                          color={"primary"}
                          checked={counselorParticipated}
                          onChange={() => onToggleCounselorParticipated()}
                        />
                      </TableCell>
                    </TableRow>
                    {participants.map((p) => (
                      <TableRow key={`${p.name}-${p.role}`}>
                        <TableCell>
                          <Typography component="span">{p.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component="span">
                            {getRoleDisplayText(p.role)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            disabled={isLoading}
                            color={"primary"}
                            checked={p.selected}
                            onChange={() => onToggleParticipant(p.name, p.role)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography color={"primary"}>Review Notes</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="notes"
                label="Notes"
                control={control}
                disabled={isLoading}
                fullWidth
                multiline
                minRows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
