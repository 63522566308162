import { dateToFormString } from "../../../app/helpers";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { SnapGroupCycleDto } from "./SnapGroupCycleDto";

export interface SnapGroupCycleFormDto {
  providerId: string;
  startedOn: string;
  programTypeId: string;
  gender: string;
}

export const createSnapGroupCycleFormDto = (
  dto?: SnapGroupCycleDto
): SnapGroupCycleFormDto => {
  if (dto) {
    const {
      provider: { id: providerId },
      startedOn,
      programType: { id: programTypeId },
      gender,
    } = dto;
    return {
      providerId,
      startedOn: dateToFormString(new Date(startedOn)),
      programTypeId,
      gender,
    };
  }

  return {
    providerId: getProviderId() || "",
    startedOn: "",
    programTypeId: "",
    gender: "",
  };
};
