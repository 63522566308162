import { Box, List, ListItem, Paper } from "@material-ui/core";
import React from "react";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import BackButton from "../../../app/components/buttons/BackButton";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import EditButton from "../../../app/components/buttons/EditButton";
import Header from "../../../app/components/Header";
import KeyValueDetails, {
  RowProps,
} from "../../../app/components/KeyValueDetails";
import DateLabel from "../../../app/components/labels/DateLabel";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import ConfirmDeleteOutreachActivityDialog from "../components/ConfirmDeleteOutreachActivityDialog";
import OutreachActivityFormDialog from "../components/OutreachActivityFormDialog";
import { OutreachActivityDto } from "../dtos/OutreachActivityDto";
import { modalityEnumTextMap } from "../enums/ModalityEnum";
import { targetAudienceEnumTextMap } from "../enums/TargetAudienceEnum";
import { topicEnumTextMap } from "../enums/TopicEnum";
import { useOutreachActivityDetailPage } from "../hooks/useOutreachActivityDetailPage";

const OutreachActivityDetails = ({
  activity,
  isLoading,
}: {
  activity?: OutreachActivityDto;
  isLoading: boolean;
}) => {
  const rows: RowProps[] = [
    {
      key: "date",
      label: "Date",
      value: <DateLabel date={activity?.activityDate} />,
    },
    {
      key: "duration",
      label: "Duration(hours)",
      value: activity?.durationHours,
    },
    {
      key: "location",
      label: "Location",
      value: `${activity?.zipCode}${activity?.locationDescription && " - "}${
        activity?.locationDescription
      }`,
    },
    {
      key: "targetAudience",
      label: "Target Audience",
      value: (
        <List disablePadding>
          {activity?.targetAudiences.map((x) => (
            <ListItem style={{ padding: "0px" }} disableGutters key={x}>
              {" "}
              {targetAudienceEnumTextMap[x]}
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      key: "estPeopleReached",
      label: "Est. People Reached",
      value: activity?.estimatedPeopleReached,
    },
    {
      key: "modality",
      label: "Modality",
      value: modalityEnumTextMap[activity?.modality || ""],
    },
    {
      key: "topics",
      label: "Topic(s)",
      value: (
        <List disablePadding>
          {" "}
          {activity?.topics.map((x) => (
            <ListItem disableGutters key={x} style={{ padding: "0px" }}>
              {topicEnumTextMap[x]}
            </ListItem>
          ))}
        </List>
      ),
    },
  ];

  return <KeyValueDetails rows={rows} isLoading={isLoading} />;
};

const OutreachActivityDetailPage = () => {
  const {
    state: {
      outreachActivity,
      getOutreachActivityRequestState,
      updateOutreachActvitiyRequestState,
      deleteOutreachActivityRequestState,
    },
    handlers: {
      handleDeleteOutreachActivity,
      handleDeleteOutreachActivityClicked,
      handleUpdateOutreachActivity,
      handleUpdateOutreachActivityClicked,
    },
  } = useOutreachActivityDetailPage();

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box marginBottom="12px">
        <BackButton
          route={`/outreach-activites`}
          text="Back to Outreach Activities"
        />
      </Box>
      <ConfirmDeleteOutreachActivityDialog
        requestState={deleteOutreachActivityRequestState}
        onConfirm={handleDeleteOutreachActivity}
        activity={outreachActivity}
      />
      <OutreachActivityFormDialog
        requestState={updateOutreachActvitiyRequestState}
        onSubmit={handleUpdateOutreachActivity}
        outreachActivity={outreachActivity}
      />
      <RequestErrorAlert requests={[getOutreachActivityRequestState]} />
      <Header
        title={outreachActivity?.title || ""}
        isLoading={getOutreachActivityRequestState.isLoading}
        actions={
          <>
            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.OutreachActivities}
            >
              <EditButton onClick={handleUpdateOutreachActivityClicked} />
            </WriteAccessLevelWrapper>
            <DeleteAccessLevelWrapper
              name={PermissionResourceNameEnum.OutreachActivities}
            >
              <DeleteButton onClick={handleDeleteOutreachActivityClicked} />
            </DeleteAccessLevelWrapper>
          </>
        }
      />
      <Paper variant="outlined" style={{ flex: 1, padding: "16px" }}>
        <OutreachActivityDetails
          activity={outreachActivity}
          isLoading={getOutreachActivityRequestState.isLoading}
        />
      </Paper>
    </Box>
  );
};

export default OutreachActivityDetailPage;
