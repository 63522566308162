import React, { Fragment } from "react";
import { YouthRaceEnum, YouthRaceEnumTextMap } from "../../enums/YouthRaceEnum";

interface Props {
  race: YouthRaceEnum;
}

const RaceLabel: React.FC<Props> = ({ race }) => {
  return <Fragment>{YouthRaceEnumTextMap[race]}</Fragment>;
};

export default RaceLabel;
