import { DemographicDto } from "./DemographicDto";

export interface DemographicFormDto {
  numberOfHouseholdMembers?: string;
  fatherEmployed?: string;
  motherEmployed?: string;
  parentalStatus?: string;
  maritalStatus?: string;
  headOfHousehold?: string;
  familyWeeklyIncome?: string;
  familyStructure?: string;
  fundingSource?: string;
}

export const createDemographicFormDto = (
  dto?: DemographicDto
): DemographicFormDto => {
  if (dto) {
    const {
      numberOfHouseholdMembers,
      fatherEmployed,
      motherEmployed,
      parentalStatus,
      maritalStatus,
      headOfHousehold,
      familyWeeklyIncome,
      familyStructure,
    } = dto;
    return {
      numberOfHouseholdMembers: numberOfHouseholdMembers?.toString() || "",
      fatherEmployed: fatherEmployed?.toString() || "",
      motherEmployed: motherEmployed?.toString() || "",
      parentalStatus: parentalStatus || "",
      maritalStatus: maritalStatus || "",
      headOfHousehold: headOfHousehold || "",
      familyWeeklyIncome: familyWeeklyIncome || "",
      familyStructure: familyStructure || "",
    };
  }

  return {
    numberOfHouseholdMembers: "",
    fatherEmployed: "",
    motherEmployed: "",
    parentalStatus: "",
    maritalStatus: "",
    headOfHousehold: "",
    familyWeeklyIncome: "",
    familyStructure: "",
  };
};
