import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHttpError, refreshTokenIfNeeded } from "../../../app/helpers";
import { fnyfsName, fnyfsProviderId } from "../../authentication/constants";
import { getProvidersRequest } from "../../provider/ProviderRequests";
import { UpdateUserEmailFormDto } from "../dtos/UpdateUserEmailFormDto";
import { UserFormDto } from "../dtos/UserFormDto";
import { UserSearchParamsDto } from "../dtos/UserSearchParamsDto";
import {
  createUserRequest,
  getUserRequest,
  getUsersRequest,
  updateUserEmailRequest,
  updateUserRequest,
} from "../UserRequests";

interface UpdateUserParams {
  id: string;
  dto: UserFormDto;
}

interface UpdateUserEmailParams {
  id: string;
  dto: UpdateUserEmailFormDto;
}

export const createUser = createAsyncThunk(
  "users/create",
  async (dto: UserFormDto, { rejectWithValue, dispatch }) => {
    await refreshTokenIfNeeded(dispatch);
    try {
      const result = await createUserRequest(dto);
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);

export const getUsers = createAsyncThunk(
  "users/get",
  async (params: UserSearchParamsDto, { rejectWithValue, dispatch }) => {
    await refreshTokenIfNeeded(dispatch);
    try {
      const result = await getUsersRequest(params);
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);

export const getUser = createAsyncThunk(
  "users/getById",
  async (id: string, { rejectWithValue, dispatch }) => {
    await refreshTokenIfNeeded(dispatch);
    try {
      const result = await getUserRequest(id);
      const providerIds = result.roles.map((x) => x.provider.id);
      const providers = await getProvidersRequest({ ids: providerIds });
      result.roles.forEach((role) => {
        if (role.provider.id === fnyfsProviderId) {
          role.provider.name = fnyfsName;
          return;
        }

        role.provider.name =
          providers.items.find((x) => x.id === role.provider.id)?.name ||
          "Unknown Provider";
      });
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/update",
  async (params: UpdateUserParams, { rejectWithValue, dispatch }) => {
    await refreshTokenIfNeeded(dispatch);
    try {
      const result = await updateUserRequest(params.id, params.dto);
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);

export const updateUserEmail = createAsyncThunk(
  "users/update-user-email",
  async (params: UpdateUserEmailParams, { rejectWithValue, dispatch }) => {
    await refreshTokenIfNeeded(dispatch);
    try {
      const result = await updateUserEmailRequest(params.id, params.dto);
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);
