import { Typography } from "@material-ui/core";
import React from "react";
import {
  EmploymentStatusEnum,
  employmentStatusTextMap,
} from "../../enums/EmploymentStatusEnum";

interface Props {
  status: EmploymentStatusEnum;
}

const EmploymentStatusLabel: React.FC<Props> = ({ status }) => {
  const text = employmentStatusTextMap[status];

  return <Typography component="span">{text}</Typography>;
};

export default EmploymentStatusLabel;
