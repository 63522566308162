import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { useAppDispatch } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { useRequest } from "../../../app/hooks/useRequest";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { useGetAllProviders, useGetProviderLocations, useGetProviderPrograms } from "../../provider/hooks/providerHooks";
import { exportSnapRequest } from "../SnapRequests";
import { snapGroupCycleModalId } from "../components/SnapGroupCycleFormDialog";
import { SnapGroupCycleFormDto } from "../dtos/SnapGroupCycleFormDto";
import { SnapGroupCycleSearchParamsDto } from "../dtos/SnapGroupCycleSearchParamsDto";
import {
  useCreateSnapGroupCycle,
  useGetSnapGroupCycles,
  useGetSnapProgramTypes,
} from "./snapHooks";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { LocationDto } from "../../locations/dtos/LocationDto";

export const useSnapGroupCycleListPage = () => {
  //#region State
  const query = useQuery();
  const [params, setParams] = useState<SnapGroupCycleSearchParamsDto>(
    new SnapGroupCycleSearchParamsDto({ query })
  );
  const [getSnapCycles, snapCyclesResult, getSnapCyclesRequestState] =
    useGetSnapGroupCycles();
  const history = useHistory();
  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [
    createSnapCycle,
    ,
    createSnapCycleRequestState,
    clearCreateSnapCycleErrors,
  ] = useCreateSnapGroupCycle();
  const dispatch = useAppDispatch();

  const [getProgramTypes, programTypesResult, getProgramTypesRequestState] =
    useGetSnapProgramTypes();

  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();

  const [providerPrograms, setProviderPrograms] = 
    useState<{[key: string]: ProgramDto[];}>({});

  const [providerLocations, setProviderLocations] = 
    useState<{[key: string]: LocationDto[];}>({});

  const [getPrograms, , getProgramsRequestState] = useGetProviderPrograms();
  const [getLocations, , getLocationsRequestState] = useGetProviderLocations();

  const [exportSnapList, , exportSnapRequestState] = useRequest(exportSnapRequest);

  //#endregion
  
  //#region Handlers
  const handlePageChange = (page: number) => {
    setParams((params) => ({ ...params, page }));
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    setParams(newParams);
  };

  const handleSearch = (dto: SnapGroupCycleSearchParamsDto) => {
    setParams(dto);
  };

  const handleOpenFilterClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => setFilterAnchorEl(null);

  const handleCreateSnapCycleClicked = () => {
    clearCreateSnapCycleErrors();
    dispatch(openModal({ modalId: snapGroupCycleModalId }));
  };

  const handleCreateSnapCycle = async (dto: SnapGroupCycleFormDto) => {
    const result = await createSnapCycle(dto);
    batch(() => {
      dispatch(closeModal());
      dispatch(addSuccessNotification({ message: "SNAP Cycle Created" }));
    });

    history.push(`/snap-cycles/${result?.id}`);
  };

  const handleExportClicked = useCallback((dto: SnapGroupCycleSearchParamsDto) => {
    const filename = `snap-export-${DateTime.now().toFormat('yyyyLLddHHmmss')}.csv`
    exportSnapList({ searchParams: dto, filename });
  }, [exportSnapList])

  const updatePrograms = useCallback(
    async (providerId: string) => {
      if (
        providerId === "" ||
        providerId == null ||
        providerPrograms[providerId]
      ) {
        return;
      }

      const result = await getPrograms(providerId);
      if (result) {
        setProviderPrograms((current) => ({
          ...current,
          [providerId]: result,
        }));
      }
    },
    [setProviderPrograms, providerPrograms, getPrograms]
  );  

  const updateLocations = useCallback(
    async (providerId: string) => {
      if (
        providerId === "" ||
        providerId == null ||
        providerLocations[providerId]
      ) {
        return;
      }

      const result = await getLocations(providerId);
      if (result) {
        setProviderLocations((current) => ({
          ...current,
          [providerId]: result,
        }));
      }
    },
    [setProviderLocations, providerLocations, getLocations]
  );  

  const handleProviderChange = useCallback(
    async (providerId: string) => {
      await updatePrograms(providerId);
      await updateLocations(providerId);
    },
    [updatePrograms, updateLocations]
  );  
  //#endregion

  //#region Effects
  useEffect(() => {
    getSnapCycles(params);
  }, [params, getSnapCycles]);

  useEffect(() => {
    history.push(
      `/snap-cycles?${SnapGroupCycleSearchParamsDto.toQueryString(params)}`
    );
  }, [params, history]);

  useEffect(() => {
    if (getProviderId() === fnyfsProviderId) getProviders(undefined);
  }, [getProviders]);

  useEffect(() => {
    getProgramTypes(undefined);
  }, [getProgramTypes]);

  useEffect(() => {
    const currentProviderId = getProviderId();

    if (currentProviderId == null) return;

    if (currentProviderId !== fnyfsProviderId) {
      updatePrograms(currentProviderId!);
      updateLocations(currentProviderId!);
    }
  }, [updatePrograms, updateLocations]);  
  //#endregion

  return {
    state: {
      filterAnchorEl,
      snapCyclesResult,
      params,
      getSnapCyclesRequestState,
      createSnapCycleRequestState,
      programTypes: programTypesResult || [],
      providers: providers || [],      
      providerLocations,
      providerPrograms,      
      getProgramTypesRequestState,
      getProvidersRequestState,
      getProgramsRequestState,
      getLocationsRequestState,
      exportSnapRequestState,
    },
    handlers: {
      handlePageChange,
      handleSort,
      handleSearch,
      handleOpenFilterClicked,
      handleCloseFilter,
      handleCreateSnapCycleClicked,
      handleCreateSnapCycle,
      handleExportClicked,
      handleProviderChange
    },
  };
};
