import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";
import { createNumberRange } from "../helpers";

interface Props {
  rows: number;
  columns: number;
}

const TableLoadingPlaceholder: React.FC<Props> = ({ rows, columns }) => {
  return (
    <Fragment>
      {createNumberRange(rows).map((i) => (
        <TableRow key={`table-loading-${i}`}>
          {createNumberRange(columns).map((j) => (
            <TableCell key={`cell-${i}-${j}`}>
              <Skeleton variant="rect" animation="pulse" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Fragment>
  );
};

export default TableLoadingPlaceholder;
