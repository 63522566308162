import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const config = {
  connectionString: 'InstrumentationKey=c40ac3ae-bffb-44fe-b413-684e41417731;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/'
};

export const appInsights = new ApplicationInsights({ config });

export const initializeAppInsights = () => {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export const registerUserInformation = (email: string, id: string) => {
  appInsights.setAuthenticatedUserContext(email, id, true);
}


