import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectModalId } from "../../features/modal/state/modalSelectors";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ErrorText from "../../app/components/ErrorText";
import ControlledInput from "../../app/components/inputs/ControlledInput";
import SpinnerButton from "../../app/components/buttons/SpinnerButton";
import { closeModal } from "../../features/modal/state/modalSlice";
import { useAddServicePlanReviewForm } from "../hooks/useAddServicePlanReviewForm";
import ControlledCheckbox from "../../app/components/inputs/ControlledCheckbox";
import NameLabel from "../../app/components/labels/NameLabel";
import { getRoleDisplayText } from "../enums/ServicePlanParticipantRolesEnum";
import { useServicePlanPageContext } from "../ServicePlanPageContext";

export const addServicePlanReviewFormDialogId =
  "add-service-plan-review-dialog";

export const AddServicePlanGoalFormDialog: React.FC = () => {
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { servicePlan, youth } = useServicePlanPageContext();

  const {
    state: {
      addReviewRequestState: { isLoading, error },
    },
    form: { isValid, clearForm, control },
    handlers: { handleSubmit },
  } = useAddServicePlanReviewForm();

  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  return (
    <Dialog
      open={modalId === addServicePlanReviewFormDialogId}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Add a 30 Day Review
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="reviewedOn"
                label="Plan Reviewed On"
                type="date"
                control={control}
                disabled={isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color={"primary"}>Review Participants</Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Participated</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"youth"}>
                      <TableCell>
                        <NameLabel nameDto={youth} />
                      </TableCell>
                      <TableCell>
                        <Typography component="span">Youth</Typography>
                      </TableCell>
                      <TableCell>
                        <ControlledCheckbox
                          color={"primary"}
                          control={control}
                          name={"youthParticipated"}
                          disabled={isLoading}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={"counselor"}>
                      <TableCell>
                        <NameLabel nameDto={servicePlan.counselor} />
                      </TableCell>
                      <TableCell>
                        <Typography component="span">Counselor</Typography>
                      </TableCell>
                      <TableCell>
                        <ControlledCheckbox
                          color={"primary"}
                          control={control}
                          name={"counselorParticipated"}
                          disabled={isLoading}
                        />
                      </TableCell>
                    </TableRow>
                    {servicePlan.participants.map((p) => (
                      <TableRow key={p.id}>
                        <TableCell>
                          <Typography component="span">{p.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component="span">
                            {getRoleDisplayText(p.role)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <ControlledCheckbox
                            color={"primary"}
                            control={control}
                            name={p.id!}
                            disabled={isLoading}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography color={"primary"}>Review Notes</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="notes"
                label="Notes"
                control={control}
                disabled={isLoading}
                fullWidth
                multiline
                minRows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
