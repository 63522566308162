import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { YouthSearchParamsDto } from "../dto/YouthSearchParamsDto";
import { useForm } from "react-hook-form";
import { YouthFilterFormDto } from "../dto/YouthFilterFormDto";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ControlledSwitch from "../../../app/components/inputs/ControlledSwitch";

interface Props {
  params: YouthSearchParamsDto;
  anchorEl: HTMLButtonElement | null;
  onClose(): void;
  onSearch(dto: YouthSearchParamsDto): void;
}

const YouthFilter: React.FC<Props> = ({
  params,
  anchorEl,
  onClose,
  onSearch,
}) => {
  //#region State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: new YouthFilterFormDto(params),
  });
  //#endregion

  //#region handlers
  const handleClearAllFilters = () => {
    reset(new YouthFilterFormDto());
  };

  const handleOnSubmit = (dto: YouthFilterFormDto) => {
    const newParams: YouthSearchParamsDto = {
      ...dto,
      page: 0,
      pageSize: params.pageSize,
    };
    onSearch(newParams);
    onClose();
  };
  //#endregion

  useEffect(() => {
    reset(new YouthFilterFormDto(params));
  }, [reset, params]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "400px" }}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">Youth Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={handleClearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="firstName"
                label="First Name"
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="lastName"
                label="Last Name"
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="dob"
                label="DOB"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="referenceNumber"
                label="Youth ID"
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledWholeNumberInput
                name="djjIdNumber"
                label="DJJ ID Number"
                control={control}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSwitch
                name="activeOnly"
                label="Active Only"
                control={control}
                color="primary"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default YouthFilter;
