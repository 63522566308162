import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { ProgramTypeSummaryDto } from "../program-types/dtos/ProgramTypeSummaryDto";
import { AddCaseToSnapGroupFormDto } from "./dtos/AddCaseToSnapGroupFormDto";
import { SnapGroupCycleDto } from "./dtos/SnapGroupCycleDto";
import { SnapGroupCycleFormDto } from "./dtos/SnapGroupCycleFormDto";
import { SnapGroupCycleSearchParamsDto } from "./dtos/SnapGroupCycleSearchParamsDto";
import { SnapGroupCycleSummaryDto } from "./dtos/SnapGroupCycleSummaryDto";
import { SnapFidelityFormDto } from "./dtos/SnapFidelityFormDto";
import { SnapGroupDto } from "./dtos/SnapGroupDto";
import { SnapGroupFormDto } from "./dtos/SnapGroupFormDto";
import { SnapGroupSummaryDto } from "./dtos/SnapGroupSummaryDto";
import { SnapCycleGenderEnum } from "./enums/SnapGroupCycleGenderEnum";
import { SnapCurriculumDto } from "./dtos/SnapCurriculumDto";
import { AlertDto } from "../alerts/dtos/AlertDto";
import { SetMadeCompliancePhoneCallDto } from "./dtos/SetMadeCompliancePhoneCallDto";

interface GetSnapCurriculumParams {
  programTypeId: string;
  gender: SnapCycleGenderEnum;
}

interface CreateSnapGroupRequestParams {
  id: string;
  dto: SnapGroupFormDto;
}

interface UpdateSnapGroupRequestParams {
  id: string;
  dto: SnapGroupFormDto;
}

interface AddCasesToSnapGroupRequestParams {
  id: string;
  dto: AddCaseToSnapGroupFormDto;
}

interface UpsertSnapFidelityRequestParams {
  id: string;
  dto: SnapFidelityFormDto;
}

interface UpdateSnapGroupCycleRequestParams {
  id: string;
  dto: SnapGroupCycleFormDto;
}

export const getSnapGroupCyclesRequest = (
  searchParams: SnapGroupCycleSearchParamsDto
) =>
  coreAgent.getRequest<PaginationResultDto<SnapGroupCycleSummaryDto>>(
    `snap/cycles?${SnapGroupCycleSearchParamsDto.toQueryString(searchParams)}`
  );

export const getSnapGroupCycleRequest = (id: string) =>
  coreAgent.getRequest<SnapGroupCycleDto>(`snap/cycles/${id}`);

export const createSnapGroupCycleRequest = (dto: SnapGroupCycleFormDto) =>
  coreAgent.postRequest<SnapGroupCycleDto>(`snap/cycles`, dto);

export const updateSnapGroupCycleRequest = ({
  id,
  dto,
}: UpdateSnapGroupCycleRequestParams) =>
  coreAgent.putRequest<SnapGroupCycleDto>(`snap/cycles/${id}`, dto);

export const createSnapGroupRequest = ({
  id,
  dto,
}: CreateSnapGroupRequestParams) =>
  coreAgent.postRequest<SnapGroupSummaryDto>(`snap/cycles/${id}/groups`, dto);

export const updateSnapGroupRequest = ({
  id,
  dto,
}: UpdateSnapGroupRequestParams) =>
  coreAgent.putRequest<SnapGroupSummaryDto>(`snap/groups/${id}`, dto);

export const getSnapGroupRequest = (id: string) =>
  coreAgent.getRequest<SnapGroupDto>(`snap/groups/${id}`);

export const addCasesToSnapGroupRequest = ({
  id,
  dto,
}: AddCasesToSnapGroupRequestParams) =>
  coreAgent.postRequest<SnapGroupDto>(`snap/groups/${id}/cases`, dto);

export const removeSnapGroupCycleRequest = (id: string) =>
  coreAgent.deleteRequest<void>(`snap/cycles/${id}`);

export const removeSnapGroupRequest = (id: string) =>
  coreAgent.deleteRequest<void>(`snap/groups/${id}`);

export const removeCaseFromSnapGroupRequest = (id: string) =>
  coreAgent.deleteRequest<void>(`snap/groupcases/${id}`);

export const getSnapProgramTypesRequest = () =>
  coreAgent.getRequest<ProgramTypeSummaryDto[]>(`snap/programTypes`);

export const upsertSnapGroupFidelityRequest = ({
  id,
  dto,
}: UpsertSnapFidelityRequestParams) =>
  coreAgent.postRequest(`/snap/groups/${id}/fidelity`, dto);

export const getSnapCurriculumRequest = ({
  programTypeId,
  gender,
}: GetSnapCurriculumParams) =>
  coreAgent.getRequest<SnapCurriculumDto[]>(
    `/snap/curriculums/${programTypeId}/${gender}`
  );

export const getSnapGroupCycleAlertsRequest = (id: string) =>
  coreAgent.getRequest<AlertDto[]>(`/snap/cycles/${id}/alerts`);

export const setMadeCompliancePhoneCallRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: SetMadeCompliancePhoneCallDto;
}) => coreAgent.putRequest(`/snap/groupcases/${id}/compliancePhoneCall`, dto);

export const exportSnapRequest = ({
  searchParams,
  filename,
}: {
  searchParams: SnapGroupCycleSearchParamsDto;
  filename: string;
}) =>
  coreAgent.downloadFileRequest(
    `snap/export?${SnapGroupCycleSearchParamsDto.toQueryString(searchParams)}`,
    filename
  );