import { Box, Card, CardContent } from "@material-ui/core";
import React from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import ForwardReferralFormDialog from "../components/ForwardReferralFormDialog";
import ReferralDetails from "../components/ReferralDetails";
import RemoveReferralFormDialog from "../components/RemoveReferralFormDialog";
import ScreeningFromReferralFormDialog from "../components/screening-from-referral/ScreeningFromReferralFormDialog";
import { useReferralDetailPage } from "../hooks/useReferralDetailPage";
import ReferralsAdditionalActions from "../ReferralAdditionalActions";
import { Alert } from "@material-ui/lab";
import { ReferralStatusEnum } from "../enums/ReferralStatusEnum";

const ReferralDetailPage = () => {
  const { state, handlers } = useReferralDetailPage();
  const {
    referral,
    getReferralRequestState,
    forwardReferralRequestState,
    createScreeningRequestState,
    removeReferralRequestState,
  } = state;
  const {
    handleForwardReferral,
    handleForwardReferralClicked,
    handleStartScreeningClicked,
    handleCreateScreening,
    // Remove Referral
    handleRemoveReferral,
    handleRemoveReferralClicked,
  } = handlers;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ForwardReferralFormDialog
        onSubmit={handleForwardReferral}
        requestState={forwardReferralRequestState}
        currentRoute={referral?.referralRoutes.find(
          (x) => x.forwardReferralRouteId === undefined
        )}
      />
      <ScreeningFromReferralFormDialog
        referral={referral}
        onSubmit={handleCreateScreening}
        requestState={createScreeningRequestState}
      />
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Referrals}>
        <RemoveReferralFormDialog
          requestState={removeReferralRequestState}
          onSubmit={handleRemoveReferral}
        />
      </WriteAccessLevelWrapper>
      <Box marginBottom="12px">
        <BackButton route={`/referrals`} text="Back to referrals" />
      </Box>
      <Header
        title={`Referral ${referral?.referralNumber || ""}`}
        isLoading={getReferralRequestState.isLoading}
        actions={
          <ReferralsAdditionalActions
            referral={referral}
            onRemoveReferralClicked={handleRemoveReferralClicked}
          />
        }
      />
      
      {
      referral?.status === ReferralStatusEnum.Pending &&
        <Alert severity="info" style={{ marginBottom:"8px" }}>
          If services requested are not provided, please utilize the "forward" feature below to forward the referral to another agency or the Florida Network.
        </Alert>
      }

      <Card variant="outlined" style={{ flex: 1, overflow: "auto" }}>
        <CardContent>
          <ReferralDetails
            referral={referral}
            isLoading={getReferralRequestState.isLoading}
            onForwardClick={handleForwardReferralClicked}
            onStartScreeningClick={handleStartScreeningClicked}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReferralDetailPage;
