import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditButton from "../../../../app/components/buttons/EditButton";
import DateTimeLabel from "../../../../app/components/labels/DateTimeLabel";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import DischargeStatusLabel from "../../../discharges/components/labels/DischargeStatusLabel";
import EmploymentStatusLabel from "../../../discharges/components/labels/EmploymentStatusLabel";
import SchoolProgramLabel from "../../../discharges/components/labels/SchoolProgramLabel";
import SchoolStatusLabel from "../../../discharges/components/labels/SchoolStatusLabel";
import LivingSituationLabel from "../../../screenings/components/LivingSituationLabel";
import { useCaseDetailPageContext } from "../../pages/CaseDetailPage";

export const caseDischargeSectionName = "case-discharge-section";

const CaseDischargeSubSection = () => {
  //#region State
  const {
    case: { caseResult },
    discharge: { handleUpsertCaseDischargeClicked },
  } = useCaseDetailPageContext();
  //#endregion

  const dischargeInfo = caseResult?.dischargeInfo;

  //#region UI Helpers
  const createRow = (label: string, content: React.ReactNode) => {
    return (
      <Fragment>
        <Grid item md={3} xs={4}>
          <Typography>{label}:</Typography>
        </Grid>
        <Grid item md={9} xs={8}>
          {content}
        </Grid>
      </Fragment>
    );
  };
  //#endregion

  if (dischargeInfo === undefined) return <></>;

  return (
    <Fragment>
      {createRow(
        "Discharge Date/Time",
        <Box position={"relative"}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography>
              <DateTimeLabel date={dischargeInfo.dischargedAt} />
            </Typography>
          </Box>
          <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
            <Box position={"absolute"} top="4px" right="4px">
              <EditButton onClick={handleUpsertCaseDischargeClicked}>
                Edit
              </EditButton>
            </Box>
          </WriteAccessLevelWrapper>
        </Box>
      )}
      {createRow(
        "Discharge Staff",
        <Typography>
          {dischargeInfo.exitCounselor.firstName}{" "}
          {dischargeInfo.exitCounselor.lastName}
        </Typography>
      )}
      {createRow(
        "Discharge Status",
        <DischargeStatusLabel status={dischargeInfo.status} />
      )}
      {createRow(
        "Living Situation",
        <LivingSituationLabel livingSituation={dischargeInfo.livingSituation} />
      )}
      {createRow(
        "School Program",
        <SchoolProgramLabel program={dischargeInfo.schoolProgram} />
      )}
      {createRow(
        "School Status at Discharge",
        <SchoolStatusLabel status={dischargeInfo.schoolStatus} />
      )}
      {dischargeInfo.employmentStatus &&
        createRow(
          "Employment Status",
          <EmploymentStatusLabel status={dischargeInfo.employmentStatus} />
        )}
      {createRow(
        "Baker Act Discharge",
        <Typography>{dischargeInfo.isBakerAct ? "Yes" : "No"}</Typography>
      )}
      {createRow(
        "Notes",
        <Paper variant="outlined">
          <Box height="375px" padding="6px" overflow="auto">
            <Typography>{dischargeInfo.notes}</Typography>
          </Box>
        </Paper>
      )}
    </Fragment>
  );
};

export default CaseDischargeSubSection;
