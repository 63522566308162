import { Divider, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import { DemographicFormDto } from "../dtos/DemographicFormDto";
import { demographicsFamilyWeeklyIncomeOptions } from "../enums/DemographicsFamilyWeeklyIncomeEnum";
import { demographicsHeadOfHouseholdOptions } from "../enums/DemographicsHeadOfHouseholdEnum";
import { demographicsMaritalStatusOptions } from "../enums/DemographicsMaritalStatusEnum";
import { demographicsParentalOptions } from "../enums/DemographicsParentalStatusEnum";
import * as Yup from "yup";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
import { DemographicsFamilyStructureEnum, DemographicsFamilyStructureEnumOptions } from "../enums/DemographicsFamilyStructureEnum";

export const demographicSchema = Yup.object()
  .shape({
    parentalStatus: Yup.string(),
    maritalStatus: Yup.string(),
    numberOfHouseholdMembers: Yup.string().test(
      "test-min-household-members",
      "# of Household Members must be at least 1.",
      function (value) {
        if (value == null || value === "" || isNaN(+value)) return true;

        return +value > 0;
      }
    ),
    headOfHousehold: Yup.string(),
    fatherEmployed: Yup.string(),
    motherEmployed: Yup.string(),
    familyWeeklyIncome: Yup.string(),
    familyStructure: Yup.string()
      .label("Family Structure")
      .when("fundingSource", {
        is: ProgramFundingSourceEnum.FloridaNetwork,
        then: (schema) => schema.required(),
      }),
  })
  .test(
    "test-one-value-filled-out",
    "Demographics must have at least one value filled out.",
    function (values) {
      return Object.values(values).some((value) => value !== "");
    }
  );

interface Props {
  control: Control<DemographicFormDto>;
  isDisabled: boolean;
  isLegacy: boolean;
}

const createYesNoOptions = (defaultLabel: string): SelectOption[] => [
  { value: "", label: defaultLabel },
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

const DemographicFormFields: React.FC<Props> = ({ control, isDisabled, isLegacy }) => {
  const { fundingSource } = useWatch({ control });
  const isFnFunded = fundingSource === ProgramFundingSourceEnum.FloridaNetwork;

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="parentalStatus"
          label="Is the Youth a parent?"
          disabled={isDisabled}
          options={demographicsParentalOptions}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="maritalStatus"
          label="Youth Marital Status"
          disabled={isDisabled}
          options={demographicsMaritalStatusOptions}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography color="primary">Household Information</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="familyStructure"
          label={`Family Structure${isFnFunded ? "*" : ""}`}
          disabled={isDisabled}
          options={DemographicsFamilyStructureEnumOptions.filter(o => isLegacy || o.value !== DemographicsFamilyStructureEnum.NotSpecified)}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledWholeNumberInput
          control={control}
          name="numberOfHouseholdMembers"
          label="# of Household Members"
          disabled={isDisabled}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="headOfHousehold"
          label="Head of Household"
          disabled={isDisabled}
          options={demographicsHeadOfHouseholdOptions}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="familyWeeklyIncome"
          label="Family Weekly Income"
          disabled={isDisabled}
          options={demographicsFamilyWeeklyIncomeOptions}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="fatherEmployed"
          label="Father Employed"
          disabled={isDisabled}
          options={createYesNoOptions("Is Father Employed")}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="motherEmployed"
          label="Mother Employed"
          disabled={isDisabled}
          options={createYesNoOptions("Is Mother Employed")}
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export default DemographicFormFields;
