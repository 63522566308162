import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { AddCaseToSnapGroupFormDto } from "../dtos/AddCaseToSnapGroupFormDto";
import { useAddCasesToGroupForm } from "../hooks/useAddCasesToGroupForm";
import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import { SnapGroupDto } from "../dtos/SnapGroupDto";
import AddCasesToGroupFormFields from "./AddCasesToGroupFormFields";
import EditButton from "../../../app/components/buttons/EditButton";
import InfoTooltip from "../../../app/components/InfoTooltip";
import DateLabel from "../../../app/components/labels/DateLabel";

export const addCasesToGroupModalId = "snap-group-add-cases-form-modal";

interface Props {
  group?: SnapGroupDto;
  onSubmit: (dto: AddCaseToSnapGroupFormDto) => Promise<any>;
  requestState: RequestStateDto;
  snapCycle: SnapGroupCycleDto;
}

const AddCasesToGroupFormDialog: React.FC<Props> = ({
  group,
  onSubmit,
  requestState,
  snapCycle,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { state, handlers, form } = useAddCasesToGroupForm(
    onSubmit,
    snapCycle,
    group
  );
  const {
    eligibleCases,
    casesToAdd,
    previousGroup,
    getPreviousSnapGroupRequestState,
  } = state;
  const {
    handleAddCase,
    handleRemoveCase,
    handleSubmit,
    handlePrepopulateYouth,
  } = handlers;
  const { clearForm, isValid } = form;

  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === addCasesToGroupModalId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Add Youth To Group
          <ErrorText error={error?.message} />
          <ErrorText error={getPreviousSnapGroupRequestState.error?.message} />
        </DialogTitle>
        <DialogContent>
          <AddCasesToGroupFormFields
            disabled={getPreviousSnapGroupRequestState.isLoading || isLoading}
            eligibleCases={eligibleCases}
            casesToAdd={casesToAdd}
            onAddCase={handleAddCase}
            onRemoveCase={handleRemoveCase}
          />
          <Box marginTop="12px" display="flex" alignItems="center">
            {previousGroup && (
              <Fragment>
                <EditButton
                  text="Copy Previous Group"
                  onClick={handlePrepopulateYouth}
                />
                <InfoTooltip
                  style={{ cursor: "pointer" }}
                  title={
                    <Typography>
                      This will pre-populate the Added Youth field from the
                      previous group date{" "}
                      <DateLabel date={previousGroup.groupHeldOn} />
                    </Typography>
                  }
                />
              </Fragment>
            )}
          </Box>
          <Typography>
            <strong>Note:</strong> Eligible Youth includes youth enrolled in a Florida Network funded {snapCycle.programType.name} Program as of the Group Date.
          </Typography>
          <br/>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || getPreviousSnapGroupRequestState.isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCasesToGroupFormDialog;
