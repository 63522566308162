import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useGetProviders } from "../../provider/hooks/providerHooks";
import { useGetRoles } from "../../roles/hooks/roleHooks";
import { providerRoleSchema } from "../components/provider-roles/ProviderRoleFormFields";
import {
  AddProviderRoleFormDto,
  createAddProviderRoleFormDto,
} from "../dtos/AddProviderRoleFormDto";

export const useProviderRoleForm = (
  onSubmit: (dto: AddProviderRoleFormDto) => Promise<any>
) => {
  //#region State
  const [
    getProviders,
    providersResult,
    getProvidersRequestState,
    clearGetProvidersError,
  ] = useGetProviders();

  const [getRoles, roles, getRolesRequestState, clearGetRolesError] =
    useGetRoles();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createAddProviderRoleFormDto(),
    resolver: yupResolver(providerRoleSchema),
    mode: "all",
  });
  //#endregion

  //#region Handlers

  const clearForm = () => {
    reset(createAddProviderRoleFormDto());
    clearGetProvidersError();
    clearGetRolesError();
  };
  const handleOnSubmit = handleSubmit(async (dto: AddProviderRoleFormDto) => {
    await onSubmit(dto);
    clearForm();
  });
  //#endregion

  //#region Effects
  useEffect(() => {
    getProviders({ sortBy: "name" });
  }, [getProviders]);

  useEffect(() => {
    getRoles(false);
  }, [getRoles]);
  //#endregion

  return {
    state: {
      providers: providersResult?.items || [],
      getProvidersRequestState,
      roles: roles || [],
      getRolesRequestState,
    },
    form: {
      isValid,
      control,
      clearForm,
    },
    handlers: {
      handleSubmit: handleOnSubmit,
    },
  };
};
