import React, { useState } from "react";

export const useInvoiceListPage = () => {

  const [tabIndex, setTabIndex] = useState("provider-invoices");

  const handleTabChange = (e: React.ChangeEvent<any>, tabIndex: string) => {
    setTabIndex(tabIndex);
  };

  return {
    state: {
      tabIndex,
    },
    handlers: {
      handleTabChange,
    },
  };
};
