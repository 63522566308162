import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import Header from "../../../app/components/Header";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { useSnapInSchoolsCycleListPage } from "../hooks/useSnapInSchoolsCycleListPage";
import SnapInSchoolsCycleFormDialog from "../components/SnapInSchoolsCycleFormDialog";
import SnapInSchoolsCycleList from "../components/SnapInSchoolsCycleList";
import SnapInSchoolsCycleFilter from "../components/SnapInSchoolsCycleFilter";
import SnapInSchoolsCycleFilterList from "../components/SnapInSchoolsCycleFilterList";
import FilterButton from "../../../app/components/buttons/FilterButton";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { SnapInSchoolsCycleSummaryDto } from "../dtos/SnapInSchoolsCycleSummaryDto";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";

const SnapInSchoolsCycleListPage = () => {
  const { state, handlers } = useSnapInSchoolsCycleListPage();
  const {
    filterAnchorEl,
    snapCyclesResult,
    params,
    getSnapCyclesRequestState,
    createSnapCycleRequestState,
    providers,
    getProvidersRequestState,
    getProgramsRequestState,
    providerPrograms,
  } = state;

  const {
    handlePageChange,
    handleSearch,
    handleOpenFilterClicked,
    handleCloseFilter,
    handleCreateSnapCycleClicked,
    handleCreateSnapCycle,
    handleSort,
    handleProviderChange
  } = handlers;

  return (
    <div>
      <SnapInSchoolsCycleFormDialog
        onSubmit={handleCreateSnapCycle}
        requestState={createSnapCycleRequestState}
      />
      <RequestErrorAlert
        requests={[getSnapCyclesRequestState, getProvidersRequestState, getProgramsRequestState]}
      />
      <Header
        title="SNAP In Schools and Communities Cycles"
        actions={
          <>
            <FilterButton
              title="Filter List"
              onClick={handleOpenFilterClicked}
              disabled={getProvidersRequestState.isLoading}
            />
            <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
              <AddButton
                className="ml-2"
                onClick={handleCreateSnapCycleClicked}
                text="Create SISC Cycle"
                variant="contained"
                color="primary"
                disabled={
                  getSnapCyclesRequestState.isLoading ||
                  createSnapCycleRequestState.isLoading
                }
              />
            </WriteAccessLevelWrapper>
            <SnapInSchoolsCycleFilter
              params={params}
              onSearch={handleSearch}
              providers={providers}
              providerPrograms={providerPrograms}
              onClose={handleCloseFilter}
              anchorEl={filterAnchorEl}
              onProviderChange={handleProviderChange}
            />
          </>
        }
        secondaryActions={
          <>
            <SnapInSchoolsCycleFilterList
              providers={providers}
              onSearch={handleSearch}
              params={params}
              isLoading={getSnapCyclesRequestState.isLoading}             />
          </>
        }
      />

      <SnapInSchoolsCycleList
        results={
          snapCyclesResult ||
          new PaginationResultDto<SnapInSchoolsCycleSummaryDto>()
        }
        isLoading={getSnapCyclesRequestState.isLoading}
        params={params}
        onPageChange={handlePageChange}
        onSort={handleSort}
      />
    </div>
  );
};

export default SnapInSchoolsCycleListPage;
