import { Typography } from "@material-ui/core";
import React from "react";
import {ColorsEnum} from "../../../../app/enums/ColorsEnum";
import ErrorText from "../../../../app/components/ErrorText";
import {RequestStateDto} from "../../../../app/dtos/RequestStateDto";
import ConfirmDialog from "../../../../app/components/modals/ConfirmDialog";



interface Props {
  requestState: RequestStateDto;
  onConfirm(): Promise<void>;
}

export const confirmDeleteYouthContactDialogId = "delete-youth-contact-dialog";
const ConfirmDeleteYouthContactDialog: React.FC<Props> = ({
                                                                onConfirm,
                                                                requestState,
                                                              }) => {

  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={() => {
        onConfirm();
      }}
      modalId={confirmDeleteYouthContactDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Youth Contact"
      confirmButtonStyle={{
        color: ColorsEnum.Red,
      }}
      maxWidth="md"
    >
      <ErrorText error={error?.message} />
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to delete this Contact?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteYouthContactDialog;
