import { useRequest } from "../../../app/hooks/useRequest";
import {
  getProviderFilledBedDaysRequest,
  upsertProviderFilledBedDaysRequest,
} from "../ProviderFilledBedDayRequests";

export const useGetProviderFilledBedDays = () =>
  useRequest(getProviderFilledBedDaysRequest);
export const useUpsertProviderFilledBedDays = () =>
  useRequest(upsertProviderFilledBedDaysRequest);
