import { ReferrerDto } from "./RefererDto";

export interface ReferrerFormDto {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  source: string;
  relationship?: string;
}

export const createReferrerFormDto = (
  referrer?: ReferrerDto
): ReferrerFormDto => {
  if (referrer) return { ...referrer };

  return {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    source: "",
    relationship: "",
  };
};
