import { Avatar, Box, Paper, Typography } from "@material-ui/core";
import DateLabel from "../../../../../../app/components/labels/DateLabel";
import AssessmentLink from "../../../../../assessments/components/AssessmentLink";
import { AssessmentDto } from "../../../../../assessments/dtos/AssessmentDto";
import { AssessmentParentTypeEnum } from "../../../../../assessments/enums/AssessmentParentTypeEnum";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssessmentStatusLabel from "../../../../../assessments/components/AssessmentStatusLabel";
import AssessmentAmendmentBadge from "../../../../../assessments/components/AssessmentAmendmentBadge";
import { assessmentTypeTextMap } from "../../../../../assessments/enums/AssessmentTypeEnum";
import EditButton from "../../../../../../app/components/buttons/EditButton";

interface Props {
  assessments: AssessmentDto[];
  onViewScoresClicked(): void;
  onViewAmendmentsClicked(): void;
}

const NirvanaAssessmentListItem: React.FC<Props> = ({
  assessments,
  onViewScoresClicked,
  onViewAmendmentsClicked,
}) => {
  const assessment = assessments[0];
  const amendmentParent = assessments.find(
    (x) =>
      x.id === assessment.parentId &&
      assessment.parentType === AssessmentParentTypeEnum.AmendmentOf
  );

  return (
    <Paper variant="outlined" style={{ marginBottom: "12px" }}>
      <Box display="flex" padding="12px">
        <Avatar>
          <AssessmentIcon />
        </Avatar>
        <Box marginLeft="12px">
          <Box display="flex">
            <AssessmentLink assessment={assessment} variant="h6" />
            <span style={{ marginLeft: "6px" }}>
              <AssessmentStatusLabel assessment={assessment} />
            </span>
            <Box marginLeft="6px">
              <AssessmentAmendmentBadge assessment={amendmentParent} />
            </Box>
          </Box>
          <Typography color="textSecondary">
            Completed On: <DateLabel date={assessment.completedOn} />
          </Typography>
          <Typography color="textSecondary">
            Type: {assessmentTypeTextMap[assessment.type]}
          </Typography>
        </Box>
        <Box marginLeft="auto">
          {assessment.scores.length !== 0 && (
            <EditButton text="View Scores" onClick={onViewScoresClicked} />
          )}
          {amendmentParent != null && (
            <EditButton
              text="View Amendments"
              onClick={onViewAmendmentsClicked}
            />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default NirvanaAssessmentListItem;
