import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import ErrorAlert from "../../../app/components/ErrorAlert";
import Header from "../../../app/components/Header";
import NirvanaExportReportFilter from "../components/NirvanaExportReportFilter";
import { useNirvanaExportReportPage } from "../hooks/useNirvanaExportReportPage";

const NirvanaExportReportPage = () => {
  const { state, handlers } = useNirvanaExportReportPage();
  const { params, downloadNirvanaExportReportRequestState, isFnyfsUser } =
    state;
  const { handleSearch } = handlers;
  return (
    <div>
      <BackButton route="/reports" style={{ marginTop: "24px" }} />
      <Header title="NIRVANA Export" />
      <ErrorAlert error={downloadNirvanaExportReportRequestState.error?.message} />
      <Box className="my-2">
        <Typography>
          Select a date range{isFnyfsUser && ", Provider(s) (optional)"} and
          "Download" for an Excel report of administered NIRVANAs.
        </Typography>
      </Box>
      <Paper
        style={{
          padding: "16px",
        }}
      >
        <Box>
          <NirvanaExportReportFilter
            onSearch={handleSearch}
            params={params}
            isLoading={downloadNirvanaExportReportRequestState.isLoading}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default NirvanaExportReportPage;
