import { Box, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { WaitlistDto } from "../dtos/WaitlistDto";
import StyledLink from "../../../app/components/StyledLink";
import InfoTooltip from "../../../app/components/InfoTooltip";
import { useHistory, useLocation } from "react-router-dom";
import ProviderWriteAccessLevelWrapper from "../../../app/components/access-wrappers/ProviderWriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import WaitlistStatusLabel from "./WaitlistStatusLabel";
import { WaitlistStatusEnum } from "../enums/WaitlistStatusEnum";

interface Props {
  waitlist: WaitlistDto;
  onRemoveWaitlistClick(): void;
}

const WaitlistListItem: React.FC<Props> = ({
  waitlist,
  onRemoveWaitlistClick,
}) => {
  //#region State
  const location = useLocation();
  const history = useHistory();
  //#endregion

  //#region UI Helpers
  const createRow = (
    label: string,
    value: React.ReactNode,
    hide: boolean = false
  ) => {
    if (hide === true) {
      return <Fragment></Fragment>;
    }

    return (
      <Box display="flex" justifyContent="space-between">
        <Typography
          style={{
            marginRight: "8px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {label}:
        </Typography>
        <Typography>{value}</Typography>
      </Box>
    );
  };
  //#endregion

  return (
    <TableRow>
      <TableCell>
        <StyledLink
          to={{
            pathname: `/screenings/${waitlist.screening.id}`,
            state: {
              prevLocation: location.pathname,
              text: "Back to waitlist",
            },
          }}
        >
          {waitlist.screening.referenceNumber}
        </StyledLink>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <StyledLink
            to={{
              pathname: `/youths/${waitlist.youth.id}`,
              state: {
                prevLocation: location.pathname,
                text: "Back to waitlist",
              },
            }}
          >
            {waitlist.youth.firstName} {waitlist.youth.lastName}
          </StyledLink>
          <span style={{ marginLeft: "4px" }}>
            <InfoTooltip
              title={
                <Fragment>
                  {createRow("Youth ID", waitlist.youth.referenceNumber)}
                  {waitlist.youth.djjIdNumber &&
                    createRow("DJJID", waitlist.youth.djjIdNumber)}
                </Fragment>
              }
            />
          </span>
        </Box>
      </TableCell>
      <TableCell>{waitlist.provider.name}</TableCell>
      <TableCell>{waitlist.program.name}</TableCell>
      <TableCell>{waitlist.location.name}</TableCell>
      <TableCell>
        <WaitlistStatusLabel waitlist={waitlist} />
      </TableCell>
      <TableCell>
        <ProviderWriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          {waitlist.status === WaitlistStatusEnum.Pending && (
            <StyledLink
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                history.push(
                  `/youths/${waitlist.youth.id}/cases/new?screeningId=${waitlist.screening.id}&programId=${waitlist.program.id}&locationId=${waitlist.location.id}`,
                  {
                    prevLocation: location.pathname,
                    text: "Back to waitlist",
                  }
                );
              }}
              to=""
            >
              Start Case
            </StyledLink>
          )}
        </ProviderWriteAccessLevelWrapper>
      </TableCell>
      <TableCell>
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Waitlist}>
          {waitlist.status === WaitlistStatusEnum.Pending && (
            <StyledLink
              to=""
              onClick={(e) => {
                e.preventDefault();
                onRemoveWaitlistClick();
              }}
            >
              Remove
            </StyledLink>
          )}
        </WriteAccessLevelWrapper>
      </TableCell>
    </TableRow>
  );
};

export default WaitlistListItem;
