import React, { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { useTabIndex } from "../../../app/hooks/useTabIndex";
import { alertDismissalModalId } from "../../alerts/components/AlertDismissalFormDialog";
import { AlertDismissalRequestDto } from "../../alerts/dtos/AlertDismissalDto";
import { AlertDto } from "../../alerts/dtos/AlertDto";
import { AlertTypeEnum } from "../../alerts/enums/AlertTypeEnum";
import { useCreateAlertDismissal } from "../../alerts/hooks/alertHooks";
import { assessmentModalId } from "../../assessments/components/AssessmentFormDialog";
import { AssessmentDto } from "../../assessments/dtos/AssessmentDto";
import { AssessmentFormDto } from "../../assessments/dtos/AssessmentFormDto";
import { useCreateAssessment } from "../../assessments/hooks/assessmentHooks";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { confirmDeleteSnapInSchoolsSatisfactionSurveyDialogId } from "../../snap-in-schools-satisfaction-surveys/components/ConfirmDeleteSnapInSchoolsSatisfactionSurveyDialog";
import { snapInSchoolsSatisfactionSurveyModalId } from "../../snap-in-schools-satisfaction-surveys/components/SnapInSchoolsSatisfactionSurveyFormDialog";
import {
  useCreateSnapInSchoolsSatisfactionSurvey,
  useDeleteSnapInSchoolsSatisfactionSurvey,
  useUpdateSnapInSchoolsSatisfactionSurvey,
} from "../../snap-in-schools-satisfaction-surveys/hooks/snapInSchoolsSatisfactionSurveyHooks";
import {
  SnapInSchoolsSatisfactionSurveyDto,
  SnapInSchoolsSatisfactionSurveyFormDto,
} from "../../snap-in-schools-satisfaction-surveys/SnapInSchoolsSatisfactionSurveyDto";
import { confirmDeleteSnapInSchoolsCycleDialogId } from "../components/ConfirmDeleteSnapInSchoolsCycle";
import { confirmDeleteSnapInSchoolsSessionDialogId } from "../components/ConfirmDeleteSnapInSchoolsSessionDialog";
import { confirmDeleteFidelityAdherenceDialogId } from "../components/ConfrimDeleteSnapInSchoolsFidelityAdherenceChecklistDialog";
import { snapInSchoolsCycleModalId } from "../components/SnapInSchoolsCycleFormDialog";
import { snapInSchoolsFidelityAdherenceChecklistModalId } from "../components/SnapInSchoolsFidelityAdherenceChecklistFormDialog";
import { snapInSchoolsSessionModalId } from "../components/SnapInSchoolsSessionFormDialog";
import { SnapInSchoolsCycleDto } from "../dtos/SnapInSchoolsCycleDto";
import { SnapInSchoolsCycleFormDto } from "../dtos/SnapInSchoolsCycleFormDto";
import { SnapInSchoolsFidelityAdherenceChecklistFormDto } from "../dtos/SnapInSchoolsFidelityAdherenceChecklistDto";
import { SnapInSchoolsSessionDto } from "../dtos/SnapInSchoolsSessionDto";
import { SnapInSchoolsSessionFormDto } from "../dtos/SnapInSchoolsSessionFormDto";
import {
  useCreateSnapInSchoolsSession,
  useDeleteFidelityAdherenceChecklist,
  useDeleteSnapInSchoolsCycle,
  useDeleteSnapInSchoolsSession,
  useDownloadAttendanceLogDocument,
  useGetSnapInSchoolsCycle,
  useGetSnapInSchoolsCycleAlerts,
  useGetSnapInSchoolsCycleAssessments,
  useUpdateSnapInSchoolsCycle,
  useUpdateSnapInSchoolsSession,
  useUpsertFidelityAdherenceChecklist,
} from "./snapInSchoolsHooks";

export const useSnapInSchoolsCycleDetailPage = (
  selectedTab: string
): SnapInSchoolsCycleDetailPageProps => {
  //#region State
  const id = useId();
  const history = useHistory();
  const dispatch = useAppDispatch();
  //   useState<SnapSessionSummaryDto>();
  const [getAlerts, alerts, getAlertsRequestState] =
    useGetSnapInSchoolsCycleAlerts();
  //#endregion

  //#region Alerts
  // State
  const [selectedAlertType, setSelectedAlertType] = useState<AlertTypeEnum>(
    AlertTypeEnum.CaseNirvanaAssessmentRequired
  );

  // Requests
  const [
    createAlertDismissal,
    ,
    createAlertDismissalRequestState,
    clearCreateAlertDismissalErrors,
  ] = useCreateAlertDismissal();
  const handleCreateAlertDismissalClicked = (type: AlertTypeEnum) => {
    clearCreateAlertDismissalErrors();
    setSelectedAlertType(type);
    dispatch(openModal({ modalId: alertDismissalModalId }));
  };

  const handleCreateAlertDismissal = async (dto: AlertDismissalRequestDto) => {
    await createAlertDismissal(dto);
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "Alert Dismissed.",
        })
      );
      dispatch(closeModal());
    });

    await handleRefresh();
  };
  const alertProps: AlertProps = {
    alerts: alerts || [],
    selectedAlertType,
    getAlertsRequestState,
    createAlertDismissalRequestState,
    handleCreateAlertDismissal,
    handleCreateAlertDismissalClicked,
  };
  //#endregion

  //#region Snap In Schools Cycle
  // Requests
  const [getSnapCycle, snapCycle, getSnapCycleRequestState] =
    useGetSnapInSchoolsCycle();

  const [
    updateSnapInSchoolsCycle,
    ,
    updateSnapInSchoolsCycleRequestState,
    clearUpdateSnapInSchoolsCycleErrors,
  ] = useUpdateSnapInSchoolsCycle();

  const [
    deleteSnapInSchoolsCycle,
    ,
    deleteSnapInSchoolsCycleRequestState,
    clearDeleteSnapInSchoolsCycleErrors,
  ] = useDeleteSnapInSchoolsCycle();

  const handleRefresh = useCallback(async () => {
    await getAlerts(id);
    await getSnapCycle(id);
  }, [id, getAlerts, getSnapCycle]);

  // Handlers
  const handleEditSnapInSchoolsCycleClicked = () => {
    clearUpdateSnapInSchoolsCycleErrors();
    dispatch(openModal({ modalId: snapInSchoolsCycleModalId }));
  };

  const handleEditSnapInSchoolsCycle = async (
    dto: SnapInSchoolsCycleFormDto
  ) => {
    await updateSnapInSchoolsCycle({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({ message: "SNAP In Schools Cycle Updated" })
      );
      dispatch(closeModal());
    });
    await handleRefresh();
  };

  const handleDeleteSnapInSchoolsCycleClicked = () => {
    clearDeleteSnapInSchoolsCycleErrors();
    dispatch(openModal({ modalId: confirmDeleteSnapInSchoolsCycleDialogId }));
  };

  const handleDeleteSnapInSchoolsCycle = async () => {
    await deleteSnapInSchoolsCycle(id);
    batch(() => {
      dispatch(
        addSuccessNotification({ message: "SNAP In Schools Cycle Deleted" })
      );
      dispatch(closeModal());
    });
    history.push("/snap-in-schools");
  };

  const [
    upsertFidelityAdherenceChecklist,
    ,
    upsertFidelityAdherenceChecklistRequestState,
    clearUpsertFidelityAdherenceChecklist,
  ] = useUpsertFidelityAdherenceChecklist();

  const handleUpsertFidelityAdherenceChecklistClicked = () => {
    clearUpsertFidelityAdherenceChecklist();
    dispatch(
      openModal({ modalId: snapInSchoolsFidelityAdherenceChecklistModalId })
    );
  };

  const handleUpsertFidelityAdherenceChecklist = async (
    dto: SnapInSchoolsFidelityAdherenceChecklistFormDto
  ) => {
    await upsertFidelityAdherenceChecklist({ id, dto });
    batch(() => {
      const action = snapCycle?.fidelityAdherenceChecklist
        ? "Updated"
        : "Added";

      dispatch(
        addSuccessNotification({
          message: `SNAP In Schools Cycle Fidelity Adherence Checklist ${action}.`,
        })
      );
      dispatch(closeModal());
    });
    await handleRefresh();
  };

  const [
    deleteFidelityAdherenceChecklist,
    ,
    deleteFidelityAdherenceChecklistRequestState,
    clearDeleteFidelityAdherenceChecklist,
  ] = useDeleteFidelityAdherenceChecklist();
  const handleDeleteFidelityAdherenceChecklistClicked = () => {
    clearDeleteFidelityAdherenceChecklist();
    dispatch(openModal({ modalId: confirmDeleteFidelityAdherenceDialogId }));
  };

  const handleDeleteFidelityAdherenceChecklist = async () => {
    await deleteFidelityAdherenceChecklist(id);
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `SNAP In Schools Cycle Fidelity Adherence Checklist Deleted.`,
        })
      );
      dispatch(closeModal());
    });
    await handleRefresh();
  };

  const snapInSchoolsCycleProps: SnapInSchoolsCycleProps = {
    snapCycle,
    getSnapCycleRequestState,
    updateSnapInSchoolsCycleRequestState,
    deleteSnapInSchoolsCycleRequestState,
    upsertFidelityAdherenceChecklistRequestState,
    deleteFidelityAdherenceChecklistRequestState,
    handleEditSnapInSchoolsCycleClicked,
    handleEditSnapInSchoolsCycle,
    handleDeleteSnapInSchoolsCycle,
    handleDeleteSnapInSchoolsCycleClicked,
    handleUpsertFidelityAdherenceChecklistClicked,
    handleUpsertFidelityAdherenceChecklist,
    handleDeleteFidelityAdherenceChecklist,
    handleDeleteFidelityAdherenceChecklistClicked,
  };
  //#endregion

  //#region Snap In Schools Sessions
  // State
  const [selectedSnapInSchoolsSession, setSelectedSnapInSchoolsSession] =
    useState<SnapInSchoolsSessionDto>();

  // Requests
  const [downloadSnapSessionAttendanceLog] = useDownloadAttendanceLogDocument();

  const [
    createSnapSession,
    ,
    createSnapSessionRequestState,
    clearCreateSnapSessionErrors,
  ] = useCreateSnapInSchoolsSession();

  const [
    deleteSnapInSchoolsSession,
    ,
    deleteSnapInSchoolsSessionRequestState,
    clearDeleteSnapInSchoolsSessionErrors,
  ] = useDeleteSnapInSchoolsSession();

  const [
    updateSnapInSchoolsSession,
    ,
    updateSnapInSchoolsSessionRequestState,
    clearUpdateSnapInSchoolsSessionErrors,
  ] = useUpdateSnapInSchoolsSession();

  // Handlers
  const handleOpenSnapInSchoolsSessionFormDialog = (
    session?: SnapInSchoolsSessionDto
  ) => {
    setSelectedSnapInSchoolsSession(session);
    clearCreateSnapSessionErrors();
    clearUpdateSnapInSchoolsSessionErrors();
    dispatch(openModal({ modalId: snapInSchoolsSessionModalId }));
  };
  const handleCreateSnapSessionClicked = () =>
    handleOpenSnapInSchoolsSessionFormDialog();

  const handleUpdateSnapSessionClicked = (session: SnapInSchoolsSessionDto) =>
    handleOpenSnapInSchoolsSessionFormDialog(session);

  const handleUpsertSnapSession = async (dto: SnapInSchoolsSessionFormDto) => {
    selectedSnapInSchoolsSession
      ? await updateSnapInSchoolsSession({
          id: selectedSnapInSchoolsSession.id,
          dto,
        })
      : await createSnapSession({ id, dto });

    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `SNAP Session ${
            selectedSnapInSchoolsSession ? "Updated" : "Created"
          }`,
        })
      );
      dispatch(closeModal());
    });
    await handleRefresh();
  };

  const handleDeleteSnapInSchoolsSessionClicked = (
    dto: SnapInSchoolsSessionDto
  ) => {
    clearDeleteSnapInSchoolsSessionErrors();
    setSelectedSnapInSchoolsSession(dto);
    dispatch(openModal({ modalId: confirmDeleteSnapInSchoolsSessionDialogId }));
  };

  const handleDeleteSnapInSchoolsSession = async () => {
    await deleteSnapInSchoolsSession(selectedSnapInSchoolsSession!.id);
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "SNAP In Schools and Communities Session Deleted",
        })
      );
      dispatch(closeModal());
    });
    await handleRefresh();
  };
  const handleViewAttendanceLogDocument = async (
    session: SnapInSchoolsSessionDto
  ) => {
    await downloadSnapSessionAttendanceLog(session);
  };

  const snapInSchoolsSessionProps: SnapInSchoolsSessionProps = {
    upsertSnapSessionRequestState: selectedSnapInSchoolsSession
      ? updateSnapInSchoolsSessionRequestState
      : createSnapSessionRequestState,
    deleteSnapInSchoolsSessionRequestState,
    selectedSnapInSchoolsSession,
    handleUpsertSnapSession,
    handleCreateSnapSessionClicked,
    handleUpdateSnapSessionClicked,
    handleDeleteSnapInSchoolsSessionClicked,
    handleDeleteSnapInSchoolsSession,
    handleViewAttendanceLogDocument,
  };

  //#endregion

  //#region Assessments
  // requests
  const [
    startAssessment,
    ,
    startAssessmentRequestState,
    clearStartAssessmentErrors,
  ] = useCreateAssessment();

  const [getAssessments, assessments, getAssessmentsRequestState] =
    useGetSnapInSchoolsCycleAssessments();

  const handleStartAssessmentClicked = () => {
    clearStartAssessmentErrors();
    dispatch(openModal({ modalId: assessmentModalId }));
  };

  const handleOpenAssessment = (id: string) => {
    window.open(
      `${
        process.env.REACT_APP_ASSESSMENT_CLIENT_URL
      }/assessment/${id}?providerId=${getProviderId()}`
    );
  };

  const handleStartAssessment = async (dto: AssessmentFormDto) => {
    const result = await startAssessment(dto);
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `MoCE Assessment Started`,
        })
      );
      dispatch(closeModal());
    });

    if (result) {
      handleOpenAssessment(result.id);
    }
  };

  const handleRefreshAssessments = async () => {
    await getAssessments(id);
  };

  // Effects
  useEffect(() => {
    getAssessments(id);
  }, [getAssessments, id]);

  const assessmentProps: AssessmentProps = {
    startAssessmentRequestState,
    getAssessmentsRequestState,
    assessments: assessments || [],
    handleStartAssessmentClicked,
    handleStartAssessment,
    handleRefreshAssessments,
  };
  //#endregion

  //#region UI
  // State
  const [tabIndex, handleChangeTabIndex] = useTabIndex(selectedTab);

  const uiProps: UiProps = {
    tabIndex,
    handleChangeTabIndex,
  };
  //#endregion

  const surveyProps = useSnapInSchoolsSatisfactionSurvey({
    id,
    refresh: handleRefresh,
  });

  //#region Effects
  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);
  //#endregion

  return {
    snapCycle: snapInSchoolsCycleProps,
    sessions: snapInSchoolsSessionProps,
    assessments: assessmentProps,
    ui: uiProps,
    alerts: alertProps,
    surveys: surveyProps,
  };
};

export interface SnapInSchoolsCycleDetailPageProps {
  snapCycle: SnapInSchoolsCycleProps;
  sessions: SnapInSchoolsSessionProps;
  assessments: AssessmentProps;
  surveys: SatisfactionSurveyProps;
  alerts: AlertProps;
  ui: UiProps;
}

interface AlertProps {
  alerts: AlertDto[];
  selectedAlertType: AlertTypeEnum;
  getAlertsRequestState: RequestStateDto;
  createAlertDismissalRequestState: RequestStateDto;
  handleCreateAlertDismissalClicked(type: AlertTypeEnum): void;
  handleCreateAlertDismissal(dto: AlertDismissalRequestDto): Promise<void>;
}

interface SnapInSchoolsCycleProps {
  snapCycle?: SnapInSchoolsCycleDto;
  getSnapCycleRequestState: RequestStateDto;
  updateSnapInSchoolsCycleRequestState: RequestStateDto;
  deleteSnapInSchoolsCycleRequestState: RequestStateDto;
  upsertFidelityAdherenceChecklistRequestState: RequestStateDto;
  deleteFidelityAdherenceChecklistRequestState: RequestStateDto;
  handleUpsertFidelityAdherenceChecklistClicked(): void;
  handleUpsertFidelityAdherenceChecklist(
    dto: SnapInSchoolsFidelityAdherenceChecklistFormDto
  ): Promise<void>;
  handleDeleteFidelityAdherenceChecklistClicked(): void;
  handleDeleteFidelityAdherenceChecklist(): Promise<void>;
  handleEditSnapInSchoolsCycleClicked(): void;
  handleEditSnapInSchoolsCycle(dto: SnapInSchoolsCycleFormDto): Promise<void>;
  handleDeleteSnapInSchoolsCycle(): Promise<void>;
  handleDeleteSnapInSchoolsCycleClicked(): void;
}

interface SnapInSchoolsSessionProps {
  upsertSnapSessionRequestState: RequestStateDto;
  deleteSnapInSchoolsSessionRequestState: RequestStateDto;
  selectedSnapInSchoolsSession?: SnapInSchoolsSessionDto;
  handleUpsertSnapSession(dto: SnapInSchoolsSessionFormDto): Promise<void>;
  handleCreateSnapSessionClicked(): void;
  handleUpdateSnapSessionClicked(dto: SnapInSchoolsSessionDto): void;
  handleDeleteSnapInSchoolsSession(): Promise<void>;
  handleDeleteSnapInSchoolsSessionClicked(dto: SnapInSchoolsSessionDto): void;
  handleViewAttendanceLogDocument(dto: SnapInSchoolsSessionDto): Promise<void>;
}

interface UiProps {
  tabIndex: string;
  handleChangeTabIndex(e: React.ChangeEvent<any>, index: string): void;
}

interface AssessmentProps {
  startAssessmentRequestState: RequestStateDto;
  getAssessmentsRequestState: RequestStateDto;
  assessments: AssessmentDto[];
  handleStartAssessmentClicked(): void;
  handleStartAssessment(dto: AssessmentFormDto): Promise<void>;
  handleRefreshAssessments(): Promise<void>;
}

//#region Satisfaction Survey
interface SatisfactionSurveyProps {
  selectedSurvey?: SnapInSchoolsSatisfactionSurveyDto;
  upsertSatisfactionSurveyRequestState: RequestStateDto;
  deleteSatisfactionSurveyRequestState: RequestStateDto;
  handleCreateSatisfactionSurveyClicked(): void;
  handleUpdateSatisfactionSurveyClicked(
    dto: SnapInSchoolsSatisfactionSurveyDto
  ): void;
  handleDeleteSatisfactionSurveyClicked(
    dto: SnapInSchoolsSatisfactionSurveyDto
  ): void;
  handleUpsertSatisfactionSurvey(
    dto: SnapInSchoolsSatisfactionSurveyFormDto
  ): Promise<void>;
  handleDeleteSatisfactionSurvey(): Promise<void>;
}

const useSnapInSchoolsSatisfactionSurvey = ({
  id,
  refresh,
}: {
  id: string;
  refresh: () => Promise<void>;
}): SatisfactionSurveyProps => {
  const dispatch = useAppDispatch();
  const [selectedSurvey, setSelectedSurvey] =
    useState<SnapInSchoolsSatisfactionSurveyDto>();
  const [createSurvey, , createRequestState, clearCreateErrors] =
    useCreateSnapInSchoolsSatisfactionSurvey();
  const [updateSurvey, , updateRequestState, clearUpdateErrors] =
    useUpdateSnapInSchoolsSatisfactionSurvey();
  const [deleteSurvey, , deleteRequestState, clearDeleteErrors] =
    useDeleteSnapInSchoolsSatisfactionSurvey();

  const openFormModal = (dto?: SnapInSchoolsSatisfactionSurveyDto) => {
    clearCreateErrors();
    clearUpdateErrors();
    setSelectedSurvey(dto);
    dispatch(openModal({ modalId: snapInSchoolsSatisfactionSurveyModalId }));
  };

  const handleCreateSatisfactionSurveyClicked = () => openFormModal();

  const handleUpdateSatisfactionSurveyClicked = (
    dto: SnapInSchoolsSatisfactionSurveyDto
  ) => openFormModal(dto);

  const handleDeleteSatisfactionSurveyClicked = (
    dto: SnapInSchoolsSatisfactionSurveyDto
  ) => {
    clearDeleteErrors();
    setSelectedSurvey(dto);
    dispatch(
      openModal({
        modalId: confirmDeleteSnapInSchoolsSatisfactionSurveyDialogId,
      })
    );
  };

  const handleUpsertSatisfactionSurvey = async (
    dto: SnapInSchoolsSatisfactionSurveyFormDto
  ) => {
    let action = "Created";

    if (selectedSurvey) {
      action = "Updated";
      await updateSurvey({ id: selectedSurvey.id, dto });
    } else {
      await createSurvey({ id, dto });
    }

    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({
          message: `SNAP in Schools and Communities Satisfaction Survey ${action}`,
        })
      );
    });

    await refresh();
  };

  const handleDeleteSatisfactionSurvey = async () => {
    await deleteSurvey(selectedSurvey!.id);
    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({
          message: `SNAP in Schools and Communities Satisfaction Survey Deleted`,
        })
      );
    });
    await refresh();
  };

  return {
    selectedSurvey,
    upsertSatisfactionSurveyRequestState: selectedSurvey
      ? updateRequestState
      : createRequestState,
    deleteSatisfactionSurveyRequestState: deleteRequestState,
    handleCreateSatisfactionSurveyClicked,
    handleUpdateSatisfactionSurveyClicked,
    handleDeleteSatisfactionSurveyClicked,
    handleUpsertSatisfactionSurvey,
    handleDeleteSatisfactionSurvey,
  };
};

//#endregion
