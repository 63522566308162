import { securityAgent } from "../../app/api/agent";
import { ConfirmEmailDto } from "./dtos/ConfirmEmailDto";
import { ResetPasswordFormDto } from "./dtos/ResetPasswordFormDto";
import { SendConfirmationEmailRequestDto } from "./dtos/SendConfirmationEmailRequestDto";
import { SendResetPasswordFormDto } from "./dtos/SendResetPasswordFormDto";

export const confirmEmailRequest = (dto: ConfirmEmailDto) =>
  securityAgent.postRequest<string | undefined>("users/confirmEmail", dto);
export const resetPasswordRequest = (dto: ResetPasswordFormDto) =>
  securityAgent.postRequest("users/changePassword", dto);
export const sendConfirmationEmailRequest = (
  dto: SendConfirmationEmailRequestDto
) => securityAgent.postRequest("users/sendconfirmemail", dto);

export const getConfirmationEmailRequest = (
  dto: SendConfirmationEmailRequestDto
) => securityAgent.getRequest<string>(`users/getconfirmemail`, { params: dto });
export const sendResetPasswordRequest = (dto: SendResetPasswordFormDto) =>
  securityAgent.postRequest("users/sendresetpassword", dto);
