import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { AssessmentDto } from "../dtos/AssessmentDto";
import AssessmentScoresTable from "./AssessmentScoresTable";

export const assessmentScoresModalId = "assessment-scores-modal";

interface Props {
  assessment?: AssessmentDto;
}

const AssessmentScoreDialog: React.FC<Props> = ({ assessment }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  //#endregion

  //#region
  const handleCloseModal = async () => {
    dispatch(closeModal());
  };
  //#endregion

  const renderContent = () => {
    if (assessment == null || assessment.scores.length === 0)
      return (
        <Grid item md={12} xs={12}>
          <Typography>No Scores Added.</Typography>
        </Grid>
      );

    return <AssessmentScoresTable scores={assessment.scores} />;
  };

  return (
    <Dialog open={modalId === assessmentScoresModalId} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex">
          {assessment && (
            <>
              Assessment #
              {assessment.referenceNumber.toString().padStart(8, "0")} Scores
            </>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {renderContent()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Close"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AssessmentScoreDialog;
