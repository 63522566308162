import {useMemo} from "react";
import {RequestStateDto} from "../dtos/RequestStateDto";
import {uniq} from "lodash";

export const useCombinedLoadingState = (requests: RequestStateDto[]) => {
  return useMemo(() => {
    return requests.some((x) => x.isLoading);
  }, [requests]);
};

export const useCombinedErrorState = (requests: RequestStateDto[]) => {
  return useMemo(() => {
    return uniq(
      requests
        .filter((x) => x.error?.message !== undefined)
        .flatMap((x) => x.error!.message.split("\n"))
    );
  }, [requests]);
};

export const useCombinedRequests = (requests: RequestStateDto[]) => {
  const isLoading = useCombinedLoadingState(requests);
  const errors = useCombinedErrorState(requests);

  return { isLoading, errors };
};
