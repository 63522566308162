import React from "react";
import { Legend } from "recharts";

const AppLegend = () => {
  const renderText = (value: string) => {
    return <span style={{ color: "black" }}>{value}</span>;
  };

  return <Legend formatter={renderText} />;
};

export default AppLegend;
