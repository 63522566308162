import { Box, Divider, Paper, Typography } from "@material-ui/core";
import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import PdfContainer from "../../../app/components/PdfContainer";
import MonthlyReportCardFilter from "../components/MonthlyReportCardFilter";
import { useMonthlyReportCardPage } from "../hooks/useMonthlyReportCardPage";

const MonthlyReportCardPage = () => {
  const {
    providers,
    invoiceYears,
    getInvoiceYearsRequestState,
    getDocumentRequestState,
    getProvidersRequestState,
    document,
    handleSearch,
    isFnyfsUser,
  } = useMonthlyReportCardPage();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <BackButton route="/routes" />
      </div>
      <Header title="Report Card" />
      <Box className="my-2">
        <Typography>
          Select a date range{isFnyfsUser && ", Provider (optional)"} and "View"
          for a PDF of the Report Card.
        </Typography>
      </Box>
      <Paper
        style={{
          padding: "16px",
          height: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <MonthlyReportCardFilter
          providers={providers}
          getProvidersRequestState={getProvidersRequestState}
          requestState={getDocumentRequestState}
          invoiceYears={invoiceYears}
          getInvoiceYearsRequestState={getInvoiceYearsRequestState}
          onSubmit={handleSearch}
        />

        <Box marginBottom="8px" marginTop="8px">
          <Divider />
        </Box>
        <PdfContainer
          document={document}
          isLoading={getDocumentRequestState.isLoading}
        />
      </Paper>
    </div>
  );
};

export default MonthlyReportCardPage;
