import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProgramTypeEnum } from "../../programs/enums/ProgramTypeEnum";
import { ScreeningFilterFormDto } from "../dtos/ScreeningFilterFormDto";
import { ScreeningSearchParamsDto } from "../dtos/ScreeningSearchParamsDto";

export const useScreeningFilter = (
  onSubmit: (dto: ScreeningSearchParamsDto) => void,
  onClose: () => void,
  onProviderChange: (id: string) => void,
  providerLocations: { [key: string]: LocationDto[] },
  providerPrograms: { [key: string]: ProgramDto[] },
  params: ScreeningSearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit, watch, setValue } = useForm({
    defaultValues: new ScreeningFilterFormDto(params),
  });

  const { providerId, assignedToWaitList, waitListProgramId, waitListLocationId } = watch();

  const [locations, setLocations] = useState<LocationDto[]>([]);
  const [programs, setPrograms] = useState<ProgramDto[]>([]);
  
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: ScreeningFilterFormDto) => {
    const newParams: ScreeningSearchParamsDto = new ScreeningSearchParamsDto({
      form: dto,
      previous: params,
    });

    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new ScreeningFilterFormDto());
  };
  
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new ScreeningFilterFormDto(params));
  }, [params, reset]);

  useEffect(() => {
    onProviderChange(providerId);
  }, [providerId, onProviderChange]);

  useEffect(() => {
    if (Object.keys(providerPrograms).length === 0) return;

    const programs = providerPrograms[providerId] || [];
    setPrograms(programs.filter(p => p.programType.programType !== ProgramTypeEnum.SnapInSchools));
    if (waitListProgramId !== "" && !programs.some((x) => x.id === waitListProgramId))
      setValue("waitListProgramId", "");
  }, [providerId, setPrograms, providerPrograms, setValue, waitListProgramId]);

  useEffect(() => {
    if (Object.keys(providerLocations).length === 0) return;

    let locations = providerLocations[providerId] || [];
    if (waitListProgramId !== "") {
      const program = programs.find((x) => x.id === waitListProgramId);
      if (program) {
        const programLocationIds = program.locations.map((x) => x.locationId);

        locations = locations.filter((x) => programLocationIds.includes(x.id));
      }
    }

    if (waitListLocationId !== "" && !locations.some((x) => x.id === waitListLocationId))
      setValue("waitListLocationId", "");

    setLocations(locations);
  }, [
    providerId,
    waitListProgramId,
    programs,
    waitListLocationId,
    setLocations,
    providerLocations,
    setValue,
  ]);

  useEffect(() => {
    if (!assignedToWaitList) {
        setValue("waitListStatus", "");
        setValue("waitListProgramId", "");
        setValue("waitListLocationId", "");
    }    
  }, [assignedToWaitList, setValue])

  //#endregion

  useEffect(() => {
    const providerId = getProviderId();

    if (
      providerId != null &&
      providerId !== "" &&
      providerId !== fnyfsProviderId
    )
      setValue("providerId", providerId);
  }, [setValue]);

  return {
    state: { locations, programs, assignedToWaitList },
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
