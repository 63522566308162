import { Grid } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { TabProps } from "../../../../../app/components/TabContainer";
import { RequestStateDto } from "../../../../../app/dtos/RequestStateDto";
import { createNumberRange } from "../../../../../app/helpers";
import DeliverableRateFormDialog from "../../../../deliverable-rates/components/DeliverableRateFormDialog";
import { DeliverableRateDto } from "../../../../deliverable-rates/dtos/DeliverableRateDto";
import { DeliverableRateFormDto } from "../../../../deliverable-rates/dtos/DeliverableRateFormDto";
import ConfirmDeleteDeliverableRateDialog from "../ConfirmDeleteDeliverableRateDialog";
import DeliverableRateListItem, {
  DeliverableRateListLoadingItem,
} from "./DeliverableRateListItem";

interface Props {
  isLoading: boolean;
  deliverableRates: { [key: string]: DeliverableRateDto[] };
  changeDeliverableTypeRequestState: RequestStateDto;
  selectedDeliverableRate?: DeliverableRateDto;
  deleteDeliverableRequestState: RequestStateDto;
  onChangeClick(deliverableRateId: string): void;
  onChange(dto: DeliverableRateFormDto): Promise<void>;
  onDeleteClick(deliverableTypeId: string, deliverableRateId: string): void;
  onDelete(): Promise<void>;
}

export const deliverableRatesSectionName = "deliverable-rates-section";

const DeliverableRatesSection: React.FC<Props> = ({
  isLoading,
  deliverableRates,
  changeDeliverableTypeRequestState,
  deleteDeliverableRequestState,
  selectedDeliverableRate,
  onChange,
  onChangeClick,
  onDelete,
  onDeleteClick,
}) => {

  const isActiveRate = (rate: DeliverableRateDto) => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    const startsOn = new Date(rate.startsOn);
    const endsOn = rate.endsOn ? new Date(rate.endsOn) : undefined;

    return startsOn <= today && (endsOn === undefined || endsOn >= today);
  };

  const getSortedCurrentRates = () => {
    const rates = Object
      .entries(deliverableRates)
      .flatMap(x => x[1])
      .filter(rate => isActiveRate(rate));

    return rates.sort((rate1, rate2) => {
      const label1 = `${rate1.contract.name} ${rate1.programType.name} ${rate1.deliverableType.name}`;
      const label2 = `${rate2.contract.name} ${rate2.programType.name} ${rate2.deliverableType.name}`;

      return label1 > label2 ? 1 : -1;
    })
  }

  const getRateHistory = (currentRate: DeliverableRateDto) => {
    return Object
      .entries(deliverableRates)
      .flatMap(x => x[1])
      .filter(r =>
        r.contract.id === currentRate.contract.id
        && r.programType.id === currentRate.programType.id
        && r.deliverableType.id === currentRate.deliverableType.id
      )
      .sort((rate1, rate2) => {

        if(!rate1.endsOn) return 1;
        if(!rate2.endsOn) return -1;

        return rate1.endsOn > rate2.endsOn ? 1 : -1;
      })
  }

  //#region State
  const [expandedId, setExpandedId] = useState<string>();
  //#endregion

  //#region State
  const handleOnChange = (id: string) => {
    setExpandedId(id === expandedId ? undefined : id);
  };
  //#endregion

  return (
    <Fragment>
      <DeliverableRateFormDialog
        onSubmit={onChange}
        requestState={changeDeliverableTypeRequestState}
        deliverableRate={selectedDeliverableRate}
      />
      <ConfirmDeleteDeliverableRateDialog
        onConfirm={onDelete}
        requestState={deleteDeliverableRequestState}
      />
      <Grid container spacing={1}>
        {isLoading &&
          createNumberRange(7).map((i) => (
            <Grid item md={12} xs={12} key={i}>
              <DeliverableRateListLoadingItem key={i} />
            </Grid>
          ))}

        {getSortedCurrentRates().map((rate) => {
          return (
            <Grid item md={12} xs={12} key={rate.id}>
              <DeliverableRateListItem
                rates={getRateHistory(rate)}
                expanded={expandedId === rate.id}
                onChange={() => handleOnChange(rate.id)}
                onChangeClick={() => onChangeClick(rate.id)}
                onDeleteClick={(deliverableRateId: string) =>
                  onDeleteClick(rate.deliverableType.id, deliverableRateId)
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

export default DeliverableRatesSection;

export const createDeliverableRateTab = (props: Props): TabProps => ({
  value: deliverableRatesSectionName,
  label: "Deliverable Rates",
  content: <DeliverableRatesSection {...props} />,
});
