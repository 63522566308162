import { Divider, Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { YouthEthnicityEnum } from "../enums/YouthEthnicityEnum";
import { YouthGenderEnum } from "../enums/YouthGenderEnum";
import { YouthRaceEnum } from "../enums/YouthRaceEnum";
import { YouthSexualOrientationEnum } from "../enums/YouthSexualOrientationEnum";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import GenderLabel from "./labels.tsx/GenderLabel";
import RaceLabel from "./labels.tsx/RaceLabel";
import SexualOrientationLabel from "./labels.tsx/SexualOrientationLabel";
import EthnicityLabel from "./labels.tsx/EthnicityLabel";
import ControlledSSNInput from "../../../app/components/inputs/ControlledSSNInput";
import ControlledPhoneNumberInput from "../../../app/components/inputs/ControlledPhoneNumberInput";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import { YouthPhoneNumberTypeOptions } from "../enums/YouthPhoneNumberTypeEnum";
import { trimPhoneNumber } from "../../../app/helpers";
import YouthAddressFormFields from "./YouthAddressFormFields";

interface Props {
  control: Control<any>;
  disabled?: boolean;
  isLegacy?: boolean
}

const genderOptions: SelectOption[] = [
  { label: "Select Gender", value: "" },
  {
    label: <GenderLabel gender={YouthGenderEnum.Female} />,
    value: YouthGenderEnum.Female,
  },
  {
    label: <GenderLabel gender={YouthGenderEnum.GenderNonConforming} />,
    value: YouthGenderEnum.GenderNonConforming,
  },
  {
    label: <GenderLabel gender={YouthGenderEnum.Male} />,
    value: YouthGenderEnum.Male,
  },
  {
    label: <GenderLabel gender={YouthGenderEnum.TransFemale} />,
    value: YouthGenderEnum.TransFemale,
  },
  {
    label: <GenderLabel gender={YouthGenderEnum.TransMale} />,
    value: YouthGenderEnum.TransMale,
  },
];

const raceOptions: SelectOption[] = [
  { label: "Select Race", value: "" },
  {
    label: <RaceLabel race={YouthRaceEnum.AmericanIndian} />,
    value: YouthRaceEnum.AmericanIndian,
  },
  {
    label: <RaceLabel race={YouthRaceEnum.AlaskanNative} />,
    value: YouthRaceEnum.AlaskanNative,
  },
  {
    label: <RaceLabel race={YouthRaceEnum.Asian} />,
    value: YouthRaceEnum.Asian,
  },
  {
    label: <RaceLabel race={YouthRaceEnum.AfricanAmerican} />,
    value: YouthRaceEnum.AfricanAmerican,
  },
  {
    label: <RaceLabel race={YouthRaceEnum.NativeHawaiian} />,
    value: YouthRaceEnum.NativeHawaiian,
  },
  {
    label: <RaceLabel race={YouthRaceEnum.White} />,
    value: YouthRaceEnum.White,
  },
  {
    label: <RaceLabel race={YouthRaceEnum.NotSpecified} />,
    value: YouthRaceEnum.NotSpecified,
  },  
];

const sexualOrientationOptions: SelectOption[] = [
  { label: "Select Sexual Orientation", value: "" },
  {
    label: (
      <SexualOrientationLabel
        sexualOrientation={YouthSexualOrientationEnum.Heterosexual}
      />
    ),
    value: YouthSexualOrientationEnum.Heterosexual,
  },
  {
    label: (
      <SexualOrientationLabel
        sexualOrientation={YouthSexualOrientationEnum.Gay}
      />
    ),
    value: YouthSexualOrientationEnum.Gay,
  },
  {
    label: (
      <SexualOrientationLabel
        sexualOrientation={YouthSexualOrientationEnum.Lesbian}
      />
    ),
    value: YouthSexualOrientationEnum.Lesbian,
  },
  {
    label: (
      <SexualOrientationLabel
        sexualOrientation={YouthSexualOrientationEnum.Bisexual}
      />
    ),
    value: YouthSexualOrientationEnum.Bisexual,
  },
  {
    label: (
      <SexualOrientationLabel
        sexualOrientation={YouthSexualOrientationEnum.QuestioningUnsure}
      />
    ),
    value: YouthSexualOrientationEnum.QuestioningUnsure,
  },
  {
    label: (
      <SexualOrientationLabel
        sexualOrientation={YouthSexualOrientationEnum.Unknown}
      />
    ),
    value: YouthSexualOrientationEnum.Unknown,
  },
];

const ethnicityOptions: SelectOption[] = [
  { label: "Select Ethnicity", value: "" },
  {
    label: <EthnicityLabel ethnicity={YouthEthnicityEnum.NonHispanic} />,
    value: YouthEthnicityEnum.NonHispanic,
  },
  {
    label: <EthnicityLabel ethnicity={YouthEthnicityEnum.Hispanic} />,
    value: YouthEthnicityEnum.Hispanic,
  },
  {
    label: <EthnicityLabel ethnicity={YouthEthnicityEnum.Haitian} />,
    value: YouthEthnicityEnum.Haitian,
  },
  {
    label: <EthnicityLabel ethnicity={YouthEthnicityEnum.Jamaican} />,
    value: YouthEthnicityEnum.Jamaican,
  },
  {
    label: <EthnicityLabel ethnicity={YouthEthnicityEnum.NotSpecified} />,
    value: YouthEthnicityEnum.NotSpecified,
  },  
];

const YouthFormFields: React.FC<Props> = ({ control, disabled, isLegacy }) => {
  const { phoneNumber, isMultiracial } = useWatch({ control });

  return (
    <Fragment>
      <Grid item md={4} xs={12}>
        <ControlledInput
          name="firstName"
          label="First Name*"
          control={control}
          disabled={disabled}
          fullWidth
          autoComplete="off"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ControlledInput
          name="middleName"
          label="Middle Name"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ControlledInput
          name="lastName"
          label="Last Name*"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledInput
          name="preferredName"
          label="Preferred Name"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledInput
          name="preferredPronouns"
          label="Pronoun(s)"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledInput
          control={control}
          name="dob"
          label="Date of Birth*"
          type="date"
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <ControlledSelect
          options={genderOptions}
          name="gender"
          label="Gender*"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      
      <Grid item md={6} xs={12}>
        <ControlledSelect
          options={raceOptions.filter(o => isLegacy || o.value !== YouthRaceEnum.NotSpecified)}
          name="race"
          label="Race*"
          control={control}
          disabled={disabled}
          fullWidth
        />
        <ControlledCheckbox
          color="primary"
          control={control}
          name="isMultiracial"
          label="Is Multiracial?"
          value={isMultiracial}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledSelect
          options={sexualOrientationOptions}
          name="sexualOrientation"
          label="Sexual Orientation*"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledSelect
          options={ethnicityOptions.filter(o => isLegacy || o.value !== YouthEthnicityEnum.NotSpecified)}
          name="ethnicity"
          label="Ethnicity*"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledWholeNumberInput
          name="djjIdNumber"
          label="DJJ ID Number"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <ControlledInput
          name="email"
          label="Email"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledSSNInput
          name="ssn"
          label="SSN"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledPhoneNumberInput
          name="phoneNumber"
          label="Phone Number"
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledSelect
          name="phoneNumberType"
          label="Phone Number Type*"
          options={YouthPhoneNumberTypeOptions}
          control={control}
          disabled={
            disabled || trimPhoneNumber(phoneNumber as string)?.length === 0
          }
          fullWidth
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
      <YouthAddressFormFields
        prefix="address"
        control={control}
        disabled={disabled}
      />
    </Fragment>
  );
};

export default YouthFormFields;
