import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import * as Yup from "yup";
import { Grid } from "@material-ui/core";

export const providerSchema = Yup.object().shape({
  centerId: Yup.string().label("Center ID").required().max(8),
  name: Yup.string().label("Name").required().max(100),
  shortName: Yup.string().label("Short Name").required().max(20),
  abbreviation: Yup.string().label("Abbreviation").required().max(5),
});

interface Props {
  control: Control<any>;
  disabled: boolean;
}

const ProviderFormFields: React.FC<Props> = ({ control, disabled }) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <ControlledInput
          control={control}
          name="centerId"
          label="Center ID*"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledInput
          control={control}
          name="name"
          label="Name*"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledInput
          control={control}
          name="shortName"
          label="Short Name*"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledInput
          control={control}
          name="abbreviation"
          label="Abbreviation*"
          disabled={disabled}
        />
      </Grid>
    </Fragment>
  );
};

export default ProviderFormFields;
