import { Box, Divider } from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditButton from "../../../../app/components/buttons/EditButton";
import KeyValueDetails, {
  RowProps,
} from "../../../../app/components/KeyValueDetails";
import DateLabel from "../../../../app/components/labels/DateLabel";
import MaskedSSNLabel from "../../../../app/components/labels/MaskedSSNLabel";
import PhoneNumberLabel from "../../../../app/components/labels/PhoneNumberLabel";
import YesNoLabel from "../../../../app/components/labels/YesNoLabel";
import { useAppSelector } from "../../../../app/hooks";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { selectHasWriteAccess } from "../../../authentication/state/authenticationSelectors";
import { YouthDto } from "../../dto/YouthDto";
import { YouthPhoneNumberTypeEnumTextMap } from "../../enums/YouthPhoneNumberTypeEnum";
import EthnicityLabel from "../labels.tsx/EthnicityLabel";
import GenderLabel from "../labels.tsx/GenderLabel";
import RaceLabel from "../labels.tsx/RaceLabel";
import SexualOrientationLabel from "../labels.tsx/SexualOrientationLabel";

interface Props {
  onEdit: () => void;
  youth?: YouthDto;
  isLoading: boolean;
}
export const youthDetailsSectionName = "youth-details-section";
const YouthDetailsSection: React.FC<Props> = ({ onEdit, youth, isLoading }) => {
  const canEditYouth = useAppSelector(
    selectHasWriteAccess(PermissionResourceNameEnum.Youth)
  );

  const columns: RowProps[] = [
    {
      key: "preferredName",
      label: "Preferred Name",
      value: youth?.preferredName,
      hide: youth?.preferredName === "" || youth?.preferredName == null,
    },
    { key: "firstName", label: "First Name", value: youth?.firstName },
    { key: "lastName", label: "Last Name", value: youth?.lastName },
    {
      key: "middleName",
      label: "Middle Name",
      value: youth?.middleName,
      hide: youth?.middleName === "",
    },
    { key: "dob", label: "DOB", value: <DateLabel date={youth?.dob} /> },
    { key: "youthId", label: "Youth ID", value: youth?.referenceNumber },
    {
      key: "preferredPronouns",
      label: "Pronoun(s)",
      value: youth?.preferredPronouns,
      hide: youth?.preferredPronouns === "" || youth?.preferredPronouns == null,
    },
    {
      key: "gender",
      label: "Gender",
      value: youth?.gender && <GenderLabel gender={youth?.gender} />,
    },
    {
      key: "race",
      label: "Race",
      value: youth?.race && <RaceLabel race={youth!.race} />,
    },
    {
      key: "multiracial",
      label: "Is Multiracial",
      value: youth && <YesNoLabel value={youth.isMultiracial} />,
    },
    {
      key: "orientation",
      label: "Sexual Orientation",
      value: youth?.sexualOrientation && (
        <SexualOrientationLabel sexualOrientation={youth!.sexualOrientation} />
      ),
    },
    {
      key: "ethnicity",
      label: "Ethnicity",
      value: youth?.ethnicity && (
        <EthnicityLabel ethnicity={youth!.ethnicity} />
      ),
      hide: youth?.ethnicity == null,
    },
    {
      key: "ssn",
      label: "SSN",
      value: <MaskedSSNLabel ssn={youth?.ssn} canToggle={canEditYouth} />,
      hide: !youth?.ssn,
    },
    {
      key: "email",
      label: "Email",
      value: youth?.email,
      hide: youth?.email === "" || youth?.email == null,
    },
    {
      key: "phone",
      label: "Phone #",
      value: (
        <>
          <PhoneNumberLabel phoneNumber={youth?.phoneNumber} />
          {youth?.phoneNumberType &&
            ` (${YouthPhoneNumberTypeEnumTextMap[youth.phoneNumberType]})`}
        </>
      ),
      hide: youth?.phoneNumber === "" || youth?.phoneNumber == null,
    },

    {
      key: "djjid",
      label: "DJJID #",
      value: youth?.djjIdNumber,
      hide: youth?.djjIdNumber == null,
    },
  ];

  const addressColumns: RowProps[] = [
    { key: "address", label: "Address", value: youth?.address?.address },
    {
      key: "building",
      label: "Building/Apartment #",
      value: youth?.address?.building,
      hide: youth?.address?.building === "" || youth?.address?.building == null,
    },
    { key: "city", label: "City", value: youth?.address?.city },
    { key: "state", label: "State", value: youth?.address?.state },
    { key: "zip", label: "Zip Code", value: youth?.address?.zipCode },
    { key: "county", label: "County", value: youth?.address?.county },
    {
      key: "mailingAddress",
      label: "Is Mailing Address",
      value: <YesNoLabel value={youth?.address?.isMailingAddress || false} />,
    },
  ];

  return (
    <Fragment>
      <Box position="absolute" right="16px">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Youth}>
          <EditButton title="Edit Youth" onClick={onEdit} />
        </WriteAccessLevelWrapper>
      </Box>
      <KeyValueDetails rows={columns} isLoading={isLoading} />

      {!!youth?.address?.address && (
        <>
          <Box marginTop="8px" marginBottom="8px">
            <Divider />
          </Box>
          <KeyValueDetails rows={addressColumns} isLoading={isLoading} />
        </>
      )}
    </Fragment>
  );
};

export default YouthDetailsSection;
