import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum CaseContactStatusEnum {
  Attempted = "Attempted",
  Completed = "Completed",
}

export const caseContactStatusTextMap: { [key: string]: string } = {
  [CaseContactStatusEnum.Attempted]: "Attempted",
  [CaseContactStatusEnum.Completed]: "Completed",
};
export const caseContactStatusOptions: SelectOption[] = [
  { value: "", label: "Select Contact Status" },
  ...Object.keys(CaseContactStatusEnum).map((x) => ({
    value: x,
    label: caseContactStatusTextMap[x],
  })),
];
