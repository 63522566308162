import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../../../app/components/buttons/AddButton";
import { TabProps } from "../../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../../authentication/enums/PermissionResourceNameEnum";
import BakerActFormDialog from "../../../../baker-act/components/BakerActFormDialog";
import BakerActList from "../../../../baker-act/components/BakerActList";
import ConfirmDeleteBakerActDialog from "../../../../baker-act/components/ConfirmDeleteBakerActDialog";
import { useCaseDetailPageContext } from "../../../pages/CaseDetailPage";

export const caseBakerActTabName = "case-baker-act-section";

const CaseBakerActSection = () => {
  //#region State
  const context = useCaseDetailPageContext();

  const {
    selectedBakerAct,
    upsertBakerActRequestState,
    deleteBakerActRequestState,
    handleDeleteBakerAct,
    handleDeleteBakerActClicked,
    handleCreateBakerActClicked,
    handleUpdateBakerActClicked,
    handleUpsertBakerAct,
  } = context.bakerAct;

  const {
    caseResult,
    getCaseRequestState: { isLoading },
  } = context.case;

  const bakerActs = caseResult?.bakerActs || [];
  //#endregion

  return (
    <Fragment>
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <AddButton
          text="Add Baker Act"
          onClick={handleCreateBakerActClicked}
          variant="contained"
          color="primary"
        />
        <BakerActFormDialog
          bakerAct={selectedBakerAct}
          requestState={upsertBakerActRequestState}
          onSubmit={handleUpsertBakerAct}
        />
        <ConfirmDeleteBakerActDialog
          bakerAct={selectedBakerAct}
          requestState={deleteBakerActRequestState}
          onConfirm={handleDeleteBakerAct}
        />
      </WriteAccessLevelWrapper>
      <BakerActList
        bakerActs={bakerActs}
        isLoading={isLoading}
        onDelete={handleDeleteBakerActClicked}
        onEdit={handleUpdateBakerActClicked}
      />
    </Fragment>
  );
};

export default CaseBakerActSection;

export const createCaseBakerActTab = (): TabProps => ({
  label: "Baker Acts",
  value: caseBakerActTabName,
  content: <CaseBakerActSection />,
});
