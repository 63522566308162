import React from "react";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export interface ProgramTypeSummaryDto {
  id: string;
  name: string;
}

export const programTypeSummaryToOption = (
  dto: ProgramTypeSummaryDto
): SelectOption => ({
  value: dto.id,
  label: dto.name,
});

export const programTypeSummariesToOptions = (
  dtos: ProgramTypeSummaryDto[],
  defaultLabel: React.ReactNode = "Select Program Type"
): SelectOption[] => {
  return [
    { value: "", label: defaultLabel },
    ...dtos.map(programTypeSummaryToOption),
  ];
};
