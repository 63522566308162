import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { SnapCycleFilterFormDto } from "./SnapFilterFormDto";

export class SnapGroupCycleSearchParamsDto
  implements PaginationSearchParamsDto
{
  programTypeId?: string;
  providerId?: string;
  programId?: string;
  locationId?: string;
  gender?: string;
  cycleStartDateRangeStart?: string;
  cycleStartDateRangeEnd?: string;  
  fidelityRequired?: boolean;
  includeDeleted?: boolean;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: SnapCycleFilterFormDto;
    previous?: SnapGroupCycleSearchParamsDto;
  }) {
    this.programTypeId =
      form?.programTypeId || (query?.programTypeId as string) || "";
    this.programTypeId =
      form?.programTypeId || (query?.programTypeId as string) || "";
    this.gender = form?.gender || (query?.gender as string) || "";
    this.providerId = form?.providerId || (query?.providerId as string) || "";
    this.includeDeleted =
      form?.includeDeleted ||
      Boolean((query?.includeDeleted as string) === "true") ||
      false;
    this.fidelityRequired =
      form?.fidelityRequired ||
      Boolean((query?.fidelityRequired as string) === "true") ||
      undefined;

    this.sortBy =
      previous?.sortBy || (query?.sortBy as string) || "cycleNumber";
    this.sortDirection =
      previous?.sortDirection || QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
  }

  public static toQueryString(dto: SnapGroupCycleSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
