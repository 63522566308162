import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import StyledLink from "../../../app/components/StyledLink";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditButton from "../../../app/components/buttons/EditButton";
import DateLabel from "../../../app/components/labels/DateLabel";
import PhoneNumberLabel from "../../../app/components/labels/PhoneNumberLabel";
import { createNumberRange } from "../../../app/helpers";
import { fnyfsName, fnyfsProviderId } from "../../authentication/constants";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { ReferralDto } from "../dtos/ReferralDto";
import { referralServiceRequestedTextMap } from "../enums/ReferralServiceRequestedEnum";
import { ReferralStatusEnum } from "../enums/ReferralStatusEnum";
import ReferralHistory from "./ReferralHistory";
import ReferralStatusLabel from "./ReferralStatusLabel";
import ReferrerRelationshipLabel from "./ReferrerRelationshipLabel";

interface Props {
  referral?: ReferralDto;
  isLoading: boolean;
  onForwardClick(): void;
  onStartScreeningClick(): void;
}

const ReferralDetails: React.FC<Props> = ({
  referral,
  isLoading,
  onForwardClick,
  onStartScreeningClick,
}) => {
  const location = useLocation();
  const assignedTo = referral?.referralRoutes.find(
    (x) => x.forwardReferralRouteId === undefined
  );

  const showDetails = isLoading === false && referral !== undefined;
  const canStartScreening =
    (assignedTo?.provider?.id === getProviderId() ||
      (assignedTo?.provider === undefined &&
        getProviderId() === fnyfsProviderId)) &&
    referral?.screening === undefined &&
    referral?.status &&
    [ReferralStatusEnum.Pending, ReferralStatusEnum.Removed].includes(
      referral.status
    );

  const canForward =
    (assignedTo?.provider?.id === getProviderId() ||
      (assignedTo?.provider === undefined &&
        getProviderId() === fnyfsProviderId)) &&
    referral?.screening === undefined &&
    referral?.status === ReferralStatusEnum.Pending;

  //#region UI Helpers
  const screeningLink = (
    <StyledLink
      to={{
        pathname: `/screenings/${referral?.screening?.id}`,
        state: {
          prevLocation: location.pathname,
          text: "Back to referral",
        },
      }}
    >
      {referral?.screening?.referenceNumber}
    </StyledLink>
  );

  const startScreeningLink = (
    <StyledLink
      to={""}
      onClick={(e) => {
        e.preventDefault();
        onStartScreeningClick();
      }}
    >
      Start Screening
    </StyledLink>
  );

  //#endregion
  const screeningContent = () => {
    if (referral?.screening) return screeningLink;

    if (!canStartScreening) return <Fragment>None</Fragment>;

    return startScreeningLink;
  };

  const getAssignedToName = () => {
    if (assignedTo) {
      if (assignedTo.provider) return assignedTo.provider.name;

      return fnyfsName;
    }
    return "";
  };
  const createRow = (label: React.ReactNode, content: React.ReactNode) => {
    return (
      <Fragment>
        <Grid item md={3} xs={4}>
          <Typography component="span">{label}:</Typography>
        </Grid>
        <Grid item md={9} xs={8}>
          <Typography component="span">{content}</Typography>
        </Grid>
      </Fragment>
    );
  };

  const createLoadingRow = (key: number) => {
    return (
      <Fragment key={key}>
        <Grid item md={3} xs={4}>
          <Typography component="span">
            <Skeleton animation="wave" width="125px" />
          </Typography>
        </Grid>
        <Grid item md={9} xs={8}>
          <Typography component="span">
            <Skeleton animation="wave" width="200px" />
          </Typography>
        </Grid>
      </Fragment>
    );
  };

  return (
    <Box height="60vh">
      <Grid container spacing={1}>
        {!showDetails && createNumberRange(8).map(createLoadingRow)}
        {showDetails && (
          <Fragment>
            {createRow("Referral #", referral!.referralNumber)}
            {createRow(
              "Referral Status",
              <Box maxWidth="200px">
                <ReferralStatusLabel referral={referral!} />
              </Box>
            )}
            
            {referral?.status === ReferralStatusEnum.Removed && 
            referral?.removalReason &&
            createRow(
              "Removal Reason",
              referral.removalReason
            )}

            {createRow(
              "Assigned To",
              <Box display={"flex"} alignItems="center">
                <Typography component="span">{getAssignedToName()}</Typography>
                <ReferralHistory referral={referral!} />
                <WriteAccessLevelWrapper
                  name={PermissionResourceNameEnum.Referrals}
                >
                  {canForward && (
                    <EditButton text="Forward" onClick={onForwardClick} />
                  )}
                </WriteAccessLevelWrapper>
              </Box>
            )}
            {createRow("Associated Screening", screeningContent())}
            {createRow(
              "Services Requested",
              referral?.requestedServices
                .map((x) => referralServiceRequestedTextMap[x])
                .join(", ")
            )}
            {createRow("How did they hear about us?", referral?.heardAboutUs)}
            {createRow(
              "Reason for Referral",
              <Paper variant="outlined">
                <Box height="125px" padding="6px" overflow="auto">
                  <Typography>{referral?.reasonForReferral}</Typography>
                </Box>
              </Paper>
            )}
          </Fragment>
        )}
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography color="primary">Youth Information</Typography>
        </Grid>
        {!showDetails && createNumberRange(4).map(createLoadingRow)}
        {showDetails && (
          <Fragment>
            {createRow(
              "Youth Name",
              `${referral!.youthFirstName} ${referral!.youthLastName}`
            )}
            {createRow(
              "Youth Zip Code",
              referral!.youthZipCode ? referral!.youthZipCode : "Not Provided"
            )}
            {createRow(
              "Youth City",
              referral!.youthCity ? referral!.youthCity : "Not Provided"
            )}
            {createRow("Youth State", referral!.state)}

            {createRow(
              "Youth DOB",
              referral!.youthDob ? (
                <DateLabel date={referral!.youthDob} />
              ) : (
                "Not Provided"
              )
            )}
          </Fragment>
        )}

        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography color="primary">Referrer Information</Typography>
        </Grid>
        {!showDetails && createNumberRange(4).map(createLoadingRow)}
        {showDetails && (
          <Fragment>
            {createRow(
              "Referrer Name",
              `${referral!.referredBy.firstName} ${
                referral!.referredBy.lastName
              }`
            )}
            {referral?.referredBy.relationship &&
              createRow(
                "Relationship to Youth",
                <ReferrerRelationshipLabel
                  relationship={referral.referredBy.relationship}
                />
              )}
            {createRow(
              "Referrer Phone #",
              <PhoneNumberLabel
                phoneNumber={referral?.referredBy.phoneNumber}
              />
            )}
            {createRow("Referral Source", referral?.referredBy.source)}
          </Fragment>
        )}
      </Grid>
    </Box>
  );
};

export default ReferralDetails;
