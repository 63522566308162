import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { SnapGroupSummaryDto } from "../dtos/SnapGroupSummaryDto";
import DateLabel from "../../../app/components/labels/DateLabel";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";

interface Props {
  onConfirm: () => Promise<void>;
  requestState: RequestStateDto;
  group: SnapGroupSummaryDto;
}

export const confirmSnapGroupRemovalDialogId =
  "remove-snap-group-confirmation-dialog";
const ConfirmSnapCaseRemovalDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  group,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmSnapGroupRemovalDialogId}
      isLoading={isLoading}
      confirmButtonText="Remove SNAP Group"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to remove the Group date{" "}
        <DateLabel date={group.groupHeldOn} /> from this SNAP group cycle?{" "}
        <br />
        <br /> <strong>This action may result in invoice corrections.</strong>
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmSnapCaseRemovalDialog;
