export enum YouthEthnicityEnum {
  NonHispanic = "NonHispanic",
  Hispanic = "Hispanic",
  Haitian = "Haitian",
  Jamaican = "Jamaican",
  NotSpecified = "NotSpecified"
}

export const YouthEthnicityEnumTextMap: { [key: string]: string } = {
  [YouthEthnicityEnum.NonHispanic]: "Non-Hispanic/Non-Latino",
  [YouthEthnicityEnum.Hispanic]: "Hispanic or Latino",
  [YouthEthnicityEnum.Haitian]: "Haitian",
  [YouthEthnicityEnum.Jamaican]: "Jamaican",
  [YouthEthnicityEnum.NotSpecified]: "Not Specified",

};
