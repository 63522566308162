import {formatUrl} from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import {PermissionResourceNameEnum} from "../authentication/enums/PermissionResourceNameEnum";
import MonthlyReportCardPage from "./pages/MonthlyReportCardPage";
import IcmActivityReportPage from "./pages/IcmActivityReportPage";
import NirvanaExportReportPage from "./pages/NirvanaExportReportPage";
import NirvanaMonthlyCheckReportPage from "./pages/NirvanaMonthlyCheckReportPage";
import RealTimeCensusReportPage from "./pages/RealTimeCensusReportPage";
import ReportsPage from "./pages/ReportsPage";
import MonthlyDeliverablesReportPage from "./pages/MonthlyDeliverablesReportPage";
import MonthlyCorrectionsReportPage from "./pages/MonthlyCorrectionsReportPage";
import BenchmarksReportPage from "./pages/BenchmarksReportPage";
import BedUtilizationReportPage from "./pages/BedUtilizationReportPage";
import ScreeningsReportPage from "./pages/ScreeningsReportPage";
import MonthlyReportCardByProviderPage from "./pages/MonthlyReportCardByProviderPage";
import {NirvanaAssessmentAveragesOrImpactReportPage, NirvanaReportType} from "./pages/NirvanaAssessmentAveragesOrImpactReportPage";
import SatisfactionSurveysExportReportPage from "./pages/SatisfactionSurveysExportReportPage";
import {SNAPExportReportPage} from "./pages/SNAPExportReportPage";

export const createReportRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "icm-activity")}
    component={IcmActivityReportPage}
    key="icm-activity"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "nirvana-export")}
    component={NirvanaExportReportPage}
    key="nirvana-export"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "nirvana-monthly")}
    component={NirvanaMonthlyCheckReportPage}
    key="nirvana-monthly"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "nirvana-averages")}
    render={() => <NirvanaAssessmentAveragesOrImpactReportPage reportType={NirvanaReportType.AssessmentAverages} />}
    key="nirvana-averages"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "nirvana-impact")}
    render={() => <NirvanaAssessmentAveragesOrImpactReportPage reportType={NirvanaReportType.Impact} />}
    key="nirvana-impact"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "real-time-census")}
    component={RealTimeCensusReportPage}
    key="real-time-census"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={baseUrl}
    component={ReportsPage}
    key="reports-page"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "end-of-month")}
    component={MonthlyReportCardPage}
    key="end-of-month-page"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "end-of-month-by-provider")}
    component={MonthlyReportCardByProviderPage}
    key="end-of-month-page-by-provider"
    exact
  />,

  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "monthly-deliverables-report")}
    component={MonthlyDeliverablesReportPage}
    key="monthly-deliverables-report-page"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "monthly-corrections-report")}
    component={MonthlyCorrectionsReportPage}
    key="monthly-corrections-report-page"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "benchmarks-report")}
    component={BenchmarksReportPage}
    key="benchmarks-page"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "bed-utilization-report")}
    component={BedUtilizationReportPage}
    key="bed-utilization-page"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "screenings")}
    component={ScreeningsReportPage}
    key="screenings"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "export-satisfaction-surveys")}
    component={SatisfactionSurveysExportReportPage}
    key="export-satisfaction-surveys"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "export-snap-sis")}
    component={SNAPExportReportPage}
    key="export-snap-sis"
    exact
  />,
];
