import { securityAgent } from "../../app/api/agent";
import { NetmisRoleDto } from "./dtos/NetmisRoleDto";

export const getRolesRequest = (isFloridaNetwork?: boolean) => {
  if (isFloridaNetwork !== undefined)
    return securityAgent.getRequest<NetmisRoleDto[]>(
      `roles?isFloridaNetwork=${isFloridaNetwork}`      
  );

  return securityAgent.getRequest<NetmisRoleDto[]>(`roles`);
}  

export const getAllRolesRequest = () =>
  securityAgent.getRequest<NetmisRoleDto[]>(`roles`);