import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import {
  DjjEntrySearchParamsDto,
  DjjEntryStatusEnum,
  DjjEntryStatusTextMap,
  getDjjEntryTypeOptions,
} from "../DjjEntryDto";
import { useDjjEntryFilter } from "../hooks/useDjjEntryFilter";
import ControlledYesNoSelect from "../../../app/components/inputs/ControlledYesNoSelect";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";

interface Props {
  anchorEl: HTMLElement | null;
  providers: ProviderDto[];
  providersAreLoading: boolean;
  params: DjjEntrySearchParamsDto;
  onClose(): void;
  onSearch(dto: DjjEntrySearchParamsDto): void;
}

const DjjEntryFilter: React.FC<Props> = ({
  onClose,
  params,
  onSearch,
  providersAreLoading,
  anchorEl,
  providers,
}) => {
  //#region State
  const {
    state: { statusOptions },
    form: { control, clearAllFilters },
    handlers: { handleSubmit },
  } = useDjjEntryFilter(onSearch, onClose, params);

  //#endregion

  const getSortedProviders = () => {
    return providers.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">DJJ Related Data Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            {/* Entry Type */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="entryType"
                label="Entry Type"
                options={getDjjEntryTypeOptions("All")}
                fullWidth
              />
            </Grid>

            {/* Provider Name */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="providerId"
                label="Provider"
                fullWidth
                options={providerToOptionDtos(getSortedProviders(), [], "All")}
                isLoading={providersAreLoading}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <ControlledYesNoSelect
                fullWidth
                control={control}
                name="hasDjjId"
                label="Youth Has DJJ ID"
                selectLabel="All"
              />
            </Grid>

            {Object.values(DjjEntryStatusEnum).map((value) => (
              <Grid item md={4} xs={12} key={value}>
                <ControlledCheckbox
                  control={control}
                  name={`statusOptions[${value}]`}
                  label={DjjEntryStatusTextMap[value]}
                  color="primary"
                  checked={statusOptions[value]}
                />
              </Grid>
            ))}

            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default DjjEntryFilter;
