import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export const ReferralSourceEnum = {
  Self: "Self",
  Dcf: "DCF or Affiliated Program",
  Djj: "DJJ or Affiliated Program",
  LawEnforcement: "Law Enforcement",
  School: "School",
  Family: "Family",
  Court: "Court",
  SafePlace: "Safe Place",
  Fnyfs: "Florida Network",
  ProviderInternal: "Agency Internal Referral",
  Internet: "Internet"
};

export const referralSourceTextMap: { [key: string]: string } = {
  [ReferralSourceEnum.Self]: "Self",
  [ReferralSourceEnum.Dcf]: "DCF or Affiliated Program",
  [ReferralSourceEnum.Djj]: "DJJ or Affiliated Program",
  [ReferralSourceEnum.LawEnforcement]: "Law Enforcement",
  [ReferralSourceEnum.School]: "School",
  [ReferralSourceEnum.Family]: "Family",
  [ReferralSourceEnum.Court]: "Court",
  [ReferralSourceEnum.SafePlace]: "Safe Place",
  [ReferralSourceEnum.Fnyfs]: "Florida Network",
  [ReferralSourceEnum.ProviderInternal]: "Agency Internal Referral",
  [ReferralSourceEnum.Internet]: "Internet"
};

export const getReferralSourceOptions = (isReferral: boolean, customOption?: string): SelectOption[] => {
  let values = Object.values(ReferralSourceEnum);

  if (isReferral)
    values = values.filter(
      (source) =>
        source !== ReferralSourceEnum.Self &&
        source !== ReferralSourceEnum.Family && 
        source !== ReferralSourceEnum.ProviderInternal &&
        source !== ReferralSourceEnum.Internet
    );
  
  let result = values.map((source) =>  
    ({
    value: source,
    label: referralSourceTextMap[source],
    })).sort((a, b) => (a.label > b.label ? 1 : -1));

  if (customOption && !result.find(x => x.value===customOption))
    result = [...result, { value: customOption, label: customOption }]  ;  
    
  return result;  
};
