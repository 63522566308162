import { Route } from "react-router-dom";
import { formatUrl } from "../../app/helpers";
import AnonymousRoute from "../../app/routes/AnonymousRoute";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";

export const createAuthenticationRoutes = (baseUrl: string) => [
  <AnonymousRoute
    path={formatUrl(baseUrl, "login")}
    component={LoginPage}
    key="login"
  />,
  <Route
    path={formatUrl(baseUrl, "logout")}
    component={LogoutPage}
    key="logout"
  />,
];
