import { coreAgent } from "../../app/api/agent";
import { CaseStaffingDto } from "./dtos/CaseStaffingDto";
import { CaseStaffingFormDto } from "./dtos/CaseStaffingFormDto";
import { CinsPetitionFormDto } from "./dtos/CinsPetitionPetitionFormDto";

interface UpdateCaseStaffingParams {
  id: string;
  dto: CaseStaffingFormDto;
}

interface CreateCaseStaffingParams {
  id: string;
  dto: CaseStaffingFormDto;
}

interface UpsertCinsPetitionParams {
  id: string;
  dto: CinsPetitionFormDto;
}

export const createCaseStaffingRequest = ({
  id,
  dto,
}: CreateCaseStaffingParams) =>
  coreAgent.postRequest<CaseStaffingDto>(`cases/${id}/caseStaffings`, dto);

export const updateCaseStaffingRequest = ({
  id,
  dto,
}: UpdateCaseStaffingParams) =>
  coreAgent.putRequest<CaseStaffingDto>(`caseStaffings/${id}`, dto);

export const getCaseStaffingRequest = (id: string) =>
  coreAgent.getRequest<CaseStaffingDto>(`caseStaffings/${id}`);

export const deleteCaseStaffingRequest = (id: string) =>
  coreAgent.deleteRequest<void>(`caseStaffings/${id}`);

export const upsertCinsPetitionRequest = ({
  id,
  dto,
}: UpsertCinsPetitionParams) =>
  coreAgent.postRequest(`caseStaffings/${id}/cinsPetition`, dto);

export const deleteCinsPetitionRequest = (id: string) =>
  coreAgent.deleteRequest<void>(`caseStaffings/cinsPetition/${id}`);
