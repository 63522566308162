import {ParsedQuery} from "query-string";
import {paginationSearchParamsToQueryString} from "../../../app/dtos/PaginationSearchParamsDto";
import {dateToFormString} from "../../../app/helpers";

export interface EventLogSearchParamsDto {
  page?: number;
  pageSize?: number;

  providerId?: string;
  referenceType?: string;
  startDate?: Date;
  endDate?: Date;
  affectedDeliverables?: string;
}

export const eventLogSearchParamsDtoToParams = (obj: EventLogSearchParamsDto) => {

  const queryString: string[] = [];

  if(obj.providerId) queryString.push(`providerId=${obj.providerId}`)
  if(obj.referenceType) queryString.push(`referenceType=${obj.referenceType}`)
  if(obj.startDate) queryString.push(`startDate=${dateToFormString(obj.startDate)}`)
  if(obj.endDate) queryString.push(`endDate=${dateToFormString(obj.endDate)}`)
  if(obj.affectedDeliverables) queryString.push(`affectedDeliverables=${obj.affectedDeliverables}`)

  queryString.push(paginationSearchParamsToQueryString(obj));

  return queryString.join("&");
}

export const createEventLogParamsDtoFromQuery = (
  query: ParsedQuery<string>
): EventLogSearchParamsDto => {
  const {
    providerId,
    referenceType,
    startDate,
    endDate,
    affectedDeliverables,
    page,
    pageSize,
  } = query;

  return {
    providerId: providerId as string,
    referenceType: referenceType as string,
    startDate: startDate ? new Date(`${startDate}T00:00` as string) : undefined,
    endDate: endDate ? new Date(`${endDate}T00:00` as string) : undefined,
    affectedDeliverables: affectedDeliverables as string,
    page: +(page as string) || 0,
    pageSize: +(pageSize as string) || 10,
  };
};
