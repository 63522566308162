import { Typography } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DeleteFollowUpFormDto,
  createDeleteFollowUpFormDto,
} from "../dtos/DeleteFollowUpFormDto";
import { FollowUpDto } from "../dtos/FollowUpDto";

const schema = Yup.object().shape({
  reason: Yup.string().label("Reason").required().max(200),
});
interface Props {
  requestState: RequestStateDto;
  followUp?: FollowUpDto;
  onConfirm(dto: DeleteFollowUpFormDto): Promise<void>;
}

export const confirmDeleteFollowUpDialogId = "delete-follow-up-dialog";
const ConfirmDeleteFollowUpDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  followUp,
}) => {
  const {
    control,
    getValues,
    formState: { isValid },
    reset
  } = useForm({
    defaultValues: createDeleteFollowUpFormDto(),
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={() => {
        onConfirm(getValues());
        reset(createDeleteFollowUpFormDto())
      }}
      modalId={confirmDeleteFollowUpDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Follow Up"
      confirmButtonStyle={{
        color: !isValid ? ColorsEnum.DisabledRed : ColorsEnum.Red,
      }}
      confirmButtonDisabled={!isValid}
      maxWidth="md"
    >
      <ErrorText error={error?.message} />
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to delete this Follow Up? <br /> Enter the reason
        for deleting the follow-up below.
      </Typography>
      <ControlledInput
        control={control}
        name="reason"
        label="Reason"
        type="text-area"
        minRows={6}
        multiline
        fullWidth
      />
    </ConfirmDialog>
  );
};

export default ConfirmDeleteFollowUpDialog;
