import Box from "@material-ui/core/Box/Box";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import LoginFormDto from "../dtos/LoginFormDto";
import { CardActions } from "@material-ui/core";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledPasswordInput from "../../../app/components/inputs/ControlledPasswordInput";
interface Props {
  control: Control<LoginFormDto>;
  isLoading: boolean;
  onSubmit(e?: React.BaseSyntheticEvent<any> | undefined): Promise<any>;
}

const LoginForm: React.FC<Props> = ({ onSubmit, isLoading, control }) => {
  return (
    <Card elevation={4}>
      <form onSubmit={onSubmit}>
        <CardContent>
          <Typography variant="h6" component="h6">
            Login
          </Typography>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="email"
                label="Email"
                fullWidth
                disabled={isLoading}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledPasswordInput
                control={control}
                name="password"
                label="Password"
                fullWidth
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box width="100%" padding="4px" paddingTop="0">
            <SpinnerButton
              showSpinner={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              content="Login"
            />
          </Box>
        </CardActions>
      </form>
    </Card>
  );
};

export default LoginForm;
