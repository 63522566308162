import { List } from "@material-ui/core";
import React, { Fragment } from "react";
import { LocationDto } from "../../../locations/dtos/LocationDto";
import { ProgramLocationDto } from "../../../locations/dtos/ProgramLocationDto";
import { ProgramDto } from "../../../programs/dtos/ProgramDto";
import { ProgramFundingSourceEnum } from "../../../programs/enums/ProgramFundingSourceEnum";
import ProviderProgramLocationItem from "./ProviderProgramLocationItem";
interface Props {
  program: ProgramDto;
  locations: LocationDto[];
  onEditLocationClick: (
    program: ProgramDto,
    programLocation: ProgramLocationDto
  ) => void;
  onTerminateProgramLocationClick: (location: ProgramLocationDto) => void;
}

const ProviderProgramLocations: React.FC<Props> = ({
  program,
  locations,
  onEditLocationClick,
  onTerminateProgramLocationClick,
}) => {
  return (
    <List>
      {program.locations.map((programLocation) => (
        <Fragment key={programLocation.id}>
          <ProviderProgramLocationItem
            programLocation={programLocation}
            location={locations.find(
              (x) => x.id === programLocation.locationId
            )}
            onTerminateProgramLocationClick={() =>
              onTerminateProgramLocationClick(programLocation)
            }
            floridaNetworkFunded={
              program.fundingSource === ProgramFundingSourceEnum.FloridaNetwork
            }
            onEditLocationClick={() =>
              onEditLocationClick(program, programLocation)
            }
          />
        </Fragment>
      ))}
    </List>
  );
};

export default ProviderProgramLocations;
