import { ParsedQuery } from "query-string";
import {
  convertSortDirection,
  PaginationSearchParamsDto,
  paginationSearchParamsToQueryString,
} from "../../../app/dtos/PaginationSearchParamsDto";
import { ProgramTypeEnum } from "../../programs/enums/ProgramTypeEnum";

export interface ProviderSearchParamsDto extends PaginationSearchParamsDto {
  name?: string;
  ids?: string[];
}

export const createProviderSearchParamsDtoFromQuery = (
  query: ParsedQuery<string>
): ProviderSearchParamsDto => {
  const { name, sortBy, page, pageSize, sortDirection } = query;

  return {
    name: name as string,
    sortBy: (sortBy as string) || "name",
    sortDirection: convertSortDirection(sortDirection as string),
    page: +(page as string) || 0,
    pageSize: +(pageSize as string) || 10,
  };
};

export const providerSearchParamsToQueryString = (
  dto: ProviderSearchParamsDto
) => {
  const queryString: string[] = [];

  const paginationQueryString = paginationSearchParamsToQueryString(dto);

  if (paginationQueryString) queryString.push(paginationQueryString);

  if (dto.name) queryString.push(`name=${dto.name}`);

  if (dto.ids && dto.ids.length > 0)
    dto.ids.forEach((id) => queryString.push(`ids=${id}`));

  return queryString.join("&");
};

export interface AllProvidersSearchParamsDto {
  programTypes: ProgramTypeEnum[];
}
