import * as Yup from "yup";

export class ScreeningsReportParamsDto {
  startOn: Date = new Date();
  endOn: Date = new Date();

  public static getSchema() {
    return Yup.object().shape({
      startOn: Yup.date().required(),
      endOn: Yup.date().required()
    });
  }
}
