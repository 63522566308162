import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { ServiceStatusEnum } from "../enums/ServiceStatusEnum";

interface Props {
  status: ServiceStatusEnum;
}

const ServiceStatusLabel: React.FC<Props> = ({ status }) => {
  switch (status) {
    case ServiceStatusEnum.FullAdmission:
      return <Typography component="span">Full Admission</Typography>;
    case ServiceStatusEnum.OrientationProbation:
      return <Typography component="span">Orientation/Probation</Typography>;
    case ServiceStatusEnum.DropIn:
      return <Typography component="span">Drop In</Typography>;
    case ServiceStatusEnum.Hotline:
      return <Typography component="span">Hotline</Typography>;
    case ServiceStatusEnum.StreetOutreach:
      return <Typography component="span">Street Outreach</Typography>;
    default:
      return <Fragment></Fragment>;
  }
};

export default ServiceStatusLabel;
