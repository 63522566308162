import { formatUrl } from "../../app/helpers";
import InvoicePreviewPage from "./pages/InvoicePreviewPage";
import InvoiceListPage from "./pages/InvoiceListPage";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import WriteAccessLevelRoute from "../../app/routes/WriteAccesslevelRoute";

export const createInvoiceRoutes = (baseUrl: string) => [
  <WriteAccessLevelRoute
    path={formatUrl(baseUrl, "preview")}
    component={InvoicePreviewPage}
    key="preview-invoice"
    name={PermissionResourceNameEnum.ProviderInvoice}
    exact
  />,
  <WriteAccessLevelRoute
    path={formatUrl(baseUrl, "preview/fnyfs")}
    component={InvoicePreviewPage}
    key="preview-fnyfs-invoice"
    name={PermissionResourceNameEnum.FnyfsInvoice}
    exact
  />,
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, "")}
    component={InvoiceListPage}
    key="list-invoice"
    name={PermissionResourceNameEnum.ProviderInvoice}
    exact
  />,
];
