import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { trimPhoneNumber } from "../../../app/helpers";
import { YouthDto } from "../dto/YouthDto";
import { YouthFormDto } from "../dto/YouthFormDto";
import { YouthSummaryDto } from "../dto/YouthSummaryDto";
import { useGetYouths } from "./youthHooks";

export const useYouthForm = (
  onSubmit: (dto: YouthFormDto) => Promise<any>,
  youth?: YouthDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: new YouthFormDto(youth),
    resolver: yupResolver(YouthFormDto.getSchema()),
    mode: "all",
  });

  const [getYouths, , getYouthsRequestState] = useGetYouths();
  const [youths, setYouths] = useState<YouthSummaryDto[]>([]);
  const {
    forceSubmit,
    address: { unableToObtainAddress, city, state, zipCode },
    phoneNumber,
  } = watch();
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit(async (dto: YouthFormDto) => {
    dto.preferredName =
      dto.preferredName === "" ? undefined : dto.preferredName;
    dto.preferredPronouns =
      dto.preferredPronouns === "" ? undefined : dto.preferredPronouns;
    if (dto.ssn === "   -  -    ") dto.ssn = undefined;
    if (dto.email === "") dto.email = undefined;
    dto.phoneNumber = trimPhoneNumber(dto.phoneNumber);
    if (dto.phoneNumber === "") dto.phoneNumberType = undefined;
    if (dto.address.address === "") dto.address.isMailingAddress = undefined;
    let createYouth = true;
    if (youths.length === 0 && forceSubmit === false && youth === undefined) {
      const { firstName, lastName, dob } = dto;
      const searchResults = await getYouths({
        firstName,
        lastName,
        dob,
        page: 0,
        pageSize: 10,
      });
      createYouth = searchResults?.count === 0;
      setYouths(searchResults?.items || []);
    }

    if (createYouth) {
      await onSubmit(dto);
    }
  });

  const clearForm = () => {
    reset(new YouthFormDto(youth));
    setYouths([]);
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new YouthFormDto(youth));
  }, [youth, reset]);

  useEffect(() => {
    if (!unableToObtainAddress) return;

    setValue("address.address", "");
    setValue("address.building", "");
    setValue("address.city", city, { shouldValidate: true });
    setValue("address.state", state, { shouldValidate: true });
    setValue("address.zipCode", zipCode, { shouldValidate: true});
    setValue("address.isMailingAddress", false);
  }, [setValue, unableToObtainAddress, city, state, zipCode]);

  useEffect(() => {
    if(!phoneNumber || trimPhoneNumber(phoneNumber)!.length === 0) {
      setValue("phoneNumberType", undefined, { shouldValidate: true })
    }
  }, [phoneNumber, setValue])
  //#endregion

  return {
    state: {
      youths,
      getYouthsRequestState,
      forceSubmit,
    },
    form: { control, isValid, clearForm, setValue },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
