import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { dischargeFormDialogId } from "../../discharges/components/DischargeFormDialog";
import { DischargeFormDto } from "../../discharges/dtos/DischargeFormDto";
import { adbttEligibilityQuestionnaireModalId } from "../../adbtt-eligibility-questionnaires/components/ADBTTEligibilityQuestionnaireFormDialog";
import { ADBTTEligibilityQuestionnaireFormDto } from "../../adbtt-eligibility-questionnaires/dtos/ADBTTEligibilityQuestionnaireFormDto";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import {addDangerNotification, addSuccessNotification} from "../../notifications/state/notificationSlice";
import { setYouthDjjIdNumberModalId } from "../../youths/components/SetYouthDjjIdNumberFormDialog";
import { SetYouthDjjIdNumberFormDto } from "../../youths/dto/SetYouthDjjIdNumberFormDto";
import { useSetYouthDjjIdNumber } from "../../youths/hooks/youthHooks";
import { caseDetailSectionName } from "../components/case-detail-sections/CaseDetailsSection";
import { setCaseScreeningModalId } from "../components/SetCaseScreeningFormDialog";
import { SetCaseScreeningFormDto } from "../dtos/SetCaseScreeningFormDto";
import {
  useChangeDischargeDate,
  useChangeIntakeDate,
  useChangeProgramLocation,
  useCreateADBTTEligibilityQuestionnaire,
  useCreateCaseHold,
  useCreateCaseContact,
  useDeleteCase,
  useDischargeCase,
  useGetCase,
  useGetCaseAlerts,
  useGetCaseAssessments,
  useSetCaseScreening,
  useUpsertCaseDemographic,
  useUpsertNonBillableDays,
  useCreateCaseHtst,
  useUpsertCaseSuicideScreening,
  useCreateCaseSuicideReScreening,
  useCreateCaseSession,
  useGetCaseAlertDismissals,
  useDeleteCaseSession,
  useDeleteCaseDemographic,
  useUpsertSatisfactionSurvey,
  useUpdateCaseDischarge,
  useUpdateCase,
  useUpdateCaseLocation, 
  useDeleteCaseContact, 
  useUpdateCaseContact, 
  useDeleteCaseSuicideScreening, 
  useClearDischarge, 
  useDownloadCourtOrderAttachment, 
  useDeleteCourtOrderAttachment, 
} from "./caseHooks";
import { caseHoldModalId } from "../../case-hold/components/CaseHoldFormDialog";
import { CaseHoldFormDto } from "../../case-hold/dtos/CaseHoldFormDto";
import { useEndCaseHold } from "../../case-hold/hooks/caseHoldHooks";
import { endCaseHoldModalId } from "../../case-hold/components/EndCaseHoldFormDialog";
import { EndCaseHoldFormDto } from "../../case-hold/dtos/EndCaseHoldFormDto";
import { CaseHoldDto } from "../../case-hold/dtos/CaseHoldDto";
import { useHistory } from "react-router";
import { confirmDeleteCaseDialogId } from "../components/ConfirmDeleteCaseDialog";
import { changeDischargeDateFormDialogId } from "../../discharges/components/ChangeDischargeDateFormDialog";
import { ChangeDischargeDateFormDto } from "../../discharges/dtos/ChangeDischargeDateFormDto";
import { changeIntakeDateFormDialogId } from "../../intakes/components/ChangeIntakeDateFormDialog";
import { ChangeIntakeDateFormDto } from "../../intakes/dtos/ChangeIntakeDateFormDto";
import { CaseProgramFormDto } from "../dtos/CaseProgramFormDto";
import { caseProgramLocationModalId } from "../components/change-program-location/CaseProgramFormDialog";
import { caseContactModalId } from "../../case-contacts/components/CaseContactFormDialog";
import { CaseContactFormDto } from "../../case-contacts/dtos/CaseContactFormDto";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useTabIndex } from "../../../app/hooks/useTabIndex";
import { CaseDetailDto } from "../dtos/CaseDetailDto";
import { AlertDto } from "../../alerts/dtos/AlertDto";
import { AlertTypeEnum } from "../../alerts/enums/AlertTypeEnum";
import { DemographicFormDto } from "../../demographics/dtos/DemographicFormDto";
import { demographicModalId } from "../../demographics/components/DemographicFormDialog";
import { NonBillableDaysFormDto } from "../dtos/NonBillableDaysFormDto";
import { nonBillableDayModalId } from "../components/NonBillableDayFormDialog";
import { CaseStaffingDto } from "../../case-staffing/dtos/CaseStaffingDto";
import { CaseStaffingFormDto } from "../../case-staffing/dtos/CaseStaffingFormDto";
import {
  useCreateCaseStaffing,
  useDeleteCaseStaffing,
  useDeleteCinsPetition,
  useUpdateCaseStaffing,
  useUpsertCinsPetition,
} from "../../case-staffing/hooks/caseStaffingHooks";
import { caseStaffingModalId } from "../../case-staffing/components/CaseStaffingFormDialog";
import { confirmDeleteCaseStaffingDialogId } from "../../case-staffing/components/ConfirmDeleteCaseStaffingDialog";
import { CaseDeleteFormDto } from "../dtos/CaseDeleteFormDto";
import { CinsPetitionDto } from "../../case-staffing/dtos/CinsPetitionDto";
import { cinsPetitionModalId } from "../../case-staffing/components/CinsPetitionFormDialog";
import { CinsPetitionFormDto } from "../../case-staffing/dtos/CinsPetitionPetitionFormDto";
import {
  FollowUpAttemptFailureDto,
  FollowUpDto,
} from "../../follow-ups/dtos/FollowUpDto";
import { DeleteFollowUpFormDto } from "../../follow-ups/dtos/DeleteFollowUpFormDto";
import { useDeleteFollowUp } from "../../follow-ups/hooks/followUpHooks";
import { confirmDeleteFollowUpDialogId } from "../../follow-ups/components/ConfirmDeleteFollowUpDialog";
import { confirmDeleteCinsPetitionDialogId } from "../../case-staffing/components/ConfirmDeleteCinsPetitionDialog";
import {useCreateAssessment, useVerifyAssessment} from "../../assessments/hooks/assessmentHooks";
import { assessmentModalId } from "../../assessments/components/AssessmentFormDialog";
import { AssessmentFormDto } from "../../assessments/dtos/AssessmentFormDto";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { AssessmentDto } from "../../assessments/dtos/AssessmentDto";
import { AssessmentTypeEnum } from "../../assessments/enums/AssessmentTypeEnum";
import { updateCompletionDataDialogId } from "../../follow-ups/components/UpdateCompletionDataDialog";
import { updateFollowUpAttemptDataDialogId } from "../../follow-ups/components/UpdateFollowUpAttemptDataDialog";
import { SuicideScreeningFormDto } from "../../suicide-screenings/dtos/SuicideScreeningDto";
import { suicideScreeningModalId } from "../../suicide-screenings/components/SuicideScreeningFormDialog";
import {
  AssessmentTemplateTagEnum,
  assessmentTemplateTagTextMap,
} from "../../assessments/enums/AssessmentTemplateTagEnum";
import { HumanTraffickingScreeningToolFormDto } from "../../human-trafficking-screening-tool/HumanTrafickingScreeningToolDto";
import { htstModalId } from "../../human-trafficking-screening-tool/components/HumanTraffickingScreeningToolFormDialog";
import { caseSessionsFormDialogId } from "../../case-sessions/components/CaseSessionsFormDialog";
import { CaseSessionFormDto } from "../../case-sessions/dtos/CaseSessionFormDto";
import { AssessmentOverrideReasonDto } from "../../assessments/dtos/AssessmentOverrideReasonDto";
import { alertDismissalModalId } from "../../alerts/components/AlertDismissalFormDialog";
import { confirmDeleteCaseSessionDialogId } from "../../case-sessions/components/CaseSessionsDeleteDialog";
import { CaseSessionDto } from "../../case-sessions/dtos/CaseSessionDto";
import { suicideReScreeningModalId } from "../../suicide-screenings/components/SuicideReScreeningFormDialog";
import { caseProgramLocationHistoryModalId } from "../components/CaseProgramLocationHistoryDialog";
import { AssessmentStatusEnum } from "../../assessments/enums/AssessmentStatusEnum";
import { useCreateAlertDismissal } from "../../alerts/hooks/alertHooks";
import { AlertDismissalRequestDto } from "../../alerts/dtos/AlertDismissalDto";
import { deleteDemographicConfirmModalId } from "../components/case-detail-sections/supplemental-sections/DemographicSection";
import { cinsPetitionDetailsModalId } from "../../case-staffing/components/CinsPetitionDetailsDialog";
import { assessmentScoresModalId } from "../../assessments/components/AssessmentScoreDialog";
import { assessmentAmendmentsModalId } from "../../assessments/components/AssessmentAmendmentHistoryDialog";
import { suicideScreeningBakerActModalId } from "../../suicide-screenings/components/SuicideScreeningBakerActDialog";
import { SatisfactionSurveyFormDto } from "../../satisfaction-survey/SatisfactionSurveyDto";
import { satisfactionSurveyModalId } from "../../satisfaction-survey/components/SatisfactionSurveyFormDialog";
import { BakerActDto, BakerActFormDto } from "../../baker-act/dto/BakerActDto";
import {
  useCreateCaseBakerAct,
  useDeleteBakerAct,
  useUpdateBakerAct,
} from "../../baker-act/hooks/bakerActHooks";
import { bakerActFormModalId } from "../../baker-act/components/BakerActFormDialog";
import { confirmDeleteBakerActDialogId } from "../../baker-act/components/ConfirmDeleteBakerActDialog";
import { useDeleteSatisfactionSurvey } from "../../satisfaction-survey/hooks/satisfactionSurveyHooks";
import { confirmDeleteSatisfactionSurveyDialogId } from "../../satisfaction-survey/components/ConfirmDeleteSatisfactionSurveyDialog";
import { selectHasWriteAccess } from "../../authentication/state/authenticationSelectors";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { useRequest } from "../../../app/hooks/useRequest";
import {
  getCaseServicePlanRequest,
  getCaseSessionsRequest,
  getCaseStaffingsRequest,
} from "../CaseRequests";
import { ServicePlanDto } from "../../../service-planning/dto/ServicePlanDto";
import { CaseFormDto } from "../dtos/CaseFormDto";
import { caseFormDialogId } from "../components/CaseFormDialog";
import { CaseLocationFormDto } from "../dtos/CaseLocationFormDto";
import { caseLocationModalId } from "../components/change-program-location/CaseLocationFormDialog";
import { youthRecordTransferModalId } from "../../transfer-youth-record/components/transfer-case/YouthRecordFormDto";
import {confirmDeleteCaseContactDialogId} from "../../case-contacts/components/ConfirmDeleteCaseContactDialog";
import {
  confirmDeleteSuicideScreeningDialogId
} from "../components/case-detail-sections/supplemental-sections/suicide-screening/ConfirmDeleteSuicideScreeningModal";
import {confirmClearDischargeDialogId} from "../../discharges/components/ClearDischargeConfirmationDialog";
import {ClearDischargeFormDto} from "../../discharges/dtos/ClearDischargeFormDto";
import { caseContactNotesModalId } from "../../case-contacts/components/CaseContactNotesDialog";
import { confirmDeleteCourtOrderAttachmentDialog } from "../components/ConfirmDeleteCourtOrderedAttachmentDialog";

//#region Satisfaction Survey
interface SatisfactionSurveyProps {
  upsertSatisfactionSurveyRequestState: RequestStateDto;
  deleteSatisfactionSurveyRequestState: RequestStateDto;
  handleUpsertSatisfactionSurveyClicked(): void;
  handleUpsertSatisfactionSurvey(dto: SatisfactionSurveyFormDto): Promise<void>;
  handleDeleteSatisfactionSurveyClicked(): void;
  handleDeleteSatisfactionSurvey(): Promise<void>;
}

const useSatisfactionSurvey = ({
  refresh,
  caseDto,
}: {
  caseDto?: CaseDetailDto;
  refresh: () => Promise<void>;
}): SatisfactionSurveyProps => {
  const dispatch = useAppDispatch();
  const [
    upsertSatisfactionSurvey,
    ,
    upsertSatisfactionSurveyRequestState,
    clearUpsertSatisfactionSurveyErrors,
  ] = useUpsertSatisfactionSurvey();

  const [
    deleteSatisfactionSurvey,
    ,
    deleteSatisfactionSurveyRequestState,
    clearSatisfactionSurveyErrors,
  ] = useDeleteSatisfactionSurvey();

  const handleUpsertSatisfactionSurveyClicked = () => {
    clearUpsertSatisfactionSurveyErrors();
    dispatch(openModal({ modalId: satisfactionSurveyModalId }));
  };

  const handleUpsertSatisfactionSurvey = async (
    dto: SatisfactionSurveyFormDto
  ) => {
    await upsertSatisfactionSurvey({ id: caseDto!.id, dto });
    batch(() => {
      const action =
        caseDto?.satisfactionSurvey == null ? "Created" : "Updated";
      dispatch(
        addSuccessNotification({ message: `Satisfaction Survey ${action}.` })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleDeleteSatisfactionSurveyClicked = () => {
    clearSatisfactionSurveyErrors();
    dispatch(openModal({ modalId: confirmDeleteSatisfactionSurveyDialogId }));
  };

  const handleDeleteSatisfactionSurvey = async () => {
    await deleteSatisfactionSurvey(caseDto!.satisfactionSurvey!.id);
    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({ message: "Satisfaction Survey Deleted." })
      );
    });
    await refresh();
  };

  return {
    upsertSatisfactionSurveyRequestState,
    deleteSatisfactionSurveyRequestState,
    handleUpsertSatisfactionSurvey,
    handleUpsertSatisfactionSurveyClicked,
    handleDeleteSatisfactionSurveyClicked,
    handleDeleteSatisfactionSurvey,
  };
};

//#endregion

//#region Baker Act
interface BakerActProps {
  selectedBakerAct?: BakerActDto;
  upsertBakerActRequestState: RequestStateDto;
  deleteBakerActRequestState: RequestStateDto;
  handleCreateBakerActClicked(): void;
  handleUpdateBakerActClicked(dto: BakerActDto): void;
  handleDeleteBakerActClicked(dto: BakerActDto): void;
  handleUpsertBakerAct(dto?: BakerActFormDto): Promise<void>;
  handleDeleteBakerAct(): Promise<void>;
}

const useCaseBakerAct = ({
  id,
  refresh,
}: {
  id: string;
  refresh(): Promise<void>;
}): BakerActProps => {
  const dispatch = useAppDispatch();
  const [selectedBakerAct, setSelectedBakerAct] = useState<BakerActDto>();
  const [
    createBakerAct,
    ,
    createBakerActRequestState,
    clearCreateBakerActErrors,
  ] = useCreateCaseBakerAct();
  const [
    updateBakerAct,
    ,
    updateBakerActRequestState,
    cleareUpdateBakerActErrors,
  ] = useUpdateBakerAct();

  const [
    deleteBakerAct,
    ,
    deleteBakerActRequestState,
    clearDeleteBakerActErrors,
  ] = useDeleteBakerAct();

  const handleOpenBakerActModal = (dto?: BakerActDto) => {
    setSelectedBakerAct(dto);
    clearCreateBakerActErrors();
    cleareUpdateBakerActErrors();
    dispatch(openModal({ modalId: bakerActFormModalId }));
  };

  const handleCreateBakerActClicked = () => handleOpenBakerActModal();

  const handleUpdateBakerActClicked = (dto: BakerActDto) =>
    handleOpenBakerActModal(dto);

  const handleUpsertBakerAct = async (dto: BakerActFormDto) => {
    selectedBakerAct != null
      ? await updateBakerAct({ id: selectedBakerAct.id, dto })
      : await createBakerAct({ caseId: id, dto });

    batch(() => {
      const message = selectedBakerAct
        ? "Baker Act Updated"
        : "Baker Act Created";
      dispatch(addSuccessNotification({ message }));
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleDeleteBakerActClicked = (dto: BakerActDto) => {
    setSelectedBakerAct(dto);
    clearDeleteBakerActErrors();
    dispatch(openModal({ modalId: confirmDeleteBakerActDialogId }));
  };

  const handleDeleteBakerAct = async () => {
    await deleteBakerAct(selectedBakerAct!.id);
    batch(() => {
      dispatch(addSuccessNotification({ message: "Baker Act Deleted" }));
      dispatch(closeModal());
    });

    await refresh();
  };

  return {
    selectedBakerAct,
    upsertBakerActRequestState:
      selectedBakerAct != null
        ? updateBakerActRequestState
        : createBakerActRequestState,
    deleteBakerActRequestState,
    handleDeleteBakerAct,
    handleDeleteBakerActClicked,
    handleCreateBakerActClicked,
    handleUpdateBakerActClicked,
    handleUpsertBakerAct,
  };
};

//#endregion

//#region Program Location
interface ProgramLocationProps {
  changeLocationRequestState: RequestStateDto;
  handleChangeLocationClicked(): void;
  handleChangeLocation(dto: CaseLocationFormDto): Promise<void>;

  changeProgramLocationRequestState: RequestStateDto;
  handleChangeProgramLocation(dto: CaseProgramFormDto): Promise<void>;
  handleChangeProgramLocationClicked(): void;
  handleViewProgramLocationHistoryClicked(): void;
}

const useProgramLocation = ({
  id,
  refresh,
}: {
  id: string;
  refresh: () => Promise<void>;
}): ProgramLocationProps => {
  const dispatch = useAppDispatch();

  const [
    changeProgramLocation,
    ,
    changeProgramLocationRequestState,
    clearChangeProgramLocationErrors,
  ] = useChangeProgramLocation();

  const [
    changeLocation,
    ,
    changeLocationRequestState,
    clearChangeLocationErrors,
  ] = useUpdateCaseLocation();

  // Handlers
  const handleChangeProgramLocationClicked = () => {
    clearChangeProgramLocationErrors();
    dispatch(openModal({ modalId: caseProgramLocationModalId }));
  };

  const handleChangeProgramLocation = async (dto: CaseProgramFormDto) => {
    await changeProgramLocation({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({ message: "Case Program/Location Changed" })
      );
      dispatch(closeModal());
    });

    await refresh();
  };

  const handleChangeLocationClicked = () => {
    clearChangeLocationErrors();
    dispatch(openModal({ modalId: caseLocationModalId }));
  };

  const handleChangeLocation = async (dto: CaseLocationFormDto) => {
    await changeLocation({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Case Location Updated" }));
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleViewProgramLocationHistoryClicked = () => {
    dispatch(openModal({ modalId: caseProgramLocationHistoryModalId }));
  };

  return {
    changeLocationRequestState,
    handleChangeLocation,
    handleChangeLocationClicked,

    changeProgramLocationRequestState,
    handleChangeProgramLocation,
    handleChangeProgramLocationClicked,
    handleViewProgramLocationHistoryClicked,
  };
};

//#endregion

export const useCaseDetailPage = (): CaseDetailPageProps => {
  //#region State
  const id = useId();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [tabIndex, handleChangeTabIndex] = useTabIndex(caseDetailSectionName);

  const [getCase, caseResult, getCaseRequestState] = useGetCase();
  const [getCaseAlerts, caseAlerts, getCaseAlertsRequestState] =
    useGetCaseAlerts();
  const [getAssessments, assessments, getAssessmentsRequestState] =
    useGetCaseAssessments();
  const [getAlertDismissals, alertDismissals, getAlertDismissalsRequestState] =
    useGetCaseAlertDismissals();
  const [activeCaseHold, setActiveCaseHold] = useState<CaseHoldDto>();

  const [getCaseStaffings, caseStaffingsResult, getCaseStaffingsRequestState] =
    useRequest(getCaseStaffingsRequest);
  const [getCaseSessions, caseSessionsResult, getCaseSessionsRequestState] =
    useRequest(getCaseSessionsRequest);
  const [
    getCaseServicePlan,
    caseServicePlanResult,
    getCaseServicePlanRequestState,
  ] = useRequest(getCaseServicePlanRequest);

  const [canDischarge, setCanDischarge] = useState(true);
  const canEditYouth = useAppSelector(
    selectHasWriteAccess(PermissionResourceNameEnum.Youth)
  );
  //#endregion

  const handleStartNewCaseClicked = () => {
    history.push(`/youths/${caseResult?.youth.id}/cases/new`, {
      case: caseResult,
    });
  };
  const refresh = useCallback(async () => {
    await getCase(id);
    await getCaseStaffings(id);
    await getCaseSessions(id);
    await getCaseServicePlan(id);
    await getCaseAlerts(id);
    await getAssessments(id);
    await getAlertDismissals(id);
  }, [
    getCase,
    id,
    getCaseStaffings,
    getCaseSessions,
    getCaseServicePlan,
    getCaseAlerts,
    getAssessments,
    getAlertDismissals,
  ]);

  //#region Alert
  // State
  const [selectedAlertType, setSelectedAlertType] = useState<AlertTypeEnum>(
    AlertTypeEnum.CaseNirvanaAssessmentRequired
  );

  // Requests
  const [
    createAlertDismissal,
    ,
    createAlertDismissalRequestState,
    clearCreateAlertDismissalErrors,
  ] = useCreateAlertDismissal();

  // Handlers
  const handleCreateAlertDismissalClicked = (type: AlertTypeEnum) => {
    clearCreateAlertDismissalErrors();
    setSelectedAlertType(type);
    dispatch(openModal({ modalId: alertDismissalModalId }));
  };

  const handleCreateAlertDismissal = async (dto: AlertDismissalRequestDto) => {
    await createAlertDismissal(dto);
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "Alert Dismissed.",
        })
      );
      dispatch(closeModal());
    });

    await refresh();
  };

  const handleRefreshAlertDismissals = async () => {
    await getAlertDismissals(id);
  };

  const alertProps: AlertProps = {
    caseAlerts: caseAlerts || [],
    selectedAlertType,
    getAlertDismissalsRequestState,
    getAlertsRequestState: getCaseAlertsRequestState,
    createAlertDismissalRequestState,
    handleCreateAlertDismissal,
    handleCreateAlertDismissalClicked,
    handleRefreshAlertDismissals,
  };

  //#endregion

  //#region ADBTT
  // Requests
  const [
    createADBTTEligibilityQuestionnaire,
    ,
    createADBTTEligibilityQuestionnaireRequestState,
    clearCreateADBTTEligibilityQuestionnaireError,
  ] = useCreateADBTTEligibilityQuestionnaire();

  // Handlers

  const handleCreateADBTTEligibilityQuestionnaireClicked = () => {
    clearCreateADBTTEligibilityQuestionnaireError();
    dispatch(openModal({ modalId: adbttEligibilityQuestionnaireModalId }));
  };

  const handleCreateADBTTEligibilityQuestionnaire = async (
    dto: ADBTTEligibilityQuestionnaireFormDto
  ) => {
    await createADBTTEligibilityQuestionnaire({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "ADBTT Eligibility Questionnaire Created",
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  const adbttProps: ADBTTProps = {
    createADBTTEligibilityQuestionnaireRequestState,
    handleCreateADBTTEligibilityQuestionnaireClicked,
    handleCreateADBTTEligibilityQuestionnaire,
  };
  //#endregion

  //#region Delete Case
  // Requests
  const [deleteCase, , deleteCaseRequestState, clearDeleteCaseErrors] =
    useDeleteCase();

  // Handlers
  const handleDeleteCaseClicked = () => {
    clearDeleteCaseErrors();
    dispatch(openModal({ modalId: confirmDeleteCaseDialogId }));
  };

  const handleTransferCaseClicked = () => {
    dispatch(openModal({ modalId: youthRecordTransferModalId }));
  };

  const handleDeleteCase = async (dto: CaseDeleteFormDto) => {
    await deleteCase({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Case Deleted" }));
      dispatch(closeModal());
    });

    history.push(`/cases`);
  };

  //#endregion

  //#region Discharge

  // Requests
  const [dischargeCase, , dischargeCaseRequestState, clearDischargeCaseError] =
    useDischargeCase();
  const [
    updateCaseDischarge,
    ,
    updateCaseDischargeRequestState,
    clearUpdateCaseDischargeError,
  ] = useUpdateCaseDischarge();

  const [
    changeDischargeDate,
    ,
    changeDischargeDateRequestState,
    clearChangeDischargeDateErrors,
  ] = useChangeDischargeDate();

  const [
    clearDischarge,
    ,
    clearDischargeRequestState,
    clearClearDischargeErrors
  ] = useClearDischarge();

  // Handlers
  const handleUpsertCaseDischargeClicked = () => {
    clearDischargeCaseError();
    clearUpdateCaseDischargeError();
    dispatch(openModal({ modalId: dischargeFormDialogId }));
  };

  const handleClearDischargeClicked = async () => {
    clearClearDischargeErrors(true);
    dispatch(openModal({ modalId: confirmClearDischargeDialogId }));
  }

  const handleClearDischarge = async (dto: ClearDischargeFormDto) => {
    await clearDischarge({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: 'Discharge Cleared.' }));
      dispatch(closeModal());
    });
    handleChangeTabIndex(undefined as any, caseDetailSectionName)
    await refresh();
  }

  const handleUpsertCaseDischarge = async (dto: DischargeFormDto) => {
    let message = "Case Discharged.";

    if (caseResult?.dischargeInfo == null) {
      await dischargeCase({ id, dto });
    } else {
      await updateCaseDischarge({ id, dto });
      message = "Case Discharge Updated.";
    }

    batch(() => {
      dispatch(addSuccessNotification({ message }));
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleChangeDischargeDateClicked = () => {
    clearChangeDischargeDateErrors();
    dispatch(openModal({ modalId: changeDischargeDateFormDialogId }));
  };

  const handleChangeDischargeDate = async (dto: ChangeDischargeDateFormDto) => {
    await changeDischargeDate({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Discharge Date Changed" }));
      dispatch(closeModal());
    });

    await refresh();
  };

  const mergedState: RequestStateDto = {
    isLoading: updateCaseDischargeRequestState.isLoading || dischargeCaseRequestState.isLoading,
    error: updateCaseDischargeRequestState.error || dischargeCaseRequestState.error
  }

  const dischargeProps: DischargeProps = {
    upsertCaseDischargeRequestState: mergedState,
    changeDischargeDateRequestState,
    clearDischargeRequestState,
    handleUpsertCaseDischarge,
    handleUpsertCaseDischargeClicked,
    handleChangeDischargeDate,
    handleChangeDischargeDateClicked,
    handleClearDischarge,
    handleClearDischargeClicked,
  };
  //#endregion

  //#region Case Hold

  // Requests
  const [
    createCaseHold,
    ,
    createCaseHoldRequestState,
    clearCreateCaseHoldError,
  ] = useCreateCaseHold();

  const [endCaseHold, , endCaseHoldRequestState, clearEndCaseHoldError] =
    useEndCaseHold();

  // Handlers

  const handleCreateCaseHoldClicked = () => {
    clearCreateCaseHoldError();
    dispatch(openModal({ modalId: caseHoldModalId }));
  };

  const handleCreateCaseHold = async (dto: CaseHoldFormDto) => {
    await createCaseHold({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "Case Hold Created",
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleEndCaseHoldClicked = () => {
    clearEndCaseHoldError();
    dispatch(openModal({ modalId: endCaseHoldModalId }));
  };

  const handleEndCaseHold = async (dto: EndCaseHoldFormDto) => {
    await endCaseHold({ id: activeCaseHold?.id!, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "Case Hold Ended",
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  const caseHoldProps: CaseHoldProps = {
    createCaseHoldRequestState,
    endCaseHoldRequestState,
    handleCreateCaseHoldClicked,
    handleCreateCaseHold,
    handleEndCaseHoldClicked,
    handleEndCaseHold,
  };

  //#endregion

  //#region Case Contact
  // Requests
  const [
    createCaseContact,
    ,
    createCaseContactRequestState,
    clearCreateCaseContactErrors,
  ] = useCreateCaseContact();

  const [
    updateCaseContact,
    ,
    updateCaseContactRequestState,
  ] = useUpdateCaseContact();

  const [
    deleteCaseContact,
    ,
    deleteCaseContactRequestState,
  ] = useDeleteCaseContact();

  //Handlers

  const [selectedCaseContactId, setSelectedCaseContactId] = useState<string | undefined>(undefined)

  const handleCreateCaseContactClicked = () => {
    setSelectedCaseContactId(undefined);
    clearCreateCaseContactErrors();
    dispatch(openModal({ modalId: caseContactModalId }));
  };

  const handleCreateCaseContact = async (dto: CaseContactFormDto) => {
    await createCaseContact({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Case Contact Created" }));
      dispatch(closeModal());
    });

    setSelectedCaseContactId(undefined);
    await refresh();
  };

  const handleDeleteCaseContactClicked = (id: string) => {
    setSelectedCaseContactId(id);
    dispatch(openModal({ modalId: confirmDeleteCaseContactDialogId }));
  }

  const handleUpdateCaseContactClicked = (id: string) => {
    setSelectedCaseContactId(id);
    dispatch(openModal({ modalId: caseContactModalId }));
  }

  const handleDeleteCaseContact = async (id: string) => {
    await deleteCaseContact(id);
    batch(() => {
      dispatch(addSuccessNotification({ message: "Case Contact Deleted" }));
      dispatch(closeModal());
    });

    setSelectedCaseContactId(undefined);
    await refresh();
  }

  const handleUpdateCaseContact = async (id: string, dto: CaseContactFormDto) => {
    await updateCaseContact({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Case Contact Updated" }));
      dispatch(closeModal());
    });

    setSelectedCaseContactId(undefined);
    await refresh();
  }

  const handleOpenCaseContactNotesClicked = (id: string) => {
    setSelectedCaseContactId(id);
    dispatch(openModal({ modalId: caseContactNotesModalId }));
  }

  const caseContactProps: CaseContactProps = {
    createCaseContactRequestState: createCaseContactRequestState,
    deleteCaseContactRequestState: deleteCaseContactRequestState,
    updateCaseContactRequestState: updateCaseContactRequestState,
    handleCreateCaseContact: handleCreateCaseContact,
    handleCreateCaseContactClicked: handleCreateCaseContactClicked,
    handleDeleteCaseContactClicked: handleDeleteCaseContactClicked,
    handleUpdateCaseContactClicked: handleUpdateCaseContactClicked,
    handleDeleteCaseContact: handleDeleteCaseContact,
    handleUpdateCaseContact: handleUpdateCaseContact,
    handleOpenCaseContactNotesClicked: handleOpenCaseContactNotesClicked,
    selectedCaseContactId,
  };
  //#endregion

  //#region Assessment
  // State
  const [selectedAssessmentTag, setSelectedAssessmentTag] =
    useState<AssessmentTemplateTagEnum>(AssessmentTemplateTagEnum.Assessment);

  const [assessmentVerificationInProgress, setAssessmentVerificationInProgress] = useState(false);
  const [assessmentVerificationFailed, setAssessmentVerificationFailed] = useState(false);
  const [createdAssessmentId, setCreatedAssessmentId] = useState<string | undefined>(undefined);

  // Requests
  const [
    startAssessment,
    ,
    startAssessmentRequestState,
    clearStartAssessmentErrors,
  ] = useCreateAssessment();

  const [
    verifyAssessment
  ] = useVerifyAssessment();

  // Handlers

  const handleStartAssessmentClicked = (tag: AssessmentTemplateTagEnum) => {
    setSelectedAssessmentTag(tag);
    clearStartAssessmentErrors();
    setAssessmentVerificationFailed(false)
    setAssessmentVerificationInProgress(false)
    setCreatedAssessmentId(undefined);
    dispatch(openModal({ modalId: assessmentModalId }));
  };

  const handleOpenAssessment = (id: string) => {
    window.open(
      `${
        process.env.REACT_APP_ASSESSMENT_CLIENT_URL
      }/assessment/${id}?providerId=${getProviderId()}`
    );
  };

  const handleOpenUnverifiedAssessment = () => {
    if(!createdAssessmentId) return;

    handleOpenAssessment(createdAssessmentId);
  }

  function later(delay: number) {
    return new Promise(function(resolve) {
      setTimeout(resolve, delay);
    });
  }

  const handleStartAssessment = async (dto: AssessmentFormDto) => {
    const result = await startAssessment(dto);

    if (result) {
      setCreatedAssessmentId(result.id);
      setAssessmentVerificationFailed(false)
      setAssessmentVerificationInProgress(true)

      let attemptCount = 1;
      let verifyResult = await verifyAssessment(result.id)

      while(!verifyResult && attemptCount < 7) {
        attemptCount++;
        await later(5000)
        verifyResult = await verifyAssessment(result.id)
      }

      if(verifyResult) {
        setAssessmentVerificationFailed(false)

        batch(() => {
          dispatch(
            addSuccessNotification({
              message: `${assessmentTemplateTagTextMap[selectedAssessmentTag]} Assessment Started`,
            })
          );
          dispatch(closeModal());
        });
        await refresh();
        handleOpenAssessment(result.id);
      }
      else {
        setAssessmentVerificationFailed(true)
        batch(() => {
          dispatch(
            addDangerNotification({
              message: `Could not validate assessment creation.`,
            })
          );
        });
      }

      setAssessmentVerificationInProgress(false)
    }
    // await refresh();
  }

  const handleRefreshAssessments = async () => {
    await getAssessments(id);
    await getCaseAlerts(id);
  };

  const assessmentProps: AssessmentProps = {
    startAssessmentRequestState,
    getAssessmentsRequestState,
    selectedAssessmentTag,
    assessments: assessments || [],
    assessmentOverrideReasons: alertDismissals || [],
    handleStartAssessmentClicked,
    handleStartAssessment,
    handleRefreshAssessments,
    verifyAssessmentFailed: assessmentVerificationFailed,
    verifyAssessmentInProgress: assessmentVerificationInProgress,
    handleOpenUnverifiedAssessment,
  };

  //#endregion

  //#region TOPSE Assessment
  // State
  const [topseAssessments, setTopseAssessments] = useState<AssessmentDto[]>([]);

  // Handlers
  const handleStartTopseAssessmentClicked = () => {
    handleStartAssessmentClicked(AssessmentTemplateTagEnum.Topse);
  };

  // Effects
  useEffect(() => {
    if (assessments != null) {
      const types = [
        AssessmentTypeEnum.TopsePostAssessment,
        AssessmentTypeEnum.TopsePreAssessment,
      ];
      setTopseAssessments(assessments.filter((x) => types.includes(x.type)));
    }
  }, [assessments, setTopseAssessments]);

  const topseAssessmentProps: TopseAssessmentProps = {
    assessments: topseAssessments,
    handleStartTopseAssessmentClicked,
  };
  //#endregion

  //#region NIRVANA Assessment
  // State
  const [nirvanaAssessments, setNirvanaAssessments] = useState<AssessmentDto[]>(
    []
  );
  const [nirvanaDomainNames, setNirvanaDomainNames] = useState<string[]>([]);

  const [selectedNirvanaAssessment, setSelectedNirvanaAssessment] =
    useState<AssessmentDto>();

  // Handlers
  const handleStartNirvanaAssessmentClicked = () => {
    handleStartAssessmentClicked(AssessmentTemplateTagEnum.Nirvana);
  };

  const handleViewNirvanaAssessmentScoresClicked = (dto: AssessmentDto) => {
    setSelectedNirvanaAssessment(dto);
    dispatch(openModal({ modalId: assessmentScoresModalId }));
  };

  const handleViewNirvanaAssessmentAmendmentsClicked = (dto: AssessmentDto) => {
    setSelectedNirvanaAssessment(dto);
    dispatch(openModal({ modalId: assessmentAmendmentsModalId }));
  };

  // Effects
  useEffect(() => {
    if (assessments != null) {
      const types = [
        AssessmentTypeEnum.NirvanaAssessment,
        AssessmentTypeEnum.NirvanaPostAssessment,
        AssessmentTypeEnum.NirvanaReAssessment,
        AssessmentTypeEnum.NirvanaSelfReport,
      ];

      setNirvanaAssessments(assessments.filter((x) => types.includes(x.type)));
    }
  }, [assessments, setNirvanaAssessments]);

  useEffect(() => {
    const domainNames: { [name: string]: number } = {};
    nirvanaAssessments
      .filter((x) => x.status === AssessmentStatusEnum.Submitted)
      .forEach((assessment) => {
        assessment.scores.forEach((score) => {
          score.categoryScores.forEach((categoryScore) => {
            if (!domainNames[categoryScore.categoryName])
              domainNames[categoryScore.categoryName] = categoryScore.order;
          });
        });
      });

    setNirvanaDomainNames(
      Object.entries(domainNames)
        .sort((a, b) => (a[1] > b[1] ? 1 : -1))
        .map((x) => x[0])
    );
  }, [nirvanaAssessments, setNirvanaDomainNames]);

  const nirvanaAssessmentProps: NirvanaAssessmentProps = {
    assessments: nirvanaAssessments,
    domainNames: nirvanaDomainNames,
    selectedAssessment: selectedNirvanaAssessment,
    handleStartNirvanaAssessmentClicked,
    handleViewNirvanaAssessmentScoresClicked,
    handleViewNirvanaAssessmentAmendmentsClicked,
  };

  //#endregion

  //#region Non-Billable Days
  // Requests
  const [
    upsertNonBillableDays,
    ,
    upsertNonBillableDaysRequestState,
    clearUpsertNonBillableDaysErrors,
  ] = useUpsertNonBillableDays();

  // Handlers
  const handleUpsertNonBillableDaysClicked = () => {
    clearUpsertNonBillableDaysErrors();
    dispatch(openModal({ modalId: nonBillableDayModalId }));
  };

  const handleUpsertNonBillableDays = async (dto: NonBillableDaysFormDto) => {
    await upsertNonBillableDays({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({ message: "Non-Billable Days Updated" })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  const nonBillableDaysProps: NonBillableDaysProps = {
    upsertNonBillableDaysRequestState,
    handleUpsertNonBillableDaysClicked,
    handleUpsertNonBillableDays,
  };
  //#endregion

  //#region Screening
  // Requests
  const [
    setCaseScreening,
    ,
    setCaseScreeningRequestState,
    clearSetCaseScreeningError,
  ] = useSetCaseScreening();

  // Handlers
  const handleSetCaseScreeningClicked = () => {
    clearSetCaseScreeningError();
    dispatch(openModal({ modalId: setCaseScreeningModalId }));
  };

  const handleSetCaseScreening = async (dto: SetCaseScreeningFormDto) => {
    await setCaseScreening({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Case Screening Set." }));
      dispatch(closeModal());
    });
    await refresh();
  };

  const screeningProps: ScreeningProps = {
    setCaseScreeningRequestState,
    handleSetCaseScreening,
    handleSetCaseScreeningClicked,
  };
  //#endregion

  //#region Program Location
  // Requests

  const programLocationProps: ProgramLocationProps = useProgramLocation({
    id,
    refresh,
  });
  //#endregion

  //#region Youth
  // Requests
  const [
    setYouthDjjIdNumber,
    ,
    setYouthDjjIdNumberRequestState,
    clearSetYouthDjjIdNumberError,
  ] = useSetYouthDjjIdNumber();

  const handleSetYouthDjjIdNumberClicked = () => {
    clearSetYouthDjjIdNumberError();
    dispatch(openModal({ modalId: setYouthDjjIdNumberModalId }));
  };

  const handleSetYouthDjjIdNumber = async (dto: SetYouthDjjIdNumberFormDto) => {
    await setYouthDjjIdNumber({ id: caseResult?.youth.id!, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Youth DJJ ID # Set." }));
      dispatch(closeModal());
    });
    await refresh();
  };

  // const handleCreateYouthContactClicked = () => {
  //   history.push(`/youths/${caseResult?.youth.id}`);
  // };

  const youthProps: YouthProps = {
    setYouthDjjIdNumberRequestState,
    handleSetYouthDjjIdNumberClicked,
    handleSetYouthDjjIdNumber,
    //handleCreateYouthContactClicked
  };
  //#endregion

  //#region Intake
  // Requests
  const [
    changeIntakeDate,
    ,
    changeIntakeDateRequestState,
    clearChangeIntakeDateErrors,
  ] = useChangeIntakeDate();

  // Handlers
  const handleChangeIntakeDateClicked = () => {
    clearChangeIntakeDateErrors();
    dispatch(openModal({ modalId: changeIntakeDateFormDialogId }));
  };

  const handleChangeIntakeDate = async (dto: ChangeIntakeDateFormDto) => {
    await changeIntakeDate({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Intake Date Changed" }));
      dispatch(closeModal());
    });

    await refresh();
  };

  const intakeProps: IntakeProps = {
    changeIntakeDateRequestState,
    handleChangeIntakeDate,
    handleChangeIntakeDateClicked,
  };
  //#endregion

  //#region Demographic
  // Requests
  const [
    upsertDemographic,
    ,
    upsertDemographicRequestState,
    clearUpsertDemographicError,
  ] = useUpsertCaseDemographic();

  const [
    deleteDemographic,
    ,
    deleteDemographicRequestState,
    clearDeleteDemographicError,
  ] = useDeleteCaseDemographic();

  const handleDeleteDemographicClicked = () => {
    clearDeleteDemographicError();
    dispatch(openModal({ modalId: deleteDemographicConfirmModalId }));
  };

  const handleDeleteDemographic = async () => {
    await deleteDemographic(id);
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `Demographics Removed`,
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleUpsertDemographicClicked = () => {
    clearUpsertDemographicError();
    dispatch(openModal({ modalId: demographicModalId }));
  };

  const handleUpsertDemographic = async (dto: DemographicFormDto) => {
    await upsertDemographic({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `Demographic ${
            caseResult?.demographic ? "Updated" : "Added"
          }`,
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  const demographicProps: DemographicProps = {
    upsertDemographicRequestState,
    handleUpsertDemographicClicked,
    handleUpsertDemographic,
    deleteDemographicRequestState,
    handleDeleteDemographicClicked,
    handleDeleteDemographic,
  };
  //#endregion

  //#region FollowUp Data Changed

  const handleFollowUpDataChanged = async () => {
    if (!caseResult) return;
    batch(() => {
      dispatch(addSuccessNotification({ message: "Follow Up Data Updated" }));
      dispatch(closeModal());
    });

    await refresh();
  };

  const handleServicePlanDataChanged = async () => {
    if (!caseResult) return;
    batch(() => {
      dispatch(addSuccessNotification({ message: "Service Plan Updated" }));
      dispatch(closeModal());
    });

    await refresh();
  };

  //#endregion

  //#region Case Staffing
  // State
  const [selectedCaseStaffing, setSelectedCaseStaffing] =
    useState<CaseStaffingDto>();

  // Requests
  const [
    createCaseStaffing,
    ,
    createCaseStaffingRequestState,
    clearCreateCaseStaffingErrors,
  ] = useCreateCaseStaffing();
  const [
    updateCaseStaffing,
    ,
    updateCaseStaffingRequestState,
    clearUpdateCaseStaffingErrors,
  ] = useUpdateCaseStaffing();
  const [
    deleteCaseStaffing,
    ,
    deleteCaseStaffingRequestState,
    clearDeleteCaseStaffingErrors,
  ] = useDeleteCaseStaffing();

  const [
    upsertCinsPetition,
    ,
    upsertCinsPetitionRequestState,
    clearUpsertCinsPetitionErrors,
  ] = useUpsertCinsPetition();

  const [
    deleteCinsPetition,
    ,
    deleteCinsPetitionRequestState,
    cleareDeleteCinsPetitionErrors,
  ] = useDeleteCinsPetition();

  // Handlers
  const handleUpsertCaseStaffingClicked = (dto?: CaseStaffingDto) => {
    setSelectedCaseStaffing(dto);
    clearUpdateCaseStaffingErrors();
    clearCreateCaseStaffingErrors();
    dispatch(openModal({ modalId: caseStaffingModalId }));
  };

  const handleCreateCaseStaffingClicked = () =>
    handleUpsertCaseStaffingClicked();
  const handleEditCaseStaffingClicked = (dto: CaseStaffingDto) =>
    handleUpsertCaseStaffingClicked(dto);

  const handleDeleteCaseStaffingClicked = (dto: CaseStaffingDto) => {
    clearDeleteCaseStaffingErrors();
    setSelectedCaseStaffing(dto);
    dispatch(openModal({ modalId: confirmDeleteCaseStaffingDialogId }));
  };

  const handleUpsertCaseStaffing = async (dto: CaseStaffingFormDto) => {
    selectedCaseStaffing
      ? await updateCaseStaffing({ id: selectedCaseStaffing.id, dto })
      : await createCaseStaffing({ id, dto });

    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `Case Staffing ${
            selectedCaseStaffing ? "Updated" : "Created"
          }.`,
        })
      );
      dispatch(closeModal());
    });
    refresh();
  };

  const handleDeleteCaseStaffing = async () => {
    await deleteCaseStaffing(selectedCaseStaffing!.id);

    batch(() => {
      dispatch(addSuccessNotification({ message: `Case Staffing Deleted.` }));
      dispatch(closeModal());
    });
    refresh();
  };

  const handleUpsertCinsPetitionClicked = (dto: CaseStaffingDto) => {
    setSelectedCaseStaffing(dto);
    clearUpsertCinsPetitionErrors();
    dispatch(openModal({ modalId: cinsPetitionModalId }));
  };

  const handleUpsertCinsPetition = async (dto: CinsPetitionFormDto) => {
    await upsertCinsPetition({ id: selectedCaseStaffing!.id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `CINS Petition ${
            selectedCaseStaffing?.cinsPetitionInfo != null
              ? "Updated"
              : "Created"
          }.`,
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  // Delete CINS petition
  const handleDeleteCinsPetitionClicked = (dto: CaseStaffingDto) => {
    setSelectedCaseStaffing(dto);
    cleareDeleteCinsPetitionErrors();
    dispatch(openModal({ modalId: confirmDeleteCinsPetitionDialogId }));
  };

  const handleDeleteCinsPetition = async () => {
    await deleteCinsPetition(selectedCaseStaffing?.cinsPetitionInfo?.id!);
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "CINS Petition Deleted.",
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleViewCinsPetitionClicked = (dto: CaseStaffingDto) => {
    setSelectedCaseStaffing(dto);
    dispatch(openModal({ modalId: cinsPetitionDetailsModalId }));
  };

  const caseStaffingProps: CaseStaffingProps = {
    caseStaffings: caseStaffingsResult || [],
    caseStaffingsRequestState: getCaseStaffingsRequestState,
    deleteCaseStaffingRequestState,
    upsertCaseStaffingRequestState: selectedCaseStaffing
      ? updateCaseStaffingRequestState
      : createCaseStaffingRequestState,
    upsertCinsPetitionRequestState,
    deleteCinsPetitionRequestState,
    selectedCaseStaffing,
    handleCreateCaseStaffingClicked,
    handleEditCaseStaffingClicked,
    handleDeleteCaseStaffingClicked,
    handleUpsertCaseStaffing,
    handleDeleteCaseStaffing,
    handleUpsertCinsPetition,
    handleUpsertCinsPetitionClicked,
    handleDeleteCinsPetition,
    handleDeleteCinsPetitionClicked,
    handleViewCinsPetitionClicked,
  };
  //#endregion

  //#region Follow Ups
  // State
  const [selectedFollowUp, setSelectedFollowUp] = useState<FollowUpDto>();

  const [selectedFollowUpAttempt, setSelectedFollowUpAttempt] =
    useState<FollowUpAttemptFailureDto>();

  // Requests
  const [
    deleteFollowUp,
    ,
    deleteFollowUpRequestState,
    clearDeleteFollowUpErrors,
  ] = useDeleteFollowUp();

  // Handlers
  const handleDeleteFollowUpClicked = (dto: FollowUpDto) => {
    setSelectedFollowUp(dto);
    clearDeleteFollowUpErrors();
    dispatch(openModal({ modalId: confirmDeleteFollowUpDialogId }));
  };

  const handleDeleteFollowUp = async (dto: DeleteFollowUpFormDto) => {
    await deleteFollowUp({ id: selectedFollowUp!.id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Follow Up Deleted." }));
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleUpdateFollowUpAttemptDataClicked = (
    dto: FollowUpDto,
    attempt: FollowUpAttemptFailureDto
  ) => {
    setSelectedFollowUp(dto);
    setSelectedFollowUpAttempt(attempt);
    clearDeleteFollowUpErrors();
    dispatch(openModal({ modalId: updateFollowUpAttemptDataDialogId }));
  };

  const handleUpdateFollowUpAttemptData = async () => {
    //await deleteFollowUp({ id: selectedFollowUp!.id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Follow Up Updated." }));
      dispatch(closeModal());
    });
    await refresh();
  };

  const handleUpdateFollowUpCompletionDataClicked = (dto: FollowUpDto) => {
    setSelectedFollowUp(dto);
    clearDeleteFollowUpErrors();
    dispatch(openModal({ modalId: updateCompletionDataDialogId }));
  };

  const handleUpdateFollowUpCompletionData = async () => {
    batch(() => {
      dispatch(addSuccessNotification({ message: "Follow Up Updated." }));
      dispatch(closeModal());
    });
    await refresh();
  };

  const followUpProps: FollowUpProps = {
    selectedFollowUp,
    selectedFollowUpAttempt,
    deleteFollowUpRequestState,
    handleDeleteFollowUp,
    handleDeleteFollowUpClicked,
    handleUpdateFollowUpAttemptDataClicked,
    handleUpdateFollowUpAttemptData,
    handleUpdateFollowUpCompletionDataClicked,
    handleUpdateFollowUpCompletionData,
  };
  //#endregion

  //#region Private
  // Requests
  const [createHtst, , createHtstRequestState, clearCreateHtstErrors] =
    useCreateCaseHtst();

  // Handlers
  const handleCreateHtstClicked = () => {
    clearCreateHtstErrors();
    dispatch(openModal({ modalId: htstModalId }));
  };

  const handleCreateHtst = async (
    dto: HumanTraffickingScreeningToolFormDto
  ) => {
    await createHtst({ id, dto });

    batch(() => {
      dispatch(addSuccessNotification({ message: "HTST Created." }));
      dispatch(closeModal());
    });
    refresh();
  };

  const htstProps: HtstProps = {
    createHtstRequestState,
    handleCreateHtstClicked,
    handleCreateHtst,
  };
  // #region Suicide Screening

  // Requests
  const [
    upsertCaseSuicideScreening,
    ,
    upsertCaseSuicideScreeningRequestState,
    clearUpsertCaseSuicideScreeningErrors,
  ] = useUpsertCaseSuicideScreening();

  const [
    createCaseSuicideReScreening,
    ,
    createSuicideReScreeningRequestState,
    clearCreateCaseSuicideReScreeningErrors,
  ] = useCreateCaseSuicideReScreening();

  const [deleteSuicideScreening, , deleteSuicideScreeningRequestState] = useDeleteCaseSuicideScreening();

  const [selectedScreeningId, setSelectedScreeningId] = useState<string | undefined>(undefined)

  const handleStartSuicideScreeningClicked = () => {
    clearUpsertCaseSuicideScreeningErrors();
    dispatch(openModal({ modalId: suicideScreeningModalId }));
  };

  const handleStartSuicideScreening = async (dto: SuicideScreeningFormDto) => {
    await upsertCaseSuicideScreening({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({ message: "Suicide Screening Created." })
      );
      dispatch(closeModal());
      dispatch(openModal({ modalId: suicideScreeningBakerActModalId }));
    });
    await refresh();
  };

  const handleUpdateSuicideScreeningClicked = () => {
    clearUpsertCaseSuicideScreeningErrors();
    dispatch(openModal({ modalId: suicideScreeningModalId }));
  };

  const handleUpdateSuicideScreening = async (dto: SuicideScreeningFormDto) => {
    await upsertCaseSuicideScreening({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({ message: "Suicide Screening Updated." })
      );
      dispatch(closeModal());
      dispatch(openModal({ modalId: suicideScreeningBakerActModalId }));
    });
    await refresh();
  };

  const handleDeleteSuicideScreeningClicked = async (suicideScreeningId: string) => {
    setSelectedScreeningId(suicideScreeningId);
    dispatch(openModal({ modalId: confirmDeleteSuicideScreeningDialogId }));
  }

  const handleDeleteSuicideScreening = async () => {
    if(!caseResult) return;
    const caseId = caseResult.id;

    await deleteSuicideScreening({ caseId, suicideScreeningId: selectedScreeningId! });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Suicide Screening Deleted." }));
      dispatch(closeModal());
    })
    await refresh();
    setSelectedScreeningId(undefined);
  }

  const handleAddSuicideReScreeningClicked = () => {
    clearCreateCaseSuicideReScreeningErrors();
    dispatch(openModal({ modalId: suicideReScreeningModalId }));
  };

  const handleAddSuicideReScreening = async (dto: SuicideScreeningFormDto) => {
    await createCaseSuicideReScreening({ id, dto });

    batch(() => {
      dispatch(
        addSuccessNotification({ message: "Suicide Re-Screening Added." })
      );
      dispatch(closeModal());
    });

    await refresh();
  };

  const suicideScreeningProps: SuicideScreeningProps = {
    handleStartSuicideScreening,
    handleStartSuicideScreeningClicked,
    startSuicideScreeningRequestState: upsertCaseSuicideScreeningRequestState,
    handleAddSuicideReScreening,
    handleAddSuicideReScreeningClicked,
    createSuicideReScreeningRequestState,
    handleUpdateSuicideScreening,
    handleUpdateSuicideScreeningClicked,
    updateSuicideScreeningRequestState: upsertCaseSuicideScreeningRequestState,
    handleDeleteSuicideScreeningClicked,
    handleDeleteSuicideScreening,
    deleteSuicideScreeningRequestState,
  };

  //#endregion

  //#region Case Sessions

  // Requests
  const [
    createCaseSession,
    ,
    createCaseSessionRequestState,
    clearCreateCaseSessionErrors,
  ] = useCreateCaseSession();

  const [
    deleteCaseSession,
    ,
    deleteCaseSessionRequestState,
    clearDeleteCaseSessionErrors,
  ] = useDeleteCaseSession();

  // Handlers
  const handleCreateCaseSessionClicked = () => {
    clearCreateCaseSessionErrors();
    dispatch(openModal({ modalId: caseSessionsFormDialogId }));
  };

  const handleCreateCaseSession = async (dto: CaseSessionFormDto) => {
    await createCaseSession({ id, dto });

    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `Case Session Created.`,
        })
      );
      dispatch(closeModal());
    });
    refresh();
  };

  const handleDeleteCaseSessionClicked = () => {
    clearDeleteCaseSessionErrors();
    dispatch(openModal({ modalId: confirmDeleteCaseSessionDialogId }));
  };

  const handleDeleteCaseSession = async (dto: CaseSessionDto) => {
    await deleteCaseSession({ id: dto.caseId, caseSessionId: dto.id });

    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "Case Session Deleted.",
        })
      );
      dispatch(closeModal());
    });
    refresh();
  };

  const caseSessionProps: CaseSessionProps = {
    caseSessions: caseSessionsResult || [],
    caseSessionsRequestState: getCaseSessionsRequestState,
    createCaseSessionRequestState,
    deleteCaseSessionRequestState,
    handleCreateCaseSessionClicked,
    handleCreateCaseSession,
    handleDeleteCaseSessionClicked,
    handleDeleteCaseSession,
  };

  //#endregion

  //#region Update Case
  const [updateCase, , updateCaseRequestState, clearUpdateCaseErrors] =
    useUpdateCase();

  const [ downloadCourtOrderAttachment ] = useDownloadCourtOrderAttachment();
  const [ deleteCourtOrderAttachment, , deleteCourtOrderAttachmentRequestState ] = useDeleteCourtOrderAttachment();

  const handleUpdateCaseClicked = () => {
    clearUpdateCaseErrors();
    dispatch(openModal({ modalId: caseFormDialogId }));
  };

  const handleUpdateCase = async (dto: CaseFormDto) => {
    await updateCase({ id, dto });
    batch(() => {
      dispatch(closeModal());
      dispatch(addSuccessNotification({ message: "Intake Details Updated." }));
    });
    await refresh();
  };

  const handleViewCourtOrderAttachmentClicked = async (dto: CaseDetailDto) => {
    await downloadCourtOrderAttachment(dto);
  };

  const handleDeleteCourtOrderAttachmentClicked = () => {
    dispatch(openModal({ modalId: confirmDeleteCourtOrderAttachmentDialog }));
  };

  const handleDeleteCourtOrderAttachment = async () => {
    await deleteCourtOrderAttachment(caseResult!.id);
    batch(() => {
      dispatch(closeModal());
      dispatch(addSuccessNotification({ message: "Court Order Document Deleted" }));
    });
    await refresh();
  }

  const handleCourtOrderAttachmentUploaded = async () => {
    dispatch(addSuccessNotification({ message: "Court Order Document Uploaded" }));
    await refresh();
  }  
  //#endregion

  //#region Effects
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    refresh();
  }, [id, refresh]);

  useEffect(() => {
    setActiveCaseHold(caseResult?.caseHolds.find((x) => x.endAt === undefined));
  }, [caseResult, setActiveCaseHold]);

  useEffect(() => {
    setCanDischarge(
      caseAlerts?.some((x) =>
        [
          AlertTypeEnum.CaseScreeningRequired,
          AlertTypeEnum.YouthDjjIdRequired,
          AlertTypeEnum.YouthLegalGuardianRequired,
          AlertTypeEnum.CaseNirvanaAssessmentRequired,
          AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired,
          AlertTypeEnum.CaseTopsePostAssessmentRequired,
          AlertTypeEnum.CaseTopsePreAssessmentRequired,          
        ].includes(x.type)
      ) === false
    );
  }, [caseAlerts, setCanDischarge]);
  //#endregion

  return {
    servicePlan: {
      servicePlan: caseServicePlanResult,
      requestState: getCaseServicePlanRequestState,
    },
    tabIndex,
    case: {
      caseResult,
      activeCaseHold,
      getCaseRequestState,
      deleteCaseRequestState,
      updateCaseRequestState,
      nirvana: nirvanaAssessmentProps,
      topse: topseAssessmentProps,
      assessment: assessmentProps,
      screening: screeningProps,
      programLocation: programLocationProps,
      intake: intakeProps,
      suicideScreening: suicideScreeningProps,
      handleDeleteCaseClicked,
      handleDeleteCase,
      handleStartNewCaseClicked,
      handleUpdateCase,
      handleUpdateCaseClicked,
      handleViewCourtOrderAttachmentClicked,
      handleDeleteCourtOrderAttachmentClicked,
      handleCourtOrderAttachmentUploaded,
      handleDeleteCourtOrderAttachment,
      deleteCourtOrderAttachmentRequestState,
      handleTransferCaseClicked
    },
    followUps: followUpProps,
    caseStaffing: caseStaffingProps,
    youth: youthProps,
    discharge: dischargeProps,
    caseHold: caseHoldProps,
    caseContacts: caseContactProps,
    adbtt: adbttProps,
    htst: htstProps,
    alerts: alertProps,
    canDischarge,
    canEditYouth,
    demographic: demographicProps,
    nonBillableDays: nonBillableDaysProps,
    caseSession: caseSessionProps,
    bakerAct: useCaseBakerAct({ id, refresh }),
    satisfactionSurvey: useSatisfactionSurvey({ refresh, caseDto: caseResult }),
    handleChangeTabIndex,
    handleStartNewCaseClicked,
    handleFollowUpDataChanged,
    handleServicePlanDataChanged,
    refreshCase: refresh,
  };
};

export interface CaseDetailPageProps {
  tabIndex: string;
  caseHold: CaseHoldProps;
  discharge: DischargeProps;
  caseContacts: CaseContactProps;
  youth: YouthProps;
  case: CaseProps;
  adbtt: ADBTTProps;
  htst: HtstProps;
  alerts: AlertProps;
  canDischarge: boolean;
  canEditYouth: boolean;
  demographic: DemographicProps;
  nonBillableDays: NonBillableDaysProps;
  caseSession: CaseSessionProps;
  caseStaffing: CaseStaffingProps;
  followUps: FollowUpProps;
  satisfactionSurvey: SatisfactionSurveyProps;
  bakerAct: BakerActProps;
  servicePlan?: CaseServicePlanProps;
  handleChangeTabIndex(e: React.ChangeEvent<any>, index: string): void;
  handleStartNewCaseClicked(): void;
  handleFollowUpDataChanged(): Promise<void>;
  handleServicePlanDataChanged(): Promise<void>;
  refreshCase(): Promise<void>;
}

interface AlertProps {
  caseAlerts: AlertDto[];
  selectedAlertType: AlertTypeEnum;
  getAlertDismissalsRequestState: RequestStateDto;
  getAlertsRequestState: RequestStateDto;
  createAlertDismissalRequestState: RequestStateDto;
  handleCreateAlertDismissalClicked(type: AlertTypeEnum): void;
  handleCreateAlertDismissal(dto: AlertDismissalRequestDto): Promise<void>;
  handleRefreshAlertDismissals(): Promise<void>;
}

interface ADBTTProps {
  createADBTTEligibilityQuestionnaireRequestState: RequestStateDto;
  handleCreateADBTTEligibilityQuestionnaireClicked(): void;
  handleCreateADBTTEligibilityQuestionnaire(
    dto: ADBTTEligibilityQuestionnaireFormDto
  ): Promise<void>;
}

interface CaseProps {
  caseResult?: CaseDetailDto;
  activeCaseHold?: CaseHoldDto;
  getCaseRequestState: RequestStateDto;
  deleteCaseRequestState: RequestStateDto;
  updateCaseRequestState: RequestStateDto;
  nirvana: NirvanaAssessmentProps;
  topse: TopseAssessmentProps;
  assessment: AssessmentProps;
  screening: ScreeningProps;
  programLocation: ProgramLocationProps;
  intake: IntakeProps;
  suicideScreening: SuicideScreeningProps;
  handleDeleteCaseClicked(): void;
  handleDeleteCase(dto: CaseDeleteFormDto): Promise<void>;
  handleStartNewCaseClicked(): void;
  handleUpdateCaseClicked(): void;
  handleUpdateCase(dto: CaseFormDto): Promise<void>;
  handleTransferCaseClicked(): void;
  handleViewCourtOrderAttachmentClicked(dto:CaseDetailDto) : Promise<void>;
  handleDeleteCourtOrderAttachmentClicked(): void;
  handleCourtOrderAttachmentUploaded(): Promise<void>;
  handleDeleteCourtOrderAttachment(): Promise<void>;
  deleteCourtOrderAttachmentRequestState: RequestStateDto;
}

interface CaseHoldProps {
  createCaseHoldRequestState: RequestStateDto;
  endCaseHoldRequestState: RequestStateDto;
  handleCreateCaseHoldClicked(): void;
  handleCreateCaseHold(dto: CaseHoldFormDto): Promise<void>;
  handleEndCaseHoldClicked(): void;
  handleEndCaseHold(dto: EndCaseHoldFormDto): Promise<void>;
}

interface CaseStaffingProps {
  caseStaffings: CaseStaffingDto[];
  caseStaffingsRequestState: RequestStateDto;
  upsertCaseStaffingRequestState: RequestStateDto;
  deleteCaseStaffingRequestState: RequestStateDto;
  upsertCinsPetitionRequestState: RequestStateDto;
  deleteCinsPetitionRequestState: RequestStateDto;
  selectedCaseStaffing?: CaseStaffingDto;
  selectedCinsPetition?: CinsPetitionDto;
  handleDeleteCinsPetitionClicked(dto: CaseStaffingDto): void;
  handleDeleteCinsPetition(): Promise<void>;
  handleCreateCaseStaffingClicked(): void;
  handleEditCaseStaffingClicked(dto: CaseStaffingDto): void;
  handleUpsertCaseStaffing(dto: CaseStaffingFormDto): Promise<void>;
  handleDeleteCaseStaffingClicked(dto: CaseStaffingDto): void;
  handleDeleteCaseStaffing(): Promise<void>;
  handleUpsertCinsPetitionClicked(dto: CaseStaffingDto): void;
  handleUpsertCinsPetition(dto: CinsPetitionFormDto): Promise<void>;
  handleViewCinsPetitionClicked(dto: CaseStaffingDto): void;
}

interface DischargeProps {
  upsertCaseDischargeRequestState: RequestStateDto;
  changeDischargeDateRequestState: RequestStateDto;
  clearDischargeRequestState: RequestStateDto;
  handleUpsertCaseDischarge(dto: DischargeFormDto): Promise<void>;
  handleUpsertCaseDischargeClicked(): void;
  handleChangeDischargeDate(dto: ChangeDischargeDateFormDto): Promise<void>;
  handleChangeDischargeDateClicked(): void;
  handleClearDischarge(dto: ClearDischargeFormDto): Promise<void>;
  handleClearDischargeClicked(): void;
}

interface FollowUpProps {
  selectedFollowUp?: FollowUpDto;
  selectedFollowUpAttempt?: FollowUpAttemptFailureDto;
  deleteFollowUpRequestState: RequestStateDto;
  handleDeleteFollowUpClicked(dto: FollowUpDto): void;
  handleDeleteFollowUp(dto: DeleteFollowUpFormDto): Promise<void>;
  handleUpdateFollowUpAttemptDataClicked(
    dto: FollowUpDto,
    attempt: FollowUpAttemptFailureDto
  ): void;
  handleUpdateFollowUpAttemptData(): Promise<void>;
  handleUpdateFollowUpCompletionDataClicked(dto: FollowUpDto): void;
  handleUpdateFollowUpCompletionData(): Promise<void>;
}

interface CaseContactProps {
  selectedCaseContactId: string | undefined;
  createCaseContactRequestState: RequestStateDto;
  deleteCaseContactRequestState: RequestStateDto;
  updateCaseContactRequestState: RequestStateDto;
  handleCreateCaseContact(dto: CaseContactFormDto): Promise<void>;
  handleDeleteCaseContact(id: string): Promise<void>;
  handleUpdateCaseContact(id: string, dto: CaseContactFormDto): Promise<void>;
  handleCreateCaseContactClicked(): void;
  handleDeleteCaseContactClicked(id: string): void;
  handleUpdateCaseContactClicked(id: string): void;
  handleOpenCaseContactNotesClicked(id: string): void;
}

interface DemographicProps {
  upsertDemographicRequestState: RequestStateDto;
  handleUpsertDemographicClicked(): void;
  handleUpsertDemographic(dto: DemographicFormDto): Promise<void>;
  deleteDemographicRequestState: RequestStateDto;
  handleDeleteDemographicClicked(): void;
  handleDeleteDemographic(): Promise<void>;
}

interface IntakeProps {
  changeIntakeDateRequestState: RequestStateDto;
  handleChangeIntakeDate(dto: ChangeIntakeDateFormDto): Promise<void>;
  handleChangeIntakeDateClicked(): void;
}

interface NirvanaAssessmentProps {
  assessments: AssessmentDto[];
  domainNames: string[];
  selectedAssessment?: AssessmentDto;
  handleStartNirvanaAssessmentClicked(): void;
  handleViewNirvanaAssessmentScoresClicked(dto: AssessmentDto): void;
  handleViewNirvanaAssessmentAmendmentsClicked(dto: AssessmentDto): void;
}

interface TopseAssessmentProps {
  assessments: AssessmentDto[];
  handleStartTopseAssessmentClicked(): void;
}

interface AssessmentProps {
  startAssessmentRequestState: RequestStateDto;
  getAssessmentsRequestState: RequestStateDto;
  assessments: AssessmentDto[];
  assessmentOverrideReasons: AssessmentOverrideReasonDto[];
  selectedAssessmentTag: AssessmentTemplateTagEnum;
  handleStartAssessmentClicked(tag: AssessmentTemplateTagEnum): void;
  handleStartAssessment(dto: AssessmentFormDto): Promise<void>;
  handleRefreshAssessments(): Promise<void>;
  verifyAssessmentInProgress: boolean;
  verifyAssessmentFailed: boolean;
  handleOpenUnverifiedAssessment: () => void;
}

interface HtstProps {
  createHtstRequestState: RequestStateDto;
  handleCreateHtstClicked(): void;
  handleCreateHtst(dto: HumanTraffickingScreeningToolFormDto): Promise<void>;
}

interface NonBillableDaysProps {
  upsertNonBillableDaysRequestState: RequestStateDto;
  handleUpsertNonBillableDaysClicked(): void;
  handleUpsertNonBillableDays(dto: NonBillableDaysFormDto): Promise<void>;
}

interface ScreeningProps {
  setCaseScreeningRequestState: RequestStateDto;
  handleSetCaseScreening(dto: SetCaseScreeningFormDto): Promise<void>;
  handleSetCaseScreeningClicked(): void;
}

interface SuicideScreeningProps {
  startSuicideScreeningRequestState: RequestStateDto;
  handleStartSuicideScreeningClicked(): void;
  handleStartSuicideScreening(dto: SuicideScreeningFormDto): Promise<void>;
  updateSuicideScreeningRequestState: RequestStateDto;
  handleUpdateSuicideScreeningClicked(): void;
  handleUpdateSuicideScreening(dto: SuicideScreeningFormDto): Promise<void>;
  handleDeleteSuicideScreeningClicked(suicideScreeningId: string): void;
  handleDeleteSuicideScreening(): Promise<void>;
  createSuicideReScreeningRequestState: RequestStateDto;
  handleAddSuicideReScreeningClicked(): void;
  handleAddSuicideReScreening(dto: SuicideScreeningFormDto): Promise<void>;
  deleteSuicideScreeningRequestState: RequestStateDto;
}
// suicideScreeningModalId
interface YouthProps {
  setYouthDjjIdNumberRequestState: RequestStateDto;
  handleSetYouthDjjIdNumberClicked(): void;
  handleSetYouthDjjIdNumber(dto: SetYouthDjjIdNumberFormDto): Promise<void>;
  //handleCreateYouthContactClicked(): void;
}

interface CaseSessionProps {
  caseSessions: CaseSessionDto[];
  caseSessionsRequestState: RequestStateDto;
  createCaseSessionRequestState: RequestStateDto;
  deleteCaseSessionRequestState: RequestStateDto;
  handleCreateCaseSessionClicked(): void;
  handleCreateCaseSession(dto: CaseSessionFormDto): Promise<void>;
  handleDeleteCaseSessionClicked(): void;
  handleDeleteCaseSession(dto: CaseSessionDto): Promise<void>;
}

interface CaseServicePlanProps {
  servicePlan?: ServicePlanDto;
  requestState: RequestStateDto;
}
