import { useRequest } from "../../../app/hooks/useRequest";
import { UpdateUserEmailFormDto } from "../dtos/UpdateUserEmailFormDto";
import { UserFormDto } from "../dtos/UserFormDto";
import {
  activateUserRoleRequest,
  addProviderRoleRequest,
  createUserRequest,
  deactivateUserRoleRequest,
  getUserRequest,
  getUsersLastGeneratedPinRequest,
  getUsersRequest,
  removeProviderRoleRequest,
  unlockAccountRequest,
  updateUserEmailRequest,
  updateUserRequest,
} from "../UserRequests";

interface UpdateRequestParams {
  id: string;
  dto: UserFormDto;
}

interface UpdateUserEmailParams {
  id: string;
  dto: UpdateUserEmailFormDto;
}

export const useCreateUser = () => useRequest(createUserRequest);
export const useGetUsers = () => useRequest(getUsersRequest);
export const useGetUser = () => useRequest(getUserRequest);
export const useUpdateUser = () =>
  useRequest(
    (params: UpdateRequestParams) => updateUserRequest(params.id, params.dto),
    true
  );
export const useUpdateUserEmail = () =>
  useRequest(
    (params: UpdateUserEmailParams) =>
      updateUserEmailRequest(params.id, params.dto),
    true
  );
export const useRemoveProviderRole = () =>
  useRequest(removeProviderRoleRequest);

export const useAddProviderRole = () => useRequest(addProviderRoleRequest);

export const useUnlockAccount = () => useRequest(unlockAccountRequest);

export const useGetUsersLastGeneratedPin = () =>
  useRequest(getUsersLastGeneratedPinRequest);

export const useActivateUserRole = () => useRequest(activateUserRoleRequest);

export const useDectivateUserRole = () => useRequest(deactivateUserRoleRequest);
