import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import { EventLogSearchParamsDto } from "../dtos/EventLogSearchParamsDto";
import { useEventLogFilter } from "../hooks/useEventLogFilter";
import ControlledInput from "../../../app/components/inputs/ControlledInput";

interface Props {
  onClose: () => void;
  params: EventLogSearchParamsDto;
  onSearch: (dto: EventLogSearchParamsDto) => void;
  anchorEl: HTMLButtonElement | null;
  providers: ProviderDto[];
  referenceTypeOptions: SelectOption[];
  affectedDeliverableOptions: SelectOption[];
}

const EventLogFilter: React.FC<Props> = ({
  onClose,
  params,
  onSearch,
  anchorEl,
  providers,
  referenceTypeOptions,
  affectedDeliverableOptions
}) => {
  //#region State
  const {
    form: { control, clearAllFilters },
    handlers: { handleSubmit },
  } = useEventLogFilter(onSearch, onClose, params);

  //#endregion

  const getSortedProviders = () => {
    return providers.sort((a, b) => (a.name < b.name ? -1 : 1));
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">
                  Invoicing Event Log Filters
                </Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            {/* Provider Name */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="providerId"
                  label="Provider"
                  fullWidth
                  options={providerToOptionDtos(
                    getSortedProviders(),
                    [],
                    "All"
                  )}
                />
              </Grid>
            </FnAccessWrapper>

            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="referenceType"
                label="Resource Type Affected"
                fullWidth
                options={referenceTypeOptions}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="affectedDeliverables"
                label="Affected Deliverables?"
                fullWidth
                options={affectedDeliverableOptions}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography color="primary">Date Range</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="startDate"
                label="Start Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="endDate"
                label="End Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>


            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default EventLogFilter;
