import { ReportFormatEnum } from "../enums/ReportFormatEnum";
import * as Yup from "yup";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../authentication/constants";

export enum MonthlyReportCardDateRangeType {
  MonthYear = "MonthYear",
  FiscalYearToDate = "FiscalYearToDate",
  PreviousFiscalYear = "PreviousFiscalYear",
}

export const monthlyReportCardDateRangeTypeOptions: SelectOption[] = [
  { value: "", label: "Select Date Range Type" },
  { value: MonthlyReportCardDateRangeType.MonthYear, label: "Month/Year" },
  {
    value: MonthlyReportCardDateRangeType.FiscalYearToDate,
    label: "Current Fiscal Year to Date",
  },
  {
    value: MonthlyReportCardDateRangeType.PreviousFiscalYear,
    label: "Previous Fiscal Year",
  },
];

export class MonthlyReportCardSearchParamsDto {
  providerId?: string = "";
  dateRangeType: string = "";
  month: string = "";
  year: string = "";
  format: string = ReportFormatEnum.PDF;

  constructor() {
    const providerId = getProviderId();

    if (providerId != null && providerId !== fnyfsProviderId)
      this.providerId = providerId;
  }

  public static getSchema() {
    return Yup.object().shape({
      year: Yup.string().required().label("Year").required(),
      month: Yup.string().test(
        "month-required",
        "Month is required.",
        function (value) {
          if (
            this.parent.dateRangeType !==
            MonthlyReportCardDateRangeType.MonthYear
          )
            return true;

          return value != null && `${value}` !== "";
        }
      ),
      dateRangeType: Yup.string().required().label("Date Range Type"),
      providerId: Yup.string().required().label("Provider")
    });
  }
}
