import {ProgramFundingSourceEnum} from "../../programs/enums/ProgramFundingSourceEnum";
import * as Yup from "yup";
import {AssessmentTypeEnum} from "../../assessments/enums/AssessmentTypeEnum";

export class NirvanaAveragesOrImpactReportParameters {
  intakeStart?: string;
  intakeEnd?: string;
  fundingSource: ProgramFundingSourceEnum | string;
  contractId?: string;
  programTypeId?: string;
  providerId?: string;
  programId?: string;
  locationId?: string;
  intakeCounselorId?: string;
  zipCode?: string;
  assessmentTypes: AssessmentTypeEnum[] = [AssessmentTypeEnum.NirvanaAssessment];

  constructor(params?: NirvanaAveragesOrImpactReportParameters) {
    this.intakeStart = params?.intakeStart;
    this.intakeEnd = params?.intakeEnd;
    this.fundingSource = params?.fundingSource || "";
    this.contractId = params?.contractId || "";
    this.programTypeId = params?.programTypeId || "";
    this.providerId = params?.providerId || "";
    this.programId = params?.programId || "";
    this.locationId = params?.locationId || "";
    this.intakeCounselorId = params?.intakeCounselorId || "";
    this.zipCode = params?.zipCode || "";
    this.assessmentTypes = params?.assessmentTypes || [AssessmentTypeEnum.NirvanaAssessment];
  }

  public static getSchema() {
    return Yup.object().shape({
      assessmentTypes: Yup.array().min(1, "You must select at least one (1) Assessment Type").label("Assessment Types"),
    });
  }

}
