import {useAppDispatch} from "../../app/hooks";
import {openModal} from "../../features/modal/state/modalSlice";
import {servicePlanParticipantFormDialogId} from "../components/ServicePlanParticipantFormDialog";
import {createServicePlanGoalFormDialogId} from "../components/CreateServicePlanGoalFormDialog";
import {addServicePlanInterventionFormDialogId} from "../components/AddServicePlanInterventionFormDialog";
import {useState} from "react";
import {servicePlanInterventionNotesDialogId} from "../components/ServicePlanInterventionNotesDialog";
import {updateServicePlanGoalFormDialogId} from "../components/UpdateServicePlanGoalFormDialog";
import {updateServicePlanInterventionFormDialogId} from "../components/UpdateServicePlanInterventionFormDialog";
import {addServicePlanReviewFormDialogId} from "../components/AddServicePlanReviewFormDialog";
import {viewServicePlanReviewDetailsModalId} from "../components/ViewServicePlanReviewDetailsModal";
import {updateServicePlanReviewModalId} from "../components/UpdateServicePlanReviewFormDialog";

export const useServicePlan = () => {

  const dispatch = useAppDispatch();

  const [selectedGoalId, setSelectedGoalId] = useState<string>();
  const [selectedInterventionId, setSelectedInterventionId] = useState<string>();

  const [selectedReviewId, setSelectedReviewId] = useState<string>();

  const onAddParticipantClicked = async () => {
    dispatch(openModal({ modalId: servicePlanParticipantFormDialogId }));
  };

  const onAddGoalClicked = async () => {
    dispatch(openModal({ modalId: createServicePlanGoalFormDialogId }));
  };

  const onUpdateGoalClicked = async (goalId: string) => {
    setSelectedGoalId(goalId);
    dispatch(openModal({ modalId: updateServicePlanGoalFormDialogId }));
  };

  const onAddGoalInterventionClicked = async (goalId: string) => {
    setSelectedGoalId(goalId);
    dispatch(openModal({ modalId: addServicePlanInterventionFormDialogId }));
  }

  const onViewGoalInterventionNotesClicked = async (goalId: string, interventionId: string) => {
    setSelectedGoalId(goalId);
    setSelectedInterventionId(interventionId);
    dispatch(openModal({ modalId: servicePlanInterventionNotesDialogId }));
  }

  const onUpdateGoalInterventionClicked = async (goalId: string, interventionId: string) => {
    setSelectedGoalId(goalId);
    setSelectedInterventionId(interventionId);
    dispatch(openModal({ modalId: updateServicePlanInterventionFormDialogId }));
  }

  const onAddServicePlanReviewClicked = async () => {
    dispatch(openModal({ modalId: addServicePlanReviewFormDialogId }));
  }

  const onViewServicePlanReviewDetailsClicked = async (reviewId: string) => {
    setSelectedReviewId(reviewId);
    dispatch(openModal({ modalId: viewServicePlanReviewDetailsModalId }));
  }

  const onUpdateServicePlanReviewClicked = async (reviewId: string) => {
    setSelectedReviewId(reviewId);
    dispatch(openModal({ modalId: updateServicePlanReviewModalId }));
  }

  return {
    state: {
      selectedGoalId,
      selectedInterventionId,
      selectedReviewId,
    },
    form: {  },
    handlers: {
      onAddParticipantClicked,
      onAddGoalClicked,
      onUpdateGoalClicked,
      onAddGoalInterventionClicked,
      onUpdateGoalInterventionClicked,
      onViewGoalInterventionNotesClicked,
      onAddServicePlanReviewClicked,
      onViewServicePlanReviewDetailsClicked,
      onUpdateServicePlanReviewClicked
    },
  };

}
