import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum ReferrerRelationshipEnum {
  Parent = "Parent",
  Grandparent = "Grandparent",
  Sibling = "Sibling",
  OtherAdultRelative = "OtherAdultRelative",
  LegalGuardian = "LegalGuardian",
  ProbationOfficer = "ProbationOfficer",
  SocialWorker = "SocialWorker",
  MentalHealthTherapist = "MentalHealthTherapist",
  SchoolCounselor = "SchoolCounselor",
  Employer = "Employer",
  Self = "Self",
  Other = "Other",
}

export const referrerRelationshipTextMap: { [key: string]: string } = {
  [ReferrerRelationshipEnum.Parent]: "Parent",
  [ReferrerRelationshipEnum.Grandparent]: "Grandparent",
  [ReferrerRelationshipEnum.Sibling]: "Sibling",
  [ReferrerRelationshipEnum.OtherAdultRelative]: "Other Adult Relative",
  [ReferrerRelationshipEnum.LegalGuardian]: "Legal Guardian",
  [ReferrerRelationshipEnum.ProbationOfficer]: "Probation Officer",
  [ReferrerRelationshipEnum.SocialWorker]: "Social Worker",
  [ReferrerRelationshipEnum.MentalHealthTherapist]: "Mental Health Therapist",
  [ReferrerRelationshipEnum.SchoolCounselor]: "School Counselor",
  [ReferrerRelationshipEnum.Employer]: "Employer",
  [ReferrerRelationshipEnum.Self]: "Self",
  [ReferrerRelationshipEnum.Other]: "Other",
};

export const getReferrerRelationshipOptions = (): SelectOption[] => {
  let keys = Object.keys(ReferrerRelationshipEnum);

  return [
    {
      value: "",
      label: "Select Relationship",
    },
    ...keys.map((status) => ({
      value: status,
      label: referrerRelationshipTextMap[status],
    })),
  ];
};
