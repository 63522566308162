import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { useHumanTraffickingScreeningToolForm } from "../hooks/useHumanTraffickingScreeningToolForm";
import {
  HumanTraffickingScreeningToolDto,
  HumanTraffickingScreeningToolFormDto,
  HumanTraffickingScreeningToolResultOptions,
} from "../HumanTrafickingScreeningToolDto";

export const htstModalId = "htst-form-modal";

interface Props {
  htst?: HumanTraffickingScreeningToolDto;
  requestState: RequestStateDto;
  onSubmit(dto: HumanTraffickingScreeningToolFormDto): Promise<any>;
}

const HumanTraffickingScreeningToolFormDialog: React.FC<Props> = ({
  htst,
  requestState,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useHumanTraffickingScreeningToolForm(
    onSubmit,
    htst
  );
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === htstModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Enter Human Trafficking Screening Result
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="administeredOn"
                label="Administered Date*"
                type="date"
                disabled={isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                options={HumanTraffickingScreeningToolResultOptions}
                name="result"
                label="Result*"
                control={control}
                disabled={isLoading}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default HumanTraffickingScreeningToolFormDialog;
