import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";

interface Props {
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
  onConfirm(): Promise<void>;
}

export const confirmDeleteSatisfactionSurveyDialogId =
  "delete-satisfaction-survey-dialog";
const ConfirmDeleteSatisfactionSurveyDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  caseDto,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteSatisfactionSurveyDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Satisfaction Survey"
      confirmButtonStyle={{
        color: ColorsEnum.Red,
      }}
      maxWidth="md"
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to delete the Satisfaction Survey for Case{" "}
        {caseDto?.caseNumber}?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteSatisfactionSurveyDialog;
