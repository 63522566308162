import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum AffectedDeliverablesEnum {
  Yes = "Yes",
  YesWithCorrections = "YesWithCorrections",
  No = "No",
}

export const affectedDeliverablesEnumTextMap: { [key: string]: string } = {
    [AffectedDeliverablesEnum.Yes]: "Deliverables Affected",
    [AffectedDeliverablesEnum.YesWithCorrections]: "Deliverables Affected + Possible Invoice Correction",
    [AffectedDeliverablesEnum.No]: "No Deliverables Affected" 
};

export const getAffectedDeliverableEnumOptions = (
  defaultLabel: string = "All (no filter applied)"
): SelectOption[] => [
  { value: "", label: defaultLabel },
  ...Object.keys(AffectedDeliverablesEnum).map((x) => ({
    value: x,
    label: affectedDeliverablesEnumTextMap[x],
  })),
];
