import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { SnapInSchoolsCycleDto } from "../dtos/SnapInSchoolsCycleDto";

interface Props {
  onConfirm(): Promise<void>;
  requestState: RequestStateDto;
  cycle?: SnapInSchoolsCycleDto;
}

export const confirmDeleteSnapInSchoolsCycleDialogId =
  "delete-snap-in-schools-cycle-dialog";
const ConfirmDeleteSnapInSchoolsCycleDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  cycle,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteSnapInSchoolsCycleDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Cycle"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to delete SNAP in Schools Cycle{" "}
        {cycle?.referenceNumber}? <br />
        <br /> <strong>This action may result in invoice corrections.</strong>
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteSnapInSchoolsCycleDialog;
