import React from "react";
import ErrorAlert from "../../../app/components/ErrorAlert";
import Header from "../../../app/components/Header";
import {useEventLogPage} from "../hooks/useEventLogPage";
import EventLogList from "../components/EventLogList";
import EventLogFilter from "../components/EventLogFilter";
import FilterButton from "../../../app/components/buttons/FilterButton";
import EventLogFilterList from "../components/EventLogFilterList";

const EventLogPage = () => {
  const { state, handlers } = useEventLogPage();

  const {
    eventLogListResult,
    getEventLogListRequestState,
    getProvidersRequestState,
    params,
    providers,
    referenceTypeOptions,
    affectedDeliverableOptions,
    filterAnchorEl,
  } = state;
  const {
    handlePageChange,
    handleSearch,
    handleOpenFilterClicked,
    handleCloseFilter,
  } = handlers;

  return (
    <div>
      <EventLogFilter
        params={params}
        onSearch={handleSearch}
        providers={providers}
        referenceTypeOptions={referenceTypeOptions}
        affectedDeliverableOptions={affectedDeliverableOptions}
        onClose={handleCloseFilter}
        anchorEl={filterAnchorEl}
      />
      <ErrorAlert error={getEventLogListRequestState.error?.message} />

      <Header
        title="Invoicing Events"
        actions={<>
          <FilterButton
            title="Filter List"
            onClick={handleOpenFilterClicked}
            disabled={
              getProvidersRequestState.isLoading
            }
          />
        </>}
     />
       <ErrorAlert error={getEventLogListRequestState.error?.message} />
      <EventLogFilterList 
        params={params} 
        isLoading={getEventLogListRequestState.isLoading} 
        providers={providers} 
        referenceTypeOptions={referenceTypeOptions} 
        affectedDeliverableOptions={affectedDeliverableOptions}
        onSearch={handleSearch} 
        />
       <EventLogList
         eventLogListResult={eventLogListResult || { items: [], page: 0, pageSize: 0, count: 0 }}
         onPageChange={handlePageChange}
         isLoading={getEventLogListRequestState.isLoading}
       />
    </div>
  );
};

export default EventLogPage;
