import React from "react";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import { JjisProgramTypeEnum } from "../../jjis/enums/JjisProgramTypeEnum";
import { ProgramTypeEnum } from "../../programs/enums/ProgramTypeEnum";
import { ContractTypeEnum } from "../enums/ContractTypeEnum";

export interface ProgramTypeDto {
  id: string;
  name: string;
  jjisProgramType?: JjisProgramTypeEnum;
  isResidential: boolean;
  canWaitlist: boolean;
  canHaveCases: boolean;
  contractType?: ContractTypeEnum;
  canApplyNonBillableDays: boolean;
  canApplyCaseStaffings: boolean;
  canApplyCaseContacts: boolean;
  canApplyCaseSessions: boolean;
  canCreateProgramFrom: boolean;
  programType: ProgramTypeEnum;
  displayOrder: number;
}

export const programTypeToOption = (dto: ProgramTypeDto): SelectOption => ({
  value: dto.id,
  label: dto.name,
});

export const programTypesToOptions = (
  dtos: ProgramTypeDto[],
  defaultLabel: React.ReactNode = "Select Program Type"
): SelectOption[] => {
  return [{ value: "", label: defaultLabel }, ...dtos.map(programTypeToOption)];
};
