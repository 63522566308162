import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { ScreeningFilterFormDto } from "./ScreeningFilterFormDto";

export class ScreeningSearchParamsDto implements PaginationSearchParamsDto {
  screeningNumber?: string;
  providerId?: string;
  youthFirstName?: string;
  youthLastName?: string;
  screeningAtStart?: string;
  screeningAtEnd?:string;

  assignedToWaitList?: boolean;
  waitListStatus?: string;
  waitListProgramId?: string;
  waitListLocationId?: string;

  includeDeleted: boolean;
  
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: ScreeningFilterFormDto;
    previous?: ScreeningSearchParamsDto;
  }) {

    this.screeningNumber = form?.screeningNumber || (query?.screeningNumber as string) || "";
    this.providerId = form?.providerId || (query?.providerId as string) || "";
    this.youthFirstName = form?.youthFirstName || (query?.youthFirstName as string) || "";
    this.youthLastName = form?.youthLastName || (query?.youthLastName as string) || "";
    this.screeningAtStart = form?.screeningAtStart || (query?.screeningAtStart as string) || "";
    this.screeningAtEnd = form?.screeningAtEnd || (query?.screeningAtEnd as string) || "";
    this.assignedToWaitList = form?.assignedToWaitList || (query?.assignedToWaitList === 'true') || false;
    this.waitListStatus = form?.waitListStatus || (query?.waitListStatus as string) || "";
    this.waitListProgramId = form?.waitListProgramId || (query?.waitListProgramId as string) || "";
    this.waitListLocationId = form?.waitListLocationId || (query?.waitlistLocationId as string) || "";
    this.includeDeleted = form?.includeDeleted || (query?.includeDeleted === 'true') || false;

    this.sortBy = previous?.sortBy || (query?.sortBy as string) || "";
    this.sortDirection =
      (previous?.sortDirection as SortDirectionEnum) ||
      QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;

  }

  public static toQueryString(dto: ScreeningSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
