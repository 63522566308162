import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum WaitlistStatusEnum {
  Pending = "Pending",
  CaseCreated = "CaseCreated",
  Removed = "Removed",
}

export const waitlistStatusTextMap: { [key: string]: string } = {
  [WaitlistStatusEnum.Pending]: "Pending",
  [WaitlistStatusEnum.CaseCreated]: "Case Created",
  [WaitlistStatusEnum.Removed]: "Removed",
};

export const waitlistStatusOptions: SelectOption[] = [
  { value: "", label: "All" },
  ...Object.keys(WaitlistStatusEnum).map((status) => ({
    value: status,
    label: waitlistStatusTextMap[status],
  })),
];
