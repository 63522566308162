import _ from "lodash";

const EXPIRE_TIME = "_expiredTime";

export class InactivityTimeout {
  private eventHandler: () => void;
  private interval: NodeJS.Timeout | undefined;

  constructor(
    private onTimeout: () => void,
    private timeout: number,
    private events: string[]
  ) {
    this.eventHandler = _.debounce(this.updateExpiredTime, 3000, {
      maxWait: 3000,
    }).bind(this);
    this.initTracker();
  }

  private updateExpiredTime() {
    localStorage.setItem(
      EXPIRE_TIME,
      `${Date.now() + this.timeout * 60 * 1000}`
    );
  }

  startInterval() {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      const expiredTime = +(localStorage.getItem(EXPIRE_TIME) || 0);
      if (expiredTime < Date.now()) {
        this.onTimeout();
        this.cleanUp();
      }
    }, 3000);
  }

  cleanUp() {
    if (this?.interval) {
      clearInterval(this.interval);

      this?.events.forEach((name) =>
        window.removeEventListener(name, this.eventHandler)
      );
    }
  }

  private initTracker() {
    this.events.map((name) =>
      window.addEventListener(
        name,
        _.debounce(this.eventHandler, 3000, { maxWait: 3000 })
      )
    );
  }
}
