import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { YouthFilterFormDto } from "./YouthFilterFormDto";

export class YouthSearchParamsDto implements PaginationSearchParamsDto {
  firstName?: string;
  lastName?: string;
  dob?: string;
  referenceNumber?: string;
  djjIdNumber?: string;
  ids?: string[];
  activeOnly?: boolean;
  zipCode?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: YouthFilterFormDto;
    previous?: YouthSearchParamsDto;
  }) {
    this.firstName = form?.firstName || (query?.firstName as string);
    this.lastName = form?.lastName || (query?.lastName as string);
    this.dob = form?.dob || (query?.dob as string);
    this.referenceNumber =
      form?.referenceNumber || (query?.referenceNumber as string);
    this.djjIdNumber = form?.djjIdNumber || (query?.djjIdNumber as string);
    this.activeOnly = query?.activeOnly === 'true' || false;
    if (form !== undefined) this.activeOnly = form.activeOnly;

    this.zipCode = form?.zipCode || (query?.zipCode as string) || "";
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
    this.sortBy = previous?.sortBy || (query?.sortBy as string) || "firstName";
    this.sortDirection =
      previous?.sortDirection || QueryStringHelpers.getSortDirection(query);
  }

  public static toQueryString(dto: YouthSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
