import FileUploadComponent from '../../../app/components/FileUploadComponent'
import { toOriginalFilename } from '../../../app/helpers';

interface Props {
  onCourtOrderedAttachmentUploaded: (filename: string) => void;
  filename?: string;
  disabled?: boolean;
  error?: string;
}

const CaseCourtOrderAttachmentUploadComponent = ({onCourtOrderedAttachmentUploaded, filename, disabled, error}: Props) => {
  const allowedFileTypes = [
    ".pdf",
    ".jpg",
    ".jpeg",
    ".png"
  ];

  return (
  <FileUploadComponent 
    onFileUploaded={onCourtOrderedAttachmentUploaded} 
    allowedFiletypes={allowedFileTypes.join(",")} 
    uploadButtonText="Upload Court Order"
    defaultFileName={toOriginalFilename(filename)}
    error={error} 
    disabled={disabled}/>
    );
}

export default CaseCourtOrderAttachmentUploadComponent