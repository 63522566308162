import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum EmploymentStatusEnum {
  FullTime = "FullTime",
  PartTime = "PartTime",
  SeasonalSporadic = "SeasonalSporadic",
  NotEmployedLooking = "NotEmployedLooking",
  NotEmployedInSchool = "NotEmployedInSchool",
  NotEmployedUnableToWork = "NotEmployedUnableToWork",
  NotEmployedNotLooking = "NotEmployedNotLooking",
  DoNotKnowOther = "DoNotKnowOther",
}

export const employmentStatusTextMap: { [key: string]: string } = {
  [EmploymentStatusEnum.FullTime]: "Full Time (Over 35 Hours)",
  [EmploymentStatusEnum.PartTime]: "Part Time",
  [EmploymentStatusEnum.SeasonalSporadic]: "Seasonal/Sporadic",
  [EmploymentStatusEnum.NotEmployedLooking]: "Not Employed, looking for work",
  [EmploymentStatusEnum.NotEmployedInSchool]: "Not Employed, in school",
  [EmploymentStatusEnum.NotEmployedUnableToWork]:
    "Not Employed, unable to work",
  [EmploymentStatusEnum.NotEmployedNotLooking]: "Not Employed, not looking",
  [EmploymentStatusEnum.DoNotKnowOther]: "Do Not Know/Other",
};

export const employmentStatusOptions: SelectOption[] = [
  { value: "", label: "Select Employment Status" },
  ...Object.keys(EmploymentStatusEnum).map((status) => ({
    value: status,
    label: employmentStatusTextMap[status],
  })),
];
