import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { useAppDispatch } from "../../../app/hooks";
import { useAnchor } from "../../../app/hooks/useAnchor";
import { useQuery } from "../../../app/hooks/useQuery";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { useGetAllProviders } from "../../provider/hooks/providerHooks";
import { setYouthDjjIdNumberModalId } from "../../youths/components/SetYouthDjjIdNumberFormDialog";
import { SetYouthDjjIdNumberFormDto } from "../../youths/dto/SetYouthDjjIdNumberFormDto";
import { useSetYouthDjjIdNumber } from "../../youths/hooks/youthHooks";
import { djjEntryDetailsModalId } from "../components/DjjEntryDetailsDialog";
import { DjjEntryNotesModalId } from "../components/DjjEntryNotesDialog";
import { setDjjEntryStatusModalId } from "../components/SetDjjEntryStatusFormDialog";
import {
  DjjEntryDetailDto,
  DjjEntryListDto,
  DjjEntrySearchParamsDto,
  SetDjjEntryNotesFormDto,
  SetDjjEntryStatusFormDto,
} from "../DjjEntryDto";
import {
  useGetDjjEntries,
  useGetDjjEntryDetails,
  useSetDjjEntryNotes,
  useSetDjjEntryStatus,
} from "./DjjEntryHooks";

//#region DJJ Entry
interface DjjEntryStatusProps {
  selectedDjjEntry?: DjjEntryListDto;
  setDjjEntryStatusRequestState: RequestStateDto;
  handleSetDjjEntryStatusClicked(dto: DjjEntryListDto): void;
  handleSetDjjEntryStatus(dto: SetDjjEntryStatusFormDto): Promise<void>;
}

const useDjjEntryStatus = ({
  refresh,
}: {
  refresh: () => Promise<void>;
}): DjjEntryStatusProps => {
  const [selectedDjjEntry, setSelectedDjjEntry] = useState<DjjEntryListDto>();
  const [
    setDjjEntryStatus,
    ,
    setDjjEntryStatusRequestState,
    clearSetDjjEntryStatusError,
  ] = useSetDjjEntryStatus();
  const dispatch = useAppDispatch();

  const handleSetDjjEntryStatusClicked = (dto: DjjEntryListDto) => {
    clearSetDjjEntryStatusError();
    setSelectedDjjEntry(dto);
    dispatch(openModal({ modalId: setDjjEntryStatusModalId }));
  };

  const handleSetDjjEntryStatus = async (dto: SetDjjEntryStatusFormDto) => {
    await setDjjEntryStatus({
      referenceId: selectedDjjEntry!.referenceId,
      referenceType: selectedDjjEntry!.referenceType,
      dto,
    });

    batch(() => {
      dispatch(
        addSuccessNotification({ message: "DJJ Entry Status Updated." })
      );
      dispatch(closeModal());
    });

    await refresh();
  };

  return {
    selectedDjjEntry,
    setDjjEntryStatusRequestState,
    handleSetDjjEntryStatus,
    handleSetDjjEntryStatusClicked,
  };
};
//#endregion

//#region Set Youth DJJ ID
interface SetYouthDjjIdProps {
  selectedYouthId?: string;
  currentDjjId?: number;
  setYouthDjjIdRequestState: RequestStateDto;
  handleSetYouthDjjIdClicked(entry: DjjEntryListDto): void;
  handleSetYouthDjjId(dto: SetYouthDjjIdNumberFormDto): Promise<void>;
}

const useSetYouthDjjId = ({
  refresh,
}: {
  refresh: () => Promise<void>;
}): SetYouthDjjIdProps => {
  const [selectedDjjEntry, setSelectedDjjEntry] = useState<DjjEntryListDto>();
  const [setYouthDjjId, , setYouthDjjIdRequestState, clearSetYouthDjjIdError] =
    useSetYouthDjjIdNumber();
  const dispatch = useAppDispatch();

  const handleSetYouthDjjIdClicked = (entry: DjjEntryListDto) => {
    clearSetYouthDjjIdError();
    setSelectedDjjEntry(entry);
    dispatch(openModal({ modalId: setYouthDjjIdNumberModalId }));
  };

  const handleSetYouthDjjId = async (dto: SetYouthDjjIdNumberFormDto) => {
    await setYouthDjjId({ id: selectedDjjEntry!.youthId, dto });
    batch(() => {
      dispatch(closeModal());
      dispatch(addSuccessNotification({ message: "Youth DJJ ID Set." }));
    });

    await refresh();
  };

  return {
    selectedYouthId: selectedDjjEntry?.youthId,
    currentDjjId: selectedDjjEntry?.djjIdNumber,
    setYouthDjjIdRequestState,
    handleSetYouthDjjIdClicked,
    handleSetYouthDjjId,
  };
};
//#endregion

//#region DJJ Entry Notes
interface DjjEntryNotesProps {
  selectedDjjEntry?: DjjEntryListDto;
  setDjjEntryNotesRequestState: RequestStateDto;
  handleOpenDjjEntryNotesClicked(dto: DjjEntryListDto): void;
  handleSetDjjEntryNotes(dto: SetDjjEntryNotesFormDto): Promise<void>;
}

const useDjjEntryNotes = ({
  refresh,
}: {
  refresh: () => Promise<void>;
}): DjjEntryNotesProps => {
  const [selectedDjjEntry, setSelectedDjjEntry] = useState<DjjEntryListDto>();
  const dispatch = useAppDispatch();
  const [
    setDjjEntryNotes,
    ,
    setDjjEntryNotesRequestState,
    clearSetDjjEntryNotesError,
  ] = useSetDjjEntryNotes();

  const handleOpenDjjEntryNotesClicked = async (dto: DjjEntryListDto) => {
    clearSetDjjEntryNotesError();
    setSelectedDjjEntry(dto);
    dispatch(openModal({ modalId: DjjEntryNotesModalId }));
  };

  const handleSetDjjEntryNotes = async (dto: SetDjjEntryNotesFormDto) => {
    const referenceType = selectedDjjEntry!.referenceType;
    const referenceId = selectedDjjEntry!.referenceId;
    await setDjjEntryNotes({ referenceId, referenceType, dto });

    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({
          message: `DJJ Entry Notes Updated.`,
        })
      );
    });
    refresh();
  };

  return {
    selectedDjjEntry,
    setDjjEntryNotesRequestState,
    handleOpenDjjEntryNotesClicked,
    handleSetDjjEntryNotes,
  };
};

//#endregion

//#region DJJ Entry Details
interface DjjEntryDetailsParams {
  selectedDjjEntry?: DjjEntryListDto;
  djjEntryDetails?: DjjEntryDetailDto;
  getDjjEntryDetailsRequestState: RequestStateDto;
  handleViewDjjEntryDetailsClicked(dto: DjjEntryListDto): void;
}

const useDjjEntryDetailsParams = (): DjjEntryDetailsParams => {
  const [selectedDjjEntry, setSelectedDjjEntry] = useState<DjjEntryListDto>();
  const [
    getDjjEntryDetails,
    djjEntryDetails,
    getDjjEntryDetailsRequestState,
    clearGetDjjEntryErrors,
  ] = useGetDjjEntryDetails();
  const dispatch = useAppDispatch();

  const handleViewDjjEntryDetailsClicked = async (dto: DjjEntryListDto) => {
    clearGetDjjEntryErrors();
    setSelectedDjjEntry(dto);

    dispatch(openModal({ modalId: djjEntryDetailsModalId }));
  };

  useEffect(() => {
    if (selectedDjjEntry == null) return;

    const { referenceId, referenceType } = selectedDjjEntry;
    getDjjEntryDetails({ referenceId, referenceType });
  }, [getDjjEntryDetails, selectedDjjEntry]);

  return {
    selectedDjjEntry,
    djjEntryDetails,
    getDjjEntryDetailsRequestState,
    handleViewDjjEntryDetailsClicked,
  };
};

//#endregion

export interface DjjEntryListPageParams {
  result: PaginationResultDto<DjjEntryListDto>;
  getDjjEntriesRequestState: RequestStateDto;
  providers: ProviderDto[];
  getProvidersRequestState: RequestStateDto;
  params: DjjEntrySearchParamsDto;
  entryStatus: DjjEntryStatusProps;
  youthDjjId: SetYouthDjjIdProps;
  notes: DjjEntryNotesProps;
  details: DjjEntryDetailsParams;
  filterAnchorEl: HTMLElement | null;
  handlePageChange(page: number): void;
  handleSort(sortBy: string, sortDirection: SortDirectionEnum): void;
  handleOpenFilterClicked(event: React.MouseEvent<HTMLElement>): void;
  handleCloseFilter(): void;
  handleSearch(dto: DjjEntrySearchParamsDto): void;
}

export const useDjjEntryListPage = (): DjjEntryListPageParams => {
  const query = useQuery();
  const [params, setParams] = useState<DjjEntrySearchParamsDto>(
    new DjjEntrySearchParamsDto({ query })
  );
  const history = useHistory();
  const [getDjjEntries, djjEntries, getDjjEntriesRequestState] =
    useGetDjjEntries();
  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();

  const [filterAnchorEl, handleOpenFilterClicked, handleCloseFilter] =
    useAnchor();

  const refresh = useCallback(async () => {
    await getDjjEntries(params);
  }, [params, getDjjEntries]);

  const handlePageChange = (page: number) => {
    const newParams = { ...params, page };
    setParams(newParams);
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    setParams(newParams);
  };

  const handleSearch = (dto: DjjEntrySearchParamsDto) => {
    setParams(dto);
  };

  useEffect(() => {
    history.push(
      `/djj-related-data?${QueryStringHelpers.toQueryString(params)}`
    );
  }, [params, history]);

  const djjEntryStatusProps = useDjjEntryStatus({ refresh });
  const youthDjjIdProps = useSetYouthDjjId({ refresh });
  const notesProps = useDjjEntryNotes({ refresh });
  const detailProps = useDjjEntryDetailsParams();
  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    getProviders(undefined);
  }, [getProviders]);

  return {
    result: djjEntries || new PaginationResultDto<DjjEntryListDto>(),
    params,
    getDjjEntriesRequestState,
    providers: providers || [],
    getProvidersRequestState,
    filterAnchorEl,
    entryStatus: djjEntryStatusProps,
    youthDjjId: youthDjjIdProps,
    notes: notesProps,
    details: detailProps,
    handleCloseFilter,
    handleOpenFilterClicked,
    handlePageChange,
    handleSort,
    handleSearch,
  };
};
