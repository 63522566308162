import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import FilterList from "../../../app/components/FilterList";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { ProgramTypeDto } from "../../program-types/dtos/ProgramTypeDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { fundingSourceTextMap } from "../../programs/enums/ProgramFundingSourceEnum";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import { CaseSearchParamsDto } from "../dtos/CaseSearchParamsDto";
import {getProviderId} from "../../authentication/state/authenticationActions";
import {fnyfsProviderId} from "../../authentication/constants";

const formatDate = (date: string) => {
  return DateTime.fromJSDate(new Date(`${date}T00:00`)).toFormat("MM-dd-yyyy");
};
interface Props {
  params: CaseSearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  programTypes: ProgramTypeDto[];
  providerLocations: { [key: string]: LocationDto[] };
  providerPrograms: { [key: string]: ProgramDto[] };
  providerStaffMembers: { [key: string]: StaffMemberDto[] };
  onSearch(dto: CaseSearchParamsDto): void;
}

const CaseFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  programTypes,
  providerLocations,
  providerPrograms,
  providerStaffMembers,
  onSearch,
}) => {
  const [programs, setPrograms] = useState<ProgramDto[]>([]);
  const [locations, setLocations] = useState<LocationDto[]>([]);
  const [staffMembers, setStaffMembers] = useState<StaffMemberDto[]>([]);

  const providerId = getProviderId();
  const additionalExclusions = providerId !== fnyfsProviderId ? ['providerId'] : [];

  const handleClearParams = () => {
    return new CaseSearchParamsDto({ previous: params });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "caseNumber":
          return `Case #: ${value}`;
        case "youthFirstName":
          return `First Name: ${value}`;
        case "youthLastName":
          return `Last Name: ${value}`;
        case "intakeDateStart":
          return `Intake Start: ${formatDate(value)}`;
        case "intakeDateEnd":
          return `Intake End: ${formatDate(value)}`;
        case "dischargeDateStart":
          return `Discharge Start: ${formatDate(value)}`;
        case "dischargeDateEnd":
          return `Discharge End: ${formatDate(value)}`;
        case "caseActiveDateStart":
          return `Case Active Start: ${formatDate(value)}`;
        case "caseActiveDateEnd":
          return `Case Active End: ${formatDate(value)}`;
        case "programTypeId":
          return `Program Type: ${
            programTypes.find((x) => x.id === value)?.name
          }`;
        case "programId":
          return `Program: ${programs.find((x) => x.id === value)?.name}`;
        case "providerId":
          return `Provider: ${providers.find((x) => x.id === value)?.name}`;
        case "locationId":
          return `Location: ${locations.find((x) => x.id === value)?.name}`;
        case "primaryStaffId":
          const primaryStaff = staffMembers.find((x) => x.id === value);
          return `Primary Staff: ${primaryStaff?.firstName} ${primaryStaff?.lastName}`;
        case "intakeStaffId":
            var intakeStaff = staffMembers.find((x) => x.id === value);
            return `Intake Staff: ${intakeStaff?.firstName} ${intakeStaff?.lastName}`;          
        case "activeOnly":
          return "Active Only";
        case "includeDeleted":
          return "Include Deleted";
        case "fundingSource":
          return `Funding Source: ${fundingSourceTextMap[value]}`;
      }
      return "";
    },
    [providers, programs, programTypes, locations, staffMembers]
  );

  const ordering = [
    "caseNumber",
    "fundingSource",
    "programTypeId",
    "providerId",
    "programId",
    "locationId",
    "youthFirstName",
    "youthLastName",
    "intakeDateStart",
    "intakeDateEnd",
    "dischargeDateStart",
    "dischargeStateEnd",
    "caseActiveDateStart",
    "caseActiveDateEnd",
    "activeOnly",
    "includeDeleted",
  ];

  useEffect(() => {
    setLocations(Object.values(providerLocations).flatMap((x) => x));
  }, [providerLocations, setLocations]);

  useEffect(() => {
    setPrograms(Object.values(providerPrograms).flatMap((x) => x));
  }, [providerPrograms, setPrograms]);

  useEffect(() => {
    setStaffMembers(Object.values(providerStaffMembers).flatMap((x) => x));
  }, [providerStaffMembers, setStaffMembers]);

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
      exclude={additionalExclusions}
    />
  );
};

export default CaseFilterList;
