import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SnapInSchoolsCycleDto } from "../dtos/SnapInSchoolsCycleDto";
import { SnapInSchoolsFidelityAdherenceChecklistFormDto } from "../dtos/SnapInSchoolsFidelityAdherenceChecklistDto";

export const useSnapInSchoolsFidelityAdherenceChecklistForm = (
  onSubmit: (
    dto: SnapInSchoolsFidelityAdherenceChecklistFormDto
  ) => Promise<void>,
  snapCycle?: SnapInSchoolsCycleDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new SnapInSchoolsFidelityAdherenceChecklistFormDto(
      snapCycle?.fidelityAdherenceChecklist
    ),
    resolver: yupResolver(
      SnapInSchoolsFidelityAdherenceChecklistFormDto.getSchema()
    ),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(
      new SnapInSchoolsFidelityAdherenceChecklistFormDto(
        snapCycle?.fidelityAdherenceChecklist
      )
    );
  };
  const handleOnSubmit = handleSubmit(
    async (dto: SnapInSchoolsFidelityAdherenceChecklistFormDto) => {
      await onSubmit(dto);
    }
  );

  useEffect(() => {
    reset(
      new SnapInSchoolsFidelityAdherenceChecklistFormDto(
        snapCycle?.fidelityAdherenceChecklist
      )
    );
  }, [snapCycle, reset]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
