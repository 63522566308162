import {getSatisfactionSurveysExportRequest} from "../ReportRequests";
import {useRequest} from "../../../app/hooks/useRequest";
import {useState} from "react";
import {SatisfactionSurveyExportParametersDto} from "../dtos/SatisfactionSurveyExportParametersDto";

export const useNirvanaExportReportPage = () => {
  const [downloadExport, , downloadExportRequestState,] = useRequest(getSatisfactionSurveysExportRequest);
  const [params, setParams] = useState<SatisfactionSurveyExportParametersDto>(
    new SatisfactionSurveyExportParametersDto()
  );

  const handleSearch = async (dto: SatisfactionSurveyExportParametersDto) => {
    setParams(dto);

    const dateString = new Date()
      .toLocaleDateString()
      .replaceAll("/", "_");

    const filename = `Satisfaction_Surveys_Export_${dateString}.csv`
    await downloadExport({ dto, filename });
  };

  return {
    state: {
      downloadExportRequestState,
      params
    },
    handlers: {
      handleSearch
    }
  }
}
