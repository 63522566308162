import { IconButton, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { CaseContactDto } from "../dtos/CaseContactDto";
import { getCaseContactTypeDisplayText } from "../enums/CaseContactTypeEnum";
import DateLabel from "../../../app/components/labels/DateLabel";
import EditButton from "../../../app/components/buttons/EditButton";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import { useCaseDetailPageContext } from "../../cases/pages/CaseDetailPage";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import NoteIcon from "@material-ui/icons/Note";

interface Props {
  contact: CaseContactDto;
}

const CaseContactListItem: React.FC<Props> = ({ contact }) => {
  const {
    caseContacts: {
        handleUpdateCaseContactClicked,
        handleDeleteCaseContactClicked,
        handleOpenCaseContactNotesClicked
    },
  } = useCaseDetailPageContext();

  return (
    <TableRow>
      <TableCell>
        <DateLabel date={contact.contactOn} />
      </TableCell>
      <TableCell>{contact.status}</TableCell>
      <TableCell>{getCaseContactTypeDisplayText(contact.type)}</TableCell>
      <TableCell>{contact.contactedPerson}</TableCell>      
      <TableCell>{contact.staffMember.firstName} {contact.staffMember.lastName}</TableCell>
      <TableCell>
        {contact.notes && 
          <IconButton 
            title="Click to view notes"            
            onClick={() => handleOpenCaseContactNotesClicked(contact.id)}
          >
            <NoteIcon />
          </IconButton>
        }
      </TableCell>
      <TableCell>
        <ProviderAccessWrapper>
          <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
            <EditButton onClick={() => handleUpdateCaseContactClicked(contact.id)} />        
            <DeleteButton onClick={() => handleDeleteCaseContactClicked(contact.id)} />
          </WriteAccessLevelWrapper>        
        </ProviderAccessWrapper>
      </TableCell>

    </TableRow>
  );
};

export default CaseContactListItem;
