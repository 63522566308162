import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { trimPhoneNumber } from "../../../app/helpers";
import { youthContactSchema } from "../components/YouthContactFormFields";
import { YouthContactDetailDto } from "../dtos/YouthContactDetailDto";
import { createYouthContactFormDto, YouthContactFormDto } from "../dtos/YouthContactFormDto";
import { useGetYouthContact } from "./youthContactHooks";

export const useYouthContactForm = (onSubmit: (dto: YouthContactFormDto) => Promise<any>, contactId?: string) => {
  //#region State

  const [getYouthContact, , getYouthContactRequestState, clearGetYouthContactError] = useGetYouthContact();
  const [youthContact, setYouthContact] = useState<YouthContactDetailDto>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createYouthContactFormDto(youthContact),
    resolver: yupResolver(youthContactSchema),
    mode: "all",
  });

  //#endregion

  //#region Handlers
  const clearForm = () => {
    clearGetYouthContactError();
    reset(createYouthContactFormDto(youthContact));
  };

  const handleOnSubmit = handleSubmit(async (dto: YouthContactFormDto) => {
    if (dto.relationship === "") dto.relationship = undefined;
    if (dto.email === "") dto.email = undefined;
    dto.phoneNumber = trimPhoneNumber(dto.phoneNumber);
    await onSubmit(dto);
    reset(createYouthContactFormDto(youthContact));
  });

  //#endregion

  //#region Effects
  useEffect(() => {
    const load = async () => {
      if (contactId) {
        const contact = await getYouthContact(contactId);
        setYouthContact(contact);
      } else {
        setYouthContact(undefined);
      }
    };
    load();
  }, [contactId, getYouthContact, setYouthContact]);

  useEffect(() => {
    reset(createYouthContactFormDto(youthContact));
  }, [youthContact, reset]);
  //#endregion

  return {
    state: { getYouthContactRequestState },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
