import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import {useForm, useWatch} from "react-hook-form";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { useGetProgramTypes } from "../../program-types/hooks/programTypeHooks";
import { programSchema } from "../components/ProgramFormFields";
import { ProgramDto } from "../dtos/ProgramDto";
import { createProgramFormDto, ProgramFormDto } from "../dtos/ProgramFormDto";
import { ProgramFundingSourceEnum } from "../enums/ProgramFundingSourceEnum";
import {useRequest} from "../../../app/hooks/useRequest";
import {getContractsRequest} from "../../contracts/ContractRequests";

export const useProgramForm = (
  onSubmit: (dto: ProgramFormDto) => Promise<any>,
  program?: ProgramDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
  } = useForm({
    defaultValues: createProgramFormDto(program),
    resolver: yupResolver(programSchema),
    mode: "all",
  });

  const selectedFundingSource = useWatch({
    control,
    name: "fundingSource",
  });

  const selectedProgramTypeId = useWatch({
    control,
    name: "programTypeId",
  });

  const selectedContractId = useWatch({
    control,
    name: "contractId",
  });

  const [
    getProgramTypes,
    programTypes,
    getProgramTypesRequestState,
    clearProgramTypeError,
  ] = useGetProgramTypes();
  const [isFloridaNetworkUser, setIsFloridaNetworkUser] = useState(false);
  //#endregion

  const [getContracts, contracts, getContractsRequestState, clearContractsError] = useRequest(getContractsRequest);

  const clearForm = () => {
    clearProgramTypeError();
    clearContractsError();
    reset(createProgramFormDto(program));
  };
  const handleOnSubmit = handleSubmit(async (dto: ProgramFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  useEffect(() => {
    if(selectedFundingSource === ProgramFundingSourceEnum.Other) {
      setValue('contractId', undefined);
    }  else {
      if(!selectedProgramTypeId) {
        setValue('contractId', undefined);
      } else {
        if(selectedContractId && contracts && !contracts.find(c => c.id === selectedContractId)?.validProgramTypeIds.some(id => id === selectedProgramTypeId)) {
          setValue('contractId', undefined);
        }
      }
    }
  }, [contracts, selectedContractId, selectedFundingSource, selectedProgramTypeId, setValue]);

  useEffect(() => {
    getProgramTypes("");
  }, [getProgramTypes]);

  useEffect(() => {
    getContracts("");
  }, [getContracts])

  useEffect(() => {
    reset(createProgramFormDto(program));
  }, [program, reset]);

  useEffect(() => {
    const providerId = getProviderId();
    setIsFloridaNetworkUser(providerId === fnyfsProviderId);
    if (providerId !== fnyfsProviderId) {
      setValue("fundingSource", ProgramFundingSourceEnum.Other);
    }
  }, [setIsFloridaNetworkUser, setValue]);

  return {
    state: {
      programTypes: programTypes?.filter(x => x.canCreateProgramFrom) || [],
      contracts: contracts || [],
      getProgramTypesRequestState,
      getContractsRequestState,
      isFloridaNetworkUser,
      selectedFundingSource,
      selectedProgramTypeId,
    },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
