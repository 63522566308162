import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import {
  CaseHoldTypeEnum,
  caseHoldTypeTextMap,
} from "../enums/CaseHoldTypeEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  type: CaseHoldTypeEnum;
}

const CaseHoldTypeLabel: React.FC<Props> = ({ type, ...props }) => {
  return <Typography {...props}>{caseHoldTypeTextMap[type]}</Typography>;
};

export default CaseHoldTypeLabel;
