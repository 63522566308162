import { useRequest } from "../../../app/hooks/useRequest";
import {
  getCurrentReportingPeriodRequest,
  getPreviousReportingPeriodRequest, getReportingPeriodsRequest,
  getReportingPeriodStatusRequest,
} from "../ReportingPeriodRequests";

export const useGetCurrentReportingPeriod = () =>
  useRequest(getCurrentReportingPeriodRequest);

export const useGetPreviousReportingPeriod = () =>
  useRequest(getPreviousReportingPeriodRequest);

export const useGetReportingPeriodStatus = () =>
  useRequest(getReportingPeriodStatusRequest);

export const useGetReportingPeriods = () =>
  useRequest(getReportingPeriodsRequest);
