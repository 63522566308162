import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { InvoiceDto } from "../dtos/InvoiceDto";

interface Props {
  onConfirm: () => void;
  invoice: InvoiceDto;
  requestState: RequestStateDto;
}

export const returnProviderInvoiceConfirmationDialogId =
  "return-provider-invoice-confirmation-dialog";
const ReturnProviderInvoiceConfirmationDialog: React.FC<Props> = ({
  onConfirm,
  invoice,
  requestState,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm Remove"
      onConfirm={onConfirm}
      modalId={returnProviderInvoiceConfirmationDialogId}
      isLoading={isLoading}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to return invoice {invoice?.invoiceNumber}?
        <br />
        <br />{" "}
        <strong>
          {" "}
          This invoice will no longer be valid, and the Provider will have to
          submit a new invoice for this Reporting Period.
        </strong>
      </Typography>
    </ConfirmDialog>
  );
};

export default ReturnProviderInvoiceConfirmationDialog;
