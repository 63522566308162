import { securityAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { UserDto } from "../authentication/dtos/UserDto";
import { UserFormDto } from "./dtos/UserFormDto";
import { UserSearchParamsDto } from "./dtos/UserSearchParamsDto";
import { AddProviderRoleFormDto } from "./dtos/AddProviderRoleFormDto";
import { UserDetailDto } from "./dtos/UserDetailDto";
import { RemoveProviderRoleFormDto } from "./dtos/RemoveProviderRoleFormDto";
import { UpdateUserEmailFormDto } from "./dtos/UpdateUserEmailFormDto";
import { UserLastGeneratedPinDto } from "./dtos/UserLastGeneratedPinDto";
import { SetUserRoleActiveDto } from "./dtos/SetUserRoleActiveDto";

interface RemoveProviderRoleParamsDto {
  id: string;
  dto: RemoveProviderRoleFormDto;
}

interface AddProviderRoleParamsDto {
  id: string;
  dto: AddProviderRoleFormDto;
}

interface SetUserRoleActiveParamsDto {
  id: string;
  dto: SetUserRoleActiveDto;
}

export const createUserRequest = (dto: UserFormDto) =>
  securityAgent.postRequest<UserDto>("/users", dto);

export const getUsersRequest = (params: UserSearchParamsDto) =>
  securityAgent.getRequest<PaginationResultDto<UserDto>>(
    `/users?${UserSearchParamsDto.toQueryString(params)}`
  );

export const getUserRequest = (id: string) =>
  securityAgent.getRequest<UserDetailDto>(`/users/${id}`);

export const updateUserRequest = (id: string, dto: UserFormDto) =>
  securityAgent.putRequest<UserDto>(`/users/${id}`, dto);

export const addProviderRoleRequest = ({ id, dto }: AddProviderRoleParamsDto) =>
  securityAgent.postRequest<any>(`/users/${id}/roles`, dto);

export const removeProviderRoleRequest = ({
  id,
  dto,
}: RemoveProviderRoleParamsDto) =>
  securityAgent.postRequest<any>(`/users/${id}/roles/remove`, dto);

export const updateUserEmailRequest = (
  id: string,
  dto: UpdateUserEmailFormDto
) => securityAgent.postRequest<any>(`/users/${id}/changeemail`, dto);

export const unlockAccountRequest = (id: string) =>
  securityAgent.postRequest(`/users/${id}/unlock`, {});

export const getUsersLastGeneratedPinRequest = (id: string) =>
  securityAgent.getRequest<UserLastGeneratedPinDto>(`/users/${id}/pin`);

export const activateUserRoleRequest = ({
  id,
  dto,
}: SetUserRoleActiveParamsDto) =>
  securityAgent.postRequest(`/users/${id}/roles/activate`, dto);

export const deactivateUserRoleRequest = ({
  id,
  dto,
}: SetUserRoleActiveParamsDto) =>
  securityAgent.postRequest(`/users/${id}/roles/deactivate`, dto);
