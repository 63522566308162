import { Paper, TableContainer } from "@material-ui/core";
import React from "react";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import { ReferralSearchParamsDto } from "../dtos/ReferralSearchParamsDto";
import { ReferralSummaryDto } from "../dtos/ReferralSummaryDto";
import ReferralListItem from "./ReferralListItem";

const ReferralList: React.FC<
  SortableTableProps<ReferralSummaryDto, ReferralSearchParamsDto>
> = (props) => {

  const columns: ColumnProps[] = [
    { name: "referralNumber", label: "Referral #" },
    { name: "youth", label: "Youth" },
    { name: "assignedTo", label: "Assigned To" },
    { name: "assignedBy", label: "Assigned By" },
    { name: "requestedServices", label: "Requested Services" },
    { name: "submittedAt", label: "Submitted Date/Time" },
    { name: "status", label: "Status" },
  ];

  const renderRow = (referralDto: ReferralSummaryDto) => (
    <ReferralListItem key={referralDto.id} referral={referralDto} />
  );

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default ReferralList;
