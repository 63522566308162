import {
  Box,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import EditButton from "../../../../app/components/buttons/EditButton";
import ControlledRadioGroup from "../../../../app/components/inputs/ControlledRadioGroup";
import NameLabel from "../../../../app/components/labels/NameLabel";
import StyledLink from "../../../../app/components/StyledLink";
import TableLoadingPlaceholder from "../../../../app/components/TableLoadingPlaceholder";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { calculateAge } from "../../../../app/helpers";
import { getProviderId } from "../../../authentication/state/authenticationActions";
import YouthForm from "../../../youths/components/YouthForm";
import { YouthFormDto } from "../../../youths/dto/YouthFormDto";
import { YouthSearchParamsDto } from "../../../youths/dto/YouthSearchParamsDto";
import { YouthSummaryDto } from "../../../youths/dto/YouthSummaryDto";
import { ReferralDto } from "../../dtos/ReferralDto";
import ScreeningFromReferralYouthSearchFilter from "./ScreeningFromReferralYouthSearchFilter";

interface Props {
  referral: ReferralDto;
  createYouthRequestState: RequestStateDto;
  getYouthRequestState: RequestStateDto;
  youth: YouthSummaryDto[];
  onYouthSelected(youth: YouthSummaryDto): void;
  onCreateYouth(dto: YouthFormDto): Promise<void>;
  onSearchYouth(dto: YouthSearchParamsDto): void;
}

const ScreeningFromReferralYouthSelect: React.FC<Props> = ({
  referral,
  createYouthRequestState,
  getYouthRequestState,
  youth,
  onYouthSelected,
  onCreateYouth,
  onSearchYouth,
}) => {
  const { control: useExistingControl, watch } = useForm({
    defaultValues: { useExisting: "true" },
  });

  const useExisting = watch().useExisting === "true";

  const createYouthRow = (dto: YouthSummaryDto) => {
    return (
      <TableRow key={dto.id}>
        <TableCell>
          <StyledLink
            to={`/youths/${dto.id}?selectedProviderId=${getProviderId()}`}
            target="_blank"
          >
            <NameLabel nameDto={dto} />
          </StyledLink>
        </TableCell>
        <TableCell>{dto.zipCode}</TableCell>
        <TableCell>{calculateAge(dto.dob)}</TableCell>
        <TableCell>
          <EditButton text="Select" onClick={() => onYouthSelected(dto)} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <Box marginBottom="8px">
        <Typography color="textSecondary">
          Is this screening for an existing youth in the system, or do you want
          to create a new youth record?
        </Typography>
        <RadioGroup>
          <ControlledRadioGroup
            row
            name="useExisting"
            value={false}
            control={useExistingControl}
            options={[
              { value: "true", label: "Existing Youth" },
              { value: "false", label: "New Youth" },
            ]}
          />
        </RadioGroup>
      </Box>
      {!useExisting && (
        <YouthForm
          onSubmit={onCreateYouth}
          isLoading={createYouthRequestState.isLoading}
          referral={referral}
        />
      )}
      {useExisting && (
        <Fragment>
          <Box marginBottom={"14px"}>
            <Typography color="textSecondary">
              Search for and select youth to associate to the screening.
            </Typography>
          </Box>
          <ScreeningFromReferralYouthSearchFilter
            onSearch={onSearchYouth}
            requestState={getYouthRequestState}
            referral={referral}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Zip Code</TableCell>
                <TableCell>Age</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {youth.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>No Youth Found.</TableCell>
                </TableRow>
              )}

              {!getYouthRequestState.isLoading && youth.map(createYouthRow)}

              {getYouthRequestState.isLoading && (
                <TableLoadingPlaceholder rows={10} columns={4} />
              )}
            </TableBody>
          </Table>
        </Fragment>
      )}
    </div>
  );
};

export default ScreeningFromReferralYouthSelect;
