import { Grid, GridProps, GridSize } from "@material-ui/core";
import React, { Fragment } from "react";
import KeyValueRow from "./KeyValueRow";

export interface RowProps {
  key: string;
  label: React.ReactNode;
  value: React.ReactNode;
  hide?: boolean;
}

interface Props extends GridProps {
  rows: RowProps[];
  mdLabelSize?: GridSize;
  lgLabelSize?: GridSize;
  xsLabelSize?: GridSize;
  lgContentSize?: GridSize;
  mdContentSize?: GridSize;
  xsContentSize?: GridSize;
  isLoading?: boolean;
  color?:
    | "inherit"
    | "initial"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error"
    | undefined;
}

const KeyValueDetails: React.FC<Props> = ({
  rows,
  lgLabelSize,
  mdLabelSize,
  xsLabelSize,
  lgContentSize,
  mdContentSize,
  xsContentSize,
  color,
  isLoading,
  ...props
}) => {
  const renderRow = (row: RowProps) => (
    <Fragment key={row.key}>
      <KeyValueRow
        color={color}
        lgLabelSize={lgLabelSize}
        mdLabelSize={mdLabelSize}
        xsLabelSize={xsLabelSize}
        lgContentSize={lgContentSize}
        mdContentSize={mdContentSize}
        xsContentSize={xsContentSize}
        isLoading={isLoading}
      >
        <span>{row.label}</span>
        <span>{row.value}</span>
      </KeyValueRow>
    </Fragment>
  );

  return (
    <Grid component="span" container {...props}>
      {rows.filter((x) => x.hide !== true).map(renderRow)}
    </Grid>
  );
};

export default KeyValueDetails;
