import { Typography } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import {
  CaseDeleteFormDto,
  createCaseDeleteFormDto,
} from "../dtos/CaseDeleteFormDto";
import { CaseDetailDto } from "../dtos/CaseDetailDto";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTogglable } from "../../../app/hooks/useTogglable";
import InvoiceCorrectionConfirmationCheckbox from "../../../app/components/InvoiceCorrectionConfirmationCheckbox";

const schema = Yup.object().shape({
  reason: Yup.string().label("Reason").required().max(200),
});
interface Props {
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
  onConfirm(dto: CaseDeleteFormDto): Promise<void>;
}

export const confirmDeleteCaseDialogId = "delete-case-dialog";
const ConfirmDeleteCaseDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  caseDto,
}) => {
  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm({
    defaultValues: createCaseDeleteFormDto(),
    mode: "all",
    resolver: yupResolver(schema),
  });

  const [isConfirmed, , handleToggleIsConfirmed] = useTogglable();
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={() => {
        onConfirm(getValues());
      }}
      modalId={confirmDeleteCaseDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Case"
      confirmButtonStyle={{
        color:
          !isConfirmed || !isValid ? ColorsEnum.DisabledRed : ColorsEnum.Red,
      }}
      confirmButtonDisabled={!isConfirmed || !isValid}
      maxWidth="md"
    >
      <ErrorText error={error?.message} />
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to delete Case {caseDto?.caseNumber}? <br /> Enter
        the reason for deleting the case below.
      </Typography>
      <ControlledInput
        control={control}
        name="reason"
        label="Reason"
        type="text-area"
        minRows={6}
        multiline
        fullWidth
      />
      <Typography>
        <strong>This action may result in invoice corrections.</strong>
      </Typography>
      <InvoiceCorrectionConfirmationCheckbox
        isConfirmed={isConfirmed}
        onCheck={handleToggleIsConfirmed}
      />
    </ConfirmDialog>
  );
};

export default ConfirmDeleteCaseDialog;
