import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { monthOptions } from "../../../app/enums/MonthEnum";
import { MonthlyReportCardByProviderSearchParamsDto } from "../dtos/MonthlyReportCardByProviderSearchParamsDto";
import {
  MonthlyReportCardDateRangeType,
  monthlyReportCardDateRangeTypeOptions,
} from "../dtos/MonthlyReportCardSearchParamsDto";
import {useGetFiscalYears} from "../../provider-fiscal-year/hooks/providerFiscalYearHooks";

interface Props {
  invoiceYears: number[];
  getInvoiceYearsRequestState: RequestStateDto;
  requestState: RequestStateDto;
  onSubmit(params: MonthlyReportCardByProviderSearchParamsDto): Promise<void>;
}

const MonthlyReportCardByProviderFilter: React.FC<Props> = ({
  invoiceYears,
  getInvoiceYearsRequestState,
  requestState,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
  } = useForm({
    defaultValues: new MonthlyReportCardByProviderSearchParamsDto(),
    resolver: yupResolver(
      MonthlyReportCardByProviderSearchParamsDto.getSchema()
    ),
    mode: "all",
  });

  const [availableMonthOptions, setAvailableMonthOptions] =
    useState<SelectOption[]>(monthOptions);

  const { dateRangeType, year } = watch();

  const [getFiscalYears, fiscalYears, getFiscalYearsRequestState] = useGetFiscalYears();

  const toFiscalYearRange = (year: number) => {
    return `${year}-${year + 1}`;
  }

  useEffect(() => {
    getFiscalYears(null);
  }, [getFiscalYears])

  const getCurrentFiscalYear = () => {
    const now = new Date();
  
    return now.getMonth() >= 6 ? now.getFullYear() : now.getFullYear() - 1;
  };

  useEffect(() => {
    if (dateRangeType !== MonthlyReportCardDateRangeType.MonthYear) return;

    const now = DateTime.now();
    if (now.year !== +year) {
      setAvailableMonthOptions(monthOptions);
    } else {
      setAvailableMonthOptions(
        monthOptions.filter((x) => x.value === "" || +x.value < now.month - 1)
      );
    }
  }, [year, dateRangeType, setAvailableMonthOptions]);

  useEffect(() => {
    if (
      dateRangeType === MonthlyReportCardDateRangeType.FiscalYearToDate &&
      invoiceYears.length > 0
    ) {
      setValue("year", `${getCurrentFiscalYear()}`);
    }
  }, [dateRangeType, setValue, invoiceYears]);

  const getButtonGridWidth = () => {

      switch(dateRangeType) {
        case MonthlyReportCardDateRangeType.MonthYear:
          return 4;
        case MonthlyReportCardDateRangeType.FiscalYearToDate:
          return 8;
        case MonthlyReportCardDateRangeType.PreviousFiscalYear:
          return 6;
          default:
            return 8;
      }

  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item md={4} sm={6}>
          <ControlledSelect
            control={control}
            name="dateRangeType"
            label="Date Type"
            fullWidth
            options={monthlyReportCardDateRangeTypeOptions}
            disabled={requestState.isLoading}
            size="small"
          />
        </Grid>

        {dateRangeType === MonthlyReportCardDateRangeType.MonthYear && (
          <Fragment>
            <Grid item md={2} sm={3}>
              <ControlledSelect
                control={control}
                name="year"
                fullWidth
                label="Year"
                isLoading={getInvoiceYearsRequestState.isLoading}
                disabled={requestState.isLoading}
                options={invoiceYears.map((x) => ({ value: x, label: `${x}` }))}
                size="small"
              />
            </Grid>
            <Grid item md={2} sm={3}>
              <ControlledSelect
                control={control}
                name="month"
                fullWidth
                label="Month"
                options={availableMonthOptions}
                disabled={requestState.isLoading}
                size="small"
              />
            </Grid>
          </Fragment>
        )}

        {dateRangeType === MonthlyReportCardDateRangeType.PreviousFiscalYear && (
          <Fragment>
            <Grid item md={2} sm={3}>
              <ControlledSelect
                control={control}
                name="year"
                fullWidth
                label="Fiscal Year"
                isLoading={getFiscalYearsRequestState.isLoading}
                disabled={requestState.isLoading}
                options={fiscalYears?.filter(x => x !== getCurrentFiscalYear()).map((x) => ({ value: x, label: toFiscalYearRange(x) })) || []}
                size="small"
              />
            </Grid>
          </Fragment>
        )}

        <Grid
          item
          md={
            getButtonGridWidth()
          }
          sm={12}
        >
          <Box display="flex" alignItems="center" justifyContent="end">
            <div>
              <SpinnerButton
                showSpinner={false}
                disabled={!isValid || requestState.isLoading}
                content="Download"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default MonthlyReportCardByProviderFilter;
