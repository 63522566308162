import {TransferableRecordTypes} from "./TransferableRecordTypes";
import {coreAgent} from "../../../../app/api/agent";

export const transferYouthRecordRequest = ({
                                      id,
                                      youthId,
                                      recordType
                                    }: {
  id: string;
  youthId: string;
  recordType: TransferableRecordTypes
}) => {
  const requestRoot = recordType === "Case" ? 'cases': 'screenings';
  return coreAgent.postRequest(`${requestRoot}/${id}/transfer`, { youthId })
}
