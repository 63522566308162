import { Grid } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  getReferralSourceOptions,
  ReferralSourceEnum,
} from "../enums/ReferralSourceEnum";
import * as Yup from "yup";
import ControlledPhoneNumberInput from "../../../app/components/inputs/ControlledPhoneNumberInput";
import ControlledYesNoRadioGroup from "../../../app/components/inputs/ControlledYesNoRadioGroup";
import { getReferrerRelationshipOptions } from "../enums/ReferrerRelationshipEnum";
import { trimPhoneNumber } from "../../../app/helpers";

export const referrerSchema = Yup.object().shape({
  firstName: Yup.string().label("First Name").required().max(100),
  lastName: Yup.string().label("Last Name").required().max(100),
  phoneNumber: Yup.string()
    .label("Phone Number")
    .required()
    .test("length", "Phone Number must be 10 digits long", function (value) {
      if (value == null || value === "") return false;
      return trimPhoneNumber(value)?.length === 10;
    }),
  source: Yup.string()
    .test(
      "text-agency-affiliation-required",
      "Referral source is required.",
      function (value) {
        return (
          this.parent.isReferralSelf === "true" ||
          this.parent.isReferralFamily === "true" ||
          value !== ""
        )})
    .test(
      "length",
      "50 characters",
      function(value) {
        return value !== undefined && value.length <= 50
      }
    ),
  relationship: Yup.string().label("Relationship to Youth").required(),
});

export const agencySchema = Yup.object().shape({
  firstName: Yup.string().label("First Name").required().max(100),
  lastName: Yup.string().label("Last Name").required().max(100),
  phoneNumber: Yup.string()
    .label("Phone Number")
    .required()
    .test("length", "Phone Number must be 10 digits long", function (value) {
      if (value == null || value === "") return false;
      return trimPhoneNumber(value)?.length === 10;
    }),
  source: Yup.string()
    .test(
      "text-agency-affiliation-required",
      "Agency/Community Affiliation is required.",
      function (value) {
        return (
          this.parent.isReferralSelf === "true" ||
          this.parent.isReferralFamily === "true" ||
          value !== ""
        )})
    .test(
      "length",
      "50 characters",
      function(value) {
        return value !== undefined && value.length <= 50
      }
    ),
  relationship: Yup.string().label("Relationship to Youth").required(),
});

interface Props {
  control: Control<any>;
  isDisabled: boolean;
  prefix: string;
  isReferral?: boolean;
  showName?: boolean;
}

const ReferrerFormFields: React.FC<Props> = ({
  control,
  isDisabled,
  prefix,
  isReferral = true,
  showName = true,
}) => {

  const { 
    isReferralFamily, 
    isReferralSelf, 
    referredBy 
  } = useWatch({ control });

  const lablePrefix = isReferral ? "Your" : "Referrer";

  const [ origReferralSource ] = useState(referredBy.source);

  return (
    <Fragment>
      {isReferral && (
        <Grid item md={12} xs={12}>
          <ControlledYesNoRadioGroup
            control={control}
            name="isReferralSelf"
            disabled={isDisabled}
            label="Are you requesting services for yourself?*"
          />
        </Grid>
      )}
      {isReferralSelf === "false" && isReferral && (
        <Grid item md={12} xs={12}>
          <ControlledYesNoRadioGroup
            control={control}
            name="isReferralFamily"
            disabled={isDisabled}
            label="Are you requesting services for a family member?*"
          />
        </Grid>
      )}
      {((isReferralSelf === "false" && isReferralFamily === "false") ||
        isReferral === false) && (
        <Grid item md={12} xs={12}>
          <Controller
            control={control}
            name={`${prefix}.source`}
            render={({ field, fieldState }) => {
              return (
                <ControlledSelect
                  control={control}
                  name={`${prefix}.source`}
                  label={
                    isReferral
                      ? "What is your agency/community affiliation?*"
                      : "Referral Source*"
                  }
                  options={ getReferralSourceOptions(isReferral, origReferralSource) }
                  fullWidth
                />

              );
            }}
          />
        </Grid>
      )}
      {showName && (
        <Fragment>
          <Grid item md={6} xs={12}>
            <ControlledInput
              name={`${prefix}.firstName`}
              control={control}
              label={`${lablePrefix} First Name*`}
              fullWidth
              disabled={isDisabled}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledInput
              name={`${prefix}.lastName`}
              control={control}
              label={`${lablePrefix} Last Name*`}
              fullWidth
              disabled={isDisabled}
            />
          </Grid>
        </Fragment>
      )}
      <Grid item md={12} xs={12}>
        <ControlledPhoneNumberInput
          name={`${prefix}.phoneNumber`}
          control={control}
          label={`${lablePrefix} Phone Number*`}
          fullWidth
          disabled={isDisabled}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name={`${prefix}.relationship`}
          options={getReferrerRelationshipOptions()}
          label="Relationship to Youth*"
          fullWidth
          disabled={
            isDisabled ||
            referredBy?.source === ReferralSourceEnum.Self ||
            isReferralSelf === "true"
          }
        />
      </Grid>
    </Fragment>
  );
};

export default ReferrerFormFields;
