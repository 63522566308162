import { coreAgent } from "../../app/api/agent";
import { CreateNewFollowUpDto } from "./dtos/CreateNewFollowUpRequestDto";
import {
  CaseFollowUpSummaryDto,
  CreateFollowUpAttemptFailureDto,
  FollowUpCompletionData,
} from "./dtos/FollowUpDto";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { DeleteFollowUpFormDto } from "./dtos/DeleteFollowUpFormDto";
import {FollowUpSearchParamsDto, PrintMultipleFollowUpsParamsDto} from "./dtos/FollowUpSearchParamsDto";
import { QueryStringHelpers } from "../../app/helpers";

export const createNewFollowUpRequest = (dto: CreateNewFollowUpDto) =>
  coreAgent.postRequest(`followUp`, dto);

export const addFailedFollowUpDataRequest = (data: {
  id: string;
  dto: CreateFollowUpAttemptFailureDto;
}) => coreAgent.postRequest(`followUp/${data.id}/failure`, data.dto);

export const updateFailedFollowUpDataRequest = (data: {
  id: string;
  failureId: string;
  dto: CreateFollowUpAttemptFailureDto;
}) => coreAgent.putRequest(`followUp/${data.id}/failure/${data.failureId}`, data.dto);

export const addFollowUpCompletionDataRequest = (data: {
  id: string;
  dto: FollowUpCompletionData;
}) => coreAgent.postRequest(`followUp/${data.id}/completion`, data.dto);

export const updateFollowUpCompletionDataRequest = (data: {
  id: string;
  dto: FollowUpCompletionData;
}) => coreAgent.putRequest(`followUp/${data.id}/completion`, data.dto);

export const getFollowUpSummaryList = (dto: FollowUpSearchParamsDto) =>
  coreAgent.getRequest<PaginationResultDto<CaseFollowUpSummaryDto>>(
    `followup?${QueryStringHelpers.toQueryString(dto)}`
  );

export const getFollowUpExport = (dto: FollowUpSearchParamsDto) =>
  coreAgent.downloadFileRequest(`followup/export?${QueryStringHelpers.toQueryString(dto)}`, 'follow-ups-export.csv')
export const deleteFollowUpRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: DeleteFollowUpFormDto;
}) => coreAgent.postRequest(`followup/${id}/delete`, dto);

export const getMultipleFollowUpReportsDocumentRequest = (
  dto: PrintMultipleFollowUpsParamsDto
) =>
  coreAgent.getRequest<Blob>(
    `/reports/followups?${QueryStringHelpers.toQueryString(dto)}`,
    {
      responseType: "blob",
    },
    false
  );
