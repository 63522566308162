import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { CaseSessionDto } from "../dtos/CaseSessionDto";
import { CaseSessionFormDto } from "../dtos/CaseSessionFormDto";
import { useCaseSessionForm } from "../hooks/useCaseSessionForm";
import CaseSessionFormFields from "./CaseSessionFormFields";
import * as Yup from "yup";

export const caseSessionsFormDialogId = "case-session-form-modal";

export const caseSessionSchema = Yup.object().shape({
  sessionHeldOn: Yup.string().label("Session Held On").required(),
  sessionType: Yup.string().label("Session Type").required(),
});

interface Props {
  requestState: RequestStateDto;
  onSubmit: (dto: CaseSessionFormDto) => Promise<any>;
  caseSession?: CaseSessionDto;
}

const CaseSessionsFormDialog: React.FC<Props> = ({
  requestState,
  onSubmit,
  caseSession,
}) => {
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useCaseSessionForm(onSubmit);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;

  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };

  return (
    <Dialog open={modalId === caseSessionsFormDialogId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{caseSession ? "Edit" : "Add a"} Session</DialogTitle>
        <ErrorText error={error?.message} />
        <DialogContent>
          <Grid container spacing={2}>
            <CaseSessionFormFields
              control={control}
              disabled={requestState.isLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CaseSessionsFormDialog;
