import React from "react";
import { createRow } from "./ContentSection";
import DateLabel from "../../app/components/labels/DateLabel";
import { useServicePlanPageContext } from "../ServicePlanPageContext";
import DateTimeLabel from "../../app/components/labels/DateTimeLabel";
import { Box, Paper, Typography } from "@material-ui/core";

export const ServicePlanCommonHeader = () => {
  const {
    servicePlan: { startedOn, updatedAt, notes },
  } = useServicePlanPageContext();

  return (
    <>
      {createRow("Plan Start Date", <DateLabel date={startedOn} />)}
      {createRow("Last Updated", <DateTimeLabel date={updatedAt} />)}
      {createRow(
        "Notes",
        <Paper variant="outlined">
          <Box height="125px" padding="6px" overflow="auto">
            <Typography>{notes}</Typography>
          </Box>
        </Paper>
      )}
    </>
  );
};
