import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { YouthContactFormDto } from "../dtos/YouthContactFormDto";
import { useYouthContactForm } from "../hooks/useYouthContactForm";
import YouthContactFormFields from "./YouthContactFormFields";

export const youthContactModalId = "youth-contact-form-modal";

interface Props {
  onSubmit: (dto: YouthContactFormDto) => Promise<any>;
  requestState: RequestStateDto;
  contactId?: string;
  isLegacy: boolean;
}

const YouthContactFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  contactId,
  isLegacy
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { state, form, handlers } = useYouthContactForm(onSubmit, contactId);
  const { getYouthContactRequestState } = state;
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  const { error: getYouthContactError, isLoading: getYouthContactIsLoading } =
    getYouthContactRequestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === youthContactModalId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {contactId ? "Edit" : "Create"} Youth Contact
          <ErrorText error={error?.message} />
          <ErrorText error={getYouthContactError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <YouthContactFormFields
              control={control}
              isDisabled={isLoading || getYouthContactIsLoading}
              isLegacy={isLegacy}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading || getYouthContactIsLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading || getYouthContactIsLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default YouthContactFormDialog;
