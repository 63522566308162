import React, { Fragment } from "react";
import Header from "../../../app/components/Header";
import FilterButton from "../../../app/components/buttons/FilterButton";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import { useOutreachActivityListPage } from "../hooks/useOutreachActivityListPage";
import OutreachActivityFilter from "../components/OutreachActivityFilter";
import OutreachActivityFilterList from "../components/OutreachActivityFilterList";
import OutreachActivityList from "../components/OutreachActivityList";
import AddButton from "../../../app/components/buttons/AddButton";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import OutreachActivityFormDialog from "../components/OutreachActivityFormDialog";
import WhiteButton from "../../../app/components/buttons/WhiteButton";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";

const OutreachActivityListPage = () => {
  //#region State
  const { state, handlers } = useOutreachActivityListPage();
  const {
    params,
    result,
    getOutreachActivityRequestState,
    providers,
    filterAnchorEl,
    createOutreachActivityRequestState,
    allowExport,
    exportRecordsLimit,
    exportOutreachActivityListRequestState
  } = state;
  const {
    handlePageChange,
    handleSort,
    handleOpenFilterClick,
    handleCloseFilter,
    handleSearch,
    handleCreateOutreachActivityClicked,
    handleCreateOutreachActivity,
    handleExportListClicked
  } = handlers;
  //#endregion

  return (
    <Fragment>
      <OutreachActivityFilter
        anchorEl={filterAnchorEl}
        params={params}
        providers={providers}
        onClose={handleCloseFilter}
        onSearch={handleSearch}
      />
      <OutreachActivityFormDialog
        onSubmit={handleCreateOutreachActivity}
        requestState={createOutreachActivityRequestState}
      />
      <RequestErrorAlert requests={[getOutreachActivityRequestState]} />
      <Header
        title="Outreach Activity"
        actions={
          <>
            <SpinnerButton
              content={<WhiteButton onClick={() => handleExportListClicked(params)} disabled={!allowExport}>Export</WhiteButton>}
              showSpinner={exportOutreachActivityListRequestState.isLoading}
              title={allowExport ? 'Export List to CSV' : `The result set is too large to export. Please apply filters to reduce the number of records below ${exportRecordsLimit}.`}
              disableRipple={!allowExport}
              style={{ backgroundColor: 'transparent' }}
            />
            <FilterButton
              title="Filter List"
              onClick={handleOpenFilterClick}
              disabled={getOutreachActivityRequestState.isLoading}
            />

            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.OutreachActivities}
            >
              <ProviderAccessWrapper>
                <AddButton
                  text="Create Outreach Activity"
                  variant="contained"
                  color="primary"
                  className="ml-2"
                  onClick={handleCreateOutreachActivityClicked}
                />
              </ProviderAccessWrapper>
            </WriteAccessLevelWrapper>
          </>
        }
        secondaryActions={
          <OutreachActivityFilterList
            params={params}
            onSearch={handleSearch}
            isLoading={getOutreachActivityRequestState.isLoading}
            providers={providers}
          />
        }
      />
      <OutreachActivityList
        results={result}
        isLoading={getOutreachActivityRequestState.isLoading}
        params={params}
        onPageChange={handlePageChange}
        onSort={handleSort}
      />
    </Fragment>
  );
};

export default OutreachActivityListPage;
