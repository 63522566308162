import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum SuicideScreeningResultEnum {
  NotOnCarePathway = "NotOnCarePathway",
  LowRisk = "LowRisk",
  ModerateRisk = "ModerateRisk",
  HighRisk = "HighRisk",
}

export const suicideScreeningResultTextMap: { [key: string]: string } = {
  [SuicideScreeningResultEnum.NotOnCarePathway]: "Not on Care Pathway",
  [SuicideScreeningResultEnum.LowRisk]: "Low Risk",
  [SuicideScreeningResultEnum.ModerateRisk]: "Moderate Risk",
  [SuicideScreeningResultEnum.HighRisk]: "High Risk",
};

export const suicideScreeningResultOptions: SelectOption[] = [
  { value: "", label: "Select Screening Result" },
  ...Object.keys(SuicideScreeningResultEnum).map((x) => ({
    value: x,
    label: suicideScreeningResultTextMap[x],
  })),
];
