import {
  SelectOption,
  textMapToSelectOption,
} from "../../app/components/inputs/ControlledSelect";
import { dateToFormString } from "../../app/helpers";

export enum HumanTraffickingScreeningToolResultEnum {
  DefinitelyNot = "DefinitelyNot",
  LikelyNot = "LikelyNot",
  NotSure = "NotSure",
  LikelyIs = "LikelyIs",
  DefinitelyIs = "DefinitelyIs",
}

export const HumanTraffickingScreeningToolResultTextMap: {
  [key: string]: string;
} = {
  [HumanTraffickingScreeningToolResultEnum.DefinitelyNot]: "Definitely Not",
  [HumanTraffickingScreeningToolResultEnum.LikelyNot]: "Likely Not",
  [HumanTraffickingScreeningToolResultEnum.NotSure]: "Not Sure",
  [HumanTraffickingScreeningToolResultEnum.LikelyIs]: "Likely Is",
  [HumanTraffickingScreeningToolResultEnum.DefinitelyIs]: "Definitely Is",
};

export const HumanTraffickingScreeningToolResultOptions: SelectOption[] =
  textMapToSelectOption(
    HumanTraffickingScreeningToolResultEnum,
    HumanTraffickingScreeningToolResultTextMap,
    { value: "", label: "Select Result" }
  );

export interface HumanTraffickingScreeningToolDto {
  id: string;
  administeredOn: Date;
  result: HumanTraffickingScreeningToolResultEnum;
  caseId: string;
}

export class HumanTraffickingScreeningToolFormDto {
  administeredOn: string;
  result: string;

  constructor(dto?: HumanTraffickingScreeningToolDto) {
    if (dto) {
      this.administeredOn = dateToFormString(new Date(dto.administeredOn));
      this.result = dto.result;
    } else {
      this.administeredOn = '';
      this.result = "";
    }
  }
}
