import React, { Fragment } from "react";
import { useEffect } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import NotificationTypeEnum from "../../notifications/enums/NotificationTypeEnum";
import {
  addDangerNotification,
  addSuccessNotification,
} from "../../notifications/state/notificationSlice";
import { logout } from "../state/authenticationActions";

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const handleLogout = () => {
      batch(async () => {
        const message = (query.message as string) || "Logout Successful";
        const level = +(query.level as string) || NotificationTypeEnum.Success;

        switch (level) {
          case NotificationTypeEnum.Success:
            dispatch(addSuccessNotification({ message }));
            break;
          case NotificationTypeEnum.Danger:
            dispatch(addDangerNotification({ message }));
            break;
        }

        await dispatch(logout()).unwrap();
        history.push(`/auth/login?message=${query.loginPageMessage || ""}`);
      });
    };
    handleLogout();
  }, [dispatch, history, query.message, query.level, query.loginPageMessage]);

  return <Fragment></Fragment>;
};

export default LogoutPage;
