import React from "react";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export interface NetmisRoleDto {
  id: string;
  name: string;
  isFloridaNetworkRole: boolean;
}

export const netmisRoleToOptionDto = (role: NetmisRoleDto): SelectOption => ({
  label: role.name,
  value: role.id,
});
export const netmisRolesToOptionDtos = (
  roles: NetmisRoleDto[],
  defaultValueLabel: React.ReactNode = "Select Role"
): SelectOption[] => [
  { value: "", label: defaultValueLabel },
  ...roles.map(netmisRoleToOptionDto),
];
