import { createContext, useContext } from "react";
import { DashboardFiltersDto } from "./dtos/MainDashboardMetricsDto";
import { RequestStateDto } from "../../app/dtos/RequestStateDto";
import { ProviderDetailDto } from "../provider/dtos/ProviderDetailDto";

export interface DashboardContextProps {
  providerId: string;
  filters: DashboardFiltersDto;
  availableDashboards: { id: string; title: string }[];
  availableDashboardsRequestState: RequestStateDto;

  provider?: ProviderDetailDto;

  onChangeFilterClicked(event: React.MouseEvent<HTMLElement>): void;
}

export const DashboardPageContext = createContext<DashboardContextProps>(
  undefined!
);

export const useDashboardContext = () => useContext(DashboardPageContext);
