import {Box, IconButton, Menu, MenuItem, Typography} from "@material-ui/core";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {calculateWasCaseDischargedDuringHold, CaseDetailDto,} from "../dtos/CaseDetailDto";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import {PermissionResourceNameEnum} from "../../authentication/enums/PermissionResourceNameEnum";
import WarningIcon from "../../../app/components/icons/WarningIcon";
import {ProgramFundingSourceEnum} from "../../programs/enums/ProgramFundingSourceEnum";
import InfoTooltip from "../../../app/components/InfoTooltip";
import {useCaseDetailPageContext} from "../pages/CaseDetailPage";
import {useAnchor} from "../../../app/hooks/useAnchor";
import {useAppSelector} from "../../../app/hooks";
import {selectHasAccess, selectHasExecuteAccess} from "../../authentication/state/authenticationSelectors";
import {AccessLevelEnum} from "../../authentication/enums/AccessLevelEnum";

const buildStartNewCaseAction = (
  onStartNewCaseClick: () => void,
  caseDto?: CaseDetailDto
) => {
  if (caseDto === undefined || !calculateWasCaseDischargedDuringHold(caseDto))
    return undefined;

  return (
    <MenuItem onClick={onStartNewCaseClick} key="start-case-menu-item">
      <Box display="flex" alignContent="center" justifyContent="center">
        Start New Case After Hold
        <Box marginLeft="8px">
          <InfoTooltip
            title={
              <Typography>
                This option will allow you to copy all information from this
                Case to a new Case. You may edit the copied information as
                needed.
              </Typography>
            }
          />
        </Box>
      </Box>
    </MenuItem>
  );
};

const buildStartCaseHoldAction = (
  onStartCaseHoldClick: () => void,
  caseDto?: CaseDetailDto
) => {
  if (
    caseDto === undefined ||
    caseDto?.dischargeInfo !== undefined ||
    caseDto?.caseHolds?.filter((x) => x.endAt === undefined)?.length === 1 ||
    caseDto.program.fundingSource !== ProgramFundingSourceEnum.FloridaNetwork ||
    !caseDto.program.programType.isResidential
  )
    return undefined;

  return (
    <MenuItem onClick={onStartCaseHoldClick} key="case-hold-menu-item">
      Start Case Hold
    </MenuItem>
  );
};

const buildDischargeCaseAction = (
  onDischargeClick: () => void,
  canDischarge: boolean,
  caseDto?: CaseDetailDto
) => {
  if (caseDto?.dischargeInfo !== undefined) return undefined;

  return (
    <MenuItem
      onClick={onDischargeClick}
      key="discharge-menu-item"
      disabled={!canDischarge}
    >
      {!canDischarge && <WarningIcon style={{ marginRight: "4px" }} />}
      Discharge Case
    </MenuItem>
  );
};

const buildClearDischargeAction = (onClearDischargeClick: () => void, caseDto?: CaseDetailDto) => {
  if (caseDto === undefined || caseDto?.deletedAt !== undefined || caseDto.dischargeInfo == null)
    return undefined;

  return (
    <MenuItem onClick={onClearDischargeClick} key="clear-discharge-menu-item">
      Undo Discharge
    </MenuItem>
  );
}

const buildDeleteCaseAction = (
  onDeleteCaseClick: () => void,
  caseDto?: CaseDetailDto
) => {
  if (caseDto === undefined || caseDto?.deletedAt !== undefined)
    return undefined;



  return (
    <MenuItem onClick={onDeleteCaseClick} key="delete-case-menu-item">
      Delete Case
    </MenuItem>
  );
};

const buildTransferCaseAction = (
  onTransferCaseClick: () => void,
  caseDto?: CaseDetailDto
) => {
  if (caseDto === undefined || caseDto?.deletedAt !== undefined)
    return undefined;

  return (
    <MenuItem onClick={onTransferCaseClick}  key="transfer-case-menu-item">
      Transfer Case
    </MenuItem>
  );
};

const buildChangeProgramCaseAction = (
  onChangeProgramClick: () => void,
  caseDto?: CaseDetailDto
) => {
  if(caseDto === undefined || caseDto?.deletedAt !== undefined)
    return undefined;

  return(
    <MenuItem onClick={onChangeProgramClick}  key="change-case-program-menu-item">
      Change Program
    </MenuItem>
  );
}

const CaseDetailAdditionalActions = () => {
  //#region State

  const [menuItems, setMenuItems] = useState<React.ReactNode[]>([]);
  const [anchorEl, handleClick, handleClose] = useAnchor();
  const {
    caseHold,
    discharge,
    canDischarge,
    case: { caseResult, handleStartNewCaseClicked, handleDeleteCaseClicked, handleTransferCaseClicked, programLocation: { handleChangeProgramLocationClicked } },
  } = useCaseDetailPageContext();
  const { handleCreateCaseHoldClicked } = caseHold;
  const { handleUpsertCaseDischargeClicked: handleDischargeCaseClicked, handleClearDischargeClicked } =
    discharge;
  //#endregion

  const handleAction = useCallback(
    (action: () => void) => () => {
      action();
      handleClose();
    },
    [handleClose]
  );

  const hasCaseDeleteAccessLevel = useAppSelector(selectHasAccess(PermissionResourceNameEnum.Case, AccessLevelEnum.Delete));
  const canTransferCase = useAppSelector(selectHasExecuteAccess(PermissionResourceNameEnum.TransferCase));
  const hasChangeCaseDeliverablesPermission = useAppSelector(selectHasExecuteAccess(PermissionResourceNameEnum.ChangeCaseDeliverables));
  const hasClearDischargeAccessLevel = useAppSelector(selectHasAccess(PermissionResourceNameEnum.Case, AccessLevelEnum.Delete));

  useEffect(() => {
    const menuItems: React.ReactNode[] = [];

    const dischargeItem = buildDischargeCaseAction(
      handleAction(handleDischargeCaseClicked),
      canDischarge,
      caseResult
    );
    if (dischargeItem) menuItems.push(dischargeItem);

    const clearDischargeItem = hasClearDischargeAccessLevel ? buildClearDischargeAction(handleAction(handleClearDischargeClicked), caseResult) : undefined;
    if(clearDischargeItem) menuItems.push(clearDischargeItem);

    const caseHoldItem = buildStartCaseHoldAction(
      handleAction(handleCreateCaseHoldClicked),
      caseResult
    );

    if (caseHoldItem) menuItems.push(caseHoldItem);

    const startNewCaseItem = buildStartNewCaseAction(
      handleAction(handleStartNewCaseClicked),
      caseResult
    );

    if (startNewCaseItem) menuItems.push(startNewCaseItem);

    if(canTransferCase) {
      const transferCaseItem = buildTransferCaseAction(
        handleAction(handleTransferCaseClicked),
        caseResult
      );

      if (transferCaseItem) menuItems.push(transferCaseItem);
    }

    const deleteCaseItem = hasCaseDeleteAccessLevel ? buildDeleteCaseAction(
      handleAction(handleDeleteCaseClicked),
      caseResult
    ): undefined;

    if (deleteCaseItem) menuItems.push(deleteCaseItem);

    const changeProgramItem = hasChangeCaseDeliverablesPermission ? buildChangeProgramCaseAction(
      handleAction(handleChangeProgramLocationClicked),
      caseResult
    ): undefined;

    if(changeProgramItem) menuItems.push(changeProgramItem);

    setMenuItems(menuItems);
  }, [
    caseResult,
    canDischarge,
    canTransferCase,
    setMenuItems,
    handleAction,
    handleDischargeCaseClicked,
    handleCreateCaseHoldClicked,
    handleStartNewCaseClicked,
    handleDeleteCaseClicked,
    hasCaseDeleteAccessLevel,
    hasChangeCaseDeliverablesPermission,
    handleTransferCaseClicked,
    handleChangeProgramLocationClicked,
    hasClearDischargeAccessLevel,
    handleClearDischargeClicked
  ]);

  if (menuItems.length === 0) return <Fragment></Fragment>;

  return (
    <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </WriteAccessLevelWrapper>
  );
};

export default CaseDetailAdditionalActions;
