import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import {
  DeliverableTypeEnum,
  deliverableTypeTextMap,
} from "../enums/DeliverableTypeEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  type: DeliverableTypeEnum;
}

const DeliverableTypeLabel: React.FC<Props> = ({ type, ...props }) => {
  return <Typography {...props}>{deliverableTypeTextMap[type]}</Typography>;
};

export default DeliverableTypeLabel;
