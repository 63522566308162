import React from "react";
import {YouthDto} from "../dto/YouthDto";
import {Typography} from "@material-ui/core";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import {ColorsEnum} from "../../../app/enums/ColorsEnum";
import {RequestStateDto} from "../../../app/dtos/RequestStateDto";
import ErrorText from "../../../app/components/ErrorText";

export const deleteYouthModalId = 'delete-youth-modal';

interface Props {
  youth: YouthDto,
  requestState: RequestStateDto,
  onDelete: () => Promise<void>;
}

export const ConfirmDeleteYouthDialog: React.FC<Props> = ({ youth, requestState, onDelete }) => {

  const { error } = requestState;
  const isError = error != null;

  const title = isError ? `Youth ${youth.referenceNumber} could not be deleted` : "Confirm Delete Youth"

  return <ConfirmDialog
    title={title}
    modalId={deleteYouthModalId}
    onConfirm={onDelete}
    isLoading={requestState.isLoading}
    hideConfirmButton={isError}
    confirmButtonText="Delete Youth"
    confirmButtonStyle={{
      color: ColorsEnum.Red,
    }}
    maxWidth="md"
  >
    <ErrorText error={requestState.error?.message} />
    {!isError && <Typography style={{ marginBottom: "8px" }}>
      Are you sure you want to delete Youth {youth.referenceNumber}?
    </Typography>}
  </ConfirmDialog>
}
