export enum AssessmentTemplateTagEnum {
  Assessment = "assessment",
  Nirvana = "nirvana",
  ReAssessment = "reassessment",
  PostAssessment = "post",
  SelfReport = "selfreport",
  Topse = "topse",
  Moce = "moce",
}

export const assessmentTemplateTagTextMap: { [key: string]: string } = {
  [AssessmentTemplateTagEnum.Nirvana]: "NIRVANA",
  [AssessmentTemplateTagEnum.Topse]: "TOPSE",
  [AssessmentTemplateTagEnum.Moce]: "MoCE",
};
