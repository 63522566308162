import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import Header from "../../../app/components/Header";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import SnapCycleFilter from "../components/SnapCycleFilter";
import SnapGroupCycleFormDialog from "../components/SnapGroupCycleFormDialog";
import SnapGroupCycleList from "../components/SnapGroupCycleList";
import { useSnapGroupCycleListPage } from "../hooks/useSnapGroupCycleListPage";
import SnapCycleFilterList from "../components/SnapCycleFilterList";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { SnapGroupCycleSummaryDto } from "../dtos/SnapGroupCycleSummaryDto";
import FilterButton from "../../../app/components/buttons/FilterButton";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";

const SnapGroupCycleListPage = () => {
  const { state, handlers } = useSnapGroupCycleListPage();
  const {
    filterAnchorEl,
    snapCyclesResult,
    params,
    getSnapCyclesRequestState,
    createSnapCycleRequestState,
    programTypes,
    providers,
    providerPrograms,
    providerLocations, 
    getProgramTypesRequestState,
    getProvidersRequestState,
    getProgramsRequestState,
    getLocationsRequestState,
  } = state;
  
  const {
    handlePageChange,
    handleSearch,
    handleOpenFilterClicked,
    handleCloseFilter,
    handleCreateSnapCycleClicked,
    handleCreateSnapCycle,
    handleSort,
    handleProviderChange
  } = handlers;

  return (
    <div>
      <SnapCycleFilter
        params={params}
        onSearch={handleSearch}
        providers={providers}
        providerPrograms={providerPrograms}
        providerLocations={providerLocations}
        programTypes={programTypes}
        onClose={handleCloseFilter}
        anchorEl={filterAnchorEl}        
        onProviderChange={handleProviderChange}       />

      <SnapGroupCycleFormDialog
        onSubmit={handleCreateSnapCycle}
        requestState={createSnapCycleRequestState}
        programTypes={programTypes}
        programTypesAreLoading={getProgramTypesRequestState.isLoading}
      />
      <RequestErrorAlert
        requests={[
          getSnapCyclesRequestState,
          getProgramTypesRequestState,
          getProvidersRequestState,
          getProgramsRequestState,
          getLocationsRequestState,
        ]}
      />
      <Header
        title="SNAP Group Cycles"
        actions={
          <>
            <FilterButton
              title="Filter List"
              onClick={handleOpenFilterClicked}
              disabled={
                getProgramTypesRequestState.isLoading ||
                getProvidersRequestState.isLoading
              }
            />

            <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
              <AddButton
                onClick={handleCreateSnapCycleClicked}
                text="Create SNAP Group Cycle"
                variant="contained"
                color="primary"
                disabled={
                  getSnapCyclesRequestState.isLoading ||
                  createSnapCycleRequestState.isLoading
                }
                className="ml-2"
              />
            </WriteAccessLevelWrapper>
          </>
        }
        secondaryActions={
          <SnapCycleFilterList
            programTypes={programTypes}
            providers={providers}
            providerPrograms={providerPrograms}
            providerLocations={providerLocations}
            onSearch={handleSearch}
            params={params}
            isLoading={getSnapCyclesRequestState.isLoading}
          />
        }
      />

      <SnapGroupCycleList
        results={
          snapCyclesResult ||
          new PaginationResultDto<SnapGroupCycleSummaryDto>()
        }
        isLoading={getSnapCyclesRequestState.isLoading}
        onPageChange={handlePageChange}
        params={params}
        onSort={handleSort}
      />
    </div>
  );
};

export default SnapGroupCycleListPage;
