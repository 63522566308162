import { useRequest } from "../../../app/hooks/useRequest";
import {
  downloadIcmActivityReportRequest,
  downloadNirvanaExportRequest,
  getCumulativeDeliverablesReportDocumentRequest as getBenchmarksReportDocumentRequest,
  getCumulativeDeliverablesPivotReportDocumentRequest as getBenchmarksPivotReportDocumentRequest,
  getIcmActivityReportRequest,
  getMonthlyCorrectionsReportDocumentRequest,
  getMonthlyDeliverablesReportDocumentRequest,
  getMonthlyDeliverablesPivotReportDocumentRequest,
  getMonthlyReportCardDocumentRequest,
  getCaseFollowUpReportDocumentRequest,
  getBlankFollowUpReportDocumentRequest,
  getMonthlyReportCardByProviderDocumentRequest as downloadMonthlyReportCardByProviderRequest, primeFunction,
} from "../ReportRequests";

export const useGetIcmActivityReport = () =>
  useRequest(getIcmActivityReportRequest);
export const useDownloadIcmActivityReport = () =>
  useRequest(downloadIcmActivityReportRequest);
export const useDownloadNirvanaExportReport = () =>
  useRequest(downloadNirvanaExportRequest);
export const useGetMonthlyReportCardDocument = () =>
  useRequest(getMonthlyReportCardDocumentRequest);
export const useDownloadMonthlyReportCardByProvider = () =>
  useRequest(downloadMonthlyReportCardByProviderRequest);

export const useGetMonthlyDeliverablesReportDocument = () =>
  useRequest(getMonthlyDeliverablesReportDocumentRequest);
export const useGetMonthlyDeliverablesPivotReportDocument = () =>
  useRequest(getMonthlyDeliverablesPivotReportDocumentRequest);
export const useGetMonthlyCorrectionsReportDocument = () =>
  useRequest(getMonthlyCorrectionsReportDocumentRequest);
export const useGetBenchmarksReportDocument = () =>
  useRequest(getBenchmarksReportDocumentRequest);
export const useGetCumulativeDeliverablesPivotReportDocument = () =>
  useRequest(getBenchmarksPivotReportDocumentRequest);

export const useGetCaseFollowUpDocument = () =>
  useRequest(getCaseFollowUpReportDocumentRequest);

export const useGetBlankFollowUpDocument = () =>
  useRequest(getBlankFollowUpReportDocumentRequest);

export const usePrimeFunctionRequest = () =>
  useRequest(primeFunction);
