import React, { Fragment, useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { AccessLevelEnum } from "../../features/authentication/enums/AccessLevelEnum";
import { PermissionResourceNameEnum } from "../../features/authentication/enums/PermissionResourceNameEnum";
import { getProviderId } from "../../features/authentication/state/authenticationActions";
import {
  selectHasAccess,
  selectIsLoggedIn,
} from "../../features/authentication/state/authenticationSelectors";
import { addDangerNotification } from "../../features/notifications/state/notificationSlice";
import { useAppDispatch, useAppSelector } from "../hooks";

interface Props extends RouteProps {
  name: PermissionResourceNameEnum;
  accessLevel: AccessLevelEnum;
}

const AccessLevelRoute: React.FC<Props> = ({ name, accessLevel, ...props }) => {
  const hasAccessLevel = useAppSelector(selectHasAccess(name, accessLevel));
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn === false) {
      dispatch(
        addDangerNotification({
          message: "Unauthorized: Please login and try again.",
        })
      );
      history.push("/auth/login");
    } else if (hasAccessLevel === false && getProviderId() !== null) {
      dispatch(
        addDangerNotification({
          message: "You do not have permission to view this page.",
        })
      );
      history.push("/");
    }
  }, [hasAccessLevel, dispatch, history, isLoggedIn]);

  if (getProviderId() === null) return <Fragment></Fragment>;

  return <Route {...props} />;
};

export default AccessLevelRoute;
