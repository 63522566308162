import { Grid } from "@material-ui/core";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import React from "react";
import { CreateNewFollowUpFormData } from "../hooks/useCreateNewFollowUpForm";
import { Control } from "react-hook-form";
import { AddFollowUpDataFormData } from "../hooks/useAddFollowUpDataForm";
import ControlledYesNoRadioGroup from "../../../app/components/inputs/ControlledYesNoRadioGroup";

export interface Props {
  control:
    | Control<CreateNewFollowUpFormData, object>
    | Control<AddFollowUpDataFormData, object>;
  livingSituationOptions: SelectOption[];
  schoolStatusOptions: SelectOption[];
  improvementStatusOptions: SelectOption[];
}

export const FollowUpCompletionDataFormFields: React.FC<Props> = ({
  control,
  livingSituationOptions,
  schoolStatusOptions,
  improvementStatusOptions
}) => {
  return (
    <>
      <Grid item md={12} xs={12}>
        <ControlledYesNoRadioGroup
          disabled={false}
          control={control}
          name="receivedServicesSinceDischarge"
          label="Has the youth received services at this agency since discharge?"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledYesNoRadioGroup
          disabled={false}
          control={control}
          name="receivedServicesFromAnotherProviderSinceDischarge"
          label="Did the youth/family receive services from another Florida Network agency/program since discharge?"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          label={"Current Living Status"}
          control={control}
          name={"livingStatus"}
          options={livingSituationOptions}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledYesNoRadioGroup
          disabled={false}
          control={control}
          name="isYouthsLivingStatusAppropriate"
          label="Is this an appropriate living situation for the youth?"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          label={"Current School Status"}
          control={control}
          name={"schoolStatus"}
          options={schoolStatusOptions}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          label="Have you seen improvement in the issues for which the youth/family
          received services at your agency?"
          control={control}
          name="seenImprovement"
          disabled={false}
          options={improvementStatusOptions}
          fullWidth
        />
      </Grid>
    </>
  );
};
