import { Chip, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { SnapInSchoolsCycleFormDto } from "../dtos/SnapInSchoolsCycleFormDto";
import * as Yup from "yup";
import { GradeLevelEnum, gradeLevelTextMap } from "../enums/GradeLevelEnum";
import InfoTooltip from "../../../app/components/InfoTooltip";
import ControlledAutocomplete from "../../../app/components/inputs/ControlledAutocomplete";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";

export const snapInSchoolsCycleSchema = Yup.object().shape({
  startedOn: Yup.string().label("Group Start Date").required(),
  grades: Yup.array().label("Grade(s)").min(1),
  location: Yup.string().label("Location").required().nullable(),
  supervisorName: Yup.string().label("Teacher/Supervisory Adult").required(),
  nonFloridaNetwork: Yup.bool().label("Not Florida Network Funded"),
  programId: Yup.string().label("Program").required(),
});

interface Props {
  control: Control<SnapInSchoolsCycleFormDto>;
  isDisabled: boolean;
  locations: string[];
  programs: ProgramDto[];
  locationsAreLoading: boolean;
  programsAreLoading: boolean;
}

const SnapInSchoolsCycleFormFields: React.FC<Props> = ({ control, isDisabled, locations, locationsAreLoading, programs, programsAreLoading }) => {
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ProgramControlledSelect
          control={control}
          name="programId"
          label="Program"
          programs={programs}
          disabled={programs.length === 0}
          defaultOptionLabel={"Select a Program"}
          isLoading={programsAreLoading}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="startedOn"
          label="Group Start Date*"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledAutocomplete
          multiple
          options={Object.values(GradeLevelEnum)}
          openOnFocus
          autoSelect
          getOptionLabel={(option) => gradeLevelTextMap[option]}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => <Chip variant="outlined" label={gradeLevelTextMap[option]} {...getTagProps({ index })} />)
          }
          name="grades"
          label={"Grade(s)*"}
          control={control}
          renderInput={() => <Fragment></Fragment>}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledAutocomplete
          options={locations.sort((a, b) => (a > b ? 1 : -1))}
          freeSolo
          loading={locationsAreLoading}
          autoSelect
          openOnFocus
          name="location"
          label={"Location*"}
          control={control}
          renderInput={() => <Fragment></Fragment>}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="supervisorName"
          label="Teacher/Supervisory Adult*"
          control={control}
          fullWidth
          InputProps={{
            endAdornment: <InfoTooltip title={<Typography>This is not the SNAP Facilitator.</Typography>} />,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="classroomSize"
          label="Classroom Size"
          control={control}
          fullWidth
          InputProps={{
            endAdornment: <InfoTooltip title={<Typography>Number of Youth in the SNAP in Schools & Communities setting, and is the maximum number of Youth in a Session.</Typography>} />,
          }}
        />
      </Grid>
    </Fragment>
  );
};

export default SnapInSchoolsCycleFormFields;
