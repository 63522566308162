import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum ReasonForPetitionEnum {
  Truancy = "Truancy",
  Ungovernable = "Ungovernable",
}

export const reasonForPetitionTextMap: { [key: string]: string } = {
  [ReasonForPetitionEnum.Truancy]: "Truancy",
  [ReasonForPetitionEnum.Ungovernable]: "Ungovernable",
};

export const reasonForPetitionOptions: SelectOption[] = [
  { value: "", label: "Select Reason For Petition" },
  ...Object.values(ReasonForPetitionEnum).map((value) => ({
    value,
    label: reasonForPetitionTextMap[value],
  })),
];
