import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum DemographicsFamilyWeeklyIncomeEnum {
  Under500 = "Under500",
  Between500And999 = "Between500And999",
  Between1000And1499 = "Between1000And1499",
  Between1500And1999 = "Between1500And1999",
  Over2000 = "Over2000",
}

export const demographicsFamilyWeeklyIncomeTextMap: { [key: string]: string } =
  {
    [DemographicsFamilyWeeklyIncomeEnum.Under500]: "<$500/week",
    [DemographicsFamilyWeeklyIncomeEnum.Between500And999]: "$500 - $999/week",
    [DemographicsFamilyWeeklyIncomeEnum.Between1000And1499]:
      "$1,000 - $1,499/week",
    [DemographicsFamilyWeeklyIncomeEnum.Between1500And1999]:
      "$1,500 - $1,999/week",
    [DemographicsFamilyWeeklyIncomeEnum.Over2000]: ">$2,000/week",
  };

export const demographicsFamilyWeeklyIncomeOptions: SelectOption[] = [
  { value: "", label: "Select Family's Income" },
  ...Object.keys(DemographicsFamilyWeeklyIncomeEnum).map((status) => ({
    value: status,
    label: demographicsFamilyWeeklyIncomeTextMap[status],
  })),
];
