import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import {
  LivingSituationEnum,
  livingSituationTextMap,
} from "../enums/LivingSituationEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  livingSituation: LivingSituationEnum;
}

const LivingSituationLabel: React.FC<Props> = ({
  livingSituation,
  ...props
}) => {
  return (
    <Typography {...props}>
      {livingSituationTextMap[livingSituation]}
    </Typography>
  );
};

export default LivingSituationLabel;
