import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getProviderId} from "../../authentication/state/authenticationActions";
import {useGetAllProviders, useGetProviderLocations, useGetProviderPrograms} from "../../provider/hooks/providerHooks";
import {useGetProgramTypes} from "../../program-types/hooks/programTypeHooks";
import {useEffect, useMemo, useState} from "react";
import {ProgramTypeEnum} from "../../programs/enums/ProgramTypeEnum";
import {fnyfsProviderId} from "../../authentication/constants";
import {ProgramTypeDto} from "../../program-types/dtos/ProgramTypeDto";
import {ProgramDto} from "../../programs/dtos/ProgramDto";
import {LocationDto} from "../../locations/dtos/LocationDto";
import {uniq} from "lodash";

export class SNAPExportParametersDto {
  providerId?: string;
  programTypeId?: string;
  programId?: string;
  cycleStartDate?: string;
  cycleEndDate?: string;
  groupStartDate?: string;
  groupEndDate?: string;
  locationId?: string;

  constructor() {
    this.cycleStartDate = "";
    this.cycleEndDate = "";
    this.groupStartDate = "";
    this.groupEndDate = "";
    this.locationId = "";
    this.providerId = "";
    this.programTypeId = "";
    this.programId = "";
  }

  public static getSchema() {
    return Yup.object().shape({
      programTypeId: Yup.string().required("Program Type is required"),
    });
  }
}


export const useSNAPExportReportFilter = (
  params: SNAPExportParametersDto,
  onSubmit: ({dto, programType}: { dto: SNAPExportParametersDto, programType: ProgramTypeEnum }) => Promise<void>,
) => {

  const {
    control,
    reset,
    getValues,
    resetField,
    formState: { isValid },
    watch
  } = useForm({
    defaultValues: new SNAPExportParametersDto(),
    resolver: yupResolver(SNAPExportParametersDto.getSchema()),
    mode: "all",
  });

  const validSnapProgramTypes = useMemo(() => [
    ProgramTypeEnum.Snap,
    ProgramTypeEnum.SnapYouthJustice,
    ProgramTypeEnum.SnapInSchools
  ], []);

  const providerId = getProviderId();

  const selectedProgramTypeId = watch("programTypeId");

  const [snapProgramTypes, setSnapProgramTypes] = useState<ProgramTypeDto[]>([])
  const [snapPrograms, setSnapPrograms] = useState<ProgramDto[]>([]);
  const [snapLocations, setSnapLocations] = useState<LocationDto[]>([]);

  const [getAllProviders, providers, getAllProvidersRequestState] =
    useGetAllProviders();

  const [getProgramTypes, programTypes, programTypesRequestState] = useGetProgramTypes();
  const [getProgramsByProviderId, programs, programsRequestState] = useGetProviderPrograms()
  const [getProviderLocations, locations, locationsRequestState] = useGetProviderLocations();

  const handleDownload = async () => {
    const values = getValues();

    const programType = snapProgramTypes.find(p => p.id === values.programTypeId);

    if(programType) {
      await onSubmit({ dto: values, programType: programType.programType });
    }
    else {
      console.error('Program type not found');
    }
  };

  const showLocationFilter = useMemo(() => {
    return selectedProgramTypeId
      && providerId !== fnyfsProviderId
      && snapProgramTypes.find(x => x.id === selectedProgramTypeId)?.programType !== ProgramTypeEnum.SnapInSchools;

  }, [providerId, selectedProgramTypeId, snapProgramTypes]);

  useEffect(() => {
    reset(params);
  }, [params, reset]);

  useEffect(() => {
    getProgramTypes(null);
  }, [getProgramTypes])

  useEffect(() => {
    if(programTypes && programTypes.length > 0) {
      const snapProgramTypes = programTypes
        .filter(x => validSnapProgramTypes.some(p => x.programType === p));

      setSnapProgramTypes(snapProgramTypes)
    } else {
      setSnapProgramTypes([])
    }
  }, [validSnapProgramTypes, programTypes, setSnapProgramTypes]);

  useEffect(() => {
    if(programs && programs.length > 0) {
      console.log(selectedProgramTypeId)
      const snapPrograms = programs
        .filter(x => validSnapProgramTypes.some(p => x.programType.programType === p))
        .filter(x => !selectedProgramTypeId || x.programType.id === selectedProgramTypeId);

      setSnapPrograms(snapPrograms)
      resetField("programId");
      resetField("locationId");
    } else {
      setSnapPrograms([])
    }
  }, [validSnapProgramTypes, programs, selectedProgramTypeId, setSnapPrograms, resetField]);

  useEffect(() => {
    if(locations && locations.length > 0) {
      const validProgramLocationIds = snapPrograms.reduce((acc, curr) => {
        return uniq([...acc,  ...(curr.locations.map(l => l.locationId))])
      }, [] as string[]);

      const validLocations = locations
        .filter(l => validProgramLocationIds.some(id => id === l.id));

      setSnapLocations(validLocations);
    } else {
      setSnapLocations([])
    }
  }, [snapPrograms, locations])

  useEffect(() => {
    if (getProviderId() === fnyfsProviderId)
      getAllProviders({
        programTypes: [],
      });
  }, [getAllProviders]);

  useEffect(() => {
    if(providerId && providerId !== fnyfsProviderId) {
      getProgramsByProviderId(providerId);
      getProviderLocations(providerId);
    }
  }, [providerId, getProgramsByProviderId, getProviderLocations])

    return {
      state: {
        getAllProvidersRequestState,
        providers: providers || [],
        programTypes: snapProgramTypes,
        programTypesRequestState,
        programs: snapPrograms,
        programsRequestState,
        locations: snapLocations,
        locationsRequestState,
        showLocationFilter,
        isValid,
      },
      form: { control },
      handlers: { handleDownload },
    };
}
