import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../app/enums/SortDirectionEnum";
import { datetimeToFormString, QueryStringHelpers } from "../../app/helpers";
import * as Yup from "yup";
import { SelectOption } from "../../app/components/inputs/ControlledSelect";
import { YouthRelationshipEnum } from "../youth-contacts/enums/YouthRelationshipEnum";
import { DischargeStatusEnum } from "../discharges/enums/DischargeStatusEnum";
import { YouthGenderEnum } from "../youths/enums/YouthGenderEnum";
import { YouthRaceEnum } from "../youths/enums/YouthRaceEnum";
import { YouthEthnicityEnum } from "../youths/enums/YouthEthnicityEnum";
import { YouthAddressDto } from "../youths/dto/YouthDto";
import { DemographicsFamilyStructureEnum } from "../demographics/enums/DemographicsFamilyStructureEnum";
import { YouthPhoneNumberTypeEnum } from "../youths/enums/YouthPhoneNumberTypeEnum";
import { isArray } from "lodash";
import { IntakeGradeLevelEnum } from "../intakes/enums/IntakeGradeLevelEnum";
import { LivingSituationEnum } from "../screenings/enums/LivingSituationEnum";
import { LocationDto } from "../locations/dtos/LocationDto";

export enum DjjEntryStatusEnum {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Completed = "Completed",
}

export const DjjEntryStatusTextMap: { [key: string]: string } = {
  [DjjEntryStatusEnum.NotStarted]: "Not Started",
  [DjjEntryStatusEnum.InProgress]: "In Progress",
  [DjjEntryStatusEnum.Completed]: "Completed",
};

export const DjjEntryStatusOptions: SelectOption[] = [
  { value: "", label: "Select Status" },
  ...Object.values(DjjEntryStatusEnum).map((value) => ({
    value,
    label: DjjEntryStatusTextMap[value],
  })),
];

export enum DjjEntryTypeEnum {
  Intake = "Intake",
  IntakeUpdated = "IntakeUpdated",
  Discharge = "Discharge",
  DischargeUpdated = "DischargeUpdated",
  CaseDeleted = "CaseDeleted",
}

export const DjjEntryTypeTextMap: { [key: string]: string } = {
  [DjjEntryTypeEnum.Intake]: "Intake",
  [DjjEntryTypeEnum.IntakeUpdated]: "Intake (Updated)",
  [DjjEntryTypeEnum.Discharge]: "Discharge",
  [DjjEntryTypeEnum.DischargeUpdated]: "Discharge (Updated)",
  [DjjEntryTypeEnum.CaseDeleted]: "Deleted Case",
};

export const getDjjEntryTypeOptions = (defaultValueLabel: React.ReactNode = "Select Entry Type"): SelectOption[] => [
  { value: "", label: defaultValueLabel },
  ...Object.values(DjjEntryTypeEnum).map((value) => ({
    value,
    label: DjjEntryTypeTextMap[value],
  })),
];

export interface DjjEntryListDto {
  referenceId: string;
  referenceType: string;
  djjEntryType: DjjEntryTypeEnum;
  eventDate: Date;
  dataEntryDate: Date;
  lag: number;
  youthId: string;
  youthName: string;
  youthReferenceNumber: string;
  djjIdNumber?: number;
  programType: string;
  providerName: string;
  providerId: string;
  judicialCircuitNumber?: number;
  juvenileJusticeProgram: string;
  status: DjjEntryStatusEnum;
  statusEnteredAt?: Date;
  hasNotes: boolean;
}

export class SetDjjEntryStatusFormDto {
  statusEnteredAt: string;
  status: string;

  constructor(dto?: DjjEntryListDto) {
    this.statusEnteredAt = datetimeToFormString(new Date());
    this.status = dto?.status || "";
  }

  public static getSchema() {
    return Yup.object().shape({
      statusEnteredAt: Yup.string().label("Date").required(),
      status: Yup.string().label("Status").required(),
    });
  }
}

export class SetDjjEntryNotesFormDto {
  notes: string;

  constructor(notes?: string) {
    this.notes = notes || "";
  }

  public static getSchema() {
    return Yup.object().shape({
      notes: Yup.string().label("Notes").max(1000),
    });
  }
}

export class DjjEntryFilterFormDto {
  entryType: string = "";
  providerId: string = "";
  hasDjjId: string = "";
  statuses: string[];
  statusOptions: { [key: string]: boolean };

  constructor(params?: DjjEntrySearchParamsDto) {
    this.entryType = params?.entryType || "";
    this.providerId = params?.providerId || "";
    this.hasDjjId = params?.hasDjjId || "";
    this.statuses = params?.statuses || [];
    this.statusOptions = DjjEntryFilterFormDto.getStatusOptions(params);
  }

  private static getStatusOptions(params?: DjjEntrySearchParamsDto) {
    return Object.values(DjjEntryStatusEnum).reduce<{ [key: string]: boolean }>((acc, value) => {
      acc[value] = params?.statuses?.includes(value) || false;

      return acc;
    }, {});
  }
}

export class DjjEntrySearchParamsDto implements PaginationSearchParamsDto {
  entryType?: string;
  providerId?: string;
  hasDjjId?: string;
  statuses?: string[];
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({ query, form, previous }: { query?: ParsedQuery<string>; form?: DjjEntryFilterFormDto; previous?: DjjEntrySearchParamsDto }) {
    this.entryType = form?.entryType || (query?.entryType as string) || "";
    this.providerId = form?.providerId || (query?.providerId as string) || "";
    this.hasDjjId = form?.hasDjjId || (query?.hasDjjId as string) || "";

    this.statuses = DjjEntrySearchParamsDto.getStatuses({ form, query });

    this.sortBy = previous?.sortBy || (query?.sortBy as string) || "lag";
    this.sortDirection = (previous?.sortDirection as SortDirectionEnum) || QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 7;
  }

  public static toQueryString(dto: DjjEntrySearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }

  private static getStatuses({ query, form }: { query?: ParsedQuery<string>; form?: DjjEntryFilterFormDto }) {
    if (form?.statuses) return form.statuses;

    if (query?.statuses == null) return [DjjEntryStatusEnum.NotStarted, DjjEntryStatusEnum.InProgress];

    if (isArray(query.statuses)) return query.statuses as string[];

    return [query.statuses as string];
  }
}

export interface DjjEntryDetailYouthContactDto {
  id: string;
  relationship: YouthRelationshipEnum;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
}

export interface DjjEntryDetailDto {
  djjEntryType: DjjEntryTypeEnum;
  intakeAt: Date;
  dischargedAt?: Date;
  intakeCreatedAt: Date;
  intakeUpdatedAt: Date;
  dischargeCreatedAt?: Date;
  dischargeUpdatedAt?: Date;
  caseNumber: string;
  dischargeStatus?: DischargeStatusEnum;
  youthId: string;
  youthName: string;
  youthReferenceNumber: string;
  youthDob: Date;
  youthGender: YouthGenderEnum;
  youthRace: YouthRaceEnum;
  youthIsMultiracial: boolean;
  youthEthnicity?: YouthEthnicityEnum;
  youthAddress: YouthAddressDto;
  youthPhoneNumber: string;
  youthPhoneNumberType?: YouthPhoneNumberTypeEnum;
  schoolName: string;
  familyStructure: DemographicsFamilyStructureEnum;
  youthContacts: DjjEntryDetailYouthContactDto[];
  programName: string;
  currentGrade?: IntakeGradeLevelEnum;
  referralSource: string;
  isYouthParent: boolean;
  dischargeLivingSituation: LivingSituationEnum;
  programLocation: LocationDto;
  deleteReason?: string;
}
