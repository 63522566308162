import { Box, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledSelect from "../../../../app/components/inputs/ControlledSelect";
import {
  getLocationOptions,
  LocationDto,
} from "../../../locations/dtos/LocationDto";
import { ProgramDto } from "../../../programs/dtos/ProgramDto";
import ProgramControlledSelect from "../../../programs/components/ProgramControlledSelect";
import InfoTooltip from "../../../../app/components/InfoTooltip";

interface Props {
  control: Control<any>;
  isDisabled: boolean;
  providerPrograms: ProgramDto[];
  locations: LocationDto[];
  providerProgramsAreLoading: boolean;
  providerLocationsAreLoading: boolean;
  locationMessage: string;
}

const CaseProgramFormFields: React.FC<Props> = ({
  control,
  isDisabled,
  providerPrograms,
  locations,
  providerLocationsAreLoading,
  providerProgramsAreLoading,
  locationMessage,
}) => {
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ProgramControlledSelect
          name="programId"
          label="Program"
          control={control}
          disabled={isDisabled}
          programs={providerPrograms}
          isLoading={providerProgramsAreLoading}
          defaultOptionLabel={"Select Program"}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          name="locationId"
          label="Location"
          control={control}
          disabled={isDisabled}
          options={getLocationOptions(
            locations,
            <Box display="flex" justifyContent="space-between" width="100%">
              <div>Select Location</div>{" "}
              <InfoTooltip title={<Typography>{locationMessage}</Typography>} />
            </Box>
          )}
          isLoading={providerLocationsAreLoading}
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export default CaseProgramFormFields;
