import { omit } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { flattenOptions } from "../../../app/helpers";
import { DjjEntryFilterFormDto, DjjEntrySearchParamsDto } from "../DjjEntryDto";

export const useDjjEntryFilter = (
  onSubmit: (dto: DjjEntrySearchParamsDto) => void,
  onClose: () => void,
  params: DjjEntrySearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit, watch } = useForm({
    defaultValues: new DjjEntryFilterFormDto(params),
  });
  const { statusOptions } = watch();
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: DjjEntryFilterFormDto) => {
    const newParams: DjjEntrySearchParamsDto = {
      ...omit(dto, "statusOptions"),
      statuses: flattenOptions(dto.statusOptions),
      sortBy: params.sortBy || "",
      sortDirection: params.sortDirection || SortDirectionEnum.Ascending,
      page: 0,
      pageSize: params.pageSize,
    };

    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new DjjEntryFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new DjjEntryFilterFormDto(params));
  }, [params, reset]);

  //#endregion

  return {
    state: { statusOptions },
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
