import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FollowUpFilterFormDto } from "../dtos/FollowUpFilterFormFilterDto";
import { FollowUpSearchParamsDto } from "../dtos/FollowUpSearchParamsDto";
import {SelectOption} from "../../../app/components/inputs/ControlledSelect";
import {FollowUpInterval, followUpIntervalTextMap} from "../enums/FollowUpInterval";

export const useFollowUpFilter = (
  onSubmit: (dto: FollowUpSearchParamsDto) => void,
  onClose: () => void,
  params: FollowUpSearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: new FollowUpFilterFormDto(params),
  });

  const hadServicesSinceDischargeOptions : SelectOption[] = [
    { label: 'All', value: '' },
    { label: 'No Services Since Discharge', value: 'false' },
    { label: 'Has Services Since Discharge', value: 'true' },
  ];

  const intervalOptions: SelectOption[] = [
    "",
    FollowUpInterval.ThirtyDay,
    FollowUpInterval.SixtyDay,
    FollowUpInterval.NinetyDay,
  ].map((interval) => ({
    label: followUpIntervalTextMap[interval] ?? "All",
    value: interval
  }));

  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: FollowUpFilterFormDto) => {
    const newParams: FollowUpSearchParamsDto = {
      ...params,
      ...dto,
      page: 0,
      pageSize: params.pageSize,
    };

    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new FollowUpFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new FollowUpFilterFormDto(params));
  }, [params, reset]);

  //#endregion

  return {
    form: { control, clearAllFilters },
    state: { hadServicesSinceDischargeOptions, intervalOptions },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
