import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React from "react";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import TableLoadingPlaceholder from "../../../app/components/TableLoadingPlaceholder";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { ProviderDto } from "../dtos/ProviderDto";
import { ProviderSearchParamsDto } from "../dtos/ProviderSearchParamsDto";

interface Props {
  providerResults: PaginationResultDto<ProviderDto>;
  isLoading: boolean;
  params: ProviderSearchParamsDto;
  onPageChange(page: number): void;
  onSort(sortBy: string, sortDirection: SortDirectionEnum): void;
}

const ProviderList: React.FC<Props> = ({
  onPageChange,
  isLoading,
  params,
  onSort,
  providerResults,
}) => {
  //#region UI Helpers
  const renderRow = (provider: ProviderDto) => {
    return (
      <TableRow key={provider.id}>
        <TableCell>
          <RouteableStyledLink
            pathname={`/provider/${provider.id}`}
            text="Back to Providers"
          >
            {provider.name}
          </RouteableStyledLink>
        </TableCell>
        <TableCell>{provider.abbreviation}</TableCell>
        <TableCell>{provider.centerId}</TableCell>
      </TableRow>
    );
  };

  const createSortTableCell = (label: React.ReactNode, name: string) => {
    let direction: SortDirectionEnum =
      params.sortBy === name
        ? params.sortDirection!
        : SortDirectionEnum.Ascending;

    const isCurrentSortBy = params.sortBy === name;
    return (
      <TableCell sortDirection={isCurrentSortBy ? params.sortDirection : false}>
        <TableSortLabel
          active={isCurrentSortBy}
          direction={direction}
          onClick={() =>
            onSort(
              name,
              isCurrentSortBy && direction === SortDirectionEnum.Ascending
                ? SortDirectionEnum.Descending
                : SortDirectionEnum.Ascending
            )
          }
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  };
  //#endregion

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {createSortTableCell("Name", "name")}
            {createSortTableCell("Abbreviation", "abbreviation")}
            {createSortTableCell("Center ID", "centerId")}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && providerResults.items.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No Providers Found</TableCell>
            </TableRow>
          )}

          {!isLoading && providerResults.items.map(renderRow)}
          {isLoading && (
            <TableLoadingPlaceholder rows={params.pageSize || 10} columns={3} />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[providerResults.pageSize || 10]}
              rowsPerPage={providerResults.pageSize || 0}
              count={providerResults?.count || 0}
              onPageChange={(_: any, page: number) => onPageChange(page)}
              page={providerResults?.page || 0}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ProviderList;
