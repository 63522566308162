import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import CaseAlertDismissalsListPage from "./pages/CaseAlertDismissalsListPage";
import CaseDetailPage from "./pages/CaseDetailPage";
import CaseListPage from "./pages/CaseListPage";

export const createCaseRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "")}
    component={CaseListPage}
    key="case-list"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "alerts/dismissals")}
    component={CaseAlertDismissalsListPage}
    key="case-alert-dismissals"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, ":id")}
    component={CaseDetailPage}
    key="case-detail"
    exact
  />,
];
