import React from "react";
import { Box, Grid } from "@material-ui/core";
import { NumericValueMetricPanel } from "../NumericValueMetricPanel";
import { PieChartMetricPanel } from "../PieChartMetricPanel";
import { createNumberRange } from "../../../../app/helpers";
import {DataEntryMetricPanel} from "../DataEntryMetricPanel";

export const metricIds = [
  "outreachEvents",
  "intakes",
  "referrals",
  "screenings",
  "nirvanaAssessmentsInitiated",
  "satisfactionSurveys",
  "sixtyDayFollowUps",
  "thirtyDayFollowUps",
  "allDischargesDataEntry",
  "allIntakesDataEntry"
] as const;

export type MainDashboardMetricsDto = {
  [metricId in typeof metricIds[number]]: any;
};

interface Props {
  metrics?: MainDashboardMetricsDto;
  isLoading: boolean;
}

export const MainDashboardMetrics: React.FC<Props> = ({
  metrics,
  isLoading,
}) => {
  const renderFirstRow = () => {
    if (isLoading || !metrics)
      return (
        <>
          {createNumberRange(5).map((x) => (
            <NumericValueMetricPanel key={x} isLoading={isLoading} />
          ))}
        </>
      );

    return (
      <>
        {[
          metrics.outreachEvents,
          metrics.referrals,
          metrics.screenings,
          metrics.intakes,
          metrics.nirvanaAssessmentsInitiated,
        ]
          .filter((val) => val)
          .map((val, index) => (
            <NumericValueMetricPanel key={index} metric={val} />
          ))}
      </>
    );
  };

  const renderSecondRow = () => {
    if (isLoading || !metrics)
      return (
        <>


              <DataEntryMetricPanel
                intakesMetric={metrics?.allIntakesDataEntry}
                dischargesMetric={
                  metrics?.allDischargesDataEntry
                }
                isLoading={isLoading}
              />

          {createNumberRange(3).map((x) => (
            <PieChartMetricPanel
              key={x}
              centerTitle={true}
              isLoading={isLoading}
            />
          ))}
        </>
      );

    return (
      <>

          <DataEntryMetricPanel
            intakesMetric={metrics?.allIntakesDataEntry}
            dischargesMetric={
              metrics?.allDischargesDataEntry
            }
            isLoading={isLoading}
          />

        {[
          metrics.satisfactionSurveys,
          metrics.thirtyDayFollowUps,
          metrics.sixtyDayFollowUps,
        ]
          .filter((val) => val)
          .map((val, index) => (
            <PieChartMetricPanel key={index} metric={val} centerTitle={true} />
          ))}
      </>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Box display={"flex"} flexDirection={"row"} style={{ width: "100%" }}>
          {renderFirstRow()}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"flex-start"}
          style={{ width: "100%" }}
        >
          {renderSecondRow()}
        </Box>
      </Grid>

    </>
  );
};
