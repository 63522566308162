import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  ProgramTypeDto,
  programTypesToOptions,
} from "../../program-types/dtos/ProgramTypeDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import { WaitlistSearchParamsDto } from "../dtos/WaitlistSearchParamsDto";
import { useWaitlistFilter } from "../hooks/useWaitlistFilter";
import {
  getLocationOptions,
  LocationDto,
} from "../../locations/dtos/LocationDto";
import { waitlistStatusOptions } from "../enums/WaitlistStatusEnum";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";

interface Props {
  params: WaitlistSearchParamsDto;
  anchorEl: HTMLButtonElement | null;
  providers: ProviderDto[];
  programs: ProgramDto[];
  programTypes: ProgramTypeDto[];
  locations: LocationDto[];
  onClose(): void;
  onSearch(dto: WaitlistSearchParamsDto): void;
}

const WaitlistFilter: React.FC<Props> = ({
  params,
  anchorEl,
  providers,
  programs,
  programTypes,
  locations,
  onClose,
  onSearch,
}) => {
  //#region State
  const {
    form: { control, clearAllFilters },
    handlers: { handleSubmit },
  } = useWaitlistFilter(onSearch, onClose, params);
  //#endregion

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">Waitlist Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            {/* Screening Number */}
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="screeningNumber"
                label="Screening #"
                fullWidth
              />
            </Grid>

            {/* Provider Name */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="providerId"
                  label="Provider"
                  fullWidth
                  options={providerToOptionDtos(providers, [], "All")}
                />
              </Grid>
            </FnAccessWrapper>

            {/* Program Types */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="programTypeId"
                  label="Program Type"
                  options={programTypesToOptions(programTypes, "All")}
                  fullWidth
                />
              </Grid>
            </FnAccessWrapper>

            {/* Programs */}
            <ProviderAccessWrapper>
              <Grid item md={12} xs={12}>
                <ProgramControlledSelect
                  control={control}
                  name="programId"
                  label="Program"
                  programs={programs}
                  disabled={false}
                  defaultOptionLabel={"All"}
                  isLoading={false}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="locationId"
                  label="Location"
                  options={getLocationOptions(locations, "All")}
                  fullWidth
                />
              </Grid>
            </ProviderAccessWrapper>

            {/* Youth Name */}
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="youthFirstName"
                label="Youth First Name"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="youthLastName"
                label="Youth Last Name"
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="status"
                fullWidth
                options={waitlistStatusOptions}
                label="Status"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default WaitlistFilter;
