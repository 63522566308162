import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { QueryStringHelpers } from "../../app/helpers";
import { LocationDto } from "../locations/dtos/LocationDto";
import { LocationFormDto } from "../locations/dtos/LocationFormDto";
import { OutreachActivityDto } from "../outreach-activities/dtos/OutreachActivityDto";
import { OutreachActivityFormDto } from "../outreach-activities/dtos/OutreachActivityFormDto";
import { ProgramDto } from "../programs/dtos/ProgramDto";
import { StaffMemberDto } from "../staff-members/dtos/StaffMemberDto";
import { ProviderDetailDto } from "./dtos/ProviderDetailDto";
import { ProviderDto } from "./dtos/ProviderDto";
import { ProviderFormDto } from "./dtos/ProviderFormDto";
import {
  AllProvidersSearchParamsDto,
  ProviderSearchParamsDto,
  providerSearchParamsToQueryString,
} from "./dtos/ProviderSearchParamsDto";
const baseUrl = "providers/";

interface UpdateProviderRequestsParamsDto {
  id: string;
  dto: ProviderFormDto;
}

interface CreateProviderLocationRequestParams {
  id: string;
  dto: LocationFormDto;
}

interface CreateOutreachActivityRequestParams {
  id: string;
  dto: OutreachActivityFormDto;
}

export const createProviderRequest = (dto: ProviderFormDto) =>
  coreAgent.postRequest<ProviderDto>(baseUrl, dto);

export const getProvidersRequest = (params: ProviderSearchParamsDto) =>
  coreAgent.getRequest<PaginationResultDto<ProviderDto>>(
    `${baseUrl}?${providerSearchParamsToQueryString(params)}`
  );

export const updateProviderRequest = ({
  id,
  dto,
}: UpdateProviderRequestsParamsDto) =>
  coreAgent.putRequest<ProviderDetailDto>(`${baseUrl}${id}`, dto);

export const getProviderByIdRequest = (id: string) =>
  coreAgent.getRequest<ProviderDetailDto>(`${baseUrl}${id}`);

export const getProviderProgramsRequest = (id: string) =>
  coreAgent.getRequest<ProgramDto[]>(`/providers/${id}/programs`);

export const getProviderLocationsRequest = (id: string) =>
  coreAgent.getRequest<LocationDto[]>(`${baseUrl}${id}/locations`);
export const getProviderStaffMembersRequest = (id: string) =>
  coreAgent.getRequest<StaffMemberDto[]>(`${baseUrl}${id}/staffmembers`);

export const getAllProvidersRequest = (dto?: AllProvidersSearchParamsDto) =>
  coreAgent
    .getRequest<ProviderDto[]>(
      `providers/all?${
        dto != null ? QueryStringHelpers.toQueryString(dto) : ""
      }`
    )
    .then((x) => x.sort((a, b) => (a.name > b.name ? 1 : -1)));

export const createProviderLocationRequest = ({
  id,
  dto,
}: CreateProviderLocationRequestParams) =>
  coreAgent.postRequest(`providers/${id}/locations`, dto);

export const createOutreachActivityRequest = ({
  id,
  dto,
}: CreateOutreachActivityRequestParams) =>
  coreAgent.postRequest<OutreachActivityDto>(
    `providers/${id}/outreachactivities`,
    dto
  );
