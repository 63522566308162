import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useQuery } from "../../../app/hooks/useQuery";

import { useGetEventLogList } from "./eventLogHooks";
import {
  EventLogSearchParamsDto,
  createEventLogParamsDtoFromQuery,
  eventLogSearchParamsDtoToParams,
} from "../dtos/EventLogSearchParamsDto";
import { EventLogListDto } from "../dtos/EventLogListDto";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { useGetAllProviders } from "../../provider/hooks/providerHooks";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../authentication/constants";
import { getAffectedDeliverableEnumOptions } from "../enums/AffectedDeliverablesEnum";

const referenceTypeOptions = [
  { label: "All", value: "" },
  { label: "Case", value: "Case" },
  { label: "Case Session", value: "CaseSession" },
  { label: "SNAP Group", value: "SnapGroup" },
  { label: "SNAP In Schools Session", value: "SnapInSchoolsSession" },
];

const affectedDeliverableOptions = getAffectedDeliverableEnumOptions();

export const useEventLogPage = () => {
  //#region State
  const query = useQuery();
  const [params, setParams] = useState<EventLogSearchParamsDto>(
    createEventLogParamsDtoFromQuery(query)
  );
  const [getEventLogList, eventLogListResult, getEventLogListRequestState] =
    useGetEventLogList();
  const history = useHistory();

  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [filteredEventList, setFilteredEventList] = useState<EventLogListDto[]>(
    eventLogListResult?.items ?? []
  );
  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();
  //#endregion

  //#region Handlers
  const handlePageChange = useCallback(
    (page: number) => {
      setParams((params) => ({ ...params, page }));
    },
    [setParams]
  );

  const handleSearch = (dto: EventLogSearchParamsDto) => {
    setParams(dto);
  };

  const handleOpenFilterClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => setFilterAnchorEl(null);
  //#endregion

  //#region Effects
  useEffect(() => {
    getEventLogList(params);
  }, [params, getEventLogList]);

  useEffect(() => {
    history.push(
      `/event-log/invoicing?${eventLogSearchParamsDtoToParams(params)}`
    );
  }, [params, history]);

  useEffect(() => {
    const distinctList =
      eventLogListResult?.items.reduce(
        (acc, itm) => ({ ...acc, [itm.id]: itm }),
        {}
      ) ?? {};
    setFilteredEventList(Object.values(distinctList));
  }, [eventLogListResult]);

  useEffect(() => {
    if (getProviderId() === fnyfsProviderId) getProviders(undefined);
  }, [getProviders]);
  //#endregion

  return {
    state: {
      eventLogListResult: {
        ...eventLogListResult,
        items: filteredEventList,
      } as PaginationResultDto<EventLogListDto>,
      params,
      providers: providers || [],
      getEventLogListRequestState,
      getProvidersRequestState,
      filterAnchorEl,
      referenceTypeOptions,
      affectedDeliverableOptions
    },
    handlers: {
      handlePageChange,
      handleSearch,
      handleOpenFilterClicked,
      handleCloseFilter,
    },
  };
};
