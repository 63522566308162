import { createSlice } from "@reduxjs/toolkit";
import {
  confirmEmail,
  resetPassword,
  sendConfirmationEmail,
  sendResetPassword,
} from "./accountActions";

export interface AccountState {
  isLoading: boolean;
}

const initialState: AccountState = {
  isLoading: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(confirmEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(confirmEmail.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(confirmEmail.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendConfirmationEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendConfirmationEmail.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendConfirmationEmail.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendResetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendResetPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendResetPassword.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default accountSlice.reducer;
