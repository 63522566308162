import { Typography } from "@material-ui/core";
import React from "react";
import {
  YouthEthnicityEnum,
  YouthEthnicityEnumTextMap,
} from "../../enums/YouthEthnicityEnum";

interface Props {
  ethnicity?: YouthEthnicityEnum;
}

const EthnicityLabel: React.FC<Props> = ({ ethnicity }) => {
  if (ethnicity == null) return <></>;

  return <Typography>{YouthEthnicityEnumTextMap[ethnicity]}</Typography>;
};

export default EthnicityLabel;
