import { Box, Divider, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import React, { Fragment } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
const useStyles = makeStyles({
  divider: { width: "50%", marginTop: "12px", marginBottom: "12px" },
});

interface Props {
  header: React.ReactNode;
  subheader?: React.ReactNode;
}

const ErrorPage: React.FC<Props> = ({ header, subheader }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 100px)"
      textAlign="center"
    >
      <ErrorOutlineIcon style={{ width: "8rem", height: "8rem" }} />
      <Typography variant="h4" component="h4">
        {header}
      </Typography>
      {subheader && (
        <Fragment>
          <Divider className={classes.divider} /> {subheader}
        </Fragment>
      )}
    </Box>
  );
};

export default ErrorPage;
