import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button, ButtonProps } from "@material-ui/core";

interface Props extends ButtonProps {
  text: string;
}

const AddButton: React.FC<Props> = ({ text, ...props }) => {
  return (
    <Button {...props} startIcon={<AddIcon />}>
      {text}
    </Button>
  );
};

export default AddButton;
