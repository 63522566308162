import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import { providerDcfFilledBedDaysSectionName } from "./components/provider-detail-sections/ProviderDcfFilledBedDaysSection";
import ProviderDetailPage from "./pages/ProviderDetailPage";
import ProviderListPage from "./pages/ProviderListPage";

export const createProviderRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Provider}
    path={formatUrl(baseUrl, ":id")}
    component={ProviderDetailPage}
    exact
    key="detail-provider"
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Provider}
    path={formatUrl(baseUrl, ":id/filledBedDays")}
    render={(props) => (
      <ProviderDetailPage
        defaultTab={providerDcfFilledBedDaysSectionName}
        {...props}
      />
    )}
    exact
    key="detail-provider"
  />,

  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Provider}
    path={formatUrl(baseUrl, "")}
    component={ProviderListPage}
    key="list-provider"
  />,
];
