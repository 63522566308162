import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { Fragment } from "react";
import {
  DemographicsHeadOfHouseholdEnum,
  demographicsHeadOfHouseholdTextMap,
} from "../../enums/DemographicsHeadOfHouseholdEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  headOfHousehold?: DemographicsHeadOfHouseholdEnum;
  defaultValue?: string;
}

const DemographicsHeadOfHouseholdLabel: React.FC<Props> = ({
  headOfHousehold,
  defaultValue,
  ...props
}) => {
  if (headOfHousehold == null && defaultValue == null)
    return <Fragment></Fragment>;
  const value =
    headOfHousehold == null
      ? defaultValue
      : demographicsHeadOfHouseholdTextMap[headOfHousehold];

  return <Typography {...props}>{value}</Typography>;
};

export default DemographicsHeadOfHouseholdLabel;
