import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { selectUser } from "../../authentication/state/authenticationSelectors";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
// import { ContractTypeEnum } from "../../program-types/enums/ContractTypeEnum";
import { useGetPreviousReportingPeriod } from "../../reporting-periods/hooks/reportingPeriodHooks";
import { useCreateInvoice, useGetInvoiceReport } from "./invoicingHooks";

export const useInvoicePreviewPage = () => {
  //#region State
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [getInvoiceReport, invoiceReportResult, getInvoiceReportRequestState] =
    useGetInvoiceReport();

  const [createInvoice, , createInvoiceRequestState] = useCreateInvoice();

  const [
    getPreviousReportingPeriod,
    previousReportingPeriod,
    getPreviousReportingPeriodRequestState,
  ] = useGetPreviousReportingPeriod();

  const [isCurrentReportingPeriod, setIsCurrentReportingPeriod] =
    useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const user = useAppSelector(selectUser);
  //#endregion

  //#region Handlers

  const handleToggleIsSigned = () => {
    setIsSigned((isSigned) => !isSigned);
  };

  const handleCreateInvoice = async () => {
    const providerId = getProviderId();
    const contractId = query.contractId as string;
    const reportingPeriodId = query.reportingPeriodId as string;
    if (providerId && isSigned) {
      await createInvoice({ providerId, contractId, reportingPeriodId });
      dispatch(addSuccessNotification({ message: "Invoice Submitted" }));
      history.push("/invoices");
    }
  };

  //#endregion

  //#region Effects
  useEffect(() => {
    //const contractType = query.contractType as ContractTypeEnum;
    const contractId = query.contractId as string;
    const providerId = query.providerId as string;
    const reportingPeriodId = query.reportingPeriodId as string;

    getInvoiceReport({
      providerId,
      contractId,
      reportingPeriodId,
      format: "pdf",
    });
  }, [
    query.contractId,
    query.providerId,
    query.reportingPeriodId,
    getInvoiceReport,
  ]);

  useEffect(() => {
    getPreviousReportingPeriod("");
  }, [getPreviousReportingPeriod]);

  useEffect(() => {
    if (previousReportingPeriod) {
      const reportingPeriodId = query.reportingPeriodId as string;
      setIsCurrentReportingPeriod(
        previousReportingPeriod.id === reportingPeriodId
      );
    }
  }, [previousReportingPeriod, query.reportingPeriodId]);
  //#endregion

  return {
    state: {
      report: invoiceReportResult,
      getInvoiceReportRequestState,
      createInvoiceRequestState,
      isCurrentReportingPeriod,
      getPreviousReportingPeriodRequestState,
      isSigned,
      user,
    },
    handlers: {
      handleCreateInvoice,
      handleToggleIsSigned,
    },
  };
};
