import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createADBTTEligibilityQuestionnaireFormDto,
  ADBTTEligibilityQuestionnaireFormDto,
} from "../dtos/ADBTTEligibilityQuestionnaireFormDto";
import { useEffect, useState } from "react";

const schema = Yup.object().shape({
  hasYouthToParentAggressionIndicators: Yup.string().label("This").required(),
  hasDomesticBatteryChargeAgainstCaregiver: Yup.string()
    .label("This")
    .required(),
});

export const useADBTTEligibilityQuestionnaireForm = (
  onSubmit: (dto: ADBTTEligibilityQuestionnaireFormDto) => Promise<void>
) => {
  //#region State
  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
    watch,
  } = useForm<ADBTTEligibilityQuestionnaireFormDto>({
    defaultValues: createADBTTEligibilityQuestionnaireFormDto(),
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [shouldCompleteADBTT, setShouldCompleteADBTT] = useState(false);
  const {
    hasYouthToParentAggressionIndicators,
    hasDomesticBatteryChargeAgainstCaregiver,
  } = watch();
  //#endregion

  const clearForm = () => {
    reset(createADBTTEligibilityQuestionnaireFormDto());
  };

  const handleOnSubmit = handleSubmit(
    async (dto: ADBTTEligibilityQuestionnaireFormDto) => {
      await onSubmit(dto);
      clearForm();
    }
  );

  useEffect(() => {
    setShouldCompleteADBTT(
      hasDomesticBatteryChargeAgainstCaregiver === "true" &&
        hasYouthToParentAggressionIndicators === "true"
    );
  }, [
    hasYouthToParentAggressionIndicators,
    hasDomesticBatteryChargeAgainstCaregiver,
    setShouldCompleteADBTT,
  ]);

  return {
    state: {
      shouldCompleteADBTT,
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
