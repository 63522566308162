import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useScreeningsReportPage } from "../hooks/useScreeningsReportPage";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { ScreeningsReportParamsDto } from "../dtos/ScreeningsReportParamsDto";
import Loader from "../../../app/components/Loader";

interface Props {
  onSubmit(params: ScreeningsReportParamsDto): void;
}

const ScreeningsReportFilter: React.FC<Props> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: new ScreeningsReportParamsDto(),
    resolver: yupResolver(ScreeningsReportParamsDto.getSchema()),
    mode: "all",
  });

  return (
    <form
      onSubmit={handleSubmit((params) => {
        onSubmit(params);
      })}
    >
      <Grid container spacing={1}>
        <Grid item md={3} sm={4}>
          <ControlledInput
            control={control}
            name="startOn"
            label="Start Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </Grid>
        <Grid item md={3} sm={4}>
          <ControlledInput
            control={control}
            name="endOn"
            label="End Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </Grid>

        <Grid item md={6} sm={4}>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            height="100%"
          >
            <Box>
              <SpinnerButton
                showSpinner={false}
                disabled={!isValid}
                content="Download"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

const ScreeningsReportPage = () => {
  const { handleSearch, downloadReportRequestState } =
    useScreeningsReportPage();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <BackButton route="/reports" />
      </div>
      <Header title="Screenings Report" />
      <Box className="my-2">
        <Typography>
          Select a date range and "Download" for an Excel report of screenings
          and their planned actions.
        </Typography>
      </Box>

      <Paper
        style={{
          padding: "16px",
          height: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <ScreeningsReportFilter onSubmit={handleSearch} />

        <Box marginBottom="8px" marginTop="8px">
          <Divider />
        </Box>

        <Box textAlign="center">
          <Loader isLoading={downloadReportRequestState.isLoading} />
        </Box>
      </Paper>
    </div>
  );
};

export default ScreeningsReportPage;
