import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import { OutreachActivitySearchParamsDto } from "../dtos/OutreachActivityDto";
import { useOutreachActivityFilter } from "../hooks/useOutreachActivityFilter";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import { getTopicEnumOptions } from "../enums/TopicEnum";
import { getModalityEnumOptions } from "../enums/ModalityEnum";

interface Props {
  params: OutreachActivitySearchParamsDto;
  anchorEl: HTMLElement | null;
  providers: ProviderDto[];
  onClose(): void;
  onSearch(dto: OutreachActivitySearchParamsDto): void;
}

const OutreachActivityFilter: React.FC<Props> = ({
  params,
  anchorEl,
  providers,
  onClose,
  onSearch,
}) => {
  const [sortedProviders, setSortedProviders] = useState<ProviderDto[]>([]);
  //#region State
  const {
    form: { control, clearAllFilters },
    handlers: { handleSubmit },
  } = useOutreachActivityFilter(onSearch, onClose, params);
  //#endregion

  useEffect(() => {
    setSortedProviders(providers.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }, [providers, setSortedProviders]);
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">Outreach Activity Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            {/* Title */}
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="title"
                label="Title"
                fullWidth
              />
            </Grid>

            {/* Est. People Reached */}
            <Grid item md={12} xs={12}>
              <ControlledWholeNumberInput
                control={control}
                name="peopleReached"
                label="Min # of People Reached"
                fullWidth
              />
            </Grid>

            {/* Date Range */}
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="dateRangeStart"
                label="Start Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="dateRangeEnd"
                label="End Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* Topic */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="topic"
                label="Topic"
                options={getTopicEnumOptions("All")}
                fullWidth
              />
            </Grid>

            {/* Modality */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="modality"
                label="Modality"
                options={getModalityEnumOptions("All")}
                fullWidth
              />
            </Grid>

            {/* Provider Name */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="providerId"
                  label="Provider"
                  fullWidth
                  options={providerToOptionDtos(sortedProviders, [], "All")}
                />
              </Grid>
            </FnAccessWrapper>

            {/* Zip Code */}
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="zipCode"
                label="Zip Code"
                fullWidth
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default OutreachActivityFilter;
