import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { UserDetailDto } from "../dtos/UserDetailDto";
import { UserFormDto } from "../dtos/UserFormDto";
import { useUserForm } from "../hooks/useUserForm";
import UserFormFields from "./UserFormFields";

export const userModalId = "user-form-modal";

interface Props {
  user?: UserDetailDto;
  requestState: RequestStateDto;
  onSubmit(dto: UserFormDto): Promise<void>;
}

const UserFormDialog: React.FC<Props> = ({ user, onSubmit, requestState }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    state: { roles, getRolesRequestState, showRoles },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit },
  } = useUserForm(onSubmit, user);

  const { error: getRolesError, isLoading: rolesAreLoading } =
    getRolesRequestState;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === userModalId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {user ? "Edit" : "Create"} User
          <ErrorText error={error?.message} />
          <ErrorText error={getRolesError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <UserFormFields
              control={control}
              disabled={isLoading}
              editMode={user !== undefined}
              roles={roles || []}
              showRoles={showRoles}
              rolesAreLoading={rolesAreLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserFormDialog;
