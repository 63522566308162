export interface SendResetPasswordFormDto {
  email: string;
}

export const createSendResetPasswordFormDto = (
  email: string = ""
): SendResetPasswordFormDto => {
  return {
    email,
  };
};
