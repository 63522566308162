import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { InvoiceDto } from "../dtos/InvoiceDto";
import { useInvoiceDocumentDialog } from "../hooks/useInvoiceDocumentDialog";
export const invoiceDocumentModalId = "invoice-document-modal";

interface Props {
  invoice?: InvoiceDto;
}

const InvoiceDocumentDialog: React.FC<Props> = ({ invoice }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { state } = useInvoiceDocumentDialog(invoice);
  const { document, getInvoiceDocumentRequestState } = state;

  //#endregion

  //#region
  const handleCloseModal = async () => {
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === invoiceDocumentModalId} fullWidth maxWidth="xl">
      <ErrorText error={getInvoiceDocumentRequestState.error?.message} />
      <DialogTitle>Invoice {invoice?.invoiceNumber}</DialogTitle>
      <DialogContent style={{ height: "100vh", overflow: "hidden" }}>
        {document && (
          <iframe
            src={URL.createObjectURL(document)}
            title="invoice-document"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        )}
      </DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Close"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
        />
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDocumentDialog;
