import { Typography } from "@material-ui/core";
import React from "react";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { ScreeningDetailDto } from "../dtos/ScreeningDetailDto";
import ErrorAlert from "../../../app/components/ErrorAlert";

interface Props {
  requestState: RequestStateDto;
  onConfirm(dto: ScreeningDetailDto): Promise<void>;
  screening: ScreeningDetailDto
}

export const confirmDeleteScreeningDialogId = "delete-screening-dialog";
const ConfirmDeleteScreeningDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  screening
}) => {
  const {error, isLoading} = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={ async () => {
        if(screening != null)
          await onConfirm(screening)
      }}
      modalId={confirmDeleteScreeningDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Screening"
      confirmButtonStyle={{
        color: ColorsEnum.Red,
      }}
      confirmButtonDisabled={false}
      maxWidth="md"
    >
      <ErrorAlert error={error?.message} />
      {screening.referral &&
        <Typography style={{ marginBottom: "8px" }}>
          This screening was generated from Referral {screening.referral.referralNumber} dated {new Date(screening.referral.createdAt).toLocaleDateString()}. 
          This Referral's status will be changed to "Pending".
        </Typography>
      }
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to delete Screening {screening.referenceNumber}?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteScreeningDialog;
