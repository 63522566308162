import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  programTypeSummariesToOptions,
  ProgramTypeSummaryDto,
} from "../../program-types/dtos/ProgramTypeSummaryDto";
import { SnapGroupCycleFormDto } from "../dtos/SnapGroupCycleFormDto";
import { getSnapCycleGenderOptions } from "../enums/SnapGroupCycleGenderEnum";

interface Props {
  control: Control<SnapGroupCycleFormDto>;
  isDisabled: boolean;
  programTypes: ProgramTypeSummaryDto[];
  programTypesAreLoading: boolean;
  isEditing: boolean;
}

const SnapCycleFormFields: React.FC<Props> = ({
  control,
  isDisabled,
  programTypes,
  programTypesAreLoading,
  isEditing,
}) => {
  if (programTypesAreLoading) return <></>;
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="startedOn"
          label="Group Start Date*"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          fullWidth
          disabled={isDisabled || isEditing}
          isLoading={programTypesAreLoading}
          name="programTypeId"
          options={programTypeSummariesToOptions(programTypes)}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          fullWidth
          disabled={isDisabled || isEditing}
          name="gender"
          options={isEditing
            ? getSnapCycleGenderOptions()
            : getSnapCycleGenderOptions().filter(o => o.value !== 'NotSpecified')
          }
        />
      </Grid>
    </Fragment>
  );
};

export default SnapCycleFormFields;
