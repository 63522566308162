import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { Fragment } from "react";
import {
  DemographicsFamilyStructureEnum,
  DemographicsFamilyStructureEnumTextMap,
} from "../../enums/DemographicsFamilyStructureEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  familyStructure?: DemographicsFamilyStructureEnum;
  defaultValue?: string;
}

const DemographicsFamilyStructureLabel: React.FC<Props> = ({
  familyStructure,
  defaultValue,
  ...props
}) => {
  if (familyStructure == null && defaultValue == null)
    return <Fragment></Fragment>;
  const value =
    familyStructure == null
      ? defaultValue
      : DemographicsFamilyStructureEnumTextMap[familyStructure];

  return <Typography {...props}>{value}</Typography>;
};

export default DemographicsFamilyStructureLabel;
