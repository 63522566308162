import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useLocation } from "react-router-dom";
import { ColorsEnum } from "../../enums/ColorsEnum";

interface Props extends ButtonProps {
  route: string;
  text?: string;
  hideIfNoPreviousLocation?: boolean;
}

const BackButton: React.FC<Props> = ({
  route,
  style,
  text = "Back",
  hideIfNoPreviousLocation,
  ...props
}) => {
  //#region State
  const history = useHistory();
  const location = useLocation<{ prevLocation: string; text: string }>();
  //#endregion

  //#region Handlers
  const handleClick = () => {
    history.push(
      location.state?.prevLocation ? location.state.prevLocation : route
    );
  };
  //#endregion

  if (
    hideIfNoPreviousLocation &&
    (location.state?.prevLocation == null || location.state.prevLocation === "")
  )
    return <></>;

  return (
    <Button
      startIcon={<ArrowBackIcon />}
      onClick={handleClick}
      {...props}
      style={{ color: ColorsEnum.Blue, ...style }}
    >
      {location.state?.text ? location.state.text : text}
    </Button>
  );
};

export default BackButton;
