import React, { useEffect, useState } from "react";
import { dateToFormString } from "../../../app/helpers";
import { CaseListDto } from "../../cases/dtos/CaseListDto";
import { useGetCases } from "../../cases/hooks/caseHooks";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
import { AddCaseToSnapGroupFormDto } from "../dtos/AddCaseToSnapGroupFormDto";
import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import { SnapGroupDto } from "../dtos/SnapGroupDto";
import { SnapGroupSummaryDto } from "../dtos/SnapGroupSummaryDto";
import { useGetSnapGroup } from "./snapHooks";

export const useAddCasesToGroupForm = (
  onSubmit: (dto: AddCaseToSnapGroupFormDto) => Promise<any>,
  snapCycle: SnapGroupCycleDto,
  group?: SnapGroupDto
) => {
  //#region State
  const [casesToAdd, setCasesToAdd] = useState<CaseListDto[]>([]);
  const [eligibleCases, setEligibleCases] = useState<CaseListDto[]>([]);
  const [isValid, setIsValid] = useState(false);
  const [getCases, , getCasesRequestState] = useGetCases();
  const [previousGroup, setPreviousGroup] = useState<SnapGroupSummaryDto>();
  const [
    getPreviousSnapGroup,
    ,
    getPreviousSnapGroupRequestState,
    clearGetPreviousSnapGroupErrors,
  ] = useGetSnapGroup();
  //#endregion

  const handleRemoveCase = (caseDto: CaseListDto) => {
    setCasesToAdd((state) => state.filter((x) => x.id !== caseDto.id));
    setEligibleCases((state) => [...state, caseDto]);
  };

  const handleAddCase = (caseDto: CaseListDto) => {
    setCasesToAdd((state) => [...state, caseDto]);
    setEligibleCases((state) => state.filter((x) => x.id !== caseDto.id));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const caseIds = casesToAdd.map((x) => x.id);

    await onSubmit({ caseIds });
    clearGetPreviousSnapGroupErrors();
  };

  const handlePrepopulateYouth = async () => {
    if (previousGroup == null) return;

    const result = await getPreviousSnapGroup(previousGroup.id);

    if (result == null) return;

    const newCasesToAdd: CaseListDto[] = [...casesToAdd];
    let newEligibleCases: CaseListDto[] = [...eligibleCases];

    for (const caseDto of result.cases) {
      const caseToAdd = eligibleCases.find((x) => x.id === caseDto.caseId);

      if (caseToAdd == null) continue;

      newEligibleCases = newEligibleCases.filter((x) => x.id !== caseToAdd.id);
      newCasesToAdd.push(caseToAdd);
    }
    setEligibleCases(newEligibleCases);
    setCasesToAdd(newCasesToAdd);
  };

  const clearForm = () => {
    setEligibleCases((state) => [...state, ...casesToAdd]);
    setCasesToAdd([]);
    clearGetPreviousSnapGroupErrors();
  };

  //#region Effects
  useEffect(() => {
    setIsValid(casesToAdd.length > 0);
  }, [casesToAdd, setIsValid]);

  useEffect(() => {
    const load = async () => {
      if (group) {
        const result = await getCases({
          activeOnly: false,
          includeDeleted: false,
          programTypeId: snapCycle.programType.id,
          providerId: snapCycle.provider.id,
          caseActiveDateStart: dateToFormString(new Date(group.groupHeldOn)),
          caseActiveDateEnd: dateToFormString(new Date(group.groupHeldOn)),
        });
        if (result) {
          const currentCaseIds = group.cases
            .filter(x => x.deletedAt == null)
            .map((x) => x.caseId);
            
          const eligibleCases = result.items.filter(
            (x) =>
              !currentCaseIds.includes(x.id) &&
              x.program.fundingSource ===
                ProgramFundingSourceEnum.FloridaNetwork
          );

          setEligibleCases(eligibleCases);
          setCasesToAdd([]);
        }
      }
    };

    load();
  }, [getCases, setEligibleCases, setCasesToAdd, snapCycle, group]);

  useEffect(() => {
    if (group) {
      const previousGroups = snapCycle.groups
        .filter(
          (x) =>
            !x.isMakeupGroup &&
            x.id !== group.id &&
            new Date(x.groupHeldOn) < new Date(group.groupHeldOn)
        )
        .sort((a, b) =>
          new Date(a.groupHeldOn) > new Date(b.groupHeldOn) ? -1 : 1
        );

      if (previousGroups.length > 0) {
        setPreviousGroup(previousGroups[0]);
      } else {
        setPreviousGroup(undefined);
      }
    }
  }, [setPreviousGroup, snapCycle, group]);
  //#endregion

  return {
    state: {
      casesToAdd,
      eligibleCases,
      getCasesRequestState,
      previousGroup,
      getPreviousSnapGroupRequestState,
    },
    handlers: {
      handleRemoveCase,
      handleAddCase,
      handleSubmit,
      handlePrepopulateYouth,
    },
    form: {
      isValid,
      clearForm,
    },
  };
};
