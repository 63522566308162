import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { CaseSearchParamsDto } from "./CaseSearchParamsDto";

export enum CaseDateRangeTypeEnum {
  Intake = "Intake",
  Discharge = "Discharge",
  CaseActive = "CaseActive",
}

export class CaseFilterFormDto {
  caseNumber: string;
  providerId: string;
  programTypeId: string;
  programId: string;
  locationId: string;
  //caseManagerId: string;
  intakeStaffId: string;
  primaryStaffId: string;
  fundingSource: string;
  youthFirstName: string;
  youthLastName: string;
  activeOnly: boolean;
  includeDeleted: boolean;

  dateRangeType: CaseDateRangeTypeEnum;
  dateRangeStart: string;
  dateRangeEnd: string;

  constructor(dto?: CaseSearchParamsDto) {
    const currentProviderId = getProviderId();

    this.caseNumber = dto?.caseNumber || "";
    this.providerId = dto?.providerId || "";
    if (
      currentProviderId != null &&
      currentProviderId !== "" &&
      currentProviderId !== fnyfsProviderId
    )
      this.providerId = currentProviderId;
    this.programTypeId = dto?.programTypeId || "";
    this.programId = dto?.programId || "";
    //this.caseManagerId = dto?.caseManagerId || "";
    this.intakeStaffId =dto?.intakeStaffId || "";
    this.primaryStaffId = dto?.primaryStaffId || "";
    this.youthLastName = dto?.youthLastName || "";
    this.youthFirstName = dto?.youthFirstName || "";
    this.activeOnly = dto?.activeOnly || false;
    this.includeDeleted = dto?.includeDeleted || false;
    this.locationId = dto?.locationId || "";
    this.fundingSource = dto?.fundingSource || "";

    if (dto?.intakeDateStart !== "" || dto?.intakeDateEnd !== "") {
      this.dateRangeStart = dto?.intakeDateStart || "";
      this.dateRangeEnd = dto?.intakeDateEnd || "";
      this.dateRangeType = CaseDateRangeTypeEnum.Intake;
    } else if (dto?.dischargeDateStart !== "" || dto?.dischargeDateEnd !== "") {
      this.dateRangeStart = dto?.dischargeDateStart || "";
      this.dateRangeEnd = dto?.dischargeDateEnd || "";
      this.dateRangeType = CaseDateRangeTypeEnum.Discharge;
    } else {
      this.dateRangeStart = dto?.caseActiveDateStart || "";
      this.dateRangeEnd = dto?.caseActiveDateEnd || "";
      this.dateRangeType = CaseDateRangeTypeEnum.CaseActive;
    }
  }
}
