import { Box, FormControlLabel, Switch } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../../app/components/buttons/AddButton";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { ScreeningDto } from "../../../screenings/dtos/ScreeningDto";
import WaitlistFormDialog from "../../../waitlists/components/WaitlistFormDialog";
import { WaitlistFormDto } from "../../../waitlists/dtos/WaitlistFormDto";
import { YouthDto } from "../../dto/YouthDto";
import YouthScreeningList from "../youth-screening/YouthScreeningList";
import FnAccessWrapper from "../../../../app/components/access-wrappers/FnAccessWrapper";
import ProviderAccessWrapper from "../../../../app/components/access-wrappers/ProviderAccessWrapper";
import { getProviderId } from "../../../authentication/state/authenticationActions";

interface Props {
  youth: YouthDto;
  isLoading: boolean;
  createScreeningWaitlistRequestState: RequestStateDto;
  onCreateClick(): void;
  onCreateScreeningWaitlistClick(): void;
  onCreateScreeningWaitlist(id: string, dto: WaitlistFormDto): Promise<any>;
}

const YouthScreeningsSection: React.FC<Props> = ({
  youth,
  isLoading,
  createScreeningWaitlistRequestState,
  onCreateClick,
  onCreateScreeningWaitlist,
  onCreateScreeningWaitlistClick,
}) => {
  //#region State
  const [selectedScreening, setSelectedScreening] = useState<ScreeningDto>();
  const [showMyProviderOnly, setShowMyProviderOnly] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [screeningsToDisplay, setScreeningsToDisplay] = useState(youth?.screenings);
  //#endregion

  //#region Handlers
  const handleCreateScreeningClicked = (screening: ScreeningDto) => {
    setSelectedScreening(screening);
    onCreateScreeningWaitlistClick();
  };
  const handleCreateScreeningWaitlist = async (dto: WaitlistFormDto) => {
    if (selectedScreening)
      await onCreateScreeningWaitlist(selectedScreening.id, dto);
  };

  useEffect(() => {
      const providerId = getProviderId();
      const screeningsToDisplay = youth?.screenings
        .filter(x => !showMyProviderOnly || providerId === x.provider.id)
        .filter((x) => showDeleted || !x.deletedAt) || [] 
      
      setScreeningsToDisplay(screeningsToDisplay)
      }, [youth, showDeleted, showMyProviderOnly]);

  
  //#endregion

  return (
    <Fragment>
      <Box display="flex">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Screening}>
          <AddButton
            text="Create Screening"
            variant="contained"
            color="primary"
            onClick={onCreateClick}
          />
          <WaitlistFormDialog
            onSubmit={handleCreateScreeningWaitlist}
            requestState={createScreeningWaitlistRequestState}
          />
        </WriteAccessLevelWrapper>

        <Box marginLeft="auto">
          <Fragment>
            <ProviderAccessWrapper>
              <FormControlLabel
                control={
                  <Switch
                    checked={showMyProviderOnly}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowMyProviderOnly(e.currentTarget.checked)}
                    disabled={isLoading}
                    color="primary"
                  />
                }
                label="Screenings for My Provider Only"
              />
            </ProviderAccessWrapper>
            <FnAccessWrapper>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDeleted}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowDeleted(e.currentTarget.checked)}
                    disabled={isLoading}
                    color="primary"
                  />
                }
                label="Show Deleted"
              />
            </FnAccessWrapper>
          </Fragment> 
        </Box>
    </Box>
    
    <Box marginTop="12px">
      <YouthScreeningList
        screenings={screeningsToDisplay}
        isLoading={isLoading}
        onCreateScreeningWaitlistClick={handleCreateScreeningClicked}
      />
    </Box>
    </Fragment>
  );
};

export default YouthScreeningsSection;
