import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { SnapInSchoolsCycleDto } from "../dtos/SnapInSchoolsCycleDto";

interface Props {
  requestState: RequestStateDto;
  snapCycle?: SnapInSchoolsCycleDto;
  onConfirm(): Promise<void>;
}

export const confirmDeleteFidelityAdherenceDialogId =
  "delete-fidelity-adherence-dialog";
const ConfrimDeleteSnapInSchoolsFidelityAdherenceChecklistDialog: React.FC<
  Props
> = ({ onConfirm, requestState, snapCycle }) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteFidelityAdherenceDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Fidelity Adherence Checklist"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to delete Fidelity Adherence Checklist for SISC
        Cycle {snapCycle?.referenceNumber}?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfrimDeleteSnapInSchoolsFidelityAdherenceChecklistDialog;
