import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import { Box, Divider, Paper, Typography } from "@material-ui/core";
import { NirvanaAveragesOrImpactReportFilter } from "../components/NirvanaAveragesOrImpactReportFilter";
import { useNirvanaAveragesOrImpactReportPage } from "../hooks/useNirvanaAveragesOrImpactReportPage";
import PdfContainer from "../../../app/components/PdfContainer";
import ErrorText from "../../../app/components/ErrorText";

export enum NirvanaReportType {
  AssessmentAverages = "AssessmentAverages",
  Impact = "Impact",
}

interface Props {
  reportType: NirvanaReportType;
}

export const NirvanaAssessmentAveragesOrImpactReportPage: React.FC<Props> = ({
  reportType,
}) => {
  const { params, report, reportRequestState, handleSearch } =
    useNirvanaAveragesOrImpactReportPage(reportType);

  return (
    <div>
      <BackButton route="/reports" style={{ marginTop: "24px" }} />
      {reportType === NirvanaReportType.AssessmentAverages && (
        <Header title="NIRVANA Assessment Averages Report" />
      )}
      {reportType === NirvanaReportType.Impact && (
        <Header title="NIRVANA Impact Report" />
      )}
      <Box className="my-2">
        <Typography>
          {reportType === NirvanaReportType.AssessmentAverages &&
            'Select criteria and "View" for a PDF of average NIRVANA scores.'}
          {reportType === NirvanaReportType.Impact &&
            'Select criteria and "View" for a PDF of average score changes between Initial and subsequent Assessments.'}
        </Typography>
      </Box>
      <ErrorText error={reportRequestState.error?.message} />
      <Paper
        style={{
          padding: "16px",
          height: "72vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <NirvanaAveragesOrImpactReportFilter
            onSearch={handleSearch}
            params={params}
            isLoading={reportRequestState.isLoading}
            reportType={reportType}
          />
        </Box>
        <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
        <PdfContainer
          document={report}
          isLoading={reportRequestState.isLoading}
        />
      </Paper>
    </div>
  );
};
