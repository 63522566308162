import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import {
  DjjEntryDetailDto,
  DjjEntryListDto,
  DjjEntrySearchParamsDto,
  SetDjjEntryNotesFormDto,
  SetDjjEntryStatusFormDto,
} from "./DjjEntryDto";

export const getDjjEntriesRequest = (params: DjjEntrySearchParamsDto) =>
  coreAgent.getRequest<PaginationResultDto<DjjEntryListDto>>(
    `djjEntries?${DjjEntrySearchParamsDto.toQueryString(params)}`
  );

export const setDjjEntryStatusRequest = ({
  referenceType,
  referenceId,
  dto,
}: {
  referenceType: string;
  referenceId: string;
  dto: SetDjjEntryStatusFormDto;
}) =>
  coreAgent.putRequest(
    `djjEntries/${referenceType}/${referenceId}/status`,
    dto
  );

export const getDjjEntryNotesRequest = ({
  referenceType,
  referenceId,
}: {
  referenceType: string;
  referenceId: string;
}) =>
  coreAgent.getRequest<string>(
    `djjEntries/${referenceType}/${referenceId}/notes`
  );

export const setDjjEntryNotesRequest = ({
  referenceType,
  referenceId,
  dto,
}: {
  referenceType: string;
  referenceId: string;
  dto: SetDjjEntryNotesFormDto;
}) =>
  coreAgent.putRequest<string>(
    `djjEntries/${referenceType}/${referenceId}/notes`,
    dto
  );

export const getDjjEntryDetailsRequest = ({
  referenceId,
  referenceType,
}: {
  referenceType: string;
  referenceId: string;
}) =>
  coreAgent.getRequest<DjjEntryDetailDto>(
    `djjEntries/${referenceType}/${referenceId}`
  );
