import { DischargeDto } from "../../discharges/dtos/DischargeDto";
import { ADBTTEligibilityQuestionnaireDto } from "../../adbtt-eligibility-questionnaires/dtos/ADBTTEligibilityQuestionnaireDto";
import { IntakeDetailDto } from "../../intakes/dtos/IntakeDetailDto";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { ScreeningDto } from "../../screenings/dtos/ScreeningDto";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import { YouthDto } from "../../youths/dto/YouthDto";
import { ServiceStatusEnum } from "../enums/ServiceStatusEnum";
import { CaseHoldDto } from "../../case-hold/dtos/CaseHoldDto";
import { CaseSnapGroupDto } from "../../snap/dtos/CaseSnapGroupDto";
import { CaseContactDto } from "../../case-contacts/dtos/CaseContactDto";
import { DemographicDto } from "../../demographics/dtos/DemographicDto";
import { FollowUpDto } from "../../follow-ups/dtos/FollowUpDto";
import { HumanTraffickingScreeningToolDto } from "../../human-trafficking-screening-tool/HumanTrafickingScreeningToolDto";
import { SuicideScreeningDto } from "../../suicide-screenings/dtos/SuicideScreeningDto";
import { SatisfactionSurveyDto } from "../../satisfaction-survey/SatisfactionSurveyDto";
import { BakerActDto } from "../../baker-act/dto/BakerActDto";
import { AttachmentDto } from "../../attachments/dtos/AttachmentDto";

export interface CaseDetailDto {
  id: string;
  caseNumber: string;
  serviceStatus: ServiceStatusEnum;
  youth: YouthDto;
  provider: ProviderDto;
  program: ProgramDto;
  location: LocationDto;
  intakeInfo: IntakeDetailDto;
  nirvanaAssessmentInitiated: boolean;
  dischargeInfo: DischargeDto;
  primaryStaff: StaffMemberDto[];
  screening?: ScreeningDto;
  adbttEligibilityQuestionnaires: ADBTTEligibilityQuestionnaireDto[];
  caseHolds: CaseHoldDto[];
  deletedAt?: Date;
  deleteReason?: string;
  isCourtOrdered: boolean;
  courtOrderAttachment: AttachmentDto;
  isLegacy: boolean;
  snapGroups?: CaseSnapGroupDto[];
  caseContacts?: CaseContactDto[];
  demographic?: DemographicDto;
  nonBillableDays?: Date[];
  followUps: FollowUpDto[];
  humanTraffickingScreeningTools: HumanTraffickingScreeningToolDto[];
  suicideScreenings: SuicideScreeningDto[];
  satisfactionSurvey?: SatisfactionSurveyDto;
  bakerActs: BakerActDto[];
}

export const calculateWasCaseDischargedDuringHold = (dto: CaseDetailDto) => {
  if (dto.caseHolds.length === 0 || dto.dischargeInfo === undefined)
    return false;

  const dischargeDate = new Date(dto.dischargeInfo.dischargedAt);

  const caseHold = dto.caseHolds.find(
    (x) => new Date(x.endAt!).valueOf() === dischargeDate.valueOf()
  );

  return caseHold !== undefined;
};
