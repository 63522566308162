import * as Yup from "yup";
import { useCallback, useEffect } from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getRoleDisplayText, ServicePlanParticipantRolesEnum} from "../enums/ServicePlanParticipantRolesEnum";
import {useRequest} from "../../app/hooks/useRequest";
import {addServicePlanParticipantRequest, updateServicePlanParticipantRequest} from "../ServicePlanRequests";
import {SelectOption} from "../../app/components/inputs/ControlledSelect";
import {useServicePlanPageContext} from "../ServicePlanPageContext";
import { createDefaultServicePlanParticipant, ServicePlanParticipantDto } from "../dto/ServicePlanDto";

const schema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  role: Yup.string().required().label("Role"),
});

export const useServicePlanParticipantForm = (participantDto?: ServicePlanParticipantDto) => {

  const { servicePlan, servicePlanUpdated  } = useServicePlanPageContext();

  const roleOptions: SelectOption[] = [
    {label: "Select Participant Role", value: ""},
    {label: getRoleDisplayText(ServicePlanParticipantRolesEnum.Parent), value: ServicePlanParticipantRolesEnum.Parent},
    {label: getRoleDisplayText(ServicePlanParticipantRolesEnum.Guardian), value: ServicePlanParticipantRolesEnum.Guardian},
    {label: getRoleDisplayText(ServicePlanParticipantRolesEnum.ProviderStaff), value: ServicePlanParticipantRolesEnum.ProviderStaff},
    {label: getRoleDisplayText(ServicePlanParticipantRolesEnum.Supervisor), value: ServicePlanParticipantRolesEnum.Supervisor},
    {label: getRoleDisplayText(ServicePlanParticipantRolesEnum.Other), value: ServicePlanParticipantRolesEnum.Other},
  ];

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<ServicePlanParticipantDto>({
    defaultValues: createDefaultServicePlanParticipant(participantDto),
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = useCallback(() => {
    reset(createDefaultServicePlanParticipant(participantDto));
  }, [reset, participantDto]);

  const [addParticipant, , addParticipantRequestState] = useRequest<ServicePlanParticipantDto, void>(
    (dto) => addServicePlanParticipantRequest(servicePlan.id, dto)
  );

  const [updateParticipant, , updateParticipantRequestState] = useRequest<ServicePlanParticipantDto, void>(
    (dto) => updateServicePlanParticipantRequest(servicePlan.id, dto)
  );

  useEffect(() => {
    reset(createDefaultServicePlanParticipant(participantDto));
  }, [participantDto, reset]);

  const handleOnCreateSubmit = handleSubmit(
    async (formData: ServicePlanParticipantDto) => {
      await addParticipant(formData);
      clearForm();
      await servicePlanUpdated();
    }
  );

  const handleOnUpdateSubmit = handleSubmit(
    async (formData: ServicePlanParticipantDto) => {
      await updateParticipant(formData);
      clearForm();
      await servicePlanUpdated();
    }
  )

  return {
    state: {
      roleOptions,
      addParticipantRequestState,
      updateParticipantRequestState
    },
    form: { isValid, control, clearForm },
    handlers: {
      handleCreateSubmit: handleOnCreateSubmit,
      handleUpdateSubmit: handleOnUpdateSubmit,
    },
  };
}
