import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form/dist/types/form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  NetmisRoleDto,
  netmisRolesToOptionDtos,
} from "../../roles/dtos/NetmisRoleDto";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ControlledPhoneNumberInput from "../../../app/components/inputs/ControlledPhoneNumberInput";
import { useWatch } from "react-hook-form";

interface Props {
  control: Control<any>;
  disabled: boolean;
  editMode?: boolean;
  roles: NetmisRoleDto[];
  showRoles: boolean;
  rolesAreLoading: boolean;
}

const UserFormFields: React.FC<Props> = ({
  control,
  disabled,
  editMode,
  roles,
  showRoles,
  rolesAreLoading,
}) => {
  const { isFloridaNetworkUser } = useWatch({ control });

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="firstName"
          label="First Name*"
          control={control}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="lastName"
          label="Last Name*"
          control={control}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="email"
          label="Email*"
          control={control}
          disabled={disabled || editMode}
        />
      </Grid>
      {!editMode && (
        <Grid item md={12} xs={12}>
          <ControlledInput
            name="confirmEmail"
            label="Confirm Email*"
            control={control}
            disabled={disabled}
            onPaste={(e: any) => e.preventDefault()}
          />
        </Grid>
      )}
      <Grid item md={12} xs={12}>
        <ControlledPhoneNumberInput
          name="phoneNumber"
          label="Phone"
          control={control}
          disabled={disabled}
        />
      </Grid>
      {showRoles && (
        <Grid item md={12} xs={12}>
          <ControlledSelect
            control={control}
            name="roleId"
            label="Role*"
            options={netmisRolesToOptionDtos(roles)}
            disabled={disabled}
            fullWidth
            isLoading={rolesAreLoading}
          />
        </Grid>
      )}
      <FnAccessWrapper>
        <Grid item md={12} xs={12}>
          <ControlledCheckbox
            name="isFloridaNetworkUser"
            label="Florida Network Main Office Staff"
            control={control}
            disabled={disabled}
            color="primary"
            value={isFloridaNetworkUser}
          />
        </Grid>
      </FnAccessWrapper>
    </Fragment>
  );
};

export default UserFormFields;
