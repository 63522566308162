import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { AlertDismissalSearchParamsDto } from "../../../alerts/dtos/AlertDismissalDto";
import { ProviderDto } from "../../../provider/dtos/ProviderDto";
import { ProgramTypeDto } from "../../../program-types/dtos/ProgramTypeDto";
import FilterList from "../../../../app/components/FilterList";
import { alertTypeTextMap } from "../../../alerts/enums/AlertTypeEnum";

interface Props {
  params: AlertDismissalSearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  programTypes: ProgramTypeDto[];
  onSearch(dto: AlertDismissalSearchParamsDto): void;
}

const formatDate = (date: string) => {
  return DateTime.fromJSDate(new Date(`${date}T00:00`)).toFormat("MM-dd-yyyy");
};

const CaseAlertDismissalFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  programTypes,
  onSearch,
}) => {
  const handleClearParams = () => {
    const newParams = new AlertDismissalSearchParamsDto();
    newParams.pageSize = params.pageSize;
    return newParams;
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "startOn":
          return `Start Date: ${formatDate(value)}`;
        case "endOn":
          return `End End: ${formatDate(value)}`;
        case "programTypeId":
          return `Program Type: ${
            programTypes.find((x) => x.id === value)?.name
          }`;
        case "providerId":
          return `Provider: ${providers.find((x) => x.id === value)?.name}`;
        case "type":
          return `Alert Type: ${alertTypeTextMap[value]}`;
      }
      return "";
    },
    [providers, programTypes]
  );

  const ordering = ["providerId", "programTypeId", "type", "startOn", "endOn"];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default CaseAlertDismissalFilterList;
