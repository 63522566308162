import React from "react";
import {
  DashboardMetricsDto,
  DateRangeType,
} from "../dtos/MainDashboardMetricsDto";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import DateLabel from "../../../app/components/labels/DateLabel";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import FilterButton from "../../../app/components/buttons/FilterButton";
import { InfoPopover } from "../../../app/components/InfoPopover";

export const DashboardFiltersPanel: React.FC<{
  data?: DashboardMetricsDto;
  isLoading: boolean;
  onChangeFilterClicked(event: React.MouseEvent<HTMLElement>): void;
}> = ({ data, isLoading, onChangeFilterClicked }) => {
  return (
    <Box display={"flex"} alignItems={"center"} style={{ marginRight: "8px" }}>
      <>
        <Box display="flex" alignItems="center">
          {!isLoading && (
            <InfoPopover>
              {!data?.dateRange ? (
                <></>
              ) : (
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography>
                    <>Date Range: </>
                    <DateLabel date={data.dateRange.startDate} />
                    &nbsp;-&nbsp;
                    <DateLabel date={data.dateRange.endDate} />
                  </Typography>
                  <Typography variant={"subtitle1"}>
                    <>
                      {"Last Refreshed: "}
                      <DateTimeLabel date={data.dataGeneratedAt} />
                    </>
                  </Typography>
                </Box>
              )}
            </InfoPopover>
          )}
          {!isLoading && (
            <Typography style={{ padding: 8, whiteSpace: "nowrap" }}>
              {data?.dateRange?.type === DateRangeType.Custom && (
                <>
                  <>Date Range: </>
                  <DateLabel date={data.dateRange.startDate} />
                  <>&nbsp;-&nbsp;</>
                  <DateLabel date={data.dateRange.endDate} />
                </>
              )}
              {data?.dateRange.type === DateRangeType.FiscalYearToDate && (
                <>Fiscal Year to Date</>
              )}
              {data?.dateRange.type === DateRangeType.YearToDate && (
                <>Calendar Year to Date</>
              )}
            </Typography>
          )}
        </Box>

        {isLoading && (
          <>
            <CircularProgress variant={"indeterminate"} size={12} />
            {" Refreshing..."}
          </>
        )}
      </>
      <FilterButton disabled={isLoading} onClick={onChangeFilterClicked} />
    </Box>
  );
};
