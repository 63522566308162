import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { TerminateProgramLocationFormDto } from "../dtos/TerminateProgramLocationFormDto";
import { useTerminateProgramLocationForm } from "../hooks/useTerminateProgramLocationForm";

export const terminateProgramLocationModalId =
  "terminate-program-location-form-modal";

interface Props {
  terminatedOn?: Date;
  onSubmit: (dto: TerminateProgramLocationFormDto) => Promise<any>;
  requestState: RequestStateDto;
}

const TerminateProgramLocationFormDialog: React.FC<Props> = ({
  terminatedOn,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useTerminateProgramLocationForm(
    onSubmit,
    terminatedOn
  );
  const { clearForm, control, isValid } = form;
  const { handleSubmit } = handlers;
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  //#endregion

  return (
    <Dialog
      open={modalId === terminateProgramLocationModalId}
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Terminate Program
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="terminatedOn"
                type="date"
                label="End Date"
                disabled={isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TerminateProgramLocationFormDialog;
