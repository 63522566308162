import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import * as Yup from "yup";

export const staffMemberSchema = Yup.object().shape({
  firstName: Yup.string().label("First Name").required().max(100),
  lastName: Yup.string().label("Last Name").required().max(100),
});

interface Props {
  control: Control<any>;
  disabled: boolean;
}

const StaffMemberFormFields: React.FC<Props> = ({ control, disabled }) => {
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <ControlledInput
            control={control}
            name="firstName"
            label="First Name"
            disabled={disabled}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <ControlledInput
            control={control}
            name="lastName"
            label="Last Name"
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default StaffMemberFormFields;
