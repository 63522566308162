import { ProgramTypeEnum } from "../../programs/enums/ProgramTypeEnum";
import { AssessmentTemplateTagEnum } from "./AssessmentTemplateTagEnum";

export enum AssessmentTypeEnum {
  NirvanaSelfReport = "NirvanaSelfReport",
  NirvanaAssessment = "NirvanaAssessment",
  NirvanaReAssessment = "NirvanaReAssessment",
  NirvanaPostAssessment = "NirvanaPostAssessment",
  MocePreAssessment = "MocePreAssessment",
  MocePostAssessment = "MocePostAssessment",
  TopsePostAssessment = "TopsePostAssessment",
  TopsePreAssessment = "TopsePreAssessment",
}

export const assessmentTypeTextMap: { [key: string]: string } = {
  [AssessmentTypeEnum.NirvanaSelfReport]: "NIRVANA Self-Report",
  [AssessmentTypeEnum.NirvanaAssessment]: "NIRVANA Assessment",
  [AssessmentTypeEnum.NirvanaReAssessment]: "NIRVANA Re-Assessment",
  [AssessmentTypeEnum.NirvanaPostAssessment]: "NIRVANA Post-Assessment",
  [AssessmentTypeEnum.MocePreAssessment]: "MoCE Pre-Assessment",
  [AssessmentTypeEnum.MocePostAssessment]: "MoCE Post-Assessment",
  [AssessmentTypeEnum.TopsePreAssessment]: "TOPSE Pre-Assessment",
  [AssessmentTypeEnum.TopsePostAssessment]: "TOPSE Post-Assessment",
};

export enum AssessmentTypeIdEnum {
  NirvanaSelfReport = "6392a03e-2588-4344-9d2c-8518e53829e9",
  NirvanaAssessment = "49d33242-9198-4caf-966b-353fd5f483ac",
  NirvanaReAssessment = "bd537f56-4cac-4da5-8482-9081e5f2a394",
  NirvanaPostAssessment = "211a511c-d830-40e3-b2ae-15fcf80a7827",
  MocePreAssessment = "",
  MocePostAssessment = "",
}
export const assessmentTypeToTagMap: { [key: string]: string[] } = {
  [AssessmentTypeEnum.NirvanaAssessment]: [AssessmentTemplateTagEnum.Nirvana, AssessmentTemplateTagEnum.Assessment],
  [AssessmentTypeEnum.NirvanaPostAssessment]: [AssessmentTemplateTagEnum.Nirvana, AssessmentTemplateTagEnum.PostAssessment],
  [AssessmentTypeEnum.NirvanaReAssessment]: [AssessmentTemplateTagEnum.Nirvana, AssessmentTemplateTagEnum.ReAssessment],
  [AssessmentTypeEnum.NirvanaSelfReport]: [AssessmentTemplateTagEnum.Nirvana, AssessmentTemplateTagEnum.SelfReport],
};

// Explicitly disable certain assessment types for certain program types.
export const disabledAssessmentTypes: { [key: string]: string[] } = {
  [ProgramTypeEnum.CommunityCounseling]: [ AssessmentTypeIdEnum.NirvanaSelfReport ],
  [ProgramTypeEnum.FyracDomesticViolenceCommunityCounseling]: [ AssessmentTypeIdEnum.NirvanaSelfReport ],
  [ProgramTypeEnum.FyracProbationRespiteCommunityCounseling]: [ AssessmentTypeIdEnum.NirvanaSelfReport ],
  [ProgramTypeEnum.IntensiveCaseManagement]: [ AssessmentTypeIdEnum.NirvanaSelfReport ],
  [ProgramTypeEnum.Snap]: [ AssessmentTypeIdEnum.NirvanaSelfReport ],
  [ProgramTypeEnum.SnapYouthJustice]: [ AssessmentTypeIdEnum.NirvanaSelfReport ],
}