import {SelectOption} from "../../../app/components/inputs/ControlledSelect";
import {FollowUpInterval, intervalSelectOptions} from "../enums/FollowUpInterval";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {useRequest} from "../../../app/hooks/useRequest";
import {getMultipleFollowUpReportsDocumentRequest} from "../FollowUpRequests";
import {PrintHelpers} from "../../../app/helpers";
import {FollowUpSearchParamsDto} from "../dtos/FollowUpSearchParamsDto";

const schema = Yup.object().shape({
  followUpInterval: Yup.string().label("Follow Up Interval").required()
})

export const usePrintMultipleFollowupsDialog = (params: FollowUpSearchParamsDto) => {

  const followupTypeOptions: SelectOption[] = intervalSelectOptions;

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: { followUpInterval: "" },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [getMultipleFollowUpReportsDocument, , getMultipleFollowUpReportsDocumentRequestState, clearRequestState] = useRequest(getMultipleFollowUpReportsDocumentRequest);

  const clearForm = () => {
    reset({ followUpInterval: "" });
    clearRequestState(true);
  };

  const handlePrintMultipleFollowUps =
    handleSubmit(formData => {
      if(!formData.followUpInterval) return;
      PrintHelpers.printDocument(() => getMultipleFollowUpReportsDocument({ ...params, interval: formData.followUpInterval as FollowUpInterval }));
    })

  return {
    state: {
      followupTypeOptions,
      getMultipleFollowUpReportsDocumentRequestState
    },
    form: {
      control,
      isValid,
      clearForm
    },
    handlers: {
      handlePrintMultipleFollowUps
    }
  }

}
