import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Fragment } from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import CollapseArrowButton from "../../../app/components/CollapseArrowButton";
import DateLabel from "../../../app/components/labels/DateLabel";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { AssessmentDto } from "../dtos/AssessmentDto";
import { AssessmentParentTypeEnum } from "../enums/AssessmentParentTypeEnum";
import AssessmentLink from "./AssessmentLink";
import AssessmentScoresTable from "./AssessmentScoresTable";

interface AssessmentLinkRowProps {
  assessment: AssessmentDto;
}

const AssessmentLinkRow: React.FC<AssessmentLinkRowProps> = ({
  assessment,
}) => {
  const [expanded, , handleToggle] = useTogglable();

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <AssessmentLink assessment={assessment} />
        </TableCell>
        <TableCell>
          <DateLabel date={assessment.completedOn} />
        </TableCell>
        <TableCell>
          <CollapseArrowButton
            title="Show Assessment Scores"
            expanded={expanded}
            onClick={handleToggle}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded}>
            <AssessmentScoresTable scores={assessment.scores} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

interface AmendmentHistorySectionProps {
  assessments: AssessmentDto[];
}

const AmendmentHistorySection: React.FC<AmendmentHistorySectionProps> = ({
  assessments,
}) => {
  return (
    <Fragment>
      <Typography color="textSecondary" style={{ fontSize: "0.9em" }}>
        This assessment is an amendment of the following assessments.
      </Typography>
      <Divider />
      <Box marginLeft="12px">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Reference #</TableCell>
              <TableCell>Administered On</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments
              .sort((a, b) => (a.referenceNumber > b.referenceNumber ? -1 : 1))
              .map((a) => (
                <AssessmentLinkRow assessment={a} key={a.id} />
              ))}
          </TableBody>
        </Table>
      </Box>
    </Fragment>
  );
};

export const assessmentAmendmentsModalId = "assessment-amendments-modal";

interface Props {
  assessments: AssessmentDto[];
}

const AssessmentAmendmentHistoryDialog: React.FC<Props> = ({ assessments }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  let assessment: AssessmentDto | undefined;
  let amendmentParent: AssessmentDto | undefined;

  if (assessments.length > 0) {
    assessment = assessments[0];
    amendmentParent = assessments.find(
      (x) =>
        x.id === assessment?.parentId &&
        assessment.parentType === AssessmentParentTypeEnum.AmendmentOf
    );
  }

  //#endregion

  //#region
  const handleCloseModal = async () => {
    dispatch(closeModal());
  };
  //#endregion

  const renderContent = () => {
    if (assessment == null || amendmentParent == null)
      return (
        <Grid item md={12} xs={12}>
          <Typography>Assessment not amended.</Typography>
        </Grid>
      );

    return (
      <Grid item md={12} xs={12}>
        <AmendmentHistorySection
          assessments={assessments.filter((x) => x.id !== assessment?.id)}
        />
      </Grid>
    );
  };

  return (
    <Dialog
      open={modalId === assessmentAmendmentsModalId}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex">
          {assessment && (
            <>
              Amendment History - Assessment #
              {assessment.referenceNumber.toString().padStart(8, "0")}{" "}
            </>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {renderContent()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Close"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AssessmentAmendmentHistoryDialog;
