import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, GridSize, List, ListItem, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import WarningIcon from "../../../app/components/icons/WarningIcon";
import KeyValueDetails, { RowProps } from "../../../app/components/KeyValueDetails";
import DateLabel from "../../../app/components/labels/DateLabel";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import PhoneNumberLabel from "../../../app/components/labels/PhoneNumberLabel";
import YesNoLabel from "../../../app/components/labels/YesNoLabel";
import ListDivider from "../../../app/components/ListDivider";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { createNumberRange } from "../../../app/helpers";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DemographicsFamilyStructureEnumTextMap } from "../../demographics/enums/DemographicsFamilyStructureEnum";
import { dischargeStatusTextMap, getDjjReleaseReason } from "../../discharges/enums/DischargeStatusEnum";
import { intakeGradeLevelTextMap } from "../../intakes/enums/IntakeGradeLevelEnum";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { livingSituationTextMap } from "../../screenings/enums/LivingSituationEnum";
import { youthRelationshipextMap } from "../../youth-contacts/enums/YouthRelationshipEnum";
import { formatYouthAddress } from "../../youths/components/YouthAddressSection";
import { YouthEthnicityEnumTextMap } from "../../youths/enums/YouthEthnicityEnum";
import { YouthGenderEnum, YouthGenderTextMap } from "../../youths/enums/YouthGenderEnum";
import { YouthPhoneNumberTypeEnumTextMap } from "../../youths/enums/YouthPhoneNumberTypeEnum";
import { YouthRaceEnumTextMap } from "../../youths/enums/YouthRaceEnum";
import { DjjEntryDetailDto, DjjEntryTypeEnum, DjjEntryTypeTextMap } from "../DjjEntryDto";
import CaseDeletedLabel from "../../youths/components/labels.tsx/CaseDeletedLabel";

const columnWidths: { [key: string]: GridSize } = {
  xsLabelSize: 5,
  mdLabelSize: 4,
  lgLabelSize: 4,
};

export const djjEntryDetailsModalId = "djj-entries-detail-modal";

interface Props {
  djjEntry?: DjjEntryDetailDto;
  requestState: RequestStateDto;
}

const DjjEntryDetailsDialog: React.FC<Props> = ({ djjEntry, requestState }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const renderTitle = () => {
    if (djjEntry == null) return "Details";
    const isIntake = [DjjEntryTypeEnum.Intake, DjjEntryTypeEnum.IntakeUpdated, ""].includes(djjEntry?.djjEntryType || "");
    const eventDate = isIntake ? djjEntry.intakeAt : djjEntry.dischargedAt;

    return (
      <>
        Details for <DateLabel date={eventDate} /> {DjjEntryTypeTextMap[djjEntry.djjEntryType]} for {djjEntry.youthName}
        <CaseDeletedLabel isDeleted={!!djjEntry.deleteReason} reason={djjEntry.deleteReason} />
      </>
    );
  };

  const renderSection1 = () => {
    const isIntake = [DjjEntryTypeEnum.Intake, DjjEntryTypeEnum.IntakeUpdated, ""].includes(djjEntry?.djjEntryType || "");

    const values: RowProps[] = [
      { key: "intakeAt", label: `Intake At`, value: <DateTimeLabel date={djjEntry?.intakeAt} /> },
      { key: "dischargeAt", label: `Discharge At`, value: <DateTimeLabel date={djjEntry?.dischargedAt} />, hide: isIntake },
      { key: "caseNumber", label: "Case Number", value: djjEntry?.caseNumber },
      { key: "programName", label: "Program", value: djjEntry?.programName },
      { key: "location", label: "Location", value: djjEntry?.programLocation.name },
      { key: "referralSource", label: "Referral Source", value: djjEntry?.referralSource },
      {
        key: "intakeCreatedAt",
        label: `Intake Created Date/Time`,
        value: <DateTimeLabel date={djjEntry?.intakeCreatedAt} />,
      },
      {
        key: "intakeUpdatedAt",
        label: "Intake Last Updated Date/Time",
        value: <DateTimeLabel date={djjEntry?.intakeUpdatedAt} />,
      },
      {
        key: "dischargeCreatedAt",
        label: `Discharge Created Date/Time`,
        value: <DateTimeLabel date={djjEntry?.dischargeCreatedAt} />,
        hide: isIntake,
      },
      {
        key: "dischargeUpdatedAt",
        label: "Discharge Last Updated Date/Time",
        value: <DateTimeLabel date={djjEntry?.dischargeUpdatedAt} />,
        hide: isIntake,
      },
    ];

    if(djjEntry?.deleteReason) {
      values.unshift({ key: "deleteReason", label: 'Delete Reason', value: djjEntry?.deleteReason });
    }

    return (
      <Grid item md={12} xs={12}>
        <KeyValueDetails {...columnWidths} rows={values} isLoading={requestState.isLoading} />
      </Grid>
    );
  };

  const renderYouthSection = () => {
    const values: RowProps[] = [
      {
        key: "dob",
        label: "DOB",
        value: <DateLabel date={djjEntry?.youthDob} />,
      },
      {
        key: "gender",
        label: "Gender",
        value: (
          <Box display="flex" alignItems="center">
            <span className="mr-1">{YouthGenderTextMap[djjEntry?.youthGender || ""]}</span>
            {djjEntry?.youthGender === YouthGenderEnum.GenderNonConforming && <WarningIcon />}
          </Box>
        ),
      },
      {
        key: "race",
        label: "Race",
        value: YouthRaceEnumTextMap[djjEntry?.youthRace || ""] || "Not Provided",
      },
      {
        key: "isMultiRacial",
        label: "Is Multiracial",
        value: <YesNoLabel value={djjEntry?.youthIsMultiracial || false} />,
      },
      {
        key: "ethnicity",
        label: "Ethnicity",
        value: YouthEthnicityEnumTextMap[djjEntry?.youthEthnicity || ""] || "Not Provided",
      },
      {
        key: "schoolName",
        label: "School Name",
        value: djjEntry?.schoolName || "Not Provided",
      },
      {
        key: "schoolGrade",
        label: "School Grade",
        value: djjEntry?.currentGrade ? intakeGradeLevelTextMap[djjEntry.currentGrade] : "Not Provided",
      },
      {
        key: "address",
        label: "Address",
        value: !djjEntry?.youthAddress?.address ? "Not Provided" : formatYouthAddress(djjEntry?.youthAddress),
      },
      {
        key: "county",
        label: "County",
        value: djjEntry?.youthAddress?.county || djjEntry?.programLocation.county || "Not Provided",
      },
      {
        key: "phone",
        label: "Phone #",
        value: (
          <>
            {djjEntry?.youthPhoneNumber === "" || djjEntry?.youthPhoneNumber == null ? (
              "Not Provided"
            ) : (
              <>
                <PhoneNumberLabel phoneNumber={djjEntry?.youthPhoneNumber} />
                {djjEntry?.youthPhoneNumberType && ` (${YouthPhoneNumberTypeEnumTextMap[djjEntry?.youthPhoneNumberType]})`}
              </>
            )}
          </>
        ),
      },
      {
        key: "familyStructure",
        label: "Family Structure",
        value: DemographicsFamilyStructureEnumTextMap[djjEntry?.familyStructure || ""] || "Not Provided",
      },
      {
        key: "isYouthParent",
        label: "Is Youth a Parent",
        value: <YesNoLabel value={djjEntry?.isYouthParent || false} />,
      },
    ];

    return (
      <>
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography color="primary">Youth Information</Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <KeyValueDetails {...columnWidths} rows={values} isLoading={requestState.isLoading} />
        </Grid>
      </>
    );
  };

  const renderYouthContactSection = () => {
    const renderContent = () => {
      if (requestState.isLoading) {
        const mockRows: RowProps[] = createNumberRange(8).map((x) => ({ key: `loading-${x}`, label: "", value: "" }));

        return (
          <>
            <KeyValueDetails {...columnWidths} isLoading={true} rows={mockRows} />
          </>
        );
      }

      if (djjEntry?.youthContacts == null || djjEntry.youthContacts.length === 0) return "No Contacts";

      return (
        <List disablePadding>
          {djjEntry?.youthContacts
            .sort((a, b) => (a.relationship >= b.relationship ? 1 : -1))
            .map((contact, index) => {
              const values: RowProps[] = [
                {
                  key: "relationship",
                  label: "Relationship",
                  value: youthRelationshipextMap[contact.relationship],
                },
                {
                  key: "firstName",
                  label: "First Name",
                  value: contact.firstName || "Not Provided",
                },
                {
                  key: "lastName",
                  label: "Last Name",
                  value: contact.lastName || "Not Provided",
                },
                {
                  key: "address",
                  label: "Address",
                  value: contact.address || "Not Provided",
                },
                {
                  key: "city",
                  label: "City",
                  value: contact.city || "Not Provided",
                },
                {
                  key: "state",
                  label: "State",
                  value: contact.state || "Not Provided",
                },
                {
                  key: "zip",
                  label: "Zip Code",
                  value: contact.zipCode || "Not Provided",
                },
                {
                  key: "county",
                  label: "County",
                  value: contact.county || "Not Provided",
                },
              ];

              return (
                <Fragment key={contact.id}>
                  <ListItem disableGutters>
                    <KeyValueDetails {...columnWidths} isLoading={requestState.isLoading} rows={values} />
                  </ListItem>
                  <ListDivider variant="fullWidth" index={index} length={djjEntry.youthContacts.length} />
                </Fragment>
              );
            })}
        </List>
      );
    };

    return (
      <>
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography color="primary">Youth Contacts</Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          {renderContent()}
        </Grid>
      </>
    );
  };

  const renderDischargeSection = () => {
    const isIntake = [DjjEntryTypeEnum.Intake, DjjEntryTypeEnum.IntakeUpdated, ""].includes(djjEntry?.djjEntryType || "");

    if (isIntake) return <></>;
    const values: RowProps[] = [
      {
        key: "dischargeStatus",
        label: "Discharge Status",
        value: dischargeStatusTextMap[djjEntry?.dischargeStatus || ""] || "Not Provided",
      },
      {
        key: "dischargeLivingSituation",
        label: "Living Situation",
        value: livingSituationTextMap[djjEntry?.dischargeLivingSituation || ""] || "Not Provided",
      },
      {
        key: "dischargeStatusDJJ",
        label: "DJJ Release Reason",
        value: getDjjReleaseReason(djjEntry?.dischargeStatus) || "Not Provided",
      },
    ];

    return (
      <>
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography color="primary">Discharge Information</Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <KeyValueDetails {...columnWidths} rows={values} isLoading={requestState.isLoading} />
        </Grid>
      </>
    );
  };

  return (
    <Dialog open={modalId === djjEntryDetailsModalId} fullWidth maxWidth="md">
      <DialogTitle>
        {renderTitle()}
        <ErrorText error={error?.message} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {renderSection1()}

          {renderYouthSection()}
          {renderYouthContactSection()}
          {renderDischargeSection()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SpinnerButton content="Close" showSpinner={false} type="button" onClick={handleCloseModal} disabled={isLoading} />
      </DialogActions>
    </Dialog>
  );
};

export default DjjEntryDetailsDialog;
