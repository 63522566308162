import { Grid, GridSize, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

interface KeyValueRowProps {
  lgLabelSize?: GridSize;
  mdLabelSize?: GridSize;
  xsLabelSize?: GridSize;

  lgContentSize?: GridSize;
  mdContentSize?: GridSize;
  xsContentSize?: GridSize;
  color?:
    | "inherit"
    | "initial"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error"
    | undefined;
  isLoading?: boolean;
  delimiter?: string;
}

const KeyValueRow: React.FC<KeyValueRowProps> = ({
  children,
  lgLabelSize = 2,
  mdLabelSize = 3,
  xsLabelSize = 5,
  lgContentSize,
  mdContentSize,
  xsContentSize,
  isLoading,
  color,
  delimiter = ":",
}) => {
  var childArray = React.Children.toArray(children);

  const calculateValueSize = (labelSize: GridSize | number, valueSize: GridSize | undefined) => {
    if(valueSize != null) return valueSize;

    labelSize = +labelSize;

    if (isNaN(labelSize) || labelSize >= 12) return 0 as GridSize;

    return (12 - +labelSize) as GridSize;
  };

  return (
    <>
      <Grid
        item
        lg={lgLabelSize}
        md={mdLabelSize}
        xs={xsLabelSize}
        component="span"
      >
        {isLoading ? (
          <Skeleton width="100px" animation="wave" />
        ) : (
          <Typography color={color} component="span">
            {childArray[0]}
            {delimiter}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={calculateValueSize(lgLabelSize, lgContentSize)}
        md={calculateValueSize(mdLabelSize, mdContentSize)}
        xs={calculateValueSize(xsLabelSize, xsContentSize)}
        component="span"
      >
        {isLoading ? (
          <Skeleton width="100px" animation="wave" />
        ) : (
          <Typography color={color} component="span">
            {childArray[1]}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default KeyValueRow;
