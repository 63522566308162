import { useEffect, useState } from "react";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { useGetInvoiceYears } from "../../invoices/hooks/invoicingHooks";
import { useGetAllProviders } from "../../provider/hooks/providerHooks";
import { MonthlyDeliverablesReportParamsDto } from "../dtos/MonthlyDeliverablesReportParametersDto";
import {
  useGetMonthlyDeliverablesReportDocument,
  useGetMonthlyDeliverablesPivotReportDocument,
} from "./reportHooks";

export const useMonthlyDeliverablesReportPage = () => {
  const [getRollupDocument, rollupDocument, getRollupDocumentRequestState] =
    useGetMonthlyDeliverablesReportDocument();
  const [getPivotDocument, pivotDocument, getPivotDocumentRequestState] =
    useGetMonthlyDeliverablesPivotReportDocument();
  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();
  const [getInvoiceYears, , getInvoiceYearsRequestState] = useGetInvoiceYears();
  const [invoiceYears, setInvoiceYears] = useState<number[]>([]);

  const isFloridaNetwork = getProviderId() === fnyfsProviderId;
  const document = isFloridaNetwork ? pivotDocument : rollupDocument;
  const getDocument = isFloridaNetwork ? getPivotDocument : getRollupDocument;
  const getDocumentRequestState = isFloridaNetwork
    ? getPivotDocumentRequestState
    : getRollupDocumentRequestState;

  const handleSearch = async (params: MonthlyDeliverablesReportParamsDto) => {
    await getDocument(params);
  };

  useEffect(() => {
    if (!isFloridaNetwork) getProviders(undefined);
  }, [getProviders, isFloridaNetwork]);

  useEffect(() => {
    const load = async () => {
      let result = await getInvoiceYears(undefined);
      if (result) {
        setInvoiceYears(result.sort((a, b) => (a > b ? 1 : -1)));
      }
    };

    load();
  }, [getInvoiceYears, setInvoiceYears]);

  return {
    document,
    getDocumentRequestState,
    providers: providers || [],
    invoiceYears,
    getInvoiceYearsRequestState,
    getProvidersRequestState,
    handleSearch,
    isFloridaNetwork,
  };
};
