import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import DateLabel from "../../../app/components/labels/DateLabel";
import { SnapInSchoolsCycleSummaryDto } from "../dtos/SnapInSchoolsCycleSummaryDto";
import { gradeLevelTextMap } from "../enums/GradeLevelEnum";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";

interface Props {
  summary: SnapInSchoolsCycleSummaryDto;
}

const SnapInSchoolsCycleListItem: React.FC<Props> = ({ summary }) => {
  return (
    <TableRow>
      <TableCell>
        <RouteableStyledLink pathname={`/snap-in-schools/${summary.id}`} text="Back to SNAP in Schools and Communities Cycles">
          {summary.referenceNumber}
        </RouteableStyledLink>
      </TableCell>
      <TableCell>
        <DateLabel date={summary.startedOn} />
      </TableCell>
      <FnAccessWrapper>
        <TableCell>{summary.provider.name}</TableCell>
      </FnAccessWrapper>
      <TableCell>
        {summary.grades.map((grade) => gradeLevelTextMap[grade]).join(", ")}
      </TableCell>
      <TableCell style={{ paddingLeft: "60px" }}>
        {summary.numberOfSessions}
      </TableCell>
      <TableCell>
        {summary.lastSessionHeldOn ? (
          <DateLabel date={summary.lastSessionHeldOn} />
        ) : (
          "N/A"
        )}
      </TableCell>
    </TableRow>
  );
};

export default SnapInSchoolsCycleListItem;
