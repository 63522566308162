import { DateTime } from "luxon";
import React, { useCallback } from "react";
import FilterList from "../../../app/components/FilterList";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { EventLogFilterFormDto } from "../dtos/EventLogFilterFormDto";
import { CaseSearchParamsDto } from "../../cases/dtos/CaseSearchParamsDto";
import { EventLogSearchParamsDto } from "../dtos/EventLogSearchParamsDto";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

const formatDate = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat("MM-dd-yyyy");
};

interface Props {
  params: EventLogSearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  referenceTypeOptions: SelectOption[];
  affectedDeliverableOptions: SelectOption[];
  onSearch(dto: CaseSearchParamsDto): void;
}

const EventLogFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  referenceTypeOptions,
  affectedDeliverableOptions,
  onSearch,
}) => {
  const handleClearParams = () => {
    return new EventLogFilterFormDto();
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "providerId":
          return `Provider: ${providers.find((p) => p.id === value)?.name}`;
        case "referenceType":
          return `Events affecting resource type: ${
            referenceTypeOptions.find((v) => v.value === value)?.label
          }`;
        case "affectedDeliverables":
            return `Affected Deliverables: ${
              affectedDeliverableOptions.find((v) => v.value === value)?.label
            }`;          
        case "startDate":
          return `Events processed on or after: ${formatDate(value)}`;
        case "endDate":
          return `Events processed on or before: ${formatDate(value)}`;
      }
      return "";
    },
    [providers, referenceTypeOptions, affectedDeliverableOptions]
  );

  const ordering = [
    "providerId",
    "referenceType",
    "affectedDeliverables",
    "startDate",
    "endDate",    
  ];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default EventLogFilterList;
