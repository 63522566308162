import { useRequest } from "../../../app/hooks/useRequest";
import {
  createInvoiceRequest,
  getInvoiceReportRequest,
  getInvoicesRequest,
  getInvoiceDocumentRequest,
  returnInvoiceRequest,
  getInvoiceYearsRequest,
} from "../InvoiceRequests";

export const useGetInvoiceReport = () => useRequest(getInvoiceReportRequest);

export const useCreateInvoice = () => useRequest(createInvoiceRequest);
export const useGetInvoices = () => useRequest(getInvoicesRequest);
export const useGetInvoiceDocument = () =>
  useRequest(getInvoiceDocumentRequest);

export const useReturnInvoice = () => useRequest(returnInvoiceRequest);

export const useGetInvoiceYears = () => useRequest(getInvoiceYearsRequest);