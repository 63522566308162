import { Box } from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { TabProps } from "../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import ProviderFiscalYearFormDialog from "../../../provider-fiscal-year/components/ProviderFiscalYearFormDialog";
import ProviderFiscalYearLicensedBedsFormDialog from "../../../provider-fiscal-year/components/ProviderFiscalYearLicensedBedsFormDialog";
import { useProviderDetailPageContext } from "../../pages/ProviderDetailPage";
import ProviderFiscalYearList from "../provider-fiscal-year/ProviderFiscalYearList";

export const providerFiscalYearSectionName = "provider-fiscal-year-section";

const ProviderFiscalYearSection: React.FC = () => {
  const context = useProviderDetailPageContext();
  const { provider } = context.provider;
  const {
    selectedFiscalYear,
    providerFiscalYears,
    getProviderFiscalYearsRequestState: { isLoading },
    updateFiscalYearRequestState,
    updateProviderFiscalYearLicensedBedsRequestState,
    handleEditFiscalYear,
    handleEditFiscalYearLicensedBedsClicked,
    handleEditFiscalYearClicked,
    handleEditFiscalYearLicensedBeds,
  } = context.fiscalYears;

  return (
    <Fragment>
      <WriteAccessLevelWrapper
        name={PermissionResourceNameEnum.FiscalYearContractDeliverable}
      >
        <ProviderFiscalYearFormDialog
          onSubmit={handleEditFiscalYear}
          requestState={updateFiscalYearRequestState}
          providerFiscalYear={selectedFiscalYear}
        />
      </WriteAccessLevelWrapper>
      <WriteAccessLevelWrapper
        name={PermissionResourceNameEnum.FiscalYearLicensedBed}
      >
        <ProviderFiscalYearLicensedBedsFormDialog
          onSubmit={handleEditFiscalYearLicensedBeds}
          requestState={updateProviderFiscalYearLicensedBedsRequestState}
          providerFiscalYear={selectedFiscalYear}
        />
      </WriteAccessLevelWrapper>
      <Box marginTop="16px">
        <ProviderFiscalYearList
          provider={provider!}
          providerFiscalYears={providerFiscalYears}
          isLoading={isLoading}
          onEdit={handleEditFiscalYearClicked}
          onEditLicensedBeds={handleEditFiscalYearLicensedBedsClicked}
        />
      </Box>
    </Fragment>
  );
};

export default ProviderFiscalYearSection;

export const createProviderFiscalYearSection = (): TabProps => ({
  label: "Fiscal Years",
  value: providerFiscalYearSectionName,
  content: <ProviderFiscalYearSection />,
});
