import { DateTime } from "luxon";
import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { dateToFormString, QueryStringHelpers } from "../../../app/helpers";
import { FollowUpFilterFormDto } from "./FollowUpFilterFormFilterDto";
import {FollowUpInterval} from "../enums/FollowUpInterval";
import {IntervalSelections} from "../components/FollowUpIntervalSelector";
import {ProgramFundingSourceEnum} from "../../programs/enums/ProgramFundingSourceEnum";

export interface PrintMultipleFollowUpsParamsDto {
  interval: FollowUpInterval;
  providerId?: string;
  programId?: string;
  programTypeId?: string;
  locationId?: string;
  dischargeDateStart?: string;
  dischargeDateEnd?: string;
  fundingSource?: string;
  hadServicesSinceDischarge?: string;
}

export class FollowUpSearchParamsDto implements PaginationSearchParamsDto, IntervalSelections {
  providerId?: string;
  programId?: string;
  programTypeId?: string;
  locationId?: string;
  dischargeDateStart?: string;
  dischargeDateEnd?: string;
  followUpCompletionRangeStart?: string;
  followUpCompletionRangeEnd?: string;
  fundingSource?: string;
  hadServicesSinceDischarge?: string;
  onlyShowFollowUpsDue?: boolean;
  // onlyShow60DaysDue?: boolean;
  dischargeStaffId?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  includeThirtyDay: boolean = true;
  includeSixtyDay: boolean = true;
  includeNinetyDay: boolean = false;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: FollowUpFilterFormDto;
    previous?: FollowUpSearchParamsDto;
  }) {
    this.providerId = form?.providerId || (query?.providerId as string) || "";
    this.programId = form?.programId || (query?.programId as string) || "";
    this.programTypeId =
      form?.programTypeId || (query?.programTypeId as string) || "";
    this.dischargeDateEnd =
      form?.dischargeDateEnd || (query?.dischargeDateEnd as string) || "";
    this.dischargeDateStart =
      form?.dischargeDateStart ||
      (query?.dischargeDateStart as string) ||
      FollowUpSearchParamsDto.getInitialDischargeStartDate();
    this.followUpCompletionRangeStart =
      form?.followUpCompletionRangeStart || (query?.followUpCompletionRangeStart as string) || "";
    this.followUpCompletionRangeEnd =
      form?.followUpCompletionRangeEnd || (query?.followUpCompletionRangeEnd as string) || "";
    this.fundingSource =
      form?.fundingSource || (query?.fundingSource as string) || ProgramFundingSourceEnum.FloridaNetwork;
    this.hadServicesSinceDischarge =
      (query?.hadServicesSinceDischarge as string) || "";

    this.onlyShowFollowUpsDue =
      form?.onlyShowFollowUpsDue || (query?.onlyShowFollowUpsDue === 'true') || false;

    this.dischargeStaffId = form?.dischargeStaffId || (query?.dischargeStaffId as string) || "";

    this.includeThirtyDay = (query?.includeThirtyDay === 'false') ? false : true;
    this.includeSixtyDay = (query?.includeSixtyDay === 'false') ? false : true;
    this.includeNinetyDay = (query?.includeNinetyDay === 'true') ? true : false;

    if (form) this.hadServicesSinceDischarge = form.hadServicesSinceDischarge;

    this.sortBy = previous?.sortBy || (query?.sortBy as string) || "caseNumber";
    this.sortDirection =
      previous?.sortDirection || QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
  }

  public static toQueryString(dto: FollowUpSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }

  private static getInitialDischargeStartDate(days: number = -120) {
    const date = DateTime.local().plus({ days });
    return dateToFormString(date.toJSDate());
  }
}
