import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import { snapCycleGroupsTabName } from "./components/SnapGroupCycleGroupsSection";
import SnapGroupCycleDetailPage from "./pages/SnapGroupCycleDetailPage";
import SnapGroupCycleListPage from "./pages/SnapGroupCycleListPage";

export const createSnapRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, "")}
    component={SnapGroupCycleListPage}
    key="list-snap-cycle"
    name={PermissionResourceNameEnum.Case}
    exact
  />,
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, ":id")}
    component={SnapGroupCycleDetailPage}
    key="detail-snap-cycle"
    name={PermissionResourceNameEnum.Case}
    exact
  />,
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, ":id/groups")}
    key="detail-snap-cycle"
    name={PermissionResourceNameEnum.Case}
    exact
    render={() => {
      return (
        <SnapGroupCycleDetailPage
          key={"detail-snap-cycle"}
          selectedTab={snapCycleGroupsTabName}
        />
      );
    }}
  />,
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, ":id/groups/:groupId")}
    key="detail-snap-cycle"
    name={PermissionResourceNameEnum.Case}
    exact
    render={() => (
      <SnapGroupCycleDetailPage
        key={"detail-snap-cycle"}
        selectedTab={snapCycleGroupsTabName}
      />
    )}
  />,
];
