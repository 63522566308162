import React, { Fragment } from "react";
import Box from "@material-ui/core/Box/Box";
import LoginForm from "../components/LoginForm";
import { Grid, Typography } from "@material-ui/core";
import StyledLink from "../../../app/components/StyledLink";
import { useLoginPage } from "../hooks/useLoginPage";
import ErrorAlert from "../../../app/components/ErrorAlert";
import { HttpErrorType } from "../../../app/HttpError";
import IpAddressConfirmationForm from "../components/IpAddressConfirmationForm";

const LoginPage = () => {
  const {
    login: { loginControl, loginRequestState, handleLogin },
    ipAddress: {
      ipAddressControl,
      confirmIpAddressRequestState,
      handleConfirmIpAddress,
    },
    errorMessage,
  } = useLoginPage();
  return (
    <Box paddingTop="10%" width="100%">
      <Grid container>
        <Grid item lg={4} md={3}></Grid>
        <Grid item lg={4} md={6} xs={12}>
          <ErrorAlert error={confirmIpAddressRequestState.error?.message} />

          {!confirmIpAddressRequestState.error && (
            <ErrorAlert error={loginRequestState?.error?.message} />
          )}

          {loginRequestState.error?.type ===
          HttpErrorType.UnconfirmedIpAddress ? (
            <IpAddressConfirmationForm
              control={ipAddressControl}
              isLoading={confirmIpAddressRequestState.isLoading}
              onSubmit={handleConfirmIpAddress}
            />
          ) : (
            <Fragment>
              <ErrorAlert error={errorMessage} />
              <LoginForm
                onSubmit={handleLogin}
                isLoading={loginRequestState.isLoading}
                control={loginControl}
              />
              <Box marginTop="8px">
                <Typography>
                  <StyledLink to="/account/send-password-reset">
                    Forgot password?
                  </StyledLink>
                </Typography>
              </Box>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
