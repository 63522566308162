import { useRequest } from "../../../app/hooks/useRequest";
import {
  createProviderFiscalYearRequest,
  getFiscalYearsRequest,
  updateProviderFiscalYearLicensedBedsRequest,
  updateProviderFiscalYearRequest,
} from "../ProviderFiscalYearRequests";

export const useCreateProviderFiscalYear = () =>
  useRequest(createProviderFiscalYearRequest, true);

export const useUpdateProviderFiscalYear = () =>
  useRequest(updateProviderFiscalYearRequest, true);

export const useUpdateProviderFiscalYearLicensedBeds = () =>
  useRequest(updateProviderFiscalYearLicensedBedsRequest, true);

export const useGetFiscalYears = () => useRequest(getFiscalYearsRequest);
