export enum CaseContactedPersonEnum {
  Youth = "Youth",
  ParentGuardian = "ParentGuardian",
  OtherFamilyMember = "OtherFamilyMember",
  TeacherSchool = "TeacherSchool",
  Employer = "Employer",
  HumanServicesProfessional = "HumanServicesProfessional",
  LawEnforcement = "LawEnforcement",
  NonRelative = "NonRelative",
}

export const icmContactedPersonTextMap: { [key: string]: string } = {
  [CaseContactedPersonEnum.Youth]: "Youth",
  [CaseContactedPersonEnum.ParentGuardian]: "Parent/Guardian",
  [CaseContactedPersonEnum.OtherFamilyMember]: "Other Family Member",
  [CaseContactedPersonEnum.TeacherSchool]: "Teacher/School",
  [CaseContactedPersonEnum.Employer]: "Employer",
  [CaseContactedPersonEnum.HumanServicesProfessional]:
    "Human Services Professional",
  [CaseContactedPersonEnum.LawEnforcement]: "Law Enforcement",
  [CaseContactedPersonEnum.NonRelative]: "Non-Relative",
};

export const getCaseContactedPersonOptions = (): string[] => {
  let values = Object.values(CaseContactedPersonEnum);
  return values.map((status) => icmContactedPersonTextMap[status]);
};
