import { Box, Paper, Switch, Typography } from "@material-ui/core";
import React, { createContext, useContext } from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import ErrorText from "../../../app/components/ErrorText";
import Header from "../../../app/components/Header";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import {
  createSnapCycleDetailTab,
  snapCycleDetailTabName,
} from "../components/SnapCycleDetails";
import { createSnapGroupCycleGroupsSectionTab } from "../components/SnapGroupCycleGroupsSection";
import {
  SnapCycleDetailPageProps,
  useSnapCycleDetailPage,
} from "../hooks/useSnapCycleDetailPage";
import SnapGroupCycleAdditionalActions from "../components/SnapGroupCycleAdditionalActions";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import SnapGroupCycleDetailDialogs from "../components/SnapGroupCycleDetailDialogs";

const SnapGroupCycleDetailPageContext = createContext<SnapCycleDetailPageProps>(
  undefined!
);
export const useSnapGroupCycleDetailPageContext = () =>
  useContext(SnapGroupCycleDetailPageContext);

export interface Props {
  selectedTab?: string;
}

const SnapGroupCycleDetailPage: React.FC<Props> = ({
  selectedTab = snapCycleDetailTabName,
}) => {
  const snapGroupCyclePageProps = useSnapCycleDetailPage(selectedTab);
  const { snapCycle, getSnapCycleRequestState } =
    snapGroupCyclePageProps.snapCycle;
  const { showDeleted, handleChangeTabIndex, tabIndex, handleSetShowDeleted } =
    snapGroupCyclePageProps.ui;
  const tabs: TabProps[] = [
    createSnapCycleDetailTab(),
    createSnapGroupCycleGroupsSectionTab(),
  ];

  if (!snapCycle) return <></>;

  return (
    <SnapGroupCycleDetailPageContext.Provider value={snapGroupCyclePageProps}>
      <Box display="flex" flexDirection="column" height="100%">
        <SnapGroupCycleDetailDialogs />
        <Box marginBottom="12px">
          <BackButton route="/snap-cycles" text="Back to SNAP Cycles" />
        </Box>
        <Header
          title={
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              SNAP Group Cycle ({snapCycle?.referenceNumber})
              {snapCycle.deletedAt ? (
                <RedAlertLabel label={"Deleted"} />
              ) : (
                <></>
              )}
            </Box>
          }
          isLoading={getSnapCycleRequestState.isLoading}
          actions={
            <>
              <SnapGroupCycleAdditionalActions />
            </>
          }
        />
        <FnAccessWrapper>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography>Show Deleted Groups and Cases</Typography>
            <Switch
              color={"primary"}
              value={showDeleted}
              checked={showDeleted}
              onChange={(_, show) => handleSetShowDeleted(show)}
            />
          </Box>
        </FnAccessWrapper>

        <ErrorText error={getSnapCycleRequestState.error?.message} />
        <Paper variant="outlined" style={{ flex: 1, position: "relative" }}>
          <TabContainer
            index={tabIndex}
            onIndexChange={handleChangeTabIndex}
            tabs={tabs}
            disabled={getSnapCycleRequestState.isLoading}
          />
        </Paper>
      </Box>
    </SnapGroupCycleDetailPageContext.Provider>
  );
};

export default SnapGroupCycleDetailPage;
