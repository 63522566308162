import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  isLoading: boolean;
  text?: string;
}

const Loader: React.FC<Props> = ({ isLoading, text }) => {
  if (!isLoading) return <></>;

  return (
    <Box
      width="100%"
      height={"100%"}
      bgcolor="rgba(0,0,0,0.4)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign={"center"}>
        <CircularProgress
          style={{ color: "white", width: "60px", height: "60px" }}
        />
        <Typography style={{ color: "white" }} variant="h4">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default Loader;
