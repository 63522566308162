import { DateTime } from "luxon";
import { dateToFormString } from "../../../app/helpers";
import { FollowUpSearchParamsDto } from "./FollowUpSearchParamsDto";
import {ProgramFundingSourceEnum} from "../../programs/enums/ProgramFundingSourceEnum";

export class FollowUpFilterFormDto {
  providerId: string;
  programTypeId: string;
  locationId: string;
  programId: string;
  dischargeDateStart: string;
  dischargeDateEnd: string;
  followUpCompletionRangeStart: string;
  followUpCompletionRangeEnd: string;
  fundingSource: string;
  hadServicesSinceDischarge: string;
  onlyShowFollowUpsDue?: boolean;
  dischargeStaffId?: string;
  constructor(dto?: FollowUpSearchParamsDto) {
    this.providerId = dto?.providerId || "";
    this.programId = dto?.programId || "";
    this.programTypeId = dto?.programTypeId || "";
    this.locationId = dto?.locationId || "";
    this.dischargeDateStart = dto?.dischargeDateStart || "";      
    this.dischargeDateEnd = dto?.dischargeDateEnd || "";
    this.followUpCompletionRangeStart = dto?.followUpCompletionRangeStart || "";
    this.followUpCompletionRangeEnd = dto?.followUpCompletionRangeEnd || "";
    this.fundingSource = dto?.fundingSource || ProgramFundingSourceEnum.FloridaNetwork;
    this.hadServicesSinceDischarge = dto?.hadServicesSinceDischarge || "";
    this.onlyShowFollowUpsDue = dto?.onlyShowFollowUpsDue || false;
    this.dischargeStaffId = dto?.dischargeStaffId || "";
  }

  private static getInitialDischargeStartDate(days: number = -120) {
    const date = DateTime.local().plus({ days });
    return dateToFormString(date.toJSDate());
  }
}
