import { coreAgent } from "../../app/api/agent";
import { TerminateProgramLocationFormDto } from "./dtos/TerminateProgramLocationFormDto";
import { ProgramLocationDto } from "./dtos/ProgramLocationDto";
import { ProgramLocationExistingFormDto } from "./dtos/ProgramLocationExistingFormDto";
import { ProgramLocationNewFormDto } from "./dtos/ProgramLocationNewFormDto";
import { ProgramLocationBaseFormDto } from "./dtos/ProgramLocationBaseFormDto";
import { LocationDto } from "./dtos/LocationDto";
import { LocationFormDto } from "./dtos/LocationFormDto";

interface CreateProgramLocatioNewParams {
  programId: string;
  dto: ProgramLocationNewFormDto;
}

interface CreateProgramLocationExistingParams {
  programId: string;
  dto: ProgramLocationExistingFormDto;
}

interface TerminateProgramLocationRequestParams {
  id: string;
  dto: TerminateProgramLocationFormDto;
}

interface UpdateProgramLocationParams {
  id: string;
  dto: ProgramLocationBaseFormDto;
}

interface UpdateLocationParams {
  id: string;
  dto: LocationFormDto;
}

export const createProgramNewLocationRequest = ({
  programId,
  dto,
}: CreateProgramLocatioNewParams) =>
  coreAgent.postRequest<ProgramLocationDto>(
    `/programs/${programId}/locations/new`,
    dto
  );
export const createProgramExistingLocationRequest = ({
  programId,
  dto,
}: CreateProgramLocationExistingParams) =>
  coreAgent.postRequest<ProgramLocationDto>(
    `/programs/${programId}/locations`,
    dto
  );

export const terminateProgramLocationRequest = ({
  id,
  dto,
}: TerminateProgramLocationRequestParams) =>
  coreAgent.postRequest<boolean>(`locations/${id}/terminate`, dto);

export const updateProgramLocationRequest = ({
  id,
  dto,
}: UpdateProgramLocationParams) =>
  coreAgent.putRequest<LocationDto>(`locations/program/${id}`, dto);

export const updateLocationRequest = ({ id, dto }: UpdateLocationParams) =>
  coreAgent.putRequest<LocationDto>(`locations/${id}`, dto);
