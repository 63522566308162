import React from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import FilterButton from "../../../app/components/buttons/FilterButton";
import ErrorAlert from "../../../app/components/ErrorAlert";
import Header from "../../../app/components/Header";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { useAppSelector } from "../../../app/hooks";
import { UserDto } from "../../authentication/dtos/UserDto";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { selectUser } from "../../authentication/state/authenticationSelectors";
import UserFilter from "../components/UserFilter";
import UserFilterList from "../components/UserFilterList";
import UserFormDialog from "../components/UserFormDialog";
import UserList from "../components/UserList";
import { useUserListPage } from "../hooks/useUserListPage";

const UserListPage = () => {
  //#region State
  const user = useAppSelector(selectUser);  
  const { state, handlers } = useUserListPage();
  const {
    getUsersRequestState,
    createUserRequestState,
    usersResult,
    params,
    filterAnchorEl,
    allRoles,
    providerRoles
  } = state;
  const {
    handleCreateUser,
    handleCreateUserClicked,
    handlePageChange,
    handleSort,
    handleCloseFilter,
    handleOpenFilterClicked,
    handleSearch,
  } = handlers;

  const roles = (user?.isFloridaNetworkUser ? allRoles : providerRoles) || []; 
  //#endregion

  return (
    <div>
      <UserFormDialog
        onSubmit={handleCreateUser}
        requestState={createUserRequestState}
      />
      <UserFilter
        anchorEl={filterAnchorEl}
        params={params}
        roles={roles} 
        onClose={handleCloseFilter}
        onSearch={handleSearch}
      />
      <ErrorAlert error={getUsersRequestState.error?.message} />
      <Header
        title="Users"
        isLoading={getUsersRequestState.isLoading}
        actions={
          <>
            <FilterButton onClick={handleOpenFilterClicked} />
            <WriteAccessLevelWrapper name={PermissionResourceNameEnum.User}>
              <AddButton
                onClick={handleCreateUserClicked}
                className="ml-2"
                title="Create User"
                color="primary"
                variant="contained"
                text="Create User"
                disabled={
                  getUsersRequestState.isLoading ||
                  createUserRequestState.isLoading
                }
              />
            </WriteAccessLevelWrapper>
          </>
        }
        secondaryActions={
          <UserFilterList
            params={params}
            roles={roles}
            onSearch={handleSearch}
            isLoading={
              getUsersRequestState.isLoading || createUserRequestState.isLoading
            }
          />
        }
      />

      <UserList
        results={usersResult || new PaginationResultDto<UserDto>()}
        params={params}
        isLoading={getUsersRequestState.isLoading}
        onPageChange={handlePageChange}
        onSort={handleSort}
      />
    </div>
  );
};

export default UserListPage;
