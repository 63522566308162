import React from "react";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum ProgramFundingSourceEnum {
  FloridaNetwork = "FloridaNetwork",
  Other = "Other",
}

export const fundingSourceTextMap: { [key: string]: string } = {
  [ProgramFundingSourceEnum.FloridaNetwork]: "Florida Network",
  [ProgramFundingSourceEnum.Other]: "Other",
};

export const getFundingSourceOptions = (
  defaultLabel: React.ReactNode = "Select Funding Source"
): SelectOption[] => [
  { value: "", label: defaultLabel },
  {
    value: ProgramFundingSourceEnum.FloridaNetwork,
    label: fundingSourceTextMap[ProgramFundingSourceEnum.FloridaNetwork],
  },
  {
    value: ProgramFundingSourceEnum.Other,
    label: fundingSourceTextMap[ProgramFundingSourceEnum.Other],
  },
];

export const fundingSourceOptions: SelectOption[] = getFundingSourceOptions();
