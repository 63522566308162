import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { FollowUpCompletionData, FollowUpDto } from "../dtos/FollowUpDto";
import { useRequest } from "../../../app/hooks/useRequest";
import { updateFollowUpCompletionDataRequest } from "../FollowUpRequests";
import {
  getLivingSituationOptions,
  LivingSituationEnum,
} from "../../screenings/enums/LivingSituationEnum";
import {
  SchoolStatusEnum,
  schoolStatusOptions,
} from "../../discharges/enums/SchoolStatusEnum";
import { useGetProviderStaffMembers } from "../../provider/hooks/providerHooks";
import { useCallback, useEffect } from "react";
import { datetimeToFormString } from "../../../app/helpers";
import { FollowUpImprovementEnum, followUpImprovementOptions } from "../enums/FollowUpImprovementEnum";

export interface UpdateFollowUpCompletionDataFormData {
  attemptedAt: string;
  receivedServicesSinceDischarge: string | null;
  receivedServicesFromAnotherProviderSinceDischarge: string | null;
  livingStatus: string;
  isYouthsLivingStatusAppropriate: string | null;
  schoolStatus: string;
  seenImprovement: string | null;
}

const schema = Yup.object().shape({
  // eslint-disable-next-line
  attemptedAt: Yup.date().required().label("Attempted At").typeError("${label} must be a valid date/time"),
  receivedServicesSinceDischarge: Yup.boolean().optional(),
  receivedServicesFromAnotherProviderSinceDischarge: Yup.boolean().optional(),
  livingStatus: Yup.string().when("outcome", {
    is: "success",
    then: Yup.string().required().label("Current Living Status"),
  }),
  IsYouthsLivingStatusAppropriate: Yup.boolean().optional(),
  schoolStatus: Yup.string().when("outcome", {
    is: "success",
    then: Yup.string().required().label("Current School Status"),
  }),
  seenImprovement: Yup.string().optional().label("Improvement Status"),
});

export const useUpdateFollowUpCompletionDataForm = (
  onSubmit: () => Promise<void>,
  caseDto: CaseDetailDto,
  followUp?: FollowUpDto
) => {
  //#region State

  const translateBoolOrStringToBool = (
    value: string | boolean | null | undefined
  ) => {
    return !!(value && value.toString() === "true");
  };

  const translateBoolOrStringToString = (
    value: string | boolean | null | undefined
  ) => {
    return value == null ? null : (value.toString() === "true").toString();
  };

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<UpdateFollowUpCompletionDataFormData>({
    defaultValues: {
      attemptedAt: followUp?.completionData?.completedOn
        ? datetimeToFormString(new Date(followUp?.completionData?.completedOn))
        : undefined,
      receivedServicesSinceDischarge: translateBoolOrStringToString(
        followUp?.completionData?.receivedServicesSinceDischarge
      ),
      seenImprovement: followUp?.completionData?.seenImprovement || "",
      isYouthsLivingStatusAppropriate: translateBoolOrStringToString(
        followUp?.completionData?.isYouthsLivingStatusAppropriate
      ),
      schoolStatus: followUp?.completionData?.schoolStatus,
      livingStatus: followUp?.completionData?.livingStatus,
      receivedServicesFromAnotherProviderSinceDischarge:
        translateBoolOrStringToString(
          followUp?.completionData
            ?.receivedServicesFromAnotherProviderSinceDischarge
        ),
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [
    getProviderStaffMembers,
    staffMembers,
    ,
    clearGetProviderStaffMemberErrors,
  ] = useGetProviderStaffMembers();

  const livingSituationOptions = getLivingSituationOptions();

  const clearForm = useCallback(() => {
    reset({
      attemptedAt: followUp?.completionData?.completedOn
        ? datetimeToFormString(new Date(followUp?.completionData?.completedOn))
        : undefined,
      receivedServicesSinceDischarge: translateBoolOrStringToString(
        followUp?.completionData?.receivedServicesSinceDischarge
      ),
      seenImprovement: followUp?.completionData?.seenImprovement || "",
      isYouthsLivingStatusAppropriate: translateBoolOrStringToString(
        followUp?.completionData?.isYouthsLivingStatusAppropriate
      ),
      schoolStatus: followUp?.completionData?.schoolStatus,
      livingStatus: followUp?.completionData?.livingStatus,
      receivedServicesFromAnotherProviderSinceDischarge:
        translateBoolOrStringToString(
          followUp?.completionData
            ?.receivedServicesFromAnotherProviderSinceDischarge
        ),
    });
  }, [followUp, reset]);

  const [updateFollowUpCompletion, , updateFollowUpCompletionRequestState] =
    useRequest(updateFollowUpCompletionDataRequest);

  const handleOnSubmit = handleSubmit(
    async (formData: UpdateFollowUpCompletionDataFormData) => {
      if (followUp == null) return;

      const isYouthsLivingStatusAppropriate = translateBoolOrStringToBool(
        formData.isYouthsLivingStatusAppropriate
      );
      const receivedServicesFromAnotherProviderSinceDischarge =
        translateBoolOrStringToBool(
          formData.receivedServicesFromAnotherProviderSinceDischarge
        );
      const receivedServicesSinceDischarge = translateBoolOrStringToBool(
        formData.receivedServicesSinceDischarge
      );

      const dto: FollowUpCompletionData = {
        completedOn: new Date(formData.attemptedAt),
        livingStatus: formData.livingStatus as LivingSituationEnum,
        isYouthsLivingStatusAppropriate,
        schoolStatus: formData.schoolStatus as SchoolStatusEnum,
        receivedServicesFromAnotherProviderSinceDischarge,
        receivedServicesSinceDischarge,
        seenImprovement: formData.seenImprovement as FollowUpImprovementEnum
      };

      await updateFollowUpCompletion({ id: followUp.id, dto });

      clearForm();
      await onSubmit();
    }
  );

  useEffect(() => {
    clearGetProviderStaffMemberErrors();
    getProviderStaffMembers(caseDto.provider.id);
  }, [
    caseDto.provider.id,
    clearGetProviderStaffMemberErrors,
    getProviderStaffMembers,
  ]);

  useEffect(() => {
    if (!followUp) return;

    clearForm();
  }, [followUp, clearForm]);

  return {
    state: {
      livingSituationOptions,
      schoolStatusOptions,
      followUpImprovementOptions,
      staffMembers,
      updateFollowUpCompletionRequestState,
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
