import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { snapInSchoolsSessionSchema } from "../components/SnapInSchoolsSessionFormFields";
import { SnapInSchoolsCurriculumDto } from "../dtos/SnapInSchoolsCurriculumDto";
import { SnapInSchoolsCycleDto } from "../dtos/SnapInSchoolsCycleDto";
import { SnapInSchoolsSessionDto } from "../dtos/SnapInSchoolsSessionDto";
import {
  createSnapInSchoolsSessionFormDto,
  SnapInSchoolsSessionFormDto,
} from "../dtos/SnapInSchoolsSessionFormDto";
import { useGetSnapInSchoolsCurriculum } from "./snapInSchoolsHooks";

export const useSnapInSchoolsSessionForm = (
  onSubmit: (dto: SnapInSchoolsSessionFormDto) => Promise<void>,
  snapSession?: SnapInSchoolsSessionDto,
  snapCycle?: SnapInSchoolsCycleDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
    trigger,
  } = useForm({
    defaultValues: createSnapInSchoolsSessionFormDto(snapSession),
    resolver: yupResolver(snapInSchoolsSessionSchema),
    mode: "all",
  });

  const [getCurriculum, curriculumResult, getCurriculumRequestState] =
    useGetSnapInSchoolsCurriculum();
  const [curriculum, setCurriculum] = useState<SnapInSchoolsCurriculumDto[]>(
    []
  );
  const [isConfirmed, setIsConfirmed, handleToggleConfirmed] = useTogglable();
  //#endregion

  const clearForm = () => {
    reset(createSnapInSchoolsSessionFormDto(snapSession));
  };

  const handleOnSubmit = handleSubmit(
    async (dto: SnapInSchoolsSessionFormDto) => {
      await onSubmit(dto);
      clearForm();
    }
  );

  const handleFileUpdated = (filename: string) => {
    setValue("attendanceLogFilename", filename);
    trigger("attendanceLogFilename");
  };

  //#region Effects
  useEffect(() => {
    reset(createSnapInSchoolsSessionFormDto(snapSession));

    setIsConfirmed(false);
  }, [snapSession, reset, setIsConfirmed]);

  useEffect(() => {
    getCurriculum(undefined);
  }, [getCurriculum]);

  useEffect(() => {
    if (curriculumResult && snapCycle) {
      const currentlyUsed = snapCycle.sessions.map((x) => x.curriculum.id);

      setCurriculum(
        curriculumResult.filter(
          (x) =>
            !currentlyUsed.includes(x.id) || snapSession?.curriculum.id === x.id
        )
      );
    }
  }, [curriculumResult, snapCycle, setCurriculum, snapSession]);
  //#endregion
  return {
    state: { curriculum, getCurriculumRequestState, isConfirmed },
    form: {
      control,
      isValid: isValid && (snapSession === undefined || isConfirmed),
      clearForm,
    },
    handlers: {
      handleSubmit: handleOnSubmit,
      handleFileUpdated,
      handleToggleConfirmed,
    },
  };
};
