import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { Fragment } from "react";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import EditButton from "../../../app/components/buttons/EditButton";
import DateLabel from "../../../app/components/labels/DateLabel";
import { TabProps } from "../../../app/components/TabContainer";
import TableLoadingPlaceholder from "../../../app/components/TableLoadingPlaceholder";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { SnapInSchoolsSessionDto } from "../dtos/SnapInSchoolsSessionDto";
import { useSnapInSchoolsDetailPageContext } from "../pages/SnapInSchoolCyclesDetailPage";

export const snapInSchoolsCycleSessionsTabName =
  "snap-in-schools-cycle-sessions";

const SnapInSchoolsCycleSessionsSection = () => {
  const context = useSnapInSchoolsDetailPageContext();
  const { snapCycle, getSnapCycleRequestState } = context.snapCycle;
  const {
    handleCreateSnapSessionClicked,
    handleViewAttendanceLogDocument,
    handleUpdateSnapSessionClicked,
    handleDeleteSnapInSchoolsSessionClicked,
  } = context.sessions;

  const sortedSessions = (sessions: SnapInSchoolsSessionDto[]) => {
    return sessions.sort((a, b) =>
      new Date(a.sessionHeldOn) > new Date(b.sessionHeldOn) ? 1 : -1
    );
  };

  //#region UI Helpers
  const createRow = (session: SnapInSchoolsSessionDto) => {
    const renderViewButton = () => {
      if (!session.attendanceLogFilename) return;

      return (
        <EditButton
          text="Download Attendance Log"
          onClick={() => handleViewAttendanceLogDocument(session)}
        />
      );
    };
    return (
      <TableRow key={session.id}>
        <TableCell>
          <DateLabel date={session.sessionHeldOn} />
        </TableCell>
        <TableCell>{session.curriculum.description}</TableCell>
        <TableCell>{session.numberOfAttendees}</TableCell>
        <TableCell>{renderViewButton()}</TableCell>
        <TableCell>
          <WriteAccessLevelWrapper
            name={PermissionResourceNameEnum.SnapInSchools}
          >
            <EditButton
              onClick={() => handleUpdateSnapSessionClicked(session)}
            />
          </WriteAccessLevelWrapper>
        </TableCell>
        <DeleteAccessLevelWrapper
          name={PermissionResourceNameEnum.SnapInSchools}
        >
          <TableCell>
            <DeleteButton
              onClick={() => handleDeleteSnapInSchoolsSessionClicked(session)}
            />
          </TableCell>
        </DeleteAccessLevelWrapper>
      </TableRow>
    );
  };

  //#endregion

  if (snapCycle === undefined && !getSnapCycleRequestState.isLoading)
    return <Fragment></Fragment>;

  return (
    <Fragment>
      <Box marginBottom={"12px"}>
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <AddButton
            text="Create Session"
            onClick={handleCreateSnapSessionClicked}
            color="primary"
            variant="contained"
          />
        </WriteAccessLevelWrapper>
      </Box>
      <Box height={"57vh"}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Held On</TableCell>
              <TableCell>Session Name</TableCell>
              <TableCell>Attendees</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <DeleteAccessLevelWrapper
                name={PermissionResourceNameEnum.SnapInSchools}
              >
                <TableCell></TableCell>
              </DeleteAccessLevelWrapper>
            </TableRow>
          </TableHead>
          <TableBody>
            {snapCycle !== undefined && (
              <Fragment>
                {snapCycle.sessions.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>No Sessions Created</TableCell>
                  </TableRow>
                )}
                {sortedSessions(snapCycle.sessions).map(createRow)}
              </Fragment>
            )}
            {getSnapCycleRequestState.isLoading && (
              <TableLoadingPlaceholder rows={10} columns={5} />
            )}
          </TableBody>
        </Table>
      </Box>
    </Fragment>
  );
};

export default SnapInSchoolsCycleSessionsSection;

export const createSnapInSchoolsCycleSessionsSectionTab = (): TabProps => {
  return {
    value: snapInSchoolsCycleSessionsTabName,
    label: "Sessions",
    content: <SnapInSchoolsCycleSessionsSection />,
  };
};
