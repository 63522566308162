import { dateToFormString } from "../../../app/helpers";
import { SnapGroupSummaryDto } from "./SnapGroupSummaryDto";

export interface SnapGroupFormDto {
  groupHeldOn: string;
  isMakeupGroup: boolean;
  snapCurriculumId: string;
}

export const createSnapGroupFormDto = (
  dto?: SnapGroupSummaryDto
): SnapGroupFormDto => {
  if (dto) {
    const { groupHeldOn, isMakeupGroup, snapCurriculumId: curriculumId } = dto;
    return {
      isMakeupGroup: isMakeupGroup,
      groupHeldOn: dateToFormString(new Date(groupHeldOn)),
      snapCurriculumId: curriculumId,
    };
  }

  return {
    groupHeldOn: '',
    isMakeupGroup: false,
    snapCurriculumId: "",
  };
};
