import * as Yup from "yup";
export interface ProviderFilledBedDayDto {
  id: string;
  providerId: string;
  month: number;
  fiscalYear: number;
  filledDays: number;
}

export class ProviderFilledBedDayFormDto {
  month: number;
  fiscalYear: number;
  filledDays: number;

  constructor(month: number, fiscalYear: number, filledDays?: number) {
    this.month = month;
    this.fiscalYear = fiscalYear;
    this.filledDays = filledDays || 0;
  }

  public static getSchema() {
    return Yup.object().shape({
      filledDays: Yup.number()
        .label("Filled Bed Days")
        .typeError("Filled Bed Days is a required field.")
        .required()
        .min(0)
        .max(9999),
    });
  }
}
