import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  amount: number;
}

const CurrencyLabel: React.FC<Props> = ({ amount, ...props }) => {
  return (
    <Typography {...props}>
      ${amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
    </Typography>
  );
};

export default CurrencyLabel;
