import { useEffect, useState } from "react";
import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { addCasesToGroupModalId } from "../components/AddCasesToGroupFormDialog";
import { snapGroupFidelityModalId } from "../components/snap-group-fidelity/SnapGroupFidelityFormDialog";
import { AddCaseToSnapGroupFormDto } from "../dtos/AddCaseToSnapGroupFormDto";
import { SnapFidelityFormDto } from "../dtos/SnapFidelityFormDto";
import {
  useAddCasesToSnapGroup,
  useDeleteSnapGroup,
  useDeleteSnapGroupCase,
  useGetSnapGroup,
  useSeetMadeCompliancePhoneCall as useSetMadeCompliancePhoneCall,
  useUpsertSnapGroupFidelity,
} from "./snapHooks";
import { SnapGroupCaseSummaryDto } from "../dtos/SnapGroupCaseSummaryDto";
import { confirmSnapCaseRemovalDialogId } from "../components/ConfirmSnapCaseRemovalDialog";
import { confirmSnapGroupRemovalDialogId } from "../components/ConfirmSnapGroupRemovalDialog";
import { useSnapGroupCycleDetailPageContext } from "../pages/SnapGroupCycleDetailPage";
import { SetMadeCompliancePhoneCallDto } from "../dtos/SetMadeCompliancePhoneCallDto";
import { selectHasWriteAccess } from "../../authentication/state/authenticationSelectors";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";

const useMadeCompliancePhoneCall = ({
  refresh,
}: {
  refresh: () => Promise<void>;
}) => {
  const [setMadeCompliancePhoneCall, , setMadeCompliancePhoneCallRequestState] =
    useSetMadeCompliancePhoneCall();
  const dispatch = useAppDispatch();
  const canSetMadeCompliancePhoneCall = useAppSelector(
    selectHasWriteAccess(PermissionResourceNameEnum.Snap)
  );

  const handleToggleComplaincePhoneCall = async (
    caseDto: SnapGroupCaseSummaryDto
  ) => {
    const dto = new SetMadeCompliancePhoneCallDto(
      !caseDto.madeCompliancePhoneCall
    );
    const { id } = caseDto;
    await setMadeCompliancePhoneCall({ id, dto });
    dispatch(
      addSuccessNotification({ message: "Updated Made Compliance Phone Call" })
    );
    await refresh();
  };

  return {
    canSetMadeCompliancePhoneCall,
    handleToggleComplaincePhoneCall,
    toggleMadeCompliancePhoneCallRequestState:
      setMadeCompliancePhoneCallRequestState,
  };
};

export const useSnapGroupSection = () => {
  const context = useSnapGroupCycleDetailPageContext();
  const { snapCycle } = context.snapCycle;
  const { selectedGroupId, handleChangeSelectedGroup } = context.group;
  const { showDeleted } = context.ui;
  const { refresh, refreshAlerts } = context;
  const [selectedGroupCase, setSelectedGroupCase] =
    useState<SnapGroupCaseSummaryDto>();

  //#region State
  const [getSnapGroup, snapGroup, getSnapGroupRequestState] = useGetSnapGroup();
  const [
    addCasesToSnapGroup,
    ,
    addCasesToSnapGroupRequestState,
    clearAddCasesToSnapGroupErrors,
  ] = useAddCasesToSnapGroup();

  const [
    removeSnapGroupCase,
    ,
    removeSnapGroupCaseRequestState,
    clearRemoveSnapGroupCaseErrors,
  ] = useDeleteSnapGroupCase();

  const [removeSnapGroup, , removeSnapGroupRequestState] = useDeleteSnapGroup();

  const [
    upsertSnapGroupFidelity,
    ,
    upsertSnapGroupFidelityRequestState,
    clearUpsertSnapGroupFidelityErrors,
  ] = useUpsertSnapGroupFidelity();
  const dispatch = useAppDispatch();
  //#endregion

  const group = snapCycle!.groups.find((g) => g.id === selectedGroupId)!;

  //#region Handlers
  const handleAddCasesClicked = () => {
    clearAddCasesToSnapGroupErrors();
    dispatch(openModal({ modalId: addCasesToGroupModalId }));
  };

  const handleAddCases = async (dto: AddCaseToSnapGroupFormDto) => {
    await addCasesToSnapGroup({ id: group.id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Youth Added To Group." }));
      dispatch(closeModal());
    });
    await getSnapGroup(group.id);
  };

  const handleUpsertSnapGroupFidelityClicked = () => {
    clearUpsertSnapGroupFidelityErrors();
    dispatch(openModal({ modalId: snapGroupFidelityModalId }));
  };

  const handleUpsertSnapGroupFidelity = async (dto: SnapFidelityFormDto) => {
    await upsertSnapGroupFidelity({ id: group.id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Group Fidelity Updated" }));
      dispatch(closeModal());
    });
    await refreshAlerts();
    await getSnapGroup(group.id);
  };

  const handleRemoveSnapGroupCaseClicked = async (
    dto: SnapGroupCaseSummaryDto
  ) => {
    setSelectedGroupCase(dto);
    clearRemoveSnapGroupCaseErrors();
    dispatch(openModal({ modalId: confirmSnapCaseRemovalDialogId }));
  };

  const handleRemoveSnapGroupCase = async () => {
    await removeSnapGroupCase(selectedGroupCase!.id);
    batch(() => {
      dispatch(
        addSuccessNotification({ message: "Youth Removed from SNAP Group" })
      );
      dispatch(closeModal());
    });
    await getSnapGroup(group.id);
  };

  const handleDeleteSnapGroupClicked = async () => {
    clearRemoveSnapGroupCaseErrors();
    dispatch(openModal({ modalId: confirmSnapGroupRemovalDialogId }));
  };

  const handleDeleteSnapGroup = async () => {
    await removeSnapGroup(group.id);
    batch(() => {
      dispatch(addSuccessNotification({ message: "SNAP Group Deleted" }));
      dispatch(closeModal());
    });
    const remainingGroups = snapCycle!.groups?.filter((g) => g.id !== group.id);
    if (remainingGroups && remainingGroups.length) {
      handleChangeSelectedGroup(remainingGroups[0].id);
    } else handleChangeSelectedGroup("");
    await refresh();
  };

  useEffect(() => {
    if (snapCycle && group && !showDeleted && group.deletedAt != null) {
      const notDeletedGroups = snapCycle.groups.filter(
        (g) => g.deletedAt == null
      );
      handleChangeSelectedGroup(
        notDeletedGroups.length > 0 ? notDeletedGroups[0].id : ""
      );
    }
  }, [group, handleChangeSelectedGroup, showDeleted, snapCycle]);
  //#endregion

  //#region Effects

  useEffect(() => {
    if (group) getSnapGroup(group.id);
  }, [group, getSnapGroup]);
  //#endregion

  const {
    canSetMadeCompliancePhoneCall,
    handleToggleComplaincePhoneCall,
    toggleMadeCompliancePhoneCallRequestState,
  } = useMadeCompliancePhoneCall({
    refresh: async () => {
      getSnapGroup(group.id);
    },
  });
  return {
    state: {
      snapCycle,
      snapGroup,
      getSnapGroupRequestState,
      addCasesToSnapGroupRequestState,
      upsertSnapGroupFidelityRequestState,
      removeSnapGroupCaseRequestState,
      removeSnapGroupRequestState,
      showDeleted,
      selectedGroupCase,
      toggleMadeCompliancePhoneCallRequestState,
      canSetMadeCompliancePhoneCall,
    },
    handlers: {
      handleAddCases,
      handleAddCasesClicked,
      // SNAP Group Fidelity
      handleUpsertSnapGroupFidelityClicked,
      handleUpsertSnapGroupFidelity,
      handleRemoveSnapGroupCaseClicked,
      handleRemoveSnapGroupCase,
      handleDeleteSnapGroup,
      handleDeleteSnapGroupClicked,
      handleToggleComplaincePhoneCall,
    },
  };
};
