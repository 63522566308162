import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import UserDetailPage from "./pages/UserDetailPage";
import UserListPage from "./pages/UserListPage";

export const createUserRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.User}
    path={formatUrl(baseUrl, ":id")}
    component={UserDetailPage}
    key="user-detail"
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.User}
    path={formatUrl(baseUrl, "")}
    component={UserListPage}
    key="user-list"
  />,
];
