import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import StyledLink from "../../../app/components/StyledLink";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { YouthSummaryDto } from "../dto/YouthSummaryDto";

interface Props {
  youths: YouthSummaryDto[];
  control: Control<any>;
}

const YouthDuplicateMessage: React.FC<Props> = ({ youths, control }) => {
  if (youths && youths.length > 0)
    return (
      <Grid item md={12} xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Alert severity="warning">
            <Typography>
              {youths.length === 1 ? "A youth" : "Youths"} with this name and
              date of birth already exists.
            </Typography>
            <List disablePadding dense={true}>
              {youths.map((youth) => (
                <ListItem key={youth.id}>
                  <StyledLink
                    to={`/youths/${
                      youth.id
                    }?selectedProviderId=${getProviderId()}`}
                    target="_blank"
                  >
                    {youth.referenceNumber} - {youth.firstName} {youth.lastName}
                  </StyledLink>
                </ListItem>
              ))}
            </List>
            <Divider />
            <ControlledCheckbox
              control={control}
              name="forceSubmit"
              label="Proceed to create new youth record."
            />
          </Alert>
        </Box>
      </Grid>
    );
  return <Fragment></Fragment>;
};

export default YouthDuplicateMessage;
