export enum DateRangeType {
  YearToDate = "YearToDate",
  FiscalYearToDate = "FiscalYearToDate",
  Custom = "Custom",
}

interface DateRangeResponseDto {
  type: DateRangeType;
  startDate?: Date;
  endDate?: Date;
}

export type DateRangeRequestDto =
  | FiscalYearToDate
  | YearToDate
  | CustomDateRange;

export const createDateRangeRequestDto = (
  queryType: string,
  queryStartDate: string,
  queryEndDate: string
) => {
  const type = (queryType as string) || DateRangeType.FiscalYearToDate;

  if (type === DateRangeType.YearToDate) return { type } as YearToDate;

  if (type === DateRangeType.Custom) {
    const startDate = new Date(`${queryStartDate as string}T00:00`);
    const endDate = new Date(`${queryEndDate as string}T00:00`);
    return { type, startDate, endDate } as CustomDateRange;
  }

  return { type } as FiscalYearToDate;
};

interface FiscalYearToDate {
  type: DateRangeType.FiscalYearToDate;
}

interface YearToDate {
  type: DateRangeType.YearToDate;
}

interface CustomDateRange {
  type: DateRangeType.Custom;
  startDate: Date;
  endDate: Date;
}

export interface DashboardMetricsDto {
  dateRange: DateRangeResponseDto;
  dataGeneratedAt: Date;
}

export interface DashboardValueMetricsDto<T> extends DashboardMetricsDto {
  values: T;
}

export interface NumericValueMetric {
  title: string;
  description: string;
  value: number;
  linkId?: string;
}

export interface PieChartValueMetric {
  title: string;
  summaryValue: string;
  summaryValueLabel: string;
  values: NumericValueMetric[];
}

export interface MultiPieChartValueMetric {
  title: string;
  subtitle: string;
  summaryValue: string;
  summaryValueLabel: string;
  pie1Values: NumericValueMetric[];
  pie2Values: NumericValueMetric[];
}

export interface TextValueMetric {
  title: string;
  value: string;
  linkId?: string;
}

export interface MultiValueMetric {
  title: string;
  linkId?: string;
  stack: boolean;
  valueKeys: string[];
  values: { title: string; values: { [index: string]: number } }[];
}

export interface PercentageValueMetric {
  title: string;
  value: number;
  total: number;
  linkId?: string;
}

export interface MainDashboardMetricsDto {
  outreachEvents: NumericValueMetric;
  referrals: NumericValueMetric;
  screenings: NumericValueMetric;
  intakes: NumericValueMetric;
  nirvanaAssessmentsInitiated: NumericValueMetric;
  satisfactionSurveys: PieChartValueMetric;
  thirtyDayFollowUps: PieChartValueMetric;
  sixtyDayFollowUps: PieChartValueMetric;
}

export interface ResidentialDashboardMetricsDto {
  cinsFinsResidentialIntakes: NumericValueMetric;
  cinsFinsResidentialDischarges: NumericValueMetric;
  //cinsFinsResidentialFilledBedDays: SingleValueMetric,
  //cinsFinsResidentialIntakesAndDischarges,
  cinsFinsResidentialDischargeReasons: PieChartValueMetric;
}

export enum DataTypes {
  // Not intended for display, included to identify a reference type by id
  ReferenceId = "ReferenceId",
  // Basic text display
  Text = "Text",
  // Format as date only
  Date = "Date",
  // Format as date and time
  DateTime = "DateTime",
  // decode assessment type
  AssessmentTypeEnum = "AssessmentTypeEnum",
  PhoneNumber = "PhoneNumber",
  ReferralStatus = "ReferralStatus",
  RequestedServices = "RequestedServices",
}

export enum ReferenceTypes {
  Case = "Case",
  Youth = "Youth",
  Provider = "Provider",
  Assessment = "Assessment",
  Referral = "Referral",
  Screening = "Screening",
}

export interface DataFieldDefinitionDto {
  dataType: DataTypes;
  fieldLabel?: string;
  referenceIdField?: string;
  referenceType?: ReferenceTypes;
}

export interface DashboardMetricDataResponseDto extends DashboardMetricsDto {
  title: string;
  description: string;
  data: { [key: string]: any }[];
  dataFields: { [key: string]: DataFieldDefinitionDto };
  count: number;
  page: number;
  pageSize: number;
}

export interface DashboardFiltersDto {
  dateRange: DateRangeRequestDto;
}

export interface DashboardMetricsRequestDto extends DashboardFiltersDto {
  metricIds: readonly string[];
}
