import jwt from "jsonwebtoken";
import { JwtPayloadDto } from "./JwtPayloadDto";
import { PermissionDto } from "./PermissionDto";

export interface UserPermissionDto {
  [id: string]: { permissions: PermissionDto[]; name: string };
}

export const decryptPermissions = (token: string | null): UserPermissionDto => {
  const results: UserPermissionDto = {};
  if (token !== null) {
    const decoded = jwt.decode(token) as JwtPayloadDto;
    const obj = JSON.parse(decoded.permissions) as {
      [id: string]: { permissions: PermissionDto[]; name: string };
    };

    Object.entries(obj).forEach(([key, values]) => {
      if (values.name === null || values.name === undefined)
        values.name = "Unknown Provider";
      results[key] = values;
    });
  }

  return results;
};
