import { Box } from "@material-ui/core";
import React, { ErrorInfo } from "react";
import ClientExceptionRequestDto, {
  createClientExceptionRequestDto,
} from "./dtos/ClientExceptionRequestDto";
import { logClentException } from "./ErrorLoggingRequests";
import FallbackPage from "./pages/FallbackPage";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const response: ClientExceptionRequestDto = createClientExceptionRequestDto(
      error,
      errorInfo
    );
    logClentException(response);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Box marginTop="18px" height="calc(100vh - 100px)">
          <FallbackPage />
        </Box>
      );
    }

    return this.props.children;
  }
}
