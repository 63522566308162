import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum RemovalReasonEnum {
  InappropriateReferral = "InappropriateReferral",
  MedicalCondition = "MedicalCondition",
  RequiresInjectableMedications = "RequiresInjectableMedications",
  NoVacancy = "NoVacancy",
  ParentRefusesCooperate = "ParentRefusesCooperate",
  UnableToMeetSpecialNeeds = "UnableToMeetSpecialNeeds",
  YouthRefusesCooperate = "YouthRefusesCooperate",
  Other = "Other",
}

export const removalReasonTextMap: { [key: string]: string } = {
  [RemovalReasonEnum.InappropriateReferral]:
    "Inappropriate Referral for Program",
  [RemovalReasonEnum.MedicalCondition]: "Medical Condition",
  [RemovalReasonEnum.RequiresInjectableMedications]:
    "Youth Requires Injectable Medication(s)",
  [RemovalReasonEnum.NoVacancy]: "No Vacant Beds/Slots",
  [RemovalReasonEnum.ParentRefusesCooperate]: "Parent(s) Refuses to Cooperate",
  [RemovalReasonEnum.UnableToMeetSpecialNeeds]:
    "Program Unable to Meet Youth's Special Needs",
  [RemovalReasonEnum.YouthRefusesCooperate]: "Youth Refuses to Cooperate",
  [RemovalReasonEnum.Other]: "Other",
};

export const removalReasonOptions: SelectOption[] = [
  { value: "", label: "Select Removal Reason" },
  ...Object.keys(RemovalReasonEnum).map((status) => ({
    value: status,
    label: removalReasonTextMap[status],
  })),
];
