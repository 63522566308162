import { ReferralSearchParamsDto } from "./ReferralSearchParamsDto";

export class ReferralFilterFormDto {
  providerId?: string;
  status: string;
  constructor(dto?: ReferralSearchParamsDto) {
    this.providerId = dto?.providerId || "";
    this.status = dto?.status || "";
  }
}
