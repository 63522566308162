import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {EventLogSearchParamsDto} from "../dtos/EventLogSearchParamsDto";
import {EventLogFilterFormDto} from "../dtos/EventLogFilterFormDto";

export const useEventLogFilter = (
  onSubmit: (dto: EventLogSearchParamsDto) => void,
  onClose: () => void,
  params: EventLogSearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: new EventLogFilterFormDto(params),
  });
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: EventLogFilterFormDto) => {
    const newParams: EventLogSearchParamsDto = {
      startDate: dto.startDate ? new Date(`${dto.startDate}T00:00`) : undefined,
      endDate: dto.endDate ? new Date(`${dto.endDate}T00:00`) : undefined,
      providerId: dto.providerId,
      referenceType: dto.referenceType,
      affectedDeliverables: dto.affectedDeliverables,
      page: 0,
      pageSize: params.pageSize,
    };

    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new EventLogFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new EventLogFilterFormDto(params));
  }, [params, reset]);

  //#endregion

  return {
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
