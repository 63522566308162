import React, { Fragment } from "react";
import { AccessLevelEnum } from "../../../features/authentication/enums/AccessLevelEnum";
import { PermissionResourceNameEnum } from "../../../features/authentication/enums/PermissionResourceNameEnum";
import { selectHasAccess } from "../../../features/authentication/state/authenticationSelectors";
import { useAppSelector } from "../../hooks";

interface Props {
  name: PermissionResourceNameEnum;
  accessLevel: AccessLevelEnum;
  alternative?: React.ReactNode;
}

const AccessLevelWrapper: React.FC<Props> = ({
  name,
  accessLevel,
  children,
  alternative,
}) => {
  const hasAccessLevel = useAppSelector(selectHasAccess(name, accessLevel));

  if (hasAccessLevel) return <Fragment>{children}</Fragment>;

  return <Fragment>{alternative}</Fragment>;
};

export default AccessLevelWrapper;
