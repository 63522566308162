import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, { Fragment } from "react";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../../app/components/ErrorText";
import NameLabel from "../../../../app/components/labels/NameLabel";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectModalId } from "../../../modal/state/modalSelectors";
import { closeModal } from "../../../modal/state/modalSlice";
import ScreeningFormFields from "../../../screenings/components/ScreeningFormFields";
import { ScreeningDetailDto } from "../../../screenings/dtos/ScreeningDetailDto";
import { ScreeningFormDto } from "../../../screenings/dtos/ScreeningFormDto";
import { YouthDto } from "../../dto/YouthDto";
import { useYouthScreeningForm } from "../../hooks/useYouthScreeningForm";

export const youthScreeningFormDialogId = "youth-screening-form-dialog";

interface Props {
  requestState: RequestStateDto;
  youth?: YouthDto;
  screening?: ScreeningDetailDto;
  onSubmit(dto: ScreeningFormDto, startCase: boolean): Promise<void>;
}

const YouthScreeningFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  youth,
  screening,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { form, handlers, state } = useYouthScreeningForm(
    onSubmit,
    youth,
    undefined,
    screening
  );

  const {
    presentingProblems,
    getPresentingProblemsRequestState: {
      isLoading: presentingProblemsAreLoading,
      error: presentingProblemError,
    },
    cinsFinsEligibilityCriteria,
    requiresEligibleOption,
    requiresIneligibleOption,
    staffMembers,
    getProviderStaffMembersRequestState: {
      isLoading: staffMembersAreLoading,
      error: getStaffMemberError,
    },
    getProviderLocationsRequestState: {
      isLoading: providerLocationsAreLoading,
      error: getProviderLocationsError,
    },
    getProviderProgramsRequestState: {
      isLoading: providerProgramsAreLoading,
      error: getProviderProgramsError,
    },
    providerPrograms,
    locations,
    createWaitlist,
  } = state;
  const { control, isValid, clearForm } = form;
  const { handleSubmit, handleSubmitAndCreateCase } = handlers;
  const { isLoading, error } = requestState;
  //#endregion

  const filteredEligibilityCriteria = cinsFinsEligibilityCriteria
  .filter((x) => !x.isDisabled
    || (screening?.cinsFinsEligibilityCriteria 
        && screening.cinsFinsEligibilityCriteria.filter(c => c.id === x.id).length > 0));

  //#region Handlers
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion
  return (
    <Dialog
      open={modalId === youthScreeningFormDialogId}
      maxWidth="md"
      fullWidth
    >
      <form>
        <DialogTitle>
          {screening ? "Edit" : "Create Youth"} Screening{" "}
          {youth ? (
            <Fragment>
              for <NameLabel nameDto={youth} variant="inherit" />
            </Fragment>
          ) : (
            ""
          )}
          <ErrorText error={presentingProblemError?.message} />
          <ErrorText error={getStaffMemberError?.message} />
          <ErrorText error={error?.message} />
          <ErrorText error={getProviderLocationsError?.message} />
          <ErrorText error={getProviderProgramsError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <ScreeningFormFields
              control={control}
              disabled={isLoading}
              presentingProblems={presentingProblems}
              staffMembers={staffMembers}
              staffMembersAreLoading={staffMembersAreLoading}
              presentingProblemsAreLoading={presentingProblemsAreLoading}
              cinsFinsEligibilityCriteria={filteredEligibilityCriteria}
              requiresEligibleOption={requiresEligibleOption}
              requiresIneligibleOption={requiresIneligibleOption}
              locations={locations}
              providerPrograms={providerPrograms}
              providerLocationsAreLoading={providerLocationsAreLoading}
              providerProgramsAreLoading={providerProgramsAreLoading}
            />
          </Grid>
          <ErrorText error={error?.message} />
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            disabled={!isValid || isLoading}
            onClick={() => handleSubmit()}
          />
          {createWaitlist === false && screening == null && (
            <SpinnerButton
              showSpinner={isLoading}
              content="Save and Start Case"
              color="primary"
              onClick={() => handleSubmitAndCreateCase()}
              disabled={!isValid || isLoading}
            />
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default YouthScreeningFormDialog;
