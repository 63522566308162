import React from "react";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import ErrorAlert from "../../../app/components/ErrorAlert";
import { DashboardSelector } from "./DashboardSelector";
import { Box, Grid, Typography } from "@material-ui/core";
import { DashboardFiltersPanel } from "./DashboardFiltersPanel";
import { useDashboardContext } from "../DashboardContext";
import { DashboardMetricsDto } from "../dtos/MainDashboardMetricsDto";
import { Skeleton } from "@material-ui/lab";

interface Props {
  requestState: RequestStateDto;
  title: string;
  metricsData?: DashboardMetricsDto;
  hideSelector?: boolean;
}

export const DashboardHeaderPanel: React.FC<Props> = ({
  requestState,
  title,
  metricsData,
  hideSelector,
}) => {
  const { onChangeFilterClicked } = useDashboardContext();
  const { error, isLoading } = requestState;

  return (
    <>
      <style>
        {`

        #action-container {
          justify-content: flex-end
        }
      @media only screen and (max-width: 960px){
        #action-container {
          justify-content: flex-start
        }
      }
      `}
      </style>
      <ErrorAlert error={error?.message} />
      <Grid container style={{ marginBottom: "8px" }}>
        <Grid item md={6} sm={12}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            {isLoading && (
              <Typography variant={"h4"}>
                <Skeleton animation="wave" width={600} />
              </Typography>
            )}

            {!isLoading && <Typography variant={"h4"}>{title}</Typography>}
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box display={"flex"} id="action-container">
            <DashboardFiltersPanel
              data={metricsData}
              isLoading={isLoading}
              onChangeFilterClicked={onChangeFilterClicked}
            />
            {!hideSelector && <DashboardSelector isloading={isLoading} />}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
