import {
  Box, CircularProgress,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, {useMemo} from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import AlertLabel from "../../../app/components/labels/AlertLabel";
import GreenAlertLabel from "../../../app/components/labels/GreenAlertLabel";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import YellowAlertLabel from "../../../app/components/labels/YellowAlertLabel";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import {
  CaseFollowUpSummaryDto,
  CaseSinceLastServiceSummaryDto,
} from "../dtos/FollowUpDto";
import { FollowUpSearchParamsDto } from "../dtos/FollowUpSearchParamsDto";
import { FollowUpStatus, followUpStatusTextMap } from "../enums/FollowUpStatus";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import { useAnchor } from "../../../app/hooks/useAnchor";
import TimelineIcon from "@material-ui/icons/Timeline";
import PrintIcon from "@material-ui/icons/Print";
import GreyAlertLabel from "../../../app/components/labels/GreyAlertLabel";

const CasesSinceDischarge = ({
  summaries,
}: {
  summaries: CaseSinceLastServiceSummaryDto[];
}) => {
  const providerId = getProviderId();
  const [anchorEl, handleOpen, handleClose] = useAnchor();

  const renderRow = (summary: CaseSinceLastServiceSummaryDto) => {
    const caseNumber =
      providerId === summary.providerId ? (
        <RouteableStyledLink
          pathname={`/cases/${summary.id}`}
          text="Back to Follow Ups"
        >
          {summary.caseNumber}
        </RouteableStyledLink>
      ) : (
        summary.caseNumber
      );

    return (
      <TableRow key={summary.id}>
        <TableCell>{caseNumber}</TableCell>
        <TableCell>{summary.programTypeName}</TableCell>
        <TableCell>{summary.providerName}</TableCell>
        <TableCell>
          <DateTimeLabel date={summary.intakeAt} />
        </TableCell>
      </TableRow>
    );
  };

  if (summaries.length === 0) return <></>;

  return (
    <>
      <IconButton onClick={handleOpen} title="Services Since Discharge">
        <TimelineIcon />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <>
          <Box paddingLeft="16px" paddingRight="16px" paddingTop="8px">
            <Typography variant="h6">Services Since Discharge</Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Case #</TableCell>
                <TableCell>Program Type</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Intake At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{summaries.map(renderRow)}</TableBody>
          </Table>
        </>
      </Popover>
    </>
  );
};

interface Props {
  onPrintCaseFollowUpClick(id: string): Promise<void>;
  printInProgress: boolean;
}

const FollowUpList: React.FC<
  SortableTableProps<CaseFollowUpSummaryDto, FollowUpSearchParamsDto> & Props
> = ({ onPrintCaseFollowUpClick, printInProgress, params, ...props }) => {
  const isFnUser = getProviderId() === fnyfsProviderId;
  const columns = useMemo(() => {
    const options: ColumnProps[] = [
      { name: "caseNumber", label: "Case #" },
      {
        name: "youth",
        label: "Youth",
      },
      { name: "program", label: "Program", hidden: isFnUser },
      { name: "provider", label: "Provider", hidden: !isFnUser },
      { name: "programType", label: "Program Type", hidden: !isFnUser },
      {
        name: "daysSinceDischarge",
        label: <span style={{ marginLeft: "26px" }}>Days Since Discharge</span>,
        align: "center",
      },
      { name: "dischargeStaff", label: "Discharge Staff" },
      { name: "print", label: "" },
    ];

    if(params.includeThirtyDay) {
      options.push({ name: "30DayFollowUp", label: "30 Day Follow Up", disabled: true });
    }

    if(params.includeSixtyDay) {
      options.push({ name: "60DayFollowUp", label: "60 Day Follow Up", disabled: true });
    }

    if(params.includeNinetyDay) {
      options.push({ name: "90DayFollowUp", label: "90 Day Follow Up", disabled: true });
    }

    return options;
  }, [params.includeThirtyDay, params.includeSixtyDay, params.includeNinetyDay, isFnUser]);

  const createStatusLabel = (
    statusDays: number,
    daysSinceDischarge: number,
    status?: FollowUpStatus
  ) => {
    if (status === FollowUpStatus.Completed) {
      return <GreenAlertLabel label={followUpStatusTextMap[status]} />;
    }

    if (status === FollowUpStatus.Attempted) {
      return (
        <AlertLabel
          color={ColorsEnum.DarkGrey}
          label={followUpStatusTextMap[status]}
        />
      );
    }

    if (status === FollowUpStatus.InProgress) {
      return (
        <YellowAlertLabel
          style={{ color: "black" }}
          label={followUpStatusTextMap[status]}
        />
      );
    }

    if (status === FollowUpStatus.NotRequired) {
      return (
        <GreyAlertLabel
          style={{ color: "darkgray" }}
          label={followUpStatusTextMap[status]}
        />
      );
    }

    if (daysSinceDischarge < 30 || statusDays > daysSinceDischarge) {
      return (
        <AlertLabel
          style={{ color: "black" }}
          color={ColorsEnum.RowGrey}
          label={"Not Started"}
        />
      );
    }

    return <RedAlertLabel label={"Not Started"} />;
  };

  const renderRowFactory = (params: FollowUpSearchParamsDto) => (item: CaseFollowUpSummaryDto) => (
    <TableRow key={item.caseId}>
      <TableCell>
        <Box display={"flex"} alignItems={"center"}>
          <RouteableStyledLink
            pathname={`/cases/${item.caseId}`}
            text="Back to Follow Ups"
          >
            {item.caseNumber.split("-")[0]}
          </RouteableStyledLink>
          <CasesSinceDischarge summaries={item.caseSinceServiceSummaries} />
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <RouteableStyledLink
            pathname={`/youths/${item.youth.id}`}
            text="Back to Follow Ups"
          >
            {item.youth.firstName} {item.youth.lastName}
          </RouteableStyledLink>
        </Box>
      </TableCell>
      <ProviderAccessWrapper>
        <TableCell>{item.program.name}</TableCell>
      </ProviderAccessWrapper>
      <FnAccessWrapper>
        <TableCell>{item.provider.name}</TableCell>
        <TableCell>{item.program.programType.name}</TableCell>
      </FnAccessWrapper>
      <TableCell align="center">{item.daysSinceDischarge}</TableCell>
      <TableCell>{item.dischargeStaff.firstName} {item.dischargeStaff.lastName}</TableCell>
      <TableCell width="50px">
        <IconButton
          title="Print form to use for this Case"
          disabled={printInProgress}
          onClick={() => onPrintCaseFollowUpClick(item.caseId)}
        >
          <Box display={"flex"} alignItems={"center"} justifyContent={"middle"}>{ printInProgress ? <CircularProgress size={24} /> : <PrintIcon /> }</Box>
        </IconButton>
      </TableCell>
      { (params.includeThirtyDay) && <TableCell>
        {createStatusLabel(
          30,
          item.daysSinceDischarge,
          item.thirtyDayFollowUpStatus
        )}
      </TableCell> }
      { (params.includeSixtyDay) && <TableCell>
        {createStatusLabel(
          60,
          item.daysSinceDischarge,
          item.sixtyDayFollowUpStatus
        )}
      </TableCell> }
      { (params.includeNinetyDay) && <TableCell>
        {createStatusLabel(
          90,
          item.daysSinceDischarge,
          item.ninetyDayFollowUpStatus
        )}
      </TableCell> }
    </TableRow>
  );

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} params={params} columns={columns} renderRow={renderRowFactory(params)} />
    </TableContainer>
  );
};

export default FollowUpList;
