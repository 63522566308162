import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { staffMemberSchema } from "../components/StaffMemberFormFields";
import { StaffMemberDto } from "../dtos/StaffMemberDto";
import {
  createStaffMemberFormDto,
  StaffMemberFormDto,
} from "../dtos/StaffMemberFormDto";

export const useStaffMemberForm = (
  onSubmit: (dto: StaffMemberFormDto) => Promise<any>,
  staff?: StaffMemberDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createStaffMemberFormDto(staff),
    resolver: yupResolver(staffMemberSchema),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(createStaffMemberFormDto(staff));
  };

  const handleOnSubmit = handleSubmit(async (dto: StaffMemberFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  useEffect(() => {
    reset(createStaffMemberFormDto(staff));
  }, [reset, staff]);

  return {
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
