import React, {Fragment, useCallback, useEffect, useState} from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import {PermissionResourceNameEnum} from "../../authentication/enums/PermissionResourceNameEnum";
import {useAppSelector} from "../../../app/hooks";
import {selectHasDeleteAccess, selectHasExecuteAccess, selectHasWriteAccess} from "../../authentication/state/authenticationSelectors";

const buildUpdateScreeningAction = (onClick: () => void) => {
  return (
    <MenuItem onClick={onClick} key="update-screening-menu-item">
      Edit Screening
    </MenuItem>
  );
};

const buildTransferScreeningAction = (onClick: () => void) => {
  return (
    <MenuItem onClick={onClick} key="transfer-screening-menu-item">
      Transfer Screening
    </MenuItem>
  );
};

const buildDeleteScreeningAction = (onClick: () => void) => {
  return (
    <MenuItem onClick={onClick} key="delete-screening-menu-item">
      Delete Screening
    </MenuItem>
  );
};

interface Props {
  onUpdateScreeningClicked(): void;
  onTransferScreeningClicked(): void;
  onDeleteScreeningClicked(): void;
}

const ScreeningDetailAdditionalActions: React.FC<Props> = ({
  onUpdateScreeningClicked,
  onTransferScreeningClicked,
  onDeleteScreeningClicked
}) => {
  const [menuItems, setMenuItems] = useState<React.ReactNode[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const canWriteScreenings = useAppSelector(
    selectHasWriteAccess(PermissionResourceNameEnum.Screening)
  );
  const canTransferScreening = useAppSelector(selectHasExecuteAccess(PermissionResourceNameEnum.TransferCase));
  const canDeleteScreening = useAppSelector(selectHasDeleteAccess(PermissionResourceNameEnum.Screening));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleAction = useCallback(
    (action: () => void) => () => {
      action();
      handleClose();
    },
    [handleClose]
  );
  useEffect(() => {
    const menuItems: React.ReactNode[] = [];

    if (canWriteScreenings) {
      const updateScreeningItem = buildUpdateScreeningAction(
        handleAction(onUpdateScreeningClicked)
      );

      if (updateScreeningItem) menuItems.push(updateScreeningItem);
    }

    if(canTransferScreening) {
      const transferScreeningItem = buildTransferScreeningAction(
        handleAction(onTransferScreeningClicked)
      );

      menuItems.push(transferScreeningItem);
    }

    if (canDeleteScreening) {
      const deleteScreeningItem = buildDeleteScreeningAction(
        handleAction(onDeleteScreeningClicked)
      );

      menuItems.push(deleteScreeningItem);
    }

    setMenuItems(menuItems);
  }, [
    setMenuItems,
    handleAction,
    onUpdateScreeningClicked,
    onTransferScreeningClicked,
    onDeleteScreeningClicked,
    canWriteScreenings,
    canTransferScreening,
    canDeleteScreening
  ]);

  if (menuItems.length === 0) return <Fragment></Fragment>;

  return (
    <Fragment>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon></MoreVertIcon>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </Fragment>
  );
};

export default ScreeningDetailAdditionalActions;
