import {useRequest} from "../../app/hooks/useRequest";
import {addServicePlanReviewRequest} from "../ServicePlanRequests";
import {useServicePlanPageContext} from "../ServicePlanPageContext";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {AddServicePlanReviewDto} from "../dto/AddServicePlanReviewDto";
import { DateTime } from "luxon"

interface AddServicePlanReviewFormData {
  [index: string]: any;
  reviewedOn: string;
  notes: string;
  youthParticipated: boolean;
  counselorParticipated: boolean;
}

const schema = Yup.object().shape({
  reviewedOn: Yup.string().required().label("Plan Reviewed On"),
  notes: Yup.string().optional(),
  youthParticipated: Yup.boolean(),
  counselorAtReview:Yup.string(),
  counselorParticipated: Yup.boolean()
}).test(
  'participantSelected',
  'At least one review participant must be selected.',
  async (value, testContext) => {
    return Object.keys(value).some(key => value[key] === true)
  },
);

export const useAddServicePlanReviewForm = () => {

  const { servicePlan, servicePlanUpdated  } = useServicePlanPageContext();

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<AddServicePlanReviewFormData>({
    defaultValues: {
      reviewedOn: "",
      notes: "",
      youthParticipated: false,
      counselorParticipated: false
    },
    resolver: yupResolver(schema),
    mode: "all",
  });


  const clearForm = () => {
    reset({
      reviewedOn: "",
      notes: "",
      youthParticipated: false,
      counselorParticipated: false
    });
  };



  const [addReview, , addReviewRequestState] = useRequest<AddServicePlanReviewDto, void>(
    (dto) => addServicePlanReviewRequest(servicePlan.id, dto)
  );

  const handleOnSubmit = handleSubmit(
    async (formData: AddServicePlanReviewFormData) => {

      const dto: AddServicePlanReviewDto = {
        reviewedOn: DateTime.fromFormat(formData.reviewedOn, "yyyy-MM-dd").toJSDate(),
        notes: formData.notes,
        counselorParticipated: formData.counselorParticipated,
        youthParticipated: formData.youthParticipated,
        counselorAtReview: servicePlan.counselor.id,
        otherParticipants: []
      }

      const otherParticipants = Object.keys(formData)
        .map(key => {
          const participant = servicePlan.participants.find(p => p.id === key);
          if(participant && formData[key] === true) {
            return { role: participant.role, name: participant.name }
          }
          return null
        })
        .filter(val => val != null)

      for (const otherParticipant of otherParticipants) {
        if(otherParticipant) {
          dto.otherParticipants.push(otherParticipant)
        }
      }

      await addReview(dto);
      clearForm();
      await servicePlanUpdated();
    }
  );

  return {
    state: {
      addReviewRequestState
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };

}
