import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import React, { useCallback, useEffect, useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import { useSnapGroupCycleDetailPageContext } from "../pages/SnapGroupCycleDetailPage";

const buildDeleteSnapGroupCycleAction = (
  onClick: () => void,
  cycleDto?: SnapGroupCycleDto
) => {
  if (cycleDto === undefined) return undefined;

  return (
    <MenuItem onClick={onClick} key="snap-group-cycle-delete-menu-item">
      Delete SNAP Group Cycle
    </MenuItem>
  );
};

const SnapGroupCycleAdditionalActions: React.FC = () => {
  const [menuItems, setMenuItems] = useState<React.ReactNode[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const context = useSnapGroupCycleDetailPageContext();
  const { snapCycle, handleDeleteSnapGroupCycleClicked } = context.snapCycle;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleAction = useCallback(
    (action: () => void) => () => {
      action();
      handleClose();
    },
    [handleClose]
  );

  useEffect(() => {
    const menuItems: React.ReactNode[] = [];

    const deleteCaseItem = buildDeleteSnapGroupCycleAction(
      handleAction(handleDeleteSnapGroupCycleClicked),
      snapCycle
    );

    if (deleteCaseItem) menuItems.push(deleteCaseItem);

    setMenuItems(menuItems);
  }, [
    snapCycle,
    setMenuItems,
    handleAction,
    handleDeleteSnapGroupCycleClicked,
  ]);

  if (menuItems.length === 0) return <></>;

  return (
    <DeleteAccessLevelWrapper name={PermissionResourceNameEnum.Snap}>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </DeleteAccessLevelWrapper>
  );
};

export default SnapGroupCycleAdditionalActions;
