import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { calculateAge } from "../../../app/helpers";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { CaseHoldDto } from "../../case-hold/dtos/CaseHoldDto";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { useValidateNirvanaPostAssessment } from "../../cases/hooks/caseHooks";
import { useGetProviderStaffMembers } from "../../provider/hooks/providerHooks";
import { YouthSummaryDto } from "../../youths/dto/YouthSummaryDto";
import { DischargeFormDto } from "../dtos/DischargeFormDto";

export const useDischargeForm = (
  onSubmit: (dto: DischargeFormDto) => Promise<void>,
  caseDto?: CaseDetailDto,
  youth?: YouthSummaryDto,
  caseHold?: CaseHoldDto
) => {
  //#region State
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: new DischargeFormDto(caseHold, caseDto?.dischargeInfo),
    mode: "all",
    resolver: yupResolver(DischargeFormDto.getSchema(caseDto!)),
  });
  const [
    getProviderStaffMembers,
    staffMembers,
    getProviderStaffMemberRequestState,
  ] = useGetProviderStaffMembers();

  const [needsPostAssessment, setNeedsPostAssessment] =
    useState<boolean>(false);
  const [
    validateNirvanaPostAssessment,
    ,
    validateNirvanaPostAssessmentRequestState,
  ] = useValidateNirvanaPostAssessment();

  const { dischargedAt, isEdit } = watch();

  const [isConfirmed, setIsConfirmed, toggleIsConfirmed] = useTogglable(false);
  //#endregion

  //#region Handlers
  const clearForm = () => {
    setNeedsPostAssessment(false);
    reset(new DischargeFormDto(caseHold, caseDto?.dischargeInfo));
    setIsConfirmed(false);
  };

  const handleClearPostAssessmentRequired = () => setNeedsPostAssessment(false);
  const handleOnSubmit = handleSubmit(async (dto: DischargeFormDto) => {
    const result = await validateNirvanaPostAssessment({
      id: caseDto!.id,
      dto: { dischargedAt: dto.dischargedAt },
    });

    if (result) {
      dto.employmentStatus =
        dto.employmentStatus === "" ? undefined : dto.employmentStatus;
      await onSubmit(dto);
    }

    setNeedsPostAssessment(!result);
  });

  //#endregion

  //#region Effects
  useEffect(() => {
    const providerId = getProviderId();
    if (providerId) {
      getProviderStaffMembers(providerId);
    }
  }, [getProviderStaffMembers]);

  useEffect(() => {
    const employmentStatusRequired =
      youth !== undefined &&
      calculateAge(new Date(youth.dob), new Date(dischargedAt)) >= 14;

    setValue("employmentStatusRequired", employmentStatusRequired);
    if (employmentStatusRequired === false) clearErrors("employmentStatus");
  }, [dischargedAt, youth, setValue, clearErrors]);

  useEffect(() => {
    reset(new DischargeFormDto(caseHold, caseDto?.dischargeInfo));
    setIsConfirmed(false);
  }, [reset, caseHold, caseDto, setIsConfirmed]);
  //#endregion

  return {
    state: {
      staffMembers,
      getProviderStaffMemberRequestState,
      validateNirvanaPostAssessmentRequestState,
      needsPostAssessment,
      isEdit,
      isConfirmed,
    },
    form: { control, isValid: isValid && (!isEdit || isConfirmed), clearForm },
    handlers: {
      handleSubmit: handleOnSubmit,
      handleClearPostAssessmentRequired,
      toggleIsConfirmed,
    },
  };
};
