import { useRequest } from "../../../app/hooks/useRequest";
import {
  createAssessmentOverrideReasonRequest,
  createAssessmentRequest,
  getAssessmentTemplatesRequest, verifyAssessmentRequest,
} from "../AssessmentRequests";

export const useCreateAssessment = () => useRequest(createAssessmentRequest);

export const useGetAssessmentTemplates = () =>
  useRequest(getAssessmentTemplatesRequest);

export const useCreateAssessmentOverrideReason = () =>
  useRequest(createAssessmentOverrideReasonRequest);

export const useVerifyAssessment = () =>
  useRequest(verifyAssessmentRequest);
