import {
  Avatar,
  Box,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";
import Section from "../../../../app/components/Section";
import { StaffMemberDto } from "../../../staff-members/dtos/StaffMemberDto";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EditButton from "../../../../app/components/buttons/EditButton";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import { ColorsEnum } from "../../../../app/enums/ColorsEnum";
import DeleteButton from "../../../../app/components/buttons/DeleteButton";
interface Props {
  staffMember: StaffMemberDto;
  onEdit: () => void;
  onActivate: () => void;
  onDeactive: () => void;
  activateIsRunning: boolean;
}

export const StaffMemberListLoadingItem = () => {
  return (
    <Section>
      <Box display="flex">
        <Typography variant="h6">
          <Skeleton variant="text" width="200px" />
        </Typography>
      </Box>
    </Section>
  );
};

const StaffMemberListItem: React.FC<Props> = ({
  staffMember,
  onEdit,
  onActivate,
  onDeactive,
  activateIsRunning,
}) => {
  return (
    <ListItem>
      <ListItemIcon>
        <Avatar>
          <AccountBoxIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={
          <Fragment>
            {staffMember.firstName} {staffMember.lastName}
            {staffMember.isInactive && (
              <Fragment>
                {" - "}
                <Typography color="textSecondary" component="span">
                  Deactivated
                </Typography>
              </Fragment>
            )}
          </Fragment>
        }
      />
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.StaffMember}>
        <ListItemSecondaryAction>
          {staffMember.isInactive && (
            <Fragment>
              <SpinnerButton
                content="Activate"
                showSpinner={activateIsRunning}
                style={{ color: ColorsEnum.Blue }}
                onClick={onActivate}
              />
            </Fragment>
          )}
          {staffMember.isInactive === false && (
            <Fragment>
              <EditButton onClick={onEdit} />
              <DeleteButton onClick={onDeactive} text="Deactivate" />
            </Fragment>
          )}
        </ListItemSecondaryAction>
      </WriteAccessLevelWrapper>
    </ListItem>
  );
};

export default StaffMemberListItem;
