import { Tooltip, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import AlertLabel from "../../../app/components/labels/AlertLabel";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import GreenAlertLabel from "../../../app/components/labels/GreenAlertLabel";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import YellowAlertLabel from "../../../app/components/labels/YellowAlertLabel";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { AssessmentDto } from "../dtos/AssessmentDto";
import { AssessmentStatusEnum } from "../enums/AssessmentStatusEnum";

interface Props {
  assessment?: AssessmentDto;
}

const AssessmentSubmittedStatusLabel = ({ assessment }: Props) => {
  return (
    <Tooltip
      title={
        <Fragment>
          <Typography>
            Submitted By: <strong>{assessment!.submittedBy}</strong>
          </Typography>
          <Typography>
            Submitted At:{" "}
            <strong>
              <DateTimeLabel date={assessment!.submittedAt} />
            </strong>
          </Typography>
        </Fragment>
      }
    >
      <span style={{ cursor: "pointer" }}>
        <GreenAlertLabel label="Submitted" />
      </span>
    </Tooltip>
  );
};

const AssessmentVoidedStatusLabel = ({ assessment }: Props) => {
  return (
    <Tooltip
      title={
        <Fragment>
          <Typography>
            Voided By: <strong>{assessment!.voidedBy}</strong>
          </Typography>
          <Typography>
            Voided At:{" "}
            <strong>
              <DateTimeLabel date={assessment!.voidedAt} />
            </strong>
          </Typography>
        </Fragment>
      }
    >
      <span style={{ cursor: "pointer" }}>
        <RedAlertLabel variant="outlined" label="Voided" />
      </span>
    </Tooltip>
  );
};

const AssessmentStatusLabel = ({ assessment }: Props) => {
  if (assessment == null)
    return <AlertLabel color={ColorsEnum.DarkGrey} label={"Not Started"} />;

  switch (assessment.status) {
    case AssessmentStatusEnum.InProgress:
      return (
        <YellowAlertLabel label="In Progress" style={{ color: "black" }} />
      );
    case AssessmentStatusEnum.Submitted:
      return <AssessmentSubmittedStatusLabel assessment={assessment} />;
    case AssessmentStatusEnum.Voided:
      return <AssessmentVoidedStatusLabel assessment={assessment} />;
    default:
      return <Fragment></Fragment>;
  }
};

export default AssessmentStatusLabel;
