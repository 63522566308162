import { TextFieldProps } from "@material-ui/core";
import React from "react";
import { Control } from "react-hook-form";
import NumberFormat from "react-number-format";
import ControlledInput from "./ControlledInput";

interface Props {
  control: Control<any>;
  name: string;
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CircuitNumberInput: React.FC<NumberFormatCustomProps> = ({
  inputRef,
  onChange,
  ...props
}) => {
  return (
    <NumberFormat
      variant="outlined"
      isNumericString
      {...props}
      getInputRef={inputRef}
      allowNegative={false}
      decimalScale={0}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
};

const ControlledWholeNumberInput: React.FC<Props & TextFieldProps> = (
  props
) => {
  return (
    <ControlledInput
      {...props}
      InputProps={{
        inputComponent: CircuitNumberInput as any,
      }}
    />
  );
};

export default ControlledWholeNumberInput;
