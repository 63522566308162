import React, { Fragment } from "react";
import AddButton from "../../../app/components/buttons/AddButton";
import YouthList from "../components/YouthList";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Box } from "@material-ui/core";
import YouthFilter from "../components/YouthFilter";
import YouthFormDialog from "../components/YouthFormDialog";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { useYouthListPage } from "../hooks/useYouthListPage";
import Header from "../../../app/components/Header";
import YouthFilterList from "../components/YouthFilterList";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { YouthSummaryDto } from "../dto/YouthSummaryDto";
import FilterButton from "../../../app/components/buttons/FilterButton";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";

const YouthListPage = () => {
  const { state, handlers } = useYouthListPage();
  const {
    params,
    filterAnchorEl,
    getYouthsRequestState,
    youthResults,
    createYouthRequestState,
  } = state;
  const {
    handleCreateYouth,
    handleCloseFilter,
    handleSearch,
    handleCreateYouthClicked,
    handleOpenFilterClicked,
    handlePageChange,
    handleSort,
  } = handlers;

  return (
    <Fragment>
      <YouthFormDialog
        onSubmit={handleCreateYouth}
        requestState={createYouthRequestState}
      />
      <YouthFilter
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilter}
        params={params}
        onSearch={handleSearch}
      />
      <RequestErrorAlert requests={[getYouthsRequestState]} />

      <Header
        title="Youth"
        isLoading={getYouthsRequestState.isLoading}
        actions={
          <Box>
            <FilterButton
              title="Filter List"
              onClick={handleOpenFilterClicked}
              disabled={getYouthsRequestState.isLoading}
              variant="outlined"
              startIcon={<FilterListIcon />}
            />
            <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Youth}>
              <AddButton
                title="Create Youth"
                color="primary"
                text="Create Youth"
                onClick={handleCreateYouthClicked}
                variant="contained"
                className="ml-2"
                disabled={
                  getYouthsRequestState.isLoading ||
                  createYouthRequestState.isLoading
                }
              />
            </WriteAccessLevelWrapper>
          </Box>
        }
        secondaryActions={
          <YouthFilterList
            params={params}
            onSearch={handleSearch}
            isLoading={getYouthsRequestState.isLoading}
          />
        }
      />
      <YouthList
        results={youthResults || new PaginationResultDto<YouthSummaryDto>()}
        isLoading={getYouthsRequestState.isLoading}
        onPageChange={handlePageChange}
        onSort={handleSort}
        params={params}
      />
    </Fragment>
  );
};

export default YouthListPage;
