import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { DeliverableRateDto } from "../../../../deliverable-rates/dtos/DeliverableRateDto";
import CurrencyLabel from "../../../../../app/components/labels/CurrencyLabel";
import EditButton from "../../../../../app/components/buttons/EditButton";
import WriteAccessLevelWrapper from "../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../../authentication/enums/PermissionResourceNameEnum";
import DateLabel from "../../../../../app/components/labels/DateLabel";
import InsertChartOutlinedOutlinedIcon from "@material-ui/icons/InsertChartOutlinedOutlined";
import { Skeleton } from "@material-ui/lab";
import InfoTooltip from "../../../../../app/components/InfoTooltip";
import DeleteAccessLevelWrapper from "../../../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import DeleteButton from "../../../../../app/components/buttons/DeleteButton";
import { ColorsEnum } from "../../../../../app/enums/ColorsEnum";
export const DeliverableRateListLoadingItem = () => {
  return (
    <Paper variant="outlined">
      <Box display="flex" alignItems="center" padding="12px">
        <InsertChartOutlinedOutlinedIcon
          style={{
            marginRight: "6px",
            width: "32px",
            height: "32px",
          }}
        />
        <Typography>
          <Skeleton animation="wave" width={"300px"} />
        </Typography>
        <IconButton size="small" style={{ marginLeft: "auto" }} disabled={true}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

interface Props {
  expanded: boolean;
  rates: DeliverableRateDto[];
  onChange(): void;
  onChangeClick(): void;
  onDeleteClick(deliverableRateId: string): void;
}

const DeliverableRateListItem: React.FC<Props> = ({
  expanded,
  rates,
  onChange,
  onChangeClick,
  onDeleteClick,
}) => {
  const getCurrentRate = () => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    return rates.find((rate) => {
      const startsOn = new Date(rate.startsOn);
      const endsOn = rate.endsOn ? new Date(rate.endsOn) : undefined;

      return startsOn <= today && (endsOn === undefined || endsOn >= today);
    });
  };

  const getCanDeleteRate = (rate: DeliverableRateDto) => {
    const today = new Date();

    const startsOn = new Date(rate.startsOn);
    return (
      today.getMonth() <= startsOn.getMonth() &&
      today.getFullYear() <= startsOn.getFullYear()
    );
  };

  const currentRate = getCurrentRate();

  return (
    <Paper variant="outlined">
      <Box display="flex" alignItems="center" padding="12px">
        <InsertChartOutlinedOutlinedIcon
          style={{
            marginRight: "6px",
            width: "32px",
            height: "32px",
          }}
        />
        <Typography>
          [{currentRate?.contract.name}]{" "}
          {currentRate?.programType.name}{" "}
          {currentRate?.deliverableType.name}
          {" "}
          - Provider Rate:{" "}
          <CurrencyLabel
            amount={currentRate?.providerAmount || 0}
            component="span"
          />{" "}
          | Florida Network Rate:{" "}
          <CurrencyLabel
            amount={currentRate?.fnyfsAmount || 0}
            component="span"
          />{" "}
        </Typography>
        <IconButton
          size="small"
          onClick={onChange}
          style={{ marginLeft: "auto" }}
        >
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
        <Box display="flex" justifyContent="space-between" padding="12px">
          <Typography component="span">Rate Effective Dates</Typography>
          <WriteAccessLevelWrapper
            name={PermissionResourceNameEnum.DeliverableRate}
          >
            <EditButton
              title="Update Rate"
              text="Update Rate"
              onClick={onChangeClick}
            />
          </WriteAccessLevelWrapper>
        </Box>
        <Box padding="12px">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>

                <TableCell>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    Start Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontWeight: "bold" }}>
                    End Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontWeight: "bold" }}>
                    Provider Rate
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontWeight: "bold" }}>
                    Florida Network Rate
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rates
                .sort((a, b) =>
                  new Date(a.startsOn) > new Date(b.startsOn) ? -1 : 1
                )
                .map((rate) => {
                  const isCurrentRate = rate.id === currentRate?.id;

                  const rowStyle = isCurrentRate
                    ? { backgroundColor: ColorsEnum.RowGrey }
                    : {};

                  const cellStyle: React.CSSProperties = isCurrentRate
                    ? { fontWeight: "bold" }
                    : {};

                  const canDelete = getCanDeleteRate(rate);
                  return (
                    <TableRow key={rate.id} style={rowStyle}>
                      <TableCell>
                        {isCurrentRate && (
                          <InfoTooltip
                            title={
                              <Typography component="span">
                                Current Rate
                              </Typography>
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography style={cellStyle} component="span">
                          <DateLabel date={rate.startsOn} />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={cellStyle} component="span">
                          {rate.endsOn && <DateLabel date={rate.endsOn} />}
                          {rate.endsOn === undefined && (
                            <span style={{ marginLeft: "32px" }}>--</span>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <CurrencyLabel
                          amount={rate.providerAmount}
                          style={cellStyle}
                          component="span"
                        />
                      </TableCell>
                      <TableCell>
                        <CurrencyLabel
                          amount={rate.fnyfsAmount}
                          style={cellStyle}
                          component="span"
                        />
                      </TableCell>
                      <TableCell>
                        <DeleteAccessLevelWrapper
                          name={PermissionResourceNameEnum.DeliverableRate}
                        >
                          {canDelete && (
                            <DeleteButton
                              onClick={() => onDeleteClick(rate.id)}
                            />
                          )}
                        </DeleteAccessLevelWrapper>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default DeliverableRateListItem;
