import { SelectProps } from "@material-ui/core";
import React from "react";
import { Control } from "react-hook-form";
import ControlledSelect, {
  SelectGroup,
} from "../../../app/components/inputs/ControlledSelect";
import { getProgramOptions, ProgramDto } from "../dtos/ProgramDto";
import { ProgramFundingSourceEnum } from "../enums/ProgramFundingSourceEnum";

interface Props extends SelectProps {
  programs: ProgramDto[];
  defaultOptionLabel: string;
  disabled: boolean;
  control: Control<any>;
  isLoading: boolean;
  name: string;
  label: string;
  size?: "small" | "medium";
}

const ProgramControlledSelect: React.FC<Props> = ({
  programs,
  defaultOptionLabel,
  disabled,
  isLoading,
  control,
  name,
  label,
  ...props
}) => {
  const getFnyfsFundedProgramOptions = () => {
    return getProgramOptions(
      sortPrograms(
        programs.filter(
          (x) => x.fundingSource === ProgramFundingSourceEnum.FloridaNetwork
        )
      )
    );
  };

  const sortPrograms = (programs: ProgramDto[]) => {
    return programs.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const getNonFnyfsFundedProgramOptions = () => {
    return getProgramOptions(
      sortPrograms(
        programs.filter(
          (x) => x.fundingSource !== ProgramFundingSourceEnum.FloridaNetwork
        )
      )
    );
  };

  const getProgramOptionsGroups = () => {
    const groups: SelectGroup[] = [];

    const fnyfsProgramOptions = getFnyfsFundedProgramOptions();
    if (fnyfsProgramOptions.length) {
      groups.push({
        name: "Florida Network Funded Programs",
        options: fnyfsProgramOptions,
      });
    }

    const nonFnyfsPrograms = getNonFnyfsFundedProgramOptions();
    if (nonFnyfsPrograms.length) {
      groups.push({
        name: "Non-Florida Network Funded Programs",
        options: nonFnyfsPrograms,
      });
    }

    return groups;
  };

  const defaultOption = {
    value: "",
    label: defaultOptionLabel,
  };

  return (
    <ControlledSelect
      control={control}
      disabled={disabled}
      name={name}
      options={[defaultOption]}
      groups={getProgramOptionsGroups()}
      label={label}
      isLoading={isLoading}
      fullWidth
      {...props}
    />
  );
};

export default ProgramControlledSelect;
