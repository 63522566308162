import { useRequest } from "../../../app/hooks/useRequest";
import {
  activateStaffMemberRequest,
  createStaffMemberRequest,
  deactivateStaffMemberRequest,
  updateStaffMemberRequest,
} from "../StaffMemberRequests";

export const useCreateStaff = () => useRequest(createStaffMemberRequest);
export const useUpdateStaff = () => useRequest(updateStaffMemberRequest);
export const useActivateStaff = () => useRequest(activateStaffMemberRequest);
export const useDeactivateStaff = () =>
  useRequest(deactivateStaffMemberRequest);
