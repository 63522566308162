import React from "react";
import {
  FormControl,
  MenuItem,
  InputLabel,
  CircularProgress,
  ListSubheader,
  Divider,
  Checkbox,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";
import { SelectProps } from "@material-ui/core";
import InputErrorText from "./ErrorText";
import { isArray } from "lodash";

export const textMapToSelectOption = (
  enumValue: any,
  map: { [key: string]: string },
  defaultValue: SelectOption
): SelectOption[] => [
  defaultValue,
  ...Object.values(enumValue).map((value: any) => ({
    value,
    label: map[value],
  })),
];

export interface SelectOption {
  value: string | number;
  label: React.ReactNode;
}

export interface SelectGroup {
  name: string;
  options: SelectOption[];
}

interface Props extends SelectProps {
  control: Control<any>;
  label?: string;
  labelId?: string;
  name: string;
  options: SelectOption[];
  isLoading?: boolean;
  groups?: SelectGroup[];
  size?: "small" | "medium";
}

const ControlledSelect: React.FC<Props> = ({
  control,
  name,
  label,
  labelId,
  options,
  disabled,
  isLoading,
  groups,
  size,
  ...props
}) => {
  const { multiple } = props;

  const optionsList = (
    options: SelectOption[],
    field: ControllerRenderProps<any, string>
  ) => {
    return options.map((option) => {
      const isChecked =
        multiple === true &&
        isArray(field.value) &&
        (field.value as string[]).indexOf(option.value as string) >= 0;

      return (
        <MenuItem
          key={`${name}-option-${option.value.toString().replaceAll(" ", "-")}`}
          value={option.value}
        >
          {multiple && <Checkbox checked={isChecked} color="primary" />}
          {option.label}
        </MenuItem>
      );
    });
  };

  const groupList = (field: ControllerRenderProps<any, string>) => {
    let items: React.ReactNode[] = [];
    groups?.forEach((group) => {
      items.push(
        <Divider key={`separator-${group.name.replaceAll(" ", "-")}`} />
      );
      items.push(
        <ListSubheader
          key={`group-${group.name.replaceAll(" ", "-")}`}
          color="primary"
          style={{ background: "white" }}
        >
          {group.name}
        </ListSubheader>
      );
      items = [...items, ...optionsList(group.options, field)];
    });
    return items;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          error={fieldState.invalid}
          fullWidth={props.fullWidth}
          variant="outlined"
          size={size}
        >
          <InputLabel
            id={name}
            shrink
            style={{
              backgroundColor: "white",
              paddingLeft: "6px",
              paddingRight: "6px",
            }}
          >
            {label}
          </InputLabel>
          <Select
            labelId={name}
            displayEmpty
            {...field}
            disabled={disabled || isLoading}
            endAdornment={isLoading && <CircularProgress />}
            renderValue={
              multiple
                ? (selected: unknown) =>
                    (selected as string[])
                      .map((key) => options.find((o) => o.value === key)?.label)
                      .join(", ")
                : undefined
            }
            {...props}
          >
            {optionsList(options, field)}
            {groupList(field)}
          </Select>
          <InputErrorText errors={fieldState.error} />
        </FormControl>
      )}
    />
  );
};

export default ControlledSelect;
