import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import DateLabel from "../../../app/components/labels/DateLabel";
import StyledLink from "../../../app/components/StyledLink";
import { SnapGroupCycleSummaryDto } from "../dtos/SnapGroupCycleSummaryDto";
import SnapCycleGenderLabel from "./SnapCycleGenderLabel";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";

interface Props {
  summary: SnapGroupCycleSummaryDto;
}

const SnapCycleListItem: React.FC<Props> = ({ summary }) => {
  return (
    <TableRow>
      <TableCell>
        <StyledLink to={`/snap-cycles/${summary.id}`}>
          {summary.referenceNumber}
        </StyledLink>
      </TableCell>
      <TableCell>
        <DateLabel date={summary.startedOn} />
      </TableCell>
      <FnAccessWrapper>
        <TableCell>{summary.provider.name}</TableCell>
      </FnAccessWrapper>
      <TableCell>
        <SnapCycleGenderLabel gender={summary.gender} />
      </TableCell>
      <TableCell>{summary.programType.name}</TableCell>
      <TableCell style={{ paddingLeft: "60px" }}>
        {summary.numberOfGroups}
      </TableCell>
      <TableCell>
        <DateLabel date={summary.lastGroupHeldOn} />
      </TableCell>
      <TableCell>
        { summary.deletedAt ? <RedAlertLabel label={"Deleted"} /> : <></> }
      </TableCell>
    </TableRow>
  );
};

export default SnapCycleListItem;
