import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { isArray } from "lodash";
import { DateTime } from "luxon";
import { ParsedQuery } from "query-string";
import { getExpirationDate } from "../features/authentication/dtos/JwtPayloadDto";
import {
  getToken,
  logout,
  refresh,
} from "../features/authentication/state/authenticationActions";
import { JjisProgramTypeEnum } from "../features/jjis/enums/JjisProgramTypeEnum";
import { ProgramDto } from "../features/programs/dtos/ProgramDto";
import { ScreeningDto } from "../features/screenings/dtos/ScreeningDto";
import { SortDirectionEnum } from "./enums/SortDirectionEnum";
import { HttpError } from "./HttpError";

export const tokenIsExpired = () => {
  const token = getToken();
  const expiration = getExpirationDate(token);
  return expiration ? expiration < (new Date().getTime() + 3) / 1000 : true;
};

const get400ErrorMessage = (data: any) => {
  if (data.errors) {
    if (Array.isArray(data.errors)) {
      return data.errors.map((x: any) => x.message).join("\n");
    } else if (typeof data.errors === "object") {
      Object.values(data.errors)
        .map((error: any) =>
          Array.isArray(error) ? error.join(",") : error.message
        )
        .join("\n");
    } else {
      return "Unknown error";
    }
  }

  return data.detail;
};

export const getHttpError = async (error: any): Promise<HttpError> => {
  if (error.response === undefined)
    return { message: "Network Error", status: 500, type: "NetworkError" };

  let { data, status } = error.response;

  // file downloads return a blob so we need to parse it into the error object
  if(data instanceof Blob && data.type === 'application/problem+json') {
    data = JSON.parse(await data.text());
  }

  switch (status) {
    case 500:
      return {
        message: "Internal Server Error",
        status: 500,
        type: "InternalServerError",
      };
    case 404:
      return {
        message: data.detail || "Not Found",
        status: 404,
        type: data.type,
      };
    case 400:
      return {
        message: get400ErrorMessage(data),
        status,
        type: data.type,
      };
    default:
      return { message: data.detail, status, type: data.type };
  }
};

export const formatUrl = (baseUrl: string, url: string) =>
  baseUrl === "" || baseUrl === "/"
    ? url
    : `${baseUrl}${baseUrl.endsWith("/") ? "" : "/"}${url}`;

export const refreshTokenIfNeeded = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  if (tokenIsExpired()) {
    try {
      await dispatch(refresh()).unwrap();
    } catch (error) {
      const err = await getHttpError(error);
      if (err.status === 401) await dispatch(logout()).unwrap();
    }
  }
};

export const createNumberRange = (n: number) => Array.from(Array(n).keys());

export const datetimeToFormString = (date: Date) => {
  const dateTime = DateTime.fromJSDate(date);
  return `${dateTime.toFormat("yyyy-MM-dd")}T${dateTime.toFormat("HH:mm")}`;
};

export const dateToFormString = (date: Date) =>
  luxonDateToFormString(DateTime.fromJSDate(date));

export const luxonDateToFormString = (date: DateTime) =>
  date.toFormat("yyyy-MM-dd");

export const calculateAge = (dob: Date, date?: Date) => {
  date = date ? new Date(date) : new Date();
  dob = new Date(dob);
  let age = date.getFullYear() - dob.getFullYear();
  if (date.getMonth() < dob.getMonth()) age--;
  if (date.getMonth() === dob.getMonth() && date.getDate() < dob.getDate())
    age--;

  return age;
};

export const programIsType = (
  programId: string,
  programs: ProgramDto[] = [],
  programType: JjisProgramTypeEnum
) => {
  return (
    programs?.find((x) => x.id === programId)?.programType.jjisProgramType ===
    programType
  );
};

export const screeningIsDvRespite = (
  screeningId: string,
  screenings: ScreeningDto[] = []
) => screenings.find((x) => x.id === screeningId)?.isDvRespite === true;

export const trimPhoneNumber = (phoneNumber?: string) => {
  return phoneNumber
    ?.replaceAll("(", "")
    ?.replaceAll(") ", "")
    ?.replaceAll("-", "")
    ?.trim();
};

export const calculateDateDifferenceInDays = (
  date1: Date | string,
  date2: Date | string
) => {
  const luxonDate1 = toDate(date1);
  const luxonDate2 = toDate(date2);

  const diff = luxonDate1.diff(luxonDate2, ["days"]);
  return diff.days;
};

export const toDate = (date: Date | string) => {
  const luxonDate = DateTime.fromJSDate(new Date(date));
  return luxonDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const areDatesEqual = (date1: DateTime, date2: DateTime) => {
  return (
    date1.day === date2.day &&
    date1.month === date2.month &&
    date1.year === date2.year
  );
};

export const getMonthDays = (date: DateTime) => {
  let newDate = date.set({ day: 1 });
  const endOfMonth = newDate.endOf("month");
  const dates: DateTime[] = [];

  while (newDate <= endOfMonth) {
    dates.push(newDate);
    newDate = newDate.plus({ days: 1 });
  }

  return dates;
};

// removes unique identifier prefix from the storage name of uploaded files
export const toOriginalFilename = (
  filename: string | undefined
): string => filename?.split("-").slice(1).join("-") || "";

export class QueryStringHelpers {
  public static getSortDirection(query?: ParsedQuery<string>) {
    if (
      query?.sortDirection === undefined ||
      (query.sortDirection as string) === "descending"
    )
      return SortDirectionEnum.Descending;

    return SortDirectionEnum.Ascending;
  }

  public static toQueryString(params: object) {
    return Object.entries(params)
      .filter(([, value]) => value != null && value !== "")
      .map(([key, value]) => {
        if (isArray(value)) return value.map((x) => `${key}=${x}`).join("&");

        if (key === "sortDirection")
          return `${key}=${QueryStringHelpers.convertSortDirection(value)}`;

        if (value instanceof Date) {
          return `${key}=${dateToFormString(value as Date)}`;
        }

        return `${key}=${value}`;
      })
      .join("&");
  }

  public static convertSortDirection(value: string) {
    return value === SortDirectionEnum.Ascending ? "ascending" : "descending";
  }
}

export class PrintHelpers {
  public static async printDocument(
    request: () => Promise<Blob | undefined>,
    iframeId: string = "print-view"
  ) {
    let iframe: HTMLIFrameElement | null = document.getElementById(
      iframeId
    ) as HTMLIFrameElement;
    if (iframe) document.body.removeChild(iframe);
    const report = await request();
    if (report) {
      iframe = document.createElement("iframe");
      iframe.style.cssText = "display:none;";
      iframe.id = iframeId;
      iframe.src = URL.createObjectURL(report);
      document.body.appendChild(iframe);
      iframe.contentWindow?.print();
    }
  }
}

export const flattenOptions = (options: { [x: string]: boolean }) => {
  return Object.keys(options).filter((key) => options[key]);
};
