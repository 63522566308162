import React, { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { selectIsLoggedIn } from "../../features/authentication/state/authenticationSelectors";
import { useAppDispatch, useAppSelector } from "../hooks";

interface Props extends RouteProps {}

const PrivateRoute: React.FC<Props> = ({ ...props }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn === false) {
      history.push("/auth/login");
    }
  }, [isLoggedIn, dispatch, history]);

  return <Route {...props} />;
};

export default PrivateRoute;
