import {useForm} from "react-hook-form";
import {useServicePlanPageContext} from "../ServicePlanPageContext";
import {useRequest} from "../../app/hooks/useRequest";
import {updateServicePlanInterventionRequest} from "../ServicePlanRequests";
import {AddServicePlanInterventionDto} from "../dto/AddServicePlanInterventionDto";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useCallback, useEffect, useState} from "react";
import {ServicePlanGoalDto, ServicePlanInterventionDto} from "../dto/ServicePlanDto";

const schema = Yup.object().shape({
  description: Yup.string().required().max(120).label("Description"),
  notes: Yup.string().optional(),
});

export const useUpdateServicePlanInterventionForm = (goalId: string, interventionId: string) => {

  const [goal, setGoal] = useState<ServicePlanGoalDto>()
  const [intervention, setIntervention] = useState<ServicePlanInterventionDto>()

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<AddServicePlanInterventionDto>({
    defaultValues: {
      description: intervention?.description,
      notes: intervention?.notes
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = useCallback(() => {
    reset({
      description: intervention?.description,
      notes: intervention?.notes
    });
  }, [intervention?.description, intervention?.notes, reset]);

  const { servicePlan, servicePlanUpdated  } = useServicePlanPageContext();

  useEffect(() => {
    setGoal(servicePlan.goals.find(g => g.id === goalId))
    clearForm();
  }, [clearForm, goalId, servicePlan.goals])

  useEffect(() => {
    setGoal(servicePlan.goals.find(g => g.id === goalId))
    setIntervention(goal?.interventions.find(i => i.id === interventionId))
    clearForm();
  }, [clearForm, goal?.interventions, goalId, interventionId, servicePlan.goals])

  const [updateIntervention, , updateInterventionRequestState] = useRequest<AddServicePlanInterventionDto, void>(
    (dto) => updateServicePlanInterventionRequest(servicePlan.id, goalId, interventionId, dto)
  );

  const handleOnSubmit = handleSubmit(
    async (formData: AddServicePlanInterventionDto) => {
      await updateIntervention(formData);
      clearForm();
      await servicePlanUpdated();
    }
  );

  return {
    state: {
      goal,
      intervention,
      updateInterventionRequestState,
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };

}
