import React, { Fragment } from "react";
import { LinkProps } from "react-router-dom";
import StyledLink from "../StyledLink";

interface Props extends Partial<LinkProps> {
  style?: React.CSSProperties;
}

const EditLinkButton: React.FC<Props> = ({ onClick, children, ...props }) => {
  return (
    <Fragment>
      <style>
        {`
          .link-btn:hover {
            background-color: rgba(68, 68, 68, 0.04);
          }

          .link-btn {
            padding: 4px 2px;
          }
        `}
      </style>
      <StyledLink
        className="link-btn"
        to={""}
        {...props}
        onClick={(e) => {
          e.preventDefault();
          if (onClick) onClick(e);
        }}
      >
        {children || "Edit"}
      </StyledLink>
    </Fragment>
  );
};

export default EditLinkButton;
