import { useRequest } from "../../../app/hooks/useRequest";
import {
  changeCourtOrderedStatusRequest,
  changeDischargeDateRequest,
  changeIntakeDateRequest,
  changeProgramLocationRequest,
  createADBTTEligibilityQuestionsRequest,
  createCaseHoldRequest,
  createCaseContactRequest,
  deleteCaseRequest,
  dischargeCaseRequest,
  getCaseAlertsRequest,
  getCaseAssessmentsRequest,
  getCaseRequest,
  getCasesRequest,
  setCaseScreeningRequest,
  upsertCaseDemographicRequest,
  upsertNonBillableDaysRequest,
  createCaseHtstRequest,
  upsertCaseSuicideScreeningRequest,
  createCaseSessionRequest,
  getCaseAlertDismissalsRequest,
  deleteCaseSessionRequest,
  createCaseSuicideReScreeningRequest,
  getCaseProgramLocationHistoryRequest,
  deleteCaseDemographicRequest,
  getCasesAlertDismissalsRequest,
  validateNirvanaPostAssessmentRequest,
  upsertSatisfactionSurveyRequest,
  getSchoolNamesRequest,
  updateCaseDiscahrgeRequest as updateCaseDischargeRequest,
  updateCaseRequest,
  updateCaseLocationRequest,
  deleteCaseContactRequest,
  updateCaseContactRequest,
  deleteSuicideScreeningRequest,
  clearDischargeRequest,
  downloadCourtOrderAttachmentRequest,
  uploadCourtOrderAttachmentRequest,
  deleteCourtOrderAttachmentRequest,
} from "../CaseRequests";

export const useGetCase = () => useRequest(getCaseRequest);

export const useGetCases = () => useRequest(getCasesRequest);

export const useDischargeCase = () => useRequest(dischargeCaseRequest);
export const useUpdateCaseDischarge = () =>
  useRequest(updateCaseDischargeRequest);

export const useSetCaseScreening = () => useRequest(setCaseScreeningRequest);

export const useCreateADBTTEligibilityQuestionnaire = () =>
  useRequest(createADBTTEligibilityQuestionsRequest);

export const useCreateCaseHold = () => useRequest(createCaseHoldRequest);

export const useDeleteCase = () => useRequest(deleteCaseRequest);

export const useUpdateCase = () => useRequest(updateCaseRequest);

export const useChangeIntakeDate = () => useRequest(changeIntakeDateRequest);

export const useChangeDischargeDate = () =>
  useRequest(changeDischargeDateRequest);

export const useChangeProgramLocation = () =>
  useRequest(changeProgramLocationRequest);

export const useChangeCourtOrderedStatus = () =>
  useRequest(changeCourtOrderedStatusRequest);

export const useUploadCourtOrderAttachment = () =>
  useRequest(uploadCourtOrderAttachmentRequest);

export const useDownloadCourtOrderAttachment = () =>
  useRequest(downloadCourtOrderAttachmentRequest);

export const useDeleteCourtOrderAttachment = () => 
  useRequest(deleteCourtOrderAttachmentRequest);

export const useCreateCaseContact = () => useRequest(createCaseContactRequest);
export const useUpdateCaseContact = () => useRequest(updateCaseContactRequest);
export const useDeleteCaseContact = () => useRequest(deleteCaseContactRequest);

export const useGetCaseAlerts = () => useRequest(getCaseAlertsRequest);

export const useUpsertCaseDemographic = () =>
  useRequest(upsertCaseDemographicRequest);

export const useDeleteCaseDemographic = () =>
  useRequest(deleteCaseDemographicRequest);

export const useUpsertNonBillableDays = () =>
  useRequest(upsertNonBillableDaysRequest);

export const useGetCaseAssessments = () =>
  useRequest(getCaseAssessmentsRequest);

export const useGetCaseAlertDismissals = () =>
  useRequest(getCaseAlertDismissalsRequest);

export const useCreateCaseHtst = () => useRequest(createCaseHtstRequest);

export const useUpsertCaseSuicideScreening = () =>
  useRequest(upsertCaseSuicideScreeningRequest);

export const useDeleteCaseSuicideScreening = () =>
  useRequest(deleteSuicideScreeningRequest);

export const useCreateCaseSuicideReScreening = () =>
  useRequest(createCaseSuicideReScreeningRequest);

export const useCreateCaseSession = () => useRequest(createCaseSessionRequest);

export const useDeleteCaseSession = () => useRequest(deleteCaseSessionRequest);

export const useGetCaseProgramLocationHistory = () =>
  useRequest(getCaseProgramLocationHistoryRequest);

export const useGetCasesAlertDismissals = () =>
  useRequest(getCasesAlertDismissalsRequest);

export const useValidateNirvanaPostAssessment = () =>
  useRequest(validateNirvanaPostAssessmentRequest);

export const useUpsertSatisfactionSurvey = () =>
  useRequest(upsertSatisfactionSurveyRequest);

export const useGetSchoolsNames = () => useRequest(getSchoolNamesRequest);

export const useUpdateCaseLocation = () =>
  useRequest(updateCaseLocationRequest);

export const useClearDischarge = () =>
  useRequest(clearDischargeRequest);
