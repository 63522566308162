import { Grid } from "@material-ui/core";
import { Control } from "react-hook-form";
import ControlledInput from "../../app/components/inputs/ControlledInput";
import ControlledSelect, { SelectOption } from "../../app/components/inputs/ControlledSelect";
import { ServicePlanParticipantDto } from "../dto/ServicePlanDto";

interface Props {
    control: Control<ServicePlanParticipantDto>;
    roleOptions: SelectOption[];
}

export const ServicePlanParticipantFormDialogFields: React.FC<Props> = ({
    control,
    roleOptions
}) => {
    return (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <ControlledInput
              name="name"
              label="Name"
              type="text"
              control={control}
              disabled={false}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <ControlledSelect
              control={control}
              name={"role"}
              options={roleOptions}
              fullWidth
              label={"Role"}
            />
          </Grid>
        </Grid>
    );
};