import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import React, { Fragment, useEffect, useState } from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import DateLabel from "../../../app/components/labels/DateLabel";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { toDate } from "../../../app/helpers";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { CaseDetailDto } from "../dtos/CaseDetailDto";
import { NonBillableDaysFormDto } from "../dtos/NonBillableDaysFormDto";
import { useNonBillableDayForm } from "../hooks/useNonBillableDayForm";
import CancelIcon from "@material-ui/icons/Cancel";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import MultiDateCalendarSelect from "../../../app/components/inputs/MultiDateCalendarSelect";
import InvoiceCorrectionConfirmationCheckbox from "../../../app/components/InvoiceCorrectionConfirmationCheckbox";
export const nonBillableDayModalId = "non-billable-day-form-modal";

interface Props {
  onSubmit: (dto: NonBillableDaysFormDto) => Promise<any>;
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
}

const NonBillableFormDialog: React.FC<Props> = ({
  caseDto,
  requestState,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers, state } = useNonBillableDayForm(onSubmit, caseDto);
  const { selectedDates, isConfirmed } = state;
  const { handleSubmit, handleToggleDate, handleToggleConfirmed } = handlers;
  const { clearForm } = form;
  const { error, isLoading } = requestState;
  const [intakeAt, setIntakeAt] = useState<DateTime>();
  const [dischargeAt, setDischargeAt] = useState<DateTime>();
  const [today] = useState(toDate(new Date()));
  //#endregion

  //#region UI Helpers
  const sortedDates = () => selectedDates.sort((a, b) => (a > b ? 1 : -1));
  const renderDate = (date: DateTime) => (
    <Fragment key={date.toString()}>
      <ListItem>
        <Typography component="span">
          <DateLabel date={date.toJSDate()} />
        </Typography>
        <ListItemSecondaryAction>
          <Tooltip title={<Typography>Remove Date</Typography>}>
            <IconButton onClick={() => handleToggleDate(date)}>
              <CancelIcon style={{ color: ColorsEnum.Red }} />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </Fragment>
  );

  //#endregion

  //#region Effects
  useEffect(() => {
    if (caseDto) setIntakeAt(toDate(caseDto.intakeInfo.intakeAt));
  }, [caseDto, setIntakeAt]);

  useEffect(() => {
    if (caseDto && caseDto.dischargeInfo)
      setDischargeAt(toDate(caseDto.dischargeInfo.dischargedAt));
  }, [caseDto, setDischargeAt]);
  //#endregion

  //#region Handlers
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === nonBillableDayModalId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Select Non-Billable Days
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
              <MultiDateCalendarSelect
                earliestDate={intakeAt}
                onDateClicked={handleToggleDate}
                selectedDates={selectedDates}
                latestDate={dischargeAt || today}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Box display="flex" flexDirection="column" height="390px">
                <Typography>Non-Billable Days</Typography>
                <Paper
                  style={{ flexGrow: 1, marginTop: "4px", overflow: "auto" }}
                  square
                  variant="outlined"
                >
                  <List
                    style={{
                      padding: 0,
                    }}
                  >
                    {selectedDates.length === 0 && (
                      <ListItem>
                        <ListItemText primary="No Dates Selected" />
                      </ListItem>
                    )}
                    {sortedDates().map(renderDate)}
                  </List>
                </Paper>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <InvoiceCorrectionConfirmationCheckbox
                isConfirmed={isConfirmed}
                onCheck={handleToggleConfirmed}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={isLoading || !isConfirmed}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NonBillableFormDialog;
