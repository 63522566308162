import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  value: boolean;
  style?: React.CSSProperties;
}

const YesNoLabel: React.FC<Props> = ({ value, style, ...props }) => {
  return (
    <Typography {...props} component="span" style={{ ...style }}>
      {value ? "Yes" : "No"}
    </Typography>
  );
};

export default YesNoLabel;
