import { coreAgent } from "../../app/api/agent";
import { StaffMemberDto } from "./dtos/StaffMemberDto";
import { StaffMemberFormDto } from "./dtos/StaffMemberFormDto";

interface CreateStaffMemberRequestParamsDto {
  providerId: string;
  dto: StaffMemberFormDto;
}

interface UpdateStaffmemberRequestParmsDto {
  id: string;
  dto: StaffMemberFormDto;
}

export const createStaffMemberRequest = ({
  providerId,
  dto,
}: CreateStaffMemberRequestParamsDto) =>
  coreAgent.postRequest<StaffMemberDto>(
    `/providers/${providerId}/staffmembers`,
    dto
  );

export const updateStaffMemberRequest = ({
  id,
  dto,
}: UpdateStaffmemberRequestParmsDto) =>
  coreAgent.putRequest<StaffMemberDto>(`/staffMembers/${id}`, dto);

export const activateStaffMemberRequest = (id: string) =>
  coreAgent.patchRequest(`/staffmembers/${id}/activate`, {});
export const deactivateStaffMemberRequest = (id: string) =>
  coreAgent.patchRequest(`/staffmembers/${id}/deactivate`, {});
