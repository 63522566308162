import { Grid, Typography } from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";
import { useAlertDismissalForm } from "../hooks/useAssessmentOverrideReasonForm";
import { AlertTypeEnum, alertTypeTextMap } from "../enums/AlertTypeEnum";
import { AlertDismissalRequestDto } from "../dtos/AlertDismissalDto";
import { useCreateAlertDismissal } from "../hooks/alertHooks";

interface Props {
  message?: string;
  caseDto?: CaseDetailDto;
  snapCycle?: SnapInSchoolsCycleDto;
  type: AlertTypeEnum;
  onSubmitSuccess(): void;
}

const AlertDismissalForm: React.FC<Props> = ({
  caseDto,
  snapCycle,
  type,
  message,
  onSubmitSuccess,
}) => {
  const [createAlertDismissal, , createAlertDismissalRequestState] =
    useCreateAlertDismissal();
  const handleOnSubmit = async (dto: AlertDismissalRequestDto) => {
    await createAlertDismissal(dto);
    onSubmitSuccess();
  };

  const { form, handlers } = useAlertDismissalForm(
    handleOnSubmit,
    type,
    caseDto,
    snapCycle
  );
  const { handleSubmit } = handlers;
  const { isValid, control } = form;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {createAlertDismissalRequestState.error && (
          <Grid item md={12} xs={12}>
            <ErrorText error={createAlertDismissalRequestState.error.message} />
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          <Typography color="textSecondary">
            {message || `${alertTypeTextMap[type]} Alert Dismissal`}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <ControlledInput
            control={control}
            name="reason"
            placeholder="Reason"
            fullWidth
            multiline
            minRows={2}
            disabled={createAlertDismissalRequestState.isLoading}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <SpinnerButton
            showSpinner={createAlertDismissalRequestState.isLoading}
            content="Submit Override"
            color="primary"
            type="submit"
            disabled={!isValid || createAlertDismissalRequestState.isLoading}
            variant="contained"
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AlertDismissalForm;
