import React from "react";
import { FormControlLabel, CheckboxProps, Switch } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";

interface Props extends CheckboxProps {
  control: Control<any>;
  label?: string;
  name: string;
}

const ControlledSwitch: React.FC<Props> = ({
  control,
  name,
  label,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          control={
            <Switch checked={Boolean(field.value)} {...props} {...field} />
          }
        />
      )}
    />
  );
};

export default ControlledSwitch;
