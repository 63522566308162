import { Typography } from "@material-ui/core";
import React from "react";
import {
  DischargeStatusEnum,
  dischargeStatusTextMap,
} from "../../enums/DischargeStatusEnum";

interface Props {
  status: DischargeStatusEnum;
}

const DischargeStatusLabel: React.FC<Props> = ({ status }) => {
  const text = dischargeStatusTextMap[status];

  return <Typography component="span">{text}</Typography>;
};

export default DischargeStatusLabel;
