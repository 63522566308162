import { useRequest } from "../../../app/hooks/useRequest";
import {
  changeDeliverableRateRequest,
  deleteDeliverableRateRequst,
  getDeliverableRatesRequest,
} from "../DeliverableRateRequests";

export const useGetDeliverableRates = () =>
  useRequest(getDeliverableRatesRequest);

export const useChangeDeliverableRate = () =>
  useRequest(changeDeliverableRateRequest);

export const useDeleteDeliverableRate = () =>
  useRequest(deleteDeliverableRateRequst);
