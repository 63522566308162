import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { useRequest } from "../../../app/hooks/useRequest";
import {
  getConfirmationEmailRequest,
  sendConfirmationEmailRequest,
} from "../../account/AccountRequests";
import { SendConfirmationEmailRequestDto } from "../../account/dtos/SendConfirmationEmailRequestDto";
import { fnyfsProviderId } from "../../authentication/constants";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { selectHasExecuteAccess } from "../../authentication/state/authenticationSelectors";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { NetmisUserRoleDto } from "../../roles/dtos/NetmisUserRoleDto";
import { confirmDeactivateRoleDialogId } from "../components/ConfirmDeactiveRoleDialog";
import { confirmRemoveRoleDialogId } from "../components/provider-roles/ConfirmRemoveRoleDialog";
import { providerRoleModalId } from "../components/provider-roles/ProviderRoleFormDialog";
import { updateUserEmailModalId } from "../components/UpdateUserEmailDialog";
import { userModalId } from "../components/UserFormDialog";
import { userPinModalId } from "../components/UserPinDialog";
import { AddProviderRoleFormDto } from "../dtos/AddProviderRoleFormDto";
import { RemoveProviderRoleFormDto } from "../dtos/RemoveProviderRoleFormDto";
import { SetUserRoleActiveDto } from "../dtos/SetUserRoleActiveDto";
import { UpdateUserEmailFormDto } from "../dtos/UpdateUserEmailFormDto";
import { UserDetailDto } from "../dtos/UserDetailDto";
import { UserFormDto } from "../dtos/UserFormDto";
import {
  useActivateUserRole,
  useAddProviderRole,
  useDectivateUserRole,
  useGetUser,
  useRemoveProviderRole,
  useUnlockAccount,
  useUpdateUser,
  useUpdateUserEmail,
} from "./userHooks";

export const useUserDetailPage = (): UserDetailPageProps => {
  //#region State
  const id = useId();
  const dispatch = useAppDispatch();
  const [isInitialized, setIsInitialzed] = useState(false);
  const [updateUser, , updateUserRequestState] = useUpdateUser();
  const [getUser, user, getUserRequestState] = useGetUser();
  const providerId = getProviderId();
  //#endregion

  //#region Handlers
  const handleEditClicked = useCallback(() => {
    dispatch(openModal({ modalId: userModalId }));
  }, [dispatch]);

  const handleEdit = useCallback(
    async (dto: UserFormDto) => {
      await updateUser({ id, dto });
      batch(() => {
        dispatch(addSuccessNotification({ message: "User Updated" }));
        dispatch(closeModal());
      });
      await getUser(id);
    },
    [updateUser, dispatch, getUser, id]
  );
  //#endregion

  //#region Email
  const [
    updateUserEmail,
    ,
    updateUserEmailRequestState,
    clearUpdateUserEmailError,
  ] = useUpdateUserEmail();

  const handleEditEmailClicked = useCallback(() => {
    clearUpdateUserEmailError();
    dispatch(openModal({ modalId: updateUserEmailModalId }));
  }, [clearUpdateUserEmailError, dispatch]);

  const handleUpdateUserEmail = useCallback(
    async (dto: UpdateUserEmailFormDto) => {
      await updateUserEmail({ id, dto });

      batch(() => {
        dispatch(closeModal());
        dispatch(addSuccessNotification({ message: "User Email Updated" }));
      });
      await getUser(id);
    },
    [updateUserEmail, dispatch, getUser, id]
  );
  //#endregion

  //#region Unlock Account
  const [canUnlockAccount, setCanUnlockAccount] = useState(false);
  const userCanUnlockAccount = useAppSelector(
    selectHasExecuteAccess(PermissionResourceNameEnum.UnlockAccount)
  );
  const [unlockAccount, , unlockAccountRequestState] = useUnlockAccount();

  const handleUnlockAccount = async () => {
    await unlockAccount(id);
    dispatch(addSuccessNotification({ message: "Account Unlocked" }));
    await getUser(id);
  };
  //#endregion

  //#region User PIN
  const handleOpenViewPinClicked = () => {
    dispatch(openModal({ modalId: userPinModalId }));
  };
  //#endregion

  //#region Role
  // Add
  const [
    addProviderRole,
    ,
    addProviderRoleRequestState,
    clearAddProviderRoleError,
  ] = useAddProviderRole();

  const handleAddRoleClicked = () => {
    clearAddProviderRoleError();
    dispatch(openModal({ modalId: providerRoleModalId }));
  };

  const handleAddRole = async (dto: AddProviderRoleFormDto) => {
    await addProviderRole({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Provider Role Added" }));
      dispatch(closeModal());
    });
    await getUser(id);
  };

  // Remove
  const [
    removeProviderRole,
    ,
    removeProviderRoleRequestState,
    clearRemoveProviderRoleError,
  ] = useRemoveProviderRole();

  const handleRemoveRoleClicked = () => {
    clearRemoveProviderRoleError();
    dispatch(openModal({ modalId: confirmRemoveRoleDialogId }));
  };

  const handleRemoveRole = async (dto: RemoveProviderRoleFormDto) => {
    await removeProviderRole({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Provider Role Removed" }));
      dispatch(closeModal());
    });
    await getUser(id);
  };

  // Activate
  const [activateUserRole, , activateUserRoleRequestState] =
    useActivateUserRole();

  const handleActivateRole = async (dto: SetUserRoleActiveDto) => {
    await activateUserRole({ id, dto });
    dispatch(addSuccessNotification({ message: "User Role Activated" }));
    await getUser(id);
  };

  // Deactivate
  const [selectedRole, setSelectedRole] = useState<NetmisUserRoleDto>();
  const [
    deactivateUserRole,
    ,
    deactivateUserRoleRequestState,
    clearDeactivateRoleErrors,
  ] = useDectivateUserRole();

  const handleDeactivateRoleClicked = (dto: NetmisUserRoleDto) => {
    setSelectedRole(dto);
    clearDeactivateRoleErrors();
    dispatch(openModal({ modalId: confirmDeactivateRoleDialogId }));
  };

  const handleDeactivateRole = async () => {
    await deactivateUserRole({
      id,
      dto: new SetUserRoleActiveDto(selectedRole),
    });
    batch(() => {
      dispatch(addSuccessNotification({ message: "User Role Deactivated" }));
      dispatch(closeModal());
    });
    await getUser(id);
  };
  const roleProps: RoleProps = {
    selectedRole,
    removeProviderRoleRequestState,
    addProviderRoleRequestState,
    activateUserRoleRequestState,
    deactivateUserRoleRequestState,
    handleAddRoleClicked,
    handleAddRole,
    handleRemoveRoleClicked,
    handleRemoveRole,
    handleDeactivateRoleClicked,
    handleDeactivateRole,
    handleActivateRole,
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    const load = async () => {
      try {
        await getUser(id);
      } catch (error: any) {
        throw error;
      } finally {
        setIsInitialzed(true);
      }
    };

    load();
  }, [getUser, id]);

  useEffect(() => {
    if (!userCanUnlockAccount) {
      setCanUnlockAccount(false);
      return;
    }

    if (providerId === fnyfsProviderId) {
      setCanUnlockAccount(true);
      return;
    }

    if (user == null) {
      setCanUnlockAccount(false);
      return;
    }

    setCanUnlockAccount(user.roles.some((x) => x.provider.id === providerId));
  }, [user, providerId, userCanUnlockAccount, setCanUnlockAccount]);
  //#endregion

  const emailConfirmationProps = useEmailConfirmation({ user });

  return {
    user,
    canUnlockAccount,
    isInitialized,
    getUserRequestState,
    updateUserRequestState,
    updateUserEmailRequestState,
    unlockAccountRequestState,
    roles: roleProps,
    emailConfirmation: emailConfirmationProps,
    handleEditClicked,
    handleEdit,
    handleEditEmailClicked,
    handleUpdateUserEmail,
    handleUnlockAccount,
    handleOpenViewPinClicked,
  };
};

interface RoleProps {
  selectedRole?: NetmisUserRoleDto;
  removeProviderRoleRequestState: RequestStateDto;
  addProviderRoleRequestState: RequestStateDto;
  activateUserRoleRequestState: RequestStateDto;
  deactivateUserRoleRequestState: RequestStateDto;
  handleAddRoleClicked(): void;
  handleAddRole(dto: AddProviderRoleFormDto): Promise<void>;
  handleRemoveRoleClicked(): void;
  handleRemoveRole(dto: RemoveProviderRoleFormDto): Promise<void>;
  handleDeactivateRole(): Promise<void>;
  handleDeactivateRoleClicked(dto: NetmisUserRoleDto): void;
  handleActivateRole(dto: SetUserRoleActiveDto): Promise<void>;
}

export interface UserDetailPageProps {
  user?: UserDetailDto;
  canUnlockAccount: boolean;
  isInitialized: boolean;
  getUserRequestState: RequestStateDto;
  updateUserRequestState: RequestStateDto;
  unlockAccountRequestState: RequestStateDto;
  updateUserEmailRequestState: RequestStateDto;
  roles: RoleProps;
  emailConfirmation: EmailConfirmationProps;
  handleEditClicked(): void;
  handleEdit(dto: UserFormDto): Promise<void>;
  handleEditEmailClicked(): void;
  handleUpdateUserEmail(dto: UpdateUserEmailFormDto): Promise<void>;
  handleUnlockAccount(): Promise<void>;
  handleOpenViewPinClicked(): void;
}

interface EmailConfirmationProps {
  resendEmailConfirmationRequestState: RequestStateDto;
  getConfirmEmailRequestState: RequestStateDto;
  handleResendEmailConfirmation(): Promise<void>;
  handleCopyEmailConfirmationLink(): Promise<void>;
}

const useEmailConfirmation = ({
  user,
}: {
  user?: UserDetailDto;
}): EmailConfirmationProps => {
  const [sendConfirmationEmail, , resendEmailConfirmationRequestState] =
    useRequest(sendConfirmationEmailRequest);

  const [getConfirmEmailLink, , getConfirmEmailRequestState] = useRequest(
    getConfirmationEmailRequest
  );
  const dispatch = useAppDispatch();

  const handleResendEmailConfirmation = async () => {
    await sendConfirmationEmail(
      SendConfirmationEmailRequestDto.fromUser(user!)
    );
    dispatch(addSuccessNotification({ message: "Confirmation Email Sent" }));
  };

  const handleCopyEmailConfirmationLink = async () => {
    const result = await getConfirmEmailLink(
      SendConfirmationEmailRequestDto.fromUser(user!)
    );
    if (result) {
      navigator.clipboard.writeText(result);
      dispatch(
        addSuccessNotification({ message: "Email Confirmation Link Copied" })
      );
    }
  };

  return {
    resendEmailConfirmationRequestState,
    getConfirmEmailRequestState,
    handleCopyEmailConfirmationLink,
    handleResendEmailConfirmation,
  };
};
