import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { batch } from "react-redux";
import ErrorAlert from "../../../app/components/ErrorAlert";
import StyledLink from "../../../app/components/StyledLink";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import SendResetPasswordForm from "../components/SendResetPasswordForm";
import { SendResetPasswordFormDto } from "../dtos/SendResetPasswordFormDto";
import { sendResetPassword } from "../state/accountActions";
import { selectAccountIsLoading } from "../state/accountSelectors";

const SendResetPasswordPage = () => {
  //#region State
  const dispatch = useAppDispatch();
  const isLoadiong = useAppSelector(selectAccountIsLoading);
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  //#endregion

  //#region Handlers
  const handleSubmit = (
    dto: SendResetPasswordFormDto,
    callback?: () => void
  ) => {
    batch(async () => {
      try {
        setError("");
        await dispatch(sendResetPassword(dto)).unwrap();
        dispatch(
          addSuccessNotification({ message: "Password Reset Email Sent" })
        );
        if (callback) callback();
        setEmailSent(true);
      } catch (error: any) {
        setError(error.message);
      }
    });
  };
  //#endregion

  return (
    <Box paddingTop="10%" width="100%">
      <Grid container>
        <Grid item md={4} xs={12}></Grid>
        <Grid item md={4} xs={12}>
          <ErrorAlert error={error} />
          {emailSent === false && (
            <Fragment>
              <SendResetPasswordForm
                onSubmit={handleSubmit}
                isLoading={isLoadiong}
              />
              <Box marginTop="8px">
                <Typography>
                  Already know your password? Click{" "}
                  <StyledLink to="/auth/login">here</StyledLink> to login.
                </Typography>
              </Box>
            </Fragment>
          )}
          {emailSent && (
            <Card>
              <CardContent>
                <Typography variant="h5">Reset Password Email Sent</Typography>
                <Typography>
                  Please follow the instructions listed in the email.
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SendResetPasswordPage;
