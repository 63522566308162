import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import NameLabel from "../../../app/components/labels/NameLabel";
import { CaseListDto } from "../../cases/dtos/CaseListDto";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";

interface ListProps {
  header: React.ReactNode;
  cases: CaseListDto[];
  actionIcon: React.ReactNode;
  disabled: boolean;
  action(dto: CaseListDto): void;
}

const AddCaseToGroupFormList: React.FC<ListProps> = ({
  header,
  cases,
  actionIcon,
  disabled,
  action,
}) => {
  const sortedCases = () => {
    return cases.sort((a, b) =>
      `${a.youth.firstName} ${a.youth.lastName}` >
      `${b.youth.firstName} ${b.youth.lastName}`
        ? 1
        : -1
    );
  };

  return (
    <Fragment>
      <Typography variant="h6">{header}</Typography>
      <Paper square variant="outlined" style={{ height: "calc(100% - 28px)" }}>
        <List style={{ padding: 0 }}>
          {sortedCases().map((caseDto) => (
            <Fragment key={caseDto.id}>
              <ListItem>
                <ListItemText
                  primary={
                    <Fragment>
                      <NameLabel component="span" nameDto={caseDto.youth} />
                    </Fragment>
                  }
                />
                <ListItemIcon>
                  <IconButton
                    onClick={() => action(caseDto)}
                    disabled={disabled}
                  >
                    {actionIcon}
                  </IconButton>
                </ListItemIcon>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
          {cases.length === 0 && (
            <ListItem>
              <ListItemText primary={`No Youth`} />
            </ListItem>
          )}
        </List>
      </Paper>
    </Fragment>
  );
};

interface Props {
  eligibleCases: CaseListDto[];
  casesToAdd: CaseListDto[];
  disabled: boolean;
  onAddCase: (dto: CaseListDto) => void;
  onRemoveCase: (dto: CaseListDto) => void;
}

const AddCasesToGroupFormFields: React.FC<Props> = ({
  eligibleCases,
  casesToAdd,
  disabled,
  onAddCase,
  onRemoveCase,
}) => {
  return (
    <Grid container spacing={1} style={{ minHeight: "500px" }}>
      <Grid item md={6} xs={6}>
        <AddCaseToGroupFormList
          header={"Eligible Youth"}
          cases={eligibleCases}
          action={onAddCase}
          actionIcon={<AddIcon style={{ color: ColorsEnum.Green }} />}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <AddCaseToGroupFormList
          header={"Added Youth"}
          cases={casesToAdd}
          action={onRemoveCase}
          actionIcon={<RemoveIcon style={{ color: ColorsEnum.Red }} />}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default AddCasesToGroupFormFields;
