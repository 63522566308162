import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { providerSchema } from "../components/ProviderFormFields";
import { ProviderDto } from "../dtos/ProviderDto";
import {
  createProviderFormDto,
  ProviderFormDto,
} from "../dtos/ProviderFormDto";

export const useProviderForm = (
  onSubmit: (dto: ProviderFormDto) => Promise<void>,
  provider?: ProviderDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createProviderFormDto(provider),
    resolver: yupResolver(providerSchema),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(createProviderFormDto(provider));
  };
  const handleOnSubmit = handleSubmit(async (dto: ProviderFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  useEffect(() => {
    reset(createProviderFormDto(provider));
  }, [provider, reset]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
