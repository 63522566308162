import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { toOriginalFilename } from "../../app/helpers";
import { AlertDto } from "../alerts/dtos/AlertDto";
import { AssessmentDto } from "../assessments/dtos/AssessmentDto";
import { SnapInSchoolsCurriculumDto } from "./dtos/SnapInSchoolsCurriculumDto";
import { SnapInSchoolsCycleDto } from "./dtos/SnapInSchoolsCycleDto";
import { SnapInSchoolsCycleFormDto } from "./dtos/SnapInSchoolsCycleFormDto";
import { SnapInSchoolsCycleSummaryDto } from "./dtos/SnapInSchoolsCycleSummaryDto";
import {
  SnapInSchoolsFidelityAdherenceChecklistDto,
  SnapInSchoolsFidelityAdherenceChecklistFormDto,
} from "./dtos/SnapInSchoolsFidelityAdherenceChecklistDto";
import { SnapInSchoolsSearchParamsDto } from "./dtos/SnapInSchoolsSearchParamsDto";
import { SnapInSchoolsSessionDto } from "./dtos/SnapInSchoolsSessionDto";
import { SnapInSchoolsSessionFormDto } from "./dtos/SnapInSchoolsSessionFormDto";

interface CreateSnapInSchoolsSessionParams {
  id: string;
  dto: SnapInSchoolsSessionFormDto;
}

interface UpdateSnapInSchoolsSessionParams {
  id: string;
  dto: SnapInSchoolsSessionFormDto;
}

interface UpdateSnapInSchoolsCycleParams {
  id: string;
  dto: SnapInSchoolsCycleFormDto;
}

interface UpdateSnapInSchoolsCycleParams {
  id: string;
  dto: SnapInSchoolsCycleFormDto;
}

export const getSnapInSchoolsCyclesRequest = (
  searchParams: SnapInSchoolsSearchParamsDto
) =>
  coreAgent.getRequest<PaginationResultDto<SnapInSchoolsCycleSummaryDto>>(
    `snapInSchools/cycles?${SnapInSchoolsSearchParamsDto.toQueryString(
      searchParams
    )}`
  );

export const getSnapInSchoolsCycleRequest = (id: string) =>
  coreAgent.getRequest<SnapInSchoolsCycleDto>(`snapInSchools/cycles/${id}`);

export const createSnapInSchoolsCycleRequest = (
  dto: SnapInSchoolsCycleFormDto
) =>
  coreAgent.postRequest<SnapInSchoolsCycleSummaryDto>(
    `snapInSchools/cycles`,
    dto
  );

export const updateSnapInSchoolsCycleRequest = ({
  id,
  dto,
}: UpdateSnapInSchoolsCycleParams) =>
  coreAgent.putRequest<SnapInSchoolsCycleSummaryDto>(
    `snapInSchools/cycles/${id}`,
    dto
  );

export const createSnapInSchoolsSessionRequest = ({
  id,
  dto,
}: CreateSnapInSchoolsSessionParams) =>
  coreAgent.postRequest<SnapInSchoolsSessionDto>(
    `snapInSchools/cycles/${id}/sessions`,
    dto
  );

export const updateSnapInSchoolsSessionRequest = ({
  id,
  dto,
}: UpdateSnapInSchoolsSessionParams) =>
  coreAgent.putRequest<SnapInSchoolsSessionDto>(
    `snapInSchools/sessions/${id}`,
    dto
  );

export const getSnapInSchoolsSessionRequest = (id: string) =>
  coreAgent.getRequest<SnapInSchoolsSessionDto>(`snapInSchools/sessions/${id}`);

export const getSnapInSchoolsCurriculumRequest = () =>
  coreAgent.getRequest<SnapInSchoolsCurriculumDto[]>(
    "snapInSchools/curriculums"
  );
export const getSnapInSchoolsLocationsRequest = () =>
  coreAgent.getRequest<string[]>("snapInSchools/locations");

export const downloadAttendanceLogDocumentRequest = (
  dto: SnapInSchoolsSessionDto
) =>
  coreAgent.downloadFileRequest(
    `snapInSchools/sessions/${dto.id}/attendancelog`,
    toOriginalFilename(dto.attendanceLogFilename)
  );

export const deleteSnapInSchoolsCycleRequest = (id: string) =>
  coreAgent.deleteRequest<void>(`snapInSchools/cycles/${id}`);

export const deleteSnapInSchoolsSessionRequest = (id: string) =>
  coreAgent.deleteRequest<void>(`snapInSchools/sessions/${id}`);

export const getSnapInSchoolsCycleAssessmentsRequest = (id: string) =>
  coreAgent.getRequest<AssessmentDto[]>(
    `/snapInSchools/cycles/${id}/assessments`
  );

export const upsertFidelityAdherenceChecklistRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: SnapInSchoolsFidelityAdherenceChecklistFormDto;
}) =>
  coreAgent.putRequest<SnapInSchoolsFidelityAdherenceChecklistDto>(
    `snapInSchools/cycles/${id}/fidelityAdherenceChecklist`,
    dto
  );

export const deleteFidelityAdherenceChecklistRequest = (id: string) =>
  coreAgent.deleteRequest(
    `snapInSchools/cycles/${id}/fidelityAdherenceChecklist`
  );

export const getSnapInSchoolsCycleAlertsRequest = (id: string) =>
  coreAgent.getRequest<AlertDto[]>(`/snapInSchools/cycles/${id}/alerts`);

  export const exportSnapInSchoolsRequest = ({
    searchParams,
    filename,
  }: {
    searchParams: SnapInSchoolsSearchParamsDto;
    filename: string;
  }) =>
    coreAgent.downloadFileRequest(
      `snapinschools/export?${SnapInSchoolsSearchParamsDto.toQueryString(searchParams)}`,
      filename
    );