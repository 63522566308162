import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { useSatisfactionSurveyForm } from "../hooks/useSatisfactionSurveyForm";
import { SatisfactionSurveyFormDto } from "../SatisfactionSurveyDto";
import SatisfactionSurveyFormFields from "./SatisfactionSurveyFormFields";

export const satisfactionSurveyModalId = "satisfaction-survey-form-modal";

interface Props {
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
  onSubmit(dto: SatisfactionSurveyFormDto): Promise<any>;
}

const SatisfactionSurveyFormDialog: React.FC<Props> = ({
  caseDto,
  requestState,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { state, form, handlers } = useSatisfactionSurveyForm(
    onSubmit,
    caseDto
  );
  const { handleSubmit } = handlers;
  const { staffMembers, getProviderStaffMembersRequestState } = state;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === satisfactionSurveyModalId}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {caseDto?.satisfactionSurvey ? "Edit" : "Enter"} Satisfaction Survey
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <SatisfactionSurveyFormFields
              staffMembers={staffMembers}
              staffMembersAreLoading={
                getProviderStaffMembersRequestState.isLoading
              }
              control={control}
              disabled={isLoading}
              programType={caseDto?.program.programType.programType!}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SatisfactionSurveyFormDialog;
