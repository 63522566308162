import * as Yup from "yup";
import { dateToFormString, trimPhoneNumber } from "../../../app/helpers";
import { ReferralDto } from "../../referrals/dtos/ReferralDto";
import { YouthAddressDto, YouthDto } from "./YouthDto";

export class YouthAddressFormDto {
  unableToObtainAddress: boolean;
  address?: string;
  building?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  isMailingAddress?: boolean;  

  constructor(dto?: YouthAddressDto) {
    this.address = dto?.address || "";
    this.building = dto?.building || "";
    this.zipCode = dto?.zipCode || "";
    this.city = dto?.city || "";
    this.state = dto?.state?.trim() || "FL";
    this.isMailingAddress = dto?.isMailingAddress || false;
    this.unableToObtainAddress =
      dto == null ? false : dto.address == null || dto.address === "";    
  }

  public static getSchema() {
    return Yup.object().shape({
      address: Yup.string()
        .label("Address")
        .when("unableToObtainAddress", {
          is: false,
          then: (schema) => schema.max(75).required(),
          otherwise: (schema) => schema.max(0),
        }),
      city: Yup.string()
        .label("City")
        .when("unableToObtainAddress", {
          is: false,
          then: (schema) => schema.max(75).required(),
        }),
      state: Yup.string()
        .label("State")
        .when("unableToObtainAddress", {
          is: false,
          then: (schema) => schema.max(2).required(),
        }),
      zipCode: Yup.string()
        .label("Zip Code")
        .when("unableToObtainAddress", {
          is: false,
          then: (schema) => schema.max(5).min(5).required(),          
        }),
      building: Yup.string()
        .label("Building/Apartment")
        .when("unableToObtainAddress", {
          is: false,
          then: (schema) => schema.max(75),
          otherwise: (schema) => schema.max(0),
        }),
    });
  }
}

export class YouthFormDto {
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  race: string;
  isMultiracial: boolean;
  sexualOrientation: string;
  middleName: string;
  djjIdNumber: string;
  ethnicity?: string;
  preferredName?: string;
  preferredPronouns?: string;
  forceSubmit: boolean;
  ssn?: string;
  email?: string;
  phoneNumber?: string;
  phoneNumberType?: string;
  isLegacy?: boolean;
  address: YouthAddressFormDto;

  constructor(dto?: YouthDto) {
    this.firstName = dto?.firstName || "";
    this.lastName = dto?.lastName || "";
    this.dob = dto?.dob ? dateToFormString(new Date(dto.dob)) : "";
    this.gender = dto?.gender || "";
    this.race = dto?.race || "";
    this.isMultiracial = dto?.isMultiracial || false;
    this.sexualOrientation = dto?.sexualOrientation || "";
    this.middleName = dto?.middleName || "";
    this.djjIdNumber = dto?.djjIdNumber ? `${dto.djjIdNumber}` : "";
    this.ethnicity = dto?.ethnicity || "";
    this.preferredName = dto?.preferredName || "";
    this.preferredPronouns = dto?.preferredPronouns || "";
    this.forceSubmit = dto != null;
    this.ssn = dto?.ssn || "";
    this.email = dto?.email || "";
    this.phoneNumber = dto?.phoneNumber || "";
    this.phoneNumberType = dto?.phoneNumberType || "";
    this.address = new YouthAddressFormDto(dto?.address);
    this.isLegacy = dto?.isLegacy || false;
  }

  public static fromReferral(dto: ReferralDto) {
    const youth = new YouthFormDto();
    youth.firstName = dto.youthFirstName;
    youth.lastName = dto.youthLastName;
    youth.address = new YouthAddressFormDto();
    youth.address.zipCode = dto.youthZipCode;
    youth.address.state = dto.state;
    youth.address.city = dto.youthCity;
    youth.dob =
      dto.youthDob != null ? dateToFormString(new Date(dto.youthDob)) : "";

    return youth;
  }

  public static getSchema() {
    return Yup.object().shape({
      firstName: Yup.string().label("First Name").required().max(100),
      lastName: Yup.string().label("Last Name").required().max(100),
      middleName: Yup.string().label("Middle Name").max(100),
      preferredName: Yup.string().label("Preferred Name").max(100),
      preferredPronouns: Yup.string().label("Pronoun(s)").max(100),
      dob: Yup.string().label("DOB").required(),
      gender: Yup.string().label("Gender").required(),
      race: Yup.string().label("Race").required(),
      sexualOrientation: Yup.string().label("Sexual Orientation").required(),

      ethnicity: Yup.string().label("Ethnicity").required(),
      ssn: Yup.string().test(
        "valid-ssn",
        "SSN must be in XXX-XX-XXXX format",
        (value) => {
          if (value === "" || value === undefined || value === "   -  -    ")
            return true;

          const regex = /^\d{3}-?\d{2}-?\d{4}$/;
          return regex.test(value);
        }
      ),
      djjIdNumber: Yup.string().test(
        "djjid-number-lenth",
        "DJJ ID Number must be less than 2147483647",
        function (value) {
          if (value === undefined) return true;

          return +value <= 2147483647;
        }
      ),
      email: Yup.string().label("Email").email(),
      phoneNumber: Yup.string()
        .label("Phone Number")
        .test(
          "test-phone-number-length",
          "Phone Number must be 10 digits long",
          function (value) {
            const trimmedPhoneNumber = trimPhoneNumber(value);

            if (
              value == null ||
              value === "" ||
              trimmedPhoneNumber == null ||
              trimmedPhoneNumber === ""
            )
              return true;
            return trimmedPhoneNumber?.length === 10;
          }
        ),
      address: YouthAddressFormDto.getSchema(),
      phoneNumberType: Yup.string()
        .label("Phone Number Type")
        .when("phoneNumber", {
          is: (phoneNumber: string) => trimPhoneNumber(phoneNumber)?.length,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.optional(),
        }),
    });
  }
}
