import { dateToFormString } from "../../../app/helpers";
import { SnapFidelityDto } from "./SnapFidelityDto";

export interface SnapFidelityFormDto {
  performedOn: string;
  youthScore: string;
  parentScore?: string;
  isSnapYouthJustice: boolean;
}

export const createSnapFideltiyFormDto = (
  isSnapYouthJustice: boolean,
  dto?: SnapFidelityDto
): SnapFidelityFormDto => {
  return {
    performedOn: dto?.performedOn ? dateToFormString(new Date(dto.performedOn)) : '',
    youthScore: dto?.youthScore.toString() || "",
    parentScore: isSnapYouthJustice
      ? undefined
      : dto?.parentScore?.toString() || "",
    isSnapYouthJustice,
  };
};
