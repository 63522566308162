import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
interface Props {
  isConfirmed: boolean;
  disabled?: boolean;
  bold?: boolean;
  onCheck(): void;
}

const InvoiceCorrectionConfirmationCheckbox: React.FC<Props> = ({ disabled, isConfirmed, bold, onCheck }) => {
  return (
    <FormControlLabel
      label={<Typography style={{ fontWeight: bold ? "bold" : "normal" }} >I understand that this action may result in invoice corrections</Typography>}
      color="primary"
      control={<Checkbox disabled={disabled} checked={isConfirmed} onClick={onCheck} color="primary" />}
    />
  );
};

export default InvoiceCorrectionConfirmationCheckbox;
