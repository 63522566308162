import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import YouthDetailPage from "./pages/YouthDetailPage";
import YouthListPage from "./pages/YouthListPage";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import YouthCaseFormPage from "./pages/YouthCaseFormPage";
import WriteAccessLevelRoute from "../../app/routes/WriteAccesslevelRoute";

export const createYouthRoutes = (baseUrl: string) => [
  <WriteAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, ":id/cases/new")}
    component={YouthCaseFormPage}
    key="case-new-youth"
  />,
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, ":id")}
    component={YouthDetailPage}
    key="detail-youth"
    name={PermissionResourceNameEnum.Youth}
    exact
  />,
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, "")}
    component={YouthListPage}
    key="list-youth"
    name={PermissionResourceNameEnum.Youth}
    exact
  />,
];
