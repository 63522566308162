import { Typography } from "@material-ui/core";
import React from "react";
import {
  SchoolProgramEnum,
  schoolProgramTextMap,
} from "../../enums/SchoolProgramEnum";

interface Props {
  program: SchoolProgramEnum;
}

const SchoolProgramLabel: React.FC<Props> = ({ program }) => {
  const text = schoolProgramTextMap[program];

  return <Typography component="span">{text}</Typography>;
};

export default SchoolProgramLabel;
