import {useCallback, useEffect, useState} from "react";
import { batch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { useTabIndex } from "../../../app/hooks/useTabIndex";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { removeWaitlistModalId } from "../../waitlists/components/RemoveWaitlistFormDialog";
import { waitlistModalId } from "../../waitlists/components/WaitlistFormDialog";
import { RemoveWaitlistFormDto } from "../../waitlists/dtos/RemoveWaitlistFormDto";
import { WaitlistFormDto } from "../../waitlists/dtos/WaitlistFormDto";
import { WaitlistScreeningDto } from "../../waitlists/dtos/WaitlistScreeningDto";
import { useRemoveWaitlist } from "../../waitlists/hooks/waitlistHooks";
import {
  useCreateScreeningWaitlist,
  useDeleteScreening,
  useGetScreeningById,
  useUpdateScreening,
} from "./screeningHooks";
import { selectHasReadAccess } from "../../authentication/state/authenticationSelectors";
import { screeningDetailsTabName } from "../components/ScreeningDetailsTab";
import { calculateDateDifferenceInDays } from "../../../app/helpers";
import { youthScreeningFormDialogId } from "../../youths/components/modals/YouthScreeningFormDialog";
import { ScreeningFormDto } from "../dtos/ScreeningFormDto";
import {youthRecordTransferModalId} from "../../transfer-youth-record/components/transfer-case/YouthRecordFormDto";
import { confirmDeleteScreeningDialogId } from "../components/ConfirmDeleteScreeningDialog";
import { ScreeningDetailDto } from "../dtos/ScreeningDetailDto";

export const useScreeningDetailPage = () => {
  //#region State
  const id = useId();
  const [getScreeningById, screening, getScreeningRequestState] =
    useGetScreeningById();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [
    createScreeningWaitlist,
    ,
    createScreeningWaitlistRequestState,
    clearCreateScreeningWaitlistError,
  ] = useCreateScreeningWaitlist();

  const [selectedWaitlist, setSelectedWaitlist] =
    useState<WaitlistScreeningDto>();

  const [
    removeWaitlist,
    ,
    removeWaitlistRequestState,
    clearRemoveWaitlistErrors,
  ] = useRemoveWaitlist();

  const [tabIndex, handleChangeTabIndex] = useTabIndex(screeningDetailsTabName);
  const canReadCases = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.Case)
  );

  const [canCreateCase, setCanCreateCase] = useState(false);
  //#endregion

  //#region Handlers
  const handleCreateScreeningWaitlistClicked = () => {
    clearCreateScreeningWaitlistError();
    dispatch(openModal({ modalId: waitlistModalId }));
  };

  const handleTransferScreeningClicked = () => {
    dispatch(openModal({ modalId: youthRecordTransferModalId }));
  }

  const handleDeleteScreeningClicked = () => {
    clearDeleteScreeningErrors();
    dispatch(openModal({ modalId: confirmDeleteScreeningDialogId }))
  };

  const refresh = useCallback(async () => {
    if(id != null) {
      await getScreeningById(id);
    }
  }, [getScreeningById, id]);

  const handleCreateScreeningWaitlist = async (dto: WaitlistFormDto) => {
    await createScreeningWaitlist({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Waitlist Created" }));
      dispatch(closeModal());
    });
    await getScreeningById(id);
  };

  const handleRemoveWaitlistClicked = (waitlist: WaitlistScreeningDto) => {
    clearRemoveWaitlistErrors();
    setSelectedWaitlist(waitlist);
    dispatch(openModal({ modalId: removeWaitlistModalId }));
  };

  const handleRemoveWaitlist = async (dto: RemoveWaitlistFormDto) => {
    await removeWaitlist({ id: selectedWaitlist!.id, dto });
    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({ message: "Youth Removed from Waitlist" })
      );
    });
    getScreeningById(id);
  };

  const handleStartCaseClicked = () => {
    history.push(`/youths/${screening?.youth.id}/cases/new?screeningId=${id}`, {
      prevLocation: location.pathname,
      text: "Back to screening",
    });
  };
  //#endregion

  //#region Update Screening
  // Request
  const [
    updateScreening,
    ,
    updateScreeningRequestState,
    cleareUpdateScreeningErrors,
  ] = useUpdateScreening();

  const [
    deleteScreening,
    ,
    deleteScreeningRequestState,
    clearDeleteScreeningErrors,
  ] = useDeleteScreening();

  const handleUpdateScreeningClicked = () => {
    cleareUpdateScreeningErrors();
    dispatch(openModal({ modalId: youthScreeningFormDialogId }));
  };

  const handleUpdateScreening = async (dto: ScreeningFormDto) => {
    await updateScreening({ id, dto });
    batch(() => {
      dispatch(closeModal());
      dispatch(addSuccessNotification({ message: "Screening Updated" }));
    });
    await getScreeningById(id);
  };

  const handleDeleteScreening = async (dto: ScreeningDetailDto) => {
    await deleteScreening(dto.id);
    batch(() => {
      dispatch(closeModal());
      dispatch(addSuccessNotification({ message: "Screening Deleted" }));
    });

    history.push(`/youths/${dto.youth.id}`);
  }

  //#endregion

  //#region Effects
  useEffect(() => {
    getScreeningById(id);
  }, [id, getScreeningById]);

  useEffect(() => {
    if (screening) {
      const days = calculateDateDifferenceInDays(
        new Date(),
        screening.screeningAt
      );

      setCanCreateCase(days <= 60);
    }
  }, [screening, setCanCreateCase]);
  //#endregion

  return {
    state: {
      screening,
      getScreeningRequestState,
      createScreeningWaitlistRequestState,
      removeWaitlistRequestState,
      tabIndex,
      canReadCases,
      canCreateCase,
      updateScreeningRequestState,
      deleteScreeningRequestState
    },
    handlers: {
      handleStartCaseClicked,
      handleChangeTabIndex,
      handleCreateScreeningWaitlist,
      handleCreateScreeningWaitlistClicked,
      handleRemoveWaitlist,
      handleRemoveWaitlistClicked,
      handleUpdateScreeningClicked,
      handleUpdateScreening,
      handleTransferScreeningClicked,
      handleDeleteScreeningClicked,
      handleDeleteScreening
    },
    refreshScreening: refresh
  };
};
