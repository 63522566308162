import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum LivingSituationEnum {
  FamilyFriendsHome = "FamilyFriendsHome",
  Runaway = "Runaway",
  Lockout = "Lockout",
  Homeless = "Homeless",
  Emancipated = "Emancipated",
  DJJPlacementRespite = "DJJPlacementRespite",
  DCFPlacement = "DCFPlacement",
}

export const livingSituationTextMap: { [key: string]: string } = {
  [LivingSituationEnum.FamilyFriendsHome]: "Family/Friend's Home",
  [LivingSituationEnum.Runaway]: "Runaway",
  [LivingSituationEnum.Lockout]: "Lockout",
  [LivingSituationEnum.Homeless]: "Homeless",
  [LivingSituationEnum.Emancipated]: "Emancipated",
  [LivingSituationEnum.DJJPlacementRespite]: "DJJ-Placement Respite",
  [LivingSituationEnum.DCFPlacement]: "DCF Placement",
};

export const getLivingSituationOptions = (
  defaultOption: string = "Select Living Situation"
): SelectOption[] => [
  { value: "", label: defaultOption },
  ...Object.keys(LivingSituationEnum).map((x) => ({
    value: x,
    label: livingSituationTextMap[x],
  })),
];
