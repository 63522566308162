import { invoicingAgent } from "../../app/api/agent";
import {
  ProviderFilledBedDayDto,
  ProviderFilledBedDayFormDto,
} from "./dtos/ProviderFilledBedDayDto";

export const getProviderFilledBedDaysRequest = (id: string) =>
  invoicingAgent.getRequest<ProviderFilledBedDayDto[]>(
    `providers/${id}/filledBedDays`,
    undefined,
    true
  );

export const upsertProviderFilledBedDaysRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: ProviderFilledBedDayFormDto;
}) =>
  invoicingAgent.postRequest<ProviderFilledBedDayDto>(
    `providers/${id}/filledBedDays`,
    dto,
    undefined,
    true
  );
