import { Typography } from "@material-ui/core";
import React from "react";
import {
  SchoolStatusEnum,
  schoolStatusTextMap,
} from "../../enums/SchoolStatusEnum";

interface Props {
  status: SchoolStatusEnum;
}

const SchoolStatusLabel: React.FC<Props> = ({ status }) => {
  const text = schoolStatusTextMap[status];

  return <Typography component="span">{text}</Typography>;
};

export default SchoolStatusLabel;
