import { SelectOption } from "../components/inputs/ControlledSelect";

export enum MonthEnum {
  January = "0",
  February = "1",
  March = "2",
  April = "3",
  May = "4",
  June = "5",
  July = "6",
  August = "7",
  September = "8",
  October = "9",
  November = "10",
  December = "11",
}

export const monthTextMap: { [key: string]: string } = {
  [MonthEnum.January]: "January",
  [MonthEnum.February]: "February",
  [MonthEnum.March]: "March",
  [MonthEnum.April]: "April",
  [MonthEnum.May]: "May",
  [MonthEnum.June]: "June",
  [MonthEnum.July]: "July",
  [MonthEnum.August]: "August",
  [MonthEnum.September]: "September",
  [MonthEnum.October]: "October",
  [MonthEnum.November]: "November",
  [MonthEnum.December]: "December",
};

export const monthOptions: SelectOption[] = [
  { value: "", label: "Select Month" },
  ...Object.values(MonthEnum).map((month) => ({
    value: month,
    label: monthTextMap[month],
  })),
];

export const fiscalYearOrder = [6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5];

export const fiscalYearMonthOptions: SelectOption[] = [
  { value: "", label: "Select Month" },
  ...Object.values(MonthEnum)
    .sort((a, b) =>
      fiscalYearOrder.indexOf(+a) > fiscalYearOrder.indexOf(+b) ? 1 : -1
    )
    .map((value) => ({ value, label: monthTextMap[value] })),
];

export const getMonthOptions = (): SelectOption[] => [
  { value: "", label: "Select Month" },
  ...Object.values(MonthEnum).map((month) => ({
    value: month,
    label: monthTextMap[month],
  })),
];
