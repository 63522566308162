import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import { Fragment, useCallback, useState } from "react";
import ProviderAccessWrapper from "../../../../app/components/access-wrappers/ProviderAccessWrapper";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../../app/components/buttons/AddButton";
import DeleteButton from "../../../../app/components/buttons/DeleteButton";
import DateLabel from "../../../../app/components/labels/DateLabel";
import { TabProps } from "../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import CaseSessionDeleteDialog from "../../../case-sessions/components/CaseSessionsDeleteDialog";
import { CaseSessionDto } from "../../../case-sessions/dtos/CaseSessionDto";
import { useCaseDetailPageContext } from "../../pages/CaseDetailPage";

export const caseSessionsSectionName = "case-sessions-section";

const CaseSessionsSection = () => {
  const {
    caseSession: {
      caseSessions,
      deleteCaseSessionRequestState,
      handleCreateCaseSessionClicked,
      handleDeleteCaseSessionClicked,
      handleDeleteCaseSession
    },
  } = useCaseDetailPageContext();

  const [selectedCaseSession, setSelectedCaseSession] = useState<CaseSessionDto>();

  const onDeleteButtonClicked = (caseSession: CaseSessionDto) => {
    setSelectedCaseSession(caseSession);
    handleDeleteCaseSessionClicked();
  };

  const onConfirmDelete = useCallback(async () => {
    await handleDeleteCaseSession(selectedCaseSession!);
  }, [selectedCaseSession, handleDeleteCaseSession]);

  const createRow = (session: CaseSessionDto) => {
    return (
      <TableRow key={session.id}>
        <TableCell>
          <DateLabel date={session.sessionHeldOn} />
        </TableCell>
        <TableCell>{session.sessionType}</TableCell>
        <TableCell>
          <ProviderAccessWrapper>
            <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
              <DeleteButton
                text="Delete"
                onClick={() => onDeleteButtonClicked(session)}
              ></DeleteButton>
            </WriteAccessLevelWrapper>
          </ProviderAccessWrapper>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Fragment>
      <ProviderAccessWrapper>
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <AddButton
            text="Add a Session"
            variant="contained"
            color="primary"
            onClick={handleCreateCaseSessionClicked}
          />
          <CaseSessionDeleteDialog
            onConfirm={onConfirmDelete}
            requestState={deleteCaseSessionRequestState}
            caseSession={selectedCaseSession}
          />
        </WriteAccessLevelWrapper>
      </ProviderAccessWrapper>
      {caseSessions && caseSessions.length > 0 && (
        <TableContainer
          component={Paper}
          variant="outlined"
          square
          style={{ marginTop: "12px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Session Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Session Type</Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {caseSessions
                .sort((a, b) => (a.sessionHeldOn > b.sessionHeldOn ? -1 : 1))
                .map((s) => createRow(s))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
};

export default CaseSessionsSection;

export const createCaseSessionsSection = (): TabProps => ({
  value: caseSessionsSectionName,
  label: "Sessions",
  content: <CaseSessionsSection />,
});
