import { Box, Button, Menu, MenuItem, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { ReportingPeriodDto } from "../../reporting-periods/dto/ReportingPeriodDto";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DateTime } from "luxon";
import {ContractDto} from "../../contracts/dtos/ContractDto";
import {InvoiceStatusDto} from "../dtos/InvoiceStatusDto";

interface Props {
  currentReportingPeriod?: ReportingPeriodDto;
  previousReportingPeriod?: ReportingPeriodDto;
  contracts: ContractDto[];
  pendingInvoices: InvoiceStatusDto;
  onPreviewInvoice(
    contractId: string,
    reportingPeriod: ReportingPeriodDto
  ): void;
}

const InvoiceListActions: React.FC<Props> = ({
  currentReportingPeriod,
  previousReportingPeriod,
  contracts,
  onPreviewInvoice,
  pendingInvoices,
}) => {
  //#region State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //#endregion

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderNewMenuItem = (contract: ContractDto, reportingPeriod: ReportingPeriodDto) => {
    const date = DateTime.fromJSDate(
      new Date(reportingPeriod.year, reportingPeriod.month - 1, 1)
    );
    const label = `${date.toFormat("MMM yyyy")} ${contract.name} Invoice`

    return <MenuItem key={`${contract.id}-${reportingPeriod.id}`}
      onClick={() => onPreviewInvoice(contract.id, reportingPeriod!)}
    >
      <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
        <Typography>{label}</Typography>
      </Box>
    </MenuItem>
  }

  const reportingPeriod = currentReportingPeriod?.id === pendingInvoices.reportingPeriodId ? currentReportingPeriod : previousReportingPeriod;
  const contractsToInvoice = contracts.filter(c => pendingInvoices.contractIds.some(cid => cid === c.id))

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        Preview Invoices
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        { contractsToInvoice.length > 0 
            && contractsToInvoice
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map(c => renderNewMenuItem(c, reportingPeriod!))
        }
        { contractsToInvoice.length === 0 && <MenuItem key="no-invoices" disabled={true}><Typography>No Invoices to Preview</Typography></MenuItem>}
      </Menu>
    </Fragment>
  );
};

export default InvoiceListActions;
