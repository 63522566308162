import { ReferralServiceRequestedEnum } from "../enums/ReferralServiceRequestedEnum";
import { createReferrerFormDto, ReferrerFormDto } from "./ReferrerFormDto";

export interface ReferralFormDto {
  referredBy: ReferrerFormDto;
  youthFirstName: string;
  youthLastName: string;
  youthDob?: string;
  youthZipCode: string;
  youthCity: string;
  reasonForReferral: string;
  isReferralSelf: string;
  isReferralFamily: string;
  referrerRelationship?: string;
  heardAboutUs: string;
  heardAboutUsOption: string;
  requestedServicesOptions: { [key: string]: boolean };
  requestedServices: string[];
  state: string;
}

export const getRequestedServicesOptions = () => {
  return Object.keys(ReferralServiceRequestedEnum).reduce<{
    [key: string]: boolean;
  }>((result, value) => {
    result[value] = false;
    return result;
  }, {});
};

export const createReferralFormDto = (): ReferralFormDto => {
  return {
    referredBy: createReferrerFormDto(),
    youthFirstName: "",
    youthLastName: "",
    youthDob: "",
    youthZipCode: "",
    youthCity: "",
    reasonForReferral: "",
    isReferralFamily: "",
    isReferralSelf: "",
    referrerRelationship: "",
    heardAboutUs: "",
    heardAboutUsOption: "",
    requestedServicesOptions: getRequestedServicesOptions(),
    requestedServices: [],
    state: "FL",
  };
};
