import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { useAppDispatch } from "../../../app/hooks";
import { useAnchor } from "../../../app/hooks/useAnchor";
import { useQuery } from "../../../app/hooks/useQuery";
import { useRequest } from "../../../app/hooks/useRequest";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import {
  useCreateOutreachActivityRequest,
  useGetAllProviders,
} from "../../provider/hooks/providerHooks";
import { outreachActivityFormModalId } from "../components/OutreachActivityFormDialog";
import {
  OutreachActivitySearchParamsDto,
  OutreachActivitySummaryDto,
} from "../dtos/OutreachActivityDto";
import { OutreachActivityFormDto } from "../dtos/OutreachActivityFormDto";
import { exportOutreachActivityListRequest } from "../OutreachActivityRequests";
import { useGetOutreachActivities } from "./outreachActivitityHooks";

export const useOutreachActivityListPage = () => {
  //#region
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const [
    getOutreachActivities,
    outreachResults,
    getOutreachActivityRequestState,
  ] = useGetOutreachActivities();

  const [
    createOutreachActivity,
    ,
    createOutreachActivityRequestState,
    clearCreateOutreachActivityError,
  ] = useCreateOutreachActivityRequest();

  const [params, setParams] = useState<OutreachActivitySearchParamsDto>(
    new OutreachActivitySearchParamsDto({ query })
  );
  const [filterAnchorEl, handleOpenFilterClick, handleCloseFilter] =
    useAnchor();
  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();

  const [exportOutreachActivityList, , exportOutreachActivityListRequestState] = useRequest(exportOutreachActivityListRequest);

  // This limit is also enforced server side, so they need to be in sync
  const exportRecordsLimit = 50000;
  const [allowExport, setAllowExport] = useState(true)  

  const dispatch = useAppDispatch();
  //#endregion

  //#region Handlers
  const handlePageChange = useCallback(
    (page: number) => {
      setParams((params) => ({ ...params, page }));
    },
    [setParams]
  );

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    setParams(newParams);
  };

  const handleSearch = useCallback(
    (dto: OutreachActivitySearchParamsDto) => {
      setParams(dto);
    },
    [setParams]
  );

  const handleExportListClicked = useCallback((dto: OutreachActivitySearchParamsDto) => {
    const filename = `outreach-list-export-${DateTime.now().toFormat('yyyyLLddHHmmss')}.csv`
    exportOutreachActivityList({ searchParams: dto, filename });
  }, [exportOutreachActivityList])

  const handleCreateOutreachActivityClicked = () => {
    clearCreateOutreachActivityError();
    dispatch(openModal({ modalId: outreachActivityFormModalId }));
  };

  const handleCreateOutreachActivity = async (dto: OutreachActivityFormDto) => {
    dto.topics = Object.entries(dto.topicOptions)
      .filter(([, value]) => value)
      .map(([key]) => key);

    dto.targetAudiences = Object.entries(dto.targetAudienceOptions)
      .filter(([, value]) => value)
      .map(([key]) => key);

    const result = await createOutreachActivity({ id: getProviderId()!, dto });

    batch(() => {
      dispatch(
        addSuccessNotification({
          message: "Outreach Activity Created",
        })
      );
      dispatch(closeModal());
    });

    if (result)
      history.push({
        pathname: `/outreach-activities/${result.id}`,
        state: {
          prevLocation: location.pathname,
          text: "Back to Outreach Activities",
        },
      });
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    getOutreachActivities(params);
  }, [params, getOutreachActivities]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId === fnyfsProviderId) {
      getProviders(undefined);
    }
  }, [getProviders]);

  useEffect(() => {
    history.push(
      `/outreach-activities?${OutreachActivitySearchParamsDto.toQueryString(
        params
      )}`
    );
  }, [params, history]);

  useEffect(() => {
    setAllowExport(!!outreachResults?.count && outreachResults.count < exportRecordsLimit);
  }, [outreachResults?.count])
  //#endregion

  return {
    state: {
      params,
      result:
        outreachResults ||
        new PaginationResultDto<OutreachActivitySummaryDto>(),
      providers: providers || [],
      getOutreachActivityRequestState,
      getProvidersRequestState,
      filterAnchorEl,
      createOutreachActivityRequestState,
      allowExport,
      exportRecordsLimit,
      exportOutreachActivityListRequestState
    },
    handlers: {
      handlePageChange,
      handleSort,
      handleCloseFilter,
      handleSearch,
      handleOpenFilterClick,
      handleCreateOutreachActivityClicked,
      handleCreateOutreachActivity,
      handleExportListClicked
    },
  };
};
