import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { useGetProviderStaffMembers } from "../../provider/hooks/providerHooks";
import { caseContactSchema } from "../components/CaseContactFormFields";
import { CaseContactDto } from "../dtos/CaseContactDto";
import {
  createCaseContactFormDto,
  CaseContactFormDto,
} from "../dtos/CaseContactFormDto";

export const useCaseContactForm = (
  onCreate: (dto: CaseContactFormDto) => Promise<any>,
  onUpdate: (id: string, dto: CaseContactFormDto) => Promise<any>,
  caseDto?: CaseDetailDto,
  contact?: CaseContactDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createCaseContactFormDto(contact),
    resolver: yupResolver(caseContactSchema),
    mode: "all",
  });

  const [
    getProviderStaffMembers,
    staffMembers,
    getProviderStaffMembersRequestState,
  ] = useGetProviderStaffMembers();
  //#endregion

  const clearForm = () => {
    reset(createCaseContactFormDto(contact));
  };
  const handleOnSubmit = handleSubmit(async (dto: CaseContactFormDto) => {
    if(contact?.id != null) {
      await onUpdate(contact.id, dto);
    } else {
      await onCreate(dto);
    }
    clearForm();
  });

  useEffect(() => {
    reset(createCaseContactFormDto(contact));
  }, [caseDto, contact, reset]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId !== fnyfsProviderId)
      getProviderStaffMembers(providerId);
  }, [getProviderStaffMembers]);

  return {
    state: {
      staffMembers: staffMembers || [],
      getProviderStaffMembersRequestState,
    },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
