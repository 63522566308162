import React, { useCallback } from "react";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { SnapInSchoolsSearchParamsDto } from "../dtos/SnapInSchoolsSearchParamsDto";
import { gradeLevelTextMap } from "../enums/GradeLevelEnum";
import FilterList from "../../../app/components/FilterList";
import { DateTime } from "luxon";

const formatDate = (date: string) => {
  return DateTime.fromJSDate(new Date(`${date}T00:00`)).toFormat("MM-dd-yyyy");
};
interface Props {
  params: SnapInSchoolsSearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  onSearch(dto: SnapInSchoolsSearchParamsDto): void;
}

const SnapInSchoolsCycleFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  onSearch,
}) => {
  
  const handleClearParams = () => {
    return new SnapInSchoolsSearchParamsDto({ previous: params });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "providerId":
          return providers.length > 0 ?
            `Provider: ${providers.find((x) => x.id === value)?.name}`
            : '';
        case "grade":
          return `Grade: ${gradeLevelTextMap[value]}`;
        case "cycleStartDateRangeStart":
            return `Cycle Start Date Start: ${formatDate(value)}`;
        case "cycleStartDateRangeEnd":
          return `Cycle Start Date End: ${formatDate(value)}`;          
      }
      return "";
    },
    [providers]
  );

  const ordering = [
    "providerId", 
    "programId", 
    "grade", 
    "cycleStarDateRangeStart",
    "cycleStarDateRangeEnd"];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default SnapInSchoolsCycleFilterList;
