import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../../app/components/ErrorText";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectModalId } from "../../../modal/state/modalSelectors";
import { closeModal } from "../../../modal/state/modalSlice";
import { AddProviderRoleFormDto } from "../../dtos/AddProviderRoleFormDto";
import { useProviderRoleForm } from "../../hooks/useProviderRoleForm";
import ProviderRoleFormFields from "./ProviderRoleFormFields";

export const providerRoleModalId = "user-role-form-modal";

interface Props {
  onSubmit: (dto: AddProviderRoleFormDto) => Promise<any>;
  requestState: RequestStateDto;
}

const ProviderRoleFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
}) => {
  //#region State
  const dispatch = useAppDispatch();
  const modalId = useAppSelector(selectModalId);

  const { isLoading, error } = requestState;
  const { form, handlers, state } = useProviderRoleForm(onSubmit);
  const { control, clearForm, isValid } = form;
  const { handleSubmit } = handlers;
  const {
    providers,
    getProvidersRequestState: {
      isLoading: providersAreLoading,
      error: getProvidersError,
    },
    roles,
    getRolesRequestState: { isLoading: rolesAreLoading, error: getRolesError },
  } = state;

  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion
  return (
    <Dialog open={modalId === providerRoleModalId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Create Provider Role
          <ErrorText error={error?.message} />
          <ErrorText error={getProvidersError?.message} />
          <ErrorText error={getRolesError?.message} />
        </DialogTitle>
        <DialogContent style={{ minWidth: "min(624px, 100vw)" }}>
          <Grid container spacing={2}>
            <ProviderRoleFormFields
              control={control}
              roles={roles}
              providers={providers}
              disabled={isLoading}
              providersAreLoading={providersAreLoading}
              rolesAreLoading={rolesAreLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleCloseModal} disabled={isLoading}>
            Cancel
          </Button>
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProviderRoleFormDialog;
