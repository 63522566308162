import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { batch } from "react-redux";
import { useHistory } from "react-router";
import { programIsType, screeningIsDvRespite } from "../../../app/helpers";
import { useAppDispatch } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { useQuery } from "../../../app/hooks/useQuery";
import { confirmForceSubmitCaseDialogId } from "../../cases/components/ConfirmForceSubmitCaseDialog";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { CaseFormDto } from "../../cases/dtos/CaseFormDto";
import { useCaseForm } from "../../cases/hooks/useCaseForm";
import { intakeSchema } from "../../intakes/components/IntakeFormFields";
import { JjisProgramTypeEnum } from "../../jjis/enums/JjisProgramTypeEnum";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { ScreeningDto } from "../../screenings/dtos/ScreeningDto";
import { YouthSummaryDto } from "../dto/YouthSummaryDto";
import { useYouthAddress } from "./useYouthAddress";
import {
  useCreateYouthCase,
  useGetYouths,
  useGetRecentYouthScreenings,
} from "./youthHooks";

export const useYouthCaseFormPage = () => {
  //#region State
  const id = useId();
  const query = useQuery();

  const [createYouthCase, , createYouthCaseRequestState] = useCreateYouthCase();
  const [forceDisclaimer, setForceDisclaimer] = useState<string>();
  const dispatch = useAppDispatch();
  const form = useForm({
    defaultValues: CaseFormDto.createNewCaseFormDto(undefined),
    resolver: yupResolver(intakeSchema),
    mode: "all",
  });
  const {
    control,
    watch,
    formState: { isValid },
    setValue,
    trigger,
    reset,
    getValues,
  } = form;
  const { programId, screeningId, intakeAt, primaryStaffIds } = watch();

  const [getYouthScreenings, screenings, getYouthScreeningsRequestState] =
    useGetRecentYouthScreenings(id);

  const updateScreening = useCallback(
    (localScreeningId?: string) => {
      if (!!query.screeningId) return;

      if (localScreeningId !== "" && localScreeningId != null) {
        const screening = screenings.find((x) => x.id === localScreeningId);
        setValue("screeningId", screening?.id || "");
      }
    },
    [screenings, setValue, query.screeningId]
  );

  const submit = async (dto: CaseFormDto, force?: boolean) => {
    try {
      const result = await createYouthCase({ id, dto, force });
      batch(() => {
        dispatch(closeModal());
        dispatch(addSuccessNotification({ message: "Case Created" }));
      });
      history.push(`/cases/${result?.id}`);
    } catch (error: any) {
      const forceDisclaimer = error.response.headers["force-disclaimer"];

      if (forceDisclaimer) {
        setForceDisclaimer(forceDisclaimer);
        dispatch(openModal({ modalId: confirmForceSubmitCaseDialogId }));
      }

      throw error;
    }
  };

  const handleStaffAdded = (id: string) => {
    setValue('primaryStaffIds', [...primaryStaffIds, id])
  }

  const handleStaffRemoved = (id: string) => {
    setValue('primaryStaffIds', [...primaryStaffIds.filter(staffId => staffId !== id)])
  }

  const handleFileUpdated = (filename: string) => {
    setValue("courtOrderDocumentFilename", filename);
    trigger("courtOrderDocumentFilename");
  };
  const {
    // providerLocations,
    providerPrograms,
    locations,
    staffMembers,
    getProgramLocationId,
    schoolNames,
    getProviderLocationsRequestState,
    getProviderProgramsRequestState,
    getProviderStaffMemberRequestState,
    getSchoolNamesRequestState,
    handleSubmit,
  } = useCaseForm(screenings || [], submit, form, updateScreening);

  const history = useHistory<{ case?: CaseDetailDto }>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [getYouths, , getYouthsRequestState] = useGetYouths();
  const [youth, setYouth] = useState<YouthSummaryDto>();

  const {
    address: youthAddress,
    getYouthAddressRequestState,
    updateYouthAddressRequestState,
    handleUpdateYouthAddress,
    handleUpdateYouthAddressClicked,
  } = useYouthAddress(id);

  //#endregion

  const handleForceSubmit = async () => {
    const dto = getValues();
    submit(dto, true);
  };

  //#region Effects
  useEffect(() => {
    const load = async () => {
      const result = await getYouths({ ids: [id] });
      setYouth(result?.items[0]);
    };
    load();
  }, [id, getYouths, setYouth]);

  useEffect(() => {
    if (
      isInitialized ||
      providerPrograms == null ||
      locations == null ||
      youth == null ||
      getYouthScreeningsRequestState.isLoading
    )
      return;

    const queryProgramId = query.programId as string;
    const queryLocationId = query.locationId as string;
    const queryScreeningId = query.screeningId as string;
    const stateCase = history.location?.state?.case;
    if (stateCase !== undefined) {
      reset(CaseFormDto.createNewCaseFormDtoFromCaseDetails(stateCase));
    } else {
      let screening: ScreeningDto | undefined = undefined;
      if (!!queryScreeningId) {
        screening = screenings.find((x) => x.id === queryScreeningId);
      }
      reset(
        CaseFormDto.createNewCaseFormDto(
          youth?.dob,
          providerPrograms?.find((x) => x.id === queryProgramId)
            ? queryProgramId
            : undefined,
          locations?.find((x) => x.id === queryLocationId)
            ? queryLocationId
            : "",
          getProgramLocationId(queryProgramId, queryLocationId),
          screening
        )
      );
    }
    setIsInitialized(true);
  }, [
    youth,
    reset,
    query.programId,
    query.locationId,
    query.screeningId,
    providerPrograms,
    locations,
    history.location.state?.case,
    getProgramLocationId,
    screenings,
    isInitialized,
    setIsInitialized,
    getYouthScreeningsRequestState.isLoading,
  ]);

  useEffect(() => {
    if (!!query.screeningId) return;

    if (
      programIsType(programId, providerPrograms, JjisProgramTypeEnum.Shelter)
    ) {
      setValue("isYouthOnMedication", false);
      setValue("youthMedicationCount", 0);
    } else if (
      programIsType(
        programId,
        providerPrograms,
        JjisProgramTypeEnum.DVRespite
      ) &&
      screeningId &&
      !screeningIsDvRespite(screeningId!, screenings)
    ) {
      setValue("screeningId", "");
    }
  }, [
    programId,
    providerPrograms,
    setValue,
    screenings,
    screeningId,
    query.screeningId,
  ]);

  useEffect(() => {
    const queryScreeningId = query.screeningId as string;
    const startFromScreening = !!queryScreeningId;
    getYouthScreenings(intakeAt, startFromScreening);
  }, [getYouthScreenings, intakeAt, query.screeningId]);
  //#endregion

  return {
    state: {
      id,
      youth,
      providerPrograms,
      locations,
      staffMembers: staffMembers || [],
      screenings: screenings || [],
      getYouthScreeningsRequestState,
      getProviderLocationsRequestState,
      getProviderProgramsRequestState,
      createYouthCaseRequestState,
      getProviderStaffMemberRequestState,
      getYouthsRequestState,
      forceDisclaimer,
      youthAddress,
      getYouthAddressRequestState,
      updateYouthAddressRequestState,
      getSchoolNamesRequestState,
      schoolNames: schoolNames || [],
    },
    form,
    formState: {
      control,
      isValid,
    },
    handlers: {
      handleSubmit,
      handleForceSubmit,
      handleUpdateYouthAddressClicked,
      handleUpdateYouthAddress,
      handleStaffAdded,
      handleStaffRemoved,
      handleFileUpdated
    },
  };
};
