import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  AlertDismissalFilterFormDto,
  AlertDismissalSearchParamsDto,
} from "../dtos/AlertDismissalDto";

export const useAlertDismissalFilter = (
  onSubmit: (dto: AlertDismissalSearchParamsDto) => void,
  onClose: () => void,
  params: AlertDismissalSearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: new AlertDismissalFilterFormDto(params),
  });
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: AlertDismissalSearchParamsDto) => {
    const newParams: AlertDismissalSearchParamsDto = {
      ...dto,
      page: 0,
      pageSize: params.pageSize,
    };

    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new AlertDismissalFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new AlertDismissalFilterFormDto(params));
  }, [params, reset]);

  //#endregion

  return {
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
