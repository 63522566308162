import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectModalId} from "../../modal/state/modalSelectors";
import {closeModal} from "../../modal/state/modalSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import ErrorText from "../../../app/components/ErrorText";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import {CaseDetailDto} from "../../cases/dtos/CaseDetailDto";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {FollowUpDto} from "../dtos/FollowUpDto";
import {useUpdateFollowUpCompletionDataForm} from "../hooks/useUpdateFollowUpCompletionDataForm";
import ControlledYesNoRadioGroup from "../../../app/components/inputs/ControlledYesNoRadioGroup";

interface Props {
  followUp: FollowUpDto,
  caseDto: CaseDetailDto;
  onSubmit: () => Promise<void>;
}
export const updateCompletionDataDialogId = "update-follow-up-completion-data-dialog";
const UpdateCompletionDataDialog: React.FC<Props> = ({
                                                      followUp,
                                                      caseDto,
                                                      onSubmit,
                                                    }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    state: {
      schoolStatusOptions,
      livingSituationOptions,
      followUpImprovementOptions,
      updateFollowUpCompletionRequestState
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useUpdateFollowUpCompletionDataForm(onSubmit, caseDto, followUp);


  const { isLoading, error } = updateFollowUpCompletionRequestState;

  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === updateCompletionDataDialogId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Edit Follow Up Data
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="attemptedAt"
                label="Attempt Date/Time"
                type="datetime-local"
                control={control}
                disabled={false}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledYesNoRadioGroup
                disabled={false}
                control={control}
                name="receivedServicesSinceDischarge"
                label="Has the youth received services at this agency since discharge?"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledYesNoRadioGroup
                control={control}
                name="receivedServicesFromAnotherProviderSinceDischarge"
                label="Did the youth/family receive services from another Florida Network agency/program since discharge?"
                disabled={false}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                label={"Current Living Status"}
                control={control}
                name={"livingStatus"}
                options={livingSituationOptions}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledYesNoRadioGroup
                control={control}
                name="isYouthsLivingStatusAppropriate"
                label="Is this an appropriate living situation for the youth?"
                disabled={false}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                label={"Current School Status"}
                control={control}
                name={"schoolStatus"}
                options={schoolStatusOptions}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="seenImprovement"
                label="Have you seen improvement in the issues for which the youth/family received services at your agency?"
                options={followUpImprovementOptions}
                disabled={false}
                fullWidth
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={false}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateCompletionDataDialog;
