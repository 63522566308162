import React from "react";
import {Checkbox, createStyles, FormControlLabel, makeStyles, Paper, Theme} from "@material-ui/core";

export interface IntervalSelections {
  includeThirtyDay: boolean;
  includeSixtyDay: boolean;
  includeNinetyDay: boolean;

}

interface Props extends IntervalSelections {
  onChange: (selections: IntervalSelections) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      alignItems: "center",
      padding: theme.spacing(0.5),
      margin: "16px 0px",
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

export const FollowUpIntervalSelector: React.FC<Props> = ({ onChange, ...selections }) => {

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updatedSelections = {...selections, [event.target.name]: checked};
    onChange(updatedSelections);
  }

  return (

      <Paper component="ul" className={classes.root}>

      <FormControlLabel
        control={<Checkbox checked={selections.includeThirtyDay} name="includeThirtyDay" onChange={handleChange} />}
        label="Show 30 Day"
      />
      <FormControlLabel
        control={<Checkbox checked={selections.includeSixtyDay} name="includeSixtyDay" onChange={handleChange} />}
        label="Show 60 Day"
      />
      <FormControlLabel
        control={<Checkbox checked={selections.includeNinetyDay} name="includeNinetyDay" onChange={handleChange} />}
        label="Show 90 Day"
      />
      </Paper>

  )
}
