import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import WriteAccessLevelWrapper from "../../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditButton from "../../../../../../app/components/buttons/EditButton";
import { TabProps } from "../../../../../../app/components/TabContainer";
import { AssessmentDto } from "../../../../../assessments/dtos/AssessmentDto";
import { AssessmentParentTypeEnum } from "../../../../../assessments/enums/AssessmentParentTypeEnum";
import { PermissionResourceNameEnum } from "../../../../../authentication/enums/PermissionResourceNameEnum";
import { useCaseDetailPageContext } from "../../../../pages/CaseDetailPage";
import NirvanaAssessmentListItem from "./NirvanaAsessmentListItem";
import RefreshIcon from "@material-ui/icons/Refresh";
import LoadingDetail from "../../../../../../app/components/loading/LoadingDetail";
import { createNumberRange } from "../../../../../../app/helpers";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssessmentScoreDialog from "../../../../../assessments/components/AssessmentScoreDialog";
import AssessmentAmendmentHistoryDialog from "../../../../../assessments/components/AssessmentAmendmentHistoryDialog";
import { AssessmentTypeEnum } from "../../../../../assessments/enums/AssessmentTypeEnum";
import { AssessmentStatusEnum } from "../../../../../assessments/enums/AssessmentStatusEnum";
import { useRequest } from "../../../../../../app/hooks/useRequest";
import { getNirvanaAssessmentComparisonReportRequest } from "../../../../../assessments/AssessmentRequests";
import ErrorText from "../../../../../../app/components/ErrorText";
import SpinnerButton from "../../../../../../app/components/buttons/SpinnerButton";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export const nirvanaTabName = "nirvana-section";

const NirvanaSection = () => {
  //#region State
  const {
    case: { caseResult, nirvana, assessment },
  } = useCaseDetailPageContext();
  const {
    assessments,
    selectedAssessment,
    handleStartNirvanaAssessmentClicked,
    handleViewNirvanaAssessmentScoresClicked,
    handleViewNirvanaAssessmentAmendmentsClicked,
  } = nirvana;

  const { handleRefreshAssessments, getAssessmentsRequestState } = assessment;

  const [assessmentGroups, setAssessmentGroups] = useState<{
    [key: string]: AssessmentDto[];
  }>({});

  const viewComparisonReportDisabled = assessments.filter((x) => x.type !== AssessmentTypeEnum.NirvanaSelfReport).length < 2;

  const getRootParentId = useCallback(
    (assessment: AssessmentDto): string => {
      if (assessment.parentId == null || assessment.parentType !== AssessmentParentTypeEnum.AmendmentOf) return assessment.id;

      const parent = assessments.find((x) => x.id === assessment.parentId);

      if (parent != null) return getRootParentId(parent);

      return "";
    },
    [assessments]
  );

  const groupAssessments = useCallback(() => {
    const groups = assessments.reduce<{ [key: string]: AssessmentDto[] }>((acc, assessment) => {
      const id = getRootParentId(assessment);

      let curr = acc[id];
      if (curr == null) curr = [];
      curr.push(assessment);
      acc[id] = curr;

      return acc;
    }, {});

    return Object.values(groups).reduce<{ [key: string]: AssessmentDto[] }>((acc, group) => {
      const key = group.sort((a, b) => (a.referenceNumber > b.referenceNumber ? -1 : 1))[0].id;
      acc[key] = group;

      return acc;
    }, {});
  }, [assessments, getRootParentId]);

  const sortedAssessments = () => {
    return assessments.filter((x) => Object.keys(assessmentGroups).includes(x.id)).sort((a, b) => (new Date(a.completedOn) > new Date(b.completedOn) ? 1 : -1));
  };
  //#endregion

  //#region UI Helpers
  const renderAssessment = (assessment: AssessmentDto) => (
    <NirvanaAssessmentListItem
      assessments={assessmentGroups[assessment.id]}
      key={assessment.id}
      onViewScoresClicked={() => handleViewNirvanaAssessmentScoresClicked(assessment)}
      onViewAmendmentsClicked={() => handleViewNirvanaAssessmentAmendmentsClicked(assessment)}
    />
  );
  const renderLoading = () =>
    createNumberRange(4).map((i) => (
      <Box marginBottom={"12px"} key={`loading-${i}`}>
        {" "}
        <LoadingDetail lines={2} icon={<AssessmentIcon />} />
      </Box>
    ));

  const renderAssessments = () => sortedAssessments().map(renderAssessment);
  //#endregion

  //#region Effects
  useEffect(() => {
    setAssessmentGroups(groupAssessments());
  }, [groupAssessments, setAssessmentGroups]);

  const [showNirvanaReport, setShowNirvanaReport] = useState(false);
  const [reportBlob, setReportBlob] = useState<Blob | undefined>(undefined);

  const [getNirvanaComparisonReport, reportResult, getNirvanaReportRequestStatus, clearReportError] = useRequest(getNirvanaAssessmentComparisonReportRequest);

  const displayNirvanaComparisonReport = useCallback(() => {
    clearReportError();
    setReportBlob(undefined);
    if (caseResult?.id) {
      getNirvanaComparisonReport(caseResult.id);
      setShowNirvanaReport(true);
    }
  }, [caseResult?.id, clearReportError, getNirvanaComparisonReport]);

  useEffect(() => {
    if (reportResult) setReportBlob(reportResult);
  }, [reportResult]);

  //#endregion

  return (
    <Fragment>
      <AssessmentScoreDialog assessment={selectedAssessment} />
      <AssessmentAmendmentHistoryDialog assessments={selectedAssessment ? assessmentGroups[selectedAssessment.id] : []} />
      <Box position="relative">
        <EditButton
          disabled={viewComparisonReportDisabled}
          text={"View NIRVANA Comparison Report"}
          onClick={() => {
            displayNirvanaComparisonReport();
          }}
        />
        {viewComparisonReportDisabled && (
          <Typography style={{ fontSize: "0.9rem", marginBottom: "20px", marginTop: "-10px", marginLeft: "8px" }} color="textSecondary">
            There must be at least two Assessments to compare in order to run this report.
          </Typography>
        )}
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Nirvana}>
          <Box display="flex">
            <IconButton title="Refresh Assessments" onClick={handleRefreshAssessments}>
              <RefreshIcon />
              <Typography>Refresh List</Typography>
            </IconButton>
            <EditButton style={{ marginLeft: "auto" }} text="Start NIRVANA Assessment" onClick={handleStartNirvanaAssessmentClicked} />
          </Box>
        </WriteAccessLevelWrapper>
        {assessments.length === 0 && <Typography>No NIRVANA Assessments Started</Typography>}
        {assessments.filter((a) => a.type !== AssessmentTypeEnum.NirvanaSelfReport && a.status === AssessmentStatusEnum.Submitted).length > 1 && (
          <>
            <Dialog open={showNirvanaReport} fullWidth maxWidth="xl">
              <ErrorText error={getNirvanaReportRequestStatus.error?.message} />
              <DialogTitle>NIRVANA Comparison Report</DialogTitle>
              <DialogContent style={{ height: "100vh", overflow: "hidden" }}>
                {reportBlob && <iframe src={URL.createObjectURL(reportBlob)} title="invoice-document" style={{ width: "100%", height: "100%" }}></iframe>}
                {getNirvanaReportRequestStatus.isLoading && (
                  <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress
                      style={{
                        height: 200,
                        width: 200,
                      }}
                    />
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <SpinnerButton content="Close" showSpinner={false} type="button" onClick={() => setShowNirvanaReport(false)} />
              </DialogActions>
            </Dialog>
          </>
        )}
        {!getAssessmentsRequestState.isLoading && renderAssessments()}
        {getAssessmentsRequestState.isLoading && renderLoading()}
      </Box>
    </Fragment>
  );
};

export default NirvanaSection;
export const createNirvanaTab = (): TabProps => ({
  label: "NIRVANA",
  value: nirvanaTabName,
  content: <NirvanaSection />,
});
