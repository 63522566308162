import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { Fragment } from "react";
import {
  DemographicsMaritalStatusEnum,
  demographicsMaritalTextMap,
} from "../../enums/DemographicsMaritalStatusEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  status?: DemographicsMaritalStatusEnum;
  defaultValue?: string;
}

const DemographicsMaritalStatusLabel: React.FC<Props> = ({
  status,
  defaultValue,
  ...props
}) => {
  if (status == null && defaultValue == null) return <Fragment></Fragment>;
  const value =
    status == null ? defaultValue : demographicsMaritalTextMap[status];

  return <Typography {...props}>{value}</Typography>;
};

export default DemographicsMaritalStatusLabel;
