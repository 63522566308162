import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { useTogglable } from "../../../app/hooks/useTogglable";

interface Props {
  requestState: RequestStateDto;
  disclaimer?: string;
  onConfirm(): Promise<void>;
}

export const confirmForceSubmitCaseDialogId = "force-submit-case-dialog";
const ConfirmForceSubmitCaseDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  disclaimer,
}) => {
  const [isConfirmed, , handleToggleIsConfirmed] = useTogglable();
  const { isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmForceSubmitCaseDialogId}
      isLoading={isLoading}
      confirmButtonText="Submit w/Deferred Billing"
      confirmButtonStyle={{
        color: !isConfirmed ? ColorsEnum.DisabledBlue : ColorsEnum.Blue,
      }}
      confirmButtonDisabled={!isConfirmed}
      maxWidth="md"
    >
      <Typography style={{ marginBottom: "8px" }}>{disclaimer}</Typography>
      <FormControlLabel
        label={
          "I understand that this case will not be invoiced until it is discharged."
        }
        color="primary"
        control={
          <Checkbox
            checked={isConfirmed}
            onClick={handleToggleIsConfirmed}
            color="primary"
          />
        }
      />
    </ConfirmDialog>
  );
};

export default ConfirmForceSubmitCaseDialog;
