import { useCallback, useState } from "react";
import { refresh } from "../../features/authentication/state/authenticationActions";
import { RequestStateDto } from "../dtos/RequestStateDto";
import { getHttpError, tokenIsExpired } from "../helpers";
import { useAppDispatch } from "../hooks";

export const useRequest = <T, R>(
  request: (params: T) => Promise<R>,
  authenticate: boolean = true
): [
  (params: T) => Promise<R | undefined>,
  R | undefined,
  RequestStateDto,
  (resetResult?: boolean) => void
] => {
  //#region State
  const [requestState, setRequestState] = useState<RequestStateDto>({
    isLoading: false,
  });
  const [result, setResult] = useState<R>();
  const dispatch = useAppDispatch();
  //#endregion

  const execute = useCallback(
    async (params: T) => {
      setRequestState({ isLoading: true });
      try {
        if (authenticate && tokenIsExpired())
          await dispatch(refresh()).unwrap();
        const result = await request(params);
        setResult(result);
        setRequestState({ isLoading: false });
        return result;
      } catch (error: any) {
        setRequestState({ error: await getHttpError(error), isLoading: false });
        throw error;
      }
    },
    [setRequestState, setResult, request, authenticate, dispatch]
  );

  const clearError = useCallback((resetResult: boolean = false) => {
    setRequestState({ isLoading: false });
    if(resetResult) setResult(undefined);
  }, [setRequestState]);

  return [execute, result, requestState, clearError];
};
