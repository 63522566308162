import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  NotificationDto,
  createDangerNotificationDto,
  createSuccessNotificationDto,
  createWarningNotificationDto
} from "../dtos/NotificationDto";

export interface NotificationState {
  notifications: NotificationDto[];
}

const initialState: NotificationState = {
  notifications: [],
};

interface NotificationPayload {
  message: string;
  duration?: number;
}

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addSuccessNotification: (
      state,
      action: PayloadAction<NotificationPayload>
    ) => {
      const { message, duration } = action.payload;
      state.notifications.push(
        createSuccessNotificationDto(message, duration)
      );
    },
    addWarningNotification: (
      state,
      action: PayloadAction<NotificationPayload>
    ) => {
      const { message, duration } = action.payload;
      state.notifications.push(
        createWarningNotificationDto(message, duration)
      );
    },
    addDangerNotification: (
      state,
      action: PayloadAction<NotificationPayload>
    ) => {
      const { message, duration } = action.payload;
      state.notifications.push(
        createDangerNotificationDto(message, duration)
      );
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (n) => n.id !== action.payload
      );
    },
  },
});

// Actions
export const {
  addSuccessNotification,
  addWarningNotification,
  addDangerNotification,
  removeNotification,
} = notificationSlice.actions;

//#region Selectors
export const notificationSelector = (state: RootState) =>
  state.notifications.notifications;
//#endregion

// Reducer
export default notificationSlice.reducer;
