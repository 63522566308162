import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { ChangeIntakeDateFormDto } from "../dtos/ChangeIntakeDateFormDto";
import { IntakeDto } from "../dtos/IntakeDto";
import { useChangeIntakeDateForm } from "../hooks/useChangeIntakeDateForm";
import {DischargeDto} from "../../discharges/dtos/DischargeDto";

export const changeIntakeDateFormDialogId = "change-intake-date-form-dialog";

interface Props {
  onSubmit: (dto: ChangeIntakeDateFormDto) => Promise<void>;
  requestState: RequestStateDto;
  intake?: IntakeDto;
  discharge?: DischargeDto;
}

const ChangeIntakeFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  intake,
  discharge
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { form, handlers } = useChangeIntakeDateForm(onSubmit, intake, discharge);

  const { control, isValid, clearForm } = form;
  const { handleSubmit } = handlers;
  const { isLoading, error } = requestState;
  //#endregion

  //#region Handlers
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === changeIntakeDateFormDialogId}
      maxWidth="xs"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Change Intake Date/Time
          {error && (
            <Box marginTop="8px" color="red">
              <Typography>{error.message}</Typography>
            </Box>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="intakeAt"
                label="Intake Date/Time"
                type="datetime-local"
                control={control}
                disabled={requestState.isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeIntakeFormDialog;
