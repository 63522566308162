import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum CaseHoldTypeEnum {
  MentalHealth = "MentalHealth",
  Medical = "Medical",
  HomePass = "HomePass",
}

export const caseHoldTypeTextMap: { [key: string]: string } = {
  [CaseHoldTypeEnum.MentalHealth]: "Mental Health",
  [CaseHoldTypeEnum.Medical]: "Medical",
  [CaseHoldTypeEnum.HomePass]: "Home Pass",
};

export const getCaseHoldTypeOptions = (): SelectOption[] => {
  let keys = Object.keys(CaseHoldTypeEnum);

  return [
    {
      value: "",
      label: "Select Case Hold Reason",
    },
    ...keys.map((type) => ({
      value: type,
      label: caseHoldTypeTextMap[type],
    })),
  ];
};
