import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { DischargeFormDto } from "../discharges/dtos/DischargeFormDto";
import { ADBTTEligibilityQuestionnaireDto } from "../adbtt-eligibility-questionnaires/dtos/ADBTTEligibilityQuestionnaireDto";
import { ADBTTEligibilityQuestionnaireFormDto } from "../adbtt-eligibility-questionnaires/dtos/ADBTTEligibilityQuestionnaireFormDto";
import { CaseDetailDto } from "./dtos/CaseDetailDto";
import { CaseDto } from "./dtos/CaseDto";
import { CaseFormDto } from "./dtos/CaseFormDto";
import { CaseListDto } from "./dtos/CaseListDto";
import { CaseSearchParamsDto } from "./dtos/CaseSearchParamsDto";
import { SetCaseScreeningFormDto } from "./dtos/SetCaseScreeningFormDto";
import { CaseHoldFormDto } from "../case-hold/dtos/CaseHoldFormDto";
import { CaseHoldDto } from "../case-hold/dtos/CaseHoldDto";
import { ChangeIntakeDateFormDto } from "../intakes/dtos/ChangeIntakeDateFormDto";
import { ChangeDischargeDateFormDto } from "../discharges/dtos/ChangeDischargeDateFormDto";
import { CaseProgramFormDto } from "./dtos/CaseProgramFormDto";
import { CaseContactFormDto } from "../case-contacts/dtos/CaseContactFormDto";
import { CaseContactDto } from "../case-contacts/dtos/CaseContactDto";
import { AlertDto } from "../alerts/dtos/AlertDto";
import { DemographicFormDto } from "../demographics/dtos/DemographicFormDto";
import { DemographicDto } from "../demographics/dtos/DemographicDto";
import { NonBillableDaysFormDto } from "./dtos/NonBillableDaysFormDto";
import { CaseDeleteFormDto } from "./dtos/CaseDeleteFormDto";
import { AssessmentDto } from "../assessments/dtos/AssessmentDto";
import {
  HumanTraffickingScreeningToolDto,
  HumanTraffickingScreeningToolFormDto,
} from "../human-trafficking-screening-tool/HumanTrafickingScreeningToolDto";
import {
  SuicideScreeningDto,
  SuicideScreeningFormDto,
} from "../suicide-screenings/dtos/SuicideScreeningDto";
import { CaseSessionFormDto } from "../case-sessions/dtos/CaseSessionFormDto";
import { CaseSessionDto } from "../case-sessions/dtos/CaseSessionDto";
import { AssessmentOverrideReasonDto } from "../assessments/dtos/AssessmentOverrideReasonDto";
import { CaseProgramLocationHistoryDto } from "./dtos/CaseProgramLocationHistoryDto";
import {
  AlertDismissalListDto,
  AlertDismissalSearchParamsDto,
} from "../alerts/dtos/AlertDismissalDto";
import { QueryStringHelpers } from "../../app/helpers";
import { PostNirvanaAssessmentValidationRequestDto } from "./dtos/PostNirvanaAssessmentValidationRequestDto";
import {
  SatisfactionSurveyDto,
  SatisfactionSurveyFormDto,
} from "../satisfaction-survey/SatisfactionSurveyDto";
import { DischargeDto } from "../discharges/dtos/DischargeDto";
import { CaseStaffingDto } from "../case-staffing/dtos/CaseStaffingDto";
import { ServicePlanDto } from "../../service-planning/dto/ServicePlanDto";
import {CaseLocationFormDto} from "./dtos/CaseLocationFormDto";
import {ClearDischargeFormDto} from "../discharges/dtos/ClearDischargeFormDto";
import { AttachmentRequestDto } from "../attachments/dtos/AttachmentRequestDto";
import { AttachmentDto } from "../attachments/dtos/AttachmentDto";

interface DischargeCaseParamsDto {
  id: string;
  dto: DischargeFormDto;
}

interface SetCaseScreeningParamsDto {
  id: string;
  dto: SetCaseScreeningFormDto;
}

interface CreateADBTTEligibilityQuestionsParamsDto {
  id: string;
  dto: ADBTTEligibilityQuestionnaireFormDto;
}

interface CreateCaseHoldParamsDto {
  id: string;
  dto: CaseHoldFormDto;
}

interface ChangeIntakeDateParams {
  id: string;
  dto: ChangeIntakeDateFormDto;
}

interface ChangeDischargeDateParams {
  id: string;
  dto: ChangeDischargeDateFormDto;
}

interface CaseProgramLocationParams {
  id: string;
  dto: CaseProgramFormDto;
}

interface CaseCourtOrderedParams {
  id: string;
  isCourtOrdered: boolean;
  courtOrderDocumentFilename: string;
}

interface CreateCaseContactParams {
  id: string;
  dto: CaseContactFormDto;
}

interface UpsertDemographicParams {
  id: string;
  dto: DemographicFormDto;
}

interface UpsertNonBillableDaysParams {
  id: string;
  dto: NonBillableDaysFormDto;
}

interface DeleteCaseParams {
  id: string;
  dto: CaseDeleteFormDto;
}

interface ClearDischargeParams {
  id: string;
  dto: ClearDischargeFormDto;
}

interface CreateCaseSessionParams {
  id: string;
  dto: CaseSessionFormDto;
}

interface DeleteCaseSessionParams {
  id: string;
  caseSessionId: string;
}

interface AddOrUpdateCaseLocationRequestDto {
  programLocationId: string;
  startOn: string;
}

interface UploadCourtOrderAttachmentParams {
  id: string;
  dto: AttachmentRequestDto;
}

export const createCaseRequest = (dto: CaseFormDto) =>
  coreAgent.postRequest<CaseDto>("cases", dto);

export const getCasesRequest = (searchParams: CaseSearchParamsDto) =>
  coreAgent.getRequest<PaginationResultDto<CaseListDto>>(
    `cases?${CaseSearchParamsDto.toQueryString(searchParams)}`
  );

export const exportCasesRequest = ({
  searchParams,
  filename,
}: {
  searchParams: CaseSearchParamsDto;
  filename: string;
}) =>
  coreAgent.downloadFileRequest(
    `cases/export?${CaseSearchParamsDto.toQueryString(searchParams)}`,
    filename
  );

export const getCaseRequest = (id: string) =>
  coreAgent.getRequest<CaseDetailDto>(`cases/${id}`);

export const dischargeCaseRequest = ({ id, dto }: DischargeCaseParamsDto) =>
  coreAgent.postRequest(`cases/${id}/discharge`, dto);

export const updateCaseDiscahrgeRequest = ({
  id,
  dto,
}: DischargeCaseParamsDto) =>
  coreAgent.putRequest<DischargeDto>(`cases/${id}/discharge`, dto);

export const setCaseScreeningRequest = ({
  id,
  dto,
}: SetCaseScreeningParamsDto) =>
  coreAgent.putRequest<boolean>(`cases/${id}/screenings`, dto);

export const createADBTTEligibilityQuestionsRequest = ({
  id,
  dto,
}: CreateADBTTEligibilityQuestionsParamsDto) =>
  coreAgent.postRequest<ADBTTEligibilityQuestionnaireDto>(
    `cases/${id}/adbttEligibilityQuestionnaire`,
    dto
  );

export const createCaseHoldRequest = ({ id, dto }: CreateCaseHoldParamsDto) =>
  coreAgent.postRequest<CaseHoldDto>(`cases/${id}/hold`, dto);

export const deleteCaseRequest = ({ id, dto }: DeleteCaseParams) =>
  coreAgent.postRequest(`cases/${id}/delete`, dto);

export const changeIntakeDateRequest = ({ id, dto }: ChangeIntakeDateParams) =>
  coreAgent.putRequest(`cases/${id}/intakeAt`, dto);
export const changeDischargeDateRequest = ({
  id,
  dto,
}: ChangeDischargeDateParams) =>
  coreAgent.putRequest(`cases/${id}/dischargedAt`, dto);

export const changeProgramLocationRequest = ({
  id,
  dto,
}: CaseProgramLocationParams) =>
  coreAgent.putRequest(`cases/${id}/program`, dto);

export const changeCourtOrderedStatusRequest = (dto: CaseCourtOrderedParams) =>
  coreAgent.putRequest(`cases/${dto.id}/courtordered`, dto);

export const uploadCourtOrderAttachmentRequest = ({id, dto }: UploadCourtOrderAttachmentParams) => {
  const formData = new FormData();
  formData.append("File", dto.file);
  formData.append("Filename", dto.filename);
  return coreAgent.postRequest<AttachmentDto>(`/cases/${id}/attachments/courtorder`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const downloadCourtOrderAttachmentRequest = (
  dto: CaseDetailDto
) =>
  coreAgent.downloadFileRequest(`cases/${dto.id}/attachments/courtorder`, 
    dto.courtOrderAttachment?.originalFilename);

export const deleteCourtOrderAttachmentRequest = (
  id: string
) => coreAgent.deleteRequest(`cases/${id}/attachments/courtorder`);

export const createCaseContactRequest = ({
  id,
  dto,
}: CreateCaseContactParams) =>
  coreAgent.postRequest<CaseContactDto>(`cases/${id}/contacts`, dto);

export const deleteCaseContactRequest = (id: string) =>
  coreAgent.deleteRequest(`cases/contacts/${id}`);

export const updateCaseContactRequest = ({
                                           id,
                                           dto,
                                         }: CreateCaseContactParams) =>
  coreAgent.putRequest(`cases/contacts/${id}`, dto);

export const getCaseAlertsRequest = (id: string) =>
  coreAgent.getRequest<AlertDto[]>(`cases/${id}/alerts`);

export const upsertCaseDemographicRequest = ({
  id,
  dto,
}: UpsertDemographicParams) =>
  coreAgent.postRequest<DemographicDto>(`cases/${id}/demographics`, dto);

export const deleteCaseDemographicRequest = (id: string) =>
  coreAgent.deleteRequest<boolean>(`cases/${id}/demographics`);

export const upsertNonBillableDaysRequest = ({
  id,
  dto,
}: UpsertNonBillableDaysParams) =>
  coreAgent.postRequest<void>(`cases/${id}/nonbillabledays`, dto);

export const getCaseAssessmentsRequest = (id: string) =>
  coreAgent.getRequest<AssessmentDto[]>(`cases/${id}/assessments`);

export const getCaseAlertDismissalsRequest = (id: string) =>
  coreAgent.getRequest<AssessmentOverrideReasonDto[]>(
    `cases/${id}/alerts/dismissals`
  );
//#region
export const createCaseHtstRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: HumanTraffickingScreeningToolFormDto;
}) =>
  coreAgent.postRequest<HumanTraffickingScreeningToolDto>(
    `cases/${id}/htst`,
    dto
  );
//#endregion
//#region Suicide Screening
interface SuicideScreeningProps {
  id: string;
  dto: SuicideScreeningFormDto;
}

export const upsertCaseSuicideScreeningRequest = ({
  id,
  dto,
}: SuicideScreeningProps) =>
  coreAgent.postRequest<SuicideScreeningDto>(`/cases/${id}/suicide`, dto);

export const deleteSuicideScreeningRequest = ({caseId, suicideScreeningId}: { caseId: string, suicideScreeningId: string }) =>
  coreAgent.deleteRequest(`/cases/${caseId}/suicide/${suicideScreeningId}`);

export const createCaseSuicideReScreeningRequest = ({
  id,
  dto,
}: SuicideScreeningProps) =>
  coreAgent.postRequest<SuicideScreeningDto>(
    `/cases/${id}/suicide/rescreening`,
    dto
  );
//#endregion

export const createCaseSessionRequest = ({
  id,
  dto,
}: CreateCaseSessionParams) =>
  coreAgent.postRequest<CaseSessionDto>(`cases/${id}/casesessions`, dto);

export const deleteCaseSessionRequest = ({
  id,
  caseSessionId,
}: DeleteCaseSessionParams) =>
  coreAgent.deleteRequest<boolean>(`cases/${id}/casesessions/${caseSessionId}`);

export const getCaseProgramLocationHistoryRequest = (id: string) =>
  coreAgent.getRequest<CaseProgramLocationHistoryDto[]>(
    `cases/${id}/programLocation/history`
  );

export const getCasesAlertDismissalsRequest = (
  params: AlertDismissalSearchParamsDto
) =>
  coreAgent.getRequest<PaginationResultDto<AlertDismissalListDto>>(
    `cases/alerts/dismissals?${QueryStringHelpers.toQueryString(params)}`
  );

export const validateNirvanaPostAssessmentRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: PostNirvanaAssessmentValidationRequestDto;
}) => coreAgent.postRequest<boolean>(`/cases/${id}/nirvana/post/validate`, dto);

export const upsertSatisfactionSurveyRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: SatisfactionSurveyFormDto;
}) =>
  coreAgent.postRequest<SatisfactionSurveyDto>(
    `cases/${id}/satisfactionSurvey`,
    dto
  );

export const getSchoolNamesRequest = () =>
  coreAgent
    .getRequest<string[]>(`cases/schoolNames`)
    .then((x) => x.sort((a, b) => (a > b ? -1 : 1)));

export const getCaseStaffingsRequest = (id: string) =>
  coreAgent.getRequest<CaseStaffingDto[]>(`cases/${id}/casestaffings`);

export const getCaseSessionsRequest = (id: string) =>
  coreAgent.getRequest<CaseSessionDto[]>(`cases/${id}/casesessions`);

export const getCaseServicePlanRequest = (id: string) =>
  coreAgent.getRequest<ServicePlanDto>(`cases/${id}/serviceplan`);

export const updateCaseRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: CaseFormDto;
}) => coreAgent.putRequest<CaseDto>(`cases/${id}`, dto);

export const updateCaseLocationRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: CaseLocationFormDto;
}) => coreAgent.putRequest(`cases/${id}/location`, dto);

export const addCaseLocationRequest = ({ id, dto, }: {
  id: string;
  dto: AddOrUpdateCaseLocationRequestDto;
}) => coreAgent.putRequest(`cases/${id}/location`, dto);

export const updateCaseProgramLocationRequest = ({ id, dto, }: {
  id: string;
  dto: AddOrUpdateCaseLocationRequestDto;
}) => coreAgent.postRequest(`cases/location/${id}`, dto);

export const deleteCaseLocationRequest = ({ id }: {
  id: string;
}) => coreAgent.deleteRequest(`cases/location/${id}`);

export const clearDischargeRequest = ({ id, dto }: ClearDischargeParams) => coreAgent.postRequest(`cases/${id}/cleardischarge`, dto);
