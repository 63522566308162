import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { DemographicFormDto } from "../dtos/DemographicFormDto";
import { useDemographicForm } from "../hooks/useDemographicForm";
import DemographicFormFields from "./DemographicFormFields";

export const demographicModalId = "demographic-form-modal";

interface Props {
  requestState: RequestStateDto;
  caseResult?: CaseDetailDto;
  onSubmit(dto: DemographicFormDto): Promise<any>;
}

const DemographicFormDialog: React.FC<Props> = ({
  requestState,
  caseResult,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useDemographicForm(onSubmit, caseResult);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === demographicModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {caseResult?.demographic ? "Edit" : "Create"} Demographics
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <DemographicFormFields control={control} isDisabled={isLoading} isLegacy={caseResult?.isLegacy || false}/>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Submit"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DemographicFormDialog;
