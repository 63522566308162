import React, {Fragment} from 'react';
import {SNAPExportParametersDto, useSNAPExportReportFilter} from "../hooks/useSNAPExportReportFilter";
import ErrorText from "../../../app/components/ErrorText";
import {Box, Grid} from "@material-ui/core";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {providerToOptionDtos} from "../../provider/dtos/ProviderDto";
import {programTypesToOptions} from "../../program-types/dtos/ProgramTypeDto";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import {ProgramTypeEnum} from "../../programs/enums/ProgramTypeEnum";
import {getLocationOptions} from "../../locations/dtos/LocationDto";

interface Props {
  params: SNAPExportParametersDto;
  isLoading: boolean;
  onSearch({dto, programType}: { dto: SNAPExportParametersDto, programType: ProgramTypeEnum }): Promise<void>;
}

export const SNAPExportReportFilter: React.FC<Props> = ({ params, isLoading, onSearch }) => {

  const {
    state: {
      providers,
      getAllProvidersRequestState,
      isValid,
      programs,
      programsRequestState,
      programTypes,
      programTypesRequestState,
      locations,
      locationsRequestState,
      showLocationFilter,
    },
    form: { control },
    handlers: { handleDownload },
  } = useSNAPExportReportFilter(params, onSearch);

  return (
    <Fragment>
      <ErrorText error={getAllProvidersRequestState.error?.message} />
      <Grid container spacing={2}>
        {/* Date Range */}

        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="cycleStartDate"
            label="Cycle Start Date Range Start"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="cycleEndDate"
            label="Cycle Start Date Range End"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>

        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="groupStartDate"
            label="Group Date Range Start"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="groupEndDate"
            label="Group Date Range End"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>

        {/* Provider Name */}
        <FnAccessWrapper>
          <Grid item md={3} xs={6}>

            <ControlledSelect
              control={control}
              name="providerId"
              label="Providers"
              fullWidth
              options={providerToOptionDtos(providers, undefined, 'All')}
              isLoading={getAllProvidersRequestState.isLoading}
              disabled={isLoading}
              size="small"
            />
          </Grid>
        </FnAccessWrapper>

        <Grid item md={3} xs={6}>
          <ControlledSelect
            control={control}
            name="programTypeId"
            label="Program Type"
            options={programTypesToOptions(programTypes, "Select a Program Type")}
            fullWidth
            isLoading={programTypesRequestState.isLoading}
            size="small"
          />
        </Grid>

        <ProviderAccessWrapper>
          {/* Programs */}
          <Grid item md={3} xs={6}>
            <ProgramControlledSelect
              control={control}
              name="programId"
              label="Program"
              programs={programs}
              disabled={programs.length === 0}
              defaultOptionLabel={"All"}
              isLoading={programsRequestState.isLoading}
              size="small"
            />
          </Grid>

          {showLocationFilter && <Grid item md={3} xs={6}>
            <ControlledSelect
              control={control}
              name="locationId"
              label="Location"
              options={getLocationOptions(locations, "All")}
              disabled={locations.length === 0}
              isLoading={locationsRequestState.isLoading}
              size="small"
              fullWidth
            />
          </Grid> }
        </ProviderAccessWrapper>

        <Grid item md={showLocationFilter ? 3 : 6} xs={6}>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            height="100%"
          >

            <SpinnerButton
              showSpinner={isLoading}
              disabled={!isValid || isLoading }
              content="Download"
              color="primary"
              variant="contained"
              type="button"
              onClick={handleDownload}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
