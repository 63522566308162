export enum ReferralServiceRequestedEnum {
  Counseling = "Counseling",
  Shelter = "Shelter",
  SNAP = "SNAP",
  DontKnow = "DontKnow",
}
export const referralServiceRequestedTextMap: { [key: string]: string } = {
  [ReferralServiceRequestedEnum.Counseling]: "Counseling",
  [ReferralServiceRequestedEnum.Shelter]: "Shelter",
  [ReferralServiceRequestedEnum.SNAP]: "SNAP",
  [ReferralServiceRequestedEnum.DontKnow]: "I Don't Know",
};

export const referralServiceRequestedTooltipTextMap: { [key: string]: string } = {
  [ReferralServiceRequestedEnum.Counseling]: "Individual, group, and family counseling services for children age 6-17 years old and their families.",
  [ReferralServiceRequestedEnum.Shelter]: "Short-term residential services for children age 10-17 years old. This program provides safe shelter, counseling, and access to education.",
  [ReferralServiceRequestedEnum.SNAP]: '“Stop Now and Plan” is appropriate for youth ages 6-11. SNAP Youth Justice is appropriate for youth ages 12-17. This program provides strategies to youth and their families to increase positive behaviors that will encourage youth to stay in school and stay out of trouble.',
  [ReferralServiceRequestedEnum.DontKnow]: "Select this option if unsure of which program type the referral should be routed to.",
};
