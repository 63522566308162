import { dateToFormString } from "../../../app/helpers";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { SnapInSchoolsCycleDto } from "./SnapInSchoolsCycleDto";

export interface SnapInSchoolsCycleFormDto {
  providerId: string;
  programId: string;
  startedOn: string;
  grades: string[];
  location: string;
  classroomSize: number;
  supervisorName: string;
}

export const createSnapInSchoolsCycleFormDto = (
  dto?: SnapInSchoolsCycleDto
): SnapInSchoolsCycleFormDto => {
  if (dto) {
    const {
      provider: { id: providerId },
      startedOn,
      grades,
      location,
      supervisorName,
      program,
      classroomSize,
    } = dto;

    return {
      providerId,
      startedOn: dateToFormString(new Date(startedOn)),
      location: location,
      supervisorName,
      grades: grades,
      programId: program.id,
      classroomSize
    };
  }

  return {
    providerId: getProviderId() || "",
    startedOn: "",
    location: "",
    supervisorName: "",
    grades: [],
    programId: "",
    classroomSize: 0
  };
};
