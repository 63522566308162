import React, { CSSProperties } from "react";
import ReportProblemTwoToneIcon from "@material-ui/icons/ReportProblemTwoTone";
import { Tooltip } from "@material-ui/core";
import { ColorsEnum } from "../../enums/ColorsEnum";

interface Props {
  tooltip?: React.ReactFragment;
  style?: CSSProperties;
}

const WarningIcon: React.FC<Props> = ({ tooltip, style = {} }) => {
  const icon = (
    <ReportProblemTwoToneIcon style={{ color: ColorsEnum.Yellow, ...style }} />
  );
  if (tooltip) return <Tooltip title={tooltip}>{icon}</Tooltip>;

  return icon;
};

export default WarningIcon;
