import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { flattenOptions } from "../../../app/helpers";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { useGetProviderStaffMembers } from "../../provider/hooks/providerHooks";
import { SatisfactionSurveyFormDto } from "../SatisfactionSurveyDto";

export const useSatisfactionSurveyForm = (
  onSubmit: (dto: SatisfactionSurveyFormDto) => Promise<any>,
  caseDto?: CaseDetailDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new SatisfactionSurveyFormDto(caseDto),
    resolver: yupResolver(SatisfactionSurveyFormDto.getSchema()),
    mode: "all",
  });
  const [
    getProviderStaffMembers,
    staffMembers,
    getProviderStaffMembersRequestState,
  ] = useGetProviderStaffMembers();
  //#endregion

  const clearForm = () => {
    reset(new SatisfactionSurveyFormDto(caseDto));
  };
  const handleOnSubmit = handleSubmit(
    async (dto: SatisfactionSurveyFormDto) => {
      if (dto.satisfactionSurveyResponseSet.isSnapProgram) {
        const { snapHelpfulSkillsOptions, snapHelpsPeopleOptions } =
          dto.satisfactionSurveyResponseSet;

        dto.satisfactionSurveyResponseSet.snapHelpfulSkills = flattenOptions(
          snapHelpfulSkillsOptions!
        );

        dto.satisfactionSurveyResponseSet.snapHelpsPeople = flattenOptions(
          snapHelpsPeopleOptions!
        );
      }

      await onSubmit(dto);
    }
  );

  useEffect(() => {
    reset(new SatisfactionSurveyFormDto(caseDto));
  }, [caseDto, reset]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId !== fnyfsProviderId)
      getProviderStaffMembers(providerId);
  }, [getProviderStaffMembers]);

  return {
    state: {
      staffMembers: staffMembers || [],
      getProviderStaffMembersRequestState,
    },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
