import React, { useCallback } from "react";
import { ProgramTypeDto } from "../../program-types/dtos/ProgramTypeDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { FollowUpSearchParamsDto } from "../dtos/FollowUpSearchParamsDto";
import FilterList from "../../../app/components/FilterList";
import { FollowUpFilterFormDto } from "../dtos/FollowUpFilterFormFilterDto";
import { fundingSourceTextMap } from "../../programs/enums/ProgramFundingSourceEnum";
import { DateTime } from "luxon";
import {LocationDto} from "../../locations/dtos/LocationDto";
import {StaffMemberDto} from "../../staff-members/dtos/StaffMemberDto";
import {followUpIntervalTextMap} from "../enums/FollowUpInterval";

const formatDate = (date: string) => {
  return DateTime.fromJSDate(new Date(`${date}T00:00`)).toFormat("MM-dd-yyyy");
};

interface Props {
  params: FollowUpSearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  programs: ProgramDto[];
  locations: LocationDto[];
  programTypes: ProgramTypeDto[];
  staff: StaffMemberDto[];
  onSearch(dto: FollowUpSearchParamsDto): void;
}

const FollowUpFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  locations,
  programTypes,
  programs,
  staff,
  onSearch,
}) => {
  const handleClearParams = () => {
    return new FollowUpSearchParamsDto({
      previous: params,
      form: new FollowUpFilterFormDto(),
    });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "programTypeId":
          return `Program Type: ${
            programTypes.find((x) => x.id === value)?.name
          }`;
        case "programId":
          return `Program: ${programs.find((x) => x.id === value)?.name}`;
        case "providerId":
          return `Provider: ${providers.find((x) => x.id === value)?.name}`;
        case "hadServicesSinceDischarge":
          if (value === "true") return "Has Services Since Discharge";
          if (value === "false") return "No Services Since Discharge";
          break;
        case "fundingSource":
          return `Funding Source: ${fundingSourceTextMap[value]}`;
        case "dischargeDateStart":
          return `Discharge Start Date: ${formatDate(value)}`;
        case "dischargeDateEnd":
          return `Discharge End Date: ${formatDate(value)}`;
        case "followUpCompletionRangeStart":
          return `Completed On or After: ${formatDate(value)}`;
        case "followUpCompletionRangeEnd":
          return `Completed On or Before: ${formatDate(value)}`;
        case "locationId":
          return `Location: ${locations.find((x) => x.id === value)?.name}`;
        case "onlyShowFollowUpsDue":
          return `Only Show Expected or Due`;
        case "dischargeStaffId": {
          const staffMember = staff.find((x) => x.id === value);
          return `Discharge Staff: ${staffMember?.firstName} ${staffMember?.lastName}`;
        }
        case "interval":
          return `Only showing ${followUpIntervalTextMap[value]} Follow Ups`;
      }
      return "";
    },
    [providers, programs, programTypes, locations, staff]
  );

  const ordering = [
    "onlyShowFollowUpsDue",
    "providerId",
    "programTypeId",
    "programId",
    "locationId",
    "dischargeStaffId",
    "dischargeDateStart",
    "dischargeDateEnd",
    "fundingSource",
    "hadServicesSinceDischarge",
  ];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default FollowUpFilterList;
