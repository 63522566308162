import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import StyledLink from "../../../app/components/StyledLink";
import { fnyfsName } from "../../authentication/constants";
import { ReferralSummaryDto } from "../dtos/ReferralSummaryDto";
import { referralServiceRequestedTextMap } from "../enums/ReferralServiceRequestedEnum";
import ReferralStatusLabel from "./ReferralStatusLabel";

interface Props {
  referral: ReferralSummaryDto;
}

const ReferralListItem: React.FC<Props> = ({ referral }) => {
  return (
    <TableRow>
      <TableCell>
        <StyledLink to={`/referrals/${referral.id}`}>
          {referral.referralNumber}
        </StyledLink>
      </TableCell>
      <TableCell>
        {referral.youthFirstName} {referral.youthLastName}
      </TableCell>
      <TableCell>
        {referral.assignedTo ? referral.assignedTo.name : fnyfsName}
      </TableCell>
      <TableCell>
        {referral.assignedBy ? referral.assignedBy.name : "Referral Form"}
      </TableCell>
      <TableCell>
        {referral.requestedServices
          .map((x) => referralServiceRequestedTextMap[x])
          .join(", ")}
      </TableCell>
      <TableCell>
        <DateTimeLabel date={referral.createdAt} />
      </TableCell>
      <TableCell>
        <ReferralStatusLabel referral={referral} />
      </TableCell>
    </TableRow>
  );
};

export default ReferralListItem;
