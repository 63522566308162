import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useEffect } from "react";
import { CreateNewFollowUpDto } from "../dtos/CreateNewFollowUpRequestDto";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import {
  unableToContactReasonOptions,
  UnableToContactReasonsEnum,
} from "../enums/UnableToContactReasonsEnum";
import { useGetProviderStaffMembers } from "../../provider/hooks/providerHooks";
import { useRequest } from "../../../app/hooks/useRequest";
import { createNewFollowUpRequest } from "../FollowUpRequests";
import {
  getLivingSituationOptions,
  LivingSituationEnum,
} from "../../screenings/enums/LivingSituationEnum";
import {
  SchoolStatusEnum,
  schoolStatusOptions,
} from "../../discharges/enums/SchoolStatusEnum";
import {
  FollowUpInterval,
  intervalSelectOptions,
} from "../enums/FollowUpInterval";
import {
  followUpImprovementOptions
} from "../enums/FollowUpImprovementEnum";

export interface CreateNewFollowUpFormData {
  interval: string;
  staffMemberId: string;
  attemptedAt: Date;
  outcome: string;
  reason: string;
  receivedServicesSinceDischarge: boolean;
  receivedServicesFromAnotherProviderSinceDischarge: boolean;
  livingStatus: string;
  isYouthsLivingStatusAppropriate: boolean;
  schoolStatus: string;
  seenImprovement: string;
}

const schema = Yup.object().shape({
  interval: Yup.string().required().label("Follow Up Interval"),
  staffMemberId: Yup.string().required().label("Staff Member"),
  // eslint-disable-next-line
  attemptedAt: Yup.date().required().label("Attempted At").typeError("${label} must be a valid date/time"),
  outcome: Yup.string().required().label("Follow Up Outcome"),
  reason: Yup.string().when("outcome", {
    is: "failed",
    then: Yup.string().required().label("Reason for Failed Attempt"),
  }),
  receivedServicesSinceDischarge: Yup.boolean().optional(),
  receivedServicesFromAnotherProviderSinceDischarge: Yup.boolean().optional(),
  livingStatus: Yup.string().when("outcome", {
    is: "success",
    then: Yup.string().required().label("Current Living Status"),
  }),
  IsYouthsLivingStatusAppropriate: Yup.boolean().optional(),
  schoolStatus: Yup.string().when("outcome", {
    is: "success",
    then: Yup.string().required().label("Current School Status"),
  }),
  seenImprovement: Yup.string().optional().label("Improvement Status"),
});

export const useCreateNewFollowUpForm = (
  onSubmit: () => Promise<void>,
  caseDto: CaseDetailDto
) => {
  //#region State

  const livingSituationOptions = getLivingSituationOptions();

  const followUpOutcomeOptions: SelectOption[] = [
    { label: "Select the outcome of this follow up attempt", value: "" },
    { label: "Failed to make contact", value: "failed" },
    { label: "Completed follow up", value: "success" },
  ];

  const [
    getProviderStaffMembers,
    staffMembers,
    getProviderStaffMembersRequestState,
    clearGetProviderStaffMemberErrors,
  ] = useGetProviderStaffMembers();

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<CreateNewFollowUpFormData>({
    defaultValues: {
      interval: "",
      outcome: "",
      staffMemberId: "",
      seenImprovement: "",
      receivedServicesSinceDischarge: false,
      receivedServicesFromAnotherProviderSinceDischarge: false,
      schoolStatus: "",
      isYouthsLivingStatusAppropriate: false,
      livingStatus: "",
      reason: "",
      attemptedAt: new Date(),
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = () => {
    reset({
      interval: "",
      outcome: "",
      staffMemberId: "",
      seenImprovement: "",
      receivedServicesSinceDischarge: false,
      receivedServicesFromAnotherProviderSinceDischarge: false,
      schoolStatus: "",
      isYouthsLivingStatusAppropriate: false,
      livingStatus: "",
      reason: "",
      attemptedAt: new Date(),
    });
  };

  const [createFollowUp, , getCreateFollowUpRequestState] = useRequest(
    createNewFollowUpRequest
  );

  const filteredIntervalOptions = intervalSelectOptions.filter(
    (opt) => !caseDto.followUps.some((f) => f.interval === opt.value)
  );

  const handleOnSubmit = handleSubmit(
    async (formData: CreateNewFollowUpFormData) => {
      const dto: CreateNewFollowUpDto =
        formData.outcome === "failed"
          ? {
              caseId: caseDto.id,
              interval: formData.interval as FollowUpInterval,
              staffMemberId: formData.staffMemberId,
              failureData: {
                reason: formData.reason as UnableToContactReasonsEnum,
                attemptedOn: formData.attemptedAt,
              },
            }
          : {
              caseId: caseDto.id,
              interval: formData.interval as FollowUpInterval,
              staffMemberId: formData.staffMemberId,
              completionData: {
                completedOn: formData.attemptedAt,
                livingStatus: formData.livingStatus as LivingSituationEnum,
                isYouthsLivingStatusAppropriate:
                  formData.isYouthsLivingStatusAppropriate,
                schoolStatus: formData.schoolStatus as SchoolStatusEnum,
                receivedServicesFromAnotherProviderSinceDischarge:
                  formData.receivedServicesFromAnotherProviderSinceDischarge,
                receivedServicesSinceDischarge:
                  formData.receivedServicesSinceDischarge,
                seenImprovement: formData.seenImprovement,
              },
            };

      await createFollowUp(dto);
      clearForm();
      await onSubmit();
    }
  );

  useEffect(() => {
    clearGetProviderStaffMemberErrors();
    getProviderStaffMembers(caseDto.provider.id);
  }, [
    caseDto.provider.id,
    clearGetProviderStaffMemberErrors,
    getProviderStaffMembers,
  ]);

  return {
    state: {
      intervalSelectOptions: filteredIntervalOptions,
      unableToContactReasonOptions,
      livingSituationOptions,
      schoolStatusOptions,
      followUpOutcomeOptions,
      staffMembers,
      getProviderStaffMembersRequestState,
      getCreateFollowUpRequestState,
      followUpImprovementOptions
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
