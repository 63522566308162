import React from "react";
import {
  ServicePlanDto,
  ServicePlanParticipantDto,
} from "../dto/ServicePlanDto";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { getRoleDisplayText } from "../enums/ServicePlanParticipantRolesEnum";
import { YouthDto } from "../../features/youths/dto/YouthDto";
import NameLabel from "../../app/components/labels/NameLabel";
import ServicePlanParticipantAdditionalActions from "./ServicePlanParticipantAdditionalActions";

export interface Props {
  servicePlan: ServicePlanDto;
  youth: YouthDto;
  onEditClicked: (participant: ServicePlanParticipantDto) => void;
  onDeleteClicked: (participant: ServicePlanParticipantDto) => void;
}

export const ServicePlanParticipants: React.FC<Props> = ({
  servicePlan,
  youth,
  onEditClicked,
  onDeleteClicked,
}) => {
  return (
    <Grid item xs={12}>
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={"youth"}>
                <TableCell>
                  <NameLabel nameDto={youth} />
                </TableCell>
                <TableCell>
                  <Typography component="span">Youth</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow key={"counselor"}>
                <TableCell>
                  <NameLabel nameDto={servicePlan.counselor} />
                </TableCell>
                <TableCell>
                  <Typography component="span">Counselor</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              {servicePlan.participants.map((p) => (
                <TableRow key={p.id}>
                  <TableCell>
                    <Typography component="span">{p.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component="span">
                      {getRoleDisplayText(p.role)}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ position: "relative" }}>
                    <ServicePlanParticipantAdditionalActions
                      participantDto={p}
                      onDeleteClicked={onDeleteClicked}
                      onEditClicked={onEditClicked}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
