import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import * as Yup from "yup";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { CaseContactFormDto } from "../dtos/CaseContactFormDto";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import { caseContactTypeOptions } from "../enums/CaseContactTypeEnum";
import { caseContactStatusOptions } from "../enums/CaseContactStatusEnum";
import { getCaseContactedPersonOptions } from "../enums/CaseContactedPersonTypeEnum";
import ControlledAutocomplete from "../../../app/components/inputs/ControlledAutocomplete";
import {StaffMemberControlledAutoComplete} from "../../staff-members/components/StaffMemberControlledAutoComplete";

export const caseContactSchema = Yup.object().shape({
  contactOn: Yup.string().label("Contacted On").required(),
  type: Yup.string().label("Contact Type").required(),
  status: Yup.string().label("Contact Status").required(),
  staffMemberId: Yup.string().label("Staff Completing Contact").required(),
  contactedPerson: Yup.string().label("Person Contacted").required().max(200).nullable(),
});

interface Props {
  control: Control<CaseContactFormDto>;
  disabled: boolean;
  staffMembers: StaffMemberDto[];
  staffMembersAreLoading: boolean;
}
const CaseContactFormFields: React.FC<Props> = ({ control, disabled, staffMembers, staffMembersAreLoading }) => {
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="contactOn"
          label="Contacted On*"
          type="date"
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Grid item md={12} xs={12}>
          <ControlledAutocomplete
            options={getCaseContactedPersonOptions()}
            freeSolo
            autoSelect
            openOnFocus
            name="contactedPerson"
            label={"Person Contacted*"}
            control={control}
            renderInput={() => <Fragment></Fragment>}
          />
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect control={control} name="status" label="Contact Status*" options={caseContactStatusOptions} fullWidth disabled={disabled} />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect control={control} name="type" label="Contact Type*" options={caseContactTypeOptions} fullWidth disabled={disabled} />
      </Grid>
      <Grid item md={12} xs={12}>
        <StaffMemberControlledAutoComplete
          control={control}
          disabled={disabled}
          name="staffMemberId"
          label="Staff Completing Contact*"
          isLoading={staffMembersAreLoading}
          staffMembers={staffMembers}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="notes"
          label="Notes"
          type="text-area"
          minRows={6}
          maxRows={12}
          multiline
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export default CaseContactFormFields;
