import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import BackButton from "../../../../app/components/buttons/BackButton";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../../app/components/ErrorText";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectModalId } from "../../../modal/state/modalSelectors";
import { closeModal } from "../../../modal/state/modalSlice";
import ScreeningFormFields from "../../../screenings/components/ScreeningFormFields";
import { ScreeningFormDto } from "../../../screenings/dtos/ScreeningFormDto";
import { ReferralDto } from "../../dtos/ReferralDto";
import {
  ScreeningFromReferralSteps,
  useScreeningFromReferralFormDialog,
} from "../../hooks/useScreeningFromReferrralFormDialog";
import ScreeningFromReferralYouthSelect from "./ScreeningFromReferralYouthSelect";

export const screeningFromReferralModalId =
  "screening-from-referral-form-modal";

interface Props {
  onSubmit: (youthId: string, dto: ScreeningFormDto) => Promise<any>;
  requestState: RequestStateDto;
  referral?: ReferralDto;
}

const ScreeningFromReferralFormDialog: React.FC<Props> = ({
  referral,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { state, handlers, screeningForm } = useScreeningFromReferralFormDialog(
    onSubmit,
    referral
  );

  const { currentStep, createYouthRequestState, getYouthsRequestState, youth } =
    state;

  const {
    handlePreviousClick,
    handleSearchYouth,
    handleCreateYouth,
    handleYouthSelected,
    handleResetForm,
  } = handlers;

  const {
    presentingProblems,
    getPresentingProblemsRequestState: {
      isLoading: presentingProblemsAreLoading,
      error: presentingProblemError,
    },
    cinsFinsEligibilityCriteria,
    requiresEligibleOption,
    requiresIneligibleOption,
    staffMembers,
    getProviderStaffMembersRequestState: {
      isLoading: staffMembersAreLoading,
      error: getStaffMemberError,
    },
    getProviderLocationsRequestState: {
      isLoading: providerLocationsAreLoading,
      error: getProviderLocationsError,
    },
    getProviderProgramsRequestState: {
      isLoading: providerProgramsAreLoading,
      error: getProviderProgramsError,
    },
    providerPrograms,
    locations,
  } = screeningForm.state;

  const { control, isValid } = screeningForm.form;
  const { handleSubmit } = screeningForm.handlers;
  const { isLoading, error } = requestState;

  //#endregion

  //#region
  const handleCloseModal = async () => {
    handleResetForm();
    dispatch(closeModal());
  };
  //#endregion

  const filteredEligibilityCriteria = cinsFinsEligibilityCriteria.filter((x) => !x.isDisabled);

  return (
    <Dialog
      open={modalId === screeningFromReferralModalId}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>Start Screening From Referral</DialogTitle>

      <DialogContent>
        <ErrorText error={presentingProblemError?.message} />
        <ErrorText error={getStaffMemberError?.message} />
        <ErrorText error={getProviderLocationsError?.message} />
        <ErrorText error={getProviderProgramsError?.message} />
        <ErrorText error={error?.message} />
        {referral && currentStep === ScreeningFromReferralSteps.SelectYouth && (
          <ScreeningFromReferralYouthSelect
            referral={referral}
            youth={youth}
            createYouthRequestState={createYouthRequestState}
            getYouthRequestState={getYouthsRequestState}
            onCreateYouth={handleCreateYouth}
            onYouthSelected={handleYouthSelected}
            onSearchYouth={handleSearchYouth}
          />
        )}

        {referral &&
          currentStep === ScreeningFromReferralSteps.CreateScreening && (
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <BackButton
                  route={""}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePreviousClick();
                  }}
                />
              </Grid>
              <ScreeningFormFields
                control={control}
                disabled={isLoading}
                presentingProblems={presentingProblems}
                staffMembers={staffMembers}
                staffMembersAreLoading={staffMembersAreLoading}
                presentingProblemsAreLoading={presentingProblemsAreLoading}
                cinsFinsEligibilityCriteria={filteredEligibilityCriteria}
                requiresEligibleOption={requiresEligibleOption}
                requiresIneligibleOption={requiresIneligibleOption}
                locations={locations}
                providerPrograms={providerPrograms}
                providerLocationsAreLoading={providerLocationsAreLoading}
                providerProgramsAreLoading={providerProgramsAreLoading}
              />
            </Grid>
          )}
      </DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Cancel"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
        />
        <SpinnerButton
          showSpinner={false}
          disabled={
            !isValid ||
            currentStep !== ScreeningFromReferralSteps.CreateScreening
          }
          content="Save"
          color="primary"
          type="submit"
          onClick={() => handleSubmit(false)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ScreeningFromReferralFormDialog;
