import { useForm } from "react-hook-form";
import { OutreachActivityFormDto } from "../dtos/OutreachActivityFormDto";
import { yupResolver } from "@hookform/resolvers/yup";
import { OutreachActivityDto } from "../dtos/OutreachActivityDto";
import { useEffect } from "react";
import { flattenOptions } from "../../../app/helpers";

export const useOutreachActivityForm = (
  onSubmit: (dto: OutreachActivityFormDto) => Promise<any>,
  dto?: OutreachActivityDto
) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new OutreachActivityFormDto(dto),
    resolver: yupResolver(OutreachActivityFormDto.getSchema()),
    mode: "all",
  });

  const clearForm = () => {
    reset(new OutreachActivityFormDto(dto));
  };

  const handleOnSubmit = handleSubmit(async (dto: OutreachActivityFormDto) => {
    dto.targetAudiences = flattenOptions(dto.targetAudienceOptions);
    dto.topics = flattenOptions(dto.topicOptions);
    await onSubmit(dto);
    if (dto == null) clearForm();
  });

  useEffect(() => {
    reset(new OutreachActivityFormDto(dto));
  }, [dto, reset]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
