import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import OrangeAlertLabel from "../../../app/components/labels/OrangeAlertLabel";
import YellowAlertLabel from "../../../app/components/labels/YellowAlertLabel";
import { ReferralScreeningCreatedLabel } from "../../waitlists/components/ReferralScreeningCreatedLabel";
import { ReferralDto } from "../dtos/ReferralDto";
import { ReferralSummaryDto } from "../dtos/ReferralSummaryDto";
import {
  ReferralStatusEnum,
  referralStatusTextMap,
} from "../enums/ReferralStatusEnum";
import { ReferralRemovedLabel } from "./ReferralRemovedLabel";

interface Props {
  referral: ReferralDto | ReferralSummaryDto;
}

const ReferralStatusLabel: React.FC<Props> = ({ referral }) => {
  switch (referral.status) {
    case ReferralStatusEnum.ScreeningCreated:
      return <ReferralScreeningCreatedLabel referral={referral} />;
    case ReferralStatusEnum.Pending:
      return (
        <YellowAlertLabel
          show={"true"}
          label="Pending"
          component="span"
          style={{ color: "black" }}
        />
      );
    case ReferralStatusEnum.OutOfState:
      return (
        <Tooltip
          title={
            <Typography component="span">State: {referral.state}</Typography>
          }
        >
          <Box style={{ cursor: "help" }}>
            <OrangeAlertLabel
              variant="outlined"
              label={referralStatusTextMap[ReferralStatusEnum.OutOfState]}
            />
          </Box>
        </Tooltip>
      );
    case ReferralStatusEnum.Removed:
      return <ReferralRemovedLabel referral={referral} />;
    default:
      return <Fragment></Fragment>;
  }
};

export default ReferralStatusLabel;
