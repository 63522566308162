import { Paper, TableContainer } from "@material-ui/core";
import React, { Fragment } from "react";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import { CaseContactDto } from "../dtos/CaseContactDto";
import CaseContactListItem from "./CaseContactListItem";
import { CaseContactSearchParamsDto } from "../dtos/CaseContactSearchParamsDto";

const CaseContactList: React.FC<
  SortableTableProps<CaseContactDto, CaseContactSearchParamsDto>
> = (props) => {

  const columns: ColumnProps[] = [
    { name: "contactOn", label: "Date of Contact" },
    { name: "status", label: "Status" },
    { name: "type", label: "Type" },
    { name: "contactedPerson", label: "Person Contacted" },
    { name: "contactedBy", label: "Contacted By" },
    { name: "notes", label: "Notes" },
    { name: "", label: "" },
  ];

  const renderRow = (contact: CaseContactDto) => (
    <CaseContactListItem 
      contact={contact} 
      key={contact.id} />
  );

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <SortableTable {...props} columns={columns} renderRow={renderRow} />
      </TableContainer>
    </Fragment>
  );
};

export default CaseContactList;
