import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { DjjEntryListDto, SetDjjEntryNotesFormDto } from "../DjjEntryDto";
import { useGetDjjEntryNotes } from "./DjjEntryHooks";

export const useDjjEntryNotesDialog = (
  onSubmit: (dto: SetDjjEntryNotesFormDto) => Promise<any>,
  entry?: DjjEntryListDto
) => {
  //#region State
  const [notes, setNotes] = useState("");
  const [getDjjEntryNotes, , getDjjEntryNotesRequestState] =
    useGetDjjEntryNotes();
  const [editMode, setEditMode, handleToggleEditMode] = useTogglable(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new SetDjjEntryNotesFormDto(notes),
    resolver: yupResolver(SetDjjEntryNotesFormDto.getSchema()),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    if (notes !== "" && notes != null) setEditMode(false);
    reset(new SetDjjEntryNotesFormDto(notes));
  };
  const handleOnSubmit = handleSubmit(async (dto: SetDjjEntryNotesFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  useEffect(() => {
    reset(new SetDjjEntryNotesFormDto(notes));
  }, [reset, notes]);

  useEffect(() => {
    setEditMode(notes === "");
  }, [notes, setEditMode]);

  useEffect(() => {
    const load = async () => {
      if (entry) {
        const { referenceId, referenceType } = entry;
        const notes = await getDjjEntryNotes({ referenceId, referenceType });
        if (notes) setNotes(notes);
        else setNotes("");
      } else {
        setNotes("");
      }
    };
    load();
  }, [entry, getDjjEntryNotes, setNotes]);

  return {
    state: { notes, editMode, getDjjEntryNotesRequestState },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit, handleToggleEditMode },
  };
};
