import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditButton from "../../../../../../app/components/buttons/EditButton";
import { TabProps } from "../../../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../../../authentication/enums/PermissionResourceNameEnum";
import { useCaseDetailPageContext } from "../../../../pages/CaseDetailPage";
import RefreshIcon from "@material-ui/icons/Refresh";
import LoadingDetail from "../../../../../../app/components/loading/LoadingDetail";
import { createNumberRange } from "../../../../../../app/helpers";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {
  AssessmentTypeEnum,
  assessmentTypeTextMap,
} from "../../../../../assessments/enums/AssessmentTypeEnum";
import DateLabel from "../../../../../../app/components/labels/DateLabel";
import {
  AssessmentScoreTypeEnum,
  assessmentScoreTypeTextMap,
} from "../../../../../assessments/enums/AssessmentScoreTypeEnum";
import AssessmentStatusLabel from "../../../../../assessments/components/AssessmentStatusLabel";
import AssessmentLink from "../../../../../assessments/components/AssessmentLink";
import { AssessmentDto } from "../../../../../assessments/dtos/AssessmentDto";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
export const topseTabName = "topse-section";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    padding: 0,
  },
}))(Tooltip);

const TopseScore = ({ assessment }: { assessment?: AssessmentDto }) => {
  const scoreTypes = [
    AssessmentScoreTypeEnum.EmotionAndAffection,
    AssessmentScoreTypeEnum.PlayAndEnjoyment,
    AssessmentScoreTypeEnum.EmpathyAndUnderstanding,
    AssessmentScoreTypeEnum.Control,
    AssessmentScoreTypeEnum.DisciplineAndSettingBoundaries,
    AssessmentScoreTypeEnum.Pressures,
    AssessmentScoreTypeEnum.SelfAcceptance,
    AssessmentScoreTypeEnum.LearningAndKnowledge,
  ];

  const renderScoreListItem = (
    assessment: AssessmentDto,
    type: AssessmentScoreTypeEnum
  ) => {
    return (
      <TableRow key={`${type}-${assessment.type}`}>
        <TableCell>
          <Typography>{assessmentScoreTypeTextMap[type]}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={{ fontWeight: "bold" }}>
            {assessment.scores.find((x) => x.type === type)?.score}/60
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  if (assessment == null || assessment.scores.length === 0)
    return <Fragment>N/A</Fragment>;

  return (
    <Fragment>
      <Box display="flex" alignItems="center">
        {assessment.scores
          .map((x) => x.score)
          .reduce((sum, value) => sum + value)}
        <span style={{ fontWeight: "bold" }}>/480</span>{" "}
        <LightTooltip
          placement="bottom-start"
          enterTouchDelay={200}
          style={{ cursor: "pointer", marginLeft: "4px" }}
          title={
            <Table>
              <TableBody>
                {scoreTypes.map((type) =>
                  renderScoreListItem(assessment, type)
                )}
              </TableBody>
            </Table>
          }
        >
          <EmojiEventsIcon />
        </LightTooltip>
      </Box>
    </Fragment>
  );
};

const TopseSection = () => {
  //#region State
  const {
    case: { topse, assessment },
  } = useCaseDetailPageContext();
  const { assessments, handleStartTopseAssessmentClicked } = topse;

  const { handleRefreshAssessments, getAssessmentsRequestState } = assessment;

  const getAssessment = (type: AssessmentTypeEnum) => {
    return assessments.find((x) => x.type === type && x.voidedAt == null);
  };

  const assessmentsCompleted =
    getAssessment(AssessmentTypeEnum.TopsePreAssessment) != null &&
    getAssessment(AssessmentTypeEnum.TopsePostAssessment) != null;
  //#endregion

  //#region UI Helpers
  const renderLoading = () =>
    createNumberRange(4).map((i) => (
      <Box marginBottom={"12px"} key={`loading-${i}`}>
        <LoadingDetail lines={2} icon={<AssessmentIcon />} />
      </Box>
    ));

  const renderTopseAssessments = () => {
    const preAssessment = getAssessment(AssessmentTypeEnum.TopsePreAssessment);
    const postAssessment = getAssessment(
      AssessmentTypeEnum.TopsePostAssessment
    );

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {assessmentTypeTextMap[AssessmentTypeEnum.TopsePreAssessment]}
            </TableCell>
            <TableCell>
              {assessmentTypeTextMap[AssessmentTypeEnum.TopsePostAssessment]}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <AssessmentStatusLabel assessment={preAssessment} />
            </TableCell>
            <TableCell>
              <AssessmentStatusLabel assessment={postAssessment} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Reference #</TableCell>
            <TableCell>
              {preAssessment ? (
                <AssessmentLink assessment={preAssessment} />
              ) : (
                "N/A"
              )}
            </TableCell>
            <TableCell>
              {postAssessment ? (
                <AssessmentLink assessment={postAssessment} />
              ) : (
                "N/A"
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Completed On</TableCell>
            <TableCell>
              {preAssessment == null && "N/A"}
              <DateLabel date={preAssessment?.completedOn} />{" "}
            </TableCell>
            <TableCell>
              {postAssessment == null && "N/A"}
              <DateLabel date={postAssessment?.completedOn} />{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Score</TableCell>
            <TableCell>
              <TopseScore assessment={preAssessment} />
            </TableCell>
            <TableCell>
              <TopseScore assessment={postAssessment} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  //#endregion

  return (
    <Fragment>
      <Box position="relative">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Assessment}>
          <Box display="flex">
            <IconButton
              title="Refresh Assessments"
              onClick={handleRefreshAssessments}
            >
              <RefreshIcon />
            </IconButton>
            {!assessmentsCompleted && (
              <EditButton
                style={{ marginLeft: "auto" }}
                text="Start TOPSE Assessment"
                onClick={handleStartTopseAssessmentClicked}
              />
            )}
          </Box>
        </WriteAccessLevelWrapper>
        {!getAssessmentsRequestState.isLoading && renderTopseAssessments()}
        {getAssessmentsRequestState.isLoading && renderLoading()}
      </Box>
    </Fragment>
  );
};

export default TopseSection;
export const createTopseTab = (): TabProps => ({
  label: "TOPSE",
  value: topseTabName,
  content: <TopseSection />,
});
