import { Grid } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import ControlledInput from "../../../../app/components/inputs/ControlledInput";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { YouthFilterFormDto } from "../../../youths/dto/YouthFilterFormDto";
import { YouthSearchParamsDto } from "../../../youths/dto/YouthSearchParamsDto";
import { ReferralDto } from "../../dtos/ReferralDto";

interface Props {
  referral?: ReferralDto;
  requestState: RequestStateDto;
  onSearch(dto: YouthSearchParamsDto): void;
}

const ScreeningFromReferralYouthSearchFilter: React.FC<Props> = ({
  referral,
  onSearch,
  requestState,
}) => {
  const { control, getValues, setValue, handleSubmit } = useForm({
    defaultValues: new YouthFilterFormDto(),
  });

  useEffect(() => {
    if (referral) {
      setValue("firstName", referral.youthFirstName);
      setValue("lastName", referral.youthLastName);
      setValue("zipCode", referral.youthZipCode || "");
      onSearch(getValues());
    }
  }, [referral, setValue, onSearch, getValues]);

  return (
    <form onSubmit={handleSubmit(onSearch)}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <ControlledInput
            control={control}
            name="firstName"
            label="First Name"
            fullWidth
            size="small"
            disabled={requestState.isLoading}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ControlledInput
            control={control}
            name="lastName"
            label="Last Name"
            fullWidth
            size="small"
            disabled={requestState.isLoading}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ControlledInput
            control={control}
            name="zipCode"
            label="Zip Code"
            fullWidth
            size="small"
            disabled={requestState.isLoading}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SpinnerButton
            showSpinner={requestState.isLoading}
            fullWidth
            content={<Fragment>Search</Fragment>}
            variant="contained"
            color="primary"
            type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ScreeningFromReferralYouthSearchFilter;
