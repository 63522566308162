import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { CaseFilterFormDto } from "../dtos/CaseFilterFormDto";
import { CaseSearchParamsDto } from "../dtos/CaseSearchParamsDto";
import {ProgramTypeEnum} from "../../programs/enums/ProgramTypeEnum";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";

export const useCaseFilter = (
  onSubmit: (dto: CaseSearchParamsDto) => void,
  onClose: () => void,
  onProviderChange: (id: string) => void,
  providerLocations: { [key: string]: LocationDto[] },
  providerPrograms: { [key: string]: ProgramDto[] },
  providerStaffMembers: { [key: string]: StaffMemberDto[] },
  params: CaseSearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit, watch, setValue } = useForm({
    defaultValues: new CaseFilterFormDto(params),
  });

  const { providerId, programId, locationId, primaryStaffId } = watch();

  const [locations, setLocations] = useState<LocationDto[]>([]);
  const [programs, setPrograms] = useState<ProgramDto[]>([]);
  const [staffMembers, setStaffMembers] = useState<StaffMemberDto[]>([]);

  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: CaseFilterFormDto) => {
    const newParams: CaseSearchParamsDto = new CaseSearchParamsDto({
      form: dto,
      previous: params,
    });

    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new CaseFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new CaseFilterFormDto(params));
  }, [params, reset]);

  useEffect(() => {
    onProviderChange(providerId);
  }, [providerId, onProviderChange]);

  useEffect(() => {
    if (Object.keys(providerPrograms).length === 0) return;

    const programs = providerPrograms[providerId] || [];
    setPrograms(programs.filter(p => p.programType.programType !== ProgramTypeEnum.SnapInSchools));
    if (programId !== "" && !programs.some((x) => x.id === programId))
      setValue("programId", "");
  }, [providerId, setPrograms, providerPrograms, setValue, programId]);

  useEffect(() => {
    if (Object.keys(providerLocations).length === 0) return;

    let locations = providerLocations[providerId] || [];
    if (programId !== "") {
      const program = programs.find((x) => x.id === programId);
      if (program) {
        const programLocationIds = program.locations.map((x) => x.locationId);

        locations = locations.filter((x) => programLocationIds.includes(x.id));
      }
    }

    if (locationId !== "" && !locations.some((x) => x.id === locationId))
      setValue("locationId", "");

    setLocations(locations);
  }, [
    providerId,
    programId,
    programs,
    locationId,
    primaryStaffId,
    setLocations,
    providerLocations,
    setValue,
  ]);

  useEffect(() => {
    if (Object.keys(providerStaffMembers).length === 0) return;

    const staffMembers = providerStaffMembers[providerId] || [];
    setStaffMembers(staffMembers);
  }, [providerId, setStaffMembers, providerStaffMembers]);
  //#endregion

  useEffect(() => {
    const providerId = getProviderId();

    if (
      providerId != null &&
      providerId !== "" &&
      providerId !== fnyfsProviderId
    )
      setValue("providerId", providerId);
  }, [setValue]);

  return {
    state: { locations, programs, staffMembers },
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
