import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CinsPetitionDto } from "../dtos/CinsPetitionDto";
import { CinsPetitionSchema } from "../components/CinsPetitionFormFields";

import {
  CinsPetitionFormDto,
  createCinsPetitionFormDto,
} from "../dtos/CinsPetitionPetitionFormDto";
import { PetitionTypeEnum } from "../enums/PetitionTypeEnum";

export const useCinsPetitionForm = (
  onSubmit: (dto: CinsPetitionFormDto) => Promise<void>,
  petition?: CinsPetitionDto
) => {
  //#region State

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: createCinsPetitionFormDto(petition),
    resolver: yupResolver(CinsPetitionSchema),
    mode: "all",
  });

  const { petitionType } = watch();

  const clearForm = () => {
    reset(createCinsPetitionFormDto(petition));
  };
  const handleOnSubmit = handleSubmit(async (dto: CinsPetitionFormDto) => {
    await onSubmit(dto);
    reset(createCinsPetitionFormDto(petition));
  });

  //#region Effects
  useEffect(() => {
    reset(createCinsPetitionFormDto(petition));
  }, [reset, petition]);

  useEffect(() => {
    if (petitionType !== PetitionTypeEnum.CinsFins)
      setValue("reasonForPetition", "");
  }, [petitionType, setValue]);
  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
