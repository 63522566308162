import React, { Fragment } from "react";
import { ProgramDto } from "../dtos/ProgramDto";
import { ProgramFundingSourceEnum } from "../enums/ProgramFundingSourceEnum";

interface Props {
  program: ProgramDto;
}

const ProgramFundingSourceLabel: React.FC<Props> = ({ program }) => {
  switch (program.fundingSource) {
    case ProgramFundingSourceEnum.FloridaNetwork:
      return <Fragment>Florida Network</Fragment>;
    case ProgramFundingSourceEnum.Other:
      return <Fragment>Other</Fragment>;
    default:
      return <Fragment></Fragment>;
  }
};

export default ProgramFundingSourceLabel;
