import { TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import {
  SnapCycleGenderEnum,
  snapCycleGenderEnumTextMap,
} from "../enums/SnapGroupCycleGenderEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  gender: SnapCycleGenderEnum;
}

const SnapCycleGenderLabel: React.FC<Props> = ({ gender, ...props }) => {
  return (
    <>{snapCycleGenderEnumTextMap[gender]}</>
  );
};

export default SnapCycleGenderLabel;
