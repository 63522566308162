import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorAlert from "../../../app/components/ErrorAlert";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { StaffMemberDto } from "../dtos/StaffMemberDto";
import { StaffMemberFormDto } from "../dtos/StaffMemberFormDto";
import { useStaffMemberForm } from "../hooks/useStaffMemberForm";
import StaffMemberFormFields from "./StaffMemberFormFields";

export const staffMemberModalId = "staff-member-form-modal";

interface Props {
  staffMember?: StaffMemberDto;
  onSubmit: (dto: StaffMemberFormDto) => Promise<void>;
  requestState: RequestStateDto;
}

const StaffMemberFormDialog: React.FC<Props> = ({
  staffMember,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useStaffMemberForm(onSubmit, staffMember);
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === staffMemberModalId} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {staffMember ? "Edit" : "Create"} Staff Member{" "}
          <ErrorAlert error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <StaffMemberFormFields control={control} disabled={isLoading} />
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default StaffMemberFormDialog;
