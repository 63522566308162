import {
  Box,
  Divider,
  Grid,
  GridSize,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { isString } from "lodash";
import DeleteAccessLevelWrapper from "../../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import DeleteButton from "../../../../app/components/buttons/DeleteButton";
import EditButton from "../../../../app/components/buttons/EditButton";
import KeyValueRow from "../../../../app/components/KeyValueRow";
import DateLabel from "../../../../app/components/labels/DateLabel";
import NameLabel from "../../../../app/components/labels/NameLabel";
import { TabProps } from "../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { ProgramTypeEnum } from "../../../programs/enums/ProgramTypeEnum";
import ConfirmDeleteSatisfactionSurveyDialog from "../../../satisfaction-survey/components/ConfirmDeleteSatisfactionSurveyDialog";
import SatisfactionSurveyFormDialog from "../../../satisfaction-survey/components/SatisfactionSurveyFormDialog";
import {
  getQuestionText,
  satisfactionSurveyQuestions,
  SatisfactionSurveyResponseSetDto,
  satisfactionSurveyResponseTextMap,
  SnapSatisfactionSurveyQuestionNames,
  snapSatisfactionSurveyQuestions,
} from "../../../satisfaction-survey/SatisfactionSurveyDto";
import {
  SnapSatisfactionSurveyHelpsPeopleEnumTextMap,
  SnapSatisfactionSurveyLikertEnumTextMap,
  SnapSatisfactionSurveySkillEnumTextMap,
} from "../../../satisfaction-survey/SatisfactionSurveyEnums";
import { useCaseDetailPageContext } from "../../pages/CaseDetailPage";

export const caseSatisfactionSurveySectionName =
  "case-satisfaction-survey-section";

const labelSizes: { [key: string]: GridSize } = {
  lgLabelSize: 6,
  mdLabelSize: 8,
  xsLabelSize: 10,
};

const CaseSatisfactionSurveySection = () => {
  const {
    case: { caseResult },
    satisfactionSurvey: {
      upsertSatisfactionSurveyRequestState,
      deleteSatisfactionSurveyRequestState,
      handleUpsertSatisfactionSurveyClicked,
      handleUpsertSatisfactionSurvey,
      handleDeleteSatisfactionSurvey,
      handleDeleteSatisfactionSurveyClicked,
    },
  } = useCaseDetailPageContext();

  const renderSnapContent = () => {
    if (
      caseResult == null ||
      ![ProgramTypeEnum.Snap, ProgramTypeEnum.SnapYouthJustice].includes(
        caseResult.program.programType.programType
      )
    )
      return <></>;

    return (
      <>
        <KeyValueRow {...labelSizes} delimiter="">
          <span style={{ whiteSpace: "normal" }}>{`${
            satisfactionSurveyQuestions.length + 1
          }. ${
            snapSatisfactionSurveyQuestions[
              SnapSatisfactionSurveyQuestionNames.snapSatisfiedWithProgram
            ]
          }`}</span>
          <span style={{ whiteSpace: "normal" }}>
            {
              SnapSatisfactionSurveyLikertEnumTextMap[
                caseResult.satisfactionSurvey?.satisfactionSurveyResponseSet
                  .snapSatisfiedWithProgram!
              ]
            }
          </span>
        </KeyValueRow>
        <KeyValueRow {...labelSizes} delimiter="">
          <span style={{ whiteSpace: "normal" }}>{`${
            satisfactionSurveyQuestions.length + 2
          }. ${
            snapSatisfactionSurveyQuestions[
              SnapSatisfactionSurveyQuestionNames.snapHelpfulSkills
            ]
          }`}</span>
          <span>
            <List disablePadding>
              {caseResult.satisfactionSurvey?.satisfactionSurveyResponseSet.snapHelpfulSkills?.map(
                (x) => (
                  <ListItem key={x} disableGutters style={{ padding: "0px" }}>
                    <Typography style={{ whiteSpace: "normal" }}>
                      {SnapSatisfactionSurveySkillEnumTextMap[x]}
                    </Typography>
                  </ListItem>
                )
              )}
            </List>
          </span>
        </KeyValueRow>
        <KeyValueRow {...labelSizes} delimiter="">
          <span style={{ whiteSpace: "normal" }}>{`${
            satisfactionSurveyQuestions.length + 3
          }. ${
            snapSatisfactionSurveyQuestions[
              SnapSatisfactionSurveyQuestionNames.snapHelpsPeople
            ]
          }`}</span>
          <span>
            <List dense disablePadding>
              {caseResult.satisfactionSurvey?.satisfactionSurveyResponseSet.snapHelpsPeople?.map(
                (x) => (
                  <ListItem key={x} disableGutters style={{ padding: "0px" }}>
                    <Typography style={{ whiteSpace: "normal" }}>
                      {SnapSatisfactionSurveyHelpsPeopleEnumTextMap[x]}
                    </Typography>
                  </ListItem>
                )
              )}
            </List>
          </span>
        </KeyValueRow>
      </>
    );
  };

  const renderContent = () => {
    if (caseResult?.satisfactionSurvey == null)
      return <Typography>Satisfaction Survey Not Completed</Typography>;

    const questions = satisfactionSurveyQuestions
      .map((question) => {
        const questionText = getQuestionText(
          question,
          caseResult.program.programType.programType!
        );
        const response =
          caseResult.satisfactionSurvey?.satisfactionSurveyResponseSet[
            question.name as keyof SatisfactionSurveyResponseSetDto
          ];
        if (!isString(response)) return undefined;
        return (
          <KeyValueRow {...labelSizes} key={question.name} delimiter="">
            <span style={{ whiteSpace: "normal" }}>{`${
              question.order + 1
            }. ${questionText}`}</span>
            <span style={{ whiteSpace: "normal" }}>
              {satisfactionSurveyResponseTextMap[response!]}
            </span>
          </KeyValueRow>
        );
      })
      .filter((x) => x !== undefined);

    return (
      <Grid container spacing={1}>
        <KeyValueRow {...labelSizes}>
          <span>Completed On</span>
          <span>
            <DateLabel date={caseResult.satisfactionSurvey.completedOn} />
          </span>
        </KeyValueRow>
        <KeyValueRow {...labelSizes}>
          <span>Counselor</span>
          <span>
            <NameLabel nameDto={caseResult.satisfactionSurvey.staffMember} />
          </span>
        </KeyValueRow>
        <Grid item md={12} xs={12}>
          <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
        </Grid>
        {questions}
        {renderSnapContent()}
      </Grid>
    );
  };

  return (
    <>
      <style>
        {`

        #action-container {
          position: absolute;
          top: 0;
          right: 0;
        }
      @media only screen and (max-width: 960px){
        #action-container {
          position: relative;
          display: flex;
          justify-content: flex-end
        }
      }
      `}
      </style>
      <Box position="relative" height="60vh">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <div id="action-container">
            <EditButton
              onClick={handleUpsertSatisfactionSurveyClicked}
              text={`${caseResult?.satisfactionSurvey ? "Edit" : "Add"}`}
            />
            {caseResult?.satisfactionSurvey && (
              <DeleteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
                <DeleteButton onClick={handleDeleteSatisfactionSurveyClicked} />
              </DeleteAccessLevelWrapper>
            )}
          </div>
          <SatisfactionSurveyFormDialog
            onSubmit={handleUpsertSatisfactionSurvey}
            requestState={upsertSatisfactionSurveyRequestState}
            caseDto={caseResult}
          />
          <ConfirmDeleteSatisfactionSurveyDialog
            onConfirm={handleDeleteSatisfactionSurvey}
            requestState={deleteSatisfactionSurveyRequestState}
            caseDto={caseResult}
          />
        </WriteAccessLevelWrapper>
        {renderContent()}
      </Box>
    </>
  );
};

export default CaseSatisfactionSurveySection;

export const createCaseSatisfactionSurveySection = (): TabProps => ({
  value: caseSatisfactionSurveySectionName,
  label: "Satisfaction Survey",
  content: <CaseSatisfactionSurveySection />,
});
