import { Box, Grid, Paper } from "@material-ui/core";
import React, { createContext, useContext } from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import ConfirmDeactivateRoleDialog from "../components/ConfirmDeactiveRoleDialog";
import ProviderRoleSection from "../components/provider-roles/ProviderRoleSection";
import UpdateUserEmailDialog from "../components/UpdateUserEmailDialog";
import UserDetails from "../components/UserDetails";
import UserFormDialog from "../components/UserFormDialog";
import UserPinDialog from "../components/UserPinDialog";
import {
  UserDetailPageProps,
  useUserDetailPage,
} from "../hooks/useUserDetailPage";

const UserDetailPageContext = createContext<UserDetailPageProps>(undefined!);

export const useUserDetailPageContext = () => useContext(UserDetailPageContext);

const UserDetailPage = () => {
  const userDetailPage = useUserDetailPage();
  const {
    getUserRequestState,
    unlockAccountRequestState,
    user,
    updateUserEmailRequestState,
    updateUserRequestState,
    roles: {
      selectedRole,
      handleDeactivateRole,
      addProviderRoleRequestState,
      removeProviderRoleRequestState,
      deactivateUserRoleRequestState,
      activateUserRoleRequestState,
    },
    handleUpdateUserEmail,
    handleEdit,
  } = userDetailPage;

  return (
    <UserDetailPageContext.Provider value={userDetailPage}>
      <UserFormDialog
        user={user}
        onSubmit={handleEdit}
        requestState={updateUserRequestState}
      />
      <UpdateUserEmailDialog
        onSubmit={handleUpdateUserEmail}
        requestState={updateUserEmailRequestState}
      />
      <ConfirmDeactivateRoleDialog
        user={user}
        onConfirm={handleDeactivateRole}
        requestState={deactivateUserRoleRequestState}
        role={selectedRole}
      />
      <RequestErrorAlert
        requests={[
          getUserRequestState,
          updateUserEmailRequestState,
          unlockAccountRequestState,
          addProviderRoleRequestState,
          removeProviderRoleRequestState,
          deactivateUserRoleRequestState,
          activateUserRoleRequestState,
        ]}
      />
      <UserPinDialog user={user} />
      <Box marginBottom="8px">
        <BackButton route="/users" text="Back to Users" />
      </Box>

      <Header
        title={`${user?.firstName} ${user?.lastName}`}
        isLoading={getUserRequestState.isLoading}
      />
      <Paper
        variant="outlined"
        style={{
          padding: "16px",
          height: "82%",
          backgroundColor: ColorsEnum.OffWhite,
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <UserDetails />
          </Grid>
          <FnAccessWrapper>
            <Grid item md={12} xs={12}>
              <ProviderRoleSection />
            </Grid>
          </FnAccessWrapper>
        </Grid>
      </Paper>
    </UserDetailPageContext.Provider>
  );
};

export default UserDetailPage;
