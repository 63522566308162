import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { SnapInSchoolsCycleFilterFormDto } from "./SnapInSchoolsCycleFilterFormDto";

export class SnapInSchoolsSearchParamsDto implements PaginationSearchParamsDto {
  providerId?: string;
  grade?: string;
  cycleStartDateRangeStart?: string;
  cycleStartDateRangeEnd?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: SnapInSchoolsCycleFilterFormDto;
    previous?: SnapInSchoolsSearchParamsDto;
  }) {
    this.providerId = form?.providerId || (query?.providerId as string) || "";
    this.grade = form?.grade || (query?.grade as string) || "";
    this.sortBy =
      previous?.sortBy || (query?.sortBy as string) || "referenceNumber";
    this.sortDirection =
      previous?.sortDirection || QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
  }

  public static toQueryString(dto: SnapInSchoolsSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
