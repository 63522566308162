import React from "react";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ColorsEnum } from "../enums/ColorsEnum";
const useStyles = makeStyles({
  noMaxWidth: {
    maxWidth: "none",
  },
});
interface Props {
  title: React.ReactFragment;
  style?: React.CSSProperties;
}

const InfoTooltip: React.FC<Props> = ({ title, style }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={<Typography component="span">{title}</Typography>}
      enterTouchDelay={200}
      classes={{ tooltip: classes.noMaxWidth }}
    >
      <InfoIcon
        style={{ cursor: "pointer", color: ColorsEnum.InfoBlue, ...style }}
      />
    </Tooltip>
  );
};

export default InfoTooltip;
