import React, { ReactComponentElement, useEffect } from "react";
import { useRequest } from "../../../app/hooks/useRequest";
import {
  getDashboardDataRequest,
  getDashboardDataRequest2,
} from "../DashboardRequests";
import { Grid } from "@material-ui/core";
import { useDashboardContext } from "../DashboardContext";
import { useParams } from "react-router-dom";
import {
  MainDashboardMetrics,
  MainDashboardMetricsDto,
  metricIds as mainDashboardMetricIds,
} from "../components/dashboardLayouts/MainDashboardMetrics";
import {
  CinsFinsResidentialDashboardMetrics,
  ResidentialMetricsDto,
  metricIds as residentialDashboardMetricIds,
} from "../components/dashboardLayouts/CinsFinsResidentialDashboardMetrics";
import { DashboardHeaderPanel } from "../components/DashboardHeaderPanel";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { DashboardValueMetricsDto } from "../dtos/MainDashboardMetricsDto";
import { ComingSoon } from "../../../app/components/ComingSoon";
import {
  CommunityCounselingDashboardMetrics,
  CommunityCounselingMetricsDto,
  metricIds as communityCounselingMetricIds,
} from "../components/dashboardLayouts/CommunityCounselingDashboardMetrics";
import {
  SnapDashboardMetrics,
  SnapMetricsDto,
  metricIds as snapMetricIds,
} from "../components/dashboardLayouts/SnapDashboardMetrics";
import {
  IcmDashboardMetrics,
  IcmMetricsDto,
  metricIds as icmMetricIds,
} from "../components/dashboardLayouts/IcmDashboardMetrics";
import {
  RespiteDashboardMetrics,
  RespiteMetricsDto,
  metricIds as respiteMetricIds,
} from "../components/dashboardLayouts/RespiteDashboardMetrics";

const Dashboards: {
  [index: string]: {
    title: string;
    render: (
      dto: any | undefined,
      isLoading: boolean
    ) => ReactComponentElement<any>;
  };
} = {
  main: {
    title: "Provider Dashboard",
    render: (
      value: MainDashboardMetricsDto | undefined,
      isLoading: boolean
    ) => <MainDashboardMetrics metrics={value} isLoading={isLoading} />,
  },
  shelter: {
    title: "Shelter Dashboard",
    render: (value: ResidentialMetricsDto | undefined, isLoading: boolean) => (
      <CinsFinsResidentialDashboardMetrics
        metrics={value}
        isLoading={isLoading}
      />
    ),
  },
  "community-counseling": {
    title: "Community Counseling Dashboard",
    render: (value: CommunityCounselingMetricsDto, isLoading: boolean) => (
      <CommunityCounselingDashboardMetrics
        isLoading={isLoading}
        metrics={value}
      />
    ),
  },
  snap: {
    title: "SNAP Dashboard",
    render: (value: SnapMetricsDto | undefined, isLoading: boolean) => (
      <SnapDashboardMetrics metrics={value} isLoading={isLoading} />
    ),
  },
  icm: {
    title: "ICM Dashboard",
    render: (value: IcmMetricsDto | undefined, isLoading: boolean) => (
      <IcmDashboardMetrics metrics={value} isLoading={isLoading} />
    ),
  },
  respite: {
    title: "Respite Dashboard",
    render: (value: RespiteMetricsDto | undefined, isLoading: boolean) => (
      <RespiteDashboardMetrics metrics={value} isLoading={isLoading} />
    ),
  },
  default: {
    title: "Coming Soon",
    render: () => <ComingSoon />,
  },
};

export const MainDashboard: React.FC = () => {
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const { filters, providerId } = useDashboardContext();

  const [getData1, data1, requestState1] = useRequest(
    getDashboardDataRequest,
    true
  );
  const [getData2, data2, requestState2] = useRequest(getDashboardDataRequest2);

  useEffect(() => {
    if (!dashboardId) return;

    switch (dashboardId) {
      case "main": {
        const requestDto = { metricIds: mainDashboardMetricIds, ...filters };
        getData1({ dashboard: dashboardId, dto: requestDto });
        const interval = setInterval(
          () => getData1({ dashboard: dashboardId, dto: requestDto }),
          60000
        );
        return () => clearInterval(interval);
      }
      case "shelter": {
        const request1Dto = {
          metricIds: residentialDashboardMetricIds.filter(
            (id) => id !== "cinsFinsResidentialFilledBedDays"
          ),
          ...filters,
        };
        const request2Dto = {
          metricIds: ["cinsFinsResidentialFilledBedDays"],
          ...filters,
        };
        getData1({ dashboard: dashboardId, dto: request1Dto });
        getData2({ dashboard: dashboardId, dto: request2Dto });
        const interval = setInterval(() => {
          getData1({ dashboard: dashboardId, dto: request1Dto });
          getData2({ dashboard: dashboardId, dto: request2Dto });
        }, 60000);
        return () => clearInterval(interval);
      }
      case "community-counseling": {
        const request1Dto = {
          metricIds: communityCounselingMetricIds.filter(
            (id) => id !== "communityCounselingIntakesAndCarryOver"
          ),
          ...filters,
        };
        const request2Dto = {
          metricIds: ["communityCounselingIntakesAndCarryOver"],
          ...filters,
        };
        getData1({ dashboard: dashboardId, dto: request1Dto });
        getData2({ dashboard: dashboardId, dto: request2Dto });
        const interval = setInterval(() => {
          getData1({ dashboard: dashboardId, dto: request1Dto });
          getData2({ dashboard: dashboardId, dto: request2Dto });
        }, 60000);
        return () => clearInterval(interval);
      }
      case "snap": {
        const request1Dto = { metricIds: snapMetricIds, ...filters };
        getData1({ dashboard: dashboardId, dto: request1Dto });
        const interval = setInterval(() => {
          getData1({ dashboard: dashboardId, dto: request1Dto });
        }, 60000);
        return () => clearInterval(interval);
      }
      case "icm": {
        const request1Dto = {
          metricIds: icmMetricIds.filter((id) => id !== "icmFilledBedDays"),
          ...filters,
        };
        const request2Dto = { metricIds: ["icmFilledBedDays"], ...filters };
        getData1({ dashboard: dashboardId, dto: request1Dto });
        getData2({ dashboard: dashboardId, dto: request2Dto });
        const interval = setInterval(() => {
          getData1({ dashboard: dashboardId, dto: request1Dto });
          getData2({ dashboard: dashboardId, dto: request2Dto });
        }, 60000);
        return () => clearInterval(interval);
      }
      case "respite": {
        const request1Dto = {
          metricIds: respiteMetricIds.filter(
            (id) =>
              !["dvRespiteFilledBedDays", "prRespiteFilledBedDays"].some(
                (v) => v === id
              )
          ),
          ...filters,
        };
        const request2Dto = {
          metricIds: ["dvRespiteFilledBedDays", "prRespiteFilledBedDays"],
          ...filters,
        };
        getData1({ dashboard: dashboardId, dto: request1Dto });
        getData2({ dashboard: dashboardId, dto: request2Dto });
        const interval = setInterval(() => {
          getData1({ dashboard: dashboardId, dto: request1Dto });
          getData2({ dashboard: dashboardId, dto: request2Dto });
        }, 60000);
        return () => clearInterval(interval);
      }
      default:
    }
  }, [dashboardId, filters, getData1, getData2, providerId]);

  const combinedRequestState: RequestStateDto = {
    isLoading: requestState1.isLoading || requestState2.isLoading,
    error: requestState1.error || requestState2.error,
  };

  const { isLoading } = combinedRequestState;

  const combinedData:
    | DashboardValueMetricsDto<{ [index: string]: any }>
    | undefined = data1
    ? {
        dataGeneratedAt: data1.dataGeneratedAt,
        dateRange: data1.dateRange,
        values: { ...data1.values, ...data2?.values },
      }
    : undefined;

  const dashboard = Dashboards[dashboardId] || Dashboards["default"];

  return (
    <div>
      <DashboardHeaderPanel
        requestState={combinedRequestState}
        title={dashboard.title}
        metricsData={combinedData}
      />

      <Grid container spacing={2}>
        {dashboard.render(combinedData?.values, isLoading)}
      </Grid>
    </div>
  );
};
