import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import * as Yup from "yup";

export const locationSchema = Yup.object().shape({
  name: Yup.string().label("Name").required().max(100),
  address1: Yup.string().label("Street 1").required().max(75),
  address2: Yup.string().label("Street 2").max(75),
  city: Yup.string().label("City").required().max(75),
  state: Yup.string().label("State").required().max(2),
  zipCode: Yup.string().label("Zip Code").required().max(10),
});

interface Props {
  control: Control<any>;
  prefix?: string;
  disabled: boolean;
}

const stateOptions: SelectOption[] = [
  { value: "", label: "Select State" },
  { value: "FL", label: "Florida" },
];

const LocationFormFields: React.FC<Props> = ({ control, prefix, disabled }) => {
  const formatName = (name: string) => (prefix ? `${prefix}.${name}` : name);

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name={formatName("name")}
          label="Name*"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name={formatName("address1")}
          label="Street 1*"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name={formatName("address2")}
          label="Street 2"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <ControlledInput
          control={control}
          name={formatName("city")}
          label="City*"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ControlledSelect
          control={control}
          name={formatName("state")}
          label="State*"
          disabled={true}
          options={stateOptions}
          fullWidth
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <ControlledInput
          control={control}
          name={formatName("zipCode")}
          label="Zip*"
          disabled={disabled}
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export default LocationFormFields;
