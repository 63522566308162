import React, { Fragment } from "react";
import { useSnapGroupCycleDetailPageContext } from "../pages/SnapGroupCycleDetailPage";
import ConfirmSnapGroupCycleDeletionDialog from "./ConfirmSnapGroupCycleDeletionDialog";
import SnapGroupCycleFormDialog from "./SnapGroupCycleFormDialog";
import SnapGroupFormDialog from "./SnapGroupFormDialog";

const SnapGroupCycleDetailDialogs = () => {
  var context = useSnapGroupCycleDetailPageContext();

  const {
    snapCycle,
    handleEditSnapCycle,
    updateSnapGroupCycleRequestState,
    deleteSnapGroupCycleRequestState,
    handleDeleteSnapGroupCycle,
  } = context.snapCycle;
  const { selectedGroup, upsertSnapGroupRequestState, handleUpsertSnapGroup } =
    context.group;
  const { curriculum, getSnapCurriculumRequestState } = context.curriculum;
  const { programTypes, getProgramTypesRequestState } = context.programType;

  return (
    <Fragment>
      <SnapGroupFormDialog
        snapCycle={snapCycle}
        snapGroup={selectedGroup}
        curriculum={curriculum}
        onSubmit={handleUpsertSnapGroup}
        requestState={upsertSnapGroupRequestState}
        getSnapCurriculumRequestState={getSnapCurriculumRequestState}
      />
      <SnapGroupCycleFormDialog
        onSubmit={handleEditSnapCycle}
        requestState={updateSnapGroupCycleRequestState}
        snapCycle={snapCycle}
        programTypes={programTypes}
        programTypesAreLoading={getProgramTypesRequestState.isLoading}
      />
      <ConfirmSnapGroupCycleDeletionDialog
        onConfirm={handleDeleteSnapGroupCycle}
        requestState={deleteSnapGroupCycleRequestState}
        cycleDto={snapCycle}
      />
    </Fragment>
  );
};

export default SnapGroupCycleDetailDialogs;
