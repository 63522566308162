import { ParsedQuery } from "query-string";
import { UserDetailDto } from "../../users/dtos/UserDetailDto";

export class SendConfirmationEmailRequestDto {
  email: string = "";
  resetPassword?: boolean;

  constructor(query?: ParsedQuery<string>) {
    this.email = (query?.email as string) || "";
    this.resetPassword = Boolean(query?.resetPassword as string);
  }

  public static fromUser(dto: UserDetailDto) {
    const result = new SendConfirmationEmailRequestDto();
    result.email = dto.email;
    result.resetPassword = true;
    return result;
  }
}
