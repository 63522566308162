import { JjisCodeDto } from "../../jjis/dtos/JjisCodeDto";

export interface ProgramLocationDto {
  id: string;
  locationId: string;
  programId: string;
  startedOn: Date;
  terminatedOn?: Date;
  judicialCircuitNumber?: string;
  juvenileJusticeProgram?: JjisCodeDto;
}

export const programLocationActiveOn = (startDate: Date | string, programId?: string) => (location: ProgramLocationDto) =>
  programLocationActiveBetween(startDate, new Date(), programId)(location)

export const programLocationActiveBetween = (startDate: Date | string, endDate: Date | string, programId?: string) =>
  (location: ProgramLocationDto) =>
    // location active on or before start date
    new Date(location.startedOn) <= new Date(startDate)
    // location not terminated or terminated after start date (seems redundant if terminatedOn is always after startedOn but ¯\_(ツ)_/¯
    && (!location.terminatedOn || new Date(location.terminatedOn) > new Date(startDate))
    // location not terminated or terminated on or after end date
    && (!location.terminatedOn || (new Date(location.terminatedOn) >= new Date(endDate)))
    && (!programId || location.programId === programId);
