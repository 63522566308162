import { Paper, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import DateLabel from "../../../app/components/labels/DateLabel";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import {
  OutreachActivitySearchParamsDto,
  OutreachActivitySummaryDto,
} from "../dtos/OutreachActivityDto";
import { modalityEnumTextMap } from "../enums/ModalityEnum";
import { topicEnumTextMap } from "../enums/TopicEnum";

const OutreachActivityList: React.FC<
  SortableTableProps<
    OutreachActivitySummaryDto,
    OutreachActivitySearchParamsDto
  >
> = (props) => {
  const isFnUser = getProviderId() === fnyfsProviderId;

  const columns: ColumnProps[] = [
    {
      name: "title",
      label: "Title",
    },
    { name: "date", label: "Activity Date" },
    { name: "provider", label: "Provider", hidden: !isFnUser },
    { name: "peopleReached", label: "Est. People Reached" },
    { name: "topics", label: "Topic(s)" },
    { name: "modality", label: "Modality" },
    { name: "zipCode", label: "Zip Code" },
  ];
  const renderRow = (summary: OutreachActivitySummaryDto) => (
    <TableRow key={summary.id}>
      <TableCell>
        <RouteableStyledLink
          pathname={`/outreach-activities/${summary.id}`}
          text="Back to Outreach Activities"
        >
          {summary.title}
        </RouteableStyledLink>
      </TableCell>
      <TableCell>
        <DateLabel date={summary.activityDate} />
      </TableCell>
      <FnAccessWrapper>
        <TableCell>{summary.provider.name}</TableCell>
      </FnAccessWrapper>
      <TableCell>{summary.estimatedPeopleReached}</TableCell>
      <TableCell>
        {summary.topics.map((x) => topicEnumTextMap[x]).join(", ")}
      </TableCell>
      <TableCell>{modalityEnumTextMap[summary.modality]}</TableCell>
      <TableCell>{summary.zipCode}</TableCell>
    </TableRow>
  );

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default OutreachActivityList;
