import { ReportFormatEnum } from "../enums/ReportFormatEnum";
import * as Yup from "yup";
import {DateTime} from "luxon";

export class IcmActivitySearchParamsDto {
  providerIds: string[];
  startOn: string;
  endOn: string;
  format: string;

  constructor() {
    this.providerIds = [];
    this.startOn = "";
    this.endOn = "";
    this.format = "";
  }
}

export class IcmActivitySearchFormDto {
  providerIds: string[];
  startOn: string;
  endOn: string;

  constructor(params?: IcmActivitySearchParamsDto) {
    this.providerIds = params?.providerIds || [];
    this.startOn = params?.startOn || "";
    this.endOn = params?.endOn || "";
  }

  public static toCsvParams(
    dto: IcmActivitySearchFormDto
  ): IcmActivitySearchParamsDto {
    return { ...dto, format: ReportFormatEnum.CSV };
  }

  public static toHtmlParams(
    dto: IcmActivitySearchFormDto
  ): IcmActivitySearchParamsDto {
    return { ...dto, format: ReportFormatEnum.HTML };
  }

  public static getSchema() {
    return Yup.object().shape({
      startOn: Yup
        .string()
        .label("Start Date")
        .required(),
      endOn: Yup
        .string()
        .label("End Date")
        .required()
        .test(
          "date-check",
          "End Date cannot be before Start Date",
          function (value) {
            if (value == null || value === "") return true;

            if (this.parent.startOn == null || this.parent.startOn === "")
              return true;

            return new Date(this.parent.startOn) <= new Date(value);
          }
        )
        .test(
            "date-range-check",
            "Start and End Dates cannot be more than 3 months apart.",
            function (value, s) {
              if (s.parent.startOn == null || s.parent.startOn === "" || value == null || value === "") return true;
              const startDate = DateTime.fromJSDate(new Date(s.parent.startOn))
              const endDate = DateTime.fromJSDate(new Date(value!))
              const dateDiff = endDate.diff(startDate, "months");

              return dateDiff.months <= 3;
            }
          )

    })
  }
}
