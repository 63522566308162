import jwt from "jsonwebtoken";
export interface JwtPayloadDto {
  sub: string;
  email: string;
  isFnUser: string;
  firstname: string;
  lastname: string;
  exp: number;
  permissions: string;
}

export const getExpirationDate = (token: string | null) => {
  if (token === null) return undefined;

  const decoded = jwt.decode(token) as JwtPayloadDto;
  return decoded.exp;
};
