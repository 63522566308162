import {
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { LocationDto } from "../../../locations/dtos/LocationDto";
import { ProgramLocationDto } from "../../../locations/dtos/ProgramLocationDto";
import ProviderProgramLocationInfo from "./ProviderProgramLocationInfo";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { getProviderId } from "../../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../../authentication/constants";
import EditButton from "../../../../app/components/buttons/EditButton";
import DeleteButton from "../../../../app/components/buttons/DeleteButton";

interface Props {
  programLocation: ProgramLocationDto;
  location?: LocationDto;
  onTerminateProgramLocationClick: () => void;
  onEditLocationClick: () => void;
  floridaNetworkFunded: boolean;
}
const ProviderProgramLocationItem: React.FC<Props> = ({
  programLocation,
  location,
  onTerminateProgramLocationClick,
  onEditLocationClick,
  floridaNetworkFunded,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={8}>
        <Box display="flex" alignItems="center">
            <Typography component="span" style={{ marginRight: "8px", marginLeft: "16px" }} color="textSecondary">
              {location?.name} - {location?.city}, {location?.state}
            </Typography>
            <ProviderProgramLocationInfo programLocation={programLocation} />
          </Box>
      </Grid>
      <Grid item xs={4}>
        <Box style={{textAlign: 'right'}}>
          {(getProviderId() === fnyfsProviderId || !floridaNetworkFunded) && (
            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.ProgramLocation}
            >
              <EditButton onClick={onEditLocationClick} />
            </WriteAccessLevelWrapper>
          )}

          {programLocation.terminatedOn === undefined &&
            (getProviderId() === fnyfsProviderId || !floridaNetworkFunded) && (
              <WriteAccessLevelWrapper
                name={PermissionResourceNameEnum.ProgramLocation}
              >
                <DeleteButton
                  onClick={onTerminateProgramLocationClick}
                  text="Terminate"
                />
              </WriteAccessLevelWrapper>
            )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProviderProgramLocationItem;
