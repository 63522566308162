import {
  Box,
  Chip,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { isArray } from "lodash";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      alignItems: "center",
      padding: theme.spacing(0.5),
      margin: "16px 0px",
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

const excludedKeys: string[] = ["page", "pageSize", "sortBy", "sortDirection"];

export interface ChipProps {
  label: string;
  name: string;
}

interface Props {
  params: any;
  isLoading: boolean;
  getLabel(key: string, value: any): string;
  onSearch(params: any): void;
  onClearParams(): any;
  ordering?: string[];
  exclude?: string[];
}

const FilterList: React.FC<Props> = ({
  isLoading,
  params,
  getLabel,
  onSearch,
  onClearParams,
  ordering,
  exclude
}) => {
  const classes = useStyles();
  const [chips, setChips] = useState<ChipProps[]>([]);

  const removeFilter = (name: string) => {
    return { ...params, page: 0, [name]: "" };
  };

  const handleRemoveFilter = (name: string) => {
    const newParams = removeFilter(name);
    onSearch(newParams);
  };

  const handleClearFilter = () => {
    const newParams = onClearParams();
    onSearch(newParams);
  };

  useEffect(() => {
    const fullExclusionList = [...excludedKeys, ...(exclude || [])];
    const chips = Object.entries(params)
      .filter(([key, value]) => value && !fullExclusionList.includes(key))
      .filter(([key, value]) => !isArray(value) || (value as any[]).length > 0)
      .map(([key, value]) => {
        return { name: key, label: getLabel(key, value) };
      })
      .sort((a, b) => {
        if (ordering == null) return 0;

        return ordering.indexOf(a.name) > ordering.indexOf(b.name) ? 1 : -1;
      })
      .filter((x) => x.label !== "");

    setChips(chips);
  }, [params, getLabel, setChips, ordering, exclude]);

  if (chips.length === 0) return <Fragment></Fragment>;

  return (
    <Paper component="ul" className={classes.root}>
      {chips.map((data) => {
        return (
          <li key={data.name}>
            <Chip
              label={<Typography>{data.label}</Typography>}
              onDelete={() => handleRemoveFilter(data.name)}
              className={classes.chip}
              disabled={isLoading}
              variant="outlined"
            />
          </li>
        );
      })}
      <Box marginLeft="auto">
        <IconButton
          title="Clear All Filters"
          onClick={handleClearFilter}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default FilterList;
