import { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import { SnapCycleGenderEnum } from "../enums/SnapGroupCycleGenderEnum";

export interface SnapCurriculumDto {
  id: string;
  description: string;
  order: number;
  programTypeId: string;
  gender: SnapCycleGenderEnum;
}

export const snapCurriculumToOption = (
  dto: SnapCurriculumDto
): SelectOption => ({ value: dto.id, label: dto.description });

export const snapCurriculumToOptions = (
  dtos: SnapCurriculumDto[]
): SelectOption[] => [
  { value: "", label: "Select Session Name" },
  ...dtos
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(snapCurriculumToOption),
];
