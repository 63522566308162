import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum CaseContactTypeEnum {
  Collateral = "Collateral",
  FaceToFace = "FaceToFace",
}

export const caseContactTypeTextMap: { [key: string]: string } = {
  [CaseContactTypeEnum.Collateral]: "Collateral",
  [CaseContactTypeEnum.FaceToFace]: "Face to Face",
};

export const caseContactTypeOptions: SelectOption[] = [
  { value: "", label: "Select Contact Type" },
  ...Object.keys(CaseContactTypeEnum)
    .sort((a, b) => (a > b ? 1 : -1))
    .map((x) => ({
      value: x,
      label: caseContactTypeTextMap[x],
    })),
];

export const getCaseContactTypeDisplayText = (type: CaseContactTypeEnum) => {
  switch (type) {
    case CaseContactTypeEnum.Collateral:
      return "Collateral";
    case CaseContactTypeEnum.FaceToFace:
      return "Face to Face";
  }
};
