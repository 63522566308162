import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import InfoTooltip from "../../../app/components/InfoTooltip";
import DateLabel from "../../../app/components/labels/DateLabel";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import CaseDeletedLabel from "../../youths/components/labels.tsx/CaseDeletedLabel";
import { CaseListDto } from "../dtos/CaseListDto";
import { CaseSearchParamsDto } from "../dtos/CaseSearchParamsDto";

const CaseList: React.FC<
  SortableTableProps<CaseListDto, CaseSearchParamsDto>
> = (props) => {
  const columns: ColumnProps[] = [
    { name: "caseNumber", label: "Case #" },
    { name: "youth", label: "Youth" },
    { name: "provider", label: "Provider" },
    { name: "program", label: "Program" },
    { name: "programType", label: "Program Type" },
    { name: "location", label: "Location" },
    { name: "intakeDate", label: "Intake Date" },
    { name: "dischargeDate", label: "Discharge Date" },
  ];

  const createRow = (label: string, value: React.ReactNode) => {
    return (
      <Box display="flex" justifyContent="space-between">
        <Typography
          style={{
            marginRight: "8px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {label}:
        </Typography>
        <Typography>{value}</Typography>
      </Box>
    );
  };

  const renderRow = (caseDto: CaseListDto) => {
    return (
      <TableRow key={caseDto.id}>
        <TableCell>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <RouteableStyledLink
              pathname={`/cases/${caseDto.id}`}
              text="Back to Cases"
            >
              {caseDto.caseNumber.split("-")[0]}
            </RouteableStyledLink>
            <CaseDeletedLabel isDeleted={caseDto.isDeleted} />
          </Box>
        </TableCell>
        <TableCell>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <RouteableStyledLink
              pathname={`/youths/${caseDto.youth.id}`}
              text="Back to cases"
            >
              {caseDto.youth.firstName} {caseDto.youth.lastName}
            </RouteableStyledLink>
            <span style={{ marginLeft: "4px" }}>
              <InfoTooltip
                title={
                  <Fragment>
                    {createRow("Youth ID", caseDto.youth.referenceNumber)}
                    {caseDto.youth.djjIdNumber &&
                      createRow("DJJID", caseDto.youth.djjIdNumber)}
                  </Fragment>
                }
              />
            </span>
          </Box>
        </TableCell>
        <TableCell>{caseDto.provider.name}</TableCell>
        <TableCell>{caseDto.program.name}</TableCell>
        <TableCell>{caseDto.program.programType.name}</TableCell>
        <TableCell>{caseDto.location.name}</TableCell>
        <TableCell>
          <DateLabel date={caseDto.intakeDate} />
        </TableCell>
        <TableCell>
          <DateLabel date={caseDto.dischargedDate} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default CaseList;
