import { Box, Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import ExecuteAccessLevelWrapper from "../../../app/components/access-wrappers/ExecuteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import EditButton from "../../../app/components/buttons/EditButton";
import EditLinkButton from "../../../app/components/buttons/EditLinkButton";
import InfoTooltip from "../../../app/components/InfoTooltip";
import KeyValueRow from "../../../app/components/KeyValueRow";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import GreenAlertLabel from "../../../app/components/labels/GreenAlertLabel";
import PhoneNumberLabel from "../../../app/components/labels/PhoneNumberLabel";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import YesNoLabel from "../../../app/components/labels/YesNoLabel";
import LoadingDetail from "../../../app/components/loading/LoadingDetail";
import { ContentSection } from "../../../service-planning/components/ContentSection";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { SetUserRoleActiveDto } from "../dtos/SetUserRoleActiveDto";
import { useUserDetailPageContext } from "../pages/UserDetailPage";

const UserDetails = () => {
  const context = useUserDetailPageContext();
  const {
    user,
    canUnlockAccount,
    getUserRequestState: { isLoading },
    handleOpenViewPinClicked,
    handleUnlockAccount,
    handleEditClicked,
    handleEditEmailClicked,
  } = context;

  const { handleResendEmailConfirmation, handleCopyEmailConfirmationLink } =
    context.emailConfirmation;

  const { handleActivateRole, handleDeactivateRoleClicked } = context.roles;

  if (isLoading) return <LoadingDetail />;

  if (user === undefined) return <Fragment></Fragment>;
  return (
    <ContentSection
      label="Overview"
      content={
        <Box position="relative" width="100%">
          <Box display="flex" position="absolute" top="0" right="0">
            {user.accountIsLocked && canUnlockAccount && (
              <EditButton
                text="Unlock Account"
                title="Unlock Account"
                onClick={handleUnlockAccount}
              />
            )}
            <WriteAccessLevelWrapper name={PermissionResourceNameEnum.User}>
              {user.roles[0]?.provider.id === getProviderId() && (
                <>
                  {user.roles[0].isActive ? (
                    <DeleteButton
                      className="ml-2"
                      onClick={() =>
                        handleDeactivateRoleClicked(user.roles[0]!)
                      }
                      text="Deactivate"
                    />
                  ) : (
                    <EditButton
                      className="ml-2"
                      onClick={() =>
                        handleActivateRole(
                          new SetUserRoleActiveDto(user.roles[0]!)
                        )
                      }
                      text="Activate"
                    />
                  )}
                </>
              )}
              <EditButton
                title="Edit User"
                color="inherit"
                onClick={handleEditClicked}
              />
            </WriteAccessLevelWrapper>
          </Box>
          <Box marginTop="12px">
            {user.accountIsLocked && (
              <Fragment>
                <Box width="160px">
                  <RedAlertLabel label="Account Locked" />
                </Box>
              </Fragment>
            )}
            <Grid container>
              {/* First Name */}
              <KeyValueRow>
                <span>First Name</span>
                <span>{user.firstName}</span>
              </KeyValueRow>

              {/* Last Name */}
              <KeyValueRow>
                <span>Last Name</span>
                <span>{user.lastName}</span>
              </KeyValueRow>

              {/* Email */}
              <KeyValueRow>
                <span>Email</span>
                <Box display="flex" alignItems="center">
                  <div>{user.email}</div>
                  <EditLinkButton
                    onClick={handleEditEmailClicked}
                    className="ml-2"
                  >
                    Change
                  </EditLinkButton>
                </Box>
              </KeyValueRow>

              {/* Email Confirmed */}
              <KeyValueRow>
                <span>Email Confirmed</span>
                <Box display="flex" alignItems="center">
                  <YesNoLabel value={user.emailConfirmed} />
                  {!user.emailConfirmed && (
                    <ExecuteAccessLevelWrapper
                      name={PermissionResourceNameEnum.GetEmailConfirmationLink}
                    >
                      <EditLinkButton
                        onClick={handleResendEmailConfirmation}
                        className="ml-2 mr-2"
                      >
                        Resend Confirmation
                      </EditLinkButton>{" "}
                      |{" "}
                      <EditLinkButton
                        onClick={handleCopyEmailConfirmationLink}
                        className="ml-2"
                      >
                        Copy Link
                      </EditLinkButton>
                      <div className="ml-2 flex align-items-center">
                        <InfoTooltip title="These actions will invalidate the previously sent confirmation link." />
                      </div>
                    </ExecuteAccessLevelWrapper>
                  )}
                </Box>
              </KeyValueRow>

              {/* Phone Number */}
              {user.phoneNumber && (
                <KeyValueRow>
                  <span>Phone</span>
                  <PhoneNumberLabel phoneNumber={user.phoneNumber} />
                </KeyValueRow>
              )}

              {/* FN Roles */}
              {user.roles[0]?.provider.id === getProviderId() && (
                <KeyValueRow>
                  <span>{user.isFloridaNetworkUser ? "FN " : ""}Role</span>
                  <Box component="span" display="flex" alignItems="center">
                    {user.roles[0]?.name || ""}
                    <div className="ml-2">
                      {user.roles[0].isActive ? (
                        <GreenAlertLabel label="Active" />
                      ) : (
                        <RedAlertLabel label="Deactivated" />
                      )}
                    </div>
                  </Box>
                </KeyValueRow>
              )}

              {/* Last PIN Generated */}
              {user.lastPinGeneratedAt && (
                <ExecuteAccessLevelWrapper
                  name={PermissionResourceNameEnum.ViewUserPin}
                >
                  <KeyValueRow>
                    <span>Last PIN Generated At</span>
                    <Box display="flex" alignItems="center">
                      <DateTimeLabel date={user.lastPinGeneratedAt} />
                      <EditLinkButton
                        onClick={handleOpenViewPinClicked}
                        className="ml-2"
                      >
                        View PIN
                      </EditLinkButton>
                    </Box>
                  </KeyValueRow>
                </ExecuteAccessLevelWrapper>
              )}
            </Grid>
          </Box>
        </Box>
      }
    />
  );
};

export default UserDetails;
