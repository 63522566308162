import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import { useBedUtilizationReportPage } from "../hooks/useBedUtilizationReportPage";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useForm } from "react-hook-form";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { fiscalYearMonthOptions } from "../../../app/enums/MonthEnum";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { BedUtilizationReportParamsDto } from "../dtos/BedUtilizationReportParamsDto";
import { yupResolver } from "@hookform/resolvers/yup";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import { getProviderId } from "../../authentication/state/authenticationActions";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import Loader from "../../../app/components/Loader";

interface Props {
  fiscalYears: number[];
  getFiscalYearsRequestState: RequestStateDto;
  onSubmit(params: BedUtilizationReportParamsDto): void;
}

const BedUtilizationReportFilter: React.FC<Props> = ({
  fiscalYears,
  getFiscalYearsRequestState,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm({
    defaultValues: new BedUtilizationReportParamsDto(),
    resolver: yupResolver(BedUtilizationReportParamsDto.getSchema()),
    mode: "all",
  });
  const [availableMonthOptions, setAvailableMonthOptions] = useState<
    SelectOption[]
  >(fiscalYearMonthOptions);

  const { fiscalYear } = watch();

  useEffect(() => {
    if (fiscalYear === "") {
      setAvailableMonthOptions(fiscalYearMonthOptions);
      return;
    }

    setAvailableMonthOptions(fiscalYearMonthOptions);
  }, [fiscalYear, setAvailableMonthOptions]);

  return (
    <form
      onSubmit={handleSubmit((params) => {
        onSubmit(params);
      })}
    >
      <Grid container spacing={1}>
        <Grid item md={3} sm={6}>
          <ControlledSelect
            control={control}
            name="fiscalYear"
            fullWidth
            label="Fiscal Year"
            isLoading={getFiscalYearsRequestState.isLoading}
            options={fiscalYears.map((x) => ({
              value: x,
              label: `${x}-${x + 1}`,
            }))}
            size="small"
          />
        </Grid>
        <Grid item md={3} sm={6}>
          <ControlledSelect
            control={control}
            name="throughMonth"
            fullWidth
            label="Through Month"
            options={availableMonthOptions}
            size="small"
          />
        </Grid>
        <Grid item md={6}>
          <Box display="flex" justifyContent="end">
            <div>
              <SpinnerButton
                showSpinner={false}
                disabled={!isValid}
                content="Download"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

const BedUtilizationReportPage = () => {
  const {
    fiscalYears,
    getFiscalYearsRequestState,
    downloadReportRequestState,
    handleSearch,
  } = useBedUtilizationReportPage();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <BackButton route="/reports" />
      </div>
      <Header title="Bed Utilization Report" />
      <Box display="flex" justifyContent="space-between">
        <Typography>
          Select fiscal year/month and "Download" for an Excel report of bed
          utilization by Contract.
        </Typography>
        <ProviderAccessWrapper>
          <RouteableStyledLink
            pathname={`/provider/${getProviderId()}/filledBedDays`}
            text="Back To Bed Utilization Report"
          >
            View DCF Filled Bed Days
          </RouteableStyledLink>
        </ProviderAccessWrapper>
      </Box>
      <Paper
        style={{
          padding: "16px",
          marginTop: "16px",
          height: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <BedUtilizationReportFilter
          fiscalYears={fiscalYears}
          getFiscalYearsRequestState={getFiscalYearsRequestState}
          onSubmit={handleSearch}
        />

        <Box marginBottom="8px" marginTop="8px">
          <Divider />
        </Box>

        <Box textAlign="center">
          <Loader isLoading={downloadReportRequestState.isLoading} />
        </Box>
      </Paper>
    </div>
  );
};

export default BedUtilizationReportPage;
