import React, { Fragment, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Menu from "./menu/Menu";
import Theme from "./Theme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NotificationsContainer from "../../features/notifications/components/NotificationsContainer";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { createNotificationRoutes } from "../../features/notifications/NotificationRoutes";
import ErrorBoundary from "../../features/errors/ErrorBoundary";
import { createAuthenticationRoutes } from "../../features/authentication/AuthenticationRoutes";
import PrivateRoute from "../routes/PrivateRoute";
import { createProviderRoutes } from "../../features/provider/ProviderRoutes";
import { createUserRoutes } from "../../features/users/UserRoutes";
import { createAccountRoutes } from "../../features/account/AccountRoutes";
import { createYouthRoutes } from "../../features/youths/YouthRoutes";
import MenuLinks from "./menu/MenuLinks";
import SelectProviderDialog from "../../features/authentication/components/SelectProviderDialog";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectAuthenticationInitialzed } from "../../features/authentication/state/authenticationSelectors";
import { initializeAuthentication } from "../../features/authentication/state/authenticationActions";
import { createCaseRoutes } from "../../features/cases/CaseRoutes";
import { createReferralRoutes } from "../../features/referrals/ReferralRoutes";
import { createScreeningRoutes } from "../../features/screenings/ScreeningRoutes";
import { createAdminRoutes } from "../../features/admin/AdminRoutes";
import { createWaitlistRoutes } from "../../features/waitlists/WaitlistRoutes";
import { createInvoiceRoutes } from "../../features/invoices/InvoiceRoutes";
import { createSnapRoutes } from "../../features/snap/SnapRoutes";
import { createSnapInSchoolsRoutes } from "../../features/snap-in-schools/SnapInSchoolsRoutes";
import { createEventLogRoutes } from "../../features/event-log/EventLogRoutes";
import "./App.css";
import { createFollowUpRoutes } from "../../features/follow-ups/FollowUpRoutes";
import { createReportRoutes } from "../../features/reports/ReportRoutes";
import { createDashboardRoutes } from "../../features/dashboards/DashboardRoutes";
import AuthenticatedActivityWrapper from "./AuthenticatedActivityWrapper";
import { createOutreachActivityRoutes } from "../../features/outreach-activities/OutreachActivityRoutes";
import { Box } from "@material-ui/core";
import { createDjjEntryRoutes } from "../../features/djj-entries/DjjEntryRoutes";
import LandingPage from "../../features/landing-page/pages/LandingPage";
import {BrowserCompatibilityWarning} from "../../features/browser-compatibility/BrowserCompatibilityWarning";
export const containerHeight = "calc(100vh - 170px)";

const content = {
  content: {
    flex: 1,
    paddingTop: (process.env.REACT_APP_SHOW_ENV_BANNER === "true") ? "127px" : "100px",
  },
};
const useStyles = makeStyles(content);

function App() {
  //#region State
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const authenticationInitialzed = useAppSelector(
    selectAuthenticationInitialzed
  );

  //#endregion

  //#region Effects
  useEffect(() => {
    if (authenticationInitialzed === false) {
      const load = async () => {
        await dispatch(initializeAuthentication()).unwrap();
      };
      load();
    }
  }, [dispatch, authenticationInitialzed]);
  //#endregion

  if (authenticationInitialzed === false)
    return <Fragment>Initializing Authentication</Fragment>;

  return (
    <ThemeProvider theme={Theme}>
      <Router>
        <BrowserCompatibilityWarning />
        <AuthenticatedActivityWrapper />
        <SelectProviderDialog />
        <Box
          display="flex"
          flexDirection="column"
          maxHeight="100%"
          height="100%"
        >
          <Menu />
          <Box display="flex" flexDirection="column" height="calc(100% - 36px)">
            <MenuLinks />
            <ErrorBoundary>
              <NotificationsContainer />
              <Container maxWidth={"xl"} className={classes.content}>
                <Switch>
                  <PrivateRoute path="/" component={LandingPage} exact />
                  {createNotificationRoutes("/notifications")}
                  {createAuthenticationRoutes("/auth")}
                  {createProviderRoutes("/provider")}
                  {createUserRoutes("/users")}
                  {createAccountRoutes("/account")}
                  {createYouthRoutes("/youths")}
                  {createCaseRoutes("/cases")}
                  {createReferralRoutes("/referrals")}
                  {createScreeningRoutes("/screenings")}
                  {createAdminRoutes("/admin")}
                  {createWaitlistRoutes("/waitlists")}
                  {createInvoiceRoutes("/invoices")}
                  {createSnapRoutes("/snap-cycles")}
                  {createSnapInSchoolsRoutes("/snap-in-schools")}
                  {createEventLogRoutes("/event-log")}
                  {createFollowUpRoutes("/follow-ups")}
                  {createReportRoutes("/reports")}
                  {createDashboardRoutes("/dashboards")}
                  {createOutreachActivityRoutes("/outreach-activities")}
                  {createDjjEntryRoutes("/djj-related-data")}
                </Switch>
              </Container>
            </ErrorBoundary>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
