import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum DemographicsParentalStatusEnum {
  Yes = "Yes",
  No = "No",
  ExpectantMother = "ExpectantMother",
}

export const demographicsParentalStatusTextMap: { [key: string]: string } = {
  [DemographicsParentalStatusEnum.Yes]: "Yes",
  [DemographicsParentalStatusEnum.No]: "No",
  [DemographicsParentalStatusEnum.ExpectantMother]: "Expectant Mother",
};

export const demographicsParentalOptions: SelectOption[] = [
  { value: "", label: "Select Parental Status" },
  ...Object.keys(DemographicsParentalStatusEnum).map((status) => ({
    value: status,
    label: demographicsParentalStatusTextMap[status],
  })),
];
