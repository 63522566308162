import { coreAgent, invoicingAgent } from "../../app/api/agent";
import {
  DashboardFiltersDto,
  DashboardMetricDataResponseDto,
  DashboardMetricsRequestDto,
  DashboardValueMetricsDto,
  DateRangeRequestDto,
} from "./dtos/MainDashboardMetricsDto";
import { PaginationSearchParamsDto } from "../../app/dtos/PaginationSearchParamsDto";
import { QueryStringHelpers } from "../../app/helpers";

export interface ListDashboardsRequestDto {
  providerId: string;
  dateRange: DateRangeRequestDto;
}

export const getAvailableDashboardsRequest = (dto: ListDashboardsRequestDto) =>
  coreAgent.postRequest<{ id: string; title: string }[]>(`dashboards`, dto);

export const getMetricDataRequest = (data: {
  metricId: string;
  dto: DashboardFiltersDto & PaginationSearchParamsDto;
}) =>
  coreAgent.postRequest<DashboardMetricDataResponseDto>(
    `dashboards/drilldown`,
    {
      metricId: data.metricId,
      ...data.dto,
      sortDirection: QueryStringHelpers.convertSortDirection(
        data.dto.sortDirection || ""
      ),
    }
  );

export interface DashboardRequestDto {
  dashboard: string;
  dto: DashboardMetricsRequestDto;
}

export function getDashboardDataRequest(data: DashboardRequestDto) {
  return coreAgent.postRequest<
    DashboardValueMetricsDto<{
      [metricId in typeof data.dto.metricIds[number]]: any;
    }>
  >(`dashboards/metrics`, data.dto);
}

export function getDashboardDataRequest2(data: DashboardRequestDto) {
  return invoicingAgent.postRequest<
    DashboardValueMetricsDto<{
      [metricId in typeof data.dto.metricIds[number]]: any;
    }>
  >(`dashboards/metrics`, data.dto);
}
