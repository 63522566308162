import Box from "@material-ui/core/Box/Box";
import React, { createContext, useContext, useEffect, useState } from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import ErrorPage from "../../errors/pages/ErrorPage";
import { Paper } from "@material-ui/core";
import { createProviderProgramSection } from "../components/provider-detail-sections/ProviderProgramSection";
import { createProviderFiscalYearSection } from "../components/provider-detail-sections/ProviderFiscalYearSection";
import { createProviderStaffMemberSection } from "../components/provider-detail-sections/ProviderStaffMemberSection";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import {
  createProviderDetailsSection,
  providerDetailSectionName,
} from "../components/provider-detail-sections/ProviderDetailsSection";
import Header from "../../../app/components/Header";
import {
  ProviderDetailPageProps,
  useProviderDetailPage,
} from "../hooks/useProviderDetailPage";
import { createProviderLocationSection } from "../components/provider-detail-sections/ProviderLocationSection";

import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import { createProviderDcfFilledBedDaysSection } from "../components/provider-detail-sections/ProviderDcfFilledBedDaysSection";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";

const ProviderDetailPageContext = createContext<ProviderDetailPageProps>(
  undefined!
);

export const useProviderDetailPageContext = () =>
  useContext(ProviderDetailPageContext);

interface Props {
  defaultTab?: string;
}

const ProviderDetailPage: React.FC<Props> = ({ defaultTab }) => {
  //#region State
  const providerDetailPage = useProviderDetailPage();
  const {
    canViewPrograms,
    canViewLocations,
    canViewFiscalYears,
    canViewStaff,
    canViewFilledBedDays,
  } = providerDetailPage;
  const {
    provider,
    getProviderRequestState: { isLoading, error },
    isInitialized,
  } = providerDetailPage.provider;
  const { getProviderFiscalYearsRequestState } = providerDetailPage.fiscalYears;

  const [tabIndex, setTabIndex] = useState(
    defaultTab || providerDetailSectionName
  );
  //#endregion

  //#region Handlers

  const handleChangeTabIndex = (e: React.ChangeEvent<any>, index: string) =>
    setTabIndex(index);

  const [tabs, setTabs] = useState<TabProps[]>([]);
  //#endregion

  useEffect(() => {
    const tabs: TabProps[] = [createProviderDetailsSection()];
    if (canViewPrograms) tabs.push(createProviderProgramSection());

    if (canViewLocations) tabs.push(createProviderLocationSection());

    if (canViewFiscalYears) tabs.push(createProviderFiscalYearSection());

    if (canViewFilledBedDays)
      tabs.push(createProviderDcfFilledBedDaysSection());

    if (canViewStaff) tabs.push(createProviderStaffMemberSection());

    setTabs(tabs);
  }, [
    setTabs,
    canViewPrograms,
    canViewLocations,
    canViewFiscalYears,
    canViewFilledBedDays,
    canViewStaff,
  ]);

  useEffect(() => {
    if (defaultTab != null && tabs.some((x) => x.value === defaultTab)) {
      setTabIndex(defaultTab);
    }
  }, [defaultTab, setTabIndex, tabs]);

  if (isInitialized && error?.status === 404)
    return (
      <ErrorPage
        header={error.message}
        subheader={<BackButton route="/provider" />}
      />
    );

  return (
    <ProviderDetailPageContext.Provider value={providerDetailPage}>
      <Box height="100%" position="relative">
        <FnAccessWrapper>
          <Box marginBottom="12px">
            <BackButton route="/provider" text="Back to Providers" />
          </Box>
        </FnAccessWrapper>
        <ProviderAccessWrapper>
          <BackButton
            route="/provider"
            text="Back to Providers"
            hideIfNoPreviousLocation={true}
          />
        </ProviderAccessWrapper>
        <Header title={provider?.name} isLoading={isLoading} />
        <Paper
          variant="outlined"
          style={{
            marginTop: "16px",
            height: "calc(100% - 114px)",
          }}
        >
          <TabContainer
            index={tabIndex}
            onIndexChange={handleChangeTabIndex}
            tabs={tabs}
            disabled={isLoading || getProviderFiscalYearsRequestState.isLoading}
          />
        </Paper>
      </Box>
    </ProviderDetailPageContext.Provider>
  );
};

export default ProviderDetailPage;
