import { ErrorInfo } from "react";

export default interface ClientExceptionRequestDto {
  readonly message: string;
  readonly componentStack: string;
  readonly stack?: string;
}

export const createClientExceptionRequestDto = (
  error: Error,
  errorInfo: ErrorInfo
): ClientExceptionRequestDto => {
  const { message, stack } = error;
  const { componentStack } = errorInfo;
  return { message, componentStack, stack };
};
