import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import {
  ServicePlanParticipantFormDialog,
  servicePlanParticipantFormDialogId,
} from "./ServicePlanParticipantFormDialog";
import { ServicePlanParticipants } from "./ServicePlanParticipants";
import { useServicePlanPageContext } from "../ServicePlanPageContext";
import { ContentSection } from "./ContentSection";
import { ServicePlanCommonHeader } from "./ServicePlanCommonHeader";
import { useAppDispatch } from "../../app/hooks";
import { openModal, closeModal } from "../../features/modal/state/modalSlice";
import { servicePlanFormDialogId } from "./ServicePlanFormDialog";
import { ServicePlanParticipantDto } from "../dto/ServicePlanDto";
import { deleteServicePlanParticipantRequest } from "../ServicePlanRequests";
import { useRequest } from "../../app/hooks/useRequest";
import ConfirmDialog from "../../app/components/modals/ConfirmDialog";
import { ServicePlanReviewList } from "./ServicePlanReviewList";
import { PermissionResourceNameEnum } from "../../features/authentication/enums/PermissionResourceNameEnum";
import WriteAccessLevelWrapper from "../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditLinkButton from "../../app/components/buttons/EditLinkButton";

export const ServicePlanGeneralInfo: React.FC = () => {
  const dispatch = useAppDispatch();

  const [selectedParticipant, setSelectedParticipant] =
    useState<ServicePlanParticipantDto>();

  const editServicePlanClicked = () => {
    dispatch(openModal({ modalId: servicePlanFormDialogId }));
  };

  const {
    servicePlan,
    youth,
    participant: { onAddParticipantClicked },
    servicePlanUpdated,
    reviews: { onAddNewReviewClicked },
  } = useServicePlanPageContext();

  const [deleteParticipant] = useRequest<string, void>((participantId) =>
    deleteServicePlanParticipantRequest(servicePlan.id, participantId)
  );

  const onDeleteParticipantConfirmed = async () => {
    if (selectedParticipant?.id) {
      await deleteParticipant(selectedParticipant.id);
      await servicePlanUpdated();
      dispatch(closeModal());
    }
  };

  const onEditParticipantClicked = (
    selectedParticipant: ServicePlanParticipantDto
  ) => {
    setSelectedParticipant(selectedParticipant);
    dispatch(openModal({ modalId: servicePlanParticipantFormDialogId }));
  };

  const onDeleteParticipantClicked = (
    selectedParticipant: ServicePlanParticipantDto
  ) => {
    setSelectedParticipant(selectedParticipant);
    dispatch(openModal({ modalId: confirmDeleteParticipantModalId }));
  };

  const addClicked = async () => {
    setSelectedParticipant(undefined);
    await onAddParticipantClicked();
  };

  const confirmDeleteParticipantModalId = "confirm-delete-participant-modal";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContentSection
          label={"Overview"}
          content={<ServicePlanCommonHeader />}
          elevation={1}
          actions={
            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.ServicePlan}
            >
              <EditLinkButton onClick={editServicePlanClicked}>
                Edit Service Plan
              </EditLinkButton>
            </WriteAccessLevelWrapper>
          }
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <ConfirmDialog
          modalId={confirmDeleteParticipantModalId}
          onConfirm={onDeleteParticipantConfirmed}
          title={`Remove participant "${selectedParticipant?.name}"?`}
        />
        <ContentSection
          label={"Service Plan Participants"}
          elevation={1}
          actions={
            <>
              <WriteAccessLevelWrapper
                name={PermissionResourceNameEnum.ServicePlan}
              >
                <ServicePlanParticipantFormDialog dto={selectedParticipant} />
                <EditLinkButton onClick={addClicked}>
                  Add Participant
                </EditLinkButton>
              </WriteAccessLevelWrapper>
            </>
          }
          content={
            <ServicePlanParticipants
              servicePlan={servicePlan}
              youth={youth}
              onEditClicked={onEditParticipantClicked}
              onDeleteClicked={onDeleteParticipantClicked}
            />
          }
        />
      </Grid>

      <Grid item lg={12} xs={12}>
        <ContentSection
          label={"30 Day Review History"}
          actions={
            <>
              <WriteAccessLevelWrapper
                name={PermissionResourceNameEnum.ServicePlan}
              >
                <EditLinkButton onClick={onAddNewReviewClicked}>
                  Record 30 Day Review
                </EditLinkButton>
              </WriteAccessLevelWrapper>
            </>
          }
          content={<ServicePlanReviewList />}
          elevation={1}
        />
      </Grid>
    </Grid>
  );
};
