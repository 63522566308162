import { Grid } from "@material-ui/core";
import React from "react";
import { Control } from "react-hook-form";
import * as Yup from "yup";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { fnyfsName } from "../../authentication/constants";
import {
  ProgramTypeDto,
  programTypesToOptions,
} from "../../program-types/dtos/ProgramTypeDto";
import { ProgramFundingSourceEnum } from "../enums/ProgramFundingSourceEnum";
import {ContractDto, contractsToOptions} from "../../contracts/dtos/ContractDto";

export const programSchema = Yup.object().shape({
  name: Yup.string().label("Name").required().max(75),
  programTypeId: Yup.string().label("Program Type").required(),
  fundingSource: Yup.string()
    .label("Program Funding Source")
    .required()
    .test("valid-funding-source", "Invalid Funding Source", function (value) {
      return Object.keys(ProgramFundingSourceEnum).includes(value || "");
    }),
  contractId: Yup.string().label("Contract").when("fundingSource", {
    is: ProgramFundingSourceEnum.FloridaNetwork,
    then: Yup.string().required("A Contract is required for Florida Network Funded Programs.")
  })
});

const fundingTypeOptions: SelectOption[] = [
  { label: "Select Funding Source", value: "" },
  { label: fnyfsName, value: ProgramFundingSourceEnum.FloridaNetwork },
  { label: "Other", value: ProgramFundingSourceEnum.Other },
];

interface Props {
  control: Control<any>;
  disabled?: boolean;
  editMode: boolean;
  programTypes: ProgramTypeDto[];
  contracts: ContractDto[];
  isFloridaNetworkUser: boolean;
  selectedFundingSource: string;
  selectedProgramTypeId: string;
}

const ProgramFormFields: React.FC<Props> = ({
  control,
  disabled,
  editMode,
  programTypes,
  contracts,
  isFloridaNetworkUser,
  selectedFundingSource,
  selectedProgramTypeId,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledInput
          name="name"
          control={control}
          label="Name"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledSelect
          options={programTypesToOptions(programTypes)}
          control={control}
          name="programTypeId"
          label="Program Type"
          fullWidth
          disabled={disabled || editMode}
        />
      </Grid>
      {isFloridaNetworkUser && (
        <Grid item xs={12}>
          <ControlledSelect
            options={fundingTypeOptions}
            control={control}
            defaultValue={0}
            name="fundingSource"
            label="Funding Source"
            fullWidth
            disabled={disabled || editMode}
          />
        </Grid>
        )}
      {isFloridaNetworkUser && selectedProgramTypeId && selectedFundingSource === ProgramFundingSourceEnum.FloridaNetwork && (
        <Grid item xs={12}>
          <ControlledSelect
            options={contractsToOptions(contracts, selectedProgramTypeId)}
            control={control}
            defaultValue={0}
            name="contractId"
            label="Contract"
            fullWidth
            disabled={disabled || editMode}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ProgramFormFields;
