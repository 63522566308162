import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import {
  ProviderFilledBedDayDto,
  ProviderFilledBedDayFormDto,
} from "../dtos/ProviderFilledBedDayDto";
import { useProviderFilledBedDayForm } from "../hooks/useProviderFilledBedDayForm";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import { monthTextMap } from "../../../app/enums/MonthEnum";

export const providerFilledBedDayModalId =
  "provider-filled-bed-days-form-modal";

interface Props {
  requestState: RequestStateDto;
  dto?: ProviderFilledBedDayDto;
  month: number;
  fiscalYear: number;
  onSubmit(dto: ProviderFilledBedDayFormDto): Promise<any>;
}

const ProviderFilledBedDayFormDialog: React.FC<Props> = ({
  requestState,
  dto,
  month,
  fiscalYear,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useProviderFilledBedDayForm(
    onSubmit,
    month,
    fiscalYear,
    dto
  );
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };

  //#endregion

  return (
    <Dialog
      open={modalId === providerFilledBedDayModalId}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {monthTextMap[month]} {fiscalYear}-{fiscalYear + 1} DCF Filled Bed
          Days
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledWholeNumberInput
                control={control}
                name="filledDays"
                label="Filled Bed Days*"
                fullWidth
                multiline
                minRows={2}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Submit"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProviderFilledBedDayFormDialog;
