import { ButtonProps } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import React, {useCallback, useState} from "react";

interface Props extends ButtonProps {
  content: React.ReactNode;
  showSpinner: boolean;
}

const SpinnerButton: React.FC<Props> = ({
  content,
  showSpinner,
  ...props
}) => {
  const { size,
    disabled,
    onClick,
    ...otherProps
  } = props;

  const [executing, setExecuting] = useState(false);

  const onRealClick = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setExecuting(true);
    try {
      if(onClick != null)
        await onClick(event);
    } finally {
      setExecuting(false);
    }
  }, [onClick]);


  const getSpinnerSize = () => {
    switch (size) {
      case "small":
        return "22px";
      case "medium":
        return "24px";
      default:
        return "26px";
    }
  };

  return (
    <Button
      onClick={onRealClick}
      disabled={executing || disabled || showSpinner}
      {...otherProps}
    >
      {showSpinner ? (
        <Box
          display="flex"
          zIndex="1"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress
            style={{
              height: getSpinnerSize(),
              width: getSpinnerSize(),
            }}
          />
        </Box>
      ) : (
        content
      )}
    </Button>
  );
};

export default SpinnerButton;
