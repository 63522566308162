import { formatUrl } from "../../app/helpers";
import PrivateRoute from "../../app/routes/PrivateRoute";
import AdminPage from "./pages/AdminPage";

export const createAdminRoutes = (baseUrl: string) => [
  <PrivateRoute
    path={formatUrl(baseUrl, "")}
    component={AdminPage}
    key="admin"
    exact
  />,
];
