import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import OutreachActivityDetailPage from "./pages/OutreachActivityDetailPage";
import OutreachActivityListPage from "./pages/OutreachActivityListPage";

export const createOutreachActivityRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.OutreachActivities}
    path={baseUrl}
    component={OutreachActivityListPage}
    exact
    key="outreach-activity-list"
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.OutreachActivities}
    path={formatUrl(baseUrl, ":id")}
    component={OutreachActivityDetailPage}
    exact
    key="outreach-activity-detail"
  />,
];
