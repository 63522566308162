import { Box } from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../../app/components/buttons/AddButton";
import { TabProps } from "../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import ProgramLocationFormDialog from "../../../locations/components/ProgramLocationFormDialog";
import TerminateProgramLocationFormDialog from "../../../locations/components/TerminateProgramLocationFormDialog";
import ProgramFormDialog from "../../../programs/components/ProgramFormDialog";
import { useProviderDetailPageContext } from "../../pages/ProviderDetailPage";
import ProviderPrograms from "../provider-program/ProviderPrograms";

export const providerProgramSectionName = "provider-program-section";

const ProviderProgramSection: React.FC = () => {
  const context = useProviderDetailPageContext();
  const {
    provider,
    getProviderRequestState: { isLoading },
  } = context.provider;
  const {
    selectedProgramLocation,
    selectedProgram,
    upsertProgramRequestState,
    terminateProgramLocationRequestState,
    createProgramLocationExistingRequestState,
    createProgramLocationNewRequestState,
    updateProgramLocationRequestState,
    handleUpsertProgram,
    handleTerminateProgramLocation,
    handleCreateProgramClicked,
    handleEditProgramLocationClicked,
    handleEditProgramClicked,
    handleCreateProgramLocationClicked,
    handleTerminateProgramLocationClicked,
    handleUpsertProgramLocation,
  } = context.programs;

  return (
    <Fragment>
      <ProgramLocationFormDialog
        onSubmit={handleUpsertProgramLocation}
        locations={provider?.locations!}
        program={selectedProgram!}
        createExistingRequestState={createProgramLocationExistingRequestState}
        createNewRequestState={createProgramLocationNewRequestState}
        updateProgramLocationRequestState={updateProgramLocationRequestState}
        programLocation={selectedProgramLocation}
      />
      <ProgramFormDialog
        onSubmit={handleUpsertProgram}
        program={selectedProgram}
        requestState={upsertProgramRequestState}
      />
      <TerminateProgramLocationFormDialog
        onSubmit={handleTerminateProgramLocation}
        requestState={terminateProgramLocationRequestState}
        terminatedOn={selectedProgramLocation?.terminatedOn}
      />
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Program}>
        <AddButton
          text="Add Program"
          onClick={handleCreateProgramClicked}
          variant="contained"
          color="primary"
        />
      </WriteAccessLevelWrapper>
      <Box marginTop="16px">
        <ProviderPrograms
          isLoading={isLoading}
          provider={provider!}
          onEdit={handleEditProgramClicked}
          onCreateLocationClick={handleCreateProgramLocationClicked}
          onEditLocationClick={handleEditProgramLocationClicked}
          selectedProgram={selectedProgram}
          onTerminateProgramLocationClick={
            handleTerminateProgramLocationClicked
          }
        />
      </Box>
    </Fragment>
  );
};

export default ProviderProgramSection;
export const createProviderProgramSection = (): TabProps => ({
  label: "Programs",
  value: providerProgramSectionName,
  content: <ProviderProgramSection />,
});
