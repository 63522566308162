import * as Yup from "yup";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";

export class MonthlyDeliverablesReportParamsDto {
  providerId?: string = "";
  compareToProviderId?: string = "";
  month: string = "";
  year: string = "";

  constructor() {
    const providerId = getProviderId();

    if (providerId != null && providerId !== fnyfsProviderId)
      this.providerId = providerId;
  }

  public static getSchema() {
    return Yup.object().shape({
      year: Yup.string().min(4).max(4).label("Year").required(),
      month: Yup.string().required("Month"),
    });
  }
}
