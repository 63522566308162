import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { WaitlistStatusEnum } from "../enums/WaitlistStatusEnum";
import { WaitlistFilterFormDto } from "./WaitlistFilterFormDto";
export class WaitlistSearchParamsDto implements PaginationSearchParamsDto {
  programId?: string;
  locationId?: string;
  providerId?: string;
  programTypeId?: string;
  youthFirstName?: string;
  youthLastName?: string;
  screeningNumber?: string;
  status?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: WaitlistFilterFormDto;
    previous?: WaitlistSearchParamsDto;
  }) {
    this.programTypeId =
      form?.programTypeId || (query?.programTypeId as string) || "";
    this.programId = form?.programId || (query?.programId as string) || "";
    this.locationId = form?.locationId || (query?.locationId as string) || "";
    this.providerId = form?.providerId || (query?.providerId as string) || "";
    this.youthFirstName =
      form?.youthFirstName || (query?.youthFirstName as string) || "";
    this.youthLastName =
      form?.youthLastName || (query?.youthLastName as string) || "";
    this.screeningNumber =
      form?.screeningNumber || (query?.screeningNumber as string) || "";

    this.status =
      form?.status ||
      (query?.status as string) ||
      (form === undefined ? WaitlistStatusEnum.Pending : "");
    this.sortBy =
      previous?.sortBy || (query?.sortBy as string) || "screeningNumber";
    this.sortDirection =
      previous?.sortDirection || QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
  }
  public static toQueryString(dto: WaitlistSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
