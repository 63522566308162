import { CinsFinsEligibilityCriterionDto } from "../../cins-fins-eligibility-criteria/dtos/CinsFinsEligibilityCriterionDto";
import { datetimeToFormString } from "../../../app/helpers";
import { ScreeningPresentingProblemFormDto } from "../../screening-presenting-problems/dtos/ScreeningPresentingProblemFormDto";
import {
  createReferrerFormDto,
  ReferrerFormDto,
} from "../../referrals/dtos/ReferrerFormDto";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { YouthDto } from "../../youths/dto/YouthDto";
import { YouthSummaryDto } from "../../youths/dto/YouthSummaryDto";
import { ReferralDto } from "../../referrals/dtos/ReferralDto";
import { ScreeningDetailDto } from "./ScreeningDetailDto";
import { heardAboutTextMap } from "../enums/HeardAboutEnum";

export interface ScreeningFormDto {
  zipCode: string;
  screeningAt: string;
  screeningPresentingProblems?: ScreeningPresentingProblemFormDto[];
  screeningPresentingProblemsText: string[];
  problemDescription: string;
  comments: string;
  eligibleCriteriaOptions: { [key: string]: boolean };
  eligibleCriteria?: string[];
  turnedAway?: string;
  isCinsFinsEligible: boolean | string;
  plannedAction: string;
  completedByStaffId: string;
  referredBy: ReferrerFormDto;
  heardAboutUsOption: string;
  heardAboutUs?: string;
  providerId: string;
  isDvRespite: boolean;
  livingSituation: string;
  programId: string;
  locationId: string;
  programLocationId?: string;
  createWaitlist: boolean;
  referralId?: string;
  editMode: boolean;
}

const criteriaToOptions = (
  cinsFinsEligibilityCriteria: CinsFinsEligibilityCriterionDto[],
  currentEligiblity?: CinsFinsEligibilityCriterionDto[]
) => {
  return Object.values(cinsFinsEligibilityCriteria).reduce<{
    [key: string]: boolean;
  }>((values, currentValue) => {
    values[currentValue.id] =
      currentEligiblity?.find((x) => x.id === currentValue.id) != null || false;
    return values;
  }, {});
};

const verifyNumber = (value?: string) => {
  if (value == null || value === "") return;

  if (isNaN(+value)) return;

  return value;
};

export const createScreeningFormDto = (
  cinsFinsEligibilityCriteria: CinsFinsEligibilityCriterionDto[],
  youth?: YouthDto | YouthSummaryDto,
  referral?: ReferralDto,
  screening?: ScreeningDetailDto
): ScreeningFormDto => {
  let youthZipCode: string | undefined =
    (youth as any)?.zipCode || (youth as any)?.address?.zipCode;

  if (screening) {
    const {
      zipCode,
      screeningAt,
      screeningPresentingProblems,
      problemDescription,
      comments,
      cinsFinsEligibilityCriteria: currentEligiblity,
      turnedAway,
      isCinsFinsEligible,
      plannedAction,
      completedByStaff,
      provider,
      isDvRespite,
      livingSituation,
      referredBy,
      heardAboutUs,
    } = screening;

    return {
      zipCode: verifyNumber(zipCode) || verifyNumber(youthZipCode) || "",
      screeningAt: datetimeToFormString(new Date(screeningAt)),
      referredBy: createReferrerFormDto(referredBy),
      screeningPresentingProblemsText: [
        ...screeningPresentingProblems.map((x) => x.problem),
      ],
      problemDescription: problemDescription || "",
      comments: comments || "",
      eligibleCriteriaOptions: criteriaToOptions(
        cinsFinsEligibilityCriteria,
        currentEligiblity
      ),
      turnedAway: turnedAway || "",
      isCinsFinsEligible: isCinsFinsEligible ? "true" : "false" || "",
      plannedAction: plannedAction || "",
      completedByStaffId: completedByStaff.id || "",
      heardAboutUsOption:
        Object.entries(heardAboutTextMap).find(
          ([, value]) => value === heardAboutUs
        )?.[0] || "Other",
      heardAboutUs: Object.values(heardAboutTextMap).find(
        (x) => x === heardAboutUs
      )
        ? ""
        : heardAboutUs, // ?
      providerId: provider.id || "",
      isDvRespite,
      livingSituation: livingSituation || "",
      programId: "",
      locationId: "",
      createWaitlist: false,
      referralId: referral?.id,
      editMode: true,
    };
  }

  return {
    zipCode: verifyNumber(youthZipCode) || "",
    screeningAt: "",
    referredBy: createReferrerFormDto(referral?.referredBy),
    screeningPresentingProblemsText: [],
    problemDescription: "",
    comments: "",
    eligibleCriteriaOptions: criteriaToOptions(cinsFinsEligibilityCriteria),
    turnedAway: "",
    isCinsFinsEligible: "",
    plannedAction: "",
    completedByStaffId: "",
    heardAboutUsOption: "",
    heardAboutUs: "",
    providerId: getProviderId() || "",
    isDvRespite: false,
    livingSituation: "",
    programId: "",
    locationId: "",
    createWaitlist: false,
    referralId: referral?.id,
    editMode: false,
  };
};
