import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../../app/components/ErrorText";
import ConfirmDialog from "../../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { NetmisUserRoleDto } from "../../../roles/dtos/NetmisUserRoleDto";
import { RemoveProviderRoleFormDto } from "../../dtos/RemoveProviderRoleFormDto";

interface Props {
  onConfirm(dto: RemoveProviderRoleFormDto): void;
  role?: NetmisUserRoleDto;
  requestState: RequestStateDto;
}

export const confirmRemoveRoleDialogId = "remove-role-dialog";
const ConfirmRemoveRoleDialog: React.FC<Props> = ({
  onConfirm,
  role,
  requestState,
}) => {
  const { error, isLoading } = requestState;
  return (
    <ConfirmDialog
      title="Confirm Remove"
      onConfirm={() => {
        onConfirm(new RemoveProviderRoleFormDto(role));
      }}
      modalId={confirmRemoveRoleDialogId}
      isLoading={isLoading}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to remove the role of "{role?.name}" for provider
        "{role?.provider.name}"?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmRemoveRoleDialog;
