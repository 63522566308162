import { Box, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";

import { ReportingPeriodDto } from "../../reporting-periods/dto/ReportingPeriodDto";
import InvoiceListActions from "./InvoiceListActions";
import { createProviderInvoiceYearListTab } from "./ProviderInvoiceYearList";
import {
  useGetCurrentReportingPeriod,
  useGetPreviousReportingPeriod
} from "../../reporting-periods/hooks/reportingPeriodHooks";
import {useRequest} from "../../../app/hooks/useRequest";
import {getContractsRequest} from "../../contracts/ContractRequests";
import {getPendingInvoicesRequest} from "../InvoiceRequests";
import {getProviderId} from "../../authentication/state/authenticationActions";
import {fnyfsProviderId} from "../../authentication/constants";
import {useHistory} from "react-router-dom";
import {useGetInvoiceYears} from "../../invoices/hooks/invoicingHooks";
import {DateTime} from "luxon";
import {useCombinedErrorState, useCombinedLoadingState} from "../../../app/hooks/useCombinedRequests";
import ErrorText from "../../../app/components/ErrorText";

const useProviderInvoiceListPage = () => {

  const [
    getCurrentReportingPeriod,
    currentReportingPeriod,
    getCurrentReportingPeriodRequestState,
  ] = useGetCurrentReportingPeriod();

  const [
    getPreviousReportingPeriod,
    previousReportingPeriod,
    getPreviousReportingPeriodRequestState,
  ] = useGetPreviousReportingPeriod();

  const [getContracts, contracts, getContractsRequestState] = useRequest(getContractsRequest);

  const [getPendingInvoices, pendingInvoices, getPendingInvoicesRequestState] = useRequest(getPendingInvoicesRequest);

  const providerId = getProviderId();
  const history = useHistory();

  const [getInvoiceYears, invoiceYears] = useGetInvoiceYears();
  
  const [years, setYears] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>(DateTime.now().year.toString());

  useEffect(() => {
    if(invoiceYears) {
      const sortedYears = invoiceYears.sort((a,b) => a > b ? -1 : 1);
      setYears(sortedYears)
      setSelectedYear(sortedYears[0].toString())
    }
  }, [setYears, invoiceYears])

  const load = useCallback(
    async (providerId) => {
      getInvoiceYears(null)
      getPreviousReportingPeriod(null);
      getCurrentReportingPeriod(null)
      getContracts(null)
      getPendingInvoices(providerId)
    },
    [getInvoiceYears, getPreviousReportingPeriod, getCurrentReportingPeriod, getContracts, getPendingInvoices]
  );

  const handlePreviewInvoiceClicked = useCallback(
    (
      contractId: string,
      reportingPeriod: ReportingPeriodDto,
      isStatewide: boolean = false
    ) => {
      const queryStrings = [`contractId=${contractId}`];
      const providerId = getProviderId();
      if (providerId !== fnyfsProviderId)
        queryStrings.push(`providerId=${providerId}`);

      if (isStatewide) queryStrings.push(`statewide=true`);

      queryStrings.push(`reportingPeriodId=${reportingPeriod.id}`);

      history.push(
        `/invoices/preview${isStatewide ? "/fnyfs" : ""}?&${queryStrings.join(
          "&"
        )}`
      );
    },
    [history]
  );

  useEffect(() => {
    load(providerId)
  }, [providerId, load]);

  const error = useCombinedErrorState([
    getCurrentReportingPeriodRequestState,
    getPreviousReportingPeriodRequestState,
    getContractsRequestState,
    getPendingInvoicesRequestState
  ])

  const isLoading = useCombinedLoadingState([
    getCurrentReportingPeriodRequestState,
    getPreviousReportingPeriodRequestState,
    getContractsRequestState,
    getPendingInvoicesRequestState
  ])

  const handleYearSelectionChange = (e: React.ChangeEvent<any>, index: string) => {
    setSelectedYear(index);
  };

  return {
    currentReportingPeriod,
    previousReportingPeriod,
    years,
    selectedYear,
    contracts,
    onPreviewInvoiceClick: handlePreviewInvoiceClicked,
    handleYearSelectionChange,
    pendingInvoices,
    isLoading,
    error,
  }
}

const ProviderInvoiceList: React.FC = () => {

  const {
    currentReportingPeriod,
    previousReportingPeriod,
    years,
    selectedYear,
    contracts,
    onPreviewInvoiceClick,
    handleYearSelectionChange,
    pendingInvoices,
    error,
    isLoading
  } = useProviderInvoiceListPage();

  const yearTabs = years.map(year => {
      const props = {
        year,
        previousReportingPeriod,
      };
      return createProviderInvoiceYearListTab(props, year.toString())
  });
useEffect(() => console.log(isLoading), [isLoading])
  return (
    <Box height={"70vh"}>
      { error && error.map(e => <ErrorText error={e} />) }
      <WriteAccessLevelWrapper
        name={PermissionResourceNameEnum.ProviderInvoice}
      >
        <Box display={"flex"} justifyContent={"flex-end"} marginBottom={"8px"}>
          {pendingInvoices && currentReportingPeriod && previousReportingPeriod && <InvoiceListActions
            contracts={contracts || []}
            pendingInvoices={pendingInvoices}
            currentReportingPeriod={currentReportingPeriod}
            previousReportingPeriod={previousReportingPeriod}
            onPreviewInvoice={onPreviewInvoiceClick}
          />}
        </Box>
      </WriteAccessLevelWrapper>
      <TabContainer
        tabs={yearTabs}
        onIndexChange={handleYearSelectionChange}
        index={selectedYear}
        orientation="vertical"
        disabled={isLoading}
        padding={0}
      />
    </Box>
  );
};

export const createProviderInvoiceListTab = (): TabProps => ({
  value: "provider-invoices",
  label: <Typography>Provider Invoices</Typography>,
  content: <ProviderInvoiceList />,
});

export default ProviderInvoiceList;
