import {
    Box,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import React, { Fragment } from "react";
import InfoTooltip from "../../../app/components/InfoTooltip";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import SortableTable, {
    ColumnProps,
    SortableTableProps,
} from "../../../app/components/SortableTable";
import DateLabel from "../../../app/components/labels/DateLabel";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import WaitlistStatusLabel from "../../waitlists/components/WaitlistStatusLabel";
import { ScreeningListDto } from "../dtos/ScreeningListDto";
import { ScreeningSearchParamsDto } from "../dtos/ScreeningSearchParamsDto";
import { WaitlistStatusEnum, waitlistStatusTextMap } from "../../waitlists/enums/WaitlistStatusEnum";
import ProviderWriteAccessLevelWrapper from "../../../app/components/access-wrappers/ProviderWriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { useHistory, useLocation } from "react-router-dom";
import { WaitlistDto } from "../../waitlists/dtos/WaitlistDto";

  const formatDate = (date: Date) =>
    DateTime.fromJSDate(new Date(date)).toLocaleString();

  const ScreeningList: React.FC<
    SortableTableProps<ScreeningListDto, ScreeningSearchParamsDto>
  > = (props) => {
    const columns: ColumnProps[] = [
      { name: "referenceNumber", label: "Screening #" },
      { name: "youth", label: "Youth" },
      { name: "screeningAt", label: "Screening Date" },
      { name: "provider", label: "Provider" },
      { name: "waitlist", label: "Wait List", align: "center" },
      { name: "waitlistAction", label: "" }
    ];
  
    const createTooltipRow = (label: string, value: React.ReactNode, justify?: string) => {
      return (
        <Box display="flex" justifyContent={justify || "space-between"}>
          <Typography
            style={{
              marginRight: "8px",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {label}:
          </Typography>
          <Typography>{value}</Typography>
        </Box>
      );
    };

    const getWaitlistStatusText = (waitList: WaitlistDto) => {
      let result = waitlistStatusTextMap[waitList.status];

      if (waitList.status === WaitlistStatusEnum.CaseCreated)
        result = `${result} (${waitList.case?.caseNumber})`;

      return result;
    }
  
    const history = useHistory();
    const location = useLocation();

    const renderRow = (screeningDto: ScreeningListDto) => {
     
      return (
        <TableRow key={screeningDto.id}>
          <TableCell>
            <Box
              display={"flex"}
              alignItems={"center"}
              
            >
              <RouteableStyledLink
                pathname={`/screenings/${screeningDto.id}`}
                text="Back to Screenings"
              >
                {screeningDto.referenceNumber.split("-")[0]}
              </RouteableStyledLink>
            
              <Box paddingLeft={1}>
                <RedAlertLabel 
                    hide={!screeningDto.isDeleted} 
                    variant="outlined" 
                    label="Deleted" />          
              </Box>
            </Box>
          </TableCell>

          <TableCell>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <RouteableStyledLink
                pathname={`/youths/${screeningDto.youth?.id}`}
                text="Back to Screenings"
              >
                {screeningDto.youth.firstName} {screeningDto.youth.lastName}
              </RouteableStyledLink>
              <span style={{ marginLeft: "4px" }}>
                <InfoTooltip
                  title={
                    <Fragment>
                      {createTooltipRow("Youth ID", screeningDto.youth.referenceNumber)}
                      {screeningDto.youth.djjIdNumber &&
                        createTooltipRow("DJJID", screeningDto.youth.djjIdNumber)}
                    </Fragment>
                  }
                />
              </span>
            </Box>
          </TableCell>

          <TableCell>
            <DateLabel date={screeningDto.screeningAt} />
          </TableCell>

          <TableCell>{screeningDto.provider.name}</TableCell>
         
          <TableCell align="center">
            {screeningDto.waitList == null 
                ? "N/A" 
                : <Tooltip
                    placement="left"
                    title={
                        <Fragment>
                            {createTooltipRow("Status", getWaitlistStatusText(screeningDto.waitList), "left")}
                            {createTooltipRow("Program", screeningDto.waitList.program.name, "left")}
                            {createTooltipRow("Location", screeningDto.waitList.location.name, "left")}
                            {createTooltipRow("Waitlisted On", formatDate(screeningDto.waitList.createdAt), "left")}
                        </Fragment>}
                    enterTouchDelay={200}
                  >
                    <div>
                      <WaitlistStatusLabel 
                        waitlist={screeningDto.waitList} 
                        hideTooltip={true}
                      />
                    </div>
                  </Tooltip>                                    
            }
          </TableCell>          
          
          <TableCell>
            <ProviderWriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
                {screeningDto.waitList?.status === WaitlistStatusEnum.Pending && (
                  <RouteableStyledLink
                    pathname={location.pathname}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.preventDefault();
                        history.push(
                          `/youths/${screeningDto.waitList?.youth.id}/cases/new?screeningId=${screeningDto.id}&programId=${screeningDto.waitList.program.id}&locationId=${screeningDto.waitList.location.id}`,
                          {
                            prevLocation: location.pathname,
                            text: "Back to Screenings",
                          }
                        );
                      }}
                  text="Back to Screenings"
                >
                  Start Case
                </RouteableStyledLink>
                )}
            </ProviderWriteAccessLevelWrapper>
          </TableCell>
        </TableRow>
      );
    };
  
    return (
      <TableContainer component={Paper}>
        <SortableTable {...props} columns={columns} renderRow={renderRow} />
      </TableContainer>
    );
  };
  
  export default ScreeningList;
  