import { SelectProps } from "@material-ui/core";
import { Control } from "react-hook-form";
import ControlledSelect, { SelectOption } from "./ControlledSelect";

const yesNoOptions: SelectOption[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

interface Props extends SelectProps {
  control: Control<any>;
  name: string;
  label: string;
  selectLabel: string;
}

const ControlledYesNoSelect: React.FC<Props> = ({ selectLabel, ...props }) => {
  return (
    <ControlledSelect
      options={[{ value: "", label: selectLabel }, ...yesNoOptions]}
      {...props}
    />
  );
};

export default ControlledYesNoSelect;
