import * as Yup from "yup";

export class BedUtilizationReportParamsDto {
  throughMonth: string = "";
  fiscalYear: string = "";

  public static getSchema() {
    return Yup.object().shape({
      fiscalYear: Yup.string().min(4).max(4).label("Year").required(),
      throughMonth: Yup.string().required("Month"),
    });
  }
}
