import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { createAgent } from "../api/agent";
import { useRequest } from "../hooks/useRequest";
import axios from "axios";

const jokeClient = axios.create({
  baseURL: "https://icanhazdadjoke.com/",
  withCredentials: false,
  headers: {
    Accept: "application/json",
  },
  responseType: "json",
});
const jokeAgent = createAgent("https://icanhazdadjoke.com/", jokeClient, false);

const jokeRequest = () => jokeAgent.getRequest<any>("", {});

export const ComingSoon: React.FC = () => {
  const [getJoke, joke, getJokeRequestState] = useRequest(jokeRequest, false);

  useEffect(() => {
    getJoke(null);
  }, [getJoke]);

  return (
    <Box
      flex={1}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Typography>
        This feature is coming soon, but here is a joke while you wait!
      </Typography>
      <Box
        style={{ fontStyle: "italic", padding: 16 }}
        flex={1}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {!getJokeRequestState.isLoading && (
          <Typography>{joke?.joke}</Typography>
        )}
      </Box>
    </Box>
  );
};
