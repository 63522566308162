import { useRequest } from "../../../app/hooks/useRequest";
import {
  createBakerActRequest,
  deleteBakerActRequest,
  getCaseBakerActsRequest,
  updateBakerActRequest,
} from "../BakerActRequests";

export const useCreateCaseBakerAct = () => useRequest(createBakerActRequest);
export const useUpdateBakerAct = () => useRequest(updateBakerActRequest);
export const useDeleteBakerAct = () => useRequest(deleteBakerActRequest);
export const useGetCaseBakerActs = () => useRequest(getCaseBakerActsRequest);
