import { useRequest } from "../../../app/hooks/useRequest";
import { YouthFormDto } from "../dto/YouthFormDto";
import {
  createYouthCaseRequest,
  createYouthContactRequest,
  createYouthRequest,
  createYouthScreeningRequest, deleteYouthRequest,
  getYouthAddressRequest,
  getYouthCasesRequest,
  getYouthRequest,
  getYouthScreeningsRequest,
  getYouthsRequest,
  setYouthDjjIdNumberRequest,
  updateYouthAddressRequest,
  updateYouthRequest,
} from "../YouthRequests";
import { ScreeningDto } from "../../screenings/dtos/ScreeningDto";
import { useCallback, useEffect, useState } from "react";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { calculateDateDifferenceInDays } from "../../../app/helpers";

export const useGetYouth = () => useRequest(getYouthRequest);

export const useGetYouths = () => useRequest(getYouthsRequest);

export const useCreateYouth = () =>
  useRequest((dto: YouthFormDto) => createYouthRequest(dto));

export const useUpdateYouth = () => useRequest(updateYouthRequest);

export const useDeleteYouth = () => useRequest(deleteYouthRequest);

export const useCreateYouthCase = () => useRequest(createYouthCaseRequest);

export const useCreateYouthScreening = () =>
  useRequest(createYouthScreeningRequest);

export const useGetRecentYouthScreenings: (
  id: string,
  currentCaseScreening?: string
) => [
  (intakeDate?: Date | string, startFromScreening?: boolean) => void,
  ScreeningDto[],
  RequestStateDto,
  () => void
] = (id: string, currentCaseScreening?: string) => {
  const [getScreenings, screenings, requestState, clearError] = useRequest(
    getYouthScreeningsRequest,
    true
  );
  const [validScreenings, setValidScreenings] = useState<ScreeningDto[]>([]);

  const getValidScreenings = useCallback(
    async (intakeDate?: Date | string, startFromScreening?: boolean) => {
      if (screenings == null) {
        setValidScreenings([]);
        return;
      }
      if (startFromScreening) {
        setValidScreenings(screenings);
      } else if (intakeDate) {
        const recentOrExistingScreeningFilter = (screening: ScreeningDto) =>
          screening.id === currentCaseScreening ||
          calculateDateDifferenceInDays(intakeDate, screening.screeningAt) <=
          60;

        setValidScreenings(screenings.filter(recentOrExistingScreeningFilter));
      } else {
        setValidScreenings(screenings || []);
      }
    },
    [setValidScreenings, screenings, currentCaseScreening]
  );

  useEffect(() => {
    if (id) getScreenings(id);
  }, [id, getScreenings]);

  return [getValidScreenings, validScreenings, requestState, clearError];
};

export const useSetYouthDjjIdNumber = () =>
  useRequest(setYouthDjjIdNumberRequest);

export const useCreateYouthContact = () =>
  useRequest(createYouthContactRequest);

export const useGetYouthCases = () => useRequest(getYouthCasesRequest);

export const useGetYouthAddress = () => useRequest(getYouthAddressRequest);

export const useUpdateYouthAddress = () =>
  useRequest(updateYouthAddressRequest);
