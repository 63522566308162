import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { CaseDto } from "../cases/dtos/CaseDto";
import { CaseFormDto } from "../cases/dtos/CaseFormDto";
import { ScreeningDto } from "../screenings/dtos/ScreeningDto";
import { ScreeningFormDto } from "../screenings/dtos/ScreeningFormDto";
import { YouthContactFormDto } from "../youth-contacts/dtos/YouthContactFormDto";
import { YouthContactSummaryDto } from "../youth-contacts/dtos/YouthContactSummaryDto";
import { SetYouthDjjIdNumberFormDto } from "./dto/SetYouthDjjIdNumberFormDto";
import { YouthAddressDto, YouthDto } from "./dto/YouthDto";
import { YouthAddressFormDto, YouthFormDto } from "./dto/YouthFormDto";
import { YouthSearchParamsDto } from "./dto/YouthSearchParamsDto";
import { YouthSummaryDto } from "./dto/YouthSummaryDto";

interface UpdateYouthRequestParams {
  id: string;
  dto: YouthFormDto;
}

interface CreateYouthCaseRequestParams {
  id: string;
  dto: CaseFormDto;
  force?: boolean;
}

interface CreateYouthScreeningRequestParams {
  id: string;
  dto: ScreeningFormDto;
}

interface SetYouthDjjIdNumberRequestParams {
  id: string;
  dto: SetYouthDjjIdNumberFormDto;
}

interface CreateYouthContactRequestParams {
  id: string;
  dto: YouthContactFormDto;
}

export const createYouthRequest = (dto: YouthFormDto) =>
  coreAgent.postRequest<YouthDto>("youths", dto);

export const getYouthRequest = (id: string) =>
  coreAgent.getRequest<YouthDto>(`youths/${id}`);

export const updateYouthRequest = ({ id, dto }: UpdateYouthRequestParams) =>
  coreAgent.putRequest<YouthDto>(`youths/${id}`, dto);

export const getYouthsRequest = (dto: YouthSearchParamsDto) =>
  coreAgent.getRequest<PaginationResultDto<YouthSummaryDto>>(
    `youths?${YouthSearchParamsDto.toQueryString(dto)}`
  );

export const createYouthCaseRequest = ({
  id,
  dto,
  force,
}: CreateYouthCaseRequestParams) =>
  coreAgent.postRequest<CaseDto>(
    `youths/${id}/cases${force ? "/force" : ""}`,
    dto
  );

export const createYouthScreeningRequest = ({
  id,
  dto,
}: CreateYouthScreeningRequestParams) =>
  coreAgent.postRequest<ScreeningDto>(`youths/${id}/screenings`, dto);

export const getYouthScreeningsRequest = (youthId: string) =>
  coreAgent.getRequest<ScreeningDto[]>(`youths/${youthId}/screenings`);

export const setYouthDjjIdNumberRequest = ({
  id,
  dto,
}: SetYouthDjjIdNumberRequestParams) =>
  coreAgent.putRequest(`youths/${id}/djjIdNumber`, dto);

export const createYouthContactRequest = ({
  id,
  dto,
}: CreateYouthContactRequestParams) =>
  coreAgent.postRequest<YouthContactSummaryDto>(`youths/${id}/contacts`, dto);

export const getYouthCasesRequest = (id: string) =>
  coreAgent.getRequest<CaseDto[]>(`youths/${id}/cases`);

export const getYouthAddressRequest = (id: string) =>
  coreAgent.getRequest<YouthAddressDto>(`youths/${id}/address`);

export const updateYouthAddressRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: YouthAddressFormDto;
}) => coreAgent.putRequest<YouthAddressDto>(`youths/${id}/address`, dto);

export const deleteYouthRequest = (id: string) =>
  coreAgent.postRequest<void>(`youths/${id}/delete`, {});
