import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { useDashboardContext } from "../DashboardContext";
import { Redirect, useHistory, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export const DashboardSelector: React.FC<{ isloading: boolean }> = ({
  isloading,
}) => {
  const history = useHistory();

  const { availableDashboards } = useDashboardContext();
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    console.info(`You clicked ${availableDashboards[selectedIndex].id}`);
  };

  const handleMenuItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
    setOpen(false);
    history.push(`/dashboards/${availableDashboards[index].id}`);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    // @ts-ignore
    if (anchorRef?.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (
    !dashboardId ||
    (availableDashboards.length > 0 &&
      !availableDashboards.find((d) => d.id === dashboardId))
  ) {
    return <Redirect to={`/dashboards/`} />;
  }

  if (availableDashboards.length === 0) return <></>;

  const selectedDashboard = availableDashboards.find(
    (d) => d.id === dashboardId
  );

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        disabled={isloading}
      >
        <Button onClick={handleClick}>{selectedDashboard?.title}</Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: 9999 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {availableDashboards.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      selected={availableDashboards[index].id === dashboardId}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
