import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  ProgramTypeDto,
  programTypesToOptions,
} from "../../program-types/dtos/ProgramTypeDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";
import { FollowUpSearchParamsDto } from "../dtos/FollowUpSearchParamsDto";
import { useFollowUpFilter } from "../hooks/useFollowUpFilter";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { getFundingSourceOptions } from "../../programs/enums/ProgramFundingSourceEnum";
import {getLocationOptions, LocationDto} from "../../locations/dtos/LocationDto";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import {StaffMemberControlledAutoComplete} from "../../staff-members/components/StaffMemberControlledAutoComplete";
import {StaffMemberDto} from "../../staff-members/dtos/StaffMemberDto";

interface Props {
  params: FollowUpSearchParamsDto;
  anchorEl: HTMLButtonElement | null;
  providers: ProviderDto[];
  programs: ProgramDto[];
  programTypes: ProgramTypeDto[];
  locations: LocationDto[];
  staff: StaffMemberDto[];
  onClose(): void;
  onSearch(dto: FollowUpSearchParamsDto): void;
}

const FollowUpFilter: React.FC<Props> = ({
  params,
  anchorEl,
  providers,
  programs,
  programTypes,
  locations,
  staff,
  onClose,
  onSearch,
}) => {
  //#region State
  const {
    form: { control, clearAllFilters },
    state: { hadServicesSinceDischargeOptions },
    handlers: { handleSubmit },
  } = useFollowUpFilter(onSearch, onClose, params);
  //#endregion

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">Follow-Up Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            {/* Provider Name */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="providerId"
                  label="Provider"
                  fullWidth
                  options={providerToOptionDtos(providers, [], "All")}
                />
              </Grid>
            </FnAccessWrapper>

            {/* Program Types */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="programTypeId"
                  label="Program Type"
                  options={programTypesToOptions(programTypes, "All")}
                  fullWidth
                />
              </Grid>
            </FnAccessWrapper>

            {/* Programs */}
            <ProviderAccessWrapper>
              <Grid item md={12} xs={12}>
                <ProgramControlledSelect
                  control={control}
                  name="programId"
                  label="Program"
                  programs={programs}
                  disabled={false}
                  defaultOptionLabel={"All"}
                  isLoading={false}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="locationId"
                  label="Location"
                  options={getLocationOptions(locations, "All")}
                  disabled={locations.length === 0}
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>

                <StaffMemberControlledAutoComplete staffMembers={staff} control={control} isLoading={false} name={'dischargeStaffId'} label={'Discharge Staff'} />
              </Grid>
            </ProviderAccessWrapper>

            {/* Discharge Date */}
            <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
              <ControlledInput
                control={control}
                name="dischargeDateStart"
                label="Discharge Start Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
              <ControlledInput
                control={control}
                name="dischargeDateEnd"
                label="Discharge End Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} style={{ paddingTop: 0 }}>
              <Typography color="textSecondary" style={{ fontSize: "0.8em" }}>
                *Discharge Start Date defaults to 120 days ago.
              </Typography>
            </Grid>


            {/* Completion Date */}
            <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
              <ControlledInput
                control={control}
                name="followUpCompletionRangeStart"
                label="Completed On or After"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
              <ControlledInput
                control={control}
                name="followUpCompletionRangeEnd"
                label="Completed On or Before"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} style={{ paddingTop: 0 }}>
              <Typography color="textSecondary" style={{ fontSize: "0.8em" }}>
                *Cases with one or more followups completed in the date range.
              </Typography>
            </Grid>

            {/* Funding Source */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="fundingSource"
                label="Funding Source"
                options={getFundingSourceOptions("All")}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="hadServicesSinceDischarge"
                label="Services Since Discharge?"
                options={hadServicesSinceDischargeOptions}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledCheckbox
                control={control}
                name={"onlyShowFollowUpsDue"}
                label={"Only Show Cases with Follow Ups that are Expected or Due"}
                color={"primary"}
                disabled={false}
              />
              <Typography color="textSecondary" style={{ fontSize: "0.8em" }}>
                *Only Florida Network funded 30- or 60-Day Follow Ups can be considered Expected or Due.
              </Typography>
            </Grid>


            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default FollowUpFilter;
