import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  createWaitlistFormDto,
  WaitlistFormDto,
} from "../dtos/WaitlistFormDto";
import { useProgramLocationNestedFields } from "../../youths/hooks/useProgramLocationNestedFields";
import { waitlistSchema } from "../components/WaitlistFormFields";
import {useEffect} from "react";

export const useWaitlistForm = (
  onSubmit: (dto: WaitlistFormDto) => Promise<any>
) => {
  //#region State

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: createWaitlistFormDto(),
    resolver: yupResolver(waitlistSchema),
    mode: "all",
  });
  const { locationId, programId } = watch();
  const {
    state: {
      locations,
      providerPrograms,
      getProviderLocationsRequestState,
      getProviderProgramsRequestState,
    },
    // handlers: { handleUpdateLocations },
    getters: { getProgramLocationId },
  } = useProgramLocationNestedFields(true, undefined, programId);

  const clearForm = () => {
    reset(createWaitlistFormDto());
  };
  const handleOnSubmit = handleSubmit(async (dto: WaitlistFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  //#region Effects
  useEffect(() => {
    const programIdInvalid = programId && providerPrograms.find(p => p.id === programId) == null;

    if(programIdInvalid) {
      const newProgramId = providerPrograms.length === 1 ? providerPrograms[0].id : "";

      console.log(`invalid program set ${programId}, changing value to ${newProgramId}`);

      setValue("programId", newProgramId, {
        shouldValidate: true,
      });
    }
  }, [programId, providerPrograms, setValue]);

  useEffect(() => {
    const locationIdInvalid = locationId && locations.find(l => l.id === locationId) == null;

    if(locationIdInvalid) {
      console.log(locations.length)
      const newLocationId = locations.length === 1 ? locations[0].id : "";

      console.log(`invalid location set ${locationId}, changing value to ${newLocationId}`);

      setValue("locationId", newLocationId, {
        shouldValidate: true,
      });
    }
  }, [locationId, locations, setValue]);

  useEffect(() => {
    setValue("programLocationId", getProgramLocationId(programId, locationId));
  }, [programId, locationId, setValue, getProgramLocationId]);

  //#endregion

  return {
    state: {
      locations,
      providerPrograms: providerPrograms || [],
      getProviderLocationsRequestState,
      getProviderProgramsRequestState,
    },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
