import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import { ReferralSearchParamsDto } from "../dtos/ReferralSearchParamsDto";
import { useReferralFilter } from "../hooks/useReferralFilter";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { fnyfsName, fnyfsProviderId } from "../../authentication/constants";
import { referralStatusOptions } from "../enums/ReferralStatusEnum";

interface Props {
  onClose: () => void;
  params: ReferralSearchParamsDto;
  onSearch: (dto: ReferralSearchParamsDto) => void;
  anchorEl: HTMLButtonElement | null;
  providers: ProviderDto[];
}

const ReferralFilter: React.FC<Props> = ({
  onClose,
  params,
  onSearch,
  anchorEl,
  providers,
}) => {
  //#region State
  const {
    form: { control, clearAllFilters },
    handlers: { handleSubmit },
  } = useReferralFilter(onSearch, onClose, params);
  //#endregion

  const sortedProviders = () => {
    return providers.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">Referral Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            {/* Provider Name */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="providerId"
                  label="Provider"
                  fullWidth
                  options={providerToOptionDtos(
                    sortedProviders(),
                    [{ label: fnyfsName, value: fnyfsProviderId }],
                    "All"
                  )}
                />
              </Grid>
            </FnAccessWrapper>

            {/* Status */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="status"
                label="Status"
                fullWidth
                options={referralStatusOptions}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default ReferralFilter;
