import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { ProgramDto } from "../dtos/ProgramDto";
import { ProgramFormDto } from "../dtos/ProgramFormDto";
import { useProgramForm } from "../hooks/useProgramForm";
import ProgramFormFields from "./ProgramFormFields";

export const programModalId = "program-form-modal";

interface Props {
  program?: ProgramDto;
  onSubmit: (dto: ProgramFormDto) => Promise<any>;
  requestState: RequestStateDto;
}

const ProgramFormDialog: React.FC<Props> = ({
  program,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const {
    state: {
      programTypes,
      contracts,
      getProgramTypesRequestState: {
        isLoading: programTypesAreLoading,
        error: getProgramTypesError,
      },
      isFloridaNetworkUser,
      selectedFundingSource,
      selectedProgramTypeId,
    },
    handlers: { handleSubmit },
    form: { control, isValid, clearForm },
  } = useProgramForm(onSubmit, program);

  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  //#endregion

  return (
    <Dialog open={modalId === programModalId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {program ? "Edit" : "Create"} Program{" "}
          <ErrorText error={error?.message} />
          <ErrorText error={getProgramTypesError?.message} />
        </DialogTitle>
        <DialogContent style={{ minWidth: "min(624px, 100vw)" }}>
          <ProgramFormFields
            control={control}
            disabled={isLoading || programTypesAreLoading}
            editMode={program !== undefined}
            programTypes={programTypes}
            contracts={contracts}
            isFloridaNetworkUser={isFloridaNetworkUser}
            selectedFundingSource={selectedFundingSource || ''}
            selectedProgramTypeId={selectedProgramTypeId || ''}
          />
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProgramFormDialog;
