import {Box, Collapse, Divider, Grid, Paper, Typography,} from "@material-ui/core";
import React, {Fragment, useEffect, useState} from "react";
import {ProviderFiscalYearDto} from "../../../provider-fiscal-year/dtos/ProviderFiscalYearDto";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Section from "../../../../app/components/Section";
import {Skeleton} from "@material-ui/lab";
import EditButton from "../../../../app/components/buttons/EditButton";
import {PermissionResourceNameEnum} from "../../../authentication/enums/PermissionResourceNameEnum";
import DeliverableTypeLabel from "../../../program-type-deliverables/components/DeliverableTypeLabel";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import ExpandButton from "../../../../app/components/buttons/ExpandButton";
import {useProviderDetailPageContext} from "../../pages/ProviderDetailPage";
import {ProgramTypeDeliverableDto} from "../../../program-type-deliverables/dtos/ProgramTypeDeliverableDto";

interface Props {
  fiscalYear: ProviderFiscalYearDto;
  expanded: boolean;
  onChange: () => void;
  onEdit: () => void;
  onEditLicensedBeds: () => void;
}

export const ProviderFiscalYearListLoadingItem = () => {
  return (
    <Section>
      <Box display="flex">
        <Skeleton
          variant="rect"
          width="35px"
          height="35px"
          style={{ marginRight: "8px" }}
        />
        <Typography variant="h5">
          <Skeleton variant="text" width="200px" />
        </Typography>
      </Box>
    </Section>
  );
};

const ProviderFiscalYearListItem: React.FC<Props> = ({
  fiscalYear,
  expanded,
  onChange,
  onEdit,
  onEditLicensedBeds,
}) => {
  const {contracts, programTypeDeliverables} = useProviderDetailPageContext();
  const [expandedContract, setExpandedContract] = useState("");

  const toggleContract = (name: string) => {
    if (expandedContract === name) {
      setExpandedContract("");
    } else {
      setExpandedContract(name);
    }
  };

  const getProgramTypesGroupedByContractName = () => {

    const groupings = fiscalYear.contractLimits
      .reduce<{
        [key: string]: ProgramTypeDeliverableDto[]
      }>((value, contractDeliverable) => {
        const { contract } =
          contractDeliverable;

        const contractWithRelatedProgramIds = contracts.find(c => c.id === contract.id);
        if (contractWithRelatedProgramIds === undefined) return value;

        value[contract.name] = contractWithRelatedProgramIds.validProgramTypeIds.flatMap(ptId => programTypeDeliverables.filter(ptd => ptd.programType.id === ptId));

        return value;
      }, {});

    return Object.entries(groupings).sort((a, b) => (a[0] > b[0] ? 1 : -1));
  };

  const getDeliverablesGroupedByProgramName = (
    programDeliverables : ProgramTypeDeliverableDto[]
  ) => {
    const deliverableGroupings = programDeliverables
      .filter(pd => pd.id !== '320ef3ff-a488-405a-8e2b-6c32728bb6f7')
      .reduce<{[index: string]: ProgramTypeDeliverableDto[]}>((acc, value) => {
      return { ...acc, [value.programType.name]: [...(acc[value.programType.name] || []), value] }
    }, {})

    return deliverableGroupings;
  };

  const sortContractDeliverables = (
    group: ProgramTypeDeliverableDto[]
  ) => {
    return group.sort((a, b) =>
      a.deliverableType.displayOrder >
      b.deliverableType.displayOrder
        ? 1
        : -1
    );
  };

  useEffect(() => {
    if (!expanded) {
      setExpandedContract("");
    }
  }, [expanded, setExpandedContract]);

  return (
    <Fragment>
      <Paper variant="outlined">
        <Box display="flex" alignItems="center" padding="12px">
          <CalendarTodayIcon
            style={{
              marginRight: "6px",
            }}
          />
          <Typography variant="h6" component="h6">
            {fiscalYear.fiscalYear} - {fiscalYear.fiscalYear + 1}
          </Typography>
          <ExpandButton
            expanded={expanded}
            size="small"
            onClick={onChange}
            style={{ marginLeft: "auto" }}
          />
        </Box>
        <Collapse in={expanded}>
          <Divider />
          <Box display="flex" justifyContent="flex-end">
            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.FiscalYearContractDeliverable}
            >
              <EditButton text="Edit Fiscal Year" onClick={onEdit} />
            </WriteAccessLevelWrapper>
            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.FiscalYearLicensedBed}
            >
              <EditButton
                text="Edit Licensed Beds"
                onClick={onEditLicensedBeds}
              />
            </WriteAccessLevelWrapper>
          </Box>
          <Box padding="12px">
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <Typography color="primary" component="h6" variant="h6">
                  Overview
                </Typography>
              </Grid>
              <Grid item md={2} xs={5}>
                <Typography component="span">Licensed Beds:</Typography>
              </Grid>
              <Grid item md={10} xs={7}>
                <Typography component="span">
                  {fiscalYear.licensedBeds}
                </Typography>
              </Grid>

              <Grid item md={12} xs={12}>
                <Typography
                  color="primary"
                  component="h6"
                  variant="h6"
                  style={{ marginTop: "12px" }}
                >
                  Contracted Deliverables
                </Typography>
              </Grid>
              {getProgramTypesGroupedByContractName().map(
                ([contractKey, contractDeliverables]) => (
                  <Fragment key={contractKey}>
                    <Grid item md={12} xs={12}>
                      <Box display={"flex"} alignItems={"center"}>
                        { contractKey }
                        <Divider style={{ flexGrow: 1, marginLeft: "6px" }} />
                        <ExpandButton
                          expanded={expandedContract === contractKey}
                          onClick={() => toggleContract(contractKey)}
                          size="small"
                          style={{ marginLeft: "auto" }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {Object.entries(getDeliverablesGroupedByProgramName(contractDeliverables)).map(
                        ([key, group]) => {
                          return (
                            <Collapse in={contractKey === expandedContract} key={key}>
                              <Grid container spacing={1}>
                                <Fragment key={key}>
                                  <Grid item md={12} xs={12}>
                                    <small>
                                      <Typography color="primary">
                                        {
                                          key
                                        }
                                      </Typography>
                                    </small>
                                  </Grid>
                                  {sortContractDeliverables(group).map(
                                    (deliverable) => (
                                      <Fragment key={deliverable.id}>
                                        <Grid item md={2} xs={5}>
                                          <DeliverableTypeLabel
                                            component="span"
                                            type={
                                              deliverable
                                                .deliverableType.type
                                            }
                                          />
                                          :
                                        </Grid>
                                        <Grid item md={10} xs={7}>
                                          <Typography component="span">
                                            { fiscalYear.contractLimits.find(cl =>
                                                cl.programTypeDeliverableId === deliverable.id
                                                && cl.contract.id === contracts.find(c => c.name === contractKey)?.id
                                            )?.limit || 'No Limit Set'}
                                          </Typography>
                                        </Grid>
                                      </Fragment>
                                    )
                                  )}
                                  <Grid item md={12} xs={12}>
                                    <Divider />
                                  </Grid>
                                </Fragment>
                              </Grid>
                            </Collapse>
                          );
                        }
                      )}
                    </Grid>
                  </Fragment>
                )
              )}
            </Grid>
          </Box>
        </Collapse>
      </Paper>
    </Fragment>
  );
};

export default ProviderFiscalYearListItem;
