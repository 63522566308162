import {
  Box,
  Grid,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import NameLabel from "../../../../app/components/labels/NameLabel";
import TableLoadingPlaceholder from "../../../../app/components/TableLoadingPlaceholder";
import { PaginationResultDto } from "../../../../app/dtos/PaginationResultDto";
import { AlertDismissalListDto } from "../../../alerts/dtos/AlertDismissalDto";
import { alertTypeTextMap } from "../../../alerts/enums/AlertTypeEnum";
import { useAnchor } from "../../../../app/hooks/useAnchor";
import RouteableStyledLink from "../../../../app/components/RouteableStyledLink";
import DateLabel from "../../../../app/components/labels/DateLabel";
import NoteIcon from "@material-ui/icons/Note";
interface Props {
  dismissals: PaginationResultDto<AlertDismissalListDto>;
  isLoading: boolean;
  onPageChange(page: number): void;
}

const CaseAlertDismissalListItem = ({
  dismissal,
}: {
  dismissal: AlertDismissalListDto;
}) => {
  const [anchorEl, handleOpenClicked, handleClose] = useAnchor();

  const reason = (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={handleClose}
    >
      <Box padding="12px" width={"400px"}>
        <Grid container>
          <Grid item md={5} xs={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Reason for Dismissal
            </Typography>
          </Grid>
          <Grid item md={7} xs={7}>
            <Typography>{dismissal.reason}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <RouteableStyledLink
            pathname={`/cases/${dismissal.referenceId}`}
            text="Back to Case Alert Dismissals"
          >
            {dismissal.referenceNumber}
          </RouteableStyledLink>
        </TableCell>
        <TableCell>{alertTypeTextMap[dismissal.type]}</TableCell>
        <TableCell>
          <NameLabel nameDto={dismissal.createdBy} />
        </TableCell>
        <TableCell>
          <DateLabel date={dismissal.createdAt} />
        </TableCell>
        <TableCell>{dismissal.provider.name}</TableCell>
        <TableCell>{dismissal.programType.name}</TableCell>
        <TableCell>
          <IconButton onClick={handleOpenClicked} title="Reason">
            <NoteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {reason}
    </Fragment>
  );
};

const CaseAlertDismissalList: React.FC<Props> = ({
  dismissals,
  isLoading,
  onPageChange,
}) => {
  const content = isLoading ? (
    <TableLoadingPlaceholder rows={10} columns={7} />
  ) : (
    dismissals.items.map((dismissal) => (
      <CaseAlertDismissalListItem key={dismissal.id} dismissal={dismissal} />
    ))
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Case #</TableCell>
            <TableCell>Alert Type</TableCell>
            <TableCell>Dismissed By</TableCell>
            <TableCell>Dismissed On</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Program Type</TableCell>
            <TableCell>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{content}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[dismissals.pageSize || 10]}
              rowsPerPage={dismissals.pageSize || 0}
              count={dismissals?.count || 0}
              onPageChange={(_: any, page: number) => onPageChange(page)}
              page={dismissals?.page || 0}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CaseAlertDismissalList;
