import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";

export interface NirvanaAssessmentContextDto {
  youthId: string;
  youthReferenceNumber: string;
  youthFullName: string;
  youthDob: string;
  youthZipCode: string;
  youthDjjId?: number;
  agencyId: string;
  agencyName: string;
  programId: string;
  programName: string;
  programType: string;
  programTypeName: string;
  intakeDate: string;
  caseNumber: string;
}

export const createCaseAssessmentContextDto = (
  caseDto: CaseDetailDto
): NirvanaAssessmentContextDto => {
  const {
    id: youthId,
    referenceNumber: youthReferenceNumber,
    firstName,
    lastName,
    dob,
    address,
    djjIdNumber,
  } = caseDto.youth;
  let youthZipCode: string | undefined = "";
  if (address) youthZipCode = address.zipCode;

  const { name: agencyName, id: agencyId } = caseDto.provider;
  const { name: programName, id: programId } = caseDto.program;
  const { programType, name: programTypeName } = caseDto.program.programType;
  const { intakeAt } = caseDto.intakeInfo;
  const { caseNumber } = caseDto;

  return {
    youthId: youthId,
    youthReferenceNumber: youthReferenceNumber,
    youthFullName: `${firstName} ${lastName}`,
    youthDob: dob.toString(),
    youthZipCode: youthZipCode || "",
    agencyId,
    agencyName,
    programId,
    programName,
    intakeDate: intakeAt.toString(),
    caseNumber,
    programType,
    programTypeName,
    youthDjjId: djjIdNumber,
  };
};
