import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import {useCaseDetailPageContext} from "../pages/CaseDetailPage";
import {AlertTypeEnum, CaseContextAlertTypeMessages} from "../../alerts/enums/AlertTypeEnum";
import InfoTooltip from "../../../app/components/InfoTooltip";
import {calculateDateDifferenceInDays} from "../../../app/helpers";
import NotificationBell, {NotificationActionDto, NotificationType,} from "../../../app/components/NotificationBell";

const CaseDetailNotifications = () => {
  //#region State
  const {
    case: { caseResult, screening, nirvana, topse },
    alerts,
    discharge,
    youth,
    canDischarge,
  } = useCaseDetailPageContext();

  const { handleStartTopseAssessmentClicked } = topse;
  const { handleSetCaseScreeningClicked } = screening;
  const { handleStartNirvanaAssessmentClicked } = nirvana;
  const { handleUpsertCaseDischargeClicked: handleDischargeCaseClicked } =
    discharge;
  const { handleSetYouthDjjIdNumberClicked } = youth;
  const { caseAlerts, handleCreateAlertDismissalClicked } = alerts;
  const [notifications, setNotifications] = useState<NotificationActionDto[]>(
    []
  );
  //#endregion

  //#region Notifications
  const createInfoContent = (
    shortMessage: React.ReactNode,
    longMessage: string
  ) => (
    <Fragment>
      <Box display={{ xs: "none", lg: "block" }}>{longMessage}</Box>
      <Box display={{ xs: "block", lg: "none" }}>
        {shortMessage} <InfoTooltip title={longMessage} />
      </Box>
    </Fragment>
  );

  const getDismissAction = useCallback(
    (type: AlertTypeEnum) => {
      switch (type) {
        case AlertTypeEnum.CaseNirvanaAssessmentRequired:
        case AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired:
        case AlertTypeEnum.CaseTopsePreAssessmentRequired:
        case AlertTypeEnum.CaseTopsePostAssessmentRequired:
        case AlertTypeEnum.ShelterCaseLengthOfStay:
        case AlertTypeEnum.SnapCaseLengthOfStay:
        case AlertTypeEnum.IcmCaseLengthOfStay:
        case AlertTypeEnum.ProbationRespiteCaseLengthOfStay:
        case AlertTypeEnum.CommunityCounselingCaseLengthOfStay:
          return () => handleCreateAlertDismissalClicked(type);
      }
    },
    [handleCreateAlertDismissalClicked]
  );

  const getAlertContent = useCallback(
    (type: AlertTypeEnum) => {
      if (caseResult == null) return undefined;

      const alert = caseAlerts.find((x) => x.type === type);

      if (alert == null) return undefined;

      const message = CaseContextAlertTypeMessages[type](caseResult);
      if(!message) return undefined;

      switch (type) {
        case AlertTypeEnum.Case60DayFollowUp:
          return createInfoContent("60 Day Follow Up Required", message);
        case AlertTypeEnum.Case30DayFollowUp:
          return createInfoContent("30 Day Follow Up Required", message);
        case AlertTypeEnum.CaseNirvanaAssessmentRequired:
        case AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired:
        case AlertTypeEnum.CaseTopsePreAssessmentRequired:
        case AlertTypeEnum.CaseTopsePostAssessmentRequired:
        case AlertTypeEnum.ShelterCaseLengthOfStay:
        case AlertTypeEnum.SnapCaseLengthOfStay:
        case AlertTypeEnum.IcmCaseLengthOfStay:
        case AlertTypeEnum.ProbationRespiteCaseLengthOfStay:
        case AlertTypeEnum.CommunityCounselingCaseLengthOfStay:
        case AlertTypeEnum.CaseScreeningRequired:
        case AlertTypeEnum.YouthDjjIdRequired:
        case AlertTypeEnum.YouthLegalGuardianRequired:
        case AlertTypeEnum.CaseDvRespiteLimit:
        case AlertTypeEnum.IcmContact:
        case AlertTypeEnum.CaseStaffSecureEligibility:
        case AlertTypeEnum.CaseDeferredBilling:
          return message;
      }
    },
    [caseAlerts, caseResult]
  );

  const getAlertAction = useCallback(
    (type: AlertTypeEnum) => {
      switch (type) {
        case AlertTypeEnum.CaseScreeningRequired:
          return handleSetCaseScreeningClicked;
        case AlertTypeEnum.CaseNirvanaAssessmentRequired:
        case AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired:
          return handleStartNirvanaAssessmentClicked;
        case AlertTypeEnum.CaseTopsePreAssessmentRequired:
        case AlertTypeEnum.CaseTopsePostAssessmentRequired:
          return handleStartTopseAssessmentClicked;
        case AlertTypeEnum.YouthDjjIdRequired:
          return handleSetYouthDjjIdNumberClicked;
        case AlertTypeEnum.CaseDvRespiteLimit:
        case AlertTypeEnum.ShelterCaseLengthOfStay:
        case AlertTypeEnum.SnapCaseLengthOfStay:
        case AlertTypeEnum.IcmCaseLengthOfStay:
        case AlertTypeEnum.ProbationRespiteCaseLengthOfStay:
        case AlertTypeEnum.CommunityCounselingCaseLengthOfStay:
          return canDischarge ? handleDischargeCaseClicked : () => {};
        case AlertTypeEnum.Case60DayFollowUp:
        case AlertTypeEnum.Case30DayFollowUp:
        case AlertTypeEnum.IcmContact:
        case AlertTypeEnum.CaseDeferredBilling:
        case AlertTypeEnum.YouthLegalGuardianRequired:
        default:
          return () => {};
      }
    },
    [
      handleSetCaseScreeningClicked,
      handleStartNirvanaAssessmentClicked,
      handleStartTopseAssessmentClicked,
      handleSetYouthDjjIdNumberClicked,
      handleDischargeCaseClicked,
      canDischarge,
    ]
  );

  const getNotificationType = useCallback(
    (type: AlertTypeEnum) => {
      const getFollowUpNotificationType = (days: number) => {
        const daysSinceDischarge = calculateDateDifferenceInDays(
          new Date(),
          caseResult!.dischargeInfo.dischargedAt
        );

        if (days + 10 <= daysSinceDischarge)
          return NotificationType.OutOfCompliance;
        return NotificationType.Informational;
      };

      const getDvRespiteNotificaitonType = () => {
        const days = calculateDateDifferenceInDays(
          new Date(),
          caseResult!.intakeInfo.intakeAt
        );
        if (days >= 22) return NotificationType.OutOfCompliance;
        if (days >= 21) return NotificationType.ActionRequired;
        return NotificationType.Informational;
      };

      switch (type) {
        case AlertTypeEnum.Case60DayFollowUp:
          return getFollowUpNotificationType(60);
        case AlertTypeEnum.Case30DayFollowUp:
          return getFollowUpNotificationType(30);
        case AlertTypeEnum.CaseDvRespiteLimit:
          return getDvRespiteNotificaitonType();
        case AlertTypeEnum.YouthDjjIdRequired:
        case AlertTypeEnum.YouthLegalGuardianRequired:
        case AlertTypeEnum.CaseScreeningRequired:
        case AlertTypeEnum.CaseNirvanaAssessmentRequired:
        case AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired:
        case AlertTypeEnum.CaseTopsePreAssessmentRequired:
        case AlertTypeEnum.CaseTopsePostAssessmentRequired:
          return NotificationType.ActionRequired;
        case AlertTypeEnum.IcmContact:
        case AlertTypeEnum.CaseDeferredBilling:
          return NotificationType.Informational;
        case AlertTypeEnum.ShelterCaseLengthOfStay:
        case AlertTypeEnum.SnapCaseLengthOfStay:
        case AlertTypeEnum.IcmCaseLengthOfStay:
        case AlertTypeEnum.ProbationRespiteCaseLengthOfStay:
        case AlertTypeEnum.CommunityCounselingCaseLengthOfStay:
        default:
          return NotificationType.OutOfCompliance;
      }
    },
    [caseResult]
  );

  const createNotification = useCallback(
    (type: AlertTypeEnum): NotificationActionDto | undefined => {
      const content = getAlertContent(type);
      if (content == null) return undefined;
      const action = getAlertAction(type);
      const notificationType = getNotificationType(type);
      return {
        id: type,
        message: content,
        type: notificationType,
        action,
        onDismiss: getDismissAction(type),
      };
    },
    [getAlertContent, getAlertAction, getNotificationType, getDismissAction]
  );

  //#endregion

  useEffect(() => {
    if (!caseResult) return;

    const notifications: NotificationActionDto[] = [
      AlertTypeEnum.CaseScreeningRequired,
      AlertTypeEnum.YouthDjjIdRequired,
      AlertTypeEnum.YouthLegalGuardianRequired,
      AlertTypeEnum.CaseDvRespiteLimit,
      AlertTypeEnum.CaseNirvanaAssessmentRequired,
      AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired,
      AlertTypeEnum.Case30DayFollowUp,
      AlertTypeEnum.Case60DayFollowUp,
      AlertTypeEnum.CaseTopsePreAssessmentRequired,
      AlertTypeEnum.CaseTopsePostAssessmentRequired,
      AlertTypeEnum.ShelterCaseLengthOfStay,
      AlertTypeEnum.CaseDeferredBilling,
      AlertTypeEnum.SnapCaseLengthOfStay,
      AlertTypeEnum.IcmCaseLengthOfStay,
      AlertTypeEnum.ProbationRespiteCaseLengthOfStay,
      AlertTypeEnum.CommunityCounselingCaseLengthOfStay,
      AlertTypeEnum.IcmContact,
      AlertTypeEnum.CaseStaffSecureEligibility,
    ]
      .map(createNotification)
      .filter((x) => x != null)
      .map((x) => x as NotificationActionDto);

    setNotifications(notifications);
  }, [caseResult, setNotifications, createNotification]);

  return <NotificationBell notifications={notifications} title="Case Alerts" />;
};

export default CaseDetailNotifications;
