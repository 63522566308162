import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import {useCaseDetailPageContext} from "../../cases/pages/CaseDetailPage";

interface Props {
  requestState: RequestStateDto;
  onConfirm(id: string): Promise<void>;
}

export const confirmDeleteCaseContactDialogId = "delete-case-contact-dialog";
const ConfirmDeleteCaseContactDialog: React.FC<Props> = ({
  onConfirm,
  requestState
                                                         }) => {
  const {
    caseContacts: { selectedCaseContactId }
  } = useCaseDetailPageContext();

  const {error, isLoading} = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={ async () => {
        if(selectedCaseContactId != null)
          await onConfirm(selectedCaseContactId)
      }}
      modalId={confirmDeleteCaseContactDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Case Contact"
      confirmButtonStyle={{
        color: ColorsEnum.Red,
      }}
      confirmButtonDisabled={false}
      maxWidth="md"
    >
      <ErrorText error={error?.message} />
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to delete this Case Contact?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteCaseContactDialog;
