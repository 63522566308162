import { DateTime } from "luxon";
import React, { Fragment } from "react";

export const formatDateTime = (date: Date) =>
  DateTime.fromJSDate(new Date(date)).toFormat("MM/dd/yyyy hh:mm a");

interface Props {
  date?: Date;
}

const DateTimeLabel: React.FC<Props> = ({ date }) => {
  if (date) return <Fragment>{formatDateTime(date)}</Fragment>;

  return <Fragment></Fragment>;
};

export default DateTimeLabel;
