import React, { useCallback } from "react";
import FilterList from "../../../app/components/FilterList";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import {
  DjjEntryFilterFormDto,
  DjjEntrySearchParamsDto,
  DjjEntryStatusTextMap,
  DjjEntryTypeTextMap,
} from "../DjjEntryDto";

interface Props {
  providers: ProviderDto[];
  params: DjjEntrySearchParamsDto;
  isLoading: boolean;
  onSearch(dto: DjjEntrySearchParamsDto): void;
}

const DjjEntryFilterList: React.FC<Props> = ({
  params,
  onSearch,
  providers,
  isLoading,
}) => {
  const handleClearParams = () => {
    const form = new DjjEntryFilterFormDto();
    return new DjjEntrySearchParamsDto({
      previous: params,
      form,
    });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "providerId":
          return `Provider: ${
            providers.find((x) => x.id === value)?.shortName ||
            "Unknown Provider"
          }`;
        case "hasDjjId":
          return `Has DJJ ID: ${value && value === 'false' ? "No" : "Yes"}`;
        case "statuses":
          return `Statuses: ${(value as string[])
            .map((value) => DjjEntryStatusTextMap[value])
            .join(", ")}`;
        case "entryType":
          return `Entry Type: ${DjjEntryTypeTextMap[value]}`;
      }
      return "";
    },
    [providers]
  );

  const ordering = ["entryType", "providerId", "hasDjjId", "statuses"];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default DjjEntryFilterList;
