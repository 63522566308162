import React from "react";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export interface ProviderNameDto {
  id: string;
  name: string;
}

export interface ProviderDto {
  id: string;
  centerId: string;
  name: string;
  shortName: string;
  abbreviation: string;
}

export const providerToOptionDto = (dto: ProviderDto): SelectOption => ({
  label: dto.name,
  value: dto.id,
});
export const providerToOptionDtos = (
  dtos: ProviderDto[],
  additionalOptions: SelectOption[] = [],
  defaultLabel: React.ReactNode = "Select Provider"
): SelectOption[] => {
  return [
    { label: defaultLabel, value: "" },
    ...additionalOptions,
    ...dtos.map(providerToOptionDto),
  ];
};
