import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { useAnchor } from "../../../app/hooks/useAnchor";
import { useQuery } from "../../../app/hooks/useQuery";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { useGetProgramTypes } from "../../program-types/hooks/programTypeHooks";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import {
  useGetAllProviders,
  useGetProviderLocations,
  useGetProviderPrograms,
  useGetProviderStaffMembers,
} from "../../provider/hooks/providerHooks";
import { CaseSearchParamsDto } from "../dtos/CaseSearchParamsDto";
import { useGetCases } from "./caseHooks";
import { ProgramTypeEnum } from "../../programs/enums/ProgramTypeEnum";
import {useRequest} from "../../../app/hooks/useRequest";
import {exportCasesRequest} from "../CaseRequests";
import {DateTime} from "luxon";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";

export const useCasesListPage = () => {
  //#region State
  const query = useQuery();
  const [params, setParams] = useState<CaseSearchParamsDto>(
    new CaseSearchParamsDto({ query })
  );
  const [getCases, casesResult, getCasesRequestState] = useGetCases();
  const history = useHistory();
  const [filterAnchorEl, handleOpenFilterClicked, handleCloseFilter] =
    useAnchor();

  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();

  const [getProgramTypes, programTypes, getProgramTypesRequestState] =
    useGetProgramTypes();

  const [getPrograms, , getProgramsRequestState] = useGetProviderPrograms();

  const [getLocations, , getLocationsRequestState] = useGetProviderLocations();

  const [getStaffMembers, , getStaffMembersRequestState] = useGetProviderStaffMembers();
  
  const [providerLocations, setProviderLocations] = useState<{
    [key: string]: LocationDto[];
  }>({});

  const [providerPrograms, setProviderPrograms] = useState<{
    [key: string]: ProgramDto[];
  }>({});

  const [providerStaffMembers, setProviderStaffMembers] = useState<{
    [key: string]: StaffMemberDto[];
  }>({});

  const [exportCasesList, , exportCasesRequestState] = useRequest(exportCasesRequest);

  // This limit is also enforced server side, so they need to be in sync
  const exportRecordsLimit = 50000;
  const [allowExport, setAllowExport] = useState(true)
  //#endregion

  //#region Handlers

  const handlePageChange = (page: number) => {
    const newParams = { ...params, page };
    history.push(`/cases?${QueryStringHelpers.toQueryString(newParams)}`);
    setParams(newParams);
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    history.push(`/cases?${QueryStringHelpers.toQueryString(newParams)}`);
    setParams(newParams);
  };

  const handleSearch = useCallback(
    (dto: CaseSearchParamsDto) => {
      setParams(dto);
    },
    [setParams]
  );

  const handleExportListClicked = useCallback((dto: CaseSearchParamsDto) => {
    const filename = `case-list-export-${DateTime.now().toFormat('yyyyLLddHHmmss')}.csv`
    exportCasesList({ searchParams: dto, filename });
  }, [exportCasesList])

  const updatePrograms = useCallback(
    async (providerId: string) => {
      if (
        providerId === "" ||
        providerId == null ||
        providerPrograms[providerId]
      ) {
        return;
      }

      const result = await getPrograms(providerId);
      if (result) {
        setProviderPrograms((current) => ({
          ...current,
          [providerId]: result,
        }));
      }
    },
    [setProviderPrograms, providerPrograms, getPrograms]
  );

  const updateLocations = useCallback(
    async (providerId: string) => {
      if (
        providerId === "" ||
        providerId == null ||
        providerLocations[providerId]
      ) {
        return;
      }

      const result = await getLocations(providerId);
      if (result) {
        setProviderLocations((current) => ({
          ...current,
          [providerId]: result,
        }));
      }
    },
    [setProviderLocations, providerLocations, getLocations]
  );

  const updateStaffMembers = useCallback(
    async (providerId: string) => {
      if (
        providerId === "" ||
        providerId == null ||
        providerStaffMembers[providerId]
      ) {
        return;
      }

      const result = await getStaffMembers(providerId);
      if (result) {
        setProviderStaffMembers((current) => ({
          ...current,
          [providerId]: result,
        }));
      }
    },
    [setProviderStaffMembers, providerStaffMembers, getStaffMembers]
  );

  const handleProviderChange = useCallback(
    async (providerId: string) => {
      await updateLocations(providerId);
      await updatePrograms(providerId);
      await updateStaffMembers(providerId);
    },
    [updateLocations, updatePrograms, updateStaffMembers]
  );
  //#endregion

  //#region Effects
  useEffect(() => {
    getCases(params);
  }, [params, getCases]);

  useEffect(() => {
    history.push(`/cases?${CaseSearchParamsDto.toQueryString(params)}`);
  }, [params, history]);

  useEffect(() => {
    getProviders(undefined);
  }, [getProviders]);

  useEffect(() => {
    getProgramTypes(undefined);
  }, [getProgramTypes]);

  useEffect(() => {
    const currentProviderId = getProviderId();

    if (currentProviderId == null) return;

    if (currentProviderId !== fnyfsProviderId) {
      updatePrograms(currentProviderId!);
      updateLocations(currentProviderId!);
    }
  }, [updatePrograms, updateLocations]);

  useEffect(() => {
    setAllowExport(!!casesResult?.count && casesResult.count < exportRecordsLimit);
  }, [casesResult?.count])

  //#endregion

  return {
    state: {
      filterAnchorEl,
      casesResult,
      params,
      providers: providers || [],
      programTypes:
        programTypes?.filter(
          (pt) => pt.programType !== ProgramTypeEnum.SnapInSchools
        ) || [],
      getCasesRequestState,
      getProvidersRequestState,
      getProgramsRequestState,
      getProgramTypesRequestState,
      getLocationsRequestState,
      getStaffMembersRequestState,
      allowExport,
      exportRecordsLimit,
      exportCasesRequestState,
      providerPrograms,
      providerLocations,
      providerStaffMembers,
    },
    handlers: {
      handlePageChange,
      handleSearch,
      handleOpenFilterClicked,
      handleCloseFilter,
      handleProviderChange,
      handleSort,
      handleExportListClicked,
    },
  };
};
