import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { YouthSexualOrientationEnum } from "../../enums/YouthSexualOrientationEnum";

interface Props {
  sexualOrientation: YouthSexualOrientationEnum;
}

const SexualOrientationLabel: React.FC<Props> = ({ sexualOrientation }) => {
  switch (sexualOrientation) {
    case YouthSexualOrientationEnum.Heterosexual:
      return <Typography>Heterosexual</Typography>;
    case YouthSexualOrientationEnum.Gay:
      return <Typography>Gay</Typography>;
    case YouthSexualOrientationEnum.Lesbian:
      return <Typography>Lesbian</Typography>;
    case YouthSexualOrientationEnum.Bisexual:
      return <Typography>Bisexual</Typography>;
    case YouthSexualOrientationEnum.QuestioningUnsure:
      return <Typography>Questioning/Unsure</Typography>;
    case YouthSexualOrientationEnum.Unknown:
      return <Typography>Unknown</Typography>;
    default:
      return <Fragment></Fragment>;
  }
};

export default SexualOrientationLabel;
