import { useRequest } from "../../../app/hooks/useRequest";
import {
  createSnapInSchoolsCycleRequest,
  createSnapInSchoolsSessionRequest,
  getSnapInSchoolsCurriculumRequest,
  getSnapInSchoolsCycleRequest,
  getSnapInSchoolsCyclesRequest,
  getSnapInSchoolsLocationsRequest,
  getSnapInSchoolsSessionRequest,
  downloadAttendanceLogDocumentRequest,
  updateSnapInSchoolsCycleRequest,
  updateSnapInSchoolsSessionRequest,
  deleteSnapInSchoolsCycleRequest,
  deleteSnapInSchoolsSessionRequest,
  getSnapInSchoolsCycleAssessmentsRequest,
  upsertFidelityAdherenceChecklistRequest,
  deleteFidelityAdherenceChecklistRequest,
  getSnapInSchoolsCycleAlertsRequest,
} from "../SnapInSchoolsRequests";

export const useGetSnapInSchoolsCycles = () =>
  useRequest(getSnapInSchoolsCyclesRequest);
export const useGetSnapInSchoolsCycle = () =>
  useRequest(getSnapInSchoolsCycleRequest);
export const useCreateSnapInSchoolsCycle = () =>
  useRequest(createSnapInSchoolsCycleRequest);
export const useUpdateSnapInSchoolsCycle = () =>
  useRequest(updateSnapInSchoolsCycleRequest);
export const useCreateSnapInSchoolsSession = () =>
  useRequest(createSnapInSchoolsSessionRequest);
export const useUpdateSnapInSchoolsSession = () =>
  useRequest(updateSnapInSchoolsSessionRequest);
export const useGetSnapInSchoolsSession = () =>
  useRequest(getSnapInSchoolsSessionRequest);
export const useGetSnapInSchoolsCurriculum = () =>
  useRequest(getSnapInSchoolsCurriculumRequest);
export const useGetSnapInSchoolsLocations = () =>
  useRequest(getSnapInSchoolsLocationsRequest);
export const useDownloadAttendanceLogDocument = () =>
  useRequest(downloadAttendanceLogDocumentRequest);
export const useDeleteSnapInSchoolsCycle = () =>
  useRequest(deleteSnapInSchoolsCycleRequest);
export const useDeleteSnapInSchoolsSession = () =>
  useRequest(deleteSnapInSchoolsSessionRequest);
export const useGetSnapInSchoolsCycleAssessments = () =>
  useRequest(getSnapInSchoolsCycleAssessmentsRequest);

export const useUpsertFidelityAdherenceChecklist = () =>
  useRequest(upsertFidelityAdherenceChecklistRequest);

export const useDeleteFidelityAdherenceChecklist = () =>
  useRequest(deleteFidelityAdherenceChecklistRequest);

export const useGetSnapInSchoolsCycleAlerts = () =>
  useRequest(getSnapInSchoolsCycleAlertsRequest);
