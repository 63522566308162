import {
  SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum,
  SnapInSchoolsSatisfactionSurveyLikertEnum,
  SnapInSchoolsSatisfactionSurveySkillEnum,
} from "./SnapInSchoolsSatisfcationSurveyEnums";
import * as Yup from "yup";
import { dateToFormString } from "../../app/helpers";
import { formatDate } from "../../app/components/labels/DateLabel";

export interface SnapInSchoolsSatisfactionSurveyResponseSetDto {
  id: string;
  snapSatisfiedWithProgram: SnapInSchoolsSatisfactionSurveyLikertEnum; // 1
  snapHelpfulSkills: SnapInSchoolsSatisfactionSurveySkillEnum[]; // 2
  snapHelpsPeople: SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum[]; // 3
}

export interface SnapInSchoolsSatisfactionSurveyDto {
  id: string;
  completedOn: Date;
  youthNameOrInitials: string;
  snapInSchoolsCycleId: string;
  satisfactionSurveyResponseSet: SnapInSchoolsSatisfactionSurveyResponseSetDto;
}

export class SnapInSchoolsSatisfactionSurveyResponseSetFormDto {
  snapSatisfiedWithProgram?: string;
  snapHelpfulSkills?: string[];
  snapHelpfulSkillsOptions?: { [x: string]: boolean };
  snapHelpsPeople?: string[];
  snapHelpsPeopleOptions?: { [x: string]: boolean };

  constructor(dto?: SnapInSchoolsSatisfactionSurveyResponseSetDto) {
    this.snapSatisfiedWithProgram = dto?.snapSatisfiedWithProgram || "";
    this.snapHelpfulSkillsOptions =
      SnapInSchoolsSatisfactionSurveyResponseSetFormDto.getSnapHelpfulSkillsOptions(
        dto
      );
    this.snapHelpsPeopleOptions =
      SnapInSchoolsSatisfactionSurveyResponseSetFormDto.getSnapHelpsPeopleOptions(
        dto
      );
  }

  private static getSnapHelpfulSkillsOptions(
    dto: SnapInSchoolsSatisfactionSurveyResponseSetDto | undefined
  ) {
    const current: string[] = dto?.snapHelpfulSkills || [];

    return Object.keys(SnapInSchoolsSatisfactionSurveySkillEnum).reduce<{
      [key: string]: boolean;
    }>((acc, value) => {
      acc[value] = current.length !== 0 && current.includes(value);
      return acc;
    }, {});
  }

  private static getSnapHelpsPeopleOptions(
    dto: SnapInSchoolsSatisfactionSurveyResponseSetDto | undefined
  ) {
    const current: string[] = dto?.snapHelpsPeople || [];

    return Object.keys(SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum).reduce<{
      [key: string]: boolean;
    }>((acc, value) => {
      acc[value] = current.length !== 0 && current.includes(value);
      return acc;
    }, {});
  }

  public static getSchema() {
    return Yup.object().shape({
      snapSatisfiedWithProgram: Yup.string().label("This").required(),
      snapHelpfulSkillsOptions: Yup.object().test(
        "test-length",
        "Must choose 3 options",
        function (values) {
          if (values === undefined) return false;

          return Object.values(values).filter((x) => x).length === 3;
        }
      ),
      snapHelpsPeopleOptions: Yup.object().test(
        "test-length",
        "Must choose 3 options",
        function (values) {
          if (values === undefined) return false;

          return Object.values(values).filter((x) => x).length === 3;
        }
      ),
    });
  }
}

export class SnapInSchoolsSatisfactionSurveyFormDto {
  completedOn: string;
  youthNameOrInitials: string;
  satisfactionSurveyResponseSet: SnapInSchoolsSatisfactionSurveyResponseSetFormDto;
  constructor(dto?: SnapInSchoolsSatisfactionSurveyDto) {
    this.youthNameOrInitials = dto?.youthNameOrInitials || "";
    this.completedOn = !!dto ? dateToFormString(new Date(dto.completedOn)) : "";
    this.satisfactionSurveyResponseSet =
      new SnapInSchoolsSatisfactionSurveyResponseSetFormDto(
        dto?.satisfactionSurveyResponseSet
      );
  }

  public static getSchema(groupStartDate?: Date) {
    return Yup.object().shape({
      completedOn: Yup.string()
        .label("Completed On")
        .required()
        .test(
          "test-completed-on-to-group-start-date",
          `Completed On must be on or after Group Start Date(${formatDate(
            new Date(groupStartDate || "")
          )})`,
          function (value) {
            if (!groupStartDate) return false;

            if (!value) return false;

            return new Date(`${value}T00:00`) >= new Date(groupStartDate);
          }
        ),
      youthNameOrInitials: Yup.string()
        .label("Youth Name/Intials")
        .max(200)
        .required(),
      satisfactionSurveyResponseSet:
        SnapInSchoolsSatisfactionSurveyResponseSetFormDto.getSchema(),
    });
  }
}

export enum SnapInSchoolsSatisfactionSurveyQuestionNames {
  snapSatisfiedWithProgram = "snapSatisfiedWithProgram",
  snapHelpfulSkills = "snapHelpfulSkills",
  snapHelpsPeople = "snapHelpsPeople",
}

export const snapInSchoolsSatisfactionSurveyQuestions: {
  [key: string]: string;
} = {
  [SnapInSchoolsSatisfactionSurveyQuestionNames.snapSatisfiedWithProgram]:
    "In general, how satisfied were you with the SNAP Program?",
  [SnapInSchoolsSatisfactionSurveyQuestionNames.snapHelpfulSkills]:
    "What skills do you feel were most helpful?",
  [SnapInSchoolsSatisfactionSurveyQuestionNames.snapHelpsPeople]:
    "Do you think SNAP helps people to",
};
