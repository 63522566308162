import { Box, List, ListItem, Typography } from "@material-ui/core";
import { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditButton from "../../../../../../app/components/buttons/EditButton";
import ListDivider from "../../../../../../app/components/ListDivider";
import { TabProps } from "../../../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../../../authentication/enums/PermissionResourceNameEnum";
import SuicideScreeningBakerActDialog from "../../../../../suicide-screenings/components/SuicideScreeningBakerActDialog";
import SuicideScreeningFormDialog from "../../../../../suicide-screenings/components/SuicideScreeningFormDialog";
import { useCaseDetailPageContext } from "../../../../pages/CaseDetailPage";
import SuicideScreeningItem from "./SuicideScreeningItem";
import ConfirmDeleteSuicideScreeningDialog from "./ConfirmDeleteSuicideScreeningModal";
import DeleteButton from "../../../../../../app/components/buttons/DeleteButton";

export const suicideScreeningSectionName = "suicide-screening-section";

const SuicideScreeningSection = () => {
  const {
    case: { suicideScreening, caseResult },
  } = useCaseDetailPageContext();
  
  const {
    startSuicideScreeningRequestState,
    handleStartSuicideScreeningClicked,
    handleUpdateSuicideScreening,
    handleDeleteSuicideScreening,
    handleDeleteSuicideScreeningClicked,
    deleteSuicideScreeningRequestState,
  } = suicideScreening;

  return (
    <Fragment>
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <SuicideScreeningFormDialog
          suicideScreeningDto={caseResult && caseResult.suicideScreenings[0]}
          onSubmit={handleUpdateSuicideScreening}
          requestState={startSuicideScreeningRequestState}
        />

        <SuicideScreeningBakerActDialog />

          <Box display="flex" justifyContent="end">
            <EditButton
              onClick={handleStartSuicideScreeningClicked}
              text={`${caseResult && caseResult?.suicideScreenings.length > 0 ? "Edit" : "Start Suicide Screening"}`}
            />
            {caseResult && caseResult.suicideScreenings.length > 0 && (
            <DeleteButton
              onClick={() => handleDeleteSuicideScreeningClicked(caseResult?.suicideScreenings[0].id)}
            />)}
            
            <ConfirmDeleteSuicideScreeningDialog onConfirm={handleDeleteSuicideScreening} requestState={deleteSuicideScreeningRequestState} />
          </Box>
        

      </WriteAccessLevelWrapper>

      {caseResult && caseResult.suicideScreenings.length === 0 && (
        <Typography>Suicide Screening Not Administered</Typography>
      )}

      {caseResult && caseResult.suicideScreenings.length > 0 && (
        <List>
          {caseResult.suicideScreenings.map((screening, index) => {
            return (
              <Fragment key={screening.id}>
                <ListItem>
                  <SuicideScreeningItem suicideScreening={screening} />
                </ListItem>
                <ListDivider
                  index={index}
                  length={caseResult.suicideScreenings.length}
                  variant="fullwidth"
                />
              </Fragment>
            );
          })}
        </List>
      )}
    </Fragment>
  );
};

export default SuicideScreeningSection;

export const createSuicideQuestionnaireSection = (): TabProps => ({
  label: "Suicide Screening",
  value: suicideScreeningSectionName,
  content: <SuicideScreeningSection />,
});
