import { useState } from "react";

export const useTogglable = (
  initialState: boolean = false
): [
  value: boolean,
  setValue: (value: boolean) => void,
  handleToggle: () => void
] => {
  const [value, setValue] = useState(initialState);

  const handleToggle = () => setValue((value) => !value);

  return [value, setValue, handleToggle];
};
