import { datetimeToFormString } from "../../../app/helpers";
import * as Yup from "yup";

export interface BakerActDto {
  id: string;
  caseId: string;
  startedAt: Date;
  notes: string;
  returnedAt?: Date;
}

export class BakerActFormDto {
  startedAt: string;
  notes: string;
  returnedAt: string;

  constructor(dto?: BakerActDto) {
    this.startedAt =
      dto != null
        ? datetimeToFormString(new Date(dto.startedAt))
        : '';
    this.notes = dto?.notes || "";
    this.returnedAt =
      dto?.returnedAt != null
        ? datetimeToFormString(new Date(dto.returnedAt))
        : "";
  }

  public static getSchema() {
    return Yup.object().shape({
      startedAt: Yup.string().label("Started At").required(),
      notes: Yup.string().label("Notes").max(1000),
      returnedAt: Yup.string()
        .label("Returned At")
        .test(
          "date-check",
          "Returned At cannot be before Started At",
          function (value) {
            if (value == null || value === "") return true;

            if (this.parent.startedAt == null || this.parent.startedAt === "")
              return true;

            return new Date(this.parent.startedAt) <= new Date(value);
          }
        ),
    });
  }
}
