import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import ExecuteAccessLevelWrapper from "../../../app/components/access-wrappers/ExecuteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import BackButton from "../../../app/components/buttons/BackButton";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import ConfirmForceSubmitCaseDialog from "../../cases/components/ConfirmForceSubmitCaseDialog";
import IntakeFormFields from "../../intakes/components/IntakeFormFields";
import YouthAddressFormDialog from "../components/modals/YouthAddressFormDialog";
import YouthAddressSection from "../components/YouthAddressSection";
import { useYouthCaseFormPage } from "../hooks/useYouthCaseFormPage";

const YouthCaseFormPage = () => {
  const { state, form, formState, handlers } = useYouthCaseFormPage();

  const {
    id,
    providerPrograms,
    locations,
    staffMembers,
    createYouthCaseRequestState,
    getProviderLocationsRequestState,
    getProviderProgramsRequestState,
    getProviderStaffMemberRequestState,
    youth,
    screenings,
    getYouthScreeningsRequestState,
    forceDisclaimer,
    youthAddress,
    updateYouthAddressRequestState,
    getYouthAddressRequestState,
    getSchoolNamesRequestState,
    schoolNames,
  } = state;
  
  const { isValid } = formState;
  
  const {
    handleSubmit,
    handleForceSubmit,
    handleUpdateYouthAddress,
    handleUpdateYouthAddressClicked,
    handleStaffAdded,
    handleStaffRemoved,
    handleFileUpdated
  } = handlers;

  return (
    <Box height="100%">
      <Box marginBottom="12px">
        <BackButton route={`/youths/${id}`} text="Back to youth" />
      </Box>
      <RequestErrorAlert
        requests={[
          getProviderLocationsRequestState,
          getProviderProgramsRequestState,
          createYouthCaseRequestState,
          getProviderStaffMemberRequestState,
          getYouthScreeningsRequestState,
          getYouthAddressRequestState,
          getSchoolNamesRequestState,
        ]}
      />
      <ExecuteAccessLevelWrapper
        name={PermissionResourceNameEnum.ForceCreateCase}
      >
        <ConfirmForceSubmitCaseDialog
          disclaimer={forceDisclaimer}
          onConfirm={handleForceSubmit}
          requestState={createYouthCaseRequestState}
        />
      </ExecuteAccessLevelWrapper>
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Youth}>
        <YouthAddressFormDialog
          address={youthAddress}
          onSubmit={handleUpdateYouthAddress}
          requestState={updateYouthAddressRequestState}
        />
      </WriteAccessLevelWrapper>
      <Card style={{ height: "80vh", overflow: "auto" }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography variant="h5" component="h5">
                  Start Case{" "}
                  {youth && `For ${youth.firstName} ${youth.lastName}`}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <YouthAddressSection
                  address={youthAddress}
                  getAddressRequestState={getYouthAddressRequestState}
                  onEditClick={handleUpdateYouthAddressClicked}
                />
              </Grid>
              <IntakeFormFields
                form={form}
                programs={providerPrograms || []}
                staffMembers={staffMembers}
                locations={locations}
                screenings={screenings}
                schoolNames={schoolNames}
                schoolNamesAreLoading={getSchoolNamesRequestState.isLoading}
                staffMembersAreLoading={
                  getProviderStaffMemberRequestState.isLoading
                }
                disabled={
                  createYouthCaseRequestState.isLoading ||
                  getYouthScreeningsRequestState.isLoading
                }
                programsAreLoading={getProviderProgramsRequestState.isLoading}
                locationsAreLoading={getProviderLocationsRequestState.isLoading}
                screeningsAreLoading={getYouthScreeningsRequestState.isLoading}
                isLegacy={false}
                onStaffMemberAdded={handleStaffAdded}
                onStaffMemberRemoved={handleStaffRemoved}
                onCourtOrderDocumentAttached={handleFileUpdated}
              />

              <Grid item md={12} xs={12}>
                <SpinnerButton
                  variant="contained"
                  fullWidth
                  showSpinner={false}
                  content="Submit"
                  color="primary"
                  type="submit"
                  disabled={!isValid || createYouthCaseRequestState.isLoading}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default YouthCaseFormPage;
