import axios, { AxiosInstance, AxiosResponse, AxiosTransformer } from "axios";
import {
  getProviderId,
  getToken,
} from "../../features/authentication/state/authenticationActions";

// shamelessly copied from Stack Overflow.
// https://stackoverflow.com/questions/70689305/customizing-date-serialization-in-axios
const dateTransformer = (data: any, headers: any): any => {
  if (headers["Content-Type"] === "multipart/form-data") return data;
  if (data instanceof Date) {
    return data.toLocaleString();
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        dateTransformer(value, headers),
      ])
    );
  }
  return data;
};

export const createAgent = (
  baseURL: string,
  existingAgent?: AxiosInstance,
  defaultIncludeApi: boolean = true
) => {
  const agent =
    existingAgent ||
    axios.create({
      baseURL,
      withCredentials: true,
      transformRequest: [
        dateTransformer,
        ...(axios.defaults.transformRequest as AxiosTransformer[]),
      ],
    });

  if (!existingAgent) {
    agent.interceptors.request.use((config) => {
      config.headers = {
        Authorization: `Bearer ${getToken()}`,
        "x-provider-id": getProviderId(),
        "x-client-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...config.headers,
      };

      return config;
    });
  }

  const responseBody = <T>(response: AxiosResponse<T>) => response.data;

  const formatUrl = (url: string, includeApi: boolean) => {
    return includeApi === true ? concatUrl(url) : url;
  };

  const concatUrl = (url: string) =>
    url.startsWith("/") ? `api${url}` : `api/${url}`;

  const getRequest = <T>(
    url: string,
    options: {} = {},
    includeApi: boolean = defaultIncludeApi
  ) => agent.get<T>(formatUrl(url, includeApi), options).then(responseBody);
  const postRequest = <T>(
    url: string,
    body: {},
    options: {} = {},
    includeApi: boolean = defaultIncludeApi
  ) =>
    agent.post<T>(formatUrl(url, includeApi), body, options).then(responseBody);
  const putRequest = <T>(
    url: string,
    body: {},
    includeApi: boolean = defaultIncludeApi
  ) => agent.put<T>(formatUrl(url, includeApi), body).then(responseBody);
  const patchRequest = <T>(
    url: string,
    body: {},
    includeApi: boolean = defaultIncludeApi
  ) => agent.patch<T>(formatUrl(url, includeApi), body).then(responseBody);
  const deleteRequest = <T>(
    url: string,
    includeApi: boolean = defaultIncludeApi
  ) => agent.delete<T>(formatUrl(url, includeApi)).then(responseBody);

  const downloadFileRequest = (
    url: string,
    filename: string,
    includeApi: boolean = defaultIncludeApi
  ) =>
    agent({
      url: formatUrl(url, includeApi),
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  return {
    agent,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    downloadFileRequest,
  };
};

export const coreAgent = createAgent(process.env.REACT_APP_CORE_API_URL || "");
export const securityAgent = createAgent(
  process.env.REACT_APP_SECURITY_API_URL || ""
);
export const invoicingAgent = createAgent(
  process.env.REACT_APP_INVOICE_URL || "",
  undefined,
  false
);

export const assessmentAgent = createAgent(
  process.env.REACT_APP_ASSESSMENT_URL || ""
);
