import {
  Box,
  Grid,
  GridSize,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

import React, { Fragment } from "react";
import { useLocation } from "react-router";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import DateTimeLabel from "../../../../app/components/labels/DateTimeLabel";
import YesNoLabel from "../../../../app/components/labels/YesNoLabel";
import StyledLink from "../../../../app/components/StyledLink";
import { TabProps } from "../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import CaseHoldTypeLabel from "../../../case-hold/components/CaseHoldTypeLabel";
import { intakeGradeLevelTextMap } from "../../../intakes/enums/IntakeGradeLevelEnum";
import { intakeSchoolStatusEnumTextMap } from "../../../intakes/enums/IntakeSchoolStatusEnum";
import { useCaseDetailPageContext } from "../../pages/CaseDetailPage";
import CaseDeletedWrapper from "../access-wrapper/CaseDeletedWrapper";
import CaseStatusLabel from "../CaseStatusLabel";
import ServiceStatusLabel from "../ServiceStatusLabel";
import HistoryIcon from "@material-ui/icons/History";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CaseDischargeSubSection from "./CaseDischargeSubSection";
import { ContentSection } from "../../../../service-planning/components/ContentSection";
import EditLinkButton from "../../../../app/components/buttons/EditLinkButton";
import { ActionRequiredIcon } from "../../../../app/components/NotificationBell";
import EditButton from "../../../../app/components/buttons/EditButton";
import KeyValueRow from "../../../../app/components/KeyValueRow";
import {ColorsEnum} from "../../../../app/enums/ColorsEnum";
export const caseDetailSectionName = "case-detail-section";

const labelSize: { [key: string]: GridSize } = {
  mdLabelSize: 5,
  xsLabelSize: 4,
  lgLabelSize: 3,
};

const CaseDetailsSection = () => {
  const {
    case: {
      activeCaseHold,
      caseResult,
      screening,
      intake,
      programLocation,
      handleUpdateCaseClicked,
      handleViewCourtOrderAttachmentClicked,
    },
    caseHold,
  } = useCaseDetailPageContext();

  const { handleSetCaseScreeningClicked } = screening;
  const { handleEndCaseHoldClicked } = caseHold;
  const { handleChangeIntakeDateClicked } = intake;
  const {
    handleViewProgramLocationHistoryClicked,
  } = programLocation;
  
  const location = useLocation();
  //#region UI Helpers
  const createRow = (
    label: string | React.ReactNode,
    content: React.ReactNode
  ) => {
    return (
      <KeyValueRow {...labelSize}>
        <span>{label}</span>
        <span>{content}</span>
      </KeyValueRow>
    );
  };
  //#endregion

  if (caseResult === undefined) return <></>;

  const programInformationSection = () => {
    return (
      <Grid item md={12} xs={12}>
        <ContentSection
          label="Program Information"
          content={
            <>
              <KeyValueRow {...labelSize}>
                <span>Program</span>
                <Box display="flex" alignItems="center">
                  <Typography>{caseResult.program.name}</Typography>
                </Box>
              </KeyValueRow>

              <KeyValueRow {...labelSize}>
                <span>Program Type</span>
                <Typography>{caseResult.program.programType.name}</Typography>
              </KeyValueRow>

              <KeyValueRow {...labelSize}>
                <span>Location</span>
                <Box display="flex" alignItems="center">
                  <Typography>
                    {caseResult.location.name} - {caseResult.location.city},{" "}
                    {caseResult.location.state}
                  </Typography>
                  <Tooltip
                    title={<Typography>View/Update Case Location History</Typography>}
                  >
                    <IconButton
                      style={{ padding: "2px", marginLeft: "6px" }}
                      onClick={handleViewProgramLocationHistoryClicked}
                      color={"primary"}
                    >
                      <HistoryIcon htmlColor={ColorsEnum.Blue} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </KeyValueRow>
            </>
          }
        />
      </Grid>
    );
  };

  return (
    <Box height={"60vh"}>
      <Grid container spacing={2}>
        {programInformationSection()}
        <Grid item md={12} xs={12}>
          <ContentSection
            label="Intake Information"
            content={
              <Fragment>
                {createRow(
                  "Status",
                  <Box display="flex" alignItems="center">
                    <div>
                      <CaseStatusLabel caseDto={caseResult} />
                    </div>

                    {activeCaseHold && (
                      <CaseDeletedWrapper
                        isDeleted={caseResult.deletedAt !== undefined}
                      >
                        <EditLinkButton
                          style={{ marginLeft: "6px" }}
                          onClick={handleEndCaseHoldClicked}
                        >
                          End Hold
                        </EditLinkButton>
                      </CaseDeletedWrapper>
                    )}
                    <WriteAccessLevelWrapper
                      name={PermissionResourceNameEnum.Case}
                    >
                      {caseResult.deletedAt == null && (
                        <EditButton
                          className="ml-auto"
                          onClick={handleUpdateCaseClicked}
                        />
                      )}
                    </WriteAccessLevelWrapper>
                  </Box>
                )}
                {createRow(
                  "Intake Date/Time",
                  <Box display="flex" alignItems="center">
                    <Typography>
                      <DateTimeLabel date={caseResult.intakeInfo.intakeAt} />
                    </Typography>
                    <CaseDeletedWrapper
                      isDeleted={caseResult.deletedAt !== undefined}
                    >
                      <WriteAccessLevelWrapper
                        name={PermissionResourceNameEnum.Case}
                      >
                        <EditLinkButton
                          onClick={handleChangeIntakeDateClicked}
                          style={{ marginLeft: "6px" }}
                        />
                      </WriteAccessLevelWrapper>
                    </CaseDeletedWrapper>
                  </Box>
                )}

                {caseResult.deletedAt !== undefined &&
                  createRow(
                    "Deleted At",
                    <Typography>
                      <DateTimeLabel date={caseResult.deletedAt} />
                    </Typography>
                  )}

                {caseResult.deleteReason != null &&
                  caseResult.deleteReason !== "" &&
                  createRow(
                    "Delete Reason",
                    <Typography>{caseResult.deleteReason}</Typography>
                  )}
                {activeCaseHold &&
                  caseResult.deletedAt === undefined &&
                  createRow(
                    "Case Hold",
                    <Typography>
                      <DateTimeLabel date={activeCaseHold.startAt} /> (
                      <CaseHoldTypeLabel
                        type={activeCaseHold.type}
                        component="span"
                      />
                      )
                    </Typography>
                  )}
                {createRow(
                  "Provider",
                  <Typography>{caseResult.provider.name}</Typography>
                )}

                {caseResult.screening &&
                  createRow(
                    "Screening #",

                    <Typography>
                      <StyledLink
                        to={{
                          pathname: `/screenings/${caseResult.screening.id}`,
                          state: {
                            prevLocation: location.pathname,
                            text: "Back to case",
                          },
                        }}
                      >
                        {caseResult.screening.referenceNumber}
                      </StyledLink>
                    </Typography>
                  )}

                {caseResult.screening === undefined &&
                  createRow(
                    "Screening #",
                    <WriteAccessLevelWrapper
                      name={PermissionResourceNameEnum.Case}
                    >
                      <CaseDeletedWrapper
                        isDeleted={caseResult.deletedAt !== undefined}
                      >
                        <Box display="flex" alignContent="center">
                          <EditLinkButton
                            onClick={handleSetCaseScreeningClicked}
                          >
                            Set Screening
                          </EditLinkButton>
                          <Tooltip
                            style={{ marginLeft: "4px", cursor: "pointer" }}
                            title={<Typography>Screening Required</Typography>}
                          >
                            <div>
                              <ActionRequiredIcon />
                            </div>
                          </Tooltip>
                        </Box>
                      </CaseDeletedWrapper>
                    </WriteAccessLevelWrapper>
                  )}
                {createRow(
                  "Service Status",
                  <ServiceStatusLabel status={caseResult.serviceStatus} />
                )}
                {createRow(
                  "Court Ordered",
                  
                  <Box display="flex" alignItems="center">
                    <YesNoLabel value={caseResult.isCourtOrdered} />
                    
                    {caseResult.courtOrderAttachment &&
                    <Tooltip title={<Typography>View/Download Court Order</Typography>}>
                      <IconButton
                        style={{ padding: "2px", marginLeft: "8px" }}
                        onClick={() => handleViewCourtOrderAttachmentClicked(caseResult)}
                        color={"primary"}
                        
                      >                 
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                    }
                  </Box>                  
                )}

                {createRow(
                  "NIRVANA Assessment Initiated",
                  <Fragment>
                    <YesNoLabel value={caseResult.nirvanaAssessmentInitiated} />
                  </Fragment>
                )}

                {createRow(
                  "Intake Staff",
                  <Typography>
                    {caseResult.intakeInfo.intakeStaff.firstName}{" "}
                    {caseResult.intakeInfo.intakeStaff.lastName}
                  </Typography>
                )}

                {caseResult.primaryStaff &&
                  createRow(
                    "Primary Staff",                                       
                      caseResult.primaryStaff.map((s) => 
                      <Typography>{s.firstName} {s.lastName}</Typography>
                  ))}

                {createRow(
                  "Youth's Age At Intake",
                  <Typography>{caseResult.intakeInfo.youthAge}</Typography>
                )}
                {createRow(
                  "Is Youth on Medication",
                  <Typography>
                    {caseResult.intakeInfo.isYouthOnMedication ? "Yes" : "No"}
                  </Typography>
                )}

                {caseResult.intakeInfo.isYouthOnMedication &&
                  createRow(
                    "Number of Medications",
                    <Typography>
                      {caseResult.intakeInfo.youthMedicationCount}
                    </Typography>
                  )}

                {createRow(
                  "School Status at Intake",
                  intakeSchoolStatusEnumTextMap[
                    caseResult.intakeInfo.schoolStatus
                  ]
                )}

                {caseResult.intakeInfo.schoolName &&
                  createRow(
                    "School Name",
                    <Typography>
                      {caseResult.intakeInfo.schoolName}
                    </Typography>                  
                )}

                {caseResult.intakeInfo.lastGradeAttended &&
                  createRow(
                    "Last Grade Attended",
                    intakeGradeLevelTextMap[
                      caseResult.intakeInfo.lastGradeAttended
                    ]
                  )}
                
                {caseResult.intakeInfo.currentGrade &&
                  createRow(
                    "Current Grade",
                    intakeGradeLevelTextMap[caseResult.intakeInfo.currentGrade]
                  )}
              </Fragment>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <ContentSection
            label="Discharge Information"
            content={
              caseResult.dischargeInfo ? (
                <CaseDischargeSubSection />
              ) : (
                <Typography>This Case is not yet discharged.</Typography>
              )
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseDetailsSection;
export const createCaseDetailsSection = (): TabProps => ({
  label: "Overview",
  value: caseDetailSectionName,
  content: <CaseDetailsSection />,
});
