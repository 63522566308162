import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GreenAlertLabel from "../../../app/components/labels/GreenAlertLabel";
import { WaitlistDto } from "../dtos/WaitlistDto";
import { WaitlistScreeningDto } from "../dtos/WaitlistScreeningDto";

interface Props {
  waitlist: WaitlistDto | WaitlistScreeningDto;
  hideTooltip?: boolean;
  prevLocationLabel?: string;
}

export const WaitlistCaseCreatedLabel: React.FC<Props> = ({ waitlist, hideTooltip, prevLocationLabel: backText }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box
      style={{ cursor: "pointer" }}
      component="span"
      onClick={() => {
        history.push({
          pathname: `/cases/${waitlist.case?.id}`,
          state: { prevLocation: location.pathname, text: backText || "Back to screening" },
        });
      }}
    >
      <Tooltip
        disableHoverListener={hideTooltip}
        title={
          <Fragment>
            <Typography component="span">
              {waitlist.case?.caseNumber}
            </Typography>
          </Fragment>
        }
      > 
        <Box component="span">
          <GreenAlertLabel
            label={"Case Created"}
            show={"true"}
            component="span"
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
