import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import { AlertDismissalSearchParamsDto } from "../dtos/AlertDismissalDto";
import { useAlertDismissalFilter } from "../hooks/useAlertDismissalFilter";
import {
  ProgramTypeDto,
  programTypesToOptions,
} from "../../program-types/dtos/ProgramTypeDto";
import { AlertTypeEnum, alertTypeTextMap } from "../enums/AlertTypeEnum";

const alertTypes = [
  AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired,
  AlertTypeEnum.CaseNirvanaAssessmentRequired,
  AlertTypeEnum.CaseNirvanaPostAssessmentRequired,
  AlertTypeEnum.CaseTopsePreAssessmentRequired,
  AlertTypeEnum.CaseTopsePostAssessmentRequired,
  AlertTypeEnum.IcmCaseLengthOfStay,
  AlertTypeEnum.ProbationRespiteCaseLengthOfStay,
  AlertTypeEnum.ShelterCaseLengthOfStay,
  AlertTypeEnum.SnapCaseLengthOfStay,
  AlertTypeEnum.CommunityCounselingCaseLengthOfStay
];

const alertTypeOptions: SelectOption[] = [
  { value: "", label: "All" },
  ...alertTypes.map((value) => ({ value, label: alertTypeTextMap[value] })),
];

interface Props {
  params: AlertDismissalSearchParamsDto;
  anchorEl: HTMLElement | null;
  providers: ProviderDto[];
  programTypes: ProgramTypeDto[];
  onClose(): void;
  onSearch(dto: AlertDismissalSearchParamsDto): void;
}

const AlertDismissalFilter: React.FC<Props> = ({
  params,
  anchorEl,
  providers,
  programTypes,
  onClose,
  onSearch,
}) => {
  //#region State
  const {
    form: { control, clearAllFilters },
    handlers: { handleSubmit },
  } = useAlertDismissalFilter(onSearch, onClose, params);
  //#endregion

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">Alert Dismissal Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            {/* Provider Name */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="providerId"
                label="Provider"
                fullWidth
                options={providerToOptionDtos(providers, [], "All")}
              />
            </Grid>

            {/* Program Types */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="programTypeId"
                label="Program Type"
                options={programTypesToOptions(programTypes, "All")}
                fullWidth
              />
            </Grid>

            {/* Alert Type */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="type"
                label="Alert Type"
                options={alertTypeOptions}
                fullWidth
              />
            </Grid>

            {/* Start Date */}
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="startOn"
                label="Start Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="endOn"
                label="End Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default AlertDismissalFilter;
