import { Avatar, Box, Paper, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import Section from "../../../../app/components/Section";
import { Skeleton } from "@material-ui/lab";
import { CaseDto } from "../../../cases/dtos/CaseDto";
import ServiceStatusLabel from "../../../cases/components/ServiceStatusLabel";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DateTimeLabel from "../../../../app/components/labels/DateTimeLabel";
import CaseDeletedLabel from "../../../youths/components/labels.tsx/CaseDeletedLabel";
import { ColorsEnum } from "../../../../app/enums/ColorsEnum";
import KeyValueDetails, {
  RowProps,
} from "../../../../app/components/KeyValueDetails";
import RouteableStyledLink from "../../../../app/components/RouteableStyledLink";
import {getProviderId} from "../../../authentication/state/authenticationActions";
import {fnyfsProviderId} from "../../../authentication/constants";
interface Props {
  caseDto: CaseDto;
}

export const CaseSummaryListLoadingItem = () => {
  return (
    <Section>
      <Box display="flex">
        <Skeleton
          variant="rect"
          width="35px"
          height="35px"
          style={{ marginRight: "8px" }}
        />
        <Typography variant="h5">
          <Skeleton variant="text" width="200px" />
        </Typography>
      </Box>
    </Section>
  );
};

const CaseSummaryListItem: React.FC<Props> = ({ caseDto }) => {

  const providerId = getProviderId();
  const isFnyfs = providerId === fnyfsProviderId;

  const rows: RowProps[] = [
    { key: "provider", label: "Provider", value: caseDto.program.providerName },
    { key: "programType", label: "Program Type", value: caseDto.program.programType.name },
    { key: "case#", label: "Case #", value: caseDto.caseNumber },
    {
      key: "service",
      label: "Service Status",
      value: <ServiceStatusLabel status={caseDto.serviceStatus} />,
    },
    {
      key: "intake",
      label: "Intake Date/Time",
      value: <DateTimeLabel date={caseDto.intakeInfo.intakeAt} />,
    },
    {
      key: "location",
      label: "Location",
      value: `${caseDto.location.name} - ${caseDto.location.city}, 
    ${caseDto.location.state}`,
    },
    {
      key: "discharge",
      label: "Discharge Date/Time",
      value: caseDto.dischargedDate ? <DateTimeLabel date={caseDto.dischargedDate} /> : <></>,
    },
    {
      key: "bakerActCount",
      label: "Baker Acts",
      value: caseDto.bakerActCount,
    }

  ];

  return (
    <Fragment>
      <Paper variant="outlined">
        <Box display="flex" padding="12px">
          <Avatar
            style={{
              marginRight: "12px",
            }}
          >
            <BusinessCenterIcon />
          </Avatar>
          <Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h6"
                component="h6"
                style={{ marginRight: "12px" }}
              >
                {(isFnyfs || providerId === caseDto.program.providerId) && <RouteableStyledLink
                  pathname={`/cases/${caseDto.id}`}
                  text="Back to Youth"
                  style={{
                    display: "inline-block",
                  }}
                >
                  {caseDto.program.name}
                </RouteableStyledLink>}
                {(!isFnyfs && providerId !== caseDto.program.providerId) && <>{caseDto.program.name}</>}
              </Typography>
              {caseDto.isActive === false && (
                <Typography
                  style={{
                    padding: "0px 12px",
                    fontWeight: "bold",
                    borderRadius: "12px",
                    background: ColorsEnum.Red,
                    color: "white",
                  }}
                >
                  Closed
                </Typography>
              )}
              <CaseDeletedLabel isDeleted={caseDto.isDeleted} />
            </Box>
            <KeyValueDetails
              color="textSecondary"
              rows={rows}
              mdLabelSize={3}
              mdContentSize={3}
              lgLabelSize={2}
              lgContentSize={4}
            />
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default CaseSummaryListItem;
