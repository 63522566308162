import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import Loader from "./Loader";

const useStyles = makeStyles((theme) => ({
  invoiceContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
  },
}));

interface Props {
  document?: Blob;
  isLoading: boolean;
  loadingText?: string;
}

const PdfContainer: React.FC<Props> = ({
  document,
  isLoading,
  loadingText,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.invoiceContainer}
      flexGrow={"1"}
      overflow={"hidden"}
    >
      {!isLoading && document !== undefined && (
        <iframe
          src={URL.createObjectURL(document)}
          title="invoice-document"
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      )}
      <Loader isLoading={isLoading} text={loadingText} />
    </Box>
  );
};

export default PdfContainer;
