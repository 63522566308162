import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { Fragment } from "react";
import {
  DemographicsParentalStatusEnum,
  demographicsParentalStatusTextMap,
} from "../../enums/DemographicsParentalStatusEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  status?: DemographicsParentalStatusEnum;
  defaultValue?: string;
}

const DemographicsParentalStatusLabel: React.FC<Props> = ({
  status,
  defaultValue,
  ...props
}) => {
  if (status == null && defaultValue == null) return <Fragment></Fragment>;
  const value =
    status == null ? defaultValue : demographicsParentalStatusTextMap[status];

  return <Typography {...props}>{value}</Typography>;
};

export default DemographicsParentalStatusLabel;
