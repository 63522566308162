import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";
import { AssessmentOverrideReasonFormDto } from "../../assessments/dtos/AssessmentOverrideReasonDto";
import { AlertDismissalRequestDto } from "../dtos/AlertDismissalDto";
import { AlertTypeEnum } from "../enums/AlertTypeEnum";

export const useAlertDismissalForm = (
  onSubmit: (dto: AlertDismissalRequestDto) => Promise<any>,
  type: AlertTypeEnum,
  caseDto?: CaseDetailDto,
  snapCycle?: SnapInSchoolsCycleDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
  } = useForm({
    defaultValues: new AlertDismissalRequestDto(),
    resolver: yupResolver(AlertDismissalRequestDto.getSchema()),
    mode: "all",
  });

  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit(
    async (dto: AssessmentOverrideReasonFormDto) => {
      await onSubmit(dto);
      reset(new AlertDismissalRequestDto());
    }
  );

  const clearForm = useCallback(() => {
    if (snapCycle != null) {
      reset(AlertDismissalRequestDto.fromSnapInSchoolsCycle(snapCycle, type));
    } else if (caseDto != null) {
      reset(AlertDismissalRequestDto.fromCase(caseDto, type));
    }
  }, [snapCycle, reset, caseDto, type]);
  //#endregion

  //#region Effects

  useEffect(() => {
    clearForm();
  }, [clearForm]);
  //#endregion

  return {
    form: { control, isValid, clearForm, setValue },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
