import React, { Fragment } from "react";
import { TabProps } from "../../../../../app/components/TabContainer";
import { useCaseDetailPageContext } from "../../../pages/CaseDetailPage";
import EditButton from "../../../../../app/components/buttons/EditButton";
import NonBillableFormDialog from "../../NonBillableDayFormDialog";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import WriteAccessLevelWrapper from "../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../../authentication/enums/PermissionResourceNameEnum";
import EventIcon from "@material-ui/icons/Event";
import DateLabel from "../../../../../app/components/labels/DateLabel";

export const nonBillableDaysTabName = "non-billable-days-section";

const NonBillableDaysSection = () => {
  const {
    case: { caseResult },
    nonBillableDays,
  } = useCaseDetailPageContext();
  const {
    upsertNonBillableDaysRequestState,
    handleUpsertNonBillableDays,
    handleUpsertNonBillableDaysClicked,
  } = nonBillableDays;

  const sortedDates = () =>
    caseResult?.nonBillableDays?.sort((a, b) => (a > b ? 1 : -1)) || [];

  //#region UI Helpers
  const renderDate = (date: Date) => (
    <Fragment key={date.toString()}>
      <ListItem>
        <ListItemIcon>
          <Avatar>
            <EventIcon />
          </Avatar>
        </ListItemIcon>
        <ListItemText primary={<DateLabel date={date} />} />
      </ListItem>
      <Divider />
    </Fragment>
  );
  //#endregion
  return (
    <div>
      <NonBillableFormDialog
        onSubmit={handleUpsertNonBillableDays}
        requestState={upsertNonBillableDaysRequestState}
        caseDto={caseResult}
      />
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <EditButton
          text="Update Non-Billable Days"
          onClick={handleUpsertNonBillableDaysClicked}
        />
      </WriteAccessLevelWrapper>

      <Box marginBottom="8px">
        <Typography color="textSecondary">
          The following dates have been marked Non-Billable, and will not be
          invoiced.
        </Typography>
      </Box>

      <List>{sortedDates().map(renderDate)}</List>
      {caseResult?.nonBillableDays?.length === 0 && (
        <Typography>No days have been marked as Non-Billable</Typography>
      )}
    </div>
  );
};

export default NonBillableDaysSection;
export const createNonBillableDaysTab = (): TabProps => ({
  label: "Non-Billable Days",
  value: nonBillableDaysTabName,
  content: <NonBillableDaysSection />,
});
