import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../../app/components/ErrorText";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectModalId } from "../../../modal/state/modalSelectors";
import { closeModal } from "../../../modal/state/modalSlice";
import { SnapGroupCycleDto } from "../../dtos/SnapGroupCycleDto";
import { SnapFidelityFormDto } from "../../dtos/SnapFidelityFormDto";
import { SnapGroupDto } from "../../dtos/SnapGroupDto";
import { useSnapGroupFidelityForm } from "../../hooks/useSnapGroupFidelityForm";
import SnapGroupFidelityFormFields from "./SnapGroupFidelityFormFields";

export const snapGroupFidelityModalId = "snap-group-fidelity-form-modal";

interface Props {
  snapGroup?: SnapGroupDto;
  snapCycle?: SnapGroupCycleDto;
  requestState: RequestStateDto;
  onSubmit: (dto: SnapFidelityFormDto) => Promise<any>;
}

const SnapGroupFidelityFormDialog: React.FC<Props> = ({
  snapGroup,
  snapCycle,
  requestState,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useSnapGroupFidelityForm(
    onSubmit,
    snapGroup,
    snapCycle
  );
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === snapGroupFidelityModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {snapGroup?.fidelityInfo ? "Update" : "Add"} SNAP Group Fidelity
          Score
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <SnapGroupFidelityFormFields
              control={control}
              isDisabled={isLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SnapGroupFidelityFormDialog;
