import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ReferralFormFields from "../components/ReferralFormFields";
import { ReferralSummaryDto } from "../dtos/ReferralSummaryDto";
import { useReferralFormPage } from "../hooks/useReferralFormPage";
import ReferralSuicideAlert from "../components/ReferralSuicideAlert";
import ReCAPTCHA from "react-google-recaptcha";
const ReferralFormPage = () => {
  //#region State
  const { state, form, handlers } = useReferralFormPage();
  const { control, isValid } = form;
  const { handleSubmit, handleCaptchaChanged } = handlers;
  const { referral, createReferralRequestState, isReferralSelf } = state;
  //#endregion

  const getSubmissionCompleteText = (referral: ReferralSummaryDto) => {
    if (referral.state === "FL")
      return (
        <Fragment>
          Thank you for submitting this referral. The Referral Number is:{" "}
          {referral.referralNumber}
        </Fragment>
      );

    return (
      <Fragment>
        We apologize for the inconvenience. The service providers supported by
        the Florida Network of Youth and Family Services (Florida Network) can only offer
        services to youth who reside within the State of Florida.
      </Fragment>
    );
  };

  if (referral)
    return (
      <Fragment>
        <Card>
          <CardContent>
            <Typography variant="h5">Submission Complete</Typography>
            <Typography>{getSubmissionCompleteText(referral)}</Typography>
          </CardContent>
        </Card>
      </Fragment>
    );

  return (
    <div>
      <ReferralSuicideAlert isReferralSelf={isReferralSelf === "true"} />
      <Card>
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Typography variant="h5" style={{ marginBottom: "8px" }}>
              Create a Referral
            </Typography>
            <Grid container spacing={2}>
              <ReferralFormFields
                control={control}
                isDisabled={createReferralRequestState.isLoading}
              />
              <Grid item md={12} xs={12}>
                <Box display="flex" justifyContent="center">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                    onChange={handleCaptchaChanged}
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <SpinnerButton
                  variant="contained"
                  fullWidth
                  showSpinner={createReferralRequestState.isLoading}
                  content="Submit"
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                />
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

export default ReferralFormPage;
