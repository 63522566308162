import { Box, Divider, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import DateLabel from "../../../app/components/labels/DateLabel";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { CaseStaffingDto } from "../dtos/CaseStaffingDto";
import { petitionTypeTextMap } from "../enums/PetitionTypeEnum";
import { reasonForPetitionTextMap } from "../enums/ReasonForPetitionEnum";

interface Props {
  staffing: CaseStaffingDto;
  caseDto?: CaseDetailDto;
}

const CinsPetitionPrintView = React.forwardRef<HTMLDivElement, Props>(
  ({ staffing, caseDto }, ref) => {
    //#region UI Helpers
    const createRow = (label: React.ReactNode, value: React.ReactNode) => {
      return (
        <Typography>
          <strong>{label}: </strong> {value}
        </Typography>
      );
    };

    //#endregion

    return (
      <div
        style={{ padding: "24px", position: "relative", height: "95vh" }}
        ref={ref}
      >
        <Typography variant="h4">
          Case Staffing - <DateLabel date={staffing.staffingOn} />
        </Typography>
        <Box marginBottom="12px" marginTop="12px">
          {createRow(
            "Youth Name",
            `${caseDto?.youth.firstName} ${caseDto?.youth.lastName}`
          )}
          {createRow("Youth ID", caseDto?.youth.referenceNumber)}
          {createRow("Case Number", caseDto?.caseNumber)}
          {createRow("Program", caseDto?.program.name)}
          {createRow("Program Type", caseDto?.program.programType.name)}
        </Box>
        <Divider />
        {staffing.cinsPetitionInfo != null && (
          <Fragment>
            <Box marginBottom="12px" marginTop="12px">
              {createRow(
                "Petition Open Date",
                <DateLabel date={staffing.cinsPetitionInfo.openedOn} />
              )}

              {staffing.cinsPetitionInfo.closedOn &&
                createRow(
                  "Petition Closed Date",
                  <DateLabel date={staffing.cinsPetitionInfo.closedOn} />
                )}
              {createRow(
                "Petition Type",
                petitionTypeTextMap[staffing.cinsPetitionInfo.petitionType]
              )}
              {staffing.cinsPetitionInfo.reasonForPetition &&
                createRow(
                  "Reason for Petition",
                  reasonForPetitionTextMap[
                    staffing.cinsPetitionInfo.reasonForPetition
                  ]
                )}
            </Box>
            <Typography>
              <strong>Notes:</strong>
            </Typography>
            <Divider />
            <Typography>{staffing.cinsPetitionInfo.notes}</Typography>
          </Fragment>
        )}

        <Box position="absolute" bottom="24px" right="24px">
          <DateLabel date={new Date()} />
        </Box>
        <Box position="absolute" bottom="24px" left="24px">
          {caseDto?.provider.name}
        </Box>
      </div>
    );
  }
);

export default CinsPetitionPrintView;
