import { Box, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";

import { createSnapGroupTab } from "./SnapGroupSection";
import { useSnapGroupCycleDetailPageContext } from "../pages/SnapGroupCycleDetailPage";

export const snapCycleGroupsTabName = "snap-cycle-groups";

const SnapGroupCycleGroupsSection: React.FC = () => {
  const [tabs, setTabs] = useState<TabProps[]>([]);

  const context = useSnapGroupCycleDetailPageContext();
  const {
    snapCycle,
    getSnapCycleRequestState: { isLoading },
  } = context.snapCycle;
  const { showDeleted } = context.ui;
  const { alerts } = context.alert;
  const {
    selectedGroupId,
    handleChangeSelectedGroup,
    handleCreateSnapGroupClicked,
  } = context.group;

  useEffect(() => {
    if (snapCycle?.groups && snapCycle.groups.length > 0) {
      const tabs: TabProps[] = snapCycle.groups
        .filter((c) => c.deletedAt == null || showDeleted)
        .sort((a, b) => (a.groupHeldOn > b.groupHeldOn ? 1 : -1))
        .map((group) =>
          createSnapGroupTab({
            group,
            alerts: alerts.filter((x) => x.referenceId === group.id),
          })
        );
      setTabs(tabs);
    } else {
      setTabs([]);
    }
  }, [snapCycle, setTabs, showDeleted, alerts]);

  if (!snapCycle) return <></>;

  return (
    <Box height="60vh">
      <Box padding="16px">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <AddButton
            text="Create SNAP Group"
            onClick={handleCreateSnapGroupClicked}
            color="primary"
            variant="contained"
          />
        </WriteAccessLevelWrapper>
      </Box>
      <Box>
        {tabs.length > 0 && (
          <TabContainer
            tabs={tabs}
            onIndexChange={(e: any, tabIndex: string) => {
              handleChangeSelectedGroup(tabIndex);
            }}
            index={selectedGroupId}
            orientation="vertical"
            disabled={isLoading}
          />
        )}
        {tabs.length === 0 && <Typography>No Groups Created</Typography>}
      </Box>
    </Box>
  );
};

export default SnapGroupCycleGroupsSection;

export const createSnapGroupCycleGroupsSectionTab = (): TabProps => {
  return {
    value: snapCycleGroupsTabName,
    label: "Groups",
    content: <SnapGroupCycleGroupsSection />,
  };
};
