import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { UseFormReturn, useFormState, useWatch} from "react-hook-form";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect, { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import ServiceStatusLabel from "../../cases/components/ServiceStatusLabel";
import { ServiceStatusEnum } from "../../cases/enums/ServiceStatusEnum";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import * as Yup from "yup";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import { CaseUpdateFormDto } from "../../cases/dtos/CaseFormDto";
import { JjisProgramTypeEnum } from "../../jjis/enums/JjisProgramTypeEnum";
import { ScreeningDto } from "../../screenings/dtos/ScreeningDto";
import { calculateDateDifferenceInDays } from "../../../app/helpers";
import { IntakeSchoolStatusEnum, intakeSchoolStatusOptions } from "../enums/IntakeSchoolStatusEnum";
import { IntakeGradeLevelEnum, intakeGradeLevelOptions } from "../enums/IntakeGradeLevelEnum";
import ControlledAutocomplete from "../../../app/components/inputs/ControlledAutocomplete";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
import { DemographicsFamilyStructureEnum, DemographicsFamilyStructureEnumOptions } from "../../demographics/enums/DemographicsFamilyStructureEnum";
import ScreeningSelect from "../../screenings/components/ScreeningSelect";
import { StaffMemberControlledAutoComplete } from "../../staff-members/components/StaffMemberControlledAutoComplete";
import {MultiStaffMemberControlledSelector} from "../../staff-members/components/MultiStaffMemberControlledSelector";
import CaseCourtOrderAttachmentUploadComponent from "../../cases/components/CaseCourtOrderAttachmentUploadComponent";

export const lastGradeAttendedStatus: string[] = [
  IntakeSchoolStatusEnum.DroppedOut,
  IntakeSchoolStatusEnum.Expelled,
  IntakeSchoolStatusEnum.DoNotKnow,
  IntakeSchoolStatusEnum.NotEnrolled,
];

export const currentGradeStatus: string[] = [
  IntakeSchoolStatusEnum.AttendingIrregularly,
  IntakeSchoolStatusEnum.AttendingRegularly,
  IntakeSchoolStatusEnum.Suspended,
  IntakeSchoolStatusEnum.NotInSession,
];

export const intakeAtSchema = Yup.date().label("Intake Date/Time").typeError("Invalid Date/Time format.").required("Discharge Date may not be a future date.");

export const intakeSchema = Yup.object().shape({
  intakeAt: intakeAtSchema
    .test("validate-not-equal-to-dob", "The Intake Date cannot be the same or prior to the Youth's Date of Birth.", function (value) {
      if (value && this.parent.youthDob) {
        const intakeAt = new Date(value);
        intakeAt.setHours(0, 0, 0, 0);
        const youthDob = new Date(this.parent.youthDob);
        youthDob.setHours(0, 0, 0, 0);
        if (intakeAt <= youthDob) {
          return false;
        }
      }
      return true;
    })
    .test(
      "validate-screening-date",
      // eslint-disable-next-line no-template-curly-in-string
      "${label} can be no more than 60 days after the screening date/time.",
      function (value) {
        if (this.parent.screeningAt == null || this.parent.screeningAt === "") return true;

        if (value) {
          const intakeAt = new Date(value);
          const screeningAt = new Date(this.parent.screeningAt);
          const dateDiff = calculateDateDifferenceInDays(intakeAt, screeningAt);

          if (dateDiff >= 60) return false;
        }

        return true;
      }
    ),
  serviceStatus: Yup.string()
    .label("Service Status")
    .required()
    .test("valid-service-status", "Invalid Service Status", function (value) {
      return Object.keys(ServiceStatusEnum).includes(value || "");
    }),
  youthMedicationCount: Yup.string().when("isYouthOnMedication", {
    is: true,
    then: Yup.string()
      .label("Number of Medications")
      .required()
      .test("valid-youth-medication", "Number must be greater than 0 if Youth is on medication.", (value) => {
        if (value === undefined || value === "") return true;

        return !isNaN(+value) && +value > 0;
      }),
  }),
  intakeStaffId: Yup.string().label("Staff Completing Intake").required(),
  schoolStatus: Yup.string().label("School Status").required(),
  lastGradeAttended: Yup.string()
    .label("Last Grade Attended")
    .when("schoolStatus", {
      is: (value: string) => lastGradeAttendedStatus.includes(value || ""),
      then: Yup.string().required(),
    }),
  currentGrade: Yup.string()
    .label("Current Grade")
    .when("schoolStatus", {
      is: (value: string) => currentGradeStatus.includes(value || ""),
      then: Yup.string().required(),
    }),
  schoolName: Yup.string()
    .label("School Name")
    .when("schoolStatus", {
      is: (value: string) => currentGradeStatus.includes(value || ""),
      then: Yup.string().required().max(200).nullable(),
    }),
  familyStructure: Yup.string()
    .label("Family Structure")
    .when("fundingSource", {
      is: ProgramFundingSourceEnum.FloridaNetwork,
      then: (schema) => schema.required(),
    }),
  courtOrderDocumentFilename: Yup.string()
    .label("Court Order Document")
    .when("isCourtOrdered", {
      is: true,
      then: (schema) => schema.required("Documentation is required for Court Ordered cases."),
    }),    
});

interface Props {
  form: UseFormReturn<CaseUpdateFormDto>;
  disabled: boolean;
  program: ProgramDto;
  staffMembers: StaffMemberDto[];
  screenings: ScreeningDto[];
  screeningsAreLoading: boolean;
  staffMembersAreLoading: boolean;
  schoolNames: string[];
  schoolNamesAreLoading: boolean;
  isLegacy: boolean;
  onStaffMemberAdded: (id: string) => void;
  onStaffMemberRemoved: (id: string) => void;
}

const serviceStatusOptions: SelectOption[] = [
  { value: "", label: "Select Service Status" },
  {
    value: ServiceStatusEnum.FullAdmission,
    label: <ServiceStatusLabel status={ServiceStatusEnum.FullAdmission} />,
  },
  {
    value: ServiceStatusEnum.OrientationProbation,
    label: <ServiceStatusLabel status={ServiceStatusEnum.OrientationProbation} />,
  },
  {
    value: ServiceStatusEnum.DropIn,
    label: <ServiceStatusLabel status={ServiceStatusEnum.DropIn} />,
  },
  {
    value: ServiceStatusEnum.Hotline,
    label: <ServiceStatusLabel status={ServiceStatusEnum.Hotline} />,
  },
  {
    value: ServiceStatusEnum.StreetOutreach,
    label: <ServiceStatusLabel status={ServiceStatusEnum.StreetOutreach} />,
  },
];



const UpdateCaseIntakeFormFields: React.FC<Props> = ({
  form,
  disabled,
  program,
  staffMembers,
  screenings,
  schoolNames,
  screeningsAreLoading,
  staffMembersAreLoading,
  schoolNamesAreLoading,
  isLegacy,
  onStaffMemberAdded,
  onStaffMemberRemoved
}) => {

  const { control } = form;

  const { 
    isYouthOnMedication, 
    screeningId, 
    schoolStatus, 
    fundingSource, 
    isCourtOrdered,
    familyStructure
  } = useWatch({ control });

  const { setValue, trigger } = form;  

  const { errors } = useFormState({ control });
  
  const { courtOrderDocumentFilename } = useWatch({ control });

  const handleFileUpdated = (filename: string) => {
    setValue("courtOrderDocumentFilename", filename);
    trigger("courtOrderDocumentFilename");
  }
  
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="intakeAt"
          label="Intake Date/Time*"
          type="datetime-local"
          control={control}
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ScreeningSelect
          name="screeningId"
          control={control}
          screenings={screenings}
          isLoading={screeningsAreLoading}
          screeningId={screeningId!}
          disabled={disabled}
          programIsDvRespite={program.programType.jjisProgramType === JjisProgramTypeEnum.DVRespite}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <StaffMemberControlledAutoComplete
          control={control}
          disabled={disabled}
          name="intakeStaffId"
          label="Staff Completing Intake*"
          isLoading={staffMembersAreLoading}
          staffMembers={staffMembers}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <MultiStaffMemberControlledSelector
          control={control}
          name={"primaryStaffIds"}
          staffMembers={staffMembers}
          onStaffAdded={onStaffMemberAdded}
          onStaffRemoved={onStaffMemberRemoved}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect name="serviceStatus" label="Service Status*" control={control} disabled={disabled} options={serviceStatusOptions} fullWidth />
      </Grid>

      <Grid item md={12} xs={12}>
        <ControlledSelect control={control} name="schoolStatus" options={intakeSchoolStatusOptions} label="School Status*" fullWidth disabled={disabled} />
      </Grid>

      {lastGradeAttendedStatus.includes(schoolStatus || "") && (
        <Grid item md={12} xs={12}>
          <ControlledSelect
            control={control}
            name="lastGradeAttended"
            options={intakeGradeLevelOptions.filter((o) => isLegacy || o.value !== IntakeGradeLevelEnum.NotSpecified)}
            label="Last Grade Attended*"
            fullWidth
            disabled={disabled}
          />
        </Grid>
      )}

      {currentGradeStatus.includes(schoolStatus || "") && (
        <>
          <Grid item md={12} xs={12}>
            <ControlledSelect
              control={control}
              name="currentGrade"
              options={intakeGradeLevelOptions.filter((o) => isLegacy || o.value !== IntakeGradeLevelEnum.NotSpecified)}
              label="Current Grade*"
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <ControlledAutocomplete
              options={schoolNames.sort((a, b) => (a > b ? 1 : -1))}
              freeSolo
              loading={schoolNamesAreLoading}
              autoSelect
              openOnFocus
              name="schoolName"
              label={"School Name*"}
              control={control}
              renderInput={() => <Fragment></Fragment>}
              disabled={disabled}
            />
          </Grid>
        </>
      )}
      <Grid item md={12} xs={12}>
        <ControlledSelect
          name="familyStructure"
          label={`Family Structure${fundingSource === ProgramFundingSourceEnum.FloridaNetwork ? "*" : ""}`}
          control={control}
          disabled={disabled}
          options={DemographicsFamilyStructureEnumOptions.filter((o) => 
            familyStructure === DemographicsFamilyStructureEnum.NotSpecified 
            || o.value !== DemographicsFamilyStructureEnum.NotSpecified)
            }
          fullWidth
        />
      </Grid>

        <Grid item md={6} xs={6}>
          <ControlledCheckbox 
            name="isCourtOrdered" 
            label="Is this a court ordered case?" 
            control={control} 
            disabled={disabled} 
            color="primary" />
        </Grid>

        {isCourtOrdered && 
           <Grid item md={12} xs={12}>
            <CaseCourtOrderAttachmentUploadComponent 
              onCourtOrderedAttachmentUploaded={handleFileUpdated}
              filename={courtOrderDocumentFilename}
              error={errors.courtOrderDocumentFilename && errors.courtOrderDocumentFilename.message} 
              disabled={disabled}/>                   
          </Grid>         
        }      

      {program.programType.jjisProgramType === JjisProgramTypeEnum.Shelter && (
        <Grid item md={12} xs={12}>
          <ControlledCheckbox name="isYouthOnMedication" label="Is Youth on Medication?" control={control} disabled={disabled} color="primary" />
          {isYouthOnMedication && (
            <ControlledWholeNumberInput name="youthMedicationCount" label="Number of Medications*" control={control} disabled={disabled} fullWidth={false} />
          )}
        </Grid>
      )}
    </Fragment>
  );
};

export default UpdateCaseIntakeFormFields;
