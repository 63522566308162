import React from "react";

import {useServicePlanInterventionList} from "../hooks/useServicePlanInterventionList";
import {
  Box,
  Grid,
  List,
  ListItem, ListItemText,
} from "@material-ui/core";
import EditButton from "../../app/components/buttons/EditButton";
import {ContentSection} from "./ContentSection";
import {ServicePlanInterventionDto} from "../dto/ServicePlanDto";
import {PermissionResourceNameEnum} from "../../features/authentication/enums/PermissionResourceNameEnum";
import WriteAccessLevelWrapper from "../../app/components/access-wrappers/WriteAccessLevelWrapper";

interface Props {
  goalId: string
}

const ServicePlanInterventionListItem: React.FC<{intervention: ServicePlanInterventionDto, onViewNotes: () => Promise<void>, onEdit: () => Promise<void>}> = ({ intervention, onViewNotes, onEdit }) => {
  return (<ListItem key={intervention.id}>
    <ListItemText >
      <Box display={"flex"} flexDirection={"row"}>
        <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-start"}}>{intervention.description}</div>
        <div style={{flex: 0, display: "flex", flexDirection: "row"}}>
          <EditButton text={"Notes"} onClick={onViewNotes} />
          <WriteAccessLevelWrapper name={PermissionResourceNameEnum.ServicePlan}>
            <EditButton text={"Edit"} onClick={onEdit} />
          </WriteAccessLevelWrapper>
        </div>
      </Box>
      </ListItemText>
  </ListItem>)

}

export const ServicePlanInterventionList: React.FC<Props> = ({ goalId }) => {

  const  {
    state: { interventions },
    handlers: { onAddInterventionClicked, onViewGoalInterventionNotesClicked, onUpdateGoalInterventionClicked }
  } = useServicePlanInterventionList(goalId)



  return (
    <ContentSection elevation={0} label={"Interventions"}
                    actions={<WriteAccessLevelWrapper name={PermissionResourceNameEnum.ServicePlan}>
                      <EditButton text={"Add Intervention"} onClick={onAddInterventionClicked} />
                    </WriteAccessLevelWrapper>}
                    content={<Grid container>
                        <List style={{width: "100%"}}>
                          {interventions.map(i => <ServicePlanInterventionListItem key={i.id} intervention={i} onViewNotes={
                            () => {
                              console.log(`notes for ${goalId} -> ${i.id}}`)
                              return onViewGoalInterventionNotesClicked(goalId, i.id)
                            }
                          } onEdit={() => onUpdateGoalInterventionClicked(goalId, i.id)} />)}
                        </List>
                    </Grid>} />
  );

}
