import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";

export class UserFilterFormDto {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  isFloridaNetworkUser: string;
  active: string;
  constructor(dto?: UserSearchParamsDto) {
    this.firstName = dto?.firstName || "";
    this.lastName = dto?.lastName || "";
    this.email = dto?.email || "";
    this.isFloridaNetworkUser = dto?.isFloridaNetworkUser || "";
    this.isFloridaNetworkUser = dto?.isFloridaNetworkUser || "";
    this.roleId = dto?.roleId || "";
    this.active = dto?.active || "";    
  }
}

export class UserSearchParamsDto implements PaginationSearchParamsDto {
  firstName: string;
  lastName: string;
  email: string;
  isFloridaNetworkUser: string;
  roleId: string;
  active: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: UserFilterFormDto;
    previous?: UserSearchParamsDto;
  }) {
    this.firstName = form?.firstName || (query?.firstName as string) || "";

    this.lastName = form?.lastName || (query?.lastName as string) || "";

    this.email = form?.email || (query?.email as string) || "";
    this.active = (query?.active as string) || "";
    this.roleId = form?.roleId || (query?.roleId as string) || "";

    if (form) this.active = form.active;

    this.isFloridaNetworkUser =
      form?.isFloridaNetworkUser ||
      (query?.isFloridaNetworkUser as string) ||
      "";

    this.sortBy = previous?.sortBy || (query?.sortBy as string) || "firstName";
    this.sortDirection = previous?.sortDirection || SortDirectionEnum.Ascending;
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
  }

  public static toQueryString(dto: UserSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
