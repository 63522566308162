import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import notificationReducer from "../features/notifications/state/notificationSlice";
import authenticationReducer from "../features/authentication/state/authenticationSlice";
import modalReducer from "../features/modal/state/modalSlice";
import userReducer from "../features/users/state/userSlice";
import usersReducer from "../features/users/state/usersSlice";
import accountReducer from "../features/account/state/accountSlice";
import menuReducer from "./state/menuSlice";
import programTypeReducer from "../features/program-types/state/programTypeSlice";

export const store = configureStore({
  reducer: {
    notifications: notificationReducer,
    authentication: authenticationReducer,
    modal: modalReducer,
    user: userReducer,
    users: usersReducer,
    account: accountReducer,
    menu: menuReducer,
    programTypes: programTypeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
