import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import ErrorPage from "./ErrorPage";

const FallbackPage = () => {
  const subheader = (
    <Typography component="h5" variant="h5" color="textSecondary">
      We apologize. Something is wrong with our system at the moment. Please
      refresh and try again.
    </Typography>
  );

  return <ErrorPage header="Something Went Wrong" subheader={subheader} />;
};

export default FallbackPage;
