import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import ScreeningDetailPage from "./pages/ScreeningDetailPage";
import ScreeningListPage from "./pages/ScreeningListPage";

export const createScreeningRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Screening}
    path={formatUrl(baseUrl, "")}
    component={ScreeningListPage}
    key="screening-list"
    exact
  />,

  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, ":id")}
    component={ScreeningDetailPage}
    key="detail-screening"
    name={PermissionResourceNameEnum.Screening}
  />,
];
