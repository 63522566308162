import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { OutreachActivityDto } from "../dtos/OutreachActivityDto";
import { OutreachActivityFormDto } from "../dtos/OutreachActivityFormDto";
import { useOutreachActivityForm } from "../hooks/useOutreachActivityForm";
import OutreachActivityFormFields from "./OutreachActivityFormFields";

export const outreachActivityFormModalId = "outreach-activity-form-modal-id";

interface Props {
  onSubmit(dto: OutreachActivityFormDto): Promise<any>;
  outreachActivity?: OutreachActivityDto;
  requestState: RequestStateDto;
}

const OutreachActivityFormDialog: React.FC<Props> = ({
  onSubmit,
  outreachActivity,
  requestState,
}) => {
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit },
  } = useOutreachActivityForm(onSubmit, outreachActivity);

  const { isLoading, error } = requestState;

  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  return (
    <Dialog
      open={modalId === outreachActivityFormModalId}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Create Outreach Activity
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <OutreachActivityFormFields 
          control={control} 
          disabled={isLoading} 
          allowNotSpecified={outreachActivity?.isLegacy || false} />
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OutreachActivityFormDialog;
