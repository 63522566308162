import { makeStyles, Paper } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import Header from "../../../app/components/Header";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import { useInvoiceListPage } from "../hooks/useInvoiceListPage";
import { createProviderInvoiceListTab } from "../components/ProviderInvoiceList";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../authentication/constants";
import { createStatewideInvoiceListTab } from "../components/StatewideInvoiceList";

const useStyle = makeStyles((theme) => ({
  tableContainer: {
    height: "94%",
  },
  subsection: {
    padding: "0px 16px",
  },
  sectionDivider: {
    backgroundColor: "rgba(0, 0, 0, 0.24)",
    height: "2px",
  },
}));

const InvoiceListPage = () => {
  //#region State
  const { state, handlers } = useInvoiceListPage();
  const {
    tabIndex,
  } = state;
  const {
    handleTabChange,
  } = handlers;
  const classes = useStyle();
  const [tabs, setTabs] = useState<TabProps[]>([]);
  //#endregion

  //#region Effects
  useEffect(() => {
    const tabs: TabProps[] = [];

    tabs.push(
      createProviderInvoiceListTab()
    );

    if (getProviderId() === fnyfsProviderId)
      tabs.push(
        createStatewideInvoiceListTab()
      );

    setTabs(tabs);
  }, [setTabs]);
  //#endregion

  return (
    <Fragment>
      <Header title="Invoices" />
      <Paper className={classes.tableContainer}>
        <TabContainer
          tabs={tabs}
          onIndexChange={handleTabChange}
          index={tabIndex}
          disabled={false}
        />
      </Paper>
    </Fragment>
  );
};

export default InvoiceListPage;
