import { Box } from "@material-ui/core";
import React, { Fragment } from "react";
import TabContainer, {
  TabProps,
} from "../../../../app/components/TabContainer";
import { ProgramTypeIdEnum } from "../../../../app/enums/ProgramTypeIdEnum";
import { useAppSelector } from "../../../../app/hooks";
import { useTabIndex } from "../../../../app/hooks/useTabIndex";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { selectHasReadAccess } from "../../../authentication/state/authenticationSelectors";
import { useCaseDetailPageContext } from "../../pages/CaseDetailPage";
import { createCaseBakerActTab } from "./supplemental-sections/CaseBakerActSection";
import { createCaseStaffingTab } from "./supplemental-sections/CaseStaffingSection";
import {
  createDemographicTab,
  demographicTabName,
} from "./supplemental-sections/DemographicSection";
import { createHtstTab } from "./supplemental-sections/HtstSection";
import { createNirvanaTab } from "./supplemental-sections/nirvana/NirvanaSection";
import { createNonBillableDaysTab } from "./supplemental-sections/NonBillableDaysSection";
import { createSuicideQuestionnaireSection } from "./supplemental-sections/suicide-screening/SuicideScreeningSection";
import { createTopseTab } from "./supplemental-sections/topse/TopseSection";

export const caseSupplementalSectionName = "case-supplemental-section";

const CaseSupplementalSection = () => {
  //#region

  const {
    case: { caseResult, getCaseRequestState },
  } = useCaseDetailPageContext();
  const canReadAssessments = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.Assessment)
  );
  const canReadNirvana = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.Nirvana)
  );

  const [tabIndex, handleChangeTabIndex] = useTabIndex(demographicTabName);

  const tabs: TabProps[] = [createDemographicTab()];

  if (caseResult?.program.programType.canApplyNonBillableDays)
    tabs.push(createNonBillableDaysTab());

  if (caseResult?.program.programType.canApplyCaseStaffings)
    tabs.push(createCaseStaffingTab());

  tabs.push(createHtstTab());

  if (canReadNirvana) tabs.push(createNirvanaTab());

  if (
    canReadAssessments &&
    caseResult?.program.programType.id === ProgramTypeIdEnum.Snap
  )
    tabs.push(createTopseTab());

  tabs.push(createSuicideQuestionnaireSection());

  tabs.push(createCaseBakerActTab());

  if (tabs.length === 0) return <Fragment></Fragment>;

  return (
    <Box>
      <TabContainer
        index={tabIndex}
        onIndexChange={handleChangeTabIndex}
        tabs={tabs}
        orientation="vertical"
        disabled={getCaseRequestState.isLoading}
      />
    </Box>
  );
};

export default CaseSupplementalSection;

export const createCaseSupplementalSection = (): TabProps => ({
  value: caseSupplementalSectionName,
  label: "Supplemental Info",
  content: <CaseSupplementalSection />,
});
