import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  SnapCurriculumDto,
  snapCurriculumToOptions,
} from "../dtos/SnapCurriculumDto";
import { SnapGroupFormDto } from "../dtos/SnapGroupFormDto";

interface Props {
  control: Control<SnapGroupFormDto>;
  isDisabled: boolean;
  curriculum: SnapCurriculumDto[];
  curriculumIsLoading: boolean;
}

const SnapGroupFormFields: React.FC<Props> = ({
  control,
  isDisabled,
  curriculum,
  curriculumIsLoading,
}) => {
  const isMakeupGroup = useWatch({ control, name: "isMakeupGroup" });

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="groupHeldOn"
          label="Group Date*"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          label="Session Name*"
          name="snapCurriculumId"
          options={snapCurriculumToOptions(curriculum)}
          isLoading={curriculumIsLoading}
          disabled={isDisabled}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledCheckbox
          control={control}
          name="isMakeupGroup"
          label="Is Makeup Group"
          color="primary"
          disabled={isDisabled}
          value={isMakeupGroup}
        />
      </Grid>
    </Fragment>
  );
};

export default SnapGroupFormFields;
