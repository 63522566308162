import { Box, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditButton from "../../../../app/components/buttons/EditButton";
import { TabProps } from "../../../../app/components/TabContainer";
import { createNumberRange } from "../../../../app/helpers";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { useProviderDetailPageContext } from "../../pages/ProviderDetailPage";
import ProviderFormDialog from "../ProviderFormDialog";

export const providerDetailSectionName = "provider-details-section";

const ProviderDetailsSection: React.FC = () => {
  const context = useProviderDetailPageContext();
  const {
    getProviderRequestState: { isLoading },
    provider,
    updateProviderRequestState,
    handleEditProviderClicked,
    handleEditProvider,
  } = context.provider;

  //#region UI Helpers
  const createRow = (label: string, content: React.ReactNode) => {
    return (
      <Fragment>
        <Grid item md={2} xs={4}>
          <Typography component="p">{label}:</Typography>
        </Grid>
        <Grid item md={10} xs={8}>
          <Typography component="p">{content}</Typography>
        </Grid>
      </Fragment>
    );
  };
  //#endregion

  if (isLoading)
    return (
      <Grid container spacing={1}>
        {createNumberRange(3).map((i) => (
          <Grid item md={8} xs={8} key={`loading-${i}`}>
            <Skeleton variant="rect" animation="wave" width="300px" />
          </Grid>
        ))}
      </Grid>
    );
  return (
    <Fragment>
      <ProviderFormDialog
        onSubmit={handleEditProvider}
        provider={provider}
        requestState={updateProviderRequestState}
      />
      <Box position="absolute" right="16px">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Provider}>
          <EditButton onClick={handleEditProviderClicked} />
        </WriteAccessLevelWrapper>
      </Box>
      <Grid container>
        {createRow("Center ID", provider?.centerId)}
        {createRow("Name", provider?.name)}
        {createRow("Short Name", provider?.shortName)}
        {provider?.abbreviation &&
          createRow("Abbreviation", provider?.abbreviation)}
      </Grid>
    </Fragment>
  );
};

export default ProviderDetailsSection;

export const createProviderDetailsSection = (): TabProps => ({
  label: "Details",
  value: providerDetailSectionName,
  content: <ProviderDetailsSection />,
});
