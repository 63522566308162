import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import { FollowUpListPage } from "./pages/FollowUpListPage";

export const createFollowUpRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "")}
    component={FollowUpListPage}
    key="follow-ups-list"
    exact
  />,
];
