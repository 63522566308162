import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { useQuery } from "../../../app/hooks/useQuery";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { useGetProviders } from "../../provider/hooks/providerHooks";
import { ReferralSearchParamsDto } from "../dtos/ReferralSearchParamsDto";
import { useGetReferrals } from "./referralHooks";

export const useReferralListPage = () => {
  //#region State
  const query = useQuery();
  const [params, setParams] = useState<ReferralSearchParamsDto>(
    new ReferralSearchParamsDto({ query })
  );
  const [getReferrals, referralsResult, getReferralsRequestState] =
    useGetReferrals();

  const [getProviders, providers, getProvidersRequestState] = useGetProviders();
  const history = useHistory();

  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handlePageChange = (page: number) => {
    setParams((params) => ({ ...params, page }));
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    setParams(newParams);
  };

  const handleSearch = (dto: ReferralSearchParamsDto) => {
    setParams(dto);
  };

  const handleOpenFilterClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => setFilterAnchorEl(null);
  //#endregion

  //#region Effects

  useEffect(() => {
    getReferrals(params);
  }, [params, getReferrals]);

  useEffect(() => {
    history.push(`/referrals?${ReferralSearchParamsDto.toQueryString(params)}`);
  }, [params, history]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId === fnyfsProviderId) {
      getProviders({ pageSize: 1000 });
    }
  }, [getProviders]);
  //#endregion

  return {
    state: {
      referralsResult,
      params,
      providers: providers?.items || [],
      getReferralsRequestState,
      getProvidersRequestState,
      filterAnchorEl,
    },
    handlers: {
      handlePageChange,
      handleCloseFilter,
      handleOpenFilterClicked,
      handleSearch,
      handleSort,
    },
  };
};
