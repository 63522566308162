import ErrorText from "../../../app/components/ErrorText";
import Header from "../../../app/components/Header";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import FilterButton from "../../../app/components/buttons/FilterButton";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import WhiteButton from "../../../app/components/buttons/WhiteButton";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import ScreeningFilter from "../components/ScreeningFilter";
import ScreeningFilterList from "../components/ScreeningFilterList";
import ScreeningList from "../components/ScreeningList";
import { ScreeningListDto } from "../dtos/ScreeningListDto";
import { useScreeningsListPage } from "../hooks/useScreeningsListPage";

const ScreeningListPage = () => {
  const { state, handlers } = useScreeningsListPage();
  const {
    screeningsResult,
    params,
    filterAnchorEl,
    providers,
    getScreeningsRequestState,
    getProvidersRequestState,
    getProgramsRequestState,
    allowExport,
    exportRecordsLimit,
    exportScreeningsRequestState,
    providerLocations,
    providerPrograms,
  } = state;
  const {
    handlePageChange,
    handleCloseFilter,
    handleSearch,
    handleOpenFilterClicked,
    handleProviderChange,
    handleSort,
    handleExportListClicked,
  } = handlers;

  return (
    <div>
      <RequestErrorAlert
        requests={[
          getScreeningsRequestState,
          getProvidersRequestState,
          getProgramsRequestState,
        ]}
      />

      <ScreeningFilter
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilter}
        params={params}
        onSearch={handleSearch}
        providers={providers}
        providerLocations={providerLocations}
        providerPrograms={providerPrograms}
        onProviderChange={handleProviderChange}
      />

      <Header
        title="Screenings"
        actions={
        <>
          <SpinnerButton
            content={<WhiteButton onClick={() => handleExportListClicked(params)} disabled={!allowExport}>Export</WhiteButton>}
            showSpinner={exportScreeningsRequestState.isLoading}
            title={allowExport ? 'Export List to CSV' : `The result set is too large to export. Please apply filters to reduce the number of records below ${exportRecordsLimit}.`}
            disableRipple={!allowExport}
            style={{ backgroundColor: 'transparent' }}
          />
          <FilterButton
            title="Filter List"
            onClick={handleOpenFilterClicked}

          />
        </>
        }
        secondaryActions={
          <ScreeningFilterList
            params={params}
            onSearch={handleSearch}
            isLoading={getScreeningsRequestState.isLoading}
            providers={providers}
            providerLocations={providerLocations}
            providerPrograms={providerPrograms}
          />
        }
      />

      { exportScreeningsRequestState.error && <ErrorText error={exportScreeningsRequestState.error.message} /> }

      <ScreeningList
        onPageChange={handlePageChange}
        isLoading={getScreeningsRequestState.isLoading}
        results={screeningsResult || new PaginationResultDto<ScreeningListDto>()}
        params={params}
        onSort={handleSort}
      />
    </div>
  );
};

export default ScreeningListPage;
