import { dateToFormString } from "../../../app/helpers";
import * as Yup from "yup";

export interface SnapInSchoolsFidelityAdherenceChecklistDto {
  id: string;
  completedOn: Date;
  score: number;
}

export class SnapInSchoolsFidelityAdherenceChecklistFormDto {
  completedOn: string;
  score: string;

  constructor(dto?: SnapInSchoolsFidelityAdherenceChecklistDto) {
    this.completedOn = dateToFormString(
      dto?.completedOn ? new Date(dto.completedOn) : new Date()
    );
    this.score = `${dto?.score || ""}`;
  }

  public static getSchema() {
    return Yup.object().shape({
      completedOn: Yup.date()
        .label("Completed On")
        // eslint-disable-next-line no-template-curly-in-string
        .typeError("${label} must be a valid date")
        .required(),
      score: Yup.number().label("Score").required().min(0).max(100),
    });
  }
}
