import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum DeliverableTypeEnum {
  FilledBedDay = "FilledBedDay",
  ServiceDay = "ServiceDay",
  Intake = "Intake",
  IndividualSession = "IndividualSession",
  GroupSession = "GroupSession",
  Fidelity = "Fidelity",
  Discharge = "Discharge",
  Session = "Session",
  Contact = "Contact",
  CarryoverCase = "CarryoverCase",
  StaffSecureDaily = "StaffSecureDaily",
  SnapGroupSession = "SnapGroupSession",
  SnapInSchoolsGroupSession = "SnapInSchoolsGroupSession" 
}

export const deliverableTypeTextMap: { [key: string]: string } = {
  [DeliverableTypeEnum.FilledBedDay]: "Filled Bed Days",
  [DeliverableTypeEnum.ServiceDay]: "Service Days",
  [DeliverableTypeEnum.Intake]: "Intakes",
  [DeliverableTypeEnum.IndividualSession]: "Individual Sessions",
  [DeliverableTypeEnum.GroupSession]: "Group Sessions",
  [DeliverableTypeEnum.Fidelity]: "Fidelity",
  [DeliverableTypeEnum.Discharge]: "Discharges",
  [DeliverableTypeEnum.Session]: "Sessions",
  [DeliverableTypeEnum.Contact]: "Contacts",
  [DeliverableTypeEnum.CarryoverCase]: "Carryover Case",
  [DeliverableTypeEnum.StaffSecureDaily]: "Staff Secure Daily",
  [DeliverableTypeEnum.SnapGroupSession]: "Group Session",
  [DeliverableTypeEnum.SnapInSchoolsGroupSession]: "Group Session",

};

export const getDeliverableTypeOptions = (): SelectOption[] => [
  { value: "", label: "Select Deliverable Type" },
  ...Object.values(DeliverableTypeEnum).map((value) => ({
    value: value,
    label: deliverableTypeTextMap[value],
  })),
];
