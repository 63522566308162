import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ReferralFilterFormDto } from "../dtos/ReferralFilterFormDto";
import { ReferralSearchParamsDto } from "../dtos/ReferralSearchParamsDto";

export const useReferralFilter = (
  onSubmit: (dto: ReferralSearchParamsDto) => void,
  onClose: () => void,
  params: ReferralSearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: new ReferralFilterFormDto(params),
  });
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: ReferralFilterFormDto) => {
    const newParams: ReferralSearchParamsDto = new ReferralSearchParamsDto({
      form: dto,
      previous: params,
    });

    if (newParams.providerId === "") newParams.providerId = undefined;

    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new ReferralFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new ReferralFilterFormDto(params));
  }, [params, reset]);

  //#endregion

  return {
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
