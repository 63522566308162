import React from "react";
import { NirvanaAveragesOrImpactReportParameters } from "../dtos/NirvanaAveragesOrImpactReportParameters";
import { Box, Grid } from "@material-ui/core";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect, { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import { useNirvanaAveragesOrImpactReportFilter } from "../hooks/useNirvanaAveragesOrImpactReportFilter";
import { getFundingSourceOptions } from "../../programs/enums/ProgramFundingSourceEnum";
import { programTypesToOptions } from "../../program-types/dtos/ProgramTypeDto";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";
import { getLocationOptions } from "../../locations/dtos/LocationDto";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { AssessmentTypeEnum } from "../../assessments/enums/AssessmentTypeEnum";
import { NirvanaReportType } from "../pages/NirvanaAssessmentAveragesOrImpactReportPage";
import { contractsToOptions } from "../../contracts/dtos/ContractDto";
import { providerToOptionDtos } from "../../provider/dtos/ProviderDto";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import {StaffMemberControlledAutoComplete} from "../../staff-members/components/StaffMemberControlledAutoComplete";

interface Props {
  params: NirvanaAveragesOrImpactReportParameters;
  isLoading: boolean;
  reportType: NirvanaReportType;
  onSearch(dto: NirvanaAveragesOrImpactReportParameters): Promise<void>;
}

export const NirvanaAveragesOrImpactReportFilter: React.FC<Props> = ({ params, isLoading, reportType, onSearch }) => {
  const {
    state: {
      isValid,
      contracts,
      contractsRequestState,
      programTypes,
      programTypesRequestState,
      providers,
      providersRequestState,
      programs,
      programsRequestState,
      locations,
      locationsRequestState,
      counselors,
      counselorsRequestState,
    },
    form: { control },
    handlers: { handleDownload },
  } = useNirvanaAveragesOrImpactReportFilter(onSearch, params);

  const fundingOptions = getFundingSourceOptions("All");

  const assessmentTypeOptions: SelectOption[] = [
    { value: AssessmentTypeEnum.NirvanaAssessment, label: "Assessment" },
    { value: AssessmentTypeEnum.NirvanaReAssessment, label: "Re-Assessment" },
    {
      value: AssessmentTypeEnum.NirvanaPostAssessment,
      label: "Post Assessment",
    },
  ];

  return (
    <form onSubmit={handleDownload}>
      {/*<ErrorText error={getAllProvidersRequestState.error?.message} />*/}
      <Grid container spacing={2}>
        {/* Date Range */}

        <Grid item md={2} xs={6}>
          <ControlledInput
            control={control}
            name="intakeStart"
            label="Intake Start Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <ControlledInput
            control={control}
            name="intakeEnd"
            label="Intake End Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>

        {reportType === NirvanaReportType.AssessmentAverages && (
          <Grid item md={2} xs={6}>
            <ControlledSelect
              control={control}
              name="assessmentTypes"
              label="Assessment Types"
              options={assessmentTypeOptions}
              fullWidth
              multiple={true}
              size="small"
            />
          </Grid>
        )}

        <Grid item md={2} xs={6}>
          <ControlledSelect control={control} name="fundingSource" label="Funding Source" fullWidth options={fundingOptions} size="small" />
        </Grid>

        <FnAccessWrapper>
          <Grid item md={2} xs={6}>
            <ControlledSelect
              control={control}
              name="contractId"
              label="Contract"
              fullWidth
              options={contractsToOptions(
                contracts.sort((a, b) => a.displayOrder - b.displayOrder),
                undefined,
                "All"
              )}
              isLoading={contractsRequestState.isLoading}
              size="small"
            />
          </Grid>
        </FnAccessWrapper>

        <Grid item md={2} xs={6}>
          <ControlledSelect
            control={control}
            name="programTypeId"
            label="Program Type"
            options={programTypesToOptions(programTypes, "All")}
            fullWidth
            isLoading={programTypesRequestState.isLoading}
            size="small"
          />
        </Grid>

        <FnAccessWrapper>
          <Grid item md={2} xs={6}>
            <ControlledSelect
              control={control}
              name="providerId"
              label="Provider"
              fullWidth
              options={providerToOptionDtos(providers, [], "All")}
              isLoading={providersRequestState.isLoading}
              size="small"
            />
          </Grid>
        </FnAccessWrapper>

        <ProviderAccessWrapper>
          {/* Programs */}
          <Grid item md={2} xs={6}>
            <ProgramControlledSelect
              control={control}
              name="programId"
              label="Program"
              programs={programs}
              disabled={programs.length === 0}
              defaultOptionLabel={"All"}
              isLoading={programsRequestState.isLoading}
              size="small"
            />
          </Grid>

          {/*Locations */}
          <Grid item md={2} xs={6}>
            <ControlledSelect
              control={control}
              name="locationId"
              label="Location"
              options={getLocationOptions(locations, "All")}
              disabled={locations.length === 0}
              isLoading={locationsRequestState.isLoading}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item md={2} xs={6}>
            <StaffMemberControlledAutoComplete
              control={control}
              disabled={counselors.length === 0}
              name="intakeCounselorId"
              label="Counselor at intake"
              isLoading={counselorsRequestState.isLoading}
              staffMembers={counselors}
              size="small"
            />
          </Grid>
        </ProviderAccessWrapper>
        <Grid item md={2} xs={6}>
          <ControlledWholeNumberInput control={control} name="zipCode" label="Zip Code" fullWidth size="small" />
        </Grid>

        <Grid item md={reportType === NirvanaReportType.AssessmentAverages ? 8 : 10} xs={6}>
          <Box display="flex" alignItems="center" height="100%" justifyContent="end">
            <div>
              <SpinnerButton showSpinner={false} disabled={!isValid || isLoading} content="View" color="primary" variant="contained" fullWidth type="submit" />
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
