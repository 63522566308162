import { Box, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import DateLabel from "../../../../app/components/labels/DateLabel";
import { ProgramLocationDto } from "../../../locations/dtos/ProgramLocationDto";
import InfoTooltip from "../../../../app/components/InfoTooltip";
interface Props {
  programLocation: ProgramLocationDto;
}

const ProviderProgramLocationInfo: React.FC<Props> = ({ programLocation }) => {
  //#region UI Helpers
  const createRow = (
    label: string,
    value: React.ReactNode,
    hide: boolean = false
  ) => {
    if (hide === true) {
      return <Fragment></Fragment>;
    }

    return (
      <Box display="flex" justifyContent="space-between">
        <Typography
          style={{
            marginRight: "8px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {label}:
        </Typography>
        <Typography>{value}</Typography>
      </Box>
    );
  };
  //#endregion

  return (
    <Fragment>
      <InfoTooltip
        title={
          <Box>
            {createRow(
              "Started Date",
              <DateLabel date={programLocation.startedOn} />
            )}
            {createRow(
              "End Date",
              <DateLabel date={programLocation.terminatedOn} />,
              programLocation.terminatedOn === undefined
            )}
            {createRow(
              "Circuit #",
              programLocation.judicialCircuitNumber,
              programLocation.judicialCircuitNumber === "" ||
                programLocation.judicialCircuitNumber === undefined
            )}
            {createRow(
              "JJ Program",
              `${programLocation.juvenileJusticeProgram?.code} - ${programLocation.juvenileJusticeProgram?.name}`,
              programLocation.juvenileJusticeProgram === undefined
            )}
          </Box>
        }
      />
    </Fragment>
  );
};

export default ProviderProgramLocationInfo;
