import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import ErrorAlert from "../../../app/components/ErrorAlert";
import {Box, Paper, Typography} from "@material-ui/core";
import {SNAPExportReportFilter} from "../components/SNAPExportReportFilter";
import {useSNAPExportReportPage} from "../hooks/useSNAPExportReportPage";

export const SNAPExportReportPage: React.FC = () => {

  const { state, handlers } = useSNAPExportReportPage();
  const { params } = state;
  const { handleSearch } = handlers;

  return (
    <div>
      <BackButton route="/reports" style={{ marginTop: "24px" }} />
      <Header title="SNAP/SIS Export" />
      <ErrorAlert error={""} />
      <Box className="my-2">
        <Typography>
          Select a Program Type and any additional filter criteria and
          "Download" for an Excel report of the data.
        </Typography>
      </Box>
      <Paper
        style={{
          padding: "16px",
        }}
      >
        <Box>
          <SNAPExportReportFilter
            onSearch={handleSearch}
            params={params}
            isLoading={false}
          />
        </Box>
      </Paper>
    </div>
  );
};
