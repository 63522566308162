import { useRequest } from "../../../app/hooks/useRequest";
import {
  deleteOutreachActivityRequest,
  getOutreachActivitiesRequest,
  getOutreachActivityRequest,
  updateOutreachActivityRequest,
} from "../OutreachActivityRequests";

export const useGetOutreachActivities = () =>
  useRequest(getOutreachActivitiesRequest);

export const useGetOutreachActivity = () =>
  useRequest(getOutreachActivityRequest);

export const useUpdateOutreachActivity = () =>
  useRequest(updateOutreachActivityRequest);

export const useDeleteOutreachActivity = () =>
  useRequest(deleteOutreachActivityRequest);
