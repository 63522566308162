import { Box, Divider, Paper, Typography } from "@material-ui/core";
import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import PdfContainer from "../../../app/components/PdfContainer";
import BenchmarksReportFilter from "../components/BenchmarksReportController";
import { useBenchmarksReportPage } from "../hooks/useBenchmarksReportPage";

const BenchmarksReportPage = () => {
  const {
    providers,
    fiscalYears,
    getFiscalYearsRequestState,
    getDocumentRequestState,
    getProvidersRequestState,
    document,
    handleSearch,
    isFloridaNetwork,
  } = useBenchmarksReportPage();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <BackButton route="/reports" />
      </div>
      <Header title="Benchmarks Report" />
      <Box className="my-2">
        <Typography>
          Select fiscal year/month
          {!isFloridaNetwork && ", a comparison Provider (optional)"}, and
          "View" for a PDF about Provider performance.
        </Typography>
      </Box>
      <Paper
        style={{
          padding: "16px",
          height: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <BenchmarksReportFilter
          providers={providers}
          getProvidersRequestState={getProvidersRequestState}
          requestState={getDocumentRequestState}
          fiscalYears={fiscalYears}
          getFiscalYearsRequestState={getFiscalYearsRequestState}
          onSubmit={handleSearch}
        />

        <Box marginBottom="8px" marginTop="8px">
          <Divider />
        </Box>
        <PdfContainer
          document={document}
          isLoading={getDocumentRequestState.isLoading}
        />
      </Paper>
    </div>
  );
};

export default BenchmarksReportPage;
