import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { YouthAddressDto } from "../dto/YouthDto";
import EditIcon from "@material-ui/icons/Edit";
interface Props {
  address?: YouthAddressDto;
  getAddressRequestState?: RequestStateDto;
  onEditClick(): void;
}

export const formatYouthAddress = (address?: YouthAddressDto) => {
  if (address == null) return "";

  if (!address.address) return "";

  let formattedAddress = address.address;

  if (address.building != null && address.building !== "")
    formattedAddress += `, ${address.building}`;

  formattedAddress += `, ${address.city}, ${address.state} ${address.zipCode}`;
  return formattedAddress || "";
};

const YouthAddressSection: React.FC<Props> = ({
  address,
  getAddressRequestState,
  onEditClick,
}) => {
  const [formattedAddress, setFormattedAddress] = useState("");

  const isLoading = getAddressRequestState?.isLoading;

  useEffect(() => {
    if (address == null || address.address == null || address.address === "") {
      setFormattedAddress("Unable to obtain address.");
      return;
    }

    const formattedAddress = formatYouthAddress(address);

    setFormattedAddress(formattedAddress);
  }, [setFormattedAddress, address]);

  return (
    <>
      <style>
        {`
      #input-box {
        display: flex;
        align-items: center;
        padding: 12px 14px;
        position: relative;
        justify-content: space-between;
        border: 1.5px solid ${ColorsEnum.InputBorderGrey};
        border-radius: 4px;
        cursor: pointer;
        
      }
      #input-box:hover {
        border-color: #444;
      }
      
      `}
      </style>

      <div id="input-box" onClick={onEditClick}>
        <Typography
          style={{
            position: "absolute",
            top: "-10px",
            left: "8px",
            background: "white",
            paddingLeft: "4px",
            paddingRight: "4px",
            fontSize: "0.75em",
          }}
          color="textSecondary"
        >
          Confirm Youth Address:{" "}
        </Typography>
        <div>
          {isLoading ? (
            <CircularProgress style={{ width: "20px", height: "20px" }} />
          ) : (
            <Typography>{formattedAddress}</Typography>
          )}
        </div>
        <EditIcon />
      </div>
    </>
  );
};

export default YouthAddressSection;
