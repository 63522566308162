import React, { useCallback, useEffect, useState } from "react";
import {
  AlertTypeEnum,
  SnapInSchoolsCycleContextAlertTypeMessages,
} from "../../alerts/enums/AlertTypeEnum";
import { useSnapInSchoolsDetailPageContext } from "../pages/SnapInSchoolCyclesDetailPage";
import NotificationBell, {
  NotificationActionDto,
  NotificationType,
} from "../../../app/components/NotificationBell";
const SnapInSchoolsDetailNotifications = () => {
  //#region State
  const {
    snapCycle: { snapCycle },
    alerts: { alerts, handleCreateAlertDismissalClicked },
  } = useSnapInSchoolsDetailPageContext();
  const [notifications, setNotifications] = useState<NotificationActionDto[]>(
    []
  );
  //#endregion

  //#region Notifications

  const getAlertContent = useCallback(
    (type: AlertTypeEnum) => {
      if (snapCycle == null) return;
      const alert = alerts.find((x) => x.type === type);

      if (alert == null) return undefined;

      const message =
        SnapInSchoolsCycleContextAlertTypeMessages[type](snapCycle);
      switch (type) {
        case AlertTypeEnum.SnapInSchoolsPostMoceRequired:
        case AlertTypeEnum.SnapInSchoolsPreMoceRequired:
        case AlertTypeEnum.SnapInSchoolsFidelityAdherenceRequired:
          return message;
      }
    },
    [alerts, snapCycle]
  );

  const getDismissAction = useCallback(
    (type: AlertTypeEnum) => {
      switch (type) {
        case AlertTypeEnum.SnapInSchoolsPostMoceRequired:
        case AlertTypeEnum.SnapInSchoolsPreMoceRequired:
          return () => handleCreateAlertDismissalClicked(type);
        case AlertTypeEnum.SnapInSchoolsFidelityAdherenceRequired:
        default:
          return undefined;
      }
    },
    [handleCreateAlertDismissalClicked]
  );

  const getAlertAction = useCallback((type: AlertTypeEnum) => {
    switch (type) {
      case AlertTypeEnum.SnapInSchoolsPostMoceRequired:
      case AlertTypeEnum.SnapInSchoolsPreMoceRequired:
      case AlertTypeEnum.SnapInSchoolsFidelityAdherenceRequired:
      default:
        return () => {};
    }
  }, []);

  const getNotificationType = useCallback((type: AlertTypeEnum) => {
    switch (type) {
      case AlertTypeEnum.SnapInSchoolsPostMoceRequired:
      case AlertTypeEnum.SnapInSchoolsPreMoceRequired:
      case AlertTypeEnum.SnapInSchoolsFidelityAdherenceRequired:
      default:
        return NotificationType.Informational;
    }
  }, []);

  const createNotification = useCallback(
    (type: AlertTypeEnum): NotificationActionDto | undefined => {
      const content = getAlertContent(type);
      if (content == null) return undefined;
      const action = getAlertAction(type);
      const notificationType = getNotificationType(type);
      const onDismiss = getDismissAction(type);
      return {
        id: type,
        message: content,
        type: notificationType,
        action,
        onDismiss,
      };
    },
    [getAlertContent, getAlertAction, getNotificationType, getDismissAction]
  );

  //#endregion

  useEffect(() => {
    if (!snapCycle) return;

    const notifications: NotificationActionDto[] = [
      AlertTypeEnum.SnapInSchoolsPostMoceRequired,
      AlertTypeEnum.SnapInSchoolsPreMoceRequired,
      AlertTypeEnum.SnapInSchoolsFidelityAdherenceRequired,
    ]
      .map(createNotification)
      .filter((x) => x != null)
      .map((x) => x as NotificationActionDto);

    setNotifications(notifications);
  }, [snapCycle, setNotifications, createNotification]);

  return (
    <NotificationBell
      notifications={notifications}
      title="SNAP In Schools and Communities Alerts"
    />
  );
};

export default SnapInSchoolsDetailNotifications;
