import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SetYouthDjjIdNumberFormDto } from "../dto/SetYouthDjjIdNumberFormDto";
import { useEffect } from "react";

const schema = Yup.object().shape({
  djjIdNumber: Yup.string().label("DJJ ID #").required(),
});

export const useSetYouthDjjIdNumberForm = (
  onSubmit: (dto: SetYouthDjjIdNumberFormDto) => Promise<void>,
  currentDjjId?: number
) => {
  //#region State
  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<SetYouthDjjIdNumberFormDto>({
    defaultValues: { djjIdNumber: currentDjjId?.toString() || "" },
    resolver: yupResolver(schema),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset({ djjIdNumber: currentDjjId?.toString() || "" });
  };

  const handleOnSubmit = handleSubmit(
    async (dto: SetYouthDjjIdNumberFormDto) => {
      await onSubmit(dto);
      if (!currentDjjId) clearForm();
    }
  );

  useEffect(() => {
    reset({ djjIdNumber: currentDjjId?.toString() || "" });
  }, [currentDjjId, reset]);

  return {
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
