import NotificationTypeEnum from "../enums/NotificationTypeEnum";
import { v4 } from "uuid";
import React from "react";

const defaultDuration = 3000;

export interface NotificationDto {
  readonly id: string;
  readonly message: React.ReactNode;
  readonly type: NotificationTypeEnum;
  readonly duration: number;
}

export function createSuccessNotificationDto(
  message: string,
  duration: number = defaultDuration
): NotificationDto {
  return createNoficationDto(message, NotificationTypeEnum.Success, duration);
}

export function createWarningNotificationDto(
  message: string,
  duration: number = defaultDuration
): NotificationDto {
  return createNoficationDto(message, NotificationTypeEnum.Warning, duration);
}

export function createDangerNotificationDto(
  message: string,
  duration: number = defaultDuration
): NotificationDto {
  return createNoficationDto(message, NotificationTypeEnum.Danger, duration);
}

function createNoficationDto(
  message: string,
  type: NotificationTypeEnum,
  duration: number
): NotificationDto {
  return { id: v4(), message, type, duration };
}
