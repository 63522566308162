export enum ProgramTypeEnum {
  Shelter = "Shelter",
  CommunityCounseling = "CommunityCounseling",
  StaffSecure = "StaffSecure",
  PhysicallySecure = "PhysicallySecure",
  ProbationRespite = "ProbationRespite",
  DomesticViolenceRespite = "DomesticViolenceRespite",
  IntensiveCaseManagement = "IntensiveCaseManagement",
  FyracDomesticViolenceCommunityCounseling = "FyracDomesticViolenceCommunityCounseling",
  FyracProbationRespiteCommunityCounseling = "FyracProbationRespiteCommunityCounseling",
  Snap = "Snap",
  SnapInSchools = "SnapInSchools",
  SnapYouthJustice = "SnapYouthJustice",
}
