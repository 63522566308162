import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { CaseDto } from "../../../cases/dtos/CaseDto";
import CaseSummaryListItem from "./CaseSummaryListItem";

interface Props {
  cases: CaseDto[];
  isLoading: boolean;
}

const CaseSummaryList: React.FC<Props> = ({ cases, isLoading }) => {
  if (isLoading)
    return (
      <Grid container spacing={1}>
        {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
          <Grid key={`fiscal-loading-${i}`} item md={12} xs={12}>
            {/* <ProviderFiscalYearListLoadingItem /> */}
          </Grid>
        ))}
      </Grid>
    );
  if (Boolean(cases?.length) === false) {
    return <Typography>No Cases Created</Typography>;
  }

  return (
    <Grid container spacing={1}>
      {[...cases]
        .sort((a, b) =>
          a.intakeInfo.intakeAt > b.intakeInfo.intakeAt ? -1 : 1
        )
        .map((caseDto) => (
          <Grid item lg={12} xs={12} key={caseDto.id}>
            <CaseSummaryListItem caseDto={caseDto} />
          </Grid>
        ))}
    </Grid>
  );
};

export default CaseSummaryList;
