import { useRequest } from "../../app/hooks/useRequest";
import { updateServicePlanGoalRequest } from "../ServicePlanRequests";
import { AddServicePlanGoalDto } from "../dto/AddServicePlanGoalDto";
import { useServicePlanPageContext } from "../ServicePlanPageContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getGoalStatusDisplayText,
  ServicePlanGoalStatusEnum,
} from "../enums/ServicePlanGoalStatusEnum";
import { SelectOption } from "../../app/components/inputs/ControlledSelect";
import { ServicePlanGoalDto } from "../dto/ServicePlanDto";
import { useCallback, useEffect, useState } from "react";
import { useCaseDetailPageContext } from "../../features/cases/pages/CaseDetailPage";

const schema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  notes: Yup.string().optional(),
});

export const useUpdateServicePlanGoalForm = (
  originalGoal: ServicePlanGoalDto
) => {
  const [goal, setGoal] = useState<ServicePlanGoalDto>();

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<AddServicePlanGoalDto>({
    defaultValues: {
      name: goal?.name || "",
      notes: goal?.notes || "",
      status: goal?.status || ServicePlanGoalStatusEnum.NotStarted,
      domains: goal?.domains || [],
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = useCallback(() => {
    reset({
      name: goal?.name || "",
      notes: goal?.notes || "",
      status: goal?.status || ServicePlanGoalStatusEnum.NotStarted,
      domains: goal?.domains || [],
    });
  }, [goal?.name, goal?.notes, goal?.status, goal?.domains, reset]);

  const { servicePlan, servicePlanUpdated } = useServicePlanPageContext();
  const {
    case: {
      nirvana: { domainNames },
    },
  } = useCaseDetailPageContext();

  const [updateGoal, , updateGoalRequestState] = useRequest<
    AddServicePlanGoalDto,
    void
  >((dto) =>
    updateServicePlanGoalRequest(servicePlan.id, originalGoal?.id || "", dto)
  );

  const handleOnSubmit = handleSubmit(
    async (formData: AddServicePlanGoalDto) => {
      formData.domains = formData.domains.filter((x) => x !== "");
      await updateGoal(formData);
      clearForm();
      await servicePlanUpdated();
    }
  );

  const goalStatusOptions: SelectOption[] = [
    {
      label: getGoalStatusDisplayText(ServicePlanGoalStatusEnum.NotStarted),
      value: ServicePlanGoalStatusEnum.NotStarted,
    },
    {
      label: getGoalStatusDisplayText(ServicePlanGoalStatusEnum.InProgress),
      value: ServicePlanGoalStatusEnum.InProgress,
    },
    {
      label: getGoalStatusDisplayText(ServicePlanGoalStatusEnum.Complete),
      value: ServicePlanGoalStatusEnum.Complete,
    },
  ];

  useEffect(() => {
    setGoal(originalGoal);
    clearForm();
  }, [clearForm, originalGoal]);

  return {
    state: {
      goal,
      updateGoalRequestState,
      goalStatusOptions,
      domainNames,
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
