import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect } from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import KeyValueRow from "../../../app/components/KeyValueRow";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { UserDetailDto } from "../dtos/UserDetailDto";
import { useUserPinDialog } from "../hooks/useUserPinDialog";

export const userPinModalId = "user-pin-modal";

interface Props {
  user?: UserDetailDto;
}

const UserPinDialog: React.FC<Props> = ({ user }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { userPin, getUserPinRequestState, refreshUserPin } =
    useUserPinDialog(user);

  //#endregion

  //#region
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  //#endregion

  const renderContent = () => {
    if (getUserPinRequestState.isLoading)
      return (
        <>
          <KeyValueRow>
            <Skeleton width={100} />
            <Skeleton width={150} />
          </KeyValueRow>
          <KeyValueRow>
            <Skeleton width={100} />
            <Skeleton width={150} />
          </KeyValueRow>
          <KeyValueRow>
            <Skeleton width={100} />
            <Skeleton width={150} />
          </KeyValueRow>
        </>
      );

    if (userPin == null) return <></>;
    return (
      <>
        {new Date(userPin.expiresAt) <= new Date() && (
          <Grid item xs={12}>
            <RedAlertLabel label="Expired" />
          </Grid>
        )}
        <KeyValueRow lgLabelSize={3}>
          <span>PIN</span>
          <span>{userPin.pin}</span>
        </KeyValueRow>
        <KeyValueRow lgLabelSize={3}>
          <span>Generated At</span>
          <DateTimeLabel date={userPin.generatedAt} />
        </KeyValueRow>
        <KeyValueRow lgLabelSize={3}>
          <span>Expires At</span>
          <DateTimeLabel date={userPin.expiresAt} />
        </KeyValueRow>
      </>
    );
  };

  useEffect(() => {
    if (user !== null && modalId === userPinModalId) refreshUserPin();
  }, [refreshUserPin, user, modalId]);

  return (
    <Dialog open={modalId === userPinModalId} maxWidth="sm" fullWidth>
      <DialogTitle>Last Generated User PIN</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {renderContent()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Close"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UserPinDialog;
