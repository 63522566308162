import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import EditLinkButton from "../../../app/components/buttons/EditLinkButton";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { DjjEntryListDto, SetDjjEntryNotesFormDto } from "../DjjEntryDto";
import { useDjjEntryNotesDialog } from "../hooks/useDjjEntryNotesForm";

export const DjjEntryNotesModalId = "djj-notes-modal";

interface Props {
  entry?: DjjEntryListDto;
  requestState: RequestStateDto;
  onSubmit(dto: SetDjjEntryNotesFormDto): Promise<any>;
}

const DjjEntryNotesDialog: React.FC<Props> = ({
  entry,
  requestState,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { state, form, handlers } = useDjjEntryNotesDialog(onSubmit, entry);
  const { notes, getDjjEntryNotesRequestState, editMode } = state;
  const { clearForm, control, isValid } = form;
  const { handleSubmit, handleToggleEditMode } = handlers;
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  //#endregion

  const renderContent = () => {
    if (getDjjEntryNotesRequestState.isLoading)
      return (
        <Grid item md={12} xs={12}>
          <Box
            height="300px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="primary" />
          </Box>
        </Grid>
      );

    if (editMode)
      return (
        <Grid item md={12} xs={12}>
          <ControlledInput
            control={control}
            name="notes"
            label="Notes"
            disabled={isLoading}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
      );

    return (
      <Grid item md={12} xs={12}>
        <Paper
          variant="outlined"
          style={{
            width: "100%",
            height: "100px",
            padding: "6px",
            overflow: "auto",
            display: "block",
          }}
        >
          <Typography>{notes}</Typography>
        </Paper>
      </Grid>
    );
  };

  const renderTitle = () => {
    if (getDjjEntryNotesRequestState.isLoading) return "Notes";

    if (!editMode)
      return (
        <Box display="flex" justifyContent="space-between">
          <span>Notes</span>{" "}
          <Typography>
            <EditLinkButton onClick={handleToggleEditMode}>Edit</EditLinkButton>
          </Typography>
        </Box>
      );

    return `${notes != null && notes !== "" ? "Update" : "Add"} Notes`;
  };

  return (
    <Dialog open={modalId === DjjEntryNotesModalId} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {renderTitle()}
          <ErrorText error={error?.message} />
          <ErrorText error={getDjjEntryNotesRequestState.error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {renderContent()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content={editMode ? "Cancel" : "Close"}
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          {editMode && (
            <SpinnerButton
              showSpinner={isLoading}
              content="Save"
              color="primary"
              type="submit"
              disabled={!isValid || isLoading}
            />
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DjjEntryNotesDialog;
