import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { YouthSearchParamsDto } from "../dto/YouthSearchParamsDto";
import FilterList from "../../../app/components/FilterList";
import { YouthFilterFormDto } from "../dto/YouthFilterFormDto";

interface Props {
  params: YouthSearchParamsDto;
  isLoading: boolean;
  onSearch(dto: YouthSearchParamsDto): void;
}

const formatDate = (date: string) => {
  return DateTime.fromJSDate(new Date(`${date}T00:00`)).toFormat("MM-dd-yyyy");
};

const YouthFilterList: React.FC<Props> = ({ params, onSearch, isLoading }) => {
  const handleClearParams = () => {
    const form = new YouthFilterFormDto();
    return new YouthSearchParamsDto({
      previous: params,
      form,
    });
  };

  const getLabel = useCallback((key: string, value: any) => {
    switch (key) {
      case "firstName":
        return `First Name: ${value}`;
      case "lastName":
        return `Last Name: ${value}`;
      case "dob":
        return `DOB: ${formatDate(value)}`;
      case "referenceNumber":
        return `Youth ID: ${value}`;
      case "djjIdNumber":
        return `DJJID: ${value}`;
      case "activeOnly":
        return `Active Only`;
    }
    return "";
  }, []);

  const ordering = [
    "firstName",
    "lastName",
    "dob",
    "referenceNumber",
    "djjIdNumber",
    "activeOnly",
  ];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default YouthFilterList;
