import React from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

interface Props extends IconButtonProps {
  expanded: boolean;
  onClick(): void;
}

const CollapseArrowButton: React.FC<Props> = ({ expanded, ...props }) => {
  return (
    <IconButton size="small" {...props}>
      {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};

export default CollapseArrowButton;
