export interface WaitlistFormDto {
  programLocationId: string;
  programId: string;
  locationId: string;
}

export const createWaitlistFormDto = (): WaitlistFormDto => {
  return {
    programLocationId: "",
    programId: "",
    locationId: "",
  };
};
