import { securityAgent } from "../../app/api/agent";
import { IpAddressConfirmationDto } from "./dtos/IpAddressConfirmationDto";
import LoginFormDto from "./dtos/LoginFormDto";

interface TokenResult {
  token: string;
}

export const loginRequest = (dto: LoginFormDto) =>
  securityAgent.postRequest<TokenResult>("auth/login", dto);

export const logoutRequest = () =>
  securityAgent.postRequest("auth/logout", {}, { withCredentials: true });

export const refreshTokenRequest = () =>
  securityAgent.postRequest<TokenResult>("auth/refresh", {});

export const confirmIpAddressRequest = (dto: IpAddressConfirmationDto) =>
  securityAgent.postRequest<TokenResult>(`auth/confirmIpAddress`, dto);
