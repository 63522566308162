import { Divider, DividerTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React, { Fragment } from "react";

interface Props extends DefaultComponentProps<DividerTypeMap<any>> {
  index: number;
  length: number;
}

const ListDivider: React.FC<Props> = ({ index, length, ...props }) => {
  if (index + 1 === length) return <Fragment />;
  return (
    <Divider {...props} variant={props.variant || "inset"} component="li" />
  );
};

export default ListDivider;
