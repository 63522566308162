import React from "react";
import { FormControlLabel, Checkbox, CheckboxProps } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";

interface Props extends CheckboxProps {
  control: Control<any>;
  label?: React.ReactNode;
  name: string;
}

const ControlledCheckbox: React.FC<Props> = ({ control, name, label, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            label={label}
            control={<Checkbox {...props} {...field} checked={field.value} value={field.value} />}
          />
        );
      }}
    />
  );
};

export default ControlledCheckbox;
