import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton, IconButtonProps } from "@material-ui/core";
import { ColorsEnum } from "../../enums/ColorsEnum";

interface Props extends IconButtonProps {}
const EditIconButton: React.FC<Props> = (props) => {
  return (
    <IconButton {...props}>
      <EditIcon style={{ color: ColorsEnum.Blue }} />
    </IconButton>
  );
};

export default EditIconButton;
