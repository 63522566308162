export enum ServicePlanGoalStatusEnum {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Complete = "Complete",
}

export const getGoalStatusDisplayText = (status: ServicePlanGoalStatusEnum) => {
  switch (status) {
    case ServicePlanGoalStatusEnum.NotStarted:
      return "Not Started";
    case ServicePlanGoalStatusEnum.InProgress:
      return "In Progress";
    default:
      return status.toString();
  }
};
