import { Box, Typography } from "@material-ui/core";
import { Fragment } from "react";
import InfoTooltip from "../../../app/components/InfoTooltip";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum DischargeStatusEnum {
  CompletedWithAfterCare = "CompletedWithAfterCare",
  CompletedNoReferral = "CompletedNoReferral",
  CompletedWithReferral = "CompletedWithReferral",
  CompleteRemovedByAgency = "CompleteRemovedByAgency",
  FamilyVoluntarilyWithdrew = "FamilyVoluntarilyWithdrew",
  IncompleteExpelled = "IncompleteExpelled",
  IncompleteRanAway = "IncompleteRanAway",
  IncompleteAdjudticatedDelinquent = "IncompleteAdjudticatedDelinquent",
  AdministrativeClosure = "AdministrativeClosure",
  Other = "Other",
}

export const dischargeStatusTextMap: { [key: string]: string } = {
  [DischargeStatusEnum.CompletedWithAfterCare]:
    "Services Completed, After Care Planned",
  [DischargeStatusEnum.CompletedNoReferral]:
    "Services Completed, No Referral Made",
  [DischargeStatusEnum.CompletedWithReferral]:
    "Services Completed, Referral Made",
  [DischargeStatusEnum.FamilyVoluntarilyWithdrew]:
    "Family Voluntarily Withdrew",
  [DischargeStatusEnum.IncompleteExpelled]:
    "Service Incomplete, Youth Expelled by Provider",
  [DischargeStatusEnum.IncompleteRanAway]: "Service Incomplete, Youth Ran Away",
  [DischargeStatusEnum.CompleteRemovedByAgency]:
    "Services Completed, Youth Removed by Protective Agency",
  [DischargeStatusEnum.IncompleteAdjudticatedDelinquent]:
    "Service Incomplete, Adjudicated Delinquent",
  [DischargeStatusEnum.Other]: "Other",
  [DischargeStatusEnum.AdministrativeClosure]: "Administrative Closure",
};

export const dischargeStatusTooltipMap: { [key: string]: React.ReactNode } = {
  [DischargeStatusEnum.CompletedWithAfterCare]:
    "Completed, Referral Made/Aftercare Planned ",
  [DischargeStatusEnum.CompletedNoReferral]: "Completed, No Referral Made",
  [DischargeStatusEnum.CompletedWithReferral]:
    "Completed, Referral Made/Aftercare Planned ",
  [DischargeStatusEnum.FamilyVoluntarilyWithdrew]:
    "Incomplete, Youth/Family Withdrew ",
  [DischargeStatusEnum.IncompleteExpelled]:
    "Incomplete, Youth Expelled by Provider",
  [DischargeStatusEnum.IncompleteRanAway]: "Incomplete, Youth Ran Away ",
  [DischargeStatusEnum.CompleteRemovedByAgency]:
    "Completed, YTH Removed by Protective Agency",
  [DischargeStatusEnum.IncompleteAdjudticatedDelinquent]:
    "Incomplete, Adjudicated Delinquent",
  [DischargeStatusEnum.Other]: (
    <Fragment>
      Youth Changed Schools, Truancy Resolution
      <br />
      Incomplete, Youth Moved
      <br />
      Incomplete, Other
    </Fragment>
  ),
  [DischargeStatusEnum.AdministrativeClosure]: "Incomplete, Other",
};

export const getDjjReleaseReason = (status: DischargeStatusEnum | undefined): React.ReactNode | undefined => {
  if (status === DischargeStatusEnum.FamilyVoluntarilyWithdrew)
    status = DischargeStatusEnum.CompletedNoReferral;

  return dischargeStatusTooltipMap[status || ""];
}

export const dischargeStatusOptions: SelectOption[] = [
  { value: "", label: "Select Discharge Status" },
  ...Object.keys(DischargeStatusEnum).map((status) => ({
    value: status,
    label: (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography component="span">
          {dischargeStatusTextMap[status]}
        </Typography>

        <InfoTooltip
          title={
            <Fragment>
              <Typography component="span">
                JJIS Release Reason:
                <br />
                <span style={{ fontWeight: "bold" }}>
                  {dischargeStatusTooltipMap[status]}
                </span>
              </Typography>
            </Fragment>
          }
        ></InfoTooltip>
      </Box>
    ),
  })),
];
