import { Box, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import EditButton from "../../../app/components/buttons/EditButton";
import DateLabel from "../../../app/components/labels/DateLabel";
import { TabProps } from "../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { useSnapGroupCycleDetailPageContext } from "../pages/SnapGroupCycleDetailPage";
import SnapCycleGenderLabel from "./SnapCycleGenderLabel";

export const snapCycleDetailTabName = "snap-cycle-details";

const SnapCycleDetails: React.FC = () => {
  const context = useSnapGroupCycleDetailPageContext();
  const { snapCycle, handleEditSnapCycleClicked } = context.snapCycle;

  const createRow = (label: React.ReactNode, content: React.ReactNode) => {
    return (
      <Fragment>
        <Grid item md={3} xs={4}>
          <Typography component="span">{label}:</Typography>
        </Grid>
        <Grid item md={9} xs={8}>
          <Typography component="span">{content}</Typography>
        </Grid>
      </Fragment>
    );
  };

  if (!snapCycle) return <></>;

  return (
    <Box position="relative" padding="16px">
      <Box position="absolute" right="16px">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Snap}>
          <EditButton
            title="Edit SNAP Cycle"
            onClick={handleEditSnapCycleClicked}
          />
        </WriteAccessLevelWrapper>
      </Box>
      <Grid container>
        {createRow("Provider", snapCycle.provider.name)}
        {createRow("Program Type", snapCycle.programType.name)}
        {createRow(
          "Group Start Date",
          <DateLabel date={snapCycle.startedOn} />
        )}
        {createRow(
          "Gender",
          <SnapCycleGenderLabel gender={snapCycle.gender} />
        )}
      </Grid>
    </Box>
  );
};

export default SnapCycleDetails;

export const createSnapCycleDetailTab = (): TabProps => {
  return {
    value: snapCycleDetailTabName,
    label: "Overview",
    content: <SnapCycleDetails />,
  };
};
