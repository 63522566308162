import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import { formatLocation, LocationDto } from "../dtos/LocationDto";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  location: LocationDto;
}

const LocationLabel: React.FC<Props> = ({ location, props }) => {
  return <Typography {...props}>{formatLocation(location)}</Typography>;
};

export default LocationLabel;
