import { useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { useAppDispatch } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { providerModalId } from "../components/ProviderFormDialog";
import { ProviderFormDto } from "../dtos/ProviderFormDto";
import {
  createProviderSearchParamsDtoFromQuery,
  ProviderSearchParamsDto,
  providerSearchParamsToQueryString,
} from "../dtos/ProviderSearchParamsDto";
import {useCreateProvider, useGetProviders} from "./providerHooks";

export const useProviderListPage = () => {
  //#region State
  const query = useQuery();
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<ProviderSearchParamsDto>(
    createProviderSearchParamsDtoFromQuery(query)
  );
  const history = useHistory();
  const [getProviders, providerResults, getProvidersRequestState] =
    useGetProviders();
  const [
    createProvider,
    ,
    createProviderRequestState,
    clearCreateProviderError,
  ] = useCreateProvider();

  //#endregion

  //#region Handlers
  const handlePageChange = (page: number) => {
    setParams((params) => ({ ...params, page: page }));
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    history.push(`/provider?${providerSearchParamsToQueryString(newParams)}`);
    setParams(newParams);
  };

  const handleCreateProviderClicked = () => {
    clearCreateProviderError();
    dispatch(openModal({ modalId: providerModalId }));
  };

  const handleCreateProvider = async (dto: ProviderFormDto) => {
    const result = await createProvider(dto);
    batch(() => {
      dispatch(addSuccessNotification({ message: "Provider Created" }));
      dispatch(closeModal());
    });
    history.push(`/provider/${result?.id}`);
  };

  //#endregion

  //#region Effects
  useEffect(() => {
    getProviders(params);
  }, [getProviders, params]);

  useEffect(() => {
    history.push(`/provider?${providerSearchParamsToQueryString(params)}`);
  }, [params, history]);
  //#endregion

  return {
    state: {
      params,
      providerResults,
      getProvidersRequestState,
      createProviderRequestState,
    },
    handlers: {
      handlePageChange,
      handleSort,
      handleCreateProviderClicked,
      handleCreateProvider,
    },
  };
};
