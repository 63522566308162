import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { SnapGroupSummaryDto } from "../dtos/SnapGroupSummaryDto";
import {
  createSnapGroupFormDto,
  SnapGroupFormDto,
} from "../dtos/SnapGroupFormDto";
import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { SnapCurriculumDto } from "../dtos/SnapCurriculumDto";

export const useSnapGroupForm = (
  onSubmit: (dto: SnapGroupFormDto) => Promise<void>,
  curriculum: SnapCurriculumDto[],
  snapGroup?: SnapGroupSummaryDto,
  snapCycle?: SnapGroupCycleDto
) => {
  const schema = Yup.object().shape({
    groupHeldOn: Yup.string()
      .label("Group Date")
      .required()
      .test(
        "test-cycle-start-date",
        "Group Date cannot occur before Group Cycle Start Date.",
        function (value) {
          if (value === undefined || value === "" || snapCycle === undefined)
            return true;
          var date = new Date(`${value}T00:00:00`);
          return date >= new Date(snapCycle.startedOn);
        }
      ),
    snapCurriculumId: Yup.string().label("Session Name").required(),
  });
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: createSnapGroupFormDto(snapGroup),
    resolver: yupResolver(schema),
    mode: "all",
  });
  const { isMakeupGroup, snapCurriculumId } = watch();
  const [isConfirmed, setIsConfirmed, handleToggleConfirmed] = useTogglable();
  const [filteredCurriculum, setFilteredCurriculum] = useState<
    SnapCurriculumDto[]
  >([]);
  //#endregion

  const clearForm = () => {
    reset(createSnapGroupFormDto(snapGroup));
  };
  const handleOnSubmit = handleSubmit(async (dto: SnapGroupFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  useEffect(() => {
    reset(createSnapGroupFormDto(snapGroup));
    setIsConfirmed(false);
  }, [snapGroup, setIsConfirmed, reset]);

  useEffect(() => {
    if (snapCycle) {
      const currentCurriculumIds = snapCycle.groups.map(
        (g) => g.snapCurriculumId
      );
      setFilteredCurriculum(
        curriculum.filter(
          (x) =>
            !currentCurriculumIds.includes(x.id) !== isMakeupGroup ||
            snapGroup?.snapCurriculumId === x.id
        )
      );
    }
  }, [setFilteredCurriculum, snapCycle, snapGroup, isMakeupGroup, curriculum]);

  useEffect(() => {
    if (snapCycle == null) return;

    if (snapCurriculumId === "") return;

    if (filteredCurriculum.some((x) => x.id === snapCurriculumId)) return;

    setValue("snapCurriculumId", "");
  }, [setValue, snapCurriculumId, filteredCurriculum, snapCycle]);

  return {
    state: { isConfirmed, filteredCurriculum },
    form: {
      control,
      isValid: isValid && (snapGroup === undefined || isConfirmed),
      clearForm,
    },
    handlers: { handleSubmit: handleOnSubmit, handleToggleConfirmed },
  };
};
