import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";

interface Props {
  title: React.ReactNode;
  actions?: React.ReactNode;
  secondaryActions?: React.ReactNode;
  isLoading?: boolean;
}

const Header: React.FC<Props> = ({
  title,
  actions,
  secondaryActions,
  isLoading,
}) => {
  return (
    <Fragment>
      <Box
        marginBottom="16px"
        marginTop="16px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">
          {isLoading ? <Skeleton animation="wave" width="300px" /> : title}
        </Typography>
        {actions && <Box>{actions}</Box>}
      </Box>
      {secondaryActions && <Box marginBottom="4px">{secondaryActions}</Box>}
    </Fragment>
  );
};

export default Header;
