import {
  Box,
  IconButton,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import EditLinkButton from "../../../app/components/buttons/EditLinkButton";
import DateLabel from "../../../app/components/labels/DateLabel";
import GreenAlertLabel from "../../../app/components/labels/GreenAlertLabel";
import YellowAlertLabel from "../../../app/components/labels/YellowAlertLabel";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import {
  DjjEntryListDto,
  DjjEntrySearchParamsDto,
  DjjEntryStatusEnum,
  DjjEntryStatusTextMap,
  DjjEntryTypeTextMap,
} from "../DjjEntryDto";
import { useDjjEntryListPageContext } from "../pages/DjjEntryListPage";
import NoteIcon from "@material-ui/icons/Note";
import PageviewIcon from "@material-ui/icons/Pageview";
import GreyAlertLabel from "../../../app/components/labels/GreyAlertLabel";
import EditIconButton from "../../../app/components/buttons/EditIconButton";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
const DjjEntryList: React.FC<
  SortableTableProps<DjjEntryListDto, DjjEntrySearchParamsDto>
> = (props) => {
  const context = useDjjEntryListPageContext();
  const { handleSetDjjEntryStatusClicked } = context.entryStatus;
  const { handleSetYouthDjjIdClicked } = context.youthDjjId;
  const { handleOpenDjjEntryNotesClicked } = context.notes;
  const { handleViewDjjEntryDetailsClicked } = context.details;

  const columns: ColumnProps[] = [
    { name: "djjEntryType", label: "Type" },
    { name: "eventDate", label: "Date" },
    { name: "dataEntryDate", label: "N3 Data Entry" },
    { name: "lag", label: "Lag" },
    { name: "youthName", label: "Youth Name" },
    { name: "youthReferenceNumber", label: "Youth ID" },
    { name: "djjReferenceNumber", label: "DJJ ID" },
    { name: "programType", label: "Program Type" },
    { name: "providerName", label: "Provider" },
    { name: "judicialCircuitNumber", label: "Circuit #" },
    { name: "juvenileJusticeProgram", label: "Juvenile Justice Program" },
    { name: "status", label: "Status" },
    { name: "notes", label: "", disabled: true },
  ];

  const getStatusLabel = (entry: DjjEntryListDto) => {
    let label: React.ReactNode = (
      <GreyAlertLabel
        label={DjjEntryStatusTextMap[entry.status]}
        hide={false}
      />
    );

    if (entry.status === DjjEntryStatusEnum.InProgress)
      label = (
        <YellowAlertLabel
          label={DjjEntryStatusTextMap[entry.status]}
          hide={false}
          style={{ color: "black" }}
        />
      );

    if (entry.status === DjjEntryStatusEnum.Completed)
      label = (
        <GreenAlertLabel
          label={DjjEntryStatusTextMap[entry.status]}
          hide={false}
        />
      );

    return (
      <Tooltip
        style={{ cursor: "pointer" }}
        title={
          <Typography>
            <DateTimeLabel
              date={entry.statusEnteredAt || entry.dataEntryDate}
            />
          </Typography>
        }
      >
        <div>{label}</div>
      </Tooltip>
    );
  };

  const renderRow = (entry: DjjEntryListDto) => {
    return (
      <TableRow key={entry.referenceId}>
        <TableCell>{DjjEntryTypeTextMap[entry.djjEntryType]}</TableCell>
        <TableCell>
          <DateLabel date={entry.eventDate} />
        </TableCell>
        <TableCell>
          <DateLabel date={entry.dataEntryDate} />
        </TableCell>
        <TableCell>{entry.lag}</TableCell>
        <TableCell>
          <RouteableStyledLink
            text="Back to DJJ Related Data"
            pathname={`/youths/${entry.youthId}`}
          >
            {entry.youthName}
          </RouteableStyledLink>
        </TableCell>
        <TableCell>
          <RouteableStyledLink
            text="Back to DJJ Related Data"
            pathname={`/youths/${entry.youthId}`}
          >
            {entry.youthReferenceNumber}
          </RouteableStyledLink>
        </TableCell>
        <TableCell>
          {entry.djjIdNumber ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              {entry.djjIdNumber}

              <EditIconButton
                onClick={() => handleSetYouthDjjIdClicked(entry)}
              />
            </Box>
          ) : (
            <EditLinkButton onClick={() => handleSetYouthDjjIdClicked(entry)}>
              Set DJJ ID
            </EditLinkButton>
          )}
        </TableCell>
        <TableCell>{entry.programType}</TableCell>
        <TableCell>
          <RouteableStyledLink
            text="Back to DJJ Related Data"
            pathname={`/provider/${entry.providerId}`}
          >
            {entry.providerName}
          </RouteableStyledLink>
        </TableCell>
        <TableCell>{entry.judicialCircuitNumber}</TableCell>
        <TableCell width="200px">{entry.juvenileJusticeProgram}</TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {getStatusLabel(entry)}
          </Box>
        </TableCell>
        <TableCell>
          <WriteAccessLevelWrapper name={PermissionResourceNameEnum.DjjEntries}>
            <EditLinkButton
              className="mr-1"
              onClick={() => handleSetDjjEntryStatusClicked(entry)}
            >
              Set Status
            </EditLinkButton>
          </WriteAccessLevelWrapper>
          <IconButton
            className="mr-1"
            onClick={() => handleViewDjjEntryDetailsClicked(entry)}
            title="View Details"
          >
            <PageviewIcon />
          </IconButton>
          <IconButton
            title={`${entry.hasNotes ? "Has" : "Add"} Notes`}
            style={{ color: entry.hasNotes ? ColorsEnum.Green : undefined }}
            onClick={() => handleOpenDjjEntryNotesClicked(entry)}
          >
            <NoteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default DjjEntryList;
