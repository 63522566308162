import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHttpError, refreshTokenIfNeeded } from "../../../app/helpers";
import { getProgramTypesRequest } from "../ProgramTypeRequests";

export const getProgramTypes = createAsyncThunk(
  "program-types/get-program-types",
  async (_, { dispatch, rejectWithValue }) => {
    await refreshTokenIfNeeded(dispatch);
    try {
      const result = await getProgramTypesRequest();
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);
