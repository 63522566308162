import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum SchoolStatusEnum {
  AttendingRegularly = "AttendingRegularly",
  GraduatedHighSchool = "GraduatedHighSchool",
  CompletedGed = "CompletedGed",
  AttendingIrregularly = "AttendingIrregularly",
  DroppedOut = "DroppedOut",
  Suspended = "Suspended",
  Expelled = "Expelled",
  SchoolNotInSession = "SchoolNotInSession",
  DoNotKnow = "DoNotKnow",
}

export const schoolStatusTextMap: { [key: string]: string } = {
  [SchoolStatusEnum.AttendingRegularly]: "Attending School Regularly",
  [SchoolStatusEnum.GraduatedHighSchool]: "Graduated High School",
  [SchoolStatusEnum.CompletedGed]: "Completed GED",
  [SchoolStatusEnum.AttendingIrregularly]:
    "Attending School Irreg/Extended Truancy",
  [SchoolStatusEnum.DroppedOut]: "Dropped Out",
  [SchoolStatusEnum.Suspended]: "Suspended",
  [SchoolStatusEnum.Expelled]: "Expelled",
  [SchoolStatusEnum.SchoolNotInSession]: "School Not in Session",
  [SchoolStatusEnum.DoNotKnow]: "Do Not know",
};

export const schoolStatusOptions: SelectOption[] = [
  { value: "", label: "Select School Status" },
  ...Object.keys(SchoolStatusEnum).map((status) => ({
    value: status,
    label: schoolStatusTextMap[status],
  })),
];
