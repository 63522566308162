import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import {
  OutreachActivityDto,
  OutreachActivitySearchParamsDto,
  OutreachActivitySummaryDto,
} from "./dtos/OutreachActivityDto";
import { OutreachActivityFormDto } from "./dtos/OutreachActivityFormDto";

export const getOutreachActivitiesRequest = (
  dto: OutreachActivitySearchParamsDto
) =>
  coreAgent.getRequest<PaginationResultDto<OutreachActivitySummaryDto>>(
    `/outreachActivities?${OutreachActivitySearchParamsDto.toQueryString(dto)}`
  );

export const getOutreachActivityRequest = (id: string) =>
  coreAgent.getRequest<OutreachActivityDto>(`/outreachActivities/${id}`);

export const updateOutreachActivityRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: OutreachActivityFormDto;
}) =>
  coreAgent.putRequest<OutreachActivityDto>(`/outreachActivities/${id}`, dto);

export const deleteOutreachActivityRequest = (id: string) =>
  coreAgent.deleteRequest(`/outreachActivities/${id}`);

export const exportOutreachActivityListRequest = ({
  searchParams,
  filename,
}: {
  searchParams: OutreachActivitySearchParamsDto;
  filename: string;
}) =>
  coreAgent.downloadFileRequest(
    `outreachActivities/export?${OutreachActivitySearchParamsDto.toQueryString(searchParams)}`,
    filename
  );
