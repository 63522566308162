import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fnyfsName, fnyfsProviderId } from "../../authentication/constants";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { providerToOptionDtos } from "../../provider/dtos/ProviderDto";
import { ForwardReferralFormDto } from "../dtos/ForwardReferralFormDto";
import { ReferralRouteDto } from "../dtos/ReferralRouteDto";
import { useForwardReferralForm } from "../hooks/useForwardReferralForm";

export const forwardReferralModalId = "forward-referral-form-modal";

interface Props {
  onSubmit: (dto: ForwardReferralFormDto) => Promise<any>;
  requestState: RequestStateDto;
  currentRoute?: ReferralRouteDto;
}

const ForwardReferralFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  currentRoute,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { form, handlers, state } = useForwardReferralForm(
    onSubmit,
    currentRoute
  );
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { providers, getProvidersRequestState } = state;

  const { error: providerError, isLoading: providersAreLoading } =
    getProvidersRequestState;
  const { error } = requestState;
  const isLoading = providersAreLoading || requestState.isLoading;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  const sortedProviders = () => {
    return providers.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  return (
    <Dialog open={modalId === forwardReferralModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Forward Referral
          <ErrorText error={error?.message} />
          <ErrorText error={providerError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <ControlledSelect
              isLoading={providersAreLoading}
              name="providerId"
              control={control}
              options={providerToOptionDtos(sortedProviders(), [
                { label: fnyfsName, value: fnyfsProviderId },
              ])}
              fullWidth
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ForwardReferralFormDialog;
