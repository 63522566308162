import * as Yup from "yup";

export const youthRecordTransferModalId = "youth-record-transfer-form-modal";

export class YouthRecordFormDto {
  youthReferenceNumber: string;

  constructor(youthId?: string) {
    this.youthReferenceNumber = youthId || "";
  }

  public static getSchema() {
    return Yup.object().shape({
      youthReferenceNumber: Yup.string().label("Youth ID").required()
    });
  }
}

