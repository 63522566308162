import { datetimeToFormString } from "../../../app/helpers";

export interface CaseHoldFormDto {
  type: string;
  startAt: string | Date;
}

export const createCaseHoldFormDto = (): CaseHoldFormDto => {
  return {
    type: "",
    startAt: datetimeToFormString(new Date()),
  };
};
