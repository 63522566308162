import { Typography } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import ErrorText from "../../../app/components/ErrorText";
import InvoiceCorrectionConfirmationCheckbox from "../../../app/components/InvoiceCorrectionConfirmationCheckbox";
import DateLabel from "../../../app/components/labels/DateLabel";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { CaseSessionDto } from "../dtos/CaseSessionDto";

export const confirmDeleteCaseSessionDialogId = "delete-case-session-dialog";

interface Props {
    onConfirm(): Promise<void>;
    requestState: RequestStateDto;
    caseSession?: CaseSessionDto;
}

const CaseSessionDeleteDialog: React.FC<Props> = ({
    onConfirm,
    requestState,
    caseSession
}) => {
    const { error, isLoading } = requestState;
    const [
        isDeleteConfirmed,
        setIsDeleteConfirmed,
        toggleIsDeleteConfirmed
    ] = useTogglable(false);

    const onCheck = useCallback(() => {
        toggleIsDeleteConfirmed();
    }, [toggleIsDeleteConfirmed]);

    useEffect(() => {
        setIsDeleteConfirmed(false);
    }, [caseSession, setIsDeleteConfirmed]);

    return (
        <ConfirmDialog
            title="Confirm"
            onConfirm={onConfirm}
            modalId={confirmDeleteCaseSessionDialogId}
            isLoading={isLoading}
            confirmButtonText="Delete Case Session"
            confirmButtonStyle={{ color: isDeleteConfirmed ? ColorsEnum.Red : ColorsEnum.DisabledRed }}
            confirmButtonDisabled={!isDeleteConfirmed}
        >
        <ErrorText error={error?.message} />
        <Typography>
          Are you sure you want to delete Case Session Held On{" "}
          <DateLabel date={caseSession?.sessionHeldOn} />? <br />
        </Typography>
        <InvoiceCorrectionConfirmationCheckbox
            isConfirmed={isDeleteConfirmed}
            onCheck={onCheck}
        />
      </ConfirmDialog>
    );
};

export default CaseSessionDeleteDialog;