import React from "react";
import { AccessLevelEnum } from "../../../features/authentication/enums/AccessLevelEnum";
import { PermissionResourceNameEnum } from "../../../features/authentication/enums/PermissionResourceNameEnum";
import AccessLevelWrapper from "./AccessLevelWrapper";

interface Props {
  name: PermissionResourceNameEnum;
  alternative?: React.ReactNode;
}

const ExecuteAccessLevelWrapper: React.FC<Props> = ({
  name,
  children,
  alternative,
}) => {
  return (
    <AccessLevelWrapper
      name={name}
      accessLevel={AccessLevelEnum.Execute}
      alternative={alternative}
    >
      {children}
    </AccessLevelWrapper>
  );
};

export default ExecuteAccessLevelWrapper;
