import { dateToFormString } from "../../../app/helpers";
import { CaseSessionDto } from "./CaseSessionDto";

export interface CaseSessionFormDto {
    sessionHeldOn: string;
    sessionType: string;
}

export const createCaseSessionFormDto = (
    dto?: CaseSessionDto
): CaseSessionFormDto => {
    if (dto) {
        const { sessionHeldOn } = dto;
        return {
            sessionHeldOn: dateToFormString(sessionHeldOn),
            sessionType: dto.sessionType
        }
    }

    return {
        sessionHeldOn: '',
        sessionType: ""
    };
};
