import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { useAppDispatch } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { useRequest } from "../../../app/hooks/useRequest";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { useGetAllProviders, useGetProviderPrograms } from "../../provider/hooks/providerHooks";
import { exportSnapInSchoolsRequest } from "../SnapInSchoolsRequests";
import { snapInSchoolsCycleModalId } from "../components/SnapInSchoolsCycleFormDialog";
import { SnapInSchoolsCycleFormDto } from "../dtos/SnapInSchoolsCycleFormDto";
import { SnapInSchoolsSearchParamsDto } from "../dtos/SnapInSchoolsSearchParamsDto";
import {
  useCreateSnapInSchoolsCycle,
  useGetSnapInSchoolsCycles,
} from "./snapInSchoolsHooks";
import { ProgramDto } from "../../programs/dtos/ProgramDto";

export const useSnapInSchoolsCycleListPage = () => {
  //#region State
  const query = useQuery();
  const [params, setParams] = useState<SnapInSchoolsSearchParamsDto>(
    new SnapInSchoolsSearchParamsDto({ query })
  );
  const [getSnapCycles, snapCyclesResult, getSnapCyclesRequestState] =
    useGetSnapInSchoolsCycles();
  const history = useHistory();
  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [
    createSnapCycle,
    ,
    createSnapCycleRequestState,
    clearCreateSnapCycleErrors,
  ] = useCreateSnapInSchoolsCycle();
  const dispatch = useAppDispatch();
  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();

  const [exportSnapInSchoolsList, , exportSnapInSchoolsRequestState] = 
    useRequest(exportSnapInSchoolsRequest)

  const [providerPrograms, setProviderPrograms] = useState<{
      [key: string]: ProgramDto[];
    }>({});

  const [getPrograms, , getProgramsRequestState] = useGetProviderPrograms();
  
  //#endregion

  //#region Handlers
  const handlePageChange = (page: number) => {
    setParams((params) => ({ ...params, page }));
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    setParams(newParams);
  };

  const handleSearch = (dto: SnapInSchoolsSearchParamsDto) => {
    setParams(dto);
  };

  const handleOpenFilterClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => setFilterAnchorEl(null);

  const handleCreateSnapCycleClicked = () => {
    clearCreateSnapCycleErrors();
    dispatch(openModal({ modalId: snapInSchoolsCycleModalId }));
  };

  const handleCreateSnapCycle = async (dto: SnapInSchoolsCycleFormDto) => {
    const result = await createSnapCycle(dto);
    batch(() => {
      dispatch(closeModal());
      dispatch(
        addSuccessNotification({
          message: "SNAP In Schools and Communities Cycle Created",
        })
      );
    });

    history.push(`/snap-in-schools/${result?.id}`);
  };

  const handleExportClicked = useCallback((dto: SnapInSchoolsSearchParamsDto) => {
    const filename = `snap-export-${DateTime.now().toFormat('yyyyLLddHHmmss')}.csv`
    exportSnapInSchoolsList({ searchParams: dto, filename });
  }, [exportSnapInSchoolsList]);

  const updatePrograms = useCallback(
    async (providerId: string) => {
      if (
        providerId === "" ||
        providerId == null ||
        providerPrograms[providerId]
      ) {
        return;
      }

      console.log("useSnapInSchoolsCycleListPage", providerId);
      const result = await getPrograms(providerId);
      if (result) {
        setProviderPrograms((current) => ({
          ...current,
          [providerId]: result,
        }));
      }
      console.log("useSnapInSchoolsCycleListPage", providerPrograms);
    },
    [setProviderPrograms, providerPrograms, getPrograms]
  );  

  const handleProviderChange = useCallback(
    async (providerId: string) => {
      await updatePrograms(providerId);
    },
    [updatePrograms]
  );  
  //#endregion

  //#region Effects
  useEffect(() => {
    getSnapCycles(params);
  }, [params, getSnapCycles]);

  useEffect(() => {
    history.push(
      `/snap-in-schools?${SnapInSchoolsSearchParamsDto.toQueryString(params)}`
    );
  }, [params, history]);

  useEffect(() => {
    if (getProviderId() === fnyfsProviderId) getProviders(undefined);
  }, [getProviders]);
  //#endregion

  return {
    state: {
      filterAnchorEl,
      snapCyclesResult,
      params,
      getSnapCyclesRequestState,
      createSnapCycleRequestState,
      providers: providers || [],
      getProvidersRequestState,
      providerPrograms,
      getProgramsRequestState,
      exportSnapInSchoolsRequestState,       
    },
    handlers: {
      handlePageChange,
      handleSearch,
      handleOpenFilterClicked,
      handleCloseFilter,
      handleCreateSnapCycleClicked,
      handleCreateSnapCycle,
      handleSort,
      handleExportClicked,
      handleProviderChange      
    },
  };
};
