import { Box, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import WriteAccessLevelWrapper from "../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import DeleteButton from "../../../../../app/components/buttons/DeleteButton";
import EditButton from "../../../../../app/components/buttons/EditButton";
import ErrorText from "../../../../../app/components/ErrorText";
import KeyValueRow from "../../../../../app/components/KeyValueRow";
import YesNoLabel from "../../../../../app/components/labels/YesNoLabel";
import ConfirmDialog from "../../../../../app/components/modals/ConfirmDialog";
import { TabProps } from "../../../../../app/components/TabContainer";
import { ColorsEnum } from "../../../../../app/enums/ColorsEnum";
import { PermissionResourceNameEnum } from "../../../../authentication/enums/PermissionResourceNameEnum";
import DemographicFormDialog from "../../../../demographics/components/DemographicFormDialog";
import DemographicsFamilyWeeklyIncomeLabel from "../../../../demographics/components/labels/DemographicFamilyWeeklyIncomeLabel";
import DemographicsFamilyStructureLabel from "../../../../demographics/components/labels/DemographicsFamilyStructureLabel";
import DemographicsHeadOfHouseholdLabel from "../../../../demographics/components/labels/DemographicsHeadOfHouseholdLabel";
import DemographicsMaritalStatusLabel from "../../../../demographics/components/labels/DemographicsMaritalStatusLabel";
import DemographicsParentalStatusLabel from "../../../../demographics/components/labels/DemographicsParentalStatusLabel";
import { ProgramFundingSourceEnum } from "../../../../programs/enums/ProgramFundingSourceEnum";
import { useCaseDetailPageContext } from "../../../pages/CaseDetailPage";

const defaultValue = "Not Provided";

export const demographicTabName = "demographic-section";

export const deleteDemographicConfirmModalId =
  "delete-demographic-confirm-modal-id";

const DemographicSection = () => {
  //#region State
  const {
    case: { caseResult },
    demographic,
  } = useCaseDetailPageContext();
  const {
    upsertDemographicRequestState,
    handleUpsertDemographicClicked,
    handleUpsertDemographic,
    deleteDemographicRequestState,
    handleDeleteDemographicClicked,
    handleDeleteDemographic,
  } = demographic;

  const { isLoading, error } = deleteDemographicRequestState;
  const isFnFunded =
    caseResult?.program.fundingSource ===
    ProgramFundingSourceEnum.FloridaNetwork;
  //#endregion

  //#region UI Helpers
  const createRow = (label: React.ReactNode, value: React.ReactNode) => {
    return (
      <KeyValueRow mdLabelSize={4} xsLabelSize={6}>
        {label}
        {value}
      </KeyValueRow>
    );
  };

  //#endregion

  return (
    <Box position="relative">
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <DemographicFormDialog
          onSubmit={handleUpsertDemographic}
          requestState={upsertDemographicRequestState}
          caseResult={caseResult}
        />
        <ConfirmDialog
          modalId={deleteDemographicConfirmModalId}
          onConfirm={handleDeleteDemographic}
          isLoading={isLoading}
          title={`Clear Demographics`}
          confirmButtonStyle={{
            color: ColorsEnum.Red,
          }}
        >
          <ErrorText error={error?.message} />
          Clear Demographics for Case {caseResult?.caseNumber}? This will clear
          all answers from demographics
          {isFnFunded ? " except for Family Strucure" : ""}.
          {isFnFunded && (
            <Typography color="textSecondary" style={{ marginTop: "16px" }}>
              *Family Structure is required for all Florida Network funded
              Cases. This field will not be cleared by this action.
            </Typography>
          )}
        </ConfirmDialog>
        <Box position="absolute" right="2px">
          {caseResult?.demographic && (
            <DeleteButton
              text="Clear"
              onClick={handleDeleteDemographicClicked}
            />
          )}
          <EditButton
            text={`${caseResult?.demographic ? "Update" : "Add"}`}
            onClick={handleUpsertDemographicClicked}
          />
        </Box>
      </WriteAccessLevelWrapper>
      {caseResult?.demographic == null && (
        <Typography>No Demographic Information Added</Typography>
      )}
      {caseResult?.demographic != null && (
        <Grid container spacing={1}>
          {createRow(
            "Is the Youth a parent",
            <DemographicsParentalStatusLabel
              status={caseResult.demographic.parentalStatus}
              defaultValue={defaultValue}
            />
          )}
          {createRow(
            "Youth Marital Status",
            <DemographicsMaritalStatusLabel
              status={caseResult.demographic.maritalStatus}
              defaultValue={defaultValue}
            />
          )}
          <Grid item md={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography color="primary">Household Information</Typography>
          </Grid>
          {createRow(
            "Family Structure",
            <DemographicsFamilyStructureLabel
              familyStructure={caseResult.demographic.familyStructure}
              defaultValue={defaultValue}
            />
          )}
          {createRow(
            "Head of Household",
            <DemographicsHeadOfHouseholdLabel
              headOfHousehold={caseResult.demographic.headOfHousehold}
              defaultValue={defaultValue}
            />
          )}
          {createRow(
            "# of Household Members",
            caseResult.demographic.numberOfHouseholdMembers || defaultValue
          )}
          {createRow(
            "Family Weekly Income",
            <DemographicsFamilyWeeklyIncomeLabel
              weeklyIncome={caseResult.demographic.familyWeeklyIncome}
              defaultValue={defaultValue}
            />
          )}
          {createRow(
            "Father Employed",
            caseResult.demographic.fatherEmployed != null ? (
              <YesNoLabel value={caseResult.demographic.fatherEmployed} />
            ) : (
              defaultValue
            )
          )}
          {createRow(
            "Mother Employed",
            caseResult.demographic.motherEmployed != null ? (
              <YesNoLabel value={caseResult.demographic.motherEmployed} />
            ) : (
              defaultValue
            )
          )}
        </Grid>
      )}
    </Box>
  );
};

export default DemographicSection;
export const createDemographicTab = (): TabProps => ({
  label: "Demographics",
  value: demographicTabName,
  content: <DemographicSection />,
});
