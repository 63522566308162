import { Box, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledSelect from "../../../../app/components/inputs/ControlledSelect";
import {
  getLocationOptions,
  LocationDto,
} from "../../../locations/dtos/LocationDto";
import InfoTooltip from "../../../../app/components/InfoTooltip";
import ControlledInput from "../../../../app/components/inputs/ControlledInput";

interface Props {
  control: Control<any>;
  isDisabled: boolean;
  locations: LocationDto[];
  providerLocationsAreLoading: boolean;
  locationMessage: string;
}

const CaseLocationFormFields: React.FC<Props> = ({
  control,
  isDisabled,
  locations,
  providerLocationsAreLoading,
  locationMessage,
}) => {
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          name="locationId"
          label="Location"
          control={control}
          disabled={isDisabled}
          options={getLocationOptions(
            locations,
            <Box display="flex" justifyContent="space-between" width="100%">
              <div>Select Location</div>{" "}
              <InfoTooltip title={<Typography>{locationMessage}</Typography>} />
            </Box>
          )}
          isLoading={providerLocationsAreLoading}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="startOn"
          label="Start On*"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Fragment>
  );
};

export default CaseLocationFormFields;
