import { Fragment } from "react";
import ExecuteAccessLevelWrapper from "../../../app/components/access-wrappers/ExecuteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import CaseHoldFormDialog from "../../case-hold/components/CaseHoldFormDialog";
import EndCaseHoldFormDialog from "../../case-hold/components/EndCaseHoldFormDialog";
import ChangeDischargeDateFormDialog from "../../discharges/components/ChangeDischargeDateFormDialog";
import DischargeFormDialog from "../../discharges/components/DischargeFormDialog";
import CaseContactFormDialog from "../../case-contacts/components/CaseContactFormDialog";
import ChangeIntakeDateFormDialog from "../../intakes/components/ChangeIntakeDateFormDialog";
import SetYouthDjjIdNumberFormDialog from "../../youths/components/SetYouthDjjIdNumberFormDialog";
import { useCaseDetailPageContext } from "../pages/CaseDetailPage";
import CaseProgramLocationFormDialog from "./change-program-location/CaseProgramFormDialog";
import ConfirmDeleteCaseDialog from "./ConfirmDeleteCaseDialog";
import SetCaseScreeningFormDialog from "./SetCaseScreeningFormDialog";
import AssessmentFormDialog from "../../assessments/components/AssessmentFormDialog";
import HumanTraffickingScreeningToolFormDialog from "../../human-trafficking-screening-tool/components/HumanTraffickingScreeningToolFormDialog";
import CaseSessionsFormDialog from "../../case-sessions/components/CaseSessionsFormDialog";
import AlertDismissalFormDialog from "../../alerts/components/AlertDismissalFormDialog";
import CaseProgramLocationHistoryDialog from "./CaseProgramLocationHistoryDialog";
import { useId } from "../../../app/hooks/useId";
import CaseFormDialog from "./CaseFormDialog";
import CaseLocationFormDialog from "./change-program-location/CaseLocationFormDialog";
import {TransferYouthRecordDialog} from "../../transfer-youth-record/components/transfer-case/TransferYouthRecordDialog";
import ConfirmDeleteCaseContactDialog from "../../case-contacts/components/ConfirmDeleteCaseContactDialog";
import ConfirmClearDischargeDialog from "../../discharges/components/ClearDischargeConfirmationDialog";
import CaseContactNotesDialog from "../../case-contacts/components/CaseContactNotesDialog";
import ConfirmDeleteCourtOrderAttachmentDialog from "./ConfirmDeleteCourtOrderedAttachmentDialog";

const CaseDialogs = () => {
  //#region State
  const {
    case: {
      deleteCaseRequestState,
      caseResult,
      activeCaseHold,
      screening,
      intake,
      programLocation,
      handleDeleteCase,
      assessment,
      handleUpdateCase,
      updateCaseRequestState,
      handleDeleteCourtOrderAttachment,
      deleteCourtOrderAttachmentRequestState
    },
    youth,
    discharge,
    caseHold,
    caseContacts,
    htst,
    caseSession,
    alerts,
    refreshCase,
  } = useCaseDetailPageContext();
  const {
    upsertCaseDischargeRequestState: dischargeCaseRequestState,
    changeDischargeDateRequestState,
    handleUpsertCaseDischarge: handleDischargeCase,
    handleChangeDischargeDate,
    clearDischargeRequestState,
    handleClearDischarge,
  } = discharge;
  const { setCaseScreeningRequestState, handleSetCaseScreening } = screening;
  const {
    createCaseHoldRequestState,
    endCaseHoldRequestState,
    handleCreateCaseHold,
    handleEndCaseHold,
  } = caseHold;
  const { selectedCaseContactId, createCaseContactRequestState, handleCreateCaseContact, handleDeleteCaseContact, handleUpdateCaseContact, deleteCaseContactRequestState } =
    caseContacts;
  const { changeIntakeDateRequestState, handleChangeIntakeDate } = intake;
  const {
    changeProgramLocationRequestState,
    handleChangeProgramLocation,
    changeLocationRequestState,
    handleChangeLocation,
  } = programLocation;
  const { setYouthDjjIdNumberRequestState, handleSetYouthDjjIdNumber } = youth;
  const {
    assessments,
    handleStartAssessment,
    startAssessmentRequestState,
    selectedAssessmentTag,
    assessmentOverrideReasons,
    verifyAssessmentInProgress,
    verifyAssessmentFailed,
    handleOpenUnverifiedAssessment
  } = assessment;

  const {
    createAlertDismissalRequestState,
    selectedAlertType,
    handleRefreshAlertDismissals,
    handleCreateAlertDismissal,
  } = alerts;

  const { createHtstRequestState, handleCreateHtst } = htst;
  const { createCaseSessionRequestState, handleCreateCaseSession } =
    caseSession;
  //#endregion

  return (
    <Fragment>
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <CaseLocationFormDialog
          onSubmit={handleChangeLocation}
          requestState={changeLocationRequestState}
          caseDto={caseResult}
        />
        {caseResult && <CaseFormDialog
          onSubmit={handleUpdateCase}
          requestState={updateCaseRequestState}
          caseDto={caseResult}
        />}
        <AlertDismissalFormDialog
          requestState={createAlertDismissalRequestState}
          type={selectedAlertType}
          onSubmit={handleCreateAlertDismissal}
          caseDto={caseResult}
        />
        <HumanTraffickingScreeningToolFormDialog
          requestState={createHtstRequestState}
          onSubmit={handleCreateHtst}
        />
        <AssessmentFormDialog
          tag={selectedAssessmentTag}
          onSubmit={handleStartAssessment}
          requestState={startAssessmentRequestState}
          caseDto={caseResult}
          assessments={assessments}
          verifyFailure={verifyAssessmentFailed}
          verifyInProgress={verifyAssessmentInProgress}
          openUnverifiedAssessment={handleOpenUnverifiedAssessment}
        />
        <DischargeFormDialog
          onSubmit={handleDischargeCase}
          requestState={dischargeCaseRequestState}
          youth={caseResult?.youth}
          caseHold={activeCaseHold}
          caseDto={caseResult}
          assessments={assessments}
          assessmentOverrideReasons={assessmentOverrideReasons}
          onRefreshAlertDismissals={handleRefreshAlertDismissals}
        />

        <SetCaseScreeningFormDialog
          youthId={caseResult?.youth?.id || ""}
          requestState={setCaseScreeningRequestState}
          onSubmit={handleSetCaseScreening}
          programType={caseResult?.program?.programType?.jjisProgramType}
          caseIntakeDate={caseResult?.intakeInfo.intakeAt}
        />
        <CaseHoldFormDialog
          onSubmit={handleCreateCaseHold}
          requestState={createCaseHoldRequestState}
        />

        <EndCaseHoldFormDialog
          onSubmit={handleEndCaseHold}
          requestState={endCaseHoldRequestState}
          caseHold={activeCaseHold}
        />

        <CaseContactFormDialog
          onCreate={handleCreateCaseContact}
          onUpdate={handleUpdateCaseContact}
          requestState={createCaseContactRequestState}
          caseDto={caseResult!}
          contact={selectedCaseContactId == null ? undefined : caseResult!.caseContacts?.find(cc => cc.id === selectedCaseContactId)}
        />

        <CaseContactNotesDialog
          contact={selectedCaseContactId == null ? undefined : caseResult!.caseContacts?.find(cc => cc.id === selectedCaseContactId)}
        />

        <ConfirmDeleteCaseContactDialog requestState={deleteCaseContactRequestState} onConfirm={handleDeleteCaseContact} />
        <ConfirmClearDischargeDialog caseDto={caseResult} requestState={clearDischargeRequestState} onConfirm={handleClearDischarge}  />
        <CaseSessionsFormDialog
          requestState={createCaseSessionRequestState}
          onSubmit={handleCreateCaseSession}
        />

        { caseResult && <TransferYouthRecordDialog currentYouth={caseResult.youth} recordId={caseResult.id} recordType={"Case"} refresh={refreshCase} /> }

        <ExecuteAccessLevelWrapper
          name={PermissionResourceNameEnum.ChangeCaseDeliverables}
        >
          <ConfirmDeleteCaseDialog
            onConfirm={handleDeleteCase}
            requestState={deleteCaseRequestState}
            caseDto={caseResult}
          />
          <ChangeDischargeDateFormDialog
            onSubmit={handleChangeDischargeDate}
            requestState={changeDischargeDateRequestState}
            caseDto={caseResult}
          />
          <ChangeIntakeDateFormDialog
            onSubmit={handleChangeIntakeDate}
            requestState={changeIntakeDateRequestState}
            intake={caseResult?.intakeInfo}
            discharge={caseResult?.dischargeInfo}
          />
          <CaseProgramLocationFormDialog
            onSubmit={handleChangeProgramLocation}
            caseDto={caseResult}
            requestState={changeProgramLocationRequestState}
          />

          <ConfirmDeleteCourtOrderAttachmentDialog
            onConfirm={handleDeleteCourtOrderAttachment}
            requestState={deleteCourtOrderAttachmentRequestState}
            caseDto={caseResult}
          />
        </ExecuteAccessLevelWrapper>
      </WriteAccessLevelWrapper>

      <CaseProgramLocationHistoryDialog id={useId()} />
      <SetYouthDjjIdNumberFormDialog
        requestState={setYouthDjjIdNumberRequestState}
        onSubmit={handleSetYouthDjjIdNumber}
      />
    </Fragment>
  );
};

export default CaseDialogs;
