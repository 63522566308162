import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum TopicEnum {
  AlcoholDrugAbuse = "AlcoholDrugAbuse",
  Adolescence = "Adolescence",
  ParentingFamilyFunctioning = "ParentingFamilyFunctioning",
  YouthEducationIssues = "YouthEducationIssues",
  InformationCinsFinsServicePrograms = "InformationCinsFinsServicePrograms",
  Other = "Other",
}

export const topicEnumTextMap: { [key: string]: string } = {
  [TopicEnum.AlcoholDrugAbuse]: "Alcohol and Other Drug Use/Abuse",
  [TopicEnum.Adolescence]: "Adolescence/Adolescent Behavior",
  [TopicEnum.ParentingFamilyFunctioning]:
    "Parenting Classes/Family Functioning",
  [TopicEnum.YouthEducationIssues]: "Youth Educational Issues",
  [TopicEnum.InformationCinsFinsServicePrograms]:
    "Information CINS/FINS and Other Service Programs",
  [TopicEnum.Other]: "Other",
};

export const getTopicEnumOptions = (
  defaultLabel: string = "Select Topic"
): SelectOption[] => [
  { value: "", label: defaultLabel },
  ...Object.keys(TopicEnum).map((x) => ({
    value: x,
    label: topicEnumTextMap[x],
  })),
];
