import { useEffect, useState } from "react";
import { InvoiceDto } from "../dtos/InvoiceDto";
import { useGetInvoiceDocument } from "../hooks/invoicingHooks";
export const useInvoiceDocumentDialog = (invoice?: InvoiceDto) => {
  //#region State

  const [getInvoiceDocument, , getInvoiceDocumentRequestState] =
    useGetInvoiceDocument();
  const [document, setDocument] = useState<Blob>();
  //#endregion

  //#region Effects
  useEffect(() => {
    const load = async () => {
      setDocument(undefined);
      if (invoice) {
        const document = await getInvoiceDocument(invoice.id);
        setDocument(document);
      }
    };

    load();
  }, [setDocument, getInvoiceDocument, invoice]);
  //#endregion
  return {
    state: {
      document,
      getInvoiceDocumentRequestState,
    },
  };
};
