import { dateToFormString } from "../../../app/helpers";
import { CaseContactDto } from "./CaseContactDto";

export interface CaseContactFormDto {
  contactOn: string;
  type: string;
  status: string;
  contactedPerson: string;
  staffMemberId: string;
  notes: string;
}

export const createCaseContactFormDto = (
  dto?: CaseContactDto
): CaseContactFormDto => {
  if (dto) {
    const { contactOn, staffMember, ...rest } = dto;
    return {
      contactOn: dateToFormString(new Date(contactOn)),
      staffMemberId: staffMember.id,
      ...rest,
    };
  }

  return {
    contactOn: '',
    type: "",
    status: "",
    contactedPerson: "",
    staffMemberId: "",
    notes: ""
  };
};
