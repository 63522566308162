import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { ScreeningSearchParamsDto } from "./ScreeningSearchParamsDto";

export class ScreeningFilterFormDto {
  screeningNumber?: string;
  providerId: string;

  youthFirstName?: string;
  youthLastName?: string;

  screeningAtStart?: string;
  screeningAtEnd?: string;

  assignedToWaitList: boolean;
  waitListStatus?: string;
  waitListProgramId?: string;
  waitListLocationId?: string;

  includeDeleted: boolean;

  constructor(dto?: ScreeningSearchParamsDto) {
    const currentProviderId = getProviderId();

    this.screeningNumber = dto?.screeningNumber || "";
    this.providerId = dto?.providerId || "";
    if (
      currentProviderId != null &&
      currentProviderId !== "" &&
      currentProviderId !== fnyfsProviderId
    )
      this.providerId = currentProviderId;

    this.youthLastName = dto?.youthLastName || "";
    this.youthFirstName = dto?.youthFirstName || "";

    this.screeningAtStart = dto?.screeningAtStart || "";
    this.screeningAtEnd = dto?.screeningAtEnd || "";

    this.assignedToWaitList = dto?.assignedToWaitList || false;    
    this.waitListStatus = dto?.waitListStatus || "";
    this.waitListProgramId = dto?.waitListProgramId || "";
    this.waitListLocationId = dto?.waitListLocationId || "";    

    this.includeDeleted = dto?.includeDeleted || false;
  }
}
