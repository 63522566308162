import { Box, Button, Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import { providerToOptionDto } from "../../provider/dtos/ProviderDto";
import { useIcmActivityReportFilter } from "../hooks/useIcmActivityReportFilter";
import { IcmActivitySearchParamsDto } from "../dtos/IcmActivitySearchParamsDto";
import ErrorText from "../../../app/components/ErrorText";

interface Props {
  params: IcmActivitySearchParamsDto;
  isLoading: boolean;
  onSearch(dto: IcmActivitySearchParamsDto): Promise<void>;
}

const IcmActivityReportFilter: React.FC<Props> = ({
  params,
  isLoading,
  onSearch,
}) => {
  //#region State
  const {
    state: { providers, getAllProvidersRequestState, isValid, isFnUser },
    form: { control },
    handlers: { handleDownload, handleView },
  } = useIcmActivityReportFilter(onSearch, params);
  //#endregion

  return (
    <Fragment>
      <ErrorText error={getAllProvidersRequestState.error?.message} />
      <Grid container spacing={2}>
        {/* Date Range */}
        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="startOn"
            label="Start Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="endOn"
            label="End Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>

        {/* Provider Name */}
        <FnAccessWrapper>
          <Grid item md={3} xs={6}>
            <ControlledSelect
              control={control}
              name="providerIds"
              label="Providers"
              fullWidth
              multiple
              options={providers.map(providerToOptionDto)}
              isLoading={getAllProvidersRequestState.isLoading}
              disabled={isLoading}
              renderValue={(selected: any) => {
                if (selected.length === 0) {
                  return "All Providers with ICM Programs";
                }

                return selected
                  .map((id: string) => providers.find((p) => p.id === id)?.name)
                  ?.join(", ");
              }}
              size="small"
            />
          </Grid>
        </FnAccessWrapper>

        <Grid item md={isFnUser ? 3 : 6} xs={6}>
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            justifyContent="end"
          >
            <Button
              disabled={!isValid || isLoading}
              onClick={handleView}
              variant="contained"
              color="primary"
            >
              View
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              disabled={!isValid || isLoading}
              onClick={handleDownload}
              color="primary"
            >
              Download
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default IcmActivityReportFilter;
