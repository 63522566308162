import {SelectOption} from "../../../app/components/inputs/ControlledSelect";
import React from "react";

export interface ContractDto {
  id: string;
  name: string;
  referenceNumber: string;
  validProgramTypeIds: string[];
  displayOrder: number;
}

export const contractToOption = (dto: ContractDto): SelectOption => ({
  value: dto.id,
  label: dto.name,
});

export const contractsToOptions = (
  dtos: ContractDto[],
  programTypeId: string | undefined = undefined,
  defaultLabel: React.ReactNode = "Select Contract"
): SelectOption[] => {
  if (programTypeId)
    dtos = dtos.filter(d => d.validProgramTypeIds && d.validProgramTypeIds.some(vpt => programTypeId && vpt === programTypeId))

  return [
    { value: "", label: defaultLabel },
    ...dtos.map(contractToOption)];
};