import { invoicingAgent } from "../../app/api/agent";
import {ProviderFiscalYearCreateOrUpdateDto, ProviderFiscalYearDto} from "./dtos/ProviderFiscalYearDto";
import { ProviderFiscalYearLicensedBedFormDto } from "./dtos/ProviderFiscalYearLicensedBedFormDto";

interface CreateProviderFiscalYearRequestParamsDto {
  providerId: string;
  dto: ProviderFiscalYearCreateOrUpdateDto;
}

interface UpdateProviderFiscalYearRequestParamsDto {
  id: string;
  dto: ProviderFiscalYearCreateOrUpdateDto;
}

interface UpdateProviderFiscalYearLicensedBedsRequestParamsDto {
  id: string;
  dto: ProviderFiscalYearLicensedBedFormDto;
}

export const createProviderFiscalYearRequest = ({
  providerId,
  dto,
}: CreateProviderFiscalYearRequestParamsDto) =>
  invoicingAgent.postRequest<ProviderFiscalYearDto>(
    `api/providers/${providerId}/fiscalyears`,
    dto
  );

export const updateProviderFiscalYearRequest = ({
  id,
  dto,
}: UpdateProviderFiscalYearRequestParamsDto) =>
  invoicingAgent.putRequest<ProviderFiscalYearDto>(
    `api/providerFiscalYears/${id}`,
    dto
  );

export const updateProviderFiscalYearLicensedBedsRequest = ({
  id,
  dto,
}: UpdateProviderFiscalYearLicensedBedsRequestParamsDto) =>
  invoicingAgent.putRequest<ProviderFiscalYearDto>(
    `api/providerFiscalYears/${id}/LicensedBeds`,
    dto
  );

export const getProviderFiscalYearsRequest = (providerId: string) =>
  invoicingAgent.getRequest<ProviderFiscalYearDto[]>(
    `api/providers/${providerId}/fiscalyears`
  );

export const getFiscalYearsRequest = () =>
  invoicingAgent.getRequest<number[]>("api/fiscalyears");
