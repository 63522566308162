import React, { Fragment } from "react";
import YellowAlertLabel from "../../../app/components/labels/YellowAlertLabel";
import { WaitlistDto } from "../dtos/WaitlistDto";
import { WaitlistScreeningDto } from "../dtos/WaitlistScreeningDto";
import { WaitlistStatusEnum } from "../enums/WaitlistStatusEnum";
import { WaitlistCaseCreatedLabel } from "./WaitlistCaseCreatedLabel";
import { WaitlistRemovedLabel } from "./WaitlistRemovedLabel";
import { Box } from "@material-ui/core";

interface Props {
  waitlist: WaitlistDto | WaitlistScreeningDto;
  hideTooltip?: boolean;
  prevLocationLabel?: string;
}

const WaitlistStatusLabel: React.FC<Props> = ({ waitlist, hideTooltip }) => {
  switch (waitlist.status) {

    case WaitlistStatusEnum.CaseCreated:
      return <WaitlistCaseCreatedLabel 
        waitlist={waitlist} 
        hideTooltip={hideTooltip} 
        prevLocationLabel="Back to Screenings" />;

    case WaitlistStatusEnum.Removed:
      return <WaitlistRemovedLabel 
          waitlist={waitlist} 
          hideTooltip={hideTooltip} 
        />;

    case WaitlistStatusEnum.Pending:
      return (
        <Box>
          <YellowAlertLabel
            label="Pending"
            component="span"
            style={{ color: "black", cursor: "help" }}
          />
        </Box>
      );
    default:
      return <Fragment></Fragment>;
  }
};

export default WaitlistStatusLabel;
