import { Box, Divider, Paper, Typography } from "@material-ui/core";
import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import PdfContainer from "../../../app/components/PdfContainer";
import MonthlyDeliverableReportFilter from "../components/MonthlyDeliverableReportFilter";
import { useMonthlyDeliverablesReportPage } from "../hooks/useMonthlyDeliverablesReportPage";

const MonthlyDeliverablesReportPage = () => {
  const {
    providers,
    invoiceYears,
    getInvoiceYearsRequestState,
    getDocumentRequestState,
    getProvidersRequestState,
    document,
    handleSearch,
    isFloridaNetwork,
  } = useMonthlyDeliverablesReportPage();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <BackButton route="/reports" />
      </div>
      <Header title="Monthly Deliverables Report" />
      <Box className="my-2">
        <Typography>
          Select month/year
          {!isFloridaNetwork && ", a comparison Provider (optional)"}, and
          "View" for a PDF summary of invoiced deliverables.
        </Typography>
      </Box>
      <Paper
        style={{
          padding: "16px",
          height: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <MonthlyDeliverableReportFilter
          providers={providers}
          getProvidersRequestState={getProvidersRequestState}
          requestState={getDocumentRequestState}
          invoiceYears={invoiceYears}
          getInvoiceYearsRequestState={getInvoiceYearsRequestState}
          onSubmit={handleSearch}
        />

        <Box marginBottom="8px" marginTop="8px">
          <Divider />
        </Box>
        <PdfContainer
          document={document}
          isLoading={getDocumentRequestState.isLoading}
        />
      </Paper>
    </div>
  );
};

export default MonthlyDeliverablesReportPage;
