import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { monthOptions } from "../../../app/enums/MonthEnum";
import { getIsFnyfsUser } from "../../authentication/state/authenticationActions";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../provider/dtos/ProviderDto";
import { MonthlyCorrectionsReportSearchParamsDto } from "../dtos/MonthlyCorrectionsReportParametersDto";

interface Props {
  providers: ProviderDto[];
  invoiceYears: number[];
  getInvoiceYearsRequestState: RequestStateDto;
  getProvidersRequestState: RequestStateDto;
  requestState: RequestStateDto;
  onSubmit(params: MonthlyCorrectionsReportSearchParamsDto): Promise<void>;
}

const MonthlyCorrectionsReportFilter: React.FC<Props> = ({
  providers,
  invoiceYears,
  getInvoiceYearsRequestState,
  getProvidersRequestState,
  requestState,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm({
    defaultValues: new MonthlyCorrectionsReportSearchParamsDto(),
    resolver: yupResolver(MonthlyCorrectionsReportSearchParamsDto.getSchema()),
    mode: "all",
  });

  const isFnyfsUser = getIsFnyfsUser();

  const [availableMonthOptions, setAvailableMonthOptions] =
    useState<SelectOption[]>(monthOptions);

  const { year } = watch();

  useEffect(() => {
    const now = DateTime.now();
    if (now.year !== +year) {
      setAvailableMonthOptions(monthOptions);
    } else {
      setAvailableMonthOptions(
        monthOptions.filter((x) => x.value === "" || +x.value < now.month)
      );
    }
  }, [year, setAvailableMonthOptions]);

  return (
    <form
      onSubmit={handleSubmit((params) => {
        params.month = `${+params.month + 1}`;

        onSubmit(params);
      })}
    >
      <Grid container spacing={1}>
        <Grid item md={3} sm={isFnyfsUser ? 4 : 6}>
          <ControlledSelect
            control={control}
            name="month"
            fullWidth
            label="Month"
            options={availableMonthOptions}
            disabled={requestState.isLoading}
            size="small"
          />
        </Grid>
        <Grid item md={3} sm={isFnyfsUser ? 4 : 6}>
          <ControlledSelect
            control={control}
            name="year"
            fullWidth
            label="Year"
            isLoading={getInvoiceYearsRequestState.isLoading}
            disabled={requestState.isLoading}
            options={invoiceYears.map((x) => ({ value: x, label: `${x}` }))}
            size="small"
          />
        </Grid>
        <FnAccessWrapper>
          <Grid item md={4} sm={6}>
            <ControlledSelect
              control={control}
              name="providerId"
              fullWidth
              label="Provider"
              isLoading={getProvidersRequestState.isLoading}
              options={providerToOptionDtos(providers)}
              disabled={requestState.isLoading}
              size="small"
            />
          </Grid>
        </FnAccessWrapper>
        <Grid item md={isFnyfsUser ? 2 : 6} sm={6}>
          <Box display="flex" justifyContent="end">
            <div>
              <SpinnerButton
                showSpinner={false}
                disabled={!isValid || requestState.isLoading}
                content="View"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default MonthlyCorrectionsReportFilter;
