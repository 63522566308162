import { Box, Divider, Grid, TextField, Typography } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import LocationFormFields, { locationSchema } from "./LocationFormFields";
import * as Yup from "yup";
import { LocationDto, locationsToOptions } from "../dtos/LocationDto";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { useState } from "react";
import { ProgramLocationFormDto } from "../dtos/ProgramLocationFormDto";
import ControlledRadioGroup from "../../../app/components/inputs/ControlledRadioGroup";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import { JjisCodeDto } from "../../jjis/dtos/JjisCodeDto";
import { Autocomplete } from "@material-ui/lab";
import { JjisProgramTypeEnum } from "../../jjis/enums/JjisProgramTypeEnum";

const validateCircuitNumberBewteen = (value?: string) => {
  if (value) {
    const numberValue = +value;
    if (isNaN(numberValue)) return true;

    return numberValue >= 1 && numberValue <= 20;
  }
  return true;
};

export const programLocationSchema = Yup.object().shape({
  // eslint-disable-next-line
  startedOn: Yup.date().label("Started Date").required().typeError("${label} must be a valid date/time"),
  location: Yup.object().when("useExisting", {
    is: "false",
    then: locationSchema,
    otherwise: Yup.object().notRequired(),
  }),
  locationId: Yup.string().when("useExisting", {
    is: "true",
    then: Yup.string().label("Location").required(),
    otherwise: Yup.string().notRequired(),
  }),
  juvenileJusticeProgramCode: Yup.string().when("fundingSource", {
    is: ProgramFundingSourceEnum.FloridaNetwork,
    then: Yup.string().label("Juvenile Justice Program").required().max(100),
    otherwise: Yup.string().label("Juvenile Justice Program").max(100),
  }),
  judicialCircuitNumber: Yup.string().when("fundingSource", {
    is: ProgramFundingSourceEnum.FloridaNetwork,
    then: Yup.string()
      .label("Judicial Circuit Number")
      .required()
      .test(
        "judicial-circuit-number-range",
        "Judicial Circuit Number must be between 1 and 20.",
        function (value) {
          return validateCircuitNumberBewteen(value);
        }
      ),
    otherwise: Yup.string().test(
      "judicial-circuit-number-range",
      "Judicial Circuit Number must be between 1 and 20.",
      function (value) {
        return validateCircuitNumberBewteen(value);
      }
    ),
  }),
});

interface Props {
  control: Control<ProgramLocationFormDto>;
  disabled: boolean;
  useExisting: boolean;
  locations: LocationDto[];
  fundingSource: ProgramFundingSourceEnum;
  jjisCodes: JjisCodeDto[];
  jjisCodesAreLoading: boolean;
}
const ProgramLocationFormFields: React.FC<Props> = ({
  control,
  disabled,
  useExisting,
  locations,
  fundingSource,
  jjisCodes,
  jjisCodesAreLoading,
}) => {
  //#region State
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const { isEditing, juvenileJusticeProgramCode, terminatedOn } = useWatch({
    control,
  });
  //#endregion

  //#region Helpers
  const formatFieldName = (name: string) =>
    fundingSource === ProgramFundingSourceEnum.FloridaNetwork
      ? `${name}*`
      : name;
  //#endregion

  //#region Effects
  useEffect(() => {
    const options: SelectOption[] = locationsToOptions(locations);
    options.unshift({ value: "", label: "Select Location" });
    setLocationOptions(options);
  }, [locations, setLocationOptions]);
  //#endregion
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h5">Location</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
      <Grid item md={12} xs={12}>
        {isEditing === false && (
          <RadioGroup>
            <ControlledRadioGroup
              row
              name="useExisting"
              value={false}
              control={control}
              options={[
                { value: "false", label: "New" },
                { value: "true", label: "Existing" },
              ]}
            />
          </RadioGroup>
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        {useExisting === false && (
          <Grid container spacing={1}>
            <LocationFormFields
              control={control}
              disabled={disabled || isEditing!}
              prefix="location"
            />
          </Grid>
        )}
        {useExisting && (
          <Fragment>
            <ControlledSelect
              control={control}
              name="locationId"
              disabled={disabled || isEditing!}
              options={locationOptions}
              fullWidth
              label="Location"
            />
          </Fragment>
        )}
      </Grid>

      <Grid item md={12} xs={12}>
        <Box marginTop="16px">
          <Typography variant="h5">Effective Dates</Typography>
        </Box>
      </Grid>
      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledInput
          control={control}
          name="startedOn"
          label="Start Date*"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {terminatedOn && (
        <Grid item md={6} xs={12}>
          <ControlledInput
            control={control}
            name="terminatedOn"
            label="Terminated Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}

      {fundingSource === ProgramFundingSourceEnum.FloridaNetwork && (
        <Fragment>
          <Grid item md={12} xs={12}>
            <Box marginTop="16px">
              <Typography variant="h5">Judicial</Typography>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledWholeNumberInput
              control={control}
              name="judicialCircuitNumber"
              label={formatFieldName("Judicial Circuit Number")}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              control={control}
              name="juvenileJusticeProgramCode"
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={[
                      ...jjisCodes,
                      {
                        name: "",
                        code: "",
                        jjisProgramType:
                          JjisProgramTypeEnum.CommunityCounseling,
                      },
                    ]}
                    openOnFocus
                    disabled={jjisCodesAreLoading}
                    onChange={(e, data) => {
                      field.onChange({
                        target: {
                          name: "juvenileJusticeProgramCode",
                          value: data?.code || "",
                        },
                      });
                    }}
                    getOptionLabel={(option) =>
                      option.code === ""
                        ? ""
                        : `${option.code} - ${option.name}`
                    }
                    defaultValue={jjisCodes.find(
                      (x) => x.code === juvenileJusticeProgramCode
                    )}
                    getOptionSelected={(option) => option.code === value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          variant="outlined"
                          {...params}
                          label={formatFieldName("Juvenile Justice Program")}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProgramLocationFormFields;
