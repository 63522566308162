import React, { Fragment } from "react";
import { Box, Button } from "@material-ui/core";
import {
  addDangerNotification,
  addSuccessNotification,
  addWarningNotification,
} from "../state/notificationSlice";
import { useAppDispatch } from "../../../app/hooks";
import { useForm } from "react-hook-form";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const durationOptions = [
  { value: 1000, label: "1 Second" },
  { value: 3000, label: "3 Second" },
  { value: 10000, label: "10 Second" },
];

const schema = yup.object().shape({
  message: yup.string().label("Message").required().min(4).max(100),
});

const NotificationTestPage = () => {
  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm({
    defaultValues: { duration: 3000, message: "" },
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
  });
  const dispatch = useAppDispatch();

  //#region Handlers
  const handleSuccessClick = () => {
    const payload = getValues();
    const action = addSuccessNotification(payload);
    dispatch(action);
  };

  const handleDangerClick = () => {
    const payload = getValues();
    const action = addDangerNotification(payload);
    dispatch(action);
  };

  const handleWarningClick = () => {
    const payload = getValues();
    const action = addWarningNotification(payload);
    dispatch(action);
  };
  //#endregion

  return (
    <Fragment>
      <Box display="flex" marginBottom="12px">
        <Box marginRight="16px">
          <ControlledInput control={control} name="message" label="Message" />
        </Box>

        <ControlledSelect
          control={control}
          style={{ width: "150px" }}
          name="duration"
          label="Duration"
          labelId="duration-label"
          options={durationOptions}
        />
      </Box>
      <Box display="flex">
        <Button
          onClick={handleSuccessClick}
          variant="outlined"
          disabled={isValid === false}
        >
          Success
        </Button>
        <Box marginLeft="8px" marginRight="8px">
          <Button
            onClick={handleDangerClick}
            variant="outlined"
            disabled={isValid === false}
          >
            Danger
          </Button>
        </Box>
        <Button
          onClick={handleWarningClick}
          variant="outlined"
          disabled={isValid === false}
        >
          Warning
        </Button>
      </Box>
    </Fragment>
  );
};

export default NotificationTestPage;
