import { Typography } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTogglable } from "../../../app/hooks/useTogglable";
import InvoiceCorrectionConfirmationCheckbox from "../../../app/components/InvoiceCorrectionConfirmationCheckbox";
import {ClearDischargeFormDto, createClearDischargeFormDto} from "../dtos/ClearDischargeFormDto";
import {CaseDetailDto} from "../../cases/dtos/CaseDetailDto";
import {useAppDispatch} from "../../../app/hooks";
import {closeModal} from "../../modal/state/modalSlice";

const schema = Yup.object().shape({
  reason: Yup.string().label("Reason").required().max(200),
});
interface Props {
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
  onConfirm(dto: ClearDischargeFormDto): Promise<void>;
}

export const confirmClearDischargeDialogId = "clear-discharge-dialog";
const ConfirmClearDischargeDialog: React.FC<Props> = ({
                                                    onConfirm,
                                                    requestState,
                                                    caseDto,
                                                  }) => {
  const {
    control,
    getValues,
    formState: { isValid },
    reset
  } = useForm({
    defaultValues: createClearDischargeFormDto(),
    mode: "all",
    resolver: yupResolver(schema),
  });

  const [isConfirmed, setIsConfirmed, handleToggleIsConfirmed] = useTogglable();
  const { error, isLoading } = requestState;

  const dispatch = useAppDispatch()

  const resetThenCloseForm = () => {
    dispatch(closeModal());
    reset(createClearDischargeFormDto())
    setIsConfirmed(false)
  }

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={() => {
        onConfirm(getValues());
        reset(createClearDischargeFormDto());
        setIsConfirmed(false)
      }}
      onClose={resetThenCloseForm}
      modalId={confirmClearDischargeDialogId}
      isLoading={isLoading}
      confirmButtonText="Undo Discharge"
      confirmButtonStyle={{
        color:
          !isConfirmed || !isValid ? ColorsEnum.DisabledRed : ColorsEnum.Red,
      }}
      confirmButtonDisabled={!isConfirmed || !isValid}
      maxWidth="md"
    >
      <ErrorText error={error?.message} />
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to undo the Discharge for Case {caseDto?.caseNumber}? <br /> Enter
        the reason below.
      </Typography>
      <ControlledInput
        control={control}
        name="reason"
        label="Reason"
        type="text-area"
        minRows={6}
        multiline
        fullWidth
      />
      <Typography>
        <strong>This action may result in invoice corrections.</strong>
      </Typography>
      <InvoiceCorrectionConfirmationCheckbox
        isConfirmed={isConfirmed}
        onCheck={handleToggleIsConfirmed}
      />
    </ConfirmDialog>
  );
};

export default ConfirmClearDischargeDialog;
