import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import ErrorText from "../../../app/components/ErrorText";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { useCreateNewFollowUpForm } from "../hooks/useCreateNewFollowUpForm";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { useWatch } from "react-hook-form";
import { FollowUpCompletionDataFormFields } from "./FollowUpCompletionDataFormFields";
import {StaffMemberControlledAutoComplete} from "../../staff-members/components/StaffMemberControlledAutoComplete";

interface Props {
  caseDto: CaseDetailDto;
  onSubmit: () => Promise<void>;
}
export const createNewFollowUpDialogId = "create-new-follow-up-dialog";
const CreateNewFollowUpFormDialog: React.FC<Props> = ({
  caseDto,
  onSubmit,
  // requestState
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    state: {
      intervalSelectOptions,
      unableToContactReasonOptions,
      schoolStatusOptions,
      livingSituationOptions,
      followUpOutcomeOptions,
      staffMembers,
      getProviderStaffMembersRequestState,
      getCreateFollowUpRequestState,
      followUpImprovementOptions
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useCreateNewFollowUpForm(onSubmit, caseDto);

  const { outcome } = useWatch({ control });

  const { isLoading, error } = getCreateFollowUpRequestState;

  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === createNewFollowUpDialogId}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Create New Follow Up
          <ErrorText error={error?.message} />
          <ErrorText
            error={getProviderStaffMembersRequestState.error?.message}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name={"interval"}
                options={intervalSelectOptions}
                fullWidth
                label={"Follow Up Interval"}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <StaffMemberControlledAutoComplete
                control={control}
                name="staffMemberId"
                staffMembers={staffMembers || []}
                isLoading={getProviderStaffMembersRequestState.isLoading}
                label="Staff Performing Follow Up"
                disabled={false}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="attemptedAt"
                label="Attempt Date/Time"
                type="datetime-local"
                control={control}
                disabled={false}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name={"outcome"}
                options={followUpOutcomeOptions}
                fullWidth
                label={"Follow Up Outcome"}
              />
            </Grid>
            {outcome === "failed" && (
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name={"reason"}
                  options={unableToContactReasonOptions}
                  fullWidth
                  label={"Reason for Failed Attempt"}
                />
              </Grid>
            )}
            {outcome === "success" && (
              <FollowUpCompletionDataFormFields
                control={control}
                livingSituationOptions={livingSituationOptions}
                schoolStatusOptions={schoolStatusOptions}
                improvementStatusOptions={followUpImprovementOptions}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={false}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateNewFollowUpFormDialog;
