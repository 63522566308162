import React, {ReactNode} from 'react';
import {ClickAwayListener, Grow, Paper, Popper} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {ColorsEnum} from "../enums/ColorsEnum";
import {useAnchor} from "../hooks/useAnchor";

interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
}

export const InfoPopover: React.FC<Props> = ({ children, style }) => {

  const [anchor, handleOpen, handleClose] = useAnchor();

  return (<>
    <InfoIcon onClick={(e: any) => handleOpen(e)}
              style={{ cursor: "pointer", color: ColorsEnum.InfoBlue, ...style }}
    />
    <Popper open={!!anchor} anchorEl={anchor} role={undefined} transition disablePortal placement={"left"}
            modifiers={
              {
                flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
        >
          <Paper style={{ width: 300, padding: 16 }}>
            <ClickAwayListener onClickAway={handleClose}>
              { children }
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>)
}
