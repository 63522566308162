import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { luxonDateToFormString, toDate } from "../../../app/helpers";
import { useMultiDateSelect } from "../../../app/hooks/useMultiDateSelect";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { CaseDetailDto } from "../dtos/CaseDetailDto";
import { NonBillableDaysFormDto } from "../dtos/NonBillableDaysFormDto";

const convertDates = (dates?: Date[]): DateTime[] =>
  dates?.map((x) => toDate(new Date(x))) || [];

export const useNonBillableDayForm = (
  onSubmit: (dto: NonBillableDaysFormDto) => Promise<any>,
  caseDto?: CaseDetailDto
) => {
  //#region State
  const [selectedDates, handleToggleDate, setSelectedDates] =
    useMultiDateSelect();
  const [isConfirmed, setIsConfirmed, handleToggleConfirmed] = useTogglable();
  //#endregion

  const clearForm = () => {
    setSelectedDates(convertDates(caseDto?.nonBillableDays));
    setIsConfirmed(false);
  };
  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await onSubmit({ serviceDates: selectedDates.map(luxonDateToFormString) });
  };

  useEffect(() => {
    setSelectedDates(convertDates(caseDto?.nonBillableDays));
  }, [caseDto, setSelectedDates]);
  return {
    state: { selectedDates, isConfirmed },
    form: { clearForm },
    handlers: {
      handleSubmit: handleOnSubmit,
      handleToggleDate,
      handleToggleConfirmed,
    },
  };
};
