import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { YouthDto } from "../dto/YouthDto";
import { YouthFormDto } from "../dto/YouthFormDto";
import { useYouthForm } from "../hooks/useYouthForm";
import YouthDuplicateMessage from "./YouthDuplicateMessage";
import YouthFormFields from "./YouthFormFields";

export const youthModalId = "youth-form-modal";

interface Props {
  youth?: YouthDto;
  onSubmit: (dto: YouthFormDto) => Promise<any>;
  requestState: RequestStateDto;
}

const YouthFormDialog: React.FC<Props> = ({
  youth,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers, state } = useYouthForm(onSubmit, youth);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { youths, getYouthsRequestState, forceSubmit } = state;

  const { error: getYouthsError, isLoading: getYouthsIsLoading } =
    getYouthsRequestState;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === youthModalId} maxWidth="xl" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {youth ? "Edit" : "Create"} Youth
          <ErrorText error={error?.message} />
          <ErrorText error={getYouthsError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <YouthFormFields control={control} disabled={isLoading} isLegacy={youth?.isLegacy} />
            <YouthDuplicateMessage control={control} youths={youths} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={
              !isValid ||
              isLoading ||
              getYouthsIsLoading ||
              (youths.length > 0 && forceSubmit === false)
            }
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default YouthFormDialog;
