import {
  Chip,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import * as Yup from "yup";
import { PresentingProblemDto } from "../../screening-presenting-problems/dtos/PresntingProblemDto";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import { CinsFinsEligibilityCriterionDto } from "../../cins-fins-eligibility-criteria/dtos/CinsFinsEligibilityCriterionDto";
import {
  getScreeningPlannedActionOptions,
  ScreeningPlannedActionEnum,
} from "../enums/ScreeningPlannedActionEnum";
import { turnedAwayOptions } from "../enums/TurnedAwayEnum";
import ErrorText from "../../../app/components/ErrorText";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import ReferrerFormFields, {
  referrerSchema,
} from "../../referrals/components/ReferrerFormFields";
import { getHeardAboutOptions, HeardAboutEnum } from "../enums/HeardAboutEnum";
import { ReferralSourceEnum } from "../../referrals/enums/ReferralSourceEnum";
import ControlledYesNoRadioGroup from "../../../app/components/inputs/ControlledYesNoRadioGroup";
import {
  getLivingSituationOptions,
  LivingSituationEnum,
} from "../enums/LivingSituationEnum";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { LocationDto } from "../../locations/dtos/LocationDto";
import WaitlistFormFields from "../../waitlists/components/WaitlistFormFields";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import ControlledAutocomplete from "../../../app/components/inputs/ControlledAutocomplete";
import {StaffMemberControlledAutoComplete} from "../../staff-members/components/StaffMemberControlledAutoComplete";
const excludedLivingSituations: string[] = [
  LivingSituationEnum.Homeless,
  LivingSituationEnum.Lockout,
  LivingSituationEnum.Runaway,
];

const zipCodeRequiredTest = (livingSituation: string) => {
  return (
    livingSituation != null &&
    livingSituation !== "" &&
    !excludedLivingSituations.includes(livingSituation)
  );
};

export const screeningSchema = Yup.object().shape({
  zipCode: Yup.string()
    .label("Zip Code")
    .when("livingSituation", (livingSituation: string, field: any) =>
      zipCodeRequiredTest(livingSituation) ? field.max(5).min(5) : field
    ),
  // eslint-disable-next-line
  screeningAt: Yup.date().label("Screening Date/Time").required().typeError("${label} must be a valid date/time"),
  referredBy: referrerSchema,
  livingSituation: Yup.string().label("Living Situation").required(),
  screeningPresentingProblemsText: Yup.array()
    .min(1,"Presenting Problems is required")
    .test(
      "test-presenting-problem-length",
      "Presenting Problem description cannot exceed 50 characters.",
      function (value) {
        if (!value) return false;
        return !value.some(p => p.length > 50);
      }
  ),
  problemDescription: Yup.string().label("Problem Description").max(1000),
  comments: Yup.string().label("Comments").max(1000),
  isCinsFinsEligible: Yup.string().label("Is CINS/FINS Eligible").required(),
  plannedAction: Yup.string().label("Planned Action").required(),
  turnedAway: Yup.string().test(
    "turned-away-valid",
    "Reason Turned Away is required.",
    function (value) {
      return (
        this.parent.ineligibleOption !==
          ScreeningPlannedActionEnum.TurnedAway || value !== ""
      );
    }
  ),
  completedByStaffId: Yup.string()
    .label("Staff Completing Screening")
    .required(),
  heardAboutUs: Yup.string().test(
    "test-heard-about-us",
    "Please provide how the referrer heard about us.",
    function (value) {
      return (
        this.parent.heardAboutUsOption !== HeardAboutEnum.Other ||
        Boolean(value)
      );
    }
  ),
  heardAboutUsOption: Yup.string()
    .label("How the referrer heard about us")
    .required(),

  locationId: Yup.string().when("createWaitlist", {
    is: true,
    then: Yup.string().label("Location").required(),
    otherwise: Yup.string(),
  }),
  programId: Yup.string().when("createWaitlist", {
    is: true,
    then: Yup.string().label("Program").required(),
    otherwise: Yup.string(),
  }),
});

interface Props {
  control: Control<any>;
  disabled: boolean;
  presentingProblems: PresentingProblemDto[];
  cinsFinsEligibilityCriteria: CinsFinsEligibilityCriterionDto[];
  requiresEligibleOption: boolean;
  requiresIneligibleOption: boolean;
  staffMembers: StaffMemberDto[];
  staffMembersAreLoading: boolean;
  presentingProblemsAreLoading: boolean;
  providerPrograms: ProgramDto[];
  locations: LocationDto[];
  providerProgramsAreLoading: boolean;
  providerLocationsAreLoading: boolean;
}

const ScreeningFormFields: React.FC<Props> = ({
  control,
  disabled,
  presentingProblems,
  cinsFinsEligibilityCriteria,
  requiresEligibleOption,
  requiresIneligibleOption,
  staffMembers,
  staffMembersAreLoading,
  presentingProblemsAreLoading,
  providerProgramsAreLoading,
  providerLocationsAreLoading,
  providerPrograms,
  locations,
}) => {
  const plannedAction = useWatch({ control, name: "plannedAction" });
  const isCinsFinsEligible = useWatch({ control, name: "isCinsFinsEligible" });
  const heardAboutUsOption = useWatch({ control, name: "heardAboutUsOption" });
  const referralSource = useWatch({ control, name: "referredBy.source" });
  const createWaitlist = useWatch({ control, name: "createWaitlist" });
  const eligibleCriteriaOptions = useWatch({
    control,
    name: "eligibleCriteriaOptions",
  });

  const editMode = useWatch({ control, name: "editMode" });
  const livingSituation = useWatch({ control, name: "livingSituation" });

  const zipCodeSuffix = zipCodeRequiredTest(livingSituation) ? "*" : "";

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="screeningAt"
          label="Screening Date/Time*"
          type="datetime-local"
          control={control}
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <StaffMemberControlledAutoComplete
          control={control}
          name="completedByStaffId"
          staffMembers={staffMembers}
          disabled={disabled}
          isLoading={staffMembersAreLoading}
          label="Staff Completing Screening*"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="livingSituation"
          label="Living Situation*"
          options={getLivingSituationOptions()}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledWholeNumberInput
          name="zipCode"
          label={`Youth Residence Zip Code${zipCodeSuffix}`}
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledAutocomplete
          multiple
          options={presentingProblems.map((x) => x.problem)}
          openOnFocus
          disableCloseOnSelect
          disabled={presentingProblemsAreLoading}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          name="screeningPresentingProblemsText"
          label={"Presenting Problems*"}
          control={control}
          renderInput={() => <Fragment></Fragment>}
        />
        <Typography color="textSecondary">
          <small>
            *Select one or more presenting problem(s) from the list.
          </small>
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="problemDescription"
          label="Problem Description"
          multiline
          minRows={3}
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledCheckbox
          control={control}
          name="isDvRespite"
          label="Is this a request for Domestic Violence Respite?"
          color="primary"
        />
      </Grid>

      {/* Referral Information */}
      <Grid item md={12} xs={12}>
        <Divider/>
        <Typography variant="h6" style={{ paddingTop: "12px" }}>Referral Information</Typography>
      </Grid>
      <ReferrerFormFields
        control={control}
        prefix="referredBy"
        isDisabled={disabled}
        isReferral={false}
        showName={referralSource !== ReferralSourceEnum.Self}
      />

      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="heardAboutUsOption"
          label="How did the referrer hear about us? We want to make sure others know how to find us.*"
          options={getHeardAboutOptions()}
          fullWidth
        />
      </Grid>

      {heardAboutUsOption === HeardAboutEnum.Other && (
        <Grid item md={12} xs={12}>
          <ControlledInput
            control={control}
            name="heardAboutUs"
            label="Please specify how the referrer found us.*"
            fullWidth
          />
        </Grid>
      )}

      {/* CINS/FINS Eligibility */}

      <Grid item md={12} xs={12}>
        <Divider />
        <Typography variant="h6" style={{ paddingTop: "12px" }}>CINS/FINS Eligibility Screening</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledYesNoRadioGroup
          control={control}
          name="isCinsFinsEligible"
          disabled={disabled}
          label="Is CINS/FINS Eligible*"
        />
        {requiresEligibleOption && (
          <ErrorText
            error={
              "Please select one or more reasons for CINS/FINS eligibility."
            }
          />
        )}
        {requiresIneligibleOption && (
          <ErrorText
            error={
              "Please select one or more reasons for CINS/FINS ineligibility."
            }
          />
        )}
      </Grid>

      <Grid item md={6} xs={6}>
        <FormControl
          component="fieldset"
          style={{ borderRight: `1px solid ${ColorsEnum.Border}` }}
        >
          <FormLabel component="legend">
            Eligibility Reasons{isCinsFinsEligible === "true" && "*"}
          </FormLabel>

          <FormGroup>
            {cinsFinsEligibilityCriteria
              .filter((x) => x.isEligible)
              .map((criterion, i) => (
                <ControlledCheckbox
                  key={criterion.id}
                  color="primary"
                  name={`eligibleCriteriaOptions[${criterion.id}]`}
                  control={control}
                  label={criterion.value}
                  value={eligibleCriteriaOptions[criterion.id]}
                />
              ))}
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item md={6} xs={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Ineligibility Reasons{isCinsFinsEligible === "false" && "*"}
          </FormLabel>
          <FormGroup>
            {cinsFinsEligibilityCriteria
              .filter((x) => !x.isEligible)
              .map((criterion, i) => (
                <ControlledCheckbox
                  name={`eligibleCriteriaOptions[${criterion.id}]`}
                  control={control}
                  label={criterion.value}
                  key={criterion.id}
                  value={eligibleCriteriaOptions[criterion.id]}
                  color="primary"
                />
              ))}
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item md={12} xs={12}>
        <ControlledSelect
          label="Planned Action*"
          control={control}
          name="plannedAction"
          options={getScreeningPlannedActionOptions()}
          fullWidth
        />
      </Grid>

      {editMode === false &&
        plannedAction === ScreeningPlannedActionEnum.Waitlist && (
          <Grid item md={12} xs={12}>
            <ControlledCheckbox
              control={control}
              name="createWaitlist"
              color="primary"
              label="Add to Waitlist Now"
              disabled={disabled}
            />
          </Grid>
        )}

      {createWaitlist && (
        <Fragment>
          <WaitlistFormFields
            control={control}
            providerPrograms={providerPrograms}
            locations={locations}
            providerLocationsAreLoading={providerLocationsAreLoading}
            providerProgramsAreLoading={providerProgramsAreLoading}
            isDisabled={disabled}
          />
        </Fragment>
      )}

      {plannedAction === ScreeningPlannedActionEnum.TurnedAway && (
        <Grid item md={12} xs={12}>
          <ControlledSelect
            name="turnedAway"
            label="Reason Turned Away*"
            control={control}
            disabled={disabled}
            options={turnedAwayOptions}
            fullWidth
          />
        </Grid>
      )}

      <Grid item md={12} xs={12}>
        <ControlledInput
          name="comments"
          label="Comments"
          multiline
          minRows={3}
          control={control}
          disabled={disabled}
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export default ScreeningFormFields;
