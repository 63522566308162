import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { AlertReferenceTypeEnum } from "../enums/AlertReferenceTypeEnum";
import { AlertTypeEnum } from "../enums/AlertTypeEnum";
import * as Yup from "yup";
import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { ParsedQuery } from "query-string";
import { ProviderSummaryDto } from "../../provider/dtos/ProviderSummaryDto";
import { UserNameDto } from "../../users/dtos/UserNameDto";
import { ProgramTypeSummaryDto } from "../../program-types/dtos/ProgramTypeSummaryDto";

export interface AlertDismissalDto {
  id: string;
  reason: string;
  type: string;
  referenceId: string;
  referenceType: AlertReferenceTypeEnum;
  createdAt: Date;
  createdBy: string;
}

export class AlertDismissalRequestDto {
  reason: string = "";
  type: string = "";
  referenceId: string = "";
  referenceType: string = "";

  public static getSchema() {
    return Yup.object().shape({
      reason: Yup.string().label("Reason").max(1000).required(),
      type: Yup.string().label("Type").required(),
      referenceId: Yup.string().label("Reference Id").required(),
      referenceType: Yup.string().label("Reference Type").required(),
    });
  }

  public static fromSnapInSchoolsCycle(
    dto: SnapInSchoolsCycleDto,
    type: AlertTypeEnum
  ) {
    const reasonDto = new AlertDismissalRequestDto();
    reasonDto.referenceType = AlertReferenceTypeEnum.SnapInSchoolsCycle;
    reasonDto.referenceId = dto.id;
    reasonDto.type = type;
    return reasonDto;
  }

  public static fromCase(dto: CaseDetailDto, type: AlertTypeEnum) {
    const reasonDto = new AlertDismissalRequestDto();
    reasonDto.referenceType = AlertReferenceTypeEnum.Case;
    reasonDto.referenceId = dto.id;
    reasonDto.type = type;
    return reasonDto;
  }
}

export class AlertDismissalSearchParamsDto
  implements PaginationSearchParamsDto
{
  page?: number = 0;
  pageSize?: number = 10;
  sortBy?: string = "createdAt";
  sortDirection?: SortDirectionEnum = SortDirectionEnum.Descending;
  type?: string;
  providerId?: string;
  startOn?: string;
  endOn?: string;
  programTypeId?: string;

  constructor(query?: ParsedQuery<string>) {
    this.page = +(query?.page as string) || 0;
    this.pageSize = +(query?.pageSize as string) || 10;
    this.sortBy = (query?.sortBy as string) || "createdAt";
    this.sortDirection =
      (query?.sortDirection as SortDirectionEnum) ||
      SortDirectionEnum.Descending;
    this.type = (query?.type as string) || "";
    this.providerId = (query?.providerId as string) || "";
    this.startOn = (query?.startOn as string) || "";
    this.endOn = (query?.endOn as string) || "";
    this.programTypeId = (query?.programType as string) || "";
  }
}

export class AlertDismissalFilterFormDto {
  type: string = "";
  providerId: string = "";
  startOn: string = "";
  endOn: string = "";
  programTypeId: string = "";
  sortBy: string = "";
  sortDirection: SortDirectionEnum;

  constructor(params?: AlertDismissalSearchParamsDto) {
    this.type = params?.type || "";
    this.providerId = params?.providerId || "";
    this.programTypeId = params?.programTypeId || "";
    this.startOn = params?.startOn || "";
    this.endOn = params?.endOn || "";
    this.sortBy = params?.sortBy || "createdAt";
    this.sortDirection = params?.sortDirection || SortDirectionEnum.Descending;
  }
}

export interface AlertDismissalListDto {
  id: string;
  reason: string;
  type: string;
  referenceId: string;
  referenceType: string;
  referenceNumber: string;
  provider: ProviderSummaryDto;
  programType: ProgramTypeSummaryDto;
  createdBy: UserNameDto;
  createdAt: Date;
}
