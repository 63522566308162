import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  CaseHoldFormDto,
  createCaseHoldFormDto,
} from "../dtos/CaseHoldFormDto";
import * as Yup from "yup";

const caseHoldSchema = Yup.object().shape({
  type: Yup.string().label("Case Hold Reason").required(),
  // eslint-disable-next-line
  startAt: Yup.date().label("Start Date/Time").required().typeError("${label} must be a valid date/time"),
});

export const useCaseHoldForm = (
  onSubmit: (dto: CaseHoldFormDto) => Promise<void>
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createCaseHoldFormDto(),
    resolver: yupResolver(caseHoldSchema),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(createCaseHoldFormDto());
  };
  const handleOnSubmit = handleSubmit(async (dto: CaseHoldFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
