import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { caseSessionSchema } from "../components/CaseSessionsFormDialog";
import { CaseSessionFormDto, createCaseSessionFormDto } from "../dtos/CaseSessionFormDto";

export const useCaseSessionForm = (
    onSubmit: (dto: CaseSessionFormDto) => Promise<any>
) => {
    const {
        control,
        handleSubmit,
        formState: { isValid },
        reset
    } = useForm({
        defaultValues: createCaseSessionFormDto(),
        resolver: yupResolver(caseSessionSchema),
        mode: 'all'
    });

    const clearForm = () => {
        reset(createCaseSessionFormDto());
    };

    const handleOnSubmit = handleSubmit(async (dto: CaseSessionFormDto) => {
        await onSubmit(dto);
        clearForm();
    });

    return {
        form: { control, isValid, clearForm },
        handlers: { handleSubmit: handleOnSubmit }
    };
};