import {yupResolver} from "@hookform/resolvers/yup";
import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {CaseDetailDto} from "../../cases/dtos/CaseDetailDto";
import {AssessmentFormDto, createAssessmentFormDto} from "../dtos/AssessmentFormDto";
import {createCaseAssessmentContextDto} from "../dtos/NirvanaAssessmentContextDto";
import {AssessmentTemplateTagEnum} from "../enums/AssessmentTemplateTagEnum";
import {useGetAssessmentTemplates} from "./assessmentHooks";
import * as Yup from "yup";
import {AssessmentReferenceTypeEnum} from "../enums/AssessmentReferenceTypeEnum";
import {AssessmentDto} from "../dtos/AssessmentDto";
import {AssessmentTemplateSummaryDto} from "../dtos/AssessmentTemplateSummaryDto";
import {AssessmentTypeEnum, AssessmentTypeIdEnum, assessmentTypeTextMap} from "../enums/AssessmentTypeEnum";
import {AssessmentStatusEnum} from "../enums/AssessmentStatusEnum";
import {createSnapAssessmentContextDto} from "../dtos/SnapAssessmentContextDto";
import {SnapInSchoolsCycleDto} from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";
import {useRequest} from "../../../app/hooks/useRequest";
import {getNirvanaPrepopulationIdRequest} from "../AssessmentRequests";

const assessmentOrdering: string[] = [
  AssessmentTypeEnum.NirvanaSelfReport,
  AssessmentTypeEnum.NirvanaAssessment,
  AssessmentTypeEnum.NirvanaReAssessment,
  AssessmentTypeEnum.NirvanaPostAssessment,
  AssessmentTypeEnum.MocePreAssessment,
  AssessmentTypeEnum.MocePostAssessment,
  AssessmentTypeEnum.TopsePreAssessment,
  AssessmentTypeEnum.TopsePostAssessment,
];

const assessmentSchema = Yup.object().shape({
  templateId: Yup.string().label("Assessment Type").required(),
  completedOn: Yup.string().label("Completed On").required(),
});

export const useAssessmentForm = (
  tag: AssessmentTemplateTagEnum,
  onSubmit: (dto: AssessmentFormDto) => Promise<any>,
  assessments: AssessmentDto[],
  caseDto?: CaseDetailDto,
  snapCycle?: SnapInSchoolsCycleDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
  } = useForm({
    defaultValues: createAssessmentFormDto(),
    resolver: yupResolver(assessmentSchema),
    mode: "all",
  });

  const [getAssessmentTemplates, templateResults, getTemplatesRequestState] = useGetAssessmentTemplates();

  const [templates, setTemplates] = useState<AssessmentTemplateSummaryDto[]>([]);

  const [getNirvanaPrepopulationId] = useRequest(getNirvanaPrepopulationIdRequest);

  const [assessmentDisclaimer, setAssessmentDisclaimer] = useState("");

  //#endregion

  const getAssessmentType = (templateId: string): AssessmentTypeEnum | undefined => {
    switch (templateId) {
      case AssessmentTypeIdEnum.NirvanaAssessment:
        return AssessmentTypeEnum.NirvanaAssessment;

      case AssessmentTypeIdEnum.NirvanaReAssessment:
        return AssessmentTypeEnum.NirvanaReAssessment;

      case AssessmentTypeIdEnum.NirvanaPostAssessment:
        return AssessmentTypeEnum.NirvanaPostAssessment;

      default:
        return undefined;
    }
  };

  const getNirvanaParentId = useCallback(
    async (dto: AssessmentFormDto) => {
      const assessmentType = getAssessmentType(dto.templateId);

      const parentNirvanaId = await getNirvanaPrepopulationId({
        type: assessmentType!,
        completedOn: dto.completedOn,
        caseId: dto.referenceId,
        youthId: caseDto?.youth?.id || "",
      });

      return parentNirvanaId;
    },
    [caseDto?.youth?.id, getNirvanaPrepopulationId]
  );

  //#region Handlers
  const handleOnSubmit = handleSubmit(async (dto: AssessmentFormDto) => {
    switch (tag) {
      case AssessmentTemplateTagEnum.Nirvana:
        dto.context = createCaseAssessmentContextDto(caseDto!);
        dto.referenceId = caseDto!.id;
        dto.referenceType = AssessmentReferenceTypeEnum.Case;
        dto.parentId = await getNirvanaParentId(dto);

        const template = templates.find((t) => t.id === dto.templateId);
        if (template!.tags.some((tag) => tag === AssessmentTemplateTagEnum.Assessment || tag === AssessmentTemplateTagEnum.SelfReport || tag === AssessmentTemplateTagEnum.PostAssessment)) {
          dto.singleOnly = true;
        }

        break;
      case AssessmentTemplateTagEnum.Topse:
        dto.context = createCaseAssessmentContextDto(caseDto!);
        dto.referenceId = caseDto!.id;
        dto.referenceType = AssessmentReferenceTypeEnum.Case;
        break;
      case AssessmentTemplateTagEnum.Moce:
        dto.context = createSnapAssessmentContextDto(snapCycle!);
        dto.referenceId = snapCycle!.id;
        dto.referenceType = AssessmentReferenceTypeEnum.Snap;
        dto.singleOnly = true;
        break;
    }

    await onSubmit(dto);
    reset(createAssessmentFormDto());
  });

  const clearForm = () => {
    reset(createAssessmentFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    getAssessmentTemplates(tag);
  }, [getAssessmentTemplates, tag]);

  useEffect(() => {
    if (templateResults != null) {
      const hasType = (type: AssessmentTypeEnum) => {
        return assessments.some((x) => x.type === type && x.status !== AssessmentStatusEnum.Voided);
      };

      const hasTypeSubmitted = (type: AssessmentTypeEnum) => {
        return assessments.some((x) => x.type === type && x.status === AssessmentStatusEnum.Submitted);
      };
      const excludedTypes: string[] = [];
      let templates: AssessmentTemplateSummaryDto[] = [];
      if (tag === AssessmentTemplateTagEnum.Nirvana) {
        templates = templateResults.filter((x) => x.tags.includes(AssessmentTemplateTagEnum.Nirvana));

        // Do not allow another initial NIRVANA Assessment if one already exists,
        if (hasType(AssessmentTypeEnum.NirvanaAssessment)) {
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.NirvanaAssessment]);
        }

        // Do not allow creation ot a re-assessment or post-assessment if an initial assessment has not been submitted.
        if(!hasTypeSubmitted(AssessmentTypeEnum.NirvanaAssessment)) {
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.NirvanaReAssessment]);
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.NirvanaPostAssessment]);

          if(hasType(AssessmentTypeEnum.NirvanaAssessment)) {
            setAssessmentDisclaimer("You may not add a Re-Assessment or Post-Assessment until a NIRVANA Assessment has been completed and submitted.")
          }
        }

        if (hasType(AssessmentTypeEnum.NirvanaSelfReport)) {
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.NirvanaSelfReport]);
        }

        if (hasType(AssessmentTypeEnum.NirvanaPostAssessment)) {
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.NirvanaPostAssessment]);
        }
      } else if (tag === AssessmentTemplateTagEnum.Topse) {
        templates = templateResults.filter((x) => x.tags.includes(AssessmentTemplateTagEnum.Topse));

        if (hasType(AssessmentTypeEnum.TopsePreAssessment)) {
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.TopsePreAssessment]);
        }

        if (hasType(AssessmentTypeEnum.TopsePostAssessment)) {
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.TopsePostAssessment]);
        }
      } else if (tag === AssessmentTemplateTagEnum.Moce) {
        templates = templateResults.filter((x) => x.tags.includes(AssessmentTemplateTagEnum.Moce));

        if (hasType(AssessmentTypeEnum.MocePreAssessment)) {
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.MocePreAssessment]);
        }

        if (hasType(AssessmentTypeEnum.MocePostAssessment)) {
          excludedTypes.push(assessmentTypeTextMap[AssessmentTypeEnum.MocePostAssessment]);
        }
      }
      templates = templates
        .filter((x) => !excludedTypes.includes(x.shortName))
        .sort((a, b) => (assessmentOrdering.indexOf(a.shortName) > assessmentOrdering.indexOf(b.shortName) ? 1 : -1));
      setTemplates(templates);
    }
  }, [templateResults, tag, setTemplates, assessments]);
  //#endregion

  return {
    state: {
      templates,
      getTemplatesRequestState,
      assessmentDisclaimer,
    },
    form: { control, isValid, clearForm, setValue },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
