import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import SpinnerButton from "../../app/components/buttons/SpinnerButton";
import {useServicePlanPageContext} from "../ServicePlanPageContext";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectModalId} from "../../features/modal/state/modalSelectors";
import {closeModal} from "../../features/modal/state/modalSlice";

interface Props {
  goalId: string,
  interventionId: string;
}

export const servicePlanInterventionNotesDialogId = 'service-plan-intervention-notes';

export const ServicePlanInterventionNotesDialog: React.FC<Props> = ({ goalId, interventionId }) => {

  const selectedModalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { servicePlan: { goals } } = useServicePlanPageContext();

  const goal = goals.find(g => g.id === goalId);

  const intervention = goal?.interventions.find(i => i.id === interventionId);

  const interventionDescription = intervention?.description || "";
  const interventionNotes = intervention?.notes || "";

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (<Dialog open={servicePlanInterventionNotesDialogId === selectedModalId} maxWidth="sm" fullWidth>

      <DialogTitle>
        Intervention Notes
      </DialogTitle>
      <DialogContent>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Intervention: {interventionDescription}</Typography>
          </Grid>
          <Grid item xs={12}>
           <Typography>
             Notes: { interventionNotes }
           </Typography>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Close"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
          disabled={false}
        />
      </DialogActions>

  </Dialog>)

}

