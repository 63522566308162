import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { JjisProgramTypeEnum } from "../../jjis/enums/JjisProgramTypeEnum";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import ScreeningSelect from "../../screenings/components/ScreeningSelect";
import { SetCaseScreeningFormDto } from "../dtos/SetCaseScreeningFormDto";
import { useSetCaseScreeningForm } from "../hooks/useSetCaseScreeningForm";

export const setCaseScreeningModalId = "set-case-screening-form-modal";

interface Props {
  onSubmit: (dto: SetCaseScreeningFormDto) => Promise<void>;
  requestState: RequestStateDto;
  youthId: string;
  programType?: JjisProgramTypeEnum;
  caseIntakeDate?: Date;
}

const SetCaseScreeningFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  youthId,
  programType,
  caseIntakeDate,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    state: { screeningId, screenings, getYouthScreeningsRequestState },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useSetCaseScreeningForm(onSubmit, youthId, caseIntakeDate);
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === setCaseScreeningModalId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Set Case Screening
          <ErrorText error={error?.message} />
          <ErrorText error={getYouthScreeningsRequestState.error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={12} xs={12}>
              { screenings.length > 0 && (
                <ScreeningSelect
                  name="screeningId"
                  control={control}
                  disabled={isLoading}
                  screenings={screenings}
                  isLoading={getYouthScreeningsRequestState.isLoading}
                  screeningId={screeningId!}
                  programIsDvRespite={
                    programType === JjisProgramTypeEnum.DVRespite
                  }
                />
              )}
              {screenings.length === 0 && (
                <Typography>
                  There are no valid screenings for this youth performed within 60 days prior to intake.
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetCaseScreeningFormDialog;
