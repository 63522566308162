import { Box, Divider, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
interface Props {
  count: number;
}

const Item = () => {
  return (
    <Paper variant="outlined">
      <Box padding="16px">
        <Box display="flex">
          <Skeleton variant="circle" animation="pulse" width={48} height={48} />

          <Box marginLeft="12px">
            <Typography variant="h5">
              <Skeleton variant="text" animation="pulse" width={250} />
            </Typography>
            <Typography color="textSecondary">
              <Skeleton variant="text" animation="pulse" width={180} />
            </Typography>
            <Typography color="textSecondary">
              <Skeleton variant="text" animation="pulse" width={180} />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box padding="16px" display="flex">
        <Typography>
          <Skeleton variant="text" animation="pulse" width={180} />
        </Typography>
        <KeyboardArrowDownIcon />
      </Box>
    </Paper>
  );
};
const LoadingProgramList: React.FC<Props> = ({ count }) => {
  return (
    <Fragment>
      {Array.from({ length: count }, (_, i) => i + 1).map((i) => (
        <Box marginBottom="12px" key={`program-item-${i}`}>
          <Item />
        </Box>
      ))}
    </Fragment>
  );
};

export default LoadingProgramList;
