import { Box, Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import {providerToOptionDtos} from "../../provider/dtos/ProviderDto";
import ErrorText from "../../../app/components/ErrorText";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import {SatisfactionSurveyExportParametersDto} from "../dtos/SatisfactionSurveyExportParametersDto";
import {useSatisfactionSurveyExportReportFilter} from "../hooks/useSatisfactionSurveyExportReportFilter";
import {getFundingSourceOptions} from "../../programs/enums/ProgramFundingSourceEnum";
import {programTypesToOptions} from "../../program-types/dtos/ProgramTypeDto";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";

interface Props {
  params: SatisfactionSurveyExportParametersDto;
  isLoading: boolean;
  onSearch(dto: SatisfactionSurveyExportParametersDto): Promise<void>;
}

const SatisfactionSurveysExportReportFilter: React.FC<Props> = ({
                                                      params,
                                                      isLoading,
                                                      onSearch,
                                                    }) => {
  //#region State
  const {
    state: { providers, getAllProvidersRequestState, isValid, programs, programsRequestState, programTypes, programTypesRequestState },
    form: { control },
    handlers: { handleDownload },
  } = useSatisfactionSurveyExportReportFilter(onSearch, params);
  //#endregion

  return (
    <Fragment>
      <ErrorText error={getAllProvidersRequestState.error?.message} />
      <Grid container spacing={2}>
        {/* Date Range */}

        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="dischargeStartDate"
            label="Discharge Start Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <ControlledInput
            control={control}
            name="dischargeEndDate"
            label="Discharge End Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isLoading}
            size="small"
          />
        </Grid>

        {/* Provider Name */}
        <FnAccessWrapper>
          <Grid item md={3} xs={6}>

            <ControlledSelect
              control={control}
              name="providerId"
              label="Providers"
              fullWidth
              options={providerToOptionDtos(providers, undefined, 'All')}
              isLoading={getAllProvidersRequestState.isLoading}
              disabled={isLoading}
              size="small"
            />
          </Grid>
        </FnAccessWrapper>

        <Grid item md={3} xs={6}>
          <ControlledSelect
            control={control}
            name="fundingSource"
            label="Funding Source"
            options={getFundingSourceOptions("All")}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item md={3} xs={6}>
          <ControlledSelect
            control={control}
            name="programTypeId"
            label="Program Type"
            options={programTypesToOptions(programTypes, "All")}
            fullWidth
            isLoading={programTypesRequestState.isLoading}
            size="small"
          />
        </Grid>

        <ProviderAccessWrapper>
          {/* Programs */}
          <Grid item md={3} xs={6}>
            <ProgramControlledSelect
              control={control}
              name="programId"
              label="Program"
              programs={programs}
              disabled={programs.length === 0}
              defaultOptionLabel={"All"}
              isLoading={programsRequestState.isLoading}
              size="small"
            />
          </Grid>
        </ProviderAccessWrapper>

        <Grid item md={9} xs={6}>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            height="100%"
          >

            <SpinnerButton
              showSpinner={isLoading}
              disabled={!isValid || isLoading }
              content="Download"
              color="primary"
              variant="contained"
              type="button"
              onClick={handleDownload}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SatisfactionSurveysExportReportFilter;
