import React, { Fragment } from "react";
import { useServicePlanPageContext } from "../ServicePlanPageContext";
import { Box, Divider, List, ListItem, ListItemText } from "@material-ui/core";
import EditButton from "../../app/components/buttons/EditButton";
import DateLabel from "../../app/components/labels/DateLabel";
import { PermissionResourceNameEnum } from "../../features/authentication/enums/PermissionResourceNameEnum";
import WriteAccessLevelWrapper from "../../app/components/access-wrappers/WriteAccessLevelWrapper";

export const ServicePlanReviewList: React.FC = () => {
  const {
    servicePlan: { reviews },
    reviews: { onViewReviewDetailsClicked, onUpdateReviewDetailsClicked },
  } = useServicePlanPageContext();

  return (
    <List style={{ width: "100%" }}>
      {reviews?.map((r) => (
        <Fragment key={r.id}>
          <ListItem>
            <ListItemText>
              <Box display={"flex"} flexDirection={"row"}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <DateLabel date={r.reviewedOn} />
                </div>
                <div style={{ flex: 0, display: "flex", flexDirection: "row" }}>
                  <EditButton
                    size="small"
                    text={"View"}
                    onClick={() => onViewReviewDetailsClicked(r.id)}
                  />
                  <WriteAccessLevelWrapper
                    name={PermissionResourceNameEnum.ServicePlan}
                  >
                    <EditButton
                      size="small"
                      text={"Edit"}
                      onClick={() => onUpdateReviewDetailsClicked(r.id)}
                    />
                  </WriteAccessLevelWrapper>
                </div>
              </Box>
            </ListItemText>
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </List>
  );
};
