import {
  Badge,
  Box,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { ColorsEnum } from "../enums/ColorsEnum";
import ErrorIcon from "@material-ui/icons/Error";
import BuildIcon from "@material-ui/icons/Build";
import InfoIcon from "@material-ui/icons/Info";
import { useTogglable } from "../hooks/useTogglable";
import ClearIcon from "@material-ui/icons/Clear";

export enum NotificationType {
  OutOfCompliance = "OutOfCompliance",
  ActionRequired = "ActionRequired",
  Informational = "Informational",
}
export interface NotificationActionDto {
  id: string;
  message: React.ReactNode;
  type: NotificationType;
  action(): void;
  onDismiss?(): void;
}

export const ActionRequiredIcon = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    bgcolor={ColorsEnum.Orange}
    borderRadius="50%"
    width="18px"
    height="18px"
    padding="2px"
  >
    <BuildIcon style={{ color: ColorsEnum.OffWhite, fontSize: "0.9rem" }} />
  </Box>
);

interface Props {
  notifications: NotificationActionDto[];
  title: React.ReactNode;
}

const getIcon = (type: NotificationType) => {
  let icon: React.ReactNode = undefined;
  switch (type) {
    case NotificationType.ActionRequired:
      icon = <ActionRequiredIcon />;
      break;
    case NotificationType.Informational:
      icon = <InfoIcon />;
      break;
    case NotificationType.OutOfCompliance:
      icon = <ErrorIcon style={{ color: ColorsEnum.Red }} />;
      break;
  }

  if (icon === undefined) return <Fragment></Fragment>;

  return <ListItemIcon>{icon}</ListItemIcon>;
};

const NotificationBell: React.FC<Props> = ({ notifications, title }) => {
  const [open, setOpen, handleClick] = useTogglable();
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const renderMessage = (notification: NotificationActionDto) => {
    if (notification.onDismiss == null) return notification.message;

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className="mr-1">{notification.message}</Typography>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            notification.onDismiss!();
            setOpen(false);
          }}
        >
          <ClearIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <>
      <IconButton onClick={handleClick} ref={anchorRef}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popper
        anchorEl={anchorRef.current}
        keepMounted
        open={open}
        placement="bottom-end"
      >
        <Paper className="p-4" style={{ maxWidth: "500px" }}>
          <Typography className="mb-4" variant="h5">
            {title}
          </Typography>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList className="p-0">
              {notifications.length === 0 && (
                <MenuItem>
                  <ListItemText primary="No Notifications" />
                </MenuItem>
              )}
              {notifications.map((notification) => (
                <MenuItem
                  key={notification.id}
                  onClick={() => {
                    setOpen(false);
                    notification.action();
                  }}
                  className="p-1"
                  style={{ whiteSpace: "normal" }}
                >
                  {getIcon(notification.type)}
                  <ListItemText>{renderMessage(notification)}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default NotificationBell;
