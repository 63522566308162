import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalState {
  modalId?: string;
}

interface OpenModalPayload {
  modalId: string;
}

const initialState: ModalState = {};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      state.modalId = action.payload.modalId;
    },
    closeModal: (state) => {
      state.modalId = undefined;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
