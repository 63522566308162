import React from "react";
import { LinkProps, useLocation } from "react-router-dom";
import StyledLink from "./StyledLink";

interface Props extends Partial<LinkProps> {
  pathname: string;
  text: string;
  search?: string | undefined;
  disabled?: boolean;
}

const RouteableStyledLink: React.FC<Props> = ({
  pathname,
  search,
  text,
  children,
  ...props
}) => {
  const location = useLocation();
  return (
    <StyledLink
      {...props}
      to={{
        pathname,
        search,
        state: { prevLocation: `${location.pathname}${location.search}`, text },
      }}
    >
      {children}
    </StyledLink>
  );
};

export default RouteableStyledLink;
