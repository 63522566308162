import React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import TabContainer, { TabProps } from "../../app/components/TabContainer";
import { useTabIndex } from "../../app/hooks/useTabIndex";
import {
  ServicePlanPageContext,
  ServicePlanContextProps,
} from "../ServicePlanPageContext";
import { ServicePlanGeneralInfo } from "./ServicePlanGeneralInfo";
import { ServicePlanDetails } from "./ServicePlanDetails";
import { ServicePlanScoreDetails } from "./ServicePlanScoreDetails";
import { useServicePlan } from "../hooks/useServicePlan";
import { CreateServicePlanGoalFormDialog } from "./CreateServicePlanGoalFormDialog";
import { AddServicePlanInterventionFormDialog } from "./AddServicePlanInterventionFormDialog";
import { ServicePlanInterventionNotesDialog } from "./ServicePlanInterventionNotesDialog";
import { UpdateServicePlanGoalFormDialog } from "./UpdateServicePlanGoalFormDialog";
import { UpdateServicePlanInterventionFormDialog } from "./UpdateServicePlanInterventionFormDialog";
import { AddServicePlanGoalFormDialog } from "./AddServicePlanReviewFormDialog";
import { ViewServicePlanReviewDetailsModal } from "./ViewServicePlanReviewDetailsModal";
import { UpdateServicePlanReviewFormDialog } from "./UpdateServicePlanReviewFormDialog";
import WhiteButton from "../../app/components/buttons/WhiteButton";
import PrintIcon from "@material-ui/icons/Print";
import { useAnchor } from "../../app/hooks/useAnchor";
import ExpandIcon from "../../app/components/icons/ExpandIcon";
import { useRequest } from "../../app/hooks/useRequest";
import { getServicePlanPDFRequest } from "../ServicePlanRequests";
import { CaseDetailDto } from "../../features/cases/dtos/CaseDetailDto";
import { RequestStateDto } from "../../app/dtos/RequestStateDto";
import { PrintHelpers } from "../../app/helpers";
import { ColorsEnum } from "../../app/enums/ColorsEnum";
import {ServicePlanDto} from "../dto/ServicePlanDto";

const PrintActions = ({ id }: { id: string }) => {
  const [printAnchorEl, handleOpenPrintOptions, handleClosePrintOptions] =
    useAnchor();
  const [getServicePlanPdf, , getServicePlanPdfRequestState] = useRequest(
    getServicePlanPDFRequest
  );

  const handlePrintServicePlan = async (includeNotes: boolean) => {
    PrintHelpers.printDocument(() => getServicePlanPdf({ id, includeNotes }));
  };
  const handlePrintWithNotes = async () => await handlePrintServicePlan(true);
  const handlePrintWithoutNotes = async () =>
    await handlePrintServicePlan(false);

  return (
    <>
      <WhiteButton
        startIcon={<PrintIcon />}
        endIcon={
          getServicePlanPdfRequestState.isLoading ? (
            <CircularProgress
              style={{ color: "#444", height: "22px", width: "22px" }}
            />
          ) : (
            <ExpandIcon expanded={Boolean(printAnchorEl)} />
          )
        }
        onClick={handleOpenPrintOptions}
        disabled={getServicePlanPdfRequestState.isLoading}
      >
        Print
      </WhiteButton>
      <Menu
        anchorEl={printAnchorEl}
        keepMounted
        open={Boolean(printAnchorEl)}
        onClose={handleClosePrintOptions}
        MenuListProps={{ style: { padding: 0 } }}
      >
        <Box position="relative">
          <Backdrop
            open={getServicePlanPdfRequestState.isLoading}
            style={{ position: "absolute", zIndex: "4000" }}
          >
            <CircularProgress style={{ color: ColorsEnum.OffWhite }} />
          </Backdrop>
          <MenuItem
            style={{ paddingTop: "8px" }}
            onClick={handlePrintWithNotes}
            disabled={getServicePlanPdfRequestState.isLoading}
          >
            Print with notes
          </MenuItem>
          <MenuItem
            onClick={handlePrintWithoutNotes}
            disabled={getServicePlanPdfRequestState.isLoading}
            style={{ paddingBottom: "8px" }}
          >
            Print without notes
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
};

export interface Props {
  caseDto: CaseDetailDto;
  servicePlan: ServicePlanDto;
  requestState: RequestStateDto;
  onPlanUpdated(): Promise<void>;
}

const generalInfoTabName = "general-info";
const planDetailsTabName = "plan-details";
const scoreDetailsTabName = "score-details";

export const createGeneralSectionTab = (): TabProps => ({
  label: "General Information",
  value: generalInfoTabName,
  content: <ServicePlanGeneralInfo />,
});

export const createPlanDetailsTab = (): TabProps => ({
  label: "Plan Details",
  value: planDetailsTabName,
  content: <ServicePlanDetails />,
});

export const createScoreDetailsTab = (): TabProps => ({
  label: "Score Details",
  value: scoreDetailsTabName,
  content: <ServicePlanScoreDetails />,
});

export const ServicePlanPage: React.FC<Props> = ({
  caseDto,
  servicePlan,
  requestState,
  onPlanUpdated,
}) => {
  const { youth } = caseDto;

  const {
    state: { selectedGoalId, selectedInterventionId, selectedReviewId },
    handlers: {
      onAddParticipantClicked,
      onAddGoalClicked,
      onUpdateGoalClicked,
      onAddGoalInterventionClicked,
      onViewGoalInterventionNotesClicked,
      onUpdateGoalInterventionClicked,
      onAddServicePlanReviewClicked,
      onViewServicePlanReviewDetailsClicked,
      onUpdateServicePlanReviewClicked,
    },
  } = useServicePlan();

  const [tabIndex, handleChangeTabIndex] = useTabIndex(generalInfoTabName);

  const servicePlanProps: ServicePlanContextProps = {
    servicePlan,
    servicePlanUpdated: onPlanUpdated,
    youth,
    participant: {
      onAddParticipantClicked,
    },
    goals: {
      onAddGoalClicked,
      onUpdateGoalClicked,
      onAddGoalInterventionClicked,
      onViewGoalInterventionNotesClicked,
      onUpdateGoalInterventionClicked,
    },
    reviews: {
      onAddNewReviewClicked: onAddServicePlanReviewClicked,
      onViewReviewDetailsClicked: onViewServicePlanReviewDetailsClicked,
      onUpdateReviewDetailsClicked: onUpdateServicePlanReviewClicked,
    },
  };

  const tabs: TabProps[] = [
    createGeneralSectionTab(),
    createPlanDetailsTab(),
    createScoreDetailsTab(),
  ];

  return (
    <ServicePlanPageContext.Provider value={servicePlanProps}>
      <CreateServicePlanGoalFormDialog />
      <AddServicePlanGoalFormDialog />
      {selectedGoalId && (
        <UpdateServicePlanGoalFormDialog goalId={selectedGoalId} />
      )}
      {selectedGoalId && (
        <AddServicePlanInterventionFormDialog goalId={selectedGoalId} />
      )}
      {selectedGoalId && selectedInterventionId && (
        <ServicePlanInterventionNotesDialog
          goalId={selectedGoalId}
          interventionId={selectedInterventionId}
        />
      )}
      {selectedGoalId && selectedInterventionId && (
        <UpdateServicePlanInterventionFormDialog
          goalId={selectedGoalId}
          interventionId={selectedInterventionId}
        />
      )}
      {selectedReviewId && (
        <ViewServicePlanReviewDetailsModal reviewId={selectedReviewId} />
      )}
      {selectedReviewId && (
        <UpdateServicePlanReviewFormDialog reviewId={selectedReviewId} />
      )}
      <Box height="60vh">
        <Box display="flex" justifyContent="end" marginBottom="8px">
          <PrintActions id={caseDto.id} />
        </Box>
        <TabContainer
          index={tabIndex}
          onIndexChange={handleChangeTabIndex}
          tabs={tabs}
          orientation="vertical"
          disabled={requestState.isLoading}
        />
      </Box>
    </ServicePlanPageContext.Provider>
  );
};
