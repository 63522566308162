import {
  Box,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { Fragment } from "react";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import EditButton from "../../../app/components/buttons/EditButton";
import DateLabel from "../../../app/components/labels/DateLabel";
import { TabProps } from "../../../app/components/TabContainer";
import TableLoadingPlaceholder from "../../../app/components/TableLoadingPlaceholder";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import ConfirmDeleteSnapInSchoolsSatisfactionSurveyDialog from "../../snap-in-schools-satisfaction-surveys/components/ConfirmDeleteSnapInSchoolsSatisfactionSurveyDialog";
import SnapInSchoolsSatisfactionSurveyFormDialog from "../../snap-in-schools-satisfaction-surveys/components/SnapInSchoolsSatisfactionSurveyFormDialog";
import { SnapInSchoolsSatisfactionSurveyDto } from "../../snap-in-schools-satisfaction-surveys/SnapInSchoolsSatisfactionSurveyDto";
import {
  SnapInSchoolsSatisfactionSurveyHelpsPeopleEnumTextMap,
  SnapInSchoolsSatisfactionSurveyLikertEnumTextMap,
  SnapInSchoolsSatisfactionSurveySkillEnumTextMap,
} from "../../snap-in-schools-satisfaction-surveys/SnapInSchoolsSatisfcationSurveyEnums";
import { useSnapInSchoolsDetailPageContext } from "../pages/SnapInSchoolCyclesDetailPage";

export const snapInSchoolsCycleSurveysTabName = "snap-in-schools-cycle-surveys";

const SnapInSchoolsCycleSurveysSection = () => {
  const context = useSnapInSchoolsDetailPageContext();
  const { snapCycle, getSnapCycleRequestState } = context.snapCycle;
  const {
    selectedSurvey,
    upsertSatisfactionSurveyRequestState,
    deleteSatisfactionSurveyRequestState,
    handleCreateSatisfactionSurveyClicked,
    handleUpdateSatisfactionSurveyClicked,
    handleDeleteSatisfactionSurveyClicked,
    handleUpsertSatisfactionSurvey,
    handleDeleteSatisfactionSurvey,
  } = context.surveys;

  const sortedSurveys = (surveys: SnapInSchoolsSatisfactionSurveyDto[]) => {
    return surveys.sort((a, b) =>
      new Date(a.completedOn) > new Date(b.completedOn) ? 1 : -1
    );
  };

  //#region UI Helpers
  const createRow = (survey: SnapInSchoolsSatisfactionSurveyDto) => {
    return (
      <TableRow key={survey.id}>
        <TableCell>
          <DateLabel date={survey.completedOn} />
        </TableCell>
        <TableCell>{survey.youthNameOrInitials}</TableCell>
        <TableCell>
          {
            SnapInSchoolsSatisfactionSurveyLikertEnumTextMap[
              survey.satisfactionSurveyResponseSet.snapSatisfiedWithProgram
            ]
          }
        </TableCell>
        <TableCell>
          <List dense style={{ padding: "0px" }}>
            {survey.satisfactionSurveyResponseSet.snapHelpfulSkills.map((x) => (
              <ListItem divider disableGutters key={x}>
                {SnapInSchoolsSatisfactionSurveySkillEnumTextMap[x]}
              </ListItem>
            ))}
          </List>
        </TableCell>
        <TableCell>
          <List dense style={{ padding: "0px" }}>
            {survey.satisfactionSurveyResponseSet.snapHelpsPeople.map((x) => (
              <ListItem divider disableGutters key={x}>
                {SnapInSchoolsSatisfactionSurveyHelpsPeopleEnumTextMap[x]}
              </ListItem>
            ))}
          </List>
        </TableCell>
        <WriteAccessLevelWrapper
          name={PermissionResourceNameEnum.SnapInSchools}
        >
          <TableCell>
            <EditButton
              onClick={() => handleUpdateSatisfactionSurveyClicked(survey)}
            />
          </TableCell>
        </WriteAccessLevelWrapper>
        <DeleteAccessLevelWrapper
          name={PermissionResourceNameEnum.SnapInSchools}
        >
          <TableCell>
            <DeleteButton
              onClick={() => handleDeleteSatisfactionSurveyClicked(survey)}
            />
          </TableCell>
        </DeleteAccessLevelWrapper>
      </TableRow>
    );
  };

  //#endregion

  if (snapCycle === undefined && !getSnapCycleRequestState.isLoading)
    return <Fragment></Fragment>;

  return (
    <Fragment>
      <SnapInSchoolsSatisfactionSurveyFormDialog
        requestState={upsertSatisfactionSurveyRequestState}
        survey={selectedSurvey}
        onSubmit={handleUpsertSatisfactionSurvey}
        snapCycle={snapCycle}
      />
      <ConfirmDeleteSnapInSchoolsSatisfactionSurveyDialog
        requestState={deleteSatisfactionSurveyRequestState}
        onConfirm={handleDeleteSatisfactionSurvey}
        survey={selectedSurvey}
      />
      <Box marginBottom={"12px"}>
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <AddButton
            text="Create Survey"
            onClick={handleCreateSatisfactionSurveyClicked}
            color="primary"
            variant="contained"
          />
        </WriteAccessLevelWrapper>
      </Box>
      <Box height={"57vh"}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Completed On</TableCell>
              <TableCell>Youth Name/Initials</TableCell>
              <TableCell>SNAP Program Satisfaction</TableCell>
              <TableCell>Most Helpful Skills</TableCell>
              <TableCell>SNAP Helps People To...</TableCell>
              <DeleteAccessLevelWrapper
                name={PermissionResourceNameEnum.SnapInSchools}
              >
                <TableCell></TableCell>
              </DeleteAccessLevelWrapper>
              <WriteAccessLevelWrapper
                name={PermissionResourceNameEnum.SnapInSchools}
              >
                <TableCell></TableCell>
              </WriteAccessLevelWrapper>
            </TableRow>
          </TableHead>
          <TableBody>
            {snapCycle !== undefined && (
              <Fragment>
                {!getSnapCycleRequestState.isLoading &&
                  snapCycle.satisfactionSurveys.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        No Satisfaction Surveys Created
                      </TableCell>
                    </TableRow>
                  )}
                {sortedSurveys(snapCycle.satisfactionSurveys).map(createRow)}
              </Fragment>
            )}
            {getSnapCycleRequestState.isLoading && (
              <TableLoadingPlaceholder rows={10} columns={5} />
            )}
          </TableBody>
        </Table>
      </Box>
    </Fragment>
  );
};

export default SnapInSchoolsCycleSurveysSection;

export const createSnapInSchoolsSatisfactionSurveysSectionTab =
  (): TabProps => {
    return {
      value: snapInSchoolsCycleSurveysTabName,
      label: "Satisfaction Surveys",
      content: <SnapInSchoolsCycleSurveysSection />,
    };
  };
