import { coreAgent } from "../../app/api/agent";
import {
  SnapInSchoolsSatisfactionSurveyDto,
  SnapInSchoolsSatisfactionSurveyFormDto,
} from "./SnapInSchoolsSatisfactionSurveyDto";

export const createSnapInSchoolsSatisfactionSurveyRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: SnapInSchoolsSatisfactionSurveyFormDto;
}) =>
  coreAgent.postRequest<SnapInSchoolsSatisfactionSurveyDto>(
    `snapInSchools/cycles/${id}/satisfactionSurveys`,
    dto
  );

export const updateSnapInSchoolsSatisfactionSurveyRequest = ({
  id,
  dto,
}: {
  id: string;
  dto: SnapInSchoolsSatisfactionSurveyFormDto;
}) =>
  coreAgent.putRequest<SnapInSchoolsSatisfactionSurveyDto>(
    `snapInSchools/cycles/satisfactionSurveys/${id}`,
    dto
  );
export const deleteSnapInSchoolsSatisfactionSurveyRequest = (id: string) =>
  coreAgent.deleteRequest(`snapInSchools/cycles/satisfactionSurveys/${id}`);
