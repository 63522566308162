import React from "react";
import { PieChartValueMetric } from "../dtos/MainDashboardMetricsDto";
import { Box, Typography } from "@material-ui/core";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  Label,
} from "recharts";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import {
  DischargeStatusEnum,
  dischargeStatusTextMap,
} from "../../discharges/enums/DischargeStatusEnum";
import AppLegend from "../../../app/components/recharts/AppLegend";
import { Skeleton } from "@material-ui/lab";

const COLORS: { [index: string]: string } = {
  "Incomplete": ColorsEnum.DarkGrey,
  "On Time": ColorsEnum.Blue,
  Satisfied: ColorsEnum.Blue,
  "Not Satisfied": ColorsEnum.DarkGrey,
  "Somewhat Satisfied": ColorsEnum.InfoBlue,
};

const DISCHARGE_REASON_COLORS: { [index: string]: string } = {
  [dischargeStatusTextMap[DischargeStatusEnum.CompletedWithAfterCare]]:
    ColorsEnum.Red,
  [dischargeStatusTextMap[DischargeStatusEnum.CompletedNoReferral]]:
    ColorsEnum.Blue,
  [dischargeStatusTextMap[DischargeStatusEnum.CompletedWithReferral]]:
    ColorsEnum.DarkBlue,
  [dischargeStatusTextMap[DischargeStatusEnum.CompleteRemovedByAgency]]:
    ColorsEnum.InfoBlue,
  [dischargeStatusTextMap[DischargeStatusEnum.FamilyVoluntarilyWithdrew]]:
    ColorsEnum.Green,
  [dischargeStatusTextMap[DischargeStatusEnum.IncompleteExpelled]]:
    ColorsEnum.Orange,
  [dischargeStatusTextMap[DischargeStatusEnum.IncompleteRanAway]]:
    ColorsEnum.Yellow,
  [dischargeStatusTextMap[
    DischargeStatusEnum.IncompleteAdjudticatedDelinquent
  ]]: ColorsEnum.OffWhite,
  [dischargeStatusTextMap[DischargeStatusEnum.AdministrativeClosure]]:
    ColorsEnum.DarkGrey,
  [dischargeStatusTextMap[DischargeStatusEnum.Other]]: ColorsEnum.RowGrey,
};

export const PieChartMetricPanel: React.FC<{
  metric?: PieChartValueMetric;
  centerTitle?: boolean;
  isLoading?: boolean;
}> = ({ metric, centerTitle, isLoading }) => {
  const renderTitle = () => {
    if (isLoading || !metric)
      return (
        <Typography variant={"body1"}>
          <Skeleton animation="wave" width={150} />
        </Typography>
      );

    return <Typography variant={"body1"}>{metric.title}</Typography>;
  };

  const renderChart = () => {
    if (isLoading || !metric)
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Skeleton
              variant="circle"
              animation="wave"
              width={274.5}
              height={274.5}
            />
            <Box
              position="absolute"
              width="220px"
              height="220px"
              bgcolor={ColorsEnum.BackgroundGrey}
              borderRadius="110px"
            ></Box>

            <Box
              position={"absolute"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Skeleton
                variant="text"
                animation="wave"
                width={75}
                height={48}
              />
              <Skeleton variant="text" animation="wave" width={100} />
            </Box>
          </Box>
        </>
      );

    if (metric.values.length > 0)
      return (
        <ResponsiveContainer width={"90%"} height={"90%"}>
          <PieChart>
            <Pie
              data={metric.values}
              dataKey="value"
              nameKey="title"
              cx="50%"
              cy="50%"
              innerRadius={"65%"}
              outerRadius={"90%"}
              activeShape={
                <Text x={"50%"} y={"50%"}>
                  80%
                </Text>
              }
            >
              {metric.values.map((entry: { title: string }) => (
                <Cell
                  key={entry.title}
                  fill={
                    COLORS[entry.title] || DISCHARGE_REASON_COLORS[entry.title]
                  }
                />
              ))}
              {metric.summaryValue && (
                <Label
                  position={"center"}
                  value={`${metric.summaryValue}`}
                  fontSize={36}
                  transform={"translate(0 -8)"}
                />
              )}
              {metric.summaryValueLabel && (
                <Label
                  position={"center"}
                  value={`${metric.summaryValueLabel}`}
                  fontSize={14}
                  transform={"translate(0 22)"}
                />
              )}
            </Pie>
            <Tooltip />
            <AppLegend />
          </PieChart>
        </ResponsiveContainer>
      );

    return (
      <Box
        display={"flex"}
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography>No data available</Typography>
      </Box>
    );
  };

  return (
    <Box
      padding="16px"
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      alignItems={centerTitle ? "center" : "left"}
      justifyContent={"space-between"}
      height={300}
      width={300}
    >
      {renderTitle()}

      {renderChart()}
    </Box>
  );
};
