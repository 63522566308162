import { DateTime } from "luxon";
import React, { useCallback } from "react";
import FilterList from "../../../app/components/FilterList";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import {
  OutreachActivityFilterFormDto,
  OutreachActivitySearchParamsDto,
} from "../dtos/OutreachActivityDto";
import { modalityEnumTextMap } from "../enums/ModalityEnum";
import { topicEnumTextMap } from "../enums/TopicEnum";
const formatDate = (date: string) => {
  return DateTime.fromJSDate(new Date(`${date}T00:00`)).toFormat("MM-dd-yyyy");
};
interface Props {
  params: OutreachActivitySearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  onSearch(dto: OutreachActivitySearchParamsDto): void;
}

const OutreachActivityFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  onSearch,
}) => {
  const handleClearParams = () => {
    return new OutreachActivitySearchParamsDto({
      previous: params,
      form: new OutreachActivityFilterFormDto(),
    });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "title":
          return `Title: ${value}`;
        case "peopleReached":
          return `Min # People Reached: ${value}`;
        case "topic":
          return `Topic: ${topicEnumTextMap[value]}`;
        case "modality":
          return `Modality: ${modalityEnumTextMap[value]}`;
        case "zipCode":
          return `Zip Code: ${value}`;
        case "dateRangeStart":
          return `Start Date: ${formatDate(value)}`;
        case "dateRangeEnd":
          return `End Date: ${formatDate(value)}`;
        case "providerId":
          return `Provider: ${providers.find((x) => x.id === value)?.name}`;
      }
      return "";
    },
    [providers]
  );

  const ordering = [
    "title",
    "peopleReached",
    "dateRangeStart",
    "dateRangeEnd",
    "topic",
    "modality",
    "providerId",
    "zipCode",
  ];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default OutreachActivityFilterList;
