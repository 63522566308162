import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, { useEffect } from "react";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../../app/components/ErrorText";
import HtmlErrorText from "../../../../app/components/HtmlErrorText";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectModalId } from "../../../modal/state/modalSelectors";
import { closeModal } from "../../../modal/state/modalSlice";
import { CaseDetailDto } from "../../dtos/CaseDetailDto";
import { CaseLocationFormDto } from "../../dtos/CaseLocationFormDto";
import { useCaseLocationForm } from "../../hooks/useCaseLocationForm";
import CaseLocationFormFields from "./CaseLocationFormFields";

export const caseLocationModalId = "case-location-form-modal";

interface Props {
  onSubmit: (dto: CaseLocationFormDto) => Promise<any>;
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
}

const CaseLocationFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  caseDto,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers, state } = useCaseLocationForm(onSubmit, caseDto);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { locations, getProviderLocationsRequestState } = state;

  const {
    error: getProviderLocationsError,
    isLoading: providerLocationsAreLoading,
  } = getProviderLocationsRequestState;
  const { error } = requestState;
  const isLoading = providerLocationsAreLoading || requestState.isLoading;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  useEffect(() => {
    // do not trigger clear form if the modal hasn't changed
    if(modalId === caseLocationModalId)
      return;

    clearForm();
  }, [modalId, clearForm]);

  let criteriaMessage = "";
  if (caseDto)
    criteriaMessage = caseDto?.dischargeInfo
      ? `Only Locations that were active during ${new Date(
          caseDto.intakeInfo.intakeAt
        ).toLocaleDateString()} through ${new Date(
          caseDto.dischargeInfo.dischargedAt
        ).toLocaleDateString()} are displayed.`
      : `Only currently active Locations that were also active on ${new Date(
          caseDto.intakeInfo.intakeAt
        ).toLocaleDateString()} are displayed.`;

  return (
    <Dialog open={modalId === caseLocationModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Change Location
          <HtmlErrorText error={error?.message} />
          <ErrorText error={getProviderLocationsError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <CaseLocationFormFields
              control={control}
              locations={locations}
              providerLocationsAreLoading={
                getProviderLocationsRequestState.isLoading
              }
              isDisabled={isLoading}
              locationMessage={criteriaMessage}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CaseLocationFormDialog;
