import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import {PermissionResourceNameEnum} from "../authentication/enums/PermissionResourceNameEnum";
import {formatUrl} from "../../app/helpers";
import {DashboardContainer} from "./pages/DashboardContainer";

export const createDashboardRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "")}
    component={DashboardContainer}
    key="dashboard-container"
  />,

];
