import { Paper, TableContainer } from "@material-ui/core";
import React from "react";
import SortableTable, { ColumnProps, SortableTableProps } from "../../../app/components/SortableTable";
import { getIsFnyfsUser } from "../../authentication/state/authenticationActions";
import { SnapGroupCycleSearchParamsDto } from "../dtos/SnapGroupCycleSearchParamsDto";
import { SnapGroupCycleSummaryDto } from "../dtos/SnapGroupCycleSummaryDto";
import SnapCycleListItem from "./SnapCycleListItem";

const SnapGroupCycleList: React.FC<SortableTableProps<SnapGroupCycleSummaryDto, SnapGroupCycleSearchParamsDto>> = (props) => {
  const isFnfsUser = getIsFnyfsUser();
  const columns: ColumnProps[] = [
    { name: "cycleNumber", label: "Cycle #" },
    {
      name: "startDate",
      label: "Start Date",
    },
    {
      name: "provider",
      label: "Provider",
      hidden: !isFnfsUser,
    },
    { name: "gender", label: "Gender" },
    { name: "program", label: "Program" },
    { name: "numberOfGroups", label: "# of Groups" },
    { name: "lastGroupDate", label: "Last Group Date" },
  ];

  const renderRow = (summary: SnapGroupCycleSummaryDto) => <SnapCycleListItem key={summary.id} summary={summary} />;

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default SnapGroupCycleList;
