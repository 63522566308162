import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ControlledSwitch from "../../../app/components/inputs/ControlledSwitch";
import { UserSearchParamsDto } from "../dtos/UserSearchParamsDto";
import { useUserFilter } from "../hooks/useUserFilter";
import ControlledYesNoSelect from "../../../app/components/inputs/ControlledYesNoSelect";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  NetmisRoleDto,
  netmisRolesToOptionDtos,
} from "../../roles/dtos/NetmisRoleDto";

interface Props {
  params: UserSearchParamsDto;
  anchorEl: HTMLElement | null;
  roles: NetmisRoleDto[];
  onClose(): void;
  onSearch(dto: UserSearchParamsDto): void;
}

const UserFilter: React.FC<Props> = ({
  params,
  anchorEl,
  roles,
  onClose,
  onSearch,
}) => {
  //#region State
  const {
    form: { control, clearAllFilters },
    handlers: { handleSubmit },
  } = useUserFilter(onSearch, onClose, params);
  //#endregion

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">User Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            {/* Name */}
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="firstName"
                label="First Name"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="lastName"
                label="Last Name"
                fullWidth
              />
            </Grid>

            {/* Email */}
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="email"
                label="Email"
                fullWidth
              />
            </Grid>

            {/* Role */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="roleId"
                label="Role"
                fullWidth
                options={netmisRolesToOptionDtos(roles, "All")}
              />
            </Grid>

            {/* Active */}
            <Grid item md={12} xs={12}>
              <ControlledYesNoSelect
                fullWidth
                control={control}
                name="active"
                label="Active"
                selectLabel="All"
              />
            </Grid>

            {/* Florida Network Only */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSwitch
                  control={control}
                  name="isFloridaNetworkUser"
                  label="Florida Network Users Only"
                  color="primary"
                />
              </Grid>
            </FnAccessWrapper>

            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default UserFilter;
