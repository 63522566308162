import { coreAgent } from "../../app/api/agent";
import { ProgramDto } from "./dtos/ProgramDto";
import { ProgramFormDto } from "./dtos/ProgramFormDto";

interface CreateProviderProgramRequestParams {
  providerId: string;
  dto: ProgramFormDto;
}

interface UpdateProviderProgramRequestParams {
  id: string;
  dto: ProgramFormDto;
}

export const createProviderProgramRequest = ({
  providerId,
  dto,
}: CreateProviderProgramRequestParams) =>
  coreAgent.postRequest<ProgramDto>(`/providers/${providerId}/programs`, dto);

export const updateProgramRequest = ({
  id,
  dto,
}: UpdateProviderProgramRequestParams) =>
  coreAgent.putRequest<ProgramDto>(`/programs/${id}`, dto);
