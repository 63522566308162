import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectModalId } from "../../features/modal/state/modalSelectors";
import { useAddServicePlanGoalForm } from "../hooks/useAddServicePlanGoalForm";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import ErrorText from "../../app/components/ErrorText";
import ControlledInput from "../../app/components/inputs/ControlledInput";
import SpinnerButton from "../../app/components/buttons/SpinnerButton";
import { closeModal } from "../../features/modal/state/modalSlice";
import ControlledSelect from "../../app/components/inputs/ControlledSelect";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";

export const createServicePlanGoalFormDialogId =
  "create-service-plan-goal-dialog";

export const CreateServicePlanGoalFormDialog: React.FC = () => {
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const {
    state: {
      addGoalRequestState: { isLoading, error },
      getServicePlanGoalsRequestState,
      goalStatusOptions,
      domainNames,
      goals,
    },
    form: { isValid, clearForm, control },
    handlers: { handleSubmit, handleRefreshGoals },
  } = useAddServicePlanGoalForm();

  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  useEffect(() => {
    if (modalId === createServicePlanGoalFormDialogId) handleRefreshGoals();
  }, [handleRefreshGoals, modalId]);

  return (
    <Dialog
      open={modalId === createServicePlanGoalFormDialogId}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Add a Service Plan Goal
          <ErrorText error={error?.message} />
          <ErrorText error={getServicePlanGoalsRequestState.error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={goals}
                      freeSolo
                      openOnFocus
                      autoSelect
                      onChange={(_, data) => {
                        field.onChange(data == null ? "" : data);
                      }}
                      loading={getServicePlanGoalsRequestState.isLoading}
                      disabled={
                        isLoading || getServicePlanGoalsRequestState.isLoading
                      }
                      renderInput={(params) => (
                        <Box display="flex">
                          <TextField
                            variant="outlined"
                            {...params}
                            name="name"
                            label="Name*"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                      )}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledSelect
                control={control}
                name={"status"}
                options={goalStatusOptions}
                fullWidth
                label={"Goal Status"}
                disabled={isLoading}
              />
            </Grid>

            {domainNames.length === 0 && (
              <Grid item md={12} xs={12}>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "0.8rem" }}
                >
                  *Cannot associate NIRVANA domains without first submitting a
                  NIRVANA Assessment for this case.
                </Typography>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                name="domains"
                label="Associated Domain(s)"
                control={control}
                disabled={domainNames.length === 0 || isLoading}
                fullWidth
                multiple
                options={[
                  ...domainNames.map((name) => ({ value: name, label: name })),
                ]}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <ControlledInput
                name="notes"
                label="Notes"
                control={control}
                disabled={isLoading}
                fullWidth
                multiline
                minRows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
