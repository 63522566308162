import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { WaitlistFilterFormDto } from "../dtos/WaitlistFilterFormDto";

import { WaitlistSearchParamsDto } from "../dtos/WaitlistSearchParamsDto";

export const useWaitlistFilter = (
  onSubmit: (dto: WaitlistSearchParamsDto) => void,
  onClose: () => void,
  params: WaitlistSearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: new WaitlistFilterFormDto(params),
  });
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: WaitlistFilterFormDto) => {
    const newParams: WaitlistSearchParamsDto = new WaitlistSearchParamsDto({
      form: dto,
      previous: params,
    });
    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new WaitlistFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new WaitlistFilterFormDto(params));
  }, [params, reset]);

  //#endregion

  return {
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
