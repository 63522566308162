import { ProgramDto } from "./ProgramDto";

export interface ProgramFormDto {
  name: string;
  programTypeId?: string;
  fundingSource?: string;
  contractId?: string;
}

export const createProgramFormDto = (dto?: ProgramDto): ProgramFormDto => {
  if (dto) {
    const { name, fundingSource, programType, contractId } = dto;
    return { name, programTypeId: programType.id, fundingSource, contractId };
  }

  return {
    name: "",
    programTypeId: "",
    fundingSource: "",
    contractId: "",
  };
};
