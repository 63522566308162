import { formatUrl } from "../../app/helpers";
import { Route } from "react-router";
import ReferralFormPage from "./pages/ReferralFormPage";
import ReferralListPage from "./pages/ReferralListPage";
import ReferralDetailPage from "./pages/ReferralDetailPage";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";

export const createReferralRoutes = (baseUrl: string) => [
  <Route
    path={formatUrl(baseUrl, "new")}
    component={ReferralFormPage}
    key="new-referral"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Referrals}
    path={formatUrl(baseUrl, "")}
    component={ReferralListPage}
    key="list-referral"
    exact
  />,
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Referrals}
    path={formatUrl(baseUrl, ":id")}
    component={ReferralDetailPage}
    key="detail-referral"
    exact
  />,
];
