import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { useAppDispatch } from "../../../app/hooks";
import { useAnchor } from "../../../app/hooks/useAnchor";
import { useQuery } from "../../../app/hooks/useQuery";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { useGetAllRoles } from "../../roles/hooks/roleHooks";
import { userModalId } from "../components/UserFormDialog";
import { UserFormDto } from "../dtos/UserFormDto";
import { UserSearchParamsDto } from "../dtos/UserSearchParamsDto";
import { useCreateUser, useGetUsers } from "./userHooks";

export const useUserListPage = () => {
  //#region State
  const query = useQuery();
  const [params, setParams] = useState<UserSearchParamsDto>(
    new UserSearchParamsDto({ query })
  );
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [getUsers, usersResult, getUsersRequestState] = useGetUsers();
  const [createUser, , createUserRequestState, clearCreateUserError] =
    useCreateUser();
  const [filterAnchorEl, handleOpenFilterClicked, handleCloseFilter] =
    useAnchor();
    const [getAllRoles, allRoles] = useGetAllRoles();

  //#endregion

  //#region Handlers
  const handleCreateUserClicked = useCallback(() => {
    clearCreateUserError();
    dispatch(openModal({ modalId: userModalId }));
  }, [clearCreateUserError, dispatch]);

  const handlePageChange = (page: number) => {
    const newParams = { ...params, page };
    setParams(newParams);
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    setParams(newParams);
  };
  const handleSearch = useCallback(
    (dto: UserSearchParamsDto) => {
      setParams(dto);
    },
    [setParams]
  );
  const handleCreateUser = useCallback(
    async (dto: UserFormDto) => {
      const result = await createUser(dto);
      if (result) {
        batch(() => {
          dispatch(
            addSuccessNotification({
              message: "Email confirmation has been sent.",
            })
          );
          dispatch(closeModal());
          history.push(`/users/${result.id}`);
        });
      }
    },
    [createUser, dispatch, history]
  );
  //#endregion

  //#region Effects
  useEffect(() => {
    getUsers(params);
  }, [params, getUsers]);

  useEffect(() => {
    history.push(`/users?${UserSearchParamsDto.toQueryString(params)}`);
  }, [params, history]);

  useEffect(() => {
    getAllRoles([]);
}, [getAllRoles]);

  //#endregion

  return {
    state: {
      usersResult,
      params,
      allRoles,
      providerRoles: allRoles?.filter(r => !r.isFloridaNetworkRole),
      getUsersRequestState,
      createUserRequestState,
      filterAnchorEl
    },
    handlers: {
      handleCreateUserClicked,
      handleCreateUser,
      handlePageChange,
      handleSort,
      handleCloseFilter,
      handleOpenFilterClicked,
      handleSearch,
    },
  };
};
