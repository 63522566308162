import { invoicingAgent } from "../../app/api/agent";
import {
  InvoiceReportParamsDto,
  invoiceReportParamsToQueryString,
} from "./dtos/InvoiceReportParamsDto";
import { InvoiceDto } from "./dtos/InvoiceDto";
import {
  InvoiceSearchParamsDto,
  invoiceSearchParamsToQueryString,
} from "./dtos/InvoiceSearchParamsDto";
import {InvoiceStatusDto} from "./dtos/InvoiceStatusDto";


interface CreateInvoiceRequestParams {
  providerId: string;
  contractId: string;
  reportingPeriodId: string;
}

export const getInvoiceReportRequest = (dto: InvoiceReportParamsDto) =>
  invoicingAgent.getRequest<Blob>(
    `reports/invoices?${invoiceReportParamsToQueryString(dto)}`,
    { responseType: "blob" }
  );

export const createInvoiceRequest = (dto: CreateInvoiceRequestParams) =>
  invoicingAgent.postRequest<any>("/api/invoices", dto);

export const getInvoicesRequest = (dto: InvoiceSearchParamsDto) =>
  invoicingAgent.getRequest<InvoiceDto[]>(
    `/api/invoices?${invoiceSearchParamsToQueryString(dto)}`
  );

export const getInvoiceDocumentRequest = (id: string) =>
  invoicingAgent.getRequest<Blob>(`/api/invoices/${id}/document`, {
    responseType: "blob",
  });

export const returnInvoiceRequest = (dto: InvoiceDto) =>
  invoicingAgent.postRequest<InvoiceDto>(`/api/invoices/${dto.id}/return`, {});

export const getPendingInvoicesRequest = (providerId: string) =>
  invoicingAgent.getRequest<InvoiceStatusDto>(
    `/api/invoices/${providerId}/pendinginvoices`
  );

export const getInvoiceYearsRequest = () =>
  invoicingAgent.getRequest<number[]>(
    `/api/invoices/years`
  );