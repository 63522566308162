import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React, { Fragment } from "react";
import {
  ScreeningPlannedActionEnum,
  screeningPlannedActionTextMap,
} from "../enums/ScreeningPlannedActionEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  action?: ScreeningPlannedActionEnum;
}

const ScreeningPlannedActionLabel: React.FC<Props> = ({ action, ...props }) => {
  if (action === undefined) return <Fragment></Fragment>;

  return (
    <Typography {...props}>{screeningPlannedActionTextMap[action]}</Typography>
  );
};

export default ScreeningPlannedActionLabel;
