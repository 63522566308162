import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { programIsType, screeningIsDvRespite } from "../../../app/helpers";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { CaseFormDto, CaseUpdateFormDto } from "../../cases/dtos/CaseFormDto";
import { currentGradeStatus, intakeSchema, lastGradeAttendedStatus } from "../../intakes/components/UpdateCaseIntakeFormFields";
import { JjisProgramTypeEnum } from "../../jjis/enums/JjisProgramTypeEnum";
import { useGetRecentYouthScreenings } from "../../youths/hooks/youthHooks";
import { useGetProviderPrograms, useGetProviderStaffMembers } from "../../provider/hooks/providerHooks";
import { useGetSchoolsNames } from "./caseHooks";

export const useUpdateCaseForm = (onSubmit: (dto: CaseUpdateFormDto) => Promise<void>, caseDto: CaseDetailDto) => {
  const form = useForm({
    defaultValues: CaseUpdateFormDto.createUpdateForm(caseDto),
    resolver: yupResolver(intakeSchema),
    mode: "all",
  });

  const {
    reset,
    watch,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = form;

  const { intakeAt, screeningId, primaryStaffIds } = watch();
  const programId = caseDto.program.id;

  const [isConfirmed, setIsConfirmed, handleToggleIsConfirmed] = useTogglable();
  const [getYouthScreenings, screenings, getYouthScreeningsRequestState] = useGetRecentYouthScreenings(caseDto?.youth.id || "", caseDto.screening?.id);

  const [getProviderPrograms, providerPrograms, getProviderProgramsRequestState] = useGetProviderPrograms();
  const [getProviderStaffMembers, staffMembers, getProviderStaffMemberRequestState] = useGetProviderStaffMembers();
  const [getSchoolNames, schoolNames, getSchoolNamesRequestState] = useGetSchoolsNames();

  useEffect(() => {
    const providerId = caseDto.program.providerId;
    getProviderPrograms(providerId);
    getProviderStaffMembers(providerId);
    getSchoolNames(null);
  }, [caseDto.program.providerId, getProviderPrograms, getProviderStaffMembers, getSchoolNames]);

  const clearForm = () => {
    if (caseDto) reset(CaseFormDto.createUpdateForm(caseDto));

    setIsConfirmed(false);
  };

  useEffect(() => {
    getYouthScreenings(intakeAt);
  }, [getYouthScreenings, intakeAt]);

  useEffect(() => {
    if (!caseDto) return;

    reset(CaseFormDto.createUpdateForm(caseDto));
  }, [reset, caseDto]);

  useEffect(() => {
    // if there is a case passed this is an update, so we do not need to set initial values
    if (caseDto) return;
    if (programIsType(programId, providerPrograms, JjisProgramTypeEnum.Shelter)) {
      setValue("isYouthOnMedication", false);
      setValue("youthMedicationCount", 0);
    } else if (programIsType(programId, providerPrograms, JjisProgramTypeEnum.DVRespite) && screeningId && !screeningIsDvRespite(screeningId!, screenings)) {
      setValue("screeningId", "");
    }
  }, [caseDto, programId, providerPrograms, setValue, screenings, screeningId]);

  const handleOnSubmit = handleSubmit(async (dto: CaseUpdateFormDto) => {
    if (!dto.isYouthOnMedication) dto.youthMedicationCount = undefined;

    if (!currentGradeStatus.includes(dto.schoolStatus)) dto.currentGrade = undefined;

    if (!lastGradeAttendedStatus.includes(dto.schoolStatus)) dto.lastGradeAttended = undefined;
    if (!currentGradeStatus.includes(dto.schoolStatus)) dto.schoolName = "";
    await onSubmit(dto);
    setIsConfirmed(false);
  });

  const handleStaffAdded = (id: string) => {
    setValue('primaryStaffIds', [...primaryStaffIds, id])
  }

  const handleStaffRemoved = (id: string) => {
    setValue('primaryStaffIds', [...primaryStaffIds.filter(staffId => staffId !== id)])
  }

  return {
    state: {
      screenings,
      providerPrograms,
      staffMembers: staffMembers || [],
      getYouthScreeningsRequestState,
      getProviderProgramsRequestState,
      getProviderStaffMemberRequestState,
      getSchoolNamesRequestState,
      schoolNames: schoolNames || [],
      isConfirmed,
    },
    form,
    formState: {
      isValid,
      clearForm,      
    },
    handlers: {
      handleSubmit: handleOnSubmit,
      handleToggleIsConfirmed,
      handleStaffAdded,
      handleStaffRemoved
    },
  };
};