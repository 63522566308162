import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import WriteAccessLevelWrapper from "../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../../../app/components/buttons/AddButton";
import DateLabel from "../../../../../app/components/labels/DateLabel";
import { TabProps } from "../../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../../authentication/enums/PermissionResourceNameEnum";
import {
  HumanTraffickingScreeningToolDto,
  HumanTraffickingScreeningToolResultTextMap,
} from "../../../../human-trafficking-screening-tool/HumanTrafickingScreeningToolDto";
import { useCaseDetailPageContext } from "../../../pages/CaseDetailPage";

export const htstTabName = "htst-section";

const HtstSection = () => {
  //#region State
  const {
    case: { caseResult },
    htst,
  } = useCaseDetailPageContext();
  const { handleCreateHtstClicked } = htst;
  //#endregion

  //#region UI Helpers
  const renderRow = (htst: HumanTraffickingScreeningToolDto) => {
    return (
      <Fragment key={htst.id}>
        <ListItem>
          <ListItemIcon>
            <Avatar>
              <AssessmentIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            secondary={
              <Fragment>
                Result:{" "}
                {HumanTraffickingScreeningToolResultTextMap[htst.result]}
              </Fragment>
            }
          >
            <DateLabel date={htst.administeredOn} />
          </ListItemText>
        </ListItem>
        <Divider />
      </Fragment>
    );
  };

  //#endregion

  return (
    <Box position="relative">
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <AddButton
          color="primary"
          variant="contained"
          text={`Add HTST`}
          onClick={handleCreateHtstClicked}
        />
      </WriteAccessLevelWrapper>
      {caseResult?.humanTraffickingScreeningTools == null && (
        <Typography>
          No Human Trafficking Screening Tools Administered
        </Typography>
      )}
      {caseResult?.humanTraffickingScreeningTools != null && (
        <List>{caseResult.humanTraffickingScreeningTools.map(renderRow)}</List>
      )}
    </Box>
  );
};

export default HtstSection;
export const createHtstTab = (): TabProps => ({
  label: "HTST",
  value: htstTabName,
  content: <HtstSection />,
});
