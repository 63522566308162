import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fnyfsProviderId } from "../../authentication/constants";
import {
  getIsFnyfsUser,
  getProviderId,
} from "../../authentication/state/authenticationActions";
import { ProgramTypeEnum } from "../../programs/enums/ProgramTypeEnum";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { useGetAllProviders } from "../../provider/hooks/providerHooks";
import {
  IcmActivitySearchFormDto,
  IcmActivitySearchParamsDto,
} from "../dtos/IcmActivitySearchParamsDto";

export const useIcmActivityReportFilter = (
  onSubmit: (dto: IcmActivitySearchParamsDto) => Promise<void>,
  params: IcmActivitySearchParamsDto
) => {
  //#region State
  const {
    control,
    reset,
    getValues,
    setValue,
    formState: { isValid },
    watch,
  } = useForm({
    defaultValues: new IcmActivitySearchFormDto(params),
    resolver: yupResolver(IcmActivitySearchFormDto.getSchema()),
    mode: "all",
  });

  const [getAllProviders, , getAllProvidersRequestState] = useGetAllProviders();
  const [providers, setProviders] = useState<ProviderDto[]>([]);
  const isFnUser = getIsFnyfsUser();

  const { startOn, endOn } = watch();

  useEffect(() => {
    if (endOn == null || endOn === "" || startOn == null || startOn === "")
      return;

    setValue("endOn", endOn, { shouldValidate: true });
  }, [startOn, endOn, setValue]);

  //#endregion

  //#region Handlers
  const handleView = async () => {
    const values = getValues();
    await onSubmit(IcmActivitySearchFormDto.toHtmlParams(values));
  };

  const handleDownload = async () => {
    const values = getValues();
    await onSubmit(IcmActivitySearchFormDto.toCsvParams(values));
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new IcmActivitySearchFormDto(params));
  }, [params, reset]);

  useEffect(() => {
    const load = async () => {
      const results = await getAllProviders({
        programTypes: [ProgramTypeEnum.IntensiveCaseManagement],
      });

      if (results) {
        setProviders(results.sort((a, b) => (a.name > b.name ? 1 : -1)));
      }
    };

    if (getProviderId() === fnyfsProviderId) load();
  }, [getAllProviders, setProviders]);

  //#endregion

  return {
    state: { getAllProvidersRequestState, providers, isValid, isFnUser },
    form: { control },
    handlers: { handleView, handleDownload },
  };
};
