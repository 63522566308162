import React from "react";
import { Box, Grid } from "@material-ui/core";
import { PaperMetricContainer } from "../PaperMetricContainer";
import { DataEntryMetricPanel } from "../DataEntryMetricPanel";
import { DischargeReasonsMetricPanel } from "../DischargeReasonsMetricPanel";
import { FilledBedDaysMetricPanel } from "../FilledBedDaysMetricPanel";
import { useDashboardContext } from "../../DashboardContext";
import { DateRangeType } from "../../dtos/MainDashboardMetricsDto";
import { dateToFormString } from "../../../../app/helpers";
import RouteableStyledLink from "../../../../app/components/RouteableStyledLink";
import { Skeleton } from "@material-ui/lab";

export const metricIds = [
  "icmIntakesDataEntry",
  "icmDischargesDataEntry",
  "icmFilledBedDays",
  "icmDischargeReasons",
] as const;

export type IcmMetricsDto = {
  [metricId in typeof metricIds[number]]: any;
};

interface Props {
  metrics?: IcmMetricsDto;
  isLoading: boolean;
}

export const IcmDashboardMetrics: React.FC<Props> = ({
  metrics,
  isLoading,
}) => {
  const { provider, filters } = useDashboardContext();

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  let startDate = dateToFormString(new Date());
  let endDate = dateToFormString(new Date());

  switch (filters.dateRange.type) {
    case DateRangeType.YearToDate:
      startDate = dateToFormString(new Date(currentYear, 0, 1));
      break;
    case DateRangeType.FiscalYearToDate:
      const fyYear = currentMonth >= 6 ? currentYear : currentYear - 1;
      const startMonth = 6;
      startDate = dateToFormString(new Date(fyYear, startMonth, 1));
      break;
    case DateRangeType.Custom:
      startDate = dateToFormString(filters.dateRange.startDate);
      endDate = dateToFormString(filters.dateRange.endDate);
      break;
  }

  return (
    <>
      <Grid item sm={12} md={4}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%" }}
        >
          <Box style={{ paddingBottom: 16 }}>
            <PaperMetricContainer>
              <DataEntryMetricPanel
                intakesMetric={metrics?.icmIntakesDataEntry}
                dischargesMetric={metrics?.icmDischargesDataEntry}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box>
            <PaperMetricContainer>
              <DischargeReasonsMetricPanel
                metric={metrics?.icmDischargeReasons}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={8}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%", height: "100%" }}
        >
          <Box style={{ paddingBottom: 16 }}>
            <PaperMetricContainer>
              <FilledBedDaysMetricPanel
                metric={metrics?.icmFilledBedDays}
                provider={provider}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box style={{ paddingBottom: 16 }}>
            <PaperMetricContainer>
              <Box
                padding="16px"
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                alignItems={"center"}
                width={300}
              >
                {isLoading ? (
                  <Skeleton animation="wave" width={250} />
                ) : (
                  <RouteableStyledLink
                    pathname="/reports/icm-activity"
                    search={`startOn=${startDate}&endOn=${endDate}`}
                    text="Back to Dashboard"
                  >
                    View ICM Activity Report
                  </RouteableStyledLink>
                )}
              </Box>
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
