import {
  Avatar,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";
import { YouthContactSummaryDto } from "../../../youth-contacts/dtos/YouthContactSummaryDto";
import PersonIcon from "@material-ui/icons/Person";
import NameLabel from "../../../../app/components/labels/NameLabel";
import PhoneNumberLabel from "../../../../app/components/labels/PhoneNumberLabel";
import YesNoLabel from "../../../../app/components/labels/YesNoLabel";
import YouthRelationshipLabel from "../../../youth-contacts/components/YouthRelationshipLabel";
import EditButton from "../../../../app/components/buttons/EditButton";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import KeyValueDetails, {
  RowProps,
} from "../../../../app/components/KeyValueDetails";
import DeleteButton from "../../../../app/components/buttons/DeleteButton";

interface Props {
  contact: YouthContactSummaryDto;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const YouthContactListItem: React.FC<Props> = ({ contact, onEditClick, onDeleteClick }) => {
  const rows: RowProps[] = [
    {
      key: "poa",
      label: "Legal Guardian/Power of Attorney",
      value: <YesNoLabel value={contact.isLegalGuardianOrPowerOfAttorney} />,
    },
    {
      key: "emergency",
      label: "Emergency Contact",
      value: <YesNoLabel value={contact.isEmergencyContact} />,
    },
    {
      key: "phone",
      label: "Phone #",
      value: contact.phoneNumber ? (
        <PhoneNumberLabel phoneNumber={contact.phoneNumber} />
      ) : (
        "None"
      ),
    },
    {
      key: "email",
      label: "Email",
      value: contact.email || "None",
    },
    {
      key: "relationship",
      label: "Relationship",
      value: contact.relationship ? (
        <YouthRelationshipLabel relationship={contact.relationship} />
      ) : (
        "Not Specified"
      ),
    },
  ];

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={
          <Box display={"flex"}>
            {contact.firstName !== "" || contact.lastName !== "" ? (
              <NameLabel nameDto={contact} variant="h6" />
            ) : (
              <Typography variant="h6">Name Unknown</Typography>
            )}

            <Box marginLeft={"auto"}>
              <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Youth}>
                <EditButton onClick={onEditClick} />
                <DeleteButton onClick={onDeleteClick} />
              </WriteAccessLevelWrapper>
            </Box>
          </Box>
        }
        secondary={
          <KeyValueDetails rows={rows} mdLabelSize={4} lgLabelSize={3} />
        }
      ></ListItemText>
    </ListItem>
  );
};

export default YouthContactListItem;
