import { ProgramTypeDeliverableDto } from "../../program-type-deliverables/dtos/ProgramTypeDeliverableDto";
import { ProviderFiscalYearDto } from "./ProviderFiscalYearDto";
import {ContractDto} from "../../contracts/dtos/ContractDto";

export interface ProviderFiscalYearFormDto {
  fiscalYear: number;
  contractLimits: { [index:string]: { [index: string]: { limit: number} }}
  licensedBeds: number;
}

export const createProviderFiscalYearFormDto = (
  contracts: ContractDto[],
  programTypeDeliverables: ProgramTypeDeliverableDto[],
  dto?: ProviderFiscalYearDto
): ProviderFiscalYearFormDto => {


  const contractLimits = dto?.contractLimits?.reduce((acc, contractLimit) => {
    return {...acc, [contractLimit.contract.id]: { ...acc[contractLimit.contract.id], [contractLimit.programTypeDeliverableId]: { limit: contractLimit.limit } } }
  }, {} as { [index: string]: { [index: string] : { limit: number}} }) || {}

  if (dto) {
    const { fiscalYear, licensedBeds } = dto;
    return {
      fiscalYear,
      contractLimits,
      licensedBeds
    };
  }
  return { fiscalYear: 0, contractLimits, licensedBeds: 0 };
};
