import { useRequest } from "../../app/hooks/useRequest";
import {
  addServicePlanGoalRequest,
  getServicePlanGoalsRequest,
} from "../ServicePlanRequests";
import { AddServicePlanGoalDto } from "../dto/AddServicePlanGoalDto";
import { useServicePlanPageContext } from "../ServicePlanPageContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getGoalStatusDisplayText,
  ServicePlanGoalStatusEnum,
} from "../enums/ServicePlanGoalStatusEnum";
import { SelectOption } from "../../app/components/inputs/ControlledSelect";
import { useCaseDetailPageContext } from "../../features/cases/pages/CaseDetailPage";
import { useCallback, useState } from "react";

const schema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  notes: Yup.string().optional(),
});

export const useAddServicePlanGoalForm = () => {
  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<AddServicePlanGoalDto>({
    defaultValues: {
      name: "",
      notes: "",
      status: ServicePlanGoalStatusEnum.NotStarted,
      domains: [],
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = () => {
    reset({
      name: "",
      notes: "",
      status: ServicePlanGoalStatusEnum.NotStarted,
      domains: [],
    });
  };

  const { servicePlan, servicePlanUpdated } = useServicePlanPageContext();
  const {
    case: {
      nirvana: { domainNames },
    },
  } = useCaseDetailPageContext();
  const [goals, setGoals] = useState<string[]>([]);

  const [getServicePlanGoals, , getServicePlanGoalsRequestState] = useRequest(
    getServicePlanGoalsRequest
  );
  const [addGoal, , addGoalRequestState] = useRequest<
    AddServicePlanGoalDto,
    void
  >((dto) => addServicePlanGoalRequest(servicePlan.id, dto));

  const handleOnSubmit = handleSubmit(
    async (formData: AddServicePlanGoalDto) => {
      formData.domains = formData.domains.filter((x) => x !== "");
      await addGoal(formData);
      clearForm();
      await servicePlanUpdated();
    }
  );

  const handleRefreshGoals = useCallback(async () => {
    const result = await getServicePlanGoals(null);
    setGoals(result || []);
  }, [getServicePlanGoals]);

  const goalStatusOptions: SelectOption[] = [
    {
      label: getGoalStatusDisplayText(ServicePlanGoalStatusEnum.NotStarted),
      value: ServicePlanGoalStatusEnum.NotStarted,
    },
    {
      label: getGoalStatusDisplayText(ServicePlanGoalStatusEnum.InProgress),
      value: ServicePlanGoalStatusEnum.InProgress,
    },
    {
      label: getGoalStatusDisplayText(ServicePlanGoalStatusEnum.Complete),
      value: ServicePlanGoalStatusEnum.Complete,
    },
  ];

  return {
    state: {
      addGoalRequestState: addGoalRequestState,
      getServicePlanGoalsRequestState,
      goalStatusOptions,
      domainNames,
      goals,
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit, handleRefreshGoals },
  };
};
