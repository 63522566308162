import { useEffect } from "react";
import { batch } from "react-redux";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch } from "../../../app/hooks";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { youthAddressModalId } from "../components/modals/YouthAddressFormDialog";
import { YouthAddressDto } from "../dto/YouthDto";
import { YouthAddressFormDto } from "../dto/YouthFormDto";
import { useGetYouthAddress, useUpdateYouthAddress } from "./youthHooks";

interface YouthAddressProps {
  address?: YouthAddressDto;
  getYouthAddressRequestState: RequestStateDto;
  updateYouthAddressRequestState: RequestStateDto;
  handleUpdateYouthAddressClicked(): void;
  handleUpdateYouthAddress(dto: YouthAddressFormDto): Promise<void>;
}

export const useYouthAddress = (id: string): YouthAddressProps => {
  const dispatch = useAppDispatch();
  const [getYouthAddress, youthAddress, getYouthAddressRequestState] =
    useGetYouthAddress();

  const [
    updateYouthAddress,
    ,
    updateYouthAddressRequestState,
    clearUpdateYouthAddressError,
  ] = useUpdateYouthAddress();

  const handleUpdateYouthAddressClicked = () => {
    clearUpdateYouthAddressError();
    dispatch(openModal({ modalId: youthAddressModalId }));
  };

  const handleUpdateYouthAddress = async (dto: YouthAddressFormDto) => {
    await updateYouthAddress({ id, dto });
    batch(() => {
      dispatch(closeModal());
      dispatch(addSuccessNotification({ message: "Youth Address Updated." }));
    });
    await getYouthAddress(id);
  };

  useEffect(() => {
    getYouthAddress(id);
  }, [getYouthAddress, id]);

  return {
    address: youthAddress,
    getYouthAddressRequestState,
    updateYouthAddressRequestState,
    handleUpdateYouthAddress,
    handleUpdateYouthAddressClicked,
  };
};
