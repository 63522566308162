import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { deliverableRateSchema } from "../components/DeliverableRateFormFields";
import {
  createDeliverableRateFormDto,
  DeliverableRateFormDto,
} from "../dtos/DeliverableRateFormDto";

export const useDeliverableRateForm = (
  onSubmit: (dto: DeliverableRateFormDto) => Promise<any>
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createDeliverableRateFormDto(),
    resolver: yupResolver(deliverableRateSchema),
    mode: "all",
  });

  //#endregion

  const clearForm = () => {
    reset(createDeliverableRateFormDto());
  };
  const handleOnSubmit = handleSubmit(async (dto: DeliverableRateFormDto) => {
    dto.startsOn = new Date(+dto.year, +dto.month, 1, 0, 0, 0, 0);

    await onSubmit(dto);
    clearForm();
  });

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
