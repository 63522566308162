export enum ColorsEnum {
  Blue = "#07C",
  DisabledBlue = "#0077CC88",
  DarkBlue = "#134b7c",
  Red = "#dc3545",
  DisabledRed = "#dc354588",
  Green = "#28a745",
  Yellow = "#ffc107",
  InfoBlue = "#5bc0de",
  Orange = "#e8a633",
  RowGrey = "#0001",
  DarkGrey = "#787878",
  InputBorderGrey = "#c7c7c7",
  OffWhite = "#fafafa",
  ValidationRed = "#f44336",
  Border = "rgba(0, 0, 0, 0.12)",
  BackgroundGrey = "#ddd",
}
