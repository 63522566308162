import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectModalId} from "../../modal/state/modalSelectors";
import {usePrintMultipleFollowupsDialog} from "../hooks/usePrintMultipleFollowupsDialog";
import {FollowUpSearchParamsDto} from "../dtos/FollowUpSearchParamsDto";
import ErrorText from "../../../app/components/ErrorText";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import {closeModal} from "../../modal/state/modalSlice";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";

interface Props {
  params: FollowUpSearchParamsDto
}

export const multipleFollowupsModalId = "multiple-followups-modal";

export const PrintMultipleFollowUpsDialog: React.FC<Props> = ({ params }) => {
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { state: { followupTypeOptions, getMultipleFollowUpReportsDocumentRequestState }, form, handlers: { handlePrintMultipleFollowUps } } = usePrintMultipleFollowupsDialog(params)

  const { control, isValid, clearForm } = form;

  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };

  return(
    <Dialog open={modalId === multipleFollowupsModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handlePrintMultipleFollowUps}>
        <DialogTitle>
          Print Multiple Follow Up Forms
          <ErrorText error={getMultipleFollowUpReportsDocumentRequestState.error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid item md={12} xs={12}>
            <ControlledSelect
              control={control}
              name="followUpInterval"
              label={"Follow Up Type"}
              options={followupTypeOptions}
              fullWidth
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <p>
            <Typography>
              This will create a single PDF of Follow Ups that match your selected criteria.
            </Typography>
            </p>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={getMultipleFollowUpReportsDocumentRequestState?.isLoading}
            content="Generate"
            color="primary"
            type="submit"
            disabled={!isValid || getMultipleFollowUpReportsDocumentRequestState?.isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}
