import React, {useCallback, useEffect, useState} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useRequest } from "../../../app/hooks/useRequest";
import { getMetricDataRequest } from "../DashboardRequests";
import ErrorAlert from "../../../app/components/ErrorAlert";
import {
  createDateRangeRequestDto,
  DashboardFiltersDto,
  DashboardMetricDataResponseDto,
  DataFieldDefinitionDto,
  DataTypes,
  ReferenceTypes,
} from "../dtos/MainDashboardMetricsDto";
import DateLabel from "../../../app/components/labels/DateLabel";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import BackButton from "../../../app/components/buttons/BackButton";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import AssessmentLink from "../../assessments/components/AssessmentLink";
import { AssessmentDto } from "../../assessments/dtos/AssessmentDto";
import { useDashboardContext } from "../DashboardContext";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import { DashboardHeaderPanel } from "../components/DashboardHeaderPanel";
import { assessmentTypeTextMap } from "../../assessments/enums/AssessmentTypeEnum";
import PhoneNumberLabel from "../../../app/components/labels/PhoneNumberLabel";
import { referralStatusTextMap } from "../../referrals/enums/ReferralStatusEnum";
import { referralServiceRequestedTextMap } from "../../referrals/enums/ReferralServiceRequestedEnum";
import SortableTable, {
  ColumnProps,
} from "../../../app/components/SortableTable";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import {useQuery} from "../../../app/hooks/useQuery";
import {ParsedQuery} from "query-string";

type DashboardDataFilterPaginatedDto = DashboardFiltersDto & PaginationSearchParamsDto;

const buildDto = (query: ParsedQuery<string>, filters: DashboardFiltersDto): DashboardDataFilterPaginatedDto => {
  return {
    dateRange: createDateRangeRequestDto(
      query.type as string,
      query.startDate as string,
      query.endDate as string
    ),
    page: parseInt(query.page as string || "0"),
    pageSize: parseInt(query.pageSize as string || "25"),
    sortBy: query.sortBy as string,
    sortDirection: query.sortDirection as SortDirectionEnum
  }
}

const toQueryString = (dto: DashboardDataFilterPaginatedDto) => {

  const dateRange = QueryStringHelpers.toQueryString(dto.dateRange)
  const pagination = QueryStringHelpers.toQueryString({ page: dto.page, pageSize: dto.pageSize, sortBy: dto.sortBy, sortDirection: dto.sortDirection })
  const combined = [dateRange, pagination].join('&')

  return combined;
}


const renderDataElement = (dataType: DataTypes, value: any) => {
  switch (dataType) {
    case DataTypes.ReferenceId:
      return <></>;
    case DataTypes.DateTime:
      return (
        <Typography>
          <DateTimeLabel date={value} />
        </Typography>
      );
    case DataTypes.Date:
      return (
        <Typography>
          <DateLabel date={value} />
        </Typography>
      );
    case DataTypes.AssessmentTypeEnum:
      return <Typography>{assessmentTypeTextMap[value] || value}</Typography>;
    case DataTypes.PhoneNumber:
      return <PhoneNumberLabel phoneNumber={value} />;
    case DataTypes.ReferralStatus:
      return <Typography>{referralStatusTextMap[value] || value}</Typography>;
    case DataTypes.RequestedServices:
      if (!value) return <></>;

      const str = value as string;
      const parts = str
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll('"', "")
        .split(",")
        .map((p) => referralServiceRequestedTextMap[p] || p)
        .join(", ");
      return <Typography>{parts}</Typography>;

    default:
      return (
        <Typography>{value == null || value === "" ? "-" : value}</Typography>
      );
  }
};

const renderReferenceElement = (
  dataType: DataTypes,
  value: any,
  referenceType: ReferenceTypes,
  referenceId: string,
  currentPathname: string
) => {
  let path = "";

  if (!referenceId) return renderDataElement(dataType, value);

  switch (referenceType) {
    case ReferenceTypes.Case:
      path = `/cases/${referenceId}`;
      break;
    case ReferenceTypes.Youth:
      path = `/youths/${referenceId}`;
      break;
    case ReferenceTypes.Provider:
      path = `/providers/${referenceId}`;
      break;
    case ReferenceTypes.Referral:
      path = `/referrals/${referenceId}`;
      break;
    case ReferenceTypes.Screening:
      path = `/screenings/${referenceId}`;
      break;
    case ReferenceTypes.Assessment:
      // NOTE: (TECH DEBT) we know we are passing in a child component so the AssessmentLink
      // only needs to know the assessment id, so we are forcing the typecast on a
      // partial object. We should really refactor the AssessmentLink component.
      return (
        <AssessmentLink assessment={{ id: referenceId } as AssessmentDto}>
          {renderDataElement(dataType, value)}
        </AssessmentLink>
      );
    default:
      return renderDataElement(dataType, value);
  }

  return (
    <RouteableStyledLink pathname={path} text={"Back to Dashboard Data"}>
      {renderDataElement(dataType, value)}
    </RouteableStyledLink>
  );
};

export const DashboardDataDetails: React.FC = () => {
  const location = useLocation();
  const query = useQuery();
  const { metric } = useParams<{ metric: string }>();

  const { filters, providerId } = useDashboardContext();

  const [getData, data, requestState] = useRequest<
    { metricId: string; dto: DashboardDataFilterPaginatedDto },
    DashboardMetricDataResponseDto
  >(getMetricDataRequest);
  const { isLoading, error } = requestState;

  const [searchParams, setSearchParams] = useState<
    DashboardDataFilterPaginatedDto
  >(buildDto(query, filters));

  useEffect(() => {
    const updatedSearchParams = { ...searchParams, ...filters };
    getData({ metricId: metric, dto: updatedSearchParams });
  }, [metric, getData, searchParams, filters, providerId]);

  const [columns, setColumns] = useState<{
    [key: string]: DataFieldDefinitionDto;
  }>();

  const [columnProps, setColumnProps] = useState<ColumnProps[]>([]);
  const [paginationResult, setPaginationResult] = useState<
    PaginationResultDto<{ [key: string]: string }>
  >(new PaginationResultDto<{ [key: string]: string }>());

  const handlePageChange = useCallback( (page: number) => {
    const newParams = { ...searchParams, page };
    setSearchParams(newParams);
  }, [searchParams]);

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...searchParams, page: 0, sortBy, sortDirection };
    setSearchParams(newParams);
  };

  const renderRow = (data: { [key: string]: string }) => {
    if (!columns) return <></>;

    return (
      <TableRow key={Object.values(data)[0]}>
        {Object.keys(columns)
          .filter(
            (columnKey) => columns[columnKey].dataType !== DataTypes.ReferenceId
          )
          .map((columnKey) => {
            const { referenceType, dataType, referenceIdField } =
              columns[columnKey];

            return (
              <TableCell key={`${columnKey}`}>
                {referenceType && referenceIdField
                  ? renderReferenceElement(
                      dataType,
                      data[columnKey],
                      referenceType,
                      data[referenceIdField],
                      location.pathname
                    )
                  : renderDataElement(dataType, data[columnKey])}
              </TableCell>
            );
          })}
      </TableRow>
    );
  };

  useEffect(() => {
    if (data && data.dataFields) {
      setColumns(data.dataFields);
    }
  }, [data]);

  useEffect(() => {
    if (columnProps.length === 0 && data && data.dataFields) {
      const columnProps: ColumnProps[] = Object.entries(data.dataFields)
        .filter(([, value]) => value.dataType !== DataTypes.ReferenceId)
        .map(([key, value]) => ({ name: key, label: value.fieldLabel }));

      setColumnProps(columnProps);
    }
  }, [data, setColumnProps, columnProps]);

  useEffect(() => {

    if (data) {
      if(data.count > 0 && data.data.length === 0 && data.page > 1) {
        handlePageChange(0)
        return;
      } else {
        const value = new PaginationResultDto<{ [key: string]: string }>();
        value.count = data.count;
        value.items = data.data;
        value.page = data.page;
        value.pageSize = data.pageSize;
        setPaginationResult(value);
      }
    }

  }, [data, setPaginationResult, handlePageChange]);

  const history = useHistory();

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: toQueryString(searchParams),
    });
  }, [searchParams, history, location.pathname]);

  return (
    <div>
      <ErrorAlert error={error?.message} />
      <Box marginBottom="12px">
        <BackButton
          route={`/dashboards/main${location.search}`}
          text="Back to Dashboard"
        />
      </Box>
      <DashboardHeaderPanel
        requestState={requestState}
        title={`Provider Dashboard Data for ${data?.title}`}
        metricsData={data}
        hideSelector={true}
      />

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant="outlined" style={{ display: "flex" }}>
            <SortableTable
              renderRow={renderRow}
              columns={columnProps}
              results={paginationResult}
              params={searchParams}
              isLoading={isLoading}
              onPageChange={handlePageChange}
              onSort={handleSort}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
