import React from "react";
import { useCreateServicePlanForm } from "../hooks/useCreateServicePlanForm";
import { CaseDetailDto } from "../../features/cases/dtos/CaseDetailDto";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectModalId } from "../../features/modal/state/modalSelectors";
import { closeModal } from "../../features/modal/state/modalSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import ErrorText from "../../app/components/ErrorText";
import SpinnerButton from "../../app/components/buttons/SpinnerButton";
import ControlledInput from "../../app/components/inputs/ControlledInput";
import {ServicePlanDto} from "../dto/ServicePlanDto";
import {
  StaffMemberControlledAutoComplete
} from "../../features/staff-members/components/StaffMemberControlledAutoComplete";

export interface Props {
  caseDto: CaseDetailDto;
  servicePlan?: ServicePlanDto;
  onSubmit: () => Promise<void>;
}

export const servicePlanFormDialogId = "service-plan-dialog";

export const ServicePlanFormDialog: React.FC<Props> = ({
  caseDto,
  servicePlan,
  onSubmit,
}) => {
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const {
    state: {
      getProviderStaffMembersRequestState,
      createServicePlanRequestState,
      updateServicePlanRequestState,
      staffMembers,
    },
    form: { isValid, clearForm, control },
    handlers: { handleCreateSubmit, handleUpdateSubmit },
  } = useCreateServicePlanForm(onSubmit, caseDto, servicePlan);

  const { isLoading, error } = servicePlan
    ? updateServicePlanRequestState
    : createServicePlanRequestState;

  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  return (
    <Dialog open={modalId === servicePlanFormDialogId} maxWidth="md" fullWidth>
      <form
        onSubmit={
          !servicePlan ? handleCreateSubmit : handleUpdateSubmit
        }
      >
        <DialogTitle>
          {!servicePlan ? "Create" : "Edit"} a Service Plan
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="startedOn"
                label="Plan Start Date*"
                type="date"
                control={control}
                disabled={isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <StaffMemberControlledAutoComplete
                staffMembers={staffMembers!}
                control={control}
                isLoading={getProviderStaffMembersRequestState.isLoading}
                name={"counselorStaffId"}
                label={"Assigned Counselor*"}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="notes"
                label="Notes"
                type="text-area"
                minRows={6}
                multiline
                fullWidth
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
