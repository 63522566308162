export enum FollowUpStatus {
  NotRequired = "NotRequired",
  InProgress = "InProgress",
  Attempted = "Attempted",
  Completed = "Completed"
}

export const followUpStatusTextMap: { [key: string]: string } = {
  [FollowUpStatus.NotRequired]: "Not Required",
  [FollowUpStatus.InProgress]: "In Progress",
  [FollowUpStatus.Attempted]: "Attempted",
  [FollowUpStatus.Completed]: "Completed",
};
