import { Fragment, useEffect, useState } from "react";
import { TabProps } from "../../../../../app/components/TabContainer";
import { CaseContactDto } from "../../../../case-contacts/dtos/CaseContactDto";
import { useCaseDetailPageContext } from "../../../pages/CaseDetailPage";
import CaseContactList from "../../../../case-contacts/components/CaseContactList";
import { PaginationResultDto } from "../../../../../app/dtos/PaginationResultDto";
import { CaseContactSearchParamsDto } from "../../../../case-contacts/dtos/CaseContactSearchParamsDto";
import WriteAccessLevelWrapper from "../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../../authentication/enums/PermissionResourceNameEnum";
import AddButton from "../../../../../app/components/buttons/AddButton";
import { SortDirectionEnum } from "../../../../../app/enums/SortDirectionEnum";

export const caseContactTabName = "case-contact-section";

const CaseContactsSection: React.FC = () => {
  //#region State
  const {
    case: {
      caseResult,
      getCaseRequestState
    },
    caseContacts: {
      handleCreateCaseContactClicked
    }, 
  } = useCaseDetailPageContext();  
  
  const sortBySelection = (params: CaseContactSearchParamsDto, 
    a: CaseContactDto, 
    b: CaseContactDto): number => { 
      const sortDirModifier = params.sortDirection === "asc" ? -1 : 1;    

      let valA = a[params.sortBy as keyof typeof a] ?? "";
      let valB = b[params.sortBy as keyof typeof b] ?? "";    
      
      // special case to handle nested `staffMember` property
      if (params.sortBy === "contactedBy") {
        valA = `${a.staffMember.firstName} ${a.staffMember.lastName}`;
        valB = `${b.staffMember.firstName} ${b.staffMember.lastName}`;
      }

      if (valA < valB) return 1 * sortDirModifier;
      if (valA > valB) return -1 * sortDirModifier;

      // secondary sort on most recent contact date.
      if (a.contactOn < b.contactOn) return 1;
      if (a.contactOn > b.contactOn) return -1;

      // final sort by unique id to ensure consistency.
      if (a.contactOn === b.contactOn) return a.id < b.id ? 1 : -1;  
      
      return 0;
  }  

  const sortContacts = (contacts: CaseContactDto[], params: CaseContactSearchParamsDto): CaseContactDto[] => {
    return contacts?.sort((a, b) => sortBySelection(params, a,b)) || [];
  }   

  const [params, setParams] = useState<CaseContactSearchParamsDto>(new CaseContactSearchParamsDto({}));

  const [contacts, setContacts] = useState<CaseContactDto[]>(sortContacts(caseResult?.caseContacts || [], params));  
  
  //#endregion

  useEffect(() => {
    setContacts(caseResult?.caseContacts || []);
  }, [caseResult]);

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    setParams(newParams);
    setContacts(sortContacts(caseResult?.caseContacts || [], newParams));
  };  

  // Construct a "fake" paginated result with all results on one page.
  const results = new PaginationResultDto<CaseContactDto>();
  results.items = contacts; 
  results.count = contacts.length;  
  results.pageSize = contacts.length;

  return (
    <Fragment>
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <AddButton
          className={"no-print"}
          text="Add Case Contact"
          onClick={handleCreateCaseContactClicked}
          variant="contained"
          color="primary"
        />
      </WriteAccessLevelWrapper>

        <CaseContactList
          results={results}
          isLoading={getCaseRequestState.isLoading}
          onPageChange={()=>{}}
          onSort={handleSort}
          params={params}
      />
    </Fragment>
  );
};

export default CaseContactsSection;

export const createCaseContactTab = (): TabProps => ({
  label: "Case Contacts",
  value: caseContactTabName,
  content: <CaseContactsSection />,
});
