import { Box, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { ColorsEnum } from "../enums/ColorsEnum";

interface Props {
  error?: string;
}

const HtmlErrorText: React.FC<Props> = ({ error }) => {
  if (error)
    return (
      <Box marginTop="8px" color={ColorsEnum.ValidationRed}>
        <ul>
          {error.split("\n").map((e, i) => (
            <li key={`error-${i}`}>
              <Typography>
                <span dangerouslySetInnerHTML={{ __html: e }}></span>
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    );

  return <Fragment></Fragment>;
};

export default HtmlErrorText;
