export enum YouthRaceEnum {
  AmericanIndian = "AmericanIndian",
  AlaskanNative = "AlaskanNative",
  Asian = "Asian",
  AfricanAmerican = "AfricanAmerican",
  NativeHawaiian = "NativeHawaiian",
  White = "White",
  NotSpecified = "NotSpecified",
}

export const YouthRaceEnumTextMap: { [key: string]: string } = {
  [YouthRaceEnum.AmericanIndian]: "American Indian",
  [YouthRaceEnum.AlaskanNative]: "Alaskan Native",
  [YouthRaceEnum.Asian]: "Asian",
  [YouthRaceEnum.AfricanAmerican]: "Black/African American",
  [YouthRaceEnum.NativeHawaiian]: "Native Hawaiian/Pacific Islander",
  [YouthRaceEnum.White]: "White",
  [YouthRaceEnum.NotSpecified]: "Not Specified",
};
