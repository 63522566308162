import { useRequest } from "../../../app/hooks/useRequest";
import {
  getDjjEntriesRequest,
  getDjjEntryDetailsRequest,
  getDjjEntryNotesRequest,
  setDjjEntryNotesRequest,
  setDjjEntryStatusRequest,
} from "../DjjEntryRequests";

export const useGetDjjEntries = () => useRequest(getDjjEntriesRequest);

export const useSetDjjEntryStatus = () => useRequest(setDjjEntryStatusRequest);

export const useGetDjjEntryNotes = () => useRequest(getDjjEntryNotesRequest);

export const useSetDjjEntryNotes = () => useRequest(setDjjEntryNotesRequest);

export const useGetDjjEntryDetails = () =>
  useRequest(getDjjEntryDetailsRequest);
