import React, { Fragment } from "react";
import Header from "../../../app/components/Header";
import ReferralList from "../components/ReferralList";
import { useReferralListPage } from "../hooks/useReferralListPage";
import ReferralFilter from "../components/ReferralFilter";
import ReferralFilterList from "../components/ReferralFilterList";
import { ReferralSummaryDto } from "../dtos/ReferralSummaryDto";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import FilterButton from "../../../app/components/buttons/FilterButton";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import AddButton from "../../../app/components/buttons/AddButton";
import { useHistory } from "react-router-dom";

const ReferralListPage = () => {
  const history = useHistory();
  const { state, handlers } = useReferralListPage();
  const {
    params,
    providers,
    referralsResult,
    getReferralsRequestState,
    getProvidersRequestState,
    filterAnchorEl,
  } = state;

  const {
    handleCloseFilter,
    handleSearch,
    handlePageChange,
    handleOpenFilterClicked,
    handleSort,
  } = handlers;

  return (
    <div>
      <ReferralFilter
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilter}
        params={params}
        onSearch={handleSearch}
        providers={providers}
      />
      <RequestErrorAlert
        requests={[getReferralsRequestState, getProvidersRequestState]}
      />
      <Header
        title="Referrals"
        actions={

          <Fragment>
            <FilterButton
              title="Filter Referrals"
              onClick={handleOpenFilterClicked}
              disabled={getProvidersRequestState.isLoading}
            />
            <AddButton
              title="New Referral"
              color="primary"
              text="New Referral"
              onClick={() => history.push('/referrals/new')}
              variant="contained"
              className="ml-2"
            />
          </Fragment>
        }
        secondaryActions={
          <ReferralFilterList
            params={params}
            onSearch={handleSearch}
            isLoading={getReferralsRequestState.isLoading}
            providers={providers}
          />
        }
      />
      <ReferralList
        results={
          referralsResult || new PaginationResultDto<ReferralSummaryDto>()
        }
        isLoading={getReferralsRequestState.isLoading}
        params={params}
        onPageChange={handlePageChange}
        onSort={handleSort}
      />
    </div>
  );
};

export default ReferralListPage;
