import { createLocationFormDto, LocationFormDto } from "./LocationFormDto";
import { ProgramLocationDto } from "./ProgramLocationDto";
import { DateTime } from "luxon";
import { ProgramLocationBaseFormDto } from "./ProgramLocationBaseFormDto";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
import { dateToFormString } from "../../../app/helpers";

export interface ProgramLocationFormDto extends ProgramLocationBaseFormDto {
  location?: LocationFormDto;
  locationId?: string;
  useExisting: "true" | "false";
  fundingSource: ProgramFundingSourceEnum;
  isEditing: boolean;
}

export const createProgramLocationFormDto = (
  fundingSource: ProgramFundingSourceEnum,
  dto?: ProgramLocationDto
): ProgramLocationFormDto => {
  return {
    fundingSource,
    locationId: dto?.locationId || "",
    location: createLocationFormDto(),
    startedOn: dto?.startedOn
      ? dateToFormString(new Date(dto.startedOn))
      : DateTime.now().toISODate(),
    terminatedOn: dto?.terminatedOn
      ? dateToFormString(new Date(dto.terminatedOn))
      : "",
    judicialCircuitNumber: dto?.judicialCircuitNumber || "",
    juvenileJusticeProgramCode: dto?.juvenileJusticeProgram?.code || "",
    useExisting: "true",
    isEditing: dto !== undefined,
  };
};
