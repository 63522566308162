import { DateTime } from "luxon";
import { datetimeToFormString } from "../../../app/helpers";
import { CaseHoldDto } from "../../case-hold/dtos/CaseHoldDto";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import * as Yup from "yup";
import { ChangeDischargeDateFormDto } from "./ChangeDischargeDateFormDto";
import { DischargeDto } from "./DischargeDto";

export class DischargeFormDto {
  dischargedAt: string;
  exitCounselorId: string;
  status: string;
  livingSituation: string;
  schoolProgram: string;
  schoolStatus: string;
  employmentStatus?: string;
  employmentStatusRequired: boolean;
  isBakerAct: boolean;
  isEdit: boolean;
  notes: string;

  constructor(caseHold?: CaseHoldDto, discharge?: DischargeDto) {
    let dischargedAt = '';
    if (discharge) {
      dischargedAt = datetimeToFormString(new Date(discharge.dischargedAt));
    } else if (caseHold) {
      let dateTime = DateTime.fromJSDate(new Date(caseHold.startAt));
      dateTime = dateTime.plus({ hours: 48 });
      if (dateTime.toJSDate() < new Date()) {
        dischargedAt = datetimeToFormString(dateTime.toJSDate());
      }
    }

    this.dischargedAt = dischargedAt;
    this.exitCounselorId = discharge?.exitCounselor?.id || "";
    this.status = discharge?.status || "";
    this.livingSituation = discharge?.livingSituation || "";
    this.schoolProgram = discharge?.schoolProgram || "";
    this.schoolStatus = discharge?.schoolStatus || "";
    this.employmentStatus = discharge?.employmentStatus || "";
    this.employmentStatusRequired = false;
    this.notes = discharge?.notes || "";
    this.isBakerAct = discharge?.isBakerAct || false;
    this.isEdit = discharge != null;
  }

  public static getSchema(caseDto: CaseDetailDto) {
    return Yup.object().shape({
      dischargedAt: ChangeDischargeDateFormDto.getDischargedAtShema(caseDto),
      exitCounselorId: Yup.string()
        .label("Discharge Staff")
        .required(),
      status: Yup.string().label("Discharge Status").required(),
      livingSituation: Yup.string().label("Living Situation").required(),
      schoolProgram: Yup.string().label("School Program").required(),
      schoolStatus: Yup.string().label("School Status").required(),
      employmentStatus: Yup.string().when("employmentStatusRequired", {
        is: true,
        then: Yup.string().required(
          "Employment Status is required for Youth aged 14+ at time of discharge."
        ),
      }),
      notes: Yup.string().label("Notes").max(1000),
    });
  }
}
