import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum PetitionTypeEnum {
  CinsFins = "CinsFins",
  Truancy = "Truancy",
}

export const petitionTypeTextMap: { [key: string]: string } = {
  [PetitionTypeEnum.CinsFins]: "CINS",
  [PetitionTypeEnum.Truancy]: "Truancy",
};

export const petitionTypeOptions: SelectOption[] = [
  { value: "", label: "Select Petition Type" },
  ...Object.keys(PetitionTypeEnum).map((value) => ({
    value,
    label: petitionTypeTextMap[value],
  })),
];
