import React from "react";
import { TextField } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";
import { TextFieldProps } from "@material-ui/core";

interface Props {
  control: Control<any>;
  name: string;
}

const ControlledInput: React.FC<Props & TextFieldProps> = ({
  control,
  ...props
}) => {
  const { name } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          variant="outlined"
          fullWidth
          {...field}
          autoComplete="off"
          {...props}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );
};

export default ControlledInput;
