import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  RadioGroup,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledRadioGroup from "../../../app/components/inputs/ControlledRadioGroup";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import ControlledSwitch from "../../../app/components/inputs/ControlledSwitch";
import {
  getLocationOptions,
  LocationDto
} from "../../locations/dtos/LocationDto";
import {
  ProgramTypeDto,
  programTypesToOptions
} from "../../program-types/dtos/ProgramTypeDto";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { getFundingSourceOptions } from "../../programs/enums/ProgramFundingSourceEnum";
import {
  ProviderDto,
  providerToOptionDtos
} from "../../provider/dtos/ProviderDto";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import { CaseDateRangeTypeEnum } from "../dtos/CaseFilterFormDto";
import { CaseSearchParamsDto } from "../dtos/CaseSearchParamsDto";
import { useCaseFilter } from "../hooks/useCaseFilter";
import {StaffMemberControlledAutoComplete} from "../../staff-members/components/StaffMemberControlledAutoComplete";

interface Props {
  params: CaseSearchParamsDto;
  anchorEl: HTMLElement | null;
  providers: ProviderDto[];
  programTypes: ProgramTypeDto[];
  providerLocations: { [key: string]: LocationDto[] };
  providerPrograms: { [key: string]: ProgramDto[] };
  providerStaffMembers: { [key: string]: StaffMemberDto[] };
  onClose(): void;
  onSearch(dto: CaseSearchParamsDto): void;
  onProviderChange(providerId: string): void;
}

const CaseFilter: React.FC<Props> = ({
  params,
  anchorEl,
  providers,
  programTypes,
  providerLocations,
  providerPrograms,
  providerStaffMembers,
  onProviderChange,
  onClose,
  onSearch,
}) => {
  const [sortedProviders, setSortedProviders] = useState<ProviderDto[]>([]);
  //#region State
  const {
    state: { locations, programs, staffMembers },
    form: { control, clearAllFilters },
    handlers: { handleSubmit },
  } = useCaseFilter(
    onSearch,
    onClose,
    onProviderChange,
    providerLocations,
    providerPrograms,
    providerStaffMembers,
    params
  );
  //#endregion

  useEffect(() => {
    setSortedProviders(providers.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }, [providers, setSortedProviders]);
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">Case Filters</Typography>
                <Box marginLeft="auto">
                  <IconButton
                    onClick={clearAllFilters}
                    title="Clear All Filters"
                  >
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            {/* Case # */}
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="caseNumber"
                label="Case #"
                fullWidth
              />
            </Grid>

            {/* Funding Source */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="fundingSource"
                label="Funding Source"
                options={getFundingSourceOptions("All")}
                fullWidth
              />
            </Grid>

            {/* Program Types */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="programTypeId"
                label="Program Type"
                options={programTypesToOptions(programTypes, "All")}
                fullWidth
              />
            </Grid>

            {/* Provider Name */}
            <FnAccessWrapper>
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name="providerId"
                  label="Provider"
                  fullWidth
                  options={providerToOptionDtos(sortedProviders, [], "All")}
                />
              </Grid>
            </FnAccessWrapper>

            {/* Programs */}
            <Grid item md={12} xs={12}>
              <ProgramControlledSelect
                control={control}
                name="programId"
                label="Program"
                programs={programs}
                disabled={programs.length === 0}
                defaultOptionLabel={"All"}
                isLoading={false}
              />
            </Grid>

            {/*Locations */}
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="locationId"
                label="Location"
                options={getLocationOptions(locations, "All")}
                disabled={locations.length === 0}
                fullWidth
              />
            </Grid>
            
            <ProviderAccessWrapper>
              {/*Case Manager */}
              <Grid item md={12} xs={12}>
                <StaffMemberControlledAutoComplete
                  control={control}
                  name="primaryStaffId"
                  label="Primary Staff"
                  staffMembers={staffMembers}
                  disabled={staffMembers.length === 0}
                  isLoading={false}
                />
              </Grid>
              
              {/*Intake Staff */}
              <Grid item md={12} xs={12}>
                <StaffMemberControlledAutoComplete
                  control={control}
                  name="intakeStaffId"
                  label="Intake Staff"
                  staffMembers={staffMembers}
                  disabled={staffMembers.length === 0}
                  isLoading={false}
                />
              </Grid>
            </ProviderAccessWrapper>

            {/* Youth Name */}
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="youthFirstName"
                label="Youth First Name"
                fullWidth
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="youthLastName"
                label="Youth Last Name"
                fullWidth
              />
            </Grid>

            {/* Date Range */}
            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography color="primary">Date Range</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="dateRangeStart"
                label="Start Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ControlledInput
                control={control}
                name="dateRangeEnd"
                label="End Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <RadioGroup>
                <ControlledRadioGroup
                  row
                  name="dateRangeType"
                  value={false}
                  control={control}
                  options={[
                    {
                      value: CaseDateRangeTypeEnum.CaseActive,
                      label: "Case Active Date",
                    },
                    {
                      value: CaseDateRangeTypeEnum.Intake,
                      label: "Intake Date",
                    },
                    {
                      value: CaseDateRangeTypeEnum.Discharge,
                      label: "Discharge Date",
                    },
                  ]}
                />
              </RadioGroup>
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
            {/* Active Only */}
            <Grid item md={6} xs={12}>
              <ControlledSwitch
                control={control}
                name="activeOnly"
                label="Show Active Only"
                color="primary"
              />
            </Grid>

            {/* Include Deleted */}
            <FnAccessWrapper>
              <Grid item md={6} xs={12}>
                <ControlledSwitch
                  control={control}
                  name="includeDeleted"
                  label="Show Deleted"
                  color="primary"
                />
              </Grid>
            </FnAccessWrapper>

            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};

export default CaseFilter;
