import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { BakerActDto } from "../dto/BakerActDto";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
interface Props {
  requestState: RequestStateDto;
  bakerAct?: BakerActDto;
  onConfirm(): Promise<void>;
}

export const confirmDeleteBakerActDialogId = "delete-baker-act-dialog";
const ConfirmDeleteBakerActDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  bakerAct,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteBakerActDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Baker Act"
      maxWidth="md"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to delete the Baker Act started at{" "}
        <DateTimeLabel date={bakerAct?.startedAt} />
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteBakerActDialog;
