import React from "react";
import {ServicePlanGoalStatusEnum, getGoalStatusDisplayText} from "../enums/ServicePlanGoalStatusEnum";
import AlertLabel from "../../app/components/labels/AlertLabel";
import OrangeAlertLabel from "../../app/components/labels/OrangeAlertLabel";
import GreenAlertLabel from "../../app/components/labels/GreenAlertLabel";

interface Props {
  status: ServicePlanGoalStatusEnum
}

export const ServicePlanGoalStatusLabel: React.FC<Props> = ({ status }) => {

  switch (status) {
    case ServicePlanGoalStatusEnum.NotStarted:
      return <AlertLabel label={getGoalStatusDisplayText(status)} color={"gray"} />;
    case ServicePlanGoalStatusEnum.InProgress:
      return <OrangeAlertLabel label={getGoalStatusDisplayText(status)} />
    case ServicePlanGoalStatusEnum.Complete:
      return <GreenAlertLabel label={getGoalStatusDisplayText(status)} />
    default:
      return <></>
  }

}
