import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useGetRecentYouthScreenings } from "../../youths/hooks/youthHooks";
import { SetCaseScreeningFormDto } from "../dtos/SetCaseScreeningFormDto";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
  screeningId: Yup.string().label("Screening").required(),
});

export const useSetCaseScreeningForm = (
  onSubmit: (dto: SetCaseScreeningFormDto) => Promise<void>,
  youthId: string,
  caseIntakeDate?: Date
) => {
  //#region State
  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
    watch,
  } = useForm<SetCaseScreeningFormDto>({
    defaultValues: { screeningId: "" },
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [getYouthScreenings, screenings, getYouthScreeningsRequestState] =
    useGetRecentYouthScreenings(youthId);
  const { screeningId } = watch();
  //#endregion

  const clearForm = () => {
    reset({ screeningId: "" });
  };

  const handleOnSubmit = handleSubmit(async (dto: SetCaseScreeningFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  useEffect(() => {
    getYouthScreenings(caseIntakeDate);
  }, [caseIntakeDate, getYouthScreenings]);

  return {
    state: {
      screeningId,
      screenings,
      getYouthScreeningsRequestState,
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
