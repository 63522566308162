import { getProviderId } from "../../authentication/state/authenticationActions";
import { UserDetailDto } from "./UserDetailDto";
import * as Yup from "yup";
import { fnyfsProviderId } from "../../authentication/constants";
import { trimPhoneNumber } from "../../../app/helpers";
export class UserFormDto {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  phoneNumber?: string;
  isFloridaNetworkUser: boolean;
  roleId: string;
  editMode: boolean;
  providerId: string;

  constructor(user?: UserDetailDto) {
    this.providerId = getProviderId() || "";
    this.firstName = user?.firstName || "";
    this.lastName = user?.lastName || "";
    this.email = user?.email || "";
    this.confirmEmail = "";
    this.phoneNumber = user?.phoneNumber || "";
    this.isFloridaNetworkUser = user?.isFloridaNetworkUser || false;
    this.roleId =
      user?.roles?.find((x) => x.provider?.id === this.providerId)?.roleId ||
      "";
    this.editMode = Boolean(user);
  }

  public static getSchema() {
    return Yup.object().shape({
      firstName: Yup.string().label("First Name").required().max(50),
      lastName: Yup.string().label("Last Name").required().max(50),
      email: Yup.string().label("Email").required().email(),
      confirmEmail: Yup.string()
        .label("Email Confirmation")
        .when("editMode", {
          is: false,
          then: Yup.string()
            .required()
            .oneOf([Yup.ref("email")], "Emails do not match."),
        }),
      roleId: Yup.string()
        .label("Role")
        .test("role-required", "Role is a required field.", function (value) {
          if (getProviderId() === fnyfsProviderId)
            return !this.parent.isFloridaNetworkUser || value !== "";

          return value !== "";
        }),
      phoneNumber: Yup.string()
        .label("Phone Number")
        .test("length", "Phone Number must be 10 digits long", function (value) {
          var trimmed = trimPhoneNumber(value);
          if (value == null || trimmed?.length === 0) return true;
          return trimmed?.length === 10;
        })
    });
  }
}
