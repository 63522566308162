import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../../app/components/ErrorText";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectModalId } from "../../../modal/state/modalSelectors";
import { closeModal } from "../../../modal/state/modalSlice";
import { YouthAddressDto } from "../../dto/YouthDto";
import { YouthAddressFormDto } from "../../dto/YouthFormDto";
import { useYouthAddressForm } from "../../hooks/useYouthAddressFormDialog";
import YouthAddressFormFields from "../YouthAddressFormFields";

export const youthAddressModalId = "youth-address-form-modal";

interface Props {
  address?: YouthAddressDto;
  requestState: RequestStateDto;
  onSubmit(dto: YouthAddressFormDto): Promise<any>;
}

const YouthAddressFormDialog: React.FC<Props> = ({
  address,
  requestState,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useYouthAddressForm(onSubmit, address);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === youthAddressModalId} maxWidth="xl" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Edit Youth Address
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <YouthAddressFormFields
              prefix="address"
              control={control}
              disabled={isLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default YouthAddressFormDialog;
