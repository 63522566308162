import { Box, FormControlLabel, Switch } from "@material-ui/core";
import React, {Fragment, useEffect, useState} from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import { TabProps } from "../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { YouthDto } from "../../youths/dto/YouthDto";
import { CaseDto } from "../dtos/CaseDto";
import CaseSummaryList from "./case-summary-list/CaseSummaryList";
import {useGetYouthCases} from "../../youths/hooks/youthHooks";
import {getProviderId} from "../../authentication/state/authenticationActions";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";

interface Props {
  cases: CaseDto[];
  isLoading: boolean;
  showFilters?: boolean;
  youth?: YouthDto;
  onCreateClick(): void;
}

export const casesTabName = "cases-tab";

const CasesSummaryTab: React.FC<Props> = ({ youth, cases, isLoading, showFilters, onCreateClick }) => {
  //#region State
  const [showMyProviderOnly, setShowMyProviderOnly] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  //#endregion

  const [getYouthCases, , youthCasesRequestState] = useGetYouthCases();
  const [casesToDisplay, setCasesToDisplay] = useState<CaseDto[]>([]);

  const providerId = getProviderId();

  useEffect(() => {
    if(!youth) {
      setCasesToDisplay(cases);
    } else {
      getYouthCases(youth.id)
        .then(data => setCasesToDisplay(data || []));
    }
  }, [youth, cases, getYouthCases])

  const casesLoading = isLoading || youthCasesRequestState.isLoading;

  return (
    <Fragment>
      <Box display="flex">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <AddButton text="Start New Case" variant="contained" color="primary" onClick={onCreateClick} disabled={isLoading} />
        </WriteAccessLevelWrapper>
        <Box marginLeft="auto">
          {showFilters && (
            <Fragment>
              <ProviderAccessWrapper>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showMyProviderOnly}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowMyProviderOnly(e.currentTarget.checked)}
                      disabled={isLoading}
                      color="primary"
                    />
                  }
                  label="Cases for My Provider Only"
                />
              </ProviderAccessWrapper>
              <FnAccessWrapper>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showDeleted}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowDeleted(e.currentTarget.checked)}
                      disabled={isLoading}
                      color="primary"
                    />
                  }
                  label="Show Deleted"
                />
              </FnAccessWrapper>
            </Fragment>
          )}
        </Box>
      </Box>
      <Box marginTop="12px">
        <CaseSummaryList cases={casesToDisplay.filter(x => !showMyProviderOnly || providerId === x.program.providerId).filter((x) => showDeleted || !x.isDeleted) || []} isLoading={casesLoading} />
      </Box>
    </Fragment>
  );
};

export default CasesSummaryTab;

export const createCaseSummaryTab = (props: Props): TabProps => ({
  label: "Cases",
  value: casesTabName,
  content: <CasesSummaryTab {...props} />,
});
