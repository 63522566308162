import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React, { Fragment } from "react";

export interface AlertLabelProps
  extends DefaultComponentProps<TypographyTypeMap<any>> {
  hide?: boolean;
  label: React.ReactNode;
  style?: React.CSSProperties;
  variant?: "outlined" | "solid";
}

interface Props extends AlertLabelProps {
  color: string;
}

const AlertLabel: React.FC<Props> = ({
  hide,
  label,
  color,
  style,
  variant = "solid",
  ...props
}) => {
  if (hide) return <></>;

  return (
    <Typography
      {...props}
      style={{
        padding: "0px 12px",
        fontWeight: "bold",
        borderRadius: "12px",
        border: variant === "outlined" ? `1px solid ${color}` : "",
        color: variant === "outlined" ? color : "white",
        background: variant === "outlined" ? "" : color,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
    >
      {label}
    </Typography>
  );
};

export default AlertLabel;
