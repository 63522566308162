import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { Fragment } from "react";
import {
  DemographicsFamilyWeeklyIncomeEnum,
  demographicsFamilyWeeklyIncomeTextMap,
} from "../../enums/DemographicsFamilyWeeklyIncomeEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  weeklyIncome?: DemographicsFamilyWeeklyIncomeEnum;
  defaultValue?: string;
}

const DemographicsFamilyWeeklyIncomeLabel: React.FC<Props> = ({
  weeklyIncome,
  defaultValue,
  ...props
}) => {
  if (weeklyIncome == null && defaultValue == null)
    return <Fragment></Fragment>;
  const value =
    weeklyIncome == null
      ? defaultValue
      : demographicsFamilyWeeklyIncomeTextMap[weeklyIncome];

  return <Typography {...props}>{value}</Typography>;
};

export default DemographicsFamilyWeeklyIncomeLabel;
