import React from "react";
import { TextField } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";
import { TextFieldProps } from "@material-ui/core";
import MaskedInput from "react-text-mask";
interface Props {
  control: Control<any>;
  name: string;
}
interface PhoneTextMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const SSNTextMask = (props: PhoneTextMaskProps) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

const ControlledSSNInput: React.FC<Props & TextFieldProps> = ({
  control,
  ...props
}) => {
  const { name } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          variant="outlined"
          fullWidth
          {...field}
          autoComplete="off"
          {...props}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          InputProps={{
            inputComponent: SSNTextMask as any,
          }}
        />
      )}
    />
  );
};

export default ControlledSSNInput;
