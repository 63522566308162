import { ProviderDto } from "./ProviderDto";

export interface ProviderFormDto {
  centerId: string;
  name: string;
  shortName: string;
  abbreviation: string;
}

export const createProviderFormDto = (dto?: ProviderDto): ProviderFormDto => {
  if (dto) {
    const { centerId, name, shortName, abbreviation } = dto;
    return { centerId, name, shortName, abbreviation };
  }

  return { centerId: "", name: "", shortName: "", abbreviation: "" };
};
