import { Box, Paper, Typography } from "@material-ui/core";
import React, { createContext, Fragment, useContext } from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";

import { createCaseDetailsSection } from "../components/case-detail-sections/CaseDetailsSection";
import CaseDetailAdditionalActions from "../components/CaseDetailAdditionalActions";
import CaseDetailNotifications from "../components/CaseDetailNotifications";

import {
  CaseDetailPageProps,
  useCaseDetailPage,
} from "../hooks/useCaseDetailPage";
import { createCaseSnapGroupTab } from "../components/case-detail-sections/CaseSnapGroupsSection";
import { ProgramTypeIdEnum } from "../../../app/enums/ProgramTypeIdEnum";
import CaseFollowUpsSection, {
  caseFollowUpsSectionName,
} from "../components/case-detail-sections/CaseFollowUpsSection";
import { createCaseContactTab } from "../components/case-detail-sections/supplemental-sections/CaseContactsSection";
import CaseDialogs from "../components/CaseDialogs";
import { createCaseSupplementalSection } from "../components/case-detail-sections/CaseSupplementalSection";
import { createCaseSessionsSection } from "../components/case-detail-sections/CaseSessionsSection";
import { createServicePlanSection } from "../components/case-detail-sections/ServicePlanSection";
import { createCaseSatisfactionSurveySection } from "../components/case-detail-sections/CaseSatisfactionSurveySection";
import StyledLink from "../../../app/components/StyledLink";
import { useLocation } from "react-router-dom";
import EditLinkButton from "../../../app/components/buttons/EditLinkButton";
import { Skeleton } from "@material-ui/lab";
import ErrorAlert from "../../../app/components/ErrorAlert";

const CaseDetailPageContext = createContext<CaseDetailPageProps>(undefined!);

export const useCaseDetailPageContext = () => useContext(CaseDetailPageContext);

const CaseDetailPage = () => {
  const caseDetailPage = useCaseDetailPage();
  const {
    case: { caseResult, getCaseRequestState },
    youth: { handleSetYouthDjjIdNumberClicked },
    canEditYouth,
    tabIndex,
    servicePlan,
    handleChangeTabIndex,
    handleFollowUpDataChanged,
    handleServicePlanDataChanged,
  } = caseDetailPage;

  const location = useLocation();
  const { pathname } = location;
  const tabs: TabProps[] = [createCaseDetailsSection()];

  if (caseResult && caseResult?.program.programType.canApplyCaseContacts)
    tabs.push(createCaseContactTab());

  if (
    caseResult &&
    caseResult.snapGroups &&
    [ProgramTypeIdEnum.SnapYouthJustice, ProgramTypeIdEnum.Snap].includes(
      caseResult?.program.programType.id as ProgramTypeIdEnum
    )
  )
    tabs.push(createCaseSnapGroupTab());

  if (caseResult && caseResult.program.programType.canApplyCaseSessions)
    tabs.push(createCaseSessionsSection());

  tabs.push(createCaseSupplementalSection());

  if (caseResult) {
    tabs.push(
      createServicePlanSection(
        caseResult,
        servicePlan?.requestState!,
        handleServicePlanDataChanged,
        servicePlan?.servicePlan,
      )
    );
  }

  if (caseResult?.dischargeInfo != null)
    tabs.push(createCaseSatisfactionSurveySection());

  if (caseResult?.dischargeInfo) {
    tabs.push({
      value: caseFollowUpsSectionName,
      label: "Follow Ups",
      content: (
        <>
          <CaseFollowUpsSection
            caseDto={caseResult!}
            onChangeFollowUpData={handleFollowUpDataChanged}
            isLoading={false}
          />
        </>
      ),
    });
  }

  return (
    <Fragment>
      { getCaseRequestState.error && <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <div><Typography variant={"h3"}>Error Loading Case</Typography></div>
          <p>
              We encountered an unexpected error attempting to load the requested case.
              <br />
              Please try again in a few minutes. If this error persists please contact support.
          </p>
          <p>
              <ErrorAlert error={getCaseRequestState.error.message} />
          </p>
      </Box>}
      { !getCaseRequestState.error && <CaseDetailPageContext.Provider value={caseDetailPage}>
        <CaseDialogs />

        <Box height="100%">
          <Box marginBottom="12px" className={"no-print"}>
            <BackButton route="/cases" text="Back to cases" />
          </Box>
          <Header
            title={`Case ${caseResult?.caseNumber} - ${caseResult?.youth.firstName} ${caseResult?.youth.lastName}`}
            actions={
              <div className={"no-print"}>
                <CaseDetailNotifications />
                <CaseDetailAdditionalActions />
              </div>
            }
            isLoading={getCaseRequestState.isLoading}
            secondaryActions={
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  marginBottom="8px"
                  marginTop="-16px"
                >
                  <Typography variant="h5" className="mr-1">
                    Youth ID:{" "}
                  </Typography>

                  {getCaseRequestState.isLoading ? (
                    <Typography variant="h5">
                      <Skeleton animation="wave" width={200} />
                    </Typography>
                  ) : (
                    <Typography variant="h5">
                      <StyledLink
                        to={{
                          pathname: `/youths/${caseResult?.youth.id}`,
                          state: {
                            prevLocation: pathname,
                            text: "Back to case",
                          },
                        }}
                      >
                        {caseResult?.youth.referenceNumber}
                      </StyledLink>
                    </Typography>
                  )}

                  <Typography
                    variant="h5"
                    className="mr-1"
                    style={{ marginLeft: "28px" }}
                  >
                    DJJ ID:{" "}
                  </Typography>
                  {getCaseRequestState.isLoading ? (
                    <Typography variant="h5">
                      <Skeleton animation="wave" width={200} />
                    </Typography>
                  ) : caseResult?.youth.djjIdNumber !== undefined ? (
                    <Typography variant="h5">
                      {caseResult?.youth.djjIdNumber}
                    </Typography>
                  ) : (
                    <Box display="flex" alignContent="center">
                      <Typography variant="h5">
                        {canEditYouth && (
                          <EditLinkButton
                            onClick={handleSetYouthDjjIdNumberClicked}
                          >
                            Set DJJ ID
                          </EditLinkButton>
                        )}

                        {!canEditYouth && "Not Set"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            }
          />
          <Paper variant="outlined" style={{ height: "86%" }}>
            <TabContainer
              index={tabIndex}
              onIndexChange={handleChangeTabIndex}
              tabs={tabs}
              disabled={getCaseRequestState.isLoading}
            />
          </Paper>
        </Box>
      </CaseDetailPageContext.Provider>}
    </Fragment>
  );
};

export default CaseDetailPage;
