import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import FilterList from "../../../app/components/FilterList";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { ScreeningSearchParamsDto } from "../dtos/ScreeningSearchParamsDto";
import { waitlistStatusTextMap } from "../../waitlists/enums/WaitlistStatusEnum";

const formatDate = (date: string) => {
  return DateTime.fromJSDate(new Date(`${date}T00:00`)).toFormat("MM-dd-yyyy");
};

interface Props {
  params: ScreeningSearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  providerLocations: { [key: string]: LocationDto[] };
  providerPrograms: { [key: string]: ProgramDto[] };
  onSearch(dto: ScreeningSearchParamsDto): void;
}

const ScreeningFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  providerLocations,
  providerPrograms,
  onSearch,
}) => {
  const [programs, setPrograms] = useState<ProgramDto[]>([]);
  const [locations, setLocations] = useState<LocationDto[]>([]);
  
  const providerId = getProviderId();
  const additionalExclusions = providerId !== fnyfsProviderId ? ['providerId'] : [];

  const handleClearParams = () => {
    return new ScreeningSearchParamsDto({ previous: params });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "screeningNumber":
          return `Screening #: ${value}`;
        case "providerId":
          return `Provider: ${providers.find((x) => x.id === value)?.name}`;
        case "youthFirstName":
          return `First Name: ${value}`;
        case "youthLastName":
          return `Last Name: ${value}`;
        case "screeningAtStart":
            return `Screening Date Start: ${formatDate(value)}`;
        case "screeningAtEnd":
            return `Screening Date End: ${formatDate(value)}`;
        case "assignedToWaitList":            
            return "Assigned to Wait List: Yes";
        case "waitListStatus":
            return `Status: ${waitlistStatusTextMap[value]}`;
        case "waitListProgramId":
          return `Program: ${programs.find((x) => x.id === value)?.name}`;
        case "waitListLocationId":
          return `Location: ${locations.find((x) => x.id === value)?.name}`;        
        case "activeOnly":
          return "Active Only";
        case "includeDeleted":
          return "Include Deleted";
      }
      return "";
    },
    [providers, programs, locations]
  );

  const ordering = [
    "screeningNumber",
    "providerId",
    "youthFirstName",
    "youthLastName",    
    "screeningAtStart",
    "screeningAtEnd",
    "assignedToWaitList",
    "waitListStatus",
    "waitListProgramId",
    "waitListLocationId",
    "includeDeleted",
  ];

  useEffect(() => {
    setLocations(Object.values(providerLocations).flatMap((x) => x));
  }, [providerLocations, setLocations]);

  useEffect(() => {
    setPrograms(Object.values(providerPrograms).flatMap((x) => x));
  }, [providerPrograms, setPrograms]);

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
      exclude={additionalExclusions}
    />
  );
};

export default ScreeningFilterList;
