import { invoicingAgent } from "../../app/api/agent";
import { DeliverableRateDto } from "./dtos/DeliverableRateDto";
import { DeliverableRateFormDto } from "./dtos/DeliverableRateFormDto";

interface ChangeDeliverableRateRequestParams {
  programTypeDeliverableId: string;
  contractId: string;
  dto: DeliverableRateFormDto;
}

export const getDeliverableRatesRequest = () =>
  invoicingAgent.getRequest<{ [key: string]: DeliverableRateDto[] }>(
    "api/deliverableRates"
  );

export const changeDeliverableRateRequest = ({
  programTypeDeliverableId,
  contractId,
  dto,
}: ChangeDeliverableRateRequestParams) =>
  invoicingAgent.postRequest(`api/deliverableRates/${programTypeDeliverableId}/${contractId}/change`, dto);

export const deleteDeliverableRateRequst = (id: string) =>
  invoicingAgent.deleteRequest<any>(`api/deliverableRates/${id}`);
