import { Avatar, Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import DateLabel from "../../../../app/components/labels/DateLabel";
import YesNoLabel from "../../../../app/components/labels/YesNoLabel";
import StyledLink from "../../../../app/components/StyledLink";
import { TabProps } from "../../../../app/components/TabContainer";
import { CaseSnapGroupDto } from "../../../snap/dtos/CaseSnapGroupDto";
import GroupIcon from "@material-ui/icons/Group";
import { useCaseDetailPageContext } from "../../pages/CaseDetailPage";

export const caseSnapGroupsSectionName = "case-snap-group-section";

const CaseSnapGroupsSection = () => {
  //#region State
  const location = useLocation();
  const {
    case: { caseResult },
  } = useCaseDetailPageContext();
  const caseSnapGroups: CaseSnapGroupDto[] = caseResult?.snapGroups || [];
  //#endregion

  const groupByCycle = (caseSnapGroups: CaseSnapGroupDto[]) => {
    return caseSnapGroups.reduce<{ [key: string]: CaseSnapGroupDto[] }>((result, value) => {
      if (result[value.groupCycle.id]) {
        result[value.groupCycle.id].push(value);
      } else {
        result[value.groupCycle.id] = [value];
      }
      return result;
    }, {});
  };

  const sortedSnapGroups = (caseSnapGroups: CaseSnapGroupDto[]) => {
    return caseSnapGroups.sort((a, b) => {
      if (a.group.groupHeldOn > b.group.groupHeldOn) {
        return -1;
      } else if (a.group.groupHeldOn > b.group.groupHeldOn) {
        return 1;
      }

      return a.curriculum.order > b.curriculum.order ? -1 : 1;
    });
  };

  const sortedSnapCycles = () => {
    return Object.entries(groupByCycle(caseSnapGroups)).sort(([, a], [, b]) =>
      new Date(a[0].groupCycle.startedOn) > new Date(b[0].groupCycle.startedOn) ? -1 : 1
    );
  };

  const createCaseSnapGroupListItem = (group: CaseSnapGroupDto) => {
    return (
      <Fragment key={group.id}>
        <ListItem>
          <ListItemIcon>
            <Avatar>
              <GroupIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            secondary={
              <Fragment>
                <Typography component="span">
                  <DateLabel date={group.group.groupHeldOn} /> ({group.curriculum.description})
                </Typography>
                <br />
                <Typography component="span">
                  Fidelity Performed: <YesNoLabel value={group.group.fidelityPerformedOn !== undefined} />
                </Typography>
                <br />
              </Fragment>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
      </Fragment>
    );
  };

  return (
    <Box padding="16px">
      {sortedSnapCycles().map(([key, groups]) => {
        return (
          <Fragment key={key}>
            <Typography variant="h6">
              <StyledLink
                to={{
                  pathname: `/snap-cycles/${key}`,
                  state: {
                    prevLocation: location.pathname,
                    text: "Back to case",
                  },
                }}
              >
                {groups[0].groupCycle.referenceNumber}
              </StyledLink>
            </Typography>
            <Typography component="span">
              Group Cycle Start Date: <DateLabel date={groups[0].groupCycle.startedOn} />
            </Typography>
            <List>{sortedSnapGroups(groups).map(createCaseSnapGroupListItem)}</List>
          </Fragment>
        );
      })}

      {caseSnapGroups.length === 0 && <Typography color="textSecondary">No SNAP Groups Assigned.</Typography>}
    </Box>
  );
};

export default CaseSnapGroupsSection;

export const createCaseSnapGroupTab = (): TabProps => ({
  value: caseSnapGroupsSectionName,
  label: "SNAP Groups",
  content: <CaseSnapGroupsSection />,
});
