import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledYesNoRadioGroup from "../../../app/components/inputs/ControlledYesNoRadioGroup";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { ADBTTEligibilityQuestionnaireFormDto } from "../dtos/ADBTTEligibilityQuestionnaireFormDto";
import { useADBTTEligibilityQuestionnaireForm } from "../hooks/useADBTTEligibilityQuestionnaireForm";

export const adbttEligibilityQuestionnaireModalId =
  "adbtt-eligibility-questionnaire-form-modal";

interface Props {
  onSubmit: (dto: ADBTTEligibilityQuestionnaireFormDto) => Promise<void>;
  requestState: RequestStateDto;
}

const ADBTTEligibilityQuestionnaireFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    state: { shouldCompleteADBTT },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useADBTTEligibilityQuestionnaireForm(onSubmit);
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === adbttEligibilityQuestionnaireModalId}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          ADBTT Eligibility Questionnaire
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledYesNoRadioGroup
                control={control}
                name="hasDomesticBatteryChargeAgainstCaregiver"
                disabled={isLoading}
                label="Does the youth have a current or past adolescent domestic battery-related charge on a parent or primary caregiver who has been in the role of primary caregiver a minimum of 12 months? *"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledYesNoRadioGroup
                control={control}
                name="hasYouthToParentAggressionIndicators"
                disabled={isLoading}
                label="Are there any indications of youth-to-parent aggression?*"
              />
            </Grid>
            {shouldCompleteADBTT && (
              <Grid item md={12} xs={12}>
                <Typography>
                  Please complete the Adolescent Domestic Battery Typology Tool.
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ADBTTEligibilityQuestionnaireFormDialog;
