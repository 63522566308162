import React from "react";
import { useFollowUpsListPage } from "../hooks/useFollowUpsListPage";
import Header from "../../../app/components/Header";
import FollowUpFilter from "../components/FollowUpFilter";
import FollowUpFilterList from "../components/FollowUpFilterList";
import FilterButton from "../../../app/components/buttons/FilterButton";
import FollowUpList from "../components/FollowUpList";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { CaseFollowUpSummaryDto } from "../dtos/FollowUpDto";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import PrintIcon from "@material-ui/icons/Print";
import WhiteButton from "../../../app/components/buttons/WhiteButton";
import InfoTooltip from "../../../app/components/InfoTooltip";
import {PrintMultipleFollowUpsDialog} from "../components/PrintMultipleFollowUpsDialog";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import {FollowUpIntervalSelector} from "../components/FollowUpIntervalSelector";

export const FollowUpListPage: React.FC = () => {
  const { state, handlers } = useFollowUpsListPage();

  const {
    filterAnchorEl,
    params,
    providers,
    programs,
    locations,
    programTypes,
    staff,
    followUpSummaryList,
    followUpSummaryListRequestStatus,
    getFollowUpDocumentRequestState,
    getBlankFollowUpDocumentRequestState,
    getFollowUpExportFileRequestStatus,
  } = state;

  const {
    handleCloseFilter,
    handleSearch,
    handleOpenFilterClicked,
    handlePageChange,
    handleSort,
    handlePrintFollowUp,
    handlePrintMultipleFollowUpsClicked,
    handleExportListClicked,
    handleIntervalSelectionsChanged,
  } = handlers;

  const printInProgress =
    getBlankFollowUpDocumentRequestState.isLoading ||
    getFollowUpDocumentRequestState.isLoading;

  return (
    <div>
      <PrintMultipleFollowUpsDialog params={params} />
      <RequestErrorAlert
        requests={[
          followUpSummaryListRequestStatus,
          getBlankFollowUpDocumentRequestState,
          getFollowUpDocumentRequestState,
          getFollowUpExportFileRequestStatus,
        ]}
      />
      <Header
        title="Follow Ups"
        actions={
          <>
            <ProviderAccessWrapper>
              <InfoTooltip title={"Please click here if you would like to print the forms expected for the selected follow-ups."} />
              <WhiteButton
                startIcon={<PrintIcon />}
                title="Print Available Forms"
                className="mr-1"
                onClick={() => handlePrintMultipleFollowUpsClicked()}
                disabled={printInProgress}
              >
                Print Available Forms
              </WhiteButton>
            </ProviderAccessWrapper>
            <WhiteButton
              startIcon={<PrintIcon />}
              title="Print Blank Follow-Up Form"
              className="mr-1"
              onClick={() => handlePrintFollowUp()}
              disabled={printInProgress}
            >
              Print Blank Form
            </WhiteButton>
            <WhiteButton
              title={"Export List"}
              className="mr-1"
              onClick={() => handleExportListClicked()}
              disabled={getFollowUpExportFileRequestStatus.isLoading}
            >
              Export
            </WhiteButton>
            <FilterButton
              title="Filter List"
              onClick={handleOpenFilterClicked}
              disabled={followUpSummaryListRequestStatus.isLoading}
            />

            <FollowUpFilter
              anchorEl={filterAnchorEl}
              onClose={handleCloseFilter}
              params={params}
              onSearch={handleSearch}
              providers={providers}
              programs={programs}
              programTypes={programTypes}
              locations={locations}
              staff={staff}
            />
          </>
        }
        secondaryActions={
          <>
            <FollowUpFilterList
              params={params}
              onSearch={handleSearch}
              providers={providers}
              locations={locations}
              isLoading={followUpSummaryListRequestStatus.isLoading}
              programs={programs}
              programTypes={programTypes}
              staff={staff}
            />
            <FollowUpIntervalSelector
              includeThirtyDay={params.includeThirtyDay}
              includeSixtyDay={params.includeSixtyDay}
              includeNinetyDay={params.includeNinetyDay}
              onChange={handleIntervalSelectionsChanged}
            />
          </>
        }
      />
      <FollowUpList
        params={params}
        results={
          followUpSummaryList ||
          new PaginationResultDto<CaseFollowUpSummaryDto>()
        }
        isLoading={followUpSummaryListRequestStatus.isLoading}
        onPageChange={handlePageChange}
        onSort={handleSort}
        onPrintCaseFollowUpClick={handlePrintFollowUp}
        printInProgress={printInProgress}
      />
    </div>
  );
};
