import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  createRemoveReferralFormDto,
  RemoveReferralFormDto,
} from "../dtos/RemoveReferralFormDto";

const schema = Yup.object().shape({
  removalReason: Yup.string().label("Removal Reason").required(),
});

export const useRemoveReferralForm = (
  onSubmit: (dto: RemoveReferralFormDto) => Promise<void>
) => {
  //#region State
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: createRemoveReferralFormDto(),
    mode: "all",
    resolver: yupResolver(schema),
  });
  //#endregion

  //#region Handlers
  const clearForm = () => {
    reset(createRemoveReferralFormDto());
  };
  const handleOnSubmit = handleSubmit(async (dto: RemoveReferralFormDto) => {
    await onSubmit(dto);
  });

  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
