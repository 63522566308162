import * as Yup from "yup";

export class NirvanaExportSearchParamsDto {
  providerIds: string[];
  startOn: string;
  endOn: string;

  constructor() {
    this.providerIds = [];
    this.startOn = "";
    this.endOn = "";
  }
}

export class NirvanaExportSearchFormDto {
  providerIds: string[];
  startOn: string;
  endOn: string;

  constructor(params?: NirvanaExportSearchParamsDto) {
    this.providerIds = params?.providerIds || [];
    this.startOn = params?.startOn || "";
    this.endOn = params?.endOn || "";
  }

  public static getSchema() {
    return Yup.object().shape({
      startOn: Yup.string().label("Start Date").required(),
      endOn: Yup.string()
        .label("End Date")
        .required()
        .test(
          "date-check",
          "End Date cannot be before Start Date",
          function (value) {
            if (value == null || value === "") return true;

            if (this.parent.startOn == null || this.parent.startOn === "")
              return true;

            return new Date(this.parent.startOn) <= new Date(value);
          }
        ),
    });
  }
}
