import { useRequest } from "../../../app/hooks/useRequest";
import {
  createSnapInSchoolsSatisfactionSurveyRequest,
  deleteSnapInSchoolsSatisfactionSurveyRequest,
  updateSnapInSchoolsSatisfactionSurveyRequest,
} from "../SnapInSchoolsSatisfactionSurveyRequests";

export const useCreateSnapInSchoolsSatisfactionSurvey = () =>
  useRequest(createSnapInSchoolsSatisfactionSurveyRequest);
export const useUpdateSnapInSchoolsSatisfactionSurvey = () =>
  useRequest(updateSnapInSchoolsSatisfactionSurveyRequest);
export const useDeleteSnapInSchoolsSatisfactionSurvey = () =>
  useRequest(deleteSnapInSchoolsSatisfactionSurveyRequest);
