import { SelectOption } from "../../app/components/inputs/ControlledSelect";

export enum SnapInSchoolsSatisfactionSurveyLikertEnum {
  Dissatisfied = "Dissatisfied",
  SomewhatDissatisfied = "SomewhatDissatisfied",
  Undecided = "Undecided",
  SomewhatSatisfied = "SomewhatSatisfied",
  Satisfied = "Satisfied",
}

export const SnapInSchoolsSatisfactionSurveyLikertEnumTextMap: {
  [key: string]: string;
} = {
  [SnapInSchoolsSatisfactionSurveyLikertEnum.Dissatisfied]: "Dissatisfied",
  [SnapInSchoolsSatisfactionSurveyLikertEnum.SomewhatDissatisfied]:
    "Somewhat Dissatisfied",
  [SnapInSchoolsSatisfactionSurveyLikertEnum.Undecided]: "Undecided",
  [SnapInSchoolsSatisfactionSurveyLikertEnum.SomewhatSatisfied]:
    "Somewhat Satisfied",
  [SnapInSchoolsSatisfactionSurveyLikertEnum.Satisfied]: "Satisfied",
};

export const SnapInSchoolsSatisfactionSurveyLikertEnumOptions: SelectOption[] =
  [
    { label: "Select Response", value: "" },
    ...Object.values(SnapInSchoolsSatisfactionSurveyLikertEnum).map(
      (value) => ({
        value,
        label: SnapInSchoolsSatisfactionSurveyLikertEnumTextMap[value],
      })
    ),
  ];

export enum SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum {
  Teachers = "Teachers",
  Peers = "Peers",
  FamilyMembers = "FamilyMembers",
  DealWithConflict = "DealWithConflict",
  IdentifyTriggers = "IdentifyTriggers",
  CalmThemselves = "CalmThemselves",
}

export const SnapInSchoolsSatisfactionSurveyHelpsPeopleEnumTextMap: {
  [key: string]: string;
} = {
  [SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum.Teachers]:
    "Get along better with teachers",
  [SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum.Peers]:
    "Get along better with peers",
  [SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum.FamilyMembers]:
    "Get along better with family members",
  [SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum.DealWithConflict]:
    "Be able to deal with conflict",
  [SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum.IdentifyTriggers]:
    "Be able to identify their triggers",
  [SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum.CalmThemselves]:
    "Calm themselves when upset",
};

export enum SnapInSchoolsSatisfactionSurveySkillEnum {
  IdentifyTriggers = "IdentifyTriggers",
  UnderstandPhysiologicalChanges = "UnderstandPhysiologicalChanges",
  StopAndCalmingStrategies = "StopAndCalmingStrategies",
  UnderstandingHardThoughts = "UnderstandingHardThoughts",
  CoolThoughtsAndCoping = "CoolThoughtsAndCoping",
  GeneratingEffectivePlans = "GeneratingEffectivePlans",
  IdentifyingCriticalMoment = "IdentifyingCriticalMoment",
  ConfidenceToTryAPlan = "ConfidenceToTryAPlan",
  AbilityToMakeGoodChoices = "AbilityToMakeGoodChoices",
}

export const SnapInSchoolsSatisfactionSurveySkillEnumTextMap: {
  [key: string]: string;
} = {
  [SnapInSchoolsSatisfactionSurveySkillEnum.IdentifyTriggers]:
    "Identify Triggers",
  [SnapInSchoolsSatisfactionSurveySkillEnum.UnderstandPhysiologicalChanges]:
    "Understanding physiological changes (Body Cues) when angry or upset",
  [SnapInSchoolsSatisfactionSurveySkillEnum.StopAndCalmingStrategies]:
    "Stops and calming strategies",
  [SnapInSchoolsSatisfactionSurveySkillEnum.UnderstandingHardThoughts]:
    "Understanding Hard Thoughts",
  [SnapInSchoolsSatisfactionSurveySkillEnum.CoolThoughtsAndCoping]:
    "Cool thoughts and coping strategies",
  [SnapInSchoolsSatisfactionSurveySkillEnum.GeneratingEffectivePlans]:
    "Generating effective plans",
  [SnapInSchoolsSatisfactionSurveySkillEnum.IdentifyingCriticalMoment]:
    "Identifying the critical moment",
  [SnapInSchoolsSatisfactionSurveySkillEnum.ConfidenceToTryAPlan]:
    "Confidence to try a plan",
  [SnapInSchoolsSatisfactionSurveySkillEnum.AbilityToMakeGoodChoices]:
    "Ability to make good choices",
};
