import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum HeardAboutEnum {
  WordOfMouth = "WordOfMouth",
  SocialMedia = "SocialMedia",
  PrintedMaterial = "PrintedMaterial",
  WebSearch = "WebSearch",
  Billboard = "Billboard",
  Other = "Other",
  ReferralSource = "ReferralSource",
  // AgencyCommunityAffiliation = "AgencyCommunityAffiliation",
}

export const heardAboutTextMap: { [key: string]: string } = {
  [HeardAboutEnum.WordOfMouth]: "Word of Mouth",
  [HeardAboutEnum.SocialMedia]: "Social Media",
  [HeardAboutEnum.PrintedMaterial]: "Printed Material",
  [HeardAboutEnum.WebSearch]: "Web Search",
  [HeardAboutEnum.Billboard]: "Billboard",
  [HeardAboutEnum.ReferralSource]: "Referral Source",
  [HeardAboutEnum.Other]: "Other",
};

export const getHeardAboutOptions = (
  defaultOption: string = "Select How You Heard About Us"
): SelectOption[] => [
  { value: "", label: defaultOption },
  ...Object.keys(HeardAboutEnum).map((x) => ({
    value: x,
    label: heardAboutTextMap[x],
  })),
];
