import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import InvoiceCorrectionConfirmationCheckbox from "../../../app/components/InvoiceCorrectionConfirmationCheckbox";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { CaseUpdateFormDto } from "../dtos/CaseFormDto";
import { useUpdateCaseForm } from "../hooks/useUpdateCaseForm";
import UpdateCaseIntakeFormFields from "../../intakes/components/UpdateCaseIntakeFormFields";

export const caseFormDialogId = "update-case-form-dialog";

interface Props {
  onSubmit(dto: CaseUpdateFormDto): Promise<void>;
  requestState: RequestStateDto;
  caseDto: CaseDetailDto;
}

const CaseFormDialog: React.FC<Props> = ({ onSubmit, requestState, caseDto }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { form, formState, handlers, state } = useUpdateCaseForm(onSubmit, caseDto);

  const { isValid, clearForm } = formState;
  
  const { handleSubmit, handleToggleIsConfirmed, handleStaffAdded, handleStaffRemoved } = handlers;
  
  const {
    staffMembers,
    screenings,
    getProviderProgramsRequestState,
    getProviderStaffMemberRequestState,
    getYouthScreeningsRequestState,
    getSchoolNamesRequestState,
    schoolNames,
    isConfirmed,
  } = state;
  
  const { isLoading } = requestState;
  //#endregion

  const renderContent = () => {
    return (
      <form onSubmit={handleSubmit} id="discharge-form">
        <Grid container spacing={2}>
          <UpdateCaseIntakeFormFields
            form={form}
            program={caseDto.program}
            staffMembers={staffMembers}
            screenings={screenings}
            schoolNames={schoolNames}
            schoolNamesAreLoading={getSchoolNamesRequestState.isLoading}
            staffMembersAreLoading={getProviderStaffMemberRequestState.isLoading}
            disabled={requestState.isLoading || getYouthScreeningsRequestState.isLoading}
            screeningsAreLoading={getYouthScreeningsRequestState.isLoading}
            isLegacy={caseDto.isLegacy}
            onStaffMemberAdded={handleStaffAdded}
            onStaffMemberRemoved={handleStaffRemoved} 
        />
        </Grid>
      </form>
    );
  };

  //#region UI Helpers

  //#endregion

  //#region Handlers
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === caseFormDialogId} maxWidth="md" fullWidth>
      <DialogTitle>
        Update Intake Information
        <RequestErrorAlert
          requests={[
            getProviderProgramsRequestState,
            requestState,
            getProviderStaffMemberRequestState,
            getYouthScreeningsRequestState,
            getSchoolNamesRequestState,
          ]}
        />
      </DialogTitle>

      <DialogContent>{renderContent()}</DialogContent>

      <Divider style={{marginLeft:"10px", marginRight:"10px"}} />

      <DialogActions>
        <Box       
          margin={2}    
          width="100%"
          display="flex" 
          justifyContent="space-between" 
          alignItems="center">

            <InvoiceCorrectionConfirmationCheckbox 
              isConfirmed={isConfirmed} 
              onCheck={handleToggleIsConfirmed} 
              disabled={requestState.isLoading}
              bold={true} />
              
            <Box>
              <SpinnerButton 
                content="Cancel" 
                showSpinner={false} 
                type="button" 
                onClick={handleCloseModal} 
                disabled={isLoading} />

              <SpinnerButton
                showSpinner={isLoading}
                content="Submit"
                color="primary"
                type="submit"
                form="discharge-form"
                disabled={!isValid || isLoading || !isConfirmed} />
            </Box>

        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CaseFormDialog;
