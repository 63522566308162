import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { demographicSchema } from "../components/DemographicFormFields";
import {
  createDemographicFormDto,
  DemographicFormDto,
} from "../dtos/DemographicFormDto";

export const useDemographicForm = (
  onSubmit: (dto: DemographicFormDto) => Promise<any>,
  caseResult?: CaseDetailDto
) => {
  //#region State
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: createDemographicFormDto(caseResult?.demographic),
    resolver: yupResolver(demographicSchema),
    mode: "all",
  });

  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit(async (dto: DemographicFormDto) => {
    await onSubmit(dto);
  });

  const clearForm = () => {
    reset(createDemographicFormDto(caseResult?.demographic));
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(createDemographicFormDto(caseResult?.demographic));
  }, [caseResult?.demographic, reset]);

  useEffect(() => {
    setValue("fundingSource", caseResult?.program.fundingSource || "");
  }, [caseResult?.program, setValue]);
  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
