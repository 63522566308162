import { Fragment } from "react";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import DateLabel from "../../../app/components/labels/DateLabel";
import { ReferrerDto } from "../../referrals/dtos/RefererDto";
import { ScreeningPresentingProblemDto } from "../../screening-presenting-problems/dtos/ScreeningPresentingProblemDto";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import { ProviderSummaryDto } from "../../provider/dtos/ProviderSummaryDto";

export interface ScreeningDto {
  id: string;
  referenceNumber: string;
  referredBy: ReferrerDto;
  screeningAt: Date;
  provider: ProviderSummaryDto;
  youthId: string;
  zipCode: string;
  screeningPresentingProblems: ScreeningPresentingProblemDto[];
  isCinsFinsEligible: boolean;
  staffCompletingScreening: StaffMemberDto;
  isDvRespite: boolean;
  deletedAt?: Date;
}

export const screeningToOption = (dto: ScreeningDto): SelectOption => ({
  label: (
    <Fragment>
      {dto.referenceNumber} - <DateLabel date={dto.screeningAt} />
    </Fragment>
  ),
  value: dto.id,
});

export const screeningsToOptions = (
  dtos: ScreeningDto[],
  filterNonDvRespite: boolean = false,
  defaultLabel: string = "Select Screening"
): SelectOption[] => {
  const options = dtos
    .filter((x) => !filterNonDvRespite || x.isDvRespite)
    .map((x) => screeningToOption(x));
  options.unshift({ value: "", label: defaultLabel });
  return options;
};
