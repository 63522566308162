import { dateToFormString } from "../../../app/helpers";
import { CinsPetitionDto } from "./CinsPetitionDto";

export interface CinsPetitionFormDto {
  openedOn: string;
  closedOn: string;
  reasonForPetition?: string;
  petitionType: string;
  notes: string;
}

export const createCinsPetitionFormDto = (
  dto?: CinsPetitionDto
): CinsPetitionFormDto => {
  if (dto) {
    const { openedOn, closedOn, reasonForPetition } = dto;

    return {
      ...dto,
      reasonForPetition: reasonForPetition || "",
      openedOn: dateToFormString(new Date(openedOn)),
      closedOn: closedOn != null ? dateToFormString(new Date(closedOn)) : "",
    };
  }

  return {
    openedOn: "",
    closedOn: "",
    reasonForPetition: "",
    notes: "",
    petitionType: "",
  };
};
