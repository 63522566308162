import { Grid, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { ProgramLocationDto } from "../../../locations/dtos/ProgramLocationDto";
import { ProgramDto } from "../../../programs/dtos/ProgramDto";
import { ProviderDetailDto } from "../../dtos/ProviderDetailDto";
import LoadingProgramList from "../LoadingProgramList";
import ProviderProgramItem from "./ProviderProgramItem";
interface Props {
  provider: ProviderDetailDto;
  onEdit: (program: ProgramDto) => void;
  onCreateLocationClick: (program: ProgramDto) => void;
  onEditLocationClick: (
    program: ProgramDto,
    programLocation: ProgramLocationDto
  ) => void;
  onTerminateProgramLocationClick: (location: ProgramLocationDto) => void;
  isLoading: boolean;
  selectedProgram?: ProgramDto;
}

const ProviderPrograms: React.FC<Props> = ({
  provider,
  onEdit,
  onCreateLocationClick,
  isLoading,
  onTerminateProgramLocationClick,
  selectedProgram,
  onEditLocationClick,
}) => {
  //#region State
  const [openProgramId, setOpenProgramId] = useState("");
  //#endregion

  //#region Handlers
  const handleProgramChanged = (program: ProgramDto) => {
    if (program.id === openProgramId) {
      setOpenProgramId("");
    } else {
      setOpenProgramId(program.id);
    }
  };
  //#endregion

  if (isLoading)
    return (
      <Fragment>
        <LoadingProgramList count={2} />
      </Fragment>
    );

  if (provider?.programs?.length === 0)
    return (
      <Typography>
        There are currently no Programs for this Provider.
      </Typography>
    );

  return (
    <Grid container spacing={1}>
      {provider?.programs.map((program) => (
        <Grid item md={12} xs={12} key={program.id}>
          <ProviderProgramItem
            program={program}
            onChange={() => handleProgramChanged(program)}
            onEdit={() => onEdit(program)}
            onCreateLocationClick={() => onCreateLocationClick(program)}
            onEditLocationClick={onEditLocationClick}
            locations={provider.locations}
            onTerminateProgramLocationClick={onTerminateProgramLocationClick}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProviderPrograms;
