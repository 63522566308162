import { createContext, useContext } from "react";
import { ServicePlanDto } from "./dto/ServicePlanDto";
import { YouthDto } from "../features/youths/dto/YouthDto";

export interface ServicePlanContextProps {
  servicePlan: ServicePlanDto;
  servicePlanUpdated: () => Promise<void>;
  youth: YouthDto;

  participant: ServicePlanParticipantsProps;
  goals: GoalsProps;
  reviews: ReviewsProps;
}

export interface ServicePlanParticipantsProps {
  onAddParticipantClicked: () => Promise<void>;
}

export interface GoalsProps {
  onAddGoalClicked: () => Promise<void>;
  onUpdateGoalClicked: (goalId: string) => Promise<void>;
  onAddGoalInterventionClicked: (goalId: string) => Promise<void>;
  onViewGoalInterventionNotesClicked: (
    goalId: string,
    interventionId: string
  ) => Promise<void>;
  onUpdateGoalInterventionClicked: (
    goalId: string,
    interventionId: string
  ) => Promise<void>;
}

export interface ReviewsProps {
  onAddNewReviewClicked: () => Promise<void>;
  onViewReviewDetailsClicked: (reviewId: string) => Promise<void>;
  onUpdateReviewDetailsClicked: (reviewId: string) => Promise<void>;
}

export const ServicePlanPageContext = createContext<ServicePlanContextProps>(
  undefined!
);

export const useServicePlanPageContext = () =>
  useContext(ServicePlanPageContext);
