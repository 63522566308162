import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SnapGroupCycleSearchParamsDto } from "../dtos/SnapGroupCycleSearchParamsDto";
import { SnapCycleFilterFormDto } from "../dtos/SnapFilterFormDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProgramTypeEnum } from "../../programs/enums/ProgramTypeEnum";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../authentication/constants";

export const useSnapCycleFilter = (
  onSubmit: (dto: SnapGroupCycleSearchParamsDto) => void,
  onClose: () => void,
  onProviderChange: (id: string) => void,
  providerPrograms: { [key: string]: ProgramDto[] },
  providerLocations: { [key: string]: LocationDto[] },
  params: SnapGroupCycleSearchParamsDto
) => {
  //#region State
  const { control, reset, watch, handleSubmit, setValue } = useForm({
    defaultValues: new SnapCycleFilterFormDto(params),
  });

  const { providerId, programId, locationId } = watch();

  const [programs, setPrograms] = useState<ProgramDto[]>([]);  
  const [locations, setLocations] = useState<LocationDto[]>([]);  
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: SnapCycleFilterFormDto) => {
    const newParams: SnapGroupCycleSearchParamsDto = {
      ...dto,
      fidelityRequired: Boolean(dto.fidelityRequired) || undefined,
      sortBy: "",
      page: 0,
      pageSize: params.pageSize,
    };

    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new SnapCycleFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new SnapCycleFilterFormDto(params));
  }, [params, reset]);

  useEffect(() => {
    onProviderChange(providerId);
  }, [providerId, onProviderChange]);

  useEffect(() => {
    if (Object.keys(providerPrograms).length === 0) return;

    const programs = providerPrograms[providerId] || [];

    setPrograms(programs.filter(p => 
      [ProgramTypeEnum.Snap, ProgramTypeEnum.SnapYouthJustice].includes(p.programType.programType)));

    if (programId !== "" && !programs.some((x) => x.id === programId))
      setValue("programId", "");
  }, [providerId, setPrograms, providerPrograms, setValue, programId]);

  useEffect(() => {
    if (Object.keys(providerLocations).length === 0) return;

    let locations = providerLocations[providerId] || [];
    if (programId !== "") {
      const program = programs.find((x) => x.id === programId);
      if (program) {
        const programLocationIds = program.locations.map((x) => x.locationId);

        locations = locations.filter((x) => programLocationIds.includes(x.id));
      }
    }

    if (locationId !== "" && !locations.some((x) => x.id === locationId))
      setValue("locationId", "");

    setLocations(locations);
  }, [
    providerId,
    programId,
    programs,
    locationId,
    setLocations,
    providerLocations,
    setValue,
  ]);
  //#endregion

  useEffect(() => {
    const providerId = getProviderId();

    if (
      providerId != null &&
      providerId !== "" &&
      providerId !== fnyfsProviderId
    ) 
      setValue("providerId", providerId);
  }, [setValue]);
  
  return {
    state: { programs, locations },
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
