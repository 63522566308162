import React from "react";
import { Box, Grid } from "@material-ui/core";
import { PaperMetricContainer } from "../PaperMetricContainer";
import { DataEntryMetricPanel } from "../DataEntryMetricPanel";
import { BarChartMetricPanel } from "../BarChartMetricPanel";
import { DischargeReasonsMetricPanel } from "../DischargeReasonsMetricPanel";
import { FilledBedDaysMetricPanel } from "../FilledBedDaysMetricPanel";
import { useDashboardContext } from "../../DashboardContext";

export const metricIds = [
  "communityCounselingIntakesDataEntry",
  "communityCounselingDischargesDataEntry",
  "communityCounselingDischarges",
  "communityCounselingIntakesAndCarryOver",
  "communityCounselingDischargeReasons",
] as const;

export type CommunityCounselingMetricsDto = {
  [metricId in typeof metricIds[number]]: any;
};

interface Props {
  metrics?: CommunityCounselingMetricsDto;
  isLoading: boolean;
}

export const CommunityCounselingDashboardMetrics: React.FC<Props> = ({
  metrics,
  isLoading,
}) => {
  const { provider } = useDashboardContext();

  return (
    <>
      <Grid item sm={12} md={4}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%" }}
        >
          <Box style={{ marginBottom: 16 }}>
            <PaperMetricContainer>
              <DataEntryMetricPanel
                intakesMetric={metrics?.communityCounselingIntakesDataEntry}
                dischargesMetric={
                  metrics?.communityCounselingDischargesDataEntry
                }
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box>
            <PaperMetricContainer>
              <DischargeReasonsMetricPanel
                metric={metrics?.communityCounselingDischargeReasons}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={8}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%", height: "100%" }}
        >
          <Box style={{ marginBottom: 16 }}>
            <PaperMetricContainer>
              <FilledBedDaysMetricPanel
                metric={metrics?.communityCounselingIntakesAndCarryOver}
                provider={provider}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box style={{ marginBottom: 16 }}>
            <PaperMetricContainer>
              <BarChartMetricPanel
                metric={metrics?.communityCounselingDischarges}
                labelMap={{ discharges: "Discharges" }}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
