import { Box, Divider, Paper, Typography } from "@material-ui/core";
import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import Loader from "../../../app/components/Loader";
import MonthlyReportCardByProviderFilter from "../components/MonthlyReportCardByProviderFilter";
import { useMonthlyReportCardByProviderPage } from "../hooks/useMonthlyReportCardByProviderPage";

const MonthlyReportCardByProviderPage = () => {
  const {
    invoiceYears,
    getInvoiceYearsRequestState,
    getDocumentRequestState,
    handleSearch,
  } = useMonthlyReportCardByProviderPage();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <BackButton route="/routes" />
      </div>
      <Header title="Report Card by Provider" />
      <Box className="my-2">
        <Typography>
          Select a date range and "Download" for an Excel report of metrics for
          all Providers.
        </Typography>
      </Box>
      <Paper
        style={{
          padding: "16px",
          height: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <MonthlyReportCardByProviderFilter
          requestState={getDocumentRequestState}
          invoiceYears={invoiceYears}
          getInvoiceYearsRequestState={getInvoiceYearsRequestState}
          onSubmit={handleSearch}
        />

        <Box marginBottom="8px" marginTop="8px">
          <Divider />
        </Box>
        <Box textAlign="center" position="relative" height="100%">
          <Loader isLoading={getDocumentRequestState.isLoading} />
        </Box>
      </Paper>
    </div>
  );
};

export default MonthlyReportCardByProviderPage;
