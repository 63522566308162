import { Typography } from "@material-ui/core";
import React from "react";
import {
  YouthGenderEnum,
  YouthGenderTextMap,
} from "../../enums/YouthGenderEnum";

interface Props {
  gender: YouthGenderEnum;
}

const GenderLabel: React.FC<Props> = ({ gender }) => {
  return <Typography>{YouthGenderTextMap[gender]}</Typography>;
};

export default GenderLabel;
