import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { YouthAddressDto } from "../dto/YouthDto";
import { YouthAddressFormDto } from "../dto/YouthFormDto";
import * as Yup from "yup";
class AddressFormWrapper {
  address: YouthAddressFormDto;
  constructor(address?: YouthAddressDto) {
    this.address = new YouthAddressFormDto(address);
  }

  public static getSchema() {
    return Yup.object().shape({
      address: YouthAddressFormDto.getSchema(),
    });
  }
}

export const useYouthAddressForm = (
  onSubmit: (dto: YouthAddressFormDto) => Promise<any>,
  address?: YouthAddressDto
) => {
  //#region State

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: new AddressFormWrapper(address),
    resolver: yupResolver(AddressFormWrapper.getSchema()),
    mode: "all",
  });
  const {
    address: { unableToObtainAddress },
  } = watch();
  //#endregion
  //#region Handlers
  const clearForm = () => {
    reset(new AddressFormWrapper(address));
  };

  const handleOnSubmit = handleSubmit(async (dto: AddressFormWrapper) => {
    if (dto.address.address === "") dto.address.isMailingAddress = undefined;
    await onSubmit(dto.address);
    if (address == null) reset(new AddressFormWrapper());
  });

  //#endregion

  //#region Effects

  useEffect(() => {
    reset(new AddressFormWrapper(address));
  }, [address, reset]);

  useEffect(() => {
    if (!unableToObtainAddress) return;

    setValue("address.address", "");
    setValue("address.city", "");
    setValue("address.state", "");
    setValue("address.zipCode", "");
    setValue("address.isMailingAddress", false);
  }, [setValue, unableToObtainAddress]);
  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
