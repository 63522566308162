import React, {ReactNode} from "react";
import {Paper} from "@material-ui/core";

interface Props extends React.ComponentProps<any> {
  children: ReactNode
}

export const PaperMetricContainer: React.FC<Props> = ({ children }) => (
  <Paper variant={"outlined"} style={{display: "flex", flex: 1}}>
    {children}
  </Paper>
)
