import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import { monthTextMap } from "../../enums/MonthEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  month: string | number;
}

const MonthLabel: React.FC<Props> = ({ month, ...props }) => {
  return <Typography {...props}>{monthTextMap[+month - 1]}</Typography>;
};

export default MonthLabel;
