import { useCallback } from "react";
import { UserDetailDto } from "../dtos/UserDetailDto";
import { useGetUsersLastGeneratedPin } from "./userHooks";

export const useUserPinDialog = (user?: UserDetailDto) => {
  const [getUserPin, userPin, getUserPinRequestState] =
    useGetUsersLastGeneratedPin();

  const refreshUserPin = useCallback(async () => {
    if (user) await getUserPin(user.id);
  }, [getUserPin, user]);

  return {
    userPin,
    getUserPinRequestState,
    refreshUserPin,
  };
};
