import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { flattenOptions } from "../../../app/helpers";
import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";
import {
  SnapInSchoolsSatisfactionSurveyDto,
  SnapInSchoolsSatisfactionSurveyFormDto,
} from "../SnapInSchoolsSatisfactionSurveyDto";

export const useSnapInSchoolsSatisfactionSurveyForm = (
  onSubmit: (dto: SnapInSchoolsSatisfactionSurveyFormDto) => Promise<any>,
  survey?: SnapInSchoolsSatisfactionSurveyDto,
  snapCycle?: SnapInSchoolsCycleDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new SnapInSchoolsSatisfactionSurveyFormDto(survey),
    resolver: yupResolver(
      SnapInSchoolsSatisfactionSurveyFormDto.getSchema(snapCycle?.startedOn)
    ),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(new SnapInSchoolsSatisfactionSurveyFormDto(survey));
  };
  const handleOnSubmit = handleSubmit(
    async (dto: SnapInSchoolsSatisfactionSurveyFormDto) => {
      const { snapHelpfulSkillsOptions, snapHelpsPeopleOptions } =
        dto.satisfactionSurveyResponseSet;

      dto.satisfactionSurveyResponseSet.snapHelpfulSkills = flattenOptions(
        snapHelpfulSkillsOptions!
      );

      dto.satisfactionSurveyResponseSet.snapHelpsPeople = flattenOptions(
        snapHelpsPeopleOptions!
      );

      await onSubmit(dto);

      clearForm();
    }
  );

  useEffect(() => {
    reset(new SnapInSchoolsSatisfactionSurveyFormDto(survey));
  }, [survey, reset]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
