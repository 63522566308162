import { Paper, TableContainer } from "@material-ui/core";
import React from "react";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { SnapInSchoolsCycleSummaryDto } from "../dtos/SnapInSchoolsCycleSummaryDto";
import { SnapInSchoolsSearchParamsDto } from "../dtos/SnapInSchoolsSearchParamsDto";
import SnapInSchoolsCycleListItem from "./SnapInSchoolsCycleListItem";

const SnapInSchoolsCycleList: React.FC<
  SortableTableProps<SnapInSchoolsCycleSummaryDto, SnapInSchoolsSearchParamsDto>
> = (props) => {
  const providerId = getProviderId();

  const columns: ColumnProps[] = [
    { name: "referenceNumber", label: "Cycle #" },
    { name: "groupStartDate", label: "Group Start Date" },
    {
      name: "provider",
      label: "Provider",
      hidden: providerId !== fnyfsProviderId,
    },
    { name: "grades", label: "Grade(s)" },
    { name: "numberOfSessions", label: "# of Sessions" },
    { name: "lastSessionDate", label: "Last Session Date" },
  ];

  const renderRow = (summary: SnapInSchoolsCycleSummaryDto) => (
    <SnapInSchoolsCycleListItem key={summary.id} summary={summary} />
  );

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default SnapInSchoolsCycleList;
