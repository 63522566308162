import {useServicePlanPageContext} from "../ServicePlanPageContext";

export const useServicePlanInterventionList = (goalId: string) => {
  const { servicePlan: { goals }, goals: { onAddGoalInterventionClicked, onViewGoalInterventionNotesClicked, onUpdateGoalInterventionClicked } } = useServicePlanPageContext();

  const goal = goals.find(g => g.id === goalId);
  const interventions = goal?.interventions || [];

  const onAddInterventionClicked = async () => {
    await onAddGoalInterventionClicked(goalId)
  };

  return {
    state: { interventions },
    handlers: { onAddInterventionClicked, onViewGoalInterventionNotesClicked, onUpdateGoalInterventionClicked }
  }
}
