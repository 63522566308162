import React, { Fragment } from "react";

interface Props {
  isDeleted: boolean;
}

const CaseDeletedWrapper: React.FC<Props> = ({ isDeleted, children }) => {
  if (isDeleted) return <Fragment></Fragment>;

  return <Fragment>{children}</Fragment>;
};

export default CaseDeletedWrapper;
