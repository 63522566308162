import {Box, Paper} from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { createCaseSummaryTab } from "../../cases/components/CaseSummaryTab";
import ErrorPage from "../../errors/pages/ErrorPage";
import YouthContactFormDialog from "../../youth-contacts/components/YouthContactFormDialog";
import YouthScreeningFormDialog from "../components/modals/YouthScreeningFormDialog";
import YouthContactsSection, {
  youthContactsSectionName,
} from "../components/youth-details-sections/YouthContactsSection";
import YouthDetailsSection, {
  youthDetailsSectionName,
} from "../components/youth-details-sections/YouthDetailsSection";
import YouthScreeningsSection from "../components/youth-details-sections/YouthScreeningsSection";
import YouthFormDialog from "../components/YouthFormDialog";
import { useYouthDetailPage } from "../hooks/useYouthDetailPage";
import YouthDetailAdditionalActions from "../components/YouthDetailAdditionalActions";
import {ConfirmDeleteYouthDialog} from "../components/ConfirmDeleteYouthDialog";
import ConfirmDeleteYouthContactDialog from "../components/youth-contacts/ConfirmDeleteYouthContactDialog";
import YouthDeletedLabel from "../components/labels.tsx/YouthDeletedLabel";

const YouthDetailPage = () => {
  const { state, handlers } = useYouthDetailPage();
  const {
    isInitialized,
    youth,
    selectedYouthContact,
    getYouthRequestState,
    updateYouthRequestState,
    deleteYouthRequestState,
    createYouthScreeningState,
    createYouthContactRequestState,
    updateYouthContactRequestState,
    createScreeningWaitlistRequestState,
    deleteYouthContactRequestState,
    tabIndex,
    canReadCases,
    canReadScreenings,
  } = state;
  const {
    handleEdit,
    handleEditClicked,
    handleDelete,
    handleDeleteClicked,
    handleChangeTabIndex,
    handleStartNewCaseClicked,
    handleCreateScreeningClicked,
    handleCreateScreening,
    handleCreateYouthContactClicked,
    handleEditYouthContactClicked,
    handleUpsertYouthContact,
    handleDeleteYouthContactClicked,
    handleDeleteYouthContact,
    handleCreateScreeningWaitlistClicked,
    handleCreateScreeningWaitlist,
  } = handlers;
  const tabs: TabProps[] = [
    {
      value: youthDetailsSectionName,
      label: "Details",
      content: (
        <Fragment>
          <YouthDetailsSection
            youth={youth}
            isLoading={getYouthRequestState.isLoading}
            onEdit={handleEditClicked}
          />
        </Fragment>
      ),
    },
  ];

  if (canReadScreenings)
    tabs.push({
      value: "screenings",
      label: "Screenings",
      content: (
        <Fragment>
          <YouthScreeningsSection
            youth={youth!}
            isLoading={getYouthRequestState.isLoading}
            createScreeningWaitlistRequestState={
              createScreeningWaitlistRequestState
            }
            onCreateClick={handleCreateScreeningClicked}
            onCreateScreeningWaitlistClick={
              handleCreateScreeningWaitlistClicked
            }
            onCreateScreeningWaitlist={handleCreateScreeningWaitlist}
          />
        </Fragment>
      ),
    });

  if (canReadCases)
    tabs.push(
      createCaseSummaryTab({
        cases: [],
        isLoading: getYouthRequestState.isLoading,
        onCreateClick: handleStartNewCaseClicked,
        showFilters: true,
        youth,
      })
    );

  tabs.push({
    value: youthContactsSectionName,
    label: "Contacts",
    content: (
      <Fragment>
        <YouthContactsSection
          youth={youth!}
          isLoading={createYouthContactRequestState.isLoading}
          onCreateClick={handleCreateYouthContactClicked}
          onEditClick={handleEditYouthContactClicked}
          onDeleteClick={handleDeleteYouthContactClicked}
        />
      </Fragment>
    ),
  });

  if (isInitialized && getYouthRequestState.error)
    return (
      <ErrorPage
        header={getYouthRequestState.error?.message}
        subheader={<BackButton route="/youths" variant="outlined" />}
      />
    );

  return (
    <Fragment>
      <YouthFormDialog
        onSubmit={handleEdit}
        youth={youth}
        requestState={updateYouthRequestState}
      />
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Screening}>
        <YouthScreeningFormDialog
          onSubmit={handleCreateScreening}
          requestState={createYouthScreeningState}
          youth={youth}
        />
      </WriteAccessLevelWrapper>
      { youth && <ConfirmDeleteYouthDialog youth={youth} requestState={deleteYouthRequestState} onDelete={() => handleDelete(youth.id)} /> }
      <YouthContactFormDialog
        onSubmit={handleUpsertYouthContact}
        requestState={
          selectedYouthContact
            ? updateYouthContactRequestState
            : createYouthContactRequestState
        }
        contactId={selectedYouthContact?.id}
        isLegacy={selectedYouthContact?.isLegacy || false} 
      />
      <ConfirmDeleteYouthContactDialog requestState={deleteYouthContactRequestState} onConfirm={handleDeleteYouthContact} />
      <Box height="100%" position="relative">
        <Box marginBottom="12px">
          <BackButton route="/youths" text="Back to youth records" />
        </Box>
        <Header
          isLoading={getYouthRequestState.isLoading}
          title={
          <>

            <div>
              {youth
              ? `${youth?.firstName} ${youth?.lastName} - ${youth?.referenceNumber}`
              : ""}
            </div>
            { youth && <YouthDeletedLabel isDeleted={youth.isDeleted} /> }
          </>
          }
          actions={ youth && <YouthDetailAdditionalActions youth={youth} onDeleteYouthClicked={handleDeleteClicked} /> }
        />
        <Paper variant="outlined" style={{ height: "88%" }}>
          <TabContainer
            index={tabIndex}
            onIndexChange={handleChangeTabIndex}
            tabs={tabs}
            disabled={getYouthRequestState.isLoading}
          />
        </Paper>
      </Box>
    </Fragment>
  );
};

export default YouthDetailPage;
