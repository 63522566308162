import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { DjjEntryListDto, SetDjjEntryStatusFormDto } from "../DjjEntryDto";

export const useSetDjjEntryStatusForm = (
  onSubmit: (dto: SetDjjEntryStatusFormDto) => Promise<any>,
  djjEntry?: DjjEntryListDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new SetDjjEntryStatusFormDto(djjEntry),
    resolver: yupResolver(SetDjjEntryStatusFormDto.getSchema()),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(new SetDjjEntryStatusFormDto(djjEntry));
  };
  const handleOnSubmit = handleSubmit(async (dto: SetDjjEntryStatusFormDto) => {
    await onSubmit(dto);
    if (djjEntry == null) reset(new SetDjjEntryStatusFormDto());
  });

  useEffect(() => {
    reset(new SetDjjEntryStatusFormDto(djjEntry));
  }, [reset, djjEntry]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
