import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import WarningIcon from "../../../app/components/icons/WarningIcon";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";

export const suicideScreeningBakerActModalId =
  "suicide-screening-baker-act-modal";

const SuicideScreeningBakerActDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedModalId = useAppSelector(selectModalId);

  const handleClose = () => dispatch(closeModal());

  return (
    <Dialog
      open={selectedModalId === suicideScreeningBakerActModalId}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Enter Baker Act Information</DialogTitle>
      <DialogContent>
        <Box style={{ textAlign: "center", marginBottom: "20px" }}>
          <WarningIcon style={{ width: "80px", height: "80px" }} />
        </Box>
        <Typography>
          Please enter Baker Act information in the <strong>Baker Acts</strong>{" "}
          tab if a Baker Act occurs during the Case.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuicideScreeningBakerActDialog;
