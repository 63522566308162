import * as Yup from "yup";

export class SatisfactionSurveyExportParametersDto {
  providerId?: string;
  programTypeId?: string;
  programId?: string;
  dischargeStartDate?: string;
  dischargeEndDate?: string;
  fundingSource?: string;

  constructor() {
    this.dischargeStartDate = "";
    this.dischargeEndDate = "";
    this.fundingSource = "";
    this.providerId = "";
    this.programTypeId = "";
    this.programId = "";
  }

  public static getSchema() {
    return Yup.object();
  }
}
