import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import {
  RemovalReasonEnum,
  removalReasonTextMap,
} from "../enums/RemovalReasonEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  reason: RemovalReasonEnum;
  style?: React.CSSProperties;
}

const RemovalReasonLabel: React.FC<Props> = ({ reason, ...props }) => {
  return <Typography {...props}>{removalReasonTextMap[reason]}</Typography>;
};

export default RemovalReasonLabel;
