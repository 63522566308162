import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum DemographicsHeadOfHouseholdEnum {
  Father = "Father",
  Mother = "Mother",
  Grandparent = "Grandparent",
  Other = "Other",
}

export const demographicsHeadOfHouseholdTextMap: { [key: string]: string } = {
  [DemographicsHeadOfHouseholdEnum.Father]: "Father",
  [DemographicsHeadOfHouseholdEnum.Mother]: "Mother",
  [DemographicsHeadOfHouseholdEnum.Grandparent]: "Grandparent",
  [DemographicsHeadOfHouseholdEnum.Other]: "Other",
};

export const demographicsHeadOfHouseholdOptions: SelectOption[] = [
  { value: "", label: "Select Head of Household" },
  ...Object.keys(DemographicsHeadOfHouseholdEnum).map((status) => ({
    value: status,
    label: demographicsHeadOfHouseholdTextMap[status],
  })),
];
