import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { WaitlistDto } from "../waitlists/dtos/WaitlistDto";
import { WaitlistFormDto } from "../waitlists/dtos/WaitlistFormDto";
import { ScreeningDetailDto } from "./dtos/ScreeningDetailDto";
import { ScreeningFormDto } from "./dtos/ScreeningFormDto";
import { ScreeningListDto } from "./dtos/ScreeningListDto";
import { ScreeningSearchParamsDto } from "./dtos/ScreeningSearchParamsDto";

interface CreateWaitlistRequestParams {
  id: string;
  dto: WaitlistFormDto;
}

interface UpdateScreeningParams {
  id: string;
  dto: ScreeningFormDto;
}

export const getScreeningsRequest = (searchParams: ScreeningSearchParamsDto) =>
  coreAgent.getRequest<PaginationResultDto<ScreeningListDto>>(
    `screenings?${ScreeningSearchParamsDto.toQueryString(searchParams)}`
  );

export const exportScreeningsRequest = ({
  searchParams,
  filename,
}: {
  searchParams: ScreeningSearchParamsDto;
  filename: string;
}) =>
  coreAgent.downloadFileRequest(
    `screenings/export?${ScreeningSearchParamsDto.toQueryString(searchParams)}`,
    filename
  );  

export const getScreeningByIdRequest = (id: string) =>
  coreAgent.getRequest<ScreeningDetailDto>(`screenings/${id}`);

export const createScreeningWaitlistRequest = ({
  id,
  dto,
}: CreateWaitlistRequestParams) =>
  coreAgent.postRequest<WaitlistDto>(`screenings/${id}/waitlist`, dto);

export const updateScreeningRequest = ({ id, dto }: UpdateScreeningParams) =>
  coreAgent.putRequest(`screenings/${id}`, dto);

export const deleteScreeningRequest = (id: string) =>
  coreAgent.deleteRequest<void>(`screenings/${id}`);