import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import Header from "../../../app/components/Header";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import IcmActivityReportFilter from "../components/IcmActivityReportFilter";
import { useIcmActivityReportPage } from "../hooks/useIcmActivityReportPage";
import ErrorText from "../../../app/components/ErrorText";
import {
  useCombinedErrorState,
  useCombinedLoadingState,
} from "../../../app/hooks/useCombinedRequests";

const IcmActivityReportPage = () => {
  const { state, handlers } = useIcmActivityReportPage();
  const {
    params,
    getIcmActivityReportRequestState,
    downloadIcmActivityReportRequestState,
    activityReport,
    isFnyfsUser,
  } = state;
  const { handleSearch } = handlers;

  const isLoading = useCombinedLoadingState([
    getIcmActivityReportRequestState,
    downloadIcmActivityReportRequestState,
  ]);
  const errors = useCombinedErrorState([
    getIcmActivityReportRequestState,
    downloadIcmActivityReportRequestState,
  ]);

  return (
    <div>
      <BackButton route="/reports" style={{ marginTop: "24px" }} />
      <Header title="ICM Activity Report" />
      <Box className="my-2">
        <Typography>
          Select a date range{isFnyfsUser && ", Provider(s) (optional)"} and
          "View" to see a report of ICM Contacts or "Download" for an Excel
          version.
        </Typography>
      </Box>
      {errors &&
        errors.map((errorMessage) => <ErrorText error={errorMessage} />)}
      <Paper
        style={{
          padding: "16px",
          height: "62vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <IcmActivityReportFilter
            onSearch={handleSearch}
            params={params}
            isLoading={isLoading}
          />
        </Box>
        <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
        <Box
          borderColor={ColorsEnum.Border}
          border={1}
          padding="8px"
          flexGrow="1"
          overflow="auto"
        >
          {activityReport !== "" &&
            !getIcmActivityReportRequestState.isLoading && (
              <div dangerouslySetInnerHTML={{ __html: activityReport }}></div>
            )}
          {isLoading && (
            <Box display={"flex"} justifyContent={"center"}>
              <CircularProgress style={{ width: "5rem", height: "5rem" }} />
            </Box>
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default IcmActivityReportPage;
