import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectModalId} from "../../modal/state/modalSelectors";
import {closeModal} from "../../modal/state/modalSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import ErrorText from "../../../app/components/ErrorText";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {FollowUpAttemptFailureDto} from "../dtos/FollowUpDto";
import {useCaseDetailPageContext} from "../../cases/pages/CaseDetailPage";
import {useUpdateFollowUpAttemptDataForm} from "../hooks/useUpdateFollowUpAttemptDataForm";

interface Props {
  followUpAttempt: FollowUpAttemptFailureDto,
  onSubmit: () => Promise<void>;
}
export const updateFollowUpAttemptDataDialogId = "update-follow-up-attempt-data-dialog";
const UpdateFollowUpAttemptDataDialog: React.FC<Props> = ({
                                                       followUpAttempt,
                                                       onSubmit,
                                                     }) => {

  const {
    case: {
      caseResult
    },
    followUps: {
      selectedFollowUp
    },
  } = useCaseDetailPageContext();

  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    state: {
      unableToContactReasonOptions,
      updateFollowUpAttemptRequestState
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useUpdateFollowUpAttemptDataForm(onSubmit, caseResult!, selectedFollowUp!, followUpAttempt);


  const { isLoading, error } = updateFollowUpAttemptRequestState;

  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === updateFollowUpAttemptDataDialogId} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Add Follow Up Data
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="attemptedAt"
                label="Attempt Date/Time"
                type="datetime-local"
                control={control}
                disabled={false}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name={"reason"}
                options={unableToContactReasonOptions}
                fullWidth
                label={"Reason for Failed Attempt"}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={false}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateFollowUpAttemptDataDialog;
