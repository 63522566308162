import { createSlice } from "@reduxjs/toolkit";
import { UserDto } from "../../authentication/dtos/UserDto";
import { getUsers } from "./userActions";

interface UsersState {
  users: UserDto[];
  isLoading: boolean;
}

const initialState: UsersState = {
  users: [],
  isLoading: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload.items;
    });
  },
});

export default usersSlice.reducer;
