import { List } from "@material-ui/core";
import React, { Fragment } from "react";
import ListDivider from "../../../../app/components/ListDivider";
import { ScreeningDto } from "../../../screenings/dtos/ScreeningDto";
import YouthScreeningListItem from "./YouthScreeningListItem";

interface Props {
  screenings: ScreeningDto[];
  isLoading: boolean;
  onCreateScreeningWaitlistClick: (screening: ScreeningDto) => void;
}

const YouthScreeningList: React.FC<Props> = ({
  screenings,
  isLoading,
  onCreateScreeningWaitlistClick,
}) => {
  return (
    <List>
      {[...screenings]
        .sort((a, b) => (a.screeningAt > b.screeningAt ? -1 : 1))
        .map((screening, index) => (
          <Fragment key={screening.id}>
            <YouthScreeningListItem
              screening={screening}
              onCreateScreeningWaitlistClick={() =>
                onCreateScreeningWaitlistClick(screening)
              }
            />
            <ListDivider index={index} length={screenings.length} />
          </Fragment>
        ))}
    </List>
  );
};

export default YouthScreeningList;
