import React from "react";
import { ServicePlanGoalDto } from "../dto/ServicePlanDto";
import { Grid, Typography } from "@material-ui/core";
import { ServicePlanInterventionList } from "./ServicePlanInterventionList";

interface Props {
  goal: ServicePlanGoalDto;
}

export const ServicePlanGoalDetails: React.FC<Props> = ({ goal }) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ marginLeft: "24px" }}>
            <strong>Domains:</strong> {goal.domains.sort().join(", ")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ marginLeft: "24px" }}>
            <strong>Notes:</strong> {goal.notes}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ServicePlanInterventionList goalId={goal.id} />
        </Grid>
      </Grid>
    </>
  );
};
