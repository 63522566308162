import React from "react";
import { AccessLevelEnum } from "../../../features/authentication/enums/AccessLevelEnum";
import { PermissionResourceNameEnum } from "../../../features/authentication/enums/PermissionResourceNameEnum";
import AccessLevelWrapper from "./AccessLevelWrapper";
import ProviderAccessWrapper from "./ProviderAccessWrapper";

interface Props {
  name: PermissionResourceNameEnum;
}

const ProviderWriteAccessLevelWrapper: React.FC<Props> = ({
  name,
  children,
}) => {
  return (
    <ProviderAccessWrapper>
      <AccessLevelWrapper name={name} accessLevel={AccessLevelEnum.Write}>
        {children}
      </AccessLevelWrapper>
    </ProviderAccessWrapper>
  );
};

export default ProviderWriteAccessLevelWrapper;
