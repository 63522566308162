import { dateToFormString } from "../../../app/helpers";

export interface TerminateProgramLocationFormDto {
  terminatedOn: string;
}

export const createTerminateProgramLocationFormDto = (
  date?: Date
): TerminateProgramLocationFormDto => {
  return {
    terminatedOn: date ? dateToFormString(date) : "",
  };
};
