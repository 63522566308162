import { Fragment } from "react";


const Banner = () => {
    if (process.env.REACT_APP_SHOW_ENV_BANNER !== "true") 
        return (null);

    return (
            <Fragment><div style={{ padding: "6px", backgroundColor: "gray", textAlign:"center"}}>
                 You are currently working in the <span style={{fontWeight: "bold"}}>{process.env.REACT_APP_ENV}</span> environment.</div></Fragment>
           );
}

export default Banner;  