import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum YouthPhoneNumberTypeEnum {
  Mobile = "Mobile",
  Home = "Home",
  Alternate = "Alternate",
}

export const YouthPhoneNumberTypeEnumTextMap: { [key: string]: string } = {
  [YouthPhoneNumberTypeEnum.Alternate]: "Alternate",
  [YouthPhoneNumberTypeEnum.Mobile]: "Mobile",
  [YouthPhoneNumberTypeEnum.Home]: "Home",
};

export const YouthPhoneNumberTypeOptions: SelectOption[] = [
  { value: "", label: "Select Phone Number Type" },
  ...Object.values(YouthPhoneNumberTypeEnum).map((value) => ({
    value,
    label: YouthPhoneNumberTypeEnumTextMap[value],
  })),
];
