import React from "react";
import SetYouthDjjIdNumberFormDialog from "../../youths/components/SetYouthDjjIdNumberFormDialog";
import { useDjjEntryListPageContext } from "../pages/DjjEntryListPage";
import DjjEntryDetailsDialog from "./DjjEntryDetailsDialog";
import DjjEntryNotesDialog from "./DjjEntryNotesDialog";
import SetDjjEntryStatusFormDialog from "./SetDjjEntryStatusFormDialog";

const DjjEntryListPageModals = () => {
  const context = useDjjEntryListPageContext();

  const {
    handleSetDjjEntryStatus,
    selectedDjjEntry: setDjjStatusSelectedEntry,
    setDjjEntryStatusRequestState,
  } = context.entryStatus;

  const { currentDjjId, handleSetYouthDjjId, setYouthDjjIdRequestState } =
    context.youthDjjId;
  const {
    setDjjEntryNotesRequestState,
    selectedDjjEntry: djjEntryNotesSelectedEntry,
    handleSetDjjEntryNotes,
  } = context.notes;

  const { djjEntryDetails, getDjjEntryDetailsRequestState } = context.details;

  return (
    <>
      <DjjEntryDetailsDialog
        djjEntry={djjEntryDetails}
        requestState={getDjjEntryDetailsRequestState}
      />
      <SetDjjEntryStatusFormDialog
        onSubmit={handleSetDjjEntryStatus}
        djjEntry={setDjjStatusSelectedEntry}
        requestState={setDjjEntryStatusRequestState}
      />
      <SetYouthDjjIdNumberFormDialog
        onSubmit={handleSetYouthDjjId}
        requestState={setYouthDjjIdRequestState}
        currentDjjId={currentDjjId}
      />
      <DjjEntryNotesDialog
        onSubmit={handleSetDjjEntryNotes}
        requestState={setDjjEntryNotesRequestState}
        entry={djjEntryNotesSelectedEntry}
      />
    </>
  );
};

export default DjjEntryListPageModals;
