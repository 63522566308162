export enum JjisProgramTypeEnum {
  CommunityCounseling = "CommunityCounseling",
  DVRespite = "DVRespite",
  FYRACDV = "FYRACDV",
  FYRACPR = "FYRACPR",
  ICM = "ICM",
  Respite = "Respite",
  Shelter = "Shelter",
  SNAP = "SNAP",
}
