import {Box, TableCell, TableRow} from "@material-ui/core";
import React from "react";
import DateLabel from "../../../app/components/labels/DateLabel";
import { YouthSummaryDto } from "../dto/YouthSummaryDto";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import YouthDeletedLabel from "./labels.tsx/YouthDeletedLabel";

interface Props {
  youth: YouthSummaryDto;
}

const YouthListItem: React.FC<Props> = ({ youth }) => {
  return (
    <TableRow>
      <TableCell>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
        <RouteableStyledLink pathname={`youths/${youth.id}`} text="Back to Youth Records">
          {youth.referenceNumber}
        </RouteableStyledLink>
        <YouthDeletedLabel isDeleted={youth.isDeleted} />
        </Box>
      </TableCell>
      <TableCell>{youth.firstName}</TableCell>
      <TableCell>{youth.lastName}</TableCell>
      <TableCell>
        <DateLabel date={youth.dob} />
      </TableCell>
      <TableCell>{youth.djjIdNumber}</TableCell>
    </TableRow>
  );
};

export default YouthListItem;
