import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { CinsPetitionFormDto } from "../dtos/CinsPetitionPetitionFormDto";
import { reasonForPetitionOptions } from "../enums/ReasonForPetitionEnum";
import * as Yup from "yup";
import {
  PetitionTypeEnum,
  petitionTypeOptions,
} from "../enums/PetitionTypeEnum";

export const CinsPetitionSchema = Yup.object().shape({
  openedOn: Yup.string().label("Opened On").required(),
  closedOn: Yup.string().label("Closed On"),
  reasonForPetition: Yup.string()
    .label("Reason for Petition")
    .when("petitionType", {
      is: PetitionTypeEnum.CinsFins,
      then: Yup.string().required(),
    }),
  petitionType: Yup.string().label("Petition Type").required(),
  notes: Yup.string().label("Notes").max(1000),
});

interface Props {
  control: Control<CinsPetitionFormDto>;
  isDisabled: boolean;
}

const CinsPetitionFormFields: React.FC<Props> = ({ control, isDisabled }) => {
  const { petitionType } = useWatch({ control });

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="openedOn"
          label="Opened On*"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="closedOn"
          label="Closed On"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="petitionType"
          options={petitionTypeOptions}
          label="Petition Type*"
          fullWidth
          disabled={isDisabled}
        />
      </Grid>
      {petitionType === PetitionTypeEnum.CinsFins && (
        <Grid item md={12} xs={12}>
          <ControlledSelect
            control={control}
            name="reasonForPetition"
            label="Reason For Petition*"
            options={reasonForPetitionOptions}
            fullWidth
            disabled={isDisabled}
          />
        </Grid>
      )}
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="notes"
          type="text-area"
          multiline
          minRows={4}
          label="Notes"
          fullWidth
          disabled={isDisabled}
        />
      </Grid>
    </Fragment>
  );
};

export default CinsPetitionFormFields;
