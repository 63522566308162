import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { QueryStringHelpers } from "../../../app/helpers";
import { useAnchor } from "../../../app/hooks/useAnchor";
import { useQuery } from "../../../app/hooks/useQuery";
import { AlertDismissalSearchParamsDto } from "../../alerts/dtos/AlertDismissalDto";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { useGetProgramTypes } from "../../program-types/hooks/programTypeHooks";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { useGetAllProviders } from "../../provider/hooks/providerHooks";
import { useGetCasesAlertDismissals } from "./caseHooks";

export const useCasesAlertDismissalListPage = () => {
  //#region State
  const query = useQuery();
  const history = useHistory();

  const [filterAnchorEl, handleOpenFilterClicked, handleCloseFilter] =
    useAnchor();
  const [params, setParams] = useState<AlertDismissalSearchParamsDto>(
    new AlertDismissalSearchParamsDto(query)
  );
  //#endregion

  //#region Requests
  const [getDismissals, dismissals, getDismissalsRequest] =
    useGetCasesAlertDismissals();
  const [getProviders, , getProvidersRequestState] = useGetAllProviders();
  const [getProgramTypes, programTypes, getProgramTypesRequestState] =
    useGetProgramTypes();
  const [providers, setProviders] = useState<ProviderDto[]>([]);
  //#endregion

  //#region Handlers
  const handlePageChange = (page: number) => {
    setParams((params) => ({ ...params, page }));
  };

  const handleSearch = (dto: AlertDismissalSearchParamsDto) => {
    setParams(dto);
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    history.push(
      `/cases/alerts/dismissals?${QueryStringHelpers.toQueryString(params)}`
    );
  }, [params, history]);

  useEffect(() => {
    getDismissals(params);
  }, [params, getDismissals]);

  useEffect(() => {
    const load = async () => {
      const results = await getProviders(undefined);

      if (results) {
        setProviders(results.sort((a, b) => (a.name > b.name ? 1 : -1)));
      }
    };

    const providerId = getProviderId();
    if (providerId && providerId === fnyfsProviderId) load();
  }, [getProviders]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId === fnyfsProviderId) {
      getProgramTypes(undefined);
    }
  }, [getProgramTypes]);

  //#endregion

  return {
    state: {
      dismissals,
      programTypes: programTypes || [],
      providers,
      params,
      getProgramTypesRequestState,
      getProvidersRequestState,
      getDismissalsRequest,
      filterAnchorEl,
    },
    handlers: {
      handleSearch,
      handlePageChange,
      handleOpenFilterClicked,
      handleCloseFilter,
    },
  };
};
