import { StaffMemberDto } from "../staff-members/dtos/StaffMemberDto";
import * as Yup from "yup";
import { dateToFormString } from "../../app/helpers";
import { SelectOption } from "../../app/components/inputs/ControlledSelect";
import { ProgramTypeEnum } from "../programs/enums/ProgramTypeEnum";
import {
  SnapSatisfactionSurveyHelpsPeopleEnum,
  SnapSatisfactionSurveyLikertEnum,
  SnapSatisfactionSurveySkillEnum,
} from "./SatisfactionSurveyEnums";
import { CaseDetailDto } from "../cases/dtos/CaseDetailDto";

export enum SatisfactionSurveyResponseEnum {
  Yes = "Yes",
  No = "No",
  Somewhat = "Somewhat",
}

export const satisfactionSurveyResponseTextMap: { [key: string]: string } = {
  [SatisfactionSurveyResponseEnum.Yes]: "Yes",
  [SatisfactionSurveyResponseEnum.No]: "No",
  [SatisfactionSurveyResponseEnum.Somewhat]: "Somewhat",
};

export const satisfactionSurveyResponseOptions: SelectOption[] = [
  { value: "", label: "Select Response" },
  ...Object.values(SatisfactionSurveyResponseEnum).map((value) => ({
    value,
    label: satisfactionSurveyResponseTextMap[value],
  })),
];

export interface SatisfactionSurveyResponseSetDto {
  id: string;
  overallSatisfied: SatisfactionSurveyResponseEnum; // 1
  counselorUnderstoodFeelings: SatisfactionSurveyResponseEnum; // 2
  counselorHelped: SatisfactionSurveyResponseEnum; // 3
  betterAbleToSolveProblems: SatisfactionSurveyResponseEnum; // 4
  counselorRespectedThoughtsAndFeelings: SatisfactionSurveyResponseEnum; // 5
  serviceInReasonableTime: SatisfactionSurveyResponseEnum; // 6
  wouldComeBackForAdditionalHelp: SatisfactionSurveyResponseEnum; // 7
  regularlyInformedAboutServices: SatisfactionSurveyResponseEnum; // 8
  staffAddressedCounselingNeeds: SatisfactionSurveyResponseEnum; // 9
  snapSatisfiedWithProgram?: SnapSatisfactionSurveyLikertEnum; // SNAP 1
  snapHelpfulSkills?: SnapSatisfactionSurveySkillEnum[]; // SNAP 2
  snapHelpsPeople?: SnapSatisfactionSurveyHelpsPeopleEnum[]; // SNAP 3
}

export interface SatisfactionSurveyDto {
  id: string;
  completedOn: Date;
  caseId: string;
  staffMember: StaffMemberDto;
  satisfactionSurveyResponseSet: SatisfactionSurveyResponseSetDto;
}

export class SatisfactionSurveyFormDto {
  completedOn: string;
  staffMemberId: string;
  satisfactionSurveyResponseSet: SatisfactionSurveyResponseSetFormDto;
  constructor(caseDto?: CaseDetailDto) {
    this.staffMemberId = caseDto?.satisfactionSurvey?.staffMember.id || "";
    this.completedOn = caseDto?.satisfactionSurvey
      ? dateToFormString(new Date(caseDto.satisfactionSurvey?.completedOn))
      : "";
    this.satisfactionSurveyResponseSet =
      new SatisfactionSurveyResponseSetFormDto(
        caseDto?.satisfactionSurvey?.satisfactionSurveyResponseSet,
        caseDto
      );
  }

  public static getSchema() {
    return Yup.object().shape({
      completedOn: Yup.string().label("Completed On").required(),
      staffMemberId: Yup.string().label("Counselor").required(),
      satisfactionSurveyResponseSet:
        SatisfactionSurveyResponseSetFormDto.getSchema(),
    });
  }
}

export class SatisfactionSurveyResponseSetFormDto {
  overallSatisfied: string; // 1
  counselorUnderstoodFeelings: string; // 2
  counselorHelped: string; // 3
  betterAbleToSolveProblems: string; // 4
  counselorRespectedThoughtsAndFeelings: string; // 5
  serviceInReasonableTime: string; // 6
  wouldComeBackForAdditionalHelp: string; // 7
  regularlyInformedAboutServices: string; // 8
  staffAddressedCounselingNeeds: string; // 9
  snapSatisfiedWithProgram?: string;
  snapHelpfulSkills?: string[];
  snapHelpfulSkillsOptions?: { [x: string]: boolean };
  snapHelpsPeople?: string[];
  snapHelpsPeopleOptions?: { [x: string]: boolean };
  isSnapProgram: boolean;

  constructor(dto?: SatisfactionSurveyResponseSetDto, caseDto?: CaseDetailDto) {
    this.overallSatisfied = dto?.overallSatisfied || "";
    this.counselorUnderstoodFeelings = dto?.counselorUnderstoodFeelings || "";
    this.counselorHelped = dto?.counselorHelped || "";
    this.betterAbleToSolveProblems = dto?.betterAbleToSolveProblems || "";
    this.counselorRespectedThoughtsAndFeelings =
      dto?.counselorRespectedThoughtsAndFeelings || "";
    this.serviceInReasonableTime = dto?.serviceInReasonableTime || "";
    this.wouldComeBackForAdditionalHelp =
      dto?.wouldComeBackForAdditionalHelp || "";
    this.regularlyInformedAboutServices =
      dto?.regularlyInformedAboutServices || "";
    this.staffAddressedCounselingNeeds =
      dto?.staffAddressedCounselingNeeds || "";

    this.isSnapProgram =
      caseDto != null &&
      [ProgramTypeEnum.Snap, ProgramTypeEnum.SnapYouthJustice].includes(
        caseDto?.program?.programType.programType
      );

    if (this.isSnapProgram) {
      this.snapSatisfiedWithProgram = dto?.snapSatisfiedWithProgram || "";
      this.snapHelpfulSkillsOptions =
        SatisfactionSurveyResponseSetFormDto.getSnapHelpfulSkillsOptions(dto);
      this.snapHelpsPeopleOptions =
        SatisfactionSurveyResponseSetFormDto.getSnapHelpsPeopleOptions(dto);
    }
  }

  private static getSnapHelpfulSkillsOptions(
    dto: SatisfactionSurveyResponseSetDto | undefined
  ) {
    const current: string[] = dto?.snapHelpfulSkills || [];

    return Object.keys(SnapSatisfactionSurveySkillEnum).reduce<{
      [key: string]: boolean;
    }>((acc, value) => {
      acc[value] = current.length !== 0 && current.includes(value);
      return acc;
    }, {});
  }

  private static getSnapHelpsPeopleOptions(
    dto: SatisfactionSurveyResponseSetDto | undefined
  ) {
    const current: string[] = dto?.snapHelpsPeople || [];

    return Object.keys(SnapSatisfactionSurveyHelpsPeopleEnum).reduce<{
      [key: string]: boolean;
    }>((acc, value) => {
      acc[value] = current.length !== 0 && current.includes(value);
      return acc;
    }, {});
  }

  public static getSchema() {
    return Yup.object().shape({
      overallSatisfied: Yup.string().label("This").required(),
      counselorUnderstoodFeelings: Yup.string().label("This").required(),
      counselorHelped: Yup.string().label("This").required(),
      betterAbleToSolveProblems: Yup.string().label("This").required(),
      counselorRespectedThoughtsAndFeelings: Yup.string()
        .label("This")
        .required(),
      serviceInReasonableTime: Yup.string().label("This").required(),
      wouldComeBackForAdditionalHelp: Yup.string().label("This").required(),
      regularlyInformedAboutServices: Yup.string().label("This").required(),
      staffAddressedCounselingNeeds: Yup.string().label("This").required(),
      snapSatisfiedWithProgram: Yup.string()
        .label("This")
        .when("isSnapProgram", {
          is: true,
          then: Yup.string().required(),
        }),
      snapHelpfulSkillsOptions: Yup.object().when("isSnapProgram", {
        is: true,
        then: Yup.object().test(
          "test-length",
          "Must choose 3 options",
          function (values) {
            if (values === undefined) return false;

            return Object.values(values).filter((x) => x).length === 3;
          }
        ),
      }),
      snapHelpsPeopleOptions: Yup.object().when("isSnapProgram", {
        is: true,
        then: Yup.object().test(
          "test-length",
          "Must choose 3 options",
          function (values) {
            if (values === undefined) return false;

            return Object.values(values).filter((x) => x).length === 3;
          }
        ),
      }),
    });
  }
}

export interface SatisfactionSurveyQuestion {
  name: string;
  communityCounselingText: string;
  shelterText: string;
  order: number;
}

export enum SatisfactionSurveyQuestionNames {
  overallSatisfied = "overallSatisfied",
  counselorUnderstoodFeelings = "counselorUnderstoodFeelings",
  counselorHelped = "counselorHelped",
  betterAbleToSolveProblems = "betterAbleToSolveProblems",
  counselorRespectedThoughtsAndFeelings = "counselorRespectedThoughtsAndFeelings",
  serviceInReasonableTime = "serviceInReasonableTime",
  wouldComeBackForAdditionalHelp = "wouldComeBackForAdditionalHelp",
  regularlyInformedAboutServices = "regularlyInformedAboutServices",
  staffAddressedCounselingNeeds = "staffAddressedCounselingNeeds",
}

export const satisfactionSurveyQuestions: SatisfactionSurveyQuestion[] = [
  {
    name: SatisfactionSurveyQuestionNames.overallSatisfied,
    communityCounselingText:
      "Overall, are you satisfied with the services you received?",
    shelterText: "Overall, are you satisfied with the services you received?",
    order: 0,
  },
  {
    name: SatisfactionSurveyQuestionNames.counselorUnderstoodFeelings,
    communityCounselingText:
      "Our counselor understood how I felt about things.",
    shelterText: "My counselor understood how I felt about things.",
    order: 1,
  },
  {
    name: SatisfactionSurveyQuestionNames.counselorHelped,
    communityCounselingText:
      "So far, our counseling has helped me and my family.",
    shelterText: "So far, my counseling has helped me and my family.",
    order: 2,
  },
  {
    name: SatisfactionSurveyQuestionNames.betterAbleToSolveProblems,
    communityCounselingText:
      "I believe that my family and I are better able to solve our problems now.",
    shelterText:
      "I believe that my family and I are better able to solve our problems now.",
    order: 3,
  },
  {
    name: SatisfactionSurveyQuestionNames.counselorRespectedThoughtsAndFeelings,
    communityCounselingText:
      "Our counselor respected my thoughts and feelings.",
    shelterText: "Our counselor respected my thoughts and feelings.",
    order: 4,
  },
  {
    name: SatisfactionSurveyQuestionNames.serviceInReasonableTime,
    communityCounselingText:
      "I was able to get services from this program in a reasonable amount of time.",
    shelterText:
      "I was able to get services from this program in a reasonable amount of time.",
    order: 5,
  },
  {
    name: SatisfactionSurveyQuestionNames.wouldComeBackForAdditionalHelp,
    communityCounselingText:
      "If I had another problem, I would come back here.",
    shelterText: "I felt safe here.",
    order: 6,
  },
  {
    name: SatisfactionSurveyQuestionNames.regularlyInformedAboutServices,
    communityCounselingText:
      "I was regularly informed about services and plans for me and my family.",
    shelterText:
      "I was regularly informed about services and plans for me and my family.",
    order: 7,
  },
  {
    name: SatisfactionSurveyQuestionNames.staffAddressedCounselingNeeds,
    communityCounselingText:
      "The staff addressed my counseling needs as soon as possible.",
    shelterText: "The staff addressed my needs as soon as possible.",
    order: 8,
  },
];

export const getQuestionText = (
  question: SatisfactionSurveyQuestion,
  programType: ProgramTypeEnum
) => {
  switch (programType) {
    case ProgramTypeEnum.Shelter:
    case ProgramTypeEnum.DomesticViolenceRespite:
    case ProgramTypeEnum.StaffSecure:
    case ProgramTypeEnum.ProbationRespite:
    case ProgramTypeEnum.PhysicallySecure:
      return question.shelterText;
    case ProgramTypeEnum.CommunityCounseling:
    case ProgramTypeEnum.IntensiveCaseManagement:
    case ProgramTypeEnum.FyracDomesticViolenceCommunityCounseling:
    case ProgramTypeEnum.FyracProbationRespiteCommunityCounseling:
    default:
      return question.communityCounselingText;
  }
};

export enum SnapSatisfactionSurveyQuestionNames {
  snapSatisfiedWithProgram = "snapSatisfiedWithProgram",
  snapHelpfulSkills = "snapHelpfulSkills",
  snapHelpsPeople = "snapHelpsPeople",
}
export const snapSatisfactionSurveyQuestions: { [key: string]: string } = {
  [SnapSatisfactionSurveyQuestionNames.snapSatisfiedWithProgram]:
    "In general, how satisfied were you with the SNAP Program?",
  [SnapSatisfactionSurveyQuestionNames.snapHelpfulSkills]:
    "What skills do you feel were most helpful?",
  [SnapSatisfactionSurveyQuestionNames.snapHelpsPeople]:
    "Do you think SNAP helps people to",
};
