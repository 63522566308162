import { useRequest } from "../../../app/hooks/useRequest";
import { getProviderFiscalYearsRequest } from "../../provider-fiscal-year/ProviderFiscalYearRequests";
import {
  createOutreachActivityRequest,
  createProviderLocationRequest,
  createProviderRequest,
  getAllProvidersRequest,
  getProviderByIdRequest,
  getProviderLocationsRequest,
  getProviderProgramsRequest,
  getProvidersRequest,
  getProviderStaffMembersRequest,
  updateProviderRequest,
} from "../ProviderRequests";

export const useGetProviders = () => useRequest(getProvidersRequest);
export const useGetProvider = () => useRequest(getProviderByIdRequest);
export const useCreateProvider = () => useRequest(createProviderRequest);
export const useUpdateProvider = () => useRequest(updateProviderRequest);

export const useGetProviderLocations = () =>
  useRequest(getProviderLocationsRequest);

export const useGetProviderPrograms = () =>
  useRequest(getProviderProgramsRequest);

export const useGetProviderStaffMembers = () =>
  useRequest(getProviderStaffMembersRequest);

export const useGetAllProviders = () => useRequest(getAllProvidersRequest);

export const useCreateProviderLocation = () =>
  useRequest(createProviderLocationRequest);

export const useCreateOutreachActivityRequest = () => useRequest(createOutreachActivityRequest);

export const useGetProviderFiscalYears = () =>
  useRequest(getProviderFiscalYearsRequest);