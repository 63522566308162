import { useRequest } from "../../../app/hooks/useRequest";
import {
  createReferralRequest,
  forwardReferralRequest,
  getReferralRequest,
  getReferralsRequest,
  removeReferralRequest,
  getPendingReferralCountRequest
} from "../ReferralsRequests";

export const useCreateReferral = () => useRequest(createReferralRequest, false);

export const useGetReferrals = () => useRequest(getReferralsRequest);

export const useGetReferral = () => useRequest(getReferralRequest);

export const useForwardReferral = () => useRequest(forwardReferralRequest);

export const useRemoveReferral = () => useRequest(removeReferralRequest);

export const useGetPendingReferralCount = () => useRequest(getPendingReferralCountRequest);