import React, { Fragment } from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

export const createRow = (label: React.ReactNode, value: React.ReactNode) => {
  return (
    <Fragment>
      <Grid item md={3} xs={4}>
        <Typography component="span">{label}:</Typography>
      </Grid>
      <Grid item md={9} xs={8}>
        <Typography component="span">{value}</Typography>
      </Grid>
    </Fragment>
  );
};

export interface SectionProps {
  label?: React.ReactNode;
  actions?: React.ReactNode;
  content: React.ReactNode;
  elevation?: number;
}

const DefaultElevation = 2;

export const ContentSection: React.FC<SectionProps> = ({
  label,
  actions,
  content,
  elevation,
}: SectionProps) => {
  const elev = elevation == null ? DefaultElevation : elevation;

  return (
    <Paper
      square
      elevation={elev}
      variant="outlined"
      style={{ height: "100%" }}
    >
      <Grid container style={{ padding: "12px" }}>
        <Grid item md={3} xs={12}>
          <Typography>
            <strong> {label}</strong>
          </Typography>
          <Box>{actions}</Box>
        </Grid>
        <Grid item md={9} xs={12}>
          <Grid container spacing={1}>
            {content}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
