import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import DateLabel from "../../../app/components/labels/DateLabel";
import { TabProps } from "../../../app/components/TabContainer";
import AssessmentLink from "../../assessments/components/AssessmentLink";
import AssessmentStatusLabel from "../../assessments/components/AssessmentStatusLabel";
import { AssessmentScoreTypeEnum } from "../../assessments/enums/AssessmentScoreTypeEnum";
import {
  AssessmentTypeEnum,
  assessmentTypeTextMap,
} from "../../assessments/enums/AssessmentTypeEnum";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditButton from "../../../app/components/buttons/EditButton";
import AssessmentFormDialog from "../../assessments/components/AssessmentFormDialog";
import { AssessmentTemplateTagEnum } from "../../assessments/enums/AssessmentTemplateTagEnum";
import { useSnapInSchoolsDetailPageContext } from "../pages/SnapInSchoolCyclesDetailPage";

export const snapCycleMoceTabName = "snap-cycle-moce";

const SnapInSchoolsCycleMoceSection: React.FC = () => {
  const context = useSnapInSchoolsDetailPageContext();

  const { snapCycle } = context.snapCycle;

  const {
    assessments,
    getAssessmentsRequestState,
    startAssessmentRequestState,
    handleStartAssessmentClicked,
    handleStartAssessment,
    handleRefreshAssessments,
  } = context.assessments;

  const getAssessment = (type: AssessmentTypeEnum) => {
    return assessments.find((x) => x.type === type && x.voidedAt == null);
  };

  const assessmentsCompleted =
    getAssessment(AssessmentTypeEnum.MocePreAssessment) != null &&
    getAssessment(AssessmentTypeEnum.MocePostAssessment) != null;
  //#endregion

  //#region UI Helpers
  const renderMoceAssessments = () => {
    const preAssessment = getAssessment(AssessmentTypeEnum.MocePreAssessment);
    const postAssessment = getAssessment(AssessmentTypeEnum.MocePostAssessment);

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {assessmentTypeTextMap[AssessmentTypeEnum.MocePreAssessment]}
            </TableCell>
            <TableCell>
              {assessmentTypeTextMap[AssessmentTypeEnum.MocePostAssessment]}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <AssessmentStatusLabel assessment={preAssessment} />
            </TableCell>
            <TableCell>
              <AssessmentStatusLabel assessment={postAssessment} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Reference #</TableCell>
            <TableCell>
              {preAssessment ? (
                <AssessmentLink assessment={preAssessment} />
              ) : (
                "N/A"
              )}
            </TableCell>
            <TableCell>
              {postAssessment ? (
                <AssessmentLink assessment={postAssessment} />
              ) : (
                "N/A"
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Administered On</TableCell>
            <TableCell>
              {preAssessment == null && "N/A"}
              <DateLabel date={preAssessment?.completedOn} />{" "}
            </TableCell>
            <TableCell>
              {postAssessment == null && "N/A"}
              <DateLabel date={postAssessment?.completedOn} />{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Score</TableCell>
            <TableCell>
              {preAssessment?.scores.find(
                (x) => x.type === AssessmentScoreTypeEnum.None
              )?.score || "N/A"}
            </TableCell>
            <TableCell>
              {postAssessment?.scores.find(
                (x) => x.type === AssessmentScoreTypeEnum.None
              )?.score || "N/A"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  //#endregion

  return (
    <Fragment>
      <AssessmentFormDialog
        assessments={assessments}
        snapCycle={snapCycle}
        requestState={startAssessmentRequestState}
        tag={AssessmentTemplateTagEnum.Moce}
        onSubmit={handleStartAssessment}
        verifyInProgress={false}
        verifyFailure={false}
        openUnverifiedAssessment={() => {}}
      />
      <Box position="relative">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Assessment}>
          <Box display="flex">
            <IconButton
              title="Refresh Assessments"
              onClick={handleRefreshAssessments}
            >
              <RefreshIcon />
              <Typography>Refresh Assessments</Typography>
            </IconButton>
            {!assessmentsCompleted && (
              <EditButton
                style={{ marginLeft: "auto" }}
                text="Start MoCE Assessment"
                onClick={handleStartAssessmentClicked}
              />
            )}
          </Box>
        </WriteAccessLevelWrapper>
        {!getAssessmentsRequestState.isLoading && renderMoceAssessments()}
      </Box>
    </Fragment>
  );
};

export default SnapInSchoolsCycleMoceSection;

export const createSnapInSchoolsCycleMoceSectionTab = (): TabProps => {
  return {
    value: snapCycleMoceTabName,
    label: "MoCE",
    content: <SnapInSchoolsCycleMoceSection />,
  };
};
