import { coreAgent } from "../../app/api/agent";
import { CaseHoldDto } from "./dtos/CaseHoldDto";
import { EndCaseHoldFormDto } from "./dtos/EndCaseHoldFormDto";

interface EndCaseHoldParamsDto {
  id: string;
  dto: EndCaseHoldFormDto;
}

export const endCaseHoldRequest = ({ id, dto }: EndCaseHoldParamsDto) =>
  coreAgent.postRequest<CaseHoldDto>(`caseholds/${id}/endhold`, dto);
