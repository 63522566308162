import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledInput from "../../../../app/components/inputs/ControlledInput";
import ControlledWholeNumberInput from "../../../../app/components/inputs/ControlledWholeNumberInput";
import { SnapFidelityFormDto } from "../../dtos/SnapFidelityFormDto";
import * as Yup from "yup";

const testStringNumberRange = function (value: any, min: number, max: number) {
  if (value === undefined || value === "" || isNaN(+value)) return true;

  const numValue = +value;

  return numValue >= min && numValue <= max;
};

export const snapGroupFidelitySchema = Yup.object().shape({
  performedOn: Yup.string().label("Performed On").required(),
  youthScore: Yup.string()
    .label("Youth Score")
    .required()
    .test(
      "youth-score-range",
      "Youth Score must be less than or equal to 100",
      function (value) {
        return testStringNumberRange(value, 0, 100);
      }
    ),
  parentScore: Yup.string()
    .label("Parent Score")
    .when("isSnapYouthJustice", {
      is: false,
      then: (schema) =>
        schema
          .required()
          .test(
            "parent-score-range",
            "Parent Score must be less than or equal to 100",
            function (value) {
              return testStringNumberRange(value, 0, 100);
            }
          ),
      otherwise: (schema) => schema,
    }),
});

interface Props {
  control: Control<SnapFidelityFormDto>;
  isDisabled: boolean;
}

const SnapGroupFidelityFormFields: React.FC<Props> = ({
  control,
  isDisabled,
}) => {
  const { isSnapYouthJustice } = useWatch({ control });

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="performedOn"
          label="Completed On*"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledWholeNumberInput
          control={control}
          name="youthScore"
          label="Youth Score"
          disabled={isDisabled}
        />
      </Grid>
      {!isSnapYouthJustice && (
        <Grid item md={12} xs={12}>
          <ControlledWholeNumberInput
            control={control}
            name="parentScore"
            label="Parent Score"
            disabled={isDisabled}
          />
        </Grid>
      )}
    </Fragment>
  );
};

export default SnapGroupFidelityFormFields;
