import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import ErrorText from "../../../app/components/ErrorText";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { RemoveReferralFormDto } from "../dtos/RemoveReferralFormDto";
import { useRemoveReferralForm } from "../hooks/useRemoveReferralForm";

export const removeReferralModalId = "remove-referral-form-modal";

const defaultReasons = [
  "Spam/Incomplete/Unusable",
  "Could Not Contact",
  "Refused Service",
  "Screening Completed",
];

interface Props {
  onSubmit: (dto: RemoveReferralFormDto) => Promise<void>;
  requestState: RequestStateDto;
}

const RemoveReferralFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useRemoveReferralForm(onSubmit);
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === removeReferralModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Remove Referral
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography>
                Select the reason why the referral is being removed.
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Controller
                control={control}
                name="removalReason"
                render={({ field, fieldState }) => {
                  return (
                    <ControlledSelect
                      {...field}
                      control={control}
                      options={defaultReasons.map((x) => ({ value: x.toString(), label: x.toString()}))}   
                      fullWidth                   
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RemoveReferralFormDialog;
