import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum DemographicsFamilyStructureEnum {
  LiveWithBothParents = "LiveWithBothParents",
  LivesWithSingleFather = "LivesWithSingleFather",
  LivesWithSingleMother = "LivesWithSingleMother",
  LivesWithRelatives = "LivesWithRelatives",
  LivesWithNonRelatives = "LivesWithNonRelatives",
  FosterCare = "FosterCare",
  Other = "Other",
  NotSpecified = "NotSpecified"
}

export const DemographicsFamilyStructureEnumTextMap: { [key: string]: string } =
  {
    [DemographicsFamilyStructureEnum.LiveWithBothParents]:
      "Lives with Both Parents",
    [DemographicsFamilyStructureEnum.LivesWithSingleFather]:
      "Lives with Single Father",
    [DemographicsFamilyStructureEnum.LivesWithSingleMother]:
      "Lives with Single Mother",
    [DemographicsFamilyStructureEnum.LivesWithRelatives]:
      "Lives with Relatives",
    [DemographicsFamilyStructureEnum.LivesWithNonRelatives]:
      "Lives with Non-Relatives",
    [DemographicsFamilyStructureEnum.FosterCare]: "Foster Care",
    [DemographicsFamilyStructureEnum.Other]: "Other",
    [DemographicsFamilyStructureEnum.NotSpecified]: "Not Specified",
  };

export const DemographicsFamilyStructureEnumOptions: SelectOption[] = [
  { value: "", label: "Select Family Structure" },
  ...Object.values(DemographicsFamilyStructureEnum).map((value) => ({
    value,
    label: DemographicsFamilyStructureEnumTextMap[value],
  })),
];
