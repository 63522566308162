import { dateToFormString } from "../../../app/helpers";
import { OutreachActivityDto } from "./OutreachActivityDto";
import * as Yup from "yup";
import { TopicEnum } from "../enums/TopicEnum";
import { TargetAudienceEnum } from "../enums/TargetAudienceEnum";

export class OutreachActivityFormDto {
  title: string;
  activityDate: string;
  durationHours: number;
  zipCode: string;
  locationDescription: string;
  targetAudienceOptions: { [x: string]: boolean };
  targetAudiences: string[];
  estimatedPeopleReached: number;
  modality: string;
  topicOptions: { [x: string]: boolean };
  topics: string[];

  constructor(activityDto: OutreachActivityDto | undefined) {
    this.title = activityDto?.title || "";
    this.activityDate = activityDto?.activityDate
      ? dateToFormString(new Date(activityDto?.activityDate))
      : dateToFormString(new Date());
    this.durationHours = activityDto?.durationHours || 0;
    this.zipCode = activityDto?.zipCode || "";
    this.locationDescription = activityDto?.locationDescription || "";
    this.targetAudiences = [];
    this.targetAudienceOptions =
      OutreachActivityFormDto.getTargetAudienceOptions(activityDto);
    this.estimatedPeopleReached = activityDto?.estimatedPeopleReached || 0;
    this.modality = activityDto?.modality || "";
    this.topics = [];
    this.topicOptions = OutreachActivityFormDto.getTopicOptions(activityDto);
  }

  private static getTopicOptions(activityDto: OutreachActivityDto | undefined) {
    const topics: string[] = activityDto?.topics || [];

    return Object.keys(TopicEnum).reduce<{ [key: string]: boolean }>(
      (acc, value) => {
        acc[value] = topics.length !== 0 && topics.includes(value);
        return acc;
      },
      {}
    );
  }

  private static getTargetAudienceOptions(
    activityDto: OutreachActivityDto | undefined
  ) {
    const targetAudiences: string[] = activityDto?.targetAudiences || [];

    return Object.keys(TargetAudienceEnum).reduce<{ [key: string]: boolean }>(
      (acc, value) => {
        acc[value] =
          targetAudiences.length !== 0 && targetAudiences.includes(value);
        return acc;
      },
      {}
    );
  }

  public static getSchema() {
    return Yup.object().shape({
      title: Yup.string().label("Title").required(),
      activityDate: Yup.string().label("Activity Date").required(),
      durationHours: Yup.number()
        .label("Duration")
        .required()
        .typeError("Must be a number between 0 and 999")
        .min(0)
        .max(999),
      zipCode: Yup.string().label("Location Zip Code").required(),
      locationDescription: Yup.string().label("Location Description").max(120),
      estimatedPeopleReached: Yup.number()
        .label("Estimated Number of People Reached")
        .typeError("Must be a number between 0 and 99,9999")
        .required()
        .min(0)
        .max(99999),
      targetAudienceOptions: Yup.object().test(
        "test-target-audience",
        "",
        function (values) {
          if (values === undefined) return false;

          return Object.values(values).filter((x) => x).length > 0;
        }
      ),
      modality: Yup.string().label("Modality").required(),
      topicOptions: Yup.object().test("test-topics", "", function (values) {
        if (values === undefined) return false;

        return Object.values(values).filter((x) => x).length > 0;
      }),
    });
  }
}
