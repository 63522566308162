import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  createForwardReferralFormDto,
  ForwardReferralFormDto,
} from "../dtos/ForwardReferralFormDto";
import { ReferralRouteDto } from "../dtos/ReferralRouteDto";
import * as Yup from "yup";
import { useGetAllProviders } from "../../provider/hooks/providerHooks";
import { fnyfsProviderId } from "../../authentication/constants";

const schema = Yup.object().shape({
  providerId: Yup.string().label("Provider").required(),
});

export const useForwardReferralForm = (
  onSubmit: (dto: ForwardReferralFormDto) => Promise<any>,
  currentRoute?: ReferralRouteDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createForwardReferralFormDto(
      currentRoute?.provider?.id || fnyfsProviderId
    ),
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();
  //#endregion
  const clearForm = () => {
    reset(
      createForwardReferralFormDto(
        currentRoute?.provider?.id || fnyfsProviderId
      )
    );
  };

  const handleOnSubmit = handleSubmit(async (dto: ForwardReferralFormDto) => {
    if (dto.providerId === fnyfsProviderId) dto.providerId = undefined;

    await onSubmit(dto);
    clearForm();
  });

  //#region Effects
  useEffect(() => {
    reset(
      createForwardReferralFormDto(
        currentRoute?.provider?.id || fnyfsProviderId
      )
    );
  }, [reset, currentRoute, providers]);

  useEffect(() => {
    getProviders(undefined);
  }, [getProviders]);
  //#endregion

  return {
    state: {
      providers: providers || [],
      getProvidersRequestState,
    },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
