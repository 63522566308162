import {useRequest} from "../../../app/hooks/useRequest";
import {getNirvanaAveragesReportDocumentRequest, getNirvanaImpactReportDocumentRequest} from "../ReportRequests";
import {NirvanaAveragesOrImpactReportParameters} from "../dtos/NirvanaAveragesOrImpactReportParameters";
import {useState} from "react";
import {NirvanaReportType} from "../pages/NirvanaAssessmentAveragesOrImpactReportPage";

export const useNirvanaAveragesOrImpactReportPage = (reportType: NirvanaReportType) => {

  const [params, setParams] = useState<NirvanaAveragesOrImpactReportParameters>(new NirvanaAveragesOrImpactReportParameters())

  const [
    getNirvanaAveragesReport, nirvanaAveragesReport, nirvanaAveragesReportRequestState
  ] = useRequest(getNirvanaAveragesReportDocumentRequest);

  const [
    getNirvanaImpactReport, nirvanaImpactReport, nirvanaImpactReportRequestState
  ] = useRequest(getNirvanaImpactReportDocumentRequest);

  const handleSearch = async (dto: NirvanaAveragesOrImpactReportParameters) => {
    setParams(dto);
    if(reportType === NirvanaReportType.AssessmentAverages) {
      await getNirvanaAveragesReport(dto);
    }
    else if(reportType === NirvanaReportType.Impact) {
      await getNirvanaImpactReport(dto);
    }
  }

  const report = reportType === NirvanaReportType.Impact ? nirvanaImpactReport : nirvanaAveragesReport;
  const reportRequestState = reportType === NirvanaReportType.Impact ? nirvanaImpactReportRequestState : nirvanaAveragesReportRequestState;

  return {
    params,
    report,
    reportRequestState,
    handleSearch
  }

}
