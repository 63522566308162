import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import * as Yup from "yup";
import ControlledPasswordInput from "../../../app/components/inputs/ControlledPasswordInput";

export const passwordResetSchema = Yup.object().shape({
  password: Yup.string().label("Password").required(),
  confirmPassword: Yup.string()
    .label("Password Confirmation")
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

interface Props {
  control: Control<any>;
  disabled?: boolean;
}

const PasswordResetFormFields: React.FC<Props> = ({ control, disabled }) => {
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledPasswordInput
          name="password"
          control={control}
          label="Password"
          disabled={disabled}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledPasswordInput
          name="confirmPassword"
          control={control}
          label="Password Confirmation"
          disabled={disabled}
        />
      </Grid>
    </Fragment>
  );
};

export default PasswordResetFormFields;
