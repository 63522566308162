import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { IntakeDto } from "../dtos/IntakeDto";
import {
  ChangeIntakeDateFormDto,
  createChangeIntakeDateFormDto,
} from "../dtos/ChangeIntakeDateFormDto";
import { intakeAtSchema } from "../components/IntakeFormFields";
import { DischargeDto } from "../../discharges/dtos/DischargeDto";

export const useChangeIntakeDateForm = (
  onSubmit: (dto: ChangeIntakeDateFormDto) => Promise<void>,
  intake?: IntakeDto,
  discharge?: DischargeDto
) => {
  //#region State

  const schema = Yup.object().shape({
    intakeAt: intakeAtSchema.test(
      "test",
      "Intake date must be before the discharge date.",
      (value) => {
        if (value && discharge?.dischargedAt) {
          const intakeAt = new Date(value);
          const dischargedAt = new Date(discharge?.dischargedAt);
          if (intakeAt >= dischargedAt) {
            return false;
          }
        }
        return true;
      }
    ),
  });

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: createChangeIntakeDateFormDto(intake),
    mode: "all",
    resolver: yupResolver(schema),
  });

  //#endregion

  //#region Handlers
  const clearForm = () => {
    reset(createChangeIntakeDateFormDto(intake));
  };
  const handleOnSubmit = handleSubmit(async (dto: ChangeIntakeDateFormDto) => {
    await onSubmit(dto);
  });

  //#endregion

  //#region Effects

  useEffect(() => {
    reset(createChangeIntakeDateFormDto(intake));
  }, [reset, intake]);
  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
