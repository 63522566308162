import { useRequest } from "../../../app/hooks/useRequest";
import {
  createCaseStaffingRequest,
  deleteCaseStaffingRequest,
  deleteCinsPetitionRequest,
  getCaseStaffingRequest,
  updateCaseStaffingRequest,
  upsertCinsPetitionRequest,
} from "../CaseStaffingRequests";

export const useCreateCaseStaffing = () =>
  useRequest(createCaseStaffingRequest);

export const useUpdateCaseStaffing = () =>
  useRequest(updateCaseStaffingRequest);

export const useGetCaseStaffing = () => useRequest(getCaseStaffingRequest);

export const useDeleteCaseStaffing = () =>
  useRequest(deleteCaseStaffingRequest);

export const useUpsertCinsPetition = () =>
  useRequest(upsertCinsPetitionRequest);

export const useDeleteCinsPetition = () =>
  useRequest(deleteCinsPetitionRequest);
