import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { selectHasReadAccess } from "../../authentication/state/authenticationSelectors";
import { locationModalId } from "../../locations/components/LocationFormDialog";
import { programLocationModalId } from "../../locations/components/ProgramLocationFormDialog";
import { terminateProgramLocationModalId } from "../../locations/components/TerminateProgramLocationFormDialog";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { LocationFormDto } from "../../locations/dtos/LocationFormDto";
import { ProgramLocationDto } from "../../locations/dtos/ProgramLocationDto";
import { ProgramLocationExistingFormDto } from "../../locations/dtos/ProgramLocationExistingFormDto";
import { ProgramLocationFormDto } from "../../locations/dtos/ProgramLocationFormDto";
import { ProgramLocationNewFormDto } from "../../locations/dtos/ProgramLocationNewFormDto";
import { TerminateProgramLocationFormDto } from "../../locations/dtos/TerminateProgramLocationFormDto";
import {
  useCreateProgramLocationExisting,
  useCreateProgramLocationNew,
  useTerminateProgramLocation,
  useUpdateLocation,
  useUpdateProgramLocation,
} from "../../locations/hooks/locationHooks";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { programModalId } from "../../programs/components/ProgramFormDialog";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProgramFormDto } from "../../programs/dtos/ProgramFormDto";
import {
  useCreateProgram,
  useUpdateProgram,
} from "../../programs/hooks/programHooks";
import { providerFilledBedDayModalId } from "../../provider-filled-bed-days/components/ProviderFilledBedDayFormDialog";
import {
  ProviderFilledBedDayDto,
  ProviderFilledBedDayFormDto,
} from "../../provider-filled-bed-days/dtos/ProviderFilledBedDayDto";
import {
  useGetProviderFilledBedDays,
  useUpsertProviderFilledBedDays,
} from "../../provider-filled-bed-days/hooks/providerFilledBedDayHooks";
import { providerFiscalYearModalId } from "../../provider-fiscal-year/components/ProviderFiscalYearFormDialog";
import { providerFiscalYearLicensedBedsModalId } from "../../provider-fiscal-year/components/ProviderFiscalYearLicensedBedsFormDialog";
import {
  ProviderFiscalYearCreateOrUpdateDto,
  ProviderFiscalYearDto,
} from "../../provider-fiscal-year/dtos/ProviderFiscalYearDto";
import { ProviderFiscalYearLicensedBedFormDto } from "../../provider-fiscal-year/dtos/ProviderFiscalYearLicensedBedFormDto";
import {
  useUpdateProviderFiscalYear,
  useUpdateProviderFiscalYearLicensedBeds,
} from "../../provider-fiscal-year/hooks/providerFiscalYearHooks";
import { confirmDeactivateStaffMemberDialogId } from "../../staff-members/components/ConfirmDeactivateStaffDialog";
import { staffMemberModalId } from "../../staff-members/components/StaffMemberFormDialog";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import { StaffMemberFormDto } from "../../staff-members/dtos/StaffMemberFormDto";
import {
  useActivateStaff,
  useCreateStaff,
  useDeactivateStaff,
  useUpdateStaff,
} from "../../staff-members/hooks/staffHooks";
import { providerModalId } from "../components/ProviderFormDialog";
import { ProviderDetailDto } from "../dtos/ProviderDetailDto";
import { ProviderFormDto } from "../dtos/ProviderFormDto";
import {
  useCreateProviderLocation,
  useGetProvider,
  useGetProviderFiscalYears,
  useGetProviderStaffMembers,
  useUpdateProvider,
} from "./providerHooks";
import { ContractDto } from "../../contracts/dtos/ContractDto";
import { getContractsRequest } from "../../contracts/ContractRequests";
import { useRequest } from "../../../app/hooks/useRequest";
import { ProgramTypeDeliverableDto } from "../../program-type-deliverables/dtos/ProgramTypeDeliverableDto";
import { useGetProgramTypeDeliverables } from "../../program-type-deliverables/hooks/programTypeDeliverableHooks";

//#region Staff
interface ProviderStaffProps {
  staffMembers: StaffMemberDto[];
  staffLoadingState: RequestStateDto;
  selectedStaff?: StaffMemberDto;
  upsertStaffRequestState: RequestStateDto;
  activateStaffRequestState: RequestStateDto;
  deactivateStaffRequestState: RequestStateDto;
  handleCreateStaffClicked(): void;
  handleEditStaffClicked(staff: StaffMemberDto): void;
  handleUpsertStaff(dto: StaffMemberFormDto): Promise<void>;
  handleActivateStaff(staff: StaffMemberDto): Promise<void>;
  handleDeactivateStaff(): Promise<void>;
  handleDeactivateStaffClicked(staff: StaffMemberDto): void;
}

const useProviderStaff = ({
  refresh,
  id,
}: {
  refresh: () => Promise<void>;
  id: string;
}): ProviderStaffProps => {
  const dispatch = useAppDispatch();
  const [selectedStaff, setSelectedStaff] = useState<StaffMemberDto>();

  const [
    updateStaff,
    ,
    editStaffRequestState,
    clearUpdateStaffMemberRequestError,
  ] = useUpdateStaff();

  const [
    createStaffMember,
    ,
    createStaffRequestState,
    clearCreateStaffMemberError,
  ] = useCreateStaff();

  const [getProviderStaff, providerStaff, getProviderStaffRequestState] =
    useGetProviderStaffMembers();

  useEffect(() => {
    getProviderStaff(id);
  }, [getProviderStaff, id]);

  const [activateStaff, , activateStaffRequestState] = useActivateStaff();

  const [deactivateStaff, , deactivateStaffRequestState] = useDeactivateStaff();

  const openStaffModal = (staffMember?: StaffMemberDto) => {
    clearCreateStaffMemberError();
    clearUpdateStaffMemberRequestError();
    setSelectedStaff(staffMember);
    dispatch(openModal({ modalId: staffMemberModalId }));
  };

  const handleCreateStaffClicked = () => {
    openStaffModal();
  };

  const handleEditStaffClicked = (staffMember: StaffMemberDto) => {
    openStaffModal(staffMember);
  };

  const handleCreateStaff = async (dto: StaffMemberFormDto) => {
    await createStaffMember({ providerId: id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Staff Member Created." }));
      dispatch(closeModal());
    });
    await getProviderStaff(id);
  };

  const handleEditStaff = async (dto: StaffMemberFormDto) => {
    await updateStaff({ id: selectedStaff!.id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Staff Member Updated." }));
      dispatch(closeModal());
    });
    await getProviderStaff(id);
  };

  const handleActivateStaff = async (staff: StaffMemberDto) => {
    await activateStaff(staff.id);
    dispatch(
      addSuccessNotification({
        message: `${staff.firstName} ${staff.lastName} Activated`,
      })
    );
    await getProviderStaff(id);
  };

  const handleDeactivateStaffClicked = (staffMember: StaffMemberDto) => {
    setSelectedStaff(staffMember);
    dispatch(openModal({ modalId: confirmDeactivateStaffMemberDialogId }));
  };

  const handleDeactivateStaff = async () => {
    await deactivateStaff(selectedStaff!.id);
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `${selectedStaff!.firstName} ${
            selectedStaff!.lastName
          } Deactivated`,
        })
      );
      dispatch(closeModal());
    });
    await getProviderStaff(id);
  };

  const handleUpsertStaff = async (dto: StaffMemberFormDto) => {
    selectedStaff != null ? handleEditStaff(dto) : handleCreateStaff(dto);
  };

  return {
    staffMembers: providerStaff || [],
    staffLoadingState: getProviderStaffRequestState,
    selectedStaff,
    upsertStaffRequestState:
      selectedStaff != null ? editStaffRequestState : createStaffRequestState,
    activateStaffRequestState,
    deactivateStaffRequestState,
    handleCreateStaffClicked,
    handleEditStaffClicked,
    handleActivateStaff,
    handleDeactivateStaffClicked,
    handleDeactivateStaff,
    handleUpsertStaff,
  };
};

//#endregion

//#region Fiscal Year
interface ProviderFiscalYearProps {
  selectedFiscalYear?: ProviderFiscalYearDto;
  initialized: boolean;
  providerFiscalYears: ProviderFiscalYearDto[];
  getProviderFiscalYearsRequestState: RequestStateDto;
  updateFiscalYearRequestState: RequestStateDto;
  updateProviderFiscalYearLicensedBedsRequestState: RequestStateDto;
  handleEditFiscalYear(dto: ProviderFiscalYearCreateOrUpdateDto): Promise<void>;
  handleEditFiscalYearClicked(dto: ProviderFiscalYearDto): void;
  handleEditFiscalYearLicensedBedsClicked(dto: ProviderFiscalYearDto): void;
  handleEditFiscalYearLicensedBeds(
    dto: ProviderFiscalYearLicensedBedFormDto
  ): Promise<void>;
}

const useProviderFiscalYear = ({
  id,
}: {
  refresh: () => Promise<void>;
  id: string;
}): ProviderFiscalYearProps => {
  const dispatch = useAppDispatch();
  const [selectedFiscalYear, setSelectedFiscalYear] =
    useState<ProviderFiscalYearDto>();
  const [initialized, setInitialized] = useState(false);

  const [
    getProviderFiscalYears,
    providerFiscalYears,
    getProviderFiscalYearsRequestState,
  ] = useGetProviderFiscalYears();

  const [
    updateFiscalYear,
    ,
    updateFiscalYearRequestState,
    clearUpdateFiscalYearError,
  ] = useUpdateProviderFiscalYear();

  const [
    updateProviderFiscalYearLicensedBeds,
    ,
    updateProviderFiscalYearLicensedBedsRequestState,
    clearUpdateProviderFiscalYearLicensedBedsError,
  ] = useUpdateProviderFiscalYearLicensedBeds();

  const openFiscalYearModal = (dto?: ProviderFiscalYearDto) => {
    setSelectedFiscalYear(dto);
    clearUpdateFiscalYearError();
    dispatch(openModal({ modalId: providerFiscalYearModalId }));
  };

  const handleEditFiscalYearClicked = (dto: ProviderFiscalYearDto) =>
    openFiscalYearModal(dto);

  const handleEditFiscalYear = async (
    dto: ProviderFiscalYearCreateOrUpdateDto
  ) => {
    await updateFiscalYear({ id: selectedFiscalYear!.id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Fiscal Year Updated" }));
      dispatch(closeModal());
    });
    await getProviderFiscalYears(id);
  };

  const handleEditFiscalYearLicensedBedsClicked = (
    dto: ProviderFiscalYearDto
  ) => {
    setSelectedFiscalYear(dto);
    clearUpdateProviderFiscalYearLicensedBedsError();
    dispatch(openModal({ modalId: providerFiscalYearLicensedBedsModalId }));
  };

  const handleEditFiscalYearLicensedBeds = async (
    dto: ProviderFiscalYearLicensedBedFormDto
  ) => {
    await updateProviderFiscalYearLicensedBeds({
      id: selectedFiscalYear!.id,
      dto,
    });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Fiscal Year Updated" }));
      dispatch(closeModal());
    });
    await getProviderFiscalYears(id);
  };

  useEffect(() => {
    try {
      getProviderFiscalYears(id);
    } catch (error) {
    } finally {
      setInitialized(true);
    }
  }, [getProviderFiscalYears, id]);

  return {
    initialized,
    selectedFiscalYear,
    providerFiscalYears: providerFiscalYears || [],
    getProviderFiscalYearsRequestState,
    updateFiscalYearRequestState,
    updateProviderFiscalYearLicensedBedsRequestState,
    handleEditFiscalYearClicked,
    handleEditFiscalYear,
    handleEditFiscalYearLicensedBeds,
    handleEditFiscalYearLicensedBedsClicked,
  };
};

//#endregion

//#region Locations
interface ProviderLocationProps {
  selectedLocation?: LocationDto;
  upsertLocationRequestState: RequestStateDto;
  handleEditLocationClicked(location: LocationDto): void;
  handleCreateLocationClicked(): void;
  handleUpsertLocation(dto: LocationFormDto): Promise<void>;
}

const useProviderLocations = ({
  refresh,
  id,
}: {
  refresh: () => Promise<void>;
  id: string;
}): ProviderLocationProps => {
  const dispatch = useAppDispatch();
  const [selectedLocation, setSelectedLocation] = useState<LocationDto>();

  const [
    createLocation,
    ,
    createLocationRequestState,
    clearCreateLocationError,
  ] = useCreateProviderLocation();

  const [
    updateLocation,
    ,
    updateLocationRequestState,
    clearUpdateLocationError,
  ] = useUpdateLocation();

  const handleUpsertLocationClicked = (dto?: LocationDto) => {
    setSelectedLocation(dto);
    clearCreateLocationError();
    clearUpdateLocationError();
    dispatch(openModal({ modalId: locationModalId }));
  };

  const handleEditLocationClicked = (location: LocationDto) =>
    handleUpsertLocationClicked(location);

  const handleCreateLocationClicked = () => handleUpsertLocationClicked();

  const handleUpsertLocation = async (dto: LocationFormDto) => {
    selectedLocation
      ? await updateLocation({ id: selectedLocation.id, dto })
      : await createLocation({ id, dto });

    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `Location ${selectedLocation ? "Updated" : "Created"}`,
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  return {
    selectedLocation,
    upsertLocationRequestState: selectedLocation
      ? updateLocationRequestState
      : createLocationRequestState,
    handleEditLocationClicked,
    handleCreateLocationClicked,
    handleUpsertLocation,
  };
};

//#endregion

//#region Program Location

interface ProviderProgramProps {
  selectedProgram?: ProgramDto;
  selectedProgramLocation?: ProgramLocationDto;
  upsertProgramRequestState: RequestStateDto;
  terminateProgramLocationRequestState: RequestStateDto;
  createProgramLocationExistingRequestState: RequestStateDto;
  createProgramLocationNewRequestState: RequestStateDto;
  updateProgramLocationRequestState: RequestStateDto;
  handleCreateProgramClicked(): void;
  handleEditProgramClicked(dto: ProgramDto): void;
  handleUpsertProgram(dto: ProgramFormDto): Promise<void>;
  handleCreateProgramLocationClicked(program: ProgramDto): void;
  handleEditProgramLocationClicked(
    program: ProgramDto,
    programLocation: ProgramLocationDto
  ): void;
  handleUpsertProgramLocation(dto: ProgramLocationFormDto): Promise<void>;
  handleTerminateProgramLocationClicked(dto: ProgramLocationDto): void;
  handleTerminateProgramLocation(
    dto: TerminateProgramLocationFormDto
  ): Promise<void>;
}

const useProviderPrograms = ({
  refresh,
  id,
}: {
  refresh: () => Promise<void>;
  id: string;
}): ProviderProgramProps => {
  const dispatch = useAppDispatch();
  const [selectedProgram, setSelectedProgram] = useState<ProgramDto>();
  const [selectedProgramLocation, setSelectedProgramLocation] =
    useState<ProgramLocationDto>();

  //#region Program
  const [createProgram, , createProgramRequestState, clearCreateProgramError] =
    useCreateProgram();
  const [updateProgram, , updateProgramRequestState, clearUpdateProgramError] =
    useUpdateProgram();

  const handleOpenProgramModal = (program?: ProgramDto) => {
    clearUpdateProgramError();
    clearCreateProgramError();
    setSelectedProgram(program);
    dispatch(openModal({ modalId: programModalId }));
  };

  const handleCreateProgramClicked = () => handleOpenProgramModal();

  const handleEditProgramClicked = (program: ProgramDto) =>
    handleOpenProgramModal(program);

  const handleUpsertProgram = async (dto: ProgramFormDto) => {
    selectedProgram
      ? await updateProgram({ id: selectedProgram.id, dto })
      : await createProgram({ providerId: id, dto });

    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `Provider Program ${
            selectedProgram ? "Updated" : "Created"
          }`,
        })
      );
      dispatch(closeModal());
    });
    await refresh();
  };
  //#endregion

  //#region Program Location
  const [
    terminateProgramLocation,
    ,
    terminateProgramLocationRequestState,
    clearTerminateProgramLocationError,
  ] = useTerminateProgramLocation();

  const [
    createProgramLocationNew,
    ,
    createProgramLocationNewRequestState,
    clearCreateProgramLocationNewError,
  ] = useCreateProgramLocationNew();

  const [
    createProgramLocationExisting,
    ,
    createProgramLocationExistingRequestState,
    clearCreateProgramLocationExistingError,
  ] = useCreateProgramLocationExisting();

  const [
    updateProgramLocation,
    ,
    updateProgramLocationRequestState,
    clearUpdateProgramLocationError,
  ] = useUpdateProgramLocation();

  const handleOpenProgramLocationModal = (
    program?: ProgramDto,
    programLocation?: ProgramLocationDto
  ) => {
    clearCreateProgramLocationExistingError();
    clearCreateProgramLocationNewError();
    clearUpdateProgramLocationError();
    setSelectedProgram(program);
    setSelectedProgramLocation(programLocation);
    dispatch(openModal({ modalId: programLocationModalId }));
  };

  const handleCreateProgramLocationClicked = (program: ProgramDto) =>
    handleOpenProgramLocationModal(program);

  const handleEditProgramLocationClicked = (
    program: ProgramDto,
    programLocation: ProgramLocationDto
  ) => {
    handleOpenProgramLocationModal(program, programLocation);
  };

  const handleUpsertProgramLocation = async (dto: ProgramLocationFormDto) => {
    if (dto.isEditing) {
      await updateProgramLocation({
        id: selectedProgramLocation?.id!,
        dto,
      });
      batch(() => {
        dispatch(
          addSuccessNotification({ message: "Program Location Update" })
        );
        dispatch(closeModal());
      });
    } else {
      if (dto.useExisting === "true") {
        await createProgramLocationExisting({
          programId: selectedProgram!.id,
          dto: dto as ProgramLocationExistingFormDto,
        });
      } else {
        await createProgramLocationNew({
          programId: selectedProgram!.id,
          dto: dto as ProgramLocationNewFormDto,
        });
      }
      batch(() => {
        dispatch(
          addSuccessNotification({ message: "Program Location Created" })
        );
        dispatch(closeModal());
      });
    }
    await refresh();
  };

  const handleTerminateProgramLocationClicked = (
    programLocation: ProgramLocationDto
  ) => {
    clearTerminateProgramLocationError();
    setSelectedProgramLocation(programLocation);
    dispatch(openModal({ modalId: terminateProgramLocationModalId }));
  };

  const handleTerminateProgramLocation = async (
    dto: TerminateProgramLocationFormDto
  ) => {
    await terminateProgramLocation({ id: selectedProgramLocation?.id!, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({ message: "Program Location Terminated" })
      );
      dispatch(closeModal());
    });
    await refresh();
  };

  //#endregion

  return {
    selectedProgram,
    selectedProgramLocation,
    upsertProgramRequestState: selectedProgram
      ? updateProgramRequestState
      : createProgramRequestState,
    terminateProgramLocationRequestState,
    createProgramLocationExistingRequestState,
    createProgramLocationNewRequestState,
    updateProgramLocationRequestState,
    handleCreateProgramClicked,
    handleEditProgramClicked,
    handleUpsertProgram,
    handleCreateProgramLocationClicked,
    handleEditProgramLocationClicked,
    handleUpsertProgramLocation,
    handleTerminateProgramLocationClicked,
    handleTerminateProgramLocation,
  };
};

//#endregion

//#region Provider
interface ProviderProps {
  id: string;
  isInitialized: boolean;
  provider?: ProviderDetailDto;
  getProviderRequestState: RequestStateDto;
  updateProviderRequestState: RequestStateDto;
  handleEditProviderClicked(): void;
  handleEditProvider(dto: ProviderFormDto): Promise<void>;
  refresh(): Promise<void>;
}

const useProvider = (): ProviderProps => {
  const dispatch = useAppDispatch();
  const id = useId();
  const [isInitialized, setIsInitialized] = useState(false);
  const [getProvider, provider, getProviderRequestState] = useGetProvider();
  const [
    updateProvider,
    ,
    updateProviderRequestState,
    clearUpdateProviderRequestError,
  ] = useUpdateProvider();

  const refresh = useCallback(async () => {
    await getProvider(id);
  }, [id, getProvider]);

  const handleEditProviderClicked = () => {
    clearUpdateProviderRequestError();
    dispatch(openModal({ modalId: providerModalId }));
  };

  const handleEditProvider = async (dto: ProviderFormDto) => {
    await updateProvider({ id, dto });
    batch(() => {
      dispatch(closeModal());
      dispatch(addSuccessNotification({ message: "Provider Updated" }));
    });
    await getProvider(id);
  };

  useEffect(() => {
    try {
      refresh();
    } catch (error) {
    } finally {
      setIsInitialized(true);
    }
  }, [refresh]);

  return {
    id,
    isInitialized,
    provider,
    getProviderRequestState,
    updateProviderRequestState,
    handleEditProvider,
    handleEditProviderClicked,
    refresh,
  };
};
//#endregion

//#region Provider Filled Bed Days
interface ProviderFilledBedDayProps {
  filledBedDays: ProviderFilledBedDayDto[];
  getFilledBedDaysRequestState: RequestStateDto;
  upsertFilledBedDaysRequestState: RequestStateDto;
  selectedFilledBedDays?: ProviderFilledBedDayDto;
  selectedMonth: number;
  selectedFiscalYear: number;
  handleUpsertFilledBedDaysClicked(
    month: number,
    fiscalYear: number,
    dto?: ProviderFilledBedDayDto
  ): void;
  handleUpsertFilledBedDays(dto: ProviderFilledBedDayFormDto): Promise<void>;
}

const useProviderFilledBedDays = ({
  id,
}: {
  id: string;
}): ProviderFilledBedDayProps => {
  const dispatch = useAppDispatch();

  const [getFilledBedDays, filledBedDays, getFilledBedDaysRequestState] =
    useGetProviderFilledBedDays();
  const [
    upsertFilledBedDays,
    ,
    upsertFilledBedDaysRequestState,
    clearUpsertFilledBedDaysErrors,
  ] = useUpsertProviderFilledBedDays();

  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(0);
  const [selectedFilledBedDays, setSelectedFilledBedDays] =
    useState<ProviderFilledBedDayDto>();

  const handleUpsertFilledBedDaysClicked = (
    month: number,
    fiscalYear: number,
    dto?: ProviderFilledBedDayDto
  ) => {
    clearUpsertFilledBedDaysErrors();
    setSelectedMonth(month);
    setSelectedFiscalYear(fiscalYear);
    setSelectedFilledBedDays(dto);
    dispatch(openModal({ modalId: providerFilledBedDayModalId }));
  };

  const handleUpsertFilledBedDays = async (
    dto: ProviderFilledBedDayFormDto
  ) => {
    await upsertFilledBedDays({ id, dto });

    batch(() => {
      dispatch(addSuccessNotification({ message: "Filled Bed Days Set" }));
      dispatch(closeModal());
    });

    await getFilledBedDays(id);
  };

  useEffect(() => {
    getFilledBedDays(id);
  }, [getFilledBedDays, id]);

  return {
    selectedFiscalYear,
    selectedMonth,
    selectedFilledBedDays,
    filledBedDays: filledBedDays || [],
    upsertFilledBedDaysRequestState,
    getFilledBedDaysRequestState,
    handleUpsertFilledBedDays,
    handleUpsertFilledBedDaysClicked,
  };
};
//#endregion

export interface ProviderDetailPageProps {
  canViewPrograms: boolean;
  canViewFiscalYears: boolean;
  canViewStaff: boolean;
  canViewLocations: boolean;
  canViewOutreachActivities: boolean;
  canViewFilledBedDays: boolean;
  provider: ProviderProps;
  programs: ProviderProgramProps;
  locations: ProviderLocationProps;
  fiscalYears: ProviderFiscalYearProps;
  staffMembers: ProviderStaffProps;
  filledBedDays: ProviderFilledBedDayProps;
  contracts: ContractDto[];
  programTypeDeliverables: ProgramTypeDeliverableDto[];
}

export const useProviderDetailPage = (): ProviderDetailPageProps => {
  //#region State
  const canViewPrograms = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.Program)
  );
  const canViewFiscalYears = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.FiscalYear)
  );
  const canViewStaff = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.StaffMember)
  );
  const canViewLocations = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.Location)
  );
  const canViewOutreachActivities = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.OutreachActivities)
  );

  const canViewFilledBedDays = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.FilledBedDays)
  );
  const providerProps = useProvider();
  const { id, refresh } = providerProps;
  const programProps = useProviderPrograms({ id, refresh });
  const locationProps = useProviderLocations({ id, refresh });
  const fiscalYearProps = useProviderFiscalYear({ id, refresh });
  const staffMemberProps = useProviderStaff({ id, refresh });
  const filledBedDayProps = useProviderFilledBedDays({ id });

  const [getContracts, contracts] = useRequest(getContractsRequest);
  useEffect(() => {
    getContracts(null);
  }, [getContracts]);

  const [getProgramTypeDeliverables, programTypeDeliverables] =
    useGetProgramTypeDeliverables();
  useEffect(() => {
    getProgramTypeDeliverables(null);
  }, [getProgramTypeDeliverables]);
  //#endregion

  return {
    canViewFiscalYears,
    canViewPrograms,
    canViewStaff,
    canViewLocations,
    canViewOutreachActivities,
    canViewFilledBedDays,
    provider: providerProps,
    programs: programProps,
    locations: locationProps,
    fiscalYears: fiscalYearProps,
    staffMembers: staffMemberProps,
    filledBedDays: filledBedDayProps,
    contracts: contracts || [],
    programTypeDeliverables: programTypeDeliverables || [],
  };
};
