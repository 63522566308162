import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  createEndCaseHoldFormDto,
  EndCaseHoldFormDto,
} from "../dtos/EndCaseHoldFormDto";
import { CaseHoldDto } from "../dtos/CaseHoldDto";
import { useEffect } from "react";

const caseHoldSchema = Yup.object().shape({
  // eslint-disable-next-line
  endAt: Yup.date().label("End Date/Time").required().typeError("${label} must be a valid date/time"),
});

export const useEndCaseHoldForm = (
  onSubmit: (dto: EndCaseHoldFormDto) => Promise<void>,
  caseHold?: CaseHoldDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createEndCaseHoldFormDto(caseHold?.startAt || new Date()),
    resolver: yupResolver(caseHoldSchema),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(createEndCaseHoldFormDto(caseHold?.startAt || new Date()));
  };
  const handleOnSubmit = handleSubmit(async (dto: EndCaseHoldFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  useEffect(() => {
    reset(createEndCaseHoldFormDto(caseHold?.startAt || new Date()));
  }, [reset, caseHold]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
