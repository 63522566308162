import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { ProviderFiscalYearDto } from "../../../provider-fiscal-year/dtos/ProviderFiscalYearDto";
import { ProviderDetailDto } from "../../dtos/ProviderDetailDto";
import ProviderFiscalYearListItem, {
  ProviderFiscalYearListLoadingItem,
} from "./ProviderFiscalYearListItem";

interface Props {
  provider: ProviderDetailDto;
  providerFiscalYears: ProviderFiscalYearDto[];
  isLoading: boolean;
  onEdit: (provider: ProviderFiscalYearDto) => void;
  onEditLicensedBeds: (provider: ProviderFiscalYearDto) => void;
}

const ProviderFiscalYearList: React.FC<Props> = ({
  provider,
  providerFiscalYears,
  isLoading,
  onEdit,
  onEditLicensedBeds,
}) => {
  //#region State
  const [expandedId, setExpandedId] = useState<undefined | string>(
    provider?.fiscalYears?.find(
      (x) => x.fiscalYear === new Date().getFullYear()
    )?.id
  );
  //#endregion

  //#region State
  const handleOnChange = (id: string) => {
    setExpandedId(id === expandedId ? undefined : id);
  };
  //#endregion

  if (isLoading)
    return (
      <Grid container spacing={1}>
        {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
          <Grid key={`fiscal-loading-${i}`} item md={12} xs={12}>
            <ProviderFiscalYearListLoadingItem />
          </Grid>
        ))}
      </Grid>
    );
  return (
    <Grid container spacing={1}>
      {[...providerFiscalYears]
        .sort((a, b) => (a.fiscalYear > b.fiscalYear ? -1 : 1))
        .map((fiscalYear) => (
          <Grid item lg={12} xs={12} key={fiscalYear.id}>
            <ProviderFiscalYearListItem
              fiscalYear={fiscalYear}
              onChange={() => handleOnChange(fiscalYear.id)}
              onEdit={() => onEdit(fiscalYear)}
              onEditLicensedBeds={() => onEditLicensedBeds(fiscalYear)}
              expanded={expandedId === fiscalYear.id}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default ProviderFiscalYearList;
