import {TransferableRecordTypes} from "./TransferableRecordTypes";
import {useRequest} from "../../../../app/hooks/useRequest";
import {transferYouthRecordRequest} from "./TransferRequests";
import {useGetYouths} from "../../../youths/hooks/youthHooks";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCallback} from "react";
import {YouthRecordFormDto} from "./YouthRecordFormDto";
import {useAppDispatch} from "../../../../app/hooks";
import {closeModal} from "../../../modal/state/modalSlice";
import {addSuccessNotification} from "../../../notifications/state/notificationSlice";

export const useTransferYouthRecordForm = (caseId: string, recordType: TransferableRecordTypes, refresh: () => Promise<void>) => {

  const dispatch = useAppDispatch();

  const [transferCase, , transferCaseRequestState, clearTransferRequestErrors] = useRequest(transferYouthRecordRequest);

  const [getYouths, selectedYouths, youthRequestState, clearRequestYouthErrors] = useGetYouths();

  const {
    control,
    formState: {isValid},
    reset,
    watch,
    handleSubmit
  } = useForm({
    defaultValues: new YouthRecordFormDto(undefined),
    resolver: yupResolver(YouthRecordFormDto.getSchema()),
    mode: "all",
  });

  const {youthReferenceNumber} = watch();

  const clearForm = useCallback(() => {
    const dto = new YouthRecordFormDto(undefined);
    reset(dto);
    clearTransferRequestErrors(true);
    clearRequestYouthErrors(true);
  }, [reset, clearRequestYouthErrors, clearTransferRequestErrors]);

  const handleOnSubmit = async (youthId: string) => {
    await transferCase({id: caseId, youthId, recordType});
    await refresh();
    clearForm();
    dispatch(
      addSuccessNotification({
        message: `${recordType} Transferred Successfully`,
      })
    );
    dispatch(closeModal())
  };

  const handleYouthLookupClicked = handleSubmit((dto) => {
    getYouths({
      referenceNumber: dto.youthReferenceNumber
    })
  })


  return {
    form: {
      control,
      isValid,
      clearForm
    },
    state: {
      transferCaseRequestState,
      youthReferenceEntered: !!youthReferenceNumber,
      selectedYouths,
      youthRequestState
    },
    handlers: {
      handleYouthLookupClicked,
      transferCase: handleOnSubmit
    }
  }
}
