import { Box, Paper } from "@material-ui/core";
import React from "react";
import Header from "../../../app/components/Header";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import { createDeliverableRateTab } from "../components/admin-sections/deliverable-rates/DeliverableRatesSection";
import { useAdminPage } from "../hooks/useAdminPage";

const AdminPage = () => {
  //#region State
  const { state, handlers } = useAdminPage();
  const {
    tabIndex,
    // Deliverable Rates
    deliverableRates,
    getDeliverableRatesRequestState,
    changeDeliverableRateRequestState,
    deleteDeliverableRateRequestState,
    selectedDeliverableRate,
    canViewDeliverableRate,
  } = state;
  const {
    handleChangeTabIndex,
    // Deliverable Rates
    handleChangeDeliverableRateClicked,
    handleChangeDeliverableRate,
    // Delete Deliverable Rate
    handleDeleteDeliverableRate,
    handleDeleteDeliverableRateClicked,
  } = handlers;
  //#endregion

  const tabs: TabProps[] = [];

  if (canViewDeliverableRate) {
    tabs.push(
      createDeliverableRateTab({
        isLoading: getDeliverableRatesRequestState.isLoading,
        deliverableRates: deliverableRates,
        changeDeliverableTypeRequestState: changeDeliverableRateRequestState,
        deleteDeliverableRequestState: deleteDeliverableRateRequestState,
        selectedDeliverableRate: selectedDeliverableRate,
        onChangeClick: handleChangeDeliverableRateClicked,
        onChange: handleChangeDeliverableRate,
        onDelete: handleDeleteDeliverableRate,
        onDeleteClick: handleDeleteDeliverableRateClicked,
      })
    );
  }

  return (
    <Box height="100%">
      <Header title={"Admin"} />
      <Paper variant="outlined" style={{ height: "88%" }}>
        <TabContainer
          index={tabIndex}
          onIndexChange={handleChangeTabIndex}
          tabs={tabs}
          disabled={getDeliverableRatesRequestState.isLoading}
        />
      </Paper>
    </Box>
  );
};

export default AdminPage;
