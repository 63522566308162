import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ComingSoon } from "../../../app/components/ComingSoon";
import BackButton from "../../../app/components/buttons/BackButton";
const RealTimeCensusReportPage = () => {
  return (
    <div>
      <BackButton route="/reports" />

      <Box display="flex" justifyContent="center" alignItems="center">
        <div>
          <Typography color="textSecondary" style={{ textAlign: "center" }}>
            Placeholder for Real Time Census Report
          </Typography>
          <ComingSoon />
        </div>
      </Box>
    </div>
  );
};

export default RealTimeCensusReportPage;
