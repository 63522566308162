import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { ProviderFiscalYearDto } from "../dtos/ProviderFiscalYearDto";
import { ProviderFiscalYearLicensedBedFormDto } from "../dtos/ProviderFiscalYearLicensedBedFormDto";
import { useProviderFiscalYearLicensedBedsForm } from "../hooks/useProviderFiscalYearLicensedBedForm";

export const providerFiscalYearLicensedBedsModalId =
  "provider-fiscal-year-licensed-beds-form-modal";

interface Props {
  providerFiscalYear?: ProviderFiscalYearDto;
  onSubmit: (dto: ProviderFiscalYearLicensedBedFormDto) => Promise<any>;
  requestState: RequestStateDto;
}

const ProviderFiscalYearLicensedBedsFormDialog: React.FC<Props> = ({
  providerFiscalYear,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    form: { clearForm, isValid, control },
    handlers: { handleSubmit },
  } = useProviderFiscalYearLicensedBedsForm(onSubmit, providerFiscalYear);
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === providerFiscalYearLicensedBedsModalId}
      maxWidth="xs"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Edit Licensed Beds
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledWholeNumberInput
                control={control}
                name="licensedBeds"
                label="Licensed Beds"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProviderFiscalYearLicensedBedsFormDialog;
