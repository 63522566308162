import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHttpError, refreshTokenIfNeeded } from "../../../app/helpers";
import {
  loginRequest,
  logoutRequest,
  refreshTokenRequest,
} from "../AuthenticationRequests";
import { getExpirationDate } from "../dtos/JwtPayloadDto";
import LoginFormDto from "../dtos/LoginFormDto";
import { decryptJwtToUser } from "../dtos/UserDto";
import {
  decryptPermissions,
  UserPermissionDto,
} from "../dtos/UserPermissionDto";
import { AuthenticationState } from "./authenticationSlice";
import { registerUserInformation } from "../../../app/appInsights";
import queryString from "query-string";
import { fnyfsProviderId } from "../constants";

const removeSelectedProviderIdFromUrl = () => {
  const params = queryString.parse(window.location.search);
  if (params.selectedProviderId) {
    window.location.href = queryString.exclude(window.location.href, [
      "selectedProviderId",
    ]);
  }
};

export const getProviderId = () => sessionStorage.getItem("providerId");

export const getIsFnyfsUser = () =>
  sessionStorage.getItem("providerId") === fnyfsProviderId;

export const setProviderId = (value?: string) => {
  value
    ? sessionStorage.setItem("providerId", value)
    : sessionStorage.removeItem("providerId");
};

export const getToken = () => localStorage.getItem("token");
export const setToken = (value?: string) =>
  value
    ? localStorage.setItem("token", value)
    : localStorage.removeItem("token");

const getSelectedProviderId = (permissions: UserPermissionDto) => {
  let selectedProviderId: string | null = getProviderId();

  if (selectedProviderId != null) return selectedProviderId;

  const params = queryString.parse(window.location.search);
  if (params?.selectedProviderId != null)
    return params.selectedProviderId as string;

  if (Object.keys(permissions).length === 1) return Object.keys(permissions)[0];

  return null;
};

const decryptToken = async (token: string) => {
  const user = decryptJwtToUser(token);
  const permissions = decryptPermissions(token);
  const expiration = getExpirationDate(token);
  const selectedProviderId: string | null = getSelectedProviderId(permissions);

  return { user, permissions, token, expiration, selectedProviderId };
};

export const initializeAuthentication = createAsyncThunk(
  "authentication/initialize",
  async (_, { dispatch }): Promise<AuthenticationState> => {
    const token = getToken();
    if (token) {
      await refreshTokenIfNeeded(dispatch);
      const { user, permissions, expiration, selectedProviderId } =
        await decryptToken(token);
      setProviderId(selectedProviderId || undefined);
      removeSelectedProviderIdFromUrl();
      registerUserInformation(user?.email || "unknown", user?.id || "unknown");

      return {
        token,
        isLoading: false,
        user,
        permissions,
        expiration,
        selectedProviderId,
        initialized: true,
      };
    }

    return {
      isLoading: false,
      initialized: true,
      permissions: {},
      selectedProviderId: null,
      token: null,
    };
  }
);

export const login = createAsyncThunk(
  "authentication/login",
  async (dto: LoginFormDto, { rejectWithValue }) => {
    try {
      const response = await loginRequest(dto);
      setToken(response.token);
      const result = await decryptToken(response.token);
      if (result.selectedProviderId !== null) {
        setProviderId(result.selectedProviderId);
      }
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);

export const logout = createAsyncThunk("authentication/logout", async () => {
  setToken();
  setProviderId();
  await logoutRequest();
  return {
    payload: {},
  };
});

export const refresh = createAsyncThunk(
  "authentication/refresh",
  async (_, { rejectWithValue }) => {
    try {
      const response = await refreshTokenRequest();
      setToken(response.token);
      const result = await decryptToken(response.token);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
