import { Backdrop, Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  message?: string;
  open?: boolean;
}

const LoadingOverlay: React.FC<Props> = ({ message, open = true }) => {
  return (
    <Backdrop open={open}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress
          style={{ color: "white", width: "70px", height: "70px" }}
        />
        <Typography variant="h4" style={{ color: "white", marginTop: "16px" }}>
          {message}
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default LoadingOverlay;
