import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { HttpErrorType } from "../../../app/HttpError";
import { IpAddressConfirmationDto } from "../dtos/IpAddressConfirmationDto";
import LoginFormDto from "../dtos/LoginFormDto";
import {
  initializeAuthentication,
  setToken,
} from "../state/authenticationActions";
import { useConfirmIpAddress, useLogin } from "./authenticationHooks";

export const useLoginPage = () => {
  const {
    control: loginControl,
    handleSubmit: handleLoginSubmit,
    getValues: getLoginValues,
  } = useForm<LoginFormDto>({
    defaultValues: { email: "", password: "" },
  });
  const [login, , loginRequestState] = useLogin();
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch = useAppDispatch();
  const query = useQuery();

  const handleLogin = handleLoginSubmit(async (dto: LoginFormDto) => {
    try {
      if (errorMessage) setErrorMessage("");
      const result = await login(dto);
      if (result) setToken(result.token);
    } catch (error) {
    } finally {
      dispatch(initializeAuthentication());
    }
  });

  const [confirmIpAddress, , confirmIpAddressRequestState] =
    useConfirmIpAddress();

  const {
    control: ipAddressControl,
    handleSubmit: ipAddressHandleSubmit,
    reset: ipAddressReset,
  } = useForm({
    defaultValues: new IpAddressConfirmationDto(),
    resolver: yupResolver(IpAddressConfirmationDto.getSchema()),
    mode: "all",
  });

  const handleConfirmIpAddress = ipAddressHandleSubmit(
    async (dto: IpAddressConfirmationDto) => {
      try {
        const result = await confirmIpAddress(dto);
        if (result) setToken(result.token);
      } catch (error) {
      } finally {
        dispatch(initializeAuthentication());
      }
    }
  );

  useEffect(() => {
    if (loginRequestState.error?.type === HttpErrorType.UnconfirmedIpAddress) {
      const { email } = getLoginValues();
      ipAddressReset(new IpAddressConfirmationDto(email));
    }
  }, [loginRequestState, ipAddressReset, getLoginValues]);

  useEffect(() => {
    setErrorMessage(query.message as string);
  }, [query.message, setErrorMessage]);

  return {
    login: {
      handleLogin,
      loginRequestState,
      loginControl,
    },
    ipAddress: {
      handleConfirmIpAddress,
      confirmIpAddressRequestState,
      ipAddressControl,
    },
    errorMessage,
  };
};
