import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum SchoolProgramEnum {
  PrimarySchool = "PrimarySchool",
  Ged = "Ged",
  Vocational = "Vocational",
  SpecialEducation = "SpecialEducation",
  AlternativeOrHomeboundProgram = "AlternativeOrHomeboundProgram",
  PostSecondary = "PostSecondary",
  College = "College",
  NotApplicable = "NotApplicable",
  DoNotKnow = "DoNotKnow",
}

export const schoolProgramTextMap: { [key: string]: string } = {
  [SchoolProgramEnum.PrimarySchool]: "Elem./Middle/High School",
  [SchoolProgramEnum.Ged]: "GED",
  [SchoolProgramEnum.Vocational]: "Vocational",
  [SchoolProgramEnum.SpecialEducation]: "Special Education",
  [SchoolProgramEnum.AlternativeOrHomeboundProgram]:
    "Alternative/Homebound Prog.",
  [SchoolProgramEnum.PostSecondary]: "Post-Secondary",
  [SchoolProgramEnum.College]: "College",
  [SchoolProgramEnum.NotApplicable]: "Not Applicable",
  [SchoolProgramEnum.DoNotKnow]: "Do Not Know",
};

export const schoolProgramOptions: SelectOption[] = [
  { value: "", label: "Select School Program" },
  ...Object.keys(SchoolProgramEnum).map((status) => ({
    value: status,
    label: schoolProgramTextMap[status],
  })),
];
