import { dateToFormString } from "../../../app/helpers";
import { CaseStaffingDto } from "./CaseStaffingDto";

export interface CaseStaffingFormDto {
  staffingOn: string;
}

export const createCaseStaffingFormDto = (
  staffing?: CaseStaffingDto
): CaseStaffingFormDto => {
  if (staffing) {
    const { staffingOn } = staffing;

    return {
      staffingOn: dateToFormString(new Date(staffingOn)) || "",
    };
  }

  return {
    staffingOn: "",
  };
};
