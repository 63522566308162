import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useCallback } from "react"
import WriteAccessLevelWrapper from "../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { useTogglable } from "../../app/hooks/useTogglable";
import { PermissionResourceNameEnum } from "../../features/authentication/enums/PermissionResourceNameEnum";
import { ServicePlanParticipantDto } from "../dto/ServicePlanDto";
import MoreVertIcon from "@material-ui/icons/MoreVert";

interface Props {
    onEditClicked: (participant: ServicePlanParticipantDto) => void;
    onDeleteClicked: (participant: ServicePlanParticipantDto) => void;
    participantDto: ServicePlanParticipantDto;
}

const ServicePlanParticipantAdditionalActions : React.FC<Props> = ({
    onEditClicked,
    onDeleteClicked,
    participantDto
}) => {
    const [isOpen, setIsOpen, toggleIsOpen] = useTogglable(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        toggleIsOpen();
    }

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setIsOpen(false);
    }, [setAnchorEl, setIsOpen])

    const handleEditClicked = () => {
        setIsOpen(false);
        onEditClicked(participantDto);
    }

    const handleDeleteClicked = () => {
        setIsOpen(false);
        onDeleteClicked(participantDto);
    }

    return (
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.ServicePlan}>
            <IconButton aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                open={isOpen}
                keepMounted
                onClose={handleClose}
                id="simple-menu"
                anchorEl={anchorEl}>
                <MenuItem onClick={handleEditClicked} key="participant-edit-menu-item">
                    Edit
                </MenuItem>
                <MenuItem onClick={handleDeleteClicked} key="participant-delete-menu-item">
                    Remove
                </MenuItem>
            </Menu>
        </WriteAccessLevelWrapper>
    );
}

export default ServicePlanParticipantAdditionalActions;
