import { createSlice } from "@reduxjs/toolkit";
import { ProgramTypeDto } from "../dtos/ProgramTypeDto";
import { getProgramTypes } from "./programTypeActions";

export interface ProgramTypeState {
  programTypes: ProgramTypeDto[];
  isLoading: boolean;
}

const initialState: ProgramTypeState = {
  programTypes: [],
  isLoading: false,
};

export const programTypeSlice = createSlice({
  name: "program-type",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProgramTypes.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getProgramTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.programTypes = action.payload;
    });
    builder.addCase(getProgramTypes.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default programTypeSlice.reducer;
