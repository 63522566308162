import React from "react";
import Header from "../../../app/components/Header";
import CaseFilter from "../components/CaseFilter";
import CaseList from "../components/CaseList";
import { useCasesListPage } from "../hooks/useCasesListPage";
import CaseFilterList from "../components/CaseFilterList";
import FilterButton from "../../../app/components/buttons/FilterButton";
import { CaseListDto } from "../dtos/CaseListDto";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import WhiteButton from "../../../app/components/buttons/WhiteButton";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";

const CaseListPage = () => {
  const { state, handlers } = useCasesListPage();
  const {
    casesResult,
    params,
    filterAnchorEl,
    providers,
    programTypes,
    getCasesRequestState,
    getProvidersRequestState,
    getProgramTypesRequestState,
    getProgramsRequestState,
    allowExport,
    exportRecordsLimit,
    exportCasesRequestState,
    providerLocations,
    providerPrograms,
    providerStaffMembers
  } = state;
  const {
    handlePageChange,
    handleCloseFilter,
    handleSearch,
    handleOpenFilterClicked,
    handleProviderChange,
    handleSort,
    handleExportListClicked,
  } = handlers;

  return (
    <div>
      <RequestErrorAlert
        requests={[
          getCasesRequestState,
          getProvidersRequestState,
          getProgramTypesRequestState,
          getProgramsRequestState,
        ]}
      />
      <CaseFilter
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilter}
        params={params}
        onSearch={handleSearch}
        providers={providers}
        providerLocations={providerLocations}
        providerPrograms={providerPrograms}
        providerStaffMembers={providerStaffMembers}
        programTypes={programTypes}
        onProviderChange={handleProviderChange}
      />

      <Header
        title="Cases"
        actions={
        <>
          <SpinnerButton
            content={<WhiteButton onClick={() => handleExportListClicked(params)} disabled={!allowExport}>Export</WhiteButton>}
            showSpinner={exportCasesRequestState.isLoading}
            title={allowExport ? 'Export List to CSV' : `The result set is too large to export. Please apply filters to reduce the number of records below ${exportRecordsLimit}.`}
            disableRipple={!allowExport}
            style={{ backgroundColor: 'transparent' }}
          />
          <FilterButton
            title="Filter List"
            onClick={handleOpenFilterClicked}

          />
        </>
        }
        secondaryActions={
          <CaseFilterList
            params={params}
            onSearch={handleSearch}
            isLoading={getCasesRequestState.isLoading}
            providers={providers}
            programTypes={programTypes}
            providerLocations={providerLocations}
            providerPrograms={providerPrograms}
            providerStaffMembers={providerStaffMembers}
          />
        }
      />
      { exportCasesRequestState.error && <ErrorText error={exportCasesRequestState.error.message} /> }
      <CaseList
        onPageChange={handlePageChange}
        isLoading={getCasesRequestState.isLoading}
        results={casesResult || new PaginationResultDto<CaseListDto>()}
        params={params}
        onSort={handleSort}
      />
    </div>
  );
};

export default CaseListPage;
