import { WaitlistSearchParamsDto } from "./WaitlistSearchParamsDto";

export class WaitlistFilterFormDto {
  providerId: string;
  programTypeId: string;
  programId: string;
  locationId: string;
  youthFirstName: string;
  youthLastName: string;
  screeningNumber: string;
  status: string;

  constructor(dto?: WaitlistSearchParamsDto) {
    this.providerId = dto?.providerId || "";
    this.programId = dto?.programId || "";
    this.locationId = dto?.locationId || "";
    this.programTypeId = dto?.programTypeId || "";
    this.youthFirstName = dto?.youthFirstName || "";
    this.youthLastName = dto?.youthLastName || "";
    this.screeningNumber = dto?.screeningNumber || "";
    this.status = dto?.status || "";
  }
}
