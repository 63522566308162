import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorAlert from "../../../app/components/ErrorAlert";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { LocationDto } from "../dtos/LocationDto";
import { LocationFormDto } from "../dtos/LocationFormDto";
import { useLocationForm } from "../hooks/useLocationForm";
import LocationFormFields from "./LocationFormFields";

export const locationModalId = "location-form-modal";

interface Props {
  onSubmit: (dto: LocationFormDto) => Promise<any>;
  location?: LocationDto;
  requestState: RequestStateDto;
}

const LocationFormDialog: React.FC<Props> = ({
  onSubmit,
  location,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useLocationForm(onSubmit, location);
  const { clearForm, control, isValid } = form;
  const { handleSubmit } = handlers;
  const isLoading = requestState.isLoading;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  //#endregion

  return (
    <Dialog open={modalId === locationModalId} fullWidth maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {location ? "Edit" : "Create"} Location{" "}
          <ErrorAlert error={requestState.error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <LocationFormFields control={control} disabled={isLoading} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LocationFormDialog;
