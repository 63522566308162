import React from "react";
import { MultiValueMetric } from "../dtos/MainDashboardMetricsDto";
import { Box, Typography } from "@material-ui/core";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { Skeleton } from "@material-ui/lab";
import LoadingLegendPlaceholder from "../../../app/components/loading/LoadingLegendPlaceholder";

const COLORS = [
  ColorsEnum.Blue,
  ColorsEnum.DarkBlue,
  ColorsEnum.DarkGrey,
  ColorsEnum.Red,
  ColorsEnum.Orange,
  ColorsEnum.Yellow,
  ColorsEnum.Green,
  ColorsEnum.InfoBlue,
];

const decodeLabels = (
  metric: MultiValueMetric,
  labelMap?: { [index: string]: string }
) => {
  if (!labelMap) return metric;

  return {
    ...metric,
    values: metric.values.map((val) => ({
      ...val,
      values: Object.keys(val.values).reduce(
        (acc, key) => ({ ...acc, [labelMap[key]]: val.values[key] }),
        {}
      ),
    })),
    valueKeys: metric.valueKeys.map((val) => labelMap[val]),
  };
};

export const BarChartMetricPanel: React.FC<{
  metric?: MultiValueMetric;
  labelMap?: { [index: string]: string };
  isLoading?: boolean;
}> = ({ metric, labelMap, isLoading }) => {
  const renderTitle = () => {
    if (isLoading || !metric)
      return (
        <Typography variant="body1">
          <Skeleton variant="text" animation="wave" width={300} />
        </Typography>
      );

    return <Typography variant={"body1"}>{metric.title}</Typography>;
  };

  const renderLegend = () => {
    if (isLoading || !metric)
      return (
        <>
          <LoadingLegendPlaceholder className="mr-2" />
          <LoadingLegendPlaceholder />
        </>
      );

    const sortedValueKeys = metric.valueKeys.sort();
    const colorMap: { [index: string]: string } = sortedValueKeys
      .map((key, index) => ({ key, color: COLORS[index % COLORS.length] }))
      .reduce((acc, val) => ({ ...acc, [val.key]: val.color }), {});

    return (
      <>
        {sortedValueKeys.map((key, index) => (
          <div
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 4,
              marginLeft: index === 0 ? "auto" : 4,
              marginRight: index === sortedValueKeys.length - 1 ? "auto" : 4,
            }}
          >
            <div
              style={{
                height: "90%",
                width: 16,
                backgroundColor: colorMap[key],
                marginRight: 4,
              }}
            >
              &nbsp;
            </div>
            <Typography
              variant={"subtitle2"}
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {key}
            </Typography>
          </div>
        ))}
      </>
    );
  };

  const renderBarChart = () => {
    if (isLoading || !metric)
      return (
        <>
          <ResponsiveContainer width={"90%"} height={"80%"}>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ padding: "-4px" }}
            />
          </ResponsiveContainer>
        </>
      );

    metric = decodeLabels(metric, labelMap);
    const values = metric.values.map((val) => ({
      title: val.title,
      ...metric!.valueKeys.reduce(
        (acc, key) => ({ ...acc, [key]: val.values[key] }),
        {}
      ),
    }));

    const sortedValueKeys = metric.valueKeys.sort();
    const colorMap: { [index: string]: string } = sortedValueKeys
      .map((key, index) => ({ key, color: COLORS[index % COLORS.length] }))
      .reduce((acc, val) => ({ ...acc, [val.key]: val.color }), {});

    return (
      <>
        {values.length > 0 && (
          <ResponsiveContainer width={"90%"} height={"90%"}>
            <BarChart data={values}>
              <YAxis />
              <XAxis dataKey={"title"} />
              <Tooltip />
              {!metric.stack &&
                sortedValueKeys.map((key, index) => (
                  <Bar key={key} dataKey={key} fill={colorMap[key]} />
                ))}
              {metric.stack &&
                sortedValueKeys.map((key, index) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    fill={colorMap[key]}
                    stackId={"a"}
                  />
                ))}
            </BarChart>
          </ResponsiveContainer>
        )}
        {values.length === 0 && <Typography>No data available.</Typography>}
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        {renderBarChart()}
        <Box style={{ overflowY: "hidden" }} display="flex" alignItems="center">
          {renderLegend()}
        </Box>
      </>
    );
  };

  return (
    <Box
      padding="16px"
      display={"flex"}
      flex={1}
      position="relative"
      flexDirection={"column"}
      justifyContent={"space-between"}
      height={320}
      width={300}
    >
      {renderTitle()}
      {renderContent()}
    </Box>
  );
};
