import { coreAgent } from "../../app/api/agent";
import { PaginationResultDto } from "../../app/dtos/PaginationResultDto";
import { RemoveWaitlistFormDto } from "./dtos/RemoveWaitlistFormDto";
import { WaitlistDto } from "./dtos/WaitlistDto";
import { WaitlistSearchParamsDto } from "./dtos/WaitlistSearchParamsDto";

interface RemoveWaitlistParams {
  id: string;
  dto: RemoveWaitlistFormDto;
}

export const getWaitlistsRequest = (searchParams: WaitlistSearchParamsDto) =>
  coreAgent.getRequest<PaginationResultDto<WaitlistDto>>(
    `waitlists?${WaitlistSearchParamsDto.toQueryString(searchParams)}`
  );

export const removeWaitlistRequest = ({ id, dto }: RemoveWaitlistParams) =>
  coreAgent.putRequest<WaitlistDto>(`waitlists/${id}/remove`, dto);
