import { DateTime } from "luxon";
import { useCallback, useState } from "react";
import { areDatesEqual } from "../helpers";

export const useMultiDateSelect = (
  initial: DateTime[] = []
): [
  selectedDates: DateTime[],
  handleToggleDate: (date: DateTime) => void,
  setSelectedDates: (dates: DateTime[]) => void
] => {
  const [selectedDates, setSelectedDates] = useState<DateTime[]>(initial);

  const handleToggleDate = (date: DateTime) => {
    if (selectedDates.find((x) => areDatesEqual(x, date))) {
      setSelectedDates((selectedDates) =>
        selectedDates.filter((x) => !areDatesEqual(x, date))
      );
    } else {
      setSelectedDates((selectedDates) => [...selectedDates, date]);
    }
  };

  const setDates = useCallback(
    (dates: DateTime[]) => setSelectedDates(dates),
    [setSelectedDates]
  );

  return [selectedDates, handleToggleDate, setDates];
};
