import { useCallback } from "react";
import { downloadScreeningsReport } from "../ReportRequests";
import { dateToFormString } from "../../../app/helpers";
import { ScreeningsReportParamsDto } from "../dtos/ScreeningsReportParamsDto";
import { useRequest } from "../../../app/hooks/useRequest";

export const useScreeningsReportPage = () => {
  const [downloadReport, , downloadReportRequestState] = useRequest(
    downloadScreeningsReport
  );
  const viewReport = useCallback(
    (dto: ScreeningsReportParamsDto) => {
      downloadReport({
        dto,
        filename: `ScreeningsReport-${dateToFormString(
          dto.startOn
        )}-${dateToFormString(dto.endOn)}.xlsx`,
      });
    },
    [downloadReport]
  );

  return {
    downloadReportRequestState,
    handleSearch: viewReport,
  };
};
