import React from "react";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export interface LocationDto {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
}
export const formatLocation = (location: LocationDto) => {
  const { address1, address2, city, state, zipCode } = location;
  if (address2) return `${address1}, ${address2}, ${city}, ${state} ${zipCode}`;

  return `${address1}, ${city}, ${state} ${zipCode}`;
};

export const locationToOption = (location: LocationDto): SelectOption => ({
  value: location.id,
  label: `${location.name} - ${location.city}, ${location.state}`,
});

export const locationsToOptions = (locations: LocationDto[]): SelectOption[] => locations.map(locationToOption);

export const getLocationOptions = (locations: LocationDto[], defaultLabel: React.ReactNode = "Select Location") => {
  const options = locationsToOptions(locations).sort((a, b) => ((a.label as string) > (b.label as string) ? 1 : -1));
  return [{ label: defaultLabel, value: "" }, ...options];
};
