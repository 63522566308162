import { CaseDetailDto } from "../dtos/CaseDetailDto";

export enum CaseStatusEnum {
  Open,
  OnHold,
  Closed,
}

export const calculateCaseStatus = (dto: CaseDetailDto): CaseStatusEnum => {
  if (dto.dischargeInfo !== undefined) return CaseStatusEnum.Closed;

  if (
    dto.caseHolds &&
    dto.caseHolds.length > 0 &&
    dto.caseHolds.find((x) => x.endAt === undefined) !== undefined
  )
    return CaseStatusEnum.OnHold;

  return CaseStatusEnum.Open;
};
