import { Box, Paper } from "@material-ui/core";
import React, { createContext, Fragment, useContext } from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import ErrorText from "../../../app/components/ErrorText";
import Header from "../../../app/components/Header";
import TabContainer, { TabProps } from "../../../app/components/TabContainer";
import SnapInSchoolsCycleAdditionalActions from "../components/SnapInSchoolsCycleAdditionalActions";
import SnapInSchoolsCycleDetailModals from "../components/SnapInSchoolsCycleDetailModals";
import { createSnapInSchoolsCycleMoceSectionTab } from "../components/SnapInSchoolsCycleMoceSection";
import { createSnapInSchoolsCycleSessionsSectionTab } from "../components/SnapInSchoolsCycleSessionsSection";
import SnapInSchoolsDetailNotifications from "../components/SnapInSchoolsDetailNotifications";
import { createSnapInSchoolsSatisfactionSurveysSectionTab } from "../components/SnapInSchoolsSatisfactionSurveySection";
import {
  createSnapInSchoolsCycleDetailTab,
  snapInSchoolsCycleDetailTabName,
} from "../dtos/SnapInSchoolsCycleDetails";
import {
  SnapInSchoolsCycleDetailPageProps,
  useSnapInSchoolsCycleDetailPage,
} from "../hooks/useSnapInSchoolsCycleDetailPage";

const SnapInSchoolsDetailPageContext =
  createContext<SnapInSchoolsCycleDetailPageProps>(undefined!);
export const useSnapInSchoolsDetailPageContext = () =>
  useContext(SnapInSchoolsDetailPageContext);

interface Props {
  selectedTab?: string;
}

const SnapInSchoolsCycleDetailPage: React.FC<Props> = ({
  selectedTab = snapInSchoolsCycleDetailTabName,
}) => {
  const values = useSnapInSchoolsCycleDetailPage(selectedTab);
  const { getSnapCycleRequestState, snapCycle } = values.snapCycle;
  const { tabIndex, handleChangeTabIndex } = values.ui;

  const tabs: TabProps[] = [
    createSnapInSchoolsCycleDetailTab(),
    createSnapInSchoolsCycleSessionsSectionTab(),
    createSnapInSchoolsCycleMoceSectionTab(),
    createSnapInSchoolsSatisfactionSurveysSectionTab(),
  ];

  return (
    <SnapInSchoolsDetailPageContext.Provider value={values}>
      <SnapInSchoolsCycleDetailModals />
      <Box height="100%" display="flex" flexDirection="column">
        <Box marginBottom="12px">
          <BackButton
            route="/snap-in-schools"
            text="Back to SNAP in Schools and Communities Cycles"
          />
        </Box>
        <Header
          title={
            <Fragment>
              SNAP In Schools and Communities Cycle (
              {snapCycle?.referenceNumber})
            </Fragment>
          }
          isLoading={getSnapCycleRequestState.isLoading}
          actions={
            <>
              <SnapInSchoolsDetailNotifications />
              <SnapInSchoolsCycleAdditionalActions />
            </>
          }
        />
        <ErrorText error={getSnapCycleRequestState.error?.message} />
        <Paper variant="outlined" style={{ flex: 1 }}>
          <TabContainer
            index={tabIndex}
            onIndexChange={handleChangeTabIndex}
            tabs={tabs}
            disabled={getSnapCycleRequestState.isLoading}
          />
        </Paper>
      </Box>
    </SnapInSchoolsDetailPageContext.Provider>
  );
};

export default SnapInSchoolsCycleDetailPage;
