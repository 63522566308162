import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { CaseStaffingFormDto } from "../dtos/CaseStaffingFormDto";
import * as Yup from "yup";

export const CaseStaffingSchema = Yup.object().shape({
  staffingOn: Yup.string().label("Staffing Held On").required(),
});

interface Props {
  control: Control<CaseStaffingFormDto>;
  isDisabled: boolean;
}

const CaseStaffingFormFields: React.FC<Props> = ({ control, isDisabled }) => {
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="staffingOn"
          label="Staffing Held On*"
          type="date"
          disabled={isDisabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Fragment>
  );
};

export default CaseStaffingFormFields;
