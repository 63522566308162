import { dateToFormString } from "../../../app/helpers";
import { SnapInSchoolsSessionDto } from "./SnapInSchoolsSessionDto";

export interface SnapInSchoolsSessionFormDto {
  curriculumId: string;
  sessionHeldOn: string;
  attendanceLogFilename: string;
  numberOfAttendees: string;
}

export const createSnapInSchoolsSessionFormDto = (
  session?: SnapInSchoolsSessionDto
): SnapInSchoolsSessionFormDto => {
  if (session === undefined)
    return {
      curriculumId: "",
      sessionHeldOn: '',
      attendanceLogFilename: "",
      numberOfAttendees: "",
    };

  const {
    sessionHeldOn,
    curriculum: { id: curriculumId },
    numberOfAttendees,
    attendanceLogFilename: signInSheetFilename,
  } = session;
  return {
    curriculumId,
    sessionHeldOn: dateToFormString(new Date(sessionHeldOn)),
    attendanceLogFilename: signInSheetFilename,
    numberOfAttendees: numberOfAttendees.toString(),
  };
};
