import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { logout } from "../../authentication/state/authenticationActions";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import PasswordResetForm from "../components/PasswordResetForm";
import { ResetPasswordFormDto } from "../dtos/ResetPasswordFormDto";
import { resetPassword } from "../state/accountActions";
import { selectAccountIsLoading } from "../state/accountSelectors";

const PasswordResetPage = () => {
  //#region State
  const query = useQuery();
  const isLoading = useAppSelector(selectAccountIsLoading);
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");
  const history = useHistory();
  //#endregion

  //#region Handlers
  const handleSubmit = async (
    dto: ResetPasswordFormDto,
    callback?: () => void
  ) => {
    try {
      setError("");
      await dispatch(resetPassword(dto)).unwrap();
      if (callback) callback();
      dispatch(addSuccessNotification({ message: "Password Reset" }));
      history.push("/auth/login");
    } catch (error: any) {
      setError(error.message);
      throw error;
    }
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);
  //#endregion

  return (
    <div>
      <Grid container>
        <Grid item md={3} xs={false}></Grid>
        <Grid item md={6} xs={12}>
          {error && (
            <Box marginBottom="8px">
              <Alert severity="error" icon={false}>
                <ul>
                  {error.split("\n").map((e, index) => (
                    <li key={index}>
                      <Typography>{e}</Typography>
                    </li>
                  ))}
                </ul>
              </Alert>
            </Box>
          )}
          <Card>
            <CardContent>
              <Typography variant="h5">Set Password</Typography>
              <PasswordResetForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                query={query}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default PasswordResetPage;
