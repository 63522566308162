import { useEffect } from "react";
import { batch } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { ScreeningFormDto } from "../../screenings/dtos/ScreeningFormDto";
import { useCreateYouthScreening } from "../../youths/hooks/youthHooks";
import { forwardReferralModalId } from "../components/ForwardReferralFormDialog";
import { removeReferralModalId } from "../components/RemoveReferralFormDialog";
import { screeningFromReferralModalId } from "../components/screening-from-referral/ScreeningFromReferralFormDialog";
import { ForwardReferralFormDto } from "../dtos/ForwardReferralFormDto";
import { RemoveReferralFormDto } from "../dtos/RemoveReferralFormDto";
import {
  useForwardReferral,
  useGetReferral,
  useRemoveReferral,
} from "./referralHooks";

export const useReferralDetailPage = () => {
  //#region State
  const id = useId();
  const [getReferral, referral, getReferralRequestState] = useGetReferral();
  const dispatch = useAppDispatch();
  const [
    forwardReferral,
    ,
    forwardReferralRequestState,
    clearForwardReferralErrors,
  ] = useForwardReferral();

  const [
    createScreening,
    ,
    createScreeningRequestState,
    clearCreateScreeningErrors,
  ] = useCreateYouthScreening();

  //#endregion

  //#region Handlers
  const handleForwardReferralClicked = () => {
    clearForwardReferralErrors();
    dispatch(openModal({ modalId: forwardReferralModalId }));
  };

  const handleForwardReferral = async (dto: ForwardReferralFormDto) => {
    await forwardReferral({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Referral Forwarded" }));
      dispatch(closeModal());
    });
    await getReferral(id);
  };

  const handleStartScreeningClicked = () => {
    clearCreateScreeningErrors();
    dispatch(openModal({ modalId: screeningFromReferralModalId }));
  };

  const handleCreateScreening = async (
    youthId: string,
    dto: ScreeningFormDto
  ) => {
    await createScreening({ id: youthId, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Screening Created" }));
      dispatch(closeModal());
    });
    await getReferral(id);
  };

  //#endregion

  //#region Removal Reason
  // Requests
  const [
    removeReferral,
    ,
    removeReferralRequestState,
    clearRemoveReferralErrors,
  ] = useRemoveReferral();
  const handleRemoveReferralClicked = () => {
    clearRemoveReferralErrors();
    dispatch(openModal({ modalId: removeReferralModalId }));
  };

  const handleRemoveReferral = async (dto: RemoveReferralFormDto) => {
    await removeReferral({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Referral Removed" }));
      dispatch(closeModal());
    });
    await getReferral(id);
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    getReferral(id);
  }, [id, getReferral]);
  //#endregion

  return {
    state: {
      referral,
      getReferralRequestState,
      forwardReferralRequestState,
      createScreeningRequestState,
      removeReferralRequestState,
    },
    handlers: {
      handleForwardReferral,
      handleForwardReferralClicked,
      handleStartScreeningClicked,
      handleCreateScreening,
      // Remove Referral
      handleRemoveReferral,
      handleRemoveReferralClicked,
    },
  };
};
