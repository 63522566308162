import { ProviderFiscalYearDto } from "./ProviderFiscalYearDto";

export interface ProviderFiscalYearLicensedBedFormDto {
  licensedBeds: number;
}

export const createProviderFiscalYearLicensedBedFormDto = (
  dto?: ProviderFiscalYearDto
): ProviderFiscalYearLicensedBedFormDto => {
  return { licensedBeds: dto?.licensedBeds || 0 };
};
