import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import { AssessmentDto } from "../dtos/AssessmentDto";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  assessment: AssessmentDto;
}

const AssessmentReferenceNumberLabel: React.FC<Props> = ({
  assessment,
  ...props
}) => {
  return (
    <Typography {...props}>
      #{assessment.referenceNumber.toString().padStart(8, "0")}
    </Typography>
  );
};

export default AssessmentReferenceNumberLabel;
