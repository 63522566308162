import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  createRemoveWaitlistFormDto,
  RemoveWaitlistFormDto,
} from "../dtos/RemoveWaitlistFormDto";
import * as Yup from "yup";

const schema = Yup.object().shape({
  removalReason: Yup.string().label("Removal Reason").required(),
});

export const useRemoveWaitlistForm = (
  onSubmit: (dto: RemoveWaitlistFormDto) => Promise<void>
) => {
  //#region State
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: createRemoveWaitlistFormDto(),
    mode: "all",
    resolver: yupResolver(schema),
  });
  //#endregion

  //#region Handlers
  const clearForm = () => {
    reset(createRemoveWaitlistFormDto());
  };
  const handleOnSubmit = handleSubmit(async (dto: RemoveWaitlistFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
