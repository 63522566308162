import { Box } from "@material-ui/core";
import ErrorAlert from "../../../app/components/ErrorAlert";
import Header from "../../../app/components/Header";
import { useCasesAlertDismissalListPage } from "../hooks/useCaseAlertDismissalsListPage";
import AlertDismissalFilter from "../../alerts/components/AlertDismissalFilter";
import FilterButton from "../../../app/components/buttons/FilterButton";
import CaseAlertDismissalList from "../components/alert-dismissals/CaseAlertDismissalList";
import CaseAlertDismissalFilterList from "../components/alert-dismissals/CaseAlertDismissalFilterList";

const CaseAlertDismissalsListPage = () => {
  const { state, handlers } = useCasesAlertDismissalListPage();
  const {
    params,
    filterAnchorEl,
    dismissals,
    providers,
    programTypes,
    getProvidersRequestState,
    getProgramTypesRequestState,
    getDismissalsRequest,
  } = state;
  const {
    handlePageChange,
    handleCloseFilter,
    handleSearch,
    handleOpenFilterClicked,
  } = handlers;

  return (
    <div>
      <AlertDismissalFilter
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilter}
        params={params}
        onSearch={handleSearch}
        providers={providers}
        programTypes={programTypes}
      />
      <Header
        title="Case Alert Dismissals"
        actions={
          <Box display="flex" alignItems="center">
            <FilterButton
              title="Filter List"
              onClick={handleOpenFilterClicked}
              disabled={getDismissalsRequest.isLoading}
            />
          </Box>
        }
        secondaryActions={
          <CaseAlertDismissalFilterList
            params={params}
            onSearch={handleSearch}
            isLoading={getDismissalsRequest.isLoading}
            providers={providers}
            programTypes={programTypes}
          />
        }
      />
      <ErrorAlert error={getDismissalsRequest.error?.message} />

      <ErrorAlert error={getProvidersRequestState.error?.message} />
      <ErrorAlert error={getProgramTypesRequestState.error?.message} />
      <CaseAlertDismissalList
        dismissals={dismissals || { items: [], page: 0, pageSize: 0, count: 0 }}
        onPageChange={handlePageChange}
        isLoading={getDismissalsRequest.isLoading}
      />
    </div>
  );
};

export default CaseAlertDismissalsListPage;
