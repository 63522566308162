import { TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React from "react";
import StyledLink from "../../../app/components/StyledLink";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { AssessmentDto } from "../dtos/AssessmentDto";
import AssessmentReferenceNumberLabel from "./AssessmentReferenceNumberLabel";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  assessment: AssessmentDto;
}

const AssessmentLink: React.FC<Props> = ({ assessment, children, ...props }) => {
  return (
    <StyledLink
      to={``}
      onClick={(e) => {
        e.preventDefault();
        window.open(
          `${process.env.REACT_APP_ASSESSMENT_CLIENT_URL}/assessment/${
            assessment.id
          }?providerId=${getProviderId()}`
        );
      }}
    >
      {children}
      { !children && <AssessmentReferenceNumberLabel
        {...props}
        color="inherit"
        assessment={assessment}
      />}
    </StyledLink>
  );
};

export default AssessmentLink;
