import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import EditButton from "../../../app/components/buttons/EditButton";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import KeyValueRow from "../../../app/components/KeyValueRow";
import DateLabel from "../../../app/components/labels/DateLabel";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { CaseStaffingDto } from "../dtos/CaseStaffingDto";
import { petitionTypeTextMap } from "../enums/PetitionTypeEnum";
import { reasonForPetitionTextMap } from "../enums/ReasonForPetitionEnum";

export const cinsPetitionDetailsModalId = "cins-petition-details-modal";

interface Props {
  caseStaffing?: CaseStaffingDto;
  onDeleteClicked(): void;
  onEditClicked(): void;
}

const CinsPetitionDetailsDialog: React.FC<Props> = ({
  caseStaffing,
  onDeleteClicked,
  onEditClicked,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  //#endregion

  //#region
  const handleCloseModal = async () => {
    dispatch(closeModal());
  };
  //#endregion

  const renderContent = () => {
    if (caseStaffing == null || caseStaffing.cinsPetitionInfo == null)
      return (
        <Grid item md={12} xs={12}>
          <Typography>No CINS petition added.</Typography>
        </Grid>
      );

    return (
      <>
        <KeyValueRow>
          <span>Opened On</span>
          <DateLabel date={caseStaffing?.cinsPetitionInfo.openedOn} />
        </KeyValueRow>
        {caseStaffing?.cinsPetitionInfo.closedOn && (
          <KeyValueRow>
            <span>Closed On</span>
            <DateLabel date={caseStaffing?.cinsPetitionInfo.closedOn} />
          </KeyValueRow>
        )}
        <KeyValueRow>
          <span>Petition Type</span>
          <span>
            {petitionTypeTextMap[caseStaffing!.cinsPetitionInfo.petitionType]}
          </span>
        </KeyValueRow>
        {caseStaffing?.cinsPetitionInfo.reasonForPetition && (
          <KeyValueRow>
            <span>Reason For Petition</span>
            <span>
              {
                reasonForPetitionTextMap[
                  caseStaffing.cinsPetitionInfo.reasonForPetition
                ]
              }
            </span>
          </KeyValueRow>
        )}
        <KeyValueRow>
          <span>Notes</span>
          <Paper variant="outlined">
            <Box height="200px" padding="6px" overflow="auto">
              <Typography>{caseStaffing.cinsPetitionInfo.notes}</Typography>
            </Box>
          </Paper>
        </KeyValueRow>
      </>
    );
  };

  return (
    <Dialog
      open={modalId === cinsPetitionDetailsModalId}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex">
          CINS Petition
          <Box marginLeft="auto">
            <DeleteAccessLevelWrapper
              name={PermissionResourceNameEnum.CaseStaffing}
            >
              <DeleteButton onClick={onDeleteClicked} />
            </DeleteAccessLevelWrapper>
            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.CaseStaffing}
            >
              <EditButton onClick={onEditClicked} />
            </WriteAccessLevelWrapper>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {renderContent()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Close"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CinsPetitionDetailsDialog;
