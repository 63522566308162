import { datetimeToFormString } from "../../../app/helpers";
import { IntakeDto } from "./IntakeDto";

export interface ChangeIntakeDateFormDto {
  intakeAt: string;
}

export const createChangeIntakeDateFormDto = (
  intake?: IntakeDto
): ChangeIntakeDateFormDto => ({
  intakeAt: intake ? datetimeToFormString(new Date(intake.intakeAt)) : "",
});
