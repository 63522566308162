import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { DeliverableRateDto } from "../dtos/DeliverableRateDto";
import { DeliverableRateFormDto } from "../dtos/DeliverableRateFormDto";
import { useDeliverableRateForm } from "../hooks/useDeliverableRateForm";
import DeliverableRateFormFields from "./DeliverableRateFormFields";

export const deliverableRateFormDialogId = "deliverable-rate-form-dialog";

interface Props {
  onSubmit: (dto: DeliverableRateFormDto) => Promise<void>;
  requestState: RequestStateDto;
  deliverableRate?: DeliverableRateDto;
}

const DeliverableRateFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  deliverableRate,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { form, handlers } = useDeliverableRateForm(onSubmit);

  const { control, isValid, clearForm } = form;
  const { handleSubmit } = handlers;
  const { isLoading, error } = requestState;
  //#endregion

  //#region Handlers
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === deliverableRateFormDialogId}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Change {deliverableRate?.contract?.name}{" "} {deliverableRate?.programType?.name}{" "}
          {deliverableRate?.deliverableType &&
            deliverableRate.deliverableType.name}{" "}
          Rate
          {error && (
            <Box marginTop="8px" color="red">
              <Typography>{error.message}</Typography>
            </Box>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 0, marginBottom: 0 }}>
            <DeliverableRateFormFields
              control={control}
              disabled={isLoading}
              currentFnyfsRate={deliverableRate?.fnyfsAmount}
              currentProviderRate={deliverableRate?.providerAmount}
            />
            <Grid item md={12} xs={12}>
              <Typography color="textSecondary">
                *New rate will go into effect on the first of the month.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeliverableRateFormDialog;
