import React, { useEffect } from "react";
import { NotificationDto } from "../dtos/NotificationDto";
import Alert from "@material-ui/lab/Alert";
import NotificationTypeEnum from "../enums/NotificationTypeEnum";

interface Props {
  notification: NotificationDto;
  removeNotification: (id: string) => void;
}

const Notification: React.FC<Props> = ({
  notification,
  removeNotification,
}) => {
  const getSeverity = (type: NotificationTypeEnum) => {
    switch (type) {
      case NotificationTypeEnum.Success:
        return "success";
      case NotificationTypeEnum.Danger:
        return "error";
      case NotificationTypeEnum.Warning:
        return "warning";
      default:
        return "info";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      removeNotification(notification.id);
    }, notification.duration);
  }, [removeNotification, notification.id, notification.duration]);

  return (
    <Alert
      onClose={() => removeNotification(notification.id)}
      severity={getSeverity(notification.type)}
      style={{ marginBottom: "8px" }}
    >
      {notification.message}
    </Alert>
  );
};

export default Notification;
