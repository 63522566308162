import React, { useEffect, useState } from "react";
import { batch } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { snapGroupCycleModalId } from "../components/SnapGroupCycleFormDialog";
import { snapGroupModalId } from "../components/SnapGroupFormDialog";
import { SnapCurriculumDto } from "../dtos/SnapCurriculumDto";
import { SnapGroupCycleFormDto } from "../dtos/SnapGroupCycleFormDto";
import { SnapGroupFormDto } from "../dtos/SnapGroupFormDto";
import {
  useCreateSnapGroup,
  useDeleteSnapGroupCycle,
  useGetSnapCurriculum,
  useGetSnapGroupCycle,
  useGetSnapGroupCycleAlerts,
  useGetSnapProgramTypes,
  useUpdateSnapGroup,
  useUpdateSnapGroupCycle,
} from "./snapHooks";
import { confirmSnapGroupCycleDeletionDialogId } from "../components/ConfirmSnapGroupCycleDeletionDialog";
import { useHistory, useParams } from "react-router-dom";
import { ProgramTypeSummaryDto } from "../../program-types/dtos/ProgramTypeSummaryDto";
import { SnapGroupSummaryDto } from "../dtos/SnapGroupSummaryDto";
import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { AlertDto } from "../../alerts/dtos/AlertDto";
import { useTabIndex } from "../../../app/hooks/useTabIndex";
import { useTogglable } from "../../../app/hooks/useTogglable";

export const useSnapCycleDetailPage = (
  selectedTab: string
): SnapCycleDetailPageProps => {
  //#region State
  const history = useHistory();
  const id = useId();
  const { groupId } = useParams<{ groupId?: string }>();
  const dispatch = useAppDispatch();
  //#endregion

  const refresh = async () => {
    await getSnapCycle(id);
    await getSnapCycleAlerts(id);
  };

  const refreshAlerts = async () => {
    await getSnapCycleAlerts(id);
  };
  //#region UI
  // State
  const [showDeleted, handleSetShowDeleted] = useTogglable(false);
  const [tabIndex, handleChangeTabIndex] = useTabIndex(selectedTab);

  const uiProps: UiProps = {
    showDeleted,
    tabIndex,
    handleChangeTabIndex,
    handleSetShowDeleted,
  };
  //#endregion

  //#region Alerts
  // Requests
  const [getSnapCycleAlerts, alerts, getSnapCycleAlertsRequestState] =
    useGetSnapGroupCycleAlerts();

  useEffect(() => {
    getSnapCycleAlerts(id);
  }, [getSnapCycleAlerts, id]);

  const alertProps: AlertProps = {
    alerts: alerts || [],
    getAlertsRequestState: getSnapCycleAlertsRequestState,
  };

  //#endregion

  //#region SNAP Cycle
  // Requests
  const [getSnapCycle, snapCycle, getSnapCycleRequestState] =
    useGetSnapGroupCycle();

  const [
    deleteSnapGroupCycle,
    ,
    deleteSnapGroupCycleRequestState,
    clearDeleteCreateSnapGroupCycleErrors,
  ] = useDeleteSnapGroupCycle();

  const [
    updateSnapGroupCycle,
    ,
    updateSnapGroupCycleRequestState,
    clearUpdateSnapGroupCycleError,
  ] = useUpdateSnapGroupCycle();

  // Handlers
  const handleDeleteSnapGroupCycleClicked = () => {
    clearDeleteCreateSnapGroupCycleErrors();
    dispatch(openModal({ modalId: confirmSnapGroupCycleDeletionDialogId }));
  };

  const handleDeleteSnapGroupCycle = async () => {
    if (!snapCycle) return;
    await deleteSnapGroupCycle(snapCycle.id);
    batch(() => {
      dispatch(addSuccessNotification({ message: "SNAP Group Cycle Deleted" }));
      dispatch(closeModal());
    });
    history.push("/snap-cycles");
  };

  const handleEditSnapCycleClicked = () => {
    clearUpdateSnapGroupCycleError();
    dispatch(openModal({ modalId: snapGroupCycleModalId }));
  };

  const handleEditSnapCycle = async (dto: SnapGroupCycleFormDto) => {
    await updateSnapGroupCycle({ id, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "SNAP Group Cycle Updated" }));
      dispatch(closeModal());
    });
    await refresh();
  };

  // Effects
  useEffect(() => {
    getSnapCycle(id);
  }, [id, getSnapCycle]);

  useEffect(() => {
    if (
      groupId &&
      snapCycle?.groups.find((x) => x.id === groupId)?.deletedAt !== undefined
    )
      handleSetShowDeleted(true);
  }, [groupId, snapCycle, handleSetShowDeleted]);

  const snapCycleProps: SnapCycleProps = {
    snapCycle,
    getSnapCycleRequestState,
    deleteSnapGroupCycleRequestState,
    updateSnapGroupCycleRequestState,
    handleDeleteSnapGroupCycleClicked,
    handleDeleteSnapGroupCycle,
    handleEditSnapCycleClicked,
    handleEditSnapCycle,
  };

  //#endregion

  //#region SNAP Groups
  // State
  const [selectedGroupId, setSelectedGroupId] = useState("");

  const [selectedGroup, setSelectedGroup] = useState<SnapGroupSummaryDto>();

  // Requests
  const [
    createSnapGroup,
    ,
    createSnapGroupRequestState,
    clearCreateSnapGroupErrors,
  ] = useCreateSnapGroup();

  const [
    updateSnapGroup,
    ,
    updateSnapGroupRequestState,
    clearUpdateSnapGroupErrors,
  ] = useUpdateSnapGroup();

  const handleCreateSnapGroupClicked = () => {
    handleOpenSnapGroupModal();
  };

  const handleUpdateSnapGroupClicked = (dto: SnapGroupSummaryDto) => {
    handleOpenSnapGroupModal(dto);
  };

  const handleUpsertSnapGroup = async (dto: SnapGroupFormDto) => {
    const result = selectedGroup
      ? await updateSnapGroup({ id: selectedGroup.id, dto })
      : await createSnapGroup({ id, dto });
    batch(() => {
      dispatch(
        addSuccessNotification({
          message: `SNAP Group ${selectedGroup ? "Updated" : "Created"}`,
        })
      );
      dispatch(closeModal());
    });
    if (result != null) setSelectedGroupId(result.id);
    await refresh();
  };

  const handleOpenSnapGroupModal = (dto?: SnapGroupSummaryDto) => {
    setSelectedGroup(dto);
    clearUpdateSnapGroupErrors();
    clearCreateSnapGroupErrors();
    dispatch(openModal({ modalId: snapGroupModalId }));
  };

  const handleChangeSelectedGroup = (id: string) => setSelectedGroupId(id);

  // Effects
  useEffect(() => {
    if (snapCycle && snapCycle.groups.length > 0 && selectedGroupId === "") {
      if (groupId) {
        setSelectedGroupId(groupId);
      } else {
        setSelectedGroupId(
          snapCycle.groups.sort((a, b) =>
            new Date(a.groupHeldOn) > new Date(b.groupHeldOn) ? -1 : 1
          )[0].id
        );
      }
    }
  }, [selectedGroupId, snapCycle, groupId]);

  const snapGroupProps: SnapGroupProps = {
    upsertSnapGroupRequestState: selectedGroup
      ? updateSnapGroupRequestState
      : createSnapGroupRequestState,
    selectedGroupId,
    selectedGroup,
    handleUpsertSnapGroup,
    handleCreateSnapGroupClicked,
    handleUpdateSnapGroupClicked,
    handleChangeSelectedGroup,
  };
  //#endregion

  //#region Curriculum
  // Requests
  const [
    getSnapCurriculum,
    snapCurriculumResult,
    getSnapCurriculumRequestState,
  ] = useGetSnapCurriculum();

  // Effects
  useEffect(() => {
    if (snapCycle) {
      getSnapCurriculum({
        programTypeId: snapCycle.programType.id,
        gender: snapCycle.gender,
      });
    }
  }, [snapCycle, getSnapCurriculum]);

  const curriculumProps: CurriculumProps = {
    curriculum: snapCurriculumResult || [],
    getSnapCurriculumRequestState,
  };
  //#endregion

  //#region Program Types
  // State
  const [programTypes, setProgramTypes] = useState<ProgramTypeSummaryDto[]>([]);

  // Requests
  const [getProgramTypes, programTypesResult, getProgramTypesRequestState] =
    useGetSnapProgramTypes();

  // Effects
  useEffect(() => {
    getProgramTypes(undefined);
  }, [getProgramTypes]);

  useEffect(() => {
    if (programTypesResult) setProgramTypes(programTypesResult);
  }, [programTypesResult, setProgramTypes]);

  const programTypeProps: ProgramTypeProps = {
    programTypes,
    getProgramTypesRequestState,
  };
  //#endregion

  return {
    snapCycle: snapCycleProps,
    group: snapGroupProps,
    programType: programTypeProps,
    curriculum: curriculumProps,
    alert: alertProps,
    ui: uiProps,
    refresh,
    refreshAlerts,
  };
};

export interface SnapCycleDetailPageProps {
  snapCycle: SnapCycleProps;
  group: SnapGroupProps;
  programType: ProgramTypeProps;
  curriculum: CurriculumProps;
  alert: AlertProps;
  ui: UiProps;
  refresh(): Promise<void>;
  refreshAlerts(): Promise<void>;
}

interface SnapCycleProps {
  snapCycle?: SnapGroupCycleDto;
  getSnapCycleRequestState: RequestStateDto;
  deleteSnapGroupCycleRequestState: RequestStateDto;
  updateSnapGroupCycleRequestState: RequestStateDto;
  handleDeleteSnapGroupCycleClicked(): void;
  handleDeleteSnapGroupCycle(): Promise<void>;
  handleEditSnapCycleClicked(): void;
  handleEditSnapCycle(dto: SnapGroupCycleFormDto): Promise<void>;
}

interface SnapGroupProps {
  upsertSnapGroupRequestState: RequestStateDto;
  selectedGroupId: string;
  selectedGroup?: SnapGroupSummaryDto;
  handleUpsertSnapGroup(dto: SnapGroupFormDto): Promise<void>;
  handleCreateSnapGroupClicked(): void;
  handleUpdateSnapGroupClicked(dto: SnapGroupSummaryDto): void;
  handleChangeSelectedGroup(id: string): void;
}

interface UiProps {
  showDeleted: boolean;
  tabIndex: string;
  handleChangeTabIndex(e: React.ChangeEvent<any>, index: string): void;
  handleSetShowDeleted(value: boolean): void;
}

interface ProgramTypeProps {
  programTypes: ProgramTypeSummaryDto[];
  getProgramTypesRequestState: RequestStateDto;
}

interface CurriculumProps {
  curriculum: SnapCurriculumDto[];
  getSnapCurriculumRequestState: RequestStateDto;
}

interface AlertProps {
  alerts: AlertDto[];
  getAlertsRequestState: RequestStateDto;
}
