import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Popover,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import ControlledSwitch from "../../../app/components/inputs/ControlledSwitch";
import {
    LocationDto,
    getLocationOptions
} from "../../locations/dtos/LocationDto";
import ProgramControlledSelect from "../../programs/components/ProgramControlledSelect";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import {
    ProviderDto,
    providerToOptionDtos
} from "../../provider/dtos/ProviderDto";
import { ScreeningSearchParamsDto } from "../dtos/ScreeningSearchParamsDto";
import { useScreeningFilter } from "../hooks/useScreeningFilter";
import { waitlistStatusOptions } from "../../waitlists/enums/WaitlistStatusEnum";
  
  interface Props {
    params: ScreeningSearchParamsDto;
    anchorEl: HTMLElement | null;
    providers: ProviderDto[];
    providerLocations: { [key: string]: LocationDto[] };
    providerPrograms: { [key: string]: ProgramDto[] };
    onClose(): void;
    onSearch(dto: ScreeningSearchParamsDto): void;
    onProviderChange(providerId: string): void;
  }
  
  const ScreeningFilter: React.FC<Props> = ({
    params,
    anchorEl,
    providers,
    providerLocations,
    providerPrograms,
    onProviderChange,
    onClose,
    onSearch,
  }) => {
    
    const [sortedProviders, setSortedProviders] = useState<ProviderDto[]>([]);
    
    //#region State
    const {
      state: { locations, programs, assignedToWaitList },
      form: { control, clearAllFilters },
      handlers: { handleSubmit },
    } = useScreeningFilter(
      onSearch,
      onClose,
      onProviderChange,
      providerLocations,
      providerPrograms,
      params
    );
    //#endregion
  
    useEffect(() => {
      setSortedProviders(providers.sort((a, b) => (a.name > b.name ? 1 : -1)));
    }, [providers, setSortedProviders]);
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={onClose}
      >
        <div style={{ margin: "15px", width: "500px" }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Box display="flex">
                  <Typography variant="h6">Screening Filters</Typography>
                  <Box marginLeft="auto">
                    <IconButton
                      onClick={clearAllFilters}
                      title="Clear All Filters"
                    >
                      <NotInterestedIcon />
                    </IconButton>
                    <IconButton onClick={onClose} title="Close Filter">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
  
              {/* Case # */}
              <Grid item md={12} xs={12}>
                <ControlledInput
                  control={control}
                  name="screeningNumber"
                  label="Screening #"
                  fullWidth
                />
              </Grid>
  
               {/* Provider */}
              <FnAccessWrapper>
                <Grid item md={12} xs={12}>
                  <ControlledSelect
                    control={control}
                    name="providerId"
                    label="Provider"
                    fullWidth
                    options={providerToOptionDtos(sortedProviders, [], "All")}
                  />
                </Grid>
              </FnAccessWrapper>
  
              {/* Youth Name */}
              <Grid item md={6} xs={12}>
                <ControlledInput
                  control={control}
                  name="youthFirstName"
                  label="Youth First Name"
                  fullWidth
                />
              </Grid>
  
              <Grid item md={6} xs={12}>
                <ControlledInput
                  control={control}
                  name="youthLastName"
                  label="Youth Last Name"
                  fullWidth
                />
              </Grid>
                
              <Grid item md={12} xs={12}>
                <Divider />
              </Grid>
  
              <Grid item md={12} xs={12}>
                <Typography color="primary">Screening Date Range</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                <ControlledInput
                    control={control}
                    name="screeningAtStart"
                    label="Start Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </Grid>
                <Grid item md={6} xs={12}>
                <ControlledInput
                    control={control}
                    name="screeningAtEnd"
                    label="End Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </Grid>

                <Grid item md={12} xs={12}>
                    <Divider />
                </Grid>

                
                {/* WaitList Filters */}
                <Grid item md={12} xs={12}>
                  <ControlledSwitch
                    control={control}
                    name="assignedToWaitList"
                    label="Assigned To Wait List"
                    color="primary"
                  />
                </Grid>
                
                {assignedToWaitList && 
                <>
                    <Grid item md={12} xs={12}>
                    <ControlledSelect
                        control={control}
                        name="waitListStatus"
                        label="Status"
                        options={waitlistStatusOptions}
                        fullWidth
                    />
                    </Grid>

                    <Grid item md={12} xs={12}>
                    <ProgramControlledSelect
                        control={control}
                        name="waitListProgramId"
                        label="Program"
                        programs={programs}
                        disabled={programs.length === 0}
                        defaultOptionLabel={"All"}
                        isLoading={false}
                    />
                    </Grid>

                    <Grid item md={12} xs={12}>
                    <ControlledSelect
                        control={control}
                        name="waitListLocationId"
                        label="Location"
                        options={getLocationOptions(locations, "All")}
                        disabled={locations.length === 0}
                        fullWidth
                    />
                    </Grid>
                </>}

              {/* Include Deleted */}
              <FnAccessWrapper>
                <Grid item md={6} xs={12}>
                  <ControlledSwitch
                    control={control}
                    name="includeDeleted"
                    label="Show Deleted"
                    color="primary"
                  />
                </Grid>
              </FnAccessWrapper>
  
              <Grid item md={12} xs={12}>
                <Button
                  startIcon={<SearchIcon />}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Popover>
    );
  };
  
  export default ScreeningFilter;
  