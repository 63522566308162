import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { ProviderSummaryDto } from "../../provider/dtos/ProviderSummaryDto";
import { ModalityEnum } from "../enums/ModalityEnum";
import { TargetAudienceEnum } from "../enums/TargetAudienceEnum";
import { TopicEnum } from "../enums/TopicEnum";

export interface OutreachActivityDto {
  id: string;
  providerId: string;
  title: string;
  activityDate: Date;
  durationHours: number;
  zipCode: string;
  locationDescription: string;
  targetAudiences: TargetAudienceEnum[];
  estimatedPeopleReached: number;
  modality: ModalityEnum;
  topics: TopicEnum[];
  isLegacy: boolean;
}

export interface OutreachActivitySummaryDto {
  id: string;
  provider: ProviderSummaryDto;
  title: string;
  activityDate: Date;
  zipCode: string;
  estimatedPeopleReached: number;
  modality: ModalityEnum;
  topics: TopicEnum[];
}

export class OutreachActivityFilterFormDto {
  dateRangeStart: string;
  dateRangeEnd: string;
  title: string;
  peopleReached: string;
  topic: string;
  modality: string;
  zipCode: string;
  providerId: string;

  constructor(dto?: OutreachActivitySearchParamsDto) {
    this.dateRangeStart = dto?.dateRangeStart || "";
    this.dateRangeEnd = dto?.dateRangeEnd || "";
    this.title = dto?.title || "";
    this.peopleReached = dto?.peopleReached || "";
    this.topic = dto?.topic || "";
    this.modality = dto?.modality || "";
    this.zipCode = dto?.zipCode || "";
    this.providerId = dto?.providerId || "";
  }
}

export class OutreachActivitySearchParamsDto
  implements PaginationSearchParamsDto
{
  dateRangeStart?: string;
  dateRangeEnd?: string;
  title?: string;
  peopleReached?: string;
  topic?: string;
  modality?: string;
  zipCode?: string;
  providerId?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: OutreachActivityFilterFormDto;
    previous?: OutreachActivitySearchParamsDto;
  }) {
    this.dateRangeStart =
      form?.dateRangeStart || (query?.dateRangeStart as string) || "";
    this.dateRangeEnd =
      form?.dateRangeEnd || (query?.dateRangeEnd as string) || "";
    this.title = form?.title || (query?.title as string) || "";
    this.peopleReached =
      form?.peopleReached || (query?.peopleReached as string) || "";
    this.topic = form?.topic || (query?.topic as string) || "";
    this.modality = form?.modality || (query?.modality as string) || "";
    this.zipCode = form?.zipCode || (query?.zipCode as string) || "";
    this.providerId = form?.providerId || (query?.providerId as string) || "";

    this.sortBy = previous?.sortBy || (query?.sortBy as string) || "date";
    this.sortDirection =
      previous?.sortDirection || QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
  }

  public static toQueryString(dto: OutreachActivitySearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
