import { useEffect } from "react";
import { useGetPendingReferralCount } from "../../../features/referrals/hooks/referralHooks";
import { RequestStateDto } from "../../dtos/RequestStateDto";
import { useSelector } from "react-redux";

interface MenuLinksProps {
    pendingReferrals?: number;
    getPendingReferralCountRequestState: RequestStateDto
}

export const useMenuLinks = (): MenuLinksProps => {
    const [getPendingReferralCount, pendingReferrals, getPendingReferralCountRequestState] = useGetPendingReferralCount();

    const selectMenuIsOpen = (state: any) => state.menu.isOpen;
    const menuIsOpen = useSelector(selectMenuIsOpen);

    useEffect(() => {
        if (menuIsOpen) {
            getPendingReferralCount(null);
        }        
    }, [getPendingReferralCount, menuIsOpen]);
    

    return {
        pendingReferrals,
        getPendingReferralCountRequestState,
    };
}