import * as Yup from "yup";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";

export class CumulativeDeliverableReportParamsDto {
  providerId?: string = "";
  compareToProviderId?: string = "";
  throughMonth: string = "";
  fiscalYear: string = "";

  constructor() {
    const providerId = getProviderId();
    if (providerId != null && providerId !== fnyfsProviderId)
      this.providerId = providerId;
  }

  public static getSchema() {
    return Yup.object().shape({
      fiscalYear: Yup.string().min(4).max(4).label("Year").required(),
      throughMonth: Yup.string().required("Month"),
    });
  }
}
