import { LocationDto } from "./LocationDto";

export interface LocationFormDto {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

export const createLocationFormDto = (dto?: LocationDto): LocationFormDto => {
  return {
    name: dto?.name || "",
    address1: dto?.address1 || "",
    address2: dto?.address2 || "",
    city: dto?.city || "",
    state: dto?.state || "FL",
    zipCode: dto?.zipCode || "",
  };
};
