import React from "react";
import { Box } from "@material-ui/core";
import Notification from "./Notification";
import {
  notificationSelector,
  removeNotification,
} from "../state/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

const NotificationsContainer = () => {
  const notifications = useAppSelector(notificationSelector);
  const dispatch = useAppDispatch();

  const handleRemoveNotification = (id: string) => {
    const action = removeNotification(id);
    dispatch(action);
  };

  return (
    <Box
      zIndex="9000"
      position="absolute"
      right="calc(50% - 250px)"
      top={10}
      width="500px"
    >
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          removeNotification={handleRemoveNotification}
        />
      ))}
    </Box>
  );
};

export default NotificationsContainer;
