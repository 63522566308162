import {
  calculateDateDifferenceInDays,
  datetimeToFormString,
} from "../../../app/helpers";
import { ScreeningDto } from "../../screenings/dtos/ScreeningDto";
import { CaseDetailDto } from "./CaseDetailDto";

export class CaseUpdateFormDto {
  intakeAt: string = "";
  serviceStatus: string = "";
  isYouthOnMedication: boolean = false;
  youthMedicationCount?: number = 0;
  intakeStaffId: string = "";
  primaryStaffIds: string[] = [];
  youthDob?: Date;
  screeningId?: string = "";
  startFromScreening: boolean = false;
  isCourtOrdered: boolean = false;
  courtOrderDocumentFilename = "";
  schoolStatus: string = "";
  lastGradeAttended?: string = "";
  currentGrade?: string = "";
  screeningAt?: string = "";
  schoolName?: string = "";
  fundingSource?: string = "";
  familyStructure?: string = "";
  isEdit: boolean = true;

  public static createUpdateForm(caseDto: CaseDetailDto) {
    const {
      program,
      intakeInfo,
      serviceStatus,
      youth,
      screening,
      demographic,
      isCourtOrdered,
      primaryStaff
    } = caseDto;

    const form = new CaseUpdateFormDto();
    form.intakeAt = datetimeToFormString(new Date(intakeInfo.intakeAt));
    form.isYouthOnMedication = intakeInfo.isYouthOnMedication;
    form.youthMedicationCount = intakeInfo.youthMedicationCount || 0;
    form.intakeStaffId = intakeInfo.intakeStaff.id;
    
    form.youthDob = youth.dob;
    form.screeningId = screening?.id || "";
    form.startFromScreening = false;
    form.isCourtOrdered = isCourtOrdered || false;
    form.courtOrderDocumentFilename = caseDto.courtOrderAttachment?.filename;
    form.schoolStatus = intakeInfo.schoolStatus;
    form.lastGradeAttended = intakeInfo.lastGradeAttended || "";
    form.currentGrade = intakeInfo.currentGrade || "";
    form.schoolName = intakeInfo.schoolName || "";
    form.fundingSource = program.fundingSource || "";
    form.familyStructure = demographic?.familyStructure || "";
    form.serviceStatus = serviceStatus;
    form.primaryStaffIds = primaryStaff.map(s => s.id);
    form.isEdit = true;
    return form;
  }
}

export class CaseFormDto extends CaseUpdateFormDto {
  programId: string = "";
  locationId: string = "";
  programLocationId: string = "";

  public static createNewCaseFormDtoFromCaseDetails(caseDto: CaseDetailDto) {
    const {
      program,
      location,
    } = caseDto;

    const form: CaseFormDto = {
      ...CaseUpdateFormDto.createUpdateForm(caseDto),
      programId: program.id,
      locationId: location.id,
      programLocationId: program.locations.find(
        (x) => x.locationId === location.id
      )?.id!,
      isEdit: false
    };

    return form;
  }

  public static createNewCaseFormDto(
    youthDob?: Date,
    programId: string = "",
    locationId: string = "",
    programLocationId: string = "",
    screening?: ScreeningDto
  ) {
    let intakeAt = '';

    if (screening != null) {
      const dateDiff = calculateDateDifferenceInDays(
        new Date(),
        new Date(screening.screeningAt)
      );

      if (dateDiff >= 60)
        intakeAt = datetimeToFormString(new Date(screening.screeningAt));
    }
    const form = new CaseFormDto();
    form.programLocationId = programLocationId;
    form.intakeAt = intakeAt;
    form.serviceStatus = "";
    form.programId = programId;
    form.locationId = locationId;
    form.isYouthOnMedication = false;
    form.youthMedicationCount = 0;
    form.intakeStaffId = "";
    form.primaryStaffIds = [];
    form.youthDob = youthDob;
    form.screeningId = screening?.id || "";
    form.startFromScreening = screening != null;
    form.isCourtOrdered = false;
    form.lastGradeAttended = "";
    form.currentGrade = "";
    form.schoolStatus = "";
    form.screeningAt = "";
    form.schoolName = "";
    form.fundingSource = "";
    form.familyStructure = "";
    form.isEdit = false;
    return form;
  }
}
