import { Box, Menu, MenuItem, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { fnyfsProviderId } from "../../../features/authentication/constants";
import {
  getProviderId,
  setProviderId,
} from "../../../features/authentication/state/authenticationActions";
import {
  selectHasMultipleProviderPermissions,
  selectIsLoggedIn,
  selectSelectedProviderName,
  selectUser,
} from "../../../features/authentication/state/authenticationSelectors";
import { setProvider } from "../../../features/authentication/state/authenticationSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useAnchor } from "../../hooks/useAnchor";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandButton from "../../components/buttons/ExpandButton";
import {closeModal} from "../../../features/modal/state/modalSlice";

const UserMenu = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const providerName = useAppSelector(selectSelectedProviderName);
  const user = useAppSelector(selectUser);
  const providerId = getProviderId();
  const multiplePermissions = useAppSelector(
    selectHasMultipleProviderPermissions
  );

  const history = useHistory();
  const [menuAnchorEl, handleOpenMenuClicked, handleCloseMenuClicked] =
    useAnchor();

  const navigate = (to: string) => () => {
    history.push(to);
    handleCloseMenuClicked();
  };

  const handleChangeProvider = () => {
    dispatch(closeModal())
    setProviderId();
    dispatch(setProvider(null));
    handleCloseMenuClicked();
  };

  const handleLogout = () => {
    dispatch(closeModal())
    history.push("/auth/logout");
    handleCloseMenuClicked();
  }

  const menu = (
    <Menu
      anchorEl={menuAnchorEl}
      keepMounted
      open={Boolean(menuAnchorEl)}
      onClose={handleCloseMenuClicked}
    >
      {multiplePermissions && (
        <MenuItem onClick={handleChangeProvider}>Change Provider</MenuItem>
      )}
      {providerId !== fnyfsProviderId && (
        <MenuItem onClick={navigate(`/provider/${providerId}`)}>
          My Provider
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  if (!isLoggedIn) return <Fragment></Fragment>;

  return (
    <Fragment>
      {menu}
      <Box marginRight="12px" display="flex" alignItems="center">
        <AccountCircleIcon style={{ width: "40px", height: "40px" }} />
        <ExpandButton
          expanded={Boolean(menuAnchorEl)}
          style={{ color: "white", marginRight: "6px" }}
          size="small"
          onClick={handleOpenMenuClicked}
        />
        <Typography>
          {user?.firstName} {user?.lastName}
          <br />
          {providerName}
        </Typography>
      </Box>
    </Fragment>
  );
};

export default UserMenu;
