import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CaseStaffingSchema } from "../components/CaseStaffingFormFields";
import { CaseStaffingDto } from "../dtos/CaseStaffingDto";
import {
  CaseStaffingFormDto,
  createCaseStaffingFormDto,
} from "../dtos/CaseStaffingFormDto";

export const useCaseStaffingForm = (
  onSubmit: (dto: CaseStaffingFormDto) => Promise<void>,
  caseStaffing?: CaseStaffingDto
) => {
  //#region State

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createCaseStaffingFormDto(caseStaffing),
    resolver: yupResolver(CaseStaffingSchema),
    mode: "all",
  });

  const clearForm = () => {
    reset(createCaseStaffingFormDto(caseStaffing));
  };
  const handleOnSubmit = handleSubmit(async (dto: CaseStaffingFormDto) => {
    await onSubmit(dto);
    if (caseStaffing == null) clearForm();
  });

  //#region Effects
  useEffect(() => {
    reset(createCaseStaffingFormDto(caseStaffing));
  }, [reset, caseStaffing]);
  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
