import { Typography } from "@material-ui/core";
import React, { useState, useEffect, Fragment, useRef } from "react";
import { batch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import LoadingOverlay from "../../../app/components/LoadingOverlay";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { logout } from "../../authentication/state/authenticationActions";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { createConfirmEmailDtoFromQuery } from "../dtos/ConfirmEmailDto";
import { confirmEmail } from "../state/accountActions";
import { selectAccountIsLoading } from "../state/accountSelectors";

const AccountConfirmPage = () => {
  //#region State
  const query = useQuery();
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectAccountIsLoading);
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();
  const dto = useRef(createConfirmEmailDtoFromQuery(query));
  //#endregion

  //#region Effects
  useEffect(() => {
    const send = async () => {
      setInitialized(true);
      batch(async () => {
        try {
          const token = await dispatch(confirmEmail(dto.current)).unwrap();
          if (dto.current.returnPasswordToken && token) {
            dispatch(
              addSuccessNotification({
                message: "Email Confirmed",
                duration: 5000,
              })
            );
            history.push(
              `/account/password-reset?email=${encodeURIComponent(
                dto.current.email
              )}&token=${encodeURIComponent(token)}`
            );
          }
        } catch (error: any) {
          setError(error.message);
          throw error;
        }
      });
    };

    send();
  }, [setInitialized, dispatch, history]);

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);
  //#endregion

  if (initialized === false && isLoading)
    return <LoadingOverlay open={isLoading} message="Confirming Email" />;

  if (error)
    return (
      <Fragment>
        <Typography variant="h6">
          Unable to confirm email address: {error}
        </Typography>
        <Typography>
          The confirmation link you clicked is no longer valid. Click{" "}
          <Link
            to={`/account/send-confirm?email=${dto.current.email}&resetPassword=${dto.current.returnPasswordToken}`}
          >
            here
          </Link>{" "}
          to receive an email with a new confirmation link.
        </Typography>
      </Fragment>
    );

  return (
    <div>
      <Typography variant="h6">Email Confirmed!</Typography>
    </div>
  );
};

export default AccountConfirmPage;
