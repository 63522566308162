import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import ErrorText from "../../../app/components/ErrorText";
import DateLabel from "../../../app/components/labels/DateLabel";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { CaseStaffingDto } from "../dtos/CaseStaffingDto";

interface Props {
  onConfirm(): Promise<void>;
  requestState: RequestStateDto;
  caseStaffing?: CaseStaffingDto;
}

export const confirmDeleteCaseStaffingDialogId = "delete-case-staffing-dialog";
const ConfirmDeleteCaseStaffingDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  caseStaffing,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteCaseStaffingDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Case Staffing"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to delete Case Staffing Held On{" "}
        <DateLabel date={caseStaffing?.staffingOn} />? <br />
      </Typography>
      {caseStaffing?.cinsPetitionInfo != null && (
        <Fragment>
          <br />
          <Typography>
            <strong>
              There is a CINS Petition associated with this Case Staffing. If
              you delete the Case Staffing, the CINS Petition will also be
              deleted.
            </strong>
          </Typography>
        </Fragment>
      )}
    </ConfirmDialog>
  );
};

export default ConfirmDeleteCaseStaffingDialog;
