import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectMenuIsOpen } from "../../state/menuSelector";
import { closeMenu, toggleMenu } from "../../state/menuSlice";
import { getProviderId } from "../../../features/authentication/state/authenticationActions";
import { PermissionResourceNameEnum } from "../../../features/authentication/enums/PermissionResourceNameEnum";
import { AccessLevelEnum } from "../../../features/authentication/enums/AccessLevelEnum";
import AccessLevelWrapper from "../../components/access-wrappers/AccessLevelWrapper";
import { fnyfsProviderId } from "../../../features/authentication/constants";
import { useMenuLinks } from "./useMenuLinks";
import YellowAlertLabel from "../../components/labels/YellowAlertLabel";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    zIndex: theme.zIndex.drawer - 1000,
  },
}));

interface NavListItemProps {
  route: string;
  text: string;
  resourceName?: PermissionResourceNameEnum;
  accessLevel?: AccessLevelEnum;
  icon?: React.ReactNode;
  isDivider?: boolean;
  badge?: React.ReactNode;
}

const MenuLinks = () => {

  const { pendingReferrals } = useMenuLinks();

  //#region State
  const history = useHistory();
  const providerId = getProviderId();
  const routes: NavListItemProps[] = [
    {
      text: "Dashboard",
      route: "/dashboards",
    },
    {
      text: "Reports",
      route: "/reports",
    },
    {
      text: "",
      route: "break-1",
      isDivider: true,
    },
    {
      text: "Outreach",
      route: "/outreach-activities",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.OutreachActivities,
    },
    {
      text: "Referrals",
      route: "/referrals",
      badge: (pendingReferrals && pendingReferrals > 0) 
        ? <YellowAlertLabel style={{ color: "black" }} label={`${pendingReferrals} Pending`}></YellowAlertLabel> 
        : <></>
    },
    {
      text: "Youth",
      route: "/youths",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.Youth,
    },
    {
      text: "Screenings",
      route: "/screenings",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.Screening,
    },
    // {
    //   text: "Screenings Waitlist",
    //   route: "/waitlists",
    //   accessLevel: AccessLevelEnum.Read,
    //   resourceName: PermissionResourceNameEnum.Screening,
    // },
    {
      text: "Cases",
      route: "/cases",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.Case,
    },
    {
      text: "SNAP",
      route: "/snap-cycles",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.Case,
    },
    {
      text: "SNAP in Schools & Communities",
      route: "/snap-in-schools",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.SnapInSchools,
    },
    {
      text: "Follow Ups",
      route: "/follow-ups",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.Case,
    },
    {
      text: "",
      route: "break-2",
      isDivider: true,
    },
    {
      text: "Invoices",
      route: "/invoices",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.ProviderInvoice,
    },
    {
      text: "Invoice Event Log",
      route: "/event-log/invoicing",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.InvoicingEventLog,
    },
    {
      text: "",
      route: "break-3",
      isDivider: true,
    },
    {
      text: "Users",
      route: "/users?active=true",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.User,
    },
  ];

  if (providerId === fnyfsProviderId) {
    routes.push({
      text: "Providers",
      route: "/provider",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.Provider,
    });
    routes.push({
      text: "Alert Dismissals",
      route: "/cases/alerts/dismissals",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.Provider,
    });
    routes.push({
      text: "Deliverable Rates",
      route: "/admin",
    });
    routes.push({
      text: "DJJ Related Data",
      route: "/djj-related-data",
      accessLevel: AccessLevelEnum.Read,
      resourceName: PermissionResourceNameEnum.DjjEntries,
    });
  }

  const menuIsOpen = useAppSelector(selectMenuIsOpen);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  //#endregion

  //#region Handlers
  const handleToggleMenu = () => dispatch(toggleMenu());
  const handleNavigation = (route: string) => {
    history.push(route);
    dispatch(closeMenu());
  };

  //#endregion

  //#region UI Helpers
  const NavListItem = ({
    route,
    text,
    accessLevel,
    resourceName,
    icon,
    badge,
    isDivider,    
  }: NavListItemProps) => {
    const item = isDivider ? (
      <Divider />
    ) : (
      <ListItem button onClick={() => handleNavigation(route)}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text} />
        {badge && <ListItemIcon>{badge}</ListItemIcon>}
      </ListItem>
    );

    if (accessLevel && resourceName)
      return (
        <AccessLevelWrapper accessLevel={accessLevel} name={resourceName}>
          {item}
        </AccessLevelWrapper>
      );

    return item;
  };
  //#endregion
  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={menuIsOpen}
        onClose={handleToggleMenu}
        style={{ flexShrink: 0 }}
      >
        <Toolbar />
        <div style={{ width: 300, marginTop: "8px" }}>
          <List>
            {routes.map((route) => (
              <NavListItem {...route} key={route.route} />
            ))}
            <Divider />
          </List>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default MenuLinks;
