import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import { snapInSchoolsCycleSessionsTabName } from "./components/SnapInSchoolsCycleSessionsSection";
import SnapInSchoolsCycleDetailPage from "./pages/SnapInSchoolCyclesDetailPage";
import SnapInSchoolsCycleListPage from "./pages/SnapInSchoolsCycleListPage";

export const createSnapInSchoolsRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, "")}
    component={SnapInSchoolsCycleListPage}
    key="list-snap-in-schools-cycle"
    name={PermissionResourceNameEnum.SnapInSchools}
    exact
  />,
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, ":id")}
    component={SnapInSchoolsCycleDetailPage}
    key="detail-snap-in-schools-cycle"
    name={PermissionResourceNameEnum.SnapInSchools}
    exact
  />,
  <ReadAccessLevelRoute
    path={formatUrl(baseUrl, ":id/sessions")}
    key="detail-snap-in-schools-cycle-sessions"
    name={PermissionResourceNameEnum.SnapInSchools}
    render={() => (
      <SnapInSchoolsCycleDetailPage
        selectedTab={snapInSchoolsCycleSessionsTabName}
      />
    )}
    exact
  />,
];
