import { Route } from "react-router-dom";
import { formatUrl } from "../../app/helpers";
import AccountConfirmPage from "./pages/AccountConfirmPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import SendConfirmationEmailPage from "./pages/SendConfirmationEmailPage";
import SendResetPasswordPage from "./pages/SendResetPasswordPage";

export const createAccountRoutes = (baseUrl: string) => [
  <Route
    path={formatUrl(baseUrl, "password-reset")}
    component={PasswordResetPage}
    key="account-password-reset"
  />,
  <Route
    path={formatUrl(baseUrl, "send-password-reset")}
    component={SendResetPasswordPage}
    key="account-send-password-reset"
  />,
  <Route
    path={formatUrl(baseUrl, "confirm")}
    component={AccountConfirmPage}
    key="account-confirm"
  />,
  <Route
    path={formatUrl(baseUrl, "send-confirm")}
    component={SendConfirmationEmailPage}
    key="account-send-confirm"
  />,
];
