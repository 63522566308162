import { ParsedQuery } from "query-string";

export interface ResetPasswordFormDto {
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
}

export const createResetPasswordFormDto = (
  query: ParsedQuery<string>
): ResetPasswordFormDto => {
  const { email, token } = query;

  return {
    email: email as string,
    token: token as string,
    password: "",
    confirmPassword: "",
  };
};
