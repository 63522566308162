import React, { useCallback, useState } from "react";

export const useTabIndex = (
  index: string
): [string, (e: React.ChangeEvent<any>, index: string) => void] => {
  const [tabIndex, setTabIndex] = useState(index);

  const handleChangeTabIndex = useCallback(
    (e: React.ChangeEvent<any>, index: string) => setTabIndex(index),
    [setTabIndex]
  );

  return [tabIndex, handleChangeTabIndex];
};
