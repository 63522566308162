import { Grid, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import { Control, useFormState, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import ErrorText from "../../../app/components/ErrorText";
import {
  SnapInSchoolsSatisfactionSurveyFormDto,
  SnapInSchoolsSatisfactionSurveyQuestionNames,
  snapInSchoolsSatisfactionSurveyQuestions,
} from "../SnapInSchoolsSatisfactionSurveyDto";
import {
  SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum,
  SnapInSchoolsSatisfactionSurveyHelpsPeopleEnumTextMap,
  SnapInSchoolsSatisfactionSurveyLikertEnumOptions,
  SnapInSchoolsSatisfactionSurveySkillEnum,
  SnapInSchoolsSatisfactionSurveySkillEnumTextMap,
} from "../SnapInSchoolsSatisfcationSurveyEnums";

interface Props {
  control: Control<SnapInSchoolsSatisfactionSurveyFormDto>;
  disabled: boolean;
}

const SnapInSchoolsSatisfactionSurveyFormFields: React.FC<Props> = ({
  control,
  disabled,
}) => {
  const { satisfactionSurveyResponseSet } = useWatch({ control });
  const { snapHelpfulSkillsOptions, snapHelpsPeopleOptions } =
    satisfactionSurveyResponseSet!;
  const { touchedFields } = useFormState({ control });

  const renderOptions = (
    values: string[],
    textMap: { [key: string]: string },
    fieldName: "snapHelpfulSkillsOptions" | "snapHelpsPeopleOptions",
    selectedOptions: { [key: string]: boolean | undefined }
  ) => {
    const wasTouched =
      touchedFields.satisfactionSurveyResponseSet != null &&
      touchedFields.satisfactionSurveyResponseSet![fieldName];
    const hasError =
      selectedOptions != null &&
      Object.values(selectedOptions).filter((x) => x).length !== 3;
    return (
      <>
        <List dense disablePadding style={{ columns: 2 }}>
          {values.map((value) => (
            <ListItem key={value} dense>
              <ControlledCheckbox
                label={textMap[value]}
                control={control}
                disabled={disabled}
                name={`satisfactionSurveyResponseSet.${fieldName}.${value}`}
                color="primary"
                checked={selectedOptions[value]}
              />
            </ListItem>
          ))}
        </List>
        {wasTouched && hasError && (
          <ErrorText error={"Three options must be selected."} />
        )}
      </>
    );
  };

  return (
    <>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="completedOn"
          label="Completed On*"
          type="date"
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="youthNameOrInitials"
          label="Youth Name/Initials*"
          disabled={disabled}
          fullWidth
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <Grid container>
          <Grid item md={8} xs={12}>
            <Typography>
              1.{" "}
              {
                snapInSchoolsSatisfactionSurveyQuestions[
                  SnapInSchoolsSatisfactionSurveyQuestionNames
                    .snapSatisfiedWithProgram
                ]
              }
              *
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <ControlledSelect
              control={control}
              options={SnapInSchoolsSatisfactionSurveyLikertEnumOptions}
              name={`satisfactionSurveyResponseSet.${SnapInSchoolsSatisfactionSurveyQuestionNames.snapSatisfiedWithProgram}`}
              fullWidth
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography>
          2.{" "}
          {
            snapInSchoolsSatisfactionSurveyQuestions[
              SnapInSchoolsSatisfactionSurveyQuestionNames.snapHelpfulSkills
            ]
          }{" "}
          (Please choose three) *
        </Typography>
        {renderOptions(
          Object.values(SnapInSchoolsSatisfactionSurveySkillEnum),
          SnapInSchoolsSatisfactionSurveySkillEnumTextMap,
          "snapHelpfulSkillsOptions",
          snapHelpfulSkillsOptions!
        )}
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography>
          3.{" "}
          {
            snapInSchoolsSatisfactionSurveyQuestions[
              SnapInSchoolsSatisfactionSurveyQuestionNames.snapHelpsPeople
            ]
          }{" "}
          (Please choose three) *
        </Typography>
        {renderOptions(
          Object.values(SnapInSchoolsSatisfactionSurveyHelpsPeopleEnum),
          SnapInSchoolsSatisfactionSurveyHelpsPeopleEnumTextMap,
          "snapHelpsPeopleOptions",
          snapHelpsPeopleOptions!
        )}
      </Grid>
    </>
  );
};

export default SnapInSchoolsSatisfactionSurveyFormFields;
