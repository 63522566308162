export enum AssessmentScoreTypeEnum {
  Dynamic = "Dynamic",
  VictoriesAndResilience = "VictoriesAndResilience",
  Static = "Static",
  ChildhoodAdversityScore = "ChildhoodAdversityScore",
  None = "None",
  EmotionAndAffection = "EmotionAndAffection",
  PlayAndEnjoyment = "PlayAndEnjoyment",
  EmpathyAndUnderstanding = "EmpathyAndUnderstanding",
  Control = "Control",
  DisciplineAndSettingBoundaries = "DisciplineAndSettingBoundaries",
  Pressures = "Pressures",
  SelfAcceptance = "SelfAcceptance",
  LearningAndKnowledge = "LearningAndKnowledge",
  ProtectiveFactors = "ProtectiveFactors",
}

export const assessmentScoreTypeTextMap: { [key: string]: string } = {
  [AssessmentScoreTypeEnum.Dynamic]: "Dynamic",
  [AssessmentScoreTypeEnum.Static]: "Static",
  [AssessmentScoreTypeEnum.VictoriesAndResilience]: "Victories (BCE & PCE)",
  [AssessmentScoreTypeEnum.ChildhoodAdversityScore]: "ACE",
  [AssessmentScoreTypeEnum.None]: "None",
  [AssessmentScoreTypeEnum.EmotionAndAffection]: "Emotion and Affection",
  [AssessmentScoreTypeEnum.PlayAndEnjoyment]: "Play and Enjoyment",
  [AssessmentScoreTypeEnum.EmpathyAndUnderstanding]:
    "Empathy and Understanding",
  [AssessmentScoreTypeEnum.Control]: "Control",
  [AssessmentScoreTypeEnum.DisciplineAndSettingBoundaries]:
    "Discipline and Setting Boundaries",
  [AssessmentScoreTypeEnum.Pressures]: "Pressures",
  [AssessmentScoreTypeEnum.SelfAcceptance]: "Self-Acceptance",
  [AssessmentScoreTypeEnum.LearningAndKnowledge]: "Learning and Knowledge",
  [AssessmentScoreTypeEnum.ProtectiveFactors]: "Protective",
};
