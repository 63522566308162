import * as Yup from "yup";

export class IpAddressConfirmationDto {
  public email: string;
  public pin: string = "";

  constructor(email?: string) {
    this.email = email || "";
  }

  public static getSchema() {
    return Yup.object().shape({
      email: Yup.string().label("Email").required().email(),
      pin: Yup.string().label("PIN").required().min(4).max(4),
    });
  }
}
