import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { ColorsEnum } from "../../enums/ColorsEnum";

interface Props extends ButtonProps {
  text?: string;
}

const DeleteButton: React.FC<Props> = ({
  style,
  text = "DELETE",
  ...props
}) => {
  return (
    <Button {...props} style={{ color: ColorsEnum.Red, ...style }}>
      {text}
    </Button>
  );
};

export default DeleteButton;
