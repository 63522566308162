import { Box, List, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../../app/components/buttons/AddButton";
import ListDivider from "../../../../app/components/ListDivider";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { YouthContactSummaryDto } from "../../../youth-contacts/dtos/YouthContactSummaryDto";
import { YouthDto } from "../../dto/YouthDto";
import YouthContactListItem from "../youth-contacts/YouthContactListItem";

interface Props {
  youth: YouthDto;
  isLoading: boolean;
  onCreateClick(): void;
  onEditClick(dto: YouthContactSummaryDto): void;
  onDeleteClick(dto: YouthContactSummaryDto): void;
}

export const youthContactsSectionName = "youth-contactss-section";

const YouthContactsSection: React.FC<Props> = ({
  youth,
  isLoading,
  onCreateClick,
  onEditClick,
  onDeleteClick,
}) => {
  return (
    <Fragment>
      <Box display="flex">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Youth}>
          <AddButton
            text="Create New Contact"
            variant="contained"
            color="primary"
            onClick={onCreateClick}
          />
        </WriteAccessLevelWrapper>
      </Box>
      <Box marginTop="12px">
        {youth.contacts.length === 0 && (
          <Typography>No contacts created.</Typography>
        )}
        <List>
          {youth.contacts.map((contact, index) => (
            <Fragment key={contact.id}>
              <YouthContactListItem
                contact={contact}
                onEditClick={() => onEditClick(contact)}
                onDeleteClick={() => onDeleteClick(contact)}
              />
              <ListDivider index={index} length={youth.contacts.length} />
            </Fragment>
          ))}
        </List>
      </Box>
    </Fragment>
  );
};

export default YouthContactsSection;
