import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export interface SnapInSchoolsCurriculumDto {
  id: string;
  description: string;
  order: number;
}

export const snapInSchoolsCurriculumToOption = (
  dto: SnapInSchoolsCurriculumDto
): SelectOption => ({ value: dto.id, label: dto.description });
export const snapInSchoolsCurriculumToOptions = (
  dtos: SnapInSchoolsCurriculumDto[]
): SelectOption[] => [
  { value: "", label: "Select Session Name" },
  ...dtos
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(snapInSchoolsCurriculumToOption),
];
