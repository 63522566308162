import React, {useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

export const BrowserCompatibilityWarning: React.FC = () => {
  const UA = navigator.userAgent;

  // Edge
  // Windows: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36 Edg/108.0.1462.54
  // macOS:   Mozilla/5.0 (Macintosh; Intel Mac OS X 13_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36 Edg/108.0.1462.54
  // iOS:     Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.0 EdgiOS/108.1462.62 Mobile/15E148 Safari/605.1.15
  // Android: Mozilla/5.0 (Linux; Android 10; HD1913) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.5359.128 Mobile Safari/537.36 EdgA/108.0.1462.48

  // Chrome
  // Windows: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36
  // macOS:   Mozilla/5.0 (Macintosh; Intel Mac OS X 13_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36
  // iOS:     Mozilla/5.0 (iPad; CPU OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/108.0.5359.112 Mobile/15E148 Safari/604.1
  // Android: Mozilla/5.0 (Linux; Android 10) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.5359.128 Mobile Safari/537.36


  const isSupportedBrowser = UA.match(/Chrome|CriOS|EdgiOS|Edg/i) != null;

  const [displayBanner, setDisplayBanner] = useState(!isSupportedBrowser);

  const chromeLink = <a href={"https://www.google.com/chrome/"}>Download Google Chrome</a>;
  const edgeLink = <a href={"https://www.microsoft.com/en-us/edge"}>Download Microsoft Edge</a>;

  return (<>
    <Dialog open={displayBanner}>
      <DialogTitle>Unsupported Browser</DialogTitle>
      <DialogContent>
        <Box display={"flex"} flexDirection={"column"}>
          <p>
            The browser you are currently using is not fully supported by NetMIS. There may be some functionality that will not work correctly.
          </p>
          <p>
            NetMIS supports current versions of Google Chrome or Microsoft Edge.
          </p>
          <p>
            {chromeLink}
          <br />
            {edgeLink}
          </p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDisplayBanner(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  </>)
}
