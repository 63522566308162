import {SelectOption} from "../../../app/components/inputs/ControlledSelect";
import {Typography} from "@material-ui/core";
import React from "react";

export enum FollowUpInterval {
  ThirtyDay = "ThirtyDay",
  SixtyDay = "SixtyDay",
  NinetyDay = "NinetyDay"
}

export const followUpIntervalTextMap: { [key: string]: string } = {
  [FollowUpInterval.ThirtyDay]: "30 Day",
  [FollowUpInterval.SixtyDay]: "60 Day",
  [FollowUpInterval.NinetyDay]: "90 Day"
};

export const intervalSelectOptions: SelectOption[] = [
  { label: "Select Follow Up Interval", value: "" },
  {
    label: <Typography>30 Day</Typography>,
    value: FollowUpInterval.ThirtyDay,
  },
  {
    label:  <Typography>60 Day</Typography>,
    value: FollowUpInterval.SixtyDay,
  },
  {
    label: <Typography>90 Day</Typography>,
    value: FollowUpInterval.NinetyDay,
  },
];
