import {
  Box,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React, {Fragment} from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import EditButton from "../../../app/components/buttons/EditButton";
import DateLabel from "../../../app/components/labels/DateLabel";
import YesNoLabel from "../../../app/components/labels/YesNoLabel";
import {TabProps} from "../../../app/components/TabContainer";
import {ProgramTypeIdEnum} from "../../../app/enums/ProgramTypeIdEnum";
import {createNumberRange} from "../../../app/helpers";
import {PermissionResourceNameEnum} from "../../authentication/enums/PermissionResourceNameEnum";
import {SnapGroupSummaryDto} from "../dtos/SnapGroupSummaryDto";
import {useSnapGroupSection} from "../hooks/useSnapGroupSection";
import AddCasesToGroupFormDialog from "./AddCasesToGroupFormDialog";
import SnapGroupFidelityFormDialog from "./snap-group-fidelity/SnapGroupFidelityFormDialog";
import ConfirmSnapCaseRemovalDialog from "./ConfirmSnapCaseRemovalDialog";
import ConfirmSnapGroupRemovalDialog from "./ConfirmSnapGroupRemovalDialog";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import StyledLink from "../../../app/components/StyledLink";
import {useLocation} from "react-router-dom";
import NameLabel from "../../../app/components/labels/NameLabel";
import {useSnapGroupCycleDetailPageContext} from "../pages/SnapGroupCycleDetailPage";
import {AlertDto} from "../../alerts/dtos/AlertDto";
import {ColorsEnum} from "../../../app/enums/ColorsEnum";
import {AlertTypeEnum, SnapGroupContextAlertTypeMessages,} from "../../alerts/enums/AlertTypeEnum";
import WarningIcon from "../../../app/components/icons/WarningIcon";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";

interface Props {
  group: SnapGroupSummaryDto;
  alerts: AlertDto[];
}

const SnapGroupSection: React.FC<Props> = ({ group, alerts }) => {
  const context = useSnapGroupCycleDetailPageContext();
  const { handleUpdateSnapGroupClicked } = context.group;
  const location = useLocation();

  const { state, handlers } = useSnapGroupSection();
  const {
    snapCycle,
    snapGroup,
    getSnapGroupRequestState,
    addCasesToSnapGroupRequestState,
    upsertSnapGroupFidelityRequestState,
    removeSnapGroupCaseRequestState,
    removeSnapGroupRequestState,
    showDeleted,
    selectedGroupCase,
    toggleMadeCompliancePhoneCallRequestState,
    canSetMadeCompliancePhoneCall,
  } = state;
  const {
    handleAddCases,
    handleAddCasesClicked,
    // SNAP Group Fidelity
    handleUpsertSnapGroupFidelity,
    handleUpsertSnapGroupFidelityClicked,
    handleRemoveSnapGroupCase,
    handleRemoveSnapGroupCaseClicked,
    handleDeleteSnapGroup,
    handleDeleteSnapGroupClicked,
    handleToggleComplaincePhoneCall,
  } = handlers;

  const createRow = (label: React.ReactNode, content: React.ReactNode) => {
    return (
      <Fragment>
        <Grid item md={3} xs={4}>
          <Typography component="span">{label}:</Typography>
        </Grid>
        <Grid item md={9} xs={8}>
          <Typography component="span">{content}</Typography>
        </Grid>
      </Fragment>
    );
  };

  const alertLabel = alerts.find(
    (x) => x.type === AlertTypeEnum.SnapGroupFidelityRequired
  ) && (
    <Grid item md={12} xs={12}>
      <Typography style={{ color: ColorsEnum.ValidationRed }}>
        {SnapGroupContextAlertTypeMessages[
          AlertTypeEnum.SnapGroupFidelityRequired
        ](group)}
      </Typography>
    </Grid>
  );
  const fidelitySection = (
    <Box marginTop="8px" marginBottom="8px">
      <Box display="flex" alignItems="center" justifyContent={"space-between"}>
        <Typography color="primary">Fidelity Score</Typography>
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <EditButton
            text={`${
              snapGroup?.fidelityInfo !== undefined ? "Update" : "Add"
            } Fidelity Score`}
            disabled={upsertSnapGroupFidelityRequestState.isLoading}
            onClick={handleUpsertSnapGroupFidelityClicked}
          />
        </WriteAccessLevelWrapper>
      </Box>
      <Divider />
      <Grid container style={{ marginTop: "12px" }}>
        {alertLabel}
        {snapGroup?.fidelityInfo !== undefined && (
          <Fragment>
            {createRow(
              "Completed On",
              <DateLabel date={snapGroup.fidelityInfo.performedOn} />
            )}
            {createRow("Youth Score", snapGroup.fidelityInfo.youthScore)}

            {snapCycle?.programType.id !== ProgramTypeIdEnum.SnapYouthJustice &&
              createRow("Parent Score", snapGroup.fidelityInfo.parentScore)}
          </Fragment>
        )}
        {snapGroup?.fidelityInfo == null && alertLabel == null && (
          <Grid item md={12} xs={12}>
            No Fidelity Score Provided
          </Grid>
        )}
      </Grid>
    </Box>
  );

  const groupInfoSection = (
    <Box marginBottom="12px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="4px"
      >
        <Typography color="primary">Group Info</Typography>
        <div>
          <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Snap}>
            <EditButton
              text={"Edit Group"}
              title="Edit SNAP Group"
              onClick={() => handleUpdateSnapGroupClicked(group)}
            />
          </WriteAccessLevelWrapper>
          <DeleteAccessLevelWrapper name={PermissionResourceNameEnum.Snap}>
            {group.deletedAt ? (
              <></>
            ) : (
              <DeleteButton
                text="Delete Group"
                onClick={handleDeleteSnapGroupClicked}
              />
            )}
          </DeleteAccessLevelWrapper>
        </div>
      </Box>
      <Divider />
      <Grid container style={{ marginTop: "12px" }}>
        {createRow("Group Date", <DateLabel date={group.groupHeldOn} />)}
        {createRow("Session Name", snapGroup?.snapCurriculum.description || "")}
        {createRow("Makeup Group", <YesNoLabel value={group.isMakeupGroup} />)}
      </Grid>
    </Box>
  );

  return (
    <Box>
      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <AddCasesToGroupFormDialog
          group={snapGroup}
          snapCycle={snapCycle!}
          onSubmit={handleAddCases}
          requestState={addCasesToSnapGroupRequestState}
        />

        <SnapGroupFidelityFormDialog
          onSubmit={handleUpsertSnapGroupFidelity}
          snapGroup={snapGroup}
          requestState={upsertSnapGroupFidelityRequestState}
          snapCycle={snapCycle}
        />
        <ConfirmSnapGroupRemovalDialog
          onConfirm={handleDeleteSnapGroup}
          requestState={removeSnapGroupRequestState}
          group={group}
        />
        <ConfirmSnapCaseRemovalDialog
          caseDto={selectedGroupCase}
          onConfirm={handleRemoveSnapGroupCase}
          requestState={removeSnapGroupCaseRequestState}
        />
      </WriteAccessLevelWrapper>

      {groupInfoSection}

      {fidelitySection}
      <RequestErrorAlert
        requests={[
          getSnapGroupRequestState,
          toggleMadeCompliancePhoneCallRequestState,
        ]}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="12px"
        marginTop="12px"
      >
        <Typography color="primary">Youth</Typography>

        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <AddButton
            text="Add Youth"
            color="primary"
            variant="contained"
            disabled={getSnapGroupRequestState.isLoading}
            onClick={handleAddCasesClicked}
          />
        </WriteAccessLevelWrapper>
      </Box>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Case #</TableCell>
              <TableCell width="200">Made Compliance Phone Call</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!getSnapGroupRequestState.isLoading && (
              <Fragment>
                {snapGroup &&
                  snapGroup.cases.filter(
                    (c) => c.deletedAt == null || showDeleted
                  ).length > 0 &&
                  snapGroup.cases
                    .filter((c) => c.deletedAt == null || showDeleted)
                    .map((caseDto) => (
                      <TableRow key={caseDto.id}>
                        <TableCell>
                          <StyledLink
                            to={{
                              pathname: `/youths/${caseDto.youth.id}`,
                              state: {
                                prevLocation: location.pathname,
                                text: "Back to SNAP Group Cycle",
                              },
                            }}
                          >
                            <NameLabel nameDto={caseDto.youth} />
                          </StyledLink>
                        </TableCell>
                        <TableCell>
                          <StyledLink
                            to={{
                              pathname: `/cases/${caseDto.caseId}`,
                              state: {
                                prevLocation: location.pathname,
                                text: "Back to SNAP Group Cycle",
                              },
                            }}
                          >
                            {caseDto.caseNumber}
                          </StyledLink>
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            checked={caseDto.madeCompliancePhoneCall}
                            onClick={() =>
                              handleToggleComplaincePhoneCall(caseDto)
                            }
                            disabled={
                              toggleMadeCompliancePhoneCallRequestState.isLoading ||
                              !canSetMadeCompliancePhoneCall
                            }
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          {caseDto.deletedAt ? (
                            <RedAlertLabel label={"Deleted"} />
                          ) : (<WriteAccessLevelWrapper name={PermissionResourceNameEnum.Snap}>
                            <EditButton
                              text={"REMOVE"}
                              onClick={() =>
                                handleRemoveSnapGroupCaseClicked(caseDto)
                              }
                            />
                            </WriteAccessLevelWrapper>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}

                {(snapGroup === undefined || snapGroup.cases.length === 0) && (
                  <TableRow>
                    <TableCell colSpan={3}>No Youth Added To Group</TableCell>
                  </TableRow>
                )}
              </Fragment>
            )}

            {getSnapGroupRequestState.isLoading && (
              <Fragment>
                {createNumberRange(5).map((i) => (
                  <TableRow key={`row-${i}`}>
                    <TableCell>
                      <Skeleton animation="wave" width="200px" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" width="200px" />
                    </TableCell>
                  </TableRow>
                ))}
              </Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SnapGroupSection;

export const createSnapGroupTab = (props: Props): TabProps => ({
  value: props.group.id,
  label: (
    <Fragment>
      <Box component="span" display="flex" alignItems="center">
        <DateLabel date={props.group.groupHeldOn} />
        {props.alerts.length > 0 ? (
          <WarningIcon
            style={{ marginLeft: "4px" }}
            tooltip={
              <List style={{ padding: "0px" }}>
                {props.alerts.map((alert) => (
                  <ListItem key={alert.type} style={{ padding: "0px" }}>
                    <ListItemText>
                      {SnapGroupContextAlertTypeMessages[
                        AlertTypeEnum.SnapGroupFidelityRequired
                      ](props.group)}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            }
          />
        ) : (
          <Box width="24px" height="24px" marginLeft="4px"></Box>
        )}
      </Box>
      {props.group.deletedAt && (
        <RedAlertLabel label={"Deleted"} variant={"outlined"} />
      )}
    </Fragment>
  ),
  content: <SnapGroupSection {...props} />,
});
