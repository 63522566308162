import React, { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { selectIsLoggedIn } from "../../features/authentication/state/authenticationSelectors";
import { useAppSelector } from "../hooks";

interface Props extends RouteProps {}

const AnonymousRoute: React.FC<Props> = ({ ...props }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/");
    }
  }, [isLoggedIn, history]);

  return <Route {...props} />;
};

export default AnonymousRoute;
