import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { AssessmentScoreDto } from "../dtos/AssessmentDto";
import {
  AssessmentScoreTypeEnum,
  assessmentScoreTypeTextMap,
} from "../enums/AssessmentScoreTypeEnum";

const scoreOrder: string[] = [
  AssessmentScoreTypeEnum.Static,
  AssessmentScoreTypeEnum.Dynamic,
  AssessmentScoreTypeEnum.ChildhoodAdversityScore,
  AssessmentScoreTypeEnum.ProtectiveFactors,
  AssessmentScoreTypeEnum.VictoriesAndResilience,
];

interface ScoresTableProps {
  scores: AssessmentScoreDto[];
}

const AssessmentScoresTable: React.FC<ScoresTableProps> = ({ scores }) => {
  //#region UI Helpers
  const renderRow = (score: AssessmentScoreDto) => (
    <TableRow key={score.id}>
      <TableCell>{assessmentScoreTypeTextMap[score.type]}</TableCell>
      <TableCell>{score.score}</TableCell>
    </TableRow>
  );
  //#endregion

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {scores
          .filter((x) => x.type !== AssessmentScoreTypeEnum.None || x.score > 0)
          .sort(
            (a, b) => scoreOrder.indexOf(a.type) - scoreOrder.indexOf(b.type)
          )
          .map(renderRow)}
      </TableBody>
    </Table>
  );
};

export default AssessmentScoresTable;
