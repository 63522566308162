import { Typography, TypographyTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import React, { Fragment } from "react";
import {
  YouthRelationshipEnum,
  youthRelationshipextMap,
} from "../enums/YouthRelationshipEnum";

interface Props extends Partial<OverridableComponent<TypographyTypeMap<{}>>> {
  relationship?: YouthRelationshipEnum;
}

const YouthRelationshipLabel: React.FC<Props> = ({
  relationship,
  ...props
}) => {
  if (relationship === undefined) return <Fragment></Fragment>;

  return (
    <Typography component="span" {...props}>
      {youthRelationshipextMap[relationship]}
    </Typography>
  );
};

export default YouthRelationshipLabel;
