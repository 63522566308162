import React from "react";
import { MultiValueMetric } from "../dtos/MainDashboardMetricsDto";
import { ProviderDetailDto } from "../../provider/dtos/ProviderDetailDto";
import { BarChartMetricPanel } from "./BarChartMetricPanel";
import { deliverableTypeTextMap } from "../../program-type-deliverables/enums/DeliverableTypeEnum";

const createLabelMap = (
  metric?: MultiValueMetric,
  provider?: ProviderDetailDto
) => {
  if (!metric || !provider) return;

  const valueKeysMap: { [index: string]: string } = metric.valueKeys.reduce(
    (map, val) => {
      const unknownLocationId = "00000000-0000-0000-0000-000000000000";

      const splitIndex = val.indexOf("-");
      if (splitIndex < 0) return map;

      const programType = val.substr(0, splitIndex);
      const locationId = val.substr(splitIndex + 1);

      const locationName =
        locationId === unknownLocationId
          ? "Unknown Location"
          : provider.locations?.find((l) => l.id === locationId)?.name ||
            locationId;

      const programTypeDto = provider.programs
        .map((p) => p.programType)
        .find(
          (pt) => pt.programType.toLowerCase() === programType.toLowerCase()
        );
      const programTypePascalCase = `${programType
        .substr(0, 1)
        .toUpperCase()}${programType.substr(1)}`;
      const deliverableName = deliverableTypeTextMap[programTypePascalCase];
      const updatedProgramType = programTypeDto
        ? programTypeDto.name
        : deliverableName
        ? deliverableName
        : programType;

      const updatedValue = `${updatedProgramType} @ ${locationName}`;

      return { ...map, [val]: updatedValue };
    },
    {}
  );

  return valueKeysMap;
};

interface Props {
  metric?: MultiValueMetric;
  provider?: ProviderDetailDto;
  isLoading?: boolean;
}

export const FilledBedDaysMetricPanel: React.FC<Props> = ({
  metric,
  provider,
  isLoading,
}) => {
  return (
    <BarChartMetricPanel
      metric={metric}
      labelMap={createLabelMap(metric, provider)}
      isLoading={isLoading}
    />
  );
};
