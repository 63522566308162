import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { useGetFiscalYears } from "../../provider-fiscal-year/hooks/providerFiscalYearHooks";
import { useGetAllProviders } from "../../provider/hooks/providerHooks";
import { CumulativeDeliverableReportParamsDto } from "../dtos/CumulativeDeliverableReportParametersDto";
import {
  useGetBenchmarksReportDocument,
  useGetCumulativeDeliverablesPivotReportDocument as useGetBenchmarksPivotReportDocument,
} from "./reportHooks";

export const useBenchmarksReportPage = () => {
  const [getRollupDocument, rollupDocument, getRollupDocumentRequestState] =
    useGetBenchmarksReportDocument();
  const [getPivotDocument, pivotDocument, getPivotDocumentRequestState] =
    useGetBenchmarksPivotReportDocument();
  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();
  const [getFiscalYears, , getFiscalYearsRequestState] = useGetFiscalYears();
  const [fiscalYears, setFiscalYears] = useState<number[]>([]);

  const isFloridaNetwork = getProviderId() === fnyfsProviderId;
  const document = isFloridaNetwork ? pivotDocument : rollupDocument;
  const getDocument = isFloridaNetwork ? getPivotDocument : getRollupDocument;
  const getDocumentRequestState = isFloridaNetwork
    ? getPivotDocumentRequestState
    : getRollupDocumentRequestState;

  const handleSearch = async (params: CumulativeDeliverableReportParamsDto) => {
    await getDocument(params);
  };

  useEffect(() => {
    if (!isFloridaNetwork) getProviders(undefined);
  }, [getProviders, isFloridaNetwork]);

  useEffect(() => {
    const load = async () => {
      let result = await getFiscalYears(undefined);
      if (result) {
        const now = DateTime.now();

        if (now.month === 7) 
          result = result.filter((x) => x < now.year);

        setFiscalYears(result.sort((a, b) => (a > b ? 1 : -1)));
      }
    };

    load();
  }, [getFiscalYears, setFiscalYears]);

  return {
    document,
    getDocumentRequestState,
    providers: providers || [],
    getProvidersRequestState,
    fiscalYears,
    getFiscalYearsRequestState,
    handleSearch,
    isFloridaNetwork,
  };
};
