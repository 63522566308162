import { Typography } from "@material-ui/core";
import React, { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import LoadingOverlay from "../../../app/components/LoadingOverlay";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { SendConfirmationEmailRequestDto } from "../dtos/SendConfirmationEmailRequestDto";
import { sendConfirmationEmail } from "../state/accountActions";
import { selectAccountIsLoading } from "../state/accountSelectors";

const SendConfirmationEmailPage = () => {
  //#region State
  const query = useQuery();
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectAccountIsLoading);
  const [initialized, setInitialized] = useState(false);
  const dto = useRef(new SendConfirmationEmailRequestDto(query));
  //#endregion

  //#region Effects
  useEffect(() => {
    const send = async () => {
      try {
        setInitialized(true);
        await dispatch(sendConfirmationEmail(dto.current)).unwrap();
      } catch (error: any) {
        setError(error.message);
        throw error;
      }
    };

    send();
  }, [dto, dispatch, setError]);
  //#endregion

  if (initialized === false && isLoading)
    return (
      <LoadingOverlay open={isLoading} message="Sending Confirmation Email" />
    );

  if (error)
    return (
      <Fragment>
        <Typography variant="h6">
          Unable to send confirmation email: {error}
        </Typography>
      </Fragment>
    );

  return (
    <div>
      <Typography>Confirmation email send.</Typography>
    </div>
  );
};

export default SendConfirmationEmailPage;
