import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { trimPhoneNumber } from "../../../app/helpers";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { useGetRoles } from "../../roles/hooks/roleHooks";
import { UserDetailDto } from "../dtos/UserDetailDto";
import { UserFormDto } from "../dtos/UserFormDto";

export const useUserForm = (
  onSubmit: (dto: UserFormDto) => Promise<any>,
  user?: UserDetailDto
) => {
  //#region State
  const [getRoles, roles, getRolesRequestState] = useGetRoles();
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = useForm({
    defaultValues: new UserFormDto(user),
    resolver: yupResolver(UserFormDto.getSchema()),
    mode: "all",
  });
  const isFloridaNetworkUser = watch("isFloridaNetworkUser");
  const [showRoles, setShowRoles] = useState(false);
  //#endregion

  const clearForm = () => {
    reset(new UserFormDto(user));
  };

  const handleOnSubmit = handleSubmit(async (dto: UserFormDto) => {
    dto.phoneNumber = dto.phoneNumber
      ? trimPhoneNumber(dto.phoneNumber)
      : undefined;
    if (getProviderId() === fnyfsProviderId && !dto.isFloridaNetworkUser)
      dto.roleId = "";
    await onSubmit(dto);
  });

  //#region Effects
  useEffect(() => {
    getRoles(getProviderId() === fnyfsProviderId);
  }, [getRoles]);

  useEffect(() => {
    reset(new UserFormDto(user));
  }, [user, reset]);

  useEffect(() => {
    setShowRoles(isFloridaNetworkUser || getProviderId() !== fnyfsProviderId);
  }, [setShowRoles, isFloridaNetworkUser]);
  //#endregion

  return {
    state: { roles, getRolesRequestState, isFloridaNetworkUser, showRoles },
    form: {
      control,
      isValid,
      clearForm,
    },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
