import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum ScreeningPlannedActionEnum {
  Program = "Program",
  InternalReferral = "InternalReferral",
  ExternalReferral = "ExternalReferral",
  TurnedAway = "TurnedAway",
  Waitlist = "Waitlist",
}

export const screeningPlannedActionTextMap: { [key: string]: string } = {
  [ScreeningPlannedActionEnum.Program]: "Admit to Florida Network Program",
  [ScreeningPlannedActionEnum.InternalReferral]:
    "Referral inside the organization that is not Florida Network funded",
  [ScreeningPlannedActionEnum.ExternalReferral]:
    "Referral outside of the organization",
  [ScreeningPlannedActionEnum.TurnedAway]: "Turned Away",
  [ScreeningPlannedActionEnum.Waitlist]: "Waitlist",
};

export const getScreeningPlannedActionOptions = (
  defaultOption: string = "Select Planned Action"
): SelectOption[] => [
  { value: "", label: defaultOption },
  ...Object.keys(ScreeningPlannedActionEnum).map((x) => ({
    value: x,
    label: screeningPlannedActionTextMap[x],
  })),
];
