import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import {
  DjjEntryListDto,
  DjjEntryStatusOptions,
  SetDjjEntryStatusFormDto,
} from "../DjjEntryDto";
import { useSetDjjEntryStatusForm } from "../hooks/useDjjEntryStatusFormDialog";

export const setDjjEntryStatusModalId = "set-djj-status-form-modal";

interface Props {
  djjEntry?: DjjEntryListDto;
  requestState: RequestStateDto;
  onSubmit(dto: SetDjjEntryStatusFormDto): Promise<any>;
}

const SetDjjEntryStatusFormDialog: React.FC<Props> = ({
  djjEntry,
  requestState,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useSetDjjEntryStatusForm(onSubmit, djjEntry);
  const { clearForm, control, isValid } = form;
  const { handleSubmit } = handlers;
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  //#endregion

  return (
    <Dialog open={modalId === setDjjEntryStatusModalId} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Set Status
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="statusEnteredAt"
                type="datetime-local"
                label="Date"
                disabled={isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="status"
                label="Status"
                options={DjjEntryStatusOptions}
                disabled={isLoading}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetDjjEntryStatusFormDialog;
