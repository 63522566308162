import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { CinsPetitionFormDto } from "../dtos/CinsPetitionPetitionFormDto";
import { useCinsPetitionForm } from "../hooks/useCinsPetitionForm";
import { CinsPetitionDto } from "../dtos/CinsPetitionDto";
import CinsPetitionFormFields from "./CinsPetitionFormFields";

export const cinsPetitionModalId = "cins-petition-form-modal";

interface Props {
  onSubmit: (dto: CinsPetitionFormDto) => Promise<any>;
  requestState: RequestStateDto;
  petition?: CinsPetitionDto;
}

const CinsPetitionFormDialog: React.FC<Props> = ({
  requestState,
  petition,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useCinsPetitionForm(onSubmit, petition);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === cinsPetitionModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {petition ? "Edit" : "Create"} CINS Petition
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <CinsPetitionFormFields control={control} isDisabled={isLoading} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CinsPetitionFormDialog;
