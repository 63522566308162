import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { StaffMemberDto } from "../dtos/StaffMemberDto";

interface Props {
  onConfirm: () => void;
  staffMember: StaffMemberDto;
  requestState: RequestStateDto;
}

export const confirmDeactivateStaffMemberDialogId =
  "deactivate-staff-member-dialog";
const ConfirmDeactivateStaffMemberDialog: React.FC<Props> = ({
  onConfirm,
  staffMember,
  requestState,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm Remove"
      onConfirm={onConfirm}
      modalId={confirmDeactivateStaffMemberDialogId}
      isLoading={isLoading}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to deactivate {staffMember?.firstName}{" "}
        {staffMember?.lastName}?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeactivateStaffMemberDialog;
