import React, { Fragment } from "react";
import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Button,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useHistory } from "react-router-dom";
import { toggleMenu } from "../../state/menuSlice";
import MenuIcon from "@material-ui/icons/Menu";
import { selectIsLoggedIn } from "../../../features/authentication/state/authenticationSelectors";
import UserMenu from "./UserMenu";
import Banner from "../Banner";

const useStyles = makeStyles((theme: Theme) => ({
  brandImg: {
    width: "450px",
    cursor: "pointer",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 100,
  },
}));

const Menu = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleBrandClick = () => history.push("/");
  const handleToggleMenu = () => dispatch(toggleMenu());

  const loggedInMenu = isLoggedIn ? (
    <Fragment>
      <UserMenu />
      <IconButton onClick={handleToggleMenu} color="inherit">
        <MenuIcon />
      </IconButton>
    </Fragment>
  ) : (
    <Button color="inherit" onClick={() => history.push("/auth/login")}>
      Login
    </Button>
  );

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar}>
          <Banner />
          <Toolbar style={{ paddingLeft: "0px" }}>
          <img
            src="/netmis-logo.png"
            className={classes.brandImg}
            alt=""
            onClick={handleBrandClick}
          />

          <Box marginLeft="auto" display="flex" alignItems="center">
            {loggedInMenu}
          </Box>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default Menu;
