import { Grid, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import { Control, useFormState, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import {
  getQuestionText,
  SatisfactionSurveyFormDto,
  SatisfactionSurveyQuestion,
  satisfactionSurveyQuestions,
  satisfactionSurveyResponseOptions,
  SnapSatisfactionSurveyQuestionNames,
  snapSatisfactionSurveyQuestions,
} from "../SatisfactionSurveyDto";
import { ProgramTypeEnum } from "../../programs/enums/ProgramTypeEnum";
import {
  SnapSatisfactionSurveyHelpsPeopleEnum,
  SnapSatisfactionSurveyHelpsPeopleEnumTextMap,
  SnapSatisfactionSurveyLikertEnumOptions,
  SnapSatisfactionSurveySkillEnum,
  SnapSatisfactionSurveySkillEnumTextMap,
} from "../SatisfactionSurveyEnums";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import ErrorText from "../../../app/components/ErrorText";
import {StaffMemberControlledAutoComplete} from "../../staff-members/components/StaffMemberControlledAutoComplete";

interface SnapProps {
  control: Control<SatisfactionSurveyFormDto>;
  disabled: boolean;
}

const SnapSatisfactionSurveyFormFields: React.FC<SnapProps> = ({
  control,
  disabled,
}) => {
  const { satisfactionSurveyResponseSet } = useWatch({ control });
  const { isSnapProgram, snapHelpfulSkillsOptions, snapHelpsPeopleOptions } =
    satisfactionSurveyResponseSet!;
  const { touchedFields } = useFormState({ control });

  const renderOptions = (
    values: string[],
    textMap: { [key: string]: string },
    fieldName: "snapHelpfulSkillsOptions" | "snapHelpsPeopleOptions",
    selectedOptions: { [key: string]: boolean | undefined }
  ) => {
    const wasTouched =
      touchedFields.satisfactionSurveyResponseSet != null &&
      touchedFields.satisfactionSurveyResponseSet![fieldName];
    const hasError =
      selectedOptions != null &&
      Object.values(selectedOptions).filter((x) => x).length !== 3;
    return (
      <>
        <List dense disablePadding style={{ columns: 2 }}>
          {values.map((value) => (
            <ListItem key={value} dense>
              <ControlledCheckbox
                label={textMap[value]}
                control={control}
                disabled={disabled}
                name={`satisfactionSurveyResponseSet.${fieldName}.${value}`}
                color="primary"
                checked={selectedOptions[value]}
              />
            </ListItem>
          ))}
        </List>
        {wasTouched && hasError && (
          <ErrorText error={"Three options must be selected."} />
        )}
      </>
    );
  };

  if (!isSnapProgram) return <></>;

  return (
    <>
      <Grid item md={12} xs={12}>
        <Grid container>
          <Grid item md={8} xs={12}>
            <Typography>
              {satisfactionSurveyQuestions.length + 1}.{" "}
              {
                snapSatisfactionSurveyQuestions[
                  SnapSatisfactionSurveyQuestionNames.snapSatisfiedWithProgram
                ]
              }
              *
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <ControlledSelect
              control={control}
              options={SnapSatisfactionSurveyLikertEnumOptions}
              name={`satisfactionSurveyResponseSet.${SnapSatisfactionSurveyQuestionNames.snapSatisfiedWithProgram}`}
              fullWidth
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography>
          {satisfactionSurveyQuestions.length + 2}.{" "}
          {
            snapSatisfactionSurveyQuestions[
              SnapSatisfactionSurveyQuestionNames.snapHelpfulSkills
            ]
          }{" "}
          (Please choose three) *
        </Typography>
        {renderOptions(
          Object.values(SnapSatisfactionSurveySkillEnum),
          SnapSatisfactionSurveySkillEnumTextMap,
          "snapHelpfulSkillsOptions",
          snapHelpfulSkillsOptions!
        )}
      </Grid>

      <Grid item md={12} xs={12}>
        <Typography>
          {satisfactionSurveyQuestions.length + 3}.{" "}
          {
            snapSatisfactionSurveyQuestions[
              SnapSatisfactionSurveyQuestionNames.snapHelpsPeople
            ]
          }{" "}
          (Please choose three) *
        </Typography>
        {renderOptions(
          Object.values(SnapSatisfactionSurveyHelpsPeopleEnum),
          SnapSatisfactionSurveyHelpsPeopleEnumTextMap,
          "snapHelpsPeopleOptions",
          snapHelpsPeopleOptions!
        )}
      </Grid>
    </>
  );
};

interface Props {
  control: Control<SatisfactionSurveyFormDto>;
  staffMembers: StaffMemberDto[];
  staffMembersAreLoading: boolean;
  disabled: boolean;
  programType: ProgramTypeEnum;
}

const SatisfactionSurveyFormFields: React.FC<Props> = ({
  control,
  disabled,
  staffMembers,
  staffMembersAreLoading,
  programType,
}) => {
  const renderQuestion = (question: SatisfactionSurveyQuestion) => (
    <Grid item md={12} xs={12} key={question.name}>
      <Grid container>
        <Grid item md={8} xs={12}>
          <Typography>
            {question.order + 1}. {getQuestionText(question, programType)}*
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <ControlledSelect
            control={control}
            name={`satisfactionSurveyResponseSet.${question.name}`}
            options={satisfactionSurveyResponseOptions}
            disabled={disabled}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name="completedOn"
          label="Completed On*"
          type="date"
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <StaffMemberControlledAutoComplete
          control={control}
          staffMembers={staffMembers}
          isLoading={staffMembersAreLoading}
          disabled={disabled}
          name="staffMemberId"
          label="Counselor*"
        />
      </Grid>

      {satisfactionSurveyQuestions.map(renderQuestion)}
      <SnapSatisfactionSurveyFormFields control={control} disabled={disabled} />
    </>
  );
};

export default SatisfactionSurveyFormFields;
