import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#134b7c",
    },
    secondary: {
      main: "#fdba14",
    },
    text: {
      primary: "#444",
      secondary: "#888",
    },
  },

  typography: {
    h1: {
      color: "#444",
    },
    h2: {
      color: "#444",
    },
    h3: {
      color: "#444",
    },
    h4: {
      color: "#444",
    },
    h5: {
      color: "#444",
    },
    h6: {
      color: "#444",
    },
  },
});

export default theme;
