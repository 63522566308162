import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum TurnedAwayEnum {
  InappropriateReferral = "InappropriateReferral",
  MedicalCondition = "MedicalCondition",
  RequiresInjectableMedications = "RequiresInjectableMedications",
  NoVacancy = "NoVacancy",
  ParentRefusesCooperate = "ParentRefusesCooperate",
  UnableToMeetSpecialNeeds = "UnableToMeetSpecialNeeds",
  YouthRefusesCooperate = "YouthRefusesCooperate",
  Other = "Other",
}

export const turnedAwayTextMap: { [key: string]: string } = {
  [TurnedAwayEnum.InappropriateReferral]: "Inappropriate Referral for Program",
  [TurnedAwayEnum.MedicalCondition]: "Medical Condition",
  [TurnedAwayEnum.RequiresInjectableMedications]:
    "Youth Requires Injectable Medication(s)",
  [TurnedAwayEnum.NoVacancy]: "No Vacant Beds/Slots",
  [TurnedAwayEnum.ParentRefusesCooperate]: "Parent(s) Refuses to Cooperate",
  [TurnedAwayEnum.UnableToMeetSpecialNeeds]:
    "Program Unable to Meet Youth's Special Needs",
  [TurnedAwayEnum.YouthRefusesCooperate]: "Youth Refuses to Cooperate",
  [TurnedAwayEnum.Other]: "Other",
};

export const turnedAwayOptions: SelectOption[] = [
  { value: "", label: "Select Reason Turned Away" },
  ...Object.keys(TurnedAwayEnum).map((status) => ({
    value: status,
    label: turnedAwayTextMap[status],
  })),
];
