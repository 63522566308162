

export interface InvoiceReportParamsDto {
  format?: string;
  providerId: string;
  //contractType: ContractTypeEnum;
  contractId: string;
  reportingPeriodId: string;
}

export const invoiceReportParamsToQueryString = (
  dto: InvoiceReportParamsDto
) => {
  const queryString: string[] = [];

  if (dto.format) queryString.push(`format=${dto.format}`);
  if (dto.providerId) queryString.push(`providerId=${dto.providerId}`);
  if (dto.contractId) queryString.push(`contractId=${dto.contractId}`);
  if (dto.reportingPeriodId)
    queryString.push(`reportingPeriodId=${dto.reportingPeriodId}`);

  return queryString.join("&");
};
