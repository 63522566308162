import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

interface Props {
  isReferralSelf: boolean;
}

const ReferralSuicideAlert: React.FC<Props> = ({ isReferralSelf }) => {
  return (
    <Paper
      style={{
        marginBottom: "8px",
        color: ColorsEnum.Red,
        border: `1px solid ${ColorsEnum.Red}`,
        padding: "8px",
      }}
      variant="outlined"
      square
    >
      <Box display="flex" alignItems="center">
        <ErrorOutlineIcon
          style={{ marginRight: "8px", width: "36px", height: "36px" }}
        />
        <Typography>
          If {isReferralSelf ? "you are" : "the youth is"} in crisis (suicidal,
          at risk of being hurt, at risk of hurting others, or in danger), seek
          immediate help: go to the emergency room, call{" "}
          <a href={"tel:988"}>988</a>, text{" "}
          <a href={"sms:741741"}>741741</a> or call <a href={"tel:911"}>911</a>.{" "}
          {!isReferralSelf && "STAY WITH THEM until they can be evaluated. "}
          For more resources, click <a href={"https://floridanetwork.org/find-help/resources/"}>here</a>.
        </Typography>
      </Box>
    </Paper>
  );
};

export default ReferralSuicideAlert;
