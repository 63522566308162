export enum ServicePlanParticipantRolesEnum {
  None = "",
  ProviderStaff = "ProviderStaff",
  Parent = "Parent",
  Guardian = "Guardian",
  Supervisor = "Supervisor",
  Other = "Other"
}

export const getRoleDisplayText = (role: ServicePlanParticipantRolesEnum) => {
  switch(role) {
    case ServicePlanParticipantRolesEnum.ProviderStaff:
      return "Staff";
    default:
      return role.toString();
  }
}
