import React from "react";
import {Control, Controller, useForm} from "react-hook-form";
import {StaffMemberDto} from "../dtos/StaffMemberDto";
import {Box, IconButton, List, ListItem, Typography} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {ColorsEnum} from "../../../app/enums/ColorsEnum";
import NameLabel from "../../../app/components/labels/NameLabel";
import {StaffMemberControlledAutoComplete} from "./StaffMemberControlledAutoComplete";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";

export const MultiStaffMemberControlledSelector: React.FC<{
  control: Control<any>,
  name: string,
  staffMembers: StaffMemberDto[],
  onStaffAdded: (id: string) => void,
  onStaffRemoved: (id: string) => void
}> = ({
        control,
        name,
        staffMembers,
        onStaffAdded,
        onStaffRemoved
      }) => {
  const {
    control: addStaffControl,
    reset,
    watch,
  } = useForm({
    defaultValues: { newStaffId: '' }
  })

  const { newStaffId } = watch()

  const handleAddStaff = () => {
    if(newStaffId) {
      onStaffAdded(newStaffId);
      reset({ newStaffId: '' })
    }
  }

  return <>

    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {

        return (
          <>
            <fieldset style={{ borderRadius: 4, borderColor: "grey.500", borderWidth: "1px" }}>
              <legend style={{ color: "#888", transform: "scale(0.85)" }}><Typography variant={"subtitle2"}>Primary Staff (Person(s) working with the Youth)</Typography></legend>
              {field.value.length === 0 && <Typography>No Staff Assigned.</Typography>}

              {field.value.length > 0 && (
                <List dense={true} disablePadding={true}>
                  { field.value.map((staffId: string) => {
                    const staff = staffMembers.find(s => s.id === staffId);
                    return (
                      <ListItem>
                        <IconButton title={"Remove Staff Member from Primary Staff"} onClick={() => onStaffRemoved(staffId)}><Delete htmlColor={ColorsEnum.Red} /></IconButton>
                        <NameLabel nameDto={staff} />
                      </ListItem>
                    )
                  }) }
                </List>
              )}

              <Box display={"flex"} flexDirection={"row"} width={"100%"}>
                <StaffMemberControlledAutoComplete
                  staffMembers={staffMembers.filter(s => field.value.findIndex((val: string) => val === s.id) === -1)}
                  control={addStaffControl}
                  isLoading={false}
                  name={"newStaffId"}
                  label={"Select Staff Member such as Case Mgr, Primary Counselor, etc."}
                />
                <SpinnerButton content={"Add"} showSpinner={false} onClick={handleAddStaff} />
              </Box>

            </fieldset>
          </>
        )
      }}
    />
  </>
}
