import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useCallback } from "react";
export const useQuery = () => {
  const { search } = useLocation();
  const parse = useCallback(() => {
    return queryString.parse(search);
  }, [search]);

  return parse();
};
