import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import {
  ProviderFiscalYearCreateOrUpdateDto,
  ProviderFiscalYearDto,
} from "../dtos/ProviderFiscalYearDto";
import { useProviderFiscalYearForm } from "../hooks/useProviderFiscalYearForm";
import { ProviderFiscalYearFormFields } from "./ProviderFiscalYearFormFields";
import { useProviderDetailPageContext } from "../../provider/pages/ProviderDetailPage";

export const providerFiscalYearModalId = "provider-fiscal-year-form-modal";

interface Props {
  providerFiscalYear?: ProviderFiscalYearDto;
  onSubmit: (dto: ProviderFiscalYearCreateOrUpdateDto) => Promise<any>;
  requestState: RequestStateDto;
}

const ProviderFiscalYearFormDialog: React.FC<Props> = ({
  providerFiscalYear,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const { contracts } = useProviderDetailPageContext();
  const dispatch = useAppDispatch();
  const {
    state: { programTypeDeliverables },
    form: { clearForm, isValid, control },
    handlers: { handleSubmit },
  } = useProviderFiscalYearForm(onSubmit, contracts, providerFiscalYear);
  const { isLoading, error } = requestState;

  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === providerFiscalYearModalId}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {providerFiscalYear ? "Edit" : "Create"} Contracted Deliverables{" "}
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <ProviderFiscalYearFormFields
            control={control}
            disabled={isLoading}
            isEdit={providerFiscalYear !== undefined}
            programTypeDeliverables={programTypeDeliverables}
            contracts={contracts}
          />
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProviderFiscalYearFormDialog;
