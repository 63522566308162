import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";
import { TextFieldProps } from "@material-ui/core";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

interface Props {
  control: Control<any>;
  name: string;
}
interface CurrencyTextMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const CurrencyTextMask = (props: CurrencyTextMaskProps) => {
  const currencyMask = createNumberMask({
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2,
    integerLimit: 4,
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={currencyMask}
      showMask
    />
  );
};

const ControlledCurrencyInput: React.FC<Props & TextFieldProps> = ({
  control,
  ...props
}) => {
  const { name } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          variant="outlined"
          fullWidth
          {...field}
          autoComplete="off"
          {...props}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          InputProps={{
            inputComponent: CurrencyTextMask as any,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      )}
    />
  );
};

export default ControlledCurrencyInput;
