import { Typography } from "@material-ui/core";
import React from "react";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { NetmisUserRoleDto } from "../../roles/dtos/NetmisUserRoleDto";
import { UserDetailDto } from "../dtos/UserDetailDto";

interface Props {
  user?: UserDetailDto;
  role?: NetmisUserRoleDto;
  requestState: RequestStateDto;
  onConfirm(): void;
}

export const confirmDeactivateRoleDialogId = "deactivate-role-dialog";
const ConfirmDeactivateRoleDialog: React.FC<Props> = ({
  onConfirm,
  user,
  role,
  requestState,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm Deactivate"
      onConfirm={onConfirm}
      modalId={confirmDeactivateRoleDialogId}
      isLoading={isLoading}
    >
      <ErrorText error={error?.message} />
      <Typography>
        <FnAccessWrapper>
          <>
            Are you sure you want to deactivate {user?.firstName}{" "}
            {user?.lastName}'s role for {role?.provider.name}? They will no
            longer be able to access {role?.provider.name}'s resources.
          </>
        </FnAccessWrapper>
        <ProviderAccessWrapper>
          <>
            Are you sure you want to deactivate {user?.firstName}{" "}
            {user?.lastName}? They will no longer be able to sign in.
          </>
        </ProviderAccessWrapper>
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeactivateRoleDialog;
