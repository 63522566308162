import React, { useCallback } from "react";
import { ProgramTypeDto } from "../../program-types/dtos/ProgramTypeDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { WaitlistSearchParamsDto } from "../dtos/WaitlistSearchParamsDto";
import { formatLocation, LocationDto } from "../../locations/dtos/LocationDto";
import { waitlistStatusTextMap } from "../enums/WaitlistStatusEnum";
import FilterList from "../../../app/components/FilterList";
import { WaitlistFilterFormDto } from "../dtos/WaitlistFilterFormDto";
interface Props {
  params: WaitlistSearchParamsDto;
  isLoading: boolean;
  providers: ProviderDto[];
  programs: ProgramDto[];
  programTypes: ProgramTypeDto[];
  locations: LocationDto[];
  onSearch(dto: WaitlistSearchParamsDto): void;
}

const WaitlistFilterList: React.FC<Props> = ({
  params,
  isLoading,
  providers,
  programTypes,
  programs,
  locations,
  onSearch,
}) => {
  const handleClearParams = () => {
    const form = new WaitlistFilterFormDto();
    return new WaitlistSearchParamsDto({
      previous: params,
      form,
    });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "youthFirstName":
          return `First Name: ${value}`;
        case "youthLastName":
          return `Last Name: ${value}`;
        case "programTypeId":
          return `Program Type: ${
            programTypes.find((x) => x.id === value)?.name
          }`;
        case "programId":
          return `Program: ${programs.find((x) => x.id === value)?.name}`;
        case "providerId":
          return `Provider: ${providers.find((x) => x.id === value)?.name}`;
        case "locationId":
          const location = locations.find((x) => x.id === value);
          return `Location:  ${
            location ? formatLocation(location) : "Unknown Location"
          }`;
        case "screeningNumber":
          return `Screening #: ${value}`;
        case "status":
          return `Status: ${waitlistStatusTextMap[value]}`;
      }
      return "";
    },
    [providers, programs, programTypes, locations]
  );

  const ordering = [
    "screeningNumber",
    "providerId",
    "programTypeId",
    "programId",
    "locationId",
    "youthFirstName",
    "youthLastName",
    "status",
  ];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default WaitlistFilterList;
