import { DateTime } from "luxon";
import { datetimeToFormString } from "../../../app/helpers";

export interface EndCaseHoldFormDto {
  endAt: string | Date;
}

export const createEndCaseHoldFormDto = (startAt: Date): EndCaseHoldFormDto => {
  let endAt = datetimeToFormString(new Date());
  let dateTime = DateTime.fromJSDate(new Date(startAt));
  dateTime = dateTime.plus({ hours: 48 });
  if (dateTime.toJSDate() < new Date()) {
    endAt = datetimeToFormString(dateTime.toJSDate());
  }

  return {
    endAt,
  };
};
