import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import InvoiceCorrectionConfirmationCheckbox from "../../../app/components/InvoiceCorrectionConfirmationCheckbox";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SnapInSchoolsCycleDto } from "../dtos/SnapInSchoolsCycleDto";
import { SnapInSchoolsSessionDto } from "../dtos/SnapInSchoolsSessionDto";
import { SnapInSchoolsSessionFormDto } from "../dtos/SnapInSchoolsSessionFormDto";
import { useSnapInSchoolsSessionForm } from "../hooks/useSnapInSchoolsSessionForm";
import SnapInSchoolsSessionFormFields from "./SnapInSchoolsSessionFormFields";

export const snapInSchoolsSessionModalId = "snap-in-schools-session-form-modal";

interface Props {
  snapSession?: SnapInSchoolsSessionDto;
  onSubmit: (dto: SnapInSchoolsSessionFormDto) => Promise<any>;
  requestState: RequestStateDto;
  snapCycle?: SnapInSchoolsCycleDto;
}

const SnapInSchoolsSessionFormDialog: React.FC<Props> = ({
  snapSession,
  onSubmit,
  requestState,
  snapCycle,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { state, form, handlers } = useSnapInSchoolsSessionForm(
    onSubmit,
    snapSession,
    snapCycle
  );
  const { handleSubmit, handleFileUpdated, handleToggleConfirmed } = handlers;
  const { curriculum, getCurriculumRequestState, isConfirmed } = state;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === snapInSchoolsSessionModalId}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {snapSession ? "Edit" : "Create"} SNAP in Schools and Communities
          Session
          <ErrorText error={error?.message} />
          <ErrorText error={getCurriculumRequestState.error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <SnapInSchoolsSessionFormFields
              curriculum={curriculum}
              curriculumIsLoading={getCurriculumRequestState.isLoading}
              control={control}
              isDisabled={isLoading}
              onFileUploaded={handleFileUpdated}
            />
            {snapSession && (
              <Grid item md={12} xs={12}>
                <InvoiceCorrectionConfirmationCheckbox
                  isConfirmed={isConfirmed}
                  onCheck={handleToggleConfirmed}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SnapInSchoolsSessionFormDialog;
