export enum YouthGenderEnum {
  Female = "Female",
  Male = "Male",
  GenderNonConforming = "GenderNonConforming",
  TransFemale = "TransFemale",
  TransMale = "TransMale",
}

export const YouthGenderTextMap: { [key: string]: string } = {
  [YouthGenderEnum.Female]: "Female",
  [YouthGenderEnum.Male]: "Male",
  [YouthGenderEnum.GenderNonConforming]: "Gender Non-Conforming",
  [YouthGenderEnum.TransFemale]: "Trans Female",
  [YouthGenderEnum.TransMale]: "Trans Male",
};
