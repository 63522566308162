import {useCallback, useState} from "react";
import { useGetCaseProgramLocationHistory } from "./caseHooks";
import {useForm} from "react-hook-form";
import {dateToFormString} from "../../../app/helpers";
import {useCaseDetailPageContext} from "../pages/CaseDetailPage";
import {useProgramLocationNestedFields} from "../../youths/hooks/useProgramLocationNestedFields";
import {useRequest} from "../../../app/hooks/useRequest";
import {addCaseLocationRequest, deleteCaseLocationRequest, updateCaseProgramLocationRequest} from "../CaseRequests";
import {yupResolver} from "@hookform/resolvers/yup";
import {AddCaseLocationFormDto} from "../dtos/CaseLocationFormDto";

type Modes = 'list' | 'edit' | 'delete';

export const useCaseProgramLocationHistoryDialog = (id: string) => {

  const {
    case: { caseResult },
    refreshCase
  } = useCaseDetailPageContext()

  const {
    control,
    reset,
    getValues,
    formState: { isValid }
  } = useForm({
    defaultValues: { locationId: '', startOn: dateToFormString(new Date()) },
    mode: "all",
    resolver: yupResolver(AddCaseLocationFormDto.getSchema()),
  })

  const {
    state: { locations },
    getters: { getProgramLocationId },
  } = useProgramLocationNestedFields(false, caseResult?.intakeInfo.intakeAt, caseResult?.program.id);

  // Requests
  const [
    getCaseProgramLocationHistory,
    locationHistory,
    getCaseProgramLocationHistoryRequestState,
  ] = useGetCaseProgramLocationHistory();

  const handleRefreshProgramLocationHistory = useCallback(async () => {
    await getCaseProgramLocationHistory(id);
  }, [getCaseProgramLocationHistory, id]);

  const [selectedLocation, setSelectedLocation] = useState<string | undefined>(undefined);

  const [mode, setMode] = useState<Modes>('list');

  const [addCaseLocation, , addCaseLocationRequestState, clearAddCaseLocationRequest] = useRequest(addCaseLocationRequest);
  const [updateCaseLocation, , updateCaseLocationRequestState, clearUpdateCaseLocationRequest] = useRequest(updateCaseProgramLocationRequest);
  const [deleteCaseLocation, , deleteCaseLocationRequestState, clearDeleteCaseLocationRequest] = useRequest(deleteCaseLocationRequest);
  const onEditClick = (id: string) => {
    const location = locationHistory?.find(l => l.id === id)
    if(!location) {
      console.log('error could not find location');
      return;
    }

    setSelectedLocation(location.id);
    setMode("edit");
    reset({
      locationId: location.locationId,
      startOn: dateToFormString(new Date(location.startOn))
    })
  }

  const onAddNewLocationClick = () => {
    setSelectedLocation('');
    setMode("edit");
    reset({
      locationId: '',
      startOn: dateToFormString(new Date())
    })
  }

  const cancelEditMode = () => {
    setMode("list");
    setSelectedLocation(undefined);
    clearAddCaseLocationRequest()
    clearUpdateCaseLocationRequest()
    clearDeleteCaseLocationRequest()
  }

  const onSaveChanges = async () => {
    if(selectedLocation == null) return;

    const { locationId, startOn } = getValues();
    const programLocationId = getProgramLocationId(caseResult!.program!.id, locationId)

    if(selectedLocation === '') {
      console.log('adding new location')
      await addCaseLocation({id: caseResult!.id, dto: { programLocationId, startOn }})
    } else {
      console.log('updating location ' + selectedLocation)
      await updateCaseLocation({id: selectedLocation, dto: { programLocationId, startOn }})
    }

    cancelEditMode()
    await handleRefreshProgramLocationHistory()
    await refreshCase()
  }

  const onDeleteLocationClick = (id: string) => {
    const location = locationHistory?.find(l => l.id === id)
    if(!location) {
      console.log('error could not find location');
      return;
    }

    setSelectedLocation(location.id);
    setMode("delete");
  }

  const onDeleteConfirm = async () => {
    if(selectedLocation == null) return;

    await deleteCaseLocation({ id: selectedLocation })
    cancelEditMode()
    await handleRefreshProgramLocationHistory()
    await refreshCase()
  }



  //const validProgramLocationIds = programLocations?.map(pl => pl.locationId) || [];

  return {
    locationHistory: locationHistory || [],
    getCaseProgramLocationHistoryRequestState,
    handleRefreshProgramLocationHistory,
    mode,
    selectedLocation,
    onAddNewLocationClick,
    onEditClick,
    locations: locations || [],
    onSaveChanges,
    cancelEditMode,
    onDeleteLocationClick,
    onDeleteConfirm,
    control,
    addCaseLocationRequestState,
    updateCaseLocationRequestState,
    deleteCaseLocationRequestState,
    editFormIsValid: isValid,
  };
};
