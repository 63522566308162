import { Box, Paper } from "@material-ui/core";
import React from "react";

const Section: React.FC<any> = ({ children }) => {
  return (
    <Paper variant="outlined">
      <Box padding="16px">{children}</Box>
    </Paper>
  );
};

export default Section;
