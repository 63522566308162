import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useGetInvoiceYears } from "../../invoices/hooks/invoicingHooks";
import { MonthlyReportCardByProviderSearchParamsDto } from "../dtos/MonthlyReportCardByProviderSearchParamsDto";
import { useDownloadMonthlyReportCardByProvider } from "./reportHooks";

export const useMonthlyReportCardByProviderPage = () => {
  const [getDocument, , getDocumentRequestState] =
    useDownloadMonthlyReportCardByProvider();
  const [getInvoiceYears, , getInvoiceYearsRequestState] = useGetInvoiceYears();
  const [invoiceYears, setInvoiceYears] = useState<number[]>([]);

  const handleSearch = async (
    params: MonthlyReportCardByProviderSearchParamsDto
  ) => {
    const filename = `Monthly_Report_Card_By_Provider_${new Date()
      .toLocaleDateString()
      .replaceAll("/", "_")}.xlsx`;

    await getDocument({ filename, params });
  };

  useEffect(() => {
    const load = async () => {
      let result = await getInvoiceYears(undefined);
      if (result) {
        const now = DateTime.now();

        if (now.month === 7) result = result.filter((x) => x === now.year);

        setInvoiceYears(result.sort((a, b) => (a > b ? 1 : -1)));
      }
    };

    load();
  }, [getInvoiceYears, setInvoiceYears]);

  return {
    document,
    getDocumentRequestState,
    invoiceYears,
    getInvoiceYearsRequestState,
    handleSearch,
  };
};
