import React, { useCallback, useEffect, useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import { useAppSelector } from "../../../app/hooks";
import { selectHasDeleteAccess } from "../../authentication/state/authenticationSelectors";
import { useSnapInSchoolsDetailPageContext } from "../pages/SnapInSchoolCyclesDetailPage";

const buildDeleteSnapInSchoolsCycleAction = (onClick: () => void) => {
  return (
    <MenuItem onClick={onClick} key="snap-in-schools-cycle-delete-menu-item">
      Delete SISC Cycle
    </MenuItem>
  );
};

const SnapInSchoolsCycleAdditionalActions = () => {
  const context = useSnapInSchoolsDetailPageContext();
  const { handleDeleteSnapInSchoolsCycleClicked } = context.snapCycle;
  const [menuItems, setMenuItems] = useState<React.ReactNode[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const canDeleteCycle = useAppSelector(
    selectHasDeleteAccess(PermissionResourceNameEnum.SnapInSchools)
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleAction = useCallback(
    (action: () => void) => () => {
      action();
      handleClose();
    },
    [handleClose]
  );

  useEffect(() => {
    const menuItems: React.ReactNode[] = [];

    const deleteCycleItem = buildDeleteSnapInSchoolsCycleAction(
      handleAction(handleDeleteSnapInSchoolsCycleClicked)
    );

    if (deleteCycleItem && canDeleteCycle) menuItems.push(deleteCycleItem);

    setMenuItems(menuItems);
  }, [
    setMenuItems,
    handleAction,
    handleDeleteSnapInSchoolsCycleClicked,
    canDeleteCycle,
  ]);

  if (menuItems.length === 0) return <></>;

  return (
    <DeleteAccessLevelWrapper name={PermissionResourceNameEnum.Snap}>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </DeleteAccessLevelWrapper>
  );
};

export default SnapInSchoolsCycleAdditionalActions;
