import Box from "@material-ui/core/Box/Box";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import { Control } from "react-hook-form";
import { CardActions } from "@material-ui/core";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { IpAddressConfirmationDto } from "../dtos/IpAddressConfirmationDto";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
interface Props {
  control: Control<IpAddressConfirmationDto>;
  isLoading: boolean;
  onSubmit(e?: React.BaseSyntheticEvent<any> | undefined): Promise<any>;
}

const IpAddressConfirmationForm: React.FC<Props> = ({
  onSubmit,
  isLoading,
  control,
}) => {
  return (
    <Card elevation={4}>
      <form onSubmit={onSubmit}>
        <CardContent>
          <Typography variant="h6" component="h6">
            Confirm PIN
          </Typography>
          <Grid container spacing={1}>
            {/* Could add this back in eventually, if we provide a way for them to navigate directly to this form */}
            {/* <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="email"
                label="Email"
                fullWidth
                disabled={isLoading}
              />
            </Grid> */}
            <Grid item md={12} xs={12}>
              <ControlledWholeNumberInput
                control={control}
                name="pin"
                label="PIN"
                fullWidth
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box width="100%" padding="4px" paddingTop="0">
            <SpinnerButton
              showSpinner={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              content="Confirm"
            />
          </Box>
        </CardActions>
      </form>
    </Card>
  );
};

export default IpAddressConfirmationForm;
