import React, { Fragment } from "react";
import { Checkbox, TextField } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";

interface Props extends AutocompleteProps<any, any, any, any> {
  control: Control<any>;
  label?: string;
  name: string;
  isLoading?: boolean;
}

const ControlledAutocomplete: React.FC<Props> = ({
  control,
  name,
  label,
  value,
  ...props
}) => {
  const { multiple } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            {...field}
            {...props}
            onChange={(_, data) => {
              field.onChange(data);
            }}
            renderOption={(option, state) => {
              const { selected } = state;
              return (
                <Fragment>
                  {multiple && <Checkbox color="primary" checked={selected} />}
                  {props.getOptionLabel ? props.getOptionLabel(option) : option}
                </Fragment>
              );
            }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                disabled={props.disabled}
                name={name}
                label={label}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        );
      }}
    />
  );
};

export default ControlledAutocomplete;
