import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { Fragment } from "react";
import DateTimeLabel from "../../../../../../app/components/labels/DateTimeLabel";
import { SuicideScreeningDto } from "../../../../../suicide-screenings/dtos/SuicideScreeningDto";
import { SuicideScreeningResponseEnum } from "../../../../../suicide-screenings/enums/SuicideScreeningResponseEnum";
import { SuicideScreeningTypeEnum } from "../../../../../suicide-screenings/enums/SuicideScreeningTypeEnum";
import {
  SuicideQuestion,
  SuicideQuestionNames,
  suicideScreeningPage1Questions,
} from "../../../../../suicide-screenings/hooks/useSuicideScreeningForm";

interface Props {
  suicideScreening: SuicideScreeningDto;
}

const SuicideScreeningItem: React.FC<Props> = ({ suicideScreening }) => {
  const { suicideScreeningResponseSet } = suicideScreening;

  const findQuestion = (name: string) => {
    const result = suicideScreeningPage1Questions.find((x) => x.name === name);
    return result;
  };

  const renderQuestion = (
    question: SuicideQuestion,
    response?: SuicideScreeningResponseEnum
  ) => {
    if (response == null) return <Fragment></Fragment>;

    return (
      <Fragment>
        <Grid item md={11} xs={10}>
          <Typography>{question.text}</Typography>
        </Grid>
        <Grid item md={1} xs={2}>
          <Box display="flex" justifyContent="end">
            <Typography>
              {response === SuicideScreeningResponseEnum.No ? "No" : "Yes"}
            </Typography>
          </Box>
        </Grid>
        {response !== SuicideScreeningResponseEnum.No && question.subQuestion &&(
          <Fragment>
            <Grid item md={11} xs={11}>
              <Typography style={{ marginLeft: "24px" }}>
                {question.subQuestion}
              </Typography>
            </Grid>
            <Grid item md={1} xs={1}>
              <Box display="flex" justifyContent="end">
                <Typography>
                  {response === SuicideScreeningResponseEnum.Yes ? "No" : "Yes"}
                </Typography>
              </Box>
            </Grid>
          </Fragment>
        )}
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
      </Fragment>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Box>
          <Typography component="span" style={{ fontWeight: "bold" }}>
            {suicideScreening.type === SuicideScreeningTypeEnum.Screening
              ? "Screening"
              : "Re-Screening"}{" "}
            on <DateTimeLabel date={suicideScreening.screeningAt} />
          </Typography>
        </Box>
      </Grid>
      {suicideScreeningResponseSet && (
        <Fragment>
          {renderQuestion(
            findQuestion(SuicideQuestionNames.selfHarmingBehaviors)!,
            suicideScreeningResponseSet.selfHarmingBehaviors
          )}
          {renderQuestion(
            findQuestion(SuicideQuestionNames.wishedDead)!,
            suicideScreeningResponseSet.wishedDead
          )}
          {renderQuestion(
            findQuestion(SuicideQuestionNames.suicidalThoughts)!,
            suicideScreeningResponseSet.suicidalThoughts
          )}
          {renderQuestion(
            findQuestion(SuicideQuestionNames.suicidalActions)!,
            suicideScreeningResponseSet.suicidalActions
          )}
          {renderQuestion(
            findQuestion(SuicideQuestionNames.suicidalThoughtHow)!,
            suicideScreeningResponseSet.suicidalThoughtHow
          )}
          {renderQuestion(
            findQuestion(SuicideQuestionNames.suicidalIntentToAct)!,
            suicideScreeningResponseSet.suicidalIntentToAct
          )}
          {renderQuestion(
            findQuestion(SuicideQuestionNames.suicidalPlanDetails)!,
            suicideScreeningResponseSet.suicidalPlanDetails
          )}
        </Fragment>
      )}
    </Grid>
  );
};

export default SuicideScreeningItem;
