import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { useAnchor } from "../../../app/hooks/useAnchor";
import { useQuery } from "../../../app/hooks/useQuery";
import { useRequest } from "../../../app/hooks/useRequest";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import {
  useGetAllProviders,
  useGetProviderLocations,
  useGetProviderPrograms
} from "../../provider/hooks/providerHooks";
import { exportScreeningsRequest } from "../ScreeningRequests";
import { ScreeningSearchParamsDto } from "../dtos/ScreeningSearchParamsDto";
import { useGetScreenings } from "./screeningHooks";

export const useScreeningsListPage = () => {
  //#region State
  const query = useQuery();
  const [params, setParams] = useState<ScreeningSearchParamsDto>(
    new ScreeningSearchParamsDto({ query })
  );
  const [getScreenings, screeningsResult, getScreeningsRequestState] = useGetScreenings();
  const history = useHistory();
  const [filterAnchorEl, handleOpenFilterClicked, handleCloseFilter] =
    useAnchor();

  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();

    const [getPrograms, , getProgramsRequestState] = useGetProviderPrograms();

  const [getLocations, , getLocationsRequestState] = useGetProviderLocations();

  const [providerLocations, setProviderLocations] = useState<{
    [key: string]: LocationDto[];
  }>({});

  const [providerPrograms, setProviderPrograms] = useState<{
    [key: string]: ProgramDto[];
  }>({});

  const [exportScreeningsList, , exportScreeningsRequestState] = 
    useRequest(exportScreeningsRequest);

  // This limit is also enforced server side, so they need to be in sync
  const exportRecordsLimit = 50000;
  const [allowExport, setAllowExport] = useState(true)
  //#endregion

  //#region Handlers

  const handlePageChange = (page: number) => {
    const newParams = { ...params, page };
    history.push(`/screenings?${QueryStringHelpers.toQueryString(newParams)}`);
    setParams(newParams);
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    history.push(`/screenings?${QueryStringHelpers.toQueryString(newParams)}`);
    setParams(newParams);
  };

  const handleSearch = useCallback(
    (dto: ScreeningSearchParamsDto) => {
      setParams(dto);
    },
    [setParams]
  );

  const handleExportListClicked = useCallback((dto: ScreeningSearchParamsDto) => {
    const filename = `screening-list-export-${DateTime.now().toFormat('yyyyLLddHHmmss')}.csv`
    exportScreeningsList({ searchParams: dto, filename });
  }, [exportScreeningsList])

  const updatePrograms = useCallback(
    async (providerId: string) => {
      if (
        providerId === "" ||
        providerId == null ||
        providerPrograms[providerId]
      ) {
        return;
      }

      const result = await getPrograms(providerId);
      if (result) {
        setProviderPrograms((current) => ({
          ...current,
          [providerId]: result,
        }));
      }
    },
    [setProviderPrograms, providerPrograms, getPrograms]
  );

  const updateLocations = useCallback(
    async (providerId: string) => {
      if (
        providerId === "" ||
        providerId == null ||
        providerLocations[providerId]
      ) {
        return;
      }

      const result = await getLocations(providerId);
      if (result) {
        setProviderLocations((current) => ({
          ...current,
          [providerId]: result,
        }));
      }
    },
    [setProviderLocations, providerLocations, getLocations]
  );

  const handleProviderChange = useCallback(
    async (providerId: string) => {
      await updateLocations(providerId);
      await updatePrograms(providerId);
    },
    [ updateLocations, updatePrograms ]
  );
  //#endregion

  //#region Effects
  useEffect(() => {
    getScreenings(params);
  }, [params, getScreenings]);

  useEffect(() => {
    history.push(`/screenings?${ScreeningSearchParamsDto.toQueryString(params)}`);
  }, [params, history]);

  useEffect(() => {
    getProviders(undefined);
  }, [getProviders]);

  useEffect(() => {
    const currentProviderId = getProviderId();

    if (currentProviderId == null) return;

    if (currentProviderId !== fnyfsProviderId) {
      updatePrograms(currentProviderId!);
      updateLocations(currentProviderId!);
    }
  }, [updatePrograms, updateLocations]);

  useEffect(() => {
    setAllowExport(!!screeningsResult?.count && screeningsResult.count < exportRecordsLimit);
  }, [screeningsResult?.count])

  //#endregion

  return {
    state: {
      filterAnchorEl,
      screeningsResult,
      params,
      providers: providers || [],
      getScreeningsRequestState,
      getProvidersRequestState,
      getProgramsRequestState,
      getLocationsRequestState,
      allowExport,
      exportRecordsLimit,
      exportScreeningsRequestState,      
      providerPrograms,
      providerLocations,
    },
    handlers: {
      handlePageChange,
      handleSearch,
      handleOpenFilterClicked,
      handleCloseFilter,
      handleProviderChange,
      handleSort,    
      handleExportListClicked  
    },
  };
};
