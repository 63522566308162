import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, {Fragment, useCallback, useEffect} from "react";
import MonthLabel from "../../../app/components/labels/MonthLabel";
import { ReportingPeriodDto } from "../../reporting-periods/dto/ReportingPeriodDto";
import ErrorIcon from "@material-ui/icons/Error";
import { TabProps } from "../../../app/components/TabContainer";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import EditLinkButton from "../../../app/components/buttons/EditLinkButton";
import {ContractDto} from "../../contracts/dtos/ContractDto";
import {useRequest} from "../../../app/hooks/useRequest";
import {getContractsRequest} from "../../contracts/ContractRequests";
import {
  useGetPreviousReportingPeriod, useGetReportingPeriods,
  useGetReportingPeriodStatus
} from "../../reporting-periods/hooks/reportingPeriodHooks";
import {DateTime} from "luxon";
import { ProgramTypeIdEnum } from "../../../app/enums/ProgramTypeIdEnum";

const useStatewideInvoicesList = () => {

  const [getContracts, contracts] = useRequest(getContractsRequest);
  const [getReportingPeriodStatus, reportingPeriodStatus] = useGetReportingPeriodStatus();
  const [getReportingPeriods, reportingPeriods] = useGetReportingPeriods()
  const [getPreviousReportingPeriod, previousReportingPeriod] = useGetPreviousReportingPeriod();

  const handleViewStatewideInvoiceXlsx = (reportingPeriodId: string, contractId: string) => {
      window.open(
        `${process.env.REACT_APP_INVOICE_URL}reports/invoices/fnyfs/summary?reportingPeriodId=${reportingPeriodId}&contractId=${contractId}`,
        "_blank"
      );
    }

  const handleViewStatewideInvoiceDetailPdf = (reportingPeriodId: string, contractId: string) => {
      window.open(
        `${process.env.REACT_APP_INVOICE_URL}reports/invoices/fnyfs/detail?reportingPeriodId=${reportingPeriodId}&contractId=${contractId}`,
        "_blank"
      );
    }

  const handleViewProviderSummaryPdf = (reportingPeriodId: string, contractId: string) => {
      window.open(
        `${process.env.REACT_APP_INVOICE_URL}reports/ProviderInvoiceSummaryReport?reportingPeriodId=${reportingPeriodId}&contractId=${contractId}`,
        "_blank"
      );
    }

    const handleDownloadAttendanceLogs = (reportingPeriodId: string, contractId: string) => {
      window.open(
        `${process.env.REACT_APP_INVOICE_URL}reports/SnapInSchoolsAttendanceLogDownload?reportingPeriodId=${reportingPeriodId}&contractId=${contractId}`,
        "_blank"
      );
    }

    const load = useCallback(() => {
      getContracts(null);
      getReportingPeriods(null);
      getPreviousReportingPeriod(null)
    }, [getContracts, getPreviousReportingPeriod, getReportingPeriods])

    useEffect(() => {
      load();
    }, [load])

  useEffect(() => {
    if(!previousReportingPeriod)
      return;

    getReportingPeriodStatus(previousReportingPeriod.id);
  }, [getReportingPeriodStatus, previousReportingPeriod])


  const currentMonth = DateTime.now().month;
  const currentYear = DateTime.now().year;

  return {
    contracts: contracts || [],
    previousReportingPeriod,
    onViewStatewideInvoiceXlsx: handleViewStatewideInvoiceXlsx,
    onViewStatewideInvoiceDetailPdf: handleViewStatewideInvoiceDetailPdf,
    onViewProviderSummaryPdf: handleViewProviderSummaryPdf,
    onDownloadAttendanceLogs: handleDownloadAttendanceLogs,
    status: reportingPeriodStatus,
    reportingPeriods: (reportingPeriods || []).filter(rp => rp.month !== currentMonth || rp.year !== currentYear)
  }
}

const StatewideInvoiceList: React.FC = () => {

  const {
    previousReportingPeriod,
    contracts,
    onViewStatewideInvoiceXlsx,
    onViewStatewideInvoiceDetailPdf,
    onViewProviderSummaryPdf,
    onDownloadAttendanceLogs,
    status,
    reportingPeriods
  } = useStatewideInvoicesList()


  //#region UI Helpers
  const renderPending = (contract: ContractDto, providers?: ProviderDto[]) => {
    if (providers === undefined || providers.length === 0)
      return <Fragment></Fragment>;

    return (
      <Box marginLeft={"8px"} component={"span"}>
        <PopupState variant="popover">
          {(popupState) => (
            <Fragment>
              <IconButton {...bindTrigger(popupState)} title="Pending Invoices">
                <Badge color="error" badgeContent={providers.length}>
                  <ErrorIcon />
                </Badge>
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                elevation={1}
              >
                <List style={{ padding: 0 }}>
                  <ListItem>
                    <ListItemText
                      secondary={
                        <Fragment>
                          The following providers have not submitted their{" "}
                          {contract.name} invoices. <br />{" "}
                          They will not be reflected on the statewide invoice.
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <Divider />
                  {providers.sort((a, b) => a.name > b.name ? 1 : -1).map((provider) => (
                    <Fragment key={`${provider.id}${contract.id}`}>
                      <ListItem style={{ marginLeft: "24px" }}>
                        <ListItemText primary={`${provider.name}`} />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                </List>
              </Popover>
            </Fragment>
          )}
        </PopupState>
      </Box>
    );
  };
  //#endregion

  const renderContractLinks = (contract: ContractDto, reportingPeriod: ReportingPeriodDto) => {
    return (<TableCell>

      <EditLinkButton
        onClick={(e) => {
          onViewStatewideInvoiceXlsx(
            reportingPeriod.id,
            contract.id
          );
        }}
        to={"/"}
      >
        Invoice
      </EditLinkButton>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <EditLinkButton
        onClick={(e) => {
          onViewStatewideInvoiceDetailPdf(
            reportingPeriod.id,
            contract.id
          );
        }}
        to={"/"}
      >
        Backup Doc.
      </EditLinkButton>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <EditLinkButton
        onClick={(e) => {
          onViewProviderSummaryPdf(
            reportingPeriod.id,
            contract.id
          );
        }}
        to={"/"}
      >
        Provider Summary
      </EditLinkButton>
      {contract.validProgramTypeIds.includes(ProgramTypeIdEnum.SnapInSchools) &&      
        <Fragment>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <EditLinkButton
            onClick={(e) => {
              onDownloadAttendanceLogs(
                reportingPeriod.id,
                contract.id
              );
            }}
            to={"/"}
          >
            SISC Attendance Logs
          </EditLinkButton>
        </Fragment>
      }
      {reportingPeriod.id === previousReportingPeriod?.id &&
        renderPending(
          contract,
          status?.pendingInvoices[contract.id]
        )}
    </TableCell>);
  }

  return (
    <Box height={"70vh"}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>Year</TableCell>
            {contracts.map(c => <TableCell>{c.name}</TableCell>)}

          </TableRow>
        </TableHead>
        <TableBody>
          {reportingPeriods.map((reportingPeriod) => (
            <TableRow key={reportingPeriod.id}>
              <TableCell>
                <MonthLabel month={reportingPeriod.month} />
              </TableCell>
              <TableCell>{reportingPeriod.year}</TableCell>

              {contracts
                .sort((a, b) => a.displayOrder > b.displayOrder ? 1: -1)
                .map(c => renderContractLinks(c, reportingPeriod))}

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export const createStatewideInvoiceListTab = (): TabProps => ({
  value: "statewide-invoices",
  label: <Typography>Statewide Invoices</Typography>,
  content: <StatewideInvoiceList />,
});

export default StatewideInvoiceList;
