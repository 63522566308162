import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { SnapGroupCycleSearchParamsDto } from "./SnapGroupCycleSearchParamsDto";

export class SnapCycleFilterFormDto {
  programTypeId: string;
  providerId: string;
  programId: string;  
  locationId: string;
  gender: string;
  cycleStartDateRangeStart: string;
  cycleStartDateRangeEnd: string;  
  fidelityRequired?: boolean;
  includeDeleted: boolean;

  constructor(dto?: SnapGroupCycleSearchParamsDto) {
    const currentProviderId = getProviderId();
    this.providerId = dto?.providerId || "";
    if (
      currentProviderId != null &&
      currentProviderId !== "" &&
      currentProviderId !== fnyfsProviderId
    )
      this.providerId = currentProviderId;
      
    this.programTypeId = dto?.programTypeId || "";
    this.programId = dto?.programId || "";    
    this.locationId = dto?.locationId || "";
    this.gender = dto?.gender || "";
    
    this.cycleStartDateRangeStart = dto?.cycleStartDateRangeStart || "";
    this.cycleStartDateRangeEnd = dto?.cycleStartDateRangeEnd || "";    
    this.fidelityRequired = dto?.fidelityRequired || false;
    this.includeDeleted = dto?.includeDeleted || false;
  }
}
