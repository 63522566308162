import React from "react"
import ControlledSelect from "./ControlledSelect";
import {Control} from "react-hook-form";
import {stateOptions} from "../../options/StateOptions";
import {SelectProps} from "@material-ui/core";

interface Props extends SelectProps  {
  control: Control<any>;
  label?: string;
  labelId?: string;
  name: string;
  size?: "small" | "medium";
}

export const ControlledStateSelect: React.FC<Props> = ({ control, name, ...props }) => {
  return <ControlledSelect control={control} name={name} options={[{ label: 'Select State', value: '' }, ...stateOptions]} {...props} />
}
