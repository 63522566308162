import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import InvoiceCorrectionConfirmationCheckbox from "../../../app/components/InvoiceCorrectionConfirmationCheckbox";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AlertDismissalForm from "../../alerts/components/AlertDismissalForm";
import { AlertTypeEnum } from "../../alerts/enums/AlertTypeEnum";
import { AssessmentDto } from "../../assessments/dtos/AssessmentDto";
import { AssessmentOverrideReasonDto } from "../../assessments/dtos/AssessmentOverrideReasonDto";
import { CaseHoldDto } from "../../case-hold/dtos/CaseHoldDto";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { YouthSummaryDto } from "../../youths/dto/YouthSummaryDto";
import { DischargeFormDto } from "../dtos/DischargeFormDto";
import { useDischargeForm } from "../hooks/useDischargeForm";
import DischargeCaseFormFields from "./DischargeCaseFormFields";

export const dischargeFormDialogId = "discharge-form-dialog";

// Check if it has a ToPSE assessment, or an override reason.
// If not, show Override Reason forms
// After submit, refresh Override Reasons
interface Props {
  onSubmit(dto: DischargeFormDto): Promise<void>;
  onRefreshAlertDismissals(): Promise<void>;
  requestState: RequestStateDto;
  youth?: YouthSummaryDto;
  caseHold?: CaseHoldDto;
  caseDto?: CaseDetailDto;
  assessmentOverrideReasons: AssessmentOverrideReasonDto[];
  assessments: AssessmentDto[];
}

const DischargeFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  youth,
  caseHold,
  caseDto,
  assessments,
  assessmentOverrideReasons,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { form, handlers, state } = useDischargeForm(
    onSubmit,
    caseDto,
    youth,
    caseHold
  );

  const { control, isValid, clearForm } = form;
  const { handleSubmit, handleClearPostAssessmentRequired, toggleIsConfirmed } =
    handlers;
  const {
    staffMembers,
    getProviderStaffMemberRequestState,
    needsPostAssessment,
    isEdit,
    isConfirmed,
  } = state;
  const { isLoading, error } = requestState;
  //#endregion

  const renderContent = () => {
    if (needsPostAssessment)
      return (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <AlertDismissalForm
              onSubmitSuccess={handleClearPostAssessmentRequired}
              caseDto={caseDto}
              message="A NIRVANA Post-Assessment is not submitted for this Case. To discharge the Case, please submit a NIRVANA Post-Assessment or enter the reason why the Assessment is not completed."
              type={AlertTypeEnum.CaseNirvanaPostAssessmentRequired}
            />
          </Grid>
        </Grid>
      );

    return (
      <form id="discharge-form">
        <Grid container spacing={2}>
          <DischargeCaseFormFields
            staffMembers={staffMembers || []}
            counselorsAreLoading={getProviderStaffMemberRequestState.isLoading}
            control={control}
            disabled={isLoading}
          />

          {isEdit && (
            <Grid item md={12} xs={12}>
              <InvoiceCorrectionConfirmationCheckbox
                isConfirmed={isConfirmed}
                onCheck={toggleIsConfirmed}
              />
            </Grid>
          )}
        </Grid>
      </form>
    );
  };

  //#region UI Helpers

  //#endregion

  //#region Handlers
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === dischargeFormDialogId} maxWidth="md" fullWidth>
      <DialogTitle>
        Discharge Case
        {error && (
          <Box marginTop="8px" color="red">
            <Typography>{error.message}</Typography>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <SpinnerButton
          content="Cancel"
          showSpinner={false}
          type="button"
          onClick={handleCloseModal}
          disabled={isLoading}
        />
        <SpinnerButton
          showSpinner={isLoading}
          content="Submit"
          color="primary"
          type="submit"
          form="discharge-form"
          onClick={handleSubmit}
          disabled={!isValid || isLoading || needsPostAssessment}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DischargeFormDialog;
