import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";

export interface SnapAssessmentContextDto {
  agencyId: string;
  agencyName: string;
  startedOn: string;
}

export const createSnapAssessmentContextDto = (
  cycle: SnapInSchoolsCycleDto
): SnapAssessmentContextDto => {
  return {
    agencyId: cycle.provider.id,
    agencyName: cycle.provider.name,
    startedOn: cycle.startedOn.toString(),
  };
};
