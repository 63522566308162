import { DateTime } from "luxon";
import React, { Fragment } from "react";

export const formatDate = (date: Date) =>
  DateTime.fromJSDate(new Date(date)).toLocaleString();

interface Props {
  date?: Date;
}

const DateLabel: React.FC<Props> = ({ date }) => {
  if (date === undefined) return <Fragment></Fragment>;
  return <Fragment>{formatDate(date)}</Fragment>;
};

export default DateLabel;
