import { useState } from "react";
import { getIsFnyfsUser } from "../../authentication/state/authenticationActions";
import { NirvanaExportSearchParamsDto } from "../dtos/NirvanaExportSearchParamsDto";
import { useDownloadNirvanaExportReport } from "./reportHooks";

export const useNirvanaExportReportPage = () => {
  // Requests
  const [
    downloadNirvanaExportReport,
    ,
    downloadNirvanaExportReportRequestState,
  ] = useDownloadNirvanaExportReport();
  const [params, setParams] = useState<NirvanaExportSearchParamsDto>(
    new NirvanaExportSearchParamsDto()
  );

  const isFnyfsUser = getIsFnyfsUser();

  const handleSearch = async (dto: NirvanaExportSearchParamsDto) => {
    setParams(dto);
    await downloadNirvanaExportReport({
      dto,
      filename: `NIRVANA_Export_Report_${new Date()
        .toLocaleDateString()
        .replaceAll("/", "_")}.csv`,
    });
  };

  return {
    handlers: { handleSearch },
    state: {
      params,
      downloadNirvanaExportReportRequestState,
      isFnyfsUser,
    },
  };
};
