import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../../app/components/ErrorText";
import ConfirmDialog from "../../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../../app/enums/ColorsEnum";

interface Props {
  onConfirm: () => Promise<void>;
  requestState: RequestStateDto;
}

export const confirmDeleteDeliverableRateDialogId =
  "delete-deliverable-rate-dialog";
const ConfirmDeleteDeliverableRateDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteDeliverableRateDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Rate"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to delete this deliverable rate?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteDeliverableRateDialog;
