import { invoicingAgent } from "../../app/api/agent";
import { ReportingPeriodDto } from "./dto/ReportingPeriodDto";
import { ReportingPeriodStatusDto } from "./dto/ReportingPeriodStatusDto";

export const getCurrentReportingPeriodRequest = () =>
  invoicingAgent.getRequest<ReportingPeriodDto>(
    "/api/reportingPeriods/current"
  );

export const getPreviousReportingPeriodRequest = () =>
  invoicingAgent.getRequest<ReportingPeriodDto>(
    "/api/reportingPeriods/previous"
  );

export const getReportingPeriodStatusRequest = (id: string) =>
  invoicingAgent.getRequest<ReportingPeriodStatusDto>(
    `/api/reportingPeriods/${id}/status`
  );

export const getReportingPeriodsRequest = () =>
  invoicingAgent.getRequest<ReportingPeriodDto[]>(
    `/api/reportingPeriods/list`
  );
