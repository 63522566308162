import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { Variant } from "@material-ui/core/styles/createTypography";
import React from "react";
import { NameDto } from "../../dtos/NameDto";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  nameDto?: NameDto;
  variant?: Variant | "inherit";
  style?: React.CSSProperties;
}

const NameLabel: React.FC<Props> = ({ nameDto, variant, ...props }) => {
  return (
    <Typography {...props} variant={variant}>
      {nameDto?.firstName} {nameDto?.lastName}
    </Typography>
  );
};

export default NameLabel;
