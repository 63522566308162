import { Box, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { Location } from "history";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import { EventLogListDto } from "../dtos/EventLogListDto";
import {useRequest} from "../../../app/hooks/useRequest";
import {getUserRequest} from "../../users/UserRequests";
import {UserDetailDto} from "../../users/dtos/UserDetailDto";
import NameLabel from "../../../app/components/labels/NameLabel";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import { AddBoxOutlined, IsoOutlined } from "@material-ui/icons";

interface Props {
  eventDto: EventLogListDto;
}

interface MessageTypeMetadata {
  displayName: string;
  generateUrl: (event: EventLogListDto, location: Location<any>) => JSX.Element;
}

const generateCaseLink = (event: EventLogListDto, location: Location<any>) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}>
      <Typography style={{ paddingRight: "5px" }} variant={"body2"}>
        Case
      </Typography>
      <RouteableStyledLink text="Back to Invoicing Event Log"
                           pathname={`/cases/${event.referenceId}`}>
        {event.caseName}
      </RouteableStyledLink>
    </Box>
  );
};

const generateCaseSessionLink = (event: EventLogListDto, location: Location<any>) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}>
      <Typography style={{ paddingRight: "5px" }} variant={"body2"}>
        Case
      </Typography>
      <RouteableStyledLink text="Back to Invoicing Event Log"
                           pathname={`/cases/${event.caseId}`}>
        {event.caseName}
      </RouteableStyledLink>
    </Box>
  );
};

const generateSnapGroupLink = (
  event: EventLogListDto,
  location: Location<any>
) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}>
      <Typography style={{ paddingRight: "5px" }} variant={"body2"}>
        SNAP Group
      </Typography>
      <RouteableStyledLink text="Back to Invoicing Event Log"
                           pathname={`/snap-cycles/${event.snapCycleId}/groups/${event.snapGroupId}`}>
        {event.snapCycleNumber} ({event.groupDate})
      </RouteableStyledLink>
    </Box>
  );
};

const generateSnapCycleLink = (
  event: EventLogListDto,
  location: Location<any>
) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}>
      <Typography style={{ paddingRight: "5px" }} variant={"body2"}>
        SNAP Group
      </Typography>
      <RouteableStyledLink text="Back to Invoicing Event Log"
                           pathname={`/snap-cycles/${event.snapCycleId}`}>
        {event.snapCycleNumber}
      </RouteableStyledLink>
    </Box>
  );
};

const generateSnapInSchoolGroupLink = (
  event: EventLogListDto,
  location: Location<any>
) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}>
      <Typography style={{ paddingRight: "5px" }} variant={"body2"}>
        SNAP in Schools and Communities Session
      </Typography>
      <RouteableStyledLink text="Back to Invoicing Event Log"
                           pathname={`/snap-in-schools/${event.snapCycleId}/sessions`}>
        {event.snapCycleNumber} ({event.groupDate})
      </RouteableStyledLink>
    </Box>
  );
};

const generateUnknownReferenceIdLink = (
  event: EventLogListDto,
  _: Location<any>
) => {
  return <Typography>{event.referenceId}</Typography>;
};

const generateProviderLink = (event: EventLogListDto) => {
  return (
    <RouteableStyledLink text="Back to Invoicing Event Log"
                         pathname={`/provider/${event.providerId}`}>
      {event.providerName}
    </RouteableStyledLink>
  );
};

const messageTypeNames: { [index: string]: MessageTypeMetadata } = {
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseSyncMessage": {
    displayName: "Case Synchronized",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseCreatedMessage": {
    displayName: "Case Created",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseUpdatedMessage": {
    displayName: "Case Updated",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseDischargedMessage": {
    displayName: "Case Discharged",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseIntakeDateChangedMessage": {
    displayName: "Intake Date Changed",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseDischargeDateChangedMessage": {
    displayName: "Discharge Date Changed",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseProgramChangedMessage": {
    displayName: "Case Program Changed",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseHoldStartedMessage": {
    displayName: "Case Hold Started",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseCourtOrderedChangedMessage": {
    displayName: "Court Ordered Status Changed",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseHtstStatusChangedMessage": {
    displayName: "HTST Status Changed",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseNonBillableDaysChangedMessage": {
    displayName: "Non-Billable Days Changed",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseDeletedMessage": {
    displayName: "Case Deleted",
    generateUrl: generateCaseLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseSessionCreatedMessage": {
    displayName: "Case Session Created",
    generateUrl: generateCaseSessionLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.CaseSessionDeletedMessage": {
    displayName: "Case Session Deleted",
    generateUrl: generateCaseSessionLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.SnapGroupCaseCreatedMessage": {
    displayName: "Youth added to SNAP Group",
    generateUrl: generateSnapGroupLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.SnapGroupFidelityInfoChangedMessage": {
    displayName: "SNAP Group Fidelity Info Changed",
    generateUrl: generateSnapGroupLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.SnapInSchoolsSessionCreatedMessage": {
    displayName: "SNAP In Schools and Communities Session Created",
    generateUrl: generateSnapInSchoolGroupLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.SnapGroupCycleDeletedMessage": {
    displayName: "SNAP Group Cycle Deleted",
    generateUrl: generateSnapCycleLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.SnapGroupDeletedMessage": {
    displayName: "SNAP Group Deleted",
    generateUrl: generateSnapGroupLink,
  },
  "Fnyfs.Netmis.Messaging.MessageTypes.SnapGroupCaseDeletedMessage": {
    displayName: "SNAP Group Youth Removed",
    generateUrl: generateSnapGroupLink,
  },
};

const DeferredUserNameLabel: React.FC<{userId: string}> = ({ userId }) => {

  const [getUserData, userDataResult, userDataRequestState] = useRequest(getUserRequest);

  const [user, setUser] = useState<UserDetailDto | undefined>(undefined);

  useEffect(() => {
    if(userId !== "00000000-0000-0000-0000-000000000000")
      getUserData(userId)
  }, [getUserData, userId])

  useEffect(() => {
    if(userDataResult) {
      setUser(userDataResult);
    }
  }, [userDataResult])

  if(userDataRequestState.isLoading)
    return <div>Loading...</div>
  else if (!user)
    return <div>Unknown User</div>
  else
    return (
      <RouteableStyledLink text="Back to Invoicing Event Log"
                           pathname={`/users/${user.id}`}>
        <NameLabel nameDto={user} variant={"body2"} />
      </RouteableStyledLink>
    );
}

const EventLogListItem: React.FC<Props> = ({ eventDto }) => {
  const messageMetadata = messageTypeNames[eventDto.name] || {
    displayName: eventDto.name,
    generateUrl: generateUnknownReferenceIdLink,
  };
  const location = useLocation();

  return (
    <TableRow key={eventDto.id}>
      <TableCell>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>{messageMetadata.displayName}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <DateTimeLabel date={eventDto.consumedAt} />
      </TableCell>
      <TableCell>{messageMetadata.generateUrl(eventDto, location)}</TableCell>
      <TableCell>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {generateProviderLink(eventDto)}
        </Box>
      </TableCell>
      <TableCell><DeferredUserNameLabel userId={eventDto.userId} /></TableCell>
      <TableCell align="center">
        {eventDto.hasCorrections &&
        <Tooltip title={<Typography>This event affected deliverable(s) and occurred after the month in which the service happened, possibly resulting in an invoice correction.</Typography>}>
           <IsoOutlined color="primary"/>
        </Tooltip>}

        {eventDto.hasDeliverables && !eventDto.hasCorrections &&
          <Tooltip title={<Typography>This event affected one or more deliverable(s).</Typography>}>
            <AddBoxOutlined color="primary" />
          </Tooltip>}
      </TableCell>
    </TableRow>
  );
};

export default EventLogListItem;
