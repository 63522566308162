import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledPhoneNumberInput from "../../../app/components/inputs/ControlledPhoneNumberInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { YouthContactFormDto } from "../dtos/YouthContactFormDto";
import { getYouthRelationshipOptions } from "../enums/YouthRelationshipEnum";
import * as Yup from "yup";
import {ControlledStateSelect} from "../../../app/components/inputs/ControlledStateSelect";
export const youthContactSchema = Yup.object().shape({
  firstName: Yup.string().label("First Name").max(100),
  lastName: Yup.string().label("Last Name").max(100),
  address: Yup.string().label("Address").max(75),
  city: Yup.string().label("City").max(75),
  state: Yup.string().label("State").max(2),
  zipCode: Yup.string().label("Zip Code").max(20),
  county: Yup.string().label("County").max(75),
  phoneNumber: Yup.string().label("Phone NUmber").max(20),
  notes: Yup.string().label("Notes").max(1000),
  email: Yup.string().label("Email").email(),
});

interface Props {
  control: Control<YouthContactFormDto>;
  isDisabled: boolean;
  isLegacy: boolean;
}

const YouthContactFormFields: React.FC<Props> = ({ control, isDisabled, isLegacy }) => {
  return (
    <Fragment>
      <Grid item md={6} xs={12}>
        <ControlledInput control={control} name="firstName" label="First Name" disabled={isDisabled} fullWidth />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledInput control={control} name="lastName" label="Last Name" disabled={isDisabled} fullWidth />
      </Grid>

      <Grid item md={12} xs={12}>
        <ControlledSelect
          options={getYouthRelationshipOptions(isLegacy)}
          control={control}
          name="relationship"
          label="Relationship to Youth"
          disabled={isDisabled}
          fullWidth
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledPhoneNumberInput control={control} name="phoneNumber" label="Phone Number" disabled={isDisabled} fullWidth />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledInput control={control} name="email" label="Email" disabled={isDisabled} fullWidth />
      </Grid>
      <Grid item md={5} xs={12}>
        <ControlledCheckbox
          control={control}
          name="isLegalGuardianOrPowerOfAttorney"
          label="Legal Guardian or Power of Attorney"
          disabled={isDisabled}
          color="primary"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <ControlledCheckbox control={control} name="isEmergencyContact" label="Emergency Contact" disabled={isDisabled} color="primary" />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput control={control} name="address" label="Address" disabled={isDisabled} fullWidth />
      </Grid>
      <Grid item md={5} xs={12}>
        <ControlledInput control={control} name="city" label="City" disabled={isDisabled} fullWidth />
      </Grid>
      <Grid item md={4} xs={12}>
        <ControlledStateSelect control={control} name={"state"} label={"State"} fullWidth />
      </Grid>
      <Grid item md={3} xs={12}>
        <ControlledInput control={control} name="zipCode" label="Zip" disabled={isDisabled} fullWidth />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput control={control} name="county" label="County" disabled={isDisabled} fullWidth />
      </Grid>

      <Grid item md={12} xs={12}>
        <ControlledInput multiline minRows={3} control={control} name="notes" label="Notes" disabled={isDisabled} fullWidth />
      </Grid>
    </Fragment>
  );
};

export default YouthContactFormFields;
