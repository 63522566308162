import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
interface Props {
  expanded: boolean;
}

const ExpandIcon = ({ expanded }: Props) => {
  return <>{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</>;
};

export default ExpandIcon;
