import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React, {useEffect} from "react";
import SpinnerButton from "../../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../../app/components/ErrorText";
import HtmlErrorText from "../../../../app/components/HtmlErrorText";
import InvoiceCorrectionConfirmationCheckbox from "../../../../app/components/InvoiceCorrectionConfirmationCheckbox";
import { RequestStateDto } from "../../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectModalId } from "../../../modal/state/modalSelectors";
import { closeModal } from "../../../modal/state/modalSlice";
import { CaseDetailDto } from "../../dtos/CaseDetailDto";
import { CaseProgramFormDto } from "../../dtos/CaseProgramFormDto";
import { useCaseProgramForm } from "../../hooks/useCaseProgramLocationForm";
import CaseProgramFormFields from "./CaseProgramFormFields";

export const caseProgramLocationModalId = "case-program-location-form-modal";

interface Props {
  onSubmit: (dto: CaseProgramFormDto) => Promise<any>;
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
}

const CaseProgramLocationFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  caseDto,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers, state } = useCaseProgramForm(onSubmit, caseDto);
  const { handleSubmit, toggleIsConfirmed } = handlers;
  const { isValid, control, clearForm } = form;
  const {
    locations,
    providerPrograms,
    getProviderLocationsRequestState,
    getProviderProgramsRequestState,
    isConfirmed,
  } = state;

  const {
    error: getProviderLocationsError,
    isLoading: providerLocationsAreLoading,
  } = getProviderLocationsRequestState;
  const {
    error: getProviderProgramsError,
    isLoading: providerProgramsAreLoading,
  } = getProviderProgramsRequestState;
  const { error } = requestState;
  const isLoading =
    providerLocationsAreLoading ||
    providerProgramsAreLoading ||
    requestState.isLoading;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  useEffect(() => {
    // do not trigger clear form if the modal hasn't changed
    if(modalId === caseProgramLocationModalId)
      return;

    clearForm();
  }, [modalId, clearForm]);

  let criteriaMessage = "";
  if (caseDto)
    criteriaMessage = caseDto?.dischargeInfo
      ? `Only Program/Locations that were active during ${new Date(
          caseDto.intakeInfo.intakeAt
        ).toLocaleDateString()} through ${new Date(
          caseDto.dischargeInfo.dischargedAt
        ).toLocaleDateString()} are displayed.`
      : `Only currently active Program/Locations that were also active on ${new Date(
          caseDto.intakeInfo.intakeAt
        ).toLocaleDateString()} are displayed.`;

  return (
    <Dialog
      open={modalId === caseProgramLocationModalId}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Change Program
          <HtmlErrorText error={error?.message} />
          <ErrorText error={getProviderProgramsError?.message} />
          <ErrorText error={getProviderLocationsError?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <CaseProgramFormFields
              control={control}
              providerPrograms={providerPrograms}
              locations={locations}
              providerLocationsAreLoading={
                getProviderProgramsRequestState.isLoading
              }
              providerProgramsAreLoading={
                getProviderLocationsRequestState.isLoading
              }
              isDisabled={isLoading}
              locationMessage={criteriaMessage}
            />
          </Grid>
          <br />
          <Typography>
            <strong>
              Changing the Program will remove this Case's location history, if
              any.
            </strong>
          </Typography>
          <br />
          <InvoiceCorrectionConfirmationCheckbox
            onCheck={toggleIsConfirmed}
            isConfirmed={isConfirmed}
          />
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CaseProgramLocationFormDialog;
