import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGetProgramTypeDeliverables } from "../../program-type-deliverables/hooks/programTypeDeliverableHooks";
import { providerFiscalYearSchema } from "../components/ProviderFiscalYearFormFields";
import { ProviderFiscalYearCreateOrUpdateDto, ProviderFiscalYearDto } from "../dtos/ProviderFiscalYearDto";
import { createProviderFiscalYearFormDto, ProviderFiscalYearFormDto } from "../dtos/ProviderFiscalYearFormDto";
import { ContractDto } from "../../contracts/dtos/ContractDto";
import { useProviderDetailPageContext } from "../../provider/pages/ProviderDetailPage";
import { ProgramTypeDeliverableDto } from "../../program-type-deliverables/dtos/ProgramTypeDeliverableDto";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
import { ProgramTypeIdEnum } from "../../../app/enums/ProgramTypeIdEnum";

export const useProviderFiscalYearForm = (
  onSubmit: (dto: ProviderFiscalYearCreateOrUpdateDto) => Promise<any>,
  contracts: ContractDto[],
  fiscalYear?: ProviderFiscalYearDto
) => {
  //#region State
  const { provider } = useProviderDetailPageContext();
  const [getProgramTypeDeliverables, programTypeDeliverables, getProgramTypeDeliverablesRequestState, clearGetProgramTypeDeliverablesError] =
    useGetProgramTypeDeliverables();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createProviderFiscalYearFormDto(contracts, programTypeDeliverables || [], fiscalYear),
    resolver: yupResolver(providerFiscalYearSchema),
    mode: "all",
  });

  const [activeProgramTypeDeliverables, setActiveProgramTypeDeliverables] = useState<ProgramTypeDeliverableDto[]>([]);
  //#endregion

  const clearForm = () => {
    clearGetProgramTypeDeliverablesError();
    reset(createProviderFiscalYearFormDto(contracts, programTypeDeliverables || [], fiscalYear));
  };
  const handleOnSubmit = handleSubmit(async (dto: ProviderFiscalYearFormDto) => {
    if (!fiscalYear) return;
    const contractLimitDtos = Object.keys(dto.contractLimits)
      .flatMap((contractId) =>
        Object.keys(dto.contractLimits[contractId]).flatMap((programTypeDeliverableId) => {
          const existing = fiscalYear.contractLimits.find((cl) => cl.contract.id === contractId && cl.programTypeDeliverableId === programTypeDeliverableId);
          return {
            id: existing?.id,
            providerFiscalYearId: fiscalYear.id,
            contractId,
            programTypeDeliverableId,
            limit: dto.contractLimits[contractId][programTypeDeliverableId].limit,
          };
        })
      )
      .filter((c) => c.limit);

    await onSubmit({
      licensedBeds: dto.licensedBeds,
      fiscalYear: dto.fiscalYear,
      providerId: provider.id,
      contractLimits: contractLimitDtos,
    });
    clearForm();
  });

  useEffect(() => {
    reset(createProviderFiscalYearFormDto(contracts, programTypeDeliverables || [], fiscalYear));
  }, [contracts, reset, fiscalYear, programTypeDeliverables]);

  useEffect(() => {
    getProgramTypeDeliverables("");
  }, [getProgramTypeDeliverables]);

  useEffect(() => {
    if (fiscalYear && provider && programTypeDeliverables) {
      const endDate = new Date(fiscalYear.fiscalYear + 1, 6, 0, 0, 0);
      const startDate = new Date(fiscalYear.fiscalYear, 6, 1, 0, 0);
      const activeProgramTypeIds = provider.provider?.programs
        .filter(
          (x) =>
            x.fundingSource === ProgramFundingSourceEnum.FloridaNetwork &&
            x.locations.some((location) => {
              // location.startOn <= endDate && (location.terminatedOn == null || location.terminated < startDate)
              return new Date(location.startedOn) <= endDate && (location.terminatedOn == null || new Date(location.terminatedOn) >= startDate);
            })
        )
        .map((x) => x.programType.id);
      const result = programTypeDeliverables.filter(
        (x) => x.programType.id === ProgramTypeIdEnum.SnapInSchools || activeProgramTypeIds?.includes(x.programType.id)
      );
      setActiveProgramTypeDeliverables(result);
    }
  }, [fiscalYear, provider, programTypeDeliverables, setActiveProgramTypeDeliverables]);
  return {
    state: {
      programTypeDeliverables: activeProgramTypeDeliverables,
      getProgramTypeDeliverablesRequestState,
    },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
