import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { datetimeToFormString } from "../../../app/helpers";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { CaseFormDto } from "../../cases/dtos/CaseFormDto";
import { useGetSchoolsNames } from "../../cases/hooks/caseHooks";
import {
  currentGradeStatus,
  lastGradeAttendedStatus,
} from "../../intakes/components/IntakeFormFields";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { useGetProviderStaffMembers } from "../../provider/hooks/providerHooks";
import { ScreeningDto } from "../../screenings/dtos/ScreeningDto";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import { useProgramLocationNestedFields } from "../../youths/hooks/useProgramLocationNestedFields";

export const useCaseForm = (
  screenings: ScreeningDto[],
  onSubmit: (dto: CaseFormDto) => Promise<void>,
  form: UseFormReturn<CaseFormDto, object>,
  updateScreening: (screeningId: string) => void
): CaseFormProps => {
  //#region State


  const { watch, handleSubmit, setValue, trigger } = form;
  const { programId, locationId, screeningId, intakeAt } = watch();

  const {
    state: {
      locations,
      providerPrograms,
      getProviderLocationsRequestState,
      getProviderProgramsRequestState,
    },
    getters: { getProgramLocationId },
  } = useProgramLocationNestedFields(false, intakeAt, programId);

  const [
    getProviderStaffMembers,
    staffMembers,
    getProviderStaffMemberRequestState,
  ] = useGetProviderStaffMembers();
  const [getSchoolNames, schoolNames, getSchoolNamesRequestState] =
    useGetSchoolsNames();

  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit(async (dto: CaseFormDto) => {
    if (!dto.isYouthOnMedication) dto.youthMedicationCount = undefined;

    if (!currentGradeStatus.includes(dto.schoolStatus))
      dto.currentGrade = undefined;

    if (!lastGradeAttendedStatus.includes(dto.schoolStatus))
      dto.lastGradeAttended = undefined;
    if (!currentGradeStatus.includes(dto.schoolStatus)) dto.schoolName = "";
    await onSubmit(dto);
  });
  //#endregion

  //#region Effects
  useEffect(() => {
    const providerId = getProviderId();
    if (providerId) {
      getProviderStaffMembers(providerId);
    }
  }, [getProviderStaffMembers]);

  useEffect(() => {
    const programIdInvalid = programId && providerPrograms.find(p => p.id === programId) == null;

    if(programIdInvalid) {
      const newProgramId = providerPrograms.length === 1 ? providerPrograms[0].id : "";

      setValue("programId", newProgramId, {
        shouldValidate: true,
      });
    }
  }, [programId, providerPrograms, setValue]);

  useEffect(() => {
    const locationIdInvalid = locationId && locations.find(l => l.id === locationId) == null;

    if(locationIdInvalid) {
      const newLocationId = locations.length === 1 ? locations[0].id : "";

      setValue("locationId", newLocationId, {
        shouldValidate: true,
      });
    }
  }, [locationId, locations, setValue]);

  useEffect(() => {
    setValue("programLocationId", getProgramLocationId(programId, locationId));
  }, [programId, locationId, setValue, getProgramLocationId]);

  useEffect(() => {
    if (screeningId === "" || screeningId == null) {
      setValue("screeningAt", undefined);
      return;
    }
    const screening = screenings.find((x) => x.id === screeningId);

    if (screening == null) {
      setValue("screeningAt", undefined);
      return;
    }

    setValue(
      "screeningAt",
      datetimeToFormString(new Date(screening.screeningAt))
    );
  }, [screenings, screeningId, setValue]);

  useEffect(() => {
    getSchoolNames(undefined);
  }, [getSchoolNames]);

  // useEffect(() => {
  //   const program = providerPrograms?.find((x) => x.id === programId);
  //   if (program) setValue("fundingSource", program.fundingSource);
  //   else setValue("fundingSource", "");
  // }, [programId, providerPrograms, setValue]);

  useEffect(() => {
    updateScreening(screeningId || "");
  }, [updateScreening, screeningId]);

  const handleFileUpdated = (filename: string) => {
    setValue("courtOrderDocumentFilename", filename);
    trigger("courtOrderDocumentFilename");
  };

  //#endregion
  return {
    providerPrograms: providerPrograms || [],
    locations: locations || [],
    staffMembers: staffMembers || [],
    schoolNames: schoolNames || [],
    // providerLocations: providerLocations || [],
    getProviderLocationsRequestState,
    getProviderStaffMemberRequestState,
    getProviderProgramsRequestState,
    getSchoolNamesRequestState,
    handleCourtOrderDocumentUploaded: handleFileUpdated,
    handleSubmit: handleOnSubmit,
    getProgramLocationId,
  };
};

interface CaseFormProps {
  providerPrograms: ProgramDto[];
  locations: LocationDto[];
  // providerLocations: LocationDto[];
  getProviderLocationsRequestState: RequestStateDto;
  getProviderStaffMemberRequestState: RequestStateDto;
  getProviderProgramsRequestState: RequestStateDto;
  getSchoolNamesRequestState: RequestStateDto;
  staffMembers: StaffMemberDto[];
  schoolNames: string[];
  handleCourtOrderDocumentUploaded:(filename: string) => void;  
  handleSubmit(
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ): Promise<void>;
  getProgramLocationId(programId: string, locationId: string): string;
}
