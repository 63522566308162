import { SortDirectionEnum } from "../enums/SortDirectionEnum";

export interface PaginationSearchParamsDto {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;
}

export const convertSortDirection = (direction: string) => {
  if (direction)
    return direction === "ascending"
      ? SortDirectionEnum.Ascending
      : SortDirectionEnum.Descending;

  return undefined;
};

export const paginationSearchParamsToQueryString = (
  dto: PaginationSearchParamsDto
) => {
  const queryString: string[] = [];

  if (dto.page !== undefined) queryString.push(`page=${dto.page}`);

  if (dto.pageSize) queryString.push(`pageSize=${dto.pageSize}`);

  if (dto.sortBy) queryString.push(`sortBy=${dto.sortBy}`);

  if (dto.sortDirection)
    queryString.push(
      `sortDirection=${
        dto.sortDirection === SortDirectionEnum.Ascending
          ? "ascending"
          : "descending"
      }`
    );

  return queryString.join("&");
};
