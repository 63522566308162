import { Typography, TypographyTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Variant } from "@material-ui/core/styles/createTypography";
import React, { Fragment } from "react";

interface Props extends Partial<OverridableComponent<TypographyTypeMap<{}>>> {
  phoneNumber?: string;
  variant?: Variant | "inherit";
}

const PhoneNumberLabel: React.FC<Props> = ({ phoneNumber, ...props }) => {
  if (phoneNumber === undefined || phoneNumber.trim() === "")
    return <Fragment></Fragment>;

  if (phoneNumber.length !== 10)
    return <Typography {...props}>{phoneNumber}</Typography>;

  return (
    <Typography component="span" {...props}>
      ({phoneNumber.substr(0, 3)}) {phoneNumber.substr(3, 3)} -{" "}
      {phoneNumber.substr(6, 4)}
    </Typography>
  );
};

export default PhoneNumberLabel;
