import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { useProgramLocationNestedFields } from "../../youths/hooks/useProgramLocationNestedFields";
import { CaseLocationFormDto } from "../dtos/CaseLocationFormDto";

export const useCaseLocationForm = (
  onSubmit: (dto: CaseLocationFormDto) => Promise<any>,
  caseDto?: CaseDetailDto
) => {
  //#region State
  const {
    control,
    watch,
    formState: { isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    defaultValues: new CaseLocationFormDto(undefined),
    resolver: yupResolver(CaseLocationFormDto.getSchema()),
    mode: "all",
  });
  const { programId, locationId, startOn } = watch();

  const {
    state: { locations, getProviderLocationsRequestState },
    getters: { getProgramLocationId },
  } = useProgramLocationNestedFields(false, startOn, programId);

  const clearForm = useCallback(() => {
    const dto = new CaseLocationFormDto(caseDto);
    dto.programLocationId = getProgramLocationId(dto.programId, dto.locationId);
    reset(dto);
  }, [reset, getProgramLocationId, caseDto]);

  const handleOnSubmit = handleSubmit(async (dto: CaseLocationFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  //#region Effects
  useEffect(() => {
    setValue("programLocationId", getProgramLocationId(programId, locationId));
  }, [programId, locationId, setValue, getProgramLocationId]);

  // useEffect(() => {
  //   clearForm();
  // }, [clearForm]);
  //#endregion

  return {
    state: {
      locations,
      getProviderLocationsRequestState,
    },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
