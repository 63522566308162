import React, { Fragment } from "react";
import Header from "../../../app/components/Header";
import WaitlistList from "../components/WaitlistList";
import { useWaitlistPage } from "../hooks/useWaitlistPage";
import WaitlistFilter from "../components/WaitlistFilter";
import WaitlistFilterList from "../components/WaitlistFilterList";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import RemoveWaitlistFormDialog from "../components/RemoveWaitlistFormDialog";
import { PaginationResultDto } from "../../../app/dtos/PaginationResultDto";
import { WaitlistDto } from "../dtos/WaitlistDto";
import FilterButton from "../../../app/components/buttons/FilterButton";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
const WaitlistPage = () => {
  //#region State
  const { state, handlers } = useWaitlistPage();
  const {
    params,
    waitlistResult,
    getWaitlistRequestState,
    programTypes,
    programs,
    providers,
    filterAnchorEl,
    locations,
    removeWaitlistRequestState,
  } = state;
  const {
    handlePageChange,
    handleSort,
    handleOpenFilterClicked,
    handleCloseFilter,
    handleSearch,
    // Remove Waitlist
    handleRemoveWaitlist,
    handleRemoveWaitlistClicked,
  } = handlers;
  //#endregion

  return (
    <Fragment>
      <WaitlistFilter
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilter}
        params={params}
        onSearch={handleSearch}
        providers={providers}
        programs={programs}
        programTypes={programTypes}
        locations={locations}
      />

      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Waitlist}>
        <RemoveWaitlistFormDialog
          onSubmit={handleRemoveWaitlist}
          requestState={removeWaitlistRequestState}
        />
      </WriteAccessLevelWrapper>
      <RequestErrorAlert requests={[getWaitlistRequestState]} />
      <Header
        title="Screenings Waitlists"
        actions={
          <FilterButton
            title="Filter List"
            onClick={handleOpenFilterClicked}
            disabled={getWaitlistRequestState.isLoading}
          />
        }
        secondaryActions={
          <WaitlistFilterList
            params={params}
            onSearch={handleSearch}
            isLoading={getWaitlistRequestState.isLoading}
            providers={providers}
            programs={programs}
            programTypes={programTypes}
            locations={locations}
          />
        }
      />
      <WaitlistList
        results={waitlistResult || new PaginationResultDto<WaitlistDto>()}
        isLoading={getWaitlistRequestState.isLoading}
        params={params}
        onPageChange={handlePageChange}
        onRemoveWaitlistClick={handleRemoveWaitlistClicked}
        onSort={handleSort}
      />
    </Fragment>
  );
};

export default WaitlistPage;
