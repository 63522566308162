import { Box, Typography } from "@material-ui/core";
import React from "react";
import buildNumber from '../../../build-number.json'

const LandingPage = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      height="100%"
      position="relative"
    >
      <img src="/NetMIS Logo.png" alt="" style={{ width: "40vw" }} />

      <Box display="flex">
        <Typography variant="h5" color="textSecondary">
          Start your work by choosing from the{" "}
          <img src="/menu.png" alt="menu" /> menu at the top right.
        </Typography>
      </Box>
      <Box position="absolute" bottom="12px">
        <Typography className="mt-3" color="textSecondary">
          Build #: {buildNumber || "Unknown"}
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingPage;
