import {
  Avatar,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { ScreeningDto } from "../../../screenings/dtos/ScreeningDto";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DateTimeLabel from "../../../../app/components/labels/DateTimeLabel";
import { referralSourceTextMap } from "../../../referrals/enums/ReferralSourceEnum";
import { useHistory } from "react-router";
import YesNoLabel from "../../../../app/components/labels/YesNoLabel";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import EditButton from "../../../../app/components/buttons/EditButton";
import { calculateDateDifferenceInDays } from "../../../../app/helpers";
import RouteableStyledLink from "../../../../app/components/RouteableStyledLink";
import KeyValueDetails, {
  RowProps,
} from "../../../../app/components/KeyValueDetails";
import RedAlertLabel from "../../../../app/components/labels/RedAlertLabel";
import { getProviderId } from "../../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../../authentication/constants";
interface Props {
  screening: ScreeningDto;
  onCreateScreeningWaitlistClick: () => void;
}

const YouthScreeningListItem: React.FC<Props> = ({
  screening,
  onCreateScreeningWaitlistClick,
}) => {
  const history = useHistory();

  const providerId = getProviderId();
  const isFnyfs = providerId === fnyfsProviderId;
  
  const rows: RowProps[] = [
    {
      key: "date",
      label: "Screening Date/Time",
      value: <DateTimeLabel date={screening.screeningAt} />,
    },
    {
      key: "provider",
      label: "Provider",
      value: screening.provider.name
    },    
    {
      key: "zip",
      label: "Youth Residence Zip Code",
      value: screening.zipCode || "None",
    },
    {
      key: "problems",
      label: "Problems",
      value: screening.screeningPresentingProblems
        ?.map((x) => x.problem)
        .join(", "),
    },
    {
      key: "cins",
      label: "Is CINS/FINS Eligible",
      value: <YesNoLabel value={screening.isCinsFinsEligible} />,
    },
    {
      key: "dv",
      label: "Request for DV Respite Services",
      value: <YesNoLabel value={screening.isDvRespite} />,
    },
  ];

  if (screening.referredBy)
    rows.splice(2, 0, {
      key: "referralBy",
      label: "Referral By",
      value: `${screening.referredBy?.firstName} 
  ${screening.referredBy?.lastName} (
  ${referralSourceTextMap[screening.referredBy?.source]})`,
    });

  //#region UI Helpers
  const renderActions = () => {
    const canWaitlist =
      calculateDateDifferenceInDays(new Date(), screening.screeningAt) <= 60;
    if(providerId !== screening.provider.id)
      return;
      
    return (
      <div>
        <EditButton
          onClick={() =>
            history.push(
              `/youths/${screening.youthId}/cases/new?screeningId=${screening.id}`
            )
          }
          text="Start Case"
        />
        {canWaitlist && (
          <EditButton
            onClick={onCreateScreeningWaitlistClick}
            text="Waitlist"
          />
        )}
      </div>
    );
  };
  //#endregion

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <Avatar>
          <CalendarTodayIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={
          <Fragment>
            <Box display="flex" alignItems="center">
              <Typography variant="h6" style={{ marginRight: "12px" }}>
                {screening.provider.id === providerId}
                {(isFnyfs || providerId === screening.provider.id) && 
                  <RouteableStyledLink
                    pathname={`/screenings/${screening.id}`}
                    text="Back to Youth"
                  >
                    {screening.referenceNumber}
                  </RouteableStyledLink>
                }
                {(!isFnyfs && providerId !== screening.provider.id) && <>{screening.referenceNumber}</>}
              </Typography>
              <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
                {renderActions()}
              </WriteAccessLevelWrapper>
              <RedAlertLabel hide={!screening.deletedAt} variant="outlined" label="Deleted" />
            </Box>
          </Fragment>
        }
        secondary={
          <KeyValueDetails lgLabelSize={3} mdLabelSize={4} rows={rows} />
        }
      />
    </ListItem>
  );
};

export default YouthScreeningListItem;
