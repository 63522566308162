import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React, { Fragment } from "react";
import LocationLabel from "../../../locations/components/LocationLabel";
import MapIcon from "@material-ui/icons/Map";
import EditButton from "../../../../app/components/buttons/EditButton";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import { createNumberRange } from "../../../../app/helpers";
import { Skeleton } from "@material-ui/lab";
import AddButton from "../../../../app/components/buttons/AddButton";
import ListDivider from "../../../../app/components/ListDivider";
import { TabProps } from "../../../../app/components/TabContainer";
import LocationFormDialog from "../../../locations/components/LocationFormDialog";
import { useProviderDetailPageContext } from "../../pages/ProviderDetailPage";
export const providerLocationSectionName = "provider-location-section";

const ProviderLocationSection: React.FC = () => {
  const context = useProviderDetailPageContext();
  const {
    provider,
    getProviderRequestState: { isLoading },
  } = context.provider;
  const {
    selectedLocation,
    upsertLocationRequestState,
    handleCreateLocationClicked,
    handleEditLocationClicked,
    handleUpsertLocation,
  } = context.locations;

  const createLoadingListItem = (i: number) => {
    return (
      <Fragment key={i}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <MapIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Skeleton animation="wave" width="200px" />}
            secondary={<Skeleton animation="wave" width="300px" />}
          />
          <ListItemSecondaryAction>
            <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Location}>
              <EditButton disabled={true} />
            </WriteAccessLevelWrapper>
          </ListItemSecondaryAction>
        </ListItem>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <LocationFormDialog
        onSubmit={handleUpsertLocation}
        requestState={upsertLocationRequestState}
        location={selectedLocation}
      />
      <Box marginBottom={"4px"}>
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Location}>
          <AddButton
            onClick={handleCreateLocationClicked}
            text="Add Location"
            variant="contained"
            color="primary"
          />
        </WriteAccessLevelWrapper>
      </Box>
      <List>
        {isLoading && createNumberRange(5).map(createLoadingListItem)}
        {!isLoading &&
          provider?.locations
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((location, index) => (
              <Fragment key={location.id}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <MapIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={location.name}
                    secondary={<LocationLabel location={location} />}
                  />
                  <ListItemSecondaryAction>
                    <WriteAccessLevelWrapper
                      name={PermissionResourceNameEnum.Location}
                    >
                      <EditButton
                        onClick={() => handleEditLocationClicked(location)}
                      />
                    </WriteAccessLevelWrapper>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListDivider
                  index={index}
                  length={provider!.locations.length}
                />
              </Fragment>
            ))}
      </List>
    </Fragment>
  );
};

export default ProviderLocationSection;
export const createProviderLocationSection = (): TabProps => ({
  label: "Locations",
  value: providerLocationSectionName,
  content: <ProviderLocationSection />,
});
