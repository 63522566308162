import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHttpError } from "../../../app/helpers";
import {
  confirmEmailRequest,
  resetPasswordRequest,
  sendConfirmationEmailRequest,
  sendResetPasswordRequest,
} from "../AccountRequests";
import { ConfirmEmailDto } from "../dtos/ConfirmEmailDto";
import { ResetPasswordFormDto } from "../dtos/ResetPasswordFormDto";
import { SendConfirmationEmailRequestDto } from "../dtos/SendConfirmationEmailRequestDto";
import { SendResetPasswordFormDto } from "../dtos/SendResetPasswordFormDto";

export const confirmEmail = createAsyncThunk(
  "account/confirm-email",
  async (dto: ConfirmEmailDto, { rejectWithValue }) => {
    try {
      const result = await confirmEmailRequest(dto);
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "account/reset-password",
  async (dto: ResetPasswordFormDto, { rejectWithValue }) => {
    try {
      const result = await resetPasswordRequest(dto);
      return result;
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);

export const sendConfirmationEmail = createAsyncThunk(
  "account/send-confirmation-email",
  async (dto: SendConfirmationEmailRequestDto, { rejectWithValue }) => {
    try {
      return await sendConfirmationEmailRequest(dto);
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);

export const sendResetPassword = createAsyncThunk(
  "account/send-reset-password",
  async (dto: SendResetPasswordFormDto, { rejectWithValue }) => {
    try {
      return await sendResetPasswordRequest(dto);
    } catch (error) {
      const err = await getHttpError(error);
      return rejectWithValue(err);
    }
  }
);
