import {useRequest} from "../../app/hooks/useRequest";
import {updateServicePlanReviewRequest} from "../ServicePlanRequests";
import {useServicePlanPageContext} from "../ServicePlanPageContext";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {UpdateServicePlanReviewDto} from "../dto/AddServicePlanReviewDto";
import { DateTime } from "luxon"
import {dateToFormString} from "../../app/helpers";
import {useCallback, useEffect, useState} from "react";
import {ServicePlanReviewDto} from "../dto/ServicePlanDto";
import {ServicePlanParticipantRolesEnum} from "../enums/ServicePlanParticipantRolesEnum";

interface UpdateServicePlanReviewFormData {
  [index: string]: any;
  reviewedOn: string;
  notes: string;
  youthParticipated: boolean;
  counselorParticipated: boolean;
}

interface Participant {
  name: string;
  role: ServicePlanParticipantRolesEnum;
  selected: boolean;
}

const schema = Yup.object().shape({
  reviewedOn: Yup.string().required().label("Plan Reviewed On"),
  notes: Yup.string().optional(),
});

export const useUpdateServicePlanReviewForm = (reviewId: string) => {

  const { servicePlan, servicePlanUpdated  } = useServicePlanPageContext();

  const [review, setReview] = useState<ServicePlanReviewDto>();

  const [participants, setParticipants] = useState<Participant[]>([]);

  const [counselorParticipated, setCounselorParticipated] = useState<boolean>(false)
  const [youthParticipated, setYouthParticipated] = useState<boolean>(false)

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<UpdateServicePlanReviewFormData>({
    defaultValues: {
      reviewedOn: review?.reviewedOn ? dateToFormString(new Date(review.reviewedOn)) : "",
      notes: review?.notes,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });


  const clearForm = useCallback(() => {
    reset({
      reviewedOn: review?.reviewedOn ? dateToFormString(new Date(review.reviewedOn)) : "",
      notes: review?.notes,
    });
  }, [reset, review?.notes, review?.reviewedOn]);

  const onToggleParticipant = (name: string, role: ServicePlanParticipantRolesEnum) => {
    const index = participants.findIndex(p => p.name === name && p.role === role);

    const newParticipants = [
      ...participants.slice(0, index),
      { name, role, selected: !participants[index].selected },
      ...participants.slice(index + 1)
    ];

    setParticipants(newParticipants);
  }

  const onToggleYouthParticipated = () => {
    setYouthParticipated(!youthParticipated);
  }

  const onToggleCounselorParticipated = () => {
    setCounselorParticipated(!counselorParticipated);
  }


  const [updateReview, , updateReviewRequestState] = useRequest<UpdateServicePlanReviewDto, void>(
    (dto) => updateServicePlanReviewRequest(servicePlan.id, reviewId, dto)
  );

  const handleOnSubmit = handleSubmit(
    async (formData: UpdateServicePlanReviewFormData) => {

      const dto: UpdateServicePlanReviewDto = {
        reviewedOn: DateTime.fromFormat(formData.reviewedOn, "yyyy-MM-dd").toJSDate(),
        notes: formData.notes,
        participants: participants.filter(p => p.selected),
        youthParticipated,
        counselorParticipated
      }

      await updateReview(dto);
      clearForm();
      await servicePlanUpdated();
    }
  );

  useEffect(() => {
    const r = servicePlan.reviews.find(r => r.id === reviewId);
    setReview(r);
    setYouthParticipated(!!r?.youthParticipated)
    setCounselorParticipated(!!r?.counselorParticipated)
    clearForm();
  }, [clearForm, reviewId, servicePlan.reviews])

  useEffect(() => {
    const mergedParticipants = [
      ...(review?.otherParticipants || []).map(({name, role}) => ({ name, role, selected: true })),
      ...servicePlan.participants.filter(p => !review?.otherParticipants.some(op => op.name === p.name && op.role === p.role)).map(({name, role}) => ({ name, role, selected: false })),
    ]
    setParticipants(mergedParticipants);
  }, [review?.otherParticipants, servicePlan.participants])

  return {
    state: {
      review,
      participants,
      youthParticipated,
      counselorParticipated,
      updateReviewRequestState
    },
    form: { isValid, control, clearForm },
    handlers: {
      handleSubmit: handleOnSubmit,
      onToggleParticipant,
      onToggleCounselorParticipated,
      onToggleYouthParticipated
    },
  };

}
