import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { selectModalId } from "../../../features/modal/state/modalSelectors";
import { closeModal } from "../../../features/modal/state/modalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import SpinnerButton from "../buttons/SpinnerButton";

interface Props {
  modalId: string;
  title?: React.ReactNode;
  isLoading?: boolean;
  confirmButtonText?: string;
  confirmButtonStyle?: React.CSSProperties;
  confirmButtonDisabled?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  onConfirm(): void;
  onClose?(): void;
  hideConfirmButton?: boolean;
}

const ConfirmDialog: React.FC<Props> = ({
  modalId,
  children,
  title,
  isLoading = false,
  confirmButtonText = "Confirm",
  confirmButtonStyle = {},
  confirmButtonDisabled = false,
  maxWidth,
  onConfirm,
  onClose,
  hideConfirmButton,
}) => {
  //#region State
  const dispatch = useAppDispatch();
  const selectedModalId = useAppSelector(selectModalId);
  //#endregion

  //#region Handlers
  const handleClose = () => (onClose ? onClose() : dispatch(closeModal()));
  //#endregion

  return (
    <Dialog open={selectedModalId === modalId} maxWidth={maxWidth}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        {!hideConfirmButton && <SpinnerButton
          showSpinner={isLoading}
          onClick={onConfirm}
          color="primary"
          content={confirmButtonText}
          style={{ ...confirmButtonStyle }}
          disabled={confirmButtonDisabled}
        />}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
