import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";
import { useSnapInSchoolsSatisfactionSurveyForm } from "../hooks/useSnapInSchoolsSatisfactionSurveyForm";
import {
  SnapInSchoolsSatisfactionSurveyDto,
  SnapInSchoolsSatisfactionSurveyFormDto,
} from "../SnapInSchoolsSatisfactionSurveyDto";
import SnapInSchoolsSatisfactionSurveyFormFields from "./SnapInSchoolsSatisfactionSurveyFormFields";

export const snapInSchoolsSatisfactionSurveyModalId =
  "snap-in-schools-satisfaction-survey-form-modal";

interface Props {
  requestState: RequestStateDto;
  survey?: SnapInSchoolsSatisfactionSurveyDto;
  snapCycle?: SnapInSchoolsCycleDto;
  onSubmit(dto: SnapInSchoolsSatisfactionSurveyFormDto): Promise<any>;
}

const SatisfactionSurveyFormDialog: React.FC<Props> = ({
  survey,
  requestState,
  snapCycle,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useSnapInSchoolsSatisfactionSurveyForm(
    onSubmit,
    survey,
    snapCycle
  );
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === snapInSchoolsSatisfactionSurveyModalId}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {survey ? "Edit" : "Enter"} SNAP in Schools and Communities
          Satisfaction Survey
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <SnapInSchoolsSatisfactionSurveyFormFields
              control={control}
              disabled={isLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SatisfactionSurveyFormDialog;
