import React, { useEffect, useState } from "react";
import ReadAccessLevelRoute from "../../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { DashboardDataDetails } from "./DashboardDataDetails";
import { MainDashboard } from "./MainDashboard";
import {
  Redirect,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectSelectedProviderId } from "../../authentication/state/authenticationSelectors";
import {
  createDateRangeRequestDto,
  DashboardFiltersDto,
} from "../dtos/MainDashboardMetricsDto";
import { DashboardFilter } from "../components/DashboardChangeFiltersModal";
import { formatUrl, QueryStringHelpers } from "../../../app/helpers";
import {
  DashboardContextProps,
  DashboardPageContext,
} from "../DashboardContext";
import { useRequest } from "../../../app/hooks/useRequest";
import { getAvailableDashboardsRequest } from "../DashboardRequests";
import PrivateRoute from "../../../app/routes/PrivateRoute";
import { useQuery } from "../../../app/hooks/useQuery";
import { getProviderByIdRequest } from "../../provider/ProviderRequests";
import { useAnchor } from "../../../app/hooks/useAnchor";

export const DashboardContainer: React.FC = () => {
  let { path } = useRouteMatch();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const selectedProviderId = useAppSelector(selectSelectedProviderId);
  const [filterAnchor, handleOpenFilterClicked, handleCloseFilterClicked] =
    useAnchor();
  const [filters, setFilters] = useState<DashboardFiltersDto>({
    dateRange: createDateRangeRequestDto(
      query.type as string,
      query.startDate as string,
      query.endDate as string
    ),
  });

  const [getDashboards, availableDashboards, requestState] = useRequest(
    getAvailableDashboardsRequest
  );

  const [getProvider, providerDetails] = useRequest(getProviderByIdRequest);

  useEffect(() => {
    if (selectedProviderId) {
      getDashboards({
        providerId: selectedProviderId,
        dateRange: filters.dateRange,
      });
      getProvider(selectedProviderId);
    }
  }, [selectedProviderId, filters.dateRange, getDashboards, getProvider]);

  const contextData: DashboardContextProps = {
    filters,
    availableDashboards: availableDashboards || [],
    availableDashboardsRequestState: requestState,
    providerId: selectedProviderId || "",
    provider: providerDetails,
    onChangeFilterClicked: handleOpenFilterClicked,
  };

  return (
    <>
      <DashboardFilter
        anchorEl={filterAnchor}
        onClose={handleCloseFilterClicked}
        filters={filters}
        onFiltersChanged={(filters) => {
          setFilters(filters);
          history.push(
            `${location.pathname}?${QueryStringHelpers.toQueryString(
              filters.dateRange
            )}`
          );
        }}
      />
      <DashboardPageContext.Provider value={contextData}>
        <Switch>
          <ReadAccessLevelRoute
            name={PermissionResourceNameEnum.Case}
            path={formatUrl(path, ":dashboardId")}
            component={MainDashboard}
            key="dashboard-main"
            exact
          />
          <ReadAccessLevelRoute
            name={PermissionResourceNameEnum.Case}
            path={formatUrl(path, "data/:metric")}
            component={DashboardDataDetails}
            key="dashboard-data"
          />
          <PrivateRoute
            path=""
            render={() => <Redirect to={"/dashboards/main/"} />}
            exact
          />
        </Switch>
      </DashboardPageContext.Provider>
    </>
  );
};
