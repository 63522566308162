import {
  Box,
  IconButton,
  Typography,
  TypographyTypeMap,
} from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { Fragment, useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  ssn?: string;
  canToggle: boolean;
}

const MaskedSSNLabel: React.FC<Props> = ({ ssn, ...props }) => {
  const [showFull, setShowFull] = useState(false);

  const handleToggle = () => setShowFull((x) => !x);

  const formatSSN = () => {
    if (showFull) return ssn;

    return `XXX-XX-${ssn?.substring(ssn.length - 4)}`;
  };

  if (!ssn) return <Fragment></Fragment>;

  return (
    <Box component={"span"} display={"flex"} alignItems={"center"}>
      <Typography {...props}>{formatSSN()}</Typography>{" "}
      <IconButton
        size="small"
        style={{ marginLeft: "8px" }}
        onClick={handleToggle}
      >
        {showFull ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </Box>
  );
};

export default MaskedSSNLabel;
