import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { UpdateUserEmailFormDto } from "../dtos/UpdateUserEmailFormDto";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import InfoTooltip from "../../../app/components/InfoTooltip";
const updateEmailSchema = Yup.object().shape({
  email: Yup.string().label("New Email Address").required().email(),
  confirmEmail: Yup.string()
    .label("Email Confirmation")
    .required()
    .oneOf([Yup.ref("email")], "Emails do not match."),
});

interface Props {
  onSubmit: (dto: UpdateUserEmailFormDto) => any;
  requestState: RequestStateDto;
}

export const updateUserEmailModalId = "user-user-email-modal";

const UpdateUserEmailDialog: React.FC<Props> = ({ onSubmit, requestState }) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const disptach = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm<UpdateUserEmailFormDto>({
    defaultValues: { email: "", confirmEmail: "" },
    mode: "all",
    resolver: yupResolver(updateEmailSchema),
  });
  const { error, isLoading } = requestState;
  //#endregion

  //#region Handlers
  const handleCloseModal = () => {
    reset({ email: "" });
    disptach(closeModal());
  };

  const handleOnSubmit = (dto: UpdateUserEmailFormDto) => {
    onSubmit(dto);
  };
  //#endregion
  return (
    <Dialog open={modalId === updateUserEmailModalId} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Change User Email
            <InfoTooltip
              title={
                <Typography>
                  Changing a user's email will change their login information,
                  and require the email to be confirmed.
                </Typography>
              }
            />
          </Box>
          {error && (
            <Box marginTop="8px" color="red">
              <Typography color="error">{error.message}</Typography>
            </Box>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="email"
                label="New Email Address"
                disabled={isLoading}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="confirmEmail"
                label="Confirm Email Address"
                disabled={isLoading}
                onPaste={(e: any) => e.preventDefault()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleCloseModal} disabled={isLoading}>
            Cancel
          </Button>
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateUserEmailDialog;
