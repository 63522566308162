import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  HumanTraffickingScreeningToolDto,
  HumanTraffickingScreeningToolFormDto,
} from "../HumanTrafickingScreeningToolDto";

const schema = Yup.object().shape({
  administeredOn: Yup.string().label("Administered Date").required(),
  result: Yup.string().label("Result").required(),
});

export const useHumanTraffickingScreeningToolForm = (
  onSubmit: (dto: HumanTraffickingScreeningToolFormDto) => Promise<void>,
  htst?: HumanTraffickingScreeningToolDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new HumanTraffickingScreeningToolFormDto(htst),
    resolver: yupResolver(schema),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(new HumanTraffickingScreeningToolFormDto(htst));
  };
  const handleOnSubmit = handleSubmit(
    async (dto: HumanTraffickingScreeningToolFormDto) => {
      await onSubmit(dto);
      if (htst == null) reset(new HumanTraffickingScreeningToolFormDto());
    }
  );

  useEffect(() => {
    reset(new HumanTraffickingScreeningToolFormDto(htst));
  }, [htst, reset]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
