import React from "react";
import { ColorsEnum } from "../../enums/ColorsEnum";
import AlertLabel, { AlertLabelProps } from "./AlertLabel";

interface Props extends AlertLabelProps {}

const YellowAlertLabel: React.FC<Props> = (props) => (
  <AlertLabel {...props} color={ColorsEnum.Yellow} />
);

export default YellowAlertLabel;
