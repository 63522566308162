import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTogglable } from "../../../app/hooks/useTogglable";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { useProgramLocationNestedFields } from "../../youths/hooks/useProgramLocationNestedFields";
import { CaseProgramFormDto } from "../dtos/CaseProgramFormDto";

export const useCaseProgramForm = (
  onSubmit: (dto: CaseProgramFormDto) => Promise<any>,
  caseDto?: CaseDetailDto
) => {
  //#region State

  const {
    control,
    watch,
    formState: { isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    defaultValues: new CaseProgramFormDto(undefined),
    resolver: yupResolver(CaseProgramFormDto.getSchema()),
    mode: "all",
  });
  const { programId, locationId } = watch();

  const {
    state: {
      locations,
      providerPrograms,
      getProviderLocationsRequestState,
      getProviderProgramsRequestState,
    },
    // handlers: { handleUpdateLocations },
    getters: { getProgramLocationId },
  } = useProgramLocationNestedFields(false, caseDto?.intakeInfo.intakeAt, programId);

  const [isConfirmed, setIsConfirmed, toggleIsConfirmed] = useTogglable(false);

  const clearForm = useCallback(() => {
    setIsConfirmed(false);
    const dto = new CaseProgramFormDto(caseDto);
    dto.programLocationId = getProgramLocationId(dto.programId, dto.locationId);
    reset(dto);
  }, [reset, getProgramLocationId, caseDto, setIsConfirmed]);
  const handleOnSubmit = handleSubmit(async (dto: CaseProgramFormDto) => {
    await onSubmit(dto);
    clearForm();
  });

  //#region Effects

  useEffect(() => {
    setValue("programLocationId", getProgramLocationId(programId, locationId));
  }, [programId, locationId, setValue, getProgramLocationId]);

  //#endregion

  return {
    state: {
      locations,
      providerPrograms: providerPrograms || [],
      getProviderLocationsRequestState,
      getProviderProgramsRequestState,
      isConfirmed,
    },
    form: { control, isValid: isValid && isConfirmed, clearForm },
    handlers: { handleSubmit: handleOnSubmit, toggleIsConfirmed },
  };
};
