import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import DateLabel from "../../app/components/labels/DateLabel";
import { ColorsEnum } from "../../app/enums/ColorsEnum";
import AssessmentReferenceNumberLabel from "../../features/assessments/components/AssessmentReferenceNumberLabel";
import { AssessmentDto } from "../../features/assessments/dtos/AssessmentDto";
import { AssessmentStatusEnum } from "../../features/assessments/enums/AssessmentStatusEnum";
import { assessmentTypeTextMap } from "../../features/assessments/enums/AssessmentTypeEnum";
import { useCaseDetailPageContext } from "../../features/cases/pages/CaseDetailPage";
import InfoTooltip from "../../app/components/InfoTooltip";
import AssessmentLink from "../../features/assessments/components/AssessmentLink";
import { useServicePlanPageContext } from "../ServicePlanPageContext";
import AddButton from "../../app/components/buttons/AddButton";

interface AssessmentScoresProps {
  [assessmentId: string]: { [category: string]: number };
}

export const ScoresSection = ({
  label,
  scores,
  categoryNames,
  assessments,
}: {
  label: React.ReactNode;
  scores: AssessmentScoresProps;
  categoryNames: string[];
  assessments: AssessmentDto[];
}) => {
  return (
    <Fragment>
      <TableRow>
        <TableCell></TableCell>
        <TableCell
          colSpan={categoryNames.length}
          align="center"
          style={{
            backgroundColor: ColorsEnum.RowGrey,
            fontWeight: "bold",
            color: "black",
          }}
        >
          {label}
        </TableCell>
      </TableRow>
      {categoryNames.map((x) => (
        <TableRow key={x} style={{ width: "100%" }}>
          <TableCell>
            <div>
              <span>{x}</span>
            </div>
          </TableCell>
          {assessments
            .filter((x) => x.status === AssessmentStatusEnum.Submitted)
            .map((assessment) => {
              const assessmentScores = scores[assessment.id];
              return (
                <Fragment key={`${assessment.id}-${x}`}>
                  <TableCell style={{ textAlign: "center" }}>
                    {(assessmentScores != null && assessmentScores[x]) || "-"}
                  </TableCell>
                </Fragment>
              );
            })}
        </TableRow>
      ))}
    </Fragment>
  );
};

const NirvanaScoreDetails = () => {
  const context = useCaseDetailPageContext();
  const { assessments, domainNames } = context.case.nirvana;
  const [dynamicScores, setDynamicScores] = useState<AssessmentScoresProps>({});
  const [protectiveScores, setProtectiveScores] =
    useState<AssessmentScoresProps>({});
  const [orderedAssessments, setOrderedAssessments] = useState<AssessmentDto[]>(
    []
  );

  const header = (
    <Fragment>
      <Divider />
      <Typography color="primary">NIRVANA Scores</Typography>
    </Fragment>
  );

  useEffect(() => {
    const dynamicScores: AssessmentScoresProps = {};
    const protectiveFactorsScores: AssessmentScoresProps = {};

    orderedAssessments.forEach((assessment) => {
      assessment.scores.forEach((score) => {
        score.categoryScores.forEach((categoryScore) => {
          if (score.type === "Dynamic") {
            if (dynamicScores[assessment.id]) {
              dynamicScores[assessment.id][categoryScore.categoryName] =
                categoryScore.score;
            } else {
              dynamicScores[assessment.id] = {
                [categoryScore.categoryName]: categoryScore.score,
              };
            }
          } else if (score.type === "ProtectiveFactors") {
            if (protectiveFactorsScores[assessment.id]) {
              protectiveFactorsScores[assessment.id][
                categoryScore.categoryName
              ] = categoryScore.score;
            } else {
              protectiveFactorsScores[assessment.id] = {
                [categoryScore.categoryName]: categoryScore.score,
              };
            }
          }
        });
      });
    });

    setProtectiveScores(protectiveFactorsScores);
    setDynamicScores(dynamicScores);
  }, [orderedAssessments, setDynamicScores, setProtectiveScores]);

  useEffect(() => {
    setOrderedAssessments(
      assessments
        .filter((x) => x.status === AssessmentStatusEnum.Submitted)
        .sort((a, b) =>
          new Date(a.completedOn) > new Date(b.completedOn) ? -1 : 1
        )
    );
  }, [assessments, setOrderedAssessments]);

  if (orderedAssessments.length === 0)
    return (
      <Fragment>
        {header}
        <Typography color="textSecondary">
          No NIRVANA Assessments have been submitted for this case.
        </Typography>
      </Fragment>
    );

  return (
    <Fragment>
      {header}

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {assessments
              .filter((x) => x.status === AssessmentStatusEnum.Submitted)
              .map((assessment) => {
                return (
                  <Fragment key={assessment.id}>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <AssessmentLink assessment={assessment}>
                          <DateLabel date={assessment.completedOn} />
                        </AssessmentLink>
                        <InfoTooltip
                          style={{ marginLeft: 8 }}
                          title={
                            <Box>
                              <Typography noWrap component="span">
                                Assessment{" "}
                                <strong>
                                  <AssessmentReferenceNumberLabel
                                    component="span"
                                    assessment={assessment}
                                  />
                                </strong>
                              </Typography>
                              <br />
                              <Typography noWrap component="span">
                                Type:{" "}
                                <strong>
                                  {assessmentTypeTextMap[assessment.type]}
                                </strong>
                              </Typography>
                            </Box>
                          }
                        />
                      </div>
                    </TableCell>
                  </Fragment>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          <ScoresSection
            label="Dynamic Scores"
            scores={dynamicScores}
            assessments={orderedAssessments}
            categoryNames={domainNames}
          />
          <ScoresSection
            label="Protective Scores"
            scores={protectiveScores}
            assessments={orderedAssessments}
            categoryNames={domainNames}
          />
        </TableBody>
      </Table>
    </Fragment>
  );
};

// const SuicideScreeningSection = () => {
//   const context = useCaseDetailPageContext();
//   const screenings = context.case.caseResult?.suicideScreenings || [];
//
//   const renderScreening = (screening: SuicideScreeningDto) => {
//     return (
//       <TableRow key={screening.id}>
//         <TableCell>
//           <DateLabel date={screening.createdAt} />
//         </TableCell>
//         <TableCell>
//           <DateLabel date={screening.screeningAt} />
//         </TableCell>
//         <TableCell>
//           <SuicideScreeningResultLabel result={screening.result} />{" "}
//         </TableCell>
//       </TableRow>
//     );
//   };
//
//   return (
//     <Fragment>
//       <Divider />
//       <Typography color="primary">Suicide Screenings</Typography>
//       {screenings.length === 0 && (
//         <Typography color="textSecondary">
//           No Suicide Screenings have been entered for this case.
//         </Typography>
//       )}
//       {screenings.length > 0 && (
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Data Entered At</TableCell>
//               <TableCell>Completed At</TableCell>
//               <TableCell>Result</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>{screenings.map(renderScreening)}</TableBody>
//         </Table>
//       )}
//     </Fragment>
//   );
// };

export const ServicePlanScoreDetails: React.FC = () => {
  const {
    goals: { onAddGoalClicked },
  } = useServicePlanPageContext();

  return (
    <Fragment>
      <Box display="flex" justifyContent="end" marginBottom="12px">
        <AddButton text="Add Goal" onClick={onAddGoalClicked} />
      </Box>
      <Grid container spacing={5}>
        <Grid item md={12} xs={12}>
          <NirvanaScoreDetails />
        </Grid>
        {/*<Grid item md={12} xs={12}>*/}
        {/*  <SuicideScreeningSection />*/}
        {/*</Grid>*/}
      </Grid>
    </Fragment>
  );
};
