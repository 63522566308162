import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import StyledLink from "../../../app/components/StyledLink";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { dischargeFormDialogId } from "../../discharges/components/DischargeFormDialog";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { CaseHoldDto } from "../dtos/CaseHoldDto";
import { EndCaseHoldFormDto } from "../dtos/EndCaseHoldFormDto";
import { useEndCaseHoldForm } from "../hooks/useEndCaseHoldForm";

export const endCaseHoldModalId = "end-case-hold-form-modal";

interface Props {
  onSubmit: (dto: EndCaseHoldFormDto) => Promise<void>;
  requestState: RequestStateDto;
  caseHold?: CaseHoldDto;
}

const EndCaseHoldFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  caseHold,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useEndCaseHoldForm(onSubmit, caseHold);
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };

  const handleOpenDischargeModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(openModal({ modalId: dischargeFormDialogId }));
  };
  //#endregion

  return (
    <Dialog open={modalId === endCaseHoldModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          End Case Hold
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="endAt"
                label="Case Hold End Date/Time*"
                type="datetime-local"
                control={control}
                disabled={isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography color="textSecondary">
                Per Florida Network Policy, the Youth must be{" "}
                <StyledLink to="" onClick={handleOpenDischargeModal}>
                  discharged
                </StyledLink>{" "}
                after a shelter absence of 48 hours.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EndCaseHoldFormDialog;
