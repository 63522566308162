import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import DjjEntryListPage from "./pages/DjjEntryListPage";

export const createDjjEntryRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.DjjEntries}
    path={formatUrl(baseUrl, "")}
    component={DjjEntryListPage}
    key="djj-entry-list"
    exact
  />,
];
