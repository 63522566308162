import { dateToFormString } from "../../app/helpers";
import { CaseDetailDto } from "../../features/cases/dtos/CaseDetailDto";
import {ServicePlanDto} from "./ServicePlanDto";

export interface ServicePlanFormDto {
  startedOn: string;
  counselorStaffId: string;
  notes: string;
}

export const createServicePlanFormDto = (
  caseDetailDto: CaseDetailDto,
  servicePlan?: ServicePlanDto,
): ServicePlanFormDto => {
  if (servicePlan) {
    return {
      startedOn: dateToFormString(new Date(servicePlan.startedOn)),
      counselorStaffId: servicePlan.counselor.id,
      notes: servicePlan.notes
    };
  }

  return {
    startedOn: dateToFormString(new Date(caseDetailDto.intakeInfo.intakeAt)),
    counselorStaffId: "",
    notes: ""
  };
}
