import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";
import { AssessmentDto } from "../dtos/AssessmentDto";
import { AssessmentFormDto } from "../dtos/AssessmentFormDto";
import { assessmentTemplatesToOptions } from "../dtos/AssessmentTemplateSummaryDto";
import { AssessmentTemplateTagEnum } from "../enums/AssessmentTemplateTagEnum";
import { useAssessmentForm } from "../hooks/useAssessmentForm";
import { disabledAssessmentTypes } from "../enums/AssessmentTypeEnum";


export const assessmentModalId = "assessment-form-modal";

interface Props {
  caseDto?: CaseDetailDto;
  assessments: AssessmentDto[];
  requestState: RequestStateDto;
  tag: AssessmentTemplateTagEnum;
  snapCycle?: SnapInSchoolsCycleDto;
  verifyInProgress: boolean;
  verifyFailure: boolean;
  openUnverifiedAssessment: () => void;
  onSubmit(dto: AssessmentFormDto): Promise<any>;
}

const AssessmentFormDialog: React.FC<Props> = ({
  caseDto,
  snapCycle,
  assessments,
  tag,
  requestState,
  verifyFailure,
  verifyInProgress,
  openUnverifiedAssessment,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers, state } = useAssessmentForm(
    tag,
    onSubmit,
    assessments,
    caseDto,
    snapCycle
  );
  const { handleSubmit } = handlers;
  const { templates, getTemplatesRequestState, assessmentDisclaimer } = state;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  const disable = isLoading || verifyInProgress;
  const filteredTemplates = caseDto?.program.programType.programType 
    ? templates.filter(t => !disabledAssessmentTypes[caseDto.program.programType.programType]?.includes(t.id))
    : templates;

  return (
    <Dialog open={modalId === assessmentModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Start Assessment
          <ErrorText error={error?.message} />
          <ErrorText error={getTemplatesRequestState.error?.message} />
          {verifyFailure && <ErrorText error={"Your selected Assessment has been started, but there has been a delay in displaying the Assessment. Click \"Close\" below and refresh your screen, or click \"Open\" to start completing the Assessment."} />}
        </DialogTitle>
        <DialogContent>
          {!verifyFailure &&
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="completedOn"
                label="Completed On*"
                type="date"
                disabled={disable}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="templateId"
                options={assessmentTemplatesToOptions(filteredTemplates)}
                disabled={disable}
                isLoading={getTemplatesRequestState.isLoading}
                fullWidth
              />
            </Grid>
            { assessmentDisclaimer && <Grid item md={12} xs={12}>
                <Typography>
                  { assessmentDisclaimer }
                </Typography>
            </Grid> }
          </Grid>
          }
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content={verifyFailure ? "Close" : "Cancel"}
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          {!verifyFailure && <SpinnerButton
            showSpinner={isLoading || verifyInProgress}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || disable}
          />}
          {verifyFailure && <SpinnerButton content={"Open"} showSpinner={false} onClick={openUnverifiedAssessment} />}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AssessmentFormDialog;
