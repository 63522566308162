import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { CaseDetailDto } from "../dtos/CaseDetailDto";

interface Props {
  onConfirm(): Promise<void>;
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
}

export const confirmDeleteCourtOrderAttachmentDialog =
  "delete-court-order-attachment-dialog";

const ConfirmDeleteSnapInSchoolsSessionDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  caseDto,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteCourtOrderAttachmentDialog}
      isLoading={isLoading}
      confirmButtonText="Delete Court Order Document"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to delete this Court Order document?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteSnapInSchoolsSessionDialog;
