import React from "react";
import { dischargeStatusTextMap } from "../../../discharges/enums/DischargeStatusEnum";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { PaperMetricContainer } from "../PaperMetricContainer";
import { DataEntryMetricPanel } from "../DataEntryMetricPanel";
import { TableValueMetricPanel } from "../TableValueMetricPanel";
import { NumericValueMetricPanel } from "../NumericValueMetricPanel";
import { Skeleton } from "@material-ui/lab";
import TableLoadingPlaceholder from "../../../../app/components/TableLoadingPlaceholder";

export const metricIds = [
  "snapIntakesDataEntry",
  "snapDischargesDataEntry",
  "snapDischargeReasons",
  "snapU12Details",
  "snapYouthDetails",
  "snapInSchoolsGroupSessions",
  "snapInSchoolsGroupSessionsAttendanceLogs",
] as const;

export type SnapMetricsDto = {
  [metricId in typeof metricIds[number]]: any;
};

interface Props {
  metrics?: SnapMetricsDto;
  isLoading: boolean;
}

function decodeDischargeReasonData(data?: any) {
  if (!data) return;

  return {
    ...data,
    values: data.values.map((v: any) => ({
      ...v,
      title: dischargeStatusTextMap[v.title] || v.title,
    })),
  };
}

export const SnapDashboardMetrics: React.FC<Props> = ({
  metrics,
  isLoading,
}) => {
  const renderTable = () => {
    if (isLoading || !metrics?.snapU12Details)
      return (
        <>
          <Typography variant={"body1"}>
            <Skeleton animation="wave" width={200} />
          </Typography>
          <Table>
            <TableHead>
              <TableLoadingPlaceholder columns={4} rows={1} />
            </TableHead>
            <TableBody>
              <TableLoadingPlaceholder columns={4} rows={5} />
            </TableBody>
          </Table>
        </>
      );

    return (
      <>
        <Typography variant={"body1"}>
          SNAP U12 and SNAP for Youth Activity
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align={"center"}>
                {metrics.snapU12Details.title}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapYouthDetails.title}
              </TableCell>
              <TableCell align={"center"}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Intakes</TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.intakes}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapYouthDetails.values.intakes}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.intakes +
                  metrics.snapYouthDetails.values.intakes}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Discharges</TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.discharges}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapYouthDetails.values.discharges}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.discharges +
                  metrics.snapYouthDetails.values.discharges}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.groups}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapYouthDetails.values.groups}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.groups +
                  metrics.snapYouthDetails.values.groups}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Makeup</TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.makeups}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapYouthDetails.values.makeups}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.makeups +
                  metrics.snapYouthDetails.values.makeups}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fidelity</TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.fidelities}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapYouthDetails.values.fidelities}
              </TableCell>
              <TableCell align={"center"}>
                {metrics.snapU12Details.values.fidelities +
                  metrics.snapYouthDetails.values.fidelities}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };

  return (
    <>
      <Grid item sm={12} md={4}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%" }}
        >
          <Box style={{ paddingBottom: 16 }}>
            <PaperMetricContainer>
              <DataEntryMetricPanel
                intakesMetric={metrics?.snapIntakesDataEntry}
                dischargesMetric={metrics?.snapDischargesDataEntry}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box>
            <PaperMetricContainer>
              <TableValueMetricPanel
                metric={decodeDischargeReasonData(
                  metrics?.snapDischargeReasons
                )}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={5}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%", height: "100%" }}
        >
          <Box display={"flex"} flexDirection={"row"} style={{ width: "100%" }}>
            <PaperMetricContainer>
              <Box
                padding="16px"
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                alignItems={"left"}
                width={300}
              >
                {renderTable()}
              </Box>
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={3}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%" }}
        >
          <Box style={{ paddingBottom: 16 }} flex={1}>
            <PaperMetricContainer>
              <NumericValueMetricPanel
                metric={metrics?.snapInSchoolsGroupSessions}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box style={{ paddingBottom: 16 }} flex={1}>
            <PaperMetricContainer>
              <NumericValueMetricPanel
                metric={metrics?.snapInSchoolsGroupSessionsAttendanceLogs}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
