import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import DateLabel from "../../../app/components/labels/DateLabel";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { CinsPetitionDto } from "../dtos/CinsPetitionDto";

interface Props {
  onConfirm(): Promise<void>;
  requestState: RequestStateDto;
  petition?: CinsPetitionDto;
}

export const confirmDeleteCinsPetitionDialogId = "delete-cins-petition-dialog";
const ConfirmDeleteCinsPetitionDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  petition,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteCinsPetitionDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete CINS Petition"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to delete the CINS Petition opened on{" "}
        <DateLabel date={petition?.openedOn} />? <br />
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteCinsPetitionDialog;
