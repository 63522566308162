export interface AssessmentFormDto {
  completedOn: string;
  templateId: string;
  context: object;
  parentId?: string;
  referenceId: string;
  referenceType: string;
  singleOnly: boolean;
}

export const createAssessmentFormDto = (): AssessmentFormDto => {
  return {
    completedOn: "",
    templateId: "",
    context: {},
    parentId: "",
    referenceId: "",
    referenceType: "",
    singleOnly: false
  };
};
