import {SelectOption} from "../../../app/components/inputs/ControlledSelect";
import {Typography} from "@material-ui/core";
import React from "react";

export enum UnableToContactReasonsEnum {
  TelephoneDisconnected = "TelephoneDisconnected",
  FamilyMoved = "FamilyMoved",
  TelephoneNotAnswered = "TelephoneNotAnswered",
  Other = "Other",
  YouthRemoved = "YouthRemoved"
}

export const unableToContactReasonsTextMap: { [key: string]: string } = {
  [UnableToContactReasonsEnum.TelephoneDisconnected]: "Telephone Disconnected",
  [UnableToContactReasonsEnum.FamilyMoved]: "Family Moved",
  [UnableToContactReasonsEnum.TelephoneNotAnswered]: "Telephone Not Answered",
  [UnableToContactReasonsEnum.Other]: "Other",
  [UnableToContactReasonsEnum.YouthRemoved]: "Youth Was Removed from Protective Agency"
};

export const unableToContactReasonOptions: SelectOption[] = [
  { label: "Why was the contact unsuccessful?", value: "" },
  {
    label: <Typography>Telephone Not Answered</Typography>,
    value: UnableToContactReasonsEnum.TelephoneNotAnswered,
  },
  {
    label: <Typography>Telephone Disconnected</Typography>,
    value: UnableToContactReasonsEnum.TelephoneDisconnected,
  },
  {
    label: <Typography>Family Moved</Typography>,
    value: UnableToContactReasonsEnum.FamilyMoved,
  },
  {
    label: <Typography>Youth Was Removed from Protective Agency</Typography>,
    value: UnableToContactReasonsEnum.YouthRemoved,
  },
  {
    label: <Typography>Other</Typography>,
    value: UnableToContactReasonsEnum.Other,
  },
]
