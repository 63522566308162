import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import FnAccessWrapper from "../../../../app/components/access-wrappers/FnAccessWrapper";
import AddButton from "../../../../app/components/buttons/AddButton";
import DeleteButton from "../../../../app/components/buttons/DeleteButton";
import EditButton from "../../../../app/components/buttons/EditButton";
import { ContentSection } from "../../../../service-planning/components/ContentSection";
import { NetmisUserRoleDto } from "../../../roles/dtos/NetmisUserRoleDto";
import { SetUserRoleActiveDto } from "../../dtos/SetUserRoleActiveDto";
import { useUserDetailPageContext } from "../../pages/UserDetailPage";
import ConfirmRemoveRoleDialog from "./ConfirmRemoveRoleDialog";
import ProviderRoleFormDialog from "./ProviderRoleFormDialog";

const ProviderRoleSection: React.FC = () => {
  //#region State
  const [selectedRole, setSelectedRole] = useState<NetmisUserRoleDto>();
  const context = useUserDetailPageContext();
  const { user, getUserRequestState } = context;
  const {
    removeProviderRoleRequestState,
    addProviderRoleRequestState,
    handleRemoveRoleClicked,
    handleRemoveRole,
    handleAddRole,
    handleAddRoleClicked,
    handleActivateRole,
    handleDeactivateRoleClicked,
  } = context.roles;
  //#endregion

  //#region Handlers

  const handleDeleteClicked = (role: NetmisUserRoleDto) => {
    setSelectedRole(role);
    handleRemoveRoleClicked();
  };
  //#endregion

  const renderRow = (role: NetmisUserRoleDto) => (
    <TableRow key={role.provider.id}>
      <TableCell>{role.name}</TableCell>
      <FnAccessWrapper>
        <TableCell>{role.provider.name}</TableCell>
      </FnAccessWrapper>
      <TableCell align="right">
        {role.isActive ? (
          <DeleteButton
            text="Deactivate"
            onClick={() => handleDeactivateRoleClicked(role)}
          />
        ) : (
          <EditButton
            text="Activate"
            onClick={() => handleActivateRole(new SetUserRoleActiveDto(role))}
          />
        )}
        <DeleteButton
          onClick={() => handleDeleteClicked(role)}
          disabled={getUserRequestState.isLoading}
          text="Remove"
        />
      </TableCell>
    </TableRow>
  );

  if (user?.isFloridaNetworkUser) return <Fragment></Fragment>;

  return (
    <Fragment>
      <ConfirmRemoveRoleDialog
        onConfirm={handleRemoveRole}
        role={selectedRole}
        requestState={removeProviderRoleRequestState}
      />
      <ProviderRoleFormDialog
        onSubmit={handleAddRole}
        requestState={addProviderRoleRequestState}
      />
      <ContentSection
        label="Provider Roles"
        content={
          <Box width="100%">
            <Box display="flex" justifyContent="flex-end">
              <FnAccessWrapper>
                <AddButton text="Add Role" onClick={handleAddRoleClicked} />
              </FnAccessWrapper>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Role</TableCell>
                  <FnAccessWrapper>
                    <TableCell>Provider</TableCell>
                  </FnAccessWrapper>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.roles.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No Provider Roles Added</TableCell>
                  </TableRow>
                )}
                {user?.roles.map(renderRow)}
              </TableBody>
            </Table>
          </Box>
        }
      />
    </Fragment>
  );
};

export default ProviderRoleSection;
