import { coreAgent } from "./agent";

export const uploadFileRequest = (file: File) => {
  const formData = new FormData();
  formData.append("File", file);
  return coreAgent.postRequest<string>("/fileupload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
