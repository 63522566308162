import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useId } from "../../../app/hooks/useId";
import { useTabIndex } from "../../../app/hooks/useTabIndex";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { selectHasReadAccess } from "../../authentication/state/authenticationSelectors";
import { closeModal, openModal } from "../../modal/state/modalSlice";
import { addSuccessNotification } from "../../notifications/state/notificationSlice";
import { ScreeningFormDto } from "../../screenings/dtos/ScreeningFormDto";
import { useCreateScreeningWaitlist } from "../../screenings/hooks/screeningHooks";
import { waitlistModalId } from "../../waitlists/components/WaitlistFormDialog";
import { WaitlistFormDto } from "../../waitlists/dtos/WaitlistFormDto";
import { youthContactModalId } from "../../youth-contacts/components/YouthContactFormDialog";
import { YouthContactFormDto } from "../../youth-contacts/dtos/YouthContactFormDto";
import { YouthContactSummaryDto } from "../../youth-contacts/dtos/YouthContactSummaryDto";
import {useDeleteYouthContact, useUpdateYouthContact} from "../../youth-contacts/hooks/youthContactHooks";
import { youthScreeningFormDialogId } from "../components/modals/YouthScreeningFormDialog";
import { youthDetailsSectionName } from "../components/youth-details-sections/YouthDetailsSection";
import { youthModalId } from "../components/YouthFormDialog";
import { YouthFormDto } from "../dto/YouthFormDto";
import {
  useCreateYouthContact,
  useCreateYouthScreening, useDeleteYouth,
  useGetYouth,
  useUpdateYouth,
} from "./youthHooks";
import {deleteYouthModalId} from "../components/ConfirmDeleteYouthDialog";
import {confirmDeleteYouthContactDialogId} from "../components/youth-contacts/ConfirmDeleteYouthContactDialog";

export const useYouthDetailPage = () => {
  //#region State
  const id = useId();
  const dispatch = useAppDispatch();
  const [isInitialized, setIsInitialzed] = useState(false);
  const [tabIndex, handleChangeTabIndex] = useTabIndex(youthDetailsSectionName);
  const history = useHistory();
  const [getYouth, youth, getYouthRequestState] = useGetYouth();
  const [updateYouth, , updateYouthRequestState, clearUpdateYouthError] =
    useUpdateYouth();

  const [deleteYouth, , deleteYouthRequestState, clearDeleteYouthRequestState] = useDeleteYouth();

  const [
    createYouthScreening,
    ,
    createYouthScreeningState,
    clearCreateYouthScreeningState,
  ] = useCreateYouthScreening();

  const [
    createYouthContact,
    ,
    createYouthContactRequestState,
    clearCreateYouthContactError,
  ] = useCreateYouthContact();

  const [
    createScreeningWaitlist,
    ,
    createScreeningWaitlistRequestState,
    clearCreateScreeningWaitlistError,
  ] = useCreateScreeningWaitlist();

  const [
    updateYouthContact,
    ,
    updateYouthContactRequestState,
    clearUpdateYouthContactError,
  ] = useUpdateYouthContact();

  const [
    deleteYouthContact,
    ,
    deleteYouthContactRequestState,
    clearDeleteYouthContactError
  ] = useDeleteYouthContact();

  const [selectedYouthContact, setSelectedYouthContact] =
    useState<YouthContactSummaryDto>();

  const canReadCases = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.Case)
  );
  const canReadScreenings = useAppSelector(
    selectHasReadAccess(PermissionResourceNameEnum.Screening)
  );
  //#endregion

  //#region Handlers
  const handleEditClicked = useCallback(() => {
    clearUpdateYouthError();
    dispatch(openModal({ modalId: youthModalId }));
  }, [dispatch, clearUpdateYouthError]);

  const handleEdit = useCallback(
    async (dto: YouthFormDto) => {
      await updateYouth({ id, dto });

      batch(async () => {
        dispatch(addSuccessNotification({ message: "Youth Updated" }));
        dispatch(closeModal());
        await getYouth(id);
      });
    },
    [dispatch, id, updateYouth, getYouth]
  );

  const handleDeleteClicked = useCallback(() => {
    clearDeleteYouthRequestState(true);
    dispatch(openModal({ modalId: deleteYouthModalId }));
  }, [dispatch, clearDeleteYouthRequestState]);

  const handleDelete = useCallback(async (youthId: string) => {
    await deleteYouth(youthId);

    batch(async () => {
      dispatch(addSuccessNotification({ message: "Youth Deleted" }));
      dispatch(closeModal());
      history.push(`/youths`);
      clearDeleteYouthRequestState(true);
    });
  }, [deleteYouth, dispatch, history, clearDeleteYouthRequestState])

  const handleStartNewCaseClicked = useCallback(
    () => history.push(`/youths/${id}/cases/new`),
    [history, id]
  );

  //#region Screening
  const handleCreateScreeningClicked = useCallback(() => {
    clearCreateYouthScreeningState();
    dispatch(openModal({ modalId: youthScreeningFormDialogId }));
  }, [dispatch, clearCreateYouthScreeningState]);

  const handleCreateScreening = useCallback(
    async (dto: ScreeningFormDto, startCase: boolean) => {
      const screening = await createYouthScreening({ id, dto });
      batch(() => {
        dispatch(addSuccessNotification({ message: "Screening Created!" }));
        dispatch(closeModal());
      });
      if (startCase) {
        history.push(`/youths/${id}/cases/new?screeningId=${screening?.id}`);
      } else {
        await getYouth(id);
      }
    },
    [dispatch, id, createYouthScreening, getYouth, history]
  );
  //#endregion

  //#region Waitlist
  const handleCreateScreeningWaitlistClicked = () => {
    clearCreateScreeningWaitlistError();
    dispatch(openModal({ modalId: waitlistModalId }));
  };

  const handleCreateScreeningWaitlist = async (
    screeningId: string,
    dto: WaitlistFormDto
  ) => {
    await createScreeningWaitlist({ id: screeningId, dto });
    batch(() => {
      dispatch(addSuccessNotification({ message: "Waitlist Created" }));
      dispatch(closeModal());
    });
    await getYouth(id);
  };
  //#endregion

  //#region Youth Contacts

  const handleOpenYouthContactClicked = (
    youthContact?: YouthContactSummaryDto
  ) => {
    setSelectedYouthContact(youthContact);
    clearCreateYouthContactError();
    clearUpdateYouthContactError();
    dispatch(openModal({ modalId: youthContactModalId }));
  };

  const handleCreateYouthContactClicked = () => handleOpenYouthContactClicked();

  const handleEditYouthContactClicked = (dto: YouthContactSummaryDto) =>
    handleOpenYouthContactClicked(dto);

  const handleDeleteYouthContactClicked = (dto: YouthContactSummaryDto) => {
    setSelectedYouthContact(dto);
    clearDeleteYouthContactError();
    dispatch(openModal({ modalId: confirmDeleteYouthContactDialogId }));
  }

  const handleDeleteYouthContact = async () => {
    if(!selectedYouthContact) return;

    await deleteYouthContact({ id: selectedYouthContact.id });

    dispatch(() => {
      dispatch(
        addSuccessNotification({
          message: `Youth Contact Deleted.`,
        })
      );
      dispatch(closeModal());
    });

    setSelectedYouthContact(undefined);
    await getYouth(id);
  }


  const handleUpsertYouthContact = async (dto: YouthContactFormDto) => {
    selectedYouthContact
      ? await updateYouthContact({ id: selectedYouthContact.id, dto })
      : await createYouthContact({ id, dto });
    dispatch(() => {
      dispatch(
        addSuccessNotification({
          message: `Youth Contact ${
            selectedYouthContact ? "Updated" : "Created"
          }.`,
        })
      );
      dispatch(closeModal());
    });
    setSelectedYouthContact(undefined);
    await getYouth(id);
  };

  //#endregion

  //#endregion

  //#region Effects
  useEffect(() => {
    const load = async () => {
      try {
        await getYouth(id);
      } catch (error: any) {
        throw error;
      } finally {
        setIsInitialzed(true);
      }
    };
    load();
  }, [id, getYouth, setIsInitialzed]);
  //#endregion

  return {
    state: {
      isInitialized,
      youth,
      selectedYouthContact,
      getYouthRequestState,
      updateYouthRequestState,
      deleteYouthRequestState,
      createYouthScreeningState,
      createYouthContactRequestState,
      updateYouthContactRequestState,
      deleteYouthContactRequestState,
      createScreeningWaitlistRequestState,
      tabIndex,
      canReadCases,
      canReadScreenings,
    },
    handlers: {
      handleEdit,
      handleEditClicked,
      handleDeleteClicked,
      handleDelete,
      handleChangeTabIndex,
      handleStartNewCaseClicked,
      handleCreateScreeningClicked,
      handleCreateScreening,
      // Youth Contact
      handleCreateYouthContactClicked,
      handleEditYouthContactClicked,
      handleUpsertYouthContact,
      handleDeleteYouthContactClicked,
      handleDeleteYouthContact,
      // Waitlist
      handleCreateScreeningWaitlistClicked,
      handleCreateScreeningWaitlist,
    },
  };
};
