import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SnapInSchoolsCycleDto } from "../dtos/SnapInSchoolsCycleDto";
import { SnapInSchoolsCycleFormDto } from "../dtos/SnapInSchoolsCycleFormDto";
import { useSnapInSchoolsCycleForm } from "../hooks/useSnapInSchoolsCycleForm";
import SnapInSchoolsCycleFormFields from "./SnapInSchoolsCycleFormFields";

export const snapInSchoolsCycleModalId = "snap-in-schools-cycle-form-modal";

interface Props {
  snapCycle?: SnapInSchoolsCycleDto;
  onSubmit: (dto: SnapInSchoolsCycleFormDto) => Promise<any>;
  requestState: RequestStateDto;
}

const SnapInSchoolsCycleFormDialog: React.FC<Props> = ({
  snapCycle,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { state, form, handlers } = useSnapInSchoolsCycleForm(
    onSubmit,
    snapCycle
  );
  const { locations, getSnapInSchoolsLocationsRequestState, programs, getProgramsRequestState } = state;
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;

  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === snapInSchoolsCycleModalId}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {snapCycle ? "Edit" : "Create"} SNAP In Schools and Communities Cycle
          <ErrorText error={error?.message} />
          <ErrorText error={getSnapInSchoolsLocationsRequestState?.error?.message} />
          <ErrorText error={getProgramsRequestState?.error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <SnapInSchoolsCycleFormFields
              control={control}
              locations={locations}
              programs={programs}
              locationsAreLoading={
                getSnapInSchoolsLocationsRequestState.isLoading
              }
              programsAreLoading={getProgramsRequestState.isLoading}
              isDisabled={isLoading}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={
              !isValid ||
              isLoading ||
              getSnapInSchoolsLocationsRequestState.isLoading
            }
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SnapInSchoolsCycleFormDialog;
