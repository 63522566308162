export interface AddProviderRoleFormDto {
  providerId: string;
  roleId: string;
}

export const createAddProviderRoleFormDto = (): AddProviderRoleFormDto => {
  return {
    providerId: "",
    roleId: "",
  };
};
