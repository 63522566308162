import { Box, FormControlLabel, Switch, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../../app/components/buttons/AddButton";
import ErrorAlert from "../../../../app/components/ErrorAlert";
import { TabProps } from "../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import ConfirmDeactivateStaffMemberDialog from "../../../staff-members/components/ConfirmDeactivateStaffDialog";
import StaffMemberFormDialog from "../../../staff-members/components/StaffMemberFormDialog";
import { StaffMemberDto } from "../../../staff-members/dtos/StaffMemberDto";
import { useProviderDetailPageContext } from "../../pages/ProviderDetailPage";
import StaffMemberList from "../staff-member/StaffMemberList";

export const providerStaffMemberSectionName = "provider-staff-member-section";

const ProviderStaffMemberSection: React.FC = () => {
  const context = useProviderDetailPageContext();

  const {
    staffMembers,
    staffLoadingState,
    selectedStaff,
    activateStaffRequestState,
    deactivateStaffRequestState,
    upsertStaffRequestState,
    handleUpsertStaff,
    handleDeactivateStaff,
    handleActivateStaff,
    handleCreateStaffClicked,
    handleEditStaffClicked,
    handleDeactivateStaffClicked,
  } = context.staffMembers;

  //#region State
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [filteredStaffMembers, setFilteredStaffMembers] = useState<
    StaffMemberDto[]
  >([]);
  //#endregion

  //#region Effects
  useEffect(() => {
    if (staffLoadingState.isLoading) return;

    if (showActiveOnly) {
      setFilteredStaffMembers(
        staffMembers.filter((x) => !x.isInactive)
      );
    } else {
      setFilteredStaffMembers(staffMembers);
    }
  }, [showActiveOnly, setFilteredStaffMembers, staffLoadingState.isLoading, staffMembers]);
  //#endregion

  return (
    <Fragment>
      <StaffMemberFormDialog
        onSubmit={handleUpsertStaff}
        requestState={upsertStaffRequestState}
        staffMember={selectedStaff}
      />
      <ConfirmDeactivateStaffMemberDialog
        onConfirm={handleDeactivateStaff}
        staffMember={selectedStaff!}
        requestState={deactivateStaffRequestState}
      />

      <Box marginBottom="8px">
        <ErrorAlert error={activateStaffRequestState.error?.message} />
        <Typography color="textSecondary">
          *Staff members are used to populate lists for Screenings, Intakes, and
          Discharges. This is not a list of NetMIS users or their accounts.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.StaffMember}>
          <AddButton
            text="Add Staff Member"
            variant="contained"
            color="primary"
            onClick={handleCreateStaffClicked}
          />
        </WriteAccessLevelWrapper>

        <Box marginLeft="auto">
          <FormControlLabel
            control={
              <Switch
                checked={showActiveOnly}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setShowActiveOnly(e.currentTarget.checked)
                }
                color="primary"
              />
            }
            label="Show Active Only"
          />
        </Box>
      </Box>

      <Box marginTop="16px">
        <StaffMemberList
          staffMembers={filteredStaffMembers}
          isLoading={staffLoadingState.isLoading}
          selectedStaff={selectedStaff}
          activateIsRunning={activateStaffRequestState.isLoading}
          onEdit={handleEditStaffClicked}
          onActivate={handleActivateStaff}
          onDeactivate={handleDeactivateStaffClicked}
        />
      </Box>
    </Fragment>
  );
};

export default ProviderStaffMemberSection;
export const createProviderStaffMemberSection = (): TabProps => ({
  label: "Staff",
  value: providerStaffMemberSectionName,
  content: <ProviderStaffMemberSection />,
});
