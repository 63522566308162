import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import BackButton from "../../../app/components/buttons/BackButton";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorAlert from "../../../app/components/ErrorAlert";
import Header from "../../../app/components/Header";
import InfoTooltip from "../../../app/components/InfoTooltip";
import PdfContainer from "../../../app/components/PdfContainer";
import { useInvoicePreviewPage } from "../hooks/useInvoicePreviewPage";

const InvoicePreviewPage = () => {
  //#region State
  const { state, handlers } = useInvoicePreviewPage();
  const {
    report,
    getInvoiceReportRequestState,
    createInvoiceRequestState,
    isCurrentReportingPeriod,
    getPreviousReportingPeriodRequestState,
    isSigned,
    user,
  } = state;
  const { handleCreateInvoice, handleToggleIsSigned } = handlers;
  //#endregion

  return (
    <Fragment>
      <Box marginBottom={"12px"}>
        <BackButton route="/invoices" text="Back to Invoices" />
      </Box>
      <Header title="Invoice Preview" />
      <ErrorAlert error={getInvoiceReportRequestState.error?.message} />
      <ErrorAlert error={createInvoiceRequestState.error?.message} />
      <ErrorAlert
        error={getPreviousReportingPeriodRequestState.error?.message}
      />
      <Paper
        style={{
          padding: "16px",
          marginTop: "16px",
          height: "calc(100% - 138px)",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} height={"100%"}>
          <PdfContainer
            document={report}
            isLoading={getInvoiceReportRequestState.isLoading}
            loadingText="Loading Invoice"
          />
          <Box display={"flex"} justifyContent={"flex-end"} marginTop={"8px"}>
            {isCurrentReportingPeriod && (
              <FormControlLabel
                label={`I, ${user?.firstName} ${user?.lastName}, certify that the information presented in this invoice and in NetMIS is accurate to the best of my knowledge.`}
                color="primary"
                control={
                  <Checkbox
                    disabled={
                      getInvoiceReportRequestState.isLoading ||
                      report === undefined
                    }
                    checked={isSigned}
                    onClick={handleToggleIsSigned}
                    color="primary"
                  />
                }
              />
            )}
            {!isCurrentReportingPeriod && (
              <Box
                marginRight={"4px"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <InfoTooltip
                  title={
                    <Typography>
                      Invoices cannot be submitted before the reporting period
                      ends.
                    </Typography>
                  }
                />
              </Box>
            )}
            <SpinnerButton
              showSpinner={createInvoiceRequestState.isLoading}
              content="Submit"
              variant="outlined"
              color="primary"
              onClick={handleCreateInvoice}
              disabled={
                !isCurrentReportingPeriod ||
                !isSigned ||
                getInvoiceReportRequestState.isLoading ||
                report === undefined
              }
            />
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default InvoicePreviewPage;
