import { CaseDetailDto } from "./CaseDetailDto";
import * as Yup from "yup";

export class CaseProgramFormDto {
  programId: string;
  locationId: string;
  programLocationId: string;
  constructor(caseDto?: CaseDetailDto) {
    this.programId = caseDto?.program.id || "";
    this.locationId = caseDto?.location.id || "";
    this.programLocationId = "";
  }

  public static getSchema() {
    return Yup.object().shape({
      locationId: Yup.string().label("Location").required(),
      programId: Yup.string().label("Program").required(),
    });
  }
}
