import { Route } from "react-router-dom";
import NotificationTestPage from "./pages/NotificationTestPage";
const formatUrl = (baseUrl: string, url: string) =>
  baseUrl === "" || baseUrl === "/" ? url : `${baseUrl}/${url}`;

export const createNotificationRoutes = (baseUrl: string) => [
  <Route
    path={formatUrl(baseUrl, "")}
    component={NotificationTestPage}
    key="notification"
  />,
];
