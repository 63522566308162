import { coreAgent } from "../app/api/agent";
import {
  ServicePlanDto,
  ServicePlanParticipantDto,
} from "./dto/ServicePlanDto";
import { ServicePlanFormDto } from "./dto/ServicePlanFormDto";
import { AddServicePlanGoalDto } from "./dto/AddServicePlanGoalDto";
import { AddServicePlanInterventionDto } from "./dto/AddServicePlanInterventionDto";
import {
  AddServicePlanReviewDto,
  UpdateServicePlanReviewDto,
} from "./dto/AddServicePlanReviewDto";

export const createServicePlanRequest = async (
  caseId: string,
  dto: ServicePlanFormDto
) => {
  await coreAgent.postRequest<ServicePlanDto>(
    `cases/${caseId}/serviceplan`,
    dto
  );
};

export const updateServicePlanRequest = async (
  caseId: string,
  dto: ServicePlanFormDto
) => {
  await coreAgent.putRequest<ServicePlanDto>(
    `cases/${caseId}/serviceplan`,
    dto
  );
};

export const addServicePlanParticipantRequest = async (
  servicePlanId: string,
  dto: ServicePlanParticipantDto
) => {
  await coreAgent.postRequest<ServicePlanDto>(
    `serviceplans/${servicePlanId}/participants`,
    dto
  );
};

export const updateServicePlanParticipantRequest = async (
  servicePlanId: string,
  dto: ServicePlanParticipantDto
) => {
  await coreAgent.putRequest<ServicePlanParticipantDto>(
    `serviceplans/${servicePlanId}/participants`,
    dto
  );
};

export const deleteServicePlanParticipantRequest = async (
  servicePlanId: string,
  participantId: string
) => {
  await coreAgent.deleteRequest<boolean>(
    `serviceplans/${servicePlanId}/participants/${participantId}`
  );
};

export const addServicePlanGoalRequest = async (
  servicePlanId: string,
  dto: AddServicePlanGoalDto
) => {
  await coreAgent.postRequest<ServicePlanDto>(
    `serviceplans/${servicePlanId}/goals`,
    dto
  );
};

export const updateServicePlanGoalRequest = async (
  servicePlanId: string,
  goalId: string,
  dto: AddServicePlanGoalDto
) => {
  await coreAgent.postRequest<ServicePlanDto>(
    `serviceplans/${servicePlanId}/goals/${goalId}`,
    dto
  );
};

export const addServicePlanInterventionRequest = async (
  servicePlanId: string,
  goalId: string,
  dto: AddServicePlanInterventionDto
) => {
  await coreAgent.postRequest<AddServicePlanInterventionDto>(
    `serviceplans/${servicePlanId}/goals/${goalId}/interventions`,
    dto
  );
};

export const updateServicePlanInterventionRequest = async (
  servicePlanId: string,
  goalId: string,
  interventionId: string,
  dto: AddServicePlanInterventionDto
) => {
  await coreAgent.postRequest<AddServicePlanInterventionDto>(
    `serviceplans/${servicePlanId}/goals/${goalId}/interventions/${interventionId}`,
    dto
  );
};

export const addServicePlanReviewRequest = async (
  servicePlanId: string,
  dto: AddServicePlanReviewDto
) => {
  await coreAgent.postRequest<AddServicePlanReviewDto>(
    `serviceplans/${servicePlanId}/reviews`,
    dto
  );
};

export const updateServicePlanReviewRequest = async (
  servicePlanId: string,
  reviewId: string,
  dto: UpdateServicePlanReviewDto
) => {
  await coreAgent.postRequest<UpdateServicePlanReviewDto>(
    `serviceplans/${servicePlanId}/reviews/${reviewId}`,
    dto
  );
};

export const getServicePlanGoalsRequest = async () =>
  await coreAgent.getRequest<string[]>("serviceplans/goals");

export const getServicePlanPDFRequest = ({
  id,
  includeNotes,
}: {
  id: string;
  includeNotes: boolean;
}) =>
  coreAgent.getRequest<Blob>(
    `reports/cases/${id}/serviceplan?includeNotes=${includeNotes}&format=PDF`,
    { responseType: "blob" },
    false
  );
