import React from "react";
import {useServicePlanPageContext} from "../ServicePlanPageContext";
import {closeModal} from "../../features/modal/state/modalSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table, TableBody, TableCell, TableHead, TableRow,
  Typography
} from "@material-ui/core";
import SpinnerButton from "../../app/components/buttons/SpinnerButton";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectModalId} from "../../features/modal/state/modalSelectors";
import NameLabel from "../../app/components/labels/NameLabel";
import DateLabel from "../../app/components/labels/DateLabel";
import {getRoleDisplayText} from "../enums/ServicePlanParticipantRolesEnum";

interface Props {
  reviewId?: string;
}

export const viewServicePlanReviewDetailsModalId = 'view-service-plan-review-details';

export const ViewServicePlanReviewDetailsModal: React.FC<Props> = ({ reviewId }) => {

  const selectedModalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { servicePlan, youth } = useServicePlanPageContext();

  const review = servicePlan.reviews.find(r => r.id === reviewId);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (<Dialog open={viewServicePlanReviewDetailsModalId === selectedModalId} maxWidth="sm" fullWidth>

    <DialogTitle>
      Service Plan Review Details
    </DialogTitle>
    <DialogContent>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>Review Performed On: </Typography>
        </Grid>
        <Grid item xs={8}>
          <DateLabel date={review?.reviewedOn} />
        </Grid>

        <Grid item xs={12}>
          <Typography color={"primary"}>
            Review Participants
          </Typography>
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {review?.youthParticipated && <TableRow>
              <TableCell><NameLabel variant={"inherit"} nameDto={youth} /></TableCell>
              <TableCell>{"Youth"}</TableCell>
            </TableRow>}
            {review?.counselorParticipated &&  <TableRow>
                <TableCell><NameLabel variant={"inherit"} nameDto={servicePlan.counselor} /></TableCell>
                <TableCell>{"Counselor"}</TableCell>
            </TableRow>}
            {review?.otherParticipants.map(op => (
              <TableRow>
                <TableCell>{op.name}</TableCell>
                <TableCell>{ getRoleDisplayText(op.role) }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Grid item xs={12}>
          <Typography color={"primary"}>
            Review Notes
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            { review?.notes }
          </Typography>
        </Grid>
      </Grid>

    </DialogContent>
    <DialogActions>
      <SpinnerButton
        content="Cancel"
        showSpinner={false}
        type="button"
        onClick={handleCloseModal}
        disabled={false}
      />
    </DialogActions>

  </Dialog>)

}
