import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import InvoiceCorrectionConfirmationCheckbox from "../../../app/components/InvoiceCorrectionConfirmationCheckbox";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SnapCurriculumDto } from "../dtos/SnapCurriculumDto";
import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import { SnapGroupFormDto } from "../dtos/SnapGroupFormDto";
import { SnapGroupSummaryDto } from "../dtos/SnapGroupSummaryDto";
import { useSnapGroupForm } from "../hooks/useSnapGroupForm";
import SnapGroupFormFields from "./SnapGroupFormFields";

export const snapGroupModalId = "snap-group-form-modal";

interface Props {
  snapGroup?: SnapGroupSummaryDto;
  snapCycle?: SnapGroupCycleDto;
  requestState: RequestStateDto;
  curriculum: SnapCurriculumDto[];
  getSnapCurriculumRequestState: RequestStateDto;
  onSubmit(dto: SnapGroupFormDto): Promise<any>;
}

const SnapGroupFormDialog: React.FC<Props> = ({
  snapGroup,
  requestState,
  curriculum,
  getSnapCurriculumRequestState,
  snapCycle,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers, state } = useSnapGroupForm(
    onSubmit,
    curriculum,
    snapGroup,
    snapCycle
  );
  const { handleSubmit, handleToggleConfirmed } = handlers;
  const { isConfirmed, filteredCurriculum } = state;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === snapGroupModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {snapGroup ? "Edit" : "Create"} SNAP Group
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <SnapGroupFormFields
              control={control}
              isDisabled={isLoading}
              curriculum={filteredCurriculum}
              curriculumIsLoading={getSnapCurriculumRequestState.isLoading}
            />
            {snapGroup && (
              <Grid item md={12} xs={12}>
                <Divider />
                <InvoiceCorrectionConfirmationCheckbox
                  isConfirmed={isConfirmed}
                  onCheck={handleToggleConfirmed}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SnapGroupFormDialog;
