import { SelectOption } from "../../app/components/inputs/ControlledSelect";

export enum SnapSatisfactionSurveyLikertEnum {
  Dissatisfied = "Dissatisfied",
  SomewhatDissatisfied = "SomewhatDissatisfied",
  Undecided = "Undecided",
  SomewhatSatisfied = "SomewhatSatisfied",
  Satisfied = "Satisfied",
}

export const SnapSatisfactionSurveyLikertEnumTextMap: {
  [key: string]: string;
} = {
  [SnapSatisfactionSurveyLikertEnum.Dissatisfied]: "Dissatisfied",
  [SnapSatisfactionSurveyLikertEnum.SomewhatDissatisfied]:
    "Somewhat Dissatisfied",
  [SnapSatisfactionSurveyLikertEnum.Undecided]: "Undecided",
  [SnapSatisfactionSurveyLikertEnum.SomewhatSatisfied]: "Somewhat Satisfied",
  [SnapSatisfactionSurveyLikertEnum.Satisfied]: "Satisfied",
};

export const SnapSatisfactionSurveyLikertEnumOptions: SelectOption[] = [
  { label: "Select Response", value: "" },
  ...Object.values(SnapSatisfactionSurveyLikertEnum).map((value) => ({
    value,
    label: SnapSatisfactionSurveyLikertEnumTextMap[value],
  })),
];

export enum SnapSatisfactionSurveyHelpsPeopleEnum {
  Teachers = "Teachers",
  Peers = "Peers",
  FamilyMembers = "FamilyMembers",
  DealWithConflict = "DealWithConflict",
  IdentifyTriggers = "IdentifyTriggers",
  CalmThemselves = "CalmThemselves",
}

export const SnapSatisfactionSurveyHelpsPeopleEnumTextMap: {
  [key: string]: string;
} = {
  [SnapSatisfactionSurveyHelpsPeopleEnum.Teachers]:
    "Get along better with teachers",
  [SnapSatisfactionSurveyHelpsPeopleEnum.Peers]: "Get along better with peers",
  [SnapSatisfactionSurveyHelpsPeopleEnum.FamilyMembers]:
    "Get along better with family members",
  [SnapSatisfactionSurveyHelpsPeopleEnum.DealWithConflict]:
    "Be able to deal with conflict",
  [SnapSatisfactionSurveyHelpsPeopleEnum.IdentifyTriggers]:
    "Be able to identify their triggers",
  [SnapSatisfactionSurveyHelpsPeopleEnum.CalmThemselves]:
    "Calm themselves when upset",
};

export enum SnapSatisfactionSurveySkillEnum {
  IdentifyTriggers = "IdentifyTriggers",
  UnderstandPhysiologicalChanges = "UnderstandPhysiologicalChanges",
  StopAndCalmingStrategies = "StopAndCalmingStrategies",
  UnderstandingHardThoughts = "UnderstandingHardThoughts",
  CoolThoughtsAndCoping = "CoolThoughtsAndCoping",
  GeneratingEffectivePlans = "GeneratingEffectivePlans",
  IdentifyingCriticalMoment = "IdentifyingCriticalMoment",
  ConfidenceToTryAPlan = "ConfidenceToTryAPlan",
  AbilityToMakeGoodChoices = "AbilityToMakeGoodChoices",
}

export const SnapSatisfactionSurveySkillEnumTextMap: {
  [key: string]: string;
} = {
  [SnapSatisfactionSurveySkillEnum.IdentifyTriggers]: "Identify Triggers",
  [SnapSatisfactionSurveySkillEnum.UnderstandPhysiologicalChanges]:
    "Understanding physiological changes (Body Cues) when angry or upset",
  [SnapSatisfactionSurveySkillEnum.StopAndCalmingStrategies]:
    "Stops and calming strategies",
  [SnapSatisfactionSurveySkillEnum.UnderstandingHardThoughts]:
    "Understanding Hard Thoughts",
  [SnapSatisfactionSurveySkillEnum.CoolThoughtsAndCoping]:
    "Cool thoughts and coping strategies",
  [SnapSatisfactionSurveySkillEnum.GeneratingEffectivePlans]:
    "Generating effective plans",
  [SnapSatisfactionSurveySkillEnum.IdentifyingCriticalMoment]:
    "Identifying the critical moment",
  [SnapSatisfactionSurveySkillEnum.ConfidenceToTryAPlan]:
    "Confidence to try a plan",
  [SnapSatisfactionSurveySkillEnum.AbilityToMakeGoodChoices]:
    "Ability to make good choices",
};
