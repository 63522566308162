import { Box, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { Fragment } from "react";
import { RequestStateDto } from "../dtos/RequestStateDto";
import { useCombinedErrorState } from "../hooks/useCombinedRequests";
interface Props {
  requests: RequestStateDto[];
}

const RequestErrorAlert: React.FC<Props> = ({ requests }) => {
  const errors = useCombinedErrorState(requests);

  const renderErrors = () => {
    if (errors.length === 1) return <Typography>{errors[0]}</Typography>;

    return (
      <ul>
        {errors.map((e) => (
          <li key={`error-${e.replace(" ", "_")}`}>
            <Typography>{e}</Typography>
          </li>
        ))}
      </ul>
    );
  };

  if (errors.length > 0)
    return (
      <Box marginBottom="12px">
        <Alert severity="error" variant="filled">
          {renderErrors()}
        </Alert>
      </Box>
    );

  return <Fragment></Fragment>;
};

export default RequestErrorAlert;
