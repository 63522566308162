import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { ReferralDto } from "../../referrals/dtos/ReferralDto";
import { YouthDto } from "../dto/YouthDto";
import { YouthFormDto } from "../dto/YouthFormDto";
import YouthFormFields from "./YouthFormFields";

interface Props {
  onSubmit: (dto: YouthFormDto, callback?: () => void) => void;
  isLoading: boolean;
  youth?: YouthDto;
  referral?: ReferralDto;
}

const YouthForm: React.FC<Props> = ({
  onSubmit,
  isLoading,
  youth,
  referral,
}) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new YouthFormDto(youth),
    mode: "all",
    resolver: yupResolver(YouthFormDto.getSchema()),
  });
  //#endregion

  //#region handlers
  const handleFormSubmit = (dto: YouthFormDto) => {
    dto.ethnicity = dto.ethnicity ? dto.ethnicity : undefined;
    onSubmit(dto, () => reset(new YouthFormDto(youth)));
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    new YouthFormDto(youth);
  }, [reset, youth]);

  useEffect(() => {
    if (referral != null) {
      reset(YouthFormDto.fromReferral(referral));
    }
  }, [referral, reset]);
  //#endregion

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={2}>
        <YouthFormFields control={control} disabled={isLoading} isLegacy={youth?.isLegacy} />
        <Grid item xs={12}>
          <SpinnerButton
            content="Save"
            showSpinner={isLoading}
            disabled={isValid === false}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default YouthForm;
