import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { StaffMemberDto } from "../../staff-members/dtos/StaffMemberDto";
import { dischargeStatusOptions } from "../enums/DischargeStatusEnum";
import { schoolProgramOptions } from "../enums/SchoolProgramEnum";
import { schoolStatusOptions } from "../enums/SchoolStatusEnum";
import { employmentStatusOptions } from "../enums/EmploymentStatusEnum";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import { getLivingSituationOptions } from "../../screenings/enums/LivingSituationEnum";
import {StaffMemberControlledAutoComplete} from "../../staff-members/components/StaffMemberControlledAutoComplete";

interface Props {
  control: Control<any>;
  disabled: boolean;
  counselorsAreLoading: boolean;
  staffMembers: StaffMemberDto[];
}

const DischargeCaseFormFields: React.FC<Props> = ({
  control,
  disabled,
  counselorsAreLoading,
  staffMembers,
}) => {
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="dischargedAt"
          label="Discharge Date/Time*"
          type="datetime-local"
          control={control}
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <StaffMemberControlledAutoComplete
          control={control}
          disabled={disabled}
          name="exitCounselorId"
          label="Staff Completing Discharge*"
          isLoading={counselorsAreLoading}
          staffMembers={staffMembers}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          disabled={disabled}
          name="status"
          options={dischargeStatusOptions}
          label="Status*"
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          disabled={disabled}
          name="livingSituation"
          options={getLivingSituationOptions()}
          label="Living Situation*"
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          disabled={disabled}
          name="schoolProgram"
          options={schoolProgramOptions}
          label="School Program*"
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          disabled={disabled}
          name="schoolStatus"
          options={schoolStatusOptions}
          label="School Status*"
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          disabled={disabled}
          name="employmentStatus"
          options={employmentStatusOptions}
          label="Employment Status"
          fullWidth
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledCheckbox
          control={control}
          disabled={disabled}
          name="isBakerAct"
          label="Is this discharge due to a Baker Act event?"
          color="primary"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          name="notes"
          label="Discharge Notes"
          control={control}
          disabled={disabled}
          fullWidth
          multiline
          minRows={3}
        />
      </Grid>
    </Fragment>
  );
};

export default DischargeCaseFormFields;
