import { Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import React, { Fragment } from "react";
import {
  ReferrerRelationshipEnum,
  referrerRelationshipTextMap,
} from "../enums/ReferrerRelationshipEnum";

interface Props extends DefaultComponentProps<TypographyTypeMap<any>> {
  relationship?: ReferrerRelationshipEnum;
}

const ReferrerRelationshipLabel: React.FC<Props> = ({
  relationship,
  ...props
}) => {
  if (relationship === undefined) {
    return <Fragment></Fragment>;
  }

  return (
    <Typography {...props}>
      {referrerRelationshipTextMap[relationship]}
    </Typography>
  );
};

export default ReferrerRelationshipLabel;
