import React, { Fragment, useCallback, useEffect, useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { useAppSelector } from "../../../app/hooks";
import {selectHasDeleteAccess} from "../../authentication/state/authenticationSelectors";
import {YouthDto} from "../dto/YouthDto";

const buildDeleteYouthAction = (onClick: () => void) => {
  return (
    <MenuItem onClick={onClick} key="delete-youth-menu-item">
      Delete Youth
    </MenuItem>
  );
};

interface Props {
  youth: YouthDto;
  onDeleteYouthClicked(): void;
}

const YouthDetailAdditionalActions: React.FC<Props> = ({
                                                        youth,
                                                         onDeleteYouthClicked,
                                                       }) => {
  const [menuItems, setMenuItems] = useState<React.ReactNode[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const canDeleteYouth = useAppSelector(
    selectHasDeleteAccess(PermissionResourceNameEnum.Youth)
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleAction = useCallback(
    (action: () => void) => () => {
      action();
      handleClose();
    },
    [handleClose]
  );
  useEffect(() => {
    const menuItems: React.ReactNode[] = [];

    if (canDeleteYouth) {
      const deleteYouthItem = buildDeleteYouthAction(
        handleAction(onDeleteYouthClicked)
      );

      if (deleteYouthItem) menuItems.push(deleteYouthItem);
    }

    setMenuItems(menuItems);
  }, [
    setMenuItems,
    handleAction,
    canDeleteYouth,
    onDeleteYouthClicked,
  ]);

  if (menuItems.length === 0) return <Fragment></Fragment>;

  return (
    <Fragment>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon></MoreVertIcon>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </Fragment>
  );
};

export default YouthDetailAdditionalActions;
