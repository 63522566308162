import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React from "react";
import { PaginationResultDto } from "../dtos/PaginationResultDto";
import { PaginationSearchParamsDto } from "../dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../enums/SortDirectionEnum";
import TableLoadingPlaceholder from "./TableLoadingPlaceholder";
import InfoTooltip from "./InfoTooltip";

export interface ColumnProps {
  name: string;
  label: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  align?: "center" | "inherit" | "left" | "right" | "justify" | undefined;
  infoText?: string;
}

interface SortTableColumnHeaderProps {
  column: ColumnProps;
  params: PaginationSearchParamsDto;
  onSort(sortBy: string, sortDirection: SortDirectionEnum): void;
}

const SortTableColumnHeader: React.FC<SortTableColumnHeaderProps> = ({
  column,
  params,
  onSort,
}) => {
  const { name, label, disabled, infoText } = column;
  const direction: SortDirectionEnum =
    params.sortBy === name
      ? params.sortDirection!
      : SortDirectionEnum.Ascending;

  const isCurrentSortBy = params.sortBy === name;
  return (
    <TableCell
      align={column.align}
      sortDirection={isCurrentSortBy ? params.sortDirection : false}
    >
      <TableSortLabel
        active={isCurrentSortBy}
        direction={direction}
        hideSortIcon={disabled}
        disabled={disabled}
        onClick={() =>
          onSort(
            name,
            isCurrentSortBy && direction === SortDirectionEnum.Ascending
              ? SortDirectionEnum.Descending
              : SortDirectionEnum.Ascending
          )
        }
      >
        {label}
        { infoText && (<InfoTooltip title={infoText} />) }
      </TableSortLabel>
    </TableCell>
  );
};

export interface SortableTableProps<T, P extends PaginationSearchParamsDto> {
  results: PaginationResultDto<T>;
  isLoading: boolean;
  params: P;
  noDataLabel?: React.ReactNode;
  onPageChange(page: number): void;
  onSort(sortBy: string, sortDirection: SortDirectionEnum): void;
}

interface Props extends SortableTableProps<any, any> {
  columns: ColumnProps[];
  renderRow(value: any): React.ReactNode;
}

const SortableTable: React.FC<Props> = ({
  results,
  isLoading,
  params,
  columns,
  noDataLabel = "No Data Found",
  renderRow,
  onPageChange,
  onSort,
}) => {
  const renderColumnHeader = (column: ColumnProps) => (
    <SortTableColumnHeader
      key={column.name}
      column={column}
      params={params}
      onSort={onSort}
    />
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.filter((x) => x.hidden !== true).map(renderColumnHeader)}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && results.items.map(renderRow)}
          {isLoading && (
            <TableLoadingPlaceholder columns={columns.filter(c => !c.hidden).length} rows={10} />
          )}
          {!isLoading && results.items.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length}>{noDataLabel}</TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[results.pageSize || 10]}
              rowsPerPage={results.pageSize || 0}
              count={results?.count || 0}
              onPageChange={(_: any, page: number) => onPageChange(page)}
              page={results?.page || 0}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default SortableTable;
