import React, { useEffect } from "react";
import {
  DashboardFiltersDto,
  DateRangeType,
} from "../dtos/MainDashboardMetricsDto";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dateToFormString, toDate } from "../../../app/helpers";
import * as Yup from "yup";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

interface Props {
  filters: DashboardFiltersDto;
  anchorEl: HTMLElement | null;
  onFiltersChanged(filters: DashboardFiltersDto): void;
  onClose(): void;
}

interface DashboardFilterFormDto {
  type: DateRangeType;
  startDate?: string;
  endDate?: string;
}

const schema = Yup.object().shape({
  type: Yup.string().required().label("Date Filter Type"),
  startDate: Yup.string().when("type", {
    is: DateRangeType.Custom,
    then: Yup.string().required().label("Start Date"),
  }),
  endDate: Yup.string().when("type", {
    is: DateRangeType.Custom,
    then: Yup.string()
      .label("End Date")
      .required()
      .test(
        "date-check",
        "End Date cannot be before Start Date",
        function (value) {
          if (value == null || value === "") return true;

          if (this.parent.startDate == null || this.parent.startDate === "")
            return true;

          return new Date(this.parent.startDate) <= new Date(value);
        }
      ),
  }),
});

export const DashboardFilter: React.FC<Props> = ({
  filters,
  anchorEl,
  onClose,
  onFiltersChanged,
}) => {
  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<DashboardFilterFormDto>({
    defaultValues: {
      type: filters.dateRange.type,
      startDate:
        filters.dateRange.type === DateRangeType.Custom
          ? dateToFormString(filters.dateRange.startDate)
          : "",
      endDate:
        filters.dateRange.type === DateRangeType.Custom
          ? dateToFormString(filters.dateRange.endDate)
          : "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = () => {
    reset({
      type: filters.dateRange.type,
      startDate:
        filters.dateRange.type === DateRangeType.Custom
          ? dateToFormString(filters.dateRange.startDate)
          : "",
      endDate:
        filters.dateRange.type === DateRangeType.Custom
          ? dateToFormString(filters.dateRange.endDate)
          : "",
    });
  };

  useEffect(() => {
    reset({
      type: filters.dateRange.type,
      startDate:
        filters.dateRange.type === DateRangeType.Custom
          ? dateToFormString(filters.dateRange.startDate)
          : "",
      endDate:
        filters.dateRange.type === DateRangeType.Custom
          ? dateToFormString(filters.dateRange.endDate)
          : "",
    });
  }, [filters.dateRange, filters.dateRange.type, reset]);

  const { type } = useWatch({ control });

  const handleOnSubmit = handleSubmit(
    async (formData: DashboardFilterFormDto) => {
      const dto: DashboardFiltersDto =
        formData.type === DateRangeType.Custom
          ? {
              dateRange: {
                type: DateRangeType.Custom,
                startDate: toDate(
                  new Date(`${formData.startDate}T00:00`)
                ).toJSDate(),
                endDate: toDate(
                  new Date(`${formData.endDate}T00:00`)
                ).toJSDate(),
              },
            }
          : {
              dateRange: {
                type: formData.type,
              },
            };

      onFiltersChanged(dto);
      onClose();
    }
  );

  const dateRangeTypeFilters: SelectOption[] = [
    { label: "Fiscal Year To Date", value: DateRangeType.FiscalYearToDate },
    { label: "Calendar Year To Date", value: DateRangeType.YearToDate },
    { label: "Custom Date Range", value: DateRangeType.Custom },
  ];

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={onClose}
    >
      <div style={{ margin: "15px", width: "500px" }}>
        <form onSubmit={handleOnSubmit}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box display="flex">
                <Typography variant="h6">
                  {" "}
                  Edit Dashboard Data Filters
                </Typography>
                <Box marginLeft="auto">
                  <IconButton onClick={clearForm} title="Clear All Filters">
                    <NotInterestedIcon />
                  </IconButton>
                  <IconButton onClick={onClose} title="Close Filter">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name={"type"}
                options={dateRangeTypeFilters}
                fullWidth
                label={"Date Range Type"}
              />
            </Grid>
            {type === DateRangeType.Custom && (
              <>
                <Grid item md={12} xs={12}>
                  <ControlledInput
                    name="startDate"
                    label="Start Date"
                    type="date"
                    control={control}
                    disabled={false}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <ControlledInput
                    name="endDate"
                    label="End Date"
                    type="date"
                    control={control}
                    disabled={false}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item md={12} xs={12}>
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!isValid}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Popover>
  );
};
