import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum IntakeSchoolStatusEnum {
  AttendingRegularly = "AttendingRegularly",
  GraduatedHighSchool = "GraduatedHighSchool",
  CompletedGed = "CompletedGed",
  AttendingIrregularly = "AttendingIrregularly",
  DroppedOut = "DroppedOut",
  Suspended = "Suspended",
  Expelled = "Expelled",
  NotInSession = "NotInSession",
  DoNotKnow = "DoNotKnow",
  NotEnrolled = "NotEnrolled",
}

export const intakeSchoolStatusEnumTextMap: { [key: string]: string } = {
  [IntakeSchoolStatusEnum.AttendingRegularly]: "Attending school regularly",
  [IntakeSchoolStatusEnum.GraduatedHighSchool]: "Graduated high school",
  [IntakeSchoolStatusEnum.CompletedGed]: "Completed GED",
  [IntakeSchoolStatusEnum.AttendingIrregularly]:
    "Attending school irregularly/extended truancy",
  [IntakeSchoolStatusEnum.DroppedOut]: "Dropped out",
  [IntakeSchoolStatusEnum.Suspended]: "Suspended",
  [IntakeSchoolStatusEnum.Expelled]: "Expelled",
  [IntakeSchoolStatusEnum.NotInSession]: "School not in session",
  [IntakeSchoolStatusEnum.DoNotKnow]: "Do not know",
  [IntakeSchoolStatusEnum.NotEnrolled]: "Not enrolled",
};

export const intakeSchoolStatusOptions: SelectOption[] = [
  { value: "", label: "Select School Status" },
  ...Object.keys(IntakeSchoolStatusEnum).map((value) => ({
    value,
    label: intakeSchoolStatusEnumTextMap[value],
  })),
];
