import { Grid, List, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import ListDivider from "../../../../app/components/ListDivider";
import { StaffMemberDto } from "../../../staff-members/dtos/StaffMemberDto";
import StaffMemberListItem, {
  StaffMemberListLoadingItem,
} from "./StaffMemberListItem";

interface Props {
  staffMembers: StaffMemberDto[];
  isLoading: boolean;
  onEdit: (staff: StaffMemberDto) => void;
  onActivate: (staff: StaffMemberDto) => void;
  onDeactivate: (staff: StaffMemberDto) => void;
  activateIsRunning: boolean;
  selectedStaff?: StaffMemberDto;
}

const StaffMemberList: React.FC<Props> = ({
  staffMembers,
  isLoading,
  onEdit,
  onActivate,
  onDeactivate,
  activateIsRunning,
  selectedStaff,
}) => {
  if (isLoading)
    return (
      <Grid container spacing={1}>
        {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
          <Grid key={`staff-loading-${i}`} item md={12} xs={12}>
            <StaffMemberListLoadingItem />
          </Grid>
        ))}
      </Grid>
    );

  if (staffMembers.length === 0)
    return <Typography>No staff members created for provider</Typography>;

  return (
    <List>
      {staffMembers.map((staffMember, index) => (
        <Fragment key={staffMember.id}>
          <StaffMemberListItem
            staffMember={staffMember}
            onEdit={() => onEdit(staffMember)}
            onActivate={() => onActivate(staffMember)}
            onDeactive={() => onDeactivate(staffMember)}
            activateIsRunning={
              activateIsRunning && selectedStaff?.id === staffMember.id
            }
          />
          <ListDivider index={index} length={staffMembers.length} />
        </Fragment>
      ))}
    </List>
  );
};

export default StaffMemberList;
