import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SuicideScreeningDto, SuicideScreeningFormDto } from "../dtos/SuicideScreeningDto";
import {
  suicideScreeningPage1Questions,
  useSuicideScreeningForm,
} from "../hooks/useSuicideScreeningForm";
import { SuicideScreeningPageFormFields } from "./SuicideScreeningFormFields";

export const suicideScreeningModalId = "suicide-screening-form-modal";

interface Props {
  requestState: RequestStateDto;
  suicideScreeningDto: SuicideScreeningDto | undefined,
  onSubmit: (dto: SuicideScreeningFormDto) => Promise<any>;  
}

const SuicideScreeningFormDialog: React.FC<Props> = ({
  suicideScreeningDto,
  requestState,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useSuicideScreeningForm(onSubmit, suicideScreeningDto);

  const { handleSubmit } = handlers;
  // const {
  //   // activeStep,
  //   // nextIsDisabled,
  //   // previousIsDisabled,
  //   // handleNextClick,
  //   // handlePreviousClick,
  //   // steps,
  //   // currentPage,
  // } = state;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === suicideScreeningModalId}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Suicide Screening
          {/*-{" "}*/}
          {/*{currentPage === SuicideScreeningPageEnum.Review*/}
          {/*  ? "Review and Save"*/}
          {/*  : "Enter Answers"}*/}
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/*{currentPage === SuicideScreeningPageEnum.Page1 && (*/}
              <SuicideScreeningPageFormFields
                control={control}
                questions={suicideScreeningPage1Questions}
              />
            {/*)}*/}
            {/*{currentPage === SuicideScreeningPageEnum.Page2 && (*/}
            {/*  <SuicideScreeningPageFormFields*/}
            {/*    control={control}*/}
            {/*    questions={suicideScreeningPage2Questions}*/}
            {/*  />*/}
            {/*)}*/}
            {/*{currentPage === SuicideScreeningPageEnum.Review && (*/}
            {/*  <Fragment>*/}
            {/*    <SuicideScreeningFormReview control={control} />*/}
            {/*  </Fragment>*/}
            {/*)}*/}
          </Grid>
          {/*<Stepper*/}
          {/*  onNextClick={handleNextClick}*/}
          {/*  onPreviousClick={handlePreviousClick}*/}
          {/*  isPreviousDisabled={previousIsDisabled}*/}
          {/*  isNextDisabled={nextIsDisabled}*/}
          {/*  activeStep={activeStep}*/}
          {/*  steps={steps}*/}
          {/*/>*/}
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SuicideScreeningFormDialog;
