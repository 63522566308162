import { formatUrl } from "../../app/helpers";
import ReadAccessLevelRoute from "../../app/routes/ReadAccessLevelRoute";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import WaitlistPage from "./pages/WaitlistPage";

export const createWaitlistRoutes = (baseUrl: string) => [
  <ReadAccessLevelRoute
    name={PermissionResourceNameEnum.Case}
    path={formatUrl(baseUrl, "")}
    component={WaitlistPage}
    key="list-waitlist"
  />,
];
