import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { CaseDateRangeTypeEnum, CaseFilterFormDto } from "./CaseFilterFormDto";

export class CaseSearchParamsDto implements PaginationSearchParamsDto {
  caseNumber?: string;
  providerId?: string;
  intakeDateStart?: string;
  intakeDateEnd?: string;
  dischargeDateStart?: string;
  dischargeDateEnd?: string;
  caseActiveDateStart?: string;
  caseActiveDateEnd?: string;
  programTypeId?: string;
  programId?: string;
  locationId?: string;
  //caseManagerId?: string;
  intakeStaffId?: string;
  primaryStaffId?: string;
  youthFirstName?: string;
  youthLastName?: string;
  fundingSource?: string;
  activeOnly: boolean;
  includeDeleted: boolean;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: CaseFilterFormDto;
    previous?: CaseSearchParamsDto;
  }) {
    this.caseNumber = form?.caseNumber || (query?.caseNumber as string) || "";
    this.providerId = form?.providerId || (query?.providerId as string) || "";
    this.programTypeId =
      form?.programTypeId || (query?.programTypeId as string) || "";
    this.programId = form?.programId || (query?.programId as string) || "";
    this.youthFirstName =
      form?.youthFirstName || (query?.youthFirstName as string) || "";
    this.youthLastName =
      form?.youthLastName || (query?.youthLastName as string) || "";
    this.activeOnly = form?.activeOnly || Boolean(query?.activeOnly === "true");
    this.locationId = form?.locationId || (query?.locationId as string) || "";
    //this.caseManagerId = form?.caseManagerId || (query?.caseManagerId as string) || "";
    this.intakeStaffId = form?.intakeStaffId || (query?.intakeStaffId as string) || "";
    this.primaryStaffId = form?.primaryStaffId || (query?.primaryStaffId as string) || "";
    this.fundingSource =
      form?.fundingSource || (query?.fundingSource as string) || "";
    this.sortBy = previous?.sortBy || (query?.sortBy as string) || "";
    this.sortDirection =
      (previous?.sortDirection as SortDirectionEnum) ||
      QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
    this.includeDeleted =
      form?.includeDeleted || Boolean(query?.includeDeleted === "true");

    this.intakeDateStart = (query?.intakeDateStart as string) || "";
    this.intakeDateEnd = (query?.intakeDateEnd as string) || "";
    this.dischargeDateStart = (query?.dischargeDateStart as string) || "";
    this.dischargeDateEnd = (query?.dischargeDateEnd as string) || "";
    this.caseActiveDateStart = (query?.caseActiveDateStart as string) || "";
    this.caseActiveDateEnd = (query?.caseActiveDateEnd as string) || "";

    if (form) {
      switch (form.dateRangeType) {
        case CaseDateRangeTypeEnum.Intake:
          this.intakeDateStart = form.dateRangeStart;
          this.intakeDateEnd = form.dateRangeEnd;
          break;
        case CaseDateRangeTypeEnum.Discharge:
          this.dischargeDateStart = form.dateRangeStart;
          this.dischargeDateEnd = form.dateRangeEnd;
          break;
        case CaseDateRangeTypeEnum.CaseActive:
          this.caseActiveDateStart = form.dateRangeStart;
          this.caseActiveDateEnd = form.dateRangeEnd;
          break;
      }
    }
  }

  public static toQueryString(dto: CaseSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
