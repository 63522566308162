import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { fnyfsProviderId } from "../../authentication/constants";
import {
  getIsFnyfsUser,
  getProviderId,
} from "../../authentication/state/authenticationActions";
import { useGetInvoiceYears } from "../../invoices/hooks/invoicingHooks";
import {useGetAllProviders} from "../../provider/hooks/providerHooks";
import { MonthlyReportCardSearchParamsDto } from "../dtos/MonthlyReportCardSearchParamsDto";
import { useGetMonthlyReportCardDocument } from "./reportHooks";

export const useMonthlyReportCardPage = () => {
  const [getDocument, document, getDocumentRequestState] =
    useGetMonthlyReportCardDocument();
  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();
  const [getInvoiceYears, , getInvoiceYearsRequestState] = useGetInvoiceYears();

  const [invoiceYears, setInvoiceYears] = useState<number[]>([]);

  const handleSearch = async (params: MonthlyReportCardSearchParamsDto) => {
    await getDocument(params);
  };

  const isFnyfsUser = getIsFnyfsUser();

  useEffect(() => {
    if (getProviderId() === fnyfsProviderId) getProviders(undefined);
  }, [getProviders]);

  useEffect(() => {
    const load = async () => {
      let result = await getInvoiceYears(undefined);
      if (result) {
        const now = DateTime.now();

        if (now.month === 7) result = result.filter((x) => x === now.year);

        setInvoiceYears(result.sort((a, b) => (a > b ? 1 : -1)));
      }
    };

    load();
  }, [getInvoiceYears, setInvoiceYears]);


  return {
    document,
    getDocumentRequestState,
    providers: providers || [],
    getProvidersRequestState,
    invoiceYears,
    getInvoiceYearsRequestState,
    handleSearch,
    isFnyfsUser,
  };
};
