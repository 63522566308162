import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum DemographicsMaritalStatusEnum {
  Single = "Single",
  Married = "Married",
  Widowed = "Widowed",
  Divorced = "Divorced",
  Separated = "Separated",
  DomesticPartnership = "DomesticPartnership",
}

export const demographicsMaritalTextMap: { [key: string]: string } = {
  [DemographicsMaritalStatusEnum.Single]: "Single",
  [DemographicsMaritalStatusEnum.Married]: "Married",
  [DemographicsMaritalStatusEnum.Widowed]: "Widowed",
  [DemographicsMaritalStatusEnum.Divorced]: "Divorced",
  [DemographicsMaritalStatusEnum.Separated]: "Separated",
  [DemographicsMaritalStatusEnum.DomesticPartnership]:
    "In a domestic partnership",
};

export const demographicsMaritalStatusOptions: SelectOption[] = [
  { value: "", label: "Select Marital Status" },
  ...Object.keys(DemographicsMaritalStatusEnum).map((status) => ({
    value: status,
    label: demographicsMaritalTextMap[status],
  })),
];
