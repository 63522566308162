import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { fnyfsProviderId } from "../../authentication/constants";
import {
  getIsFnyfsUser,
  getProviderId,
} from "../../authentication/state/authenticationActions";
import { useGetAllProviders } from "../../provider/hooks/providerHooks";
import {
  NirvanaExportSearchFormDto,
  NirvanaExportSearchParamsDto,
} from "../dtos/NirvanaExportSearchParamsDto";

export const useNirvanaExportReportFilter = (
  onSubmit: (dto: NirvanaExportSearchParamsDto) => Promise<void>,
  params: NirvanaExportSearchParamsDto
) => {
  //#region State
  const {
    control,
    reset,
    getValues,
    formState: { isValid },
  } = useForm({
    defaultValues: new NirvanaExportSearchFormDto(params),
    resolver: yupResolver(NirvanaExportSearchFormDto.getSchema()),
    mode: "all",
  });
  const isFnyfsUser = getIsFnyfsUser();
  const [getAllProviders, providers, getAllProvidersRequestState] =
    useGetAllProviders();

  //#endregion

  //#region Handlers

  const handleDownload = async () => {
    const values = getValues();
    await onSubmit(values);
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new NirvanaExportSearchFormDto(params));
  }, [params, reset]);

  useEffect(() => {
    if (getProviderId() === fnyfsProviderId)
      getAllProviders({
        programTypes: [],
      });
  }, [getAllProviders]);

  //#endregion

  return {
    state: {
      getAllProvidersRequestState,
      providers: providers || [],
      isValid,
      isFnyfsUser,
    },
    form: { control },
    handlers: { handleDownload },
  };
};
