import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import ErrorText from "../../../app/components/ErrorText";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { useWatch } from "react-hook-form";
import { useAddFollowUpDataForm } from "../hooks/useAddFollowUpDataForm";
import { FollowUpDto } from "../dtos/FollowUpDto";
import { FollowUpCompletionDataFormFields } from "./FollowUpCompletionDataFormFields";

interface Props {
  followUp: FollowUpDto;
  caseDto: CaseDetailDto;
  onSubmit: () => Promise<void>;
}
export const addFollowUpDialogId = "add-follow-up-dialog";
const AddFollowUpDataFormDialog: React.FC<Props> = ({
  followUp,
  caseDto,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    state: {
      unableToContactReasonOptions,
      schoolStatusOptions,
      livingSituationOptions,
      followUpOutcomeOptions,
      addFollowUpDataRequestState,
      followUpImprovementOptions
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useAddFollowUpDataForm(onSubmit, caseDto, followUp);

  const { outcome } = useWatch({ control });

  const { isLoading, error } = addFollowUpDataRequestState;

  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  const modalIdWithFollowUp = `${addFollowUpDialogId}-${followUp.id}`;

  return (
    <Dialog open={modalId === modalIdWithFollowUp} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Add Follow Up Data
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="attemptedAt"
                label="Attempt Date/Time"
                type="datetime-local"
                control={control}
                disabled={false}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name={"outcome"}
                options={followUpOutcomeOptions}
                fullWidth
                label={"Follow Up Outcome"}
              />
            </Grid>
            {outcome === "failed" && (
              <Grid item md={12} xs={12}>
                <ControlledSelect
                  control={control}
                  name={"reason"}
                  options={unableToContactReasonOptions}
                  fullWidth
                  label={"Reason for Failed Attempt"}
                />
              </Grid>
            )}
            {outcome === "success" && (
              <FollowUpCompletionDataFormFields
                control={control}
                livingSituationOptions={livingSituationOptions}
                schoolStatusOptions={schoolStatusOptions}
                improvementStatusOptions={followUpImprovementOptions}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={false}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddFollowUpDataFormDialog;
