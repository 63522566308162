import {CaseDetailDto} from "../../dtos/CaseDetailDto";
import React, {Fragment} from "react";
import {Box, Divider, Grid, Typography} from "@material-ui/core";
import AddButton from "../../../../app/components/buttons/AddButton";
import {FollowUpDto} from "../../../follow-ups/dtos/FollowUpDto";
import CreateNewFollowUpFormDialog, {
  createNewFollowUpDialogId,
} from "../../../follow-ups/components/CreateNewFollowUpFormDialog";
import {openModal} from "../../../modal/state/modalSlice";
import {useAppDispatch} from "../../../../app/hooks";
import {livingSituationTextMap} from "../../../screenings/enums/LivingSituationEnum";
import {schoolStatusTextMap} from "../../../discharges/enums/SchoolStatusEnum";
import YesNoLabel from "../../../../app/components/labels/YesNoLabel";
import EditButton from "../../../../app/components/buttons/EditButton";
import {FollowUpInterval, followUpIntervalTextMap,} from "../../../follow-ups/enums/FollowUpInterval";
import AddFollowUpDataFormDialog, {
  addFollowUpDialogId,
} from "../../../follow-ups/components/AddFollowUpDataFormDialog";
import {unableToContactReasonsTextMap} from "../../../follow-ups/enums/UnableToContactReasonsEnum";
import {FollowUpStatus, followUpStatusTextMap,} from "../../../follow-ups/enums/FollowUpStatus";
import DateTimeLabel from "../../../../app/components/labels/DateTimeLabel";
import GreenAlertLabel from "../../../../app/components/labels/GreenAlertLabel";
import AlertLabel from "../../../../app/components/labels/AlertLabel";
import {ColorsEnum} from "../../../../app/enums/ColorsEnum";
import YellowAlertLabel from "../../../../app/components/labels/YellowAlertLabel";
import DateLabel from "../../../../app/components/labels/DateLabel";
import DeleteAccessLevelWrapper from "../../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import {PermissionResourceNameEnum} from "../../../authentication/enums/PermissionResourceNameEnum";
import DeleteButton from "../../../../app/components/buttons/DeleteButton";
import {useCaseDetailPageContext} from "../../pages/CaseDetailPage";
import ConfirmDeleteFollowUpDialog from "../../../follow-ups/components/ConfirmDeleteFollowUpDialog";
import UpdateFollowUpAttemptDataDialog from "../../../follow-ups/components/UpdateFollowUpAttemptDataDialog";
import UpdateCompletionDataDialog from "../../../follow-ups/components/UpdateCompletionDataDialog";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import {followUpImprovementTextMap} from "../../../follow-ups/enums/FollowUpImprovementEnum";
import GreyAlertLabel from "../../../../app/components/labels/GreyAlertLabel";

interface Props {
  caseDto: CaseDetailDto;
  isLoading: boolean;
  onChangeFollowUpData(): Promise<void>;
}

export const caseFollowUpsSectionName = "case-follow-ups-section";

const CaseFollowUpsSection: React.FC<Props> = ({
  caseDto,
  onChangeFollowUpData,
}) => {
  const {
    followUps: {
      selectedFollowUp,
      selectedFollowUpAttempt,
      handleDeleteFollowUpClicked,
      handleDeleteFollowUp,
      deleteFollowUpRequestState,
      handleUpdateFollowUpAttemptDataClicked,
      handleUpdateFollowUpAttemptData,
      handleUpdateFollowUpCompletionData,
      handleUpdateFollowUpCompletionDataClicked,
    },
  } = useCaseDetailPageContext();

  const dispatch = useAppDispatch();

  const showCreateModalClicked = () => {
    dispatch(openModal({ modalId: createNewFollowUpDialogId }));
  };

  const showAddFollowUpModalClicked = (followUp: FollowUpDto) => {
    const followUpDialogId = `${addFollowUpDialogId}-${followUp.id}`;
    dispatch(openModal({ modalId: followUpDialogId }));
  };

  //#region UI Helpers
  const createRow = (
    label: string | React.ReactNode,
    content: React.ReactNode
  ) => {
    return (
      <Fragment>
        <Grid item md={3} xs={4}>
          <Typography>{label}:</Typography>
        </Grid>
        <Grid item md={9} xs={8} style={{ fontWeight: "bold" }}>
          {content}
        </Grid>
      </Fragment>
    );
  };
  const createSectionHeader = (text: string) => {
    return (
      <Grid item md={12} xs={12}>
        <Divider style={{ marginBottom: "8px" }} />
        <Typography color="primary">{text}</Typography>
      </Grid>
    );
  };
  const createStatusBadge = (status: FollowUpStatus) => {
    const labelText = followUpStatusTextMap[status];
    switch (status) {
      case FollowUpStatus.Completed:
        return <GreenAlertLabel style={{ width: 120 }} label={labelText} />;
      case FollowUpStatus.Attempted:
        return (
          <AlertLabel
            color={ColorsEnum.DarkGrey}
            style={{ width: 120 }}
            label={labelText}
          />
        );
      case FollowUpStatus.InProgress:
        return <YellowAlertLabel style={{ width: 120 }} label={labelText} />;
      case FollowUpStatus.NotRequired:
        return (<GreyAlertLabel
          style={{ color: "darkgray", width: 120 }}
          label={followUpStatusTextMap[status]}
        />
        );
    }
  };
  const createFollowUpSection = (followUp: FollowUpDto) => {

    const allowAddingAttemptsOrCompletion = followUp.status !== FollowUpStatus.Completed && followUp.status !== FollowUpStatus.Attempted;

    return (
      <Box position="relative" width="100%" style={{ margin: "12px" }}>
        <DeleteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
          <Box position="absolute" right="0">
            <DeleteButton
              title="Delete Follow Up"
              onClick={() => handleDeleteFollowUpClicked(followUp)}
            />
          </Box>
        </DeleteAccessLevelWrapper>
        <Grid container spacing={2}>
          {createSectionHeader(
            `${followUpIntervalTextMap[followUp.interval]} Follow Up`
          )}

          {createRow("Status", createStatusBadge(followUp.status))}
          {createRow(
            "Staff Member",
            <Typography style={{ fontWeight: "bold" }}>
              {followUp.staffMemberName}
            </Typography>
          )}
          {followUp.failedAttempts
            .sort((a, b) => a.attemptNumber - b.attemptNumber)
            .map((fa) => {
              const labelText = `Attempt ${fa.attemptNumber}`;
              return (
                <Fragment key={fa.id}>
                  {createRow(
                    labelText,
                    <>
                      <Typography>
                        {unableToContactReasonsTextMap[fa.reason]} on{" "}
                        <DateTimeLabel date={fa.attemptedOn} />
                      </Typography>
                      <WriteAccessLevelWrapper
                        name={PermissionResourceNameEnum.Case}
                      >
                        <EditButton
                          text={"Edit Attempt Details"}
                          onClick={() =>
                            handleUpdateFollowUpAttemptDataClicked(followUp, fa)
                          }
                        />
                      </WriteAccessLevelWrapper>
                    </>
                  )}
                </Fragment>
              );
            })}

          {allowAddingAttemptsOrCompletion && (
            <>
            <WriteAccessLevelWrapper
              name={PermissionResourceNameEnum.Case}
            >
              <AddFollowUpDataFormDialog
                followUp={followUp}
                caseDto={caseDto}
                onSubmit={onChangeFollowUpData}
              />
              <EditButton
                text={"Record New Contact Attempt"}
                onClick={() => showAddFollowUpModalClicked(followUp)}
              />
            </WriteAccessLevelWrapper>
            </>
          )}

          {followUp && followUp.status === FollowUpStatus.Completed && (
            <>
              {createRow(
                "Completed On",
                <DateLabel date={followUp.completionData.completedOn} />
              )}
              {createRow(
                "Has Youth received services at this agency since discharge?",
                <YesNoLabel
                  style={{ fontWeight: "bold" }}
                  value={followUp.completionData.receivedServicesSinceDischarge}
                />
              )}
              {createRow(
                "Did the youth/family receive services from another Florida Network agency/program since discharge?",
                <YesNoLabel
                  style={{ fontWeight: "bold" }}
                  value={
                    followUp.completionData
                      .receivedServicesFromAnotherProviderSinceDischarge
                  }
                />
              )}
              {createRow(
                "Current Living Status",
                <Typography style={{ fontWeight: "bold" }}>
                  {livingSituationTextMap[followUp.completionData.livingStatus]}
                </Typography>
              )}
              {createRow(
                "Is this an appropriate living situation for the youth?",
                <YesNoLabel
                  style={{ fontWeight: "bold" }}
                  value={
                    followUp.completionData.isYouthsLivingStatusAppropriate
                  }
                />
              )}
              {createRow(
                "Current School status",
                <Typography style={{ fontWeight: "bold" }}>
                  {schoolStatusTextMap[followUp.completionData.schoolStatus]}
                </Typography>
              )}
              {createRow(
                "Have you seen improvement in the issues for which the youth/family received services at your agency?",
                <Typography style={{ fontWeight: "bold" }}>
                  {followUpImprovementTextMap[followUp.completionData.seenImprovement] || "N/A"}
                </Typography>
              )}
              <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
                <EditButton
                  text={"Edit Completion Data"}
                  onClick={() =>
                    handleUpdateFollowUpCompletionDataClicked(followUp)
                  }
                />
              </WriteAccessLevelWrapper>
            </>
          )}
        </Grid>
      </Box>
    );
  };

  //#endregion

  if (!caseDto) return <></>;

  const thirtyDayFollowUp = caseDto.followUps.find(
    (f) => f.interval === FollowUpInterval.ThirtyDay
  );
  const sixtyDayFollowUp = caseDto.followUps.find(
    (f) => f.interval === FollowUpInterval.SixtyDay
  );
  const ninetyDayFollowUp = caseDto.followUps.find(
    (f) => f.interval === FollowUpInterval.NinetyDay
  );

  return (
    <div>


      {caseDto.followUps.length < 3 && (
        <div style={{display: "flex", justifyContent: "flex-start" }}>
          { caseDto.followUps.length === 0 && (
            <Typography>No Follow Ups Entered.</Typography>
          )}

        <div style={{ marginLeft: "auto" }}>
        <WriteAccessLevelWrapper
          name={PermissionResourceNameEnum.Case}
        >
          <CreateNewFollowUpFormDialog
            caseDto={caseDto}
            onSubmit={onChangeFollowUpData}
          />
          <AddButton
            text={"Enter Follow Up"}
            onClick={showCreateModalClicked}
          />
        </WriteAccessLevelWrapper>
        </div>
        </div>
      )}

      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <UpdateFollowUpAttemptDataDialog
          onSubmit={handleUpdateFollowUpAttemptData}
          followUpAttempt={selectedFollowUpAttempt!}
        />
        <UpdateCompletionDataDialog
          followUp={selectedFollowUp!}
          caseDto={caseDto}
          onSubmit={handleUpdateFollowUpCompletionData}
        />
      </WriteAccessLevelWrapper>

      <DeleteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
        <ConfirmDeleteFollowUpDialog
          onConfirm={handleDeleteFollowUp}
          requestState={deleteFollowUpRequestState}
          followUp={selectedFollowUp}
        />
      </DeleteAccessLevelWrapper>

      <Grid container spacing={1}>
        {thirtyDayFollowUp && createFollowUpSection(thirtyDayFollowUp)}
        {sixtyDayFollowUp && createFollowUpSection(sixtyDayFollowUp)}
        {ninetyDayFollowUp && createFollowUpSection(ninetyDayFollowUp)}
      </Grid>
    </div>
  );
};

export default CaseFollowUpsSection;
