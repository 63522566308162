import React, { Fragment } from "react";
import ProviderList from "../components/ProviderList";
import AddButton from "../../../app/components/buttons/AddButton";
import ProviderFormDialog from "../components/ProviderFormDialog";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { useProviderListPage } from "../hooks/useProviderListPage";
import Header from "../../../app/components/Header";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";

const ProviderListPage = () => {
  //#region State

  const { state, handlers } = useProviderListPage();
  const {
    params,
    providerResults,
    getProvidersRequestState,
    createProviderRequestState,
  } = state;
  const {
    handlePageChange,
    handleCreateProviderClicked,
    handleCreateProvider,
    handleSort,
  } = handlers;
  const { isLoading } = getProvidersRequestState;
  //#endregion

  return (
    <Fragment>
      <ProviderFormDialog
        onSubmit={handleCreateProvider}
        requestState={createProviderRequestState}
      />
      <RequestErrorAlert requests={[getProvidersRequestState]} />
      <Header
        title="Providers"
        isLoading={isLoading}
        actions={
          <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Provider}>
            <AddButton
              onClick={handleCreateProviderClicked}
              title="Create Provider"
              color="primary"
              text="Create Provider"
              variant="contained"
              disabled={isLoading}
            />
          </WriteAccessLevelWrapper>
        }
      />

      <ProviderList
        isLoading={isLoading}
        providerResults={
          providerResults || { items: [], page: 0, pageSize: 10, count: 0 }
        }
        params={params}
        onPageChange={handlePageChange}
        onSort={handleSort}
      />
    </Fragment>
  );
};

export default ProviderListPage;
