import { useRequest } from "../../../app/hooks/useRequest";
import {
  deleteYouthContactRequest,
  getYouthContactRequest,
  updateYouthContactRequest,
} from "../YouthContactRequest";

export const useGetYouthContact = () => useRequest(getYouthContactRequest);

export const useUpdateYouthContact = () =>
  useRequest(updateYouthContactRequest);

export const useDeleteYouthContact = () =>
  useRequest(deleteYouthContactRequest);
