import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledCurrencyInput from "../../../app/components/inputs/ControlledCurrencyInput";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import * as Yup from "yup";
import { getMonthOptions } from "../../../app/enums/MonthEnum";

export const deliverableRateSchema = Yup.object().shape({
  month: Yup.string().label("Month").required(),
  year: Yup.string()
    .label("Year")
    .required()
    .length(4, "Year must be in YYYY format."),
  providerAmount: Yup.string()
    .label("Provider Rate")
    .required()
    .test("valid-rate", "Provider Rate is required.", (value) => {
      return value !== undefined && value !== "" && value !== "_";
    }),
  fnyfsAmount: Yup.string()
    .label("Florida Network Rate")
    .required()
    .test("valid-rate", "Florida Network Rate is required.", (value) => {
      return value !== undefined && value !== "" && value !== "_";
    }),
});

const monthOptions: SelectOption[] = getMonthOptions();

interface Props {
  control: Control<any>;
  disabled: boolean;
  currentProviderRate?: number;
  currentFnyfsRate?: number;
}

const DeliverableRateFormFields: React.FC<Props> = ({
  control,
  disabled,
  currentFnyfsRate,
  currentProviderRate,
}) => {
  // console.log(monthOptions);
  return (
    <Fragment>
      <Grid item md={6} xs={12}>
        <ControlledSelect
          control={control}
          name="month"
          label="Month"
          fullWidth
          disabled={disabled}
          options={monthOptions}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledWholeNumberInput
          control={control}
          name="year"
          label="Year"
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledCurrencyInput
          control={control}
          name="providerAmount"
          label={`Provider Rate (current: $${currentProviderRate?.toFixed(2)})`}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledCurrencyInput
          control={control}
          name="fnyfsAmount"
          label={`Florida Network Rate (current: $${currentFnyfsRate?.toFixed(2)})`}
          fullWidth
          disabled={disabled}
        />
      </Grid>
    </Fragment>
  );
};

export default DeliverableRateFormFields;
