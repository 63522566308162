import { FormControl, FormLabel, RadioGroupProps } from "@material-ui/core";
import { Control } from "react-hook-form";
import ControlledRadioGroup, { RadioOption } from "./ControlledRadioGroup";

const yesNoOptions: RadioOption[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

interface Props extends RadioGroupProps {
  control: Control<any>;
  name: string;
  label: string;
  disabled: boolean;
  options?: RadioOption[];
}

const ControlledYesNoRadioGroup: React.FC<Props> = ({
  control,
  name,
  label,
  disabled,
  options = yesNoOptions,
  ...props
}) => {
  return (
    <FormControl component="fieldset" disabled={disabled}>
      <FormLabel>{label}</FormLabel>
      <ControlledRadioGroup
        control={control}
        name={name}
        options={options}
        style={{ display: "flex", flexDirection: "row" }}
        {...props}
      />
    </FormControl>
  );
};

export default ControlledYesNoRadioGroup;
