import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SetYouthDjjIdNumberFormDto } from "../dto/SetYouthDjjIdNumberFormDto";
import { useSetYouthDjjIdNumberForm } from "../hooks/useSetYouthDjjIdNumberForm";

export const setYouthDjjIdNumberModalId = "set-youth-djj-id-number-form-modal";

interface Props {
  onSubmit: (dto: SetYouthDjjIdNumberFormDto) => Promise<void>;
  requestState: RequestStateDto;
  currentDjjId?: number;
}

const SetYouthDjjIdNumberFormDialog: React.FC<Props> = ({
  currentDjjId,
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useSetYouthDjjIdNumberForm(onSubmit, currentDjjId);
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === setYouthDjjIdNumberModalId}
      maxWidth="xs"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Set Youth DJJ ID #
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="djjIdNumber"
                label="DJJ ID #"
                control={control}
                disabled={isLoading}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetYouthDjjIdNumberFormDialog;
