import { Typography } from "@material-ui/core";
import React from "react";
import ConfirmDialog from "../../../../../../app/components/modals/ConfirmDialog";
import {RequestStateDto} from "../../../../../../app/dtos/RequestStateDto";
import ErrorText from "../../../../../../app/components/ErrorText";
import {ColorsEnum} from "../../../../../../app/enums/ColorsEnum";


interface Props {
  requestState: RequestStateDto;
  onConfirm(): Promise<void>;
}

export const confirmDeleteSuicideScreeningDialogId = "delete-suicide-screening-dialog";
const ConfirmDeleteSuicideScreeningDialog: React.FC<Props> = ({
                                                        onConfirm,
                                                        requestState,
                                                      }) => {

  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={() => {
        onConfirm();
      }}
      modalId={confirmDeleteSuicideScreeningDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Suicide Screening"
      confirmButtonStyle={{
        color: ColorsEnum.Red,
      }}
      maxWidth="md"
    >
      <ErrorText error={error?.message} />
      <Typography style={{ marginBottom: "8px" }}>
        Are you sure you want to delete this Suicide Screening?
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteSuicideScreeningDialog;
