import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ProgramTypeIdEnum } from "../../../app/enums/ProgramTypeIdEnum";
import { snapGroupFidelitySchema } from "../components/snap-group-fidelity/SnapGroupFidelityFormFields";
import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import {
  createSnapFideltiyFormDto,
  SnapFidelityFormDto,
} from "../dtos/SnapFidelityFormDto";
import { SnapGroupDto } from "../dtos/SnapGroupDto";

export const useSnapGroupFidelityForm = (
  onSubmit: (dto: SnapFidelityFormDto) => Promise<void>,
  group?: SnapGroupDto,
  snapCycle?: SnapGroupCycleDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createSnapFideltiyFormDto(
      snapCycle?.programType.id === ProgramTypeIdEnum.SnapYouthJustice,
      group?.fidelityInfo
    ),
    resolver: yupResolver(snapGroupFidelitySchema),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(
      createSnapFideltiyFormDto(
        snapCycle?.programType.id === ProgramTypeIdEnum.SnapYouthJustice,
        group?.fidelityInfo
      )
    );
  };
  const handleOnSubmit = handleSubmit(async (dto: SnapFidelityFormDto) => {
    await onSubmit(dto);
  });

  useEffect(() => {
    reset(
      createSnapFideltiyFormDto(
        snapCycle?.programType.id === ProgramTypeIdEnum.SnapYouthJustice,
        group?.fidelityInfo
      )
    );
  }, [group, snapCycle, reset]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
