import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

interface Props {
  width?: number;
  className?: string;
}

const LoadingLegendPlaceholder: React.FC<Props> = ({
  width = 200,
  ...props
}) => {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <Skeleton
        variant="rect"
        animation="wave"
        width={16}
        height={19.77}
        className="mr-2"
      />
      <Typography variant={"subtitle2"}>
        <Skeleton variant="text" animation="wave" width={width} />
      </Typography>
    </Box>
  );
};

export default LoadingLegendPlaceholder;
