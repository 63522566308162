import React from "react";
import { RouteProps } from "react-router-dom";
import { AccessLevelEnum } from "../../features/authentication/enums/AccessLevelEnum";
import { PermissionResourceNameEnum } from "../../features/authentication/enums/PermissionResourceNameEnum";
import AccessLevelRoute from "./AccessLevelRoute";

interface Props extends RouteProps {
  name: PermissionResourceNameEnum;
}

const WriteAccessLevelRoute: React.FC<Props> = (props) => {
  return <AccessLevelRoute {...props} accessLevel={AccessLevelEnum.Write} />;
};

export default WriteAccessLevelRoute;
