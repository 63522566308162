import { YouthSearchParamsDto } from "./YouthSearchParamsDto";

export class YouthFilterFormDto {
  firstName: string;
  lastName: string;
  dob: string;
  referenceNumber: string;
  djjIdNumber: string;
  activeOnly: boolean;
  zipCode: string;

  constructor(params?: YouthSearchParamsDto) {
    this.firstName = params?.firstName || "";
    this.lastName = params?.lastName || "";
    this.dob = params?.dob || "";
    this.referenceNumber = params?.referenceNumber || "";
    this.djjIdNumber = params?.djjIdNumber || "";
    this.zipCode = params?.zipCode || "";
    this.activeOnly = params?.activeOnly || false;
  }
}
