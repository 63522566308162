import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { ProgramTypeIdEnum } from "../../../app/enums/ProgramTypeIdEnum";
import { calculateDateDifferenceInDays } from "../../../app/helpers";
import { DateTime } from "luxon";
import { SnapGroupSummaryDto } from "../../snap/dtos/SnapGroupSummaryDto";
import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";

export enum AlertTypeEnum {
  CaseScreeningRequired = "Fnyfs.Netmis.Core.Api.Alerts.CaseScreeningRequiredAlertStrategy",
  YouthDjjIdRequired = "Fnyfs.Netmis.Core.Api.Alerts.CaseYouthDjjIdRequiredAlertStrategy",
  YouthLegalGuardianRequired = "Fnyfs.Netmis.Core.Api.Alerts.CaseYouthLegalGuardianRequiredAlertStrategy",
  CaseNirvanaAssessmentRequired = "Fnyfs.Netmis.Core.Api.Alerts.CaseNirvanaAssessmentRequiredAlertStrategy",
  CaseNirvanaPostAssessmentRequired = "Fnyfs.Netmis.Core.Api.Cases.NirvanaPostAssessmentValidation",
  CaseNirvanaSelfReportAssessmentRequired = "Fnyfs.Netmis.Core.Api.Alerts.CaseNirvanaSelfReportAssessmentRequiredAlertStrategy",
  CaseTopsePostAssessmentRequired = "Fnyfs.Netmis.Core.Api.Alerts.CaseTopsePostRequiredAlertStrategy",
  CaseTopsePreAssessmentRequired = "Fnyfs.Netmis.Core.Api.Alerts.CaseTopsePreRequiredAlertStrategy",
  CaseDvRespiteLimit = "Fnyfs.Netmis.Core.Api.Alerts.DvRespiteLimitAlertStrategy",
  Case30DayFollowUp = "Fnyfs.Netmis.Core.Api.Alerts.Case30DayFollowUpAlertStrategy",
  Case60DayFollowUp = "Fnyfs.Netmis.Core.Api.Alerts.Case60DayFollowUpAlertStrategy",
  SnapGroupFidelityRequired = "Fnyfs.Netmis.Core.Api.Alerts.SnapGroupFidelityRequiredAlertStrategy",
  ShelterCaseLengthOfStay = "Fnyfs.Netmis.Core.Api.Alerts.ShelterCaseLengthOfStayAlertStrategy",
  CaseDeferredBilling = "Fnyfs.Netmis.Core.Api.Alerts.CaseDeferredBillingAlertStrategy",
  SnapCaseLengthOfStay = "Fnyfs.Netmis.Core.Api.Alerts.SnapCaseLengthOfStayAlertStrategy",
  IcmCaseLengthOfStay = "Fnyfs.Netmis.Core.Api.Alerts.IcmCaseLengthOfStayAlertStrategy",
  ProbationRespiteCaseLengthOfStay = "Fnyfs.Netmis.Core.Api.Alerts.ProbationRespiteCaseLengthOfStayAlertStrategy",
  CommunityCounselingCaseLengthOfStay = "Fnyfs.Netmis.Core.Api.Alerts.CommunityCounselingCaseLengthOfStayAlertStrategy",
  IcmContact = "Fnyfs.Netmis.Core.Api.Alerts.IcmContactAlertStrategy",
  SnapInSchoolsPreMoceRequired = "Fnyfs.Netmis.Core.Api.Alerts.SiscPreMoceRequiredAlertStrategy",
  SnapInSchoolsPostMoceRequired = "Fnyfs.Netmis.Core.Api.Alerts.SiscPostMoceRequiredAlertStrategy",
  SnapInSchoolsFidelityAdherenceRequired = "Fnyfs.Netmis.Core.Api.Alerts.SiscFidelityAdherenceChecklistRequiredAlertStrategy",
  CaseStaffSecureEligibility = "Fnyfs.Netmis.Core.Api.Alerts.Strategies.CaseStaffSecureEligibilityAlertStrategy",  
}

export const alertTypeTextMap: { [key: string]: string } = {
  [AlertTypeEnum.CaseScreeningRequired]: "Case Screening Required",
  [AlertTypeEnum.YouthDjjIdRequired]: "Youth DJJ ID Required.",
  [AlertTypeEnum.YouthLegalGuardianRequired]: "Youth must have at least one Youth Contact that is a legal guardian or have power of attorney.",
  [AlertTypeEnum.CaseNirvanaAssessmentRequired]: "NIRVANA Assessment Required",
  [AlertTypeEnum.CaseNirvanaPostAssessmentRequired]:
    "NIRVANA Post-Assessment Required",
  [AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired]:
    "NIRVANA Self-Report Required",
  [AlertTypeEnum.CaseTopsePostAssessmentRequired]:
    "TOPSE Post-Assessment Required",
  [AlertTypeEnum.CaseTopsePreAssessmentRequired]:
    "TOPSE Pre-Assessment Required",
  [AlertTypeEnum.IcmContact]: "ICM Contact",
  [AlertTypeEnum.ShelterCaseLengthOfStay]: "Length of Stay (Shelter)",
  [AlertTypeEnum.SnapCaseLengthOfStay]: "Length of Stay (SNAP)",
  [AlertTypeEnum.IcmCaseLengthOfStay]: "Length of Stay (ICM)",
  [AlertTypeEnum.ProbationRespiteCaseLengthOfStay]:
    "Length of Stay (Probation Respite)",
  [AlertTypeEnum.CommunityCounselingCaseLengthOfStay]:
    "Length of Stay (Community Counseling)",    
  [AlertTypeEnum.CaseDeferredBilling]:
    "Deferred Billing",
};

const createFollowUpMessage = (
  followUpDays: number,
  lateDateInterval: number,
  caseDto: CaseDetailDto
): string => {
  if(caseDto?.dischargeInfo?.dischargedAt == null) {
    return "";
  }

  let lateDate = new Date(caseDto.dischargeInfo.dischargedAt);
  lateDate.setDate(lateDate.getDate() + lateDateInterval);

  return `Please conduct the ${followUpDays}-day follow-up for Case Number ${
    caseDto.caseNumber
  }, Youth ID ${caseDto.youth.referenceNumber}.
    It is considered 'Late' after ${DateTime.fromJSDate(lateDate).toFormat(
      "M/d/yyyy"
    )}.`;
};

export const CaseContextAlertTypeMessages: {
  [alertType in AlertTypeEnum]: (c: CaseDetailDto) => string;
} = {
  [AlertTypeEnum.CaseScreeningRequired]: () => "Screening required.",
  [AlertTypeEnum.YouthDjjIdRequired]: () => "Youth DJJ ID # required.",
  [AlertTypeEnum.YouthLegalGuardianRequired]: () => "Youth must have at least one Youth Contact that is a legal guardian or have power of attorney.",
  [AlertTypeEnum.CaseNirvanaAssessmentRequired]: () =>
    "NIRVANA Assessment required.",
  [AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired]: () =>
    "NIRVANA Self-Report Assessment required.",
  [AlertTypeEnum.CaseNirvanaPostAssessmentRequired]: () =>
    "NIRVANA Post Assessment required.",
  [AlertTypeEnum.CaseTopsePreAssessmentRequired]: () =>
    "TOPSE Pre-Assessment required.",
  [AlertTypeEnum.CaseTopsePostAssessmentRequired]: () =>
    "TOPSE Post-Assessment required.",
  [AlertTypeEnum.CaseStaffSecureEligibility]: () =>
    "Indicate that the Case is eligible for Staff Secure services for invoicing.",
  [AlertTypeEnum.CaseDvRespiteLimit]: (caseDto: CaseDetailDto) => {
    if (
      !caseDto ||
      caseDto?.dischargeInfo ||
      caseDto.program.programType.id !== ProgramTypeIdEnum.DvRespite
    )
      return "";

    const days = calculateDateDifferenceInDays(
      new Date(),
      caseDto?.intakeInfo.intakeAt
    );
    if (days >= 22)
      return "Please discharge from Program. It has been greater than the 21-day limit for eligibility for this program type.";
    if (days >= 21)
      return "Please discharge from Program. Today is the last day that they are eligible for this program type.";
    if (days >= 19)
      return "Please prepare discharge from Program. After day 21, they will no longer be eligible for this program type.";

    return "";
  },
  [AlertTypeEnum.ShelterCaseLengthOfStay]: (caseDto: CaseDetailDto) =>
    lengthOfStayMessage(caseDto, ProgramTypeIdEnum.Shelter),
  [AlertTypeEnum.SnapCaseLengthOfStay]: (caseDto: CaseDetailDto) =>
    lengthOfStayMessage(caseDto, ProgramTypeIdEnum.Snap),
  [AlertTypeEnum.IcmCaseLengthOfStay]: (caseDto: CaseDetailDto) =>
    lengthOfStayMessage(caseDto, ProgramTypeIdEnum.Icm),
  [AlertTypeEnum.ProbationRespiteCaseLengthOfStay]: (
    caseDto: CaseDetailDto
  ) => lengthOfStayMessage(caseDto, ProgramTypeIdEnum.ProbationRespite),
  [AlertTypeEnum.CommunityCounselingCaseLengthOfStay]: (
    caseDto: CaseDetailDto
  ) => lengthOfStayMessage(caseDto, ProgramTypeIdEnum.CommunityCounseling),  
  [AlertTypeEnum.CaseDeferredBilling]: (
    caseDto: CaseDetailDto
  ) => `There is a more recent Case open for Youth ID ${caseDto.youth.referenceNumber}. The Case you are viewing (${caseDto.caseNumber}) must be discharged in order to receive payment for ${caseDto.caseNumber}.`,
  [AlertTypeEnum.Case30DayFollowUp]: (caseDto: CaseDetailDto) =>
    createFollowUpMessage(30, 41, caseDto),
  [AlertTypeEnum.Case60DayFollowUp]: (caseDto: CaseDetailDto) =>
    createFollowUpMessage(60, 71, caseDto),
  [AlertTypeEnum.SnapGroupFidelityRequired]: () => "",
  [AlertTypeEnum.SnapInSchoolsPostMoceRequired]: () => "",
  [AlertTypeEnum.SnapInSchoolsPreMoceRequired]: () => "",
  [AlertTypeEnum.SnapInSchoolsFidelityAdherenceRequired]: () => "",
  [AlertTypeEnum.IcmContact]: () =>
    "It has been one week since the last collateral or face-to-face contact was entered for this Case/Youth. Please remember to add your contacts.",
};

const lengthOfStayMessage = (caseDto: CaseDetailDto, programTypeId: string) => {
  if (!caseDto || caseDto.program.programType.id !== programTypeId) return "";

  const days = calculateDateDifferenceInDays(
    new Date(),
    caseDto?.intakeInfo.intakeAt
  );

  const additionalMessage =
    programTypeId !== ProgramTypeIdEnum.ProbationRespite
      ? ""
      : " (and contact Probation Officer)";

  return `This case has been open ${days} days. Please specify if the youth is still in service${additionalMessage} or discharge at this time.`;
};

export const SnapGroupContextAlertTypeMessages: {
  [key: string]: (summary: SnapGroupSummaryDto) => string;
} = {
  [AlertTypeEnum.SnapGroupFidelityRequired]: (summary: SnapGroupSummaryDto) =>
    `Please add a Fidelity for the Group held on ${DateTime.fromJSDate(
      new Date(summary.groupHeldOn)
    ).toLocaleString()}. It has been at least three days since the Group Date.`,
};
export const SnapInSchoolsCycleContextAlertTypeMessages: {
  [key: string]: (summary: SnapInSchoolsCycleDto) => string;
} = {
  [AlertTypeEnum.SnapInSchoolsPreMoceRequired]: () =>
    "Enter a Pre-MOCE for this Cycle if one has been administered.",
  [AlertTypeEnum.SnapInSchoolsPostMoceRequired]: () =>
    "Enter a Post-MOCE for this Cycle if one has been administered.",
  [AlertTypeEnum.SnapInSchoolsFidelityAdherenceRequired]: () =>
    "Enter a Fidelity Adherence Checklist date and score for this Cycle, when completed.",
};
