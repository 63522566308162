import React, { Fragment } from "react";
import { PercentageValueMetric } from "../dtos/MainDashboardMetricsDto";
import { Box, Typography } from "@material-ui/core";
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import { Skeleton } from "@material-ui/lab";
import LoadingLegendPlaceholder from "../../../app/components/loading/LoadingLegendPlaceholder";

interface Props {
  intakesMetric?: PercentageValueMetric;
  dischargesMetric?: PercentageValueMetric;
  isLoading?: boolean;
}

export const DataEntryMetricPanel: React.FC<Props> = ({
  intakesMetric,
  dischargesMetric,
  isLoading,
}) => {
  const renderPieChart = () => {
    if (isLoading || !intakesMetric || !dischargesMetric)
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Skeleton
            variant="circle"
            animation="wave"
            width={274.5}
            height={274.5}
          />
          <Box
            position="absolute"
            width="220px"
            height="220px"
            bgcolor="white"
            borderRadius="110px"
          ></Box>

          <Box
            position={"absolute"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Skeleton variant="text" animation="wave" width={75} height={48} />
            <Skeleton variant="text" animation="wave" width={100} />
          </Box>
        </Box>
      );

    const intakesData = [
      { title: "Intakes On Time", value: intakesMetric.value },
      {
        title: "Intakes Late",
        value: intakesMetric.total - intakesMetric.value,
      },
    ];

    const exitsData = [
      { title: "Discharges On Time", value: dischargesMetric.value },
      {
        title: "Discharges Late",
        value: dischargesMetric.total - dischargesMetric.value,
      },
    ];

    const summaryTotal = intakesMetric.total + dischargesMetric.total;
    const summaryCount = intakesMetric.value + dischargesMetric.value;
    const summaryValue =
      summaryTotal === 0
        ? "N/A"
        : `${((summaryCount * 100) / summaryTotal).toFixed(0)}%`;

    return (
      <PieChart>
        <Pie
          data={intakesData}
          dataKey="value"
          nameKey="title"
          cx="50%"
          cy="50%"
          innerRadius={"75%"}
          outerRadius={"90%"}
        >
          <Cell key={"Intakes On Time"} fill={ColorsEnum.Blue} />
          <Cell key={"Intakes Late"} fill={ColorsEnum.DarkGrey} />
          <Label
            position={"center"}
            value={`${summaryValue}`}
            fontSize={36}
            transform={"translate(0 -8)"}
          />
          <Label
            position={"center"}
            value={`entries on time`}
            fontSize={14}
            transform={"translate(0 22)"}
          />
        </Pie>

        <Pie
          data={exitsData}
          dataKey="value"
          nameKey="title"
          cx="50%"
          cy="50%"
          innerRadius={"55%"}
          outerRadius={"70%"}
        >
          <Cell key={"Discharges On Time"} fill={ColorsEnum.InfoBlue} />
          <Cell key={"Discharges Late"} fill={ColorsEnum.DarkGrey} />
          {intakesMetric.total === 0 && (
            <Fragment>
              <Label
                position={"center"}
                value={`${summaryValue}`}
                fontSize={36}
                transform={"translate(0 -8)"}
              />
              <Label
                position={"center"}
                value={`entries on time`}
                fontSize={14}
                transform={"translate(0 22)"}
              />
            </Fragment>
          )}
        </Pie>
        <Tooltip />
      </PieChart>
    );
  };

  const renderLegend = () => {
    if (isLoading || !intakesMetric || !dischargesMetric)
      return (
        <>
          <LoadingLegendPlaceholder width={220} />
          <LoadingLegendPlaceholder />
        </>
      );

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "90%",
              width: 16,
              backgroundColor: ColorsEnum.Blue,
              marginRight: 4,
            }}
          >
            &nbsp;
          </div>
          <RouteableStyledLink
            text={"Back to Dashboard"}
            pathname={`/dashboards/data/${intakesMetric.linkId}`}
          >
            {intakesMetric.total > 0 && (
              <Typography variant={"subtitle2"}>
                Intakes: {intakesMetric.value} of {intakesMetric.total} (
                {((intakesMetric.value * 100) / intakesMetric.total).toFixed(2)}
                %) entered within 3 business days
              </Typography>
            )}
            {intakesMetric.total === 0 && (
              <Typography variant={"subtitle2"}>
                Intakes: No data available.
              </Typography>
            )}
          </RouteableStyledLink>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "90%",
              width: 16,
              backgroundColor: ColorsEnum.InfoBlue,
              marginRight: 4,
            }}
          >
            &nbsp;
          </div>
          <RouteableStyledLink
            text={"Back to Dashboard"}
            pathname={`/dashboards/data/${dischargesMetric.linkId}`}
          >
            {dischargesMetric.total > 0 && (
              <Typography variant={"subtitle2"}>
                Discharges: {dischargesMetric.value} of {dischargesMetric.total}{" "}
                (
                {(
                  (dischargesMetric.value * 100) /
                  dischargesMetric.total
                ).toFixed(2)}
                %) entered within 3 business days
              </Typography>
            )}
            {dischargesMetric.total === 0 && (
              <Typography variant={"subtitle2"}>
                Discharges: No data available.
              </Typography>
            )}
          </RouteableStyledLink>
        </div>
      </>
    );
  };

  return (
    <Box
      padding="16px"
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
      height={450}
      width={300}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"baseline"}
        style={{ width: "100%" }}
      >
        {isLoading ? (
          <>
            <Typography variant={"body1"}>
              <Skeleton animation="wave" width={150} />
            </Typography>
          </>
        ) : (
          <>
            <Typography variant={"body1"}>Data Entry</Typography>
            <Typography
              variant={"subtitle2"}
              style={{ color: "gray", marginLeft: 4 }}
            >
              within 3 business days
            </Typography>
          </>
        )}
      </Box>
      <ResponsiveContainer width={"100%"} height={"70%"}>
        {renderPieChart()}
      </ResponsiveContainer>
      <Box display={"flex"} flexDirection={"column"} style={{ width: "100%" }}>
        {renderLegend()}
      </Box>
    </Box>
  );
};
