import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import NameLabel from "../../../app/components/labels/NameLabel";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { SnapGroupCaseSummaryDto } from "../dtos/SnapGroupCaseSummaryDto";

interface Props {
  onConfirm: () => Promise<void>;
  requestState: RequestStateDto;
  caseDto?: SnapGroupCaseSummaryDto;
}

export const confirmSnapCaseRemovalDialogId =
  "remove-snap-case-confirmation-dialog";
const ConfirmSnapCaseRemovalDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  caseDto,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmSnapCaseRemovalDialogId}
      isLoading={isLoading}
      confirmButtonText="Remove Youth From SNAP Group"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to remove{" "}
        <NameLabel component="span" nameDto={caseDto?.youth} /> from this SNAP
        group? <br />
        <br /> <strong>This action may result in invoice corrections.</strong>
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmSnapCaseRemovalDialog;
