import { Box, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import EditButton from "../../../app/components/buttons/EditButton";
import InfoTooltip from "../../../app/components/InfoTooltip";
import DateLabel from "../../../app/components/labels/DateLabel";
import { TabProps } from "../../../app/components/TabContainer";
import { ContentSection } from "../../../service-planning/components/ContentSection";
import { gradeLevelTextMap } from "../enums/GradeLevelEnum";
import { useSnapInSchoolsDetailPageContext } from "../pages/SnapInSchoolCyclesDetailPage";
import KeyValueDetails, {
  RowProps,
} from "../../../app/components/KeyValueDetails";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import DeleteAccessLevelWrapper from "../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
export const snapInSchoolsCycleDetailTabName = "snap-in-schools-cycle-details";

const FidelityAdherenceChecklistSection = () => {
  const context = useSnapInSchoolsDetailPageContext();
  const {
    snapCycle: {
      snapCycle,
      handleUpsertFidelityAdherenceChecklistClicked,
      handleDeleteFidelityAdherenceChecklistClicked,
    },
  } = context;

  const rows: RowProps[] = [
    {
      key: "completedOn",
      label: "Completed On",
      value: (
        <DateLabel date={snapCycle?.fidelityAdherenceChecklist?.completedOn} />
      ),
    },
    {
      key: "score",
      label: "Score",
      value: snapCycle?.fidelityAdherenceChecklist?.score,
    },
  ];

  const label = `Fidelity Adherence Checklist`;

  const content = (
    <Box position="relative" width="100%">
      {snapCycle?.fidelityAdherenceChecklist ? (
        <div>
          <KeyValueDetails lgLabelSize={4} mdLabelSize={4} rows={rows} />
        </div>
      ) : (
        <Typography>Fidelity Adherence Checklist Not Completed.</Typography>
      )}

      <Box top="0" right="0" position="absolute">
        <WriteAccessLevelWrapper
          name={PermissionResourceNameEnum.SnapInSchools}
        >
          <EditButton
            text={snapCycle?.fidelityAdherenceChecklist ? "Edit" : "Add"}
            onClick={handleUpsertFidelityAdherenceChecklistClicked}
          />
        </WriteAccessLevelWrapper>

        {snapCycle?.fidelityAdherenceChecklist && (
          <DeleteAccessLevelWrapper
            name={PermissionResourceNameEnum.SnapInSchools}
          >
            <DeleteButton
              onClick={handleDeleteFidelityAdherenceChecklistClicked}
            />
          </DeleteAccessLevelWrapper>
        )}
      </Box>
    </Box>
  );

  return <ContentSection label={label} content={content} />;
};

const OverviewSection = () => {
  const context = useSnapInSchoolsDetailPageContext();
  const {
    snapCycle: {
      snapCycle,
      getSnapCycleRequestState: { isLoading },
      handleEditSnapInSchoolsCycleClicked,
    },
  } = context;

  const rows: RowProps[] = [
    {
      key: "provider",
      label: "Provider",
      value: snapCycle?.provider.name,
    },
    {
      key: "program",
      label: "Program",
      value: snapCycle?.program.name,
    },
    {
      key: "startDate",
      label: "Group Start Date",
      value: <DateLabel date={snapCycle?.startedOn} />,
    },
    {
      key: "grades",
      label: "Grade(s)",
      value: snapCycle?.grades
        .map((grade) => gradeLevelTextMap[grade])
        .join(", "),
    },
    {
      key: "location",
      label: "Location",
      value: snapCycle?.location,
    },
    {
      key: "teacher",
      label: (
        <Box display="inline-flex" component="span">
          Teacher/Supervisory Adult Name{" "}
          <InfoTooltip
            style={{ marginLeft: "4px" }}
            title={<Typography>This is not the SNAP Facilitator.</Typography>}
          ></InfoTooltip>
        </Box>
      ),
      value: snapCycle?.supervisorName,
    },
    {
      key: "classroomSize",
      label: "Classroom Size",
      value: snapCycle?.classroomSize,
    },
  ];

  const label = `Overview`;

  const content = (
    <Box position="relative" width="100%">
      {!isLoading ? (
        <KeyValueDetails lgLabelSize={4} mdLabelSize={5} rows={rows} />
      ) : (
        <Typography>Loading</Typography>
      )}

      <Box top="0" right="0" position="absolute">
        <WriteAccessLevelWrapper
          name={PermissionResourceNameEnum.SnapInSchools}
        >
          <EditButton
            title="Edit SNAP In Schools Cycle"
            onClick={handleEditSnapInSchoolsCycleClicked}
          />
        </WriteAccessLevelWrapper>
      </Box>
    </Box>
  );

  return <ContentSection label={label} content={content} />;
};

const SnapInSchoolsCycleDetails = () => {
  const context = useSnapInSchoolsDetailPageContext();
  const { snapCycle, getSnapCycleRequestState } = context.snapCycle;

  if (snapCycle === undefined && !getSnapCycleRequestState.isLoading)
    return <Fragment></Fragment>;

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <OverviewSection />
      </Grid>
      <Grid item md={12} xs={12}>
        <FidelityAdherenceChecklistSection />
      </Grid>
    </Grid>
  );
};

export default SnapInSchoolsCycleDetails;

export const createSnapInSchoolsCycleDetailTab = (): TabProps => {
  return {
    value: snapInSchoolsCycleDetailTabName,
    label: "Overview",
    content: <SnapInSchoolsCycleDetails />,
  };
};
