import {useForm} from "react-hook-form";
import {useServicePlanPageContext} from "../ServicePlanPageContext";
import {useRequest} from "../../app/hooks/useRequest";
import {addServicePlanInterventionRequest} from "../ServicePlanRequests";
import {AddServicePlanInterventionDto} from "../dto/AddServicePlanInterventionDto";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useEffect, useState} from "react";
import {ServicePlanGoalDto} from "../dto/ServicePlanDto";

const schema = Yup.object().shape({
  description: Yup.string().required().max(120).label("Description"),
  notes: Yup.string().optional(),
});

export const useAddServicePlanInterventionForm = (goalId: string) => {

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<AddServicePlanInterventionDto>({
    defaultValues: {
      description: "",
      notes: ""
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = () => {
    reset({
      description: "",
      notes: ""
    });
  };

  const { servicePlan, servicePlanUpdated  } = useServicePlanPageContext();

  const [goal, setGoal] = useState<ServicePlanGoalDto>()

  useEffect(() => {
    setGoal(servicePlan.goals.find(g => g.id === goalId))
  }, [goalId, servicePlan.goals])

  const [addIntervention, , addInterventionRequestState] = useRequest<AddServicePlanInterventionDto, void>(
    (dto) => addServicePlanInterventionRequest(servicePlan.id, goalId, dto)
  );

  const handleOnSubmit = handleSubmit(
    async (formData: AddServicePlanInterventionDto) => {
      await addIntervention(formData);
      clearForm();
      await servicePlanUpdated();
    }
  );

  return {
    state: {
      goal,
      addInterventionRequestState,
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };

}
