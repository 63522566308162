          import { Avatar, Box, IconButton, Paper, Typography } from "@material-ui/core";
import React, { Fragment, useRef } from "react";
import DeleteAccessLevelWrapper from "../../../../../app/components/access-wrappers/DeleteAccessLevelWrapper";
import WriteAccessLevelWrapper from "../../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import DeleteButton from "../../../../../app/components/buttons/DeleteButton";
import EditButton from "../../../../../app/components/buttons/EditButton";
import DateLabel from "../../../../../app/components/labels/DateLabel";
import { TabProps } from "../../../../../app/components/TabContainer";
import { PermissionResourceNameEnum } from "../../../../authentication/enums/PermissionResourceNameEnum";
import CaseStaffingFormDialog from "../../../../case-staffing/components/CaseStaffingFormDialog";
import ConfirmDeleteCaseStaffingDialog from "../../../../case-staffing/components/ConfirmDeleteCaseStaffingDialog";
import { CaseStaffingDto } from "../../../../case-staffing/dtos/CaseStaffingDto";
import { useCaseDetailPageContext } from "../../../pages/CaseDetailPage";
import CinsPetitionFormDialog from "../../../../case-staffing/components/CinsPetitionFormDialog";
import GroupIcon from "@material-ui/icons/Group";
import PrintIcon from "@material-ui/icons/Print";
import CinsPetitionPrintView from "../../../../case-staffing/components/CinsPetitionPrintView";
import ReactToPrint from "react-to-print";
import { CaseDetailDto } from "../../../dtos/CaseDetailDto";
import AddButton from "../../../../../app/components/buttons/AddButton";
import ConfirmDeleteCinsPetitionDialog from "../../../../case-staffing/components/ConfirmDeleteCinsPetitionDialog";
import CinsPetitionDetailsDialog from "../../../../case-staffing/components/CinsPetitionDetailsDialog";
export const caseStaffTabName = "case-staffing-section";

interface Props {
  staffing: CaseStaffingDto;
  caseDto?: CaseDetailDto;
  onUpsertPetitionClicked(): void;
  onEditClicked(): void;
  onDeleteClicked(): void;
  onViewCinsPetitionClicked(): void;
}

const CaseStaffingListItem: React.FC<Props> = ({
  staffing,
  caseDto,
  onUpsertPetitionClicked,
  onEditClicked,
  onDeleteClicked,
  onViewCinsPetitionClicked,
}) => {
  const printRef = useRef(null);
  return (
    <Paper variant="outlined" style={{ marginBottom: "12px" }}>
      <Box display="flex" padding="12px" alignItems="center">
        <Avatar style={{ alignSelf: "center" }}>
          <GroupIcon />
        </Avatar>
        <Box marginLeft="12px">
          <Typography>
            <DateLabel date={staffing.staffingOn} />
          </Typography>
        </Box>
        <Box marginLeft="auto" display="flex" alignItems="center">
          {staffing.cinsPetitionInfo && (
            <EditButton
              text={`View Petition`}
              onClick={onViewCinsPetitionClicked}
            />
          )}

          <WriteAccessLevelWrapper
            name={PermissionResourceNameEnum.CaseStaffing}
          >
            {staffing.cinsPetitionInfo == null && (
              <EditButton
                text={`Add Petition`}
                onClick={onUpsertPetitionClicked}
              />
            )}
            <EditButton onClick={onEditClicked} />
          </WriteAccessLevelWrapper>
          <DeleteAccessLevelWrapper
            name={PermissionResourceNameEnum.CaseStaffing}
          >
            <DeleteButton onClick={onDeleteClicked} />
          </DeleteAccessLevelWrapper>
          <ReactToPrint
            content={() => printRef.current}
            trigger={() => (
              <IconButton>
                <PrintIcon />
              </IconButton>
            )}
          />
          <Box display="none">
            <CinsPetitionPrintView
              ref={printRef}
              staffing={staffing}
              caseDto={caseDto}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const CaseStaffingSection = () => {
  const {
    case: { caseResult },
    caseStaffing,
  } = useCaseDetailPageContext();
  const {
    caseStaffings,
    upsertCaseStaffingRequestState,
    deleteCaseStaffingRequestState,
    upsertCinsPetitionRequestState,
    deleteCinsPetitionRequestState,
    selectedCaseStaffing,
    handleUpsertCaseStaffing,
    handleCreateCaseStaffingClicked,
    handleEditCaseStaffingClicked,
    handleDeleteCaseStaffingClicked,
    handleDeleteCaseStaffing,
    handleUpsertCinsPetition,
    handleUpsertCinsPetitionClicked,
    handleDeleteCinsPetitionClicked,
    handleDeleteCinsPetition,
    handleViewCinsPetitionClicked,
  } = caseStaffing;

  const sortedCaseStaffings =
    caseStaffings.sort((a, b) =>
      new Date(a.staffingOn) > new Date(b.staffingOn) ? 1 : -1
    ) || [];



  //#region UI Helpers
  const renderRow = (staffing: CaseStaffingDto) => {
    return (
      <CaseStaffingListItem
        key={staffing.id}
        staffing={staffing}
        caseDto={caseResult}
        onDeleteClicked={() => handleDeleteCaseStaffingClicked(staffing)}
        onEditClicked={() => handleEditCaseStaffingClicked(staffing)}
        onUpsertPetitionClicked={() =>
          handleUpsertCinsPetitionClicked(staffing)
        }
        onViewCinsPetitionClicked={() =>
          handleViewCinsPetitionClicked(staffing)
        }
      />
    );
  };
  //#endregion

  return (
    <Box position={"relative"}>
      <DeleteAccessLevelWrapper name={PermissionResourceNameEnum.CaseStaffing}>
        <ConfirmDeleteCaseStaffingDialog
          onConfirm={handleDeleteCaseStaffing}
          caseStaffing={selectedCaseStaffing}
          requestState={deleteCaseStaffingRequestState}
        />
        <ConfirmDeleteCinsPetitionDialog
          onConfirm={handleDeleteCinsPetition}
          requestState={deleteCinsPetitionRequestState}
          petition={selectedCaseStaffing?.cinsPetitionInfo}
        />
      </DeleteAccessLevelWrapper>

      <CinsPetitionDetailsDialog
        caseStaffing={selectedCaseStaffing}
        onDeleteClicked={() =>
          handleDeleteCinsPetitionClicked(selectedCaseStaffing!)
        }
        onEditClicked={() =>
          handleUpsertCinsPetitionClicked(selectedCaseStaffing!)
        }
      />

      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.CaseStaffing}>
        <CaseStaffingFormDialog
          onSubmit={handleUpsertCaseStaffing}
          requestState={upsertCaseStaffingRequestState}
          caseStaffing={selectedCaseStaffing}
        />
        <CinsPetitionFormDialog
          onSubmit={handleUpsertCinsPetition}
          requestState={upsertCinsPetitionRequestState}
          petition={selectedCaseStaffing?.cinsPetitionInfo}
        />

        <Box marginBottom="8px">
          <AddButton
            text="Add Case Staffing"
            onClick={handleCreateCaseStaffingClicked}
            variant="contained"
            color="primary"
          />
        </Box>
      </WriteAccessLevelWrapper>

      {sortedCaseStaffings.length === 0 && (
        <Typography>No Case Staffings Created</Typography>
      )}

      {sortedCaseStaffings.length > 0 && (
        <Fragment>{sortedCaseStaffings.map(renderRow)}</Fragment>
      )}
    </Box>
  );
};

export default CaseStaffingSection;
export const createCaseStaffingTab = (): TabProps => ({
  label: "Case Staffing",
  value: caseStaffTabName,
  content: <CaseStaffingSection />,
});
