import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledSelect from "../../../../app/components/inputs/ControlledSelect";
import {
  ProviderDto,
  providerToOptionDtos,
} from "../../../provider/dtos/ProviderDto";
import {
  NetmisRoleDto,
  netmisRolesToOptionDtos,
} from "../../../roles/dtos/NetmisRoleDto";
import * as Yup from "yup";

export const providerRoleSchema = Yup.object().shape({
  providerId: Yup.string().label("Provider").required(),
  roleId: Yup.string().label("Role").required(),
});

interface Props {
  control: Control<any>;
  roles: NetmisRoleDto[];
  providers: ProviderDto[];
  disabled: boolean;
  providersAreLoading: boolean;
  rolesAreLoading: boolean;
}

const ProviderRoleFormFields: React.FC<Props> = ({
  control,
  roles,
  providers,
  disabled,
  providersAreLoading,
  rolesAreLoading,
}) => {
  const createProviderOptions = () => {
    const options = providerToOptionDtos(providers);
    return options;
  };

  const createRoleOptions = () => {
    const options = netmisRolesToOptionDtos(roles);
    return options;
  };
  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="providerId"
          label="Provider"
          options={createProviderOptions()}
          disabled={disabled}
          fullWidth
          isLoading={providersAreLoading}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="roleId"
          label="Role"
          options={createRoleOptions()}
          disabled={disabled}
          fullWidth
          isLoading={rolesAreLoading}
        />
      </Grid>
    </Fragment>
  );
};

export default ProviderRoleFormFields;
