import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useGetJjisPrograms } from "../../jjis/hooks/jjisHooks";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { ProgramFundingSourceEnum } from "../../programs/enums/ProgramFundingSourceEnum";
import { programLocationSchema } from "../components/ProgramLocationFormFields";
import { ProgramLocationDto } from "../dtos/ProgramLocationDto";
import {
  createProgramLocationFormDto,
  ProgramLocationFormDto,
} from "../dtos/ProgramLocationFormDto";

export const useProgramLocationForm = (
  onSubmit: (dto: ProgramLocationFormDto) => Promise<any>,
  program: ProgramDto,
  programLocation?: ProgramLocationDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = useForm({
    defaultValues: createProgramLocationFormDto(
      program?.fundingSource || ProgramFundingSourceEnum.Other,
      programLocation
    ),
    resolver: yupResolver(programLocationSchema),
    mode: "all",
  });

  const useExisting = watch("useExisting");
  const [getJjisPrograms, jjisPrograms, getJjisProgramsRequestState] =
    useGetJjisPrograms();

  //#endregion
  const clearForm = () => {
    reset(
      createProgramLocationFormDto(
        program?.fundingSource || ProgramFundingSourceEnum.Other,
        programLocation
      )
    );
  };

  const handleOnSubmit = handleSubmit(async (dto: ProgramLocationFormDto) => {
    dto.judicialCircuitNumber = dto.judicialCircuitNumber
      ? dto.judicialCircuitNumber
      : undefined;
    dto.juvenileJusticeProgramCode = dto.juvenileJusticeProgramCode
      ? dto.juvenileJusticeProgramCode
      : undefined;
    await onSubmit(dto);
    clearForm();
  });

  //#region Effects
  useEffect(() => {
    reset(
      createProgramLocationFormDto(
        program?.fundingSource || ProgramFundingSourceEnum.Other,
        programLocation
      )
    );
  }, [program, programLocation, reset]);

  useEffect(() => {
    getJjisPrograms("");
  }, [getJjisPrograms]);
  //#endregion

  return {
    state: {
      jjisPrograms: jjisPrograms || [],
      getJjisProgramsRequestState,
      useExisting,
    },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
