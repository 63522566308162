import { CaseDetailDto } from "./CaseDetailDto";
import * as Yup from "yup";
import { dateToFormString } from "../../../app/helpers";

export class AddCaseLocationFormDto {
  locationId: string;
  startOn: string;

  constructor(caseDto?: CaseDetailDto) {
    this.locationId = caseDto?.location.id || "";
    this.startOn = dateToFormString(new Date());
  }

  public static getSchema() {
    return Yup.object().shape({
      locationId: Yup.string().label("Location").required(),
      startOn: Yup.string().required("Start On").required(),
    });
  }
}

export class CaseLocationFormDto {
  programId: string;
  locationId: string;
  programLocationId: string;
  startOn: string;
  constructor(caseDto?: CaseDetailDto) {
    this.programId = caseDto?.program.id || "";
    this.locationId = caseDto?.location.id || "";
    this.programLocationId = "";
    this.startOn = dateToFormString(new Date());
  }

  public static getSchema() {
    return Yup.object().shape({
      locationId: Yup.string().label("Location").required(),
      programId: Yup.string().label("Program").required(),
      startOn: Yup.string().required("Start On").required(),
    });
  }
}
