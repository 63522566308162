import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { getProviderId } from "../../features/authentication/state/authenticationActions";
import { ColorsEnum } from "../enums/ColorsEnum";

interface Props extends LinkProps {
  disabled?: boolean;
}

const StyledLink: React.FC<Props> = ({
  children,
  style,
  onClick,
  disabled,
  ...props
}) => {
  const color = disabled ? ColorsEnum.DarkGrey : ColorsEnum.Blue;
  const cursor = disabled ? "not-allowed" : "pointer";

  return (
    <Link
      {...props}
      style={{
        color,
        textDecoration: "none",
        cursor,
        ...style,
      }}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
          return;
        }

        if (e.ctrlKey)
          e.currentTarget.href = `${
            e.currentTarget.href
          }?selectedProviderId=${getProviderId()}`;

        if (onClick) onClick(e);
      }}
    >
      {children}
    </Link>
  );
};

export default StyledLink;
