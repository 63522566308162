import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fnyfsProviderId } from "../../authentication/constants";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { useGetCinsFinsEligibilityCriteria } from "../../cins-fins-eligibility-criteria/hooks/cinsFinsEligibilityCriteriaHooks";
import { useGetProviderStaffMembers } from "../../provider/hooks/providerHooks";
import { ReferralDto } from "../../referrals/dtos/ReferralDto";
import { ReferralSourceEnum } from "../../referrals/enums/ReferralSourceEnum";
import { ReferrerRelationshipEnum } from "../../referrals/enums/ReferrerRelationshipEnum";
import { useGetPresentingProblems } from "../../screening-presenting-problems/hooks/presentingProblemHooks";
import { screeningSchema } from "../../screenings/components/ScreeningFormFields";
import { ScreeningDetailDto } from "../../screenings/dtos/ScreeningDetailDto";
import {
  createScreeningFormDto,
  ScreeningFormDto,
} from "../../screenings/dtos/ScreeningFormDto";
import {
  HeardAboutEnum,
  heardAboutTextMap,
} from "../../screenings/enums/HeardAboutEnum";
import { ScreeningPlannedActionEnum } from "../../screenings/enums/ScreeningPlannedActionEnum";
import { YouthDto } from "../dto/YouthDto";
import { YouthSummaryDto } from "../dto/YouthSummaryDto";
import { useProgramLocationNestedFields } from "./useProgramLocationNestedFields";

export const useYouthScreeningForm = (
  onSubmit: (dto: ScreeningFormDto, createCase: boolean) => Promise<void>,
  youth?: YouthDto | YouthSummaryDto,
  referral?: ReferralDto,
  screening?: ScreeningDetailDto
) => {
  //#region State
  const {
    control,
    formState: { isValid },
    getValues,
    reset,
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: createScreeningFormDto([], youth, referral, screening),
    mode: "all",
    resolver: yupResolver(screeningSchema),
  });
  const [
    getPresentingProblems,
    presentingProblems,
    getPresentingProblemsRequestState,
    clearGetPresentingProblemsError,
  ] = useGetPresentingProblems();

  const [
    getCinsFinsEligibilityCriteria,
    cinsFinsEligibilityCriteria,
    getCinsFinsEligibilityCriteriaRequestState,
    clearGetCinsFinsEligibilityCriteriaError,
  ] = useGetCinsFinsEligibilityCriteria();

  const {
    isCinsFinsEligible,
    plannedAction,
    eligibleCriteriaOptions,
    referredBy,
    programId,
    locationId,
    createWaitlist,
    livingSituation,
    screeningAt,
  } = watch();

  const [requiresEligibleOption, setRequireEligibleOption] = useState(false);
  const [requiresIneligibleOption, setRequireIneligibleOption] =
    useState(false);

  const [
    getProviderStaffMembers,
    staffMembers,
    getProviderStaffMembersRequestState,
    clearGetProviderStaffMemberErrors,
  ] = useGetProviderStaffMembers();

  const {
    state: {
      locations,
      providerPrograms,
      getProviderLocationsRequestState,
      getProviderProgramsRequestState,
    },
    // handlers: { handleUpdateLocations },
    getters: { getProgramLocationId },
  } = useProgramLocationNestedFields(true, screeningAt, programId);

  // Need to typecast as any, or the transpiler will choke on itself, trying to figure out the type
  const setAnyValue = setValue as any;
  const clearErrorsAny = clearErrors as any;
  const triggerAny = trigger as any;
  //#endregion

  //#region Handlers
  const clearForm = () => {
    clearGetPresentingProblemsError();
    clearGetCinsFinsEligibilityCriteriaError();
    clearGetProviderStaffMemberErrors();
    reset(
      createScreeningFormDto(
        cinsFinsEligibilityCriteria || [],
        youth,
        referral,
        screening
      )
    );
  };

  const mapScreeningPresentingProblems = (problems: string[]) => {
    return problems.map((problem) => ({
      problem,
      presentingProblemId: presentingProblems?.find(
        (x) => x.problem === problem
      )?.id,
    }));
  };

  const handleSubmit = async (createCase: boolean = false) => {
    const dto = getValues();
    dto.screeningPresentingProblems = mapScreeningPresentingProblems(
      dto.screeningPresentingProblemsText
    );

    dto.eligibleCriteria = Object.entries(dto.eligibleCriteriaOptions)
      .filter(([_, value]) => value)
      .map(([key]) => {
        return key;
      });

    if (dto.heardAboutUsOption !== HeardAboutEnum.Other)
      dto.heardAboutUs = heardAboutTextMap[dto.heardAboutUsOption];

    await onSubmit(dto, createCase);
  };

  const handleSubmitAndCreateCase = async () => {
    handleSubmit(true);
  };

  //#endregion

  //#region Effects
  useEffect(() => {
    if (getProviderId() === fnyfsProviderId) return;
    getPresentingProblems("");
  }, [getPresentingProblems]);

  useEffect(() => {
    if (getProviderId() === fnyfsProviderId) return;

    getCinsFinsEligibilityCriteria("");
  }, [getCinsFinsEligibilityCriteria]);

  useEffect(() => {
    reset(
      createScreeningFormDto(
        cinsFinsEligibilityCriteria || [],
        youth,
        referral,
        screening
      )
    );
  }, [cinsFinsEligibilityCriteria, youth, reset, referral, screening]);

  useEffect(() => {
    if (plannedAction !== ScreeningPlannedActionEnum.TurnedAway) {
      clearErrorsAny("turnedAway");
      setAnyValue("turnedAway", "");
    }

    if (plannedAction !== ScreeningPlannedActionEnum.Waitlist) {
      setAnyValue("createWaitlist", false);
    }
  }, [plannedAction, setAnyValue, clearErrorsAny]);

  // For some reason cinsFinsEligibilityCriteria wasn't triggering this effect anymore
  // Hacky workaround
  const eligibilityTrigger = Object.values(eligibleCriteriaOptions);

  useEffect(() => {
    if (cinsFinsEligibilityCriteria && isCinsFinsEligible) {
      const getEligibleCriteriaCount = (isEligible: boolean) => {
        const criteriaIds = cinsFinsEligibilityCriteria
          .filter((x) => x.isEligible === isEligible)
          .map((x) => x.id);
        return Object.entries(eligibleCriteriaOptions).filter(
          ([key, value]) => criteriaIds.includes(key) && value
        ).length;
      };

      if (isCinsFinsEligible === "true") {
        setRequireIneligibleOption(false);
        setRequireEligibleOption(getEligibleCriteriaCount(true) === 0);
      } else if (isCinsFinsEligible === "false") {
        setRequireIneligibleOption(getEligibleCriteriaCount(false) === 0);
        setRequireEligibleOption(false);
      }
    }
  }, [
    eligibilityTrigger,
    cinsFinsEligibilityCriteria,
    eligibleCriteriaOptions,
    setRequireIneligibleOption,
    setRequireEligibleOption,
    isCinsFinsEligible,
  ]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId) {
      getProviderStaffMembers(providerId);
    }
  }, [getProviderStaffMembers]);

  useEffect(() => {
    switch (referredBy.source) {
      case ReferralSourceEnum.Self:
        setAnyValue("referredBy.firstName", `${youth?.firstName}`);
        setAnyValue("referredBy.lastName", `${youth?.lastName}`);
        setAnyValue("referredBy.relationship", ReferrerRelationshipEnum.Self);
        break;
      case ReferralSourceEnum.Dcf:
      case ReferralSourceEnum.Djj:
      case ReferralSourceEnum.LawEnforcement:
      case ReferralSourceEnum.Court:
        setAnyValue("heardAboutUsOption", HeardAboutEnum.ReferralSource);
        break;
    }
  }, [referredBy.source, youth, setAnyValue]);

  //#region Effects
  // useEffect(() => {
  //   handleUpdateLocations(programId);
  // }, [programId, handleUpdateLocations]);
  //
  // useEffect(() => {
  //   setAnyValue("locationId", locations.length === 1 ? locations[0].id : "", {
  //     shouldValidate: locations.length === 1,
  //   });
  // }, [locations, setAnyValue]);

  useEffect(() => {
    setAnyValue(
      "programLocationId",
      getProgramLocationId(programId, locationId)
    );
  }, [programId, locationId, setAnyValue, getProgramLocationId]);

  useEffect(() => {
    if (createWaitlist === false) setAnyValue("programId", "");
  }, [setAnyValue, createWaitlist]);

  useEffect(() => {
    triggerAny("zipCode");
  }, [livingSituation, triggerAny]);
  //#endregion

  return {
    state: {
      cinsFinsEligibilityCriteria: cinsFinsEligibilityCriteria || [],
      presentingProblems: presentingProblems || [],
      staffMembers: staffMembers || [],
      getPresentingProblemsRequestState,
      getCinsFinsEligibilityCriteriaRequestState,
      requiresEligibleOption,
      requiresIneligibleOption,
      getProviderStaffMembersRequestState,
      locations,
      providerPrograms: providerPrograms || [],
      getProviderLocationsRequestState,
      getProviderProgramsRequestState,
      createWaitlist,
    },
    form: {
      control,
      isValid: isValid && !requiresEligibleOption && !requiresIneligibleOption,
      clearForm,
    },
    handlers: { handleSubmit, handleSubmitAndCreateCase },
  };
};
