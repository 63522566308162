import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { ColorsEnum } from "../../enums/ColorsEnum";

interface Props extends ButtonProps {
  text?: string;
}

const EditButton: React.FC<Props> = ({ style, text = "EDIT", ...props }) => {
  const styleProps = { ...style }
  if(!props.disabled) {
    styleProps.color = ColorsEnum.Blue
  }

  return (
    <Button {...props} style={{ ...styleProps }}>
      {text}
    </Button>
  );
};

export default EditButton;
