import { ParsedQuery } from "query-string";
import { PaginationSearchParamsDto } from "../../../app/dtos/PaginationSearchParamsDto";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import { QueryStringHelpers } from "../../../app/helpers";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { ReferralStatusEnum } from "../enums/ReferralStatusEnum";
import { ReferralFilterFormDto } from "./ReferralFilterFormDto";

export class ReferralSearchParamsDto implements PaginationSearchParamsDto {
  providerId?: string;
  status?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionEnum;

  constructor({
    query,
    form,
    previous,
  }: {
    query?: ParsedQuery<string>;
    form?: ReferralFilterFormDto;
    previous?: ReferralSearchParamsDto;
  }) {
    this.providerId =
      (query?.providerId as string) || getProviderId() || undefined;
    this.status = (query?.status as string) || ReferralStatusEnum.Pending;

    if (form) {
      this.status = form.status;
      this.providerId = form.providerId;
    }
    this.sortBy =
      previous?.sortBy || (query?.sortBy as string) || "referralNumber";
    this.sortDirection =
      previous?.sortDirection || QueryStringHelpers.getSortDirection(query);
    this.page = +(query?.page as string) || 0;
    this.pageSize = previous?.pageSize || +(query?.pageSize as string) || 10;
  }

  public static toQueryString(dto: ReferralSearchParamsDto) {
    return QueryStringHelpers.toQueryString(dto);
  }
}
