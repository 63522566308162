import React, { createContext, useContext } from "react";
import FilterButton from "../../../app/components/buttons/FilterButton";
import Header from "../../../app/components/Header";
import RequestErrorAlert from "../../../app/components/RequestErrorAlert";
import DjjEntryFilter from "../components/DjjEntryFilter";
import DjjEntryFilterList from "../components/DjjEntryFilterList";
import DjjEntryList from "../components/DjjEntryList";
import DjjEntryListPageModals from "../components/DjjEntryListPageModals";
import {
  DjjEntryListPageParams,
  useDjjEntryListPage,
} from "../hooks/useDjjEntryListPage";

const DjjEntryListPageContext = createContext<DjjEntryListPageParams>(
  undefined!
);

export const useDjjEntryListPageContext = () =>
  useContext(DjjEntryListPageContext);

const DjjEntryListPage = () => {
  const context = useDjjEntryListPage();
  const {
    getDjjEntriesRequestState,
    params,
    result,
    handlePageChange,
    handleSort,
    providers,
    getProvidersRequestState,
    filterAnchorEl,
    handleCloseFilter,
    handleOpenFilterClicked,
    handleSearch,
  } = context;

  return (
    <DjjEntryListPageContext.Provider value={context}>
      <DjjEntryListPageModals />
      <RequestErrorAlert
        requests={[getDjjEntriesRequestState, getProvidersRequestState]}
      />
      <DjjEntryFilter
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilter}
        params={params}
        onSearch={handleSearch}
        providers={providers}
        providersAreLoading={getProvidersRequestState.isLoading}
      />

      <Header
        title="DJJ Related Data"
        actions={
          <FilterButton
            title="Filter List"
            onClick={handleOpenFilterClicked}
            disabled={getProvidersRequestState.isLoading}
          />
        }
        secondaryActions={
          <>
            <DjjEntryFilterList
              params={params}
              onSearch={handleSearch}
              isLoading={getDjjEntriesRequestState.isLoading}
              providers={providers}
            />
          </>
        }
      />

      <DjjEntryList
        onPageChange={handlePageChange}
        isLoading={getDjjEntriesRequestState.isLoading}
        results={result}
        params={params}
        onSort={handleSort}
      />
    </DjjEntryListPageContext.Provider>
  );
};

export default DjjEntryListPage;
