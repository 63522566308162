import { YouthContactDetailDto } from "./YouthContactDetailDto";

export interface YouthContactFormDto {
  firstName: string;
  lastName: string;
  relationship?: string;
  isLegalGuardianOrPowerOfAttorney: boolean;
  isEmergencyContact: boolean;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  phoneNumber?: string;
  notes: string;
  email?: string;
  isLegacy?: boolean;
}

export const createYouthContactFormDto = (
  contact?: YouthContactDetailDto
): YouthContactFormDto => {
  return {
    firstName: contact?.firstName || "",
    lastName: contact?.lastName || "",
    relationship: contact?.relationship || "",
    isLegalGuardianOrPowerOfAttorney:
      contact?.isLegalGuardianOrPowerOfAttorney || false,
    isEmergencyContact: contact?.isEmergencyContact || false,
    address: contact?.address || "",
    city: contact?.city || "",
    state: contact?.state || "FL",
    zipCode: contact?.zipCode || "",
    county: contact?.county || "",
    phoneNumber: contact?.phoneNumber || "",
    notes: contact?.notes || "",
    email: contact?.email || "",
    isLegacy: contact?.isLegacy || false,
  };
};
