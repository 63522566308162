import {
  Avatar,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../../authentication/enums/PermissionResourceNameEnum";
import EditButton from "../../../../app/components/buttons/EditButton";
import { WaitlistScreeningDto } from "../../../waitlists/dtos/WaitlistScreeningDto";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { WaitlistStatusEnum } from "../../../waitlists/enums/WaitlistStatusEnum";
import WaitlistStatusLabel from "../../../waitlists/components/WaitlistStatusLabel";
interface Props {
  waitlist: WaitlistScreeningDto;
  onRemoveWaitlistClick(): void;
  onStartCaseClick(): void;
}

const WaitlistScreeningListItem: React.FC<Props> = ({
  waitlist,
  onRemoveWaitlistClick,
  onStartCaseClick,
}) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <Avatar>
          <HourglassEmptyIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        secondary={
          <Fragment>
            <Box display={"flex"} component={"span"}>
              <Box component={"span"}>
                <Typography color="textSecondary" component="span">
                  Program: {waitlist.program.name}
                </Typography>
                <br />
                <Typography color="textSecondary" component="span">
                  Location: {waitlist.location.name}
                </Typography>
              </Box>
              <Box component={"span"} marginLeft="auto" width="175px">
                <WaitlistStatusLabel waitlist={waitlist} />
                {waitlist.status === WaitlistStatusEnum.Pending && (
                  <Box display="flex" component="span">
                    <WriteAccessLevelWrapper
                      name={PermissionResourceNameEnum.Case}
                    >
                      <EditButton
                        onClick={onStartCaseClick}
                        text="Start Case"
                      />
                    </WriteAccessLevelWrapper>
                    <WriteAccessLevelWrapper
                      name={PermissionResourceNameEnum.Waitlist}
                    >
                      <EditButton
                        onClick={onRemoveWaitlistClick}
                        text="Remove"
                      />
                    </WriteAccessLevelWrapper>
                  </Box>
                )}
              </Box>
            </Box>
          </Fragment>
        }
      />
    </ListItem>
  );
};

export default WaitlistScreeningListItem;
