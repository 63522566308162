import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment } from "react";

export interface Props {
  count: number;
}

export const LoadingListItem = () => {
  return (
    <ListItem style={{ padding: "14px" }}>
      <ListItemIcon>
        <Skeleton animation="pulse" variant="circle" width={44} height={44} />
      </ListItemIcon>
      <ListItemText
        primary={<Skeleton animation="pulse" variant="text" width="160px" />}
        secondary={<Skeleton animation="pulse" variant="text" width="120px" />}
      ></ListItemText>
    </ListItem>
  );
};

const LoadingList: React.FC<Props> = ({ count }) => {
  return (
    <Paper variant="outlined">
      <List style={{ padding: "0px" }}>
        {Array.from({ length: count }, (_, i) => i + 1).map((i) => (
          <Fragment key={`loading-${i}`}>
            <LoadingListItem />
            <Divider />
          </Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default LoadingList;
