import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectPermissions,
  selectSelectedProviderId,
  selectUser,
} from "../state/authenticationSelectors";
import { setProvider } from "../state/authenticationSlice";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { setProviderId } from "../state/authenticationActions";
import { useHistory } from "react-router-dom";

const SelectProviderDialog = () => {
  //#region State
  const user = useAppSelector(selectUser);
  const selectedProviderId = useAppSelector(selectSelectedProviderId);
  const permissions = useAppSelector(selectPermissions);
  const dispatch = useAppDispatch();
  const history = useHistory();
  //#endregion

  return (
    <Dialog
      open={
        user !== undefined &&
        user.isFloridaNetworkUser === false &&
        selectedProviderId == null
      }
      fullWidth
    >
      <DialogTitle>Select Provider</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography>
              You have permissions for multiple providers. Please select the
              provider you would like to act as.
            </Typography>
          </Grid>
          {Object.entries(permissions).map(([value, permission]) => (
            <Grid item md={12} xs={12} key={value}>
              <Button
                onClick={() => {
                  setProviderId(value);
                  dispatch(setProvider(value));
                  history.push("/");
                }}
                variant="outlined"
                endIcon={<ExitToAppIcon />}
                fullWidth
              >
                <Box width="100%" textAlign="left">
                  {permission.name}
                </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SelectProviderDialog;
