import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum CaseSessionTypeEnum {
  Individual = "Individual",
  Group = "Group",
}

export const caseSessionTypeTextMap: { [key: string]: string } = {
  [CaseSessionTypeEnum.Individual]: "Individual",
  [CaseSessionTypeEnum.Group]: "Group",
};

export const caseSessionTypeOptions: SelectOption[] = [
  { value: "", label: "Select Session Type" },
  ...Object.keys(CaseSessionTypeEnum)
    .map((x) => ({
      value: x,
      label: caseSessionTypeTextMap[x],
    })),
];
