import {
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import { ReferralDto } from "../dtos/ReferralDto";
import { ReferralRouteDto } from "../dtos/ReferralRouteDto";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import HistoryIcon from "@material-ui/icons/History";
import InfoTooltip from "../../../app/components/InfoTooltip";
import { fnyfsName } from "../../authentication/constants";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
interface Props {
  referral: ReferralDto;
}

const ReferralHistory: React.FC<Props> = ({ referral }) => {
  const sortedHistory = () => {
    let current = referral.referralRoutes.find(
      (x) => x.forwardReferralRouteId === undefined
    );

    const routes: ReferralRouteDto[] = [];

    while (current !== undefined) {
      routes.push(current);

      current = referral.referralRoutes.find(
        // eslint-disable-next-line no-loop-func
        (x) => x.forwardReferralRouteId === current!.id
      );
    }

    return routes;
  };

  const getAssignedToName = (assignedTo?: ReferralRouteDto) => {
    if (assignedTo) {
      if (assignedTo.provider) return assignedTo.provider.name;

      return fnyfsName;
    }
    return "";
  };

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <IconButton {...bindTrigger(popupState)} title="History" size="small">
            <HistoryIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            elevation={3}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Assigned Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedHistory().map((x) => {
                  const isCurrent = x.forwardReferralRouteId === undefined;

                  const rowStyle = isCurrent
                    ? { backgroundColor: ColorsEnum.RowGrey }
                    : {};

                  const cellStyle: React.CSSProperties = isCurrent
                    ? { fontWeight: "bold" }
                    : {};
                  return (
                    <TableRow key={x.id} style={rowStyle}>
                      <TableCell style={cellStyle}>
                        {" "}
                        {isCurrent && (
                          <InfoTooltip
                            title={
                              <Typography>Currently Assigned To</Typography>
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell style={cellStyle}>
                        {getAssignedToName(x)}
                      </TableCell>
                      <TableCell style={cellStyle}>
                        <DateTimeLabel date={x.createdAt} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Popover>
        </Fragment>
      )}
    </PopupState>
  );
};

export default ReferralHistory;
