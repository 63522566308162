import { Box, Tooltip, Typography } from "@material-ui/core";
import { Fragment } from "react";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { AssessmentDto } from "../dtos/AssessmentDto";
import AssessmentReferenceNumberLabel from "./AssessmentReferenceNumberLabel";

interface Props {
  assessment?: AssessmentDto;
}

const AssessmentAmendmentBadge: React.FC<Props> = ({ assessment }) => {
  const color = `${ColorsEnum.Red}99`;

  if (assessment == null) return <Fragment></Fragment>;

  return (
    <Tooltip
      title={
        <Typography>
          Amendment of Assessment{" "}
          <AssessmentReferenceNumberLabel
            assessment={assessment}
            component="span"
          />
        </Typography>
      }
    >
      <Box
        width="25px"
        height="25px"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        border={`1px solid ${color}`}
        style={{ cursor: "pointer" }}
      >
        <Typography component="span" style={{ color }}>
          A
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default AssessmentAmendmentBadge;
