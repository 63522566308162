import {assessmentAgent, coreAgent} from "../../app/api/agent";
import { AssessmentFormDto } from "./dtos/AssessmentFormDto";
import {
  AssessmentOverrideReasonDto,
  AssessmentOverrideReasonFormDto,
} from "./dtos/AssessmentOverrideReasonDto";
import { AssessmentTemplateSummaryDto } from "./dtos/AssessmentTemplateSummaryDto";
import { AssessmentTemplateTagEnum } from "./enums/AssessmentTemplateTagEnum";
import {NirvanaParentAssessmentIdRequestDto, convertToQueryString} from "./dtos/NirvanaParentAssessmentIdRequestDto";

export const createAssessmentRequest = (dto: AssessmentFormDto) =>
  assessmentAgent.postRequest<{ id: string }>(`assessment/start`, dto);

export const verifyAssessmentRequest = (assessmentId: string) =>
  coreAgent.getRequest<boolean>(`/assessments/verify/${assessmentId}`)


export const getAssessmentTemplatesRequest = (tag: AssessmentTemplateTagEnum) =>
  assessmentAgent.getRequest<AssessmentTemplateSummaryDto[]>(
    `assessmentTemplate?tag=${tag}`
  );

export const createAssessmentOverrideReasonRequest = (
  dto: AssessmentOverrideReasonFormDto
) =>
  coreAgent.postRequest<AssessmentOverrideReasonDto>(
    `/assessments/override`,
    dto
  );

export const getNirvanaAssessmentComparisonReportRequest = (caseId: string) =>
  coreAgent.getRequest<Blob>(`/reports/nirvanaComparisonReport/${caseId}`, {
    responseType: "blob",
  }, false);

export const getNirvanaPrepopulationIdRequest = (dto: NirvanaParentAssessmentIdRequestDto) =>
  coreAgent.getRequest<string>(`/assessments/nirvana_parent?${convertToQueryString(dto)}`, { responseType: 'string' });
