import React, { useCallback } from "react";
import FilterList from "../../../app/components/FilterList";
import { NetmisRoleDto } from "../../roles/dtos/NetmisRoleDto";
import {
  UserFilterFormDto,
  UserSearchParamsDto,
} from "../dtos/UserSearchParamsDto";

interface Props {
  params: UserSearchParamsDto;
  isLoading: boolean;
  roles: NetmisRoleDto[];
  onSearch(dto: UserSearchParamsDto): void;
}

const UserFilterList: React.FC<Props> = ({
  params,
  isLoading,
  roles,
  onSearch,
}) => {
  const handleClearParams = () => {
    return new UserSearchParamsDto({
      previous: params,
      form: new UserFilterFormDto(),
    });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "email":
          return `Email: ${value}`;
        case "firstName":
          return `First Name: ${value}`;
        case "lastName":
          return `Last Name: ${value}`;
        case "roleId":
          return `Role: ${roles.find((x) => x.id === value)?.name}`;
        case "isFloridaNetworkUser":
          return `FN Users Only: ${value}`;
        case "active":
          return `Active: ${value === "true" ? "Yes" : "No"}`;
      }
      return "";
    },
    [roles]
  );

  const ordering = [
    "firstName",
    "lastName",
    "email",
    "roleId",
    "active",
    "isFloridaNetworkUser",
  ];

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default UserFilterList;
