import { Box, FormControlLabel, Switch, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { TabProps } from "../../../app/components/TabContainer";
import WaitlistScreeningList from "./waitlist-screening-list/WaitlistScreeningList";
import { WaitlistScreeningDto } from "../../waitlists/dtos/WaitlistScreeningDto";
import { ScreeningDetailDto } from "../dtos/ScreeningDetailDto";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import AddButton from "../../../app/components/buttons/AddButton";
import InfoTooltip from "../../../app/components/InfoTooltip";

interface Props {
  screening?: ScreeningDetailDto;
  isLoading: boolean;
  allowWaitlistCreation: boolean;
  onRemoveWaitlistClick(dto: WaitlistScreeningDto): void;
  onCreateWaitlistClick(): void;
}

export const screeningWaitlistTabName = "screening-waitlist-tab";

const ScreeningWaitlistTab: React.FC<Props> = ({
  screening,
  isLoading,
  allowWaitlistCreation,
  onRemoveWaitlistClick,
  onCreateWaitlistClick,
}) => {
  //#region State
  const [showRemoved, setShowRemoved] = useState(false);
  //#endregion

  return (
    <Fragment>
      <Box display="flex">
        <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Waitlist}>
          {allowWaitlistCreation ? (
            <AddButton
              text="Add to Waitlist"
              variant="contained"
              color="primary"
              onClick={onCreateWaitlistClick}
              disabled={isLoading}
            />
          ) : (
            <InfoTooltip
              title={
                <Typography>
                  Cannot create Waitlist from a Screening that is more than 60
                  days old.
                </Typography>
              }
            />
          )}
        </WriteAccessLevelWrapper>
        <Box marginLeft="auto">
          <FormControlLabel
            control={
              <Switch
                checked={showRemoved}
                disabled={isLoading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setShowRemoved(e.currentTarget.checked)
                }
                color="primary"
              />
            }
            label="Show Removed Waitlists"
          />
        </Box>
      </Box>
      <Box marginTop="12px">
        <WaitlistScreeningList
          screening={screening}
          onRemoveWaitlistClick={onRemoveWaitlistClick}
          isLoading={isLoading}
          showRemoved={showRemoved}
        />
      </Box>
    </Fragment>
  );
};

export default ScreeningWaitlistTab;

export const createScreeningWaitlistTab = (props: Props): TabProps => ({
  label: "Waitlists",
  value: screeningWaitlistTabName,
  content: <ScreeningWaitlistTab {...props} />,
});
