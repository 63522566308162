import { Grid } from "@material-ui/core";
import { Fragment } from "react";
import { Control } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { CaseSessionFormDto } from "../dtos/CaseSessionFormDto";
import { caseSessionTypeOptions } from "../enums/CaseSessionTypeEnum";

interface Props {
    control: Control<CaseSessionFormDto>;
    disabled: boolean;
}

const CaseSessionFormFields: React.FC<Props> = ({
    control,
    disabled
}) => {
    return (
        <Fragment>
            <Grid item md={12} xs={12}>
                <ControlledInput
                    control={control}
                    name="sessionHeldOn"
                    label="Session Held On"
                    type="date"
                    disabled={disabled}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <ControlledSelect
                    control={control}
                    name="sessionType"
                    label="Session Type"
                    options={caseSessionTypeOptions}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Fragment>
    );
}

export default CaseSessionFormFields;