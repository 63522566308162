import { Box, Collapse, Divider, Grid, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { Control } from "react-hook-form";
import ControlledSelect, { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import * as Yup from "yup";
import { ProgramTypeDeliverableDto } from "../../program-type-deliverables/dtos/ProgramTypeDeliverableDto";
import DeliverableTypeLabel from "../../program-type-deliverables/components/DeliverableTypeLabel";
import ExpandButton from "../../../app/components/buttons/ExpandButton";
import { ContractDto } from "../../contracts/dtos/ContractDto";

export const providerFiscalYearSchema = Yup.object().shape({
  fiscalYear: Yup.number()
    .test("fiscal-year-range", "Fiscal Year must be between 1980 and 2099", function (value) {
      if (value) {
        return value >= 1980 && value <= 2099;
      }
      return true;
    })
    .test("fiscal-year-required", "Fiscal Year is requied.", function (value) {
      return value !== 0 || value === undefined;
    }),
});

interface Props {
  control: Control<any>;
  disabled: boolean;
  isEdit: boolean;
  programTypeDeliverables: ProgramTypeDeliverableDto[];
  contracts: ContractDto[];
}

export const ProviderFiscalYearFormFields: React.FC<Props> = ({ control, disabled, isEdit, programTypeDeliverables, contracts }) => {
  //#region State
  const [expandedContract, setExpandedContract] = useState("");
  //#endregion

  const getYears = () => {
    const current = new Date().getFullYear();
    const options: SelectOption[] = [{ value: 0, label: "Select Year" }];
    for (let i = -2; i <= 2; i++) {
      const value = current + i;
      options.push({
        value,
        label: `${value.toString()} - ${(value + 1).toString()}`,
      });
    }

    return options;
  };

  const getContractTypeGroups = () => {
    const groupings: {
      [key: string]: ProgramTypeDeliverableDto[];
    } = contracts.reduce((acc, contract) => {
      return {
        ...acc,
        [contract.id]: programTypeDeliverables.filter((ptd) => contract.validProgramTypeIds.some((ptId) => ptId === ptd.programType.id)),
      };
    }, {});

    return Object.entries(groupings).sort((a, b) => (a[0] > b[0] ? 1 : -1));
  };

  const getProgramTypeGroups = (deliverables: ProgramTypeDeliverableDto[]) => {
    const groupings = deliverables
      .filter((pd) => pd.id !== "320ef3ff-a488-405a-8e2b-6c32728bb6f7")
      .reduce<{
        [key: string]: ProgramTypeDeliverableDto[];
      }>((value, deliverable) => {
        if (value[deliverable.programType.id]) {
          value[deliverable.programType.id].push(deliverable);
        } else {
          value[deliverable.programType.id] = [deliverable];
        }

        return value;
      }, {});

    return Object.entries(groupings).sort((a, b) => (a[1][0].programType.displayOrder > b[1][0].programType.displayOrder ? 1 : -1));
  };

  const toggleExpandedContract = (name: string) => {
    if (expandedContract === name) {
      setExpandedContract("");
    } else {
      setExpandedContract(name);
    }
  };

  return (
    <Grid container spacing={2} style={{ overflow: "hidden", width: "100%" }}>
      <Grid item md={12} xs={12}>
        <ControlledSelect name="fiscalYear" control={control} label="Fiscal Year" options={getYears()} disabled={disabled || isEdit} fullWidth />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledWholeNumberInput control={control} name="licensedBeds" label="Licensed Beds" disabled={disabled} />
      </Grid>
      {getContractTypeGroups().map(([contractKey, contractValues]) => (
        <Fragment key={contractKey}>
          <Grid item md={12} xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h6">{contracts.find((c) => c.id === contractKey)?.name || "Error Determining Contract Name"}</Typography>
              <Divider style={{ flexGrow: 1, marginLeft: "6px" }} />
              <ExpandButton
                onClick={() => toggleExpandedContract(contractKey)}
                expanded={expandedContract === contractKey}
                style={{ marginLeft: "auto" }}
                size="small"
              />
            </Box>
          </Grid>
          <Collapse in={expandedContract === contractKey} style={{ width: "100%" }}>
            <Grid item md={12} xs={12}>
              <Grid container spacing={2} style={{ width: "100%" }}>
                {getProgramTypeGroups(contractValues).map(([key, value]) => (
                  <Fragment key={key}>
                    <Grid item md={12} xs={12}>
                      <Typography>{value[0].programType.name}</Typography>
                    </Grid>
                    {value
                      .sort((a, b) => (a.deliverableType.displayOrder > b.deliverableType.displayOrder ? 1 : -1))
                      .map((ptd) => (
                        <Grid item md={4} xs={12} key={ptd.id}>
                          <ControlledWholeNumberInput
                            control={control}
                            name={`contractLimits[${contractKey}][${ptd.id}].limit`}
                            label={
                              <Fragment>
                                {" "}
                                <Typography component="span"># of </Typography>
                                <DeliverableTypeLabel component="span" type={ptd.deliverableType.type} />
                              </Fragment>
                            }
                          />
                        </Grid>
                      ))}
                    <Grid item md={12} xs={12}>
                      <Divider />
                    </Grid>
                  </Fragment>
                ))}
                {contractValues.length === 0 && (
                  <Grid item md={12} xs={12}>
                    <Typography>No active program/locations for the fiscal year.</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Collapse>
        </Fragment>
      ))}
    </Grid>
  );
};
