import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { ReferralFormDto } from "../dtos/ReferralFormDto";
import * as Yup from "yup";
import ReferrerFormFields, { agencySchema } from "./ReferrerFormFields";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import {
  getHeardAboutOptions,
  HeardAboutEnum,
} from "../../screenings/enums/HeardAboutEnum";
import {
  ReferralServiceRequestedEnum,
  referralServiceRequestedTextMap,
  referralServiceRequestedTooltipTextMap,
} from "../enums/ReferralServiceRequestedEnum";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import InfoTooltip from "../../../app/components/InfoTooltip";
import {ControlledStateSelect} from "../../../app/components/inputs/ControlledStateSelect";

export const referralSchema = Yup.object().shape(
  {
    referredBy: agencySchema,
    youthFirstName: Yup.string().label("Youth First Name").required().max(100),
    youthLastName: Yup.string().label("Youth Last Name").required().max(100),
    reasonForReferral: Yup.string().label("Reason for Referral").required(),
    youthZipCode: Yup.string().test(
      "test-youth-zip-code",
      "You must provide either the Youth City or Youth Zip Code.",
      function (value) {
        const { youthCity, state } = this.parent;

        if (state === "" || state !== "FL") return true;

        return value !== "" || youthCity !== "";
      }
    ),
    youthCity: Yup.string().test(
      "test-youth-city",
      "You must provide either the Youth City or Youth Zip Code.",
      function (value) {
        const { youthZipCode, state } = this.parent;

        if (state === "" || state !== "FL") return true;

        return value !== "" || youthZipCode !== "";
      }
    ),
    heardAboutUs: Yup.string().test(
      "test-heard-about-us",
      "Please provide how you heard about us.",
      function (value) {
        return (
          this.parent.heardAboutUsOption !== HeardAboutEnum.Other ||
          Boolean(value)
        );
      }
    ),
    state: Yup.string().label("State").required(),
    heardAboutUsOption: Yup.string().label("How you heard about us").required(),
    requestedServicesOptions: Yup.object().test(
      "test-requested-services",
      "",
      function (values) {
        if (values === undefined) return false;

        return Object.values(values).filter((x) => x).length > 0;
      }
    ),
  },
  [["youthCity", "youthZipCode"]]
);

interface Props {
  control: Control<ReferralFormDto>;
  isDisabled: boolean;
}

const ReferralFormFields: React.FC<Props> = ({ control, isDisabled }) => {
  const { isReferralSelf, requestedServicesOptions, state } = useWatch({
    control,
  });
  const heardAboutUsOption = useWatch({ control, name: "heardAboutUsOption" });
  const prefix = isReferralSelf === "false" ? "Youth" : "Your";
  return (
    <Fragment>
      <ReferrerFormFields
        prefix="referredBy"
        control={control}
        isDisabled={isDisabled}
      />

      {isReferralSelf === "false" && (
        <Fragment>
          <Grid item md={6} xs={12}>
            <ControlledInput
              name="youthFirstName"
              control={control}
              label="Youth First Name*"
              fullWidth
              disabled={isDisabled}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledInput
              name="youthLastName"
              control={control}
              label="Youth Last Name*"
              fullWidth
              disabled={isDisabled}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item md={12} xs={12}>
        <ControlledInput
          name="youthDob"
          control={control}
          label={`${prefix} DOB`}
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledStateSelect
          name="state"
          control={control}
          label="State"
          fullWidth
        />
      </Grid>
      {state !== "" && state === "FL" && (
        <Fragment>
          <Grid item md={12} xs={12}>
            <Typography color="textSecondary">
              Please provide either{" "}
              {isReferralSelf === "false" ? "the Youth's" : "Your"} Zip Code or
              City at a minimum.*
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledInput
              name="youthZipCode"
              control={control}
              label={`${prefix} Zip Code`}
              fullWidth
              disabled={isDisabled}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledInput
              name="youthCity"
              control={control}
              label={`${prefix} City`}
              fullWidth
              disabled={isDisabled}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item md={12} xs={12}>
        <ControlledSelect
          control={control}
          name="heardAboutUsOption"
          label={`How did you hear about us? We want to make sure others know how to find us.*`}
          options={getHeardAboutOptions()}
          fullWidth
        />
      </Grid>

      {heardAboutUsOption === HeardAboutEnum.Other && (
        <Grid item md={12} xs={12}>
          <ControlledInput
            control={control}
            name="heardAboutUs"
            label={`Please specify how you found us.*`}
            fullWidth
          />
        </Grid>
      )}

      <Grid item md={12} xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            What service(s) are you requesting?*
          </FormLabel>
          <FormGroup row>
            {Object.keys(ReferralServiceRequestedEnum).map((value) => {
              return (
                <ControlledCheckbox
                  control={control}
                  key={value}
                  label={
                    <Box display="flex" alignItems="center">
                      <Typography style={{ marginRight: "4px" }}>
                        {referralServiceRequestedTextMap[value]}
                      </Typography>
                      <InfoTooltip
                        title={
                          <Typography>
                            {referralServiceRequestedTooltipTextMap[value]}
                          </Typography>
                        }
                      />
                    </Box>
                  }
                  name={`requestedServicesOptions.${value}`}
                  color="primary"
                  disabled={
                    requestedServicesOptions![
                      ReferralServiceRequestedEnum.DontKnow
                    ] === true &&
                    value !== ReferralServiceRequestedEnum.DontKnow
                  }
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item md={12} xs={12}>
        <FormControl fullWidth>
          <FormLabel style={{ marginBottom: "4px" }}>
            Please explain the reason for this referral. This may include
            details about the{" "}
            {isReferralSelf === "false"
              ? "presenting problems"
              : "problems you are experiencing"}{" "}
            (for example, school-related issues, homelessness, substance abuse,
            etc.).*
          </FormLabel>
          <ControlledInput
            name="reasonForReferral"
            control={control}
            fullWidth
            multiline
            minRows={6}
            disabled={isDisabled}
          />
        </FormControl>
      </Grid>
    </Fragment>
  );
};

export default ReferralFormFields;
