import { JwtPayloadDto } from "./JwtPayloadDto";
import jwt from "jsonwebtoken";
import { NetmisUserRoleDto } from "../../roles/dtos/NetmisUserRoleDto";

export interface UserDto {
  email: string;
  isFloridaNetworkUser: boolean;
  firstName: string;
  lastName: string;
  id: string;
  phoneNumber?: string;
  accountIsLocked?: boolean;
  isInactive?: boolean;
  userRoles?: NetmisUserRoleDto[]
}

export const decryptJwtToUser = (token: string | null): UserDto | undefined => {
  if (token === null) return;

  const decoded = jwt.decode(token) as JwtPayloadDto;

  return {
    id: decoded.sub,
    email: decoded.email,
    isFloridaNetworkUser: decoded.isFnUser === "True",
    firstName: decoded.firstname,
    lastName: decoded.lastname,
    
  };
};
