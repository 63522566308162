import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  UserFilterFormDto,
  UserSearchParamsDto,
} from "../dtos/UserSearchParamsDto";

export const useUserFilter = (
  onSubmit: (dto: UserSearchParamsDto) => void,
  onClose: () => void,
  params: UserSearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: new UserFilterFormDto(params),
  });
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: UserFilterFormDto) => {
    const newParams: UserSearchParamsDto = new UserSearchParamsDto({
      form: dto,
      previous: params,
    });
    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new UserFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new UserFilterFormDto(params));
  }, [params, reset]);

  //#endregion

  return {
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
