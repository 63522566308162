import { Box, makeStyles, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React from "react";
import { ColorsEnum } from "../enums/ColorsEnum";

const useStyle = makeStyles((theme) => ({
  verticalTabPanel: {
    height: "100%",
    width: "100%",
  },
  horizontalTabPanel: {
    height: "calc(100% - 48px)",
  },
}));

export interface TabProps {
  value: string;
  label: React.ReactNode;
  content: React.ReactNode;
}

interface TabContainerProps {
  index: string;
  tabs: TabProps[];
  orientation?: "vertical" | "horizontal";
  disabled: boolean;
  padding?: string | number;
  onIndexChange(e: React.ChangeEvent<any>, index: string): void;
}

const TabContainer: React.FC<TabContainerProps> = ({
  index,
  tabs,
  disabled,
  orientation,
  padding = "16px",
  onIndexChange,
}) => {
  const classes = useStyle();
  return (
    <Box
      display={orientation === "vertical" ? "flex" : undefined}
      height="100%"
    >
      {tabs.length > 0 && index !== "" && (
        <TabContext value={index}>
          <TabList
            onChange={onIndexChange}
            orientation={orientation}
            style={{ overflow: "auto" }}
            variant="scrollable"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab
                disabled={disabled}
                label={tab.label}
                key={tab.value}
                value={tab.value}
              />
            ))}
          </TabList>

          <Box style={{ borderBottom: "1px solid #ddd" }}></Box>
          <Box
            overflow="auto"
            display={"flex"}
            flexDirection={"column"}
            className={
              orientation === "vertical"
                ? classes.verticalTabPanel
                : classes.horizontalTabPanel
            }
            bgcolor={ColorsEnum.OffWhite}
          >
            {tabs.map((tab) => (
              <TabPanel
                style={{ flex: 1, padding }}
                value={tab.value}
                key={tab.value}
              >
                 {index === tab.value ? tab.content : <></>}
              </TabPanel>
            ))}
          </Box>
        </TabContext>
      )}
    </Box>
  );
};

export default TabContainer;
