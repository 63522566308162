import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { SnapInSchoolsCycleDto } from "../../snap-in-schools/dtos/SnapInSchoolsCycleDto";
import { useAlertDismissalForm } from "../hooks/useAssessmentOverrideReasonForm";
import { AlertDismissalRequestDto } from "../dtos/AlertDismissalDto";
import { AlertTypeEnum } from "../enums/AlertTypeEnum";

export const alertDismissalModalId = "alert-dismissal-form-modal";

interface Props {
  requestState: RequestStateDto;
  type: AlertTypeEnum;
  caseDto?: CaseDetailDto;
  snapCycle?: SnapInSchoolsCycleDto;
  onSubmit(dto: AlertDismissalRequestDto): Promise<any>;
}

const AlertDismissalFormDialog: React.FC<Props> = ({
  requestState,
  type,
  caseDto,
  snapCycle,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useAlertDismissalForm(
    onSubmit,
    type,
    caseDto,
    snapCycle
  );
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };

  const getTypeName = () => {
    switch (type) {
      case AlertTypeEnum.SnapInSchoolsPostMoceRequired:
        return "Post-MoCE";
      case AlertTypeEnum.SnapInSchoolsPreMoceRequired:
        return "Pre-MoCE";
      case AlertTypeEnum.CaseNirvanaAssessmentRequired:
        return "NIRVANA";
      case AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired:
        return "NIRVANA Self-Report";
      case AlertTypeEnum.CaseTopsePostAssessmentRequired:
      case AlertTypeEnum.CaseTopsePreAssessmentRequired:
        return "TOPSE";
      case AlertTypeEnum.ShelterCaseLengthOfStay:
      case AlertTypeEnum.SnapCaseLengthOfStay:
      case AlertTypeEnum.IcmCaseLengthOfStay:
      case AlertTypeEnum.ProbationRespiteCaseLengthOfStay:
      case AlertTypeEnum.CommunityCounselingCaseLengthOfStay:
        return "Length of Stay";
      default:
        return "";
    }
  };

  const getHelpText = () => {
    switch (type) {
      case AlertTypeEnum.CaseNirvanaAssessmentRequired:
      case AlertTypeEnum.CaseNirvanaSelfReportAssessmentRequired:
      case AlertTypeEnum.CaseTopsePostAssessmentRequired:
      case AlertTypeEnum.CaseTopsePreAssessmentRequired:
      case AlertTypeEnum.SnapInSchoolsPostMoceRequired:
      case AlertTypeEnum.SnapInSchoolsPreMoceRequired:
        return "Enter the reason the Assessment is not completed.";
      case AlertTypeEnum.ShelterCaseLengthOfStay:
      case AlertTypeEnum.SnapCaseLengthOfStay:
      case AlertTypeEnum.IcmCaseLengthOfStay:
      case AlertTypeEnum.ProbationRespiteCaseLengthOfStay:
      case AlertTypeEnum.CommunityCounselingCaseLengthOfStay:
        return "Enter the reason the youth is still in service.";
      default:
        return "";
    }
  };
  //#endregion

  return (
    <Dialog open={modalId === alertDismissalModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Dismiss {getTypeName()} Alert
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography color="textSecondary">{getHelpText()}</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <ControlledInput
                control={control}
                name="reason"
                label="Reason*"
                fullWidth
                multiline
                minRows={2}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Submit Override"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AlertDismissalFormDialog;
