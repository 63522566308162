import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { ChangeDischargeDateFormDto } from "../dtos/ChangeDischargeDateFormDto";
import { useChangeDischargeDateForm } from "../hooks/useChangeDischargeDateForm";

export const changeDischargeDateFormDialogId =
  "change-discharge-date-form-dialog";

interface Props {
  onSubmit: (dto: ChangeDischargeDateFormDto) => Promise<void>;
  requestState: RequestStateDto;
  caseDto?: CaseDetailDto;
}

const ChangeDischargeDateFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
  caseDto,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { form, handlers } = useChangeDischargeDateForm(onSubmit, caseDto);

  const { control, isValid, clearForm } = form;
  const { handleSubmit } = handlers;
  const { isLoading, error } = requestState;
  //#endregion

  //#region Handlers
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog
      open={modalId === changeDischargeDateFormDialogId}
      maxWidth="xs"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Change Discharged Date/Time
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledInput
                name="dischargedAt"
                label="Discharge Date/Time"
                type="datetime-local"
                control={control}
                disabled={requestState.isLoading}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeDischargeDateFormDialog;
