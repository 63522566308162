import { useState } from "react";
import { IcmActivitySearchParamsDto } from "../dtos/IcmActivitySearchParamsDto";
import { ReportFormatEnum } from "../enums/ReportFormatEnum";
import {
  useDownloadIcmActivityReport,
  useGetIcmActivityReport,
} from "./reportHooks";
import { useQuery } from "../../../app/hooks/useQuery";
import { getIsFnyfsUser } from "../../authentication/state/authenticationActions";

export const useIcmActivityReportPage = () => {
  const query = useQuery();
  const initialParams = new IcmActivitySearchParamsDto();
  if (query.startOn) {
    initialParams.startOn = query.startOn as string;
  }
  if (query.endOn) {
    initialParams.endOn = query.endOn as string;
  }

  const isFnyfsUser = getIsFnyfsUser();

  // Requests
  const [getIcmActivityReport, , getIcmActivityReportRequestState] =
    useGetIcmActivityReport();
  const [downloadIcmActivityReport, , downloadIcmActivityReportRequestState] =
    useDownloadIcmActivityReport();
  const [activityReport, setActivityReport] = useState<string>("");
  const [params, setParams] =
    useState<IcmActivitySearchParamsDto>(initialParams);

  const handleSearch = async (dto: IcmActivitySearchParamsDto) => {
    setActivityReport("");
    setParams(dto);
    switch (dto.format) {
      case ReportFormatEnum.HTML:
        const result = await getIcmActivityReport(dto);
        setActivityReport(result || "");
        break;
      case ReportFormatEnum.CSV:
        await downloadIcmActivityReport({
          dto,
          filename: `ICM_Activity_Report_${new Date()
            .toLocaleDateString()
            .replaceAll("/", "_")}.xlsx`,
        });
        break;
    }
  };

  return {
    handlers: { handleSearch },
    state: {
      params,
      activityReport,
      getIcmActivityReportRequestState,
      downloadIcmActivityReportRequestState,
      isFnyfsUser,
    },
  };
};
