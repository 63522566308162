import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";
import { RadioGroupProps } from "@material-ui/core";

export interface RadioOption {
  label: React.ReactNode;
  value: string | boolean;
}

interface Props extends RadioGroupProps {
  control: Control<any>;
  name: string;
  options: RadioOption[];
}

const ControlledRadioGroup: React.FC<Props> = ({
  control,
  name,
  options,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RadioGroup {...props} {...field}>
          {options.map((option) => (
            <FormControlLabel
              key={`${name}-option-${option.value
                .toString()
                .replaceAll(" ", "-")}`}
              label={option.label}
              control={
                <Radio {...field} value={option.value} color="primary" />
              }
            />
          ))}
        </RadioGroup>
      )}
    />
  );
};

export default ControlledRadioGroup;
