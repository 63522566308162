import { Typography } from "@material-ui/core";
import React from "react";
import ErrorText from "../../../app/components/ErrorText";
import DateLabel from "../../../app/components/labels/DateLabel";
import ConfirmDialog from "../../../app/components/modals/ConfirmDialog";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { SnapInSchoolsSessionDto } from "../dtos/SnapInSchoolsSessionDto";

interface Props {
  onConfirm(): Promise<void>;
  requestState: RequestStateDto;
  session?: SnapInSchoolsSessionDto;
}

export const confirmDeleteSnapInSchoolsSessionDialogId =
  "delete-snap-in-schools-session-dialog";
const ConfirmDeleteSnapInSchoolsSessionDialog: React.FC<Props> = ({
  onConfirm,
  requestState,
  session,
}) => {
  const { error, isLoading } = requestState;

  return (
    <ConfirmDialog
      title="Confirm"
      onConfirm={onConfirm}
      modalId={confirmDeleteSnapInSchoolsSessionDialogId}
      isLoading={isLoading}
      confirmButtonText="Delete Session"
      confirmButtonStyle={{ color: ColorsEnum.Red }}
    >
      <ErrorText error={error?.message} />
      <Typography>
        Are you sure you want to delete SNAP in Schools and Communities Session
        held on <DateLabel date={session?.sessionHeldOn} />? <br />
        <br /> <strong>This action may result in invoice corrections.</strong>
      </Typography>
    </ConfirmDialog>
  );
};

export default ConfirmDeleteSnapInSchoolsSessionDialog;
