import { Typography } from "@material-ui/core";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";

export enum FollowUpImprovementEnum {
    Yes = "Yes",
    No = "No",
    Somewhat = "Somewhat"
}

export const followUpImprovementTextMap: { [key: string]: string } = {
    [FollowUpImprovementEnum.Yes]: "Yes",
    [FollowUpImprovementEnum.No]: "No",
    [FollowUpImprovementEnum.Somewhat]: "Somewhat"
}

export const followUpImprovementOptions: SelectOption[] = [
    { label: "Select an option", value: "" },
    {
        label: <Typography>Yes</Typography>,
        value: FollowUpImprovementEnum.Yes
    },
    {
        label: <Typography>No</Typography>,
        value: FollowUpImprovementEnum.No
    },
    {
        label: <Typography>Somewhat</Typography>,
        value: FollowUpImprovementEnum.Somewhat
    }
];
