import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BakerActDto, BakerActFormDto } from "../dto/BakerActDto";

export const useBakerActForm = (
  onSubmit: (dto: BakerActFormDto) => Promise<any>,
  bakerAct?: BakerActDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new BakerActFormDto(bakerAct),
    resolver: yupResolver(BakerActFormDto.getSchema()),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(new BakerActFormDto(bakerAct));
  };
  const handleOnSubmit = handleSubmit(async (dto: BakerActFormDto) => {
    await onSubmit(dto);
    if (bakerAct == null) reset(new BakerActFormDto());
  });

  useEffect(() => {
    reset(new BakerActFormDto(bakerAct));
  }, [reset, bakerAct]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
