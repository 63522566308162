import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ChangeDischargeDateFormDto } from "../dtos/ChangeDischargeDateFormDto";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";

export const useChangeDischargeDateForm = (
  onSubmit: (dto: ChangeDischargeDateFormDto) => Promise<void>,
  caseDto?: CaseDetailDto
) => {
  //#region State
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: new ChangeDischargeDateFormDto(caseDto?.dischargeInfo),
    mode: "all",
    resolver: yupResolver(ChangeDischargeDateFormDto.getSchema(caseDto)),
  });

  //#endregion

  //#region Handlers
  const clearForm = () => {
    reset(new ChangeDischargeDateFormDto(caseDto?.dischargeInfo));
  };
  const handleOnSubmit = handleSubmit(
    async (dto: ChangeDischargeDateFormDto) => {
      await onSubmit(dto);
    }
  );

  //#endregion

  //#region Effects

  useEffect(() => {
    reset(new ChangeDischargeDateFormDto(caseDto?.dischargeInfo));
  }, [reset, caseDto?.dischargeInfo]);
  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
