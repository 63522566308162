import { Paper, TableContainer } from "@material-ui/core";
import React from "react";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import { WaitlistDto } from "../dtos/WaitlistDto";
import { WaitlistSearchParamsDto } from "../dtos/WaitlistSearchParamsDto";
import WaitlistListItem from "./WaitlistListItem";

const WaitlistList: React.FC<
  SortableTableProps<WaitlistDto, WaitlistSearchParamsDto> & {
    onRemoveWaitlistClick: (waitlist: WaitlistDto) => void;
  }
> = ({ onRemoveWaitlistClick, ...props }) => {
  const { params } = props;

  const columns: ColumnProps[] = [
    { name: "screeningNumber", label: "Screening #" },
    {
      name: "youth",
      label: "Youth",
      disabled: !!params.youthFirstName || !!params.youthLastName,
    },
    { name: "provider", label: "Provider" },
    { name: "program", label: "Program" },
    { name: "location", label: "Location" },
    { name: "status", label: "Status" },
    { name: "placeholder", label: "", disabled: true },
    { name: "placeholder2", label: "", disabled: true },
  ];
  const renderRow = (waitlist: WaitlistDto) => (
    <WaitlistListItem
      waitlist={waitlist}
      key={waitlist.id}
      onRemoveWaitlistClick={() => onRemoveWaitlistClick(waitlist)}
    />
  );

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default WaitlistList;
