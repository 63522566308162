import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { datetimeToFormString } from "../../../app/helpers";
import {
  SuicideScreeningDto,
  SuicideScreeningFormDto,
  SuicideScreeningResponseFormDto,
} from "../dtos/SuicideScreeningDto";
import { SuicideScreeningResponseEnum } from "../enums/SuicideScreeningResponseEnum";

export interface SuicideQuestion {
  name: string;
  text: string;
  subQuestion?: string;
}

export enum SuicideQuestionNames {
  selfHarmingBehaviors = "selfHarmingBehaviors",
  wishedDead = "wishedDead",
  suicidalThoughts = "suicidalThoughts",
  suicidalActions = "suicidalActions",
  suicidalThoughtHow = "suicidalThoughtHow",
  suicidalIntentToAct = "suicidalIntentToAct",
  suicidalPlanDetails = "suicidalPlanDetails",
}

const translateYesNoToBoolString = (value: string, subQuestion: boolean) : string => {
  if (!subQuestion)
    return value === "Yes" || value === "YesAndYes" ? "true" : "false";

  return value === "YesAndYes" ? "true" : "false"
}

const createQuestionFormFields = (dto?: SuicideScreeningDto) => {
  return Object.values(SuicideQuestionNames).reduce<{ [key: string]: string }>(
    (acc, value) => {
      acc[value] = dto?.suicideScreeningResponseSet[value]
        ? translateYesNoToBoolString(dto.suicideScreeningResponseSet[value], false)
        : "";

      const question = suicideScreeningPage1Questions.find(q => q.name === value && q.subQuestion)
      if(question)
        acc[`${value}-a`] = dto?.suicideScreeningResponseSet[value]
        ? translateYesNoToBoolString(dto.suicideScreeningResponseSet[value], true)
        : "";

      return acc;
    },
    {
      screeningAt: dto?.screeningAt ? datetimeToFormString(new Date(dto.screeningAt)) : ""
    }
  );
};

export const suicideScreeningPage1Questions: SuicideQuestion[] = [
  {
    name: SuicideQuestionNames.selfHarmingBehaviors,
    text: "1. Have you recently been in a situation where you did not care whether you lived or died?",
  },
  {
    name: SuicideQuestionNames.wishedDead,
    text: "2. Have you felt continuously sad or hopeless to the point of wanting to die?",
  },
  {
    name: SuicideQuestionNames.suicidalThoughts,
    text: "3. Do you feel like life is not worth living or wish you were dead?",
  },
  {
    name: SuicideQuestionNames.suicidalActions,
    text: "4. Have you ever tried to harm or kill yourself?",
  },
  {
    name: SuicideQuestionNames.suicidalThoughtHow,
    text: "5. Are you thinking about harming or killing yourself now or in the last two weeks?",
    subQuestion:
      "a. If yes, do you have a plan (specific method) to kill yourself?",
  },
];

export const useSuicideScreeningForm = (
  onSubmit: (dto: SuicideScreeningFormDto) => Promise<void>,
  suicideScreeningDto?: SuicideScreeningDto
) => {
  const [isValid, setIsValid] = useState(false);
  // const [disableNext] = useState(true);

  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues:  createQuestionFormFields(suicideScreeningDto)
  });
  const values = watch();

  const clearForm = () => {
    reset(createQuestionFormFields(suicideScreeningDto));
  };

  const getResponse = (
    values: { [key: string]: string },
    name: SuicideQuestionNames
  ) => {
    if (values[name] === undefined || values[name] === "") return undefined;

    const first = values[name] === "true";
    const second = values[`${name}-a`] === "true";

    if (first && second) return SuicideScreeningResponseEnum.YesAndYes;

    if (first) return SuicideScreeningResponseEnum.Yes;

    return SuicideScreeningResponseEnum.No;
  };

  const handleOnSubmit = handleSubmit(
    async (values: { [key: string]: string }) => {
      const result: SuicideScreeningResponseFormDto = {
        selfHarmingBehaviors: getResponse(
          values,
          SuicideQuestionNames.selfHarmingBehaviors
        )!,
        wishedDead: getResponse(values, SuicideQuestionNames.wishedDead)!,
        suicidalThoughts: getResponse(
          values,
          SuicideQuestionNames.suicidalThoughts
        )!,
        suicidalActions: getResponse(
          values,
          SuicideQuestionNames.suicidalActions
        )!,
        suicidalThoughtHow: getResponse(
          values,
          SuicideQuestionNames.suicidalThoughtHow
        ),
        suicidalIntentToAct: getResponse(
          values,
          SuicideQuestionNames.suicidalIntentToAct
        ),
        suicidalPlanDetails: getResponse(
          values,
          SuicideQuestionNames.suicidalPlanDetails
        ),
      };

      const dto: SuicideScreeningFormDto = {
        screeningAt: values.screeningAt,
        suicideScreeningResponseSet: result,
      };

      await onSubmit(dto);
    }
  );

  useEffect(() => {
    const mainQuestionsAnswered = suicideScreeningPage1Questions.map(q => q.name).every(name => values[name] && values[name] !== "")
    if(!mainQuestionsAnswered) {
      setIsValid(false);
      return;
    }

    const subQuestionsAnswered = suicideScreeningPage1Questions.filter(q => q.subQuestion && values[q.name] === "true").map(q => `${q.name}-a`).every(name => values[name] && values[name] !== "")

    setIsValid(subQuestionsAnswered);
  }, [values])

  useEffect(() => {
    reset(createQuestionFormFields(suicideScreeningDto));
  }, [suicideScreeningDto, reset])

  return {
    state: { },
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
