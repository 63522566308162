import { ReportFormatEnum } from "../enums/ReportFormatEnum";
import * as Yup from "yup";
import { MonthlyReportCardDateRangeType } from "./MonthlyReportCardSearchParamsDto";
export class MonthlyReportCardByProviderSearchParamsDto {
  dateRangeType: string = "";
  month: string = "";
  year: string = "";
  format: string = ReportFormatEnum.CSV;

  public static getSchema() {
    return Yup.object().shape({
      year: Yup.string().required().label("Year").required(),
      month: Yup.string().test(
        "month-required",
        "Month is required.",
        function (value) {
          if (
            this.parent.dateRangeType !==
            MonthlyReportCardDateRangeType.MonthYear
          )
            return true;

          return value != null && `${value}` !== "";
        }
      ),
      dateRangeType: Yup.string().required().label("Date Range Type"),
    });
  }
}
