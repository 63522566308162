import React, { useCallback, useEffect, useState } from "react";
import { ProviderDto } from "../../provider/dtos/ProviderDto";
import { SnapGroupCycleSearchParamsDto } from "../dtos/SnapGroupCycleSearchParamsDto";
import { snapCycleGenderEnumTextMap } from "../enums/SnapGroupCycleGenderEnum";
import { ProgramTypeSummaryDto } from "../../program-types/dtos/ProgramTypeSummaryDto";
import { SnapCycleFilterFormDto } from "../dtos/SnapFilterFormDto";
import FilterList from "../../../app/components/FilterList";
import { ProgramDto } from "../../programs/dtos/ProgramDto";
import { LocationDto } from "../../locations/dtos/LocationDto";
import { DateTime } from "luxon";

const formatDate = (date: string) => {
  return DateTime.fromJSDate(new Date(`${date}T00:00`)).toFormat("MM-dd-yyyy");
};
interface Props {
  params: SnapGroupCycleSearchParamsDto;
  isLoading: boolean;
  programTypes: ProgramTypeSummaryDto[];
  providers: ProviderDto[];
  providerPrograms: { [key: string]: ProgramDto[] };
  providerLocations: { [key: string]: LocationDto[] };
  onSearch(dto: SnapGroupCycleSearchParamsDto): void;
}

const SnapCycleFilterList: React.FC<Props> = ({
  params,
  isLoading,
  programTypes,
  providers,
  providerPrograms,
  providerLocations,
  onSearch,
}) => {
  
  const [programs, setPrograms] = useState<ProgramDto[]>([]);
  const [locations, setLocations] = useState<LocationDto[]>([]);

  const handleClearParams = () => {
    return new SnapGroupCycleSearchParamsDto({
      form: new SnapCycleFilterFormDto(),
      previous: params,
    });
  };

  const getLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "programTypeId":
          return `Program Type: ${
            programTypes.find((x) => x.id === value)?.name
          }`;
        case "providerId":
          return providers.length > 0 ?
            `Provider: ${providers.find((x) => x.id === value)?.name}`
            : '';

        case "programId":
          return `Program: ${programs.find((x) => x.id === value)?.name}`;
        case "locationId":
            return `Location: ${locations.find((x) => x.id === value)?.name}`;          
        case "gender":
          return `Gender: ${snapCycleGenderEnumTextMap[value]}`;
        case "cycleStartDateRangeStart":
            return `Cycle Start Date Start: ${formatDate(value)}`;
        case "cycleStartDateRangeEnd":
          return `Cycle Start Date End: ${formatDate(value)}`;
        case "includeDeleted":
          return "Include Deleted";
        case "fidelityRequired":
          return "Fidelity Required";
      }
      return "";
    },
    [ programTypes, providers, programs, locations ]
  );

  const ordering = [
    "providerId",
    "programTypeId",
    "programId",
    "locationId",
    "gender",
    "cycleStartDateRangeStart",
    "cycleStartDateRangeEnd",
    "fidelityRequired",
    "includeDeleted",
  ];

  useEffect(() => {
    setPrograms(Object.values(providerPrograms).flatMap((x) => x));
  }, [providerPrograms, setPrograms]);
  
  useEffect(() => {
    setLocations(Object.values(providerLocations).flatMap((x) => x));
  }, [providerLocations, setLocations]);

  return (
    <FilterList
      params={params}
      isLoading={isLoading}
      onSearch={onSearch}
      onClearParams={handleClearParams}
      getLabel={getLabel}
      ordering={ordering}
    />
  );
};

export default SnapCycleFilterList;
