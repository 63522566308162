import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  ProviderFilledBedDayDto,
  ProviderFilledBedDayFormDto,
} from "../dtos/ProviderFilledBedDayDto";

export const useProviderFilledBedDayForm = (
  onSubmit: (dto: ProviderFilledBedDayFormDto) => Promise<any>,
  month: number,
  fiscalYear: number,
  dto?: ProviderFilledBedDayDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: new ProviderFilledBedDayFormDto(
      dto?.month || month,
      dto?.fiscalYear || fiscalYear,
      dto?.filledDays
    ),
    resolver: yupResolver(ProviderFilledBedDayFormDto.getSchema()),
    mode: "all",
  });

  //#endregion
  const clearForm = () => {
    reset(
      new ProviderFilledBedDayFormDto(
        dto?.month || month,
        dto?.fiscalYear || fiscalYear,
        dto?.filledDays
      )
    );
  };

  const handleOnSubmit = handleSubmit(
    async (dto: ProviderFilledBedDayFormDto) => {
      await onSubmit(dto);
    }
  );

  //#region Effects
  useEffect(() => {
    reset(
      new ProviderFilledBedDayFormDto(
        dto?.month || month,
        dto?.fiscalYear || fiscalYear,
        dto?.filledDays
      )
    );
  }, [reset, month, fiscalYear, dto]);
  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
