import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { Control, useWatch } from "react-hook-form";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import { OutreachActivityFormDto } from "../dtos/OutreachActivityFormDto";
import { getModalityEnumOptions } from "../enums/ModalityEnum";
import {
  TargetAudienceEnum,
  targetAudienceEnumTextMap,
} from "../enums/TargetAudienceEnum";
import { TopicEnum, topicEnumTextMap } from "../enums/TopicEnum";

interface Props {
  control: Control<OutreachActivityFormDto>;
  disabled?: boolean;
  allowNotSpecified: boolean;
}

const OutreachActivityFormFields: React.FC<Props> = ({
  control,
  disabled,
  allowNotSpecified,
}) => {
  const { targetAudienceOptions, topicOptions } = useWatch({
    control,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledInput
          name="title"
          control={control}
          label="Title*"
          disabled={disabled}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ControlledInput
          name="activityDate"
          control={control}
          label="Date*"
          type="date"
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ControlledInput
          name="durationHours"
          control={control}
          label="Duration (Hours)*"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ControlledInput
          name="zipCode"
          control={control}
          label="Zip Code*"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledInput
          name="locationDescription"
          control={control}
          label="Location Description"
          disabled={disabled}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledInput
          name="estimatedPeopleReached"
          control={control}
          label="Estimated Number of People Reached"
          disabled={disabled}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControlledSelect
          control={control}
          name="modality"
          options={getModalityEnumOptions()}
          label="Modality*"
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid
        item
        xs={6}
        style={{ borderRight: `1px solid ${ColorsEnum.Border}` }}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">Target Audience*</FormLabel>
          <FormGroup>
            {Object.keys(TargetAudienceEnum)
              .filter((a) => allowNotSpecified || a !== "NotSpecified")
              .sort((a, b) => (a > b ? 1 : a === "NotSpecified" ? 1 : -1))
              .map((value) => {
                return (
                  <ControlledCheckbox
                    control={control}
                    key={value}
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography style={{ marginRight: "4px" }}>
                          {targetAudienceEnumTextMap[value]}
                        </Typography>
                      </Box>
                    }
                    name={`targetAudienceOptions[${value}]`}
                    color="primary"
                    value={targetAudienceOptions![value]}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Topic(s)*</FormLabel>
          <FormGroup>
            {Object.keys(TopicEnum)
              .sort((a, b) => (a > b || a === TopicEnum.Other ? 1 : -1))
              .map((value) => {
                return (
                  <ControlledCheckbox
                    control={control}
                    key={value}
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography style={{ marginRight: "4px" }}>
                          {topicEnumTextMap[value]}
                        </Typography>
                      </Box>
                    }
                    name={`topicOptions[${value}]`}
                    color="primary"
                    value={topicOptions![value]}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default OutreachActivityFormFields;
