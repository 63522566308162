import { useRequest } from "../../../app/hooks/useRequest";
import {getFollowUpExport, getFollowUpSummaryList} from "../FollowUpRequests";
import {useCallback, useEffect, useState} from "react";
import {
  useGetAllProviders, useGetProviderLocations,
  useGetProviderPrograms, useGetProviderStaffMembers,
} from "../../provider/hooks/providerHooks";
import { useGetProgramTypes } from "../../program-types/hooks/programTypeHooks";
import { FollowUpSearchParamsDto } from "../dtos/FollowUpSearchParamsDto";
import { useQuery } from "../../../app/hooks/useQuery";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../authentication/constants";
import { PrintHelpers, QueryStringHelpers, dateToFormString } from "../../../app/helpers";
import { useHistory } from "react-router-dom";
import { SortDirectionEnum } from "../../../app/enums/SortDirectionEnum";
import {
  useGetBlankFollowUpDocument,
  useGetCaseFollowUpDocument,
} from "../../reports/hooks/reportHooks";
import { ReportFormatEnum } from "../../reports/enums/ReportFormatEnum";
import {openModal} from "../../modal/state/modalSlice";
import {useAppDispatch} from "../../../app/hooks";
import {multipleFollowupsModalId} from "../components/PrintMultipleFollowUpsDialog";
import { DateTime } from "luxon";
import {IntervalSelections} from "../components/FollowUpIntervalSelector";

export const useFollowUpsListPage = () => {
  //#region State
  const dispatch = useAppDispatch();

  const [
    getFollowUpSummaryListData,
    followUpSummaryList,
    followUpSummaryListRequestStatus,
  ] = useRequest(getFollowUpSummaryList);

  const [
    getFollowUpExportFile,
    ,
    getFollowUpExportFileRequestStatus,
  ] = useRequest(getFollowUpExport);


  const query = useQuery();
  const history = useHistory();

  // we want all the regular defaults, but we want to start with servicesSinceDischarge as false
  const initialParameters: FollowUpSearchParamsDto = {
    ...(new FollowUpSearchParamsDto({ query })),
    hadServicesSinceDischarge: 'false',
    dischargeDateStart: getInitialDischargeStartDate()
  };

  function getInitialDischargeStartDate(days: number = -120) {
    const date = DateTime.local().plus({ days });
    return dateToFormString(date.toJSDate());
  }

  
  const [params, setParams] = useState<FollowUpSearchParamsDto>(initialParameters);

  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [getProviders, providers, getProvidersRequestState] =
    useGetAllProviders();
  const [getProgramTypes, programTypes, getProgramTypesRequestState] =
    useGetProgramTypes();

  const [getPrograms, programs, getProgramsRequestState] =
    useGetProviderPrograms();

  const [getLocations, locations, getLocationsRequestState] = useGetProviderLocations();

  const [getCaseFollowUpDocument, , getFollowUpDocumentRequestState] =
    useGetCaseFollowUpDocument();

  const [getBlankFollowUpDocument, , getBlankFollowUpDocumentRequestState] =
    useGetBlankFollowUpDocument();

  const [getStaff, staff, getStaffRequestState] = useGetProviderStaffMembers();

  //#endregion

  //#region Handlers
  const handlePageChange = (page: number) => {
    setParams((params) => ({ ...params, page }));
  };

  const handleSort = (sortBy: string, sortDirection: SortDirectionEnum) => {
    const newParams = { ...params, page: 0, sortBy, sortDirection };
    setParams(newParams);
  };

  const handleSearch = (dto: FollowUpSearchParamsDto) => {
    // if (dto.hadServicesSinceDischarge === "")
    //   dto.hadServicesSinceDischarge = "false";

    setParams(dto);
  };

  const handleOpenFilterClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => setFilterAnchorEl(null);

  const handlePrintFollowUp = async (id?: string) => {
    const action =
      id == null
        ? () => getBlankFollowUpDocument(ReportFormatEnum.PDF)
        : () => getCaseFollowUpDocument({ id, format: ReportFormatEnum.PDF });

    PrintHelpers.printDocument(action);
  };

  const handlePrintMultipleFollowUpsClicked = useCallback(async () => {
    dispatch(openModal({ modalId: multipleFollowupsModalId }));
  }, [dispatch]);

  const handleExportListClicked = useCallback(async () => {
    await getFollowUpExportFile(params)
  }, [getFollowUpExportFile, params])

  const handleIntervalSelectionsChanged = useCallback((selections: IntervalSelections) => {
    setParams({...params, ...selections})
  }, [params])

  //#endregion

  //#region Effects
  useEffect(() => {
    getFollowUpSummaryListData({...params});
  }, [getFollowUpSummaryListData, params]);

  useEffect(() => {
    history.push(`/follow-ups?${QueryStringHelpers.toQueryString(params)}`);
  }, [params, history]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId === fnyfsProviderId) {
      getProviders(undefined);
    }
  }, [getProviders]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId === fnyfsProviderId) {
      getProgramTypes("");
    }
  }, [getProgramTypes]);

  useEffect(() => {
    const providerId = getProviderId();
    if (providerId && providerId !== fnyfsProviderId) {
      getPrograms(providerId);
      getLocations(providerId);
      getStaff(providerId)
    }
  }, [getPrograms, getLocations, getStaff]);
  //#endregion
  return {
    state: {
      filterAnchorEl,
      followUpSummaryList,
      params,
      providers: providers || [],
      programTypes: programTypes || [],
      programs: programs || [],
      locations: locations || [],
      staff: staff || [],
      followUpSummaryListRequestStatus,
      getProvidersRequestState,
      getProgramTypesRequestState,
      getProgramsRequestState,
      getLocationsRequestState,
      getStaffRequestState,
      getFollowUpDocumentRequestState,
      getBlankFollowUpDocumentRequestState,
      getFollowUpExportFileRequestStatus,

    },
    handlers: {
      handlePageChange,
      handleCloseFilter,
      handleOpenFilterClicked,
      handleSearch,
      handleSort,
      handlePrintFollowUp,
      handlePrintMultipleFollowUpsClicked,
      handleExportListClicked,
      handleIntervalSelectionsChanged,
    },
  };
};
