import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import { FollowUpDto } from "../dtos/FollowUpDto";
import { useRequest } from "../../../app/hooks/useRequest";
import {
  addFailedFollowUpDataRequest,
  addFollowUpCompletionDataRequest,
} from "../FollowUpRequests";
import {
  getLivingSituationOptions,
  LivingSituationEnum,
} from "../../screenings/enums/LivingSituationEnum";
import {
  SchoolStatusEnum,
  schoolStatusOptions,
} from "../../discharges/enums/SchoolStatusEnum";
import { intervalSelectOptions } from "../enums/FollowUpInterval";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import {
  unableToContactReasonOptions,
  UnableToContactReasonsEnum,
} from "../enums/UnableToContactReasonsEnum";
import { followUpImprovementOptions } from "../enums/FollowUpImprovementEnum";

export interface AddFollowUpDataFormData {
  attemptedAt: Date;
  outcome: string;
  reason: string;
  receivedServicesSinceDischarge: boolean;
  receivedServicesFromAnotherProviderSinceDischarge: boolean;
  livingStatus: string;
  isYouthsLivingStatusAppropriate: boolean;
  schoolStatus: string;
  seenImprovement: string;
}

const schema = Yup.object().shape({
  // eslint-disable-next-line
  attemptedAt: Yup.date().required().label("Attempted At").typeError("${label} must be a valid date/time"),
  outcome: Yup.string().required().label("Follow Up Outcome"),
  reason: Yup.string().when("outcome", {
    is: "failed",
    then: Yup.string().required().label("Reason for Failed Attempt"),
  }),
  receivedServicesSinceDischarge: Yup.boolean().optional(),
  receivedServicesFromAnotherProviderSinceDischarge: Yup.boolean().optional(),
  livingStatus: Yup.string().when("outcome", {
    is: "success",
    then: Yup.string().required().label("Current Living Status"),
  }),
  IsYouthsLivingStatusAppropriate: Yup.boolean().optional(),
  schoolStatus: Yup.string().when("outcome", {
    is: "success",
    then: Yup.string().required().label("Current School Status"),
  }),
  seenImprovement: Yup.string().optional().label("Improvement Status"),
});

export const useAddFollowUpDataForm = (
  onSubmit: () => Promise<void>,
  caseDto: CaseDetailDto,
  followUp: FollowUpDto
) => {
  //#region State

  const livingSituationOptions = getLivingSituationOptions();

  const followUpOutcomeOptions: SelectOption[] = [
    { label: "Select the outcome of this follow up attempt", value: "" },
    { label: "Failed to make contact", value: "failed" },
    { label: "Completed follow up", value: "success" },
  ];

  const {
    formState: { isValid },
    control,
    handleSubmit,
    reset,
  } = useForm<AddFollowUpDataFormData>({
    defaultValues: {
      outcome: "",
      attemptedAt: new Date(),
      isYouthsLivingStatusAppropriate: false,
      livingStatus: "",
      reason: "",
      receivedServicesFromAnotherProviderSinceDischarge: false,
      receivedServicesSinceDischarge: false,
      schoolStatus: "",
      seenImprovement: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clearForm = () => {
    reset({
      outcome: "",
      attemptedAt: new Date(),
      isYouthsLivingStatusAppropriate: false,
      livingStatus: "",
      reason: "",
      receivedServicesFromAnotherProviderSinceDischarge: false,
      receivedServicesSinceDischarge: false,
      schoolStatus: "",
      seenImprovement: "",
    });
  };

  const [addFailedFollowUp, , getAddFailedFollowUpRequestState] = useRequest(
    addFailedFollowUpDataRequest
  );
  const [addFollowUpCompletion, , getAddFollowUpCompletionRequestState] =
    useRequest(addFollowUpCompletionDataRequest);

  const handleOnSubmit = handleSubmit(
    async (formData: AddFollowUpDataFormData) => {
      if (formData.outcome === "failed") {
        const dto = {
          reason: formData.reason as UnableToContactReasonsEnum,
          attemptedOn: formData.attemptedAt,
        };

        await addFailedFollowUp({ id: followUp.id, dto });
      } else {
        const dto = {
          completedOn: formData.attemptedAt,
          livingStatus: formData.livingStatus as LivingSituationEnum,
          isYouthsLivingStatusAppropriate:
            formData.isYouthsLivingStatusAppropriate,
          schoolStatus: formData.schoolStatus as SchoolStatusEnum,
          receivedServicesFromAnotherProviderSinceDischarge:
            formData.receivedServicesFromAnotherProviderSinceDischarge,
          receivedServicesSinceDischarge:
            formData.receivedServicesSinceDischarge,
          seenImprovement: formData.seenImprovement,
        };
        await addFollowUpCompletion({ id: followUp.id, dto });
      }
      clearForm();
      await onSubmit();
    }
  );

  const addFollowUpDataRequestState: RequestStateDto = {
    isLoading:
      getAddFailedFollowUpRequestState.isLoading ||
      getAddFollowUpCompletionRequestState.isLoading,
    error:
      getAddFailedFollowUpRequestState.error ||
      getAddFollowUpCompletionRequestState.error,
  };

  return {
    state: {
      intervalSelectOptions,
      unableToContactReasonOptions,
      livingSituationOptions,
      schoolStatusOptions,
      followUpOutcomeOptions,
      addFollowUpDataRequestState,
      followUpImprovementOptions
    },
    form: { isValid, control, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
