import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  OutreachActivityFilterFormDto,
  OutreachActivitySearchParamsDto,
} from "../dtos/OutreachActivityDto";

export const useOutreachActivityFilter = (
  onSubmit: (dto: OutreachActivitySearchParamsDto) => void,
  onClose: () => void,
  params: OutreachActivitySearchParamsDto
) => {
  //#region State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: new OutreachActivityFilterFormDto(params),
  });
  //#endregion

  //#region Handlers
  const handleOnSubmit = handleSubmit((dto: OutreachActivityFilterFormDto) => {
    const newParams: OutreachActivitySearchParamsDto =
      new OutreachActivitySearchParamsDto({
        form: dto,
        previous: params,
      });
    onSubmit(newParams);
    onClose();
  });
  const clearAllFilters = () => {
    reset(new OutreachActivityFilterFormDto());
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(new OutreachActivityFilterFormDto(params));
  }, [params, reset]);

  //#endregion

  return {
    form: { control, clearAllFilters },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
