import { ParsedQuery } from "query-string";

export interface ConfirmEmailDto {
  email: string;
  token: string;
  returnPasswordToken?: boolean;
}

export const createConfirmEmailDtoFromQuery = (
  query: ParsedQuery<string>
): ConfirmEmailDto => {
  const { email, token, resetPassword } = query;

  return {
    email: email as string,
    token: token as string,
    returnPasswordToken: (resetPassword as string)?.toLowerCase() === "true",
  };
};
