import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { locationSchema } from "../components/LocationFormFields";
import { LocationDto } from "../dtos/LocationDto";
import {
  createLocationFormDto,
  LocationFormDto,
} from "../dtos/LocationFormDto";

export const useLocationForm = (
  onSubmit: (dto: LocationFormDto) => Promise<any>,
  location?: LocationDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createLocationFormDto(location),
    resolver: yupResolver(locationSchema),
    mode: "all",
  });

  //#endregion
  const clearForm = () => {
    reset(createLocationFormDto(location));
  };

  const handleOnSubmit = handleSubmit(async (dto: LocationFormDto) => {
    await onSubmit(dto);
  });

  //#region Effects
  useEffect(() => {
    reset(createLocationFormDto(location));
  }, [reset, location]);
  //#endregion

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
