import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import { ParsedQuery } from "query-string";
import React from "react";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import {
  createResetPasswordFormDto,
  ResetPasswordFormDto,
} from "../dtos/ResetPasswordFormDto";
import PasswordResetFormFields, {
  passwordResetSchema,
} from "./PasswordResetFormFields";

interface Props {
  onSubmit: (dto: ResetPasswordFormDto, callback?: () => void) => void;
  query: ParsedQuery<string>;
  isLoading: boolean;
}

const PasswordResetForm: React.FC<Props> = ({ query, onSubmit, isLoading }) => {
  //#region State
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    defaultValues: createResetPasswordFormDto(query),
    resolver: yupResolver(passwordResetSchema),
    mode: "all",
  });
  //#endregion

  //#region Handlers
  const handleFormSubmit = (dto: ResetPasswordFormDto) => {
    onSubmit(dto);
  };
  //#endregion

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={1}>
        <PasswordResetFormFields control={control} />
        <Grid item md={12} xs={12}>
          <SpinnerButton
            content="Submit"
            showSpinner={isLoading}
            disabled={isValid === false}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordResetForm;
