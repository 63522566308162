import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { fnyfsProviderId } from "../../authentication/constants";
import {
  getProviderId,
} from "../../authentication/state/authenticationActions";
import {useGetAllProviders, useGetProviderPrograms} from "../../provider/hooks/providerHooks";
import {SatisfactionSurveyExportParametersDto} from "../dtos/SatisfactionSurveyExportParametersDto";
import {useGetProgramTypes} from "../../program-types/hooks/programTypeHooks";

export const useSatisfactionSurveyExportReportFilter = (
  onSubmit: (dto: SatisfactionSurveyExportParametersDto) => Promise<void>,
  params: SatisfactionSurveyExportParametersDto
) => {
  //#region State
  const {
    control,
    reset,
    getValues,
    formState: { isValid },
  } = useForm({
    defaultValues: new SatisfactionSurveyExportParametersDto(),
    resolver: yupResolver(SatisfactionSurveyExportParametersDto.getSchema()),
    mode: "all",
  });

  const providerId = getProviderId();

  const [getAllProviders, providers, getAllProvidersRequestState] =
    useGetAllProviders();

  const [getProgramTypes, programTypes, programTypesRequestState] = useGetProgramTypes();
  const [getProgramsByProviderId, programs, programsRequestState] = useGetProviderPrograms()

  //#endregion

  //#region Handlers

  const handleDownload = async () => {
    const values = getValues();
    await onSubmit(values);
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    reset(params);
  }, [params, reset]);

  useEffect(() => {
    getProgramTypes(null);
  }, [getProgramTypes])

  useEffect(() => {
    if (getProviderId() === fnyfsProviderId)
      getAllProviders({
        programTypes: [],
      });
  }, [getAllProviders]);

  useEffect(() => {
    if(providerId && providerId !== fnyfsProviderId) {
      getProgramsByProviderId(providerId);
    }
  }, [providerId, getProgramsByProviderId])

  //#endregion

  return {
    state: {
      getAllProvidersRequestState,
      providers: providers || [],
      programTypes: programTypes || [],
      programTypesRequestState,
      programs: programs || [],
      programsRequestState,
      isValid,
    },
    form: { control },
    handlers: { handleDownload },
  };
};
