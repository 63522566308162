import { useRequest } from "../../../app/hooks/useRequest";
import {
  createScreeningWaitlistRequest,
  deleteScreeningRequest,
  getScreeningByIdRequest,
  getScreeningsRequest,
  updateScreeningRequest,
} from "../ScreeningRequests";

export const useGetScreenings = () => useRequest(getScreeningsRequest);

export const useGetScreeningById = () => useRequest(getScreeningByIdRequest);

export const useCreateScreeningWaitlist = () =>
  useRequest(createScreeningWaitlistRequest);

export const useUpdateScreening = () => useRequest(updateScreeningRequest);

export const useDeleteScreening = () => useRequest(deleteScreeningRequest);