import { StaffMemberDto } from "./StaffMemberDto";

export interface StaffMemberFormDto {
  firstName: string;
  lastName: string;
}

export const createStaffMemberFormDto = (
  dto?: StaffMemberDto
): StaffMemberFormDto => {
  if (dto) {
    const { firstName, lastName } = dto;
    return { firstName, lastName };
  }

  return { firstName: "", lastName: "" };
};
