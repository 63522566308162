export enum PermissionResourceNameEnum {
  Youth = "Youth",
  User = "User",
  Provider = "Provider",
  Program = "Program",
  ChangeOtherEmail = "ChangeOtherEmail",
  Case = "Case",
  Screening = "Screening",
  StaffMember = "StaffMember",
  FiscalYear = "FiscalYear",
  ProgramLocation = "ProgramLocation",
  Location = "Location",
  FiscalYearLicensedBed = "FiscalYearLicensedBed",
  FiscalYearContractDeliverable = "FiscalYearContractDeliverable",
  DeliverableRate = "DeliverableRate",
  ProviderInvoice = "ProviderInvoice",
  FnyfsInvoice = "FnyfsInvoice",
  Referrals = "Referrals",
  ReturnProviderInvoice = "ReturnProviderInvoice",
  ChangeCaseDeliverables = "ChangeCaseDeliverables",
  Waitlist = "Waitlist",
  SnapInSchools = "SnapInSchools",
  InvoicingEventLog = "InvoicingEventLog",
  Snap = "Snap",
  CaseStaffing = "CaseStaffing",
  Assessment = "Assessments",
  ServicePlan = "ServicePlan",
  OutreachActivities = "OutreachActivities",
  UnlockAccount = "UnlockAccount",
  ViewUserPin = "ViewUserPin",
  FilledBedDays = "FilledBedDays",
  ForceCreateCase = "ForceCreateCase",
  DjjEntries = "DjjEntries",
  GetEmailConfirmationLink = "GetEmailConfirmationLink",
  DataExport = "DataExport",
  TransferCase = "TransferCase",
  Nirvana = "Nirvana",
}
