import { coreAgent } from "../../app/api/agent";
import { YouthContactDetailDto } from "./dtos/YouthContactDetailDto";
import { YouthContactFormDto } from "./dtos/YouthContactFormDto";
import { YouthContactSummaryDto } from "./dtos/YouthContactSummaryDto";

interface UpdateYouthContactRequestParams {
  id: string;
  dto: YouthContactFormDto;
}

export const getYouthContactRequest = (id: string) =>
  coreAgent.getRequest<YouthContactDetailDto>(`youthContacts/${id}`);

export const updateYouthContactRequest = ({
  id,
  dto,
}: UpdateYouthContactRequestParams) =>
  coreAgent.putRequest<YouthContactSummaryDto>(`youthContacts/${id}`, dto);

export const deleteYouthContactRequest = ({ id }: { id: string }) =>
  coreAgent.deleteRequest<void>(`youthContacts/${id}`);
