import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../../../app/components/Header";
import InfoTooltip from "../../../app/components/InfoTooltip";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import { getProviderId } from "../../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../../authentication/constants";
import { useRequest } from "../../../app/hooks/useRequest";
import { getDataExportRequest } from "../ReportRequests";
import ErrorText from "../../../app/components/ErrorText";
import { DateTime } from "luxon";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import AccessLevelWrapper from "../../../app/components/access-wrappers/AccessLevelWrapper";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { AccessLevelEnum } from "../../authentication/enums/AccessLevelEnum";
import { usePrimeFunctionRequest } from "../hooks/reportHooks";

interface LinkProps {
  label: string;
  pathname: string;
  disabled?: boolean;
  hidden?: boolean;
}

interface ReportSectionProps {
  id: string;
  name: string;
  links: LinkProps[];
  info?: string;
}

const getReportSections: (params: {
  isFloridaNetwork: boolean;
}) => ReportSectionProps[] = ({ isFloridaNetwork }) => [
  {
    id: "general",
    name: "General Reports",
    links: [
      {
        label: "ICM Activity Report",
        pathname: "/reports/icm-activity",
      },
      {
        label: "Screenings",
        pathname: "/reports/screenings",
      },
    ],
  },
  {
    id: "end-of-month",
    name: "End of Month Reports",
    links: [
      {
        label: "Report Card",
        pathname: "/reports/end-of-month",
      },
      {
        label: "Report Card by Provider",
        pathname: "/reports/end-of-month-by-provider",
      },
      {
        label: "Monthly Deliverables",
        pathname: "/reports/monthly-deliverables-report",
      },
      {
        label: "Monthly Corrections",
        pathname: "/reports/monthly-corrections-report",
      },
      {
        label: "Benchmarks",
        pathname: "/reports/benchmarks-report",
      },
      {
        label: "Bed Utilization",
        pathname: "/reports/bed-utilization-report",
      },
    ],
  },
  {
    id: "nirvana",
    name: "NIRVANA Reports",
    links: [
      {
        label: "NIRVANA Export",
        pathname: "/reports/nirvana-export",
        disabled: false,
      },
      {
        label: "NIRVANA Impact",
        pathname: "/reports/nirvana-impact",
        disabled: false,
      },
      {
        label: "NIRVANA Averages",
        pathname: "/reports/nirvana-averages",
        disabled: false,
      },
    ],
    info: "NIRVANA Comparison Report for a Case is in the Case's Supplemental Info tab.",
  },
];

const ReportsPage = () => {
  const [getDataExport, , dataExportRequestState] =
    useRequest(getDataExportRequest);

  const [primeReportFunction] = usePrimeFunctionRequest();
  useEffect(() => {
    primeReportFunction(null);
  }, [primeReportFunction]);

  const handleDataExportDownloadClick = async () => {
    const filename = `${DateTime.now().toFormat("yyyyLLddHHmmss")}.zip`;
    await getDataExport(filename);
  };

  const renderSection = (props: ReportSectionProps) => {
    return (
      <Grid item md={6} xs={12} key={props.id}>
        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">{props.name}</Typography>
            {props.info && (
              <InfoTooltip
                style={{ marginLeft: "4px" }}
                title={<Typography>{props.info}</Typography>}
              />
            )}
          </Box>
          <Divider />
          <List dense>
            {props.links
              .filter((l) => !l.hidden)
              .map(({ label, ...props }) => (
                <ListItem disableGutters key={label}>
                  <RouteableStyledLink text="Back to Reports" {...props}>
                    {label}
                  </RouteableStyledLink>
                </ListItem>
              ))}
          </List>
        </Box>
      </Grid>
    );
  };

  const renderDataExportsSection = () => {
    return (
      <Grid item md={6} xs={12}>
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Data Exports</Typography>
          </Box>
          <Divider />
          <List dense>
            <AccessLevelWrapper
              name={PermissionResourceNameEnum.DataExport}
              accessLevel={AccessLevelEnum.Execute}
            >
            <ListItem disableGutters>
              <Box display="flex" alignItems="center">
                <Link
                  style={{
                    color: ColorsEnum.Blue,
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onClick={handleDataExportDownloadClick}
                >
                  Provider Data Export
                </Link>
                <Typography className="ml-2">
                  (click to download CSV)
                </Typography>
              </Box>
              {dataExportRequestState.isLoading && (
                <CircularProgress variant={"indeterminate"} size={"1rem"} />
              )}
              {!dataExportRequestState.isLoading &&
                dataExportRequestState.error && (
                  <ErrorText error={dataExportRequestState.error.message} />
                )}
            </ListItem>
            </AccessLevelWrapper>
            <ListItem disableGutters>
              <RouteableStyledLink text="Back to Reports"  id="satisfactionSurveysExport" pathname={"/reports/export-satisfaction-surveys"} >
                Satisfaction Surveys Export
              </RouteableStyledLink>
            </ListItem>
            <ListItem disableGutters>
              <RouteableStyledLink text="Back to Reports"  id="SNAPExport" pathname={"/reports/export-snap-sis"} >
                SNAP/SIS Export
              </RouteableStyledLink>
            </ListItem>
          </List>
        </Box>
      </Grid>
    );
  };

  const providerId = getProviderId();
  const isFloridaNetwork = providerId === fnyfsProviderId;
  const reportSections = getReportSections({
    isFloridaNetwork: providerId == null || isFloridaNetwork,
  });

  return (
    <div>
      <Header title="Reports" />
      <Paper style={{ padding: "16px" }} variant="outlined">
        <Grid container spacing={3}>
          {reportSections.map(renderSection)}
          {renderDataExportsSection()}
        </Grid>
      </Paper>
    </div>
  );
};

export default ReportsPage;
