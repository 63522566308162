import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "../../features/authentication/state/authenticationActions";
import {
  selectAuthenticationInitialzed,
  selectIsLoggedIn,
} from "../../features/authentication/state/authenticationSelectors";
import NotificationTypeEnum from "../../features/notifications/enums/NotificationTypeEnum";
import { useAppDispatch, useAppSelector } from "../hooks";
import { InactivityTimeout } from "../hooks/useTimeout";

const inactivityDuration = +(
  process.env.REACT_APP_INACTIVITY_LOGOUT_DURATION || 20
);
const AuthenticatedActivityWrapper = () => {
  const [timeout] = useState(
    new InactivityTimeout(
      () => {
        dispatch(logout());

        const message = encodeURI(
          "You have been logged out due to inactivity."
        );

        history.push(
          `/auth/logout?message=${message}&level=${NotificationTypeEnum.Danger}&loginPageMessage=${message}`
        );
      },
      inactivityDuration,
      [
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
        "wheel",
        "keydown",
        "DOMMouseScroll",
        "mouseWheel",
        "touchstart",
        "touchmove",
        "MSPointerDown",
        "MSPointerMove",
        "visibilitychange",
      ]
    )
  );
  const dispatch = useAppDispatch();
  const authenticationInitialzed = useAppSelector(
    selectAuthenticationInitialzed
  );
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const history = useHistory();

  useEffect(() => {
    if (authenticationInitialzed && isLoggedIn) timeout.startInterval();
    else if (authenticationInitialzed && !isLoggedIn) timeout.cleanUp();
  }, [authenticationInitialzed, isLoggedIn, timeout]);

  useEffect(() => {
    return timeout.cleanUp;
  }, [timeout]);

  return <></>;
};

export default AuthenticatedActivityWrapper;
