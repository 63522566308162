import {NirvanaAveragesOrImpactReportParameters} from "../dtos/NirvanaAveragesOrImpactReportParameters";
import {useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {useEffect} from "react";
import {useGetProgramTypes} from "../../program-types/hooks/programTypeHooks";
import {
  useGetAllProviders,
  useGetProviderLocations,
  useGetProviderPrograms,
  useGetProviderStaffMembers
} from "../../provider/hooks/providerHooks";
import {getProviderId} from "../../authentication/state/authenticationActions";
import {fnyfsProviderId} from "../../authentication/constants";
import { useRequest } from "../../../app/hooks/useRequest";
import { getContractsRequest } from "../../contracts/ContractRequests";

export const useNirvanaAveragesOrImpactReportFilter = (
  onSubmit: (dto: NirvanaAveragesOrImpactReportParameters) => Promise<void>,
  params: NirvanaAveragesOrImpactReportParameters
) => {

  const providerId = getProviderId();

  const {
    control,
    reset,
    getValues,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    defaultValues: new NirvanaAveragesOrImpactReportParameters(),
    resolver: yupResolver(NirvanaAveragesOrImpactReportParameters.getSchema()),
    mode: "all",
  });

  const handleDownload = handleSubmit (async() => {
    const values = getValues();
    await onSubmit(values);
  });

  const [getContracts, contracts, contractsRequestState] =  useRequest(getContractsRequest);
  const [getProgramTypes, programTypes, programTypesRequestState] = useGetProgramTypes();
  const [getProviders, providers, providersRequestState] = useGetAllProviders();
  const [getProgramsByProviderId, programs, programsRequestState] = useGetProviderPrograms()
  const [getProviderLocations, locations, locationsRequestState] = useGetProviderLocations();
  const [getProviderStaffMembers, staffMembers, staffMembersRequestState] = useGetProviderStaffMembers()
  //#region Effects

  useEffect(() => {
    reset(new NirvanaAveragesOrImpactReportParameters(params));
  }, [params, reset]);

  useEffect(() => {
    getProgramTypes(null);
  }, [getProgramTypes])

  useEffect(() => {
    getProviders(undefined);
  }, [getProviders])

  useEffect(() => {
    if(providerId && providerId !== fnyfsProviderId) {
      getProgramsByProviderId(providerId);
      getProviderLocations(providerId)
      getProviderStaffMembers(providerId);
    } 
  }, [providerId, getProgramsByProviderId, getProviderLocations, getProviderStaffMembers])

  useEffect(() => {
    if(!providerId || providerId === fnyfsProviderId) {
      getContracts(undefined);
      getProviders(undefined);
    } 
  }, [providerId, getContracts, getProviders])
  
  //#endregion

  return {
    state: {
      isValid,
      contracts: contracts || [],
      contractsRequestState,
      programTypes: programTypes || [],
      programTypesRequestState,
      providers: providers || [],
      providersRequestState,
      programs: programs || [],
      programsRequestState,
      locations: locations || [],
      locationsRequestState,
      counselors: staffMembers || [],
      counselorsRequestState: staffMembersRequestState
    },
    form: { control },
    handlers: { handleDownload }
  };

}
