import React, { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import ControlledSelect, {
  SelectOption,
} from "../../../app/components/inputs/ControlledSelect";
import { screeningIsDvRespite } from "../../../app/helpers";
import { ScreeningDto, screeningsToOptions } from "../dtos/ScreeningDto";

interface Props {
  control: Control<any>;
  screenings: ScreeningDto[];
  programIsDvRespite: boolean;
  screeningId: string;
  disabled: boolean;
  isLoading: boolean;
  name: string;
}

const ScreeningSelect: React.FC<Props> = ({
  control,
  screeningId,
  screenings,
  programIsDvRespite,
  disabled,
  isLoading,
  name,
}) => {
  const [filteredScreeningOptions, setFilteredScreeningOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    const shouldFilter =
      programIsDvRespite &&
      (screeningId === "" || screeningIsDvRespite(screeningId!, screenings));
    setFilteredScreeningOptions(screeningsToOptions(screenings, shouldFilter));
  }, [
    screenings,
    programIsDvRespite,
    screeningId,
    setFilteredScreeningOptions,
  ]);
  return (
    <ControlledSelect
      name={name}
      label={`Screening${
        programIsDvRespite
          ? " (DV Respite Screening required for DV Respite Intake)"
          : ""
      }`}
      control={control}
      disabled={disabled}
      options={filteredScreeningOptions}
      fullWidth
      isLoading={isLoading}
    />
  );
};

export default ScreeningSelect;
