import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { CaseStaffingDto } from "../dtos/CaseStaffingDto";
import { CaseStaffingFormDto } from "../dtos/CaseStaffingFormDto";
import { useCaseStaffingForm } from "../hooks/useCaseStaffingForm";
import CaseStaffingFormFields from "./CaseStaffingFormFields";

export const caseStaffingModalId = "case-staffing-form-modal";

interface Props {
  onSubmit: (dto: CaseStaffingFormDto) => Promise<any>;
  requestState: RequestStateDto;
  caseStaffing?: CaseStaffingDto;
}

const CaseStaffingFormDialog: React.FC<Props> = ({
  requestState,
  caseStaffing,
  onSubmit,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useCaseStaffingForm(onSubmit, caseStaffing);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === caseStaffingModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {caseStaffing ? "Edit" : "Create"} Case Staffing
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography>
                Please only enter data that pertains to statutorily governed
                Case Staffing events. Please reference{" "}
                <a
                  href="http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&Search_String=&URL=0900-0999/0984/Sections/0984.12.html"
                  rel="noreferrer"
                  target="_blank"
                >
                  FL Statute 984.12
                </a>{" "}
                for additional information. (Do not enter data for “generic” or
                other types of case staffing meetings your Agency might
                conduct.)
              </Typography>
            </Grid>
            <CaseStaffingFormFields control={control} isDisabled={isLoading} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CaseStaffingFormDialog;
