import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import NameLabel from "../../../app/components/labels/NameLabel";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import { ReferralDto } from "../../referrals/dtos/ReferralDto";
import { ReferralSummaryDto } from "../../referrals/dtos/ReferralSummaryDto";
import { referralStatusTextMap } from "../../referrals/enums/ReferralStatusEnum";

interface Props {
  referral: ReferralDto | ReferralSummaryDto;
}

export const ReferralRemovedLabel: React.FC<Props> = ({ referral }) => {
  return (
    <Box style={{ cursor: "help" }} component="span">
      <Tooltip
        title={
          <Fragment>
            <Typography component="span">
              Reason: <strong>{referral.removalReason}</strong>
            </Typography>
            <br />
            <Typography component="span">
              Removed By:{" "}
              <NameLabel
                style={{ fontWeight: "bold" }}
                component="span"
                nameDto={referral.removedBy}
              />
            </Typography>
            <br />
            <Typography component="span">
              Removed At:{" "}
              <strong>
                <DateTimeLabel date={referral.removedAt} />
              </strong>
            </Typography>
          </Fragment>
        }
      >
        <Box component="span">
          <RedAlertLabel
            label={referralStatusTextMap[referral.status]}
            component="span"
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
