import React from "react";
import { NumericValueMetric } from "../dtos/MainDashboardMetricsDto";
import { Box, Paper, Typography } from "@material-ui/core";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import { InfoPopover } from "../../../app/components/InfoPopover";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

export const NumericValueMetricPanel: React.FC<{
  metric?: NumericValueMetric;
  isLoading?: boolean;
}> = ({ metric, isLoading }) => {
  const location = useLocation();

  const renderContent = () => {
    if (isLoading || !metric)
      return (
        <>
          <Box
            display={"flex"}
            flex={0}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography variant={"subtitle2"}>
              <Skeleton animation="wave" width={100} />
            </Typography>
            <Typography variant={"subtitle2"}>
              <Skeleton animation="wave" width={20} />
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant={"h3"}>
              <Skeleton animation="wave" width={30} />
            </Typography>
          </Box>
        </>
      );

    return (
      <>
        <Box
          display={"flex"}
          flex={0}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant={"subtitle2"}>{metric.title}</Typography>
          <Typography variant={"subtitle2"}>
            {metric.description && (
              <InfoPopover style={{ color: "lightgray" }}>
                <div>{metric.description}</div>
              </InfoPopover>
            )}
          </Typography>
        </Box>
        <Box flex={1}>
          {metric.linkId && (
            <Typography variant={"h3"}>
              <RouteableStyledLink
                text={"Back to Dashboard"}
                pathname={`/dashboards/data/${metric.linkId}`}
                search={location.search}
              >
                {metric.value.toLocaleString()}
              </RouteableStyledLink>
            </Typography>
          )}
          {!metric.linkId && (
            <Typography variant={"h3"}>
              {metric.value.toLocaleString()}
            </Typography>
          )}
        </Box>
      </>
    );
  };
  return (
    <Paper variant="outlined" style={{ flex: 1, borderRadius: 0 }}>
      <Box display={"flex"} flexDirection={"column"} style={{ padding: 16 }}>
        {renderContent()}
      </Box>
    </Paper>
  );
};
