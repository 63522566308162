import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { ProgramTypeSummaryDto } from "../../program-types/dtos/ProgramTypeSummaryDto";
import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import { SnapGroupCycleFormDto } from "../dtos/SnapGroupCycleFormDto";
import { useSnapCycleForm } from "../hooks/useSnapCycleForm";
import SnapCycleFormFields from "./SnapCycleFormFields";

export const snapGroupCycleModalId = "snap-group-cycle-form-modal";

interface Props {
  snapCycle?: SnapGroupCycleDto;
  onSubmit: (dto: SnapGroupCycleFormDto) => Promise<any>;
  requestState: RequestStateDto;
  programTypes: ProgramTypeSummaryDto[];
  programTypesAreLoading: boolean;
}

const SnapGroupCycleFormDialog: React.FC<Props> = ({
  snapCycle,
  onSubmit,
  requestState,
  programTypes,
  programTypesAreLoading,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();

  const { form, handlers } = useSnapCycleForm(onSubmit, snapCycle);
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;

  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === snapGroupCycleModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {snapCycle ? "Edit" : "Create"} SNAP Group Cycle
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <SnapCycleFormFields
              control={control}
              programTypes={programTypes}
              programTypesAreLoading={programTypesAreLoading}
              isDisabled={isLoading}
              isEditing={snapCycle != null}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading || programTypesAreLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SnapGroupCycleFormDialog;
