import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import SortableTable, {
  ColumnProps,
  SortableTableProps,
} from "../../../app/components/SortableTable";
import { UserDto } from "../../authentication/dtos/UserDto";
import { UserSearchParamsDto } from "../dtos/UserSearchParamsDto";
import LockIcon from "@material-ui/icons/Lock";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import ProviderAccessWrapper from "../../../app/components/access-wrappers/ProviderAccessWrapper";
const UserList: React.FC<SortableTableProps<UserDto, UserSearchParamsDto>> = (
  props
) => {
  const columns: ColumnProps[] = [
    { name: "firstName", label: "First Name" },
    { name: "lastName", label: "Last Name" },
    { name: "email", label: "Email" },
    { name: "userRoles", label: "Roles", disabled: true },
    { name: "", label: "", disabled: true },
  ];

  const renderRow = (user: UserDto) => (
    <TableRow key={user.id} style={{ verticalAlign: "top" }}>
      <TableCell>
        <RouteableStyledLink
          text="Back to users"
          pathname={`/users/${user.id}`}
        >
          {user.firstName}
        </RouteableStyledLink>
      </TableCell>
      <TableCell>
        <RouteableStyledLink
          text="Back to users"
          pathname={`/users/${user.id}`}
        >
          {user.lastName}
        </RouteableStyledLink>
      </TableCell>
      <TableCell valign="top">{user.email}</TableCell>
      <TableCell valign="top">
        <FnAccessWrapper>
          <>
            {user.userRoles
              ?.sort((a, b) => {
                return a.name > b.name ? -1 : 1;
              })
              .slice(0, 3)
              .map((ur) => (
                <p style={{ margin: "0px" }} key={ur.provider.id}>
                  {ur.provider.name} - {ur.name}
                </p>
              ))}

            {!!user.userRoles && user.userRoles?.length > 3 && (
              <>
                <RouteableStyledLink
                  text="Back to users"
                  pathname={`/users/${user.id}`}
                >
                  more...
                </RouteableStyledLink>
              </>
            )}
          </>
        </FnAccessWrapper>
        <ProviderAccessWrapper>
          {user.userRoles?.map((ur) => (
            <span key={ur.provider.id}>{ur.name}</span>
          ))}
        </ProviderAccessWrapper>
      </TableCell>
      <TableCell valign="top">
        <Box display="flex">
          {user.accountIsLocked && (
            <Tooltip title={<Typography>Account Locked</Typography>}>
              <div>
                <LockIcon style={{ color: ColorsEnum.Red }} />
              </div>
            </Tooltip>
          )}

          {user.isInactive && (
            <Tooltip title={<Typography>Account Inactive</Typography>}>
              <RemoveCircleIcon style={{ color: ColorsEnum.Red }} />
            </Tooltip>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );

  return (
    <TableContainer component={Paper}>
      <SortableTable {...props} columns={columns} renderRow={renderRow} />
    </TableContainer>
  );
};

export default UserList;
