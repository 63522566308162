import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import ControlledSelect from "../../../app/components/inputs/ControlledSelect";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { RemoveWaitlistFormDto } from "../dtos/RemoveWaitlistFormDto";
import { removalReasonOptions } from "../enums/RemovalReasonEnum";
import { useRemoveWaitlistForm } from "../hooks/useRemoveWaitlistForm";

export const removeWaitlistModalId = "remove-waitlist-form-modal";

interface Props {
  onSubmit: (dto: RemoveWaitlistFormDto) => Promise<void>;
  requestState: RequestStateDto;
}

const RemoveWaitlistFormDialog: React.FC<Props> = ({
  onSubmit,
  requestState,
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const {
    form: { isValid, control, clearForm },
    handlers: { handleSubmit },
  } = useRemoveWaitlistForm(onSubmit);
  const { isLoading, error } = requestState;
  //#endregion

  //#region
  const handleCloseModal = () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === removeWaitlistModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Remove Waitlist
          <ErrorText error={error?.message} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <ControlledSelect
                control={control}
                name="removalReason"
                options={removalReasonOptions}
                disabled={isLoading}
                label="Removal Reason"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
            disabled={isLoading}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RemoveWaitlistFormDialog;
