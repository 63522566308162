import { useRequest } from "../../../app/hooks/useRequest";
import {
  createProgramExistingLocationRequest,
  createProgramNewLocationRequest,
  terminateProgramLocationRequest,
  updateLocationRequest,
  updateProgramLocationRequest,
} from "../LocationRequests";

export const useCreateProgramLocationNew = () =>
  useRequest(createProgramNewLocationRequest);
export const useCreateProgramLocationExisting = () =>
  useRequest(createProgramExistingLocationRequest);

export const useTerminateProgramLocation = () =>
  useRequest(terminateProgramLocationRequest);

export const useUpdateProgramLocation = () =>
  useRequest(updateProgramLocationRequest);

export const useUpdateLocation = () => useRequest(updateLocationRequest);
