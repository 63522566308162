import { Typography } from "@material-ui/core";
import React from "react";
import { ColorsEnum } from "../../../app/enums/ColorsEnum";
import CaseDeletedLabel from "../../youths/components/labels.tsx/CaseDeletedLabel";
import { CaseDetailDto } from "../dtos/CaseDetailDto";
import { calculateCaseStatus, CaseStatusEnum } from "../enums/CaseStatusEnum";
interface Props {
  caseDto: CaseDetailDto;
}

const CaseStatusLabel: React.FC<Props> = ({ caseDto }) => {
  const getBackgroundColor = () => {
    const status = calculateCaseStatus(caseDto);
    switch (status) {
      case CaseStatusEnum.Open:
        return ColorsEnum.Green;
      case CaseStatusEnum.Closed:
        return ColorsEnum.Red;
      case CaseStatusEnum.OnHold:
        return ColorsEnum.Yellow;
      default:
        return "";
    }
  };

  const getColor = () => {
    const status = calculateCaseStatus(caseDto);
    switch (status) {
      case CaseStatusEnum.Open:
        return "white";
      case CaseStatusEnum.Closed:
        return "white";
      case CaseStatusEnum.OnHold:
        return "black";
      default:
        return "";
    }
  };

  const getLabel = () => {
    const status = calculateCaseStatus(caseDto);
    switch (status) {
      case CaseStatusEnum.Open:
        return "Open";
      case CaseStatusEnum.Closed:
        return "Closed";
      case CaseStatusEnum.OnHold:
        return "On Hold";
      default:
        return "";
    }
  };

  if (caseDto.deletedAt !== undefined)
    return <CaseDeletedLabel isDeleted={true} reason={caseDto.deleteReason} />;

  return (
    <Typography
      style={{
        padding: "0px 12px",
        fontWeight: "bold",
        borderRadius: "12px",
        background: getBackgroundColor(),
        color: getColor(),
      }}
    >
      {getLabel()}
    </Typography>
  );
};

export default CaseStatusLabel;
