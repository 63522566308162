export interface InvoiceSearchParamsDto {
  providerId?: string;
  deliverableType?: string;
  year?: number;
}

export const invoiceSearchParamsToQueryString = (
  dto: InvoiceSearchParamsDto
) => {
  const queryString: string[] = [];

  if (dto.providerId) queryString.push(`providerId=${dto.providerId}`);
  if (dto.deliverableType)
    queryString.push(`deliverableType=${dto.deliverableType}`);
  if (dto.year)
    queryString.push(`year=${dto.year}`);

  return queryString.join("&");
};
