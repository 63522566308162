import {Box, Grid, Paper, Typography} from "@material-ui/core";
import React, {Fragment} from "react";
import KeyValueDetails, {RowProps,} from "../../../app/components/KeyValueDetails";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import NameLabel from "../../../app/components/labels/NameLabel";
import PhoneNumberLabel from "../../../app/components/labels/PhoneNumberLabel";
import YesNoLabel from "../../../app/components/labels/YesNoLabel";
import RouteableStyledLink from "../../../app/components/RouteableStyledLink";
import {TabProps} from "../../../app/components/TabContainer";
import {ContentSection} from "../../../service-planning/components/ContentSection";
import ReferrerRelationshipLabel from "../../referrals/components/ReferrerRelationshipLabel";
import {ReferrerRelationshipEnum} from "../../referrals/enums/ReferrerRelationshipEnum";
import {ScreeningDetailDto} from "../dtos/ScreeningDetailDto";
import LivingSituationLabel from "./LivingSituationLabel";
import ScreeningPlannedActionLabel from "./ScreeningPlannedActionLabel";
import {
  TransferYouthRecordDialog
} from "../../transfer-youth-record/components/transfer-case/TransferYouthRecordDialog";
import ExecuteAccessLevelWrapper from "../../../app/components/access-wrappers/ExecuteAccessLevelWrapper";
import {PermissionResourceNameEnum} from "../../authentication/enums/PermissionResourceNameEnum";

interface Props {
  screening?: ScreeningDetailDto;
  isLoading: boolean;
  refresh: () => Promise<void>;
}

export const screeningDetailsTabName = "screening-details-tab";

const ScreeningDetailsTab: React.FC<Props> = ({ isLoading, screening, refresh }) => {
  const showDetails = isLoading === false && screening !== undefined;

  const createElibilityRow = (isEligible: boolean) => {
    const criteria =
      screening?.cinsFinsEligibilityCriteria.filter(
        (x) => x.isEligible === isEligible
      ) || [];

    const value = (
      <Fragment>
        {criteria.length === 0 && "None"}
        {criteria.map((criterion) => (
          <Typography key={criterion.id} style={{ whiteSpace: "normal" }}>
            {criterion.value}
          </Typography>
        ))}
      </Fragment>
    );

    if (isEligible) {
      return {
        key: "eligible",
        label: "Eligibility Reasons",
        value,
      };
    } else {
      return {
        key: "ineligible",
        label: "Ineligibility Reasons",
        value,
      };
    }
  };

  const youthInformation: RowProps[] = [
    {
      label: "Youth ID",
      key: "youth-id",
      value: (
        <RouteableStyledLink
          text="Back to Screening"
          pathname={`/youths/${screening?.youth.id}`}
        >
          {screening?.youth.referenceNumber}
        </RouteableStyledLink>
      ),
    },
    {
      key: "youth-name",
      label: "Youth Name",
      value: <NameLabel nameDto={screening?.youth} />,
    },
    {
      key: "youth-djjid",
      label: "Youth DJJ ID",
      value: screening?.youth.djjIdNumber,
      hide: screening?.youth.djjIdNumber == null,
    },
  ];

  const referralInformation: RowProps[] = [
    {
      key: "referrer-name",
      label: "Referrer Name",
      value: <NameLabel nameDto={screening?.referredBy} />,
    },
    {
      key: "relationship",
      label: "Relationship to Youth",
      value: (
        <ReferrerRelationshipLabel
          relationship={
            screening?.referredBy.relationship ||
            ReferrerRelationshipEnum.Employer
          }
        />
      ),
      hide: screening?.referredBy.relationship == null,
    },
    {
      key: "referrer-phone",
      label: "Referrer Phone #",
      value: (
        <PhoneNumberLabel phoneNumber={screening?.referredBy.phoneNumber} />
      ),
    },
    {
      key: "referral-source",
      label: "Referral Source",
      value: screening?.referredBy.source,
    },
    {
      key: "referral-how-did-you-hear",
      label: "How did they hear about us?",
      value: screening?.heardAboutUs,
    },
  ];

  const cinsInformation: RowProps[] = [
    {
      key: "is-cins-eligible",
      label: "Is CINS/FINS Eligible",
      value: <YesNoLabel value={screening?.isCinsFinsEligible || false} />,
    },
    createElibilityRow(true),
    createElibilityRow(false),
    {
      key: "planned-action",
      label: "Planned Action",
      value: <ScreeningPlannedActionLabel action={screening?.plannedAction} />,
      hide: screening?.plannedAction == null,
    },
    {
      key: "comments",
      label: "Comments",
      value: (
        <Paper variant="outlined">
          <Box height="125px" padding="6px" overflow="auto">
            <Typography>{screening?.comments}</Typography>
          </Box>
        </Paper>
      ),
    },
  ];

  const screeningInformation: RowProps[] = [
    {
      key: "screening-number",
      label: "Screening #",
      value: screening?.referenceNumber,
    },
    {
      key: "screening-date",
      label: "Screening Date/Time",
      value: <DateTimeLabel date={screening?.screeningAt} />,
    },
    {
      key: "provider",
      label: "Provider",
      value: screening?.provider.name,
    },
    {
      key: "screening-staff",
      label: "Staff Completing Screening",
      value: <NameLabel nameDto={screening?.completedByStaff} />,
    },
    {
      key: "screening-living-situation",
      label: "Living Situation",
      value: (
        <LivingSituationLabel
          livingSituation={screening?.livingSituation!}
          component="span"
        />
      ),
    },
    {
      key: "screening-youth-zip",
      label: "Youth Residence Zip Code",
      value: screening?.zipCode || "None",
    },
    {
      key: "screening-youth-residence",
      label: "Youth Residence County",
      value: screening?.county,
      hide: screening?.county == null || screening.county === "",
    },
    {
      key: "screening-presenting-problems",
      label: "Presenting Problems",
      value: (
        <Typography style={{ whiteSpace: "normal" }}>
          {screening?.screeningPresentingProblems
            .map((x) => x.problem)
          .join(", ")}
        </Typography>
      ),
    },
    {
      key: "screening-problem-description",
      label: "Problem Description",
      value: (
        <Paper variant="outlined">
          <Box height="125px" padding="6px" overflow="auto">
            <Typography style={{ whiteSpace: "normal" }}>
              {screening?.problemDescription}
            </Typography>
          </Box>
        </Paper>
      ),
    },
  ];

  return (
    <Box position="relative" height="60vh">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <ExecuteAccessLevelWrapper name={PermissionResourceNameEnum.TransferCase}>
            {screening && <TransferYouthRecordDialog currentYouth={screening.youth} recordId={screening.id} recordType="Screening" refresh={refresh} />}
          </ExecuteAccessLevelWrapper>
          <ContentSection
            label="Screening Information"
            content={
              <>
                <KeyValueDetails
                  rows={screeningInformation}
                  isLoading={!showDetails}
                  lgLabelSize={4}
                  mdLabelSize={5}
                />
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <ContentSection
            label="Youth Information"
            content={
              <>
                <KeyValueDetails
                  rows={youthInformation}
                  isLoading={!showDetails}
                  lgLabelSize={4}
                  mdLabelSize={5}
                />
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <ContentSection
            label="Referral Information"
            content={
              <>
                <KeyValueDetails
                  rows={referralInformation}
                  isLoading={!showDetails}
                  lgLabelSize={4}
                  mdLabelSize={5}
                />
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <ContentSection
            label="CINS/FINS Eligibility"
            content={
              <>
                <KeyValueDetails
                  rows={cinsInformation}
                  isLoading={!showDetails}
                  lgLabelSize={4}
                  mdLabelSize={5}
                />
              </>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const createScreeningDetailsTab = (props: Props): TabProps => ({
  value: screeningDetailsTabName,
  label: "Overview",
  content: <ScreeningDetailsTab {...props} />,
});

export default ScreeningDetailsTab;
