import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Typography,
  } from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { CaseContactDto } from "../dtos/CaseContactDto";

export const caseContactNotesModalId = "case-contact-notes-modal";

interface Props {
    contact?: CaseContactDto;
}

const CaseContactNotesDialog: React.FC<Props> = ({contact}) => {

  
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();    
    
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog open={modalId === caseContactNotesModalId} fullWidth maxWidth="sm">
    <form>
        <DialogTitle>
            Case Contact Notes
        </DialogTitle>

        <DialogContent>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                    <Paper
                        variant="outlined"
                        style={{
                        width: "100%",
                        height: "300px",
                        padding: "6px",
                        overflow: "auto",
                        display: "block",
                        }}
                    >
                        <Typography>{contact?.notes}</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </DialogContent>

        <DialogActions>
            <SpinnerButton
                content="Close"
                showSpinner={false}
                type="button"
                onClick={handleCloseModal}
            />
        </DialogActions>
    </form>
    </Dialog>
  );
};
  
export default CaseContactNotesDialog;