import { SelectOption } from "../../../app/components/inputs/ControlledSelect";
import { AssessmentTemplateTagEnum } from "../enums/AssessmentTemplateTagEnum";

export interface AssessmentTemplateSummaryDto {
  id: string;
  name: string;
  shortName: string;
  tags: AssessmentTemplateTagEnum[];
  order: number;
}

export const assessmentTemplateToOption = (
  dto: AssessmentTemplateSummaryDto
): SelectOption => ({ value: dto.id, label: dto.name });

export const assessmentTemplatesToOptions = (
  dtos: AssessmentTemplateSummaryDto[]
): SelectOption[] => [
  { value: "", label: "Assessment Type" },
  ...dtos
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(assessmentTemplateToOption),
];
