import React from "react";
import { Box, Grid } from "@material-ui/core";
import { PaperMetricContainer } from "../PaperMetricContainer";
import { DataEntryMetricPanel } from "../DataEntryMetricPanel";
import { NumericValueMetricPanel } from "../NumericValueMetricPanel";
import { FilledBedDaysMetricPanel } from "../FilledBedDaysMetricPanel";
import { useDashboardContext } from "../../DashboardContext";

export const metricIds = [
  "respiteIntakesDataEntry",
  "respiteDischargesDataEntry",
  "dvFyracIntakes",
  "dvFyracGroupSessions",
  "dvFyracIndividualSessions",
  "prFyracIntakes",
  "prFyracGroupSessions",
  "prFyracIndividualSessions",
  "dvRespiteFilledBedDays",
  "prRespiteFilledBedDays",
] as const;

export type RespiteMetricsDto = {
  [metricId in typeof metricIds[number]]: any;
};

interface Props {
  metrics?: RespiteMetricsDto;
  isLoading: boolean;
}

export const RespiteDashboardMetrics: React.FC<Props> = ({
  metrics,
  isLoading,
}) => {
  const { provider } = useDashboardContext();

  return (
    <>
      <Grid item sm={12} md={4}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%" }}
        >
          <PaperMetricContainer>
            <DataEntryMetricPanel
              intakesMetric={metrics?.respiteIntakesDataEntry}
              dischargesMetric={metrics?.respiteDischargesDataEntry}
              isLoading={isLoading}
            />
          </PaperMetricContainer>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%", height: "100%" }}
        >
          <Box style={{ paddingBottom: 16 }}>
            <PaperMetricContainer>
              <FilledBedDaysMetricPanel
                isLoading={isLoading}
                metric={metrics?.dvRespiteFilledBedDays}
                provider={provider}
              />
            </PaperMetricContainer>
          </Box>
          <Box>
            <PaperMetricContainer>
              <FilledBedDaysMetricPanel
                isLoading={isLoading}
                metric={metrics?.prRespiteFilledBedDays}
                provider={provider}
              />
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={2}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ width: "100%" }}
        >
          <Box style={{ paddingBottom: 16 }} flex={1}>
            <PaperMetricContainer>
              <NumericValueMetricPanel
                metric={metrics?.dvFyracIntakes}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box style={{ paddingBottom: 16 }} flex={1}>
            <PaperMetricContainer>
              <NumericValueMetricPanel
                metric={metrics?.dvFyracGroupSessions}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box style={{ paddingBottom: 16 }} flex={1}>
            <PaperMetricContainer>
              <NumericValueMetricPanel
                metric={metrics?.dvFyracIndividualSessions}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box style={{ paddingBottom: 16 }} flex={1}>
            <PaperMetricContainer>
              <NumericValueMetricPanel
                metric={metrics?.prFyracIntakes}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box style={{ paddingBottom: 16 }} flex={1}>
            <PaperMetricContainer>
              <NumericValueMetricPanel
                metric={metrics?.prFyracGroupSessions}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
          <Box style={{ paddingBottom: 16 }} flex={1}>
            <PaperMetricContainer>
              <NumericValueMetricPanel
                metric={metrics?.prFyracIndividualSessions}
                isLoading={isLoading}
              />
            </PaperMetricContainer>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
