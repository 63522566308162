import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import SpinnerButton from "../../../app/components/buttons/SpinnerButton";
import ErrorText from "../../../app/components/ErrorText";
import { RequestStateDto } from "../../../app/dtos/RequestStateDto";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CaseDetailDto } from "../../cases/dtos/CaseDetailDto";
import { selectModalId } from "../../modal/state/modalSelectors";
import { closeModal } from "../../modal/state/modalSlice";
import { CaseContactDto } from "../dtos/CaseContactDto";
import { CaseContactFormDto } from "../dtos/CaseContactFormDto";
import { useCaseContactForm } from "../hooks/useCaseContactForm";
import CaseContactFormFields from "./CaseContactFormFields";

export const caseContactModalId = "case-contact-form-modal";

interface Props {
  caseDto: CaseDetailDto;
  onCreate: (dto: CaseContactFormDto) => Promise<any>;
  onUpdate: (id: string, dto: CaseContactFormDto) => Promise<any>;
  requestState: RequestStateDto;
  contact?: CaseContactDto;
}

const CaseContactFormDialog: React.FC<Props> = ({
  caseDto,
  contact,
  requestState,
  onCreate,
  onUpdate
}) => {
  //#region State
  const modalId = useAppSelector(selectModalId);
  const dispatch = useAppDispatch();
  const { form, handlers, state } = useCaseContactForm(
    onCreate,
    onUpdate,
    caseDto,
    contact
  );
  const { handleSubmit } = handlers;
  const { isValid, control, clearForm } = form;
  const { staffMembers, getProviderStaffMembersRequestState } = state;
  const { error, isLoading } = requestState;
  //#endregion

  //#region
  const handleCloseModal = async () => {
    clearForm();
    dispatch(closeModal());
  };
  //#endregion

  return (
    <Dialog open={modalId === caseContactModalId} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {contact ? "Edit" : "Create"} Case Contact
          <ErrorText error={error?.message} />
          <ErrorText
            error={getProviderStaffMembersRequestState.error?.message}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <CaseContactFormFields
              disabled={requestState.isLoading}
              control={control}
              staffMembers={staffMembers}
              staffMembersAreLoading={
                getProviderStaffMembersRequestState.isLoading
              }
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <SpinnerButton
            content="Cancel"
            showSpinner={false}
            type="button"
            onClick={handleCloseModal}
          />
          <SpinnerButton
            showSpinner={isLoading}
            content="Save"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CaseContactFormDialog;
