import {
  Box, IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import EditButton from "../../../app/components/buttons/EditButton";
import CurrencyLabel from "../../../app/components/labels/CurrencyLabel";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import MonthLabel from "../../../app/components/labels/MonthLabel";
import {TabProps} from "../../../app/components/TabContainer";
import {InvoiceDto} from "../dtos/InvoiceDto";
import AccessLevelWrapper from "../../../app/components/access-wrappers/AccessLevelWrapper";
import {AccessLevelEnum} from "../../authentication/enums/AccessLevelEnum";
import {PermissionResourceNameEnum} from "../../authentication/enums/PermissionResourceNameEnum";
import RedAlertLabel from "../../../app/components/labels/RedAlertLabel";
import StyledLink from "../../../app/components/StyledLink";
import {ReportingPeriodDto} from "../../reporting-periods/dto/ReportingPeriodDto";
import FnAccessWrapper from "../../../app/components/access-wrappers/FnAccessWrapper";
import {useGetInvoices, useReturnInvoice} from "../hooks/invoicingHooks";
import InvoiceDocumentDialog, {invoiceDocumentModalId} from "./InvoiceDocumentDialog";
import ReturnProviderInvoiceConfirmationDialog, {
  returnProviderInvoiceConfirmationDialogId
} from "./ReturnProviderInvoiceConfirmationModal";
import {closeModal, openModal} from "../../modal/state/modalSlice";
import {useAppDispatch} from "../../../app/hooks";
import {addSuccessNotification} from "../../notifications/state/notificationSlice";
import {useCombinedLoadingState} from "../../../app/hooks/useCombinedRequests";
import TableLoadingPlaceholder from "../../../app/components/TableLoadingPlaceholder";
import FilterListIcon from "@material-ui/icons/FilterList";

interface Props {
  year: number;
  previousReportingPeriod?: ReportingPeriodDto;
}

interface FilterOptions {
  providerName: string;
  month?: number;
  contract: string;
}

const ProviderInvoiceYearList: React.FC<Props> = ({
  year,
  previousReportingPeriod,
}) => {
  const dispatch = useAppDispatch();

  const [providerFilter, setProviderFilter] = useState<FilterOptions>({ providerName: "*", contract: "*" });


  const filter = useCallback((invoices: InvoiceDto[]) => {
    invoices = providerFilter.providerName === "*" ? invoices : invoices.filter(i => i.provider.name === providerFilter.providerName)
    invoices = !providerFilter.month ? invoices : invoices.filter(i => i.reportingPeriod.month === providerFilter.month)
    invoices = providerFilter.contract === "*" ? invoices : invoices.filter(i => i.contract.name === providerFilter.contract)
    return invoices;
  }, [providerFilter])

  const [fetchInvoices, invoices, fetchInvoicesRequestState] = useGetInvoices()
  const [returnInvoice, , returnInvoiceRequestState] = useReturnInvoice();
  const handleConfirmReturnInvoice = useCallback(
    async (invoice: InvoiceDto) => {
      await returnInvoice(invoice);
      await fetchInvoices({ year })
      dispatch(addSuccessNotification({ message: "Invoice Returned" }));
      dispatch(closeModal());
    },
    [returnInvoice, fetchInvoices, year, dispatch]
  );
  useEffect(() => {
    fetchInvoices({ year })
  }, [fetchInvoices, year])

  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceDto>();
const [showFilters, setShowFilters] = useState(false);
  const handleViewInvoiceDocument = useCallback(
    (invoice: InvoiceDto) => {
      setSelectedInvoice(invoice);
      dispatch(openModal({ modalId: invoiceDocumentModalId }));
    },
    [setSelectedInvoice, dispatch]
  );

  const handleReturnInvoice = useCallback(
    async (invoice: InvoiceDto) => {
      setSelectedInvoice(invoice);
      dispatch(
        openModal({ modalId: returnProviderInvoiceConfirmationDialogId })
      );
    },
    [setSelectedInvoice, dispatch]
  );

  const months = Object.keys(invoices?.map(i => i.reportingPeriod.month).reduce((acc, month) => ({ ...acc, [month]: month }), {}) || {})
    .map(i => parseInt(i))
    .sort((a, b) => { return a < b ? 1 : -1 });

  //#region UI Helpers
  const createTableRow = (invoice: InvoiceDto, bgColor: string) => {
    return (
      <TableRow
        key={invoice.id}
        style={{
          backgroundColor: bgColor,
          color: invoice.isReturned ? "red" : "black",
        }}
      >
        <TableCell>
          <StyledLink
            to="/"
            onClick={(e) => {
              e.preventDefault();
              handleViewInvoiceDocument(invoice);
            }}
          >
            {invoice.invoiceNumber}
          </StyledLink>
        </TableCell>
        <TableCell>
          <MonthLabel month={invoice.reportingPeriod.month} />
        </TableCell>
        <FnAccessWrapper>
          <TableCell>{invoice.provider.name}</TableCell>
        </FnAccessWrapper>
        <TableCell>
          <Typography >{invoice.contract.name} </Typography>
        </TableCell>
        <TableCell>
          <DateTimeLabel date={invoice.createdAt} />
        </TableCell>
        <TableCell>
          <CurrencyLabel amount={invoice.total} />
        </TableCell>
        <TableCell>
          {invoice.isReturned && (
            <Tooltip
              title={
                <Typography>
                  Invoice returned by Florida Network. Please correct data and submit a
                  new invoice for this Reporting Period as soon as possible.
                </Typography>
              }
              enterTouchDelay={200}
            >
              <Box style={{ cursor: "pointer" }}>
                <RedAlertLabel hide={!invoice.isReturned} label="Returned" />
              </Box>
            </Tooltip>
          )}

          {!invoice.isReturned &&
            invoice.reportingPeriod.id === previousReportingPeriod?.id && (
              <AccessLevelWrapper
                name={PermissionResourceNameEnum.ReturnProviderInvoice}
                accessLevel={AccessLevelEnum.Execute}
              >
                <EditButton
                  text="Return"
                  onClick={() => handleReturnInvoice(invoice)}
                />
              </AccessLevelWrapper>
            )}
        </TableCell>
      </TableRow>
    );
  };

  const providers = invoices ? Object.keys(invoices?.reduce((acc, val) => ({...acc, [val.provider.name]: val}), {})).sort() : [];
  const contracts = invoices ? Object.keys(invoices?.reduce((acc, val) => ({...acc, [val.contract.name]: val}), {})).sort() : [];

  const renderMonth = (
    invoices: InvoiceDto[],
    index: number
  ) => {
    return (
      <Fragment key={`month-${index}`}>
        {filter(invoices)
          .map((invoice) =>
            createTableRow(
              invoice,
              index % 2 === 0 ? "" : "rgba(0, 0, 0, 0.067)"
            )
          )}
      </Fragment>
    );
  };

  const loadingInvoices = useCombinedLoadingState([fetchInvoicesRequestState])

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const filterApplied = providerFilter.providerName !== "*"
    || providerFilter.contract !== "*"
    || !!providerFilter.month;

  //#endregion

  return (
    <Box width={"100%"}>
      {selectedInvoice &&
        <InvoiceDocumentDialog invoice={selectedInvoice} />}
        
      <ReturnProviderInvoiceConfirmationDialog
        onConfirm={() => handleConfirmReturnInvoice(selectedInvoice!)}
        invoice={selectedInvoice!}
        requestState={returnInvoiceRequestState}
      />
      <Table>
        <TableHead>
          <FnAccessWrapper>
            <TableRow>
              <TableCell style={{ padding: 4 }}>
                <IconButton onClick={e => setShowFilters(!showFilters)}>
                  <FilterListIcon style={{ color: filterApplied ? "red" : "inherit" }} />
                  { filterApplied ? <Typography>Filter Applied</Typography> : <></>}
                </IconButton>
              </TableCell>
              <TableCell style={{ padding: 4 }}>
                { showFilters && <Select variant={"outlined"} value={providerFilter.month || 0} onChange={e => setProviderFilter({ ...providerFilter, month: e.target.value as number })}>
                    <MenuItem value={0}>All Months</MenuItem>
                  {months.map(p => <MenuItem key={p} value={p}>{monthNames[p - 1]}</MenuItem>)}
                </Select> }
              </TableCell>
              <FnAccessWrapper>
                <TableCell style={{ padding: 4 }}>
                  { showFilters && <Select variant={"outlined"} value={providerFilter.providerName} onChange={e => setProviderFilter({ ...providerFilter, providerName: e.target.value as string })}>
                      <MenuItem value={"*"}>All Providers</MenuItem>
                    {providers.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
                  </Select> }
                </TableCell>
              </FnAccessWrapper>
              <TableCell style={{ padding: 4 }}>
                { showFilters && <Select variant={"outlined"} value={providerFilter.contract} onChange={e => setProviderFilter({ ...providerFilter, contract: e.target.value as string })}>
                    <MenuItem value={"*"}>All Contracts</MenuItem>
                  {contracts.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
                </Select> }

              </TableCell>
              <TableCell style={{ padding: 4 }}></TableCell>
              <TableCell style={{ padding: 4 }}></TableCell>
              <TableCell style={{ padding: 4 }}></TableCell>
              <AccessLevelWrapper
                name={PermissionResourceNameEnum.ReturnProviderInvoice}
                accessLevel={AccessLevelEnum.Execute}
              >
                <TableCell style={{ padding: 4 }}></TableCell>
              </AccessLevelWrapper>
            </TableRow>
          </FnAccessWrapper>
          <TableRow>
            <TableCell>Invoice #</TableCell>
            <TableCell>Month</TableCell>
            <FnAccessWrapper>
              <TableCell>Provider</TableCell>
            </FnAccessWrapper>
            <TableCell>Contract</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Total</TableCell>
            <TableCell></TableCell>
            <AccessLevelWrapper
              name={PermissionResourceNameEnum.ReturnProviderInvoice}
              accessLevel={AccessLevelEnum.Execute}
            ></AccessLevelWrapper>
          </TableRow>

        </TableHead>

        <TableBody>
                  {loadingInvoices && (
          <TableLoadingPlaceholder columns={6} rows={10} />
        )}
          {invoices && months && months
            .map((month) =>
              renderMonth(invoices.filter(i => i.reportingPeriod.month === month), month)
            )}
        </TableBody>
      </Table>
      {invoices && invoices.length === 0 && (
        <Typography
          color="textSecondary"
          variant="h6"
          style={{ marginTop: "6px" }}
        >
          No invoices created for this year.
        </Typography>
      )}
    </Box>
  );
};

export const createProviderInvoiceYearListTab = (
  props: Props,
  year: string
): TabProps => ({
  value: year,
  label: <Typography>{year}</Typography>,
  content: <ProviderInvoiceYearList {...props} />,
});

export default ProviderInvoiceYearList;
