import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import React, { Fragment } from "react";
import WriteAccessLevelWrapper from "../../../app/components/access-wrappers/WriteAccessLevelWrapper";
import DeleteButton from "../../../app/components/buttons/DeleteButton";
import EditButton from "../../../app/components/buttons/EditButton";
import KeyValueRow from "../../../app/components/KeyValueRow";
import DateTimeLabel from "../../../app/components/labels/DateTimeLabel";
import ListDivider from "../../../app/components/ListDivider";
import { LoadingListItem } from "../../../app/components/loading/LoadingList";
import { createNumberRange } from "../../../app/helpers";
import { PermissionResourceNameEnum } from "../../authentication/enums/PermissionResourceNameEnum";
import { BakerActDto } from "../dto/BakerActDto";

interface BakerActListItemProps {
  bakerAct: BakerActDto;
  onEdit?(): void;
  onDelete?(): void;
}

export const BakerActListItem: React.FC<BakerActListItemProps> = ({
  bakerAct,
  onEdit,
  onDelete,
}) => {
  return (
    <ListItem>
      <ListItemText
        secondary={
          <Box component={"span"} position="relative">
            <Box position="absolute" top="0" right="0">
              <WriteAccessLevelWrapper name={PermissionResourceNameEnum.Case}>
                <Box marginLeft="auto" component="span">
                  {onDelete && <DeleteButton onClick={onDelete} />}
                  {onEdit && <EditButton onClick={onEdit} />}
                </Box>
              </WriteAccessLevelWrapper>
            </Box>
            <Grid container spacing={1} component="span">
              <KeyValueRow>
                <span>Started At</span>
                <span>
                  <DateTimeLabel date={bakerAct.startedAt} />
                </span>
              </KeyValueRow>
              <KeyValueRow>
                <span>Returned At</span>
                {bakerAct.returnedAt ? (
                  <DateTimeLabel date={bakerAct.returnedAt} />
                ) : (
                  "No return date entered"
                )}
              </KeyValueRow>
              <KeyValueRow>
                <span>Notes</span>
                <span>
                  <Paper
                    variant="outlined"
                    style={{
                      width: "100%",
                      height: "100px",
                      padding: "6px",
                      overflow: "auto",
                      display: "block",
                    }}
                    component="span"
                  >
                    {bakerAct.notes}
                  </Paper>
                </span>
              </KeyValueRow>
            </Grid>
          </Box>
        }
      />
    </ListItem>
  );
};

interface BakerActListProps {
  bakerActs: BakerActDto[];
  isLoading: boolean;
  onEdit?(dto: BakerActDto): void;
  onDelete?(dto: BakerActDto): void;
}

const BakerActList: React.FC<BakerActListProps> = ({
  bakerActs,
  isLoading,
  onDelete,
  onEdit,
}) => {
  const sortedBakerActs = () => {
    return bakerActs.sort((a, b) =>
      new Date(a.startedAt) > new Date(b.startedAt) ? 1 : -1
    );
  };

  const createListItem = (dto: BakerActDto, index: number) => {
    const handleEdit = onEdit ? () => onEdit(dto) : undefined;
    const handleDelete = onDelete ? () => onDelete(dto) : undefined;

    return (
      <Fragment key={dto.id}>
        <BakerActListItem
          bakerAct={dto}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
        <ListDivider
          variant="fullWidth"
          index={index}
          length={bakerActs.length}
        />
      </Fragment>
    );
  };

  const content = () => {
    if (isLoading)
      return createNumberRange(4).map((i) => (
        <LoadingListItem key={`loading-${i}`} />
      ));

    if (bakerActs.length === 0)
      return (
        <ListItem>
          <ListItemText primary={"No Baker Acts created."} />
        </ListItem>
      );

    return sortedBakerActs()?.map(createListItem);
  };

  return <List>{content()}</List>;
};

export default BakerActList;
