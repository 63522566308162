import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { ContentSection } from "./ContentSection";
import { ServicePlanCommonHeader } from "./ServicePlanCommonHeader";
import EditButton from "../../app/components/buttons/EditButton";
import { useServicePlanPageContext } from "../ServicePlanPageContext";
import { ServicePlanGoalDetails } from "./ServicePlanGoalDetails";
import AddButton from "../../app/components/buttons/AddButton";
import { ServicePlanGoalStatusLabel } from "./ServicePlanGoalStatusLabel";
import { PermissionResourceNameEnum } from "../../features/authentication/enums/PermissionResourceNameEnum";
import WriteAccessLevelWrapper from "../../app/components/access-wrappers/WriteAccessLevelWrapper";

export const ServicePlanDetails: React.FC = () => {
  const {
    servicePlan: { goals },
    goals: { onAddGoalClicked, onUpdateGoalClicked },
  } = useServicePlanPageContext();

  return (
    <Grid container spacing={2} style={{ marginTop: -24 }}>
      <Grid item xs={12}>
        <ContentSection
          label={"Overview"}
          content={<ServicePlanCommonHeader />}
        />
      </Grid>

      <WriteAccessLevelWrapper name={PermissionResourceNameEnum.ServicePlan}>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            style={{ width: "100%", paddingTop: 24 }}
            justifyContent={"end"}
          >
            <AddButton text={"Add Goal"} onClick={onAddGoalClicked} />
          </Box>
        </Grid>
      </WriteAccessLevelWrapper>

      {goals.map((goal) => (
        <Grid item xs={12} key={goal.id}>
          <ContentSection
            label={
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>Goal: {goal.name}</Typography>
                </Grid>
                <Grid item>
                  <ServicePlanGoalStatusLabel status={goal.status} />
                </Grid>
              </Grid>
            }
            content={<ServicePlanGoalDetails goal={goal} />}
            actions={
              <>
                <WriteAccessLevelWrapper
                  name={PermissionResourceNameEnum.ServicePlan}
                >
                  <EditButton
                    text={"Edit"}
                    onClick={() => onUpdateGoalClicked(goal.id)}
                  />
                </WriteAccessLevelWrapper>
              </>
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};
