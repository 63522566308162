import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SnapGroupCycleDto } from "../dtos/SnapGroupCycleDto";
import {
  createSnapGroupCycleFormDto,
  SnapGroupCycleFormDto,
} from "../dtos/SnapGroupCycleFormDto";
import * as Yup from "yup";

const schema = Yup.object().shape({
  startedOn: Yup.string().label("Group Start Date").required(),
  gender: Yup.string().label("Gender").required(),
  programTypeId: Yup.string().label("Program Type").required(),
  providerId: Yup.string().label("Provider").required(),
});

export const useSnapCycleForm = (
  onSubmit: (dto: SnapGroupCycleFormDto) => Promise<void>,
  snapCycle?: SnapGroupCycleDto
) => {
  //#region State
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: createSnapGroupCycleFormDto(snapCycle),
    resolver: yupResolver(schema),
    mode: "all",
  });
  //#endregion

  const clearForm = () => {
    reset(createSnapGroupCycleFormDto(snapCycle));
  };
  const handleOnSubmit = handleSubmit(async (dto: SnapGroupCycleFormDto) => {
    await onSubmit(dto);
  });

  useEffect(() => {
    reset(createSnapGroupCycleFormDto(snapCycle));
  }, [snapCycle, reset]);

  return {
    form: { control, isValid, clearForm },
    handlers: { handleSubmit: handleOnSubmit },
  };
};
