import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useFileUpload } from "../hooks/coreApiHooks";
import ClearIcon from "@material-ui/icons/Clear";
import ErrorText from "./ErrorText";
import { ColorsEnum } from "../enums/ColorsEnum";

interface Props {
  uploadButtonText?: string;
  defaultFileName?: string;
  error?: string;
  allowedFiletypes?: string;
  disabled?: boolean;
  onFileUploaded(filename: string): void;
}

const FileUploadComponent: React.FC<Props> = ({
  uploadButtonText = "Upload File",
  error,
  onFileUploaded,
  defaultFileName = "",
  allowedFiletypes,
  disabled,
  ...props
}) => {
  const [uploadFile, , uploadFileRequestState] = useFileUpload();
  const [fileName, setFileName] = useState<string>(defaultFileName);

  const handleChange = async (e: React.ChangeEvent<any>) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const result = await uploadFile(file);
      setFileName(file.name);

      if (result) onFileUploaded(result);
    }
  };

  const handleClearFile = () => {
    setFileName("");
    onFileUploaded("");
  };

  return (
    <Fragment>
      <Paper
        variant="outlined"
        style={{
          borderColor: error ? ColorsEnum.ValidationRed : "rgba(0,0,0,0.12",
        }}
      >
        <Box margin="4px">
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              component="label"
              onChange={handleChange}
              onClick={(event: any) => {
                event.target.value = null;
              }}
              color="primary"
              endIcon={
                uploadFileRequestState.isLoading ? (
                  <CircularProgress
                    style={{
                      height: "24px",
                      width: "24px",
                    }}
                  />
                ) : (
                  <CloudUploadIcon />
                )
              }
              {...props}
              disabled={disabled || uploadFileRequestState.isLoading}
            >
              <span style={{ textAlign: "center" }}>{uploadButtonText}</span>
              <input type="file" hidden accept={allowedFiletypes} />
            </Button>
            <Typography
              title={fileName || ""}
              style={{
                marginLeft: "16px",
                maxWidth: "55%",
                whiteSpace: "pre",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {fileName || "No File Selected."}
            </Typography>
            <Tooltip title="Clear File">
              <IconButton
                style={{ marginLeft: "auto" }}
                onClick={handleClearFile}
                disabled={disabled || uploadFileRequestState.isLoading}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <ErrorText error={uploadFileRequestState.error?.message} />
        </Box>
      </Paper>
      {error && (
        <Typography
          style={{
            color: ColorsEnum.ValidationRed,
            fontSize: "0.75rem",
            marginTop: "3px",
            fontWeight: "400",
            marginLeft: "14px",
            marginRight: "14px",
            letterSpacing: "0.03333em",
            lineHeight: "1.66",
          }}
        >
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

export default FileUploadComponent;
