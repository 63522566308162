import React, { Fragment, useCallback, useEffect, useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ReferralDto } from "./dtos/ReferralDto";
import { ReferralStatusEnum } from "./enums/ReferralStatusEnum";
import { PermissionResourceNameEnum } from "../authentication/enums/PermissionResourceNameEnum";
import { useAppSelector } from "../../app/hooks";
import { selectHasWriteAccess } from "../authentication/state/authenticationSelectors";
import { getProviderId } from "../authentication/state/authenticationActions";
import { fnyfsProviderId } from "../authentication/constants";

const buildRemoveReferralAction = (
  onClick: () => void,
  referral?: ReferralDto
) => {
  if (referral === undefined || referral.status !== ReferralStatusEnum.Pending)
    return undefined;

  return (
    <MenuItem onClick={onClick} key="remove-referral-menu-item">
      Remove Referral
    </MenuItem>
  );
};

interface Props {
  referral?: ReferralDto;
  onRemoveReferralClicked(): void;
}

const ReferralsAdditionalActions: React.FC<Props> = ({
  referral,
  onRemoveReferralClicked,
}) => {
  const [menuItems, setMenuItems] = useState<React.ReactNode[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const canWriteReferral = useAppSelector(
    selectHasWriteAccess(PermissionResourceNameEnum.Referrals)
  );
  const assignedTo = referral?.referralRoutes.find(
    (x) => x.forwardReferralRouteId === undefined
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleAction = useCallback(
    (action: () => void) => () => {
      action();
      handleClose();
    },
    [handleClose]
  );

  useEffect(() => {
    const menuItems: React.ReactNode[] = [];

    const removeReferralItem = buildRemoveReferralAction(
      handleAction(onRemoveReferralClicked),
      referral
    );

    if (
      removeReferralItem &&
      canWriteReferral &&
      (assignedTo?.provider?.id === getProviderId() ||
        (assignedTo?.provider === undefined &&
          getProviderId() === fnyfsProviderId))
    )
      menuItems.push(removeReferralItem);

    setMenuItems(menuItems);
  }, [
    referral,
    setMenuItems,
    handleAction,
    onRemoveReferralClicked,
    canWriteReferral,
    assignedTo,
  ]);

  if (menuItems.length === 0) return <></>;

  return (
    <Fragment>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </Fragment>
  );
};

export default ReferralsAdditionalActions;
