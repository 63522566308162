import { useCallback, useEffect, useState } from "react";
import { trimPhoneNumber } from "../../../app/helpers";
import { ScreeningFormDto } from "../../screenings/dtos/ScreeningFormDto";
import { YouthDto } from "../../youths/dto/YouthDto";
import { YouthFormDto } from "../../youths/dto/YouthFormDto";
import { YouthSearchParamsDto } from "../../youths/dto/YouthSearchParamsDto";
import { YouthSummaryDto } from "../../youths/dto/YouthSummaryDto";
import { useYouthScreeningForm } from "../../youths/hooks/useYouthScreeningForm";
import { useCreateYouth, useGetYouths } from "../../youths/hooks/youthHooks";
import { ReferralDto } from "../dtos/ReferralDto";

export enum ScreeningFromReferralSteps {
  SelectYouth = 1,
  CreateScreening = 2,
}

export const useScreeningFromReferralFormDialog = (
  onSubmit: (youthId: string, dto: ScreeningFormDto) => Promise<void>,
  referral?: ReferralDto
) => {
  const [selectedYouth, setSelectedYouth] = useState<
    YouthSummaryDto | YouthDto
  >();
  const [createYouth, , createYouthRequestState] = useCreateYouth();
  const [getYouths, youthResults, getYouthsRequestState] = useGetYouths();
  const [params, setParams] = useState<YouthSearchParamsDto>();
  const [currentStep, setCurrentStep] = useState(
    ScreeningFromReferralSteps.SelectYouth
  );

  const screeningForm = useYouthScreeningForm(
    (dto: ScreeningFormDto) => onSubmit(selectedYouth!.id, dto),
    selectedYouth,
    referral
  );

  const handleSearchYouth = useCallback(
    (dto: YouthSearchParamsDto) => {
      setParams(dto);
    },
    [setParams]
  );

  const handlePreviousClick = () => {
    switch (currentStep) {
      case ScreeningFromReferralSteps.CreateScreening:
        setCurrentStep(ScreeningFromReferralSteps.SelectYouth);
    }
  };

  const handleCreateYouth = async (dto: YouthFormDto) => {
    dto.preferredName =
      dto.preferredName === "" ? undefined : dto.preferredName;
    dto.preferredPronouns =
      dto.preferredPronouns === "" ? undefined : dto.preferredPronouns;
    if (dto.ssn === "   -  -    ") dto.ssn = undefined;
    if (dto.email === "") dto.email = undefined;
    dto.phoneNumber = trimPhoneNumber(dto.phoneNumber);
    const result = await createYouth(dto);

    if (result) handleYouthSelected(result);
  };

  const handleYouthSelected = (youth?: YouthSummaryDto | YouthDto) => {
    setSelectedYouth(youth);

    setCurrentStep(
      youth !== undefined
        ? ScreeningFromReferralSteps.CreateScreening
        : ScreeningFromReferralSteps.SelectYouth
    );
  };

  const handleResetForm = () => {
    screeningForm.form.clearForm();
    setSelectedYouth(undefined);
    setCurrentStep(ScreeningFromReferralSteps.SelectYouth);
  };

  //#endregion

  useEffect(() => {
    if (params) getYouths(params);
  }, [params, getYouths]);

  return {
    state: {
      currentStep,
      selectedYouth,
      createYouthRequestState,
      getYouthsRequestState,
      youth: youthResults?.items || [],
    },
    handlers: {
      handlePreviousClick,
      handleSearchYouth,
      handleCreateYouth,
      handleYouthSelected,
      handleResetForm,
    },
    screeningForm,
  };
};
