import { Grid } from "@material-ui/core";
import React from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledCheckbox from "../../../app/components/inputs/ControlledCheckbox";
import ControlledInput from "../../../app/components/inputs/ControlledInput";
import ControlledWholeNumberInput from "../../../app/components/inputs/ControlledWholeNumberInput";
import {ControlledStateSelect} from "../../../app/components/inputs/ControlledStateSelect";

interface Props {
  control: Control<any>;
  disabled?: boolean;
  prefix?: string;
}

const YouthAddressFormFields: React.FC<Props> = ({
  control,
  disabled,
  prefix,
}) => {
  const { address } = useWatch({ control });
  const { unableToObtainAddress, isMailingAddress } = address;

  const formatName = (name: string) =>
    prefix != null && prefix !== "" ? `${prefix}.${name}` : name;

  return (
    <>
      <Grid item md={12} xs={12}>
        <ControlledCheckbox
          control={control}
          name={formatName("unableToObtainAddress")}
          label="Unable to Obtain Address"
          disabled={disabled}
          color="primary"
          value={unableToObtainAddress}
        />
      </Grid>
      {!unableToObtainAddress && <><Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name={formatName("address")}
          label="Address*"
          fullWidth
          disabled={disabled || unableToObtainAddress}
        />
      <ControlledCheckbox
          color="primary"
          control={control}
          name={formatName("isMailingAddress")}
          label="Is Mailing Address?"
          disabled={disabled}
          value={isMailingAddress}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ControlledInput
          control={control}
          name={formatName("building")}
          label="Building or Apartment #"
          fullWidth
          disabled={disabled}
        />
      </Grid></>}
      <Grid item md={5} xs={12}>
        <ControlledInput
          control={control}
          name={formatName("city")}
          label={`City${unableToObtainAddress ? '' : '*'}`}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ControlledStateSelect
          control={control}
          name={formatName("state")}
          label={`State${ unableToObtainAddress ? '' : '*'}`}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <ControlledWholeNumberInput
          control={control}
          name={formatName("zipCode")}
          label={`Zip${unableToObtainAddress ? '' : '*'}`}
          disabled={disabled}
          fullWidth
        />
      </Grid>
    </>
  );
};

export default YouthAddressFormFields;
